import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vis-window-titlebar',
  templateUrl: './vis-window-titlebar.component.html',
  styleUrls: ['./vis-window-titlebar.component.css']
})
export class VisWindowTitlebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
