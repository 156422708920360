<div class="row narrow-padding">
  <!-- Left Area -->
  <div class="col-sm-7">
    <div class="card">
      <!-- </div> [attr.disabled]="allDisabled"> -->
      <div class="card-header">
        <!-- {{ PersonCId }} {{ PersonName }} -->
        <div class="c-form-inline" [formGroup]="queryFormGroup">
          <app-search-bar [Cols]="3">
            <app-vis-datepicker [maxWidth]="'100%'" [formControlName]="queryFormHelper.FieldName.StartDate" searchBarField [fieldTitle]="'開始日期'"></app-vis-datepicker>
            <app-vis-datepicker [maxWidth]="'100%'" [formControlName]="queryFormHelper.FieldName.EndDate" searchBarField [fieldTitle]="'結束日期'"></app-vis-datepicker>
            <app-vis-combobox searchBarField [fieldTitle]="'科別'" [valueFormat]="'valuetext'" [formControlName]="queryFormHelper.FieldName.Dept" [data]="dept"></app-vis-combobox>

            <input searchBarField [fieldTitle]="'病歷號'" [formControlName]="queryFormHelper.FieldName.PatientNo"/>
            <input searchBarField [fieldTitle]="'身分證號'" [formControlName]="queryFormHelper.FieldName.CId"/>
            <app-vis-combobox searchBarField [fieldTitle]="'身分類別'" [valueFormat]="'valuetext'" [formControlName]="queryFormHelper.FieldName.ICodeTp" [data]="icodes"></app-vis-combobox>

            <input [formControlName]="queryFormHelper.FieldName.Name" searchBarField [fieldTitle]="'姓名'"/>
            <app-vis-datepicker searchBarField [fieldTitle]="'生日'" [maxWidth]="'100%'" [formControlName]="queryFormHelper.FieldName.Birthday"></app-vis-datepicker>
            <app-vis-combobox  searchBarField [fieldTitle]="'調劑類別'" [valueFormat]="'valuetext'" [formControlName]="queryFormHelper.FieldName.DispTp" [data]="disp"></app-vis-combobox>
            <mat-checkbox searchBarField [formControlName]="queryFormHelper.FieldName.IsClearDate">輸入病歷號/身分證號/姓名/生日時，查詢會清除日期</mat-checkbox>

            <app-vis-button (click)="onBtnOpenDeclareStatisticsClick()" [icon]="'assessment'" [label]="'統計'" title="申報資料核對統計"></app-vis-button>
            <app-vis-button (click)="onBtnExportHistoryListAsExcel()" [icon]="'file_open'" [label]="'匯出'" title="病歷資料匯出Excel"></app-vis-button>

            <!-- <app-vis-button style="width: 50px; pointer-events: none;"></app-vis-button> -->

            <app-vis-button (click)="onBtnAdvQueryClick()" [icon]="'search'" [label]="'進階查詢'"></app-vis-button>
            <app-vis-button (click)="onBtnQueryClearClick()" [styleType]="'warning'" [icon]="'remove_circle'" [label]="'清除'"></app-vis-button>
            <app-vis-button submit (click)="onBtnQueryClick()" [icon]="'search'" [label]="'查詢'"></app-vis-button>
            <!-- <button type="submit" (click)="onBtnAdvQueryClick()" i18n="@@Query"
              class="vis-btn-config p-0 position-relative pl-2" title="進階查詢">
              <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">進階查詢</span>
            </button> -->
            <!-- <button type="button" (click)="onBtnQueryClearClick()" i18n="@@Clear"
              class="vis-btn-config p-0 position-relative pl-2 w-18" title="清除">
              <mat-icon class="pref-icon remove vis-combine-icon">remove_circle</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">清除</span>
            </button> -->
            <!-- <button type="submit" (click)="onBtnQueryClick()" i18n="@@Query"
              class="vis-btn-config p-0 position-relative pl-2 w-18" title="查詢">
              <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
            </button> -->
          </app-search-bar>
          <div class="clearfix"></div>
        </div>
        <!-- [pageSize]="pageSize" -->
      </div>
      <div class="card-body no-padding patients-list more-small hist">

        <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true"
           (pageChange)="pageChange($event)" [selectable]="{mode: 'single'}"
          (selectionChange)="selectChange($event)" [kendoGridSelectBy]="'Id'" [selectedKeys]="selectedItems"
          class="no-border" (sort)="sort($event)">
          <app-vis-grid-column title="選入" [width]="40" class="text-center" *ngIf="fromMode=='hist'">
            <ng-template let-dataItem>
                <mat-icon  (click)="onBtnOrderClick(dataItem)"
                style="width: 100%;"
                class="vis-combine-icon cursor-pointer icon-hover mat-icon-20"
                  title="選入">check_circle</mat-icon>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="閱" [width]="40" class="text-center" *ngIf="fromMode=='hist'">
            <ng-template let-dataItem>
                <mat-checkbox [(ngModel)]="dataItem.IsRead" (change)="readChanged($event,dataItem)"></mat-checkbox>

            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column [sortable]="true" field="PatientNo" title="病歷號" [width]="80" [CellStyle]="{direction:'rtl'}" i18n-title="@@PatientNo"></app-vis-grid-column>
          <app-vis-grid-column [sortable]="true" field="PatientName" title="病患姓名" [width]="90" i18n-title="@@PatientName">
          </app-vis-grid-column>
          <app-vis-grid-column [sortable]="true" field="RegDate"  [sortOrder]="'desc'" title="看診日期" [width]="140" class="text-center custom-m-auto" i18n-title="@@RegDate">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.RegDate | formatROCTime:'ispadstart'">{{ dataItem.RegDate | formatROCTime:'ispadstart,timetohourmin' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Isqno" title="卡序" i18n-title="@@Isqno" [width]="60" class="text-center custom-m-auto"></app-vis-grid-column>
          <app-vis-grid-column field="RoomCode" title="診" i18n-title="@@RoomCode" [width]="30" class="text-center custom-m-auto"></app-vis-grid-column>
          <app-vis-grid-column field="DoctorName" title="醫師" i18n-title="@@DoctorName" [width]="80" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div [ngStyle]="{'color' : (dataItem.DeclareDrID!=dataItem.DoctorCId) ? '#4900ff' : 'rgba(0, 0, 0, 0.87)'}" [title]="dataItem.DoctorName +(dataItem.DeclareDrID!=dataItem.DoctorCId?'　(看診醫師與申報醫師身分證不同)':'')">{{ dataItem.DoctorName }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Icode" title="身分" i18n-title="@@Icode" [width]="40"></app-vis-grid-column>
          <app-vis-grid-column field="SameTreat" title="類" i18n-title="@@SameTreat" [width]="30" class="text-center custom-m-auto"></app-vis-grid-column>
          <app-vis-grid-column field="Case" title="案類" i18n-title="@@Case" [width]="40" class="text-center custom-m-auto"></app-vis-grid-column>
          <app-vis-grid-column field="TreatCount" title="次" i18n-title="@@TreatCount" [width]="30" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div style="word-break: keep-all;"
                [title]="(dataItem.TreatCount?'療次：'+ dataItem.TreatCount:'') +(dataItem.PresCount? ' 慢次：'+dataItem.PresCount:'')">
                {{ (dataItem.TreatCount?((dataItem.PresCount? ' 療':'')+dataItem.TreatCount):'')+ (dataItem.PresCount?((dataItem.TreatCount? ' \n慢':'')+dataItem.PresCount):'')}}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="ADcl" title="健保額" i18n-title="@@ADcl" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
          <app-vis-grid-column field="AOwn" title="自費" i18n-title="@@AOwn" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
        </app-vis-grid>
      </div>
    </div>
  </div>
  <!-- Right Area -->
  <div class="col-sm-5">
    <div class="card">
      <div class="card-header card-header-light-blue" i18n="@@MedRecord">
        <h5 class="vis-title d-inline-block text-base font-weight-bold" style="padding-top: 5px;"><mat-icon
            class="pref-icon vis-combine-icon">import_contacts</mat-icon>病歷紀錄
        </h5>
        <div class="float-right end-0" style="display: inline-block;">
        <span class="ml-2">
          <!-- <button type="button" (click)="onBtnSaveClick()"  [disabled]="!btnSave.isEnable" i18n="@@Save">儲存</button> -->
          <button type="button"
            (click)="onOpenHistCertDialog()"
            class="vis-btn-config p-0 position-relative pl-2 w-26"
            title="就醫證明">
            <mat-icon class="pref-icon vis-combine-icon">print</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">就醫證明</span>
          </button>
          <button type="button"
            (click)="onBtnPrintClick()"
            class="vis-btn-config p-0 position-relative pl-2 w-18 ml-1"
            title="補印">
            <mat-icon class="pref-icon vis-combine-icon">collections_bookmark</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">補印</span>
          </button>
        </span>
        </div>
      </div>
      <div class="card-body" style="padding-left: 10px; padding-right: 10px;">
        <app-shared-hist-hist-view #histViewComp [showNhiPay]="true" [IsShowToolBar]="false">
        </app-shared-hist-hist-view>
      </div>
    </div>
  </div>
</div>

<div class="row narrow-padding">
  <app-medication-way-and-receipt-report [hidden]="true" *ngIf="medicationWayRegId" [regId]="medicationWayRegId"
    #medicationWayAndReceipt></app-medication-way-and-receipt-report>
</div>

<!-- 就醫證明視窗 -->
<app-vis-dialog #d [title]="'就醫證明'" [maxWidth]="'90vw'" [height]="printHeight" [show]="isOpenHistCert" (close)="isOpenHistCert=false">
  <div style="text-align: right;">
    <button type="button" (click)="onBtnPrintHistCert()" i18n="@@Print"
      class="vis-btn-config p-0 position-relative pl-2 w-20 m-1" title="列印">
      <mat-icon
        class="pref-icon add vis-combine-icon">print</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
    </button>
  </div>
  <div class="card inside-scrollbar cusDialog" [ngStyle]="{'max-height':printMaxHeight+'px'}">
    <div class="card-header" style="border-bottom:0px; line-height: normal;">
      <app-history-certificate #histCertRptComp [reportData]="this.histCertData"></app-history-certificate>
    </div>
  </div>
</app-vis-dialog>

<!-- 進階查詢視窗 -->
<app-vis-dialog  [title]="'進階查詢'"  [width]="'600px'" [show]="isOpenAdvSearch" (close)="isOpenAdvSearch=false">

  <div style="padding: 10px;">
    <app-search-bar [Cols]="4" [formGroup]="queryFormGroup">
      <div searchBarField [fieldTitle]="'案件分類'" style="display: flex;column-gap: 10px;">
        <!-- <app-vis-dropdownlist style="flex-grow: 1;" [formControlName]="queryFormHelper.FieldName.CaseTp" [data]="case"></app-vis-dropdownlist> -->
        <app-vis-combobox [valueFormat]="'valuetext'"  style="flex-grow: 1;" [formControlName]="queryFormHelper.FieldName.CaseTp" [data]="case"></app-vis-combobox>
        <mat-checkbox [formControlName]="queryFormHelper.FieldName.CaseExlude">排除</mat-checkbox>
      </div>
      <!-- <app-vis-dropdownlist [formControlName]="queryFormHelper.FieldName.CaseTp" [data]="case" searchBarField [fieldTitle]="'案件分類'"></app-vis-dropdownlist> -->
      <!-- <app-vis-dropdownlist searchBarField [fieldTitle]="'科別'" [formControlName]="queryFormHelper.FieldName.Dept" [data]="dept" ></app-vis-dropdownlist> -->
      <app-vis-combobox searchBarField [fieldTitle]="'科別'" [valueFormat]="'valuetext'"  style="flex-grow: 1;" [formControlName]="queryFormHelper.FieldName.Dept" [data]="dept"></app-vis-combobox>
      <input searchBarField [fieldTitle]="'病歷號'" [formControlName]="queryFormHelper.FieldName.PatientNo"/>
      <input searchBarField [fieldTitle]="'患者姓名'" [formControlName]="queryFormHelper.FieldName.Name"/>

      <app-vis-combobox searchBarField [fieldTitle]="'醫師'" [data]="doctor" [formControlName]="queryFormHelper.FieldName.Doctor" [valueFormat]="'valuetext'"></app-vis-combobox>
      <app-vis-combobox searchBarField [fieldTitle]="'藥師'" [data]="pharm" [formControlName]="queryFormHelper.FieldName.Pharmcist" [valueFormat]="'valuetext'"></app-vis-combobox>
      <!-- <app-vis-dropdownlist searchBarField [fieldTitle]="'醫師'" [data]="doctor" [formControlName]="queryFormHelper.FieldName.Doctor"></app-vis-dropdownlist>
      <app-vis-dropdownlist searchBarField [fieldTitle]="'藥師'" [data]="pharm" [formControlName]="queryFormHelper.FieldName.Pharmcist"></app-vis-dropdownlist> -->
      <app-vis-datepicker searchBarField [fieldTitle]="'生日'" [maxWidth]="'100%'" [formControlName]="queryFormHelper.FieldName.Birthday"></app-vis-datepicker>
      <input searchBarField [fieldTitle]="'身分證號'" [formControlName]="queryFormHelper.FieldName.CId"/>

      <app-vis-datepicker searchBarField [fieldTitle]="'看診日期'" [maxWidth]="'100%'"
                          [max]="queryFormHelper.Value.EndDate" [formControlName]="queryFormHelper.FieldName.StartDate"></app-vis-datepicker>
      <app-vis-datepicker searchBarField [fieldTitle]="'看診日期迄'" [maxWidth]="'100%'"
                          [min]="queryFormHelper.Value.StartDate" [formControlName]="queryFormHelper.FieldName.EndDate"></app-vis-datepicker>
      <input searchBarField [fieldTitle]="'患者電話'" [formControlName]="queryFormHelper.FieldName.Phone"/>
      <input searchBarField [fieldTitle]="'特記'" [formControlName]="queryFormHelper.FieldName.Remark"/>

      <app-vis-timepicker searchBarField [fieldTitle]="'看診時間'" [formControlName]="queryFormHelper.FieldName.RegTimeStart"> </app-vis-timepicker>
      <app-vis-timepicker searchBarField [fieldTitle]="'看診時間迄'" [formControlName]="queryFormHelper.FieldName.RegTimeEnd"> </app-vis-timepicker>
      <div searchBarField [fieldTitle]="'疾病碼'" style="display: flex;column-gap: 10px;" >
        <app-vis-multicombobox style="flex-grow: 1;" [allowCustom] [valueFormat]="'multiCommaValue'" [textField]="'CName'" [valueField]="'Code'" [formControlName]="queryFormHelper.FieldName.Dx" [data]="dx">
          <ng-template let-data>
            <div style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;" [title]="data.Code+' - '+data.CName">
              {{data.Code}} - {{data.CName}}
            </div>
          </ng-template>
        </app-vis-multicombobox>
        <mat-checkbox [formControlName]="queryFormHelper.FieldName.DxExclude">排除</mat-checkbox>
      </div>
      <!-- <app-vis-dropdownlist [formControlName]="queryFormHelper.FieldName.Dx" [data]="dx" searchBarField [fieldTitle]="'疾病瑪'">
        <ng-template let-data>
          {{data.Code}} - {{data.CName}}
        </ng-template>
      </app-vis-dropdownlist> -->
      <app-vis-multicombobox [valueFormat]="'multiCommaValue'" [textField]="'text'" [valueField]="'value'" [formControlName]="queryFormHelper.FieldName.Spec" [data]="spcT" searchBarField [fieldTitle]="'主要治療'">
        <ng-template let-data>
          <div style="max-width: 200px; text-overflow: ellipsis; overflow: hidden;" [title]="data.value+' - '+data.text">
            {{data.value}} - {{data.text}}
          </div>
        </ng-template>
      </app-vis-multicombobox>
      <!-- <app-vis-dropdownlist [data]="spcT" [formControlName]="queryFormHelper.FieldName.Spec" searchBarField [fieldTitle]="'主要治療'"></app-vis-dropdownlist> -->

      <div style="display: flex;" searchBarField [fieldTitle]="'申報金額'">
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.AmountMin"/>
        ～
        <input  style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.AmountMax"/>
      </div>
      <!-- <app-vis-dropdownlist [formControlName]="queryFormHelper.FieldName.DispTp" [data]="disp" searchBarField [fieldTitle]="'調劑類別'"></app-vis-dropdownlist> -->
      <app-vis-combobox [formControlName]="queryFormHelper.FieldName.DispTp" [data]="disp" searchBarField [fieldTitle]="'調劑類別'" [valueFormat]="'valuetext'"></app-vis-combobox>
      <input searchBarField [fieldTitle]="'卡序'" [formControlName]="queryFormHelper.FieldName.Icno"/>

      <div style="display: flex;" searchBarField [fieldTitle]="'藥費'">
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.DrugFeeMin"/>
        ～
        <input  style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.DrugFeeMax"/>
      </div>

      <div style="display: flex;" searchBarField [fieldTitle]="'給藥天數'">
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.DaysMin"/>
        ～
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.DaysMax"/>
      </div>
      <!-- <input searchBarField [fieldTitle]="'給藥天數'" [formControlName]="queryFormHelper.FieldName.DaysMin"/>
      <input searchBarField [fieldTitle]="'天數(至)'" [formControlName]="queryFormHelper.FieldName.DaysMax"/> -->

      <input searchBarField [fieldTitle]="'部分負擔'" [formControlName]="queryFormHelper.FieldName.SelfPay"/>
      <input searchBarField [fieldTitle]="'藥付'" [formControlName]="queryFormHelper.FieldName.DrugPay"/>
      <input searchBarField [fieldTitle]="'主訴'" [formControlName]="queryFormHelper.FieldName.CC"/>

      <input searchBarField [fieldTitle]="'院內醫令'" [formControlName]="queryFormHelper.FieldName.Rxs" title="多重醫令可用逗號區隔" (keyup)="rxsChange($event)"/>
      <div style="display: flex;" searchBarField [fieldTitle]="'院內醫令起迄'">
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.StartRxs" (keyup)="betweenRxsChange($event)"/>
        ～
        <input  style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.EndRxs" (keyup)="betweenRxsChange($event)"/>
      </div>

      <input searchBarField [fieldTitle]="'健保醫令'" [formControlName]="queryFormHelper.FieldName.SRx" title="多重醫令可用逗號區隔" (keyup)="srxChange($event)"/>
      <div style="display: flex;" searchBarField [fieldTitle]="'健保醫令起迄'">
        <input style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.StartSRx" (keyup)="betweenSRxChange($event)"/>
        ～
        <input  style="flex-grow: 1;width: 40%;" [formControlName]="queryFormHelper.FieldName.EndSRx" (keyup)="betweenSRxChange($event)"/>
      </div>

      <mat-checkbox style="padding-left: 15px;" searchBarField [formControlName]="queryFormHelper.FieldName.IsClearDate">輸入病歷號/身分證號/姓名/生日時，查詢會清除日期</mat-checkbox>
    </app-search-bar>
  </div>
  <app-vis-dialog-actions>
    <button (click)="onBtnQueryClick()">搜尋</button>
    <button (click)="onBtnQueryClearClick()">清除</button>
    <button (click)="isOpenAdvSearch=false">取消</button>
  </app-vis-dialog-actions>

</app-vis-dialog>

<!-- 補印視窗 -->
<app-print-dialog [isOpenDialog]="isOpenPrintDialog" [isDemoMode]="isDemoMode" [regItem]="regItem" (close)="isOpenPrintDialog=false"></app-print-dialog>

<!--申報資料核對統計-->
<app-vis-dialog [title]="'申報資料核對統計'" [show]="isOpenDeclareStatistics" (close)="isOpenDeclareStatistics=false">
  <div style="padding: 10px;">
    <app-vis-grid [data]="statisticsGridView" [pageSize]="statisticsPageSize" [skip]="statisticsPageSkipNo" [pageable]="true"
    (pageChange)="statisticsPageChange($event)" [selectable]="true" [kendoGridSelectBy]="'StatDate'" class="no-border">
      <app-vis-grid-column field="StatDate" title="日期" [width]="100" class="text-left custom-m-auto" i18n-title="@@StatDate">
        <ng-template let-data>
          <div class="ellipsis-content" [title]="data.StatDate | formatROCDate">{{ data.StatDate | formatROCDate }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column field="TotalCount" title="總數" i18n-title="@@TotalCount" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="LackCard" title="欠卡" i18n-title="@@LackCard" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="Apply" title="申請" i18n-title="@@Apply" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="Case09" title="09專" i18n-title="@@Case09" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="CaseChronic" title="慢專" i18n-title="@@CaseChronic" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="CaseChronic04" title="04慢專" i18n-title="@@CaseChronic04" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="CaseChronic08" title="08慢專" i18n-title="@@CaseChronic08" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="CaseOther" title="它專" i18n-title="@@CaseOther" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="SimpleForm" title="簡表" i18n-title="@@SimpleForm" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="Normal" title="一般" i18n-title="@@Normal" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="Prepaid" title="預保" i18n-title="@@Prepaid" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="Special" title="特件" i18n-title="@@Special" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="NoMedicine" title="無藥" i18n-title="@@NoMedicine" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="HighMedicine" title="高藥" i18n-title="@@HighMedicine" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="HighTotal" title="高總" i18n-title="@@HighTotal" [width]="60" class="text-right custom-m-auto"></app-vis-grid-column>
      <app-vis-grid-column field="ApplyAmount" title="申請總額" i18n-title="@@ApplyAmount" [width]="120" class="text-right custom-m-auto"></app-vis-grid-column>
    </app-vis-grid>
  </div>
  <app-vis-dialog-actions>
    <button (click)="isOpenDeclareStatistics=false" primary="true">確定</button>
    <button (click)="isOpenStatisticsRemark=true" class="ml-4">統計說明</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!--統計說明-->
<app-vis-dialog [title]="'統計說明'"  [minWidth]="'400'" [show]="isOpenStatisticsRemark" (close)="isOpenStatisticsRemark=false">
  <div style="padding: 20px;">
    <div class="content-section multiLine-pre-line">
      申報資料核對統計<br>
      以下統計都不含自費，依日期統計件數，及申請總額。<br><br>
      總數 = 欠卡 + 申請<br>
      總數 = 09專 + 慢專 + 簡表 + 它專<br>
      總數 = 一般 + 預保<br>
      慢專 = 04慢專 + 08慢專<br>
      它專 = 除了09專, 慢專, 簡表以外<br>
      無藥 = 無內服藥 且 無外用藥 且 無注射藥<br>
      高藥 = 內服藥 或 外用藥 或 注射藥>300<br>
      高總 = 總額>500<br><br>
      申請總額 = 加總申請總額(健保總額 - 部分負擔)<br>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button (click)="isOpenStatisticsRemark=false">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<ng-container *ngIf="isExportHistoryList">
  <app-history-list [hidden]="true"></app-history-list>
</ng-container>
