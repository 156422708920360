import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SystemcodeApi } from "../api-service/system-code/systemcode-api";
import { GroupMsgData} from "../signalr-service";
import { ClinicDataService } from "../data-service/clinic-data-service";
import { EasyNotificationService } from "../easy-notification.service";
import { AuthenticationService } from "src/app/security/services/authentication.service";
import { UserConfirmService } from "../user-confirm.service";
import { MenuComponent } from "src/app/layout/menu/menu.component";
import { UserCache } from "../user-cache";


@Injectable({
    providedIn: 'root'
  })
export class UserEventHandler {

    /**
     *
     */
    constructor(private auth:AuthenticationService,
      private notify:EasyNotificationService,
      private userConfirm:UserConfirmService,
      private clinicData:ClinicDataService,) {
    }
    /** 執行事件 */
    Invoke(evt:GroupMsgData){
      if(evt.Type=='Action'){
        this.doAction(evt);
      }else if(evt.Type== 'Notify'){
        this.doNotify(evt);
      }else if(evt.Type== 'Alert'){
        this.doAlert(evt);
      }
    }
    /** 動作事件 */
    private doAction(evt:GroupMsgData){
      if(evt.Act == 'Logout'){
        this.auth.logout();
      }else if(evt.Act == 'UpdateRs'){
        this.clinicData.UpdateRs(evt.Arg);
      }
      else if(evt.Act == "ResetMenu"){
        UserCache.getLoginUser().Menus = evt.Arg.children;
        MenuComponent.Instance.resetMenu();
      }
    }
    private doNotify(evt:GroupMsgData){
      this.notify.showInfo(evt.Arg);
    }
    private doAlert(evt:GroupMsgData){
      this.userConfirm.showAlert("訊息",evt.Arg);
    }
}
