/** RH列舉 */
export enum RHEnum {
    /** 陰性 */
    RHNegative = 0,
    /** 陽性 */
    RHPositive = 1,
    /** 未知 */
    RHNone = 999
}
export const RHDesc: {text: string, value: number}[] =
    [{text: '陰性', value: 0 },
     {text: '陽性', value: 1 },
     {text: '未知', value: 999 }];

