import { Component, OnInit, ElementRef } from '@angular/core';

@Component({
  selector: 'app-msg-text',
  templateUrl: './msg-text.component.html',
  styleUrls: ['./msg-text.component.css']
})
export class MsgTextComponent implements OnInit {

  constructor(private elem: ElementRef) {
    this.setId();
  }

  ngOnInit() {
  }

  setId() {
    this.elem.nativeElement.style.display = 'none'; // 不顯示
    // console.log(this.elem.nativeElement.attributes);
    // 取得textId
    const textId = this.elem.nativeElement.attributes.textId.nodeValue;
    // console.log(textId);
    // 檢查textId格式
    // 必須以MSG_開頭
    if (textId.indexOf('MSG_') !== 0) {
      throw new Error('app-msg-text textId: ' + textId + ' format error. ' +
        'textId must start with MSG_ and follow by module/component name + serial number. ' +
        'Ex: MSG_MyModuleMyComponent1');
    }
    // 設定Id
    this.elem.nativeElement.id = textId;
  }
}
