/** 機構特約類別 MedicalEnum */
export enum MedicalEnum {
    /** 醫學中心 */
    MedicalCenter = 1,
    /** 區域醫院 */
    RegionalHospital = 2,
    /** 地區醫院 */
    DistrictHospital = 3,
    /** 基層診所 */
    PrimaryClinic = 4,
    /** 藥局 */
    DrugStore = 5,
    /** 居家照護 */
    HomeCare = 6,
    /** 康復之家 */
    RecoveryHome = 7,
    /** 助產所 */
    Midwifery = 8,
    /** 檢驗所 */
    Inspection = 9,
    /** 物理治療所 */
    PhysicalTherapy = 10,
    /** 特約醫事放射機構 */
    Radiation = 11,
    /** 不詳 */
    Unknown = 99,
    /** 自費院所 */
    OwnExpense = 999
}
export const MedicalDesc: {text: string, value: number}[] =
    [{text: '醫學中心', value: 1 },
     {text: '區域醫院', value: 2 },
     {text: '地區醫院', value: 3 },
     {text: '基層診所', value: 4 },
     {text: '藥局', value: 5 },
     {text: '居家照護', value: 6 },
     {text: '康復之家', value: 7 },
     {text: '助產所', value: 8 },
     {text: '檢驗所', value: 9 },
     {text: '物理治療所', value: 10 },
     {text: '特約醫事放射機構', value: 11 },
     {text: '不詳', value: 99 },
     {text: '自費院所', value: 999 }];

