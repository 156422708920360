import { EventEmitter, Injectable } from '@angular/core';
import { StartUpService } from './start-up-service';
import { UserApi } from './api-service/user/user-api';

@Injectable({
  providedIn: 'root'
})
export class FontGreatService {
  private fontGreat:boolean = false;
  private startUp:StartUpService;
  public onFontGreatChanged: EventEmitter<boolean> = new EventEmitter()
  constructor(private userApi:UserApi) {
  }

  Init(startUp:StartUpService){
    this.startUp = startUp;
    this.fontGreat = this.startUp.userData.prefs.FontGreat||false;
    this.updateBodyClass();
  }
  /** 取得目前大小字 */
  public getFontGreat(){
    return this.fontGreat;
  }
  /** 切換大小字並儲存 */
  async switchFontGreat() {
    this.fontGreat = !this.fontGreat;
    this.startUp.userData.prefs.FontGreat = this.fontGreat;
    await this.userApi.UpdatePrefs(this.startUp.userData.prefs);
    this.updateBodyClass();
    this.onFontGreatChanged.emit(this.fontGreat);
    return this.fontGreat;
  }
  updateBodyClass(){
    document.body.classList.remove('font-great-on');
    document.body.classList.remove('font-great-off');
    document.body.classList.add(this.fontGreat?'font-great-on':'font-great-off');
    document.documentElement.style.setProperty('--controlled-fontsize-s',this.fontGreat?'14px':'12px')
    document.documentElement.style.setProperty('--controlled-fontsize-m',this.fontGreat?'16px':'14px')
    document.documentElement.style.setProperty('--controlled-fontsize-l',this.fontGreat?'18px':'16px')
    document.documentElement.style.setProperty('--controlled-fontsize-xl',this.fontGreat?'20px':'18px')
  }
}

