import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { decreeRx, dentalICD10CM, dentalICD10PCS, DentalValidationRule } from '../../api-service/hist/decreeRx';
import { toothPosRuleDto } from './opd-api.model';
import { toothTreatPosDto, toothTreated } from 'src/app/hist/models/tooth-treat-position';

@Injectable({
  providedIn: 'root'
})
export class OpdApiService {
    

    apiService: WebApiService;
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createDentistService('opd');
    }

    decreeRxs: decreeRx[] = [];
    toothRules: toothPosRuleDto[] = [];
    public async GetDecreeRxes(): Promise<decreeRx[]> {
        if (this.decreeRxs.length > 0) {
            return this.decreeRxs;
        } else {
            var result = await this.apiService.get('GetDecreeRxes').toPromise<decreeRx[]>();
            this.parseToothRule(result);
            return result;
        }
    }

    public async GetDentalICD10CMs(): Promise<dentalICD10CM[]> {
        return await this.apiService.get('GetDentalICD10CMs').toPromise<dentalICD10CM[]>();
    }

    public async GetDentalICD10PCs(): Promise<dentalICD10PCS[]> {
        return await this.apiService.get('GetDentalICD10PCs').toPromise<dentalICD10PCS[]>();
    }

    private parseToothRule(decreeRxs: decreeRx[]) {
        decreeRxs.forEach(x => {
            var toothRule: toothPosRuleDto = new toothPosRuleDto();
            var jsonObj = JSON.parse(x.DentalValidationRule) as DentalValidationRule;
      
            toothRule.StdCode = x.StdCode;
            toothRule.ToothPosLimited = (jsonObj.ToothPosLimited.length > 0 ? jsonObj.ToothPosLimited.split(',') : []);
            toothRule.NeedToCheckToothPos = jsonObj.NeedToCheckToothPos == 'Y' ? true : false;;
            toothRule.NeedToCheckQuantity = jsonObj.NeedToCheckQuantity == 'Y' ? true : false;
            toothRule.NeedToRemark = jsonObj.NeedToRemark == 'Y' ? true : false;
            toothRule.EndDate = jsonObj.EndDate;
            this.toothRules.push(toothRule);
        });
    }
}