export class MenuItem {
  id: number;
  text: string;
  children: MenuItem[];
  path: string;
  funcSystemId: number;
  static getUrl(item: MenuItem, companyCode: string) {
    return '/' + companyCode + '/' + item.path;
  }
}
