export class RxClass {
  public static readonly Drug = 'Drug';
  public static readonly Inject = 'Inj';
  public static readonly Lab = 'Lab';
  public static readonly XRay = 'XRay';
  public static readonly CnSp1 = 'CnSp1';
  public static readonly CnSp2 = 'CnSp2';
  public static readonly CnSp3 = 'CnSp3';
  public static readonly CnMed = 'CnMed';
  public static readonly Acu = 'Acu';
  public static readonly Tuina = 'Tuina';
  public static readonly CnTrt = 'CnTrt';
  public static readonly HCare = 'HCare';

  public static readonly SelfSpecial = 'SelfSpecial';
}
