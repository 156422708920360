import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportDetailDTO } from 'src/app/services/api-service/declare/declare-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { NullOrEmpty } from 'src/app/shared/utilities';

@Component({
  selector: 'app-doctor-roomcode-payment',
  templateUrl: './doctor-roomcode-payment.component.html',
  styleUrls: ['./doctor-roomcode-payment.component.css']
})
export class DoctorRoomcodePaymentReportComponent implements OnInit, AfterViewInit {

  sizePage = {
    width: 20, //cm
    height: 28.2 //cm
  };
  paddingPage = {
      top: 0.5, //cm
      right: 0, //cm
      bottom: 0.75, //cm
      left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
      this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  /** 是否列印 */
  @Input()
  set isPrint(value) {
    if (value) {
      this.onPrint();
    }
  }
  @Input()
  set isPreviewPrint(value) {
    if (value) {
        this.fetchData();
    }
  }
  @Input() set startMonth(value) {
    if (value) {
      this._sDate = value;
    }
  }
  @Input() set endMonth(value) {
    if (value) {
      this._eDate = value;
    }
  }

  @Input()
  set isExportAsExcel(value) {
    if (value) {
      this.exportAsExcel();
    }
  }
  @Input()
  set toggleDoctor(value) {
    this.doctorType =value;
  }
  @Input() isShowTotal = false;
  @Input() isCaseGroup = false;

  @Output()
  previewPrintDone = new EventEmitter();
  @Output()
  printed = new EventEmitter<boolean>();

  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  doctorType:number;
  doctorReport: DeclareCaseReportDTO[] = [];
  reportName: string = '醫師分診別金額統計表';
  shortReportName: string = this.reportName;
  forExcel: boolean = false;

  constructor(
    private declareApi: DeclareApi,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService:HcrService,
  ) {
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
        '.pres-report2 table{ border:0px solid black; border-collapse: collapse;}\n' +
        '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 0.1px;padding-right: 0.1px;}\n' +
        '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
        '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
        '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
        '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
        '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
        // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  clearData() {
    this.totalRecord = 0;
    if (this.elContainer)
      this.elContainer.innerHTML = '';
  }

  fillData(data: DeclareCaseReportDTO[]) {
    this.elContainer = document.getElementById("container");
    this.doctorReport = data;
    // this.reportName = '醫師分診別金額統計表';
    // this.shortReportName = this.reportName;
    this.forExcel = true;
    this.totalRecord = this.doctorReport.length;
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);

    this.insertListData();
  }

  // 取得統計期間的看診人次資料，並組出報表的html內容
  async fetchData() {
    this.elContainer = document.getElementById("container");

    this.doctorReport = await this.declareApi.GetDoctorRoomcodeDeclareReport(UserCache.getLoginClinicId(), this._sDate, this._eDate, this.doctorType,this.isCaseGroup,this.isShowTotal);
    if(this.doctorReport.length > 0){
      this.insertListData();
    }
    else
    {
      this.notification.showInfo('查無資料可供列印!');
      this.previewPrintDone.emit();
    }
  }

  // 組報表的html內容
  insertListData() {
      if (!this.elContainer)
        return;

      var html_Block = "";
      var iPage = 0;
      var iBlock = 0;
      this.nowStr = this.onGetDateString(new Date(), true);
      this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
      var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
      // var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
      var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
      var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
      // 組報表內容
      // [頁表頭][List欄位顯示名稱]
      elPageContent.innerHTML = html_header;
      var elTableContent = document.getElementById("table-" + iPage + "-content");
      for (let i = 0; i < this.doctorReport.length; i++) {
          // 加入 List 每一筆的內容
          var html_ListerHeader = this.createHTMLListHeader( this.doctorReport[i].Month);    // List欄位顯示名稱
          elTableContent.innerHTML += html_ListerHeader;

          let doctorDetail = this.doctorReport[i].Detail;
          doctorDetail = [...doctorDetail, this.doctorReport[i].Subtotal];
          if(doctorDetail.length > 0) {
              for (let  j= 0; j < doctorDetail.length; j++) {
                  html_Block = this.createHTMLBlock(doctorDetail[j]);
                  elTableContent.innerHTML += html_Block;
                  var prevTableContent = elTableContent.innerHTML;

                  // 判斷是否超出頁面範圍? -> 新增一頁
                  if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                      elTableContent.innerHTML = prevTableContent;
                      iPage += 1;
                      this.elContainer.innerHTML += this.createHTMLPage(iPage);
                      elPageContent = document.getElementById("page-" + iPage + "-content");
                      // [頁表頭][List欄位顯示名稱]
                      html_header = this.createHTMLPageHeader(iPage);
                      // html_header = this.createHTMLPageNoHeader(iPage);
                      elPageContent.innerHTML += html_header;
                      elTableContent = document.getElementById("table-" + iPage + "-content");
                      elTableContent.innerHTML = html_ListerHeader;

                      this.pageContent[iPage] = [];
                      this.pageContent[iPage].push(iBlock);
                  } else {
                      this.pageContent[iPage].push(iBlock);
                  }
                  iBlock += 1;
              }
          }
      }
      // elPageContent.innerHTML +=  html_finish;
      this.previewPrintDone.emit();
  }

  convertPxToCm(px) {
      return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
      return `
        <style>
          .excel-format {
            font-size: 14pt;
          }
          .excel-format-text {
            font-size: 14pt;
            mso-number-format: "@";
          }
        </style>
        <div class="page" id="page-` + iPage + `"
                  style="
                  margin: 0px auto;
                  height: ` + this.sizePage.height + `cm;
                  width: ` + this.sizePage.width + `cm;
                  padding-top: ` + this.paddingPage.top + `cm;
                  padding-right: ` + this.paddingPage.right + `cm;
                  padding-bottom: ` + this.paddingPage.bottom + `cm;
                  padding-left: ` + this.paddingPage.left + `cm;
                  display: block;
                  margin: 0px auto;
                  box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.05);
                  box-sizing: border-box;
                  ">
                  <div class="content" id="page-` + iPage + `-content">
                  </div>
              </div>`;
  }

  createHTMLListHeader(month : string) {
      let doctype = this.doctorType === 2 ? '<th style="text-align: left;max-width: 2rem;min-width: 2rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">診醫別</th>' :'';
      let caseGroup = this.isCaseGroup ? '<th style="text-align: left;max-width: 2rem;min-width: 2rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">案類</th>' :'';
      let showMonth = month.length === 5 ? month.substring(0, 3) + '年' + month.substring(3, 5) + '月' : month;
      return `
      <tr>
      <th colspan="5" style="text-align: left;max-width: 3rem;min-width: 3rem;border-right: none;border-left: none;border-top: none;border-bottom: none;">
      資料範圍：${showMonth}
      </th>
      </tr>
      <tr>
      <th style="text-align: left;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      醫師別
      </th>
      ${doctype}
      ${caseGroup}
      <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      件數
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      診察費
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      藥服費
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      藥費
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      診療費
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      合計金額
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      申報金額
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      自付費
      </th>
      <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-right: none;border-left: none;border-top: none;border-bottom: 1px solid black;">
      自費
      </th>
  </tr>`;
  }

  stringFactory(input:string) :string{
      return !NullOrEmpty(input)?input.trimRight() :' ';
  }
  numberFactory(input:number):string{
      let result = '';
      if(!isNaN(input)){
          result = input > 0 ? input.toString():'0';
      }
      let paddedString = this.padToLength(result, 4, " ");
      return paddedString;
  }
  padToLength(inputString: string, targetLength: number, paddingChar: string): string {
      const paddingLength = targetLength - inputString.length;
      if (paddingLength > 0) {
          return inputString + paddingChar.repeat(paddingLength);
      } else {
          return inputString;
      }
  }

  stringBFactory(input:string) :string{
      let str = !NullOrEmpty(input)?input :'';
      let inputSlice = str.length >= 5 ? str.slice(0, 5) :str;
      return inputSlice;
  }
  stringCFactory(input:string) :string{
      let str = !NullOrEmpty(input)?input :'';
      let inputSlice = str.length > 7 ? str.slice(-7):str;
      return inputSlice;
  }
  stringTimeZone(input:string) :string{
      let result = !NullOrEmpty(input)?input.trimRight() :' ';
      switch(input){
          case'早上':
          result = '早';
          break;
          case'下午':
          result = '午';
          break;
          case'晚上':
          result = '晚';
          break;
          default:
          result = ' ';
          break;
      }
      return result;
  }


  createHTMLBlock(data: DeclareCaseReportDetailDTO) {
      // var summaryStyle = "border-top:2px double black;";
      // var drID = this.stringFactory(data?.DrID || '');
      var drID = this.stringFactory(data?.DoctorName || '');
      var isTotal = drID === '月計' || drID === '總計';
      var summaryStyle = !isTotal ? "border-top:1px solid black;" : "border-top:5px double black;";
      var room =  this.stringFactory(data?.RoomCode || '');
      var doctor = this.stringFactory(data?.DoctorAccount || '');
      var roomCode = this.doctorType === 2 ? '<td class="excel-format-text" style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + room + doctor+'</td>' :'';
      let caseGroup = this.isCaseGroup ? '<td class="excel-format-text" style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + data?.Case +'</td>' :'';
      if (drID === '月計') drID = '';
      var times = this.numberFactory(data?.Count);
      // var feeReg = this.numberFactory(data?.ADx);
      var feeReg = this.numberFactory(data?.e11a1);
      // var feeDrugserivce = this.numberFactory(data?.AMed);
      var feeDrugserivce = this.numberFactory(data?.e1199);
      // var feeDrug = this.numberFactory(data?.ARx2+data?.ARx3+data?.ARx4);
      var feeDrug = this.numberFactory(data?.e11a2);
      //將其他的費用都加在此，否則總額對不上
      // var feeA9 = this.numberFactory(data?.ATx9);
      // let sumFee =
      //   (data?.ATx5 ?? 0) +
      //   (data?.ATx6 ?? 0) +
      //   (data?.ATx7 ?? 0) +
      //   (data?.ATx8 ?? 0) +
      //   (data?.ATx9 ?? 0) +
      //   (data?.ATx10 ?? 0) +
      //   (data?.ATx11 ?? 0) +
      //   (data?.AMat ?? 0) +
      //   (data?.ATx13 ?? 0) +
      //   (data?.ATx14 ?? 0) +
      //   (data?.ATx15 ?? 0) +
      //   (data?.ATx16 ?? 0) +
      //   (data?.ATx17 ?? 0) +
      //   (data?.ATx18 ?? 0) +
      //   (data?.Aother1 ?? 0) +
      //   (data?.Aother2 ?? 0);
      // var feeA9 = this.numberFactory(sumFee);
      var feeA9 = this.numberFactory(data?.e11a6);
      // var feeTotal = this.numberFactory(data?.ATot);
      var feeTotal = this.numberFactory(data?.e11tt);
      // var feedecl =  this.numberFactory(data?.ADcl);
      var feedecl =  this.numberFactory(data?.e11ta);
      var feeApart = this.numberFactory(data?.e1198);
      var feeAOwn = this.numberFactory(data?.AOwn);

      return  '<tr>' +
                  '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + drID + '</td>' +
                  roomCode+
                  caseGroup+
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + times + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeReg + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeDrugserivce + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeDrug + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeA9 + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTotal + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feedecl + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeApart + '</td>' +
                  '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeAOwn + '</td>' +

              '</tr>' ;
  }
  createHTMLPageHeader(iPage) {
      return `<div id="header" style="margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table style="width: 100%; text-align: center; border: none;">
                          <tr>
                              <td colspan="10" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              ${this.reportName}
                              </td>
                          </tr>
                          <tr>
                              <td colspan="3" style="width: 30%; font-size: 10pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              印表日期 ` + this.nowStr + `
                              </td>
                              <td colspan="4" style="font-size: 10pt; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              期間：` + this.stringBFactory(DateHelper.formatROCDate(this._sDate)) + ` 至 ` + this.stringBFactory(DateHelper.formatROCDate(this._eDate)) + `
                              </td>
                              <td colspan="3" style="width: 30%; font-size: 10pt; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              頁 ` + (iPage + 1) + `
                              </td>
                          </tr>
                          <tr>
                            <td colspan="10" style="font-size: 10pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            印表時間 ` + this.timeStr + `
                            </td>
                          </tr>

                      </table>
                  </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 10pt;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 1px;">
                  <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12.5pt; border: none;">
                  </table>
                  </div>
              </div>`;
  }
  createHTMLPageFirstSumHeader(iPage){
      return `<br />
              <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 1px;">
                      <table id="table-` + iPage + `-sum" style="width: 100%; font-size: 11.5pt; border: none;">
                      </table>
                  </div>
              </div>`;
  }
  createHTMLPageSumHeader(iPage) {
      return `<div id="header" style="margin: 0px 10px;">
                  <div style="display: flex;column-gap: 5px;">
                      <table style="width: 100%; border: none;">
                          <tr>
                              <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              ${this.reportName}
                              </td>
                          </tr>
                          <tr>
                              <td colspan="2"; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              日期 ` + this.nowStr + `
                              </td>
                              <td colspan="2"; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              時間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                              </td>
                              <td colspan="2" ; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                              頁 ` + (iPage + 1) + `
                              </td>
                          </tr>
                      </table>
                  </div>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                  <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 1px;">

                      <table id="table-` + iPage + `-sum" style="width: 100%; font-size: 11.5pt; border: none;">
                      </table>
                  </div>
              </div>`;
  }
  createHTMLPageNoHeader(iPage) {
      return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
              <label style="font-weight: bold; height: 23px;">　</label>
              </div>
              <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                  <div style="display: flex;column-gap: 1px;">
                      <table id="table-` + iPage + `-content" style="width: 100%; font-size: 11.5pt; border: none;">
                      </table>
                  </div>
              </div>`;
  }
  createHTMLFinish() {
      return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                  <label style="font-weight: bold;">以下空白</label>
              </div>`;
  }
  createNoDetailFinish() {
      return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                  <label style="font-weight: bold;">查無資料</label>
              </div>`;
  }
  createHTMLTotal() {
      return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                  <label style="font-weight: bold;"></label>
              </div>`;
  }
  getHtml() {
      var html = this.reportContainer.nativeElement.innerHTML;
      return html;
  }

  async onPrint() {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.shortReportName, printContents);
      if (ret.Successed) {
          this.notification.showSuccess(this.shortReportName + '已送出列印!', true);
      } else {
          this.notification.showError(this.shortReportName + '送出列印失敗!');
      }
      this.previewPrintDone.emit();
      this.printed.emit(true);
  }

  async exportAsExcel() {
      if (this.doctorReport.length > 0) {
          const table = this.reportContainer.nativeElement;
          //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
          const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

          await this.hcrService.ExportTableToExcel(this.shortReportName, html);
      } else {
          this.notification.showInfo('查無資料可供匯出!');
      }

      this.forExcel = false;

      this.printed.emit(true);
  }

  /** 轉換Date To String
   *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
   */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
      if (ev) {
          var d = new Date(ev);
          var month = '' + (d.getMonth() + 1);
          var day = '' + d.getDate();
          var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          if (isMonth) {
              return [year, month].join(symbol);
          } else {
              return [year, month, day].join(symbol);
          }
      }
  }

  /** 轉換Time To String
      *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
      if (ev) {
          var d = new Date(ev);
          // 趕時間先簡單寫
          let hr = d.getHours().toString();
          if (d.getHours() < 10) {
              hr = '0' + hr;
          }
          let min = d.getMinutes().toString();
          if (d.getMinutes() < 10) {
              min = '0' + min;
          }
          let sec = d.getSeconds().toString();
          if (d.getSeconds() < 10) {
              sec = '0' + sec;
          }
          var timeStr = [hr, min, sec].join(timeSymbol);
          return timeStr;
      }
  }

  onGetDate(ev) {
      if (ev) {
          var d = new Date(ev);
          var month = '' + (d.getMonth() + 1);
          var day = '' + d.getDate();

          if (month.length < 2)
              month = '0' + month;
          if (day.length < 2)
              day = '0' + day;

          return [month, day].join('');
      }
  }
}
