<app-vis-dialog title="搜尋病患" [show]="_isDialogOpened" (close)="onDialogClose('close')"
 [maxWidth]="'95vw'" [superWidth]="'95%'"
  i18n-title="@@DialogSearchPatient">
    <!-- 這邊要加ngif避免dialog未開啟的時候Oninit的行為被觸發-->
    <app-patients-list [mode]="'search'" *ngIf="_isDialogOpened" [emitResultWhenOnlyOne]="!demoIc"
    [isDialogOpened]="_isDialogOpened"
    (onEmitResult)="receivePatientChange($event)"
    (onEmitChose)="onDialogChose($event)"
    (onEmitQueryNotFound)="onQueryNotFound($event)">
    </app-patients-list>
  <app-vis-dialog-actions>
    <button type="button" (click)="onDialogClose('ok')" [disabled]="isDisableBtnSelect" i18n="@@Select">選擇</button>
    <button type="button" *ngIf="demoIc" (click)="onDialogClose('ic')" [disabled]="isDisableBtnSelect" i18n="@@Select">IC</button>
    <button type="button" (click)="onDialogClose('cancel')" i18n="@@Cancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
