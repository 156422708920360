import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

export declare type GrowDataStat = {
  Month:number,
  Value:number[];
}
export declare type GrowData = {
  Height:GrowDataStat[],
  Weight:GrowDataStat[],
  BMI:GrowDataStat[],
  Head:GrowDataStat[]
}
export declare type ChildGrow = {
  Header: string[];
  Boy:GrowData;
  Girl:GrowData;
}

export declare type ChildGrowDataType = 'Weight'|'Height'|'BMI'|'Head';
export declare type Sex = 'Boy'|'Girl';

export enum PIndex{
  P01 = 0, P1 = 1, P3=2, P5=3,P10=4,
  P15 = 5,P25 = 6,P50=7,P75=8,P85=9,
  P90=10,P95=11,P97=12,P99=13,P999=14
}

@Injectable({
  providedIn: 'root'
})
export class ChildGrowDataChartService{
    // 標準資料
    growData:ChildGrow = null;
    /**
     *
     */
    constructor(private http:HttpClient) {
      
    }
    public async load(){
      if(!this.growData){
        let jsonFile = 'assets/data/childGrow.json';
        this.growData = await this.http.get<ChildGrow>(jsonFile).toPromise();
      }
    }
    /**
     *
     */
     getData(sex:Sex, type:ChildGrowDataType){
      var data  =this.growData[sex][type] ;
      return data;
     }
     getHeaders(){
      return this.growData.Header;
     }
    
    validate(sex:Sex, type:ChildGrowDataType,month:number,value:number){
      var data  =this.growData[sex][type] ;
      var monthData = data.find((x,i)=> x.Month == month);
      var idx = monthData.Value.findIndex(v=>v>value);
      return idx as PIndex;
    }
  }