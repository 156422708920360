<div class="card border-top-0" style="overflow-y: hidden;" [ngStyle]="{height:registerResizeService.histAreaHeight}"
  *ngIf="!isCancelRegister">
  <div class="card-body p-0 overflow-x-hidden d-flex">
    <div>
      <button
        [ngStyle]="histAreaA==registerHistAreaState.idle?{background:'var(--third-primary)',fontWeight:'bolder'}:{background:'#e6ffff'}"
        class="d-flex three-part-label position-sticky top-0" (click)="handleHistAreaChange('A')">一般看診</button>
      <!-- <button
        [ngStyle]="histAreaB==registerHistAreaState.idle?{background:'#c2d6ff',fontWeight:'bolder'}:{background:'#e6e6ff'}"
        class="d-flex three-part-label position-sticky top-0" (click)="handleHistAreaChange('B')"
        style="z-index: 9;">同療看診</button> -->
    </div>

    <div class="three-part-content" style="background: linear-gradient(90deg, #e6ffff 0%, white 150%);"
      [@slide_in_out]="histAreaA">
      <div style="padding: 0 15px;">
        <div class="row">
          <div class="col-sm-7 pl-1">
            <div class="inside-scrollbar w-100 p-0 mt-1">
              <app-vis-grid *ngIf="histGrid" [data]="histGrid" [pageSize]="histGridPageSize" [skip]="histGridPageSkipNo"
                [pageable]="false" [setDefaultSeclected]="true" [showStickyHead]="true" [customStyle]="'198px'"
                [customClass]="'float-right '" [navigable]="true" [selectable]="{mode: 'single'}"
                [showMultiLineHead]="true" [kendoGridSelectBy]="'RegId'" (cellClick)="histGridCellClickHandler($event)"
                [selectedKeys]="selectedItems" style="width: calc(100% - 0.25rem);display: inline-grid;"
                class="no-border">

                <app-vis-grid-column field="RegDate" title="就診日" [width]="fontGreater===true?90:80"
                  i18n-title="@@RegDate" class="text-center custom-m-auto">
                  <ng-template kendoGridCellTemplate let-dataItem>
                    <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate |
                      formatDate:'' }}</div>
                  </ng-template>
                </app-vis-grid-column>

                <app-vis-grid-column field="ICode" title="身分" [width]="40" i18n-title="@@ICode"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="SameTreat" title="類" [width]="20" i18n-title="@@SameTreat"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="Isqno" title="卡序" [width]="50" i18n-title="@@Isqno"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="Case" title="案類" [width]="50" i18n-title="@@Case"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="Dx1Name" title="症候" [width]="170"
                  i18n-title="@@DxName"></app-vis-grid-column>
                <app-vis-grid-column field="Days" title="日" [width]="40" i18n-title="@@DrugDays"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="Deposit" title="押金" [width]="50" i18n-title="@@Deposit"
                  class="text-right"></app-vis-grid-column>
                <app-vis-grid-column field="Dept" title="科別" [width]="45" i18n-title="@@Dept"
                  class="text-center custom-m-auto"></app-vis-grid-column>
                <app-vis-grid-column field="DoctorName" title="醫師" [width]="70" i18n-title="@@DoctorName"
                  class="text-center custom-m-auto"></app-vis-grid-column>
              </app-vis-grid>

              <div class="d-block text-center bg-light-gray px-5" style="width: calc(100% - 4px);padding: 2.7rem 0;"
                *ngIf="!histGrid || histGrid.total==0" #nodata>
                <p>沒有本院病歷</p>
              </div>

            </div>

          </div>
          <div class="col-sm-3">
            <label class="mb-0 font-weight-bold d-block">醫令
              <button type="button"
                class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                style="min-height: 20px;">
                <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="handleCCToggle()">
                  <ng-container *ngIf="maxCCOpen">call_to_action</ng-container>
                  <ng-container *ngIf="!maxCCOpen">web_asset</ng-container>
                </mat-icon>
              </button>
            </label>

            <div style="height: calc(100% - 22px);min-height:181px;max-height: 181px;overflow: hidden;"
              class="d-block w-100">
              <div class="rcd-textarea w-100 overflow-y-auto" style="resize: none;z-index: 1000;"
                [ngClass]="maxCCOpen?'resize-open cc-rm':''"
                [ngStyle]="{height:maxCCOpen?registerResizeService.ccRmHeight:'',overflowY:maxCCOpen?'auto':''}">
                <app-shared-hist-hist-view #histViewComp [IsShowToolBar]="false"></app-shared-hist-hist-view>
              </div>
            </div>
          </div>
          <div class="col-sm-2">
            <label class="mb-0 font-weight-bold d-block"><span class="ml-1">特別記載</span>
              <mat-icon class="w-4 h-auto mr-1 top-2 pref-icon vis-combine-icon text-xlg" type="button"
                style="font-size: 133%;" (click)="onBtnRemarkClick()" title="特別記載選項">settings</mat-icon>
              <button type="button"
                class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none"
                style="min-height: 20px;">
                <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="handleRmToggle()">
                  <ng-container *ngIf="maxRmOpen">call_to_action</ng-container>
                  <ng-container *ngIf="!maxRmOpen">web_asset</ng-container>
                </mat-icon>
              </button>
            </label>
            <div style="height: calc(100% - 22px);min-height:181px;max-height: 181px;" class="d-block w-100">
              <textarea class="rcd-textarea w-100" style="resize: none;z-index: 1000;"
                [ngClass]="maxRmOpen?'resize-open cc-rm':''"
                [ngStyle]="{height:maxRmOpen?registerResizeService.ccRmHeight:''}"
                [value]="spRemark"
                (change)="onSpRemarkChange($event.target.value)">
              </textarea>
            </div>
          </div>
        </div>

        <div class="w-100 d-block mt-1 position-relative" style="height: 1px;" [hidden]="maxRegMenuOpen">
          <div class="d-inline-block position-absolute end-0" style="width:41.333%;">
            <app-periodic-grid [data]="periodItems" [pageSize]="6"></app-periodic-grid>
          </div>
        </div>

        <app-register-hist-info *ngIf="patientId" [patientId]="patientId" [(maxRegMenuOpen)]="maxRegMenuOpen"></app-register-hist-info>

      </div>
    </div>
  </div>
</div>
