import { Injectable, OnDestroy } from '@angular/core';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEvent } from '../models/helper-event';
import { HistUiEventService } from './hist-ui-event.service';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { HelperId } from '../models/helper-id';

/** 輔助輸入面板 */
@Injectable()
export class HistHelperService extends HistUiEventService implements OnDestroy {
  constructor() {
    super();
  }

  //#region ========= Textarea =========
  private isActiveChar(previousChar: string) {
    const activeChars = ' `-=[]\\;,./~!@#$%^&*()_+{}:"<>?，。\n\r';
    if (activeChars.indexOf(previousChar) >= 0) {
      // console.log('activeChars-true');
      return true;
    } else {
      // console.log('activeChars-false');
      return false;
    }
  }

  // 現有的inputValue移除最後一個字元
  private removeOneChar() {
    // console.log('removeOneChar');
    if (!this.lastEvent || !this.lastEvent.inputValue) {
      return;
    }
    const lastValue = this.lastEvent.inputValue;
    // 原inputValue長度大於1時，才可backspace => 刪除一個字元
    if (lastValue.length > 0) {
      const newValue = lastValue.substr(0, lastValue.length - 1);
      const newEvent = Object.assign(new HelperEvent(), this.lastEvent);
      newEvent.inputValue = newValue;
      this.sendEvent(newEvent);
    }
  }
  // 現有的inputValue增加字元
  private addWord(sourceId: string, key: string) {
    const source: HelperSourceEnum = HelperId.getSourceById(sourceId) ;
    // console.log('addWord-----------');
    let lastValue = '';
    if (this.lastEvent && this.lastEvent.source === source && this.lastEvent.sourceId === sourceId &&
      this.lastEvent.type === HelperEventTypeEnum.Value && this.lastEvent.inputValue) {
      lastValue = this.lastEvent.inputValue;
    }

    const newEvent = HelperEvent.ValueEvent(sourceId, lastValue + key);
    this.sendEvent(newEvent);
  }
  /*
  ======== (1) keydown for textarea ========
  只處理這些輸入：ArrowUp, ArrowDown, ArrowLeft, ArrowRight, Enter, Escape, Space, Backspace
  再分為helper視窗開啟或關閉的狀態
  (1) helper開啟
      ArrowUp, ArrowDown, Enter => 傳送到helper處理，並preventDefault
      ArrowLeft, ArrowRight, Escape, Space => 清除inputValue，關閉helper
      Backspace => 變更目前inputValue內容
  (2) helper關閉
      Backspace => 變更目前inputValue內容
  */
  keydownForTextarea(event: KeyboardEvent) {
    // console.log('keydownForTextarea - key:' + event.key);
    // 因為每次blur都會reset，不用檢查source

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter') {
      if (this.isWindowOpened()) {
        // 此事件要傳遞給helper處理，原物件不處理，所以preventDefault
        event.preventDefault();
        // 傳遞給helper處理
        this.sendKeyboard(event);
      } else {
        this.reset();
      }
    } else if (event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Escape' || event.key === ' ') {
      this.reset();
    } else if (event.key === 'Backspace') {
      // 前面已檢查是來自同個來源，此處不再檢查
      this.removeOneChar();
    }
  }

  /*
  ======== (2) keypress ========
  備註：Backspace不會出現在keypress，各特殊按鍵已先在keydown處理，keypress就不用處理了
  keypress只處理變更目前inputValue內容
  */
  // inputKey: 此次keyup的值
  // sourceId: helper視窗要出現在哪個element的右邊
  // keypress是給textarea用的
  keypressForTextarea(sourceId: string, inputValue: string, previousChar: string) {
    // console.log('keypress - inputValue:' + inputValue);
    const source = HelperId.getSourceById(sourceId);

    if (!inputValue || !this.isSameSource(source)) {
      // console.log('keypress-reset');
      this.reset();
      return;
    }
    if (inputValue === ' ' || inputValue === 'Enter') {
      this.reset();
      return;
    }
    // 原本非空值(新輸入)，且前一個字元非啟動字元則不動作
    if (!this.lastEvent?.inputValue && !this.isActiveChar(previousChar)) {
      return;
    }
    // 增加字元到現有inputValue
    this.addWord(sourceId, inputValue);
  }
  //#endregion

  //#region ========= input =========
  // input可以直接把整個欄位值作為inputValue，不像textarea需紀錄前後的輸入字元
  keydownForInput(event: KeyboardEvent) {
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown' || event.key === 'Enter' 
      || event.key=='PageDown' || event.key=='PageUp') {
      if (this.isWindowOpened()) {
        // 此事件要傳遞給helper處理，原物件不處理，所以preventDefault
        event.preventDefault();
        // 傳遞給helper處理
        this.sendKeyboard(event);
      }
    }
  }
  // Order必須指定來源，因為有分TAB，但UI的Id相同
  keyupForInput(sourceId: string, inputValue: string, event: KeyboardEvent, rowIndex: number, rxTypes?: number[]) {
    // console.log('keyup - inputValue:' + inputValue + ' event.key:' + event.key);

    const source = HelperId.getSourceById(sourceId);
    if (!inputValue || !this.isSameSource(source)) {
      this.reset();
      return;
    }

    // 用event.key.length來判斷是輸入了一個字元，還是輸入了其他功能按鍵
    // 不影響到欄位內容的話，就直接return結束
    if (event.key.length > 1 && event.key !== 'Backspace') {
      return;
    }

    if (!inputValue || inputValue.trim() === '') {
      this.reset();
      return;
    }
    const newEvent = HelperEvent.ValueEvent(sourceId, inputValue, rowIndex, rxTypes);
    this.sendEvent(newEvent);
  }
  //#endregion --------------------

  //#region ========= dropdown =========
  keydownForDropdown(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'ArrowUp' || key === 'ArrowDown' || key === 'Enter') {
      if (this.isWindowOpened()) {
        // 此事件要傳遞給helper處理，原物件不處理，所以preventDefault
        event.preventDefault();
        // 傳遞給helper處理
        const evt = HelperEvent.KeyboardEvent(key);
        this.sendEvent(evt);
      }
    }
  }

  clickForDropdown(sourceId: string, rowIndex: number, subSourceType: string = '') {
    const evt = HelperEvent.ClickEvent(sourceId, rowIndex);
    evt.subSourceType = subSourceType;
    this.sendEvent(evt);
  }
  //#endregion

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
