
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Moment } from 'moment';
import { BaseConfig } from 'src/app/services/base-config.service';
export const VIS_DatePicker_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisDatepickerComponent),
  multi: true
};
@Component({
  selector: 'app-vis-datepicker',
  templateUrl: './vis-datepicker.component.html',
  styleUrls: ['./vis-datepicker.component.css'],
  providers: [VIS_DatePicker_CONTROL_VALUE_ACCESSOR]
})
export class VisDatepickerComponent implements OnInit, ControlValueAccessor  {
  //#region UI Interface
  prefix:'民'|'民前'|'' = '民';

  @Input()
  format: string;
  /** 是否顯示日曆按鈕 */
  @Input()
  showPicker:boolean = true;
  /** 是否顯示T按鈕 */
  @Input()
  showToday:boolean = false;
  /** 可選最大日期 */
  @Input()
  max:Date = null;
  /** 可選最小日期 */
  @Input()
  min:Date = new Date(1900,0,1);
  /** 日期 */
  @Input()
  value:Date;

  @Input()
  openPickerOnClick:boolean = false;
  //#endregion
  /** 自定義css */
  @Input()
  panelClass?:string;
  /** 初始年月日視窗 */
  @Input()
  startView?:string = 'month';
  /** 最大寬開關 */
  @Input()
  maxWidth?:string = '12rem';

  @Input()
  showLastDateButton: boolean = false;

  @Input()
  showNextDateButton: boolean = false;

  @Input() disabled: boolean = false;
  // set disabled(v:boolean){
  //   console.log( ' set disabled:',v);
  //   if(v!=null){
  //     this._disabled = v;
  //   }
  // }
  // get disabled(): boolean{
  //   return this._disabled;
  // }


  @ViewChild('year')
  yearInput: ElementRef<HTMLInputElement>;
  @ViewChild('month')
  monthInput:ElementRef<HTMLInputElement>;
  @ViewChild('day')
  dayInput: ElementRef<HTMLInputElement>;
  @ViewChild('picker')
  matDatePicker:MatDatepicker<any>;

  @Input() customClass?:string='';

  /** 值改變(使用者輸入或者選取) */
  @Output()
  valueChange = new EventEmitter<Date>();
  @Output()
  blurChange = new EventEmitter<Date>();

  /** T按鈕按下去 */
  @Output()
  today = new EventEmitter();

  @Output()
  lastDate = new EventEmitter<Date>();

  @Output()
  nextDate = new EventEmitter<Date>();

  _currentValue: Date;


  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean;
  writeValue(obj: any): void {
    var prevValue = this._currentValue;
    if(obj){
      this._currentValue = new Date(obj);
      setTimeout(() => {
        this.setYear(this._currentValue.getFullYear());
        this.monthInput.nativeElement.value = (this._currentValue.getMonth()+1).toString();
          if ( this.format !== 'MM yyyy') {
            this.dayInput.nativeElement.value = this._currentValue.getDate().toString();
          }
        this.updateValue(false);
      }, 0);

    }else{
      this._currentValue = null;
      setTimeout(() => {
        if(BaseConfig.getConfig().ui.rocDate){
          this.prefix = '民'
        }
        this.yearInput.nativeElement.value = '';
        this.monthInput.nativeElement.value = '';
        this.dayInput.nativeElement.value = '';
      }, 0);
      new Date('',)
    }
    // 初次不產生change, 值一樣不產生change Chrome有夏令時區問題 待解
    //if(prevValue && this.fixTimeZone(prevValue)!=this.fixTimeZone(this._currentValue)){
      // this.emitChange();
    //}

  }
  private fixTimeZone(time:Date){
    time.setMinutes(time.getMinutes()+time.getTimezoneOffset()+480)
    return time;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // debugger
    this._disabled = isDisabled;
  }

  control:FormControl;

  constructor(private el: ElementRef) {
    el.nativeElement.focus = (opt)=>{
      this.yearInput.nativeElement.focus(opt);
    }
  }

  ngOnInit(): void {
    this._disabled = this.disabled;
    if(this._currentValue){
      this.setYear(this._currentValue.getFullYear());
      this.monthInput.nativeElement.value = (this._currentValue.getMonth()+1).toString();
      this.dayInput.nativeElement.value = this._currentValue.getDate().toString();
    }
  }


  onDateChanged(evt: MatDatepickerInputEvent<Date>){
    var dt = evt.value;
    if (dt != null) {
      if((evt.value as any).toDate){
        dt = (evt.value as any as Moment).toDate();
      }
      if(dt !==this._currentValue){
        if(dt){
          this.setYear(dt.getFullYear());
          this.monthInput.nativeElement.value = (dt.getMonth()+1).toString();
          if ( this.format !== 'MM yyyy') {
            this.dayInput.nativeElement.value = dt.getDate().toString();
          }
        }
        this.updateValue(false);
        this.emitChange();
        this.blurChange.emit(this._currentValue);
        this.matDatePicker.close();
      }
    }
  }

  numberDown(evt: KeyboardEvent){
    var allow = ['ArrowRight','ArrowLeft','ArrowUp','ArrowDown','Backspace', 'Tab' ,'Delete', 'Enter']
    if(allow.some(k=>k==evt.key))
    {
      return true;
    }
    if(evt.key<"0" || evt.key>"9")
    {
      return false;
    }
    return true;
  }
  yearDown(evt: KeyboardEvent ){
    evt.stopPropagation();
    if(!this.numberDown(evt)){
      return false;
    }
    var input = this.yearInput.nativeElement;
    // 在最後一位數按右鍵 -> 跳月份
    if(evt.key == 'ArrowRight' || evt.key == 'Enter'){
      if(input.selectionEnd == input.value.length){
        this.focusTo(this.monthInput.nativeElement);
        return false;
      }
    } else if(evt.key == 'ArrowLeft'){
      //左鍵 不做事
    } else if(evt.key == 'ArrowUp') {
      if(BaseConfig.getConfig().ui.rocDate){
        //民前轉正
        if(parseInt(input.value) ==1 && this.prefix == '民前'){
          input.value= '0';
          this.prefix = '民';
        }
        // 民前 ↑ 數字下降
        else if(this.prefix == '民前'){
          input.value= Math.min((this.max?.getFullYear()??9999)-1911,parseInt(input.value) - 1).toString()
          this.prefix = '民前'
        }else{
          if(!input.value){
            input.value = ((this.max?.getFullYear()??new Date().getFullYear())-1911).toString();
          }else{
            input.value = Math.min((this.max?.getFullYear()??9999)-1911,parseInt(input.value) +1).toString()
          }
        }
      }else{
        // 上 選最大值獲今年
        if(!input.value){
          input.value = ((this.max?.getFullYear()??new Date().getFullYear()-1911)).toString();
        }else{
          input.value = Math.min(this.max?.getFullYear()??9999,parseInt(input.value) +1).toString()
        }
      }

      this.updateValue(true);
      this.emitChange();
      return false;
    } else if(evt.key == 'ArrowDown'){
      if(BaseConfig.getConfig().ui.rocDate){
        //民前轉正
        if(parseInt(input.value) ==0 && this.prefix == '民'){
          input.value= '1'
          this.prefix = '民前'
        }
        // 民前 ↓ 數字上升
        else if(this.prefix == '民前'){
          var y = Math.max((this.min?.getFullYear()??1812)-1911,-1*(parseInt(input.value) + 1))*-1;
          input.value= y.toString()
          this.prefix = '民前'
        }else{
          var y = 0;
          // 下 選最小值或今年
          if(!input.value){
            y = (this.min?.getFullYear()??new Date().getFullYear())-1911;
          }else{
            y = Math.max((this.min?.getFullYear()??1812)-1911,parseInt(input.value) - 1);
          }
          if(y<0){
            y*=-1;
            this.prefix = '民前'
          }
          input.value = y.toString();
        }
      }else{
        // 下 選最小值或今年
        if(!input.value){
          input.value = ((this.min?.getFullYear()??new Date().getFullYear())-1911).toString();
        }else{
          input.value = Math.max(this.min?.getFullYear()??1900,parseInt(input.value) - 1).toString()
        }
      }
      this.updateValue(true);
      this.emitChange();
      return false;
    }
  }
  yearUp(evt: KeyboardEvent){
    evt.stopPropagation();
    var skip = ['ArrowRight','ArrowLeft', 'ArrowUp', 'ArrowDown','Tab'];
    if(skip.some(k=>evt.key==k)){
      return;
    }
    if(this.yearInput.nativeElement.value?.length>= (BaseConfig.getConfig().ui.rocDate?3:4)){
      this.focusTo(this.monthInput.nativeElement);
    }
    this.trySaveValue();
    this.emitChange();
    //this.updateValue(true);
  }
  focusTo(inputEl:HTMLInputElement){
    inputEl.focus()
    inputEl.setSelectionRange(0,inputEl.value.length,'none');
  }
  monthDown(evt: KeyboardEvent){
    evt.stopPropagation();
    if(!this.numberDown(evt)){
      return false;
    }
    var input = this.monthInput.nativeElement;
    // 在最後一位數按右鍵 -> 跳日
    if(evt.key == 'ArrowRight' || evt.key == 'Enter' ){
      if(input.selectionEnd == input.value.length){
        this.focusTo(this.dayInput.nativeElement);
        return false;
      }
    } else if(evt.key == 'ArrowLeft'){
      //左鍵 在最左回年分
      if(input.selectionEnd == 0){
        this.focusTo(this.yearInput.nativeElement);
        return false;
      }
    }  else if(evt.key == 'ArrowUp') {
      // 上 選最大值獲今年
      if(!input.value){
        input.value = '1';
      }else{
        var next =(parseInt(input.value) +1);
        if(!this._currentValue){
          next = next>12?12:next;
        }
        input.value = next.toString()
      }
      this.fixDayOnMonthChanged();
      this.updateValue(true);
      this.emitChange();
      return false;
    } else if(evt.key == 'ArrowDown'){
      // 下 選最小值或今年
      if(!input.value){
        input.value = '1';
      }else{
        var nextMonth = parseInt(input.value) -1;
        if(!this._currentValue){
        nextMonth= nextMonth<=0?1:nextMonth;
        }
        input.value = nextMonth.toString()
      }
      this.fixDayOnMonthChanged();
      this.updateValue(true);
      this.emitChange();
      return false;
    }else if(evt.key == 'Backspace'){
      //左鍵 在最左回年分
      if(input.selectionEnd == 0){
        this.focusTo(this.yearInput.nativeElement);
        return false;
      }
    }
  }
  monthUp(evt: KeyboardEvent ){
    evt.stopPropagation();
    var skip = ['ArrowRight','ArrowLeft','ArrowUp','ArrowDown', 'Tab' ];
    if(skip.some(k=>evt.key==k)){
      return;
    }
    // 手輸入卡上限 12
    var m = parseInt(this.monthInput.nativeElement.value);
    if(m>12){
      this.monthInput.nativeElement.value = '12';
    }else if(m<0){
      this.monthInput.nativeElement.value = '1';
    }
    // 手輸入兩位後跳右
    if(this.monthInput.nativeElement.value?.length>=2 ){
      this.focusTo(this.dayInput.nativeElement);
    }
    this.trySaveValue();
    this.emitChange();
    //this.updateValue(true);
  }
  dayDown(evt: KeyboardEvent){
    evt.stopPropagation();
    if(!this.numberDown(evt)){
      return false;
    }
    var input = this.dayInput.nativeElement;

    if(evt.key == 'ArrowRight' ){
      // 右鍵 不管
    } else if(evt.key == 'ArrowLeft'){
      //左鍵 在最左回月分
      if(input.selectionEnd == 0){
        this.focusTo(this.monthInput.nativeElement);
        return false;
      }
    } else if(evt.key == 'ArrowUp') {
      // 上 選最大值獲今年
      if(!input.value){
        input.value = '1';
      }else{
        var next =(parseInt(input.value) +1);
        if(!this._currentValue){
          next = next>31?31:next;
        }
        input.value = next.toString()
      }
      this.updateValue(true);
      this.emitChange();
      return false;
    } else if(evt.key == 'ArrowDown'){
      // 下 選最小值或今年
      if(!input.value){
        input.value = '1';
      }else{
        var next = parseInt(input.value) -1;
        if(!this._currentValue){
          next=next<=0?1:next;
        }
        input.value = next.toString()
      }
      this.updateValue(true);
      this.emitChange();
      return false;
    }else if(evt.key == 'Backspace'){
      //左鍵 在最左回年分
      if(input.selectionEnd == 0){
        this.focusTo(this.monthInput.nativeElement);
        return false;
      }
    }

  }

  dayUp(evt: KeyboardEvent ){
    evt.stopPropagation();
    var skip = ['ArrowRight','ArrowLeft','ArrowUp','ArrowDown', 'Tab' ];
    if(skip.some(k=>evt.key==k)){
      return;
    }
    var d = parseInt(this.dayInput.nativeElement.value);
    // 手輸入卡上限 當月最大日
    if(this._currentValue){
      var dInM = this.daysInMonth(this._currentValue.getFullYear(), this._currentValue.getMonth()+1)
      if(d>dInM){
        this.dayInput.nativeElement.value = dInM.toString();
      }
    }else{
      if(d>31){
        this.dayInput.nativeElement.value = '31';
      }else if(d<0){
        this.dayInput.nativeElement.value = '1';
      }
    }
    this.trySaveValue();
    this.emitChange();
    //this.updateValue(true);
  }
  openPicker(){
    console.log( '_disabled:',this._disabled,'   ,disabled:' ,this.disabled);

    //this.matDatePicker.xPosition = (evt.target as HTMLInputElement).x
    this.matDatePicker.select(this._currentValue);
    this.matDatePicker.open();
  }
  daysInMonth (year,month ) {
    return new Date(year, month, 0).getDate();
  }
  trySaveValue(){
    try{
      var year = this.toAcYear(parseInt(this.yearInput.nativeElement.value),this.prefix)
      var month = parseInt(this.monthInput.nativeElement.value);
      var day = parseInt(this.dayInput.nativeElement.value);
      if(isNaN(year+month+day)){
        this._currentValue = null;
      }else{
        this._currentValue = new Date(year,month-1,day)
      }
      if((this._currentValue as any) == 'Invalid Date'){
        this._currentValue = null;
      }
    }catch(ex){
      this._currentValue = null;
    }
  }
  emitChange(){
    if(this._onChange){
      this._onChange(this._currentValue);
      this.valueChange.emit(this._currentValue);
    }
    if(this._onTouched){
      this._onTouched();
    }
  }

  valueCheck(){
    var year = this.toAcYear(parseInt(this.yearInput.nativeElement.value),this.prefix)
    var month = parseInt(this.monthInput.nativeElement.value);
    var day = parseInt(this.dayInput.nativeElement.value);
    if( isNaN(year+month+day)){
      return false;
    }
    if(year<1900){
      year = 1900;
      month = 1;
      day = 1;
    }
    var date = new Date(year,month-1,day);
    if(this.max && date>this.max){
      date = this.max
    }
    if(this.min && date<this.min){
      date = this.min
    }

    this.setYear(date.getFullYear());
    this.monthInput.nativeElement.value = (date.getMonth()+1).toString();
    this.dayInput.nativeElement.value = (date.getDate()).toString();
    return true;
  }
  setYear(acYear){
    if(BaseConfig.getConfig().ui.rocDate){
      var rocYear =acYear-1911;
      if(rocYear<0){
        rocYear*=-1;
        this.prefix = '民前';
      }else{
        this.prefix = '民';
      }
      this.yearInput.nativeElement.value = rocYear.toString();
    }else{
      this.yearInput.nativeElement.value = acYear.toString();
    }
  }
  toAcYear(year:number,prefix: '民'|'民前'|''){
    if(BaseConfig.getConfig().ui.rocDate){
      var acYear = year *(prefix=='民前'?-1:1) + 1911;
      return acYear;
    }else{
      return year;
    }
  }
  updateValue(fromInput:boolean){
    if(fromInput){
      this.valueCheck();
    }
    this.trySaveValue();
  }
  focused = [0,0,0]
  onfocus(evt){
    var inputEl = evt.target as HTMLInputElement;
    inputEl?.setSelectionRange(0,inputEl.value.length,'none');

    if(evt.target==this.yearInput.nativeElement) this.focused[0]=1;
    if(evt.target==this.monthInput.nativeElement) this.focused[1]=1;
    if(evt.target==this.dayInput.nativeElement) this.focused[2]=1;
  }

  onblur(evt){
    setTimeout(() => {
      if(evt.target==this.yearInput.nativeElement){
        this.focused[0]=0;
      }
      if(evt.target==this.monthInput.nativeElement) this.focused[1]=0;
      if(evt.target==this.dayInput.nativeElement) this.focused[2]=0;
      var sum = 0;
      this.focused.forEach(f=>sum+=f);
      if(sum == 0){
        if(!this.valueCheck()){
          this.yearInput.nativeElement.value = '';
          this.monthInput.nativeElement.value = '';
          this.dayInput.nativeElement.value = '';
        }
          this.updateValue(false);

          this.blurChange.emit(this._currentValue);
      }else{
        // month離開導致日期超出要校正
        if(evt.target==this.monthInput.nativeElement){
          this.fixDayOnMonthChanged();
        }
        this.updateValue(true)
      }
      // this.emitChange();
      this.valueChange.emit(this._currentValue);
    }, 0);
  }

  fixDayOnMonthChanged(){
    var year = this.toAcYear(parseInt(this.yearInput.nativeElement.value),this.prefix)
    var month = parseInt(this.monthInput.nativeElement.value);
    var day = parseInt(this.dayInput.nativeElement.value);
    var dayInMonth = this.daysInMonth(year,month);
    if(day> dayInMonth){
      this.dayInput.nativeElement.value = dayInMonth.toString();
    }
  }

  onBtnTodayClick(){
    this.today.emit();
  }

  goLastDay() {
    this._currentValue.setDate(this._currentValue.getDate() - 1);
    this.ngOnInit();
    this.lastDate.emit(this._currentValue);
  }

  goNextDay() {
    this._currentValue.setDate(this._currentValue.getDate() + 1);
    this.ngOnInit();
    this.nextDate.emit(this._currentValue);
  }
}
