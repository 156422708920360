import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { H10, H10Helper } from 'src/app/models/H10';
import { H11, H11Helper } from 'src/app/models/H11';
import { H12Helper } from 'src/app/models/H12';
import { OPDxmlDataDTO } from 'src/app/services/api-service/declare/declare-api';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';

@Component({
  selector: 'app-sampling-order-list',
  templateUrl: './sampling-order-list.component.html',
  styleUrls: ['./sampling-order-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SamplingOrderListComponent implements OnInit {

  @ViewChild('reportContainer')
  reportContainer:ElementRef<HTMLDivElement>;
  constructor(private cdr:ChangeDetectorRef) {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@page { size: A4 landscape; margin:0.3cm 0.3cm; }\n'+
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
      '.pres-report table{ border:1px solid black; border-collapse: collapse;font-size:12pt; }\n'+
      '.pres-report table td, table th { border: 1px solid black; padding-left: 2px;padding-right: 2px;}\n'+
      '.pres-report table tr:first-child th { border-top: 0; }\n'+
      '.pres-report table tr:last-child td { border-bottom: 0; }\n'+
      '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n'+
      '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
    ));
    setTimeout(() => {
      if(this.reportContainer){
      this.reportContainer.nativeElement.appendChild(st);
      this.cdr.markForCheck();
      }
    }, 0);
  }
  print(){
    var st = document.createElement('div');
    st.innerHTML = this.reportContainer.nativeElement.innerHTML;
    document.body.appendChild(st);
    st.style.display = 'none'
//WinPrint.close();
    // var originalContent = document.body.innerHTML;
    // document.body.innerHTML = this.reportContainer.nativeElement.innerHTML;
    window.print()
    document.body.removeChild(st);
    // document.body.innerHTML = originalContent;

  }
  ngOnInit(): void {
    this.clinic = UserCache.getLoginUser().Clinic;
  }
  clinic:Clinic;
  _h10:H10;
  @Input()
  set h10(v:H10){
     this._h10 = v;
     if(this._h10 && this._h11 && this.clinic){
      this.cdr.markForCheck();
     }
  }
  _h11:H11;
  @Input()
  set h11(v:H11){
    this._h11 = v;
    if(this._h11 && this._h10 && this.clinic){
      this.cdr.markForCheck();
    }
  }
  /** (過渡期)參數設定ic2.0上傳模式 => 決定是否顯示d60、d61、d62及p26相關就醫識別碼欄位 */
  @Input()
  icUpMode: number = 1;

  get H10(){ return this._h10?new H10Helper(this._h10):null;}
  get H11(){ return this._h11?new H11Helper(this._h11):null;}
  get H12List(){ return this._h11.H12List?.map(x=>{ return new H12Helper(x);})};
  async setData(h10:H10,h11:H11){

    this._h10 = h10;
    this._h11 = h11;
    this.cdr.markForCheck();
    setTimeout(() => {
      var hasSt = false;
      for(let i = 0; i< this.reportContainer.nativeElement.children.length; i++){
        var el = this.reportContainer.nativeElement.children[i];
        if(el.tagName.toLowerCase() == 'style') {
          hasSt=  true;
          break;
        }
      }
      if(!hasSt){
        var st = document.createElement('style');
        st.appendChild(document.createTextNode(
          '@page { size: A4 landscape; margin:0.3cm 0.3cm; }\n'+
          '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
          '.pres-report table{ border:1px solid black; border-collapse: collapse;font-size:12pt; }\n'+
          '.pres-report table td, table th { border: 1px solid black; padding-left: 2px;padding-right: 2px;}\n'+
          '.pres-report table tr:first-child th { border-top: 0; }\n'+
          '.pres-report table tr:last-child td { border-bottom: 0; }\n'+
          '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n'+
          '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
        ));
          this.reportContainer.nativeElement.appendChild(st);

      }
      this.cdr.markForCheck();
    }, 0);

    //this.regist =  await this.api.Get(regId);
    //var param = await this.parameterApi.GetPRN001();
    //this.cidMask = param.IDHidePRN??this.cidMask;
    //this.bitrhMask = param.BirthdayHidePRN??this.bitrhMask;
  }
  getHtml():string{
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }
  toBox(a,b){
    return a==b?'■':'□'
  }
  toBoxs(a:string,...values:string[]){
    return values.map(x=>{
      return `${this.toBox(a,x)}${x}`
    }).join(' ');
  }

  dispTypeBox(value){
    var a = [
      {text:'自行調劑',value:'0'},
      {text:'交付調劑',value:'1'},
      {text:'藥品自行調劑，物理(或職能)治療自行執行',value:'A'},
      {text:'藥品交付調劑，物理(或職能)治療自行執行',value:'C'},
      {text:'未開處方調劑，物理(或職能)治療自行執行',value:'E'},
      {text:'未開處方',value:'2'},
      {text:'緊急傷病自行調劑',value:'6'},
      {text:'藥品自行調劑，物理(或職能)治療交付執行',value:'B'},
      {text:'藥品交付調劑，物理(或職能)治療交付執行',value:'D'},
      {text:'未開處方調劑，物理(或職能)治療交付執行',value:'F'},
    ];
    return a.map(x=>{
      return `${this.toBox(value,x.value)}${x.text}`
    }).join(' ');
  }
}
