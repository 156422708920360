<ng-container>
  <div class="edit-control-block" [hidden]="!isReadyToShow" style="min-height:34px">
    <ng-content select="[begin]"></ng-content>
    <span [hidden]="!btnClear.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnClearClick()" [disabled]="!btnClear.isEnable" i18n="@@Clear">清除</button> -->
      <button type="button" (click)="onBtnClearClick()" [disabled]="!btnClear.isEnable" i18n="@@Clear"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="清除">
        <mat-icon class="pref-icon remove vis-combine-icon">remove_circle</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">清除</span>
      </button>
    </span>
    <span [hidden]="!btnEdit.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnEditClick()" [disabled]="!btnEdit.isEnable" i18n="@@Edit">編輯</button> -->
      <button type="button" *appAllowPermissionIf="[UserPermissions.Modify]" (click)="onBtnEditClick()"
        [disabled]="!btnEdit.isEnable" i18n="@@Edit" class="vis-btn-config p-0 position-relative pl-2 w-18" title="編輯">
        <mat-icon class="pref-icon add vis-combine-icon">edit</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">編輯</span>
      </button>
    </span>
    <span [hidden]="!btnAdd.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnAddClick()" [disabled]="!btnAdd.isEnable" i18n="@@Add">新增</button> -->
      <button type="button" (click)="onBtnAddClick()" [disabled]="!btnAdd.isEnable"
      *appAllowPermissionIf="[UserPermissions.Add]" i18n="@@Add" class="vis-btn-config p-0 position-relative pl-2 w-18"
        title="新增">
        <mat-icon class="pref-icon add vis-combine-icon">add_circle</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">新增</span>
      </button>
    </span>
    <span [hidden]="!btnDelete.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnDeleteClick()" [disabled]="!btnDelete.isEnable" i18n="@@Delete">刪除</button> -->
      <button type="button" (click)="onBtnDeleteClick()" [disabled]="!btnDelete.isEnable"
      *appAllowPermissionIf="[UserPermissions.Delete]" i18n="@@Delete"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="刪除">
        <mat-icon *ngIf="deleteIcon != ''" class="pref-icon remove vis-combine-icon"><ng-container>{{deleteIcon}}</ng-container></mat-icon>
        <mat-icon *ngIf="deleteIcon == ''" class="pref-icon remove vis-combine-icon">delete</mat-icon>
        <span *ngIf="deleteWords != ''" class="position-absolute text-xs-sm start-0 ml-4 pl-2"><ng-container>{{deleteWords}}</ng-container></span>
        <span  *ngIf="deleteWords == ''" class="position-absolute text-xs-sm start-0 ml-4 pl-2"><ng-container>刪除</ng-container></span>
      </button>
    </span>
    <span [hidden]="!btnCancel.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnCancelClick()" [disabled]="!btnCancel.isEnable" i18n="@@Cancel">取消</button> -->
      <button type="button" (click)="onBtnCancelClick()" [disabled]="!btnCancel.isEnable" i18n="@@Cancel"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="取消">
        <mat-icon class="pref-icon remove vis-combine-icon">clear</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
      </button>
    </span>
    <span [hidden]="!btnSave.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnSaveClick()"  [disabled]="!btnSave.isEnable" i18n="@@Save">儲存</button> -->
      <button type="button" (click)="onBtnSaveClick()" [disabled]="!btnSave.isEnable"
      *appAllowPermissionIf="[[UserPermissions.Modify, UserPermissions.Add]]" i18n="@@Save"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="儲存">
        <mat-icon class="pref-icon add vis-combine-icon">save</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">儲存</span>
      </button>
    </span>
    <span [hidden]="!btnFinish.isShow()" class="ml-1">
      <!-- <button type="button" (click)="onBtnFinishClick()"  [disabled]="!btnFinish.isEnable" i18n="@@Finish">完成</button> -->
      <button type="button" (click)="onBtnFinishClick()" [disabled]="!btnFinish.isEnable" i18n="@@Finish"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="完成">
        <mat-icon class="pref-icon add vis-combine-icon">done</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">完成</span>
      </button>
    </span>
    <ng-content select="[end]"></ng-content>
  </div>
  <div class="clearfix"></div>
</ng-container>
<app-shared-delete-dialog [isDialogOpened]="isDeleteDialogOpened" (emitResult)="onDeleteDialogResult($event)">
</app-shared-delete-dialog>
