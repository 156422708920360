<form class="m-lg-0 controlled-font-size-middle" style="display: flex;flex-direction: column;" [ngStyle]="RowGap?{rowGap:RowGap}:{}">
    <div class="d-flex w-100 mt-1" [ngStyle]="Flex?{flexWrap:'wrap'}:{}" *ngFor="let row of childTable;let ind=index">
        <ng-container *ngFor="let item of row;let i=index">
            <div style="flex-shrink: 1;flex-grow:1;display:flex;padding-right: 0.6%;" [hidden]="item.hidden==true"
                [ngStyle]="fieldWidthStyle" [ngClass]="Flex?'field-width-'+i:''" *ngIf="item;else els">
                <div *ngIf="item.displayTitle" style="text-align-last: right;margin-right: 5px;" 
                        class="bar-name-width" [ngClass]="item.titleCls" [ngStyle]="titleWidthStyle">
                    <div class="vis-title d-inline-block" #title>{{item.fieldTitle}}</div>
                </div>
                <div #container class="field-container no-extend" [ngClass]="item.cls"></div>
            </div>
            <ng-template #els let-index=i>
                <div style="flex-shrink: 1;flex-grow: 1;" class="d-flex"></div>
            </ng-template>
        </ng-container>
        <div style="flex-shrink: 0;flex-grow:1" class="d-flex" *ngIf="Flex==false && ind==childTable.length-1">
            <div *ngIf="!buttonLeft" style="flex-grow: 1;"></div>
            <ng-content select="button, app-vis-button"></ng-content>
        </div>
    </div>
</form>