import { Directive, HostListener, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter, Optional } from '@angular/core';
import { FormControl } from '@angular/forms';
@Directive({
    selector: '[appClearInput]'
  })
  export class ClearInputDirective implements OnInit, OnDestroy {
    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  
    clearBtn:HTMLDivElement = null;
    el:HTMLInputElement = null;
    fc:FormControl;
    constructor(private elRef:ElementRef<HTMLInputElement>,@Optional() private formControl:FormControl) {
      this.el =  elRef.nativeElement;
      this.fc = formControl;
      var clearBtn = document.createElement('div');
      document.body.appendChild(clearBtn);
      clearBtn.innerText = "X";
      clearBtn.style.position = 'absolute';
      //clearBtn.style.backgroundColor = 'red';
      clearBtn.style.display = 'none';
      clearBtn.style.width = '20px'
      clearBtn.style.height = '20px'
      clearBtn.style.cursor = 'pointer'
      clearBtn.style.textAlign = 'center'
    
      clearBtn.onclick = ()=>{
        //debugger
        this.el.value = '';      
        this.ngModelChange.emit('');
        this.fc?.setValue('');
        this.hideClearBtn();
        //this.el.focus();
      };
      this.clearBtn = clearBtn;
      //el.appendChild(clearBtn);        
    }
    ngOnInit(): void {
      setTimeout(() => {
        if(this.el.value){
          // debugger
          this.showClearBtn();
        }  
      }, 0);
    }
    ngOnDestroy(): void {
      this.hideClearBtn();
      document.body.removeChild(this.clearBtn);
    }
    originStyle:{paddingRight:string,width:string} = null ;
    
    @HostListener('input', ['$event'])
    onInput(e: KeyboardEvent) {
      if(e.target instanceof HTMLInputElement){
        if(e.target.value){
          this.showClearBtn();
        }else{
          this.hideClearBtn();
        }
      }    
    }
    @HostListener('window:resize', ['$event'])
    onResize(e: Event) {
        if(this.el.value){
            setTimeout(() => {
                this.showClearBtn();    
            }, 0);
        }
      
    }
  
    private hideClearBtn(){
      //設定X鈕的位置
      this.clearBtn.style.display = 'none';
      //還原原有樣式設定
      if(this.originStyle!=null){
        this.el.style.width = this.originStyle.width;
        this.el.style.paddingRight = this.originStyle.paddingRight;
        this.originStyle = null;
      }
      
    }
    private showClearBtn(){
      var t:HTMLElement = this.el;
      var top = (t.offsetHeight - 20)/2;
      var left = t.offsetWidth - 20;
      
      while(t){
        top+= t.offsetTop;
        left+= t.offsetLeft;
        t = t.offsetParent as HTMLElement;
      }
      //設定X鈕的位置
      this.clearBtn.style.top = top +'px';
      this.clearBtn.style.left = left+'px';
      this.clearBtn.style.display = '';
      //設定元素的內縮排, 保留原有樣式設定
      if(!this.originStyle){
        this.originStyle = {
            width:this.el.style.width,
            paddingRight:this.el.style.paddingRight
          }
        }
      // this.el.style.width = '100%';
      // this.el.style.paddingRight = 20 + 'px';
    }
  }