import { Directive, HostListener, ElementRef, Input, Renderer2, Optional, OnInit } from '@angular/core';
import { HistHelperPadService } from '../services/hist-helper-pad.service';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperId } from '../models/helper-id';
import { VisDatepickerComponent } from 'src/app/shared/components/datepicker/vis-datepicker/vis-datepicker.component';

@Directive({
  selector: '[appHistFocus]'
})
export class HistFocusDirective  implements OnInit{

  static stopEvent = false;
  static StopPadHelperEvent(time:number = 500){
    //console.log('['+new Date().toISOString()+'] Set focus disable');
    HistFocusDirective.stopEvent = true;
    setTimeout(() => {
      HistFocusDirective.stopEvent = false;
      //console.log('['+new Date().toISOString()+'] Set focus enable');
    }, time);
  }
  constructor(private renderer: Renderer2, 
    public elem: ElementRef,
    private padService: HistHelperPadService,
    private helperService: HistHelperService,
    @Optional() private datePicker:VisDatepickerComponent) {
    this.renderer.addClass(elem.nativeElement, 'area-input');
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.datePicker?.yearInput.nativeElement.addEventListener('focus',(e)=>{this.onfocus(e)})
      this.datePicker?.dayInput.nativeElement.addEventListener('focus',(e)=>{this.onfocus(e)})
      this.datePicker?.monthInput.nativeElement.addEventListener('focus',(e)=>{this.onfocus(e)})  
    }, 0);
    
  }
  @Input()
  data:any;
  //#region focus
  lastAreaId = '';
  /** 若因為Pad的動作引起的Service，透過這個flag暫時關閉回傳回pad */
  emitEvent = true;

  

  @HostListener('focus', ['$event'])
  onfocus(evt:any) {
    //console.log('['+new Date().toISOString()+'] Element focsed ', this.elem, evt)
    this.focus();
  }
  setFocus(emitPadEvent:boolean){
    this.emitEvent = emitPadEvent;
    // 當瀏覽器本身就不在Focus的時候，要Focus回瀏覽器後elementFocus才會觸發
    // 先呼叫一次變更狀態以避免上述問題
    this.focus();
    this.elem.nativeElement.focus();
  }
  focus(){
    const id: string = this.elem.nativeElement.id;
    this.areaFocus(id);
    if(this.emitEvent){
      this.helperService.focus(id,this.data);
      if(HistFocusDirective.stopEvent){
        return;
      }
      var toTab = HelperId.getHelpTab(HelperId.getSourceById(id));
      this.padService.Command_FocusTo(toTab,this.data);
    }
    this.emitEvent = true;
  }

  private removeAreaFocusStyle(areaId: string,nextFocusId:string) {
    const area = document.getElementById(areaId);
    if (area) {
      try {
        if(area.classList.contains('area-focus')){
          area.classList.remove('area-focus');  
          if(nextFocusId != areaId){
            HelperId.StoredPrevFocusAreaId = areaId;
          }
          // console.log('HelperId.StoredPrevFocusAreaId',HelperId.StoredPrevFocusAreaId)
        }
        

      } catch {}
    }
  }
  private areaFocus(elemId: string) {
    if (!elemId) { return; }
    
    const areaId = HelperId.getAreaIdByInputId(elemId);
    const ids = HelperId.getAreaIds();
    ids.forEach((id) => {
      this.removeAreaFocusStyle(id,areaId);
    });

    if (!this.lastAreaId && areaId === this.lastAreaId) {
      return;
    }
    this.lastAreaId = areaId;
    const area = document.getElementById(areaId);
    if (area) {
      area.classList.add('area-focus');
    }
  }
  //#endregion
}
