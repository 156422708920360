<div class="card" [hidden]="!isShow()">
  <div class="card-header">
    <ng-container i18n="@@Contract">合約</ng-container>
    <app-shared-edit-control-buttons (onEmitSave)="save()" (onEmitCancel)="cancel()" (onEmitDelete)="delete()" (onEmitEdit)="editFromView()">
    </app-shared-edit-control-buttons>
  </div>
  <!--./card-header-->
  <div class="card-body" *ngIf="editFormGroup">
    <form [formGroup]="editFormGroup" class="vis-form vis-form-narrow">
      <div class="row">
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@Name">姓名</span>
            <input id="name" class="vis-input" [disabled]="true" [value]="selectedPatientName">
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span class="required" i18n="@@ContractType">類型</span>
            <app-vis-dropdownlist formControlName="TypeCode" [data]="editOptions.contractOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true" class="required" #type (valueChange)="selectContractType($event)">
            </app-vis-dropdownlist>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span class="required" i18n="@@StartDate">起始日期</span>
            <app-vis-datepicker formControlName="StartDate" class="required" ></app-vis-datepicker>
            <app-validation-tip controlName="StartDate"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@EndDate" class="required">結束日期</span>
            <app-vis-datepicker formControlName="EndDate" class="required" ></app-vis-datepicker>
            <app-validation-tip controlName="EndDate"></app-validation-tip>
            <label *ngIf="editFormGroup.errors" style="color: #dd3141; font-size: 13px;">{{ editFormGroup.errors?.error }}</label>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@Doctor" [class.required]="type.value === 'CnHCare'">醫師</span>
            <app-vis-dropdownlist formControlName="DoctorId" [data]="editOptions.doctorOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true" [ngClass]="{'required': type.value === 'CnHCare'}">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="DoctorId"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@ContractStatus" class="required">狀態</span>
            <app-vis-dropdownlist formControlName="Status" [data]="editOptions.contractStatusOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true" class="required">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="Status"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@CloseDate">結案日期</span>
            <app-vis-datepicker formControlName="CloseDate" ></app-vis-datepicker>
            <app-validation-tip controlName="CloseDate"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-8">
          <label class="vis-form-field">
            <span i18n="@@CloseReason">結案原因</span>
            <input class="vis-input" formControlName="CloseReason" />
            <app-validation-tip controlName="CloseReason"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@IdentityType">身分類別</span>
            <app-vis-dropdownlist formControlName="IdentityType" [data]="editOptions.identityOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="IdentityType"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-8">
          <label class="vis-form-field">
            <span i18n="@@IdentityTypeOther">身分類別其他</span>
            <input class="vis-input" formControlName="IdentityTypeOther"/>
            <app-validation-tip controlName="IdentityTypeOther"></app-validation-tip>
          </label>
        </div>
        <!-- <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@Name">Payment Type</span>
            <app-vis-dropdownlist formControlName="PaymentType" [data]="editOptions.paymentOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="PaymentType"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-8">
          <label class="vis-form-field">
            <span i18n="@@Name">Payment Type Other</span>
            <input class="vis-input" formControlName="PaymentTypeOther"/>
            <app-validation-tip controlName="PaymentTypeOther"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@Name">Care Level</span>
            <app-vis-dropdownlist formControlName="CareLevel" [data]="editOptions.careLevelOptions" [textField]="'text'"
            [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="CareLevel"></app-validation-tip>
          </label>
        </div> -->
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@IsVerifing">審批狀態</span>
            <!-- <input class="vis-input" formControlName="IsVerify"/> -->
            <input type="radio" formControlName="IsVerify" id="IsVerifyYes" value="true" class="k-radio" >
            <label class="k-radio-label" for="IsVerifyYes" i18n="@@IsVerifyYes">是</label>
            <input type="radio" formControlName="IsVerify" id="IsVerifyNo" value="false" class="k-radio" >
            <label class="k-radio-label" for="IsVerifyNo" i18n="@@IsVerifyNo">否</label>
            <app-validation-tip controlName="IsVerify"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@VerifyNo">審批編號</span>
            <input class="vis-input" formControlName="VerifyNo"/>
            <app-validation-tip controlName="VerifyNo"></app-validation-tip>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Remark">備註</span>
            <input class="vis-input" formControlName="Remark"/>
            <app-validation-tip controlName="Remark"></app-validation-tip>
          </label>
        </div>
      </div>
      <!--./row-->
      <!--隱藏欄位-->
      <!-- <input formControlName="Id" [hidden]="true" /> -->
      <input class="vis-input" formControlName="PatientId" [hidden]="true" />
      <!-- <input class="vis-input" formControlName="ClinicId" [hidden]="true" /> -->
    </form>
  </div>
  <!--./card-body-->
</div>
<!--./card-->
