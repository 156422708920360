/** 性別列舉 */
export enum SexEnum {
    /** 女性 */
    SexWoman = 0,
    /** 男性 */
    SexMan = 1,
    /** 未知 */
    SexNone = 999
}

export const SexDesc: {text: string, value: number}[] =
    [{text: '女性', value: 0 },
     {text: '男性', value: 1 },
     {text: '未知', value: 999 }];

/**
 * 報表需節省空間
 */
export const SexDescRpt: {text: string, value: number}[] =
    [{text: '女', value: 0 },
     {text: '男', value: 1 },
     {text: '', value: 999 }];


