

/**
 * 新版就醫序號
 */ 
export class SeqNumber256 {
    /**
     * 就診日期時間
     */ 
    public ClinicDateTime: Date = new Date(0);
    /**
     * 就醫序號
     */ 
    public Number: string = null;
    /**
     * 醫療院所代碼
     */ 
    public ClinicCode: string = null;
    /**
     * 安全簽章
     */ 
    public SecuritySeal: string = null;
    /**
     * SAM ID
     */ 
    public SamId: string = null;
    /**
     * 同日就診
     */ 
    public SameDay: string = null;
}
