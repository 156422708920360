import { HelpPadTab } from 'src/app/services/api-service/hist/help-pad-api';
import { HelperSourceEnum } from '../enums/helper-source-enum';

export class HelperId {

  // area 用來決定目前哪個區塊是得到focus
  static CCArea = 'xCCArea';
  static PEArea = 'xPEArea';
  static DiagArea = 'xDiagArea';
  static VSArea = 'xVSArea';
  static OrderArea = 'xOrderArea';
  static ChiLookArea = 'xChiLookArea';
  static ChiAskArea = 'xChiAskArea';
  static ChiPulseArea = 'xChiPulseArea';
  static ChiBianZhengArea = 'xChiBianZhengArea';
  static ChiTreatRuleArea = 'xChiTreatRuleArea';

  // input
  static CC = 'xCC';
  static PE = 'xPE';
  static Diag = 'xDiag';
  static VS = 'xVS';
  static OdrGrid = 'xOGrid';
  static ChiGrid = 'xCGrid';
  static ChiLook = 'xChiLook';
  static ChiAsk = 'xChiAsk';
  static ChiPulse = 'xChiPulse';
  static ChiBianZheng = 'xChiBianZheng';
  static ChiTreatRule = 'xChiTreatRule';

  // Default Grid
  static OdrGridMark = HelperId.OdrGrid + '_1_';
  static OdrGridCode = HelperId.OdrGrid + '_2_';
  static OdrGridName = HelperId.OdrGrid + '_3_';
  static OdrGridQty = HelperId.OdrGrid + '_4_'; // enter quantity
  static OdrGridUnit = HelperId.OdrGrid + '_5_';
  static OdrGridFreq = HelperId.OdrGrid + '_6_';
  static OdrGridDays = HelperId.OdrGrid + '_7_';
  static OdrGridTotalDose = HelperId.OdrGrid + '_8_';
  static OdrGridWay = HelperId.OdrGrid + '_9_';
  static OdrGridTotalBox = HelperId.OdrGrid + '_10_';
  static OdrGridBoxUnit = HelperId.OdrGrid + '_11_';
  static OdrGridDispensing = HelperId.OdrGrid + '_12_';
  static OdrGridRemark = HelperId.OdrGrid + '_13_';
  static OdrGridMedIdName = HelperId.OdrGrid + '_14_';
  static OdrGridSDate = HelperId.OdrGrid + '_15_';
  static OdrGridSTime = HelperId.OdrGrid + '_16_';
  static OdrGridEDate = HelperId.OdrGrid + '_17_';
  static OdrGridETime = HelperId.OdrGrid + '_18_';
  static OdrGridCalPrice = HelperId.OdrGrid + '_19_';

  // Chinese Grid
  static ChiGridCode = HelperId.ChiGrid + '_1_';
  static ChiGridQty = HelperId.ChiGrid + '_2_';
  static ChiGridUnit = HelperId.ChiGrid + '_3_';
  static ChiGridProcess = HelperId.ChiGrid + '_4_';
  static StoredPrevFocusAreaId = '';
  private static startsWith(str: string, prefix: string): boolean {
    if (!str || !prefix) {
      return false;
    }
    return str.slice(0, prefix.length) === prefix;
  }
  static getSourceById(elemId: string): HelperSourceEnum {
    if (!elemId) {
      return HelperSourceEnum.NA;
    } else if (this.startsWith(elemId, HelperId.CC)) {
      return HelperSourceEnum.CC;
    } else if (this.startsWith(elemId, HelperId.PE)) {
      return HelperSourceEnum.PE;
    } else if (this.startsWith(elemId, HelperId.ChiAsk)) {
      return HelperSourceEnum.ChiAsk;
    } else if (this.startsWith(elemId, HelperId.ChiLook)) {
      return HelperSourceEnum.ChiLook;
    } else if (this.startsWith(elemId, HelperId.ChiPulse)) {
      return HelperSourceEnum.ChiPulse;
    } else if (this.startsWith(elemId, HelperId.ChiBianZheng)) {
      return HelperSourceEnum.ChiBianZheng;
    } else if (this.startsWith(elemId, HelperId.ChiTreatRule)) {
      return HelperSourceEnum.ChiTreatRule;
    } else if (this.startsWith(elemId, HelperId.Diag)) {
      return HelperSourceEnum.Diag;
    } else if (this.startsWith(elemId, HelperId.OdrGridFreq)) {
      return HelperSourceEnum.Freq;
    } else if (this.startsWith(elemId, HelperId.OdrGridWay)) {
      return HelperSourceEnum.Way;
    } else if (this.startsWith(elemId, HelperId.OdrGridDispensing)) {
      return HelperSourceEnum.Dispensing;
    } else if (this.startsWith(elemId, HelperId.ChiGridProcess)) {
      return HelperSourceEnum.ChiProcess;
    } else if (this.startsWith(elemId, HelperId.OdrGrid)) {
      // 先檢查Grid內部其他型態，如Freq等，沒有才看是不是Grid
      return HelperSourceEnum.Order;
    } else if (this.startsWith(elemId, HelperId.ChiGrid)) {
      // 先檢查Grid內部其他型態，沒有才看是不是Grid
      return HelperSourceEnum.ChiOrder;
    } else {
      return HelperSourceEnum.NA;
    }
  }
  static getAreaIds(): string[] {
    const ids = [];
    ids.push(this.CCArea);
    ids.push(this.PEArea);
    ids.push(this.ChiAskArea);
    ids.push(this.ChiLookArea);
    ids.push(this.ChiPulseArea);
    ids.push(this.ChiBianZhengArea);
    ids.push(this.ChiTreatRuleArea);
    ids.push(this.DiagArea);
    ids.push(this.VSArea);
    ids.push(this.OrderArea);
    return ids;
  }
  static getAreaIdByInputId(elemId: string): string {
    if (!elemId) {
      return '';
    } else if (this.startsWith(elemId, HelperId.CC)) {
      return this.CCArea;
    } else if (this.startsWith(elemId, HelperId.PE)) {
      return this.PEArea;
    } else if (this.startsWith(elemId, HelperId.ChiAsk)) {
      return this.ChiAskArea;
    } else if (this.startsWith(elemId, HelperId.ChiLook)) {
      return this.ChiLookArea
    } else if (this.startsWith(elemId, HelperId.ChiPulse)) {
      return this.ChiPulseArea;
    } else if (this.startsWith(elemId, HelperId.ChiBianZheng)) {
      return this.ChiBianZhengArea;
    } else if (this.startsWith(elemId, HelperId.ChiTreatRule)) {
      return this.ChiTreatRuleArea;
    } else if (this.startsWith(elemId, HelperId.Diag)) {
      return this.DiagArea;
    } else if (this.startsWith(elemId, HelperId.VS)) {
      return this.VSArea;
    } else if (this.startsWith(elemId, HelperId.OdrGrid)) {
      // 先檢查Grid內部其他型態，如Freq等，沒有才看是不是Grid
      return this.OrderArea;
    } else if (this.startsWith(elemId, HelperId.ChiGrid)) {
      // 先檢查Grid內部其他型態，沒有才看是不是Grid
      return this.OrderArea;
    } else {
      return '';
    }
  }

  static getHelpTab(areaEnum: HelperSourceEnum): HelpPadTab{
    return areaEnum==HelperSourceEnum.CC?HelpPadTab.CC:
          areaEnum==HelperSourceEnum.PE?HelpPadTab.PE:
    areaEnum==HelperSourceEnum.Diag?HelpPadTab.DX:
    areaEnum==HelperSourceEnum.Order?HelpPadTab.RX:
    HelpPadTab.RX;
  }
}
