
import { CSHISResult } from './CSHISResult';
/**
 * 
 */ 
export class CSHISResultWithData<T> extends CSHISResult {
    /**
     * 資料
     */ 
    public Data: T = null;
}
