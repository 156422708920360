import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BaseConfig } from './base-config.service';
import { UserCache } from './user-cache';
import { IBaseConfig } from '../shared/models/base-config';
import { ClinicDataService } from './data-service/clinic-data-service';
import { ClinicEventHandler } from './signalr-event-handler/clinic-event-handler';
import { delay } from '../shared/utilities';
import { CompanyEventHandler } from './signalr-event-handler/company-event-handler';
import { UserEventHandler } from './signalr-event-handler/user-event-handler';
import { SystemEventHandler } from './signalr-event-handler/system-event-handler';
import { AuthenticationService } from '../security/services/authentication.service';
import { UserConfirmService } from './user-confirm.service';
import { EasyFormService } from './easy-form-service';

export type Notify = (data: string) => void;
export enum AccessCheckLevel {
  User = 0,
  Clinic = 1,
  Company = 2
}
export declare type GroupMsgData = {
  Group: 'Clinic' | 'Company' | 'User';//自訂自行擴充
  Type: 'Action' | 'Notify' | 'Alert';
  Act: string;
  Arg: any;
}
@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  get ConnId() {
    return this.hubConnection.connectionId;
  }
  get State() {
    return this.hubConnection.state;
  }
  // 保留的連線Id,與Server斷線(close)重新接回後，通知Server移除之前的ConnId
  private prevConnId: string;
  config: IBaseConfig;
  public notify: Notify;
  private clinicId: number;
  private user: string;
  private sysStTime: number = 0;
  private showRefrechConfirm: boolean = true;
  private autoReconnectEnable = false;

  constructor(private clinicData: ClinicDataService,
    private auth: AuthenticationService,
    private clinicEvt: ClinicEventHandler,
    private companyEvt: CompanyEventHandler,
    private userEvt: UserEventHandler,
    private systemEvt: SystemEventHandler,
    private ezForm: EasyFormService) {
    // load config from BaseConfig
    this.config = BaseConfig.getConfig();
    const user = UserCache.getLoginUser();
    if (user !== null) {
      this.user = user.Clinic.Id + '@' + user.Account;
      this.clinicId = user.Clinic.Id;
    }
    setInterval(() => {
      this.connectionAlive()
    }, 30000)
  }

  private connectionAlive() {
    if (this.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.invoke('Alive');
    }
  }

  public async startConnection(onConnected: (a: string) => void) { // : Observable<Observable<void>> {
    var user = UserCache.getLoginUser();
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Debug)
      .withUrl(this.config.webApi.hisBaseUrl + 'chathub', {
        accessTokenFactory: () => user.AuthToken,
      })
      .build();
    this.hubConnection.on('SysStTime', async (sysStTime: number) => {
      if (this.sysStTime && this.sysStTime != sysStTime) {
        if (this.showRefrechConfirm) {
          var ret = await this.ezForm.show({
            'msg': '偵測到系統更新，請重新整理網頁後再繼續使用。',
            'title': '版本更新',
            'fields': {
              skip: {
                'type': 'check',
                'label': '不再提醒',
                'name': 'skip',
                'value': false
              }
            },
            'hideClose': true,
            'showBtnCancel': false,
            'textOk': '稍後重新整理',
            'textCancel': '立即重新整理',
            'btns': [{
              'text': '立即重新整理',
              'callback': () => {
                location.reload();
              }
            }]
          });
          this.showRefrechConfirm = !ret.skip;
        }
      } else {
        this.sysStTime = sysStTime;
      }
    })
    this.hubConnection.onreconnected(() => {
      console.log('SignalR Reconnected');
    })
    this.hubConnection.onreconnecting(() => {
      console.log('SignalR Reconnecting');
    })
    this.hubConnection.onclose(async (er) => {
      console.log('SignalR Closed', this.prevConnId, er);
      if (this.autoReconnectEnable) {
        await this.autoReconnect();
      }
    })
    try {
      var prev = this.prevConnId;
      await this.hubConnection.start();
      this.prevConnId = this.hubConnection.connectionId;
      this.autoReconnectEnable = true;
      if (prev) {
        await this.hubConnection.invoke('RemovePrevConnectId', prev);
      }
      console.log('SignalR Connected:', this.hubConnection.connectionId);
      onConnected(this.hubConnection.connectionId);
    } catch (e) {
      console.log('SignalR open fail', e);
    }
    this.auth.logoutEvent.subscribe(async x => {
      await this.StopConnection();
    });
  }
  public async StopConnection() {
    this.autoReconnectEnable = false;
    await this.hubConnection.stop()
  }
  private async autoReconnect() {
    try {
      console.log('SignalR start AutoReconnect');
      await delay(Math.random() * 1000 + 4);
      if (this.hubConnection.state == signalR.HubConnectionState.Disconnected) {
        var prev = this.prevConnId;
        await this.hubConnection.start();
        this.prevConnId = this.hubConnection.connectionId;
        if (prev) {
          await this.hubConnection.invoke('RemovePrevConnectId', prev);
        }
      }
    } catch (e) {
      console.log('SignalR AutoReconnect fail', e);
      await this.autoReconnect();
    }
  }

  public userLogin() {
    var user = UserCache.getLoginUser();
    if (!user) {
      this.notify('使用者尚未登入');
    }

    var companyGroup = 'company'
    var clinicGroup = 'clinic'
    var userGroup = `User_${user.UserId}`;
    var systemGroup = 'system';
    // 集團群組
    this.joinGroup(companyGroup);
    // 診所群組
    this.joinGroup(clinicGroup);
    // 個人群組(多重登入)
    this.joinGroup(userGroup);
    // 系統群組
    this.joinGroup(systemGroup);
    this.hubConnection.on('ClinicMessage', (data: GroupMsgData) => {
      this.clinicEvt.Invoke(data);
    });
    this.hubConnection.on('CompanyMessage', (data: GroupMsgData) => {
      this.companyEvt.Invoke(data);
    });
    this.hubConnection.on('UserMessage', (data: GroupMsgData) => {
      this.userEvt.Invoke(data);
    });
    this.hubConnection.on('SystemMessage', (data: GroupMsgData) => {
      this.systemEvt.Invoke(data);
    });
  }

  public BroadcastClinic(data: GroupMsgData) {
    //var clinicGroup = 'clinic_' + UserCache.getLoginUser().Clinic.Code;
    var msg: GroupMsgData = {
      Group: 'Clinic',
      Type: 'Notify',
      Arg: 'Hello',
      Act: ''
    }
    this.hubConnection.invoke('Broadcast', msg)
      .catch(err => console.error(err));
  }



  public joinGroup(group: string) {
    this.hubConnection.invoke('JoinGroup', /*this.user,*/ group)
      .catch(err => console.error(err));

  }
  public leaveGroup(group: string) {
    this.hubConnection.invoke('LeaveGroup', group)
      .catch(err => console.error(err));
  }

  async CheckAccessAvailable(state: string, checkLevel: AccessCheckLevel) {
    return false; //SignalR 前端 CheckAccessAvailable先return false，先不讓候診清單點選入會進不去診間 => 後續有空再處理
    return await this.hubConnection.invoke<string>("CheckAccessAvailable", state, checkLevel);
  }
  async CompleteAccess() {
    await this.hubConnection.invoke("CompleteAccess");
  }
  /**
   *
   *
   * @param {string} groupId 必須唯一，訊息收送群組
   * @param {string} data 通知的傳遞訊息
   * @memberof SignalRService
   */
  public registerNotify(groupId: string, data: string) {
    this.hubConnection.invoke('register', groupId, data)
      .catch(err => console.error(err));
  }

  public addRegisterNotifyListener = (notifyHandler: Notify) => {
    this.notify = notifyHandler;
    this.hubConnection.on('register', (data) => {
      this.notify(data);
      console.log(data);
    });
  }
  private methodId: Map<string, { type: keyof NotifyType, fn: (...any) => void }> = new Map();
  /** 新增事件監聽，回傳監聽方法Id，作為呼叫removeNotifyListener的參數使用 */
  public addNotifyListener<K extends keyof NotifyType>(notifyType: K, notifyHandler: (data: NotifyType[K]) => void) {
    var fn = (data) => {
      notifyHandler(data);
    }
    var mId = crypto['randomUUID']()
    this.methodId.set(mId, { type: notifyType, fn: fn });
    this.hubConnection.on(notifyType, fn);
    return mId
  }
  /** 解除事件監聽，傳入 addNotifyListener的回傳值 */
  public removeNotifyListener(mId: string) {
    var notifyType = this.methodId.has(mId) ? this.methodId.get(mId) : null;
    if (notifyType) {
      this.hubConnection.off(notifyType.type, notifyType.fn);
    }
  }
  public GenGroupId<K extends keyof NotifyType>(notifyType: K, ...parameters: string[]): string {
    let groupId = this.clinicId.toString(); // yogi modify 1104
    parameters.forEach((p) => {
      groupId += '@' + p;
    });
    groupId += '@' + notifyType;
    return groupId;
  }
}
export type DeclareMessage = {
  Msg: string,
  Type: 'Msg' | 'Fin'
}
export class NotifyType {
  DeclareMessage: DeclareMessage;
  DeclareXmlMsg: DeclareMessage;
  DeclareMsgAna33: DeclareMessage;
  RxUpdateMessage: RxUpdateMessage;
  Register: void;
  FinishOrder: any;
}
// export const enum NotifyType {
//   Register = 'Regist',
//   FinishOrder = 'OrderFinish',
//   SYSTEM = '_SYSTEM_',
//   ReceiveMessage = 'DeclareMessage'
// }
export type RxUpdateMessage = {
  Msg: string,
  Type: 'Msg' | 'Fin'
}
