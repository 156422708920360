import { Injectable, OnDestroy } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';

import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { Hist } from 'src/app/hist/models/hist';


@Injectable({
    providedIn: 'root'
})
export class NHIDeclareApi {

    constructor(private webApiFactory: WebApiClient,
        private router: Router, private routeInfo: RouteInfoService) {
        this.api = this.webApiFactory.createHisService('registers/NHIDeclare');
        this.api.enableLoader = false;
    }
    api: WebApiService;
    async GetRTX8Data(pid: number): Promise<Hist> {
        return await this.api.get('GetRTX8Data?patientId=' + pid).toPromise<Hist>();
    }
    // async GetRTX4Data(patientId: number, icNo: string, date: Date): Promise<Hist> {
    //     var strdate = new Date(date).toLocaleDateString();
    //     return await this.api.get(`GetRTX4Data?patientId=${patientId}&icNo=${icNo}&date=${strdate}`).toPromise<Hist>();
    // }

}
