<div class="row narrow-padding overflow-x-auto font-hidden-before before-none overflow-y-hidden">
    <div class="w-100 mt-1">
		<div class="card m-0">
      <div class="card-header" style="height: 46px;">
          <div style="display: flex; align-items: center;" class="lh-sm">
              <button type="button" (click)="openPatientDialog()" i18n="@@QueryPatient"
                  class="vis-btn-config p-0 position-relative pl-2 min-w-24" title="病患搜尋">
                  <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">病患搜尋</span>
              </button>&nbsp;　&nbsp;
              <ng-container *ngIf="patient">
                  <div style="display: flex; align-items: center;" class="lh-sm" [ngClass]="nowWidth>=1600?'extend-msg':''">
                      <div class="highlight-color w-24"
                      style="display: inline-block; font-weight: 600;">
                      {{patientName==undefined || patientName==null ? '' : patientName}}
                      </div>
                      <span class="min-w-14" i18n="@@Brithday"><b>病歷號</b><span class="word-keep-all">{{patientNo}}</span></span>&nbsp;&nbsp;
                      <span class="min-w-14" i18n="@@Brithday"><b>出生日</b><span class="word-keep-all">{{patientBirthday | formatROCDate:true:false}}</span></span>&nbsp;&nbsp;
                      <span class="min-w-10" i18n="@@Brithday"><b>電話</b>{{patientPhone}}</span>&nbsp;&nbsp;
                      <span class="min-w-10" i18n="@@Brithday"><b>地址</b>{{patientAddress}}</span>&nbsp;
                  </div>
              </ng-container>
              <div style="flex-grow: 1;"></div>
              <button type="button" (click)="onBtnAddClick()" i18n="@@Add" *ngIf="patient" [disabled]="isAdd||canEdit"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="新增">
                  <mat-icon class="pref-icon add vis-combine-icon">add_circle</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">新增</span>
              </button>
              <button type="button" (click)="onBtnEditClick()" i18n="@@Edit" *ngIf="patient" [disabled]="(!isAdd&&!hasRecord)||canEdit"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="修改">
                  <mat-icon class="pref-icon edit vis-combine-icon">edit</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">修改</span>
              </button>
              <button type="button" (click)="onBtnDeleteClick()" i18n="@@Delete" *ngIf="patient" [disabled]="(!isAdd&&!hasRecord)||canEdit"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="刪除">
                  <mat-icon class="pref-icon delete vis-combine-icon">delete</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">刪除</span>
              </button>
              <button type="button" (click)="onPrintSettingDialogOpen()" i18n="@@Preview" *ngIf="patient" [disabled]="(!isAdd&&!hasRecord)||canEdit"
                  class="vis-btn-config p-0 position-relative pl-2 w-24" title="預覽列印">
                  <mat-icon class="pref-icon print vis-combine-icon">print</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">預覽列印</span>
              </button>
              <button type="button" (click)="onBtnSaveClick()" i18n="@@Save" *ngIf="patient" [disabled]="!(isAdd||canEdit)"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="儲存">
                  <mat-icon class="pref-icon save vis-combine-icon">save</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">儲存</span>
              </button>
              <button type="button" (click)="onBtnCancelClick()" i18n="@@Cancel" *ngIf="patient" [disabled]="!(isAdd||canEdit)"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="取消">
                  <mat-icon class="pref-icon remove vis-combine-icon">keyboard_backspace</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
              </button>
          </div>
      </div>

      <div class="card-body p-0">
          <div class="col-sm-12 row personalList" style="padding: 0 15px;">
              <div *ngIf="canEdit&&editCC" class="col-sm-4" style="border-top: 1px solid #ccc; border-left: 1px solid #ccc; border-bottom: 1px solid #ccc; border-right: 1px solid #ddd;">
                  <div> <!--醫囑-->
                      <form [formGroup]="ccQueryFormGroup">
                          <label class="c-form-field mr-2">
                              <span i18n="@@ICD10">代碼: </span>
                              <input class="vis-input w-28" formControlName="ccCode" />
                          </label>
                          <label class="c-form-field">
                              <span i18n="@@StdDiagLocalName">名稱: </span>
                              <input class="vis-input w-32" formControlName="ccName" />
                          </label>
                          <div class="float-right">
                              <button
                              type="button"
                              (click)="onBtnCCQueryClick()"
                              i18n="@@Query"
                              class="vis-btn-config p-0 position-relative pl-2 w-18"
                              title="查詢">
                              <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
                              </button>
                          </div>
                      </form>
                  </div>
                  <app-vis-grid [customClass]="'patients-list'"
                      [data]="systemCodeList"
                      [pageSize]="ccPageSize"
                      [skip]="ccPageSkipNo"
                      [pageable]="true"
                      (pageChange)="onCCPageChange($event)"
                      [selectable]="true"
                      [kendoGridSelectBy]="'Id'"
                      [selectedKeys]="selectedItems"
                      (cellClick)="ccGridSelectionChange($event)">
                      <app-vis-grid-column field="Code" title="代碼" [width]="30" i18n-title="@@Code">
                      <ng-template let-data>
                          <ng-container *ngIf="editing==data.Id;else elsTmp">
                          <input class="vis-grid-input text-center" [(ngModel)]="editingData.Code" />
                          </ng-container>
                          <ng-template #elsTmp>
                          <div class="ellipsis-content text-center" [title]="data.Code">{{data.Code}}</div>
                          </ng-template>
                      </ng-template>
                      </app-vis-grid-column>
                      <app-vis-grid-column field="CName" title="醫囑" [width]="350" min-width="350" i18n-title="@@StdDiagLocalName" class="custom-name">
                      <ng-template let-data>
                          <ng-container *ngIf="editing==data.Id;else elsTmp">
                          <input class="vis-grid-input" [(ngModel)]="editingData.Name" />
                          </ng-container>
                          <ng-template #elsTmp>
                          <div class="ellipsis-content" [title]="data.Name">{{data.Name}}</div>
                          </ng-template>
                      </ng-template>
                      </app-vis-grid-column>
                  </app-vis-grid>
              </div>

              <div *ngIf="canEdit&&editDx" class="col-sm-4" style="width: 500px; overflow-y: auto; overflow-x: auto; border-top: 1px solid #ccc; border-left: 1px solid #ccc; border-bottom: 1px solid #ccc; border-right: 1px solid #ddd;">
                  <div> <!--病名-->
                      <form [formGroup]="dxQueryFormGroup">
                          <label class="c-form-field mr-2">
                              <span i18n="@@ICD10">代碼: </span>
                              <input class="vis-input w-28" formControlName="dxCode" />
                          </label>
                          <label class="c-form-field">
                              <span i18n="@@StdDiagLocalName">名稱: </span>
                              <input class="vis-input w-32" formControlName="dxName" />
                          </label>
                          <div class="float-right">
                              <button
                              type="button"
                              (click)="onBtnDxQueryClick()"
                              i18n="@@Query"
                              class="vis-btn-config p-0 position-relative pl-2 w-18"
                              title="查詢">
                              <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
                              </button>
                          </div>
                      </form>
                  </div>
                  <div style="width: 800px;">
                      <app-vis-grid [customClass]="'patients-list'"
                          [data]="dxList"
                          [pageSize]="dxPageSize"
                          [skip]="dxPageSkipNo"
                          [pageable]="true"
                          (pageChange)="onDxPageChange($event)"
                          [selectable]="true"
                          [kendoGridSelectBy]="'Id'"
                          [selectedKeys]="selectedItems"
                          (cellClick)="dxGridSelectionChange($event)">
                          <app-vis-grid-column field="Code" title="代碼" [width]="50" i18n-title="@@Code">
                          <ng-template let-data>
                              <ng-container *ngIf="editing==data.Id;else elsTmp">
                              <input class="vis-grid-input text-center" [(ngModel)]="editingData.Code" />
                              </ng-container>
                              <ng-template #elsTmp>
                              <div class="ellipsis-content text-center" [title]="data.Code">{{data.Code}}</div>
                              </ng-template>
                          </ng-template>
                          </app-vis-grid-column>
                          <app-vis-grid-column field="CName" title="病名" [width]="200" min-width="100" i18n-title="@@StdDiagLocalName" class="custom-name">
                          <ng-template let-data>
                              <ng-container *ngIf="editing==data.Id;else elsTmp">
                              <input class="vis-grid-input" [(ngModel)]="editingData.CName" />
                              </ng-container>
                              <ng-template #elsTmp>
                              <div class="ellipsis-content" [title]="data.CName">{{data.CName}}</div>
                              </ng-template>
                          </ng-template>
                          </app-vis-grid-column>
                          <app-vis-grid-column field="EName" title="英文病名" [width]="200" min-width="100" i18n-title="@@StdDiagLocalName">
                          <ng-template let-data>
                              <ng-container *ngIf="editing==data.Id;else elsTmp">
                              <input class="vis-grid-input" [(ngModel)]="editingData.EName" />
                              </ng-container>
                              <ng-template #elsTmp >
                              <div class="ellipsis-content" [title]="data.EName">{{data.EName}}</div>
                              </ng-template>
                          </ng-template>
                          </app-vis-grid-column>
                      </app-vis-grid>
                  </div>
              </div>

              <div *ngIf="!canEdit" class="col-sm-4" style="border-top: 1px solid #ccc; border-left: 1px solid #ccc; border-bottom: 1px solid #ccc; border-right: 1px solid #ddd;">
                  <app-vis-grid [data]="masterGridView" #masterGridCom
                      [pageable]="false"
                      [scroll]="true"
                      [selectable]="true"
                      [showStickyHeight]="550"
                      [showStickyHead]="true"
                      [kendoGridSelectBy]="'Id'"
                      [setDefaultSeclected]="true"
                      (cellClick)="gridSelectionChange($event)">
                      <app-vis-grid-column field="CreateTime" title="開立日" [width]="90" i18n-title="@@CreateTime" class="text-center custom-m-auto">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <div class="ellipsis-content" [title]="dataItem.CreateTime | formatDate:''">{{ dataItem.CreateTime | formatDate:'' }}</div>
                          </ng-template>
                      </app-vis-grid-column>
                      <app-vis-grid-column field="StartRegDate" title="開始日" [width]="90" i18n-title="@@StartRegDate" class="text-center custom-m-auto">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <div class="ellipsis-content" [title]="dataItem.StartRegDate | formatDate:''">{{ dataItem.StartRegDate | formatDate:'' }}</div>
                          </ng-template>
                      </app-vis-grid-column>
                      <app-vis-grid-column field="EndRegDate" title="結束日" [width]="90" i18n-title="@@EndRegDate" class="text-center custom-m-auto">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <div class="ellipsis-content" [title]="dataItem.EndRegDate | formatDate:''">{{ dataItem.EndRegDate | formatDate:'' }}</div>
                          </ng-template>
                      </app-vis-grid-column>
                      <app-vis-grid-column field="LastPrintDate" title="列印日" [width]="90" i18n-title="@@LastPrintDate" class="text-center custom-m-auto">
                          <ng-template kendoGridCellTemplate let-dataItem>
                              <div class="ellipsis-content" [title]="dataItem.LastPrintDate | formatDate:''">{{ dataItem.LastPrintDate | formatDate:'' }}</div>
                          </ng-template>
                      </app-vis-grid-column>
                      <app-vis-grid-column field="PrintCount" title="次" [width]="40" i18n-title="@@PrintCount" class="text-right custom-m-auto">
                      </app-vis-grid-column>
                  </app-vis-grid>
              </div>

              <div class="col-sm-4" style="width: 500px; height: 580px; overflow-y: auto; overflow-x: auto; border-top: 1px solid #ccc; border-left: 1px solid #ddd; border-bottom: 1px solid #ccc; border-right: 1px solid #ccc;" >
                <app-vis-grid style="width: 1010px;"
                [data]="detailGridView"
                [pageable]="false"
                [selectable]="false"
                [multiselect]="true"
                [selectByCheckOnly]="true"
                [kendoGridSelectBy]="'Id'"
                [scroll]="true"
                [showStickyHeight]="550"
                [showStickyHead]="true">
                <app-vis-grid-checkbox-column (checkChanged)="checkChanged($event)" [field]="'Checked'" [showSelectAll]="canEdit" [width]="30" [setDisable]="!canEdit">
                </app-vis-grid-checkbox-column>
                <app-vis-grid-column field="RegDate" title="就醫日" [width]="100" i18n-title="@@RegDate" class="text-center custom-m-auto">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate | formatDate:'' }}</div>
                    </ng-template>
                </app-vis-grid-column>
                <app-vis-grid-column field="Icode" title="身分" [width]="60" i18n-title="@@ICode" class="text-center custom-m-auto">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="ellipsis-content" [title]="setIcodeName(dataItem.Icode)">{{ setIcodeName(dataItem.Icode) }}</div>
                    </ng-template>
                </app-vis-grid-column>
                <app-vis-grid-column field="Icno" title="卡序" [width]="60" i18n-title="@@Icno" class="text-center custom-m-auto">
                </app-vis-grid-column>
                <app-vis-grid-column field="DoctorName" title="醫師" [width]="90" i18n-title="@@DoctorName" class="text-center custom-m-auto">
                </app-vis-grid-column>
                <app-vis-grid-column field="Dx1" title="主診斷" [width]="250" i18n-title="@@Dx1" class="text-left custom-m-auto">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="ellipsis-content" [title]="selectedUseLang == '1' ? dataItem.Dx1Name : dataItem.Dx1EName">{{ selectedUseLang == '1' ? dataItem.Dx1Name : dataItem.Dx1EName }}</div>
                    </ng-template>
                </app-vis-grid-column>
                <app-vis-grid-column field="Dx2" title="次診斷" [width]="450" i18n-title="@@Dx2" class="text-left custom-m-auto">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="ellipsis-content" [title]="selectedUseLang == '1' ? dataItem.Dx2Name : dataItem.Dx2EName">{{ selectedUseLang == '1' ? dataItem.Dx2Name : dataItem.Dx2EName }}</div>
                    </ng-template>
                </app-vis-grid-column>
            </app-vis-grid>
              </div>
              <div [formGroup]="SettingFormGroup" class="col-sm-4">
                  <div class="flex-glow-one" style="width: 100%;">
                      <label class="d-inline-flex mr-1">醫師囑言：</label>
                      <textarea id="ccInput" style="resize: none; width: 100%;" draggable="false" rows="11" [value]=""
                      formControlName="printCC" [disabled]="printCCDisabled" (click)="onCcInputClick()"></textarea>
                  </div>
                  <div class="flex-glow-one" style="width: 100%;">
                      <label class="d-inline-flex mr-1">診斷病名：</label>
                      <textarea id="dxInput" style="resize: none; width: 100%;" draggable="false" rows="11" [value]=""
                      formControlName="printSickName" [disabled]="printSickNameDisabled" (click)="onDxInputClick()"></textarea>
                  </div>
                  <div class="flex-glow-one" style="width: 100%;">
                      <label class="d-inline-flex mr-1">診治醫師：</label>
                      <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 150px;"
                          [data]="doctorList" [textField]="'text'" [valueField]="'value'"
                          (valueChange)="onDdlMenualDrChange($event)" formControlName="printDoctor">
                      </app-vis-dropdownlist>
                  </div>
                  <div class="flex-glow-one" style="width: 100%;">
                      <label class="d-inline-flex mr-1">科&nbsp;&nbsp;　&nbsp;&nbsp;別：</label>
                      <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true"
                          style="width: 150px;" [data]="sectionList" [textField]="'text'" [valueField]="'value'"
                          (valueChange)="onDdlMenualDeptChange($event)" formControlName="printDept">
                      </app-vis-dropdownlist>&nbsp;&nbsp;
                      <button type="button" (click)="onEnglishSetting()" class="my-1 text-xs btn-grid px-1 lh-base" 
                        style="background-color: #fff194;">
                        <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="英文設定">how_to_reg</mat-icon>
                        <span class="text-xs-sm float-right">英文設定</span>
                      </button>
                  </div>
              </div>
          </div>
      </div>
		</div>
	</div>
</div>

<!--搜尋病患視窗-->
<app-patients-patient-query-dialog [isDialogOpened]="isPatientDialogOpened"
    (onEmitResult)="onPatientDialogClose($event)">
</app-patients-patient-query-dialog>

<!-- 開立診斷證明書病例查詢視窗 -->
<app-vis-dialog title="開立診斷證明書" [show]="isSearchHistDialogOpened" (close)="onSearchHistDialogClose('')" [minWidth]="200"
  [width]="400" i18n-title="@@printSetting">
  <div class="card-body pb-0">
    <form [formGroup]="queryFormGroup" class="vis-form extend-msg vis-form-narrow p-0">
      <div class="row">
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@startDate">請輸入就診起日</span>
            <app-vis-datepicker  formControlName="startDate"
                [showToday]="true" (today)="onBtnTodayForStartDateClick()"
                style="vertical-align: middle;" class="d-inline-flex position-relative -top-2"
                (valueChange)="onDtpStartDateChange($event)">
            </app-vis-datepicker>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@endDate">請輸入就診迄日</span>
            <app-vis-datepicker formControlName="endDate"
                    [showToday]="true" (today)="onBtnTodayForEndDateClick()"
                    style="vertical-align: middle;" class="d-inline-flex position-relative -top-2"
                    (valueChange)="onDtpEndDateChange($event)">
                </app-vis-datepicker>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@deptCode">科別選擇</span>
            <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 100px;"
                [data]="sectionList" [textField]="'text'" [valueField]="'value'"
                formControlName="deptCode" (valueChange)="onDdlDeptChange($event)">
            </app-vis-dropdownlist>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@useLang">病名</span>
            <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 100px;"
                [data]="langsSet" [textField]="'text'" [valueField]="'value'"
                formControlName="useLang" (valueChange)="onDdlLangChange($event)">
            </app-vis-dropdownlist>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@icode">請選擇保險分類</span>
            <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 100px;"
                [data]="icodeSet" [textField]="'text'" [valueField]="'value'"
                formControlName="icode" (valueChange)="onDdlICodeChange($event)">
            </app-vis-dropdownlist>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@EAddress">診治醫師預設</span>
            <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 160px;"
                [data]="defDoctorSet" [textField]="'text'" [valueField]="'value'"
                formControlName="defaultDoctorUseBy" (valueChange)="onDdlDefDrChange($event)">
            </app-vis-dropdownlist>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <mat-checkbox style="margin-right: 10px;" formControlName="isIncludeCC" class="extend-label">是否載入主訴</mat-checkbox>
            <!-- <mat-checkbox style="margin-right: 10px;" formControlName="isIncludePE" class="extend-label">理學</mat-checkbox> -->
          </label>
        </div>
      </div>
    </form>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onSearchHistDialogClose('sure')" i18n="@@Add">確定</button>
    <button type="button" (click)="onSearchHistDialogClose('')" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 診斷證明書列印設定視窗 -->
<app-vis-dialog title="診斷證明書列印" [show]="isPrintSettingDialogOpened" (close)="onPrintSettingDialogClose('')" [minWidth]="200"
  [width]="600" i18n-title="@@printSetting">
  <div class="card-body pb-0">
    <form [formGroup]="printSettingFormGroup" class="vis-form extend-msg vis-form-narrow p-0">
      <div class="row">
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Remark">備註說明：</span>
            <input class="vis-input" formControlName="DxCertRemark" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Remark">國籍/出生地：</span>
            <input class="vis-input" formControlName="BirthCity" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Job">職    業：</span>
            <input class="vis-input" formControlName="Job" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@PrintName">中文格式姓名：</span>
            <input class="vis-input" formControlName="PrintName" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@EName">英文姓名：</span>
            <input class="vis-input" formControlName="EName" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@EAddress">英文地址：</span>
            <input class="vis-input" formControlName="EAddress" style="width: 450px;" />
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Sex">選擇報表格式</span>
            <app-vis-dropdownlist class="d-inline-block no-extend" formControlName="ReportType" style="width: 80px;"
            [data]="langsSet" [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
          </label>
        </div>
      </div>
    </form>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onPrintSettingDialogClose('sure')" i18n="@@Sure">確定</button>
    <button type="button" (click)="onPrintSettingDialogClose('')" i18n="@@Cancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<app-vis-dialog title="科別英文設定" [show]="isEnglishSettingDialogOpened" (close)="onEnglishSettingDialogClose()" [minWidth]="600"
  [width]="700" i18n-title="@@Englishsetting">
  <div class="card-body pb-0" style="height: 400px; overflow-x: auto;">
    <!-- <form [formGroup]="englishSettingFormGroup" class="vis-form extend-msg vis-form-narrow p-0"> -->
      <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="false"
        (pageChange)="pageChange($event)" [selectable]="true" [kendoGridSelectBy]="'Id'"
        [selectedKeys]="selectedMedItemsId" (edit)="editHandler($event)" (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)" [navigable]="true" #grid>
        <app-vis-grid-column field="Code" title="代碼" [width]="50" i18n-title="@@Code">
        </app-vis-grid-column>
        <app-vis-grid-column field="Name" title="中文名" [width]="120" i18n-title="@@Name">
          <ng-template let-data>
            <!--不折行-->
            <div [title]="data.Name">{{data.Name}}</div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column field="Name1" title="英文名" [width]="230" i18n-title="@@Name1" class="text-center">
          <ng-template let-data>
            <div *ngIf="!isEdit(data); else editTmp" class="w-100">
              <ng-container>{{data.Name1}}</ng-container>
            </div>
            <ng-template #editTmp>
              <ng-container>
                <input class="vis-input w-100" [(ngModel)]="data.Name1" />
              </ng-container>
            </ng-template>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column title="" class="text-center custom-m-auto" [width]="200">
          <ng-template let-dataItem>
            <div class="w-100 text-center d-block multi-btns">
              <button type="button" (click)="editHandler(dataItem)" i18n="@@Edit"
                class="my-1 text-xs btn-grid px-1 background-none" *appAllowPermissionIf="[this.UserPermissions.Modify]">
                <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="編輯">edit</mat-icon>
                <span class="text-xs-sm float-right">編輯</span>
              </button>
              <button type="button" (click)="saveHandler(dataItem)" [disabled]="editId==dataItem" i18n="@@Update"
                  class="my-1 text-xs btn-grid px-1 background-none">
                  <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="更新">refresh</mat-icon>
                  <span class="text-xs-sm float-right">{{'更新'}}</span>
                </button>
                <button type="button" (click)="cancelHandler(dataItem)" i18n="@@Cancel"
                  class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none">
                  <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="取消">cancel</mat-icon>
                  <span class="text-xs-sm float-right">{{'取消'}}</span>
                </button>
            </div>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
    <!-- </form> -->
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onEnglishSettingDialogClose()" i18n="@@Sure">確定</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 診斷書預覽列印畫面 -->
<app-vis-dialog title="診斷書預覽列印" [maxWidth]="'90vw'" [superWidth]="'90%'" [show]="isDiagCertPreviewOpened" (close)="isDiagCertPreviewOpened=false">
    <app-diagnosis-cert (printed)="onPrinted($event)"></app-diagnosis-cert>
    <app-vis-dialog-actions>
        <button type="button" (click)="onDiagnosisCertPrint()" primary="true" i18n="@@Pprint">列印</button>
        <button type="button" (click)="onPreviewClose()" i18n="@@DialogClose">關閉</button>
    </app-vis-dialog-actions>
</app-vis-dialog>
