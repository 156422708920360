import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
// his - shared
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { BaseListComponent } from 'src/app/shared/components/base/base.list.component';
import { BasePageListComponent } from 'src/app/shared/components/base/base.pagelist.component';

// this module
import { Contract } from '../models/contract';
import { ContractEditComponent } from '../edit/edit.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { PatientListDto } from 'src/app/services/api-service/patient/patient-api';

@Component({
  selector: 'app-patients-contract-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ContractListComponent extends BasePageListComponent<Contract> implements OnInit {

  constructor(private fb: FormBuilder,
    private webApiClient: WebApiClient,
    private notificationDI: EasyNotificationService) {
    super();
    this.api = this.webApiClient.createHisService('patients/contract');
    this.notification = notificationDI;
  }

  @ViewChild(ContractEditComponent,  { static: false })
  editComponent: ContractEditComponent;

  // 查詢時需要的選項或數值
  editOptions = {
    contractAllOptions: [],
    contractOptions: [],
    identityOptions: [] ,
    careLevelOptions: [],
    paymentOptions: [],
    contractStatusOptions: [],
    doctorOptions: [],
    contractStatusOptions2: [],
  };

  queryContractType: string;    // query合約類型的值
  selectedPatientId: number;    // patient-query-dialog選到的病患Id
  selectedPatientName: string;  // patient-query-dialog選到的病患Name
  isPatientDialogOpened: Boolean = false;   // 是否開啟patine-query-dialog
  isAdd: Boolean = false;   // 是否為新增

  // 建立query form group
  makeQueryFormGroup() {
    return this.fb.group({
      contractType: [''],
      patientNo: [''],
      patientCid: [''],
      condition: ['custom'],
      status: [''],
      startDate1: [''],
      startDate2: [''],
      endDate1: [''],
      endDate2: [''],
    });
    // , {validator: [this.dateCheck('startDate', 'endDate'), this.dateCheck('startDate1', 'endDate1')]}
  }

  dateCheck(start, end) {
    // tslint:disable-next-line: no-shadowed-variable
    return (group: FormGroup): {[key: string]: any} => {
      const s = group.controls.startDate;
      const e = group.controls.endDate;
      if (e.value) {
        if (e.value < s.value) {
          return {error : 'End Date must be greater than Start Date' };
        }
      }
    };
  }



  makeQueryConditions() {
    let startString1 = '';
    let startString2 = '';
    let endString1 = '';
    let endString2 = '';
    if (this.queryFormGroup.controls['startDate1'].value) {
      startString1 = DateHelper.getIsoStringNoTimeZone(this.queryFormGroup.controls['startDate1'].value);
    }
    if (this.queryFormGroup.controls['startDate2'].value) {
      startString2 = DateHelper.getIsoStringNoTimeZone(this.queryFormGroup.controls['startDate2'].value);
    }
    if (this.queryFormGroup.controls['endDate1'].value) {
      endString1 = DateHelper.getIsoStringNoTimeZone(this.queryFormGroup.controls['endDate1'].value);
    }
    if (this.queryFormGroup.controls['endDate2'].value) {
      endString2 = DateHelper.getIsoStringNoTimeZone(this.queryFormGroup.controls['endDate2'].value);
    }

    // tslint:disable-next-line: no-shadowed-variable
    const query = {
      contractType: this.queryFormGroup.controls['contractType'].value,
      patientNo: this.queryFormGroup.controls['patientNo'].value,
      patientCid: this.queryFormGroup.controls['patientCid'].value,
      Status: this.queryFormGroup.controls['status'].value,
      startDate1: startString1,
      startDate2: startString2,
      endDate1: endString1,
      endDate2: endString2,
    };
    // console.log(query)
    return query;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getEditOptions();
    this.onConditionChanges();
  }

  private clearCondtion() {
    this.queryFormGroup.controls['status'].setValue('');
    this.queryFormGroup.controls['startDate1'].setValue('');
    this.queryFormGroup.controls['startDate2'].setValue('');
    this.queryFormGroup.controls['endDate1'].setValue('');
    this.queryFormGroup.controls['endDate2'].setValue('');
  }
  private enableCondtion(isEnable: boolean) {
    if (isEnable) {
      this.queryFormGroup.controls['status'].enable();
      this.queryFormGroup.controls['startDate1'].enable();
      this.queryFormGroup.controls['startDate2'].enable();
      this.queryFormGroup.controls['endDate1'].enable();
      this.queryFormGroup.controls['endDate2'].enable();
    } else {
      this.queryFormGroup.controls['status'].disable();
      this.queryFormGroup.controls['startDate1'].disable();
      this.queryFormGroup.controls['startDate2'].disable();
      this.queryFormGroup.controls['endDate1'].disable();
      this.queryFormGroup.controls['endDate2'].disable();
    }
  }
  private onConditionChanges() {
    this.queryFormGroup.get('condition').valueChanges.subscribe(val => {
      if (val === 'custom') {
        this.clearCondtion();
        this.enableCondtion(true);
      } else if (val === 'effective') {
        this.clearCondtion();
        this.queryFormGroup.controls['status'].setValue('Book');
        this.queryFormGroup.controls['startDate2'].setValue(new Date());
        this.queryFormGroup.controls['endDate1'].setValue(new Date());
        this.enableCondtion(false);
      } else if (val === 'expired') {
        this.clearCondtion();
        const previousDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 1);
        this.queryFormGroup.controls['endDate2'].setValue(previousDate);
        this.enableCondtion(false);
      } else if (val === 'willExpire1') {
        this.clearCondtion();
        const lastDateOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        this.queryFormGroup.controls['endDate2'].setValue(lastDateOfMonth);
        this.enableCondtion(false);
      } else if (val === 'willExpire2') {
        this.clearCondtion();
        const lastDateOfNextMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0);
        this.queryFormGroup.controls['endDate2'].setValue(lastDateOfNextMonth);
        this.enableCondtion(false);
      }
    });
  }
  onStatusChange(evt) { console.log(evt); }

  // 取得查詢時需要的選項或數值
  private getEditOptions() {
    // 呼叫api
    // api的查詢條件
    this.api.get('getEditOptions').subscribe(
      (data) => {
        this.editOptions.contractAllOptions = data.contractAllOptions;
        this.editOptions.contractOptions = data.contractOptions;
        this.editOptions.identityOptions = data.identityOptions;
        this.editOptions.careLevelOptions = data.careLevelOptions;
        this.editOptions.paymentOptions = data.paymentOptions;
        this.editOptions.doctorOptions = data.doctorOptions;
        this.editOptions.contractStatusOptions = data.ContractStatusOptions;
        // condition method
        this.editOptions.contractStatusOptions2 = [];
        this.editOptions.contractStatusOptions2.push({ value: '', text: '' });
        data.ContractStatusOptions.forEach(element => {
          this.editOptions.contractStatusOptions2.push(element);
        });
        // set init
        this.queryFormGroup.controls['condition'].setValue('effective');
      },
      error => {
        this.notification.showGetDataFailed('patient-contract-list');
      }
    );
  }

  public onBtnAddContract() {
    this.isPatientDialogOpened = true;
    this.isAdd = true;
    this.selectedPatientId = 0;
    this.selectedPatientName = '';
  }

  //#region 病患選擇視窗 --------------------
  // 開啟 病患查詢視窗
  openPatientDialog() {
    // 清除原本選擇
    this.isPatientDialogOpened = true;
  }
  // 關閉 病患查詢視窗
  closePatientDialog() {
    this.isAdd = false;
    this.isPatientDialogOpened = false;
  }
  // 病患查詢視窗 關閉事件
  onPatientDialogClose(patient: PatientListDto) {
    if (patient && patient.Id) {
      if (patient.Id === 0) {
        this.notification.showInfoById('MSG_RegistersWaitingList1');
        // 關閉
        this.closePatientDialog();
        // 進入新增病患畫面
        // this.goToPatientPageForAdd(patient);
        return false;
      } else {
        this.selectedPatientId = patient.Id;
        this.selectedPatientName = patient.Name;
        // 關閉
        this.closePatientDialog();
        this.onBtnAddClick();
        // 找尋病患掛號資料
        return false;
      }
    } else {
      // 關閉
      this.closePatientDialog();
    }
  }
  //#endregion --------------------

  selectContract(value) {
    this.queryContractType = value;
    this.refreshList(true);
  }
}
