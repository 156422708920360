<div style="padding: 20px; background-color: rgb(241, 241, 241);">
    <div [formGroup]="referralFormGroup" class="c-form-inline vis-form vis-patients-edit overflow-initial">
        <table cellpadding="0" cellspacing="0" width="100%" border="0" style="padding: 0px;">
            <tr width="90%">
                <td width="100%" style="padding: 0px; margin: 0px;">
                    <app-referrals-referral-form-dialog-info [oriRef]="oriRef" [queryPatientNo]="queryPatientNo" [histRcd]="this.histRcd" [referralFormGroup]="this.referralFormGroup">
                    </app-referrals-referral-form-dialog-info>
                </td>
            </tr>
            <tr width="90%">
                <td width="100%" style="padding: 0px; margin: 0px;">
                    <app-referrals-referral-form-dialog-edit [oriRef]="oriRef" [queryPatientNo]="queryPatientNo" [histRcd]="this.histRcd" [referralFormGroup]="this.referralFormGroup">
                    </app-referrals-referral-form-dialog-edit>
                </td>
            </tr>
            <tr width="90%">
                <td width="100%" style="padding: 0px; margin: 0px;">
                    <div class="c-form-inline card" style="margin-left: 0rem;">
                        <div class="card-header">
                            <mat-grid-list [rowHeight]="'35'" cols="3" [gutterSize]="'3px'">
                                <mat-grid-tile class="vis-form-field" style="margin-left: 15px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <span class="required extend-label" i18n="@@SuggestionReferral">建議轉診→醫院</span>
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <span class="extend-label" i18n="@@ReferralDectorName">醫師姓名</span>
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <span class="required extend-label" i18n="@@Dept">科別</span>
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 15px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <app-vis-dropdownlist formControlName="InHspid" [data]="hspid"
                                        [valueField]="'HID'" [textField]="'HNAME'" (valueChange)="onHspidChange($event)"
                                        [valuePrimitive]="true" style="width: 90%;"></app-vis-dropdownlist>
                                    </label>
                                    <!-- <label class="vis-form-field" style="margin-left: 0px;">
                                        <button type="button" i18n="@@EditModeForSuggestion">編</button>
                                    </label> -->
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <input class="vis-input" style="width: 95%;" formControlName="InDrName" />
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px; margin-right: 25px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <app-vis-dropdownlist formControlName="InDep" [data]="medDeptCodes" [valueField]="'value'"
                                        [textField]="'text'" (valueChange)="onDepChange($event)" [valuePrimitive]="true" style="width: 95%;">
                                        </app-vis-dropdownlist>
                                    </label>
                                    <!-- <label class="vis-form-field" style="margin-left: 0px;">
                                        <button type="button" i18n="@@EditModeForDept">編</button>
                                    </label> -->
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 15px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <span class="extend-label" style="max-width: initial !important;" i18n="@@ReferralHospital">轉入院所病歷號</span>
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <span class="extend-label" style="max-width: initial !important;" i18n="@@ArrangeDate">安排就醫日期</span>
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field extend-msg" style="margin-left: 0px;">
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-checkbox style="margin-right: 10px;"
                                        [labelPosition]="'after'"
                                        [checked]="true"
                                        formControlName="printReferral">列印轉診單</mat-checkbox>
                                    </label>
                                </mat-grid-tile>
                                <!-- <mat-grid-tile rowspan="2" class="c-form-field" style="margin-left: 0px;">
                                    <label class="c-form-field" style="margin-left: 0px;">
                                        <button type="button" i18n="@@BtnArrange">安排</button>　
                                    </label>
                                    <label class="c-form-field" style="margin-left: 0px;">
                                        <button type="button" i18n="@@BtnCheck">檢查</button>
                                    </label>
                                </mat-grid-tile> -->
                                <mat-grid-tile class="vis-form-field" style="margin-left: 15px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <input class="vis-input" style="width: 90%;" formControlName="InPatientNo" />
                                    </label>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field" style="margin-left: 0px;">
                                        <app-vis-datepicker formControlName="SchMedDate" [maxWidth]="'calc(100% - 1.2rem)'"></app-vis-datepicker>
                                    </label>
                                    <button type="button" (click)="btnArrangeClick()" i18n="@@Arrange" class="my-1 text-xs vis-btn-third-primary px-1 w-18" style="height: 1.5rem;">
                                        <mat-icon class="vis-combine-icon d-inline-flex mr-1 text-base w-auto" title="安排">edit</mat-icon>
                                        <span class="text-xs-sm float-right position-relative" style="right: 2px;">安排</span>
                                    </button>
                                </mat-grid-tile>
                                <mat-grid-tile class="vis-form-field" style="margin-left: 0px;">
                                    <label class="vis-form-field extend-msg" style="margin-left: 0px;">
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <mat-checkbox style="margin-right: 10px;"
                                        [labelPosition]="'after'"
                                        [checked]="true"
                                        formControlName="uploadNHI">上傳健保署平台</mat-checkbox>
                                    </label>
                                </mat-grid-tile>
                            </mat-grid-list>
                            <div>&nbsp;</div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>

<app-vis-dialog [title]="'請輸入安排及轉入相關資訊'" [superWidth]="'40%'" [maxWidth]="'40vw'"
[show]="isArrangeWinOpened" (close)="ArrangeInDiagClose()">
    <div class="card-body" style="font-size: 16px;" [formGroup]="arrangeFormGroup">
      <div>
        <div class="col-sm-12">
            <label style="display: flex;">
                <span i18n="@@SchMedDiag">安 排 就 醫 診 別：</span>
                <input class="vis-input" formControlName="SchMedDiag" style="width: 120px;" />
            </label>
        </div>
        <div class="col-sm-12">
            <label style="display: flex;">
                <span i18n="@@SchMedNum">安 排 就 醫 號 碼：</span>
                <input class="vis-input" formControlName="SchMedNum" style="width: 120px;" />
            </label>
        </div>
        <div class="col-sm-12">
            <label  style="display: flex;">
                <span i18n="@@InHsAddrw">轉 入 院 所 地 址：</span>
                <input class="vis-input" formControlName="InHsAddrw" style="width: 400px;" />
            </label>
        </div>
        <div class="col-sm-12">
            <label  style="display: flex;">
                <span i18n="@@InHsPhone">轉 入 院 所 電 話：</span>
                <input class="vis-input" formControlName="InHsPhone" style="width: 120px;" />
            </label>
          </div>
          <div class="col-sm-12">
            <label  style="display: flex;">
                <span i18n="@@DrTrans" style="width: 145px;">醫師交班注意事項：</span>
                <textarea rows="4" maxlength="1000" formControlName="DrTrans" style="top:0; width: 390px; resize: none;">
                </textarea>
            </label>
          </div>
      </div>
    </div>
    <app-vis-dialog-actions>
      <button type="button" (click)="ArrangeInSure()" i18n="@@Sure">確定</button>
      <button type="button" (click)="ArrangeInDiagClose()" i18n="@@Cancel">取消</button>
    </app-vis-dialog-actions>
  </app-vis-dialog>
