/** 申報類別 Enum */
export enum DeclareClassEnum {
    /** 1.送核 */
    SendApproval = 1,
    /** 2.補報 */
    Supplement = 2
}
export const DeclareClassDesc: {text: string, value: number}[] =
    [{text: '送核', value: 1 },
     {text: '補報', value: 2 }];

