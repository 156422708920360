export enum HistActionEnum {

  NA = 0,

  // 進入診間
  EnterRoom = 1,

  // 離開
  Exit = 2,

  // 存檔
  Save = 3,

  // 完診
  Finish = 4,
}
