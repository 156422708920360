

/**
 * 不須pin碼的基資
 */ 
export class BasicData {
    /**
     * 卡片號碼
     */ 
    public IcNo: string = null;
    /**
     * 姓名
     */ 
    public Name: string = null;
    /**
     * 身分證號或身分證明文件號碼
     */ 
    public CId: string = null;
    /**
     * 出生日期
     */ 
    public Birth: Date = new Date(0);
    /**
     * 性別
     */ 
    public Sex: string = null;
    /**
     * 發卡日期
     */ 
    public Issuedate: Date = new Date(0);
    /**
     * 卡片註銷註記
     */ 
    public CancellationFlag: string = null;
    /**
     * 緊急聯絡電話
     */ 
    public RelationPhone: string = null;
}
