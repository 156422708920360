import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LabHRxListPageCollection } from 'src/app/services/api-service/hist/hist-api.model';
import { LabHrxListReportComponent } from '../lab-hrx-list-report/lab-hrx-list-report.component';

@Component({
  selector: 'app-lab-hrx-list-report-preview',
  templateUrl: './lab-hrx-list-report-preview.component.html',
  styleUrls: ['./lab-hrx-list-report-preview.component.css']
})
export class LabHrxListReportPreviewComponent implements OnInit {
  cssContent: string = '';
  @Input() reportData: LabHRxListPageCollection;

  @ViewChild('detailPDF') detailPDF: LabHrxListReportComponent;
  @ViewChild('detailExcel') detailExcel: LabHrxListReportComponent;

  constructor() { }

  ngOnInit(): void {
  }

  onReportPrint = () => {
    this.detailPDF.onPrint();
  }

  onReportExportExcel = () => {
    this.detailExcel.onPrint();
  }

}
