<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;

    }
    .pres-report2 table td,
    table th {
      border-bottom: 5px double black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .th-btm{
      border-right: 0px solid black;
      border-left: 0px solid black;
      border-bottom: 5px double black;
    }
    .subT-btm{
      border-bottom: 5px double blue !important;
    }
    .sum-btm{
      border-bottom: 1px solid black !important; padding-top: 10px;
    }
    .txt-right{
      text-align: right;
    }
  }
</style>
<div class="a4-print-style-h" #reportContainer style="height: 600px; margin: 0px auto; overflow-y: auto;">
  <!-- <div class="pres-report2" style="background-color: white; height: 90%; width: 80%;"> -->
  <div class="pres-report2" style="background-color: white; height: 100%; width: 100%;">
    <div class="container" id="container">
    </div>
  </div>
</div>
