

/**
 * 最近一次就醫序號
 */ 
export class LastSeqNum {
    /**
     * 最近一次就醫序號
     */ 
    public LastSeqNumData: string = null;
}
