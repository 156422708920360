import { Component, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const VIS_NT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisNumerictextboxComponent),
  multi: true
};
@Component({
  selector: 'app-vis-numerictextbox',
  templateUrl: './vis-numerictextbox.component.html',
  styleUrls: ['./vis-numerictextbox.component.css'],
  providers: [VIS_NT_CONTROL_VALUE_ACCESSOR]
})
export class VisNumerictextboxComponent implements OnInit, ControlValueAccessor {
  @ViewChild('input')
  inputElememt: ElementRef<HTMLInputElement>
  _max: number = null;
  @Input()
  set max(v:number){
    this._max = v;
  }
  _min: number = null;
  @Input()
  set min(v:number){
    this._min = v;
  }
  _step: number = 1;
  @Input()
  set step(v:number){
    this._step = v;
  }
  _value: string = '';
  @Input()
  set value(v:number){
    if(v){
      this._value = v.toString();
      this.fixDecimal();
    }
  }
  _style: string = 'width: 100%';
  @Input()
  set style(v:number){
    if(v){
      this._style = v.toString();
      this.fixDecimal();
    }
  }
  _decimal = 0;
  @Input()
  set format(v:string){
    if(v.startsWith('n')){
      var decimal = v.substring(1);
      this._decimal = parseInt(decimal);
    }else{
      this._decimal = 0;
    }
    this.fixDecimal();
  }
  @Input()
  OnlyPositive: boolean = false;
  @Input()
  set disabled(disabled:boolean){
    this._disabled = disabled;
  }

  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean;
  constructor(private el: ElementRef) {
  }

  writeValue(obj: any): void {
    if(typeof( obj) ==  'number'){
      this._value = obj.toFixed(this._decimal);
    }else{
      this._value = "";
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  ngOnInit(): void {
  }
  fixDecimal(){
    var v = parseFloat(this._value);
    this._value = v.toFixed(this._decimal);
  }

  onChange(e){
    if (this.OnlyPositive && (e.target.value.startsWith('-') || parseFloat(e.target.value) <= 0)) {
      e.target.value = '1';
    }
    this._value = e.target.value;
    this.fixDecimal();

    if(this._onChange){
      this._onTouched();
      // 吐數字出去
      var v = parseFloat(this._value);
      this._onChange(v)
    }
  }
  @Output()
  blur = new EventEmitter<any>();
  @Output()
  focus = new EventEmitter<any>();
  onBlur(e){
    this.blur.emit(e);
  }
  setFocus(){
    this.inputElememt.nativeElement.focus();
  }
  @Output()
  submit = new EventEmitter();
  onKeyUp(evt: KeyboardEvent){
    if(evt.key == 'Enter'){
      var v = parseFloat(this._value);
      this.submit.emit(v);
    }
  }
  onFocus(evt){
    this.focus.emit(evt);
  }
}
