<style>

    @media print {
        .a4-rotate-print-style{
            position: absolute;
            width: 100%;
            top: 0px;
            left: 0px;
            z-index: 1000000000;
            margin: 0px;
            padding: 0px;
            border: none;
        }
        .print-mask{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: #dddd;
            top: 0px;
            left: 0px;
            z-index: 100000000;
        }
    }
</style>
<div class="print-mask" ></div>
<div class="a4-rotate-print-style mb-3 p-2" *ngIf="_h10&&_h11" #reportContainer>
    <div class="pres-report" style="background-color: white;height: 100%;width: 100%;">
        <div style="text-align: center;font-weight: bolder;padding-top: 15px;">特 約 醫 事 服 務 機 構 門 診 醫 療 服 務 點 數 及 醫 令 清 單 </div>
        <div style="-webkit-text-size-adjust: none;font-size: 12pt;zoom: 0.8;margin: 0px 15px;">
            <div style="display: flex;column-gap: 5px;">
                <table style="width: 12.5%">
                    <tr>
                        <td >
                            d2 流水編號
                            <div>{{H11.d2_Sequence}}</div>
                        </td>
                    </tr>
                </table>
                <table style="width: 87.5%;">
                    <tr>
                        <td style="width: 20%;">t1 資料格式</td>
                        <td style="width: 30%;">t2 服務機構</td>
                        <td style="width: 15%;">t3 費用年月</td>
                        <td style="width: 20%;">t5 申報類別 {{toBox(H10.t5_declareType,'1')}}送核 {{toBox(H10.t5_declareType,'2')}}補報</td>
                        <td style="width: 15%;">d1 案件分類</td>
                    </tr>
                    <tr>
                        <td>{{H10.t1_type}} 門診費用明細</td>
                        <td>{{H10.t2_nhiCode}} {{clinic.Name}}</td>
                        <td>{{H10.t3_month |formatROCDateUnit}}</td>
                        <td> d12 補報原因註記 {{toBoxs(H11.d12_ReasonNote,'1','2')}}</td>
                        <td>{{H11.d1_Case}}</td>
                    </tr>
                </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:18%">特定治療項目代號:d4 {{H11.d4_SpecialItem1}},d5 {{H11.d5_SpecialItem2}},d6 {{H11.d6_SpecialItem3}},d7 {{H11.d7_SpecialItem4}}</td>
                    <td style="width:18%" colspan="2">d49 姓名：{{H11.d49_Name}}</td>
                    <td style="width:24%" colspan="2">d9 就醫日期:{{H11.d9_ClinicDate |formatROCDateUnit}} </td>
                    <td style="width:18%;">d8 就醫科別: {{H11.d8_Dept}}</td>
                    <td style="width:22%">d27 給藥日份: {{H11.d27_Days}}</td>
                </tr>
                <tr>
                    <td style="width:18%">d11 出生日期：{{H11.d11_BirthDate | formatROCDateUnit}}</td>
                    <td style="width:18%" colspan="2">d3 身分證號:{{H11.d3_Cid}}</td>
                    <td style="width:12%">d29 就醫序號：{{H11.d29_icno}}</td>
                    <td style="width:12%">d14 給付類別：{{H11.d14_PayTpe}}</td>
                    <td style="width:18%">d15 部分負擔代號：{{H11.d15_PartCode}}</td>
                    <td style="width:22%">d10 治療結束日期：{{H11.d10_EndDate}}</td>
                </tr>
                <!-- <tr *ngIf="icUpMode==3">
                  <td style="width:30%" colspan="2">d60 就醫識別碼:{{H11.d60_Itreatnum}}</td>
                  <td style="width:40%" colspan="3">d61 實際就醫(調劑或檢查)之就醫識別碼: {{H11.d61_LackTreatnum}}</td>
                  <td style="width:30%" colspan="2">d62 原就醫識別碼：{{H11.d62_ItreatnumOri}}</td>
                </tr> -->
                <tr>
                    <td style="width:22%">d42 論病例計酬代碼:{{H11.d42_PayCodeByCase}}</td>
                    <td style="width:22%" colspan="2">d18 病患是否轉出: {{toBoxs(H11.d18_Referral,'Y','N')}}</td>
                    <td style="width:26%" colspan="2">d45 依附就醫新生兒出生日期: {{H11.d45_BabyBirth| formatROCDateUnit}}</td>
                    <td style="width:30%" colspan="2">d44 慢性病連續處方箋有效期間總處方日份：{{H11.d44_TotDays_chronic}}</td>
                </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
                <tr>
                    <td style="width:6.5%">主診斷代碼</td>
                    <td style="width:9%">d19:{{H11.d19_Dx1}}</td>
                    <td style="width:6.5%">次診斷代碼</td>
                    <td style="width:9%">d20:{{H11.d20_Dx2}}</td>
                    <td style="width:9%">d21:{{H11.d21_Dx3}}</td>
                    <td style="width:8.5%">d22:{{H11.d22_Dx4}}</td>
                    <td style="width:8.5%">d23:{{H11.d23_Dx5}}</td>
                    <td style="width:18%">d50矯正機關代號:{{H11.d50_CorrectionalCode}}</td>
                    <td style="width:14%">d52特定地區醫療服務: {{H11.d52_SpecialRegion}}</td>
                    <td style="width:11%">d53支援區域: {{H11.d53_SupportRegion}}</td>
                </tr>
            </table>
            <table style="border-top: 0;width: 100%;">
                <tr>
                    <td colspan="12" style="text-align: center;">
                        <span style="float: left;"> d24 主手術(處置)代碼:{{H11.d24_Surgen}} </span> 傷病名稱及主要症候：{{H11.DxName}}
                    </td>
                    <td colspan="2">
                        d25 次手術(處置)代碼(一): {{H11.d25_SubSurgen1}}
                    </td>
                    <td colspan="6">
                        d54實際提供醫療服務之醫事服務機構代碼：{{H11.d54_ClinicNhiCode_acture}}
                    </td>
                </tr>
                <tr>
                    <td colspan="4">
                        d26 次手術(處置)代碼(二) {{H11.d26_SubSurgen2}}
                    </td>
                    <td colspan="4">
                        d55轉住之醫事服務機構代號 {{H11.d55_ClinicNhiCode_refferlTo}}
                    </td>
                    <td colspan="4">
                        d56原處方就醫序號: {{H11.d56_OriIsqno}}
                    </td>
                    <td colspan="5">
                        d48山地離島地區醫療服務計畫代碼:{{H11.d48_MountainCode}}
                    </td>
                    <td colspan="3">
                        d51依附就醫新生兒胞胎註記:{{H11.d51_BabyCount}}
                    </td>
                </tr>
                <tr>
                    <td colspan="6">
                        d16轉診、處方調劑或特定檢查資源共享條件案件註記:{{H11.d16_ShareNote}}
                    </td>
                    <td colspan="7">
                        d17轉診、處方調劑或特定檢查資源共享條件之服務機構代號:{{H11.d17_ShareNHICode}}
                    </td>
                    <td colspan="7">
                        d13整合式照護計畫註記:{{toBoxs(H11.d13_IntegrateNote,'A','B','D','E','F','G','K','L','M','1','3','4','5','6')}}
                    </td>
                </tr>
                <tr>
                    <td colspan="20">
                        <div style="display: flex;">
                            <div style="flex-shrink: 0;" >d28處方調劑方式:</div>
                            <div >{{dispTypeBox(H11.d28_DispTp)}}</div>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="H11.source.CC!=null">
                    <td style="width: 20px;">
                        主訴
                    </td>
                    <td colspan="19">
                        <div style="display: flex;align-content: start;height:63px" [ngStyle]="{height: !H12List?'388px':'63px'}">
                            {{H11.source.CC}}
                        </div>
                    </td>
                </tr>
            </table>
            <table *ngIf="H12List" style="width: 100%;">
                <tr style="text-align: center; font-size: 9pt;">
                    <td style="max-width: 30px; padding-right: 0px; padding-left: 0px;">p13<br> 醫令序</td>
                    <!-- <td *ngIf="icUpMode==3" style="max-width: 70px; padding-right: 0px; padding-left: 0px;">p26<br> 醫令執行就醫識別碼</td> -->
                    <td style="padding-right: 0px; padding-left: 0px;">p20<br> 就醫科別</td>
                    <td style="max-width:52px;min-width:52px; padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p17<br> 慢性病連續處方箋、同一療程及排檢案件註記</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p2<br> 醫令調劑方式</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p3<br> 醫令類別</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p1<br> 藥品給藥日份</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p4<br> 藥品(項目)代號</td>
                    <td style="max-width:180px;min-width:100px;padding-right: 0px; padding-left: 0px;">診療項目或藥品、材料名稱規格</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p21<br> 自費特材群組序號</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p14<br> 執行時間-起</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p15<br> 執行時間-迄</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p16<br> 執行醫事人員代號</td>
                    <td style="padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p19<br> 事前審查受理編號</td>
                    <td style="padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p18<br> 影像來源</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p5<br> 藥品用量 <br>p6 診療之部位</td>
                    <td style="max-width:50px;min-width:50px; padding-right: 0px; padding-left: 0px;">p7<br> 藥品使用頻率 <br>p8 支付成數</td>
                    <td style="min-width:30px; padding-right: 0px; padding-left: 0px;">p9<br> 給藥途徑<br>作用部位</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p10<br> 總量</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p11<br> 單價</td>
                    <td style="padding-right: 0px; padding-left: 0px;">p12<br> 點數</td>
                    <td style="padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p22<br> 未列項註記</td>
                    <td style="padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p23<br> 未列項名稱</td>
                    <td style="max-width:70px;min-width:60px; padding-right: 0px; padding-left: 0px; font-size: 7.5pt;">p24<br> 委託或受託執行轉(代)檢醫事機構代號</td>
                    <td style="padding-right: 0px; padding-left: 0px;">P25<br> 藥品批號</td>
                    <td style="max-width:70px;min-width:30px; padding-right: 0px; padding-left: 0px;">審查欄</td>
                </tr>
                <tr *ngFor="let H12 of H12List">
                    <td>{{H12.p13_orderSn}}</td>
                    <!-- <td *ngIf="icUpMode==3" style="font-size: 9pt;">{{H12.p26_Itreatnum}}</td> -->
                    <td>{{H12.p20_deptCode}}</td>
                    <td>{{H12.p17_caseNote}}</td>
                    <td>{{H12.p2_dispType}}</td>
                    <td>{{H12.p3_OrderType}}</td>
                    <td>{{H12.p1_days}}</td>
                    <td>{{H12.p4_rxCode}}</td>
                    <td style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;max-width: 200px;">{{H12.ProdName||H12.IIName}}</td>
                    <td>{{H12.p21_materialSq}}</td>
                    <td>{{H12.p14_startTime}}</td>
                    <td>{{H12.p15_endTime}}</td>
                    <td>{{H12.p16_doctorCode}}</td>
                    <td>{{H12.p19_previewNo}}</td>
                    <td>{{H12.p18_imageSource}}</td>
                    <td>{{H12.p5_rxDose}}</td>
                    <td>{{H12.p7_freq}}</td>
                    <td>{{H12.p9_way}}</td>
                    <td>{{H12.p10_total}}</td>
                    <td>{{H12.p11_price}}</td>
                    <td>{{H12.p12_point}}</td>
                    <td>{{H12.p22_unlistItemNote}}</td>
                    <td>{{H12.p23_unlistItemName}}</td>
                    <td>{{H12.p24_entrustClinicCode}}</td>
                    <td>{{H12.p25_rxBatchNumber}}</td>
                    <td></td>
                </tr>
            </table>
            <table style="width: 100%;margin-top: 5px;">
                <tr>
                    <td style="width: 30%;">d57 基本部分負擔點數： {{H11.d57_APart}}</td>
                    <td style="width: 30%;">d58 藥品部分負擔點數： {{H11.d58_APartDrug}}</td>
                    <td colspan="4" style="width: 40%;">d59 檢驗(查)部分負擔點數：{{H11.d59_APartExam}}</td>
                </tr>
                <tr>
                    <td>d32 用藥明細點數小計：{{H11.d32_ADrug}}</td>
                    <td>d33 診療明細點數小計：{{H11.d33_ATreat}}</td>
                    <td colspan="4">d34 特殊材料明細點數小計：{{H11.d34_AMat}}</td>
                </tr>
                <tr>
                    <td>d30 診治醫事人員代號：{{H11.DoctorName}} {{H11.d30_DoctorCId}}</td>
                    <td>d31 藥師代號：{{H11.PharmacistName}} {{H11.d31_PhmarcistCId}}</td>
                    <td style="width: 9%;">項目代號</td>
                    <td style="width: 13%;">項目名稱</td>
                    <td style="width: 10%;">點數</td>
                    <td style="width: 8%;">審查欄</td>
                </tr>
                <tr>
                    <td rowspan="6">診療醫師人員簽章：</td>
                    <td rowspan="6">藥師簽章：</td>
                    <td>d35:{{H11.d35_ADiagCode}}</td>
                    <td>診察費</td>
                    <td style="text-align: right;padding-right: 5px;"><span style="float: left;">d36:</span><span>{{H11.d36_ADiag}}</span></td>
                    <td></td>
                </tr>
                <tr>
                    <td>d37:{{H11.d37_AMedCode}}</td>
                    <td>藥事服務費</td>
                    <td style="text-align: right;padding-right: 5px;"><span style="float: left;">d38:</span>{{H11.d38_AMed}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td>行政協助項目部分負擔</td>
                    <td style="text-align: right;padding-right: 5px;"><span style="float: left;">d43:</span>{{H11.d43_Part_admin}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="2">d39合計點數</td>
                    <td style="text-align: right;padding-right: 5px;">{{H11.d39_ATot}}</td>
                    <td></td>
                </tr>

                <tr>
                    <td colspan="2">d40合計部分負擔點數</td>
                    <td style="text-align: right;padding-right: 5px;">{{H11.d40_APart}}</td>
                    <td></td>
                </tr>
                <tr>
                    <td colspan="2">d41申請點數(扣除部分負擔淨額)</td>
                    <td style="text-align: right;padding-right: 5px;">{{H11.d41_ATotPoint}}</td>
                    <td></td>
                </tr>
            </table>
            <div>
                事項：  本醫療服務點數及醫令清單為一式二份，一份交病患供調劑用，另一份向保險人申報用；交付調劑之處方自就醫日三日內有效。<br/>
                        醫令明細請儘量填寫於本表，如有不敷書寫時，請用另一份書寫，基本資料得僅填與本表相同之流水號與姓名，並於左上角註明“續頁“。<br/>
                        請依醫令類別(用藥、診療、特殊材料)依序集中填寫。
                        各項費用算至整數為止，小數點以下四捨五入。
                        本表各欄位請按照媒體申報格式之填表說明填寫。
            </div>
        </div>
    </div>
</div>

