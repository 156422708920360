import { Component, ContentChild, ElementRef, forwardRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VisGridColumnComponent } from '../vis-grid-column/vis-grid-column.component';

@Component({
  selector: 'app-vis-grid-editable-column',
  templateUrl: './vis-grid-editable-column.component.html',
  styleUrls: ['./vis-grid-editable-column.component.css'],
  providers: [{provide: VisGridColumnComponent, useExisting: forwardRef(() => VisGridEditableColumnComponent)}],
})
export class VisGridEditableColumnComponent extends VisGridColumnComponent {

  @ViewChild(TemplateRef)
  templateRef : TemplateRef<any>;


  @ContentChild('viewTmp')
  viewTmp : TemplateRef<any>;

  @Input()
  editRow: number = null;

  /** 置中開關 */
  @Input()
  textCenter?: boolean = false;
  constructor(protected elementRef: ElementRef) {
    super(elementRef)
   }

}
