import { Dosage } from "src/app/opd/dosage/models/dosage";
import { Rx } from 'src/app/services/api-service/rx/rx';
import { CalcRuleDoseAsTotal, CalcRuleNoAct, CalcRuleDose, CalcRuleDaily, CalcRuleTotal, CalcRuleClear } from "./hrx-calcrule";
import { OrderSpecialRuleEnum } from "src/app/enums/OrderSpecialRuleEnum";


export interface IPatient {
    Id: number;
    PatientNo: string;
    Birthday: Date;
  }
  
  export interface IHRx {
    Id: number;
    SPRule:OrderSpecialRuleEnum;
    Remark:string;
    StdCode:string;
    RxCode: string;
    ProdName: string;
    RxType: number;
    /** 輸入量 */
    QTY: number;
    Freq: string;
    Days: number;
    Way: string;
    DispTP: string;
    Dose: number;
    /** 日量 */
    DailyDose: number;
    /** 總量 */
    TotalDose: number;
    /** 次量單位 */
    Unit: string;
    /** 總量單位(顯示用) */
    BoxUnit: string;
    
    IPrice: number;
    Price: number;
  
    CalPrice: number;
    InfoDays: number;
    FreqQTY: number;
    DrugsPerBox: number;
    TotalBox: number;
    QtyRule: number;
    NeedExecutor: boolean;
    NeedExecuteDate: boolean;
    NeedExecuteTime: boolean;
    MedID: string;
    SDate: Date;
    EDate: Date;
    STime: string;
    ETime: string;
  }
  
  export enum QTYRule {
    Dose = 1,        // 次(劑)量法
    DailyDose = 2,   // 日量法
    TotalDose = 3,   // 總量法
  }
export enum AutoFillRule {
    NoFill = 0,
    TopToEnd = 1,
    EndToTop = 2
  }
/** I量法 */
export interface ICalcRule {
    /** 目標計算值是否為空 (根據總量/日量/次量…) */
    IsTargetEmpty():boolean;
    QtyToDose();
    DoseToQty();
}
export interface IHrxCalc{
    findDosage(): Dosage; 
    model: IHRx;
    rx:Rx;
    decimalRule: string;
}