<div class="container-fluid" style="width: 95%;">
  <div class="row">
    <div *ngFor="let option of options" class="col-4">
      <mat-checkbox [checked]="checkIsSelected(option.value)" [value]="option.value"
        (change)="onCheckboxChange($event, option.value)">
        {{option.text}}
      </mat-checkbox>
    </div>

  </div>
</div>
