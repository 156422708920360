import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
// import { ActivatedRoute, Router } from '@angular/router';
// import { Subject } from 'rxjs';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import { PageData } from 'src/app/shared/models/page-data';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
// import { RouteInfoService } from 'src/app/services/route-info.service';
// shared module
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
// import { DateHelper } from '../../shared/helpers/date-helper';
import { PatientEditComponent } from '../edit/patients-edit.component';
// this module
import { BaseListComponent } from 'src/app/shared/components/base/base.list.component';
import { PatientApi, PatientListDto, PatientResult } from 'src/app/services/api-service/patient/patient-api';
import { PatientsDto, QueryPatient } from 'src/app/services/api-service/patient/patientsDto';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { RegisterService } from 'src/app/services/register-service';
import { VisGridComponent } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';

// 模式:
// list: List只有 病歷號、姓名、編輯/修改按鈕
// search: List有 病歷號、姓名、生日...，但沒有 編輯/修改按鈕
enum PatientListMode {
  list = 'list',
  search = 'search'
}

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.css']
})
export class PatientListComponent extends BaseListComponent<PatientListDto> implements OnInit, OnDestroy {
  screenHeight: number;
  screenWidth: number;
  devicePixelRatio: number;
  @Input() tileColSpan: string;
  @Input() isDialogOpened?: boolean=false;
  constructor(
    private fb: FormBuilder,
    private webApiClient: WebApiClient,
    private ezNotification: EasyNotificationService,
    private patientApi: PatientApi,
    private registerService: RegisterService) {
    super();
    this.notification = this.ezNotification;
    this.api = this.webApiClient.createHisService('patients/patient');
  }

  //#region 變數 --------------------
  // api服務
  api: WebApiService;

  @Input() mode = PatientListMode.list;
  get patientListMode() {
    return PatientListMode;
  }

  @Input()
  emitResultWhenOnlyOne = true;
  // 只有在mode===search才有用途，用來通知父元件被選到的Patient
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitResult = new EventEmitter<PatientListDto>();
  @Output() onEmitChose = new EventEmitter<PatientListDto>();
  @Output() onEmitQueryNotFound = new EventEmitter<QueryPatient>();

  // Child元件
  @ViewChild(PatientEditComponent, { static: false })
  editComponent: PatientEditComponent;

  @ViewChild(VisGridComponent)
  grid: VisGridComponent;
  isChildInEdit = false;
  get allDisabled() {
    if (this.isChildInEdit) {
      return 'disabled';
    } else {
      return null;
    }
  }

  //#region 變數 - path --------------------
  // Route Path
  routeRegister = '/registers/register';
  routePatientEdit = '/patients/edit';
  routeOpdRecord = '/hist/record';
  //#endregion --------------------

  // 查詢條件
  queryFormGroup: FormGroup;

  // 清單物件
  listItems: PatientListDto[];
  pageData: PageData = {'data':[],'total':0,'totalPages':0};

  //  掛號歷史紀錄
  historyRecords: RegisterEditDto[];

  // 選定的patientId
  selectedPatientId: number;

  // 表格-所選項目
  selectedItems: number[] = [];

  selectPatient: PatientListDto

  // 表格-分頁
  gridView = { data: [], total: 0 };
  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  pageSkipNo = 0;

  // 是否開啟詢問前往掛號視窗
  // isGotoRegDialogOpened = false;
  isRegConfirmDialogOpened = false;

  // 病人選擇視窗
  isPatientDialogOpened = false;

  // 處理save時 fcousId的 highlight用的
  saveselectid = 0;

  //#endregion --------------------
  dateSearch = '';
  /** 基資姓名英文 */
  inputValue: string;

  //#region View --------------------
  // 是否顯示
  isShow(): boolean {
    if (true) {
      return true;
    } else {
      return false;
    }
  }
  get listColClass() {
    if (this.mode === PatientListMode.search) {
      return 'col-sm-12';
    } else {
      return 'col-sm-5';
    }
  }
  get editColClass() {
    if (this.mode === PatientListMode.search) {
      return '';
    } else {
      return 'col-sm-7';
    }
  }
  //#endregion --------------------

  async ngOnInit() {
    // createQueryFormGroup必須在refreshList之前，因為refreshList會用到form group參數
    this.createQueryFormGroup();

    // 基資幾乎沒有不查詢直接使用的情境
    //this.onQueryClick();
  }


  receiveDateSearch(value: string) {
    this.dateSearch = value;
  }
  //#region 查詢 --------------------
  // 建立query form group
  createQueryFormGroup() {
    this.inputValue = '';
    this.queryFormGroup = this.makeQueryFormGroup();
  }

  makeQueryFormGroup() {
    return this.fb.group({
      PatientNo: [''],
      Name: [''],
      Birthday: [null],
      PhoneNo: [''],
      Mobile: [''],
      CId: [''],
      Address: [''],
      PaySysA: ['']
    });

  }
  // query click
  editOrNewAddfcous = false;
  onQueryClick() {
    this.pageSkipNo = 0;
    this.refreshList(true);
  }

  // queryClear click
  onBtnQueryClearClick() {
    this.pageSkipNo = 0;
    this.createQueryFormGroup();
    this.listItems = this.parseResult({ Data:{data:[],total:0,totalPages:0 } ,RowNumber:0});
    this.setGridView();
    const emptyId = IdHelper.IdEmpty;
    this.selectedItems[0] = emptyId;
    this.editComponent?.editControlButtons.toNoneMode();
    this.editComponent?.cancel();
    //this.refreshList(true);
  }

  // add click
  onBtnAddClick() {
    this.editComponent.add(); // 呼叫edit元件進行新增作業
    this.selectedItems[0] = 0; // 清空選擇
  }
  //#endregion

  //#region 清單

  // 更新清單
  // isFirstEnter: 是否為第一次進入
  refreshList(isNewQuery?: boolean) {
    if (isNewQuery) {
      this.pageSkipNo = 0;
    }
    let isfocus = false;
    let getNew = 0;
    let savepatientId = 0;
    if(this.editComponent?.savingPId > 0 && this.mode == PatientListMode.list)
    {

      isfocus = true;
      savepatientId = this.editComponent.savingPId;
      this.editComponent.savingPId = 0;

      if (this.editComponent.editOrNewAddfcous)
      {
        this.createQueryFormGroup();
        this.editComponent.editOrNewAddfcous = false;
      }
    }

    // 取得參數資料
    let query = this.expandQuery(this.makeQueryConditions());
    query.PatientId = savepatientId;
    this.patientApi.GetAll(query).then(
      (result: PatientResult) => {
        this.listItems = this.parseResult(result);
        //this.setGridView();
        if (this.listItems != undefined) {
          if (this.listItems.length > 0) {
            // 表格分頁
            this.setGridView();
            if (this.mode == PatientListMode.list && this.listItems.length > 0 && isNewQuery) {
              const firstRowId = this.listItems[0].Id;
              setTimeout(() => {
                this.selectedItems[0] = firstRowId;
                this.selectedPatientId = firstRowId;
                this.editComponent.view(firstRowId);

              }, 1);
            }
            if (isfocus) {
              getNew = result.RowNumber - (Math.floor(result.RowNumber / this.pageSize)) * this.pageSize;
              const firstRowId = this.listItems[getNew].Id;
              this.pageSkipNo = result.RowNumber;
              // console.log('firstRowId',firstRowId);
              setTimeout(() => {
                this.saveselectid = getNew;
                this.selectedItems[getNew] = firstRowId;
                this.selectedPatientId = firstRowId;
                this.editComponent.view(firstRowId);
              }, 1);
            }
            // 如果查詢到只有一筆，變觸發已選擇結果
            if (this.pageSkipNo == 0 && this.listItems.length == 1 && this.mode === PatientListMode.search && this.emitResultWhenOnlyOne) {
              this.onEmitChose.emit(this.listItems[0]);
            }
            if (this.listItems.length === 0) {
              const emptyId = IdHelper.IdEmpty;
              this.selectedItems[0] = emptyId;
              this.editComponent?.editControlButtons.toNoneMode();
              this.editComponent?.cancel();
            }

          } else {
            this.setGridView();
            var ret = new QueryPatient();
            ret.Found = true;
            ret.PatientName = this.queryFormGroup.controls['Name'].value;
            ret.PatientCId = this.queryFormGroup.controls['CId'].value;

            this.onEmitQueryNotFound.emit(ret);
          }
        }
      })
      .catch(error => {
        // console.log(error)
        this.notification.showGetDataFailed('patient-list:refreshList');
      }); // 失敗訊息);
  }
  toChildData:any;
  getQueryForSave(){
    const query =  this.expandQuery(this.makeQueryConditions())
    this.toChildData =query;

  }
  // 產生查詢條件
  makeQueryConditions() {
    const query = {
      // 時間轉換為ISO格式再回傳server
      Birthday: this.queryFormGroup.controls['Birthday'].value,
      PatientNo: this.queryFormGroup.controls['PatientNo'].value,
      Name: this.queryFormGroup.controls['Name'].value,
      PhoneNo: this.queryFormGroup.controls['PhoneNo'].value,
      Mobile: this.queryFormGroup.controls['Mobile'].value,
      CId: this.queryFormGroup.controls['CId'].value,
      Address: this.queryFormGroup.controls['Address'].value,
      SearchType:this.dateSearch,
      PatientId :0,
    };
    return query;
  }

  handleListData(data: PatientListDto[]) {
    if (data == null || data.length === 0) {
      // 通知父元件沒此病患，並把填入的資料傳出去
      const pat = new PatientListDto();
      pat.Id = 0;
      pat.Name = this.queryFormGroup.controls['Name'].value;
      pat.Birthday = this.queryFormGroup.controls['Birthday'].value;
      pat.PhoneNo = this.queryFormGroup.controls['PhoneNo'].value;
      pat.Mobile = this.queryFormGroup.controls['Mobile'].value;
      pat.CId = this.queryFormGroup.controls['CId'].value;
      this.onEmitResult.emit(pat);
      return data;
    }
    return data;
  }
  //#endregion --------------------

  //#region 表格 --------------------
  // 表格分頁
  setGridView() {
    this.gridView = {
      data: this.pageData.data,
      total: this.pageData.total
    };
  }
  // 分頁切換事件
  pageChange(page: number): void {
    this.pageSkipNo = (page - 1) * this.pageSize;
    this.refreshList(); // 更新清單，因為server端資料可能異動
  }
  // 處理grid cell點擊，預設view
  public gridCellClickHandler({ isEdited, dataItem, rowIndex }): void {
    // for 新增後的row 取消 highlight(黃底);
    if (this.saveselectid > 0) {
      this.selectedItems[this.saveselectid] = IdHelper.IdEmpty;
      this.saveselectid = 0;
    }
    this.selectPatient = dataItem

    // rowIndex
    if (this.mode === PatientListMode.list) {
      // console.log('gridCellClickHandler1222');
      this.editComponent.view(dataItem.Id);
    }
  }
  // 處理grid row點擊執行，預設view
  public rowExcuteHandler(event: any): void {
    if (this.mode === PatientListMode.search) {
      this.onEmitChose.emit(this.selectPatient);
    }
  }
  // highlight
  setGridHighlight(data: PatientListDto) {
    this.selectedItems[0] = data.Id;
  }
  // edit click
  onEditClick(evt: Event, data: PatientListDto) {
    evt.stopPropagation();
    evt.preventDefault();
    this.setGridHighlight(data);
    this.editComponent.edit(data.Id); // 呼叫edit元件進行編輯作業
  }

  //#region 進入註冊頁面 --------------------
  // btnRegister
  onBtnRegisterClick(data: PatientsDto) {

    this.selectedPatientId = data.Id;
    this.registerService.Regist(this.selectedPatientId);
    // 判斷今日是否已有掛號記錄
    //this.findHistory(data.Id);
  }

  CIdInput(evt: InputEvent) {
    if (evt.target instanceof HTMLInputElement) {
      var text = evt.target.value;
      this.queryFormGroup.controls['CId'].setValue(text.toUpperCase());
    }
  }

  NumberInput(evt: InputEvent, ctrl: string) {
    var v: string = this.queryFormGroup.controls[ctrl].value;
    if (evt.target instanceof HTMLInputElement) {
      var text = evt.data;
      if (text) {
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        if (numbers.findIndex(x => x == text) < 0) {
          this.queryFormGroup.controls[ctrl].setValue(v.substring(0, v.length - 1));
        }
      } else {
        if (!this.isNumber(v)) {
          this.notification.showInfo('輸入的資料不符合格式規則！');
          this.queryFormGroup.controls[ctrl].setValue('');
        }
      }
    }
  }

  isNumber(n: string | number): boolean {
    if (n == undefined) {
      return false;
    } else {
      var startWith = n.toString().substring(0, 1);
      if (startWith == '-' || startWith == '+') {
        return false;
      } else {
        return ((n != null) && (n !== '') && !isNaN(Number(n.toString())));
      }
    }
  }

  // // 開啟 掛號詢問視窗
  // openRegDialog() {
  //   this.isRegConfirmDialogOpened = true;
  // }

  // closeRegDialog() {
  //   this.isRegConfirmDialogOpened = false;
  // }

  // // RegConfirmDialog control
  // onRegConfirmDialogOpened(status: string) {
  //   if (status === 'new') {

  //     this.closeRegDialog();
  //     this.gotoRegPageForAdd(this.selectedPatientId);

  //   } else if (status === 'close') {

  //     this.closeRegDialog();
  //   }

  // }

  // // 查詢病患的掛號紀錄
  // // 無=> 進入掛號頁面，有=> 跳出界面詢問，是否進行掛號
  // findHistory(patientId: number) {
  //   this.patientApi.GetRegister(DateHelper.today,patientId).then(
  //     (data: RegisterEditDto[]) => {
  //       this.handleHistory(patientId, data);
  //     }
  //   ).catch(error => this.notification.showGetDataFailed());
  // }

  // // 處理找到的病患掛號記錄
  // handleHistory(patientId: number, records: RegisterEditDto[]) {
  //   this.historyRecords = records;

  //   this.historyRecords.forEach(element => {
  //     element.RegDate = new Date(element.RegDate);
  //   });
  //   // 若無任何掛號記錄就直接新增
  //   if (this.historyRecords == null || this.historyRecords.length === 0) {

  //     // 前往掛號頁面
  //     this.gotoRegPageForAdd(patientId);

  //   } else {
  //     // 若已有掛號紀錄，開啟詢問視窗(是否繼續掛號)
  //     this.openRegDialog();
  //   }
  // }

  // // 前往掛號頁面
  // gotoRegPageForAdd(patientId: number) {

  //   // 路徑
  //   const path = this.routeInfo.makeFullUrl(this.routeRegister);
  //   this.router.navigate([path, { patientId: patientId }]);

  // }

  //#endregion --------------------

  //#region 子元件區 --------------------
  // 接收Edit Component的存檔異動
  onEditUpdated() {
    this.refreshList(false); // edit存檔後，清單也更新，最新的結果才會反映出來
  }

  // 子元件狀態
  onChildEnterEdit() {
    this.isChildInEdit = true;
  }

  onChildEnterView() {
    this.isChildInEdit = false;
  }

  @HostListener('window:resize', ['$event'])
  getColsByScreenSize(event?): number {
    this.screenHeight = window.screen.height;
    this.screenWidth = window.screen.width;
    this.devicePixelRatio = window.devicePixelRatio;

    if (this.screenWidth < 1550 && this.devicePixelRatio > 1.0) {
      this.tileColSpan = "2";
      return 3;
    }
    else {
      this.tileColSpan = "1";
      return 4;
    }
  }

  //#endregion --------------------

  //#region 分頁功能
  expandQuery(query) {
    query.pageSkip = this.pageSkipNo;
    query.pageSize = this.pageSize;
    return query;
  }
  parseResult(result: PatientResult): PatientListDto[] {
    if (result && result.Data && result.Data.data) {
      this.pageData = result.Data;
      return result.Data.data;
    } else {
      return [];
    }
  }
  //#endregion 分頁功能

  //#region search模式，選擇病患事件 --------------------
  // 只有mode===search才有用途
  patientSelectChange(event: any) {
    if (this.mode == PatientListMode.search) {
      if (event.selectedRows.length == 0) {
        this.onEmitResult.emit(null);
      } else {
        // get selected item
        const selectedItem = event.selectedRows[0].dataItem;
        // emit to parent
        this.onEmitResult.emit(selectedItem);
      }
    }
  }
  //#endregion --------------------

  /** 基資姓名英文 */
  handleInputChange() {
    const englishPattern = /^[A-Za-z\s]+$/;
    if (this.inputValue.match(englishPattern)) {
      this.inputValue = this.inputValue.toUpperCase();
    }
  }


  ngOnDestroy() {
  }
}
