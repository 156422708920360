import { Entity } from 'src/app/shared/models/entity';

export class NhiRecord extends Entity {

    ClinicId: number;
    ClinicCode:string;
    PatientId: number;
    PatientNo:string;
    /** 重新讀取日期時間*/
    AgainDate: Date;
    /** 就醫資料*/    
    MedInfo: string;
    /**器官捐贈 */    
    OrganDon: string;
    
    /** 重大傷病*/
    
    MajorInj: string;
    
    /**門診醫令 */ 
    
    OPTOrder: string;
    /** 長期醫令*/
    LongOrder: string;
    /** 重要醫令*/
    ImpOrder: string;
    /**過敏藥品*/
    AllergyMed: string;
    /** 產前檢查 */
    PrenatalCare: string;
    /**預防保健 */
    HealthCare: string;
    /** 預防接種 */    
    Vaccination: string;
    /** 就醫費用*/
    MedFee: string;
    /** 主次診斷 */
    Diagno: string; 
}