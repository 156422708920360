<div style="background-image: url(../../../../../assets/images/bg/background.png) !important;background-repeat: round;
padding: 30px 20px;" *ngIf="formType==1" class="a4-print-style" #reportContainer>   <!-- 1:展望標準藥袋 2:一藥一袋型式 -->
    <div style="-webkit-text-size-adjust: none; font-family: '{{ fontType }}'; font-weight: bold; line-height: 1.2;max-height: 100%;" *ngIf="hist && clinic">
        <div [style]="startBlankHeightStyle"></div>
        <div *ngIf="!hist.DontPrintDrugBagTitle" style="height: 134px;padding: 0px 110px 0px 110px;font-weight: bold;text-align: center;">
            <div [ngStyle]="{'font-size': titleFont + 'px'}" style="margin-right: 90px;padding-top: 62px;text-align: center;">
                {{clinic.Name}}
            </div>
            <div [ngStyle]="{'font-size': addressFont + 'px'}" style="margin-left: 4px;text-wrap: nowrap;text-align: left;">
                <span style="text-align: left; margin-left: 11px;margin-right: 11px;">{{'電話:' + (clinic.Phone ? clinic.Phone : '')}}</span>
                <span style="text-align: left;">{{clinicAddress ? clinicAddress : ''}}</span>
            </div>
        </div>
        <div [ngStyle]="{'font-size': patientFont + 'px', 'padding-top': patPaddingTop + 'px', 'padding-bottom': patPaddingBottom + 'px'}" style="font-weight: bolder;text-wrap: nowrap;">
            <div style="display: flex;align-items: center;height: 32px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{hist.PatientNo}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.PatientName}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.SexName}}
                        {{calculateAge(hist.Birthday)}}{{printPatientBirthday ? '(' + formatRocDate(hist.Birthday, false) + ')' : ''}}
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;height: 32px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{formatRocDate(hist.RegDate, true)}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.ICodeName}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.MedDeptName}}
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;height: 32px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{hist.TotalBag}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.BagNo}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.Pharmacist}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="printNextRegDate" [ngStyle]="{'font-size': addressFont + 'px'}" style="padding-top: 16px;padding-left: 17px; font-weight: lighter;">
            <div style="font-weight: bold;padding-left: 5px;">
                <span style="display: inline-block;text-align-last:justify;">【建議下次回診日: {{reConsulateDate}}】</span>
            </div>
        </div>

        <div *ngIf="printNextTakeDrugDate && printNextPickupDrugDate" [ngStyle]="{'font-size': addressFont + 'px'}" style="padding-top: 16px;padding-left: 17px; font-weight: lighter;">
            <div style="font-weight: bold;padding-left: 5px;">
                <span style="display: inline-block;text-align-last:justify;">【慢箋下次領藥日期: {{nextDrugDateStart}}～{{nextDrugDateEnd}}】</span>
            </div>
        </div>
        <div style="padding-top: 10px;padding-left: 12px; font-weight: lighter;" [ngStyle]="{'font-size': addressFont + 'px'}">
            <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                <span style="display: inline-block;width: 64px;text-align-last:justify;">內含</span>:
                <span style="width: 34px;display: inline-block; text-align: center;">{{drugCount}}</span>種藥品
            </div>
            <ng-container *ngFor="let hrx of hrxes">
                <div [ngStyle]="{'font-size': drugFont + 'px'}" style="display: flex; text-align: left;margin-top: 1px; margin-left: 6px;height: 20px; font-weight: bold;">
                    <div style="width: 217px;overflow: hidden; white-space: nowrap;flex-shrink: 0;">
                        {{hrx.Name}}
                    </div>
                    <div style="width: 130px;">
                        {{hrx.WayName}} {{hrx.FreqName}}
                    </div>
                    <div style="width: 71px; text-align: right;min-width: 50px;">
                        {{hrx.Days}}天,共
                    </div>
                    <div style="width: 36px; text-align: right;">
                        {{hrx.TotalDose}}.
                    </div>
                    <div style="width: 32px; text-align: left;margin-right: 5px;">
                         {{hrx.TotalUnit}}
                    </div>
                    <div style="min-width: 70px;width: 83px;">
                        每次 {{hrx.Dose}}.
                    </div>
                    <div style="min-width: 40px;flex-grow: 1; ">
                        {{hrx.Unit}} .
                    </div>
                </div>
                <!-- <tr
                    style="text-align: left; font-size: 16px;">
                    <td colspan="4">外觀:</td>
                </tr> -->
                <div *ngIf="hrx.Warning" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △警語:{{hrx.Warning}}
                </div>
                <div *ngIf="hrx.Indication" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △適應症:{{hrx.Indication}}
                </div>
                <div *ngIf="hrx.SideEffect" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △副作用:{{hrx.SideEffect}}
                </div>
            </ng-container>
            <div *ngIf="hist.DoctorName" [ngStyle]="{'font-size': patientFont + 'px'}" style="font-weight: bold; padding-left: 9px;">
                <span style="display: inline-block;width: 71px;text-align-last:justify;">醫師</span>:
                <span >{{hist.DoctorName}}</span>
            </div>
        </div>

    </div>
</div>

<div style="background-image: url(../../../../../assets/images/bg/background.png) !important;background-repeat: round;
padding: 30px 20px;" *ngIf="formType==2" class="a4-print-style" #reportContainer>   <!-- 1:展望標準藥袋 2:一藥一袋型式 -->
    <div style="-webkit-text-size-adjust: none; font-family: '{{ fontType }}'; font-weight: bold; line-height: 1.2;height: 100%;" *ngIf="hist && clinic">
        <div [style]="startBlankHeightStyle"></div>
        <div *ngIf="!hist.DontPrintDrugBagTitle" style="height: 134px;padding: 0px 110px 0px 110px;font-weight: bold;text-align: center;">
            <div [ngStyle]="{'font-size': titleFont + 'px'}" style="margin-right: 90px;padding-top: 62px;text-align: center;">
                {{clinic.Name}}
            </div>
            <div [ngStyle]="{'font-size': addressFont + 'px'}" style="margin-left: 4px;text-wrap: nowrap;text-align: left;">
                <span style="text-align: left;margin-left: 11px;margin-right: 11px;">{{'電話:' + (clinic.Phone ? clinic.Phone : '')}}</span>
                <span style="text-align: left;">{{clinicAddress ? clinicAddress : ''}}</span>
            </div>
        </div>
        <div [ngStyle]="{'font-size': patientFont + 'px', 'padding-top': patPaddingTop + 'px', 'padding-bottom': patPaddingBottom + 'px'}" style="font-weight: bold;text-wrap: nowrap;">
            <div style="display: flex;align-items: center;height: 32px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{hist.PatientNo}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.PatientName}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.SexName}}
                        {{calculateAge(hist.Birthday)}}{{printPatientBirthday ? '(' + formatRocDate(hist.Birthday, false) + ')' : ''}}
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;height: 35px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{formatRocDate(hist.RegDate, true)}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.ICodeName}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.MedDeptName}}
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;height: 35px;width: 100%;">
                <div style="width: 36%; text-align: left;">
                    <div style="padding-left: 122px;text-align: left;">
                        {{hist.TotalBag}}
                    </div>
                </div>
                <div style="width: 31%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.BagNo}}
                    </div>
                </div>
                <div style="width: 33%; text-align: left;">
                    <div style="padding-left: 80px;text-align: left;">
                        {{hist.Pharmacist}}
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="printNextRegDate" [ngStyle]="{'font-size': addressFont + 'px'}" style="padding-top: 16px;padding-left: 17px; font-weight: lighter;">
            <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                <span style="display: inline-block;text-align-last:justify;">【建議下次回診日: {{reConsulateDate}}】</span>
            </div>
        </div>

        <div *ngIf="printNextTakeDrugDate && printNextPickupDrugDate" [ngStyle]="{'font-size': addressFont + 'px'}" style="padding-top: 16px;padding-left: 17px; font-weight: lighter;">
            <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                <span style="display: inline-block;text-align-last:justify;">【慢箋下次領藥日期: {{nextDrugDateStart}}～{{nextDrugDateEnd}}】</span>
            </div>
        </div>
        <div [ngStyle]="{'font-size': addressFont + 'px'}" style="padding-top: 16px;padding-left: 17px;font-weight: lighter;">
            <ng-container *ngFor="let hrx of hrxes">
                <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                    <span style="display: inline-block;width: 64px;text-align-last:justify;">內含</span>:
                    <span style="width: 34px;display: inline-block; text-align: center;">{{drugCount}}</span>種藥品　共　{{hrx.Days}}　日份
                </div>
                <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                    <span style="display: inline-block;width: 64px;text-align-last:justify;">用法</span>:
                    <span style="display: inline-block; text-align: center;">{{hrx.WayName}}--{{hrx.FreqName}},每次　{{hrx.Dose}}.　{{hrx.Unit}}</span>
                </div>
                <div style="padding-bottom: 12px; font-weight: bold;padding-left: 5px;">
                    <span style="display: inline-block; text-align: center;">{{hrx.Name}}　　　　共</span>:　{{hrx.TotalDose}}.　{{hrx.TotalUnit}}
                </div>
                <div [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    {{hrx.IIName}}
                </div>
                <div *ngIf="hrx.Warning" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △警語:{{hrx.Warning}}
                </div>
                <div *ngIf="hrx.Indication" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △適應症:{{hrx.Indication}}
                </div>
                <div *ngIf="hrx.SideEffect" [ngStyle]="{'font-size': drugFont + 'px'}" style="padding-left: 30px;margin-top: 1px;letter-spacing: 0.1px; font-weight: bold;">
                    △副作用:{{hrx.SideEffect}}
                </div>
            </ng-container>
            <div *ngIf="hist.DoctorName" [ngStyle]="{'font-size': patientFont + 'px'}" style="font-weight: bold;padding-left: 9px;">
                <span style="display: inline-block;width: 71px;text-align-last:justify;">醫師</span>:
                <span >{{hist.DoctorName}}</span>
            </div>
        </div>

    </div>
</div>
