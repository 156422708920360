
export class KidneyDialysisDto {
    HCT:string = '';
    Time: string = '00:00:00';
    BUN: string = '';
    Cr : string = '';
    AK : string = '';
    Hb : string = '';
    EPO:  string = '';
}

export class JailInfo {
    JailHouse : string = '';
    JailAddressCode: string = null;
    PensonalCode : string = '';
}