import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientListComponent } from './list/patients-list.component';
import { PatientEditComponent } from './edit/patients-edit.component';
import { ContractListComponent } from './contract/list/list.component';
import { ExpiringListComponent } from './contract/expiring-contract/list/list.component';
import { PatientHistListComponent } from './patient-hist/list/list.component';

const routes: Routes = [
  {
    path: '',
    component: PatientListComponent,
  },
  { path: 'edit', component: PatientEditComponent },
  {
    path: 'contract',
    component: ContractListComponent,
  },
  {
    path: 'expiring-contract',
    component: ExpiringListComponent,
  },
  {
    path: 'patient-hist',
    component: PatientHistListComponent,
    data:{from:'hist'}
  },
  {
    path: 'patient-hist-reg',
    component: PatientHistListComponent,
    data:{from:'reg'}
  },

  // {
  //   path: 'patient-hist',
  //   loadChildren: () => import('./patient-hist/patient-hist.module').then(m => m.PatientHistModule),
  //   data: { title: 'Patient History' }
  // },
  {
    path: 'patient-hist-personal',
    loadChildren: () => import('./patient-hist/patient-hist.module').then(m => m.PatientHistModule),
    data: { title: 'Patient History' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientsRoutingModule { }
