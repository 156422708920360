<div class="container-fluid">
  <div class="row">
    <div class="col-sm-8 no-padding">
      <div class="card" style="min-height: 100%;">
        <div class="card-header">
          <p style="color: red;">基本資料，不可異動</p>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-4">
              <label class="mb-0">病歷號</label>
              <input [value]="patientView.PatientNo" disabled class="form-control" />
            </div>
            <div class="col-sm-3">
              <label class="mb-0">姓名</label>
              <input [value]="patientView.PatientName" disabled class="form-control" />
            </div>
            <div class="col-sm-3">
              <label class="mb-0">生日</label>
              <input [value]="patientView.Birthday | formatDate:''" disabled class="form-control" />
            </div>
            <div class="col-sm-2">
              <label class="mb-0">性別</label>
              <input [value]="patientView.SexName" disabled class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4">
              <label class="mb-0">身分證</label>
              <input [value]="patientView.CId" disabled class="form-control" />
            </div>
            <div class="col-sm-3">
              <label class="mb-0">電話</label>
              <input [value]="patientView.Phone" disabled class="form-control" />
            </div>
            <div class="col-sm-3">
              <label class="mb-0">手機</label>
              <input [value]="patientView.CellPhone" disabled class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">地址</label>
              <input [value]="patientView.Address" disabled class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-4 no-padding">
      <div class="card" style="min-height: 100%;">
        <div class="card-header">
          <p style="color: red;">開單明細</p>
        </div>

        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">執行日期</label>
              <input [value]="triggerDate | formatDate:''" disabled class="form-control" />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <label class="mb-0">開單號</label>
              <input [value]="accessionNumber" disabled class="form-control" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
        <mat-tab *ngFor="let group of regCodeGroups" [label]="group.key">
          <app-pacscreate-order-tab-content [selectRegCodeId]="selectedRegCodes" [options]="group.items"
            (onEmitOptionChecked)="onCheckboxRegCodeChecked($event)"></app-pacscreate-order-tab-content>
        </mat-tab>
      </mat-tab-group>
    </div>

  </div>
  <div class="row">
    <div class="col-sm-1">
      <button type="button" class="btn btn-primary" (click)="onBtnSendClick()">送出</button>
    </div>
    <div class="col-sm-2">
      <mat-checkbox [checked]="notInputRx" (change)="onCheckBoxNotInputRxChecked($event)">不加醫令</mat-checkbox>
    </div>
    <div class="col-sm-7">
    </div>
    <div class="col-sm-2 right">
      <button type="button" class="btn btn-danger" (click)="onBtnCloseClick()">退出</button>
    </div>
  </div>

</div>
