import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { CnhiEiiTestComponent } from 'src/app/card-reader/cnhi-eii-test/cnhi-eii-test.component';
import { LabApi } from 'src/app/services/api-service/labs/lab-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { UserCache } from 'src/app/services/user-cache';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { IcHelper } from 'src/app/shared/helpers/ic-helper';
import { verifyTaiwanIdIntermediateStringAndMsg } from 'src/app/shared/utilities';
import { MainLayoutService } from '../services/main-layout.service';

@Component({
  selector: 'app-debug-tool',
  templateUrl: './debug-tool.component.html',
  styleUrls: ['./debug-tool.component.css']
})
export class DebugToolComponent implements OnInit {

  constructor(
    private hcrService: HcrService,
    private confirmService: UserConfirmService,
    private notification: EasyNotificationService,
    private matDialog: MatDialog,
    private labApi:LabApi,
    private mainlayout:MainLayoutService
  ) { }

  ngOnInit(): void {

    this.useWebApi = HcrService.useWebApi;
    this.clinicNhiCode = UserCache.getLoginUser().Clinic.NHICode;
    this.companyCode = UserCache.getLoginUser().CompanyCode;
  }

  //#region 虛擬健保卡
  vhcStatus: string = '';
  async onQRCodeScanResult(code: string, dialog: VisDialogComponent) {
    dialog.show = false;
    var ret = await this.hcrService.VHCApi.uploadqrcode(code)
    if (ret.StatusCode != 0) {
      this.notification.showError(ret.ErrorMsg);
    } else {
      this.notification.showSuccess(ret.ErrorMsg);
    }
    var cardStatus = await this.hcrService.VHCApi.GetCardStatusHC();
    this.vhcStatus = cardStatus.Data;
    console.log(code)
  }
  async clearVHC() {
    var ret = await this.hcrService.VHCApi.cleanQRCode()
    if (ret.StatusCode != 0) {
      this.notification.showError(ret.ErrorMsg);
    } else {
      this.notification.showSuccess(ret.ErrorMsg);
    }
  }
  async VHCStatus() {
    var ret = await this.hcrService.VHCApi.GetCardStatusHC()
    this.confirmService.showAlert("結果", JSON.stringify(ret));
  }
  async VHCBasic() {
    var ret = await this.hcrService.VHCApi.GetBasicData()
    this.confirmService.showAlert("結果", JSON.stringify(ret));
  }
  async VHCRegBasic() {
    var ret = await this.hcrService.VHCApi.GetRegisterBasic()
    this.confirmService.showAlert("結果", JSON.stringify(ret));
  }
  async VHCTreat() {
    var ret = await this.hcrService.VHCApi.GetTreatmentNoNeedHPC()
    console.log(ret);
    var ret2 = await this.hcrService.VHCApi.QuickInsurence('')
    console.log(ret2);
    this.confirmService.showAlert("結果", JSON.stringify(ret) + '\n\n' + JSON.stringify(ret2));
  }
  // 需醫師卡
  async VHCTreat2() {
    var ret = await this.hcrService.VHCApi.GetTreatmentNeedHPC()
    console.log(ret);
    var ret2 = await this.hcrService.VHCApi.ReadPrescription();
    console.log(ret2);
    this.confirmService.showAlert("結果", JSON.stringify(ret) + '\n\n' + JSON.stringify(ret2));
  }
  async VHCIsq() {
    var ret = await this.hcrService.VHCApi.GetLastSeqNum()
    console.log(ret);
    this.confirmService.showAlert("結果", JSON.stringify(ret));
  }
  //#endregion
  //#region api測試

  cnhieiiTest(evt) {
    var diag = this.matDialog.open(CnhiEiiTestComponent, {
      disableClose: true,
      panelClass: 'CnhiEiiTestWidth',
      width: '95%',
      maxWidth: '95vw'
    });
    diag.componentInstance.onClose.subscribe(x => diag.close());
    //this.cnhi_eii.GetHospType
  }
  async dayliUploadTest(evt) {
    var file = await this.hcrService.SelectFiles()
    var ret = await this.hcrService.VisionApi.UploadData(file.FullName, "60");
    if (ret.StatusCode == 0) {
      if (ret.Data) {
        await this.confirmService.showAlert('執行成功',
          '醫事服務機構代碼: \t' + ret.Data.HospId + '\n' +
          '上傳日期時間: \t' + ret.Data.UploadDateTime + '\n' +
          '接收日期時間: \t' + ret.Data.RecieveDateTime + '\n' +
          'DC接收檔案筆數: \t' + ret.Data.RecieveNumber + '\n' +
          'DC接收檔案大小: \t' + ret.Data.RecieveSize + ' (Byte)\n' +
          '安全模組代碼: \t' + ret.Data.SAMId + '\n', { 'fitContent': true }
        );
      } else {
        this.notification.showSuccess('執行成功');
      }
    } else {
      this.notification.showError(ret.ErrorMsg);
    }
  }
  //#endregion
  //#region 就醫識別碼 webapi切換
  useWebApi: boolean;
  useWebApiChecked(evt: MatCheckboxChange) {
    HcrService.useWebApi = evt.checked;
  }
  //#endregion

  //#region  就醫識別碼模擬
  treat_hinno: string = '';
  treat_dt: Date = new Date();
  treat_tm: string = '';
  treat_cid: string = '';
  treat_no: string = '';
  async TestITreat() {
    //var a = new VisionHisDemo(null);
    //3532018235
    //HTU4AY G21LNZC 3AF1D3W
    //this.getTreatId('7431110698','G221489822',new Date(2023,7,21,15,11,44))
    this.treat_no = '';
    var dt = new Date(this.treat_dt.toLocaleDateString() + ' ' + this.treat_tm)
    this.treat_no = this.getTreatId(this.treat_hinno, this.treat_cid, dt)
    console.log('目標：HTU4AYG21LNZC3AF1D3W')

    //this.getTreatInfo(this.treat_no);
  }
  ParseTreat() {
    this.getTreatInfo(this.treat_no);
  }
  ClearTreat() {
    this.treat_hinno = '';
    this.treat_dt = new Date();
    this.treat_tm = '';
    this.treat_cid = '';
    this.treat_no = '';
  }
  private getITreatString(n: number) {
    var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    var calcTemp = Math.floor(n);
    var treatIdBuild = ''
    do {
      var a = calcTemp % char.length;
      treatIdBuild = char[a] + treatIdBuild;
      calcTemp = Math.floor(calcTemp / char.length)
    }
    while (calcTemp > 0)
    return treatIdBuild;
  }
  private getITreatNum(s: string) {
    var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
    var n = 0;
    for (let c of s) {
      var i = char.indexOf(c);
      n *= char.length
      n += i;
    }

    return n;
  }
  /** 第一欄請自行複製6碼 */
  public getTreatId(nhiCode: string, cid: string, dt: Date) {
    //機構代馬 (6)
    var c1 = nhiCode;// this.getITreatString(parseInt(nhiCode));
    // 身分證 前2馬保留
    var cCid1 = cid.substring(0, 2)
    // 身分證 3-9碼 轉成5碼 (身分證檢查碼跳過)
    var code = cid.substring(2, 9)
    var cCid2 = this.getITreatString(parseInt(code));
    // 年份 2碼
    var cYr = this.getITreatString(dt.getFullYear() - 1911);
    // 時間5碼
    var secDiff = (dt.getTime() - new Date(dt.getFullYear(), 0, 1, 0, 0, 0).getTime()) / 1000
    var cDt = this.getITreatString(secDiff).padStart(5, '0');

    console.log('轉換：', nhiCode, cid, dt)
    console.log('結果：' + c1 + cCid1 + cCid2 + cYr + cDt);
    return c1 + cCid1 + cCid2 + cYr + cDt;
  }
  public getTreatInfo(treatNo: string) {
    //機構代馬 (6)
    var c1 = treatNo.substring(0, 6);// this.getITreatString(parseInt(nhiCode));
    // 身分證 前2馬保留
    var cCid1 = treatNo.substring(6, 8)
    // 身分證 3-9碼 轉成5碼 (身分證檢查碼跳過)
    var code = treatNo.substring(8, 13)
    var cCid2 = this.getITreatNum(code);
    var cid = `${cCid1}${cCid2}0`;
    var cCid3 = verifyTaiwanIdIntermediateStringAndMsg(cid, true).validateNum; // 身分證驗證碼
    var cid = `${cCid1}${cCid2}${cCid3}`;
    // 年份 2碼
    var cYr = this.getITreatNum(treatNo.substring(13, 15));
    // 時間5碼
    var cSec = this.getITreatNum(treatNo.substring(15, 20))
    var dt = new Date(new Date(cYr + 1911, 0, 1, 0, 0, 0).getTime() + cSec * 1000);

    this.treat_hinno = c1;
    this.treat_cid = cid;
    this.treat_dt = dt;
    this.treat_tm = DateHelper.getTimeString(dt, ':')
    console.log('結果：' + cid + ', ' + dt);

  }
  //#endregion

  //#region 檢驗
  async CreateFakeResult(){
    try{
      await this.labApi.CreateFakeResult();
      this.notification.showSuccess('造假完成');
    }catch(e){
      this.notification.showError(e);
    }
  }
  clinicNhiCode:string;
  companyCode:string;
  labImportSt:Date = new Date();
  labImportEd:Date = new Date();
  async importLabFrom(){
    try{
      this.mainlayout.showLoader()
      var ret = await this.labApi.ImportLabFrom(this.companyCode,this.clinicNhiCode,this.labImportSt,this.labImportEd)
      this.mainlayout.hideLoader()
      await this.confirmService.showAlert('匯入結果', `新取得 ${ret.ImportCount} 筆匯入資料。\n更新 ${ret.LabForms.length} 筆檢驗報告。`)
    }catch(e){
      this.notification.showError(e);
      this.mainlayout.hideLoader()
    }
    
  }
  //#endregion

}
