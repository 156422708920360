<form [formGroup]="editFG" *ngIf="editFG" class="vis-form	vis-patients-edit overflow-x-auto  position-relative" style="height:inherit;overflow-y: scroll;">
  <!-- ./ chi-top -->
  <div formArrayName="orders" *ngIf="orderArray" [class]="layoutCssClass" [id]="areaId" class="position-relative" [ngStyle]="{width:tableWidth}">
    <!-- default grid -->
    <table [class]="'odr-tb'" class="position-relative w-100" [hidden]="isChineseGrid">
      <thead style="z-index: 990;" class="top-0 position-sticky">
        <tr>
            <th class="code text-center" (click)="codeSort()" i18n="@@OrderCode">代碼</th>
            <th class="name text-center" style="width: 80px;" i18n="@@OrderName" [ngStyle]="{minWidth:namePlusWidth}">名稱 {{ showPrice }}</th>
            <th class="user text-center" i18n="@@OrderWay" style="min-width: 100px;max-width: 100px;">執行人員</th>
            <th class="date text-center" i18n="@@OrderWay">開始日</th>
            <th class="time text-center" i18n="@@OrderWay">開始時</th>
            <th class="date text-center" i18n="@@OrderWay">截止日</th>
            <th class="time text-center" i18n="@@OrderWay">截止時</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let odr of orderArray.controls; let idx = index" [formGroupName]="idx">
          <tr>
            <td class="code position-relative">
              <div style="display: flex;" class="extend">
                <input type="tel" formControlName="RxCode" class="odr-cell" [id]="codeIdPrefix + idx" [attr.disabled]="true"
                  tabindex="{{idx * 1000 + 1}}" #codeInput/>
                  
              </div>
            </td>
            <td class="name position-relative">
              <div style="display: flex;" class="extend">
                <input formControlName="ProdName" class="odr-cell" [id]="nameIdPrefix + idx" [attr.disabled]="true"
                title="{{ getOrderName(idx) }}" readonly="readonly" [enterLastColIdx]="enterLastColIdx" (click)="onClickAtName(idx)"
                appOrderGridInput appHistFocus [data]="_tab.rxType"/>
                <button type="button" *ngIf="odr.value.RxCode" style="height: 25px; width: 25px; padding: 0px;" (click)="viewDetail(odr)" [disabled]="checkDisabled()">
                  <app-icon-etc></app-icon-etc>
                </button>
              </div>
            </td>
            
            
            <ng-container *ngIf="odr.value.RxCode; else noRxCode">
              <td class="freq position-relative">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="MedIDName" class="odr-cell" 
                  [attr.disabled]="inputDisabled()" tabindex="{{idx * 1000 + 9}}"
                  (keydown)="onMedIDNameKeyDown($event, idx)" [id]="medIdNamePrefix + idx" autocomplete="off" [value]="odr.MedIDName" (blur)="medIDNameInputBlur($event, idx)"
                  [enterLastColIdx]="enterLastColIdx" appOrderGridInput appHistFocus [data]="editOptions.executor" (input)="medIDNameInputChange($event, idx)" />
                  <button type="button" [matMenuTriggerData]="medIdNamePrefix + idx" 
                    class="title-btn2" style="width: 20px;"
                    [matMenuTriggerFor]="menuMedIdName" aria-label="popup menu" 
                    [disabled]="checkDisabled()||checkNeedExecutor(idx)"
                    #freqSelectBtn>
                      <app-icon-etc></app-icon-etc>
                  </button>
                </div>
                <mat-menu #menuMedIdName="matMenu" (closed)="medIdNameMenuClosed(idx)">
                  <ng-template matMenuContent >
                    <div class="input-menu">
                      <button type="button" *ngFor="let item of getFilterExecutor(idx)"
                        (click)="setMedID(odr, item.value, item.text, medIdNamePrefix + idx)"
                        mat-menu-item>
                      <span class="managedBy-value">{{item.text}}</span>
                    </button>
                    </div>
                  </ng-template>
                </mat-menu>
              </td>
              <!--要調整，根據RxCode的屬性決定Filter editOptions.medUse -->
              <td class="st-date">
                <div style="display: flex;">
                  <app-vis-datepicker formControlName="SDate" class="odr-cell" tabindex="{{idx * 1000 + 10}}"
                  [id]="sdatePrefix + idx" autocomplete="off" [disabled]="checkDisabled()" 
                  appOrderGridInput></app-vis-datepicker>
                </div>
              </td>
              <td class="st-time" i18n="@@STime">
                <app-vis-timepicker formControlName="STime" class="odr-cell" tabindex="{{idx * 1000 + 11}}"
                  [id]="stimePrefix + idx" autocomplete="off" [disabled]="checkDisabled()" 
                  appOrderGridInput></app-vis-timepicker>
              </td>
              <td class="st-date" i18n="@@EDate">
                <div style="display: flex;">
                  <app-vis-datepicker formControlName="EDate" class="odr-cell" tabindex="{{idx * 1000 + 12}}"
                  [id]="edatePrefix + idx" autocomplete="off" [disabled]="checkDisabled()" 
                  appOrderGridInput></app-vis-datepicker>
                </div>
              </td>
              <td class="st-time" i18n="@@ETime">
                <app-vis-timepicker formControlName="ETime" class="odr-cell" tabindex="{{idx * 1000 + 13}}"
                  [id]="etimePrefix + idx" autocomplete="off" [disabled]="checkDisabled()" 
                  appOrderGridInput ></app-vis-timepicker>
              </td>
            </ng-container>

            <ng-template #noRxCode>
              <td class="freq position-relative"></td>
              <td class="st-date"> </td>
              <td class="st-time" i18n="@@STime"> </td>
              <td class="st-date" i18n="@@EDate"> </td>
              <td class="st-time" i18n="@@ETime">  </td>
            </ng-template>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!--formArrayName-->
</form>
<!-- <div style="display: block; height: 100%;background-color: red;" >

</div> -->
<ng-template let-data #rxDetail>
    <table class="rx-detail w-100 mat-table">
      <tbody>
        <tr>
          <th class="mat-header-cell w-40">標準處方代碼</th>
          <td class="mat-cell"><div class="extend-content">{{data.StdCode}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">歸屬</th>
          <td class="mat-cell"><div class="extend-content">{{data.Type}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">原瓶藥水註記</th>
          <td class="mat-cell"><div class="extend-content">{{data.SyrupNote}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">ATC代碼</th>
          <td class="mat-cell"><div class="extend-content">{{data.ATC}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">成分名</th>
          <td class="mat-cell"><div class="extend-content">{{data.IIName}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">價格變更日</th>
          <td class="mat-cell"><div class="extend-content">{{data.PriceChgDate|formatDate:''}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">健保價</th>
          <td class="mat-cell"><div class="extend-content">{{data.IPrice}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">上次健保價</th>
          <td class="mat-cell"><div class="extend-content">{{data.LastIPrice}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">自費價</th>
          <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
        </tr>
        <tr>
          <th class="mat-header-cell">特殊使用條件</th>
          <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
        </tr>
      </tbody>
    </table>
</ng-template>
