<ng-template #checkColumn let-data let-col="col">
    <div *ngIf="_displayType=='icon'" style="width: 100%;text-align: center;">
      <ng-container *ngIf="data[col._field]">
        <mat-icon>checked</mat-icon>
      </ng-container>
    </div>
    <div *ngIf="_displayType=='checkbox'" style="width: 100%;text-align: center;">
        <mat-checkbox (click)="checkClick($event)" (change)="checkChange($event,data)" [disabled]="setDisable"  [(ngModel)]="data[col._field]"></mat-checkbox>
    </div>
    <div *ngIf="_displayType=='radio'" style="height: 1rem;" class="text-center w-4 end-0 start-0 vis-form-field position-relative top-0 bottom-0 m-auto">
      <mat-radio-button (click)="checkClick($event)" (change)="checkChange($event,data)" [disabled]="setDisable"  [(ngModel)]="data[col._field]">
      </mat-radio-button>
    </div>
</ng-template>
