import { Injectable } from '@angular/core';
import { DictionaryCacheService } from './icache-service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { SystemcodeApi } from '../api-service/system-code/systemcode-api';
import { EasyNotificationService } from '../easy-notification.service';

@Injectable({
  providedIn: 'root'
})
export class SystemCodeCachedService extends DictionaryCacheService<string,ValueTextPair[]> {
  constructor(private systemCodeApi: SystemcodeApi, private notifyService: EasyNotificationService) {
    super(notifyService);
   }
  protected notifyMsgOnUpdated: string = '系統代碼已變更…'
  protected notifyMsgOnReload: string = '已重載系統代碼'
  protected cache: Map<string, ValueTextPair[]> = new Map();
  protected async getCacheObject(keys: string[]): Promise<Map<string, ValueTextPair[]>> {
    var o = await this.systemCodeApi.GetCodes(keys);
    var map = new Map();
    for(let k in o){
      map.set(k,o[k])
    }
    return map;
  }
}
