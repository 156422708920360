import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { DateHelper } from "../shared/helpers/date-helper";
import { PatientApi } from "./api-service/patient/patient-api";
import { RegisterEditDto } from "./api-service/register/RegisterEditDto";
import { EasyNotificationService } from "./easy-notification.service";
import { RouteInfoService } from "./route-info.service";
import { UserConfirmService } from "./user-confirm.service";
import { GetAllQuery, RegistEditOpt, RegisterApi, RegisterQueryOptions } from "./api-service/register/register-api";
import { UserCache } from "./user-cache";
import { SystemcodeApi } from "./api-service/system-code/systemcode-api";
import { ClinicDataService } from "./data-service/clinic-data-service";
import { ParameterApi } from "./api-service/parameters/parameter-api";
import { TimeSectionRange } from "./api-service/register/time-section-range";
import { PageData } from "../shared/models/page-data";


export class RegistInfo {
  patientId?: number;
  room?: string;
  abCode?: string;
  isCard?: boolean;
  isVHC?: boolean = false;//虛擬卡
  isFirst?: boolean;
  isOweCard?: boolean;
  isCheckin?: boolean;
  isCancelRegister?: boolean;
  isHasCard?: boolean;
  hasMultipltRegister?: boolean;
  from: 'OPD' | 'REG' | 'PAT' | '';
  regId?: number
  constructor(patientId: number, mode: 'OPD' | 'REG' | 'PAT' | '') {
    this.patientId = patientId;
    this.regId = 0;
    this.from = mode;
  }
  patient(id: number) {
    this.patientId = id;
    return this;
  }
  roomTo(roomCode: string): RegistInfo {
    this.room = roomCode;
    return this;
  }
  reg(regId: number): RegistInfo {
    this.regId = regId;
    return this;
  }
  first(): RegistInfo {
    this.isFirst = true;
    return this;
  }
  selfPay(): RegistInfo {
    this.isCard = false;
    this.isOweCard = false;
    this.abCode = '';
    return this;
  }
  ic(): RegistInfo {
    this.isCard = true;
    this.isOweCard = false;
    this.abCode = '';
    return this;
  }

  vic(): RegistInfo {
    this.isCard = true;
    this.isOweCard = false;
    this.abCode = '';
    this.isVHC = true;
    return this;
  }
  oweCard(): RegistInfo {
    this.isCard = false;
    this.isOweCard = true;
    this.abCode = '';
    return this;
  }
  abnormal(abCode: string): RegistInfo {
    this.isCard = false;
    this.isOweCard = false;
    this.abCode = abCode;
    return this;
  }
  checkIn() {
    this.isCheckin = true;
    return this;
  }
  cancelRegister(hasCard: boolean, hasMultipltRegister: boolean) {
    this.isCancelRegister = true;
    this.isHasCard = hasCard;
    this.hasMultipltRegister = hasMultipltRegister;
    return this;
  }
}
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  //queryOptions: RegisterQueryOptions;

  get loginUserCid() { return UserCache.getLoginUser().CId; }
  get isHomeCare(): boolean { return UserCache.getLoginUser().Clinic.TypeIsHomeCare; }
  routeRegister = '/registers/register';
  /**
   *
   */
  constructor(private patientApi: PatientApi,
    private regApi: RegisterApi,
    private paramApi: ParameterApi,
    private clinicDataService: ClinicDataService,
    private notification: EasyNotificationService,
    private routeInfo: RouteInfoService,
    private router: Router,
    private userConfirm: UserConfirmService) {
  }

  editOptions: RegistEditOpt;
  userRoom: string = '';
  // 候診清單主頁呼叫
  public async Init() {
    // 把有CACHE的先撈一撈
    await this.clinicDataService.getSystemCodes(['B0280', 'C0120', 'C0010', 'C0140', 'C0030', 'C0040', 'N0020', 'C0240'])
    await this.clinicDataService.getEnum(['TimeSec'])
    await this.clinicDataService.getParam("REG001");
    var ret = await this.regApi.GetQueryOptions();
    this.userRoom = ret.room;
  }

  public async GetQueryOptions(): Promise<RegisterQueryOptions> {
    // 這些都有快取
    // 後面的是順便幫別的元件先載入
    var codes = await this.clinicDataService.getSystemCodes(['B0280', 'C0120', 'C0010', 'C0140', 'C0030', 'C0040', 'N0020', 'C0240'])
    var enums = await this.clinicDataService.getEnum(['TimeSec'])
    var reg001 = await this.clinicDataService.getParam("REG001");

    return {
      //clinicId:UserCache.getLoginClinicId().toString(),
      room: this.userRoom,
      timeSection: enums['TimeSec'],
      remark: codes['B0280'] ?? [],  // 用戶可以不設預設值=>會取不到清單資料(undefined)，所以要加判斷
      medRoom: codes['C0120'],
      insurance: codes['C0010'],
      visitType: codes['C0140'],
      registerType: codes['C0030'].map(x => {
        if (x.value == '') {
          x.text = '一般看診'
        }
        return x;
      }),
      status: codes['C0040'],
      paramList: reg001,
      timeSectionRange: new TimeSectionRange(reg001)
    }
  }

  public async getEditOptions() {
    // 呼叫api
    try {
      this.editOptions = await this.regApi.GetEditOptions();
    } catch (ex) {
      this.notification.showError(ex);
    }
    return this.editOptions;
  }

  async Regist(patientId: number) {
    try {
      var data: RegisterEditDto[] = await this.patientApi.GetRegister(DateHelper.today, patientId);
      if (data == null || data.length === 0) {
        // 前往掛號頁面
        this.gotoRegPageForAdd(patientId);
      } else {
        // 若已有掛號紀錄，開啟詢問視窗(是否繼續掛號)
        var ret = await this.userConfirm.showConfirm({
          msg: '此病患今日已有掛號, 你要再次掛號嗎?',
          title: '掛號確認'
        });
        if (ret) {
          this.gotoRegPageForAdd(patientId);
        }
      }
      return true;
    } catch (ex) {
      this.notification.showGetDataFailed();
      return false;
    }
  }

  // 前往掛號頁面
  gotoRegPageForAdd(patientId: number) {
    // 路徑
    const path = this.routeInfo.makeFullUrl(this.routeRegister);
    this.router.navigate([path, { patientId: patientId }]);
  }

  async query(query: GetAllQuery): Promise<PageData> {
    return await this.regApi.GetAll(query);
  }

  goToRegPage(info: RegistInfo) {
    const path = this.routeInfo.makeFullUrl(this.routeRegister);
    // 先等UI更新做完
    setTimeout(() => {
      //轉跳至掛號畫面
      this.router.navigate([path, info]);
    }, 100);
  }
}
