
export class ValidateMsg{
    static QTYEmpty = '醫令 {RxCode} 的 [輸入量] 未填！';
    static DayEmpty = '醫令 {RxCode} 的 [日分] 未填！';
    static FreqEmpty = '醫令 {RxCode} 的 [頻率] 未填！';
    static TotalEmpty = '醫令 {RxCode} 的 [總量] 未填！';
    //static TotalOne = '醫令 {RxCode} 的 [總量] 不為1！';
    static Executor = '醫令 {RxCode} 的 [執行人員] 未填！';
    static ExecDate = '醫令 {RxCode} 的 [起訖日期] 未填！';
    static ExecTime = '醫令 {RxCode} 的 [起訖時間] 未填！';
    static WAY = '醫令 {RxCode} 的 [途徑] 非標準給藥途徑！';
    static WAYEMPTY = '醫令 {RxCode} 的 [途徑] 非標準給藥途徑！';
    static RxBelowtheAge = '醫令 {RxCode} 的 [年齡低於{Limit}歲] 不可使用！';
    static RxHigherthanAge = '醫令 {RxCode} 的 [年齡高於{Limit}歲] 不可使用！';
    static RxNumberWarning = '醫令 {RxCode} 的 [輸入量{Input}] >= 每(次)用藥警告量{Limit}！';
    static RxDayWarning = '醫令 {RxCode} 的 [日量{Input}] >= 每(日)用藥警告量{Limit}！';
    static RxMonthWarning = '醫令 {RxCode} 的 [本月總量{Input}] >= 每(月)用藥警告量{Limit}！';
    static RxWarningDays = '醫令 {RxCode} 的 [總日數{Input}] >= 警告天數{Limit}！';
    static RxIntervalDays = '醫令 {RxCode} 的 [間隔日期不足] 上次開立日期{Input}，警告間隔天數{Limit}，排檢除外！';
    private _msg = '';
    constructor(public rxCode,public colType: 'QTY'|'DAY'|'FREQ'|'TOTAL'|
    //'TOTAL_ONE'|
    'EXEC'|'EXECDT'|'EXECTIME'|'WAY'|
    'EVERYTIME'|'EVERYDAY'|'EVERYMONTH'|'EVERYINFODAYS'|'INTERVALDAYS'|
    'BELOWAGE'|'HIGHERAGE',
    private level:'ERROR'|'WARNING',
    public limit = '',
    public input = ''
    ) {
    }

    get Level(){return this.level;}

    setMseeage(msg:string){
      this._msg = msg;
    }
    get Message(){
      if(this._msg){
        return this._msg;
      }
      switch(this.colType){
        case 'QTY':
          return ValidateMsg.QTYEmpty.replace('{RxCode}',this.rxCode);
        case 'DAY':
          return ValidateMsg.DayEmpty.replace('{RxCode}',this.rxCode);
        case 'FREQ':
          return ValidateMsg.FreqEmpty.replace('{RxCode}',this.rxCode);
        case 'TOTAL':
          return ValidateMsg.TotalEmpty.replace('{RxCode}',this.rxCode);
        // case 'TOTAL_ONE':
        //   return ValidateMsg.TotalOne.replace('{RxCode}',this.rxCode);
        case 'EXEC':
          return ValidateMsg.Executor.replace('{RxCode}',this.rxCode);
        case 'EXECDT':
          return ValidateMsg.ExecDate.replace('{RxCode}',this.rxCode);
        case 'EXECTIME':
          return ValidateMsg.ExecTime.replace('{RxCode}',this.rxCode);
        case 'WAY':
          return ValidateMsg.WAY.replace('{RxCode}',this.rxCode);
        case 'BELOWAGE':
          return ValidateMsg.RxBelowtheAge.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'HIGHERAGE':
          return ValidateMsg.RxHigherthanAge.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'EVERYTIME':
          return ValidateMsg.RxNumberWarning.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'EVERYDAY':
          return ValidateMsg.RxDayWarning.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'EVERYMONTH':
          return ValidateMsg.RxMonthWarning.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'EVERYINFODAYS':
          return ValidateMsg.RxWarningDays.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
        case 'INTERVALDAYS':
          return ValidateMsg.RxIntervalDays.replace('{RxCode}',this.rxCode).replace('{Limit}',this.limit).replace('{Input}',this.input);
      }
    }
  }
