<div class="tab-area border-bottom-0" [ngClass]="isShowRxDialog?'px-2':''" *ngIf="displayTabs">
  <ng-container *ngFor="let tab of tabs;">
    <div [class]="tabCssClass(tab.isSelected)" [hidden]="tab.isHidden" (click)="changeTabByClick(tab, true)">
      {{tab.rxClass}}
    </div>
  </ng-container>
  <div class="clear"></div>
  <div class="tab-btn tab-btn-add" *ngIf="hasTabSp" i18n="@@OrderAddSoup" (click)="onAddSoupClick()">新增飲片</div>
</div>
<!-- <div class="clear"><mat-checkbox [checked]="false">緊急加成</mat-checkbox></div> -->
<ng-container *ngIf="_orders && !displayDentalOrders">
  <!-- 先 bind editOptions, 再 bind資料, 不然資料先進去render時會找不到 editOptions -->
  <app-hist-order-grid [isRecordDone]="isRecordDone" *ngIf="!fillExecuteOnly" [ngStyle]="{height:fillExecuteOnly?'calc(100% - 60px)':'calc(100% - 0px)'}" 
  [ngClass]="maxPanelOpen" [allergies]="allergies" #orderGridComp [fillExecuteOnly]="fillExecuteOnly" 
  [hiddenWhenOpen]="hiddenWhenOpen" [orderData]="tabOrderData" [disabled]="disabled" [dragDisabled]="isShowRxDialog" [isCopy]="isCopy"></app-hist-order-grid>
 <app-hist-order-grid-exec *ngIf="fillExecuteOnly" 
  [ngStyle]="{height:'calc(100% - 60px)'}" 
  [allergies]="allergies" #orderGridExeComp 
  [isRecordDone]="isRecordDone"
  [editOptions]="editOptions"
  [orderData]="tabOrderData" [disabled]="disabled"></app-hist-order-grid-exec>
</ng-container>

<ng-container *ngIf="_orders && displayDentalOrders">
  <!-- 先 bind editOptions, 再 bind資料, 不然資料先進去render時會找不到 editOptions -->
  <app-hist-order-grid-dental [ngStyle]="{height:fillExecuteOnly?'calc(100% - 60px)':'calc(100% - 0px)'}" [ngClass]="maxPanelOpen"
  [allergies]="allergies" #orderGridDentalComp (emitSelect)="onSelected($event)"
  [hiddenWhenOpen]="hiddenWhenOpen" [orderData]="tabDenOrderData" [disabled]="disabled" [dragDisabled]="isShowRxDialog" [isCopy]="isCopy"></app-hist-order-grid-dental>
</ng-container>
