import { OrderSpecialRuleEnum } from "src/app/enums/OrderSpecialRuleEnum";
import { Rx } from 'src/app/services/api-service/rx/rx';

export class RxSetDto {
	Id : number  ;
	MainSetId : number  ;
	/**處方分類 */
	Classify : string ;
	Sort : number ;
	PackageNo : number ;
	SpecialRule : OrderSpecialRuleEnum ;
	RxId : number ;
  	
	Dose : number ;     // from Rx.DefaultDose
	DailyDose : number ;
	TotalDose : number ;
	DosageId : number ;
	Frequency : string;    //Frequency.Code for dropdown selection
	InfoFrequency : number ;
	Way : string ;       // Rx.DefaultWay
	Days : number ;
	InfoDays : number ;

	RxCode: string ;    // from Rx.RxCode  	
	StdCode:string;
	
	ProdName: string;  // from Rx.ProdName
	RsCode:string;
	AlternativeRxId?:number;
	DispTP:string;
	Rx: Rx;
}
