import { WholeHist } from "src/app/services/api-service/hist/whole-hist"
import { NHIHistory } from "src/app/services/api-service/NHI/nhiHistory"
import { NHIOrder } from "src/app/services/api-service/NHI/nhiOrder"
import { WholeNHIData } from "src/app/services/api-service/NHI/wholeNHIData"
import { NHIRegIC } from "src/app/services/api-service/register/nhiRegIc"
import { RegisterConst } from "src/app/shared/constants/register-const"
import { DateHelper } from "src/app/shared/helpers/date-helper"
import { CreateXml } from "src/app/shared/utilities"

/**健保就醫資料 */
export function CreateICXml_2(regDate: Date, nhiData: WholeNHIData) {
  var nhiRegIc: NHIRegIC = nhiData.RegIc;
  var nhiHist: NHIHistory = nhiData.Hist ?? new NHIHistory();
  var nhiOrders: NHIOrder[] = nhiData.Orders;
  var regDateNum = Number(nhiRegIc.IRegDT.substring(0, 9));
  var icDtNum = Number((nhiRegIc.Icdt??'0').toString().substring(0, 9));
  return CreateXml({
    // RECords ?
    RECS: {
      // RECord
      REC: [{
        // Message Start Header = =?
        MSH: {
          H00: '1',
          H01: nhiRegIc.UpNote
        },
        // Message Body = =?
        MB: {
          MB1: {
            M01: nhiRegIc.Isam,
            M02: nhiRegIc.Icno,
            M03: nhiRegIc.Cid,
            M04: nhiRegIc.Birthdate,
            M05: nhiRegIc.Ihcd,
            // 代檢使用原醫師身分證
            M06: (nhiData.ICode == RegisterConst.ICode_009_Other && nhiData.INote == RegisterConst.INote_代檢)?nhiData.OriLabDcotor :nhiRegIc.DrCid,
            //M06: nhiRegIc.DrCid,
            M07: nhiRegIc.Nhictp,
            M08: nhiRegIc.BabyDate,
            M09: nhiRegIc.BirthsNum,
            M10: nhiRegIc.BabyMark,
            M11: nhiRegIc.Ierrcd ? (regDateNum != icDtNum ? nhiRegIc.IRegDT : nhiRegIc.Icdt) : nhiRegIc.Icdt,
            M12: nhiRegIc.IreCard,
            M13: nhiRegIc.Isqno,
            M14: nhiRegIc.Isgn,
            M15: nhiRegIc.Itreatnum,
            M16: nhiRegIc.ItreatnumOri,
            M17: nhiRegIc.IhcdOri,
            M18: nhiRegIc.IsqnoOri,
            M19: nhiRegIc.IcdtOri,
            M20: nhiHist.Idays,
            M21: nhiHist.IChrContDays,
            M22: nhiHist.ICtrlDays,
            M23: nhiHist.Idru,
            M24: nhiHist.IGenFreq,
            M25: nhiHist.IChrFreq,
            M26: nhiHist.IChrContFreq,
            M27: nhiHist.ICtrlFreq,
            M28: nhiHist.ICtrlChrFreq,
            M29: nhiHist.ICtrlContFreq,
            M30: nhiHist.IPTDose,
            M31: nhiHist.IOTDose,
            M32: nhiHist.ISTDose,
            M33: nhiHist.IChrItem,
            M34: nhiHist.ICtrlItem,
            M35: nhiHist.MainDisease,
            M36: nhiHist.SecDisease1,
            M37: nhiHist.SecDisease2,
            M38: nhiHist.SecDisease3,
            M39: nhiHist.SecDisease4,
            M40: nhiHist.SecDisease5,
            M41: nhiHist.MainSPC,
            M42: nhiHist.SecSPC1,
            M43: nhiHist.SecSPC2,
            M44: nhiHist.MedicalFees? nhiHist.MedicalFees.toString() : '0',
            M45: nhiHist.Deductible? nhiHist.Deductible.toString() : '0',
            M46: nhiHist.HospitalFees,
            M47: nhiHist.HdeductibleA,
            M48: nhiHist.HdeductibleB,
            M49: DateHelper.formatROCDate(regDate),
            M50: nhiHist.IBedNo,
            M51: nhiHist.ICase,
            M52: nhiHist.IReTreatnum,
            M53: nhiHist.IPartOPD? nhiHist.IPartOPD.toString() : '0',
            M54: nhiHist.IPartRx ? nhiHist.IPartRx.toString() : '0',
            M55: nhiHist.IPartExam ? nhiHist.IPartExam.toString() : '0',
            M56: nhiRegIc.M56Type,
          },
          MB2: nhiOrders.map(x => createMB2_2(nhiRegIc, x)),
        }
      }]
    }
  });

  //ToXml()
}
function createMB2_2(nhiRegIc: NHIRegIC, nhiOrder: NHIOrder) {
  var d02= translateOrderClassdata_2(nhiOrder.OrderClass, nhiOrder.OrderClass2);
  var regDateNum = Number(nhiRegIc.IRegDT.substring(0, 9));
  var icDtNum = Number((nhiRegIc.Icdt??'0').toString().substring(0, 9));
  return {
    D01: nhiRegIc.Ierrcd ? (regDateNum != icDtNum ? nhiRegIc.IRegDT : nhiRegIc.Icdt) : nhiRegIc.Icdt,  //nhiRegIc.Icdt,
    D02: d02,
    D03: nhiOrder.Iseq == 0?  '0' : nhiOrder.Iseq,
    D04: nhiOrder.PrescType,
    D05: nhiOrder.DosingMethod,
    D06: nhiOrder.NhiDrugCode,
    D07: nhiOrder.TreatmentSite,
    D08: nhiOrder.Frequency,
    D09: nhiOrder.Days,
    D10: nhiOrder.TotalDose > 0 ? nhiOrder.TotalDose.toFixed(1) : '0.0',
    D11: nhiOrder.Isgn,
    D12: nhiOrder.IOrgNo,
    D13: nhiOrder.IBatchNo,
    D14: nhiOrder.IWay,
    D15: nhiOrder.IRemark,
  }
}

/**健保就醫資料 IC1.0格式 */
export function CreateICXml_1(nhiDatas: WholeNHIData[]) {
  return CreateXml({
    // RECords ?
    RECS: {
      // RECord
      REC: nhiDatas.map(x => createRec_1(x))
    }
  });

  //ToXml()
}
function createRec_1(nhiData: WholeNHIData) {
  var NHIRegIC: NHIRegIC = nhiData.RegIc;
  var NHIHistory: NHIHistory = nhiData.Hist;
  var nhiOrders: NHIOrder[] = nhiData.Orders;
  var regDateNum = Number(NHIRegIC.IRegDT.substring(0, 9));
	var icDtNum = Number((NHIRegIC.Icdt??'0').toString().substring(0, 9));
  return {
    // Message Start Header = =?
    MSH: {
      A00: '1',
      A01: NHIRegIC.UpNote,
    },
    // Message Body = =?
    MB: {
      MB1: {
        A11: NHIRegIC.Icno,
        A12: NHIRegIC.Cid,
        A13: NHIRegIC.Birthdate,
        A14: NHIRegIC.Ihcd,
        // 代檢使用原醫師身分證
        A15: (nhiData.ICode == RegisterConst.ICode_009_Other && nhiData.INote == RegisterConst.INote_代檢)?nhiData.OriLabDcotor :NHIRegIC.DrCid,
        A16: NHIRegIC.Isam,
        A17: NHIRegIC.Ierrcd ? (regDateNum != icDtNum ? NHIRegIC.IRegDT : NHIRegIC.Icdt) : NHIRegIC.Icdt, //NHIRegIC.Icdt,
        A18: ((NHIRegIC.Nhictp == 'ZA' || NHIRegIC.Nhictp == 'ZB') ? '' : (NHIRegIC.Isqno || NHIRegIC.Ierrcd)),
        A19: NHIRegIC.IreCard,
        A20: NHIRegIC.BabyDate,
        A21: NHIRegIC.BirthsNum,
        A22: NHIRegIC.Isgn,
        A23: NHIRegIC.Nhictp,
        A24: NHIRegIC.BabyMark,
        A25: NHIHistory.MainDisease,
        A26: NHIHistory.SecDisease1,
        A27: NHIHistory.SecDisease2,
        A28: NHIHistory.SecDisease3,
        A29: NHIHistory.SecDisease4,
        A30: NHIHistory.SecDisease5,
        A31: NHIHistory.MedicalFees ? NHIHistory.MedicalFees.toString() : '0',
        A32: NHIHistory.Deductible ? NHIHistory.Deductible.toString() : '0',
        A33: NHIHistory.HospitalFees ? NHIHistory.HospitalFees.toString() : '0',
        A34: NHIHistory.HdeductibleA ? NHIHistory.HdeductibleA.toString() : '0',
        A35: NHIHistory.HdeductibleB ? NHIHistory.HdeductibleB.toString() : '0',
        A41: NHIHistory.HealthCare,
        A42: NHIHistory.HealthCareDate,
        A43: NHIRegIC.Ihcd43,
        A44: NHIHistory.HealthCareCode,
        A51: NHIHistory.PrenatalCare,
        A52: NHIRegIC.Ihcd52,
        A53: NHIHistory.PrenatalCode,
        A54: NHIRegIC.IreCard == '2' ? (NHIRegIC.IRegDT ? NHIRegIC.IRegDT : '') : '',
        A55: NHIHistory.ICase,
        A56: NHIHistory.IBedNo,
        A57: NHIHistory.IPartOPD,
        A58: NHIHistory.IPartRx,
        A59: NHIHistory.IPartExam,
      },
      MB2: nhiOrders.map(x => createMB2_1(NHIRegIC, x)),
    }
  }
}
function createMB2_1(NHIRegIC: NHIRegIC, NHIOrder: NHIOrder) {
  var regDateNum = Number(NHIRegIC.IRegDT.substring(0, 9));
	var icDtNum = Number((NHIRegIC.Icdt??'0').toString().substring(0, 9));
  return {
    A71: NHIRegIC.Ierrcd ? (regDateNum != icDtNum ? NHIRegIC.IRegDT : NHIRegIC.Icdt) : NHIRegIC.Icdt, //NHIRegIC.Icdt,
    A72: NHIOrder.OrderClass,
    A73: NHIOrder.NhiDrugCode,
    A74: NHIOrder.TreatmentSite,
    A75: NHIOrder.Frequency,
    A76: NHIOrder.Days,
    A77: NHIOrder.TotalDose > 0 ? NHIOrder.TotalDose.toFixed(1) : '0.0',
    A78: NHIOrder.DispensingType,
    A79: NHIOrder.Isgn,
    A80: NHIOrder.AllergyMark,
    A81: NHIOrder.DrugAllergy,
    A91: NHIOrder.IRemark,

  }
}
// 預檢上傳
export function CreateICXml_2_Beta(nhiDatas: WholeNHIData[]) {

  return CreateXml({
    // RECords ?
    RECS: {
      // RECord
      REC:  nhiDatas.map(x => createRec_2_Beta(x))
    }
  });

  //ToXml()
}
function translateUpNotedata_2(input:string, inputSec:string, isCancel = false):string{
  var res = input;
  if (inputSec != '')return inputSec; // 2,0有存就回填 否則用1.0欄位
  switch(input){
    case "1":
    case "3":
      res = 'A';
      break;
    case "2":
    case "4":
      res = 'B';
      break;
    default:
      break;
  }
  if (isCancel) res ='D';
  return res;
}
function translateOrderClassdata_2(input:string, inputSec:string):string{
  var res = input;
  if (inputSec != '')return inputSec;
  switch(input){
    case "1":
    case "2":
      res = '1';
      break;
    case "3":
      res = '2';
      break;
      case "4":
      res = '3';
      break;
    default:
      break;
  }
  return res;
}

function createRec_2_Beta(nhiData: WholeNHIData)
{
  var nhiRegIc: NHIRegIC = nhiData.RegIc;
  var nhiHist: NHIHistory = nhiData.Hist ?? new NHIHistory();
  var nhiOrders: NHIOrder[] = nhiData.Orders;
  var regDateNum = Number(nhiRegIc.IRegDT.substring(0, 9));
	var icDtNum = Number((nhiRegIc.Icdt??'0').toString().substring(0, 9));
  // Message Start Header = =?
  if (nhiRegIc.Nhictp !== 'ZA' && nhiRegIc.Nhictp !== 'ZB') {
    return {
      MSH: {
        H00: '1',
        H01: translateUpNotedata_2(nhiRegIc.UpNote,(nhiRegIc.UpNote2 || '')),
      },
      // Message Body = =?

      MB: {
        MB1: {
          M01: nhiRegIc.Isam,
          M02: nhiRegIc.Icno,
          M03: nhiRegIc.Cid,
          M04: nhiRegIc.Birthdate,
          M05: nhiRegIc.Ihcd,
          // 代檢使用原醫師身分證
          M06: (nhiData.ICode == RegisterConst.ICode_009_Other && nhiData.INote == RegisterConst.INote_代檢)?nhiData.OriLabDcotor :nhiRegIc.DrCid,
          //M06: nhiRegIc.DrCid,
          M07: nhiRegIc.Nhictp,
          M08: nhiRegIc.BabyDate,
          M09: nhiRegIc.BirthsNum,
          M10: nhiRegIc.BabyMark,
          M11: nhiRegIc.Ierrcd ? (regDateNum != icDtNum ? nhiRegIc.IRegDT : nhiRegIc.Icdt) : nhiRegIc.Icdt,
          M12: nhiRegIc.IreCard,
          M13: nhiRegIc.Isqno,
          M14: nhiRegIc.Isgn,
          M15: nhiRegIc.Itreatnum,
          M16: nhiRegIc.ItreatnumOri,
          M17: nhiRegIc.IhcdOri,
          M18: nhiRegIc.IsqnoOri,
          M19: nhiRegIc.IcdtOri,
          M20: nhiHist.Idays,
          M21: nhiHist.IChrContDays,
          M22: nhiHist.ICtrlDays,
          M23: nhiHist.Idru,
          M24: nhiHist.IGenFreq,
          M25: nhiHist.IChrFreq,
          M26: nhiHist.IChrContFreq,
          M27: nhiHist.ICtrlFreq,
          M28: nhiHist.ICtrlChrFreq,
          M29: nhiHist.ICtrlContFreq,
          M30: nhiHist.IPTDose,
          M31: nhiHist.IOTDose,
          M32: nhiHist.ISTDose,
          M33: nhiHist.IChrItem,
          M34: nhiHist.ICtrlItem,
          M35: nhiHist.MainDisease,
          M36: nhiHist.SecDisease1,
          M37: nhiHist.SecDisease2,
          M38: nhiHist.SecDisease3,
          M39: nhiHist.SecDisease4,
          M40: nhiHist.SecDisease5,
          M41: nhiHist.MainSPC,
          M42: nhiHist.SecSPC1,
          M43: nhiHist.SecSPC2,
          M44: nhiHist.MedicalFees? nhiHist.MedicalFees.toString() : '0',
          M45: nhiHist.Deductible? nhiHist.Deductible.toString() : '0',
          M46: nhiHist.HospitalFees,
          M47: nhiHist.HdeductibleA,
          M48: nhiHist.HdeductibleB,
          M49: nhiRegIc.IreCard == '2' ? (nhiRegIc.IRegDT ? nhiRegIc.IRegDT : '') : '',
          M50: nhiHist.IBedNo,
          M51: nhiHist.ICase,
          M52: nhiHist.IReTreatnum,
          M53: nhiHist.IPartOPD? nhiHist.IPartOPD.toString() : '0',
          M54: nhiHist.IPartRx ? nhiHist.IPartRx.toString() : '0',
          M55: nhiHist.IPartExam ? nhiHist.IPartExam.toString() : '0',
          M56: nhiRegIc.M56Type,
        },
        MB2: nhiOrders.map(x => createMB2_2(nhiRegIc, x)),
      }
    }
  } else {
    return {
      MSH: {
        H00: '1',
        H01: translateUpNotedata_2(nhiRegIc.UpNote,(nhiRegIc.UpNote2 || ''), true),
      },
      // Message Body = =?
      MB: {
        MB1: {
          M01: nhiRegIc.Isam,
          M02: nhiRegIc.Icno,
          M03: nhiRegIc.Cid,
          M04: nhiRegIc.Birthdate,
          M05: nhiRegIc.Ihcd,
          M06: nhiRegIc.DrCid,
          M11: nhiRegIc.Ierrcd ? (regDateNum != icDtNum ? nhiRegIc.IRegDT : nhiRegIc.Icdt) : nhiRegIc.Icdt,
          M15: nhiRegIc.Itreatnum,
        }
      }
    }
  }
}
