import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { UrlHelper } from 'src/app/shared/helpers/url-helper';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { CookieService } from 'ngx-cookie-service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { CompanyApi } from 'src/app/services/api-service/company/company-api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private notification: EasyNotificationService,
    private webApiClient: WebApiClient,
    private companyApi:CompanyApi,
    private printService: LocalPrintService,
    private cookieService: CookieService
  ) {
    this.route.queryParams.subscribe(params => {
      const isAutoLoginClinic = '' + params['isAutoLoginClinic'];
      if (isAutoLoginClinic) {
        // 需明確指定，不然以系統預設參數為準
        if (isAutoLoginClinic.toUpperCase() === 'Y' || isAutoLoginClinic.toUpperCase() === 'TRUE') {
          this.isAutoLoginClinic = true;
        } else if (isAutoLoginClinic.toUpperCase() === 'N' || isAutoLoginClinic.toUpperCase() === 'FALSE') {
          this.isAutoLoginClinic = false;
        }
      }
    });
  }

  stockApi: WebApiService;

  loginForm: FormGroup;

  loginMessage = '';

  isLoadingCompanyName = true;
  isLoadCompanyNameFailed = false;

  returnUrl: string;
  companyCode = '';
  clinics: Clinic[];
  logoutReason: string;
  openLoginConfirm = false;
  loginConfirmMessage = '';
  isAutoLoginClinic: boolean = BaseConfig.getConfig().env.isAutoLoginClinic;

  lastLoginCompanyCode = '';
  lastLoginSuccessUserName = '';
  lastLoginSuccessClinicCode = '';
  companyName = '';

  private getDefaultPage(): string {
    // A.有returnUrl
    if (this.returnUrl && this.returnUrl !== '/') {
      return this.returnUrl;
    }
    // B.沒有returnUrl
    return '/' + this.companyCode + BaseConfig.getConfig().env.defaultRoute;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      clinicCode: [''],
      isAutoLoginClinic: [this.isAutoLoginClinic]
    });
    this.loginForm.valueChanges.subscribe(v=>{
      this.clearLoginMessage()
    });
    // get company code
    this.route.paramMap.subscribe(async (q) => {
      this.companyCode = UrlHelper.getCompanyCodeFromRouteParamMap(q);
      if (this.authenticationService.isLoginToCompany(this.companyCode)) {
        const url = this.getDefaultPage();
        this.router.navigate([url]);
      } else {
        if(this.companyCode == '_'){
          await this.companyApi.CreateStd();
        }
        this.getCompanyName(this.companyCode);
      }
    });
  }

  getMsg(msgId: string): string {
    return this.notification.getTextById(msgId);
  }

  // 進行登入到某診所
  processClinicLogin() {
    this.clearLoginMessage();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.logoutReason = this.route.snapshot.queryParams['reason'];
    if (this.logoutReason === '401') {
      this.notification.showErrorById('MSG_LoginReason401');
    }

    this.lastLoginCompanyCode = this.cookieService.get('LastLoginCompanyCode');
    this.lastLoginSuccessUserName = this.cookieService.get('LastLoginSuccessUserName');
    this.lastLoginSuccessClinicCode = this.cookieService.get('LastLoginSuccessClinicCode');

    // 相同公司碼才設定user與clinic
    if (this.companyCode && this.companyCode === this.lastLoginCompanyCode) {
      if (this.lastLoginSuccessUserName !== '') {
        this.loginForm.controls['username'].setValue(this.lastLoginSuccessUserName);
        this.onAccountBlur();
      }
    }
  }

  get loginFC() { return this.loginForm.controls; }

  async login(force: boolean) {
    this.loginForm.disable();
    this.clearLoginMessage();

    this.loginMessage = this.getMsg('MSG_SystemLoginLogging');
    var clinicCode = this.loginFC.clinicCode.value;
    var clinic = this.clinics.find(c=>c.Code == clinicCode);
    try{
      var data = await this.authenticationService.login(
                        this.loginFC.username.value, this.loginFC.password.value,
                        this.companyCode, clinic, force, this.loginFC.isAutoLoginClinic.value
                        );
      this.clearLoginMessage();
      this.loginForm.enable();
      if (data) {
        const url = this.getDefaultPage();
        // 注意return url必須清除，不然切換company時，return url會是前一個company
        this.returnUrl = '';
        this.router.navigate([url]);
        await this.afterLogin();
      } else {
        this.loginMessage = this.getMsg('MSG_SystemLoginFailed');
      }
    }catch(error){
      this.clearLoginMessage();
      if (error.code === 'AUT004') {
        this.openLoginConfirm = true;
        this.loginConfirmMessage = error.message;
      } else if (error.code === 'AUT005') {
        this.loginMessage = this.getMsg('MSG_SystemLoginFailed');
      } else if (error.code === 'AUT006') {
        this.loginMessage = this.getMsg('MSG_SystemLoginWorkingCheck');
      } else if (error.code === 'AUT007') {
        this.loginMessage = this.getMsg('MSG_SystemLoginCompanyNotEffective');
      } else if (error.code === 'AUT008') {
        this.loginMessage = this.getMsg('MSG_SystemLoginClinicNotEffective');
      } else {
        this.loginMessage = this.getMsg('MSG_SystemLoginFailed');
      }
      this.loginForm.enable({emitEvent:false});
    }     
  }
  async afterLogin() {
    this.stockApi = this.webApiClient.createHisService('stock/stockItem');
    
    await this.getLowerSafeStockItemsInfo();
    //this.printService.initialPrintClient();
    this.cookieService.set('LastLoginCompanyCode', this.companyCode, 7);
    this.cookieService.set('LastLoginSuccessUserName', this.loginFC.username.value, 7);
    this.cookieService.set('LastLoginSuccessClinicCode', this.loginFC.clinicCode.value, 7);

  }
  private async getLowerSafeStockItemsInfo() {
    try{
      var data = await this.stockApi.get('getLowerSafeStockItemsInfo').toPromise()
      if (data.isShow) {
        (data.messages as Array<string>).forEach(message => {
          this.notification.showError(message, false);
        });
      }
    }catch(error){
      this.notification.showGetDataFailed();
    }
  }

  private getCompanyName(companyCode: string) {
    this.clearLoginMessage();
    this.isLoadingCompanyName = true;
    this.authenticationService.getCompanyName(companyCode)
      .subscribe(
        (data) => {
          this.companyName = data.companyName;
          if (this.companyName) {
            this.isLoadingCompanyName = false;
            this.processClinicLogin();
          } else {
            this.isLoadCompanyNameFailed = true;
          }
        },
        (error) => {
          this.clearLoginMessage();
          this.isLoadCompanyNameFailed = true;
        });
  }

  clearLoginMessage() {
    this.loginMessage = '';
    this.loginConfirmMessage = '';
  }
  onLoginClick() {
    this.clearLoginMessage();
    if (this.isAutoLoginClinic) {
    } else {
      if (this.clinics === undefined || this.clinics.length <= 0) {
        this.loginMessage = this.getMsg('MSG_SystemLoginNoClinic');
        return;
      }
      if (this.loginFC.clinicCode.value === '') {
        this.loginMessage = this.getMsg('MSG_SystemLoginNoClinicSelected');
        return;
      }
    }
    if (this.loginForm.invalid) {
      this.loginMessage = this.getMsg('MSG_SystemLoginInputError');
      return;
    }

    this.login(false);
  }

  onAccountChange() {
    if (this.isLoadingCompanyName || this.isLoadCompanyNameFailed) {
      return;
    }
    this.clearLoginMessage();
    if (this.isAutoLoginClinic) {
      return;
    }
    this.loginMessage = this.getMsg('MSG_SystemLoginFetchingClinics');
    this.authenticationService.getClinics(this.companyCode, this.loginFC.username.value)
      .subscribe(
        (data) => {
          this.clearLoginMessage();
          if (!data || data.length === 0) {
            this.loginMessage = this.getMsg('MSG_SystemLoginAccountNoClinic');
          }
          this.clinics = data;
          if (this.clinics.length > 0) {

            if (this.lastLoginSuccessUserName !== '') {
              if (this.lastLoginSuccessUserName === this.loginFC.username.value) {
                if (this.lastLoginSuccessClinicCode !== '') {
                  this.loginForm.controls['clinicCode'].setValue(this.lastLoginSuccessClinicCode);
                }
                else {
                  this.loginForm.controls['clinicCode'].setValue(this.clinics[0].Code);
                }
              } 
              else {
                this.loginForm.controls['clinicCode'].setValue(this.clinics[0].Code);
              }
            }
            else {
              this.loginForm.controls['clinicCode'].setValue(this.clinics[0].Code);
            }
          }
  
        },
          (error) => {
          this.loginMessage = this.getMsg('MSG_SystemLoginFetchClinicsFailed');
        },
        () => {
        });
  }
  onAccountBlur() {
    if (!this.clinics) {
      this.onAccountChange();
    }
  }
  onConfirmResult(result: boolean) {
    if (result) {
      this.login(true);
    }
    this.openLoginConfirm = false;
  }
  capsLockEnabled:boolean;
  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    this.capsLockEnabled = event.getModifierState('CapsLock');
  }
}
