export class Hspid {
    Id: number;
    HID: string;
    HSET: string;
    HNAME: string;
    HAREA: string;
    HTEL: string;
    BRANCH: string;
    HLEVEL: string;
    HKIND: string;
    Htype: number;
    Hno: number;
    Edate: Date;

    static mapLevel(data: Hspid[]) {
        let d: Hspid[];
        d = Object.assign([], data);
        if (data) {
            d.forEach((item) => {
                var level = data.find(x => x.HID == item.HID).HLEVEL;
                switch(level) {
                    case "醫學中心":
                        item.HLEVEL = "1";
                        break;
                    case "區域醫院":
                        item.HLEVEL = "2";
                        break;
                    case "地區醫院":
                        item.HLEVEL = "3";
                        break;
                    case "診所":
                        item.HLEVEL = "4";
                        break;
                    case "藥局":
                        item.HLEVEL = "5";
                        break;
                    case "居家護理":
                        item.HLEVEL = "6";
                        break;
                    case "康復之家":
                        item.HLEVEL = "7";
                        break;
                    case "助產所":
                        item.HLEVEL = "8";
                        break;
                    case "檢驗所":
                        item.HLEVEL = "9";
                        break;
                    case "物理治療所":
                        item.HLEVEL = "A";
                        break;
                    case "特約醫事放射機構":
                        item.HLEVEL = "B";
                        break;
                    case "不詳":
                        item.HLEVEL = "X";
                        break;
                }
            });
        }
        return d;
    }
}

export class HspidSimpleDTO{
  Id:number;
  HID:string;
  HNAME:string;
}
