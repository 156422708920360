import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { VisGridColumnComponent } from '../vis-grid-column/vis-grid-column.component';

@Component({
  selector: 'app-vis-grid-checkbox-column',
  templateUrl: './vis-grid-checkbox-column.component.html',
  styleUrls: ['./vis-grid-checkbox-column.component.css'],
  providers: [{provide: VisGridColumnComponent, useExisting: forwardRef(() => VisGridCheckboxColumnComponent)}],
})
export class VisGridCheckboxColumnComponent extends VisGridColumnComponent implements OnInit {

  @ViewChild(TemplateRef)
  templateRef : TemplateRef<any>;

  @Input()
  width: number;
  @Input()
  showSelectAll: boolean;
  @Input()
  selectOnCheck: boolean = true;
  @Input()
  checkOnSelect: boolean = false;
  @Input()
  allChecked: boolean = null;
  @Input()
  someChecked: boolean = null;
  @Output()
  checkChanged:EventEmitter<{checked:boolean,data:any}> = new EventEmitter();
  @Input()
  setDisable: boolean = false;
  selectByKey:string;
  selectedKeys:any[] = [];
  @Input() 
  isRadio?:boolean = false;
  @Input() 
  isIcon?:boolean = false;
  _displayType:'radio'|'checkbox'|'icon'
  constructor(protected elementRef: ElementRef) {
   super(elementRef);
  }
  ngOnInit(): void {
   this._displayType = this.isRadio?'radio':this.isIcon?'icon':'checkbox';
  }
  checkClick(evt:Event){
    if(!this.selectOnCheck || this.checkOnSelect){
      evt.stopPropagation();
    }
  }

  rowClick(row: number, data: any): void {
    if(this.checkOnSelect){
      if(this.selectedKeys.some(x=>x== data[this.selectByKey])){
        data[this._field] = true;
      }else{
        data[this._field] = false;
      }
    }
  }

  checkChange(evt:MatCheckboxChange,data:any){
    var key = data[this.selectByKey];
    if(this.selectOnCheck && !this.checkOnSelect){
      if(evt.checked){
        if(!this.selectedKeys.some(x=>x==key)){
          this.selectedKeys.push(key);
        }
      }else{
        var ind = this.selectedKeys.indexOf(key);
        if(ind >= 0){
          this.selectedKeys.splice(ind,1);
        }
      }
    }
    this.checkChanged.emit({checked:evt.checked,data:data});
    // console.log(evt.checked,this.selectedKeys,data);
  }
}
