<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;

    }
  }
</style>
<div class="a4-print-style-h h-auto" #reportContainer>
  <div class="pres-report2 bg-white px-2 page m-0 m-auto d-block page-shadow" id="page-01"
    style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content m-auto" [ngStyle]="{height: sizePage.height + 'cm', width: sizePage.width + 'cm'}">
      <div>
          <div class="w-100 w-100-print text-center font-weight-bolder my-2 mb-2mm" style="font-size: 16pt; height: 50px; line-height: 60px; ">衛生福利部中央健康保險署特約醫事服務機構復健治療診療項目申報表
          </div>
      </div>
      <div style="-webkit-text-size-adjust: none;">
        <!-- PIPE{{someNum|NumberThousandFixed}}-->
        <table cellspacing="0" class="border-solid border-bottom-0 w-100 w-100-print">
          <tr>
            <td class="text-left pl-2" style="width: 12%;">院所代號</td>
            <td class="text-left pl-2" style="width: 19%;">3501195369</td>
            <td class="text-left pl-2" style="width: 12%;">院所名稱</td>
            <td class="text-left pl-2" style="width: 31%;">松柏復健科診所</td>
            <td class="text-left pl-2" style="width: 14%;">費用年月</td>
            <td class="text-left pl-2" style="width: 12%;">11207</td>
          </tr>
        </table>
        <table cellspacing="0" class="border-solid w-100 w-100-print second-border-top-print">
            <tr>
              <td class="text-left pl-2" style="width:21%">當月一日在職專任物理治療人員數</td>
              <td class="text-right px-2" style="width:10%">人</td>
              <td class="text-left pl-2" style="width:28%">專任物理治療人員實際執行物理治療總日數</td>
              <td class="text-right px-2" style="width:15%">日</td>
              <td class="text-left pl-2" style="width:14%">當月復健治療項申報案件上限數</td>
              <td class="text-right px-2" style="width:12%"></td>
            </tr>
        </table>

        <table class="border-solid w-100 w-100-print mt-3 m-t-1mm-print" cellspacing="0">
          <tr>
            <td colspan="2" class="text-left pl-2" style="width: 18%;">物理治療</td>
            <td class="text-center" style="width:14%">簡單-簡單治療</td>
            <td class="text-center" style="width:14%">簡單-中度治療</td>
            <td class="text-center" style="width:14%">中度-中度治療</td>
            <td class="text-center" style="width:14%">中度-複雜治療</td>
            <td class="text-center" style="width:14%">複雜治療</td>
            <td rowspan="3" class="text-center" style="width:12%">總 計</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">相關科醫師申報</td>
            <td class="text-center">42001A-42003C</td>
            <td class="text-center">42004A-42006C</td>
            <td class="text-center">42007A-42009C</td>
            <td class="text-center">42010A-42012C</td>
            <td class="text-center border-right">42013A-42015C</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">限復健專科醫師申報</td>
            <td class="text-center"></td>
            <td class="text-center"></td>
            <td class="text-center">42017C</td>
            <td class="text-center">42018C</td>
            <td class="text-center border-right">42019C</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2" style="width: 12%;">申報件數</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報件數<br/>百分比</td>
            <td class="text-center" style="width: 5%;">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-center">100%</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報金額</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
        </table>
        <div class="w-100 w-100-print d-flex mt-3 ">
            <div class="flex-grow-one notify-text">註：
            </div>
            <div class="flex-grow-one" style="width: calc(100% - 30px);">
                <ol class="pl-3 ml-0">
                    <li data-list-text="1.">
                      超次費用核扣方式：
                      <div>每月申報上限件數 = 當月專任物理治療人員實際執行物理治療總日數 * 45</div>
                      <div>門診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數) * 門診申報金額</div>
                      <div>住診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數)</div>
                    </li>
                    <li data-list-text="2.">核減率計算至小第四位，第五位四捨五入</li>
                    <li data-list-text="3.">醫院請填寫一式兩份，分送門診費用組及住院費用組</li>
                  </ol>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pres-report2 bg-white px-2 page m-0 m-auto d-block page-shadow" id="page-02"
    style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content m-auto" [ngStyle]="{height: sizePage.height + 'cm', width: sizePage.width + 'cm'}">
      <div>
          <div class="w-100 w-100-print text-center font-weight-bolder my-2 mb-2mm" style="font-size: 16pt; height: 50px; line-height: 60px; ">衛生福利部中央健康保險署特約醫事服務機構復健治療診療項目申報表
          </div>
      </div>
      <div style="-webkit-text-size-adjust: none;">
        <!-- PIPE{{someNum|NumberThousandFixed}}-->
        <table cellspacing="0" class="border-solid border-bottom-0 w-100 w-100-print">
          <tr>
            <td class="text-left pl-2" style="width: 12%;">院所代號</td>
            <td class="text-left pl-2" style="width: 19%;">3501195369</td>
            <td class="text-left pl-2" style="width: 12%;">院所名稱</td>
            <td class="text-left pl-2" style="width: 31%;">松柏復健科診所</td>
            <td class="text-left pl-2" style="width: 14%;">費用年月</td>
            <td class="text-left pl-2" style="width: 12%;">11207</td>
          </tr>
        </table>
        <table cellspacing="0" class="border-solid w-100 w-100-print second-border-top-print">
            <tr>
              <td class="text-left pl-2" style="width:21%">當月一日在職專任職能治療人員數</td>
              <td class="text-right px-2" style="width:10%">人</td>
              <td class="text-left pl-2" style="width:28%">專任職能治療人員實際執行職能治療總日數</td>
              <td class="text-right px-2" style="width:15%">日</td>
              <td class="text-left pl-2" style="width:14%">當月職能治療項申報案件上限數</td>
              <td class="text-right px-2" style="width:12%"></td>
            </tr>
        </table>
        <table class="border-solid w-100 w-100-print mt-3 m-t-1mm-print" cellspacing="0">
          <tr>
            <td colspan="2" class="text-left pl-2" style="width: 20%;">職能治療</td>
            <td class="text-center" style="width:14%">簡單治療</td>
            <td class="text-center" style="width:14%">中度治療</td>
            <td class="text-center" style="width:14%">中度複雜治療</td>
            <td class="text-center" style="width:14%">複雜治療</td>
            <td rowspan="3" class="text-center" style="width:14%">總 計</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">相關科醫師申報</td>
            <td class="text-center">43001A-43003C</td>
            <td class="text-center">43004A-43006C</td>
            <td class="text-center">43007A-43009C</td>
            <td class="text-center border-right">43029A-43032C</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">限復健專科醫師申報</td>
            <td class="text-center"></td>
            <td class="text-center">43027C</td>
            <td class="text-center">43028C</td>
            <td class="text-center border-right">43031C</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2" style="width: 13%;">申報件數</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報件數百分比</td>
            <td class="text-center" style="width: 7%;">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-center">100%</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報金額</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
        </table>
        <div class="w-100 w-100-print d-flex mt-3 ">
            <div class="flex-grow-one">註：
            </div>
            <div class="flex-grow-one" style="width: calc(100% - 30px);">
                <ol class="pl-3 ml-0">
                    <li data-list-text="1.">
                      超次費用核扣方式：
                      <div>每月申報上限件數 = 當月專任物理治療人員實際執行物理治療總日數 * 45</div>
                      <div>門診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數) * 門診申報金額</div>
                      <div>住診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數)</div>
                    </li>
                    <li data-list-text="2.">核減率計算至小第四位，第五位四捨五入</li>
                    <li data-list-text="3.">醫院請填寫一式兩份，分送門診費用組及住院費用組</li>
                  </ol>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pres-report2 bg-white px-2 page m-0 m-auto d-block page-shadow" id="page-03"
  style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content m-auto" [ngStyle]="{height: sizePage.height + 'cm', width: sizePage.width + 'cm'}">
      <div>
          <div class="w-100 w-100-print text-center font-weight-bolder my-2 mb-2mm" style="font-size: 16pt; height: 50px; line-height: 60px; ">衛生福利部中央健康保險署特約醫事服務機構復健治療診療項目申報表
          </div>
      </div>
      <div style="-webkit-text-size-adjust: none;">
        <!-- PIPE{{someNum|NumberThousandFixed}}-->
        <table cellspacing="0" class="border-solid border-bottom-0 w-100 w-100-print">
          <tr>
            <td class="text-left pl-2" style="width: 12%;">院所代號</td>
            <td class="text-left pl-2" style="width: 19%;">3501195369</td>
            <td class="text-left pl-2" style="width: 12%;">院所名稱</td>
            <td class="text-left pl-2" style="width: 31%;">松柏復健科診所</td>
            <td class="text-left pl-2" style="width: 14%;">費用年月</td>
            <td class="text-left pl-2" style="width: 12%;">11207</td>
          </tr>
        </table>
        <table cellspacing="0" class="border-solid w-100 w-100-print second-border-top-print">
            <tr>
              <td class="text-left pl-2" style="width:21%">當月一日在職專任語言治療人員數</td>
              <td class="text-right px-2" style="width:10%">&nbsp;人</td>
              <td class="text-left pl-2" style="width:28%">專任語言治療人員實際執行語言治療總日數</td>
              <td class="text-right px-2" style="width:15%">&nbsp;日</td>
              <td class="text-left pl-2" style="width:14%">當月語言治療項申報案件上限數</td>
              <td class="text-right px-2" style="width:12%">&nbsp;</td>
            </tr>
        </table>
        <table class="border-solid w-100 w-100-print m-t-1mm-print" cellspacing="0">
          <tr>
            <td colspan="2" class="text-left pl-2" style="width: 20%;">語言治療</td>
            <td class="text-center" style="width:14%">簡單治療</td>
            <td class="text-center" style="width:14%">中度治療</td>
            <td class="text-center" style="width:14%">中度複雜治療</td>
            <td class="text-center" style="width:14%">複雜治療</td>
            <td rowspan="3" class="text-center" style="width:14%">總 計</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">相關科醫師申報</td>
            <td class="text-center">44001A-44003C</td>
            <td class="text-center">44004A-44006C</td>
            <td class="text-center">44013A-44016C</td>
            <td class="text-center border-right">44007A-44009C</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2">限復健專科醫師申報</td>
            <td class="text-center">&nbsp;</td>
            <td class="text-center">44011C</td>
            <td class="text-center">44015C</td>
            <td class="text-center border-right">44012C</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2" style="width: 13%;">申報件數</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報件數百分比</td>
            <td class="text-center" style="width: 7%;">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-center">100%</td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
          <tr>
            <td rowspan="2" class="text-left pl-2">申報金額</td>
            <td class="text-center">門診</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td></td>
          </tr>
          <tr>
            <td class="text-center">住院</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
            <td class="text-right pr-2">&nbsp;</td>
          </tr>
        </table>
        <div class="w-100 w-100-print d-flex mt-3 ">
            <div class="flex-grow-one">註：
            </div>
            <div class="flex-grow-one" style="width: calc(100% - 30px);">
                <ol class="pl-3 ml-0">
                    <li data-list-text="1.">
                      超次費用核扣方式：
                      <div>每月申報上限件數 = 當月專任物理治療人員實際執行物理治療總日數 * 45</div>
                      <div>門診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數) * 門診申報金額</div>
                      <div>住診核減金額 = ((總申報件數 - 每月申報上限件數 ) / 總申報件數)</div>
                    </li>
                    <li data-list-text="2.">核減率計算至小第四位，第五位四捨五入</li>
                    <li data-list-text="3.">醫院請填寫一式兩份，分送門診費用組及住院費用組</li>
                  </ol>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
