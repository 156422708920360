import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: '[searchBarField]'
})
export class SearchBarFieldDirective implements OnInit{
    @Input()
    set searchBarField(v){
        this.fieldTitle = v;
    }
    /** 欄位標題文字 */
    @Input()
    fieldTitle:string = '';
    /** 顯示隱藏 */
    @Input()
    hidden:boolean = false;
    /** 標題隱藏 */
    @Input()
    displayTitle:boolean = true;
    @Input()
    titleCls:string[] = [];

    cls:string;
    /**
     *
     */
    constructor(public elementRef: ElementRef) {

    }
    ngOnInit(): void {
        if(!this.fieldTitle){
            this.fieldTitle = 'R'+ Math.ceil(Math.random()*10000000);
            this.displayTitle = false;
        }
        this.cls = 'sb-field-'+this.fieldTitle;
        (this.elementRef.nativeElement as HTMLElement).classList.add(this.cls)
    }
}
