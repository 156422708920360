import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { RouteInfoService } from "../../route-info.service";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { Entity } from "src/app/shared/models/entity";


export enum HelpPadTab{
    CC,
    PE,
    DX,
    RX,
    Set,
    HomeCare,
    SP,
}
export enum HelpPadType{
    Self = 0,
    Clinic = 1
}
export interface HelpPadGroups extends Entity {
    //Id:number
    ClinicId :number;
    UserId?:number;
    Tab: HelpPadTab;
    Type: HelpPadType;
    GroupName:string;
    PrevId:number;
    HelpPadItems:HelpPadItems[]
  }
  
  export interface HelpPadItems extends Entity {
    //Id:number;
    GroupId:number;
    ItemCode:string;
    SN:number;
  }
@Injectable({
    providedIn: 'root'
})
  
export class HelpPadApi {
    private api: WebApiService;
    constructor(private webApiFactory: WebApiClient) {
        this.api = this.webApiFactory.createHisService('Hist/helppad');
        this.api.enableLoader = false;
    }
    
    public async GetHelperPadGroups():Promise<HelpPadGroups[]>{
        return this.api.get('Get').toPromise<HelpPadGroups[]>()
    }
    public async AddGroup(group:HelpPadGroups):Promise<number>{
        return this.api.post('AddGroup',group).toPromise<number>()
    }
    public async DeleteGroup(group:HelpPadGroups){
        return this.api.delete('DeleteGroup?groupId='+group.Id).toPromise()
    }
    public async UpdateGroup(group:HelpPadGroups){
        return this.api.post('UpdateGroup',group).toPromise()
    }
}