/** 申報方式 Enum */
export enum DeclareWayEnum {
    /** 1.書面 */
    Paper = 1,
    /** 2.媒體 */
    Media = 2,
    /** 3.網路 */
    Network = 3
}
export const DeclareWayDesc: {text: string, value: number}[] =
    [{text: '書面', value: 1 },
     {text: '媒體', value: 2 },
     {text: '網路', value: 3 }];

