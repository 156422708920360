<app-vis-window title=""
  class="opd-helper-pad"
  [minWidth]="600"
  [width]="winWidth"
  [minHeight]="400"
  [height]="winHeight"
  [left]="winLeft"
  [top]="winTop"
  (close)="closeWindow()"
  [resizable]="true"
  (sizeChanged)="winSizeChanged($event)">
    <app-vis-window-titlebar>
      <div class="width-100 d-flex">
        <div style="flex-grow: 1;" class="lh-lg lh-base text-white pl-1">
          <span>{{tab.name}}</span>
        </div>

      </div>
    </app-vis-window-titlebar>
    <div class="help-tab-win-body">
      <!-- 次選項 -->
      <div *ngIf="tab?.subTab && tab?.subTab?.length>0" class="toolbar">
        <div class="tab-btn" [class.selected]="this.subtab==null"  title="全部" (click)="changeSubtabType(null)" i18n="@@HistDiag">全部</div>
        <div class="tab-btn" [class.selected]="this.subtab==subTab" [title]="subTab.name" (click)="changeSubtabType(subTab)"
             *ngFor="let subTab of tab.subTab" i18n="@@HistDiag">{{subTab.name}}</div>
      </div>
      <!-- 搜尋 -->
      <div class="toolbar">
        <div class="search-icon position-relative">
          <app-icon-search class="mx-1" (click)="onBtnQueryClick()" i18n="@@Query"></app-icon-search>
          <input class="vis-input" placeholder="請輸入查詢名稱"
          [(ngModel)]="queryText" class="tab-input pr-3" (keypress)="queryKeydown($event)"  />
          <mat-icon  (click)="clearQuery()" i18n="@@Query" role="img" title="清除" class="position-absolute m-auto bottom-0 cursor-pointer mat-icon float-left d-inline-flex"
                      style="font-size: 1.2rem;right: 0px;top: 6px;;">clear</mat-icon>
        </div>
      </div>
      <!-- 查詢結果 -->
      <div class="overflow-y-auto" style="flex-grow: 1;" >
        <div *ngFor="let item of filteredItems">
          <ng-container *ngTemplateOutlet="gridItem;context:{$implicit:item}"></ng-container>
        </div>
      </div>
    </div>
    <!-- 針灸圖 -->
    <!-- <ng-container *ngIf="isTabAcuPic">
      <app-hist-acu-chart (acuPointClicked)="acuPointClicked($event)"></app-hist-acu-chart>
    </ng-container> -->

</app-vis-window>


<!--清單項目-->
<ng-template let-item #gridItem>
  <div (click)="onItemClick($event,item.code)" class="grid-item wid50item" style="display: flex">
    <div class="preventHover enable-hover" style="max-height: 21px;" >
      <mat-icon class="pref-icon add">
        add_circle
      </mat-icon>
    </div>
    <div style="display:flex;flex-grow: 1;overflow: hidden; " class="grid-item-content">
      <div class="ellipsis-content" style="flex-grow: 1;padding-left: 5px;" [title]="item.name">
        <span *ngIf="item.flagDescription?.includes('STOP;')" style="color: red;">停|</span>
        <span *ngIf="item.flagDescription?.includes('ALTERNATIVE;')" style="color: blue;">替|</span>
        <span *ngIf="item.flagDescription?.includes('a1;')" style="color: var(--light-purple-color);">瓶|</span>
        {{item.code}}-{{item.name}}
      </div>
    </div>
  </div>
</ng-template>
