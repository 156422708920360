import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { ReserveItem } from '../../reserve.service';
import { ReserveCreateDto } from '../../api-service/register/register-api';

@Injectable({
  providedIn: 'root'
})
export class ReserveApiService {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createDentistService('reserve');
  }
  /** 取得預約清單 */
  public async getAll(doctorId:number,sDate:Date,eDate:Date):Promise<ReserveItem[]>{
    return await this.apiService.get('getAll',{
      doctorId:doctorId,
      startDate:sDate.toLocaleDateString(),
      endDate:eDate.toLocaleDateString()
    }).toPromise<ReserveItem[]>()
  }
  
  /** 新增預約掛號 */
  public async Reserve(data: DentistReserveCreateDto): Promise<number> {
    return await this.apiService.post('Reserve', data).toPromise<number>()
    
  }
  /** 更新預約掛號 */
  public async Update(data: DentistReserveCreateDto): Promise<number> {
    return await this.apiService.post('Update', data).toPromise<number>()
  }
  
  /** 取消預約掛號 */
  public async Cancel(id:number): Promise<number> {
    return await this.apiService.post('cancel?id='+id, {}).toPromise<number>()
  }
}
export interface DentistReserveCreateDto extends ReserveCreateDto{
  ReserveMessage:string;
  EstimatedTime:number;
  ReserveMatters:string;
  ReminderText:string;
  ScheduleTime:Date;
}
