import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { CmsLayout, RepeatPresCol, RepeatPresRow, cmsRepeatPres, RepeatPresStyle, RepeatData } from '../repeated-pres-models';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { CmsReport, CmsReportApi } from 'src/app/services/api-service/cms-report/cms-report-api';
import { DrugBagInfo } from 'src/app/services/api-service/hist/whole-large-hist';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { style } from '@angular/animations';

@Component({
  selector: 'app-repeated-pres-dynamic',
  templateUrl: './repeated-pres-dynamic.component.html',
  styleUrls: ['./repeated-pres-dynamic.component.css']
})
export class RepeatedPrescriptionDynamicComponent implements OnInit, AfterViewInit {

  private defaultFontSize = 14;
  private defaultFontFamily='細明體';

  private data:RepeatData;
  private report:CmsReport;
  @Input()
  set cmsData(v:cmsRepeatPres){
    this._cmsData = v;
    this.updatePrintStyle();
  } 
  _cmsData = new cmsRepeatPres();
  clinic : Clinic;
  get cmsData() {
    return this._cmsData;
  }
  constructor(private histApi:HistApi,
    private regApi:RegisterApi,
    private cmsReportApi:CmsReportApi,
    private clinicData:ClinicDataService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    //this.setRegId(10267)
    
  }
  async ngAfterViewInit(){
    await this.updatePrintStyle()
  }
  async getReport(code:string){
    try{
      this.report =  await this.cmsReportApi.GetByCode(code);
      if(!this.report){
        throw '找不到報表'
      }
      if(this.report.Type == 0){
        this.cmsData = JSON.parse(this.report.Template);
      }
      await this.updatePrintStyle();
    }catch(e){
      throw e;
    }
  }
  async updatePrintStyle():Promise<void>{
    await new Promise<void>((rs,rj)=>{
      setTimeout(() => {
        if (this.reportContainer) {
          var styleNode = this.reportContainer.nativeElement.getElementsByTagName('style').item(0)
          if(styleNode){
            this.reportContainer.nativeElement.removeChild(styleNode)
          }
          
          var ss = this.cmsData.width+' '+this.cmsData.height;
          var st = document.createElement('style');
          var node = document.createTextNode(
            '@page { size: ' + ss + '; margin: 0cm; }\n' +
            '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;} div { margin:0;padding:0;}}'
          );
          
          st.appendChild(node);
          st.id = 'st';
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
        rs();
      }, 0);
    })
    
  }
  async setRegId(regId: number) {
    var param = await this.clinicData.getParam("PRN001");

    this.clinic = UserCache.getLoginUser().Clinic;
    let userCId = UserCache.getLoginUser().CId;
    let userName = UserCache.getLoginUser().Name;
    let histAndIc = await this.histApi.getRepeatedPrescription(regId, '', this.clinic, userCId, userName, false)
    var opt1 = await this.histApi.getEditOptions();
    var opt2 = await this.regApi.GetEditOptions();
    
    this.data = new RepeatData(histAndIc,param,opt1,opt2);
    
  }
  getHRx(){
    if(!this.data){
      return [];
    }
    return this.data.getHrx();
  }
  getData(col:RepeatPresCol,arg:{tp:'hrx',ind:number}){
    if(!this.data){
      return '';
    }
    if(arg?.tp=='hrx'){
      return this.data.getHrxData(arg.ind,col.dataField);
    }else{
      return this.data.getData(col.dataSource,col.dataField);
    }
    
  }
  mergeStyle(a:RepeatPresStyle,b:RepeatPresStyle){
    var r =  Object.assign({},a,b);
    //console.log(i,r)
    return r;
  }
  sizeStyle(){
    return{
      width:this.cmsData.width,
      height:this.cmsData.height,
      padding:this.cmsData.padding,
      //width:this.cmsData.width.indexOf('in')>0?('calc('+this.cmsData.width+' / 0.96)'):this.cmsData.width,
      //height:this.cmsData.height.indexOf('in')>0?('calc('+this.cmsData.height+' / 0.96)'):this.cmsData.height,
    }
  }
  innerSizeStyle(){
    return{
      width:this.cmsData.isHelf?'50%':'100%',
      height:'100%',
      overflow:'hidden'
    }
  }
  rowStyle(row:RepeatPresRow,st:RepeatPresStyle){
     return{
      flexWrap:row.wrap?'wrap':'nowrap',
      columnGap:(row.spacing||0)*(st?.fontSize||this.defaultFontSize)/2 +'px',
    }
  }
  style(st:RepeatPresStyle,value:string,isDiv= false){
    var s = {
      marginLeft:(st?.padLeft||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      fontSize:(st.fontSize||this.defaultFontSize)+'px',
      fontFamily:st.fontFamily||this.defaultFontFamily,
      textAlign:st.textAlign||'left',
      // display:st.isBlock?'block':'',
      textOverflow:st.textOverflow??'clip',
      // border:st.isNotBorder?'':'1px black solid',
      border:st.border,
      // borderTop:st.border ?'':st.borderTop,
      // borderBottom: st.border ?'':st.borderBottom,
      // borderLeft: st.border ?'':st.borderLeft,
      // borderRight: st.border ?'':st.borderRight,
      lineHeight:st.lineheight > 0? (st.lineheight*1.25*100).toString() +'%':'125%',
      // border-collapse: 'collapse',
    }
    if (!st.border){
      s = Object.assign(s,{
        borderTop:st.border ?'':st.borderTop,
        borderBottom: st.border ?'':st.borderBottom,
        borderLeft: st.border ?'':st.borderLeft,
        borderRight: st.border ?'':st.borderRight,
      });

    }
    if(st.width && !isDiv){
            s = Object.assign(s,{
        width: st.width + 'cm',
      });
      // s = Object.assign(s,{
      //   width:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      //   minWidth:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px',
      //   maxWidth:(st?.width||0)*(st?.fontSize||this.defaultFontSize)/2+'px'
      // });
    }
    return s;
      
  }
  styleBorder(st:RepeatPresStyle,value:string,isDiv= false){
    var s = {
      textOverflow:st.textOverflow??'clip',
      border:st.border,
      // borderTop:st.border ?'':st.borderTop,
      // borderBottom: st.border ?'':st.borderBottom,
      // borderLeft: st.border ?'':st.borderLeft,
      // borderRight: st.border ?'':st.borderRight,
    }
    if (!st.border){
      s = Object.assign(s,{
        borderTop:st.border ?'':st.borderTop,
        borderBottom: st.border ?'':st.borderBottom,
        borderLeft: st.border ?'':st.borderLeft,
        borderRight: st.border ?'':st.borderRight,
      });

    }
    return s;
      
  }
  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;
  async getHtml() {
    // if(this.report.Type==1){
    //   var layout:CmsLayout = JSON.parse(this.report.Template);
    //   var d = document.createElement('div');
    //   d.style.display = 'flex';
    //   d.style.flexWrap= 'wrap';
    //   d.style.width= layout.width;
    //   d.style.height = layout.height;
      

    //   for(let code of layout.contents){
    //     // 容器
    //     var d2 = document.createElement('div');
    //     d2.style.width= 'calc(100% / '+ layout.cols+')';
    //     // 取內容
    //     var r = await this.cmsReportApi.GetByCode(code);
    //     this.cmsData = JSON.parse(r.Template);
    //     // 等待繪製
    //     await new Promise<void>((rs,rj)=>{
    //       setTimeout(() => {
    //         // 置入容器
    //         var html = this.reportContainer.nativeElement.innerHTML;    
    //         d2.innerHTML = html;
    //         rs();
    //       }, 0);
    //     })
    //     // 加入配置
    //     d.appendChild(d2);
    //   }
      
    //   var ss = this.cmsData.width+' '+this.cmsData.height;
    //   var st = document.createElement('style');
    //   var node = document.createTextNode(
    //     '@page { size: ' + ss + '; margin: 0cm; }\n' +
    //     '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;} div { margin:0;padding:0;}}'
    //   );
      
    //   st.appendChild(node);
    //   st.id = 'st';
    //   d.appendChild(st);
    //   // 取出html
    //   var h = d.outerHTML;
    //   return h;
    // }else{
      var html = this.reportContainer.nativeElement.innerHTML;
      return html;
    // }
    
  }

  
}
