import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-shared-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.css']
})
export class InfoDialogComponent implements OnInit {

  constructor() {
  }

  @Input() isDialogOpened = false;

  // 點OK按鈕時emit true，Close或Cancel按鈕emit false
  @Output() emitResult = new EventEmitter<boolean>();

  ngOnInit() {
  }

  // 交給父元件用this.isDialogOpened來控制視窗開關
  // 對話視窗
  onDialogClose(isOk: boolean) {
    this.emitResult.emit(isOk);
  }
}
