import { option } from 'src/app/shared/models/dropdown-list';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, ElementRef, AfterViewInit, ViewChildren, QueryList, NgZone, HostListener, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, AsyncValidatorFn, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
// his - shared
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ValidationTipComponent as ValidTip } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { IConfirmLeave, RouteInfoService } from 'src/app/services/route-info.service';
import { EditControlButtonsComponent } from 'src/app/shared/components/edit-control-buttons/edit-control-buttons.component';
import { BaseConfig, FunctionEnable } from 'src/app/services/base-config.service';
// this module
import { PatientBasicPrint, PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { ContactDto } from 'src/app/services/api-service/patient/contactDto';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { EditModeEnum } from 'src/app/shared/enums/edit-mode-enum';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { Or, onGetDateString } from 'src/app/shared/utilities';
import { PatientEditOption } from 'src/app/services/api-service/patient/patient-edit-option';
import { ZipCodeHelper } from 'src/app/shared/helpers/zipcode-helper';
import { PostAreaAPI } from 'src/app/services/api-service/postarea-api';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { UserCache } from 'src/app/services/user-cache';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { RegisterService } from 'src/app/services/register-service';
import { PatientContactComponent } from '../patient-contact/patient-contact.component';
import { MedLackAreaApi } from "src/app/services/api-service/medlackarea/medlackarea-api";
import { MedLackArea } from "src/app/services/api-service/medlackarea/medlackarea";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { DrugAllergyComponent } from 'src/app/shared/components/patients/drug-allergy/drug-allergy.component';
import { PatientRemarkDialogComponent } from 'src/app/shared/components/patients/patient-remark-dialog/patient-remark-dialog.component';
import { PatientConst } from 'src/app/shared/Constants/patient-const';
import { ClinicDataService, ValueTextPairBundle, EnumTypes, NumberValueTextPairBundle } from 'src/app/services/data-service/clinic-data-service';
import { SexEnum } from 'src/app/enums/SexEnum';
import { DatePipe } from '@angular/common';
import { VisDatepickerComponent } from 'src/app/shared/components/datepicker/vis-datepicker/vis-datepicker.component';
import { VisDropdownlistComponent } from 'src/app/shared/components/dropdownlist/vis-dropdownlist/vis-dropdownlist.component';
import { PatientForPrint } from 'src/app/system/parameter-profile/models/parameter-profile';
import { ReportHistIndexComponent } from 'src/app/system/report/components/declare/report-hist-index/report-hist-index.component';
import { VisDialogComponent } from 'src/app/shared/components/dialog/vis-dialog/vis-dialog.component';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { IICHelper, IcHelper } from 'src/app/shared/helpers/ic-helper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { DemoIcHelper } from 'src/app/shared/helpers/demoic-helper';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { OpHelper } from 'src/app/registers/helpers/op-helper';
import { StdSystemCodeImportComponent } from 'src/app/shared/components/system-code/std-system-code-import/std-system-code-import.component';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { SystemCodeType } from 'src/app/services/api-service/system-code/system-code-type';
import { SystemcodetypeApi } from 'src/app/services/api-service/system-code/systemcodetype-api';
import { SystemCodeListGridViewComponent } from 'src/app/shared/components/system-code/system-code-list-grid-view/system-code-list-grid-view.component';
import { BaseComponent } from 'src/app/shared/components/base/base.component';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';


@Component({
  selector: 'app-patients-edit',
  templateUrl: './patients-edit.component.html',
  styleUrls: ['./patients-edit.component.css']
})
export class PatientEditComponent extends BaseComponent implements OnInit, OnDestroy, IConfirmLeave {
  @ViewChild(EditControlButtonsComponent, { static: false })
  editControlButtons: EditControlButtonsComponent;
  @ViewChild('zipCodeRes')
  zipCodeResEl: ElementRef<HTMLInputElement>
  @ViewChild('zipCode', { static: false })
  zipCodeEl: ElementRef<HTMLInputElement>
  @ViewChildren(PatientContactComponent)
  contactComps: QueryList<PatientContactComponent>;
  @ViewChild(DrugAllergyComponent)
  drugAllergyComp: DrugAllergyComponent;
  @ViewChild(PatientRemarkDialogComponent)
  patientRemarkDialogComp: PatientRemarkDialogComponent;
  @ViewChild('cidFocusElement') cidFocusElement!: ElementRef;
  @ViewChild('birthdayFocusElement') birthdayFocusElement!: VisDatepickerComponent;
  @ViewChild('paySysAFocusElement') paySysAFocusElement!: VisDropdownlistComponent;
  @ViewChild('report')
  reports: ReportHistIndexComponent;
  @ViewChild('respTextAreaSampling')
  respTextArea: ElementRef<HTMLTextAreaElement>;

  @ViewChild(SystemCodeListGridViewComponent, { static: false })
  gridViewComponent: SystemCodeListGridViewComponent;

  // 編輯時需要的選項或數值
  //@Input()
  editOptions: PatientEditOption = new PatientEditOption();
  // 資料更新完成後，傳給父元件
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onUpdated = new EventEmitter();
  @Output() public emitEnterEdit = new EventEmitter();
  @Output() public emitEnterView = new EventEmitter();
  @Output() public parentQuery = new EventEmitter();
  @Output() public ExitPatEvent = new EventEmitter();
  // @Input() parentQueryData: any;
  @Input() isFromCompEnter: boolean;
  @Input()
  set parentQueryData(queryData: any) {

    this.listqueryData = queryData ?? [];
  }
  get parentQueryData() {
    return this.listqueryData;
  }
  listqueryData: any;
  getOneRecordSuccess = new EventEmitter();
  getOneRecordFail = new EventEmitter();
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const isChineseInputSwitchKey = event.key === 'Shift' || (event.altKey && event.key === 'Shift');

    if (isChineseInputSwitchKey) {
      event.preventDefault();
    }
  }

  showCreate: boolean = false;
  addData = new SystemCode('', '');
  showSetting: boolean = false;
  importTargetSystemCode: string = '';
  isCanEdit: boolean = false;
  //已選擇的系統代碼類型
  currentClinicId?: number;
  // 系統代碼清單
  systemTypeList: SystemCodeType[];

  constructor(
    private fb: FormBuilder,
    private router: Router, private route: ActivatedRoute,
    private routeInfo: RouteInfoService,
    private notification: EasyNotificationService,
    private patientApi: PatientApi,
    private postAreaAPI: PostAreaAPI,
    private userConfirm: UserConfirmService,
    private icHelper: IcHelper,
    private demoIcHelper: DemoIcHelper,
    private hcrService: HcrService,
    private MedLackAreaApi: MedLackAreaApi,
    private opHelper: OpHelper,
    private loaderService: MainLayoutService,
    private registService: RegisterService,
    private cdref: ChangeDetectorRef,
    public clinicData: ClinicDataService,
    private renderer: Renderer2,
    private zone: NgZone,
    private localPrint: LocalPrintService,
    private notify: EasyNotificationService,
    private mainLayoutService: MainLayoutService,
    private cdRef: ChangeDetectorRef,
    private systemcodeApi: SystemcodeApi
  ) {
    super();
    this.patientApi.apiService.enableLoader = false;
    const mode_from_route = this.route.snapshot.params['mode'];
    if (mode_from_route) {
      this.mode = mode_from_route;
    }
    this.routeInfo.comp = this;
  }
  getIcHelper(): IICHelper {
    if (this.hcrService.isDemoMode) {
      this.demoIcHelper.setPatient(this.patientId);
      return this.demoIcHelper;
    } else {
      return this.icHelper;
    }
  }
  confirmExit(): string {
    if ((this.editControlButtons.editMode === EditModeEnum.add
      || this.editControlButtons.editMode === EditModeEnum.edit)
      && this.editFormGroup.dirty) {
      return '資料尚未儲存，是否離開？';
    }
    return '';
  }

  //#region 變數 ----------

  // 模式
  mode: string = PatientConst.mode_basic; // 預設模式
  editOrNewAddfcous = false;
  // 初始化參數 來自路由
  // 起始的patientId

  @Input()
  patientId = 0;

  newPatients: PatientsDto;
  newPatientData: PatientsDto; // 新增病患時的病患初始參數
  mainContact: ContactDto = null;
  contacts: ContactDto[];
  isSameAddressCheck = false;
  isTheHandiCappChecked = false;
  canDelete = true;
  // remark-dialog 視窗開關控制
  isRemarkWindowOpened = false;
  isDrugAllergyWindowOpened = false;

  isDrugAllergyDialogOpened: boolean = false;
  isPatientRemarkDialogOpened: boolean = false;
  switchDrugAllergyDialog: boolean = false;
  switchPatientRemarkDialog: boolean = false;
  isNewBorn: boolean = false;
  // 初始化參數
  maxFirstVisitDate = DateHelper.todayMax; // 最大可選之初診日為今日，不可選未來日期的意思
  todayMax = DateHelper.todayMax;
  //#endregion ----------

  //#region 變數 UI ----------
  isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  // 預估年齡
  estAge: number;

  // CId欄位的顯示格式mask
  cIdMask = '';

  enableQRCode = FunctionEnable.UserLineNotifyQRCode;
  //#endregion 變數 UI ----------

  //#region 變數 表單 ----------
  // 表單內容
  editFormGroup: FormGroup;
  get editFormValue() {
    return this.editFormGroup.value;
  }
  get editFormId() {
    return this.editFormGroup?.getRawValue()?.Id || 0;
  }
  city: ValueTextPair[];
  area: ValueTextPair[];
  // 居住地
  adrHelper: ZipCodeHelper;
  areaFilterd: ValueTextPair[];
  //戶籍地
  adrHelperRes: ZipCodeHelper;
  areaFilterdRes: ValueTextPair[];
  //#endregion 變數 表單 ----------

  //#region 變數 route ----------
  routeRegister = '/registers/register';
  routeRegList = '/registers/reg-list';
  routeOpdList = '/registers/opd-list';
  routeOpe = '/hist/record'
  //#endregion 變數 route ----------
  inputPhoneValue: number;
  /** 基資姓名英文 */
  inputValue: string;
  /** 列印預覽 */
  previewData: PatientBasicPrint[] = [];
  printData: PatientBasicPrint[] = [];
  clinicName = '';
  relationOpt: ValueTextPair[];

  systemCodeTypeTarget: string = "";

  //#region 初始化
  async ngOnInit() {
    this.editFormGroup = this.makeFormGroup();
    this.getSystemCodeOptions(this.systemCodeTypeTarget);

    this.disableForm();
    this.cdref.detach();
    setTimeout(async () => {
      await this.getEditOptions();
      await this.getMedLackArea();

      // 若路由有傳遞參數，以路由為主
      if (this.mode === PatientConst.mode_regList || this.mode === PatientConst.mode_opdList || this.isFromCompEnter) {
        this.getParamsForEdit();
      } else if (this.mode === PatientConst.mode_firstVisit) {
        // 複診作業 掛號 不選患者的新增
        this.getParamsForAdd();
      }

      await this.createZipHelper();

      if (this.isFromCompEnter) {
        await this.edit(this.patientId);
      } else {
        if (this.mode != PatientConst.mode_basic && this.patientId) {
          await this.edit(this.patientId);
        } else {
          await this.view(this.patientId);
        }
      }
      this.editFormGroup.markAsPristine();
      //this.loaderService.hideLoader();
    }, 0);
    this.cdref.reattach();
    this.setClinicInfo();

  }

  get hasQueryCondition() {
    return this.currentClinicId && this.systemCodeTypeTarget;
  }

  setClinicInfo() {
    const user = UserCache.getLoginUser();
    this.clinicName = user.Clinic.Name;
    this.currentClinicId = UserCache.getLoginUser().Clinic.Id;
  }

  // 取得來自路由的參數，為了編輯病患基本資料
  getParamsForEdit() {
    const patientId = this.route.snapshot.params['patientId'];
    if (patientId) {
      this.patientId = patientId;
    }
  }
  // 取得來自路由的參數，為了新增病患基本資料
  getParamsForAdd() {
    this.newPatients = new PatientsDto();

    const FirstName = this.route.snapshot.params['CName'];
    if (FirstName) {
      this.newPatientData.CName = FirstName;
    }
    const Birthday = this.route.snapshot.params['Birthday'];
    if (Birthday) {
      this.newPatientData.Birthday = Birthday;
    }
    const PhoneHome = this.route.snapshot.params['PhoneHome'];
    if (PhoneHome) {
      this.newPatients.Phone = PhoneHome;
    }
    const Mobile = this.route.snapshot.params['Mobile'];
    if (Mobile) {
      this.newPatients.CellPhone = Mobile;
    }
    const CId = this.route.snapshot.params['CId'];
    if (CId) {
      this.newPatientData.CId = CId;
    }
  }

  get isChineseName() {
    return BaseConfig.getConfig().patient.isChineseName;
  }
  //#endregion

  // #region 表單物件-建立,銷毀
  // 移除editFormGroup
  destroyEditFormGroup() {
    if (this.editFormGroup) {
      // 如果不reset，kendo dropdownlist等元件的值不會清除
      // 後面加上detectChanges後應該可以省略
      this.editFormGroup.reset(); // 清除form group資料
    }
  }

  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      Id: [0],
      ClinicId: [0],
      PatientNo: [''],
      Kcstmr: ['', Validators.maxLength(10)],
      CName: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(ValidTip.patternRuleCName)]],
      MiddleName: ['', Validators.maxLength(50)],
      LastName: ['', Validators.maxLength(50)],
      Nickname: ['', Validators.maxLength(30)],
      CId: ['', [Validators.required, this.CIDValidator()
        //, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(ValidTip.patternLetterNumber)
      ]],
      Birthday: ['', [Validators.required]],
      Sex: [999, Validators.required],
      Country: ['', Validators.maxLength(50)],
      BlRH: [0, Validators.required],
      BlType: [0, Validators.required],
      Married: [0],
      PhoneArea: ['', Validators.maxLength(3)],
      Phone: ['', Validators.maxLength(20)],
      CellPhone: ['', Validators.maxLength(20)],
      EMail: ['', Validators.maxLength(50)],
      FoodAllergy: ['', Validators.maxLength(2000)],
      DrugAllergy: ['', Validators.maxLength(2000)],
      DiseaseHistory: [''],
      DiseaseHistoryRemark: [''],
      Remark: ['', Validators.maxLength(1000)],
      NeedToCheckCName: [true, [Validators.required]],
      DiscountId: [0], // 40
      DiscountCode: [''],
      ICode: [''],
      ZipCode: ['', [Validators.maxLength(10)]],
      Nation: ['', Validators.maxLength(50)],
      State: ['', Validators.maxLength(50)],
      City: ['', Validators.maxLength(50)],
      Area: ['', Validators.maxLength(50)],
      Street: ['', [Validators.maxLength(200)]], // 30
      IsSMS: [false, [Validators.required]],
      IsAgreeMsg: [false, [Validators.required]],
      IsCheckCId: [false, [Validators.required]], // 17
      SupArea: [null],
      PaySysA: [null],

      PatientHisId: [0],
      Title: [''],
      EstBirthYear: [0],
      Age: [0, [Validators.max(120)]],
      EducationCode: [''],
      Family: ['', Validators.maxLength(20)],
      JobCode: [''],
      StatusCode: [''],
      ShortNo: ['', Validators.maxLength(10)],
      FirstDate: [null],
      LastDate: [null],
      LastMCDate: [null],
      DueDate: [null],
      SpcIdentity: [''],
      SoldierEdt: [null],
      MonParentsNo: [''],
      BirthsNum: [null],
      Handicapp: [''],
      MissTimes: [0],
      Height: [0],
      Weight: [0],
      BMI: [0],
      SocialInusranceNo: ['', [Validators.maxLength(18)]],
      RaceCode: [''],
      MSTS: [0],
      ResZipCode: ['', Validators.maxLength(10)],
      ResCity: ['', Validators.maxLength(50)],
      ResArea: ['', Validators.maxLength(50)],
      ResStreet: ['', [Validators.maxLength(200)]], // 30
      RemarkOne: [''],
      FirstDialysisDate: [null],
      MajorInjuryTimes: [''],
      MajorInjuryDate: [null],
    });

    this.isTheHandiCappChecked = false;
    fg.controls.Sex.valueChanges.subscribe((v) => {
      if (v == 0) {
        this.editFormGroup.controls.LastMCDate.enable();
        this.editFormGroup.controls.DueDate.enable();
      } else {
        this.editFormGroup.controls.LastMCDate.disable();
        this.editFormGroup.controls.DueDate.disable();
      }
    })
    // 新增時的固定預設值
    var clinicId = UserCache.getLoginUser().Clinic.Id;

    fg.controls.ClinicId.setValue(clinicId);
    // <!-- 2023/06/12 沈協理 feeback: 電話和手機應該為非必填，方便快速讓病患掛號 -->
    // var createPhoneValidator = () => {
    //   return (c: AbstractControl) => {
    //     var phoneCtrl = this.editFormGroup.controls['Phone'];
    //     var cellCtrl = this.editFormGroup.controls['CellPhone'];
    //     // 不能用formValue,還沒更新
    //     if (!cellCtrl.value && !phoneCtrl.value) {
    //       // 這個timeout是要等錯誤回傳後更新狀態才能去驗證另一個，不然會無限遞迴
    //       setTimeout(() => {
    //         if (phoneCtrl.valid) {
    //           phoneCtrl.updateValueAndValidity();
    //         }
    //         if (cellCtrl.valid) {
    //           cellCtrl.updateValueAndValidity();
    //         }
    //       }, 0);
    //       // 狀態有效更新成無效
    //       return {'custom':'手機/電話擇一填寫'}
    //     }
    //     setTimeout(() => {
    //       if (phoneCtrl.invalid) {
    //         phoneCtrl.updateValueAndValidity();
    //       }
    //       if (cellCtrl.invalid) {
    //         cellCtrl.updateValueAndValidity();
    //       }
    //     }, 0);
    //   };
    // }
    // fg.controls.Phone.setValidators(createPhoneValidator().bind(this));
    // fg.controls.CellPhone.setValidators(createPhoneValidator().bind(this));


    return fg;
  }
  async createZipHelper() {
    if ((!this.zipCodeEl) || !this.zipCodeResEl) { return; }
    if (this.isSameAddressCheck) { return; }
    this.adrHelper = new ZipCodeHelper(this.postAreaAPI, this.userConfirm, this.zipCodeEl.nativeElement,
      this.editFormGroup.controls.ZipCode, this.editFormGroup.controls.City,
      this.editFormGroup.controls.Area, this.editFormGroup.controls.Street)
    this.adrHelper.onAreaChange.subscribe(v => {
      this.city = v.City;
      this.areaFilterd = v.Area;
    });
    this.adrHelperRes = new ZipCodeHelper(this.postAreaAPI, this.userConfirm, this.zipCodeResEl.nativeElement,
      this.editFormGroup.controls.ResZipCode, this.editFormGroup.controls.ResCity,
      this.editFormGroup.controls.ResArea, this.editFormGroup.controls.ResStreet)
    this.adrHelperRes.onAreaChange.subscribe(v => {
      this.city = v.City;
      this.areaFilterdRes = v.Area;
    });
    await this.adrHelper.init();

    await this.adrHelperRes.init();
  }
  // lockReadonlyColumns() {
  //   this.editFormGroup.controls.DrugAllergy.disable();
  // }
  // unlockReadonlyColumns() {
  //   this.editFormGroup.controls.DrugAllergy.enable();
  // }
  //#endregion

  //#region 取得表單編輯選項

  // 由Server端取得編輯時需要的選項或數值
  systemCodes: ValueTextPairBundle = {};
  enumsCodes: NumberValueTextPairBundle = {};
  getOption(code): (ValueTextPair | ValueTextPairNumberValue)[] {
    return this.systemCodes[code] || this.enumsCodes[code];
  }
  async getEditOptions() {
    this.systemCodes = await this.clinicData.getSystemCodes([
      'E0050',// E0050 既往病史
      'E0060',// E0060 家族病史
      'E0070',// E0070 手術病史
      'E0080',// E0080 食物環境過敏史

      //'B0010',// B0010 國籍
      //'B0020',// B0020 種族
      'B0030',// B0030 職業代碼
      'B0040',// B0040 教育程度
      //'B0060',// B0060 關系人類型(緊急連絡人)
      //'B0210',//  B0210 稱謂
      'B0270',// B0270 備註一

      'C0010',// C0010 保險身分
      'C0130',// C0130 病歷狀態
    ])
    this.systemCodes.B0030 = [{ value: '', text: '' }].concat(this.systemCodes.B0030);
    this.systemCodes.B0040 = [{ value: '', text: '' }].concat(this.systemCodes.B0040);
    this.systemCodes.C0010 = [{ value: '', text: '' }].concat(this.systemCodes.C0010);
    this.systemCodes.C0130 = [{ value: '', text: '' }].concat(this.systemCodes.C0130);

    //將value=text, 直接存文字
    // for(let i = 0;i<this.systemCodes.B0270.length;i++) {
    //   this.systemCodes.B0270[i].value = this.systemCodes.B0270[i].text;
    // }
    this.systemCodes.B0270 = this.systemCodes.B0270.map(d => { return { value: d.text, text: d.text } });

    this.enumsCodes = await this.clinicData.getEnum(['Sex', 'Blood', 'RH', 'Married'])
    this.editOptions = await this.patientApi.GetEditOption();
  }
  //#endregion

  //#region View
  // 是否顯示
  isShow(): boolean {
    if (this.editFormGroup) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion

  //#region 新增,編輯
  // 新增
  async add() {
    this.editFormGroup.reset();
    this.isSameAddressCheck = false;
    if (this.mode === PatientConst.mode_firstVisit) {

      if (this.newPatientData.CName) {
        this.editFormGroup.controls['CName'].setValue(this.newPatientData.CName);
      }
      if (this.newPatientData.Birthday) {
        this.editFormGroup.controls['Birthday'].setValue(new Date(this.newPatientData.Birthday));
      }
      if (this.newPatients.Phone) {
        this.editFormGroup.controls['Phone'].setValue(this.newPatients.Phone);
      }
      if (this.newPatients.CellPhone) {
        this.editFormGroup.controls['CellPhone'].setValue(this.newPatients.CellPhone);
      }
      if (this.newPatientData.CId) {
        this.editFormGroup.controls['CId'].setValue(this.newPatientData.CId);
      }
    }

    //this.setValidatorsForMode();
    this.editControlButtons.toAddMode(); // [按鈕列]切換到[新增]模式，並啟用所有按鈕
    this.enableForm();
    this.editFormGroup.controls['FirstDate'].setValue(DateHelper.today);
    this.editFormGroup.controls['LastDate'].setValue(DateHelper.today);
    this.editFormGroup.controls.LastMCDate.disable();
    this.editFormGroup.controls.DueDate.disable();
    this.editFormGroup.controls['IsSMS'].setValue(true);
    this.editFormGroup.controls['IsCheckCId'].setValue(true);
    this.editFormGroup.controls['IsAgreeMsg'].setValue(true);
    this.editFormGroup.controls['NeedToCheckCName'].setValue(true);

    try {
      var patNo = await this.patientApi.PreviewPatientNo();
      this.editFormGroup.controls['PatientNo'].setValue(patNo);
    } catch { }

    this.editFormGroup.patchValue(this.editOptions.defaultData);

    this.editFormGroup.controls.DrugAllergy.disable();
    this.editFormGroup.markAllAsTouched();
    this.contacts = [];
    this.mainContact = new ContactDto(0);
    this.emitEnterEdit.emit();
    this.nameInput.nativeElement.focus();
  }
  @ViewChild('nameInput')
  nameInput: ElementRef<HTMLInputElement>;

  viewFromSave() {
    // 這邊不多跳一次生命週期會導致formGroup.disable以後disabled狀態沒更新(formgroup的bug)
    setTimeout(() => {
      this.disableForm();
      this.editControlButtons.toViewMode();
      this.emitEnterView.emit();
    }, 0);
  }

  async viewFromEdit() {
    this.disableForm();
    await this.view(this.patientId); // 重讀一次資料是因為edit時可能有修改，但不確定是否有存檔

    this.emitEnterView.emit();
  }


  // get SexCodeRequired(): string {
  //   if (this.isAdd()) {
  //     return 'required';
  //   } else {
  //     return '';
  //   }
  // }

  // 編輯

  async view(id: number) {
    if (id) {
      this.patientId = id;
      await this.getOneRecord(id); // 由server端抓取此筆資料
    }
    this.disableForm();
    this.editControlButtons.toViewMode();
    this.emitEnterView.emit();
    this.drugAllergyComp.reloadData(this.drugAllergySelectedText);
    this.patientRemarkDialogComp.reloadData(this.remarkSelectedText);
  }

  get arrayResults() {
    return (this.editFormGroup.get('Contacts') as FormArray);
  }

  editFromView() {
    this.enableForm();
    //this.editFormGroup.controls.DrugAllergy.disable();
    var sex = this.editFormGroup.controls.Sex.value;
    if (sex == 0) {
      this.editFormGroup.controls.LastMCDate.enable();
      this.editFormGroup.controls.DueDate.enable();
    } else {
      this.editFormGroup.controls.LastMCDate.disable();
      this.editFormGroup.controls.DueDate.disable();
    }
    this.editControlButtons.toEditMode();
    // 生日有值則年齡不可編輯
    // if (this.editFormGroup.value.Birthdate) {
    //   this.editFormGroup.controls.Age.disable();
    // }
    this.emitEnterEdit.emit();
    this.nameInput.nativeElement.focus();
  }
  // 編輯
  async edit(id: number) {
    if (!id) {
      this.notification.showParameterError(); // 缺少必要參數，顯示錯誤訊息
      return;
    }
    this.patientId = id;
    await this.getOneRecord(id); // 由server端抓取此筆資料
    this.editFromView();
    this.SameAddressCheck(); // ckeck 同戶籍地址 in edit mode
  }

  jumpToElement(element: ElementRef) {
    element.nativeElement.focus();
  }
  fullContacts = [];
  // 由server取得單筆資料並填充到表格
  async getOneRecord(id: number) {
    try {
      var data: PatientsDto = await this.patientApi.Get(id);
      this.isSameAddressCheck = false;
      this.canDelete = data.CanDelete;
      this.isNewBorn = data.BirthsNum ? true : false;
      this.fillFormData(data); // 填充表格資料
      setTimeout(() => {
        this.fullContacts = data.Contacts;
        // console.log('fullContacts',this.fullContacts[0].Name);
      }, 500)
      this.getOneRecordSuccess.emit();
    } catch (ex) {
      this.notification.showGetDataFailed('patient-edit'); // 顯示查詢失敗訊息
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
      this.getOneRecordFail.emit();
    }
  }
  // 填入表單資料
  fillFormData(data: PatientsDto) {
    this.remarkSelectedText = '';
    this.drugAllergySelectedText = '';
    if (!data) {
      return;
    }

    this.adrHelper?.disable();
    this.adrHelperRes?.disable()
    this.editFormGroup.patchValue({
      Id: data.Id || 0,
      ClinicId: Or(data.ClinicId, 0),
      PatientNo: Or(data.PatientNo, ''),
      CName: Or(data.CName, ''),
      MiddleName: Or(data.MiddleName, ''),
      LastName: Or(data.LastName, ''),
      Nickname: Or(data.Nickname, ''),
      CId: Or(data.CId?.toUpperCase(), ''),
      Birthday: data.Birthday == null ? null : DateHelper.createDate(data.Birthday),
      Kcstmr: Or(data.Kcstmr, ''),
      // 用data.Sex || '', 當data.Sex==0(女性)的時候會變成''
      Sex: Or(data.Sex, ''),
      Country: Or(data.Country, ''),
      BlRH: Or(data.BlRH, ''),
      BlType: Or(data.BlType, ''),
      Married: Or(data.Married, ''),
      PhoneArea: Or(data.PhoneArea, ''),
      Phone: Or(data.Phone, ''),
      CellPhone: Or(data.CellPhone, ''),
      EMail: Or(data.EMail, ''),
      FoodAllergy: Or(data.FoodAllergy, ''),
      DrugAllergy: Or(data.DrugAllergy, ''),
      DiseaseHistory: Or(data.DiseaseHistory, ''),
      DiseaseHistoryRemark: Or(data.DiseaseHistoryRemark, ''),
      Remark: Or(data.Remark, ''),
      DiscountId: Or(data.DiscountId, ''),
      DiscountCode: Or(data.DiscountCode, ''),// 40
      ICode: Or(data.ICode, ''),
      ZipCode: Or(data.ZipCode, ''),
      Nation: Or(data.Nation, ''),
      State: Or(data.State, ''),
      City: Or(data.City, ''),
      Area: Or(data.Area, ''),
      Street: Or(data.Street, ''),
      ResZipCode: Or(data.ResZipCode, ''),
      PaySysA: Or(data.PaySysA, ''),
      ResCity: Or(data.ResCity, null),
      ResArea: Or(data.ResArea, ''),
      ResStreet: Or(data.ResStreet, ''), // 30
      IsAgreeMsg: data.IsAgreeMsg,
      IsSMS: Or(data.IsSMS, false),
      IsCheckCId: data.IsCheckCId, // 17
      NeedToCheckCName: Or(data.NeedToCheckCName, true),

      Title: Or(data.Title, ''),
      //Age: data.Age || '',
      EstBirthYear: Or(data.EstBirthYear, ''),
      EducationCode: Or(data.EducationCode, ''),
      Family: Or(data.Family, ''),
      JobCode: Or(data.JobCode, ''),
      StatusCode: Or(data.StatusCode, ''),
      ShortNo: Or(data.ShortNo, ''),
      FirstDate: DateHelper.createDate(data.FirstDate) || null,
      LastDate: DateHelper.createDate(data.LastDate) || null,
      LastMCDate: DateHelper.createDate(data.LastMCDate) || null,
      DueDate: DateHelper.createDate(data.DueDate) || null,
      SpcIdentity: Or(data.SpcIdentity, ''),
      SoldierEdt: DateHelper.createDate(data.SoldierEdt) || null,
      MonParentsNo: Or(data.MonParentsNo, ''),
      BirthsNum: Or(data.BirthsNum, null),
      SupArea: Or(data.SupArea, null),
      Handicapp: Or(data.Handicapp, '0'),
      MissTimes: Or(data.MissTimes, ''),
      Height: Or(data.Height, ''),
      Weight: Or(data.Weight, ''),
      BMI: data.BMI, // Math.round(data.Weight/Math.pow(data.Height/100,2)*100)/100
      SocialInusranceNo: Or(data.SocialInusranceNo, ''),
      RaceCode: Or(data.RaceCode, ''),
      //PhoneCompanyArea: data.PhoneCompanyArea || '',
      //PhoneCompany: data.PhoneCompany || '',
      FirstDialysisDate: Or(data.FirstDialysisDate, ''),
      MajorInjuryTimes: Or(data.MajorInjuryTimes, ''),
      MajorInjuryDate: Or(data.MajorInjuryDate, ''),
      RemarkOne: Or(data.RemarkOne, ''),
    }, { emitEvent: false });
    this.remarkSelectedText = Or(data.Remark, '');
    this.drugAllergySelectedText = Or(data.DrugAllergy, '');
    this.adrHelper?.enable();
    this.adrHelperRes?.enable();
    this.isTheHandiCappChecked = false;
    if (this.editFormGroup.controls.Handicapp.value != null) {
      if (this.editFormGroup.controls.Handicapp.value == '1') {
        this.isTheHandiCappChecked = true;
      } else {
        this.isTheHandiCappChecked = false;
      }
    } else {
      this.isTheHandiCappChecked = false;
    }
    this.mainContact = data.Contacts.filter(x => x.MainPerson)[0] ?? new ContactDto(data.Id);
    this.mainContact.MainPerson = true;
    this.contacts = data.Contacts.filter(x => !x.MainPerson);

    this.SameAddressCheck();
    // if (this.editFormGroup.value.Birthdate) {
    // this.editFormGroup.controls.EstBirthYear.disable();
    // }
    //this.lockReadonlyColumns();
  }

  enableForm() {
    this.editFormGroup.enable({ emitEvent: false });
  }
  disableForm() {
    setTimeout(() => {
      this.editFormGroup.disable({ emitEvent: false });
    }, 0);
  }
  //#endregion

  //#region 移除依附註記
  async updateNewBorn() {
    // 讀取IC卡內容
    var icHelper = this.getIcHelper();
    var icRegData = await icHelper.getRegisterBasic();
    if (icRegData?.availablecnt < 1) {
      await icHelper.updateAvailable();
      icRegData = await icHelper.getRegisterBasic();
    }
    // if (!this.hcrService.isDemoMode) {
    //   var icRegData = await this.icHelper.getRegisterBasic();
    //   if (icRegData?.availablecnt < 1) {
    //     await this.icHelper.updateAvailable();
    //     icRegData = await this.icHelper.getRegisterBasic();
    //   }
    // } else {
    //   icRegData = await this.demoIcHelper.getRegisterBasic(this.patientId);
    //   icRegData.CId = 'A12345987';
    //   icRegData.Name = '李瑞文';
    // }
    // 讀卡成功才執行
    if (icRegData) {
      // 檢查是否有欠卡?
      var arrears: CustomResult = await this.opHelper.makeArrears(UserCache.getLoginClinicId(), icRegData.CId);
      if (arrears.IsSuccess) {   // 有欠卡資料
        this.userConfirm.showAlert('提示', '病患尚有欠卡就診紀錄，請先執行還卡後再更新新生兒依附註記。');
      } else {    // 無欠卡 -> 移除依附註記
        var patBirth = new Date(this.editFormGroup.controls["Birthday"].value);
        var patSex = this.editFormGroup.controls["Sex"].value == 0 ? 'F' : (this.editFormGroup.controls["Sex"].value == 1 ? 'M' : '');
        if ((new Date(icRegData.Birth)).toLocaleDateString() === (new Date(patBirth)).toLocaleDateString() && icRegData.Sex === patSex) {
          var pid = this.patientId;
          var cid = icRegData.CId;
          var cName = icRegData.Name;
          var ret = await this.patientApi.UpdateNewbornNote(pid, cid, cName);
          if (ret) {
            this.notification.showSuccess('依附註記更新完成。');
            // 重新載入病患基資
            if (this.isFromCompEnter) {
              await this.edit(this.patientId);
            } else {
              if (this.mode != PatientConst.mode_basic && this.patientId) {
                await this.edit(this.patientId);
              } else {
                await this.view(this.patientId);
              }
            }
            this.editFormGroup.markAsPristine();
          } else {
            this.notification.showError('依附註記更新失敗。');
          }
        } else {
          this.notification.showError('IC卡資料與病患資料不符。');
        }
      }
    } else {
      this.notification.showError('依附註記更新失敗。');
    }
  }
  //#endregion

  //#region 儲存
  // 是否為新增，依據Id判斷
  isAdd(): boolean {
    if (this.editFormGroup && !this.editFormId) {
      return true;
    }
    return false;
  }

  waitFor(fn: () => boolean, ms: number, limit: number): Promise<boolean> {
    var left = limit;
    var p = new Promise<boolean>((rs, rj) => {
      var a = setInterval(() => {
        if (fn()) {
          clearInterval(a);
          rs(true);
        }
        left -= ms;
        if (left <= 0) {
          clearInterval(a);
          rj('timeout');
        }
      }, ms)
    })
    return p;
  }
  registering: boolean;
  savingPId: number;
  savepageNo: number;
  // 儲存

  async save(register: boolean = false) {
    // console.log("save",this.editFormGroup);
    this.registering = register;
    this.editControlButtons.disableButtons();
    this.editFormGroup.updateValueAndValidity();
    await this.CIdChange(null);
    // 等非同步的驗證結果完成
    await this.waitFor(() => {
      return this.editFormGroup.status != 'PENDING';
    }, 100, 30000);

    // 解鎖欄位
    //this.unlockReadonlyColumns();

    // this.editFormGroup.controls.DrugAllergy.enable();
    // this.editFormGroup.controls['City'].enable();
    // this.editFormGroup.controls['State'].enable();
    // this.editFormGroup.controls['Area'].enable();
    // this.editFormGroup.controls['Street'].enable();
    if (!this.editFormGroup) {
      // console.log('123');
      this.registering = false;
      this.editControlButtons.enableButtons();
      return;
    }
    // 姓名欄位統一轉大寫
    var cname = this.editFormGroup.controls["CName"].value;
    this.editFormGroup.controls["CName"].setValue(cname.toUpperCase());
    // 註記所有欄位為touched以進行Validator檢查
    var dis = !this.editFormGroup.enabled;
    if (dis) {
      this.editFormGroup.enable({ 'emitEvent': false });
    }
    this.editFormGroup.markAllAsTouched();
    var valid = this.editFormGroup.valid;
    if (dis) {
      this.editFormGroup.disable({ 'emitEvent': false });
    }
    var isHomeCareWarning = false;
    if (this.isHomeCare) {
      var psa = this.editFormGroup.controls.PaySysA.value;
      if (psa == null || psa.length == 0) {
        valid = false;
        isHomeCareWarning = true;
      }
    }
    if (valid) {
      // 居護院所醫定要填收案別
      // if (this.isHomeCare) {
      //   var psa = this.editFormGroup.controls.PaySysA.value;
      //   if (psa == null || psa.length == 0) {
      //     this.notification.showError('請選擇居護收案別');
      //     this.editControlButtons.enableButtons(); // [按鈕列]解除鎖定
      //     return;
      //   }
      // }

      // <!-- 2023/06/12 沈協理 feeback: 電話和手機應該為非必填，方便快速讓病患掛號 -->
      // 檢查phone mobile 擇一必填
      // if (!this.editFormGroup.controls['Phone'].value && !this.editFormGroup.controls['CellPhone'].value) {
      //   this.notification.showError('電話/手機 請擇一填寫');
      //   this.editControlButtons.enableButtons(); // [按鈕列]解除鎖定
      //   return;
      // }

      // if(this.editFormGroup.controls["Sex"].value === null && this.editFormGroup.controls["Sex"].value == "")
      // {
      //   this.notification.showError("性別為必填欄位")
      //   this.editControlButtons.enableButtons(); // [按鈕列]解除鎖定

      //   return;
      // }

      // 檢查 Birthday EstBirthYear 擇一必填
      if (this.editFormGroup.controls['Birthday'].value === null && this.editFormGroup.controls['Age'].value === null) {
        //this.notification.showError('BirthDay,Age one of them is required');
        //this.editControlButtons.enableButtons(); // [按鈕列]解除鎖定

        //return;
        // 年齡欄位有值
      } else if (this.editFormGroup.controls['Age'].value != null) {
        const age = (Number)(this.editFormGroup.controls['Age'].value);
        const curryear = (Number)(new Date().getFullYear());
        // 預估年份
        this.editFormGroup.controls['EstBirthYear'].setValue(curryear - Math.floor(age));
      }
      var contactValid = true;
      // this.contactComps.forEach(c =>
      //   contactValid = contactValid && c.validate());
      this.contactComps.forEach((c, i) => {
        var isok = c.validate();
        contactValid = isok;
        // console.log('i',c,i);
        if (!isok) {

          // var cTitle = c.title;
          // const parentDiv = this.contactFocusElement.nativeElement.querySelector(`[title="${cTitle}"]`);
          // console.log(parentDiv);
          //  const inputElement = parentDiv.nativeElement.querySelectorAll('[formControlName="PhoneHome"]');
          // this.renderer.selectRootElement(inputElement).focus();
        }
      })

      // focusOnControlUnderTitle(title: string, controlName: string) {
      //   const divsWithTitle = this.spanElements.filter(spanElement => spanElement.nativeElement.parentElement.getAttribute('title') === title);

      //   if (divsWithTitle.length > 0) {
      //     const controls = divsWithTitle[0].nativeElement.querySelectorAll(`[formControlName="${controlName}"]`);
      //     if (controls.length > 0) {
      //       const controlElement = controls[0];
      //       this.renderer.selectRootElement(controlElement).focus();
      //     }
      //   }

      if (!contactValid) {
        // console.log('789');
        this.editControlButtons.enableButtons(); // [按鈕列]解除鎖定
        this.notification.showWarning('聯絡人的電話/手機/地址擇一必填');
        this.registering = false;
        return;
      }
      var value = this.editFormGroup.getRawValue();
      value.Birthday = onGetDateString(value.Birthday);
      // value.BirthDay = value.Birthday

      // 3條路 mainContact有name，mainContact沒有name，及全部為空
      if (this.mainContact.Name) {
        this.mainContact.MainPerson = true;
        value.Contacts = this.contacts.concat([this.mainContact]).filter(c => c.Name);
      }
      else if (!this.mainContact.Name && this.contacts.length > 0) {
        this.contacts[0].MainPerson = true;
        value.Contacts = this.contacts.filter(c => c.Name);
      }
      else {
        value.Contacts = this.contacts.filter(c => c.Name);
      }

      // 驗證成功
      if (this.isAdd()) {
        // 新增
        try {
          var data: PatientsDto = await this.patientApi.Create(value);
          if (this.mode === PatientConst.mode_firstVisit) { // 來自掛號作業的新增病患，新增後須直接回掛號作業進行掛號
            this.notification.showOk(); // 顯示存檔成功
            this.goToRegisterPage(data);
          } else {
            this.savingPId = data.Id;
            // 最新一筆的rowNumber
            if (data.Id > 0) {
              // this.savepageNo = await this.patientApi.GetListRowNumber(data.PatientNo,null);
              this.editOrNewAddfcous = true;
            }
            this.notification.showOk(); // 顯示存檔成功
            this.fillFormData(data); // 填充表格資料
            this.patientId = data.Id;
            this.viewFromSave();
            // 不通知是因為不想refresh list，因為refresh list後會自動到第一筆
            this.onUpdated.emit(true); // 通知父元件(父元件更新清單內容)
          }
          if (register) {
            this.regist()
          }
        } catch (ex) {
          this.registering = false;
          this.notification.showFailed(); // 顯示存檔失敗
          this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
        }
      } else {  // edit
        try {
          this.parentQuery.emit();
          var data: PatientsDto = await this.patientApi.Update(value);
          this.savingPId = data.Id;
          // 最新一筆的rowNumber
          if (data.Id > 0) {
            // this.savepageNo = await this.patientApi.GetListRowNumber(data.PatientNo,this.listqueryData);
            this.editOrNewAddfcous = false;
          }

          this.notification.showSuccess('基資更新成功'); // 顯示存檔成功
          if (!this.redirectBack()) {
            if (this.isFromCompEnter) {
              this.ExitPatEvent.emit(this.savingPId);
            } else {
              this.fillFormData(data); // 填充表格資料
              this.viewFromSave();
              // 不通知是因為不想refresh list，因為refresh list後會自動到第一筆
              this.onUpdated.emit(false); // 通知父元件(父元件更新清單內容)
            }
          }
          if (register) {
            this.regist();
          }
        } catch (ex) {
          this.registering = false;
          this.notification.showFailed(); // 顯示存檔失敗
          this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
        }
      }
    } else { // un validate
      var warningWord = '';
      Object.keys(this.editFormGroup.controls).forEach(field => {
        const control = this.editFormGroup.get(field);
        if (control.errors) {
          var i = this.showWaringForSave(field);
          this.focusOnFirstError(field);
          // const element = document.getElementById(field);
          // if (element) {
          //   element.focus();
          // }
          if (warningWord.length > 0) {
            warningWord = warningWord + '、' + i;
          }
          else {
            warningWord = i;
          }
        }
      });
      if (this.isHomeCare && isHomeCareWarning) {
        warningWord += warningWord.length > 0 ? '、' + '居護類別' : '居護類別';
        this.focusOnFirstError('PaySysA');
      }
      // 驗證失敗
      this.registering = false;
      this.notification.showWarning(`${warningWord}，需要更正`);
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
    }
    this.SameAddressCheck(); // save action done to ckeck 同戶籍地址
    //this.lockReadonlyColumns();
    this.registering = false;
    this.editControlButtons.enableButtons();
  }

  async preview(d: VisDialogComponent) {
    this.mainLayoutService.showLoader();
    this.notify.showSuccess('資料整理中')
    this.previewData = [];
    let data = this.previewOrderList();
    this.notify.showSuccess('資料已備妥')
    this.zone.run(() => {
      this.previewData = [data];
      d.show = true;
      this.mainLayoutService.hideLoader()
    }, this);
  }
  async print(d: VisDialogComponent) {
    let data = this.previewOrderList();
    let h = '';
    for (let d of [data]) {
      let rs;
      let p = new Promise((a) => { rs = a; });
      this.printData = [d];
      // 父data指給子列印用
      [this.reports.data] = this.printData;
      this.printData[0].DrugAllergy ? this.reports.hasDrugAllergy : !this.reports.hasDrugAllergy;
      // console.log('reports.data',this.reports.data);
      this.mainLayoutService.hideLoader()
      let j = new Promise((resolve) => {
        if (this.printData[0].DrugAllergy && resolve) {
          this.reports.hasDrugAllergy = true
        } else {
          this.reports.hasDrugAllergy = false;
        }
      });

      let m = new Promise((resolve) => {
        if (this.printData[0].Remark && resolve) {
          this.reports.hasRemark = true
        } else {
          this.reports.hasRemark = false;
        }
      });
      setTimeout(() => {
        h += this.reports.getHtml();
        // console.log('h TIMEOUT',h);
        rs();
      }, 500);
      await p;
    };
    // console.log('[data]',[data]);
    this.localPrint.printHtml(444, '病歷首頁', h);
    this.printData = [data];
    // console.log('printData',this.printData);
    this.notify.showSuccess('開始列印-病歷首頁');
    if (d) {
      d.show = false
    }
  }

  previewOrderList(): PatientBasicPrint {
    const parsedHeight = parseFloat(this.editFormValue.Height);
    const parsedWeight = parseFloat(this.editFormValue.Weight);
    //TODO this.contacts[0]非主要聯絡人，最後一筆才是，但是this.contacts.length - 1沒有work
    let previewData: PatientBasicPrint = {
      PatientNo: this.editFormValue.PatientNo,
      CName: this.editFormValue.CName,
      Sex: this.editFormValue.Sex,
      ICode: this.editFormValue.ICode,
      CId: this.editFormValue.CId,
      Birthday: this.editFormValue.Birthday,
      BlType: this.editFormValue.BlType,
      BlRH: this.editFormValue.BlRH,
      Phone: (this.editFormValue.PhoneArea && this.editFormValue.Phone) ? `${this.editFormValue.PhoneArea}-${this.editFormValue.Phone}` : this.editFormValue.Phone,
      CellPhone: this.editFormValue.CellPhone,
      contactName: this.fullContacts.length > 0 ? (this.fullContacts.length > 1 ? this.fullContacts[this.fullContacts.length - 1].Name : this.fullContacts[0].Name) : '',
      contactOnePhone: this.fullContacts.length > 0 ?
        (this.fullContacts.length > 1 ?
          this.fullContacts[this.fullContacts.length - 1].PhoneHomeArea && this.fullContacts[this.fullContacts.length - 1].PhoneHome ? `${this.fullContacts[this.fullContacts.length - 1].PhoneHomeArea}-${this.fullContacts[this.fullContacts.length - 1].PhoneHome}` : this.fullContacts.length > 1 ? this.fullContacts[this.fullContacts.length - 1].PhoneHome : ''
          :
          this.fullContacts[0].PhoneHomeArea && this.fullContacts[0].PhoneHome ? `${this.fullContacts[0].PhoneHomeArea}-${this.fullContacts[0].PhoneHome}` : this.fullContacts.length > 0 ? this.fullContacts[0].PhoneHome : ''
        )
        :
        '',
      contactOneMobile: this.fullContacts.length > 0 ?
        this.fullContacts.length > 1 ?
          this.fullContacts[this.fullContacts.length - 1].Mobile
          :
          this.fullContacts[0].Mobile
        :
        '',
      JobCode: this.editFormValue.JobCode,
      EducationCode: this.editFormValue.EducationCode,
      FirstDate: this.editFormValue.FirstDate,
      Height: !isNaN(parsedHeight) ? Math.round(parsedHeight * 10) / 10 : 0,
      Weight: !isNaN(parsedWeight) ? Math.round(parsedWeight * 10) / 10 : 0,
      DrugAllergy: this.editFormValue.DrugAllergy,
      Remark: this.editFormValue.Remark,
      clinicName: this.clinicName,
      patientAddress: this.editFormValue.Street ?
        this.editFormValue.City + this.editFormValue.Area + this.editFormValue.Street
        :
        this.editFormValue.ResStreet ?
          this.editFormValue.ResCity + this.editFormValue.ResArea + this.editFormValue.ResStreet
          :
          '',
      contactsAddress: this.fullContacts.length > 0 ?
        this.fullContacts.length > 1 ?
          this.fullContacts[this.fullContacts.length - 1].City + this.fullContacts[this.fullContacts.length - 1].Area + this.fullContacts[this.fullContacts.length - 1].Street
          :
          this.fullContacts[0].City + this.fullContacts[0].Area + this.fullContacts[0].Street == 0 ?
            ''
            :
            this.fullContacts[0].City + this.fullContacts[0].Area + this.fullContacts[0].Street
        :
        '',
      relation: this.fullContacts.length > 0 ?
        this.fullContacts.length > 1 ?
          this.fullContacts[this.fullContacts.length - 1].RelationCode
          :
          this.fullContacts[0].RelationCode
        :
        ''
    }
    return previewData;
  }


  showWaringForSave(input: string): string {
    switch (input) {
      case 'CName':
        return '姓名'
        break;
      case 'CId':
        return '身分證字號'
        break;
      case 'Birthday':
        return '生日'
        break;
      default:
        return input
        break;
    }
  }
  focusOnFirstError(formControlName: string) {
    // console.log('formControlName',formControlName);
    if (formControlName == 'CId') var formControlElement = this.renderer.selectRootElement(this.cidFocusElement.nativeElement)
    else if (formControlName == 'Birthday') var formControlElement = this.renderer.selectRootElement(this.birthdayFocusElement.yearInput.nativeElement)
    else if (formControlName == 'PaySysA') var formControlElement = this.renderer.selectRootElement(this.paySysAFocusElement.select)
    else var formControlElement = this.renderer.selectRootElement(`[formControlName="${formControlName}"]`);

    formControlElement.focus();
    // if (formControlName != 'PaySysA')formControlElement.scrollIntoView({ behavior: 'smooth' });
  }
  // 停用 不可修改欄位
  // disableReadonlyControls() {
  //   if (!this.editFormGroup || !this.editFormGroup.controls) { return false; } // 只檢查到這一階層，有點偷懶
  // }
  // 開啟 不可修改欄位
  // enableReadonlyControls() {
  //   if (!this.editFormGroup || !this.editFormGroup.controls) { return false; } // 只檢查到這一階層，有點偷懶
  // }

  // 來自掛號作業的新增病患，新增後須直接回掛號作業進行掛號
  goToRegisterPage(data: PatientsDto) {
    const path = this.routeInfo.makeFullUrl(this.routeRegister);
    this.router.navigate([path, { patientId: data.Id }]);
  }
  //#endregion

  //#region 刪除,取消
  // 刪除
  async delete() {
    // 檢查參數
    if (this.isAdd()) {
      this.notification.showParameterError(); // 顯示參數錯誤
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
      return;
    }
    if (!this.canDelete) {
      this.notification.showError('無法刪除有掛號紀錄的基本資料'); // 顯示參數錯誤
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
      return;
    }
    try {
      var ret = await this.userConfirm.showConfirm({
        'msg': '是否刪除此筆資料',
        'title': '基本資料刪除',
      });
      if (ret == true) {
        var s = await this.patientApi.Delete(this.editFormId);
        this.notification.showOk(); // 顯示刪除成功
        this.editControlButtons.toNoneMode();
        this.cancel();
        this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
      }
    } catch (ex) {
      this.notification.showError(ex); // 顯示刪除失敗
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
    }

  }
  // 取消
  async cancel() {
    if (!this.redirectBack()) {
      if (this.isFromCompEnter) {
        this.ExitPatEvent.emit(0);
      } else {
        if (this.editControlButtons.editMode === EditModeEnum.edit) {
          await this.viewFromEdit();
          this.editControlButtons.toViewMode();
        } else {
          this.patientId = 0;
          this.destroyEditFormGroup();
          this.editControlButtons.toNoneMode();
          this.disableForm();
          this.emitEnterView.emit();
        }
      }
    }
    // this.SameAddressCheck();
  }
  redirectBack() {
    if (this.mode === PatientConst.mode_regList || this.mode === PatientConst.mode_firstVisit) {
      const path = this.routeInfo.makeFullUrl(this.routeRegList);
      this.router.navigate([path]);
      return true;
    } else if (this.mode === PatientConst.mode_opdList) {
      const path = this.routeInfo.makeFullUrl(this.routeOpdList);
      this.router.navigate([path]);
      return true;
    }
    return false;
  }
  hasRegisterBtn() {
    return this.editFormGroup && this.editFormGroup.enable && !(this.mode === PatientConst.mode_opdList || this.mode === PatientConst.mode_regList || this.mode === PatientConst.mode_firstVisit || this.isFromCompEnter);
  }
  //#endregion

  // #region remark-dialog
  onBtnRemarkClick() {
    if (!this.editFormGroup.enabled) {
      return;
    }
    this.remarkSelectedText = this.editFormGroup.controls.Remark.value;
    this.patientRemarkDialogComp.reloadData(this.remarkSelectedText);
    this.patientRemarkDialogComp.cleanData();
    this.switchPatientRemarkDialog = true;
  }

  openRemarkWindow() {
    this.isRemarkWindowOpened = true;
  }

  onBtnSaveClickPatientRemark(status: string) {
    // this.emitResult.emit(this.selectedItems);
    // // 清除選取
    // this.selectedItems = [];

    this.switchPatientRemarkDialog = false;
  }
  onBtnCancelClickPatientRemark(evt: Event) {
    // 這次沒選
    //this.emitResult.emit([]);
    // 清除選取
    //this.selectedItems = [];

    this.switchPatientRemarkDialog = false;
  }

  remarkSelectedText: string = '';
  getRemarkWindowResult(data: any) {
    this.switchPatientRemarkDialog = false;
    if (data) {
      this.editFormGroup.controls.Remark.setValue(data.text);
    }
  }
  // #endregion remark-dialog

  // #region drug-allergy
  // 開啟 drug-allergy 視窗

  onBtnDrugAllergyClick(evt: Event, data: string) {
    if (!this.editFormGroup.enabled) {
      return;
    }
    this.drugAllergySelectedText = this.editFormGroup.controls.DrugAllergy.value;
    this.drugAllergyComp.cleanData();
    this.switchDrugAllergyDialog = true;
  }

  drugAllergySelectedText: string = '';
  getDrugAllergyWindowResult(data: any) {
    this.switchDrugAllergyDialog = false;
    if (data) {
      this.editFormGroup.controls.DrugAllergy.setValue(data.text+ ';'+this.otherAllergies);
    }
    
    //重新載入會讓其他編輯中的重置
    //this.getOneRecord(this.patientId); // 由server端抓取此筆資料
    // console.log(`data:[${data}] isDrugAllergyWindowOpened:[${this.isDrugAllergyWindowOpened}]`);
  }

  public onBirthdayChange(Birthday: Date): void {
    if (Birthday === null) {
      this.editFormGroup.get('Age').enable();
    } else {
      this.editFormGroup.controls['Age'].setValue(null);
      this.editFormGroup.get('Age').disable();
    }
  }

  ngOnDestroy() {
    if (this.routeInfo.comp == this) {
      this.routeInfo.comp = null;
    }
  }
  // 檢查 同戶籍地址 checkBox
  SameAddressCheck() {
    if (this.editFormGroup.controls.ResStreet.value) {
      var thesamezipcode = this.editFormGroup.controls.ResZipCode.value == this.editFormGroup.controls.ZipCode.value;
      var thesamecity = this.editFormGroup.controls.ResCity.value == this.editFormGroup.controls.City.value;
      var thesamestreet = this.editFormGroup.controls.ResStreet.value == this.editFormGroup.controls.Street.value;
      var thesamearea = this.editFormGroup?.controls.ResArea.value == this.editFormGroup.controls.Area.value;
      // console.log('bool:', thesamezipcode, thesamecity, thesamestreet, thesamearea);
      if (thesamezipcode && thesamecity && thesamestreet && thesamearea) {
        this.isSameAddressCheck = true;
      }
      else {
        this.isSameAddressCheck = false;
      }
    }
  }

  onChkSameAddressClick(event: MatCheckboxChange) {
    if (this.editFormGroup.enabled) {
      this.isSameAddressCheck = event.checked;
      if (event.checked) {
        this.editFormGroup.patchValue({
          City: this.editFormGroup.controls.ResCity.value,
          Area: this.editFormGroup.controls.ResArea.value,
          ZipCode: this.editFormGroup.controls.ResZipCode.value,
          Street: this.editFormGroup.controls.ResStreet.value
        }, { emitEvent: false });
        this.editFormGroup.controls['City'].disable();
        this.editFormGroup.controls['ZipCode'].disable();
        this.editFormGroup.controls['Area'].disable();
        this.editFormGroup.controls['Street'].disable();

      } else {
        this.editFormGroup.controls['City'].enable();
        this.editFormGroup.controls['ZipCode'].enable();
        this.editFormGroup.controls['Area'].enable();
        this.editFormGroup.controls['Street'].enable();

      }
    }
  }

  spcIdentities: ValueTextPair[];
  spcChange(idx: number, evt: MatCheckboxChange) {
    var spc: string = this.editFormValue.SpcIdentity;
    // 補滿(新增的時候)
    if (spc.length <= 50) {
      // console.log('<50');
      this.editFormValue.SpcIdentity.padEnd(50, '0');
    }
    // 取代其中一個字元
    spc = spc.substring(0, idx) + (evt.checked ? '1' : '0') + spc.substring(idx + 1);
    this.editFormGroup.patchValue({
      SpcIdentity: spc
    });
  }
  spcChecked(idx: number) {

    var spc: string = this.editFormValue.SpcIdentity;
    if (!spc || spc.length < idx) {
      return false;
    }
    return spc.charAt(idx) == '1';
  }
  regist() {
    this.registService.Regist(this.patientId);
  }

  addContact() {
    if (!this.mainContact?.Name) {
      this.notification.showWarning('請先填寫主要聯絡人。');
      return;
    }
    if (this.contacts.length > 0 && !this.contacts[this.contacts.length - 1]?.Name) {
      this.notification.showWarning('請先完成緊急聯絡人' + this.contacts.length + '的資料。');
      return;
    }
    this.contacts.push(new ContactDto(this.patientId));
  }
  deleteContact(ind) {
    this.contacts.splice(ind, 1);
  }
  calculateBMI() {
    var w = parseFloat(this.editFormValue.Weight);
    var h = parseFloat(this.editFormValue.Height);

    var bmi = w * 10000 / (h * h);
    //四捨五入
    if (!isNaN(bmi)) {
      this.editFormGroup.patchValue({ BMI: Math.round(bmi * 10) / 10 });
    }
  }

  CIdInput(evt: InputEvent) {
    if (evt.target instanceof HTMLInputElement) {
      var text = evt.target.value;
      this.editFormGroup.controls['CId'].setValue(text.toUpperCase());
      if (text.length >= 2) {
        var sexCode = text[1];
        this.editFormGroup.controls['Sex'].patchValue(sexCode == '1' ? 1 : sexCode == '2' ? 0 : 999);
        return
      }
    }
    this.editFormGroup.controls['Sex'].patchValue(999);
  }
  CIdChange(evt) {
    var ctrl = this.editFormGroup.controls['CId'];
    ctrl.setAsyncValidators(this.CIdValidate());
    ctrl.updateValueAndValidity();
    ctrl.clearAsyncValidators();
  }

  CIdValidate() {
    return async (control: AbstractControl) => {
      var text = control.value;
      var exist = await this.patientApi
        .CheckCIdDuplicated(this.editFormId, text);
      if (exist) {
        return { 'custom': '身分證與其他病患重複' }
      }
      return null;
    }
  }
  isPregnent() {
    return !!this.editFormGroup?.controls?.DueDate?.value;
  }
  pregnantChange(evt: MatCheckboxChange) {
    if (evt.checked) {
      var lastMc = this.editFormGroup.controls.LastMCDate.value as Date;
      if (lastMc) {
        this.editFormGroup.patchValue({
          DueDate: new Date(lastMc.getFullYear(), lastMc.getMonth(), lastMc.getDate() + 280)
        });
      }
    } else {
      this.editFormGroup.patchValue({
        DueDate: null
      });
    }
  }
  CIDValidator(): ValidatorFn {
    var comp = this;
    return ((control: AbstractControl): { [key: string]: any } | null => {
      if (comp.editFormGroup?.value?.IsCheckCId == true) {
        return ValidTip.ROCCIDValidatorForMsg(control);
      } else {
        return null;
      }
    }).bind(this);
  }

  validCidChanged() {
    this.editFormGroup.controls.CId.updateValueAndValidity();
  }

  onHandicappChange(evt: any) {
    if (evt.checked) {
      this.editFormGroup.controls.Handicapp.setValue('1');
    } else {
      this.editFormGroup.controls.Handicapp.setValue('0');
    }
  }

  medLackAreaData: MedLackArea[] = [];
  medLackAreas: ValueTextPair[] = [];
  // 取得支援地區代碼
  async getMedLackArea() {
    this.medLackAreaData = await this.MedLackAreaApi.GetAll('EC');
    if (this.medLackAreaData.length > 0) {
      var i: ValueTextPair = {
        text: '',
        value: '',
      };
      this.medLackAreas.push(i);
    }
    this.medLackAreaData.forEach(x => {
      var item: ValueTextPair = {
        text: x.Area,
        value: x.Zipcode,
      };
      this.medLackAreas.push(item);
    });
  }
  // handleKeyDown(event: KeyboardEvent) {
  //   const isChineseInput = event.key === 'Shift' && event.code === 'ShiftLeft';
  //   console.log('type', event.key)
  //   if (isChineseInput) {
  //     event.preventDefault(); // 阻止浏览器切换到中文输入法
  //   }
  // }
  getPatientPrint(id: string) {

  }


  isComposing = false;

  handleCompositionStart(event: CompositionEvent) {
    this.isComposing = true;
  }

  handleCompositionEnd(event: CompositionEvent) {
    this.isComposing = false;
  }

  handleKeyDown(event: KeyboardEvent) {
    if (this.isComposing) {
      event.preventDefault(); // 阻止浏览器切换到中文输入法
    }
  }

  // 目前沒有資料來源，只能先Hard code
  paySysAList: ValueTextPair[] = [{ value: 'NG', text: '一般居家' }, { value: 'NI', text: '整合照護' }, { value: 'N4', text: '呼吸器照護' }];

  qrCodeDataUrl = '';
  @ViewChild('qrcodeImg')
  qrcodeImg: ElementRef<HTMLImageElement>
  async getQRCode() {
    if (this.enableQRCode) {
      var url = await this.patientApi.GetNotifyUrl(this.patientId);
      this.qrcodeImg.nativeElement.src = url.dataUrl;
      //this.qrCodeDataUrl= url.dataUrl;
    }
  }

  onSetting(type: string) {
    this.showSetting = true;
    this.systemCodeTypeTarget = type;
  }

  onHandleDone() {
    this.showSetting = false;
    this.systemCodeTypeTarget = "";
    this.getEditOptions();
  }

  onShowImport() {
    this.importTargetSystemCode = this.systemCodeTypeTarget;
  }

  onAddRow() {
    this.gridViewComponent.addRow();
  }

  onCloseImport() {
    this.importTargetSystemCode = "";
  }

  onCheckIsEdit(isCanEdit: boolean) {
    this.cdRef.detectChanges();
    this.isCanEdit = isCanEdit;
    this.cdRef.detectChanges();

    console.log("onCheckIsEdit", isCanEdit);
  }

  async handleGridViewQuery() {
    if (this.currentClinicId && this.systemCodeTypeTarget) {
      await this.gridViewComponent.handleQuery();
    } else {
      this.notification.showError('Type value required!');
    }
  }

  async getSystemCodeOptions(type) {
    var data = await this.systemcodeApi.GetEditOptions(type);
    this.systemTypeList = data.systemCodeTypeList;
  }

  otherAllergies:string = '';
  otherAllergyInput(evt:KeyboardEvent){
    this.otherAllergies = (evt.target as HTMLInputElement).value;
    var alergies = (this.editFormGroup.value.DrugAllergy??'').split(';');
    var t = [alergies[0]??'',alergies[1]??'',alergies[2]??'',this.otherAllergies]
    this.editFormGroup.controls.DrugAllergy.patchValue(t.join(';'));
  }
  displayAllergies() {
    var tmp = (this.editFormGroup.value.DrugAllergy??'').split(';');
    var result = '';
    result += tmp.length > 0 ? (tmp[0].length > 0 ? ('[藥品代碼]:' + tmp[0] + ' ') : '') : '';
    result += tmp.length > 1 ? (tmp[1].length > 0 ? ('[ATC碼]:' + tmp[1] + ' ') : '') : '';
    result += tmp.length > 2 ? (tmp[2].length > 0 ? ('[過敏藥群組]:' + tmp[2] + ' ') : '') : '';
    // 自己打字可能會包含到;號，將其組回去
    this.otherAllergies = tmp.filter((x,i)=>i>=3).join(';')
    return result;
  }
}
