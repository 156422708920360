import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { OrderGridTypeEnum } from '../enums/order-grid-type-enum';
import { HistOrder } from './hist-order';

export class OrderTab {
    constructor() {
        this.rxClass = '';
        this.isSelected = false;
        this.orderGridType = OrderGridTypeEnum.Default;
        this.isHidden = false;
    }

    // 沒有name的屬性是因為i18n，要把name直接放在畫面
    rxClass: string;
    isSelected: boolean;
    orderGridType: OrderGridTypeEnum;
    isHidden: boolean;
    rxType: number[];

    public static createTab(rxClass: string, orderGridType: OrderGridTypeEnum, rxType: number[]): OrderTab {
        const tab: OrderTab = new OrderTab();
        tab.rxClass = rxClass;
        tab.isSelected = false;
        tab.orderGridType = orderGridType;
        tab.isHidden = false;
        tab.rxType = rxType;
        return tab;
    }
}
