
<div style="padding: 10px;">
  <form [formGroup]="queryFormGroup">
    <app-search-bar>
      <mat-radio-group class="vis-form-field" searchBarField [fieldTitle]="'檢視方式'" [(ngModel)]="reportRdoValue" [ngModelOptions]="{standalone: true}">
        <mat-radio-button value="common" for="common" i18n="@@LabReportDefault" id="common" [checked]="true">預設</mat-radio-button>
        <mat-radio-button value="table" for="table"  i18n="@@LabReportTable" id="table">表格</mat-radio-button>
      </mat-radio-group>
      
      <app-vis-button type="button" (click)="s()" [icon]="'settings'" [title]="'設定'" [label]="'設定'">
      </app-vis-button>
    </app-search-bar>
    <app-search-bar [Cols]="6">
      <input class="vis-input" formControlName="patientNo" searchBarField [fieldTitle]="'病歷號'" />
      <input class="vis-input" [value]="this.queryPatientName" readonly disabled searchBarField [fieldTitle]="'姓名'" />
      <app-vis-datepicker formControlName="timeStart" [maxWidth]="false" searchBarField [fieldTitle]="'開始日期'"></app-vis-datepicker>
      <app-vis-datepicker formControlName="timeEnd" [maxWidth]="false"  searchBarField [fieldTitle]="'結束日期'"></app-vis-datepicker>
      
      
      <mat-radio-group class="vis-form-field" formControlName="statusType" searchBarField [fieldTitle]="'狀態'">
        <ng-container *ngFor="let radioItem of queryOptions.statusType">
          <mat-radio-button [value]="radioItem.value">
            {{radioItem.text}}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>
      <app-vis-button type="button" (click)="onBtnQueryClick()" [icon]="'search'" [title]="'查詢'" [label]="'查詢'">
      </app-vis-button>
    </app-search-bar>
  </form>
</div>

<div style="height: calc(100% - 80px);">
  <app-labs-report-query-dialog-list 
    [queryPatientNo]="queryPatientNo" [queryPatientName]="queryPatientName" [isLockPatientNo]="isLockPatientNo" 
    (onPageChange)="pageChange($event)" [data]="gridView"
    *ngIf="reportRdoValue === 'common'">

  </app-labs-report-query-dialog-list>
  <app-labs-report-query-dialog-table  [data]="tableGridView" *ngIf="reportRdoValue === 'table'">

  </app-labs-report-query-dialog-table>
</div>
