<div class="card-body no-padding watch-list" [style.padding]="'5px'" [style.width]="width">
  <div class="grid-title">{{title}}</div>
  <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true" tabindex="0"
    (pageChange)="pageChange($event)" [kendoGridSelectBy]="'Id'" [tableFullWidth]="'w-100'" [showStickyHead]="true"
    [showStickyHeight]="560" [class]="'no-border'" [customClass]="'overflow-x-hidden'" #reserveList>

    <app-vis-grid-column field="FinishedDate" title="日期" [width]="50" class="text-center custom-m-auto">
      <ng-template let-data>
        <div class="ellipsis-content" [title]="data.FinishedDate | formatDate:''">
          {{data.FinishedDate | formatDate:'' }}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="ToothPos" title="牙位" [width]="50" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="SRxCode" title="醫令" [width]="50" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="ClinicName" title="院所" [width]="50" class="text-center">
    </app-vis-grid-column>
  </app-vis-grid>
</div>
