<div class="a4-print-style mb-3 p-2" #reportContainer>
    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 16px;line-height: 1.2;"
    *ngIf="regist && clinic"  class="reg-report">
        <div  style="width: 100%;font-size: 24px;text-align: center;">
            {{clinicName}}
        </div>
        <div  style="width: 100%;text-align: center;">
            掛號單
        </div>
        <table style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 16px;line-height: 1;">
            <tr>
                <td style="width: 100px;">病歷編號</td>
                <td style="width: calc(50% - 80px);">{{patientNo}}</td>
                <td style="width: calc(50% - 80px);"></td>
            </tr>
            <tr>
                <td >患者姓名</td>
                <td >{{patientName}}</td>
                <td >年齡：{{patientBirth|age}}</td>
            </tr>
            <tr>
                <td >看診日期</td>
                <td >{{regDate |formatDate:''}}</td>
                <td >時段：{{regSec}}</td>
            </tr>
            <tr>
                <td >科別/診間</td>
                <td >{{medDeptName}}/{{room}}</td>
                <td >醫師：{{doctorName}}</td>
            </tr>
            <tr>
                <td >看診號</td>
                <td >{{waitNo}}</td>
                <td ></td>
            </tr>
            <tr>
                <td >備註</td>
                <td >{{remark}}</td>
                <td ></td>
            </tr>
            <tr>
                <td >院所地址</td>
                <td >{{address}}</td>
                <td >電話：{{clinicPhone}}</td>
            </tr>
        </table>
    </div>
</div>
