import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class VisMatPaginatorIntl extends MatPaginatorIntl {
  itemsPerPageLabel = '每頁';
  nextPageLabel     = '下一頁';
  previousPageLabel = '上一頁';
  firstPageLabel = "最前頁";
  lastPageLabel = "最後頁";

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '無可用資料';
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return  (startIndex + 1) + '-' + endIndex +' 共'+length+ '項';
  };

}