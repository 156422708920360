<!-- 測試訊息用 -->
<!--  <button type="button" (click)="shownoty(1)">訊1</button>
<button type="button" (click)="shownoty(2)">訊2</button>
<button type="button" (click)="shownoty(3)">訊3</button> -->
<div  *ngIf="!isLoaded" class="loading">Loading...</div>
<div *ngIf="isLoaded" (click)="hideMenu()"
    style="display: flex;flex-direction: column;height: 100%;overflow:hidden;">
    <app-header></app-header>
    <!--麵包穴-->
    <div class="page-title" *ngIf="isShowPageTitle" [hidden]="!pageTitle">
        <div class="navigation">
            {{pageTitle}}
        </div>
        <div class="marqueeDiv"  >
          <marquee width="88%" *ngIf="isopened&&isShowMarquee" style="color: red;font-size: 15.5px;" hspace="30" scrollamount="5" (click)="onClickMarquee()">
            系統於 2024/09/21(六)23點 ~ 2024/09/22(日)7點 配合微軟進行機房轉移作業，轉移過程會暫時停止系統服務，如作業提前完成，將會提前恢復服務，造成您的不便，敬請見諒。
          </marquee>
        </div>

        <div [ngClass]="isShowMarquee?'font-controller-marquee':'font-controller'">
          <a type="button" [title]="'跑馬燈顯示/隱藏'" style="width: 100px; font-size: 14px;"
             (click)="onDoubleClick()" class="rect-btn" *ngIf="isShowMarquee">
            跑馬燈{{isopened?'顯示':'隱藏'}}<mat-icon *ngIf="isopened" class="vis-combine-icon">notifications</mat-icon>
            <mat-icon *ngIf="!isopened" class="vis-combine-icon">notifications_off</mat-icon>
          </a>
            <app-icon-font class="position-relative cursor-pointer w-16"
                (click)="getFontGreater($event)"
                [cssName]="fontGreater?'font-great-on':'font-great-off'">
            </app-icon-font>
        </div>
    </div>
<!--[ngClass]="layoutForMenuCssClass"-->
    <div style="flex-grow: 1;" class="container-fluid main-layout-container" >
        <!-- <div class="left-menu" [hidden]="!isShowMenu" *ngIf="isUseLeftMenu">
            <app-menu [isVertical]="true"></app-menu>
        </div> -->

        <div class="main-content" [ngClass]="[isHist?'is-hist':'']" #mainContent>

            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<!--列印-->
<app-report-helper #printHelper></app-report-helper>
<!-- Top Loader -->
<app-loader></app-loader>
<!-- Notify -->
<div class="snack-container">
    <ng-container *ngFor="let msg of msgs;let i = index">
        <div class="snack-message snack-fadeout-delay" *ngIf="msg.isAutoHide"
            [ngClass]="msg.cssClass" [ngStyle]="{top:posY[i]+'px'}"
            (animationstart)="onAniStart($event,i)" (animationend)="onAniEnd($event,i)"
            #snackdiv>
            {{msg.content}}
        </div>
        <div class="snack-message" *ngIf="!msg.isAutoHide"
            [ngClass]="msg.cssClass" [ngStyle]="{top:posY[i]+'px'}"
            (animationstart)="onAniStart($event,i)" (animationend)="onAniEnd($event,i)"
            #snackdiv>
            <div style="width: calc(100% - 80px)">
                {{msg.content}}
            </div>
            <button mat-button class="snack-ok vis-btn-alert" (click)="closeMsg(snackdiv,i)">確定</button>
        </div>
    </ng-container>
</div>
<!--Confirm-->
<app-vis-dialog [fitContent]="confirmContent.fitContent" [show]="showConfirm" [width]="confirmDiagWidth" [title]="confirmContent.title" (close)="onCustomConfirm(null)">
    <div style="display: flex;align-items: center;">
        <div *ngIf="confirmContent.wait=='wait'" style="width: 100px;height: 100px;position: relative;">
            <div class="loader-default"></div>
        </div>
        <div *ngIf="confirmContent.wait=='done'" style="width: 100px;height: 100px;position: relative;">
            <mat-icon style="color: limegreen;font-size: 83px;
            height: 83px;
            width: 83px;
            margin: 9px;">check_circle_outline</mat-icon>
        </div>
        <div *ngIf="confirmContent.msg" style="line-height: 1.2; padding: 10px 15px; white-space: pre-line; overflow-x: hidden; overflow-y: auto;line-break: anywhere; max-height: 500px; width: 100%;" class="extend-msg controlled-font-size-middle">
            {{confirmContent.msg}}
        </div>
    </div>

    <ng-container *ngIf="confirmContent.template!=null">
        <ng-container *ngTemplateOutlet="confirmContent.template;context:{$implicit:confirmContent.templateData}"></ng-container>
    </ng-container>
    <app-vis-dialog-actions *ngIf="confirmDiagHasAction">
        <ng-container *ngIf="confirmContent.customBtnStart">
            <ng-container *ngFor="let btn of confirmContent.customBtnStart">
                <button type="button" (click)="onCustomConfirm(btn.return)" #confirmDialogSt>{{btn.text}}</button>
            </ng-container>
        </ng-container>

        <button type="button" i18n="@@Ok" *ngIf="!confirmContent.hideYes" (click)="onCustomConfirm(true)"
            [attr.disabled]="confirmContent.disableYes?'':null"
         #confirmDialogYes>{{confirmContent.textYes||'是'}}</button>
        <ng-container *ngIf="confirmContent.customBtnMid">
            <ng-container *ngFor="let btn of confirmContent.customBtnMid">
                <button type="button" (click)="onCustomConfirm(btn.return)" [disabled]="btn.disabled" #confirmDialogMid>{{btn.text}}</button>
            </ng-container>
        </ng-container>
        <button type="button" i18n="@@No" *ngIf="!confirmContent.hideNo" (click)="onCustomConfirm(false)" #confirmDialogNo>{{confirmContent.textNo||'否'}}</button>
        <ng-container *ngIf="confirmContent.customBtnEnd">
            <ng-container *ngFor="let btn of confirmContent.customBtnEnd">
                <button type="button" i18n="@@Ok" (click)="onCustomConfirm(btn.return)" #confirmDialogEnd>{{btn.text}}</button>
            </ng-container>
        </ng-container>
    </app-vis-dialog-actions>
</app-vis-dialog>

<app-vis-dialog [show]="getErrorStatus()!==0" [width]="250" [height]="200" [title]="'Warning'" (close)="onCustomConfirmClose()">
     <span>{{getErrorMsg()}}</span>
    <app-vis-dialog-actions>
        <button type="button" i18n="@@Ok" (click)="onConfirm(getErrorStatus())"> Ok</button>
    </app-vis-dialog-actions>
</app-vis-dialog>



<app-patients-patient-query-dialog [isDialogOpened]="isPatientDialogOpened"
    (onEmitResult)="onPatientDialogClose($event)">
</app-patients-patient-query-dialog>

<app-msg-text textId="MSG_MainLayout1" i18n="@@MSG_MainLayout1">取得資料失敗</app-msg-text>
<app-msg-text textId="MSG_MainLayout2" i18n="@@MSG_MainLayout2">成功</app-msg-text>
<app-msg-text textId="MSG_MainLayout3" i18n="@@MSG_MainLayout3">失敗</app-msg-text>
<app-msg-text textId="MSG_MainLayout4" i18n="@@MSG_MainLayout4">請更正無效的欄位</app-msg-text>
<app-msg-text textId="MSG_MainLayout5" i18n="@@MSG_MainLayout5">輸入參數錯誤</app-msg-text>
<app-msg-text textId="MSG_LocalReportReady" i18n="@@MSG_LocalReportReady">本地報表已準備完成.</app-msg-text>
<app-msg-text textId="MSG_LocalReportNotReady" i18n="@@MSG_LocalReportNotReady">本地報表尚未準備完成.</app-msg-text>

<!-- TIP -->
<ng-container>
    <div class="tip-container" *ngIf="tipObj">
        <div class="tip-message"  [ngStyle]="{top:tipObj.msgY+'px',left:tipObj.msgX+'px',display:tipObj.hidden?'none':'flex'}">
            <div [ngClass]="tipObj.cssCls">{{tipObj.contentFix+tipObj.content}}</div>

            <button mat-button class="tip-ok" (click)="nextTip(tipObj.name)">{{tipObj.isLast?'完成':'繼續'}}</button>
        </div>
        <div *ngIf="tipObj.showBorder" class="tip-border" [ngStyle]="tipBorderStyle"></div>
        <div class="tip-close">
            <button mat-button (click)="setTip(null)">>>>跳過</button>
        </div>
    </div>
</ng-container>
