import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { RepeatedPrescriptionComponent } from './components/repeated-prescription/repeated-prescription.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { HistoryComponent } from './components/order-dosage/history/history.component';
import { ReportHelperComponent } from './report-helper/report-helper.component';
import { RegisterComponent } from './components/register/register.component';
import { OpdDeclareSummaryComponent } from './components/declare/opd-declare-summary/opd-declare-summary.component';
import { SamplingOrderListComponent } from './components/nhi/sampling-order-list/sampling-order-list.component';
import { DeclareCaseStatisticsComponent } from './components/declare/declare-case-statistics/declare-case-statistics.component';
import { ConsultationVoulmeComponent } from './components/consultation-volume/consultation-volume.component';
import { OrderDosageComponent } from './components/order-dosage/order-dosage.component';
import { DailyPaymentReportComponent } from './components/payment/daily-report/daily-payment-report.component';
import { HcvisitsComponent } from './components/hcvisits/hcvisits.component';
import { DoctorRoomcodePaymentReportComponent } from './components/payment/doctor-roomcode-payment/doctor-roomcode-payment.component';
import { ReportRehabilitationComponent } from './components/declare/report-rehabilitation/report-rehabilitation.component';
import { ReportRehabilitateComponent } from './components/declare/report-rehabilitate/report-rehabilitate.component';
import { ReportHistIndexComponent } from './components/declare/report-hist-index/report-hist-index.component';
import { ReportMissRegisterComponent } from './components/declare/report-miss-register/report-miss-register.component';
import { ReportCancelReserveComponent } from './components/declare/report-cancel-reserve/report-cancel-reserve.component';
import { DrugBagComponent } from './components/drug-bag/drug-bag.component';
import { DiagnosisCertComponent } from './components/diagnosis-cert/diagnosis-cert.component';
import { HistoryCertificateComponent } from './components/hist-report/history-certificate/history-certificate.component';
import { PeriodReceiptReportcomponent } from './components/payment/period-receipt/period-receipt-report.component';
import { ControlledDrugsComponent } from './components/controlled-drugs/controlled-drugs.component';
import { CtrdrugsContainerComponent } from './components/ctrdrugs-container/ctrdrugs-container.component';
import { ReportRegFreePayListComponent } from './components/declare/report-reg-free-pay-list/report-reg-free-pay-list.component';
import { OpdDeclareDetailsComponent } from './components/declare/opd-declare-details/opd-declare-details.component';
import { LabsOutclinicReportComponent } from './components/labs/labs-outclinic-report/labs-outclinic-report.component';
import { ReserveReceiptComponent } from './components/reg-reserve/reserve-receipt/reserve-receipt.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { HistoryListComponent } from './components/hist-report/history-list/history-list.component';
import { ReportMedicalVisitPatientComponent } from './components/declare/report-medical-visit-patient/report-medical-visit-patient.component';
import { ReportIndicatorDetailComponent } from './components/val-addapp/report-indicator-detail/report-indicator-detail.component';
import { ReportIndicatorListComponent } from './components/val-addapp/report-indicator-list/report-indicator-list.component';
import { SameTreatPerMonthReportComponent } from './components/declare/sameTreat-per-month/sameTreat-per-month.component';
import { ReportHemodialysisEpoInjectionComponent } from './components/declare/report-hemodialysis-epo-injection/report-hemodialysis-epo-injection.component';
import { ReportHistPrintCompressComponent } from './components/hist-report/report-hist-print-compress/report-hist-print-compress.component';
import { ReportHistPrintOriginalComponent } from './components/hist-report/report-hist-print-original/report-hist-print-original.component';
import { ReportHistPrintDiffpersonComponent } from './components/hist-report/report-hist-print-diffperson/report-hist-print-diffperson.component';
import { ArrearReceiptComponent } from './components/arrear-receipt/arrear-receipt.component';
import { BasicdataReportComponent } from './components/basicdata-report/basicdata-report.component';
import { RxdataReportContentComponent } from './components/rxdata-report-content/rxdata-report-content.component';
import { MainSetDataPrtComponent } from './components/mainsetdata-prt/mainsetdata-prt.component';
import { RegistersubPrtComponent } from './components/registersub-prt/registersub-prt.component';
import { CumulativeMedicalVisitsComponent } from './components/cumulative-medical-visits/cumulative-medical-visits.component';
import { ToothPositionsMapComponent } from 'src/app/hist/tooth-positions-map/tooth-positions-map.component';
import { NoreportPrtComponent } from './components/noreport-prt/noreport-prt.component';
import { DiscountCodeComponent } from './components/discount-code/discount-code.component';
import { FirstFollowUpReportComponent } from './components/first-followUp-report/first-followUp-report.component';
import { SalesOrderReceiptComponent } from './components/sales-order-receipt/sales-order-receipt.component';
import { VisitcountReportComponent } from 'src/app/system/report/components/visitcount-report/visitcount-report.component';
import { UnreportedPrescriptionPrtComponent } from './components/unreported-prescription-prt/unreported-prescription-prt.component';
import { DynamicReciptComponent } from './design-tools/dynamic-recipt/dynamic-recipt.component';
import { PreventCareReportComponent } from './components/prevent-care-report/prevent-care-report.component';
import { RegFreeFeeComponent } from './components/reg-free-fee/reg-free-fee.component';
import { DxanalyzeComponent } from './components/dxanalyze/dxanalyze.component';
import { DxanalyzeDetailComponent } from './components/dxanalyze-detail/dxanalyze-detail.component';
import { InsuranceDeclarationReportComponent } from './components/insurance-declaration-report/insurance-declaration-report.component';
import { StatusFinishWithoutPrescriptionReportComponent } from './components/status-finish-without-prescription-report/status-finish-without-prescription-report.component';
import { RepeatedPrescriptionDynamicComponent } from './design-tools/repeated-prescription/repeated-pres-dynamic/repeated-pres-dynamic.component';


@NgModule({
  declarations: [
    RepeatedPrescriptionComponent,
    DrugBagComponent,
    DiagnosisCertComponent,
    ReceiptComponent,
    HistoryComponent,
    ReportHelperComponent,
    RegisterComponent,
    OpdDeclareSummaryComponent,
    OpdDeclareDetailsComponent,
    ReportRehabilitationComponent,
    ReportRehabilitateComponent,
    ReportRegFreePayListComponent,
    ReportHistIndexComponent,
    ReportMissRegisterComponent,
    ReportCancelReserveComponent,
    DeclareCaseStatisticsComponent,
    DoctorRoomcodePaymentReportComponent,
    PeriodReceiptReportcomponent,
    ConsultationVoulmeComponent,
    CtrdrugsContainerComponent,
    DailyPaymentReportComponent,
    OrderDosageComponent,
    SamplingOrderListComponent,
    HcvisitsComponent,
    HistoryCertificateComponent,
    ControlledDrugsComponent,
    BasicdataReportComponent,
    RxdataReportContentComponent,
    MainSetDataPrtComponent,
    LabsOutclinicReportComponent,
    ReserveReceiptComponent,
    HistoryListComponent,
    SameTreatPerMonthReportComponent,
    ReportMedicalVisitPatientComponent,
    ReportIndicatorDetailComponent,
    ReportIndicatorListComponent,
    ReportHemodialysisEpoInjectionComponent,
    ReportHistPrintCompressComponent,
    ReportHistPrintOriginalComponent,
    ReportHistPrintDiffpersonComponent,
    ArrearReceiptComponent,
    RegistersubPrtComponent,
    FirstFollowUpReportComponent,
    NoreportPrtComponent,
    DiscountCodeComponent,
    SalesOrderReceiptComponent,
    VisitcountReportComponent,
    CumulativeMedicalVisitsComponent,
    UnreportedPrescriptionPrtComponent,
    PreventCareReportComponent,
    DynamicReciptComponent,
    RepeatedPrescriptionDynamicComponent,
    RegFreeFeeComponent,
    ToothPositionsMapComponent,
    DxanalyzeComponent,
    DxanalyzeDetailComponent,
    InsuranceDeclarationReportComponent,
    StatusFinishWithoutPrescriptionReportComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxBarcode6Module,
    ],
  exports: [
    ReportHelperComponent,
    OpdDeclareSummaryComponent,
    OpdDeclareDetailsComponent,
    ReportRehabilitationComponent,
    ReportRehabilitateComponent,
    ReportRegFreePayListComponent,
    ReportHistIndexComponent,
    ReportMissRegisterComponent,
    ReportCancelReserveComponent,
    DeclareCaseStatisticsComponent,
    ConsultationVoulmeComponent,
    DailyPaymentReportComponent,
    DoctorRoomcodePaymentReportComponent,
    PeriodReceiptReportcomponent,
    OrderDosageComponent,
    SamplingOrderListComponent,
    HcvisitsComponent,
    HistoryComponent,
    SameTreatPerMonthReportComponent,
    DiagnosisCertComponent,
    HistoryCertificateComponent,
    ControlledDrugsComponent,
    BasicdataReportComponent,
    RxdataReportContentComponent,
    MainSetDataPrtComponent,
    CtrdrugsContainerComponent,
    ReserveReceiptComponent,
    HistoryListComponent,
    ReportMedicalVisitPatientComponent,
    ReportIndicatorDetailComponent,
    ReportIndicatorListComponent,
    DrugBagComponent,
    ReportHemodialysisEpoInjectionComponent,
    ReportHistPrintCompressComponent,
    ReportHistPrintOriginalComponent,
    ReportHistPrintDiffpersonComponent,
    ArrearReceiptComponent,
    RegistersubPrtComponent,
    NoreportPrtComponent,
    DiscountCodeComponent,
    FirstFollowUpReportComponent,
    SalesOrderReceiptComponent,
    VisitcountReportComponent,
    CumulativeMedicalVisitsComponent,
    UnreportedPrescriptionPrtComponent,
    PreventCareReportComponent,
    DynamicReciptComponent,
    RepeatedPrescriptionDynamicComponent,
    RegFreeFeeComponent,
    ToothPositionsMapComponent,
    DxanalyzeComponent,
    DxanalyzeDetailComponent,
    InsuranceDeclarationReportComponent,
    StatusFinishWithoutPrescriptionReportComponent
  ]
})
export class ReportHelperModule { }
