import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qrcode-scanner',
  templateUrl: './qrcode-scanner.component.html',
  styleUrls: ['./qrcode-scanner.component.scss']
})
export class QrcodeScannerComponent implements OnInit {

  @Output()
  onSuccess:EventEmitter<string> = new EventEmitter();
  constructor() { }
  _camera: MediaDeviceInfo;
  scanEnable = false;
  result = '';
  href = '';
  messageBody = '';
  set activeCamera(value: MediaDeviceInfo){
    this._camera = value;
    if(value){
      this.scanEnable = true;
    }
  }
  get activeCamera(): MediaDeviceInfo{
    return this._camera;
  }
  
  ngOnInit(): void {
    //window.location.href= 'sms:1922?body=1234';
  }
  scanSuccessed(data: string)
  {
    this.result =data
    this.onSuccess.emit(data);
    this.scanEnable = false;
  }
  isIos():boolean{
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

}
