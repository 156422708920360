<div class="card-header mt-1" style="padding-top: 15px;padding-bottom: 0px;">
    <form>
        <div [formGroup]="queryFormGroup" class="c-form-inline float-left w-100">
            <app-search-bar [Cols]="6" [Flex]="true">
                <div searchBarField [fieldTitle]="'診別代號：'" style=" padding:-20px; margin-right: -15px; ">
                    <app-vis-dropdownlist formControlName="RoomId" [data]="roomsDropdown" [textField]="'text'" [valueField]="'value'"
                        [valuePrimitive]="true" style="width: 70px;">
                    </app-vis-dropdownlist>
                </div>
                <div searchBarField [fieldTitle]="'醫師：'" style=" padding:-20px; margin-right: -15px; ">
                    <app-vis-dropdownlist formControlName="DoctorId" [data]="doctorsDropdown" [textField]="'text'" [valueField]="'extension'"
                        [valuePrimitive]="true">
                    </app-vis-dropdownlist>
                </div>
                <div searchBarField [fieldTitle]="'起始日期：'" style=" padding:-20px;  margin-right: -15px; ">
                    <app-vis-datepicker formControlName="startDate"></app-vis-datepicker>
                </div>
                <div searchBarField [fieldTitle]="'結束日期：'" style=" padding:-20px; margin-right: -15px; ">
                    <app-vis-datepicker formControlName="endDate"></app-vis-datepicker>
                </div>
                <div searchBarField [fieldTitle]="'報表類別：'" style=" padding:-20px;  ">
                  <app-vis-dropdownlist formControlName="ReportType" [data]="ReportType"
                      [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                      style="vertical-align: middle; width: 160px;">
                  </app-vis-dropdownlist>
                </div>
                <div searchBarField></div>
                <!-- <div searchBarField></div>
                <div searchBarField></div> -->
                <div searchBarField>
                    <button type="button" (click)="onBtnQueryClick()" i18n="@@Query"
                        class="vis-btn-config p-0 position-relative pl-2 w-18" title="查詢">
                        <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
                    </button>
                    <button type="button" (click)="onBtnPrintClick()" i18n="@@Print"
                        class="vis-btn-config p-0 position-relative pl-2 w-18" title="列印">
                        <mat-icon class="pref-icon vis-combine-icon">printer</mat-icon>
                        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
                    </button>
                    <button type="button" (click)="onBtnExportAsExcel()" i18n="@@Excel"
                        class="vis-btn-config p-0 position-relative pl-2 w-18" title="匯出Excel">
                        <mat-icon class="pref-icon vis-combine-icon">file_open</mat-icon>
                        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯出</span>
                    </button>
                </div>
            </app-search-bar>
        </div>
        <div class="clearfix"></div>
    </form>
    <div class="card-body">
        <ng-container>
            <!-- <app-cumulative-medical-visits [ReportType]="queryFormGroup.controls['ReportType'].value"></app-cumulative-medical-visits> -->
            <app-cumulative-medical-visits></app-cumulative-medical-visits>
        </ng-container>
    </div>
</div>
