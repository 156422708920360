/**  */
export enum HistActionEnum {
    /** 無 */
    NA = 0,
    /** 進入診間 */
    EnterRoom = 1,
    /** 離開 */
    Exit = 2,
    /** 存檔 */
    Save = 3,
    /** 完診 */
    Finish = 4
}
export const HistActionDesc: {text: string, value: number}[] =
    [{text: '無', value: 0 },
     {text: '進入診間', value: 1 },
     {text: '離開', value: 2 },
     {text: '存檔', value: 3 },
     {text: '完診', value: 4 }];

