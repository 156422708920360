import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { WholeHist, WholeHistPatientsDto } from 'src/app/services/api-service/hist/whole-hist';
import { PatientView } from './model/PatientView';
import { formatDate } from '@angular/common';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { RegistrationCodeApiService } from 'src/app/services/api-service/registration-code/registration-code-api.service';
import { IGroup } from 'src/app/shared/models/i-group';
import { RegistrationCodeView } from 'src/app/services/api-service/registration-code/registration-code-api.model';
import { SendEmitData } from './model/SendEmitData';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HistOrder } from '../models/hist-order';

@Component({
  selector: 'app-pacscreate-order',
  templateUrl: './pacscreate-order.component.html',
  styleUrls: ['./pacscreate-order.component.css']
})
export class PACSCreateOrderComponent implements OnInit {

  @Input() wholeHist: WholeHist;
  @Input() triggerDate: Date = null
  @Input() accessionNumber: string = "";

  @Output() onEmitClose = new EventEmitter();
  @Output() onEmitSendSelectedRxCodes = new EventEmitter<SendEmitData>();

  patientView: PatientView = {
    Id: null,
    PatientNo: "",
    PatientName: "",
    Birthday: null,
    SexName: "",
    CId: "",
    Phone: "",
    CellPhone: "",
    Address: "",
  };

  regCodes: RegistrationCodeView[] = [];
  notInputRx: boolean = false;

  regCodeGroups: IGroup<ValueTextPairNumberValue>[] = [];

  selectedRegCodes: number[] = [];


  constructor(private registrationCodeApiService: RegistrationCodeApiService,
    private notificationDI: EasyNotificationService
  ) { }

  async ngOnInit(): Promise<void> {
    this.fillPatientView();
    await this.genRegCodeGroups();
    this.initViewControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.fillPatientView();
  }

  fillPatientView = () => {
    if (this.wholeHist && this.wholeHist.Patient) {
      this.patientView.Id = this.wholeHist.Patient.Id;
      this.patientView.PatientNo = this.wholeHist.Patient.PatientNo;
      this.patientView.PatientName = this.wholeHist.Patient.CName;
      this.patientView.Birthday = this.wholeHist.Patient.Birthday;
      this.patientView.SexName = this.wholeHist.Patient.SexName;
      this.patientView.CId = this.wholeHist.Patient.CId;
      this.patientView.Phone = this.combinePhone(this.wholeHist.Patient);
      this.patientView.CellPhone = this.wholeHist.Patient.CellPhone;
      this.patientView.Address = this.combineAddress(this.wholeHist.Patient);
    }
  }

  combinePhone = (patient: WholeHistPatientsDto): string => {
    let { PhoneArea, Phone } = patient;

    if (PhoneArea && Phone) {
      return `(${patient.PhoneArea})${patient.Phone}`;
    }

    if (Phone) {
      return patient.Phone;
    }

    return '';

  }

  combineAddress = (patient: WholeHistPatientsDto): string => {
    let { ZipCode, City, Area, Street } = patient;

    // 使用 `filter` 跳過 `null` 和空值
    let addresses = [ZipCode, City, Area, Street].filter(Boolean) as string[];

    return addresses.length > 0 ? addresses.join(" ") : "";
  }

  onBtnSendClick = () => {
    let selectRegCodes = Array.from(new Set(
      this.regCodes
        .filter(x => this.selectedRegCodes.includes(x.Id))
    ));

    if (selectRegCodes.length > 0) {
      let firstMType = selectRegCodes[0].MType;
      let allSameMType = selectRegCodes.every(data => data.MType === firstMType);

      if (!allSameMType) {
        this.notificationDI.showError("選取項目的檢查種類必須全部一致")
        return;
      }

      let sendEmitData: SendEmitData = {
        isInputRx: !this.notInputRx,
        selectedRegCodes: selectRegCodes,
        patientView: this.patientView,
        triggerDate: this.triggerDate,
        accessionNumber: this.accessionNumber
      }


      this.onEmitSendSelectedRxCodes.emit(sendEmitData);
    }

    this.onEmitClose.emit();
  }

  onBtnCloseClick = () => {
    this.onEmitClose.emit();
  }

  onCheckboxRegCodeChecked = (event: any): void => {
    if (event.checked) {
      this.selectedRegCodes.push(event.value);
    } else {
      const index = this.selectedRegCodes.indexOf(event.value);
      if (index !== -1) {
        this.selectedRegCodes.splice(index, 1);
      }
    }
  }

  onCheckBoxNotInputRxChecked = (event: any) => {
    this.notInputRx = event.checked;
  }

  private getRegCodes = async () => {
    this.regCodes = await this.registrationCodeApiService.GetAll({
      CodeValue: "",
      CodeMean: "",
      No: "",
      MType: "",
      EndDate: null,
      isClinic: true,
      expireFilter: true,
      pageNumber: 0,
      pageSize: 0,
    })

  }

  private genRegCodeGroups = async () => {
    await this.getRegCodes();

    const groupsMap = new Map<string, ValueTextPairNumberValue[]>();

    this.regCodes.forEach(item => {
      let groupKey = item.No && item.No.includes('-') ? item.No.split('-')[0] : "Other";
      let itemText = `【${item.No}】${item.CodeMean}`;
      let newItem: ValueTextPairNumberValue = { text: itemText, value: item.Id };

      if (groupsMap.has(groupKey)) {
        groupsMap.get(groupKey)?.push(newItem);
      }
      else {
        groupsMap.set(groupKey, [newItem]);
      }
    })

    this.regCodeGroups = Array.from(groupsMap, ([key, items]) => ({ key, items }));
  }

  private initViewControl = () => {

    if (this.wholeHist && this.wholeHist.Hist && this.wholeHist.Hist.HRxs.length > 0) {
      this.notInputRx = true;

      let selected: number[] = [];
      this.wholeHist.Hist.HRxs.map(element => {
        let regCode = this.regCodes.find(reg => this.getCodeValueFull(reg) == element.RxCode);
        if (regCode) {
          selected = [...selected, regCode.Id];
        }
      })

      this.selectedRegCodes = [...this.selectedRegCodes, ...selected]
    }

  }

  private getCodeValueFull(regCode: RegistrationCodeView): string {
    return regCode.CodeValue + (regCode.CodeValueSuffix ?? '');
  }


}
