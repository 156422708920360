<app-search-bar>
  <button type="button" (click)="onReportPrint()" [disabled]="!this.reportData || this.reportData.TotalPage === 0"
    class="vis-btn-config p-0 position-relative pl-2 w-18 m-1 mt-0" title="列印">
    <mat-icon class="pref-icon add vis-combine-icon">print</mat-icon>
    <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
  </button>
  <button type="button" *ngIf="false" (click)="onReportExportExcel()"
    [disabled]="!this.reportData || this.reportData.TotalPage === 0" i18n="@@Excel"
    class="vis-btn-config p-0 position-relative pl-2 w-18 m-1 mt-0" title="匯出Excel">
    <mat-icon class="pref-icon vis-combine-icon">file_open</mat-icon>
    <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯出</span>
  </button>
</app-search-bar>
<mat-divider></mat-divider>

<app-lab-hrx-list-report [hidden]="false" #detailPDF [reportData]="this.reportData">
</app-lab-hrx-list-report>
