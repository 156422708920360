import { H12 } from "./H12";

export class H11Helper {

  /**
   *
   */
  constructor(public source: H11) { }
  /** 醫師名 */
  get DoctorName() { return this.source.DoctorName; }
  /** 藥師名 */
  get PharmacistName() { return this.source.PharmacistName; }
  /** 資料格式，請填入"11" */
  get DxName() { return this.source.DxName; }
  /** 資料格式，請填入"11" */
  get Type() { return this.source.e11tp; }
  /** 醫事服務機構代號 */
  get NHICode() { return this.source.e11cd; }
  /** 費用年月，前三碼為年份後二碼為月份 */
  get Month() { return this.source.e11ym; }
  /** 申報類別：1送核 2補報 */
  get Kind() { return this.source.e11kd; }
  /** 案件分類 */
  get d1_Case() { return this.source.e11cs; }
  /** 流水號 */
  get d2_Sequence() { return this.source.e11sq; }
  /** 身分證統一編號 */
  get d3_Cid() { return this.source.e11id; }
  /** 特定治療項目代號(一) */
  get d4_SpecialItem1() { return this.source.e11i1; }
  /** 特定治療項目代號(二) */
  get d5_SpecialItem2() { return this.source.e11i2; }
  /** 特定治療項目代號(三) */
  get d6_SpecialItem3() { return this.source.e11i3; }
  /** 特定治療項目代號(四) */
  get d7_SpecialItem4() { return this.source.e11i4; }
  /** 就醫科別 */
  get d8_Dept() { return this.source.e11sb; }
  /** 就醫日期 */
  get d9_ClinicDate() { return this.source.e11da; }
  /** 治療結束日期 */
  get d10_EndDate() { return this.source.e11ed; }
  /** 出生日期 */
  get d11_BirthDate() { return this.source.e11bd; }

  /** 補報原因註記，原E11CR取消 */
  get d12_ReasonNote() { return this.source.e1112; }
  /** 整合式照護計畫註記，原E11CR取消 */
  get d13_IntegrateNote() { return this.source.e1113; }
  /** 給付類別 */
  get d14_PayTpe() { return this.source.e11ds; }
  /** 部分負擔代號 */
  get d15_PartCode() { return this.source.e11c8; }
  /** 轉診、處方調劑或特定檢查資源共享案件註記 */
  get d16_ShareNote() { return this.source.e11rf; }
  /** 轉診、處方調劑或特定檢查資源共享案件之服務機構代號 */
  get d17_ShareNHICode() { return this.source.e11r0; }
  /** 病患是否轉出 */
  get d18_Referral() { return this.source.e11so; }
  /** 國際疾病分類號(一) */
  get d19_Dx1() { return this.source.e11n1; }

  /** 國際疾病分類號(二) */
  get d20_Dx2() { return this.source.e11n2; }
  /** 國際疾病分類號(三) */
  get d21_Dx3() { return this.source.e11n3; }
  /** 國際疾病分類號(四) */
  get d22_Dx4() { return this.source.e11n4; }
  /** 國際疾病分類號(五) */
  get d23_Dx5() { return this.source.e11n5; }
  /** 健保欄位=(d24)長度S:0→9字元←[主手術代碼] */
  get d24_Surgen() { return this.source.e11op; }
  /** 健保欄位=(d25)長度S:0→9字元←[次手術(處置)代碼(一)] */
  get d25_SubSurgen1() { return this.source.e11o1; }
  /** 健保欄位=(d26)長度S:0→9字元←[次手術(處置)代碼(二)] */
  get d26_SubSurgen2() { return this.source.e11o2; }
  /** 健保欄位=(d27)長度N:1→2字元←[給藥日份] */
  get d27_Days() { return this.source.e11dy; }
  /** 健保欄位=(d28)長度F:1→1字元←[處方調劑方式 ] */
  get d28_DispTp() { return this.source.e11em; }
  /** 健保欄位=(d29)長度F:4→4字元←[就醫序號(原E11LN前4碼，及末2碼取消)] */
  get d29_icno() { return this.source.e1129; }

  /** 健保欄位=(d30)長度S:0→10字元←[診治醫師代號] */
  get d30_DoctorCId() { return this.source.e11dt; }
  /** 健保欄位=(d31)長度S:0→10字元←[藥師代號] */
  get d31_PhmarcistCId() { return this.source.e11dr; }
  /** 健保欄位=(d32)長度N:1→8字元←[用藥明細點數小計] */
  get d32_ADrug() { return this.source.e11a2; }
  /** 健保欄位=(d33)長度N:1→8字元←[診療明細點數小計] */
  get d33_ATreat() { return this.source.e11a6; }
  /** 健保欄位=(d34)長度N:1→8字元←[特殊材料明細點數小計] */
  get d34_AMat() { return this.source.e11ab; }
  /** 健保欄位=(d35)長度S:0→12字元←[診察費項目代號] */
  get d35_ADiagCode() { return this.source.e11c1; }
  /** 健保欄位=(d36)長度N:1→8字元←[診察費] */
  get d36_ADiag() { return this.source.e11a1; }
  /** 健保欄位=(d37)長度S:0→12字元←[藥事服務費項目代號] */
  get d37_AMedCode() { return this.source.e11c9; }
  /** 健保欄位=(d38)長度N:1→8字元←[藥事服務費] */
  get d38_AMed() { return this.source.e1199; }
  /** 健保欄位=(d39)長度N:1→8字元←[合計點數] */
  get d39_ATot() { return this.source.e11tt; }
  /** 健保欄位=(d40)長度N:1→8字元←[部分負擔費用金額] */
  get d40_APart() { return this.source.e1198; }
  /** 健保欄位=(d41)長度N:1→8字元←[申請點數] */
  get d41_ATotPoint() { return this.source.e11ta; }
  /** 健保欄位=(d42)長度S:0→2字元←[論病例計酬代碼(原E11R1前2碼)] */
  get d42_PayCodeByCase() { return this.source.e1142; }
  /** 健保欄位=(d43)長度S:0→6字元←[行政協助項目部分負擔點數(原E11R1,3-8)] */
  get d43_Part_admin() { return this.source.e1143; }
  /** 健保欄位=(d44)長度N:1→2字元←[慢性病連續處方箋有效期間總處方日份(原E11R1末2碼)] */
  get d44_TotDays_chronic() { return this.source.e1144; }
  /** 健保欄位=(d45)長度S:0→7字元←[依附就醫新生兒出生日期] */
  get d45_BabyBirth() { return this.source.e11bb; }


  /** 健保欄位=(d48)長度S:0→10字元←[山地離島地區醫療服務計畫代碼] */
  get d48_MountainCode() { return this.source.e11g9; }

  /** 健保欄位=(d49)長度S:0→20字元←[姓名] */
  get d49_Name() { return this.source.e11nm; }
  /** 健保欄位=(d50)長度S:0→10字元←[矯正機關代號] */
  get d50_CorrectionalCode() { return this.source.e11rc; }
  /** 健保欄位=(d51)長度S:0→1字元←[依附就醫新生兒胞胎註記] */
  get d51_BabyCount() { return this.source.e11bm; }
  /** 健保欄位=(d52)長度S:0→2字元←[特定地區醫療服務] */
  get d52_SpecialRegion() { return this.source.e11la; }
  /** 健保欄位=(d53)長度S:0→4字元←[支援區域] */
  get d53_SupportRegion() { return this.source.e11ar; }
  /** 健保欄位=(d54)長度S:0→10字元←[實際提供醫療服務之醫事服務機構代號] */
  get d54_ClinicNhiCode_acture() { return this.source.e11gd; }
  /** 健保欄位=(d55)長度S:0→10字元←[轉往之醫事服務機構代號] */
  get d55_ClinicNhiCode_refferlTo() { return this.source.e11sy; }
  /** 健保欄位=(d56)長度S:0→4字元←[原處方就醫序號] */
  get d56_OriIsqno() { return this.source.e11is; }
  /** 健保欄位=(d57)長度N:1→8字元←[基本部分負擔點數，小數點以下無條件捨去] */
  get d57_APart() { return this.source.e1157; }
  /** 健保欄位=(d58)長度N:1→8字元←[藥品部分負擔點數，小數點以下無條件捨去] */
  get d58_APartDrug() { return this.source.e1158; }
  /** 健保欄位=(d59)長度N:1→8字元←[檢驗(查)部分負擔點數，小數點以下無條件捨去] */
  get d59_APartExam() { return this.source.e1159; }
  /** 健保欄位=(d60)長度N:1→20字元←[就醫識別碼(刷卡當次、若欠卡則為還卡當次)NHIRegIC.Itreatnum(M15)] */
  get d60_Itreatnum() { return this.source.e11nic; }
  /** 健保欄位=(d61)長度N:1→20字元←[實際就醫(調劑或檢查)之就醫識別碼(欠上當時的)NHIDeclare.LackTreatnum] */
  get d61_LackTreatnum() { return this.source.e11lic; }
  /** 健保欄位=(d62)長度N:1→20字元←[原就醫識別碼(同療、排檢及慢箋必填，跨月時取首次的)NHIRegIC.ItreatnumOri(M16)] */
  get d62_ItreatnumOri() { return this.source.e11oic; }
}
/** 門診申報-點數清單段(H11) */
export interface H11 {

  /** table stoarge用 */
  PartitionKey: string;
  /** table stoarge用 */
  RowKey: string;
  /** table stoarge用 */
  Timestamp: Date;
  /** table stoarge用 */
  ETag: string;
  /** 診所id */
  ClinicId: number;
  /** 個案Id */
  PatientId: number;
  /** 個案病歷號 */
  PatientNo: string;
  /** 病歷id */
  HistroyId: number;
  /** 原sq流水號 */
  sq: string;
  /** 健保欄位=(無)長度F:2→2字元←[資料格式，請填入"11"] */
  e11tp: string;
  /** 健保欄位=(無)長度F:10→10字元←[醫事服務機構代號] */
  e11cd: string;
  /** 健保欄位=(無)長度F:5→5字元←[費用年月，前三碼為年份後二碼為月份] */
  e11ym: string;
  /** 健保欄位=(無)長度F:1→1字元←[申報類別：1送核 2補報] */
  e11kd: string;
  /** 健保欄位=(d1)長度F:2→2字元←[案件分類] */
  e11cs: string;
  /** 健保欄位=(d2)長度F:6→6字元←[流水號] */
  e11sq: string;
  /** 健保欄位=(d4)長度S:0→2字元←[特定治療項目代號(一)] */
  e11i1: string;
  /** 健保欄位=(d5)長度S:0→2字元←[特定治療項目代號(二)] */
  e11i2: string;
  /** 健保欄位=(d6)長度S:0→2字元←[特定治療項目代號(三)] */
  e11i3: string;
  /** 健保欄位=(d7)長度S:0→2字元←[特定治療項目代號(四)] */
  e11i4: string;
  /** 健保欄位=(d8)長度F:2→2字元←[就醫科別] */
  e11sb: string;
  /** 健保欄位=(d9)長度F:7→7字元←[就醫日期] */
  e11da: string;
  /** 健保欄位=(d10)長度S:0→7字元←[治療結束日期] */
  e11ed: string;
  /** 健保欄位=(d11)長度F:7→7字元←[出生日期] */
  e11bd: string;
  /** 健保欄位=(d3)長度F:10→10字元←[身分證統一編號] */
  e11id: string;
  /** 健保欄位=(d12)長度S:0→1字元←[補報原因註記，原E11CR取消] */
  e1112: string;
  /** 健保欄位=(d13)長度S:0→1字元←[整合式照護計畫註記，原E11CR取消] */
  e1113: string;
  /** 健保欄位=(d14)長度F:1→1字元←[給付類別] */
  e11ds: string;
  /** 健保欄位=(d15)長度F:3→3字元←[部分負擔代號] */
  e11c8: string;
  /** 健保欄位=(d16)長度S:0→2字元←[轉診、處方調劑或特定檢查資源共享案件註記] */
  e11rf: string;
  /** 健保欄位=(d17)長度S:0→10字元←[轉診、處方調劑或特定檢查資源共享案件之服務機構代號] */
  e11r0: string;
  /** 健保欄位=(d18)長度F:1→1字元←[病患是否轉出] */
  e11so: string;
  /** 健保欄位=(d19)長度S:0→9字元←[國際疾病分類號(一)] */
  e11n1: string;
  /** 健保欄位=(d20)長度S:0→9字元←[國際疾病分類號(二)] */
  e11n2: string;
  /** 健保欄位=(d21)長度S:0→9字元←[國際疾病分類號(三)] */
  e11n3: string;
  /** 健保欄位=(d22)長度S:0→9字元←[國際疾病分類號(四)] */
  e11n4: string;
  /** 健保欄位=(d23)長度S:0→9字元←[國際疾病分類號(五)] */
  e11n5: string;
  /** 健保欄位=(d24)長度S:0→9字元←[主手術代碼] */
  e11op: string;
  /** 健保欄位=(d25)長度S:0→9字元←[次手術(處置)代碼(一)] */
  e11o1: string;
  /** 健保欄位=(d26)長度S:0→9字元←[次手術(處置)代碼(二)] */
  e11o2: string;
  /** 健保欄位=(d27)長度N:1→2字元←[給藥日份] */
  e11dy: string;
  /** 健保欄位=(d28)長度F:1→1字元←[處方調劑方式 ] */
  e11em: string;
  /** 健保欄位=(d29)長度F:4→4字元←[就醫序號(原E11LN前4碼，及末2碼取消)] */
  e1129: string;
  /** 健保欄位=(d30)長度S:0→10字元←[診治醫師代號] */
  e11dt: string;
  /** 健保欄位=(d31)長度S:0→10字元←[藥師代號] */
  e11dr: string;
  /** 健保欄位=(d32)長度N:1→8字元←[用藥明細點數小計] */
  e11a2: string;
  /** 健保欄位=(d33)長度N:1→8字元←[診療明細點數小計] */
  e11a6: string;
  /** 健保欄位=(d34)長度N:1→8字元←[特殊材料明細點數小計] */
  e11ab: string;
  /** 健保欄位=(d35)長度S:0→12字元←[診察費項目代號] */
  e11c1: string;
  /** 健保欄位=(d36)長度N:1→8字元←[診察費] */
  e11a1: string;
  /** 健保欄位=(d37)長度S:0→12字元←[藥事服務費項目代號] */
  e11c9: string;
  /** 健保欄位=(d38)長度N:1→8字元←[藥事服務費] */
  e1199: string;
  /** 健保欄位=(d39)長度N:1→8字元←[合計點數] */
  e11tt: string;
  /** 健保欄位=(d40)長度N:1→8字元←[部分負擔費用金額] */
  e1198: string;
  /** 健保欄位=(d41)長度N:1→8字元←[申請點數] */
  e11ta: string;
  /** 健保欄位=(d42)長度S:0→2字元←[論病例計酬代碼(原E11R1前2碼)] */
  e1142: string;
  /** 健保欄位=(d43)長度S:0→6字元←[行政協助項目部分負擔點數(原E11R1,3-8)] */
  e1143: string;
  /** 健保欄位=(d44)長度N:1→2字元←[慢性病連續處方箋有效期間總處方日份(原E11R1末2碼)] */
  e1144: string;
  /** 健保欄位=(d45)長度S:0→7字元←[依附就醫新生兒出生日期] */
  e11bb: string;
  /** 健保欄位=(d48)長度S:0→10字元←[山地離島地區醫療服務計畫代碼] */
  e11g9: string;
  /** 健保欄位=(d49)長度S:0→20字元←[姓名] */
  e11nm: string;
  /** 健保欄位=(d50)長度S:0→10字元←[矯正機關代號] */
  e11rc: string;
  /** 健保欄位=(d51)長度S:0→1字元←[依附就醫新生兒胞胎註記] */
  e11bm: string;
  /** 健保欄位=(d52)長度S:0→2字元←[特定地區醫療服務] */
  e11la: string;
  /** 健保欄位=(d53)長度S:0→4字元←[支援區域] */
  e11ar: string;
  /** 健保欄位=(d54)長度S:0→10字元←[實際提供醫療服務之醫事服務機構代號] */
  e11gd: string;
  /** 健保欄位=(d55)長度S:0→10字元←[轉往之醫事服務機構代號] */
  e11sy: string;
  /** 健保欄位=(d56)長度S:0→4字元←[原處方就醫序號] */
  e11is: string;
  /** 健保欄位=(d57)長度N:1→8字元←[基本部分負擔點數，小數點以下無條件捨去] */
  e1157: string;
  /** 健保欄位=(d58)長度N:1→8字元←[藥品部分負擔點數，小數點以下無條件捨去] */
  e1158: string;
  /** 健保欄位=(d59)長度N:1→8字元←[檢驗(查)部分負擔點數，小數點以下無條件捨去] */
  e1159: string;
  /** 健保欄位=(d60)長度N:1→20字元←[就醫識別碼(刷卡當次、若欠卡則為還卡當次)NHIRegIC.Itreatnum(M15)] */
  e11nic: string;
  /** 健保欄位=(d61)長度N:1→20字元←[實際就醫(調劑或檢查)之就醫識別碼(欠上當時的)NHIDeclare.LackTreatnum] */
  e11lic: string;
  /** 健保欄位=(d62)長度N:1→20字元←[原就醫識別碼(同療、排檢及慢箋必填，跨月時取首次的)NHIRegIC.ItreatnumOri(M16)] */
  e11oic: string;
  /** paysys 比對用 */
  e11ps: string;
  /** 建立者 */
  creater: string;
  /** 建立時間 */
  createtime: Date;
  /** 國際病名 */
  DxName: string;
  /** 主訴區段 */
  CC: string;
  /** 醫師名 */
  DoctorName: string;
  /** 藥師名 */
  PharmacistName: string;
  /**  H12 醫令清單段 */
  H12List: H12[];
}
