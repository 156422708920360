import { Component, Input, OnInit } from '@angular/core';
import { GridData } from '../../grid/vis-grid/vis-grid.component';
import { RegisterClinicHistoryDTO, RegisterPeriodItemDTO } from 'src/app/services/api-service-dentist/register/register-api.model';

@Component({
  selector: 'app-clinic-history-grid',
  templateUrl: './clinic-history-grid.component.html',
  styleUrls: ['./clinic-history-grid.component.css']
})
export class ClinicHistoryGridComponent implements OnInit {

  @Input() title: string;
  @Input() pageSize: number = 10;
  @Input() pageSkipNo: number = 0;
  @Input() width: string = '100%';
  @Input() set data(value: RegisterClinicHistoryDTO[]) {
    this._data = value;
    this.pageChange(1); // 每次設定時執行
  }

  get data(): RegisterClinicHistoryDTO[] {
    return this._data;
  }


  private _data: RegisterClinicHistoryDTO[] = [];
  gridView: GridData;
  selectedItems: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.pageChange(1);

  }

  pageChange(page: number): void {
    const startIndex = (page - 1) * this.pageSize;
    let pageData= this.data.slice(startIndex, startIndex + this.pageSize);

    this.gridView = {
      data: pageData,
      total: pageData.length
    };
  }

}
