import { IEntity } from "./i-entity";
import { IdHelper } from "../helpers/guid-helper";

export class Entity implements IEntity {
  Id: number;
  Creater: string;
  CreateTime: Date;
  Editer: string;
  EditTime: Date;
  IsEnabled: boolean;

  constructor() {
    this.Creater = "";
    this.CreateTime = new Date();
    this.Editer = "";
    this.EditTime = new Date();
    this.IsEnabled = true;
  }
}
