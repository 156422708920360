

/**
 * 
 */ 
export class CSHISResult {
    /**
     * DLL回來的狀態碼
     */ 
    public StatusCode: number = 0;
    /**
     * 錯誤訊息
     */ 
    public ErrorMsg: string = null;
}
