
<app-vis-window id="histHelper"  *ngIf="true||isOpened" title="" class="opd-helper"
  [hidden]="!isOpened" [minWidth]="winWidth" [width]="winWidth" [minHeight]="0"
  [height]="winHeight" [left]="winLeft" [top]="winTop" [hideTitle]="true" [resizable]="false">

  <div class="item-wrapper" #itemWrapper [style.height]="wrapperHeightStyle">
    <div class="item-row-set">
      <!-- Loading-->
      <ng-container *ngIf="templateType === templateTypeEnum.NA">
        <div>資料載入中…</div>
      </ng-container>
    <!-- template 1 -->
    <ng-container *ngIf="templateType === templateTypeEnum.Default">
      <ng-container *ngFor="let item of listItems; let i = index">
        <!--model: SystemCode -->
        <div [class]="rowClass(i)" (click)="onItemClick(item)" >
          <ng-container *ngTemplateOutlet="name; context: {$implicit: display(item)}"></ng-container>

          <ng-template let-data #name>
            <div style="display: flex;" *ngIf="data.isCodeMatch">
              <div style="width: 40px;">
                [{{data.before}}<span>{{data.match}}</span>{{data.after}}]
              </div>
              <div style="font-style: italic;overflow-x: hidden;">
                {{item.Name}}
              </div>
            </div>
            <ng-container *ngIf="!data.isCodeMatch">
              {{data.before}}<span>{{data.match}}</span>{{data.after}}
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </ng-container>
    <!-- template 2 -->
    <ng-container *ngIf="templateType === templateTypeEnum.Diag">
      <div class="item-header">
        <div class="item-text-70px">ICD-9</div>
              <div class="item-text-70px">院內代碼</div>
              <div class="item-text-70px">ICD-10</div>
              <div class="item-text-verylongstring-overhide">診斷名(中)</div>
              <!-- <div class="item-text-20px">{{empty}}</div> -->
              <div class="item-text-verylongstring-overhide">診斷名(英)</div>
      </div>
      <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperDiag>
        <ng-container *ngFor="let item of listItems; let i = index">
          <!--model: Diagnosis -->
          <div [class]="rowClass(i)" (click)="onItemClick(item)">
            <ng-container *ngTemplateOutlet="diag; context: {$implicit: displayDiag(item)}"></ng-container>
            <ng-template let-data #diag>
              <ng-container *ngIf="data.isMatch=='ICD9'">
                <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                <div class="item-text-70px">{{item.Code}}</div>
                <div class="item-text-70px">{{item.ApplyCode}}</div>
                <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                <!-- <div class="item-text-20px">{{empty}}</div> -->
                <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
              </ng-container>
              <ng-container *ngIf="data.isMatch=='Code'">
                <div class="item-text-70px">{{item.ICD9}}</div>
                <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                <div class="item-text-70px">{{item.ApplyCode}}</div>
                <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                <!-- <div class="item-text-20px">{{empty}}</div> -->
                <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
              </ng-container>
              <ng-container *ngIf="data.isMatch=='Apply'">
                <div class="item-text-70px">{{item.ICD9}}</div>
                <div class="item-text-70px">{{item.Code}}</div>
                <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                <!-- <div class="item-text-20px">{{empty}}</div> -->
                <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
              </ng-container>
              <ng-container *ngIf="data.isMatch=='Name'">
                <div class="item-text-70px">{{item.ICD9}}</div>
                <div class="item-text-70px">{{item.Code}}</div>
                <div class="item-text-70px">{{item.ApplyCode}}</div>
                <div class="item-text-verylongstring-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                <!-- <div class="item-text-20px">{{empty}}</div> -->
                <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!-- template 3 -->
    <ng-container *ngIf="templateType === templateTypeEnum.OrderCode">
      <div [disabled]="true" class="item2">
        <ng-container>
          <div class="rx-code">
            <div class="item-text-70px">代碼</div>
            <div class="item-text-green">健保價</div>
            <div class="item-text-longstring">名稱</div>
            <div class="item-text-green">歸屬</div>
            <div class="item-text-70px">備註</div>
          </div>
        </ng-container>
      </div>
      <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperRx>
        <ng-container *ngFor="let item of listItems; let i = index">
          <!--model: Order -->
          <div [class]="rowClass(i)" (click)="onItemClick(item)">
            <ng-container *ngTemplateOutlet="rx; context: {$implicit: displayRx(item)}"></ng-container>
            <ng-template let-data #rx>
              <ng-container *ngIf="data.isMatch=='Code'">
                <div class=" rx-code">
                  <div class="item-text-70px-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <div class="item-text-green">{{(item.IPrice || 0) | NumberFixed:2}}</div>
                  <div class="item-text-longstring-overhide">{{item.ProdName}}</div>
                  <div class="item-text-green">{{item.Type}}</div>
                  <ng-container *ngTemplateOutlet="flag; context: {$implicit: item}"></ng-container>
                </div>
              </ng-container>
              <ng-container *ngIf="data.isMatch=='Name'">
                <div class="item-text-70px-overhide">{{item.RxCode}}</div>
                <div class="item-text-green">{{(item.IPrice || 0) | NumberFixed:2}}</div>
                <div class="item-text-longstring-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                <div class="item-text-green">{{item.Type}}</div>
                <ng-container *ngTemplateOutlet="flag; context: {$implicit: item}"></ng-container>
              </ng-container>
            </ng-template>
            <ng-template #flag let-item>
              <div class="item-text-70px">
                <div *ngIf="item.FlagDescription?.includes('STOP;')" class="item-text-red-overhide">[停用]</div>
                <div *ngIf="item.FlagDescription?.includes('ALTERNATIVE;')" class="item-text-blue-overhide">[替代]</div>
                <div *ngIf="item.FlagDescription?.includes('a1;')" class="item-text-purple-overhide">[瓶藥]</div>
                <div *ngIf="item.FlagDescription?.includes('MAINSET;')" class="item-text-black-overhide">[套餐]</div>
              </div>
            </ng-template>
            <!--<div class="item-text-1">{{item.RxCode}}</div> -
            <div class="item-text-3">{{item.ProdName}}</div> -->
          </div>
        </ng-container>
      </div>
    </ng-container>
    </div>
  </div>
</app-vis-window>
