/** 血型列舉 */
export enum BloodEnum {
    /** A型 */
    BloodA = 1,
    /** B型 */
    BloodB = 2,
    /** O型 */
    BloodO = 3,
    /** AB型 */
    BloodAB = 4,
    /** 未知 */
    BloodNone = 999
}
export const BloodDesc: {text: string, value: number}[] =
    [{text: 'A型', value: 1 },
     {text: 'B型', value: 2 },
     {text: 'O型', value: 3 },
     {text: 'AB型', value: 4 },
     {text: '未知', value: 999 }];

