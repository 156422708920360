import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';
import { BaseConfig } from 'src/app/services/base-config.service';
import { FormatRocDatePipe } from './format-date-roc.pipe';
import { NullOrEmpty } from '../utilities';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let sepetator = '/';
    if(!NullOrEmpty(args)&&args.toLowerCase().includes("bar")){
      sepetator = '-';
    }

    if(BaseConfig.getConfig().ui.rocDate){
      args = 'roc';
    }
    
    if(args.toLowerCase() == "short"){
      return DateHelper.formatShortDate(value);
    } else if(args.toLowerCase() == "roc") {
      if(!NullOrEmpty(value)) {
        return DateHelper.getROCDateStringWithSepetator(value instanceof Date?value: new Date(value),sepetator);
      } else {
        return '';
      }
      //return DateHelper.formatROCDate(value, true);
    } else {
      return DateHelper.formatDate(value);
    }
  }
}
