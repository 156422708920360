export class PageData {
  // 這兩個暫時用不到
  // pageSkipNo: number;
  // pageSize: number;

  total: number;
  totalPages: number;
  data: any[];
}

export class PageData2<T> {
  // 這兩個暫時用不到
  // pageSkipNo: number;
  // pageSize: number;

  total: number;
  totalPages: number;
  data: T[];
}
