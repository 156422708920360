import { ReportComponent } from './../report-component';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { ReportComponentService } from '../report-component.service';

export class ArrearReceiptViewModel {
  PatientBirthDay: string;
  PatientName: string;
  PatientCId: string;
  OutpatientDay: string;
  OutpatientTime: string;
  ICTypeName: string;
  MissingCardDeposit: number;
  ClinicName: string;
  ClinicAddress: string;
  ClinicPhone: string;
}


@Component({
  selector: 'app-arrear-receipt',
  templateUrl: './arrear-receipt.component.html',
  styleUrls: ['./arrear-receipt.component.css']
})

export class ArrearReceiptComponent implements ReportComponent, OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  @Input()
  regId: number;

  arrearReceiptViewModel: ArrearReceiptViewModel = null;

  initHasException: boolean = false;

  constructor(private histApi: HistApi,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService: HcrService,
    private changeDetectorRef: ChangeDetectorRef,
    private reportComponentService: ReportComponentService
  ) { }

  ngOnInit(): void {

    this.arrearReceiptViewModel = {
      PatientBirthDay: "",
      PatientName: "",
      PatientCId: "",
      OutpatientDay: "",
      OutpatientTime: "",
      ICTypeName: "",
      MissingCardDeposit: 0,
      ClinicName: "",
      ClinicAddress: "",
      ClinicPhone: ""
    }

    this.getHistArrearReceipt();
  }

  async getHistArrearReceipt() {
    if (this.regId) {
      await this.histApi.GetHistArrearReceipt(this.regId)
        .then(result => {
          this.arrearReceiptViewModel = result;
        })
        .catch(error => {
          this.notification.showError(error.message);
          this.initHasException = true;
        });
      this.arrearReceiptViewModel.MissingCardDeposit = Math.floor(this.arrearReceiptViewModel.MissingCardDeposit);
      this.changeDetectorRef.detectChanges();
    }

  }

  async onPrint(): Promise<boolean> {
    await this.getHistArrearReceipt();

    if (this.initHasException) {
      this.initHasException = false;
      return false;
    }

    if (this.arrearReceiptViewModel) {
      const reportName = '欠卡單據';

      let printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(810, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
        return true;
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
        return false;

      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
      return false;
    }
  }

  getHtml(): string {
    let cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    let style = `
      <style>
        ${cssText}

        @page {
          size: '21.5cm 9cm;';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    var html = this.reportContainer.nativeElement.innerHTML;

    let fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm">${html}</body>
    </html>`;

    return fullHtml;
  }
}
