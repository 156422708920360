import { query } from '@angular/animations';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DeclareApi, OpdDeclareDetailsRptDTO } from 'src/app/services/api-service/declare/declare-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ToThousandFixed, isNullOrUndefined } from 'src/app/shared/utilities';

@Component({
  selector: 'app-opd-declare-details',
  templateUrl: './opd-declare-details.component.html',
  styleUrls: ['./opd-declare-details.component.css']
})
export class OpdDeclareDetailsComponent implements OnInit {
  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;

  @Input()
  set queryData(value) {
    if (value) {
      this.setDeclareMonth(value);
    }
  }

  sizePage = {
    width: 29.5, //cm
    height: 20.5 //cm
  };
  paddingPage = {
    top: -0.2, //cm
    right: 0, //cm
    bottom: 0.2, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom * 2)
  );

  data: OpdDeclareDetailsRptDTO;
  dataSource: OpdDeclareDetailsRptDTO[];
  upperboundIndex: number = 0;
  /** 整份報表的html內容 */
  elContainer;
  forExcel = true;

  nowDate = DateHelper.getROCDateStringWithSepetator(new Date());
  monthStr='';

  constructor(
    private declareApi: DeclareApi,
    private localPrintService: LocalPrintService,
    private hcrService:HcrService,
    private notification: EasyNotificationService) {

  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.pres-report table td, table th { border: 1px solid black; padding-left: 2px;padding-right: 2px; padding-top: 3px; padding-bottom: 3px;}\n' +
      '.pres-report table tr:first-child th { border-top: 0; }\n' +
      '.pres-report table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4 landscape; margin: 0cm 0cm; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  /** 取得健保申報明細摘要表 報表資料 */
  async setDeclareMonth(query: any) {
    //this.OpdSummaryData = await this.declareApi.GetOpdDeclareReport(UserCache.getLoginClinicId(), monthDate);
    setTimeout(async () => {
      this.elContainer = document.getElementById("container");
      this.elContainer.innerHTML = "";
      this.monthStr = DateHelper.getFormatedDateString(query.monthDate, true, '', true);

      var result = await this.declareApi.GetOpdDeclareDetailsRpt(UserCache.getLoginClinicId(), query.monthDate, query.amenNum);

      if (result?.length > 0) {
        this.dataSource = result;
        //this.data = decData[0];
        // 將查詢的資料組成報表html
        this.insertListData();
      }
    }, 100);

    // if (this.OpdSummaryData != null && this.OpdSummaryData.H10 != null) {

    // }

  }

  /** 組報表的html內容(this.Data) */
  insertListData() {
    var iPage = 0;
    var html_ListerHeader = this.createHTMLListHeader();  // List欄位顯示名稱
    var prevTableContent: any;

    /** -- 組報表內容 -- this.dataSource 依醫師分頁才會有多筆，否則只會有一筆 */
    for (let x = 0; x < this.dataSource.length; x++) {
      // 建立一個新的分頁
      // console.log("頁頭+++++", iPage);
      this.elContainer.innerHTML += this.createHTMLPage(iPage);
      var elPageContent = document.getElementById("page-" + iPage + "-content");    // 取得某頁的content
      this.data = this.dataSource[x];
      elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.data); // [頁表頭][List欄位顯示名稱]
      var elTableContent = document.getElementById("table-" + iPage + "-content");
      elTableContent.innerHTML += html_ListerHeader;

      for (let i = 0; i < this.dataSource[x].Detail.length; i++) {

        //prevTableContent = elTableContent.innerHTML;
        //elTableContent.innerHTML += this.createHTMLGrouping(this.dataSource[x].Detail[i].e11cs);

        // 迴圈逐筆加入報表table
        //for (let j = 0; j < this.dataSource[x].Detail[i].Detail.length; j++) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += this.createHTMLBlock(this.dataSource[x].Detail[i]);
        // 判斷是否超出頁面範圍? -> 新增一頁
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          // console.log("逐筆+++++", iPage);
          elTableContent.innerHTML = prevTableContent;
          iPage++;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.data);
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += this.createHTMLBlock(this.dataSource[x].Detail[i]);
        }
        //}


      }
      // 總計
      prevTableContent = elTableContent.innerHTML;
      elTableContent.innerHTML += this.createHTMLTotal(this.data);
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        // console.log("總計+++++", iPage);
        elTableContent.innerHTML = prevTableContent;
        iPage++;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.data);
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += this.createHTMLTotal(this.data);
      }
      iPage++;
    }
    this.upperboundIndex = iPage;
  }

  createHTMLListHeader() {
    return `<tr>
              <th style="width: 60px;">
                流水號
              </th>
              <th style="width: 72px;">
                起日期
              </th>
              <th style="width: 72px;">
                迄日期
              </th>
              <th style="width: 95px;">
                姓名
              </th>
              <th style="width: 109px;">
                身分證號
              </th>
              <th style="width: 48px;">
                卡序
              </th>
              <!-- <th style="max-width: 105px;">
                用量
              </th> -->
              <th>
                醫令
              </th>
              <th>
                傷病名稱
              </th>
              <th style="width: 45px;">
                日份
              </th>
              <th style="width: 95px;">
                診療費
              </th>
              <th style="width: 80px;">
                診察費
              </th>
              <th style="width: 105px;">
                總額
              </th>
              <th style="width: 80px;">
                部份負擔
              </th>
              <th style="width: 105px;">
                申請額
              </th>
              <th style="width: 62px;">
                醫師
              </th>
            </tr>`;
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format-text {
            mso-number-format: "@";
          }
        </style>`;
    }
    page += `
      <div class="page" id="page-` + iPage + `"
                style="
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
    return page;
  }

  createHTMLPageHeader(iPage, data) {
    return `<div>
              <div style="text-align: center;font-weight: bolder; font-size: 16pt; ">健保申報明細摘要表
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;">
              <div style="font-size: 13pt;width: 100%;">
                <div style="width: 200px; display: inline-block;">頁數：第` + (iPage + 1) + `頁</div>
                <div style="width: calc(100% - 415px); display: inline-block;">～` + data?.e11cs + " " + data?.e11csName + `～</div>
                <div style="text-align:right; width: 200px;display: inline-block;padding-right: 5px;">製表日期：` + this.nowDate + `</div>
              </div>
              <div style="display: flex;column-gap: 5px;">
                <table id="table-` + iPage + `-content" style="width: 100%; font-size: 13pt; text-align: center;">
                </table>
              </div>
            </div>`;
  }

  createHTMLBlock(data: any) {
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    return `<tr>
              <td>` + data.e11sq + `</td>
              <td>` + data.e11da + `</td>
              <td>` + data.e11ed + `</td>
              <td>` + data.e11nm + `</td>
              <td>` + data.e11id + `</td>
              <td ${commonClassText}>` + data.e1129 + `</td>
              <!-- <td>用量</td> -->
              <td style="text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 160px;max-width: 160px;">` + data.e12rx + `</td>
              <td style="text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;max-width: 200px;">` + data.DxName + `</td>
              <td>` + data.e11dy + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data.e11a6) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data.e11a1) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data.e11tt) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data.e1198) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data.e11ta) + `</td>
              <td>` + (isNullOrUndefined(data.Doctor) ? "" : data.Doctor) + `</td>
            </tr>`;
  }

  createHTMLTotal(data: any) {
    return `<tr>
              <td colspan="7"></td>
              <td colspan="2" style="text-align: right; padding-right: 15px;"> <span style="margin-right: 10px;">合計：</span>` + data?.Subtotal.DxName + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data?.Subtotal.e11a6) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data?.Subtotal.e11a1) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data?.Subtotal.e11tt) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data?.Subtotal.e1198) + `</td>
              <td style="text-align: right; padding-right: 5px;">` + ToThousandFixed(data?.Subtotal.e11ta) + `</td>
              <td></td>
            </tr>`;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
    // var html = this.reportContainer.nativeElement.innerHTML;
    // return html;
  }

  /** 列印 */
  async onPrint() {
    if (this.dataSource.length > 0) {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, '健保申報明細摘要表', printContents);
      if (ret.Successed) {
        this.notification.showSuccess('健保申報明細摘要表 已送出列印!', true);
      } else {
        this.notification.showError('健保申報明細摘要表 送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  /** 匯出excel */
  async exportAsExcel() {
    const reportName = '健保申報明細摘要表_' + this.monthStr;
    if (this.dataSource.length > 0) {
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)

      // const uri = 'data:application/vnd.ms-excel;base64,';
      // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
      //                       <head>
      //                           <!--[if gte mso 9]>
      //                           <xml>
      //                               <x:ExcelWorkbook>
      //                                   <x:ExcelWorksheets>
      //                                       <x:ExcelWorksheet>
      //                                           <x:Name>{worksheet}</x:Name>
      //                                           <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
      //                                       </x:ExcelWorksheet>
      //                                   </x:ExcelWorksheets>
      //                               </x:ExcelWorkbook>
      //                           </xml>
      //                           <![endif]-->
      //                       </head>
      //                       <body>
      //                           <table>{table}</table>
      //                       </body>
      //                       </html>`;
      // const base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) };
      // const format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) };
      // const table = this.reportContainer.nativeElement;
      // const ctx = { worksheet: reportName, table: table.innerHTML };

      // var fileName = reportName + `_` + DateHelper.getTimeString(new Date(), '') + `.xls`
      // var content = format(template, ctx);
      // var ret =await this.hcrService.SaveTextAsZipIfPwd(reportName+'\\'+fileName,content)
      // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);


      // const link = document.createElement('a');
      // link.download = reportName + `_` + DateHelper.getTimeString(new Date(), '') + `.xls`;
      // link.href = uri + base64(format(template, ctx));
      // link.click();
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

}
