import { Injectable } from "@angular/core";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";
import { CustomResult } from "src/app/shared/models/custom-result";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { Referral } from "./referral";

@Injectable({providedIn:'root'})
export class ReferralApi {
    apiService: WebApiService;

    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('referrals/referral')
        this.apiService.enableLoader = false;
    }

    /**
     * 以 regId 取得轉診資料
     * @param regId 
     * @returns 
     */
    public async Get(regId: number): Promise<Referral[]>{
        return await this.apiService.get('Get/' + regId).toPromise<Referral[]>();
    }

    /**
     *  新增或更新轉診資料
     * @param data 
     * @returns 
     */
    public async CreateOrUpdate(data: Referral): Promise<CustomResult> {
        return await this.apiService.post('CreateOrUpdate', data).toPromise<CustomResult>();
    }
}