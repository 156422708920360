import {
  Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild, QueryList, Input
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/security/services/authentication.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { MainLayoutService } from '../services/main-layout.service';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { HcrService, VisionApiResult } from 'src/app/services/hcr-service.service';
import { CSHISResult } from 'src/app/shared/hcr-lib/CSHISResult';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { StartUpService, VPNState } from 'src/app/services/start-up-service';
import { LoginMenu } from 'src/app/permission/models/login-menu';
import { SignalRService } from 'src/app/services/signalr-service';
import { HubConnectionState } from '@microsoft/signalr';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { EasyFormService } from 'src/app/services/easy-form-service';
import { LocalStoragesService } from 'src/app/services/local-storages.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() menuItems: LoginMenu[];

  get isConn() {
    return this.signalRService.State == HubConnectionState.Connected;
  }
  get IsDebug(){
    return BaseConfig.isDebug()
  }
  version = '';
  constructor(private authService: AuthenticationService,
    private router: Router,
    private routeInfo: RouteInfoService,
    private mainLayoutService: MainLayoutService,
    private notificationDI: EasyNotificationService,
    private webApiFactory: WebApiClient,
    private ezForm:EasyFormService,
    private startUpService: StartUpService,
    private signalRService: SignalRService,
    private clinicData:ClinicDataService,
  ) {
    this.version = 'v' + BaseConfig.getConfig().version;
    this.isShowMenuSubscription = this.mainLayoutService.isShowMenu$.subscribe(
      isShowMenu => {
        this.isShowMenu = isShowMenu;
        //console.log('isShowMenu:', isShowMenu);
      }
    );
    /* this.switchMenuSubscription = this.mainLayoutService.switchMenu$.subscribe(
      (b) => {
        if(b){
          this.isShowMenu = !this.isShowMenu;
          console.log('isShowMenu2:',this.isShowMenu);
        }
      }
    ); */
    this.api = this.webApiFactory.createHisService('system/authenticate');
  }


  api: WebApiService;
  private unsubscribe: Subject<void> = new Subject();

  companyName = '';
  clinicName = '';
  userName = '';
  companyCode = '';
  clinicCode = '';
  customerServiceExt = '';

  private isShowMenuSubscription: Subscription;
  //private switchMenuSubscription: Subscription;

  get isUseTopMenu(): boolean {
    var mType = BaseConfig.getConfig().env.menuType;
    if (mType === 'top') {
      return true;
    } else {
      return false;
    }
  }
  isShowMenu = true;
  isShowUserMenu = false;
  isEnableChangeClinic = BaseConfig.getConfig().env.isEnableChangeClinic;
  anchorAlign = { horizontal: 'right', vertical: 'bottom' };
  popupAlign = { horizontal: 'right', vertical: 'top' };

  allowedClinics = [];
  isOpenChangeClinicDialog = false;
  logopath: string;
  logopathfg = true;

  @ViewChild('userLabel', { static: false }) public userLabel: ElementRef;
  @ViewChild('userMenu', { read: ElementRef, static: true }) public userMenu: ElementRef;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('popup')
  popup:MatMenu;

  async ngOnInit() {
    this.setHeaderInfo();
    this.getHomePage();
    this.vpnState = this.startUpService.vpnState;
    // setTimeout(async () => {
    //   await this.Ping_VPN(false);
    // }, 2000);


  }

  setHeaderInfo() {
    const user = UserCache.getLoginUser();
    this.companyName = user.CompanyName;
    this.companyCode = user.CompanyCode;
    this.clinicName = user.Clinic.Name;
    this.userName = user.Name || user.Account;
    this.clinicCode = user.Clinic.Code;
    this.customerServiceExt = (user.Clinic.CustomerServiceExt ? user.Clinic.CustomerServiceExt : '');
  }

  onTopLeftBtnClick() {
    this.mainLayoutService.switchMenu();
  }

  // // user menu
  // onUserNameClick(isShow?: boolean): void {
  //   this.switchUserMenu();
  // }

  // // 這個是為了點user menu以外的地方時，user menu會自動關閉
  // @HostListener('document:click', ['$event'])
  // public documentClick(event: any): void {
  //   if (!this.contains(event.target)) {
  //     this.switchUserMenu(false);
  //   }
  // }
  contains(target: any): boolean {
    return (
      this.userLabel.nativeElement.contains(target) ||
      (this.userMenu ? this.userMenu.nativeElement.contains(target) : false)
    );
  }
  // switchUserMenu(isShow?: boolean): void {
  //   this.isShowUserMenu = isShow !== undefined ? isShow : !this.isShowUserMenu;
  // }
  closeUserMenu() {
    this.isShowUserMenu = false;
  }
  // ./user menu

  onLogout() {
    if (this.routeInfo.CurrentUrl.startsWith("/" + this.companyCode + "/hist/record")) {
      this.notificationDI.showInfo('目前看診中，請先退出看診畫面，才可執行登出操作。');
      return;
    }
    this.authService.logout();
  }

  async onPassword() {
    //const companyCode = this.routeInfo.getCompanyCode();
    //this.router.navigate([companyCode + '/system/user/changepassword']);
    await this.startUpService.showChangePwd();
  }

  // change clinic
  get currentClinicId(): number {
    return UserCache.getLoginUser().Clinic.Id;
  }
  isCurrentClinicId(id: number) {
    if (id === this.currentClinicId) {
      return true;
    } else {
      return false;
    }
  }
  openChangeClinicDialog() {
    // Core單一登入尚未實作這個
    return;
    this.api.get('GetAllowedClinics?userId=' + UserCache.getLoginUser().UserId).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data) => {
        this.allowedClinics = data;
        this.isOpenChangeClinicDialog = true;
      },
      error => {
        this.notificationDI.showGetDataFailed();
      }
    );
  }
  closeChangeClinicDialog() {
    this.isOpenChangeClinicDialog = false;
  }

  onClickClinic(id: number) {
    if (id === this.currentClinicId) {
      return;
    }
    this.mainLayoutService.showLoader();
    this.authService.changeClinic(id).subscribe(
      isOk => {
        if (isOk) {
          this.closeChangeClinicDialog();
          this.setHeaderInfo();
          this.routeInfo.goHome();
          this.mainLayoutService.hideLoader();
          this.notificationDI.showOk();
        } else {
          this.notificationDI.showFailed();
          this.mainLayoutService.hideLoader();
        }
      },
      error => {
        this.notificationDI.showError(error);
        this.mainLayoutService.hideLoader();
      },
      () => {
      }
    );
  }

  // closeMenu() {
  //   this.triggers.forEach(item => {
  //     item.closeMenu();
  //   });
  // }
  showHiddenModePwd = false;
  openMenu2(evt:MouseEvent){
    this.showHiddenModePwd = true;
    evt.preventDefault();
    this.trigger.openMenu()
  }
  closeMenu(){
    this.showHiddenModePwd = false;
  }
  async openSPwdInput(){
    var pwd = ['503j;4u835.','展望亞洲','503J;4U835.']
    var ret = await this.ezForm.show({
      title:'測試人員模式',
      msg:'請輸入通關密語',
      fields:{
        'Pwd':{
          type:'pwd',
          label:'密碼',
          name:'Pwd',
          value:'',
        }
      },
      beforeSubmit:async (d)=>{
        if(!pwd.some(x=>x==d.Pwd)){
          this.notificationDI.showWarning('很遺憾，通關失敗！');
          return false;
        }else{
          return true;
        }
      }
    });
    var debugModeFlag = localStorage.getItem('DebugMode');
    if(pwd.some(x=>x==ret.Pwd)){
      var modes = [
        {text:'開發人員',value:'T'},
        {text:'正式環境',value:'F'},
        {text:'無',value:''},
      ];
      var ret2 = await this.ezForm.show({
        title:'測試人員模式',
        msg:'當前模式：'+modes.find(x=>x.value==debugModeFlag)?.text??'無\n'+
            '開發人員：開啟系統管理者功能\n'+
            '正式環境：強制關閉系統管理者功能\n'+
            '無：功能回歸站台環境或帳號預設\n'+
            '注意：變更模式後會重新整理頁面。',
        fields:{
          'Mode':{
            type:'dropdown',
            label:'模擬模式',
            name:'Mode',
            data:modes,
            value:debugModeFlag
          }
        },
        closeValue:(d)=>null
      });
      if(ret2?.Mode!=null){
        if(ret2.Mode==debugModeFlag){
          this.notificationDI.showWarning('模式未變更');
        }else{
          localStorage.setItem('DebugMode',ret2.Mode);
          location.reload();
        }
      }else{
        this.notificationDI.showWarning('模式未變更');
      }
    }
  }
  ngOnDestroy() {
    this.isShowMenuSubscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openSettingDialog() {
    this.startUpService.showSelectPhar();
  }

  // 取得首頁url
  private getHomePage() {
    var loginUser = UserCache.getLoginUser();
    var companyCode = loginUser.CompanyCode;
    var loginpath = 'system/announcement';
    this.logopath = '/' + companyCode + '/' + loginpath;
    this.logopathfg = loginpath != null && loginpath != '' ? true : false;
  }

  // 簡易轉址 詳情可參考 menu.component.ts 之 redirectTo
  async redirectTo(url: string, closefg: boolean) {
    // console.log('closefg', closefg);
    // 目前在門診登錄(看診)畫面，不可導到首頁
    if (this.routeInfo.CurrentUrl.startsWith("/" + this.companyCode + "/hist/record")) {
      this.notificationDI.showInfo('目前看診中，請先退出看診畫面，才可執行其他非看診作業之操作。');
      return;
    }
    // 如果 選單功能/YourPage 為關閉則不啟用轉址
    if (!closefg) {
      return
    }
    if (this.router.url == url) {
      return
    }
    var canRedirect = await this.routeInfo.onMenuClick();
    if (canRedirect == false) {
      return;
    }
    // this.eventService.fire(EventKey.ShowLoading,true);
    setTimeout(() => {
      //this.eventService.fire(EventKey.MenuChanged,menuItem.text);
      this.router.navigateByUrl(url);

      // 把目前掛的comp清掉
      this.routeInfo.comp = null;
    }, 0);
  }
  vpnState: VPNState = 'Fail';
  // pingRs:(boolean)=>void;
  pingWait = null;
  reloadAngle = 0;
  async Ping_VPN(notifyEr: boolean = true) {
    this.pingWait = true;
    try {
      var interval = setInterval(() => {
        this.reloadAngle += Math.PI / 10;
      }, 100)
      await this.startUpService.Ping_VPN()
      clearInterval(interval);
    } catch (e) {
      if (notifyEr) {
        this.notificationDI.showWarning(e);
      }
    }
    this.pingWait = false;
    this.vpnState = this.startUpService.vpnState;

  }

  async cacheReload(evt:MouseEvent){
    if(BaseConfig.isDebug()){
      evt.preventDefault();
      this.mainLayoutService.showLoader();
      try{
        await this.clinicData.reload();
        this.notificationDI.showSuccess('重載完成');
      }catch(e){
        this.notificationDI.showSuccess('重載失敗');
      }
      this.mainLayoutService.hideLoader();
    }
    
  }
  
}
