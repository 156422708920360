import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FontGreatService } from 'src/app/services/font-great.service';

@Component({
  selector: 'app-icon-font',
  template: `<svg class="{{cssName}} small" width="16px" height="18px" viewBox="-3 0 26 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
      <path d="M22.176,3.864 L13.128,3.864 C12.816,2.88 12.48,1.992 12.144,1.2 L10.176,1.536 C10.56,2.232 10.92,3 11.256,3.864 L1.824,3.864 L1.824,9.216 L3.552,9.216 L3.552,5.52 L20.448,5.52 L20.448,9.216 L22.176,9.216 L22.176,3.864 Z M5.52,8.112 L5.52,9.72 L15.816,9.72 C14.832,10.344 13.368,11.184 11.424,12.192 L11.424,14.544 L1.392,14.544 L1.392,16.224 L11.424,16.224 L11.424,20.712 C11.424,21.408 11.112,21.768 10.488,21.768 C9.48,21.768 8.568,21.744 7.728,21.72 L8.136,23.304 L11.088,23.304 C12.48,23.304 13.176,22.632 13.176,21.312 L13.176,16.224 L22.632,16.224 L22.632,14.544 L13.176,14.544 L13.176,12.936 C15.096,11.88 16.848,10.824 18.408,9.72 L18.408,8.112 L5.52,8.112 Z" id="Shape" fill="#464746" fill-rule="nonzero"></path>
      <path d="M22.176,3.864 L13.128,3.864 C12.816,2.88 12.48,1.992 12.144,1.2 L10.176,1.536 C10.56,2.232 10.92,3 11.256,3.864 L1.824,3.864 L1.824,9.216 L3.552,9.216 L3.552,5.52 L20.448,5.52 L20.448,9.216 L22.176,9.216 L22.176,3.864 Z M5.52,8.112 L5.52,9.72 L15.816,9.72 C14.832,10.344 13.368,11.184 11.424,12.192 L11.424,14.544 L1.392,14.544 L1.392,16.224 L11.424,16.224 L11.424,20.712 C11.424,21.408 11.112,21.768 10.488,21.768 C9.48,21.768 8.568,21.744 7.728,21.72 L8.136,23.304 L11.088,23.304 C12.48,23.304 13.176,22.632 13.176,21.312 L13.176,16.224 L22.632,16.224 L22.632,14.544 L13.176,14.544 L13.176,12.936 C15.096,11.88 16.848,10.824 18.408,9.72 L18.408,8.112 L5.52,8.112 Z" id="Shape" fill="#464746" fill-rule="nonzero"></path>
  </g>
</svg>
<svg class="{{cssName}} large" width="18px" height="20px" viewBox="0 2 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
      <path d="M22.176,3.864 L13.128,3.864 C12.816,2.88 12.48,1.992 12.144,1.2 L10.176,1.536 C10.56,2.232 10.92,3 11.256,3.864 L1.824,3.864 L1.824,9.216 L3.552,9.216 L3.552,5.52 L20.448,5.52 L20.448,9.216 L22.176,9.216 L22.176,3.864 Z M5.52,8.112 L5.52,9.72 L15.816,9.72 C14.832,10.344 13.368,11.184 11.424,12.192 L11.424,14.544 L1.392,14.544 L1.392,16.224 L11.424,16.224 L11.424,20.712 C11.424,21.408 11.112,21.768 10.488,21.768 C9.48,21.768 8.568,21.744 7.728,21.72 L8.136,23.304 L11.088,23.304 C12.48,23.304 13.176,22.632 13.176,21.312 L13.176,16.224 L22.632,16.224 L22.632,14.544 L13.176,14.544 L13.176,12.936 C15.096,11.88 16.848,10.824 18.408,9.72 L18.408,8.112 L5.52,8.112 Z" id="Shape" fill="#464746" fill-rule="nonzero"></path>
      <path d="M22.176,3.864 L13.128,3.864 C12.816,2.88 12.48,1.992 12.144,1.2 L10.176,1.536 C10.56,2.232 10.92,3 11.256,3.864 L1.824,3.864 L1.824,9.216 L3.552,9.216 L3.552,5.52 L20.448,5.52 L20.448,9.216 L22.176,9.216 L22.176,3.864 Z M5.52,8.112 L5.52,9.72 L15.816,9.72 C14.832,10.344 13.368,11.184 11.424,12.192 L11.424,14.544 L1.392,14.544 L1.392,16.224 L11.424,16.224 L11.424,20.712 C11.424,21.408 11.112,21.768 10.488,21.768 C9.48,21.768 8.568,21.744 7.728,21.72 L8.136,23.304 L11.088,23.304 C12.48,23.304 13.176,22.632 13.176,21.312 L13.176,16.224 L22.632,16.224 L22.632,14.544 L13.176,14.544 L13.176,12.936 C15.096,11.88 16.848,10.824 18.408,9.72 L18.408,8.112 L5.52,8.112 Z" id="Shape" fill="#464746" fill-rule="nonzero"></path>
  </g>
</svg>`,
  styles: [`
    
  `]
})
export class IconFontComponent implements OnInit {

  @Input() cssName:string ='';

  constructor(private fontGreatService:FontGreatService) { }

  ngOnInit(): void {
  }

}
