import { Component, OnInit } from '@angular/core';
import { BaseConfig } from 'src/app/services/base-config.service';
import { Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-no-company',
  templateUrl: './no-company.component.html',
  styleUrls: ['./no-company.component.css']
})
export class NoCompanyComponent implements OnInit {

  constructor(private router: Router) { }

  config = BaseConfig.getConfig();

  ngOnInit(): void {
    if (this.config.env.defaultCompany) {
      this.router.navigate([this.config.env.defaultCompany]);
    }
  }

}
