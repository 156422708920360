import { EventEmitter, Injectable } from '@angular/core';
import { StartUpService } from './start-up-service';
import { UserApi } from './api-service/user/user-api';
import { WebApiClient } from './web-api-client.service';
import { WebApiService } from './web-api.service';

@Injectable({
  providedIn: 'root'
})
export class DebigNotifyService {
  apiService:WebApiService;
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createHisService('system/ApiLog');
  }

  Notify(...o:any[]){
    this.apiService.enableLoader = false;
    var m = '';
    o.forEach(x=>{
      var t = JSON.stringify(x); 
      if(x instanceof Error){
        t = x.message+'\n\n'+x.stack
      }
      m+= t+'\n\n';
    })
    
    this.apiService.post('DebugNotify',{
      text: m
    }).subscribe();
  }
  
}

