<div class="row narrow-padding overflow-x-auto font-hidden-before before-none overflow-y-hidden">
  <div class="w-100 mt-1">
      <div class="card m-0">
          <div class="card-header" style="height: 46px;">
              <div style="display: flex; align-items: center;" class="lh-sm">
                  <div><span> </span></div>
                  <div style="flex-grow: 1;"></div>
                  <!-- <button type="button" i18n="@@QueryPatient" (click)="onBtnQuery()"
                      class="vis-btn-config p-0 position-relative pl-2 min-w-24" title="查詢資料">
                      <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢資料</span>
                  </button> -->
                  <button type="button" i18n="@@Preview" (click)="onBtnQuery()"
                      class="vis-btn-config p-0 position-relative pl-2 w-24" title="預覽查詢">
                      <mat-icon class="pref-icon print vis-combine-icon">print</mat-icon>
                      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">預覽查詢</span>
                  </button>
                  <!-- <button type="button" i18n="@@Excel" (click)="onBtnExportAsExcel()"
                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="匯出Excel">
                  <mat-icon class="pref-icon vis-combine-icon">file_open</mat-icon>
                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯出</span>
                  </button> -->
              </div>
          </div>

          <div class="card-body p-0">
              <div class="col-sm-12 row personalList" style="padding: 0 80px; padding-top: 20px; justify-content: center;">
                  <form [formGroup]="queryFormGroup" class="vis-form extend-msg vis-form-narrow p-0">
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@reportType">報表格式：　</span>
                        <mat-radio-group formControlName="selectReportType" (change)="onGroupChange($event)"
                          style="top: 3px;line-height: 0px;" class=" vis-form-field position-relative">
                          <mat-radio-button [value]="'1'" style="margin-right: 30px;">原紙張大小</mat-radio-button>
                          <mat-radio-button [value]="'2'">濃縮病歷表</mat-radio-button>
                        </mat-radio-group>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@StartDate">起始日期：　</span>
                        <app-vis-datepicker formControlName="startDate" style="vertical-align: middle;" class="d-inline-flex position-relative -top-2"></app-vis-datepicker>
                      </label>
                      <label>
                        <app-vis-timepicker formControlName="startTime" class="odr-cell" autocomplete="off" appNumberOnly></app-vis-timepicker>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@EndDate">結束日期：　</span>
                        <app-vis-datepicker formControlName="endDate" style="vertical-align: middle;" class="d-inline-flex position-relative -top-2"></app-vis-datepicker>
                      </label>
                      <label>
                        <app-vis-timepicker formControlName="endTime" class="odr-cell" autocomplete="off" appNumberOnly></app-vis-timepicker>
                      </label>
                    </div>

                    <div class="col-sm-12">
                      <label>
                        <span style="margin-right: 56px;">以下欄位多重輸入, 以逗號區隔(如: 1,2,3...), 姓名可模糊搜尋。</span>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeSpecialMark">
                          不印特記
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@PatientNo">　病歷號：　</span>
                        <input class="vis-input" formControlName="patientNo" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeLackCard">
                          不印欠卡
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@CId">身分證號：　</span>
                        <input class="vis-input" formControlName="cId" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="includeSelfPayIdentity">
                          包含自費身分
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@CName">　姓　名：　</span>
                        <input class="vis-input" formControlName="cName" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="onlyHealthInsurance" (change)="onOnlyHealthInsuranceCheckChanged($event)" style="margin-right: 20px;">
                          只印健保藥
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@Phones">　電　話：　</span>
                        <input class="vis-input" formControlName="phones" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeSelfPay" style="margin-right: 20px;">
                          排除(*自費)
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeNoCharge" style="margin-right: 20px;">
                          排除(-不計價)
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeDeclareNoCharge">
                          排除(+申報不計價)
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@Kcstmr">　自訂碼：　</span>
                        <input class="vis-input" formControlName="kcstmr" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="excludeSameTreat26" style="margin-right: 20px;">
                          不印同療2~6次
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox *ngIf="isPharmacy" [checked]="false" formControlName="includeImport" style="margin-right: 20px;" [disabled]="!isPharmacy">
                          列印藥局外來單
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="case09">
                          09案類
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@Birthday">　生　日：　</span>
                        <app-vis-datepicker formControlName="birthday" style="vertical-align: middle; margin-right: 200px;" class="d-inline-flex position-relative"></app-vis-datepicker>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="diffPerson" (change)="onDiffPersonCheckChanged($event)" style="margin-right: 20px;">
                          左右聯不同人
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="addonBodyInfo">
                          加印體重,體溫,血壓
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@Dept">　科　別：　</span>
                        <input class="vis-input" formControlName="dept" style="width: 250px; margin-right: 100px;" />
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="hideStaffCId" style="margin-right: 20px;">
                          隱藏醫師,藥師身分證
                        </mat-checkbox>
                      </label>
                      <label>
                        <mat-checkbox [checked]="false" formControlName="orderByDate">
                          依日期排序
                        </mat-checkbox>
                      </label>
                    </div>
                    <div class="col-sm-12">
                      <label>
                        <span i18n="@@nothing">　</span>
                      </label>
                    </div>
                  </form>
              </div>
          </div>
      </div>
  </div>
</div>

<!-- 濃縮病歷表預覽列印畫面 -->
<app-vis-dialog [title]="'濃縮病歷表預覽列印(' + totalRecord + '筆)'" [maxWidth]="'90vw'" [superWidth]="'90%'" [show]="isCompressPreviewOpened" (close)="onCompressPreviewClose()">
  <app-report-hist-print-compress (printed)="onCompressPrinted($event)"></app-report-hist-print-compress>
  <app-vis-dialog-actions>
      <button *ngIf="totalRecord>0" type="button" (click)="onCompressPrint()" primary="true" i18n="@@Pprint">列印</button>
      <button *ngIf="totalRecord>0" type="button" (click)="onCompressExport()" primary="true" i18n="@@Pprint">匯出Excel</button>
      <button type="button" (click)="onCompressPreviewClose()" i18n="@@DialogClose">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 原紙張病歷表預覽列印畫面 -->
<app-vis-dialog [title]="'原紙張病歷表預覽列印(' + totalRecord + '筆)'" [maxWidth]="'90vw'" [superWidth]="'90%'" [show]="isOriginalPreviewOpened" (close)="onOriginalPreviewClose()">
  <app-report-hist-print-original (printed)="onOriginalPrinted($event)"></app-report-hist-print-original>
  <app-vis-dialog-actions>
      <button *ngIf="totalRecord>0" type="button" (click)="onOriginalPrint()" primary="true" i18n="@@Pprint">列印</button>
      <button *ngIf="totalRecord>0" type="button" (click)="onOriginalExport()" primary="true" i18n="@@Pprint">匯出Excel</button>
      <button type="button" (click)="onOriginalPreviewClose()" i18n="@@DialogClose">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 原紙張病歷表(左右聯不同人)預覽列印畫面 -->
<app-vis-dialog [title]="'原紙張病歷表(左右聯不同人)預覽列印(' + totalRecord + '筆)'" [maxWidth]="'90vw'" [superWidth]="'90%'" [show]="isDiffPersonPreviewOpened" (close)="onDiffPersonPreviewClose()">
  <app-report-hist-print-diffperson (printed)="onDiffPersonPrinted($event)"></app-report-hist-print-diffperson>
  <app-vis-dialog-actions>
      <button *ngIf="totalRecord>0" type="button" (click)="onDiffPersonPrint()" primary="true" i18n="@@Pprint">列印</button>
      <button *ngIf="totalRecord>0" type="button" (click)="onDiffPersonExport()" primary="true" i18n="@@Pprint">匯出Excel</button>
      <button type="button" (click)="onDiffPersonPreviewClose()" i18n="@@DialogClose">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
