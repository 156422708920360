import { Injectable } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { PCSxEditDto, PCSxKeywordQuery, PCSxListDto, PCSxQuery } from './pcsx-api.model';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { CustomResult } from 'src/app/shared/models/custom-result';

@Injectable({
  providedIn: 'root'
})
export class PCSxApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('opd/PCSx');
    this.apiService.enableLoader = false;
  }

  async GetPCSxPageList(param: PCSxQuery): Promise<PageData2<PCSxListDto>> {
    return this.apiService.get('GetPCSxPageList', param).toPromise<PageData2<PCSxListDto>>();
  }

  async CreatePCSxByStandard(spcsxIds: number[]): Promise<CustomResult> {
    return this.apiService.post('CreatePCSxByStandard', spcsxIds).toPromise<CustomResult>();
  }

  async Update(param: PCSxEditDto): Promise<CustomResult> {
    return this.apiService.post('Update', param).toPromise<CustomResult>();
  }

  async Delete(ids: number[]): Promise<CustomResult> {
    return this.apiService.post('Delete', ids).toPromise<CustomResult>();
  }
}
