<style>
    @media print {
      body {
        padding-top: 3cm;
        padding-bottom: 3cm;
        margin: 0;
        color: #000;
        background-color: #fff;
        font-size: 10pt;
      }
  
      .pres-report2 table td,
      table th {
        border-bottom: 5px double black;
        padding-left: 0px;
        padding-right: 0px;
      }
      .container {
        height: 100%;
        width: 100%;
      }
      .page {
        display: block;
        margin: 40px auto;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
      }
      .page .content {
        overflow: auto;
        outline: 0;
      }
      .page .content .block {
        border: 1px solid rgba(0, 0, 0, 0);
        padding: 0.25rem;
        cursor: default;
      }
      .page .content .block:hover {
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
      }
      .page .content .block .title {
        font-weight: bold;
      }
      .page .content .block .title {
        cursor: text;
      }
    }
  </style>
  <div class="a4-print-style-h" *ngIf="!isEnglish" #reportContainer style="margin: 0px auto; overflow-y: auto;">
    <div class="pres-report2" style="background-color: white; height: 100%; width: 100%;">
      <div class="container" id="container" #reportUseContainer>

      </div>

    </div>
  </div>

  <div #enReportContainer style="height: 550px; margin: 0px auto; overflow-y: auto;" *ngIf="isEnglish">
    <div style="background-color: white; height: 100%; width: 100%; font-family: '微軟正黑體';">
        <div id="container">
            <div style="margin: 10px auto;
                        height: 28.7cm;
                        width: 20.0cm;
                        padding-top: 0.5cm;
                        padding-right: 1.0cm;
                        padding-bottom: 0.5cm;
                        padding-left: 1.0cm;
                        display: block;
                        box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.3);
                        box-sizing: border-box;">
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 26px; text-align: center; font-weight: bold;">
                    <strong>{{'Period Receipt'}}</strong>
                </div>
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Record No.（病歷號）:'+ enInfo?.PatientNo}}</strong>
                </div>                
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Name（姓名）:'+enInfo?.Name}}</strong>
                </div>   
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Age（年齡）:'+enInfo?.Age+' years（歲）'}}</strong>
                </div>  
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Period（就醫期間）:'+enInfo?.StartDate +'~'+ enInfo?.EndDate}}</strong>
                </div> 
                <div style="height: 20px;">
                    <strong></strong>
                </div>  
                <table style="width: 650px; border-spacing: 0px;">
                    <tr>
                        <td style="width: 40%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 15px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>Item（項目）</strong>
                        </td>
                        <td style="width: 40%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 15px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>Charge（金額）(NT$)</strong>
                        </td>
                        <td rowspan="18" style="width: 2.5%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 15px; vertical-align: middle; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>　</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>1. Rehabilitation Therapy（復健治療費）</strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>{{ ( enInfo?.FeeTx8 || '' ) }}</strong>
                        </td>
                    </tr>
                    <tr>
                      <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                          <strong>2. Diagnosis（診察費）</strong>
                      </td>
                      <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                          <strong>{{ (enInfo?.FeeDx   || '' ) }}</strong>
                      </td>
                  </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>3. Prescription（處方費）</strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>{{ ( enInfo?.Prescription || '' ) }}</strong>
                        </td>
                    </tr>
                    <tr>
                      <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                          <strong>4. Medicine（藥費）</strong>
                      </td>
                      <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                          <strong>{{ ( enInfo?.FeeDrug || '' ) }}</strong>
                      </td>
                  </tr>
                  <tr>
                    <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                        <strong>5. Treatment（處置費）
                        </strong>
                    </td>
                    <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                        <strong>{{ ( enInfo?.FeeTx9 || '' ) }}</strong>
                    </td>
                </tr>
                <tr>
                  <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                      <strong>6. Radiological Diagnosis（放射診斷費）
                      </strong>
                  </td>
                  <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                      <strong>{{ ( enInfo?.FeeTx7 || '' ) }}</strong>
                  </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>7. Material（衛材費）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeMAT || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>8. Laboratory Exam.（檢驗費）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeMAT || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                  <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                      <strong>9. Certificate （證書費）
                      </strong>
                  </td>
                  <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                      <strong>{{ ( enInfo?.Certificate || '' ) }}</strong>
                  </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>10. Physical Exam（體檢）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.PhysicalExam || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>11. Miscellaneous（其它雜費）</strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeMiscellaneous || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>NHI payment（健保支付）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeTot || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>Registration（掛號費）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeRegister || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>Other self payment（自費額）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeOwn || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>Copayment（部分負擔）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeePartExam+ enInfo?.FeePartRx + enInfo?.FeeRegister || '' ) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>Total（總計）
                    </strong>
                </td>
                <td style="padding-left: 5px; padding-right: 5px; font-size: 15px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                    <strong>{{ ( enInfo?.FeeSubTotal || '' ) }}</strong>
                </td>
              </tr>
                </table>
                <div style="height: 100px;">
                </div> 
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Superintendent（院長）:'+ enInfo?.Superintendent}}</strong>
                </div>   
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Issued Date（掣據日期）:'+enInfo?.IssuedDate}}</strong>
                </div>  
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>{{'Receipt No.（收據編號）:'+''}}</strong>
                </div> 
                <div style="height: 40px;">
                </div> 
                <div style=" padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left;">
                    <strong>This receipt is invalid without seal of authority. （未蓋本院印章者無效）</strong>
                </div> 
            </div>
        </div>
    </div>
</div>

  <div *ngIf="!isEnglish" id="containerlast" [hidden]="true">
    <br />
    <table style="width: 680px; border: 1px solid black;">
        <tr border="0">
            <td  style="height: 20px; padding-left: 2.5px; padding-right: 2.5px; font-family: '細明體'; font-size: 22.5px; text-align: center; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                <strong>{{personal?.Title}}　</strong>
            </td>
        </tr>
        <tr border="0">
            <td  style="height: 17.5px; padding-left: 2.5px; padding-right: 2.5px; font-family: '細明體'; font-size: 17.5px; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                <strong>{{' 茲證明 ' + personal?.Name +' ' +personal?.PersonTitle +'身分證號' +personal?.Cid+','+' 在本院所'}}</strong>
            </td>
        </tr>

        <tr border="0">
            <td style="height: 17.5px; padding-left: 2.5px; padding-right: 2.5px; font-family: '細明體'; font-size: 17.5px; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                <strong>{{'病歷號為' + personal?.PatientNo +', '+ '於民國' +personal?.StartDate+'至民國'+personal?.EndDate+','+' 診療 '+personal?.Count+'次'}}</strong>
            </td>
        </tr>
        <tr border="0">
            <td  style="height: 17.5px; padding-left: 2.5px; padding-right: 2.5px; font-family: '細明體'; font-size: 17.5px; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                <strong>{{'在本院所就診費用為新臺幣' + personal?.FeeTotal+'元整' }}</strong>
            </td>
        </tr>
        <tr border="0">

            <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              <strong style="padding-left: 80px;">{{'此　　　　證明'}}</strong>
            </td>
        </tr>
        <tr border="0">

            <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              <strong style="padding-left: 150px;">{{'台　　　　照'}}</strong>
            </td>
        </tr>
      <tr border="0">
        <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
          <strong  style="padding-left: 240px;">{{'院所名稱:'+personal?.ClinicName}}</strong>
        </td>
      </tr>
      <tr border="0">

        <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
          <strong  style="padding-left: 240px;">{{'負責醫師:'+personal?.DoctorName}}</strong>
        </td>
      </tr>
      <tr border="0">

        <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
          <strong  style="padding-left: 240px;">{{'院所地址:'+personal?.ClinicAddress}}</strong>
        </td>
      </tr>
      <tr border="0">

        <td  style="padding-left: 2.5px; padding-right: 2.5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
          <strong style="padding-left: 240px;">{{'　中華民國'+personal?.PrintDate}}</strong>
        </td>
      </tr>
    </table>
  </div>