import { Injectable, Injector } from '@angular/core';
import { UserPermissions } from "src/app/enums/UserPermssionEnum";

/**
 * @description
 * BaseComponent 提供一個抽象基類，主要用途為放一些公開的定義，比如說Enum的公開參數
 *
 * @remarks
 *
 * @example
 * 如何繼承 BaseComponent：
 * ```typescript
 * @Component({
 *   selector: 'app-user-profile',
 *   templateUrl: './user-profile.component.html',
 *   styleUrls: ['./user-profile.component.css']
 * })
 * export class UserProfileComponent extends BaseComponent {
 *   constructor() {
 *     super();
 *   }
 * }
 * ```
 * 此範例展示了如何在子組件中利用BaseComponent提供的屬性和枚舉來管理用戶的角色和權限。
 */

@Injectable()
export abstract class BaseComponent {
  // 從枚舉中導入用戶權限，以便於在組件中直接使用
  UserPermissions = UserPermissions;

  constructor() { }
}
