<div class="card" style="height: 100%;">
  <div class="card-header card-header-light-blue">
    <ng-container i18n="@@LabForm"><h5 class="vis-title d-inline-block text-base font-weight-bold"><mat-icon class="vis-combine-icon">
      <span class="material-icons">science</span>
    </mat-icon>檢驗單</h5></ng-container>
  </div>
  <!--./card-header 不熟Boostrap 這邊height要指定一些px 不然會壓到dialog的底部按鈕-->
  <div class="card-body controlled-font-size-middle" style="overflow: auto;height: 1px;" *ngIf="initInsp">
      <div class="row">
        <div class="col-sm-2">
          <label class="vis-form-field">
            <span i18n="@@LabFormNo">檢驗單號</span>
            <div class="k-textbox read-only">{{initInsp.LabNo}}</div>
          </label>
        </div>
        <div class="col-sm-2">
          <label class="vis-form-field">
            <span i18n="@@PatientNo">病歷號</span>
            <div >{{initInsp.PatientNo}}</div>
          </label>
        </div>
        <div class="col-sm-2">
          <label class="vis-form-field">
            <span i18n="@@PatientName">姓名</span>
            <div >{{initInsp.PatientName}}</div>
          </label>
        </div>
        <div class="col-sm-3">
          <label class="vis-form-field">
            <span i18n="@@LabCreateDate">開立日期</span>
            <div >{{initInsp.CreateDate | formatDate}}</div>
          </label>
        </div>
        <!-- <div class="col-sm-4" [hidden]="true">
          <label class="vis-form-field">
            <span i18n="@@LabBookingDate">預約日期</span>
            <app-vis-datepicker formControlName="BookingDate" >
            </app-vis-datepicker>
          </label>
        </div> -->
        <!-- <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@LabCollectDate">採檢日期</span>
            <app-vis-datepicker formControlName="CollectDate" >
            </app-vis-datepicker>
          </label>
        </div> -->
        <!-- <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@LabDeliverDate">外送日期</span>
            <app-vis-datepicker formControlName="DeliverDate" >
            </app-vis-datepicker>
          </label>
        </div> -->
        <!-- <div class="col-sm-3">
          <label class="vis-form-field">
            <span i18n="@@LabReportDate">報告日期</span>
            <app-vis-datepicker formControlName="ReportDate" >
            </app-vis-datepicker>
          </label>
        </div> -->
        <div class="col-sm-3">
          <label class="vis-form-field">
            <span i18n="@@Status">狀態</span>
            <div>{{initInsp.Status|valueToText:editOptions.labStatusEnum}}</div>
          </label>
        </div>
        <!-- <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@LabTestUnit">外送檢驗單位</span>
            <input class="vis-input" formControlName="DeliverCompanyName" />
            <app-validation-tip controlName="DeliverCompanyName"></app-validation-tip>
          </label>
        </div> -->
        <!-- <div class="col-sm-4" [hidden]="true">
          <label class="vis-form-field">
            <span i18n="@@LabTestDate">檢驗日期</span>
            <app-vis-datepicker formControlName="TestDate" >
            </app-vis-datepicker>
          </label>
        </div> -->
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@Remark">備註</span>
            <input readonly disabled class="vis-input" [value]="initInsp?.Remark||''"/>
          </label>
        </div>
        <!-- <div class="col-sm-4">
          <label class="vis-form-field">
            <span i18n="@@RoomName">診間</span>
            <div class="k-textbox read-only">{{editFormValue.RoomName}}</div>
          </label>
        </div> -->
        <!-- <div class="col-sm-3">
          <label class="vis-form-field">
            <span i18n="@@LabClass">Class</span>
            <input class="vis-input" formControlName="LabClass" />
            <app-validation-tip controlName="LabClass"></app-validation-tip>
          </label>
        </div> -->
      </div>
      <!--./row-->
      <div class="row" style="overflow: scroll;">
        <!--[showStickyTableRows]="5" [showStickyHead]="true" -->
        <app-vis-grid  *ngIf="initInsp.Results?.length>0" [data]="initInsp?.Results">
          <app-vis-grid-column [title]="'檢驗項目'" [field]="'Id'" [width]="200">
            <ng-template let-data>
              <div style="width:inherit"> {{data.LabItem.ShortName}} </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column [title]="'檢驗值'" [field]="'TestValue'" [width]="80"></app-vis-grid-column>
          <app-vis-grid-column [title]="'檢驗結果'" style="text-align: center;" [field]="'TestResult'" [width]="80">
            <ng-template let-data>
              <div [ngStyle]="{color:GetTestValueColor(data.TestResult)}">{{data.TestResult}}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column [title]="'備註'" [field]="'Remark'" [width]="100"></app-vis-grid-column>
          <app-vis-grid-column [title]="'參考值'" [field]="'TestRules'" [width]="100"></app-vis-grid-column>
          <!-- <app-vis-grid-column [title]="'內容'" [field]="'Content'" [width]="100"></app-vis-grid-column> -->
          <app-vis-grid-row >
            <ng-template let-data>
              <div style="width: 100%;padding: 5px;display: flex;">
                內容：
                <textarea style="flex-grow: 1;resize: none;"  [value]="data?.Content||''" disabled readonly></textarea>
              </div>
            </ng-template>
          </app-vis-grid-row>
        </app-vis-grid>
        <!-- <div style="margin:4px;width:100%;overflow-x:auto;">
          <table class="c-table">
            <thead>
              <tr>
                <th i18n="@@LabItem" class="mat-header-cell">
                  檢驗項目
                </th>
                <th i18n="@@LabValue" class="mat-header-cell">
                  檢驗值
                </th>
                <th i18n="@@Result" class="mat-header-cell">
                  檢驗結果
                </th>
                <th i18n="@@Remark" class="mat-header-cell">
                  備註
                </th>
                <th i18n="@@Reference" class="mat-header-cell">
                  參考值
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let result of arrayResults.controls; let idx = index" [formGroupName]="idx">
                <tr>
                  <td>
                    <div style="width:100px" class="extend-content" [title]="arrayResults.controls[idx].controls.ShortName.value"> {{arrayResults.controls[idx].controls.ShortName.value}} </div>
                  </td>
                  <td *ngIf="result.controls.DataType.value === LabDataTypeNum || result.controls.DataType.value === LabDataTypeIndex">
                    <div style="width:100px;" class="extend-content ellipsis-content" [title]="arrayResults.controls[idx].controls.TestValue.value"
                      [style.color]="GetTestValueColor(arrayResults.controls[idx].controls.TestResult.value)">
                      {{arrayResults.controls[idx].controls.TestValue.value}} </div>
                  </td>
                  <td *ngIf="result.controls.DataType.value === LabDataTypeShortText || result.controls.DataType.value === LabDataTypeLongText">
                    <div class="extend-content" [title]=""></div>
                  </td>
                  <td>
                    <div class="extend-content ellipsis-content"><app-vis-dropdownlist formControlName="TestResult" [data]="editOptions.testResultCodes"
                      [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" style="width:100px;" [title]="">
                    </app-vis-dropdownlist></div>
                  </td>
                  <td *ngIf="result.controls.DataType.value === LabDataTypeNum || result.controls.DataType.value === LabDataTypeIndex">
                    <div class="extend-content"><input class="vis-input" formControlName="Remark" style="width:100px" /></div>
                  </td>
                  <td *ngIf="result.controls.DataType.value === LabDataTypeShortText || result.controls.DataType.value === LabDataTypeLongText">
                    <div class="extend-content"></div>
                  </td>
                  <td>
                    <div style="width:200px" class="extend-content ellipsis-content" [title]="arrayResults.controls[idx].controls.TestRules.value"> {{arrayResults.controls[idx].controls.TestRules.value}} </div>
                  </td>
                </tr>
                <tr *ngIf="result.controls.DataType.value === LabDataTypeShortText">
                  <td colspan="5">
                    <div class="extend-content"><textarea formControlName="Content" style="width:100%;height:150px;"></textarea></div>
                  </td>
                </tr>
                <tr *ngIf="result.controls.DataType.value === LabDataTypeLongText">
                  <td colspan="5">
                    <div class="extend-content"><textarea formControlName="Content" style="width:100%;height:300px;"></textarea></div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div> -->
      </div>
  </div>
  <!--./card-body-->
</div>
<!--./card-->

