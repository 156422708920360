export class LabItem {
    Id: number;
    CreateTime: Date;
    Creater: string;
    EditTime: Date;
    Editor: string;
    IsEnable: boolean;
    ClinicId: number;
    TypeId: number;
    ItemCode: string;
    ItemName: string;
    NhiCode:string;
    Sort: number;
    LOINC: string;
    ShortName: string;
    CShort:string;
    DataType: number;
    DataValue: string;
    Unit: string;
    Desc1: string;
    Desc2: string;
    TextRef: string;
    IsBySex: boolean;
    High: number;
    Low: number;
    CriHigh: number;
    CriLow: number;
    MHigh: number;
    MLow: number;
    MCriHigh: number;
    MCriLow: number;
    FHigh: number;
    FLow: number;
    FCriHigh: number;
    FCriLow: number;
    LowSuggestion: string;
    HighSuggestion: string;
    IsSet: boolean;
    OrderType: string;
    
    
    LabClassName: string;
    HSPIDHID?: string

    ResultSource:LabItemResultSource[]
}

export class LabItemResultSource{
    Hinno:string;
    ItemCode:string;
    NhiCode:string;
}
export class LabItemSource{
    Id:number;
    Name:string;
    ItemCode:string;
    NhiCode:string;
}


export class BatchSetImportSourceDto{
    Id:number;
    Name:string;
    ItemCode:string;
    NhiCode:string;
    SourceLabItemCode:string;
    Hinno:string;
}