import { Injectable } from "@angular/core";
import { MemoryCache } from "../memory-cache-service";
import { WebApiClient } from "../web-api-client.service";
import { WebApiService } from "../web-api.service";

export class CompleteResult{
    Title:string;
    Content: string;
    Link:string;
} 

@Injectable({providedIn:'root'})
export class AIChatAPI{
    apiService: WebApiService;
    constructor(private apiClient: WebApiClient,
        private memoryCache:MemoryCache) {
        this.apiService = this.apiClient.createHisService('system/AIChat');
        this.apiService.enableLoader = false;
    }
    async Complete(msg:string): Promise<CompleteResult[]>{
        var ret = await this.apiService.post('Complete',{Msg:msg}).toPromise<CompleteResult[]>();
        return ret
    }
}