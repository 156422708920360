<div *ngIf="thisControl && thisControl.invalid && (thisControl.touched)" class="validation-error">
  <div *ngIf="theErrors.required" i18n="@@ValRequired" class="ml-1 text-right">必填欄位</div>
  <div *ngIf="theErrors.email" i18n="@@ValEmail" class="ml-1 text-right">請輸入一個有效的電子郵寄地址</div>
  <div *ngIf="theErrors.minlength" class="ml-md-n1 ml-1">
    <ng-container i18n="@@ValMinLength">最小長度</ng-container> {{theErrors.minlength.requiredLength}}
  </div>
  <div *ngIf="theErrors.maxlength" class="ml-1 text-right">
    <ng-container i18n="@@ValMaxLength">最大長度</ng-container> {{theErrors.maxlength.requiredLength}}
  </div>
  <div *ngIf="theErrors.min" class="ml-1 text-right">
    <ng-container i18n="@@ValMinValue">最小值</ng-container> {{theErrors.min.min}}
  </div>
  <div *ngIf="theErrors.max" class="ml-1 text-right">
    <ng-container i18n="@@ValMaxValue">最大值</ng-container> {{theErrors.max.max}}
  </div>
  <div *ngIf="theErrors.pattern" class="ml-1 text-right">
    <div [ngSwitch]="theErrors.pattern.requiredPattern">
      <div *ngSwitchCase="patternSymbolCode" i18n="@@ValLetNumSym">
        必須是英文大小寫/數字/符號
      </div>
      <div *ngSwitchCase="patternLetterNumber" i18n="@@ValLetNum">必須是英文大小寫/數字</div>
      <div *ngSwitchCase="patternNumber" i18n="@@ValNum">必須是數字</div>
      <div *ngSwitchCase="patternPhone" i18n="@@ValPhone">電話號碼格式 數字或()-#</div>
      <div *ngSwitchCase="patternCName" i18n="@@ValCName">必須2字以上不含空格中文或英文</div>
      <div *ngSwitchDefault i18n="@@ValFormat">無效的格式</div>
    </div>
  </div>
  <div *ngIf="theErrors.enum" i18n="@@ValRequired" class="ml-1 text-right">必填欄位</div>
  <div *ngIf="theErrors.custom" i18n="@@ValRequired" class="ml-1 text-right">{{theErrors.custom}}</div>
</div>
