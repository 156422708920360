<table>
    <thead><tr><td>
      <div class="header-space"></div>
    </td></tr></thead>
    <tbody><tr><td>
      <div class="content">
        <app-referral-form></app-referral-form>
      </div>
    </td></tr></tbody>
    <tfoot><tr><td>
      <div class="footer-space"></div>
    </td></tr></tfoot>
  </table>
  
  <!-- <div class="header">轉診單</div> -->
  <!-- <div class="footer"></div> -->
