import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BaseConfig } from 'src/app/services/base-config.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PageData } from 'src/app/shared/models/page-data';
import { NullOrEmpty } from 'src/app/shared/utilities';
import { LabFormList } from '../../models/labForm-list';
import { LabApi } from 'src/app/services/api-service/labs/lab-api';
import { GridData } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { EasyFormComponent } from 'src/app/shared/components/easy-form/easy-form.component';
import { EasyFormService } from 'src/app/services/easy-form-service';
import { LAB001Params } from 'src/app/services/api-service/parameters/LAB001Params';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { FieldDefineT, FormDefineT, FormFieldCollection } from 'src/app/shared/components/easy-form/form-define';
import { ParameterDataType } from 'src/app/system/parameter-profile/models/parameter-profile';
import { ParamGroupEnum } from 'src/app/services/data-service/system-param-cache-define';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

@Component({
  selector: 'app-labs-report-query-dialog-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  @Input() queryPatientNo = '';
  @Input() queryPatientName = '';
  @Input() isLockPatientNo = false;

  reportRdoValue = 'common';
  bodyHeight = document.body.clientHeight;
  icViewScrollHeight:string = '';

  queryOptions = {
    statusType: [
      { value: 3, text: '完成' },
      { value: 99, text: '全部' },
    ],
  };
  // 查詢條件
  queryFormGroup: FormGroup;
  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  fh:FormHelper<LabViewQuery>;
  
  gridView: GridData;
  tableGridView:GridData;
  constructor( private fb: FormBuilder,
    private fhs:FormHelperService,
    private labApi:LabApi,
    private paramApi:ParameterApi,
    private form:EasyFormService,
    private notify:EasyNotificationService,
    private clinicData:ClinicDataService
  ) {
    
  }

  async ngOnInit() {
    this.createQueryFormGroup();
    this.fh.setValue('patientNo',this.queryPatientNo);
    if (this.isLockPatientNo) {
      this.fh.Controls.patientNo.disable();
    }
    
    await this.refreshList(true);
    
  }

  /** 取捲軸高 */
  getViewScrollHeight(): string {
    let scrollHeight= this.bodyHeight - 145;
    this.icViewScrollHeight = scrollHeight + 'px';
    return this.icViewScrollHeight;
  }
  createQueryFormGroup() {
    this.fh = this.fhs.Create<LabViewQuery>().build({
      patientNo: ['',null],
      timeStart: [new Date(DateHelper.today.setMonth(DateHelper.today.getMonth() - 3)),null],
      timeEnd: [DateHelper.today,null],
      statusType: [3,null],
      pageNumber:[0,null],
      pageSize:[this.pageSize,null],
      operationType:[0,null]
    })
    this.queryFormGroup =this.fh.FormGroup;
  }

  
  async onBtnQueryClick() {
    const patientNo = this.fh.Value.patientNo;
    if (patientNo !== this.queryPatientNo) {
      this.queryPatientName = '';
    }
    await this.refreshList(true);
  }
  async refreshList(isNewQuery?: boolean) {
    if (isNewQuery) {
      this.fh.setValue('pageNumber',0)
    }
    // 取得參數資料
    const query = this.queryFormGroup.value;// this.makeQueryConditions();
    var data = await this.labApi.getAll(query)
    this.gridView = {
      data: data.data,
      total: data.total
    };
    var data2 = await this.labApi.getTable(query)
    this.tableGridView = {
      data: data2.data,
      total: data2.total
    };
    
  }
  async pageChange(pNo: number){
    this.fh.setValue('pageNumber',pNo);
    await this.refreshList(); // 更新清單，因為server端資料可能異動
  }
  async s(){
    this.clinicData.editParam('LAB001');
    // var def = await this.paramApi.GetDesc('LAB001');
    // var p = await this.paramApi.GetParams('LAB001');
    // var field:FormFieldCollection<LAB001Params> = {};
    // def.forEach(x=>{
    //   var d // :FieldDefineT<LAB001Params,''>  
    //     = {
    //     name: x.Key,
    //     label:x.Name,
    //     type: x.DataType== ParameterDataType.Boolean?'check':
    //           x.DataType== ParameterDataType.Datetime?'date':
    //           x.DataType== ParameterDataType.Int?'number':
    //           x.DataType== ParameterDataType.String?'text':
    //           x.DataType== ParameterDataType.Switch?'check':'text',
    //     value:p[x.Key],
    //     tip:x.Description+' Ex:'+x.Value
    //   }
    //   field[x.Key] = d;
    // })
    // var ret = await this.form.show<LAB001Params>({
    //   title:'LAB001',
    //   msg:'參數編輯',
    //   width:450,
    //   height:550,
    //   fields:field
    // });
    // if(ret){
    //   p = Object.assign(p,ret);
    //   try{
    //     await this.paramApi.UpdateParamGroup(ParamGroupEnum.LAB001 ,p);
    //     this.notify.showSuccess('更新完成');
    //   }catch(e){
    //     this.notify.showSuccess('更新失敗');
    //   }
    // }
  }
}

interface LabViewQuery{
  operationType:number;
  timeStart:Date;
  timeEnd: Date;
  patientNo: string;
  statusType: number;
  pageNumber:number;
  pageSize:number;
}