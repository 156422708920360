import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { HelperId } from './helper-id';

export class HelperEvent {
  constructor() {
    this.type = HelperEventTypeEnum.NA;
    this.source = HelperSourceEnum.NA;
    this.sourceId = '';
    this.orderClass = null;
    this.inputValue = '';
    this.key = '';
    this.rowIndex = -1;
    this.selectedItem = null;
    this.isInsert = true;
  }

  type: HelperEventTypeEnum;
  sourceId: string;
  source: HelperSourceEnum; // input來源
  orderClass: string;
  rxType:number[];
  subSourceType: string; // 次要來源類型，如: 來源Way的次要類型有Part
  inputValue: string;
  key: string; // keyboardEvent的key
  rowIndex: number; // form array的那一筆
  selectedItem: any;
  isInsert: boolean;

  static FocusEvent(sourceId: string,data:any=null): HelperEvent {
    const source = HelperId.getSourceById(sourceId);
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.Focus;
    event.sourceId = sourceId;
    event.source = source;
    if(source == HelperSourceEnum.Order){
      event.rxType = data;
    }
    return event;
  }
  static ClickEvent(sourceId: string, rowIndex: number): HelperEvent {
    const source = HelperId.getSourceById(sourceId);
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.ClickForDropdown;
    event.sourceId = sourceId;
    event.source = source;
    event.rowIndex = rowIndex;
    return event;
  }
  static KeyboardEvent(key: string): HelperEvent {
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.Keyboard;
    event.key = key;
    return event;
  }
  static ValueEvent(sourceId: string, inputValue: string, rowIndex?: number, rxTypes?: number[]): HelperEvent {
    const source = HelperId.getSourceById(sourceId);
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.Value;
    event.sourceId = sourceId;
    event.source = source;
    event.rxType = rxTypes;
    //event.orderClass = orderClass;
    event.inputValue = inputValue;
    event.rowIndex = rowIndex;
    return event;
  }
  static ResetEvent(): HelperEvent {
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.Reset;
    return event;
  }
  static OrderClassEvent(classCode: string, rxType: number[]): HelperEvent {
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.OrderClass;
    event.orderClass = classCode;
    event.rxType = rxType;
    return event;
  }
  static HistListSelectEvent(): HelperEvent {
    const event = new HelperEvent();
    event.type = HelperEventTypeEnum.HistListSelect;
    event.source = HelperSourceEnum.HistList;
    return event;
  }
}
