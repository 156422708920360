

/**
 * 就醫識別碼
 */ 
export class TreatNumNoICCard {
    /**
     * 電腦日期時間
     */ 
    public DateTime: Date = new Date(0);
    /**
     * 醫療院所代碼
     */ 
    public HospCode: string = null;
    /**
     * 就醫識別碼
     */ 
    public TreatNum: string = null;
}
