import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ContactDto } from 'src/app/services/api-service/patient/contactDto';
import { PatientEditOption } from 'src/app/services/api-service/patient/patient-edit-option';
import { PostAreaAPI } from 'src/app/services/api-service/postarea-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { ZipCodeHelper } from 'src/app/shared/helpers/zipcode-helper';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { NullOrEmpty, isNullOrUndefined } from 'src/app/shared/utilities';

@Component({
  selector: 'app-patient-contact',
  templateUrl: './patient-contact.component.html',
  styleUrls: ['./patient-contact.component.css']
})
export class PatientContactComponent implements OnInit {


  @ViewChild('zipCode', { static: false })
  zipCodeEl: ElementRef<HTMLInputElement>


  @Output()
  delete = new EventEmitter()

  @Input()
  deletable = false;

  //@Input()
  //editOptions: PatientEditOption;
  @Input()
  title: string = '聯絡人';
  _data: ContactDto;

  @Input()
  patientForm: FormGroup;
  @Input()
  set data(d: ContactDto) {
    this.validateMsg = '';
    this.sameAddrFromPatient = false;
    if (d) {
      this._data = d;
      var temp = JSON.parse(JSON.stringify(d));
      if (this.form) {
        // enable會觸發valueChanges把_data洗掉，所以要寫在前面
        this.form?.enable({ emitEvent: false });
        this.form?.reset({ emitEvent: false });
        // 這個動作觸發事件 讓zipCodeHelper去更新Area選項
        this.form?.patchValue(temp);
      }

      // 為主要聯絡人 勾選同住者
      if (this.patientForm) {
        var zipCodeval = this.form.controls.ZipCode.value;
        if (zipCodeval == null) zipCodeval = '';
        var thesamezipcode = this.patientForm?.controls.ZipCode.value == zipCodeval;
        var thesamecity = this.patientForm?.controls.City.value == this.form.controls.City.value;
        var thesamestreet = this.patientForm?.controls.Street.value == this.form.controls.Street.value;
        var thesamearea = this.patientForm?.controls.Area.value == this.form.controls.Area.value;

        if (thesamezipcode && thesamecity && thesamestreet && thesamearea) {
          this.sameAddrFromPatient = true;
        }
      }
      //this.form?.updateValueAndValidity({emitEvent:false});
      if (!this._enable) {
        this.form?.disable({ emitEvent: false });
      }
    }
  }

  _enable = true;
  @Input()
  set enable(v) {
    if (!isNullOrUndefined(v)) {
      this._enable = v;
      if (v == true) {
        this.form?.enable({ emitEvent: false });
        this.form?.markAllAsTouched();
      } else {
        this.form?.disable({ emitEvent: false });
      }
    }
  }

  form: FormGroup;
  zipCodeHelper: ZipCodeHelper;
  city: ValueTextPair[];
  area: ValueTextPair[];
  titleOpt: ValueTextPair[];
  relationOpt: ValueTextPair[];
  validateMsg = '';
  constructor(private formBuilder: FormBuilder,
    private postAreaAPI: PostAreaAPI,
    private userConfirm: UserConfirmService,
    private clinicData: ClinicDataService,
    private renderer: Renderer2
  ) { }

  async ngOnInit() {
    var options = await this.clinicData.getSystemCodes(['B0210', 'B0060'])
    this.titleOpt = [{ value: '', text: '' }].concat(options.B0210);
    this.relationOpt = [{ value: '', text: '' }].concat(options.B0060);

    this.form = this.formBuilder.group({
      Name: [''],
      Title: [''],

      PhoneHomeArea: ['', Validators.maxLength(5)],
      PhoneHome: ['', Validators.maxLength(20)],
      PhoneCompanyArea: ['', Validators.maxLength(5)],
      PhoneCompany: ['', Validators.maxLength(20)],
      Mobile: ['', Validators.maxLength(20)],
      EMail: ['', Validators.maxLength(50)],
      ZipCode: ['', Validators.maxLength(10)],
      Country: [''],
      State: [''],
      City: [''],
      Area: [''],
      Street: ['', Validators.maxLength(200)],
      RelationCode: [''],
      ProviderRelationCode: [''],
      Occupation: [0],
      OccupationName: [''],
      Remark: [''],
      MainPerson: ['']
    });

    var createContactValidator = () => {
      return (c: AbstractControl) => {
        var ph = this.form.controls.PhoneHome;
        var mo = this.form.controls.Mobile;
        var st = this.form.controls.Street;
        if (this.form.controls.Name.value &&
          !(ph.value || mo.value || st.value)) {
          if (c.valid == true) {
            setTimeout(() => {
              // 把其他幾個valid的更新成invalid
              [ph, mo, st].filter(x => x.valid && x != c).forEach(x => x.updateValueAndValidity({ 'emitEvent': false }));
            }, 0);
          }
          return { 'custom': '聯絡人的電話/手機/地址擇一必填' };
        } else {
          //if(c.invalid){
          setTimeout(() => {
            [ph, mo, st].filter(x => x.invalid && x != c).forEach(x => x.updateValueAndValidity({ 'emitEvent': false }));
          }, 0);
          //}
        }
      };
    };
    this.form.controls.PhoneHome.setValidators(createContactValidator().bind(this));
    this.form.controls.Mobile.setValidators(createContactValidator().bind(this));
    this.form.controls.Street.setValidators(createContactValidator().bind(this));
    this.form.controls.Name.valueChanges.subscribe(v => {
        var ph = this.form.controls.PhoneHome;
        var mo = this.form.controls.Mobile;
        var st = this.form.controls.Street;
      if (v) {

        [ph, mo, st].forEach(x => x.updateValueAndValidity({ 'emitEvent': false }));
      }else{
        setTimeout(() => {
          [ph, mo, st].forEach(x => x.updateValueAndValidity({ 'emitEvent': false }));
        }, 0);
      }
    })
    this.form.patchValue(this._data);
    if (this._enable) {
      this.form.enable({ emitEvent: false });
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();
    } else {
      this.form.disable({ emitEvent: false });
    }
    this.form.valueChanges.subscribe(v => {
      if (this._data) {
        Object.assign(this._data, v)
      } else {
        console.log(this._data, v)
      }
    });
    setTimeout(() => {
      this.zipCodeHelper = new ZipCodeHelper(this.postAreaAPI, this.userConfirm, this.zipCodeEl.nativeElement,
        this.form.controls.ZipCode, this.form.controls.City, this.form.controls.Area, this.form.controls.Street);
      this.zipCodeHelper.onAreaChange.subscribe(v => {
        this.city = v.City;
        this.area = v.Area
      });
      this.zipCodeHelper.onChanged.subscribe(v => {

        // 再helper中設定連動欄位不會觸發事件，所以this.form.valueChanges抓不到
        // 故在helper的值改變的部分進行額外的patch
        Object.assign(this._data, { ZipCode: v.Zip, City: v.City, Area: v.Area, Street: v.Street });

      })

      this.zipCodeHelper.init();
    }, 0);
    // 控制聯絡人在匯入資料時勾選同住者
    if (this.patientForm && this._data) {
      var thesamezipcode = this.patientForm?.controls.ZipCode.value == this.form.controls.ZipCode.value;
      var thesamecity = this.patientForm?.controls.City.value == this.form.controls.City.value;
      var thesamestreet = this.patientForm?.controls.Street.value == this.form.controls.Street.value;
      var thesamearea = this.patientForm?.controls.Area.value == this.form.controls.Area.value;
      if (thesamezipcode && thesamecity && thesamestreet && thesamearea) {
        this.sameAddrFromPatient = true;
      }
    }
  }
  onDelete() {
    this.delete.emit();
  }
  validate() {
    // 沒用到的欄位跟MainPerson 不檢核
    var { Occupation,OccupationName,MainPerson, ...formValue }  = this.form.getRawValue();
    var stDisable = this.form.disabled;
    if (stDisable) {
      return true
      this.form.enable()
    }
    let formValuesArray = Object.values(formValue);
    const nothaveValue = formValuesArray.every(value => value === null || value === '');
    let isNameNullOrEmpty = formValue.Name === '' || formValue.Name === null
    // 可刪除(非主聯絡人) 才強制姓名
    // if (!formValue.Name && !nothaveValue) {

    //   if (this.deletable) {
    //     this.validateMsg = '(*姓名欄位不可留空，或刪除此聯絡人)';
    //     return false;
    //   }else{
    //     this.validateMsg = '';
    //   }
    // } else
    if (!formValue.PhoneHome && !formValue.Mobile && !formValue.Street) {
      if (!isNameNullOrEmpty) {
        this.validateMsg = '(*電話/手機/地址請至少擇一填寫)'
        return false;
      }else{
        this.validateMsg = '';
      }
    }
    else if(nothaveValue){
      // 都沒有就全刪了 不去影響判斷
      this.delete.emit();
    }
    var valid = this.form.valid;
    if (stDisable) {
      this.form.disable()
    }
    return valid;
  }
  sameAddrFromPatient = false;
  onChkSameAddressClick(event: MatCheckboxChange) {

    if (this.form.enabled && this.patientForm) {
      this.sameAddrFromPatient = event.checked;

      if (event.checked) {
        //this.zipCodeHelper.disable();
        this.form.patchValue({
          City: this.patientForm.controls.City.value,
          Area: this.patientForm.controls.Area.value,
          ZipCode: this.patientForm.controls.ZipCode.value,
          Street: this.patientForm.controls.Street.value
        }, { emitEvent: false });

        this.form.controls['City'].disable();
        this.form.controls['ZipCode'].disable();
        this.form.controls['Area'].disable();
        this.form.controls['Street'].disable();
      } else {

        this.form.controls['City'].enable();
        this.form.controls['ZipCode'].enable();
        this.form.controls['Area'].enable();
        this.form.controls['Street'].enable();
      }
    }
  }
}
