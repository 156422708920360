import { Component, OnInit, Input, ViewChild, ContentChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { CheckBoxData } from 'src/app/system/user/models/check-box-data';
import { EditControlButtonsComponent } from '../edit-control-buttons/edit-control-buttons.component';
import { EditModeEnum } from '../../enums/edit-mode-enum';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-shared-check-edit',
  templateUrl: './check-edit.component.html',
  styleUrls: ['./check-edit.component.css']
})
export class CheckEditComponent implements OnInit {

  @ViewChild('ctrlBtns',  { static: false })
  controlButtons: EditControlButtonsComponent;
  @ContentChild(TemplateRef, { static: true })
  templateRef: TemplateRef<any>;

  @Input()
  api: WebApiService;
  // getAction: HttpGet, {id:id} return CheckBoxData array
  @Input()
  getAction: string;
  @Input()
  saveAction: string;
  @Input()
  height = 300;
  @Input()
  groupCheckAll = true
  @Output() selectChange = new EventEmitter<{checked:boolean,item:CheckBoxData,selected:CheckBoxData[]}>()
  @Output() saved = new EventEmitter();
  @Output() checkDataChanged = new EventEmitter<CheckBoxData[]>();
  checkData: CheckBoxData[];
  groupData: {group:number,order:number,groupName:string}[] = [];
  curId: number;
  @Input()
  set editId(id: number) {
    // console.log(id);
    if (id) {
      this.curId = id;
      this.getData(this.curId);
    }
  }

  _hiden = false;
  @Input()
  set isHide(hide:boolean){
    this._hiden = hide;
    if(this._hiden){
      this.onCancel();
    }
  }
  get isHide():boolean{
    return this._hiden;
  }
  constructor(private notification: EasyNotificationService) { }

  ngOnInit() {
    this.resetCtrlBtns();
  }

//#region Role
convertToChkData(result: CheckBoxData[]) {
  this.groupData = [];
  const chDatas = [];
  result.forEach(r => {
    const chData = new CheckBoxData();
    chData.Id = r.Id;
    chData.Name = r.Name;
    chData.Checked = r.Checked;
    chData.OriginalChecked = r.Checked;
    chData.Group = r.Group;
    chData.Order = r.Order;
    chDatas.push(chData);
    this.insertGroupData (r.Group, r.Order,r.GroupName);
  });
  return chDatas;
}
insertGroupData(group: number, order: number,groupName:string) {
  if (!group) {
    return;
  }
  if (this.groupData.findIndex(g => g.group === group) >= 0) {
    return;
  }
  let i = 0;
  let compResult = 1;
  for (i; i < this.groupData.length ; i = i + 1) {
    const g = this.groupData[i];
    compResult = this.orderCompare(g.order, order);
    if ( compResult === 1) {
      if ( i === this.groupData.length - 1) {
        i = i + 1;
      }
      continue;
    } else {
      break;
    }
  }
  this.groupData.splice(i, 0, { group, order,groupName});
}
// 1: 往後比, -1, :塞前面
orderCompare(x: number, y: number) {

  const x1 = x % 100;
  const y1 = y % 100;
  if (x1 < y1) {
    return 1;
  } else if (x1 === y1) {
    if(x1==0){return 0}
    return this.orderCompare(Math.ceil(x / 100) , Math.ceil( y / 100));
  } else {
    return -1;
  }

}
getData(id: number) {
  this.api.get(this.getAction, { id: id}).subscribe( (result: any[] ) => {
    setTimeout(() => {
      this.checkData = this.convertToChkData(result);
      this.checkDataChanged.emit(this.checkData);
      this.resetCtrlBtns();
    }, 0);

  });
}
resetCtrlBtns() {
  if (this.controlButtons) {
    this.controlButtons.toViewMode();
  }
}
isEditing() {
  return this.controlButtons.editMode === EditModeEnum.edit;
}
onSave() {
  const changeData = [];
  this.checkData.forEach( r => {
    if (r.Checked !== r.OriginalChecked) {
      changeData.push(r);
    }
  });
  const saveData = {
    MasterId: this.curId,
    Data: changeData
  };
  this.api.post(this.saveAction, saveData).subscribe(r => {
    this.notification.showOk();
    this.getData(this.curId);
    this.saved.emit();
  },
  error => {
    this.notification.showError(error);
    this.resetCtrlBtns();
  });
}
onEdit() {
  this.controlButtons.toEditMode();
}
onCancel() {
  this.controlButtons.toViewMode();
  if(this.checkData){
    this.checkData.forEach(r => {
      r.Checked = r.OriginalChecked;
    });
  }
}
groupCheck(e: MatCheckboxChange,group:number) {
    this.checkData.forEach( ch => {
      if (ch.Group === group) {
        ch.Checked = e.checked;
      }
    });
}

checkChange(evt:MatCheckboxChange,item: CheckBoxData){
  item.Checked = evt.checked;
  this.selectChange.emit({
    checked: evt.checked,
    item:item,
    selected:this.checkData
  });
}
//#endregion
}
