import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MainLayoutService } from '../services/main-layout.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { AuthenticationService } from 'src/app/security/services/authentication.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit, OnDestroy {
  constructor(
    private mainLayoutService: MainLayoutService,
    private userConfirm:UserConfirmService,
    private auth:AuthenticationService) {
    this.showLoaderSubs = this.mainLayoutService.isShowLoader$.subscribe(
      isShowLoader => {
        this.showLoader(isShowLoader);
      }
    );
  }

  get isUseTopLoader(): boolean {
    if (BaseConfig.getConfig().env.loaderType === 'top') {
      return true;
    } else {
      return false;
    }
  }
  get isUseCenterLoader(): boolean {
    if (BaseConfig.getConfig().env.loaderType === 'center') {
      return true;
    } else {
      return false;
    }
  }
  showLoaderSubs: Subscription;
  isWaitShowLoader = false;
  isShowLoader = false;

  timer: any;
  timeOutTimer:any;
  isWaitingConfirm = false;
  showLoader(isShow: boolean) {
    //console.log('show loader', isShow)
    if (isShow) {
      if(this.timer == null){
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.isShowLoader = true;
        }, 0);
        if(this.mainLayoutService.EnableLoaderTimeout){
          this.timeOutTimer = setTimeout(async() => {
            this.isWaitingConfirm = true
            var ret = await this.userConfirm.showConfirm({
              title:'等候逾時',
              msg:'操作回應逾時，您可以：',
              width:350,
              'textYes':'繼續等候',
              hideNo:true,
              customBtnEnd:[
                {'text':'重新載入','return':'reload'},
                {'text':'登出','return':'logout'}]
            });
            this.isWaitingConfirm = false
            if(ret == 'reload'){
              location.reload();
            }else if(ret == 'logout'){
              this.auth.logout();
            }else{
              //this.showLoader(this.isShowLoader);
            }
          }, 30000);
        }
      }
    } else {
      clearTimeout(this.timer);
      if(this.timeOutTimer){
        clearTimeout(this.timeOutTimer);
      }
      
      this.timer = null;
      this.isShowLoader = false;
    }
  }

  classProgress(): string {
    if (this.isShowLoader) {
      return 'progress';
    } else {
      return 'progress-none';
    }
  }
  classProgressBar(): string {
    if (this.isShowLoader) {
      return 'progress-bar progress-bar-moved';
    } else {
      return 'progress-bar';
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.showLoaderSubs.unsubscribe();
  }

}
