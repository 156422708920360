import { Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { isNullOrUndefined } from 'util';


export const VIS_DD_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisDropdownlistComponent),
  multi: true
};
@Component({
  selector: 'app-vis-dropdownlist',
  templateUrl: './vis-dropdownlist.component.html',
  styleUrls: ['./vis-dropdownlist.component.css'],
  providers: [VIS_DD_CONTROL_VALUE_ACCESSOR]
})
export class VisDropdownlistComponent implements OnInit, ControlValueAccessor {
  @ContentChild(TemplateRef)
  displayTmp:TemplateRef<any>;
  @ViewChild(MatSelect)
  select:MatSelect;

  @Input()
  data: { text: string, value: string | number }[];
  @Input()
  textField: string = 'text';
  @Input()
  valueField: string = 'value';
  @Input()
  valuePrimitive: boolean;
  @Input()
  set disabled(v) {
    if (v !== null && v !== undefined) {
      this._disabled = v;
    }
  }

  @Input()
  set value(v) {
    if (v) {
      this._currentValue = v;
    }
  }
  get value(): any {
    return this._currentValue;
  };
  @Input()
  set readonly(v) {
    this._readonly = v;
  }
  @Input()
  defaultItem: any;
  @Input()
  panelClass: string;
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter(false);

  _currentValue: any;
  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean = false;
  _readonly: boolean = false;
  _class: string;
  
  constructor(private elementRef: ElementRef) {
    elementRef.nativeElement.focus = ()=>{
      if(!this._disabled){
        this.select.focus();
      }
    }
  }

  ngAfterContentChecked() {
    this._class = this.elementRef.nativeElement.className;
  }

  writeValue(obj: any): void {
  //  if (obj != null) {
      this._currentValue = obj;
  //  }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    if (this._currentValue === null || this._currentValue === undefined) {
      this._currentValue =
      this.defaultItem === undefined ? '' : this.defaultItem.value;
    }
  }

  _valueChange(evt: MatSelectChange) {
    this._currentValue = evt.value;
    if (this._onChange) {
      this._onChange(evt.value)
    }
    setTimeout(() => {
      if (this.valuePrimitive) {
        this.valueChange.emit(evt.value);
      } else {
        var seletcedItem = this.data.find(d => d.value == evt.value);
        this.valueChange.emit(seletcedItem);
      }
    }, 0);

  }
}
