import {  Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output } from '@angular/core';

import { VisDatepickerComponent } from '../components/datepicker/vis-datepicker/vis-datepicker.component';
export type appKeydownCode = 'Up'|'Down'|'Left'|'Right'|'Enter';
/** 預設參數為Name,給其他使用appKeydownTo的element設定focus對象的名稱
 * 
 * 支援leftTo="name" 
 * 
 * 支援rightTo="name"
 * 
 * 支援upTo="name"
 * 
 * 支援downTo="name"
 *
 * 支援enterTo="name"
 * 
 * example:
 * 
 * <input appKeydownTo="x"/>
 * 
 * <input appKeydownTo="a" rightTo="b"/>
 * 
 * <input appKeydownTo="b" leftTo="a,x"/>
 */
@Directive({
  selector: '[appKeydownTo]'
})
export class KeydownToDirective implements OnInit {
  @Input()
  appKeydownTo: string;
  /** 游標在最前時左鍵要前往的對象 */
  @Input()
  leftTo: string;
  /** 游標在最後時右鍵要前往的對象 */
  @Input()
  rightTo: string;
  @Input()
  upTo: string;
  @Input()
  downTo: string;  
  @Input()
  enterTo: string;
  
  /** Setter, 設定到leftTo與upTo */
  @Input()
  set prevTo(s: string){
    this.leftTo = s;
    this.upTo = s;
  }
  /** Setter, 設定到rightTo與downTo與enterTo */
  @Input()
  set nextTo(s: string){
    this.rightTo = s;
    this.downTo = s;
    this.enterTo = s;
  }
  @Output()
  appKeyDown = new EventEmitter<appKeydownCode>()

  constructor(private self:ElementRef<HTMLElement> ,@Optional() private datePicker: VisDatepickerComponent) {
    
  }

  ngOnInit(): void {
    // 如果用單向綁定的情況，會沒有這個attribute,所以要補上來
    this.self.nativeElement.setAttribute('appkeydownto',this.appKeydownTo);
    if(this.datePicker){
      setTimeout(() => {
        
      this.datePicker.yearInput.nativeElement.addEventListener('keydown',(evt)=>{
        var targetList = [];
        if (evt.key == 'ArrowLeft' && (evt.target as HTMLInputElement).selectionStart == 0) {
          targetList = this.leftTo?.split(',')||[];
          this.appKeyDown.emit('Left');
          if(targetList?.length>0){
            var tar = targetList.map(n=>document.querySelector('[appkeydownto="'+n+'"]')).find(a=>a);
            this.focusTo(tar as HTMLInputElement);
          }
        }
      });
      this.datePicker.dayInput.nativeElement.addEventListener('keydown',(evt)=>{
        var targetList = [];
        var input = (evt.target as HTMLInputElement);
        if (input.selectionEnd == input.value.length) {
          if(evt.key == 'ArrowRight'){
            targetList = this.rightTo?.split(',')||[];
            this.appKeyDown.emit('Right');          
          }
          if(evt.key == 'Enter'){
            targetList = this.enterTo?.split(',')||[];
            this.appKeyDown.emit('Enter');          
          }
          if(targetList?.length>0){
            var tar = targetList.map(n=>document.querySelector('[appkeydownto="'+n+'"]')).find(a=>a);
            this.focusTo(tar as HTMLInputElement);
          }
        }
        
      });
      
    }, 0);
    }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    var el = e.target as HTMLInputElement;
    var targetList = [];
    if(this.datePicker){
      return;
    }else{
      if (e.key == 'Enter') {
        targetList = this.enterTo?.split(',')||[];
        this.appKeyDown.emit('Enter');
      } else if (e.key == 'ArrowUp') {
        targetList = this.upTo?.split(',')||[];
        this.appKeyDown.emit('Up');
      }else if (e.key == 'ArrowDown') {
        targetList = this.downTo?.split(',')||[];
        this.appKeyDown.emit('Down');
      }else if (e.key == 'ArrowLeft' && el.selectionStart == 0) {
        targetList = this.leftTo?.split(',')||[];
        this.appKeyDown.emit('Left');
      }else if (e.key == 'ArrowRight'  && el.selectionEnd == el.value.length) {
        targetList = this.rightTo?.split(',')||[];
        this.appKeyDown.emit('Right');
      }
    }
    
    if(targetList?.length>0){
      var tar = targetList.map(n=>document.querySelector('[appkeydownto="'+n+'"]')).find(a=>a);
      this.focusTo(tar as HTMLInputElement);
    }
    
  }
  private focusTo(el: HTMLInputElement){
    if(!el) return;
    el.focus();
    if(el.select){
      setTimeout(() => {
        el.select();  
      }, 0);        
    }
  }
}
