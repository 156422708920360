import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  printContent = '';
  isReady = false;
  // referrals: string[] = [];
  dataObserver: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router) { 
    this.dataObserver.subscribe(value => this.isReady = value); 
  }

  onDataReady() {
    this.dataObserver.next(true);
  }
}

