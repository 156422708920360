import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// import { HistApi } from 'src/app/services/api-service/hist/hist-api';
// import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { UserCache } from 'src/app/services/user-cache';
// import { Clinic } from 'src/app/system/clinic/models/clinic';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
// import { RegistEditOpt, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DxCertDto, DxPrintSetting } from 'src/app/patients/patient-hist/models/patient-hist';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { ClinicDataService, ValueTextPairBundle } from 'src/app/services/data-service/clinic-data-service';
import { ValueTextPair, ValueTextPairAnyValue } from 'src/app/shared/models/value-text-pair';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { EasyFormService } from 'src/app/services/easy-form-service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';


@Component({
    selector: 'app-diagnosis-cert',
    templateUrl: './diagnosis-cert.component.html',
    styleUrls: ['./diagnosis-cert.component.css']
})
export class DiagnosisCertComponent implements OnInit {

    @ViewChild('reportContainer')
    reportContainer: ElementRef<HTMLDivElement>;

    @ViewChild('enReportContainer')
    EnReportContainer: ElementRef<HTMLDivElement>;

    @Output()
    printed = new EventEmitter<boolean>();

    api: WebApiService;
    printSettng: DxPrintSetting;
    clinicName: string = UserCache.getLoginUser().ClinicName;
    clinicChief: string = '';
    businessLicense: string = '';
    clinicPhone: string = '';
    clinicAddr: string = '';
    medLicense: string = '';
    patientName: string = '';
    patientNo: string = '';
    patientId?: number;
    patientBirthday: string = '';
    patientPhone: string = '';
    patientAddress: string = '';
    patientCId: string = '';
    patientSexName: string = '';
    patientJob: string = '';
    patientBirthCity: string = '';
    diagnosisDate: string = '';
    dexcertRemark: string = '';
    reportType: string = '1';
    medDep: string = '';
    docName: string = '';
    CCs: string = '';
    dxName: string = '';
    dates: string = '';
    issuseDate: Date = new Date();
    engIssueDate: string = this.issuseDate.getFullYear().toString() + '/' + (this.issuseDate.getMonth() + 1).toString() + '/' + this.issuseDate.getDate().toString()
    dxCertId: number = 0;
    systemCodes: ValueTextPairBundle = {};
    enumsCodes: ValueTextPairBundle = {};
    cities: ValueTextPairAnyValue[] = [ {'text': '臺北市', 'value': 'A', 'extension': 'Taipei City'}, {'text': '臺中市', 'value': 'B', 'extension': 'Taichung City'},
            {'text': '基隆市', 'value': 'C', 'extension': 'Keelung City'}, {'text': '臺南市', 'value': 'D', 'extension': 'Tainan City'},
            {'text': '高雄市', 'value': 'E', 'extension': 'Kaohsiung City'}, {'text': '新北市', 'value': 'F', 'extension': 'New Taipei City'},
            {'text': '宜蘭市', 'value': 'G', 'extension': 'Yilan City'}, {'text': '桃園市', 'value': 'H', 'extension': 'Taoyuan City'},
            {'text': '嘉義市', 'value': 'I', 'extension': 'Chiayi City'}, {'text': '新竹縣', 'value': 'J', 'extension': 'Hsinchu County'},
            {'text': '苗栗縣', 'value': 'K', 'extension': 'Miaoli County'}, {'text': '臺中市', 'value': 'L', 'extension': 'Taichung City'},
            {'text': '南投縣', 'value': 'M', 'extension': 'Nantou County'}, {'text': '彰化縣', 'value': 'N', 'extension': 'Changhua County'},
            {'text': '新竹市', 'value': 'O', 'extension': 'Hsinchu City'}, {'text': '雲林縣', 'value': 'P', 'extension': 'Yunlin County'},
            {'text': '嘉義縣', 'value': 'Q', 'extension': 'Chiayi County'}, {'text': '臺南縣', 'value': 'R', 'extension': 'Tainan County'},
            {'text': '高雄縣', 'value': 'S', 'extension': 'Kaohsiung County'}, {'text': '屏東縣', 'value': 'T', 'extension': 'Pingtung County'},
            {'text': '花蓮縣', 'value': 'U', 'extension': 'Hualien County'}, {'text': '臺東縣', 'value': 'V', 'extension': 'Taitung County'},
            {'text': '金門縣', 'value': 'W', 'extension': 'Kinmen County'}, {'text': '澎湖縣', 'value': 'X', 'extension': 'Penghu County'},
            {'text': '陽明山', 'value': 'Y', 'extension': 'Yangmingshan'}, {'text': '連江縣', 'value': 'Z', 'extension': 'Lianjiang County'} ];

    constructor(private localPrintService: LocalPrintService,
        private easyForm: EasyFormService,
        private clinicData: ClinicDataService,
        private patientApi: PatientApi,
        private webApiClient: WebApiClient,
        private notification: EasyNotificationService) {
            this.api = this.webApiClient.createHisService('patients/PatientHist');
    }

    ngOnInit() {
        // debugger
    }

    getHRx(PatId: number, startDate: Date, endDate: Date) {

    }

    getHtml() {
        var html = '';
        if (this.reportType == '1') {
            html = this.reportContainer.nativeElement.innerHTML;
        } else {
            html = this.EnReportContainer.nativeElement.innerHTML;
        }

        html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.3);','');
        //html = html.replace('padding-top: 0.5cm;','');
        html = html.replace('height: 28.7cm;',''); //因為列印會多印一頁空白頁

        return html;
    }

    getOption(code): ValueTextPair[]{
        return this.systemCodes[code] || this.enumsCodes[code];
    }

    async getEditOptions() {
        this.systemCodes = await this.clinicData.getSystemCodes([
            'B0030',    // B0030 職業代碼
        ]);
        this.systemCodes.B0030 = [{value:'',text:''}].concat(this.systemCodes.B0030);
    }

    async onPrint() {
        const printContents = this.getHtml();
        var ret = await this.localPrintService.printHtml(444, '診斷證明書', printContents);
        if (ret.Successed) {
            this.notification.showSuccess('診斷證明書已送出列印!', true);
            this.updateDxCertPrintData();
        } else {
            this.notification.showError('診斷證明書送出列印失敗!');
        }
    }

    async updateDxCertPrintData() {
        let query = { id: this.dxCertId };
        var ret: boolean = await this.api.get('UpdateDxCertById', query).toPromise();
        this.printed.emit(ret);
    }

    getBirthday() {
        return new Date(this.patientBirthday).toLocaleDateString();
    }

    async fillData(data: DxCertDto, p: any, setting: DxPrintSetting, dept: string, docName: string, docLicence: string) {
        this.dxCertId = data.dxm.Id;
        this.printSettng = setting;
        this.reportType = setting.ReportType;
        // this.getPatientRecord(p.PatientId);
        var id = p;
        var patData: PatientsDto;
        try {
            patData = await this.patientApi.Get(id);
            this.patientName = patData.CName == undefined || patData.CName == null ? '' : patData.CName;
            this.patientNo = patData.PatientNo == undefined || patData.PatientNo == null ? '' : patData.PatientNo;
            this.patientId = patData.Id == undefined || patData.Id == null ? null : patData.Id;
            this.patientPhone = patData.Phone == undefined || patData.Phone == null ? '' : patData.Phone;
            this.patientBirthday = patData.Birthday == undefined || patData.Birthday == null ? '' : new Date(patData.Birthday).toDateString();
            this.patientAddress = patData.City + patData.Area + patData.Street;
            this.patientCId = patData.CId == undefined || patData.CId == null ? '' : patData.CId;
            //this.patientJob = data.
            this.patientSexName = patData.Sex == 0 ? (this.reportType == '1' ? '女' : 'Female') : (patData.Sex == 1 ? (this.reportType == '1' ? '男' : 'Male') : '');
        } catch (ex) {
            this.notification.showGetDataFailed();
        }

        this.medDep = dept;
        this.docName = docName;
        this.medLicense = docLicence;
        var clinic = UserCache.getLoginUser().Clinic;
        this.clinicName = this.reportType == '1' ? clinic.Name : clinic.EName;
        if (clinic.OwnerName == undefined || clinic.OwnerName.length == 0) {
            this.clinicChief = this.reportType == '1' ? '' : (clinic.OwnerEName ? clinic.OwnerEName : '');
        } else {
            this.clinicChief = this.reportType == '1' ? clinic.OwnerName : (clinic.OwnerEName ? clinic.OwnerEName : '');
        }

        if (!clinic.BusinessLicense) {
            this.businessLicense = '';
        } else {
            this.businessLicense = clinic.BusinessLicense;
        }

        // if (!UserCache.getLoginUser().MedLicense) {
        //     this.medLicense = '';
        // } else {
        //     this.medLicense = UserCache.getLoginUser().MedLicense;
        // }

        if (clinic.Phone == undefined || clinic.Phone.length == 0) {
            this.clinicPhone = '';
        } else {
            this.clinicPhone = clinic.Phone;
        }
        this.clinicAddr = this.reportType == '1' ? ((clinic.City ?? '') + (clinic.Area ?? '') + (clinic.Street ?? '')) : (clinic.EAddress ? clinic.EAddress : '');

        this.CCs = data.dxm.CC + (this.reportType == '1' ? '\r\n--以下空白--' : '\r\n--The space below left blank--');
        this.dxName = data.dxm.DxName + (this.reportType == '1' ? '\r\n--以下空白--' : '\r\n--The space below left blank--');

        var days = 0;
        var times = data.dxds.length;
        var oRegDate: string = '';
        var nRegDate: string;
        var startRegDate: Date;
        var endRegDate: Date;
        this.dates = '';
        data.dxds.forEach(x => {
            var rDate = new Date(x.RegDate);
            if (!startRegDate) startRegDate = rDate;
            if (!endRegDate) endRegDate = rDate;
            startRegDate = startRegDate < rDate ? startRegDate : rDate;
            endRegDate = rDate > endRegDate ? rDate : endRegDate;

            nRegDate = rDate.getFullYear().toString() + '/' + (rDate.getMonth() + 1).toString() + '/' + rDate.getDate().toString();
            if (oRegDate != nRegDate) {
                if (this.reportType == '2') {
                    this.dates += nRegDate + ', ';
                } else {
                    this.dates += DateHelper.formatROCDate(nRegDate, true, false) + ', ';
                }
                days++;
                oRegDate = nRegDate;
            }
        });
        this.dates = this.dates.substring(0, this.dates.length - 2);  // Remove the comma of the last
        if (setting.DxCertRemark != '') this.dexcertRemark = setting.DxCertRemark;
        if (setting.Job != '') this.patientJob = setting.Job;
        if (setting.PrintName != '') this.patientName = setting.PrintName;
        if (this.reportType == '2') {  // 英文
            if (setting.EName != '') this.patientName = setting.EName;
            if (setting.EAddress != '') this.patientAddress = setting.EAddress;
            this.patientBirthCity = this.cities.find(x => x.value == this.patientCId.substring(0, 1)).extension;
            var sDate = new Date(startRegDate).getFullYear().toString() + '/' + (new Date(startRegDate).getMonth() + 1).toString() + '/' + new Date(startRegDate).getDate().toString();
            var eDate = new Date(endRegDate).getFullYear().toString() + '/' + (new Date(endRegDate).getMonth() + 1).toString() + '/' + new Date(endRegDate).getDate().toString();
            this.diagnosisDate = sDate + '—' + eDate;
        } else {
            this.patientBirthCity = this.cities.find(x => x.value == this.patientCId.substring(0, 1)).text;
            this.diagnosisDate = '門診' + DateHelper.formatROCDate(startRegDate, true, false) + '至'
            + DateHelper.formatROCDate(endRegDate, true, false) + '止，共計' + days + '天' + times + '次';
        }
        if (setting.BirthCity != '') this.patientBirthCity = setting.BirthCity;

        // 更新基資
        patData.DxCertRemark = setting.DxCertRemark;
        patData.Job = setting.Job;
        patData.PrintName = setting.PrintName;
        patData.EName = setting.EName;
        patData.EAddress = setting.EAddress;
        var ret = await this.patientApi.Update(patData);
    }
}
