import { Injectable } from "@angular/core";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { QueryParam } from "../queryParam";
import { RxListDto } from "./rx-list-dto";
import { Rx } from "./rx";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { MainSetDto } from "src/app/services/api-service/rx/main-set-dto";
import { MainSetView } from "./main-set-view";
 

@Injectable({providedIn:'root'})
export class MainSetApi{
    apiService: WebApiService;
    /**
     *
     */
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('opd/MainSet');
        this.apiService.enableLoader = false;
    }
    
    async Get(id:number): Promise<MainSetDto>{
        return this.apiService.get(`Get/`+id).toPromise<MainSetDto>();
    }

    async Create(mainset:MainSetDto):Promise<MainSetDto>{
        return this.apiService.post(`Create`,mainset).toPromise<MainSetDto>();
    }
    async Update(mainset:MainSetDto):Promise<MainSetDto>{
        return this.apiService.put(`Update`,mainset).toPromise<MainSetDto>();
    }
    /** 檢查是否有套餐代碼重複 */
    async CheckMainSetCode(code: string): Promise<{ newCode: string, exist: MainSetView }> {
        var ret = await this.apiService.get('CheckMainSetCode?code=' + code, null).toPromise<{ newCode: string, exist: any }>();
        return ret;
    }
    /** 取得注音轉換的代碼 */
    async GetMainSetCode(name: string): Promise<string> {
        var ret = await this.apiService.get('GetMainSetCode?name=' + name, null).toPromise<{ code: string }>();
        return ret?.code || '';
    }
    /** 取得套餐檢視清單(門診組合用) */
    async GetMainSetViews(query:GetMainSetViewQuery):Promise<PageData2<MainSetView>>{
        var ret = await this.apiService.get('GetMainSetViews',query).toPromise<PageData2<MainSetView>>();
        return ret;
    }
    /** 取得組合套餐資料檔列印(報表) */
    async GetMainSetReport(startName:string, endName:string): Promise<MainSetView[]>{
        var ret = await this.apiService.get('GetMainSetReport', {startName: startName, endName: endName }).toPromise<MainSetView[]>();
        return ret;
    }
  
}

export interface GetMainSetViewQuery{
    code:string;
    name:string;
    dx:string;
    isClinic:boolean;
    pageSkip:number;
    pageSize:number;
}