import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { NonPaymentDetailDto } from 'src/app/val-addapp/model/indicator-list';

@Component({
  selector: 'app-report-indicator-detail',
  templateUrl: './report-indicator-detail.component.html',
  styleUrls: ['./report-indicator-detail.component.css']
})
export class ReportIndicatorDetailComponent implements OnInit, AfterViewInit {

  reportName = '指標明細';
  forExcel = false;
  //當一隻程式有多張報表時，要將命名區別，否則會互相影響。
  containerId = 'detail-container';
  contentId = 'detail-content';

  sizePage = {
    // width: 20, //cm
    // height: 28.7 //cm
    width: 28.7, //cm
    height: 20.7 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  _sDate: Date;
  _eDate: Date;
  excelName: string;
  // visitsReport: VisitsReport;  //報表表頭資料
  listItems: NonPaymentDetailDto[] = [];

  constructor(
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService,
  ) {

  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  async fetchData(query: any, data: NonPaymentDetailDto[], forExcel = false) {
    this.forExcel = forExcel;

    this.excelName = this.reportName + query.functionName;
    this.elContainer = document.getElementById(this.containerId);
    //此報表沒表頭
    // this.visitsReport = new VisitsReport();

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    this._sDate = DateHelper.today;
    this._eDate = DateHelper.today;

    //資料改由外部傳入

    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData();
  }

  insertListData() {
    //因為此報表只做匯出Excel, 沒表頭, 沒跳頁, 沒加總, 改成只有明細。以forExcel判斷。

    // debugger
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭 (excel只用到table)
    var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var html_finish = this.forExcel ? '' : this.createHTMLFinish();              // 報表結束 -- 以下空白 (excel沒用到)
    var html_total = this.forExcel ? '' : this.createHTMLTotal();             //  (excel沒用到)
    var html_blank = this.forExcel ? '' : this.createHTMLBlankLine();         //  (excel沒用到)
    var elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i]);
      elTableContent.innerHTML += html_Block;

      // (excel沒用到)
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (!this.forExcel && elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // (excel沒用到)
      // 最後一筆要加上總計
      if (!this.forExcel && i == this.listItems.length-1) {
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // (excel沒用到)
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length-1)) {
      if (!this.forExcel && !(i == this.listItems.length-1)) {
        afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-" + this.contentId);
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-" + this.contentId);
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 20px;
          }
          .excel-format-text {
            font-size: 20px;
            mso-number-format: "@";
          }
        </style>`;
    }
    page += `
      <div class="page" id="page-${iPage}"
                style="
                height: ${this.sizePage.height}cm;
                width: ${this.sizePage.width}cm;
                padding-top: ${this.paddingPage.top}cm;
                padding-right: ${this.paddingPage.right}cm;
                padding-bottom: ${this.paddingPage.bottom}cm;
                padding-left: ${this.paddingPage.left}cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-${iPage}-${this.contentId}">
                </div>
            </div>`;
    return page;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="11" style="height: 46px; border-right: none; border-left: none; border-bottom: none; border-top: none;">

              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    var commonStyle = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;';
    var commonClass = this.forExcel ? 'class="excel-format"' : '';
    return `<tr>
              <th style="text-align: left;  width: 12%;  ${commonStyle}" ${commonClass}>病歷號</th>
              <th style="text-align: left;  width: 10%;  ${commonStyle}" ${commonClass}>姓名</th>
              <th style="text-align: left;  width: 10%;  ${commonStyle}" ${commonClass}>生日</th>
              <th style="text-align: left;  width: 10%;  ${commonStyle}" ${commonClass}>就醫日期</th>
              <th style="text-align: left;  width: 10%;  ${commonStyle}" ${commonClass}>就醫時間</th>
              <th style="text-align: left;  width: 12%;  ${commonStyle}" ${commonClass}>藥品代號</th>
              <th style="text-align: left;  width: 36%;  ${commonStyle}" ${commonClass}>名稱</th>
            </tr>`;
  }

  createHTMLBlock(data: NonPaymentDetailDto) {
    var summaryStyle = "border-top: none;";
    var showSummaryName = '';
    var roomCode = '';
    var showDate = '';
    var visitorSummary = '';

    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClass = this.forExcel ? 'class="excel-format"' : '';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var dataBirthday = data.Birthday ? DateHelper.getROCFullDateString(new Date(data.Birthday)) : '';
    var dataMedDate = data.MedDate ? DateHelper.getROCFullDateString(new Date(data.MedDate)) : '';
    var dataMedTime = data.MedTime ? DateHelper.getTimeString(new Date(data.MedDate), '', true) : '';
    return `
      <tr>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CName ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${dataBirthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${dataMedDate}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${dataMedTime}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.RxCode ?? ''}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ProdName ?? ''}</td>
      </tr>`;
  }

  createHTMLTotal() {
    // return `<tr border="0">
    //           <td style="text-align: left; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             總計
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalFirstVisitPatients + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalRevisitPatients + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalPatientsInMorning + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalPatientsInAfternoon + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalPatientsInEvening + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalPaidByHealthCare + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + this.totalPaidBySelf + `
    //           </td>
    //           <td style="text-align: right; font-weight: bold; border-top: 1px dotted #777;padding-top: 0.6rem;">
    //             ` + (this.totalFirstVisitPatients + this.totalRevisitPatients) + `
    //           </td>
    //         </tr>`;
    return '';
  }

  //for excel 前面div沒用到, 只留table。
  createHTMLPageHeader(iPage) {
    var pageHeader = '';
    if (!this.forExcel) {
      pageHeader += `
        <div id="header" style="margin: 0px 10px;">
          <div style="display: flex;column-gap: 5px;">
            <table style="width: 100%; text-align: center; border: none;">
              <tr>
                <td colspan="11" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                ` + this.reportName + `
                </td>
              </tr>
              <tr>
                  <td colspan="3" style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表日期 ` + this.nowStr + `
                  </td>
                  <td colspan="5" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  期間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                  </td>
                  <td colspan="3" style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  總筆數 ` + this.totalRecord + `
                  </td>
              </tr>
              <tr>
                  <td colspan="3" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  印表時間 ` + this.timeStr + `
                  </td>
                  <td colspan="5" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                  </td>
                  <td colspan="3" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  頁 ` + (iPage + 1) + `
                  </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
          <label style="font-weight: bold; height: 23px;">　</label>
        </div>`;
    }
    pageHeader += `
      <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-${iPage}-${this.contentId}" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
          </table>
        </div>
      </div>`;
    return pageHeader;
  }

  createHTMLFinish() {
    // return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
    //             <label style="font-weight: bold;">以下空白</label>
    //         </div>`;
    return '';
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      //去除邊框, 實際印出到pdf
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
      html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(this.reportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const table = this.reportContainer.nativeElement;

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      await this.hcrService.ExportTableToExcel(this.excelName, html);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;
  }

}
