import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { FormBuilder } from '@angular/forms';
import { HelperId } from '../models/helper-id';
import { HelperTextareaComponent } from './helper-textarea.component';
import { HistHelperPadService } from '../services/hist-helper-pad.service';
import { HistService } from '../services/hist.service';

@Component({
  selector: 'app-hist-helper-textarea-chi-bian-zheng',
  templateUrl: './helper-textarea.component.html',
  styleUrls: ['./helper-textarea.component.css', '../styles/hist.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelperTextareaChiBianZhengComponent extends HelperTextareaComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public fb: FormBuilder,
    public helperService: HistHelperService,
    public padService: HistHelperPadService,
    protected histService:HistService,
    public cd: ChangeDetectorRef)  {
    super(fb, helperService, padService,histService, cd);
  }

  areaId = HelperId.ChiBianZhengArea;
  elemId = HelperId.ChiBianZheng;
  sourceType: HelperSourceEnum = HelperSourceEnum.ChiBianZheng;
  
  ngOnInit() {
    super.ngOnInit();
  }

  //#region after view init
  ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  //#endregion

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
