<div class="card-body no-padding watch-list" [style.padding]="'5px'" [style.width]="width">
  <div class="grid-title">{{title}}</div>
  <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true" tabindex="0"
    (pageChange)="pageChange($event)" [kendoGridSelectBy]="'Id'" [tableFullWidth]="'w-100'" [showStickyHead]="true"
    [showStickyHeight]="560" [class]="'no-border'" [customClass]="'overflow-x-hidden'" #reserveList>

    <app-vis-grid-column title="掛" [width]="28" class="text-center">
      <ng-template let-dataItem>
        <button (click)="onBtnCheckInClick(dataItem)" i18n="@@RegListConsult" style="width: 100%;"
          class="vis-combine-icon cursor-pointer icon-hover mat-icon-20 no-background center-icon" title="掛入">
          <mat-icon>how_to_reg</mat-icon>
        </button>
      </ng-template>
    </app-vis-grid-column>


    <app-vis-grid-column [sortable]="true" field="PatientNo" [CellStyle]="{direction:'rtl'}" title="病歷號" [width]="75">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientName" title="病患姓名" [width]="70">
      <ng-template let-data>
        <div class="ellipsis-content" [ngClass]="data.PatientSex == '女' ? 'vis-color-purple' : ''"
          title="{{data.PatientName}}">
          {{data.PatientName}}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column field="PatientSex" title="性別" [width]="45" class="text-center custom-m-auto">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientAge" title="年齡" [width]="40" class="text-center custom-m-auto">
      <ng-template let-data>
        <div class="ellipsis-content" title="{{data.PatientBirth| realage}}">
          {{data.PatientBirth| realage}}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="ReserveMatters" title="預約事項" [width]="80"
      i18n-title="@@ReserveMatters">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="EstimatedTime" title="估" [width]="35" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="ReserveDateTime" title="時間" [width]="75" class="text-center">
      <ng-template let-data>
        <div class="ellipsis-content" [title]="data.ReserveDateTime | formatTime:':'">
          {{data.ReserveDateTime | formatTime:':' }}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="DoctorName" title="醫師" [width]="70"></app-vis-grid-column>
    <app-vis-grid-column [sortable]="false" field="PatientPhone" title="電話" [width]="70"></app-vis-grid-column>
  </app-vis-grid>
</div>
