<div style="display: flex;background-color: inherit;">
  <div class="app-v-dateinput v-control" [class.v-disabled]="disabled||_disabled"
        style=" display: flex;;padding: 1px 2px 1px 2px;width: 100%;line-height: 21px;margin-top: 0px;font-size: 14px;">
      <input type="tel"
          autocomplete="off"
          [disabled]="disabled||_disabled"
          pattern="[0-9]{1}"
          class="border-0 text-center"
          style="max-width: 37px; "
          maxlength="2"
          max="24"
          min="00"
          placeholder="時"
          (keyup)="inputUp($event, 'min')"
          (keydown)="inputDown($event, null, 'min')"
          (blur)="onblur($event)"
          (focus)="onfocus($event)"
          #hour />
      <span style="color: #aaaaaa;">：</span>
      <input type="tel"
          autocomplete="off"
          [disabled]="disabled||_disabled"
          class="border-0"
          style="max-width: 20px;"
          maxlength="2"
          placeholder="分"
          max="59"
          min="00"
          (keyup)="inputUp($event, 'sec')"
          (keydown)="inputDown($event, 'hour', 'sec')"
          (blur)="onblur($event)"
          (focus)="onfocus($event)"
          #min />
      <span style="color: #aaaaaa;" [ngStyle]="{display:showSecond?'block':'none'}">：</span>
      <input type="tel" [ngStyle]="{display:showSecond?'block':'none'}"
          autocomplete="off"
          [disabled]="disabled||_disabled"
          class="border-0"
          style="max-width: 20px;"
          maxlength="2"
          max="59"
          min="00"
          placeholder="秒"
          (keyup)="inputUp($event, null)"
          (keydown)="inputDown($event, 'min', null)"
          (blur)="onblur($event)"
          (focus)="onfocus($event)"
          #sec />
  </div>
  <button type="button" [matMenuTriggerFor]="menuTime" [matMenuTriggerData]="{hour:hour.value,min:min.value,sec:sec.value}" tabindex="-1" 
        [attr.disabled]="disabled||null"
        (onMenuOpen)="updateWidth();" [disabled]="disabled" 
        style=" height: 25px;width: 25px; font-size: 10px;line-height: 26px;padding: 0px;" #menuTrigger>
        <mat-icon style="font-size: 16px;margin-left: -1px;margin-top: 2px;">access_time</mat-icon>
  </button>

  <mat-menu [xPosition]="'before'" class="vis-comp" #menuTime="matMenu" #menu>
      <ng-template matMenuContent >
        <div class="input-menu d-flex" style="height: 9.66rem;" (click)="$event.stopPropagation()"
              [ngStyle]="menuContainerStyle">
              <div class="scroll-picker" #hourPicker>
                    <button mat-menu-item 
                      type="button"
                      *ngFor="let opt of optHour"
                      class="time-btn"
                      [class.selected]="opt==hour.value"
                      (click)="setHour(opt)">
                      <span style="text-align: right;">{{opt}}</span>
                    </button>
              </div>
              <div class="scroll-picker" #minPicker>
                    <button mat-menu-item
                      type="button" *ngFor="let opt of optMin"
                      class="time-btn"
                      [class.selected]="opt==min.value"
                      (click)="setMin(opt)">
                      <span style="text-align: right;">{{opt}}</span>
                    </button>
              </div>
              <div class="scroll-picker" #secPicker [ngStyle]="{display:showSecond?'block':'none'}">
                    <button mat-menu-item 
                      type="button" *ngFor="let opt of optSec"
                      class="time-btn"
                      [class.selected]="opt==sec.value"
                      (click)="setSec(opt)">
                      <span style="text-align: right;">{{opt}}</span>
                    </button>
              </div>
        </div>
      </ng-template>
  </mat-menu>

</div>
