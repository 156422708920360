import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { resolve } from 'dns';
import { promise } from 'protractor';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'app-referral-form',
  templateUrl: './referral-form.component.html',
  styleUrls: ['./referral-form.component.css']
})
export class ReferralFormComponent implements OnInit {
  referrals: string[];
  printContent: string;
  referralDetails: Promise<any>[];

  constructor(route: ActivatedRoute,
              private printService: PrintService) {
    this.printContent = this.printService.printContent;
  }

  ngOnInit() {
    Promise.all(this.printContent)
      .then(() => this.printService.onDataReady());
  }

  getPrintContentDetail() {
    const amount = this.printContent.length;
    return new Promise(resolve =>
      setTimeout(() => resolve({amount}), 1000)
    );
  }
}

