export class HistPrintOperationDataDto {
  HistoryId: number;
  PatientId: number;
  PatientNo: string;
  CId: string;
  CName: string;
  Sex: number;
  SexName: string;
  Birthday: Date;
  CellPhone: string;
  Address: string;
  PatientRemark: string;
  Phone: string;
  PhoneArea: string;
  ResZipCode: string;
  ResCity: string;
  ResArea: string;
  ResStreet: string;
  ZipCode: string;
  City: string;
  Area: string;
  Street: string;
  DrugAllergy: string;
  BlType: number;
  BlRH: number;
  JobCode: string;
  EducationCode: string;
  FirstDate: Date;

  DeclareId: number;
  Month?: Date;
  Date?: Date;
  DateOnly?: Date;
  PatientNoAndName: string;
  DrID: string;
  DoctorName: string;
  PharID: string;
  PharmacistName: string;
  Isqno: string;
  CusNo: string;
  CusTot: string;
  CntPresNo: string;
  CntPresTot: string;
  ICode: string;
  DxName: string[];
  AllDxName: string;
  CC: string;
  PE: string;
  Dept: string;
  Case: string;
  DeclareDays?: number;
  DispTP: string;
  ADx: number;
  ARx: number;
  ATx: number;
  AMed: number;
  ADcl: number;
  FeeReg: number;
  APartRx: number;
  APartOPD: number;
  APartExam: number;
  AOwn: number;
  Height?: number;
  Weight?: number;
  BT?: number;
  SBP?: number;
  DBP?: number;
  BMI?: number;
  RegisterRemark: string;

  NHICode: string;
  ClinicName: string;
  ClinicPhone: string;
  ClinicAddress: string;
}

export class HistPrintOperationDataItemDto {
  HistoryId: number;
  HRxId: number;
  Month?: Date;
  Date?: Date;
  SPRule: number;
  RxCode: string;
  StdCode: string;
  ProdName: string;
  GXName: string;
  IIName: string;
  SrxProdName: string;
  QtyRule: number;
  QTY: number;
  Unit: string;
  Freq: string;
  FreqName: string;
  Days: number;
  TotalDose?: number;
  Way: string;
  Remark: string;
  IPrice?: number;
}

/**
 * 濃縮病歷表
 * 一頁是印一個病人+(一到多個)病歷及醫令。
 * 每筆資料都是展開到每筆明細(病人+病歷+醫令)，以便控制跳頁。
 */
export class HistPrintCompressDataDto {
  Master: HistPrintOperationDataDto;
  Detail: HistPrintOperationDataItemDto;
  IsMasterFirst: boolean;
  IsDetailFirst: boolean;
  IsMasterLast: boolean;
  IsSummary: boolean;
  SummaryAmount: number;
}

/**
 * 原紙張病歷表
 * 一頁分左右兩邊格式有一點差異，都是印同一個病人+(一個)病歷及醫令。
 * 每筆資料是(病人+病歷)及明細清單(醫令[])，以便控制跳頁。
 */
export class HistPrintOriginalDataDto {
  Master: HistPrintOperationDataDto;
  Items: HistPrintOperationDataItemDto[];
  CombineItems: HistPrintOperationDataItemDto[];
  IsMasterFirst: boolean;
  IsSummary: boolean;
  SummaryAmount: number;
  SequenceNumber: number;
}

/**
 * 原紙張病歷表(左右聯不同人)
 * 一頁分左右兩邊，不同人的病歷及醫令。
 */
export class HistPrintDiffPersonDataDto {
  MasterLeft: HistPrintOperationDataDto;
  ItemsLeft: HistPrintOperationDataItemDto[];
  CombineLeft: HistPrintOperationDataItemDto[];
  MasterRight: HistPrintOperationDataDto;
  ItemsRight: HistPrintOperationDataItemDto[];
  CombineRight: HistPrintOperationDataItemDto[];
  IsMasterFirst: boolean;
  IsMasterFirstLeft: boolean;
  IsMasterFirstRight: boolean;
  IsSummary: boolean;
  SummaryAmount: number;
}
