import { Injectable } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { SalesOrderCommandDTO, SalesOrderDetailDTO, SalesOrderListDTO, SalesOrderPatientSumAmountDTO, SalesOrderQuery, SalesOrderRecepipt } from './sales-order-api.model';
import { PageData2 } from 'src/app/shared/models/page-data';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { StandardErrorMessage } from 'src/app/shared/components/base/base.edit.component';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('sales/SalesOrder');
    this.apiService.enableLoader = false;
  }

  async GetSalesOrderList(param: SalesOrderQuery): Promise<PageData2<SalesOrderListDTO>> {
    var query = Object.assign({}, param) as any;
    if (param.BeginSalesDateTime) {
      query.BeginSalesDateTime = DateHelper.getDate(param.BeginSalesDateTime).toLocaleDateString();
    }
    if (param.EndSalesDateTime) {
      query.EndSalesDateTime = DateHelper.getDate(param.EndSalesDateTime).toLocaleDateString();
    }
    return this.apiService.get('GetSalesOrderList', query).toPromise<PageData2<SalesOrderListDTO>>();
  }

  async GetSalesOrderDeatil(id: number): Promise<SalesOrderDetailDTO> {
    return this.apiService.get(`GetSalesOrderDeatil?id=${id}`).toPromise<SalesOrderDetailDTO>();
  }

  async CreateSalesOrder(param: SalesOrderCommandDTO): Promise<CustomResult> {
    return this.apiService.post('CreateSalesOrder', param).toPromise<CustomResult>();
  }

  async UpdateSalesOrder(param: SalesOrderCommandDTO): Promise<CustomResult> {
    return this.apiService.post('UpdateSalesOrder', param).toPromise<CustomResult>();
  }

  async GetSalesOrderReceipt(id: number): Promise<SalesOrderRecepipt> {
    return this.apiService.get(`GetSalesOrderReceipt?id=${id}`).toPromise<SalesOrderRecepipt>();
  }

  async GetSalesOrderPatientSumAmountAsync(patientId: number, beginDate: string, endDate: string): Promise<SalesOrderPatientSumAmountDTO> {
    return this.apiService.get(`GetSalesOrderPatientSumAmount?patientId=${patientId}&beginDate=${beginDate}&endDate=${endDate}`).toPromise<SalesOrderPatientSumAmountDTO>();
  }
}
