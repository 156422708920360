import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trim'
})
export class TrimPipe implements PipeTransform {

  transform(value: string,character:string, direct: 'start'|'end'|'all'): string {
    var ret = value;
    if(direct=='start' || direct == 'all'){
        while(ret.startsWith(character)){
            ret = ret.substring(character.length);
        }
    }
    if(direct=='end' || direct=='all'){
        while(ret.endsWith(character)){
            ret = ret.substring(0,ret.length - character.length);
        }
    }
    return ret;
  }

}
