import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { RegistEditOpt, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';

@Component({
  selector: 'app-history-report',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css'],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryComponent implements OnInit {

  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;
  @Input()
  set Hist(v: WholeHist) {
    this.hist = v;
    this.clinic = UserCache.getLoginUser().Clinic;
    this.cdr.markForCheck();
  }
  hist: WholeHist;
  editOptions: RegistEditOpt;
  clinic: Clinic;
  fontType: string;

  @Input()
  set regId(id) {
    this.setRegId(id);
  }

  constructor(private histApi: HistApi,
    private cdr: ChangeDetectorRef,
    private parameterApi: ParameterApi,
    private regApi: RegisterApi,
    private clinicDataService: ClinicDataService
  ) { }

  async ngOnInit() {

  }
  @Input()
  cidMask = '1234567890'
  @Input()
  bitrhMask = 'YYY年MM月DD日'
  async setRegId(regId: number) {
    this.clinic = UserCache.getLoginUser().Clinic;
    this.editOptions = await this.regApi.GetEditOptions();
    this.hist = await this.histApi.getWholeHist({ regId: regId });
    this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => x.SPRule != 5);
    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;
    this.bitrhMask = param.BirthdayHidePRN ?? this.bitrhMask;
    this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
    this.cdr.markForCheck();
  }

  getHtml(): string {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }

  dxs() {
    var h = this.hist.Hist;
    return [h.Dx1, h.Dx2, h.Dx3, h.Dx4, h.Dx5, h.Dx6].filter(x => x).join(',')
  }

  getMedDeptName(src: string): string {
    let result = src;
    if (this.editOptions) {
      let medDep = this.editOptions.section.find(x => x.value == src);
      if (medDep) {
        return medDep.text;
      }
    }
    return result;
  }

}
