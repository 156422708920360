import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { toothTreatPosDto } from '../models/tooth-treat-position';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';

@Component({
    selector: 'app-tooth-pos-map',
    templateUrl: './tooth-positions-map.component.html',
    styleUrls: ['./tooth-positions-map.component.css']
})
export class ToothPositionsMapComponent implements OnInit {

    colorRed = '#FF4122';

    @Input() isHelperPadOpened?: string = '';
    @Input() toothTreatPos: toothTreatPosDto[] = [];
    @Input() test: boolean = false;
    @Output() toothPosClick = new EventEmitter<toothTreatPosDto>();

    constructor(private notification: EasyNotificationService) 
    {
    }

    ngOnInit() {
        /** 測試 */
        if (this.test) this.setToothTreatPost();
        /** end 測試 */

        this.setDefaultToothPos();
    }

    // defvalue for test
    setToothTreatPost() {
        var colorWhite = '#FFFFFF'; 
        var colorGary = '#D3D3D3' 
        for (var i = 1; i <= 8; i++) {
            if (i < 5) {  // 成人
                for (var j = 1; j <= 8; j++) {
                    for (var k = 1; k <= 6; k++) {
                        var toothPos: toothTreatPosDto = new toothTreatPosDto;
                        toothPos.unselectedColor = colorWhite;
                        toothPos.isTreat = false;
                        toothPos.selectedColor = this.colorRed;
                        switch (k) {
                            case 1:
                                toothPos.posCode = '' + i + j + 'A';
                                toothPos.unselectedColor = colorGary;
                                break;
                            case 2:
                                toothPos.posCode = '' + i + j + 'T';
                                break;
                            case 3:
                                toothPos.posCode = '' + i + j + 'L';
                                break;
                            case 4:
                                toothPos.posCode = '' + i + j + 'B';
                                break;
                            case 5:
                                toothPos.posCode = '' + i + j + 'R';
                                break;
                            case 6: 
                                toothPos.posCode = '' + i + j + 'M';
                                break;
                        }
                        this.toothTreatPos.push(toothPos);
                    }
                }
            } else {    // 乳齒
                for (var j = 1; j <= 5; j++) {
                    for (var k = 1; k <= 5; k++) {
                        var toothPos: toothTreatPosDto = new toothTreatPosDto;
                        toothPos.unselectedColor = colorWhite;
                        toothPos.isTreat = false;
                        toothPos.selectedColor = this.colorRed;
                        switch (k) {
                            case 1:
                                toothPos.posCode = '' + i + j + 'T';
                                break;
                            case 2:
                                toothPos.posCode = '' + i + j + 'L';
                                break;
                            case 3:
                                toothPos.posCode = '' + i + j + 'B';
                                break;
                            case 4:
                                toothPos.posCode = '' + i + j + 'R';
                                break;
                            case 5: 
                                toothPos.posCode = '' + i + j + 'M';
                                break;
                        }
                        this.toothTreatPos.push(toothPos);
                    }
                }
            }
        }
    }

    onSelected(selId: string) {
        // console.log('selId >>>>>', selId);
        var idx = this.toothTreatPos.findIndex(x => x.posCode == selId);
        if (idx > -1) {
            var selectedItem = this.toothTreatPos[idx];
            if (selectedItem) {
                var elm = document.getElementById(selId);
                if (!selectedItem.isTreat) {
                    elm.setAttribute("fill", this.colorRed);
                } else {
                    elm.setAttribute("fill", selectedItem.unselectedColor);
                }
                this.toothTreatPos[idx].isTreat = !selectedItem.isTreat;
                this.toothPosClick.emit(this.toothTreatPos[idx]);
            }
        }
    }

    setDefaultToothPos() {
        this.toothTreatPos.forEach((itm) => {
            var elm = document.getElementById(itm.posCode);
            var color = '';
            if (itm.isTreat) {
                color = this.colorRed;  //itm.selectedColor;
            } else {
                color = itm.unselectedColor;
            }
            elm.setAttribute("fill", color);
        });
    }
}
