import { Component, OnInit, Input, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { AuthenticationService } from 'src/app/security/services/authentication.service';
import { UserCache } from 'src/app/services/user-cache';
import { LoginMenu } from 'src/app/permission/models/login-menu';
import { BaseConfig } from 'src/app/services/base-config.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { OverlayContainer } from '@angular/cdk/overlay';
import { IBaseConfig } from 'src/app/shared/models/base-config';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { MenuItem } from 'src/app/models/MenuItem';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],

})
export class MenuComponent implements OnInit {
  public static Instance: MenuComponent;
  @Input() isVertical: boolean;
  @Input() menuItems: LoginMenu[];

  config: IBaseConfig;
  constructor(private router: Router, private overlayContainer: OverlayContainer,
    private routeInfo: RouteInfoService,
    private auth: AuthenticationService,
    private localStorage: LocalStoragesService) {
    this.config = BaseConfig.getConfig();
    MenuComponent.Instance = this;
  }

  selectedIndex = -1;

  get isUseLeftMenu(): boolean {
    if (BaseConfig.getConfig().env.menuType === 'left') {
      return true;
    } else {
      return false;
    }
  }
  get menuCssClass(): string {
    if (this.isUseLeftMenu) {
      return 'left-menu';
    } else {
      return 'top-menu';
    }
  }
  allowCloseOnClickOut() {
    this.overlayContainer.getContainerElement().classList.remove('disable-backdrop-click');
  }
  closeMenu() {
    this.triggers.forEach(item => {

      item.closeMenu();
    });
  }

  // onMenuItemClick(item: LoginMenu, index) {
  //   this.selectedIndex = index;
  //   if (!item.path) { return; }
  //   if (!item.param) { item.param = {}; }
  //   const path = this.routeInfo.makeFullUrl(item.path);
  //   this.router.navigate([path, item.param]);
  // }

  private createMenuData() {
    this.menuItems = UserCache.getLoginUser().Menus;
    // console.log('menuItems:', this.menuItems);
    // 不取Menu之首頁
    this.menuItems = this.menuItems.filter(x => x.text != '首頁');

  }

  resetMenu(){
    this.createMenuData();
  }

  @ViewChildren(MatMenuTrigger)
  triggers: QueryList<MatMenuTrigger>;
  menu: MenuItem[] = [];
  ngOnInit(): void {
    this.createMenuData();
    setTimeout(() => {
      const m = new MenuItem();
      m.children = this.menu;
      //this.eventService.fire(EventKey.MenuChanged,this.findCurrentItem(m)?.text);
    }, 100);

  }
  private findCurrentItem(menuItem: MenuItem) {
    if (menuItem.path == this.router.url) {
      return menuItem;
    } else {
      if (menuItem.children.length > 0) {
        for (let i = 0; i < menuItem.children.length; i++) {
          const childItem = this.findCurrentItem(menuItem.children[i]);
          if (childItem) {
            return childItem;
          }
        }
      }
      return null
    }
  }
  onMenuOpen(e: string, open: boolean) {
    this.triggers.forEach(item => {
      if (item.menuData != e) {
        item.closeMenu();
        if (open) {
          item.openMenu();
        }
      }
    })

  }
  async redirectTo(menuItem: MenuItem) {
    this.clearKeepWaitListData();
    var loginUser = UserCache.getLoginUser();
    var url = MenuItem.getUrl(menuItem, loginUser?.CompanyCode);
    if (this.router.url == url) {
      return
    }
    var canRedirect = await this.routeInfo.onMenuClick();
    if (canRedirect == false) {
      return;
    }
    if (menuItem.funcSystemId == 2) {
      var hcUrl = this.config.homeCare.hcUrl;
      var ssotoken = loginUser?.SSOToken;
      var parm = "?s=" + ssotoken;
      var tempUrl = url.startsWith("/") ? url.substring(1) : url;
      var go = (window as Window).location.assign(hcUrl + tempUrl + parm);
    } else {
      // this.eventService.fire(EventKey.ShowLoading,true);
      setTimeout(() => {
        //this.eventService.fire(EventKey.MenuChanged,menuItem.text);
        this.router.navigateByUrl(url);

        // 把目前掛的comp清掉
        this.routeInfo.comp = null;
      }, 0);
    }
  }

  clearKeepWaitListData() {
    var loginUserCid = UserCache.getLoginUser().CId;
    this.localStorage.setItem("WaitingList_ToRegister_" + loginUserCid, false);
    this.localStorage.setItem("WaitingList_NeedKeep_" + loginUserCid, false);
    this.localStorage.setItem("WaitingList_PageSkipNo_" + loginUserCid, 0);
    this.localStorage.setItem("WaitingList_PatientRegId_" + loginUserCid, 0);
  }
}
