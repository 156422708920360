import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportPageDTO } from 'src/app/services/api-service/declare/declare-api';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';

@Component({
  selector: 'app-declare-case-statistics',
  templateUrl: './declare-case-statistics.component.html',
  styleUrls: ['./declare-case-statistics.component.css']
})
export class DeclareCaseStatisticsComponent implements OnInit, AfterViewInit {

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
      top: -0.5, //cm
      right: 0, //cm
      bottom: 0.2, //cm
      left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;            // 整份報表的html內容
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  /** 申報月份 */
  @Input()
  set declareMonth(value) {
    if (value) {
      this.declareMonthValue = value;
      this.setDeclareMonth();
    }
  }

  /** 是否列印 */
  @Input()
  set isTestPrint(value) {
    if (value) {
      // this.onPrintAll();
      this.onPrint();
    }
  }

  @Input()
  set isExportAsExcel(value) {
    if (value) {
      this.exportAsExcel();
    }
  }

  /** 是否依醫師別分頁 */
  @Input() set isPageByDoctor(val) {
    this.isByDoctor = val;
    this.setDeclareMonth();
  }

  /** 換頁=>指定換到第幾頁 */
  @Input() set pageNum(val) {
    this.goToPage(val);
  }
  @Output()
  DataTotalPageEvent = new EventEmitter();
  @Output()
  printed = new EventEmitter<boolean>();

  upperboundIndex: number = 0;
  Data: DeclareCaseReportDTO[];
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  declareMonthValue = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
  isByDoctor = false;
  doctorName: string = '';
  dataSource: any;
  reportName: string = '申報案類金額統計表';
  shortReportName: string = this.reportName;
  forExcel: boolean = false;

  constructor(
    private declareApi: DeclareApi,
    private localPrintService: LocalPrintService,
    private hcrService:HcrService,
    private notification: EasyNotificationService
  ) {
  }

  ngOnInit() {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '#container {padding:0}\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px; line-height:1rem; padding-top:4px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '.th-btm { border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;}\n'+
      '.txt-right { text-align: right;}\n'+
      '.line-btm { border-bottom: 1px solid black !important;}\n'+
      // '.subT-btm { border-bottom: 1px dotted #777 !important;}\n'+
      '.subT-btm { border-bottom: 5px double black !important;}\n'+
      '.sum-btm { border-bottom: 1px solid black !important; padding-top: 10px;}\n'+
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  clearData() {
    if (this.elContainer)
      this.elContainer.innerHTML = '';
  }

  fillData(data: DeclareCaseReportPageDTO[]) {
    this.elContainer = document.getElementById("container");
    this.dataSource = data;
    // this.reportName = '申報案類金額統計表';
    // this.shortReportName = this.reportName;
    this.forExcel = true;
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    this.monthStr = DateHelper.getFormatedDateString(this.declareMonthValue, true, '', true);

    this.insertListData();
  }

  /** 取得申報案類金額統計 報表資料 */
  async setDeclareMonth() {
    this.elContainer = document.getElementById("container");
    this.elContainer.innerHTML = "";
    var result = await this.declareApi.GetDeclareCaseReport(UserCache.getLoginClinicId(), this.declareMonthValue, this.isByDoctor);
    this.dataSource = result;
    this.nowStr = DateHelper.getFormatedDateString(new Date(), true, '.');
    this.timeStr = DateHelper.getFormatedDateString(new Date());
    this.monthStr = DateHelper.getFormatedDateString(this.declareMonthValue, true, '', true);
    if (result != null && result.length > 0) {
      this.DataTotalPageEvent.emit({ totalPage: result.length, currentPage: 1, isPrint: false });
      this.Data = result[0].Detail;

      this.monthStr = result[0].Detail[0].Month;
      this.doctorName = result[0].DrName;
      // 將查詢的資料組成報表html
      this.insertListData();
    }
  }

  // 組報表的html內容(this.Data)
  insertListData() {
    var iPage = 0;
    var html_ListerHeader = this.createHTMLListHeader();  // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();            // 報表結束 -- 以下空白
    var prevTableContent: any;

    /** -- 組報表內容 -- this.dataSource 依醫師分頁才會有多筆，否則只會有一筆 */
    for (let x = 0; x < this.dataSource.length; x++) {
      // 建立一個新的分頁
      this.elContainer.innerHTML += this.createHTMLPage(iPage);
      var elPageContent = document.getElementById("page-" + iPage + "-content");    // 取得某頁的content
      this.doctorName = this.dataSource[x].DrName ? this.dataSource[x].DrName : this.dataSource[x].DrID;
      elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.doctorName); // [頁表頭][List欄位顯示名稱]
      var elTableContent = document.getElementById("table-" + iPage + "-content");
      elTableContent.innerHTML += html_ListerHeader;

      for(let i = 0; i < this.dataSource[x].Detail.length; i++) {
        // 每一個案類前先加入案類
        if (this.dataSource[x].Detail[i].e11cs != '總計') {
          prevTableContent = elTableContent.innerHTML;
          elTableContent.innerHTML += this.createHTMLGrouping(this.dataSource[x].Detail[i].e11cs);

          // 迴圈逐筆加入報表table
          for(let j = 0; j < this.dataSource[x].Detail[i].Detail.length; j++) {
            prevTableContent = elTableContent.innerHTML;
            elTableContent.innerHTML += this.createHTMLBlock(this.dataSource[x].Detail[i].Detail[j]);
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
              elTableContent.innerHTML = prevTableContent;
              iPage++;
              this.elContainer.innerHTML += this.createHTMLPage(iPage);
              elPageContent = document.getElementById("page-" + iPage + "-content");
              // [頁表頭][List欄位顯示名稱]
              elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.doctorName);
              elTableContent = document.getElementById("table-" + iPage + "-content");
              elTableContent.innerHTML = html_ListerHeader;
              elTableContent.innerHTML += this.createHTMLBlock(this.dataSource[x].Detail[i].Detail[j]);
            }
          }
          // 小計
          if (this.dataSource[x].Detail[i].e11cs != '總計') {
            prevTableContent = elTableContent.innerHTML;
            elTableContent.innerHTML += this.createHTMLSummary(this.dataSource[x].Detail[i]);
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
              elTableContent.innerHTML = prevTableContent;
              iPage++;
              this.elContainer.innerHTML += this.createHTMLPage(iPage);
              elPageContent = document.getElementById("page-" + iPage + "-content");
              // [頁表頭][List欄位顯示名稱]
              elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.doctorName);
              elTableContent = document.getElementById("table-" + iPage + "-content");
              elTableContent.innerHTML = html_ListerHeader;
              elTableContent.innerHTML += this.createHTMLSummary(this.dataSource[x].Detail[i]);
            }
          }
        } else { //結尾總計->可能會超出頁面
          // 總計
          prevTableContent = elTableContent.innerHTML;
          elTableContent.innerHTML += this.createHTMLTotal(this.dataSource[x].Detail[i]);
          // 判斷是否超出頁面範圍? -> 新增一頁
          if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
            elTableContent.innerHTML = prevTableContent;
            iPage++;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            elPageContent.innerHTML += this.createHTMLPageHeader(iPage, this.doctorName);
            elTableContent = document.getElementById("table-" + iPage + "-content");
            elTableContent.innerHTML = html_ListerHeader;
            elTableContent.innerHTML += this.createHTMLTotal(this.dataSource[x].Detail[i]);
          }
        }
      }
      iPage++;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `
        <style>
          .excel-format {
            font-size: 14pt;
          }
          .excel-format-text {
            font-size: 14pt;
            mso-number-format: "@";
          }
        </style>
        <div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLPageHeader(iPage, doctorName) {
    var showByDoctorName = this.isByDoctor ? '<label style="font-weight: bold; margin-bottom:0px;">醫師：<span style="color: blue;">' + doctorName + '</span></label>' : '　';
    return `<div style="margin: 0px 10px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="24" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ${this.reportName}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" style="width: 33%; font-size: 10pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    印表日期 ` + this.nowStr + `
                    </td>
                    <td colspan="12" style="width: 34%; font-size: 10pt; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    申報月份：` + this.monthStr + `
                    </td>
                    <td colspan="6" style="width: 33%; font-size: 10pt; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + showByDoctorName + `
                    </td>
                  </tr>
                  <tr>
                    <td colspan="6" style="width: 33%; font-size: 10pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    印表時間 ` + this.timeStr + `
                    </td>
                    <td colspan="12" style="width: 34%; font-size: 10pt; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                    </td>
                    <td colspan="6" style="width: 33%; font-size: 10pt; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    頁 ` + (iPage + 1) + `
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;">
            <div style="display: flex;column-gap: 5px;">
              <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: 0px solid black">
              </table>
            </div>`;
  }

  createHTMLListHeader() {
    return `<tr>
              <th
                style="width: 5%; text-align: left;" class="th-btm">
                案類
              </th>
              <th
                style="width: 2%; text-align: center;" class="th-btm">
                保別
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                診察費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                內服用
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                外用費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                注射費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                注技費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                檢查費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                放射費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                復健費
              </th>
              <th
                style="width: 5%; " class="txt-right th-btm">
                處置費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                手術費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                麻醉費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                特材費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                輸血費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                透析費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                病房費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                管灌費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                嬰兒費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                精療費
              </th>
              <th
                style="width: 3%; " class="txt-right th-btm">
                其它
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                藥事費
              </th>
              <th
                style="width: 4%; " class="txt-right th-btm">
                自付額
              </th>
              <th
                style="width: 6%; " class="txt-right th-btm">
                總額
              </th>
            </tr>`;
  }

  createHTMLGrouping(group: string) {
    return `<tr>
              <td colspan="24"
              class="excel-format-text"
              style="text-align: left;
              font-size: 14pt;
              color: red;
              font-weight: bold;
              font-style: italic;">
              ` + group + `
              </td>
            </tr>`;
  }

  createHTMLBlock(data: any) {
    return `<tr>
              <td colspan="2" style="text-align: left;" class="line-btm">` + data.ICode + data.ICodeName + `</td>
              <td class="txt-right line-btm">` + (data.ADx == 0 ? "" : data.ADx) + `</td>
              <td class="txt-right line-btm">` + (data.ARx2 == 0 ? "" : data.ARx2) + `</td>
              <td class="txt-right line-btm">` + (data.ARx3 == 0 ? "" : data.ARx3) + `</td>
              <td class="txt-right line-btm">` + (data.ARx4 == 0 ? "" : data.ARx4) + `</td>
              <td class="txt-right line-btm">` + (data.ATx5 == 0 ? "" : data.ATx5) + `</td>
              <td class="txt-right line-btm">` + (data.ATx6 == 0 ? "" : data.ATx6) + `</td>
              <td class="txt-right line-btm">` + (data.ATx7 == 0 ? "" : data.ATx7) + `</td>
              <td class="txt-right line-btm">` + (data.ATx8 == 0 ? "" : data.ATx8) + `</td>
              <td class="txt-right line-btm">` + (data.ATx9 == 0 ? "" : data.ATx9) + `</td>
              <td class="txt-right line-btm">` + (data.ATx10 == 0 ? "" : data.ATx10) + `</td>
              <td class="txt-right line-btm">` + (data.ATx11 == 0 ? "" : data.ATx11) + `</td>
              <td class="txt-right line-btm">` + (data.AMat == 0 ? "" : data.AMat) + `</td>
              <td class="txt-right line-btm">` + (data.ATx13 == 0 ? "" : data.ATx13) + `</td>
              <td class="txt-right line-btm">` + (data.ATx14 == 0 ? "" : data.ATx14) + `</td>
              <td class="txt-right line-btm">` + (data.ATx15 == 0 ? "" : data.ATx15) + `</td>
              <td class="txt-right line-btm">` + (data.ATx16 == 0 ? "" : data.ATx16) + `</td>
              <td class="txt-right line-btm">` + (data.ATx17 == 0 ? "" : data.ATx17) + `</td>
              <td class="txt-right line-btm">` + (data.ATx18 == 0 ? "" : data.ATx18) + `</td>
              <td class="txt-right line-btm">` + ((data.Aother1 + data.Aother2) == 0 ? "" : (data.Aother1 + data.Aother2)) + `</td>
              <td class="txt-right line-btm">` + (data.AMed == 0 ? "" : data.AMed) + `</td>
              <td class="txt-right line-btm">` + (data.e1198 == 0 ? "" : (-data.e1198)) + `</td>
              <td class="txt-right line-btm">` + data.e11ta + `</td>
            </tr>`;
  }

  createHTMLSummary(data: any) {
    return `<tr>
              <td colspan="2" style="text-align: left;" class="subT-btm">小計：</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ADx + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ARx2 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ARx3 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ARx4 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx5 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx6 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx7 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx8 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx9 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx10 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx11 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.AMat + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx13 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx14 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx15 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx16 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx17 + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.ATx18 + `</td>
              <td class="txt-right subT-btm">` + (data.Subtotal.Aother1 + data.Subtotal.Aother2) + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.AMed + `</td>
              <td class="txt-right subT-btm">` + (-data.Subtotal.e1198) + `</td>
              <td class="txt-right subT-btm">` + data.Subtotal.e11ta + `</td>
            </tr>`;
  }

  createHTMLTotal(data: any) {
    return `<tr>
              <td colspan="2" style="font-size: 14pt; font-weight: bold; text-align: left;" class="sum-btm">總計：</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ADx + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ARx2 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ARx3 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ARx4 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx5 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx6 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx7 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx8 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx9 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx10 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx11 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.AMat + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx13 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx14 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx15 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx16 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx17 + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.ATx18 + `</td>
              <td class="txt-right sum-btm">` + (data.Subtotal.Aother1 + data.Subtotal.Aother2) + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.AMed + `</td>
              <td class="txt-right sum-btm">` + (-data.Subtotal.e1198) + `</td>
              <td class="txt-right sum-btm">` + data.Subtotal.e11ta + `</td>
            </tr>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 0px double black;">
              <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  /** 多Page換頁 */
  goToPage(val) {
    var result = this.dataSource;
    if (result != null && result.length > 0) {
      let idx = val - 1;
      idx = idx > 0 ? idx : 0;
      this.Data = result[idx].Detail;
      this.doctorName = result[idx].DrName;
    }
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
    }
    return html;
  }

  /** 單張列印 */
  async onPrint() {
    if (this.dataSource.length > 0) {
      var data = this.dataSource;
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.shortReportName, printContents);  // 'Declare Case Statistics'
      if (ret.Successed) {
        this.notification.showSuccess(this.shortReportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.shortReportName + '送出列印失敗!');
      }
      setTimeout(() => {
        this.DataTotalPageEvent.emit({ totalPage: data.length, currentPage: data.length, isPrint: false });
        this.printed.emit(true);
      }, 1000);
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    const reportName = this.shortReportName;

    if (this.dataSource.length > 0) {
        const table = this.reportContainer.nativeElement;
        await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;

    this.printed.emit(true);
  }

  /** 多頁列印 */
  async onPrintAll() {
    if (this.dataSource.length > 0) {
      var data = this.dataSource;
      var h = '';
      for (let index = 0; index < data.length; index++) {
        var rs;
        var p = new Promise((a, b) => { rs = a; });
        this.goToPage(index+1);
        setTimeout(() => {
          h += this.getHtml();
          rs();
        }, 0);
        await p;
      }
      var ret = await this.localPrintService.printHtml(444, 'Declare Case Statistics', h);
      if (ret.Successed) {
        this.notification.showSuccess('申報案類金額統計表 已送出列印!', true);
      } else {
        this.notification.showError('申報案類金額統計表 送出列印失敗!');
      }
      setTimeout(() => {
        this.DataTotalPageEvent.emit({ totalPage: data.length, currentPage: data.length, isPrint: false });
      }, 1000);
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }
}
