<form [formGroup]="editDentalFG" *ngIf="editDentalFG" class="vis-form	vis-patients-edit overflow-x-auto position-relative" style="height:inherit;overflow-y: scroll;">
    <!-- ./ chi-top -->
    <div formArrayName="orders" *ngIf="orderArray" [class]="layoutCssClass" [id]="areaId" class="position-relative" [ngStyle]="{width:tableWidth}">
      <!-- default grid -->
      <table [class]="'odr-tb'" class="position-relative w-100">
        <thead style="z-index: 990;" class="top-0 position-sticky">
          <tr>
            <ng-container>
              <th class="name text-center" style="cursor: pointer;" title="牙位輸入">牙位輸入</th>
              <th class="unit text-center" style="cursor: pointer;" title="處置">處置</th>
              <th class="code text-center" style="cursor: pointer;" title="處置名">處置名稱</th>
              <th class="unit text-center" style="cursor: pointer;" title="牙面">牙面</th>
              <th class="unit text-center" style="cursor: pointer;" title="PCS">PCS</th>
              <th class="unit text-center" style="cursor: pointer;" title="療卡">療卡</th>
              <th class="date text-center" style="cursor: pointer;" title="療程開始日">療程開始日</th>
              <th class="unit text-center" style="cursor: pointer;" title="加成">加成</th>
              <th class="unit text-center" style="cursor: pointer;" title="單價">單價</th>
              <th class="code text-center" style="cursor: pointer;" title="小計">小計</th>
              <th class="name text-center" style="cursor: pointer;" title="代檢院所">代檢院所</th>
            </ng-container>
          </tr>
        </thead>
        <tbody cdkDropList (cdkDropListDropped)="drop($event)">
          <ng-container *ngFor="let odr of orderArray.controls; let idx = index" [formGroupName]="idx">
            <tr [ngStyle]="{'cursor' : dragable ? 'grab' : 'default'}" [cdkDragDisabled]="dragDisabled" cdkDrag>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div style="display: flex" class="extend">
                  <input type="tel" formControlName="SPRule" class="odr-cell" />
                  <button type="button" (mousedown)="deleteRowAt(idx)" class="title-btn2" [attr.disabled]="checkDisabled()">刪</button>
                </div>
              </td>
              <td class="position-relative">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="RxCode" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative">
                <div style="display: flex;" class="extend">
                  <input formControlName="ProdName" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="QTY" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="Freq" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative"  *ngIf="!fillExecuteOnly">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="Days" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div class="extend" style="display: flex;">
                  <input type="tel" formControlName="TotalDose" class="odr-cell" />
                  <span></span>
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div class="extend" style="display: flex;">
                  <input type="tel" formControlName="Dose" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div style="display: flex;" class="extend">
                  <input type="tel" formControlName="Way" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative"  *ngIf="!fillExecuteOnly">
                <div class="extend" style="display: flex;">
                  <input type="tel" formControlName="TotalBox" class="odr-cell" />
                </div>
              </td>
              <td class="position-relative" *ngIf="!fillExecuteOnly">
                <div type="tel" class="extend" style="display: flex;">
                  <input class="odr-cell" formControlName="BoxUnit" class="odr-cell" />
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!--formArrayName-->
  </form>
  
  <!-- 
  <div class="batch-edit-mask" [ngStyle]="styleLeftMask" (click)="closeBatch()"></div>
  <div class="batch-edit-mask" [ngStyle]="styleRightMask" (click)="closeBatch()">
    <div style="z-index: 1002;position: absolute;top:5px; left: 5px; min-width: 120px;max-width: 200px; background-color: rgba(255,255,255,0.8);border-radius: 5px;padding: 5px;"
    (click)="$event.stopPropagation()">
      <div class="position-relative text-left">
        <button
          type="button"
          (click)="closeBatch()"
          i18n="@@DialogClose"
          style="top: -0.8rem;right: -0.8rem;z-index: 995;"
          class="position-absolute vis-btn-config p-0 m-0 w-auto h-auto background-white border-0">
          <mat-icon class="mat-icon notranslate position-relative float-left d-inline-flex material-icons mat-icon-no-color" title="離開[Esc]">cancel</mat-icon>
        </button>
      </div>
      <div>批次修改：</div>
      <div style="display: flex;flex-wrap: wrap;column-gap:10px">
        <ng-container *ngFor="let item of batchRxtype" >
          <mat-checkbox  style="width: calc(50% - 5px);" (change)="batchRxTypeCheckChange($event,item.value)">{{item.text}}</mat-checkbox>
        </ng-container>
      </div>
      <div style="display: flex;">
        <input style="min-width: 0;flex-grow: 1;height: 25px;" (keyup)="batchInput($event)" #data>
        <button class="px-0" type="button" style="height: 25px;padding: 0px;min-width: 30px;" (click)="saveBatch(data.value)">
          <mat-icon style="font-size: 15px;line-height: 15px;height: 15px;vertical-align: middle;">keyboard_backspace</mat-icon>
        </button>
      </div>
      <div style="display: flex;flex-flow: row wrap;max-height: 200px;overflow-y: scroll;">
        <ng-container *ngFor="let item of batchOpt">
          <button class="px-0" type="button" style="min-width: 50%; max-height: unset;" (click)="saveBatch(item.value)">{{item.text}}</button>
        </ng-container>
      </div>
    </div>
  </div> -->
  <!-- <div style="display: block; height: 100%;background-color: red;" >
  
  </div> -->
  <!--
  <ng-template let-data #rxDetail>
      <table class="rx-detail w-100 mat-table">
        <tbody>
          <tr>
            <th class="mat-header-cell w-40">標準處方代碼</th>
            <td class="mat-cell"><div class="extend-content">{{data.StdCode}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">歸屬</th>
            <td class="mat-cell"><div class="extend-content">{{data.Type}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">原瓶藥水註記</th>
            <td class="mat-cell"><div class="extend-content">{{data.SyrupNote}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">ATC代碼</th>
            <td class="mat-cell"><div class="extend-content">{{data.ATC}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">成分名</th>
            <td class="mat-cell"><div class="extend-content">{{data.IIName}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">價格變更日</th>
            <td class="mat-cell"><div class="extend-content">{{data.PriceChgDate|formatDate:''}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">健保價</th>
            <td class="mat-cell"><div class="extend-content">{{data.IPrice}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">上次健保價</th>
            <td class="mat-cell"><div class="extend-content">{{data.LastIPrice}}</div></td>
          </tr>
          <tr>
            <th class="mat-header-cell">自費價</th>
            <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
          </tr> -->
          <!-- 不明用途的欄位
          <tr>
            <th class="mat-header-cell">特殊使用條件</th>
            <td class="mat-cell"><div class="extend-content">{{data.Price}}</div></td>
          </tr> -->
        <!--</tbody>
      </table>
  </ng-template> -->
  