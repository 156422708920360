import { LOCALE_ID, NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
/* 佈署用
// import { HashLocationStrategy , LocationStrategy } from '@angular/common';
// kendo
*/
// Load the required calendar data for the locale
// app
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// his
import { HttpErrorInterceptor } from './shared/module/http/http-error.interceptor';
import { BaseConfig, initializeBaseConfig } from './services/base-config.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { SecurityModule } from './security/security.module';
import { HistRoutingModule } from './hist/hist-routing.module';

// Apply Material UI use

import { NgxElectronModule } from 'ngx-electron';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { VisMatPaginatorIntl } from './shared/components/grid/visMatPaginatorIntl';
import { HcrConsoleModule } from './card-reader/hcr-console/hcr-console.module';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { LayoutModule } from './layout/layout.module';
import { UserRoleFunctionService } from './services/data-service/user-role-function.service';


const globalRippleConfig: RippleGlobalOptions = {
  disabled: true
};


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'cloud-clinics' }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    LayoutModule,
    // his
    // app routing總是放在最後一個import
    AppRoutingModule,
    HistRoutingModule,
    NgxElectronModule,
  ],
  exports: [

],
  providers: [HttpClientModule, HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    BaseConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeBaseConfig,
      deps: [BaseConfig], multi: true
    },
    {provide: LOCALE_ID, useValue: 'zh-Hant'},
    { provide: MatPaginatorIntl, useClass: VisMatPaginatorIntl},
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig},
    UserRoleFunctionService
    // {provide: LOCALE_ID, useValue: 'en-US'},
    // {provide: LOCALE_ID, useValue: 'zh-Hant'},
    // 佈署用
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }
