<div class="row p-0 mx-1 mt-1 justify-content-around">
  <div class="card col-sm-12">
    <div class="card-header">
      <form class="c-form-inline" *ngIf="queryGroup" [formGroup]="queryGroup.FormGroup">
        <div class="card-body m-auto row extend-msg" style="max-width: 1200px;">
          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">查詢日期(起)</div>
              <div class="form-control-mergin-left">
                <app-vis-datepicker [formControlName]="queryGroup.FieldName.beginDate" style="vertical-align: middle;"
                  class="d-inline-flex position-relative -top-2 w-100" [max]="today">
                </app-vis-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">查詢日期(迄)</div>
              <div class="form-control-mergin-left">
                <app-vis-datepicker [formControlName]="queryGroup.FieldName.endDate" style="vertical-align: middle;"
                  class="d-inline-flex position-relative -top-2 w-100" [max]="today">
                </app-vis-datepicker>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <mat-checkbox [formControlName]="queryGroup.FieldName.dontPrintLackCard">
                不印欠卡
              </mat-checkbox>
            </div>
          </div>
          <div class="col-sm-12">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">範圍查詢選項</div>
              <div class="form-control-mergin-left">
                <mat-button-toggle-group class="d-inline-flex" [formControlName]="queryGroup.FieldName.otherRangeQueryType">
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.None">
                    無
                  </mat-button-toggle>
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.PatientNo">
                    病歷號
                  </mat-button-toggle>
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.PatientPhone">
                    電話
                  </mat-button-toggle>
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.PatientBirthday">
                    生日
                  </mat-button-toggle>
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.PatientCId">
                    身分證
                  </mat-button-toggle>
                  <mat-button-toggle class="p-0" [value]="otherRangeQueryType.PatientName">
                    姓名
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
            </div>
          </div>
          <div class="col-sm-4" *ngIf="isShowRangeQuery()">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">{{rangeQueryName}}(起)</div>
              <div class="form-control-mergin-left">
                <input class="w-100" type="text" [formControlName]="queryGroup.FieldName.otherRangeQueryBegin">
              </div>
            </div>
          </div>
          <div class="col-sm-8" *ngIf="isShowRangeQuery()">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">{{rangeQueryName}}(迄)</div>
              <div class="form-control-mergin-left">
                <input class="w-100" type="text" [formControlName]="queryGroup.FieldName.otherRangeQueryEnd">
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">醫師別代號</div>
              <div class="form-control-mergin-left">
                <app-vis-combobox class="no-extend" [formControlName]="queryGroup.FieldName.doctorCode"
                  [data]="doctorOption" [valueFormat]="'value'">
                </app-vis-combobox>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">代檢機構</div>
              <div class="form-control-mergin-left">
                <app-vis-combobox class="no-extend" [formControlName]="queryGroup.FieldName.hispidHId"
                  [data]="hspidOption" [valueFormat]="'value'">
                </app-vis-combobox>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">預防保健</div>
              <div class="form-control-mergin-left">
                <app-vis-combobox class="no-extend" [formControlName]="queryGroup.FieldName.sphcCode"
                  [data]="sphcOption" [valueFormat]="'value'">
                </app-vis-combobox>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">醫令標記</div>
              <div class="form-control-mergin-left">
                <app-vis-dropdownlist class="no-extend" style="width:11.3rem" [formControlName]="queryGroup.FieldName.spRule"
                  [data]="spRuleOption">
                </app-vis-dropdownlist>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">身分別</div>
              <div class="form-control-mergin-left">
                <mat-radio-group [formControlName]="queryGroup.FieldName.regType" class="d-inline-flex extend-label">
                  <mat-radio-button [value]="regType.All">全部</mat-radio-button>
                  <mat-radio-button [value]="regType.NHI">健保</mat-radio-button>
                  <mat-radio-button [value]="regType.NonNHI">自費</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>

          <div class="col-sm-4">
            <div class="w-100 d-flex vis-form-field" style="white-space:nowrap">
              <div class="label w-24">列印目標</div>
              <div class="form-control-mergin-left">
                <mat-radio-group [formControlName]="queryGroup.FieldName.printTarget" class="d-inline-flex extend-label">
                  <mat-radio-button [value]="printTarget.LabPrescription">處方箋</mat-radio-button>
                  <mat-radio-button [value]="printTarget.LabHrxList">醫令清單</mat-radio-button>
                </mat-radio-group>
              </div>
            </div>
          </div>
        </div>

        <div class="float-right vis-scale-anchor small-screen position-relative pt-2">
          <button type="button" (click)="handlePrintForm()" title="預覽" i18n="@@Review"
            class="vis-btn-config p-0 position-relative pl-2 w-24 mt-2">
            <mat-icon class="pref-icon vis-combine-icon btn-scale-90">print</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">預覽查詢</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<app-vis-dialog *ngIf="showType==printTarget.LabHrxList && labHRxListPageCollection" [show]="isPreviewPrint" (close)="isPreviewPrint=false"
  [fitContent]="true" [height]="'95%'" [maxWidth]="'1200px'" [superWidth]="'95%'" title="全民健康保險門診交付檢驗醫令清單">
  <app-lab-hrx-list-report-preview  [reportData]="labHRxListPageCollection"></app-lab-hrx-list-report-preview>
</app-vis-dialog>


<app-vis-dialog *ngIf="showType==printTarget.LabPrescription && labPrescriptionPageCollection" [show]="isPreviewPrint" (close)="isPreviewPrint=false"
  [fitContent]="true" [height]="'95%'" [maxWidth]="'1200px'" [superWidth]="'95%'" title="全民健康保險門診交付檢驗處方箋清單">
  <app-lab-prescription-report-preview  [reportData]="labPrescriptionPageCollection"></app-lab-prescription-report-preview>
</app-vis-dialog>
