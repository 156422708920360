import { EventEmitter, Injectable, OnInit } from "@angular/core";
import { TimeSecEnum } from "src/app/enums/TimeSecEnum";
import { GpParaApi } from "src/app/services/api-service/gp-para/gp-para-api";
import { REG001Params } from "src/app/services/api-service/parameters/REG001Params";
import { SCH001Params } from "src/app/services/api-service/parameters/SCH001Params";
import { ParameterApi } from "src/app/services/api-service/parameters/parameter-api";
import { RegisterApi, RegisterQueryOptions, ReserveCreateDto, ReserveDto, ReserveListDto } from "src/app/services/api-service/register/register-api";
import { TimeSectionRange } from "src/app/services/api-service/register/time-section-range";
import { ReserveScheduleDto, ScheduleApi } from "src/app/services/api-service/schedule/schedule-api";
import { ClinicDataService } from "src/app/services/data-service/clinic-data-service";
import { ParamGroupEnum } from "src/app/services/data-service/system-param-cache-define";
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { UserCache } from "src/app/services/user-cache";
import { DateHelper } from "src/app/shared/helpers/date-helper";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";

export declare type SchFilter = { roomCode: string, doctorId: number };

@Injectable({ providedIn: 'root' })
export class RegReserveService {

  private initialized = false;
  private sch001: SCH001Params;
  private reg001: REG001Params;
  private closeDateSec: { [key: string]: number[] };
  private closeMonthDay: string[];
  private gpPara: string;
  //private options:RegisterQueryOptions;

  private reserveSch: ReserveScheduleDto[];

  private reserveSDate: Date;
  private reserveEDate: Date;

  timeSecOption: ValueTextPairNumberValue[]
  iCodeSecOption: ValueTextPair[];
  roomOption: ValueTextPair[];

  public currentPatient = 0;
  public filter: SchFilter = { roomCode: '', doctorId: 0 };
  public onReserveReload: EventEmitter<void> = new EventEmitter();
  public onReserveLoad: EventEmitter<void> = new EventEmitter();
  /**
   *
   */
  constructor(private parameterApi: ParameterApi,
    private registApi: RegisterApi,
    private schduleApi: ScheduleApi,
    private gpParaApi: GpParaApi,
    private clinicDataService: ClinicDataService,
    private notification: EasyNotificationService) {
  }
  /** 初始化 */
  async init() {
    if (!this.initialized) {
      this.sch001 = await this.clinicDataService.getParam("SCH001");
      this.reg001 = await this.clinicDataService.getParam("REG001");;
      this.closeDateSec = JSON.parse(this.sch001.CloseDateSec || '{}');
      this.closeMonthDay = JSON.parse(this.sch001.CloseMonthDay || '[]');
      this.gpPara = await this.gpParaApi.Get();

      //this.options = await this.registApi.GetQueryOptions();

      var codes = await this.clinicDataService.getSystemCodes(['C0120', 'C0010'])
      var enums = await this.clinicDataService.getEnum(['TimeSec'])
      this.timeSecOption = enums['TimeSec'];
      this.iCodeSecOption = codes['C0010'];
      this.roomOption = codes['C0120'];

      //調整文字
      this.timeSecOption.find(x => x.value == 0).text = '全日'
      this.initialized = true;
    }

  }
  //#region 預約班表
  /** 載入指定區間的預約統計 */
  public async loadReserve(/*sDate:Date,eDate:Date*/) {
    var sDate = new Date();
    var eDate = DateHelper.addMonthDay(sDate, this.sch001.CanScheduledMonth);
    var clinicId = UserCache.getLoginClinicId();
    this.reserveSDate = sDate;
    this.reserveEDate = eDate;
    this.reserveSch = await this.schduleApi.GetReserve(clinicId, sDate, eDate);
    this.onReserveLoad.emit();
  }
  public getInerval() {
    return DateHelper.dayDiff(this.reserveEDate, this.reserveSDate) + 1
  }
  /** 依目前區間重讀預約統計 */
  public async reloadReserve() {
    if (!this.reserveSDate || !this.reserveEDate) {
      return;
    }
    var clinicId = UserCache.getLoginClinicId();
    this.reserveSch = await this.schduleApi.GetReserve(clinicId, this.reserveSDate, this.reserveEDate);
    this.onReserveReload.emit();
  }
  public setFilter(filter: SchFilter) {
    this.filter = filter;
    this.onReserveReload.emit();
  }
  /** 取得目前所選區間的預約統計 */
  public currentReserve() {
    var ret: ReserveScheduleDto[] = JSON.parse(JSON.stringify(this.reserveSch));
    if (this.filter && this.filter.roomCode) {
      ret.forEach(x => x.Reserves = x.Reserves.filter(y => y.RoomCode == this.filter.roomCode));
    }
    if (this.filter && this.filter.doctorId) {
      ret.forEach(x => x.Reserves = x.Reserves.filter(y => y.DoctorId == this.filter.doctorId));
    }
    ret = ret.filter(x => x.Reserves.length > 0);
    return ret;
  }
  /** 查詢指定醫師的預約統計 */
  public findReserve(doctorId: number) {
    var ret: ReserveScheduleDto[] = [];

    this.reserveSch.forEach(x => {
      var doctorReserves = x.Reserves.filter(y => y.DoctorId == doctorId)
      if (doctorReserves.length > 0) {
        ret.push({
          Date: x.Date,
          Sec: x.Sec,
          Reserves: doctorReserves
        });
      }
    })
    return ret;
  }
  /** 取得統計細項 */
  getReserveDetail(date: Date, sec: TimeSecEnum) {
    var ret = this.reserveSch.find(x => DateHelper.compareDate(x.Date, date) && x.Sec == sec)?.Reserves;
    if (this.filter && this.filter.roomCode) {
      ret = ret.filter(x => x.RoomCode == this.filter.roomCode)
    }
    if (this.filter && this.filter.doctorId) {
      ret = ret.filter(x => x.DoctorId == this.filter.doctorId)
    }
    return ret;
  }
  //#endregion

  //#region Configs
  /** 取得休診 */
  public getCloseDateSec() {
    return this.closeDateSec;
  }
  /** 取得節日 */
  public getHolidays() {
    return this.closeMonthDay;
  }
  /** 新增休診 */
  public addCloseDateSec(date: Date, sec: number) {
    if (!date) {
      this.notification.showWarning('請選擇休診時間');
      return;
    }
    if (date < new Date()) {
      this.notification.showWarning('無法加入過去的時間');
      return;
    }
    var secs = this.closeDateSec[date.toLocaleDateString()];
    if (secs != null) {
      if (secs.some(x => x == sec)) {
        this.notification.showWarning('無法加入重複的時間');
        return;
      }
      if (secs.some(x => x == 0)) {
        this.notification.showWarning('該日期已設定為全日休診');
        return;
      }

      if (secs.length != 0 && sec == 0) {
        this.notification.showWarning('該日期已設定過單獨休診時段');
        return;
      }
      secs.push(sec);
    } else {
      this.closeDateSec[DateHelper.getFormatedDateString(date, false, '/', false)] = [sec];
    }
  }
  /** 新增節日 */
  public addHoliday(monthDay: string) {
    if (!monthDay) {
      this.notification.showWarning('請輸入假日日期');
      return;
    }
    var month = parseInt(monthDay.substring(0, 2));
    if (isNaN(month) || month > 12 || month < 1) {
      this.notification.showWarning('「月」格式錯誤');
      return;
    }
    var day = parseInt(monthDay.substring(2));
    if (isNaN(day) || day > 31 || day < 1) {
      this.notification.showWarning('「日」格式錯誤');
      return;
    }

    if (!this.closeMonthDay.some(x => x == monthDay)) {
      this.closeMonthDay.push(monthDay);
    } else {
      this.notification.showWarning('無法加入重複的日期');
      return;
    }
  }
  /** 移除休診 */
  public removeCloseDateSec(date: Date, sec: TimeSecEnum) {
    var secs = this.closeDateSec[DateHelper.getFormatedDateString(date)];
    if (secs != null) {
      secs = secs.filter(x => x != sec);
      if (secs.length == 0) {
        delete this.closeDateSec[DateHelper.getFormatedDateString(date)]
      } else {
        this.closeDateSec[DateHelper.getFormatedDateString(date)] = secs;
      }
    } else {
      //this.closeDateSec[date.toLocaleDateString()] = [sec];
    }
  }
  /** 移除節日 */
  public removeHoliday(monthDay: string) {
    this.closeMonthDay = this.closeMonthDay.filter(x => x !== monthDay);
  }
  /** 清除休診 */
  public clearCloseDateSec() {
    this.closeDateSec = {};
  }
  /** 清除節日 */
  public clearHolidays() {
    this.closeMonthDay = [];
  }
  /** 儲存設定 */
  public async saveConfig(): Promise<boolean> {
    this.sch001.CloseDateSec = JSON.stringify(this.closeDateSec);
    this.sch001.CloseMonthDay = JSON.stringify(this.closeMonthDay);
    try {
      await this.parameterApi.UpdateParamGroup(ParamGroupEnum.SCH001, this.sch001);
      this.notification.showSuccess('儲存成功');
      return true;
    } catch (ex) {
      this.notification.showError(ex);
      return false;
    }

  }
  //#endregion

  public async getReservesByDate(date: Date): Promise<ReserveListDto[]> {
    var ret = await this.registApi.GetReserveByDate(date);
    return ret;
  }

  public async getReserveData(patientId: number): Promise<ReserveDto> {
    return await this.registApi.GetReserveData(patientId);
  }

  public getScheduleDoctorByDateSec(date: Date, sec: TimeSecEnum): ValueTextPairNumberValue[] {
    if (!date || !sec) {
      //this.notification.showWarning('請先選擇日期/時段');
      return [];
    }
    var doctorOpt: ValueTextPairNumberValue[] = this.reserveSch.find(x => DateHelper.compareDate(x.Date, date) && x.Sec == sec).Reserves
      .map(x => {
        return { value: x.DoctorId, text: x.DoctorName, extension: x.RoomCode }
      });
    if (this.filter && this.filter.roomCode) {
      doctorOpt = doctorOpt.filter(x => x.extension == this.filter.roomCode)
    }

    if (this.filter && this.filter.doctorId) {
      doctorOpt = doctorOpt.filter(x => x.value == this.filter.doctorId)
    }
    return doctorOpt;
  }
  /** 從預約班表撈可以用的所有醫師, ext:對應診間 */
  public getAllDoctor() {
    var doctorOpt: ValueTextPairNumberValue[] = this.reserveSch.map(x => x.Reserves).reduce((a, b) => a.concat(b))
      .map(x => {
        return { value: x.DoctorId, text: x.DoctorName, extension: x.RoomCode }
      });
    return doctorOpt;
  }
  public async getWaitNo(date: Date, sec: TimeSecEnum, roomCode: string) {
    var d = new Date(date).toLocaleDateString()
    return await this.registApi.GetWaitNo(d, sec, roomCode, true)
  }
  public async reserve(data: ReserveCreateDto) {
    data.ClinicId = UserCache.getLoginClinicId();
    try {
      var ret = await this.registApi.Reserve(data);
      this.notification.showSuccess('儲存成功');
      return true;
    } catch (e) {
      this.notification.showError(e);
      return false;
    }
  }
  public async updateReserve(data: ReserveCreateDto) {
    data.ClinicId = UserCache.getLoginClinicId();
    try {
      await this.registApi.updateReserve(data);
      this.notification.showSuccess('儲存成功');
      return true;
    } catch (e) {
      this.notification.showError(e);
      return false;
    }
  }
  public async deleteReserve(id: number) {
    try {
      await this.registApi.deleteReserve(id);
      this.notification.showSuccess('刪除成功');
      return true;
    } catch (e) {
      this.notification.showError(e);
      return false;
    }
  }

  public getCurrentSection(): TimeSecEnum {
    var tsRange = new TimeSectionRange(this.reg001)
    const timeSecDefault = TimeSectionRange.getSectionValue(tsRange);
    return timeSecDefault as TimeSecEnum;
  }
  public isNoschedule() {
    var p289 = this.gpPara[288];
    var isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    var isRecoveryHome = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
    if (isHomeCare || isRecoveryHome || p289 == ' ') { //居護院所不檢查是否有班表
      return true;
    }
    return false;
  }
}
