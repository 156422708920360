<app-vis-dialog title="選擇檔案" [show]="_isDialogOpened" (close)="onDialogClose('close')"
 [maxWidth]="'60vw'" [superWidth]="'60%'" i18n-title="@@DialogFileUpload">

<div class="vis-file-uploader" style="margin: 10px;" dragAndDrop (onFileDropped)="selectFiles($event)">
  <div class="file-uploader__instructions">
      <!--Drag and drop the file(s) here or click on "Browse Files".-->
      拖拉檔案到這 或 瀏覽檔案。
      <span *ngIf="config.max_file_limit > 0">最多 {{config.max_file_limit}} 個檔案。</span>
      <span *ngIf="config.max_file_size_mb > 0">每個檔案最大 {{config.max_file_size_mb}} MB。</span>
  </div>
  <div class="files-for-upload">
      <mat-expansion-panel
          *ngFor="let selected_file of (selected_files || []); index as i"
          class="selected-file" hideToggle disabled
          expanded="{{!!selected_file.upload_result}}">
          <mat-expansion-panel-header>
              <div class="selected-file__header" style="flex: auto;">
                  <div class="selected-file__name">
                      {{selected_file.file.name}} <span class="selected-file__filesize">({{selected_file.file.size | fileSizeUnit}})</span>
                  </div>
                  <div class="selected-file__actions">
                      <!--將此button關閉, 因實際上傳檔案, 寫在存檔時才進行, 此處功能只剩選擇檔案-->
                      <button [hidden]="true"
                          matRipple
                          class="selected_file__upload primary-button"
                          matTooltip="Upload"
                          matTooltipPosition="left"
                          aria-label="Upload File"
                          (click)="uploadFile(i)"
                          [disabled]="selected_file.is_upload_in_progress || selected_file.upload_result=='success'">
                          <span *ngIf="selected_file.upload_result!='success'"
                              aria-hidden="true" class="material-icons">file_upload</span>
                          <span *ngIf="selected_file.upload_result=='success'"
                              aria-hidden="true" class="material-icons">check</span>
                      </button>
                      <button
                          matRipple
                          class="selected_file__cancel secondary-button"
                          matTooltip="Cancel"
                          matTooltipPosition="left"
                          aria-label="Cancel File"
                          (click)="inititateFileCancel(i)">
                          <span aria-hidden="true" class="material-icons">clear</span>
                      </button>
                  </div>
                </div>

                <mat-progress-bar *ngIf="selected_file.is_upload_in_progress" mode="indeterminate" style="position: absolute;"></mat-progress-bar>
          </mat-expansion-panel-header>

          <div *ngIf="selected_file.upload_result=='success'"
              class="selected-file__upload-result" style="padding-top: 1rem;">
              <!--The file was uploaded successfully.-->
              檔案上傳成功 {{selected_file.file.size | fileSizeUnit}}
          </div>
          <div *ngIf="!!selected_file.upload_result && selected_file.upload_result!='success'"
              class="selected-file__upload--error" style="padding-top: 1rem;">
              {{selected_file.upload_result}}
          </div>

      </mat-expansion-panel>
  </div>
  <div class="file-upload__options">
      <!--
        [accept]="config.MIME_types_accepted"
        multiple="{{config.is_multiple_selection_allowed}}">
      -->
      <form
        class="file-upload__native-selection"
        [formGroup]="file_selection_form">
        <input type="file" #fileSelector
            formControlName="file_selection"
            [accept]="config.MIME_types_accepted"
            [multiple]="config.is_multiple_selection_allowed">
      </form>
      <button
      matRipple
      class="file-upload__browse primary-button"
      (click)="openFileSelector()">
          <!--Browse Files-->
          瀏覽檔案
      </button>
      <ng-container *ngIf="selected_files?.length>1">
          <!--將此button關閉, 因實際上傳檔案, 寫在存檔時才進行, 此處功能只剩選擇檔案-->
          <button [hidden]="true"
              matRipple
              class="all_file__upload primary-button"
              matTooltip="Upload All"
              matTooltipPosition="above"
              aria-label="Upload All Files"
              (click)="uploadAll()"
              [disabled]="!isAnyFileNotUploaded()">
              <span aria-hidden="true" class="material-icons">file_upload</span>
          </button>
          <button
              matRipple
              class="all_file__cancel secondary-button"
              matTooltip="Cancel All"
              matTooltipPosition="above"
              aria-label="Cancel All Files"
              (click)="initiateCancelAll()">
              <span aria-hidden="true" class="material-icons">clear</span>
          </button>
      </ng-container>
  </div>
</div>

<app-vis-dialog-actions>
  <button type="button" (click)="onDialogClose('ok')" i18n="@@Ok">確定</button>
  <!--將此button關閉, 因實際上傳檔案, 寫在存檔時才進行, 都改由[確定]控制-->
  <button type="button" [hidden]="true" (click)="onDialogClose('cancel')" i18n="@@Cancel">取消</button>
</app-vis-dialog-actions>

</app-vis-dialog>
