import { EventEmitter, Injectable } from '@angular/core';
import { MainLayoutService } from '../layout/services/main-layout.service';

@Injectable({
  providedIn: 'root'
})
export class WindowSizeService {

  private readonly bigRatioWidth = 1251
  get bodyHeight(){
    return document.body.clientHeight
  }
  get contentHeight(){
    return this.mainLayoutService.getMainHeight();
  }
  get nowWidth(){
    return window.innerWidth;
  }

  bigRatio:boolean = false;
  onWinResize = new EventEmitter();
  private resizeTimer:any;
  constructor(private mainLayoutService:MainLayoutService) { 
    window.addEventListener('resize',this.onResize.bind(this));
  }

  
  private onResize(event) {
    this.updateSize();
    if(this.resizeTimer){
      clearTimeout(this.resizeTimer);
    }
    this.resizeTimer = setTimeout(() => {
      this.onWinResize.emit();
      this.resizeTimer = null;
    }, 200);
  }
  private updateSize(){
    document.documentElement.style.setProperty('--main-content-height', this.bodyHeight+'px');
    //this.bodyHeight = document.body.clientHeight;
    //this.nowWidth = window.innerWidth;
    this.bigRatio = this.nowWidth < this.bigRatioWidth;
  }
}
