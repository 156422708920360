import { Component, Input, OnInit } from '@angular/core';
import { GridData } from '../../grid/vis-grid/vis-grid.component';

@Component({
  selector: 'app-nhihistory-grid',
  templateUrl: './nhihistory-grid.component.html',
  styleUrls: ['./nhihistory-grid.component.css']
})
export class NHIHistoryGridComponent implements OnInit {

  @Input() title: string;
  @Input() pageSize: number = 10;
  @Input() pageSkipNo: number = 0;
  @Input() width: string = '100%';

  gridView: GridData;
  selectedItems: number[] = [];

  constructor() { }

  ngOnInit(): void {
    this.refreshList();
  }

  pageChange(page: any): void {
    this.pageSkipNo = (page - 1) * this.pageSize;  // 跳過的資料筆數
    this.refreshList();
  }

  refreshList() {
    const defaultReserveList: NHIHistoryItem[] = [
      new NHIHistoryItem({
        Id: 1,
        FinishedDate:new Date(2024,5,22),
        ToothPos:"25",
        SRxId:1,
        SRxCode:"89009C",
        ClinicName:"展望牙科診所"
      }),
      new NHIHistoryItem({
        Id: 2,
        FinishedDate: new Date(2024,6,19),
        ToothPos:"27",
        SRxId:2,
        SRxCode:"89010C",
        ClinicName:"本院"
      }),
      new NHIHistoryItem({
        Id: 3,
        FinishedDate: new Date(2024,7,4),
        ToothPos:"FM",
        SRxId:3,
        SRxCode:"91004C",
        ClinicName:"本院"
      })
    ];

    this.gridView = {
      data: defaultReserveList,
      total: defaultReserveList.length
    };
  }

}

/**
 * 紀錄在健保卡上的，病患的歷史病歷
 */
class NHIHistoryItem {
  Id: number;
  FinishedDate: Date;
  ToothPos: string;
  SRxId: number;
  SRxCode: string;
  ClinicName: string;

  constructor(init?: Partial<NHIHistoryItem>) {
    Object.assign(this, init);
  }
}
