import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ValueTextPair } from '../../../models/value-text-pair';
import { BaseConfig } from '../../../../services/base-config.service';

// module
import { BasePickUpLeftRightOfGrid } from 'src/app/shared/components/base/base.pickup-left-right-ofgrid.component';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { WebApiService } from 'src/app/services/web-api.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { VisGridComponent } from '../../grid/vis-grid/vis-grid.component';
import { MatRadioChange } from '@angular/material/radio';
import { RxApi, RxQueryParam } from 'src/app/services/api-service/rx/rx-api';
import { RxListDto } from 'src/app/services/api-service/rx/rx-list-dto';
import { Distinct } from 'src/app/shared/utilities';

declare type AllergyType = 'Drug'|'ATC'|'AllergyGroup'

@Component({
  selector: 'app-shared-patients-drug-allergy',
  templateUrl: './drug-allergy.component.html',
  styleUrls: ['./drug-allergy.component.css'],
})
export class DrugAllergyComponent //extends BasePickUpLeftRightOfGrid<Rx> implements OnInit //, ControlValueAccessor
{
  @ViewChild('grid')
  grid:VisGridComponent;

  constructor(
    private rxApi: RxApi ,
    private cd: ChangeDetectorRef) {
      //this.ngOnInit();
    }

  //#region 參數 --------------------
  //api: WebApiService;

  // window
  winWidth = 650;
  winHeight = 480;
  oringin = '';
  @Input() isDialogOpened = false;
  @Input()
  set selectedText(v: string){
    if(v){
      this.oringin = v;
      this.parseInput();
    }else{
      this.userInput = {
        history:{}
      };
      this.codeTypeList.forEach(ct=>{
        this.userInput.history[ct.value] = [];
      });
    }
  }
  //@Input() codeTypeList: ValueTextPair[] = [];
  codeTypeList: {text:string, value:AllergyType}[] = [
    { text: '藥品代碼', value: 'Drug' },
    { text: 'ATC碼', value: 'ATC' },
    { text: '過敏藥群組', value: 'AllergyGroup' }
  ];
  userInput:{history:{[key:string]:string[]}}
  @Output() 
  emitResult = new EventEmitter<{items:{type: string,items:string[]}[],text:string}>();

  // 清單物件
  listItems: ValueTextPair[];
  allItems:{[key:string]:ValueTextPair[]} = {}
  //#endregion 參數 --------------------

  //#region 參數 表格 --------------------

  // 表格-分頁
  gridView: {
    data: RxListDto[],
    total: number,
  };
  // pageSize: number = BaseConfig.settings.ui.pageSize;
  pageSize = 5; // 讓資料在同一頁
  pageSkip = 0;
  page = 1;
  code = '';
  name = '';
  // 表格-selectable設定
  //#endregion 參數 表格 --------------------

  ngOnInit() {
    setTimeout(async () => {
      await this.refreshList();
      this.parseInput();
    }, 0);
  }

  cleanData() {
    this.code = '';
    this.currentTp == 'Drug';
    this.ngOnInit();
  }

  reloadData(d: any) {
    //await this.refreshList();
    this.selectedText = d;
    if(d){
      this.oringin = d;
      this.parseInput();
    }else{
      this.userInput = {
        history:{}
      };
      this.codeTypeList.forEach(ct=>{
        this.userInput.history[ct.value] = [];
      });
      this.updateGridSelect();
    }
  }

  parseInput(){
    var items = (this.oringin+';;').split(';'); // 確保能拆出三個
    this.userInput = {
      history:{}
    };
    for(var i = 0; i < this.codeTypeList.length; i++) {
      this.userInput.history[this.codeTypeList[i].value] = items[i].split(',').filter(x=>x);
    }
    this.updateGridSelect();
    
  }

  updateGridSelect(){
    if(this.gridView?.data){
      var selected = this.userInput.history[this.currentTp];
      this.gridView.data.forEach(x=>x['checked']= selected.includes(x.Code) );
    }
  }

  checkChanged(evt:{checked:boolean,data:any}){

    if(this.isDialogOpened){
      var selected = this.userInput.history[this.currentTp];
      if(evt.checked==true){
        // select all
        if(evt.data==null){
          selected = selected.concat(this.gridView.data.map(d=>d.Code));
        }else{
          selected.push(evt.data.Code);
        }
        selected = Distinct(selected);
      }else{
        var filter = []
        if(evt.data==null){
          filter = filter.concat(this.gridView.data.map(d=>d.Code));
        }else{
          filter.push(evt.data.Code);
        }
        selected = selected.filter(s=>!filter.includes(s));
      }
      this.userInput.history[this.currentTp] = selected;
      this.grid.resetSelectStatus();
    }
  }
  
  // #region form and query --------------------
  currentTp:AllergyType = this.codeTypeList[0].value;
  changeCodeType(evt: MatRadioChange) {
    // 重設頁數
    this.page = 1;
    this.pageSkip = 0;
    this.currentTp = evt.value;
    this.code = '';
    this.refreshList();
  }
  async query(){
    this.pageSkip = 0;
    await this.refreshList();
  }
  // 更新清單 --使用 api GetCodeList()
  async refreshList() {
    this.gridView = {
      data: [],
      total: 0
    };
    var q = new RxQueryParam();
    q.code = this.code;
    //q.name = this.name;
    q.pageSize = this.pageSize;
    q.pageSkip = this.pageSkip;

    if(this.currentTp == 'Drug'){
      var data = await this.rxApi.GetRealDrugAll(q);
      data.data.forEach(x => x['checked'] = this.userInput.history['Drug'].includes(x.Code));
      this.setGridView(data);
    } else if (this.currentTp == 'ATC') {
      var data = await this.rxApi.GetRealDrugATCList(q);
      data.data.forEach(x => x['checked'] = this.userInput.history['ATC'].includes(x.Code));
      this.setGridView(data);
    } else {
      var data = await this.rxApi.GetAllergyGroupList(q);
      data.data.forEach(x => x['checked'] = this.userInput.history['AllergyGroup'].includes(x.Code));
      this.setGridView(data);
    }
  }
  // #endregion form and query --------------------

  // #region grid --------------------
  // 表格分頁
  setGridView(data:PageData2<RxListDto>) {
    // debugger
    this.gridView = {
      data: data.data,
      total: data.total,
    };
    this.cd.markForCheck();
  }

  // 分頁切換事件
  pageChange(pageNum: any): void {
    this.page = pageNum; // kendo grid page用法
    this.pageSkip = (pageNum-1)*this.pageSize;
    this.refreshList()
  }
  // #endregion grid --------------------

  // #region dialog --------------------
  get isDisableBtnSelect() {
    
    var selectedAny = false;
    this.codeTypeList?.forEach(c=>{
      selectedAny = selectedAny || this.allItems[c.value].some(it=>it['checked']==true);
    });
    return !selectedAny;
  }

  onDialogClose(status: boolean) {    
    if (status === true) {
      var ret = this.codeTypeList.map(ct=>{
        return  { type: ct.value, items: this.userInput.history[ct.value]};
      });
      var retText = this.userInput.history['Drug'].join(',') + ';' + this.userInput.history['ATC'].join(',') + ';' + this.userInput.history['AllergyGroup'].join(',');
      this.selectedText = '';
      this.oringin = '';
      //this.refreshList();
      this.currentTp = this.codeTypeList[0].value;   
      this.emitResult.emit({items:ret,text:retText});
    } else {
      this.selectedText = ''; //this.oringin;
      this.oringin = '';
      //this.refreshList();
      this.currentTp = this.codeTypeList[0].value;
      // 這次沒選
      this.emitResult.emit(null);
      // 清除選取
      //this.selectedItems = [];
    }
  }
  result(){
    if(!this.userInput){
      return '';
    }
    var retText =  '';
    this.codeTypeList.forEach(ct=>{
      var labelTxt = `[${ct.text}]：`;
      
      var itemTxt = this.userInput.history[ct.value].join(',');
      retText +=labelTxt + itemTxt+' \n';
    });
    return retText;
  }

  unCheck(type:AllergyType,item:string){
    var selecteds = this.userInput.history[type];
    selecteds = selecteds.filter(s=>s!=item);
    this.userInput.history[type] = selecteds;
    this.updateGridSelect();
  }
  // #endregion dialog --------------------

}
