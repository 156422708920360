<div style="display: flex" *ngIf="this.familyMemberName?.length>=1">
  <mat-button-toggle-group>
  <mat-button-toggle [checked]="this.patient.Id==selectedMemberId"
          class="main tab-btn ellipsis-content" [title]="this.patient.CName"
          (click)="changeList(this.patient.Id)">{{this.patient.CName}}</mat-button-toggle>
  </mat-button-toggle-group>
  <mat-divider style="width: 1px;height: 24px;background: #ddd;margin-left: 1px;margin-right: 3px;"></mat-divider>
  <div style="font-weight: bold;">家族：</div>
  <button type="button" class="arrow-btn" (click)="prev()"><</button>
  <mat-button-toggle-group>
    <ng-container *ngFor="let member of familyMemberNameDisplay">
      <mat-button-toggle [checked]="member.id==selectedMemberId" class="tab-btn ellipsis-content" [title]="member.name" (click)="changeList(member.id)" style="font-size: 14px;">{{member.name}}</mat-button-toggle>
      <!-- <button type="button" class="tab-btn ellipsis-content" [title]="member.name" (click)="changeList(member.id)">{{member.name}}</button> -->
    </ng-container>
  </mat-button-toggle-group>
  <div style="flex-grow: 1;"></div>
  <button type="button" class="arrow-btn" (click)="next()">></button>
</div>

<app-vis-grid [data]="gridView"
  [pageSize]="pageSize"
  [skip]="pageSkipNo"
  [allowDoubleClick]="true"
  (pageChange)="pageChange($event)"
  (selectionChange)="cellClickHandler($event)"
  (selectionDblClickChange)="cellDblClickHandler($event)"
  [selectable]="true"
  [setDefaultSeclected]="true"
  [customClass]="'vis-history-bg extend vis-history-bg-hist-extend'"
  [kendoGridSelectBy]="'Id'"
  [showStickyHead]="true"
  [customStyle]="getHistListHeight()"
  [scroll]="true"
  [selectedKeys]="selectedItems">
  <app-vis-grid-column field="RegDate" title="看診日期" [width]="95" i18n-title="@@RegDate" class="text-center custom-m-auto">
    <ng-template let-dataItem>
      <div class="ellipsis-content" [title]="'看診日：'+(dataItem.RegDate | formatDate:'')+', 案類：'+dataItem.Case+', 卡序：'+dataItem.Isqno+(dataItem.TreatCount?', 療次：'+ dataItem.TreatCount:'') +(dataItem.PresCount? ', 慢次：'+dataItem.PresCount:'')+', 總額：'+dataItem.ADcl">{{ dataItem.RegDate | formatDate:'' }}</div>
    </ng-template>
  </app-vis-grid-column>
  <app-vis-grid-column field="Case" title="案類" [width]="35" i18n-title="@@Case"  class="text-center">
    <ng-template let-dataItem>
      <div class="ellipsis-content" [title]="'看診日：'+(dataItem.RegDate | formatDate:'')+', 案類：'+dataItem.Case+', 卡序：'+dataItem.Isqno+(dataItem.TreatCount?', 療次：'+ dataItem.TreatCount:'') +(dataItem.PresCount? ', 慢次：'+dataItem.PresCount:'')+', 總額：'+dataItem.ADcl">{{ dataItem.Case}}</div>
    </ng-template>
  </app-vis-grid-column>
  <app-vis-grid-column field="Isqno" title="卡序" [width]="50" i18n-title="@@Isqno"  class="text-center">
    <ng-template let-dataItem>
      <div class="ellipsis-content" [title]="'看診日：'+(dataItem.RegDate | formatDate:'')+', 案類：'+dataItem.Case+', 卡序：'+dataItem.Isqno+(dataItem.TreatCount?', 療次：'+ dataItem.TreatCount:'') +(dataItem.PresCount? ', 慢次：'+dataItem.PresCount:'')+', 總額：'+dataItem.ADcl">{{ dataItem.Isqno}}</div>
    </ng-template>
  </app-vis-grid-column>
  <app-vis-grid-column field="MainDiagName" title="診斷" i18n-title="@@Diagnosis">
    <ng-template let-dataItem>
      <div class="ellipsis-content" [title]="dataItem.MainDiagName+', 案類：'+dataItem.Case+', 卡序：'+dataItem.Isqno+(dataItem.TreatCount?', 療次：'+ dataItem.TreatCount:'') +(dataItem.PresCount? ', 慢次：'+dataItem.PresCount:'')+', 總額：'+dataItem.ADcl">{{ dataItem.MainDiagName }}</div>
    </ng-template>
  </app-vis-grid-column>
  <!-- <app-vis-grid-column field="ADcl" title="總額" [width]="55" i18n-title="@@ADcl"  class="text-right">
  </app-vis-grid-column> -->
  <app-vis-grid-column field="DoctorName" title="醫師" [width]="70" i18n-title="@@Doctor">
  </app-vis-grid-column>
</app-vis-grid>
