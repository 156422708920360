import { PresDateStartAndEndDto } from "src/app/services/api-service/hist/whole-large-hist";

export class CtrDrugsReport {
  /** 掛號Id */
  RegisterId: number;
  /** 病歷編號 */
  PatientNo: string;
  /** 病患姓名 */
  PatientName: string;
  /** 性別 */
  Sex: string;
  /** 生日 */
  Birthday: string;
  /** 病患身分證 */
  CID: string;
  /** 病患電話 */
  Phone: string;
  /** 管制用藥清單 */
  Drugs: CtrDrugsReportItem[];
  /** 醫事機構代號 */
  NHICode: number;
  /** 機構名稱 */
  ClinicName: string;
  /** 掛號日=>處方日期 */
  RegDate: string;
  /** 調劑日期 */
  DispDate: string;
  /** 疾病名稱 */
  DxName: string;
  /** 醫師姓名 */
  DoctorName: string;
  /** 機構電話 */
  ClinicPhone: string;
  /** 管制藥品使用執照號碼 */
  DrugControlNo: string;
  /** 藥師姓名 */
  PharName: string;
  /** 是否為自調 */
  IsDispTP0 :boolean;
  PresDates: PresDateStartAndEndDto[] =[];
}

export class CtrDrugsReportItem {
  /** 醫令Id */
  Id: number;
  /** 醫令健保代碼 */
  RxCode: string;
  /** 醫令商品名稱 */
  RxName: string;
  /** 用藥頻率 */
  Freq: string;
  /** 服法 */
  Way: string;
  /** 用藥次量 */
  Dose: number;
  /** 用藥單位 */
  Unit: string;
  /** 用藥天數 */
  InfoDays: number;
  /** 用藥總量 */
  TotalDose: number;
  /** 是否為管制藥品 */
  IsCtrdrug: boolean;
  /** 管制藥品級別 */
  CTRDrugs:string;
  /** 調劑 */
  DispTP:string;
  /** 是否為/7以下藥品限領一次 (此筆資料不會給前端) */
  IsSeven: boolean;
  /** 限領一次 */
  TakeOnce: boolean;
}
