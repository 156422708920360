import { verify } from "crypto";

const invalidCharRegex = /[\x00-\x08\x0B\x0C\x0E-\x1F]/g;

export function delay(ms: number): Promise<void> {
  return new Promise<void>(res => setTimeout(res, ms));
}

export function Distinct<T>(array: T[], selector: (item: T) => any = null) {
  // 第一個相同的項目的Index跟其Index一致
  if (selector) {
    return array.filter((a, i, r) => r.findIndex(x => selector(x) == selector(a)) == i);
  } else {
    return array.filter((a, i, r) => r.indexOf(a) == i);
  }

}

export function Or(data: any, replaceValue: any) {
  return data == null || data == undefined ? replaceValue : data;
}
export function NullOrEmpty(str: string|any[]) {
  //return str == null || str == undefined || str == '';
  if(str instanceof String){
    return str == null || str == undefined || str == '';
  }else if(str instanceof Array){
    return str == null || str == undefined || str?.length==0;
  }else{
    return str == null || str == undefined;
  }
}
export function NullOrEmptyTrim(str: string) {
  if(str != null && str != undefined){
    str=str.trim();
  }
  return str == null || str == undefined || str == '';
}
/** 用這個取代 if(字串/數字) 避免空字串或0被認定為false */
export function isNullOrUndefined(value: any) {
  return value == null || value == undefined;
}
export function Compare(a, b) {
  return a > b ? 1 : a == b ? 0 : -1;
}

export function StringEqual(a:string, b:string):boolean {
  // console.log('StringEqual >>>>>', '[' + a + ']', '[' + b + ']');
  return (a??'') == (b??'');
}
export function replaceAll(s: string, c1: string, c2: string): string {
  if (s) {
    return (s as any).replaceAll(c1, c2);
  } else {
    return '';
  }
}
export function escapeStr(s: string):string {
  // if (s) {
  //   return (s as any)
  //   .replaceAll("&","&amp;")
  //   .replaceAll("\"","&quot;")
  //   .replaceAll("<","&lt;")
  //   .replaceAll(">","&gt;")
  //   .replaceAll("'","&apos;")
  // } else {
  //   return '';
  // }
  return xmlFixSpecialChar(s);
}
/**
 * 轉換XML符號以及無效字元處理
 */
export function xmlFixSpecialChar(s: string, type: 'HTML' | 'FULL' = 'HTML'):string {
  if (s) {
    // const invalidCharRegex = /[\x00-\x08\x0B\x0C\x0E-\x1F]/g;
    if (type == 'HTML') {
      return (s as any)
      .replaceAll("&","&amp;")
      .replaceAll("\"","&quot;")
      .replaceAll("<","&lt;")
      .replaceAll(">","&gt;")
      .replaceAll("'","&apos;")
      .replaceAll(invalidCharRegex, "");
    }
    else {
      return (s as any)
      .replaceAll("&","＆")
      .replaceAll("\"","＂")
      .replaceAll("<","＜")
      .replaceAll(">","＞")
      .replaceAll("'","’")
      .replaceAll(invalidCharRegex, "");
    }
  } else {
    return '';
  }
}
/**
 * 檢查是否包含特殊字元
 */
export function isSpecialChar(s: string):boolean {
  return invalidCharRegex.test(s);
}
/**
 * 特殊字元轉換空白
 */
export function fixSpecialChar(s: string, fix: string = ''):string {
  if (s) {
    return (s as any).replaceAll(invalidCharRegex, fix);
  } else {
    return '';
  }
}
/** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
export function onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
  if (ev) {
    var d = new Date(ev);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    if (isMonth) {
      return [year, month].join(symbol);
    } else {
      return [year, month, day].join(symbol);
    }
  }
}

/** 西元日期 轉民國 YYYMMDD (不足補0) */
export function ConvertToRocDateString(date: Date) {
  return `${(date.getFullYear() - 1911).toString().padStart(3, '0')}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`
}

export function AgeYearDiff(fromDate: Date | string, toDate: Date | string) {
  fromDate = new Date(fromDate);
  toDate = new Date(toDate);
  return toDate.getFullYear() - fromDate.getFullYear();
}

// 算實數
export function AgeYearTruthDiff(fromDate: Date | string, toDate: Date | string) {
  fromDate = new Date(fromDate);
  toDate = new Date(toDate);
  let monthdiff = fromDate.getMonth() -toDate.getMonth()
  let year = monthdiff > 0 || (monthdiff == 0 && (fromDate.getDay() -toDate.getDay())) > 0 ? 1 :0;
  return toDate.getFullYear() - fromDate.getFullYear() -year;
}
export function AgeYearMonthDiff(fromDate: Date, toDate: Date) {
  fromDate = new Date(fromDate);
  toDate = new Date(toDate);
  var year = toDate.getFullYear() - fromDate.getFullYear();
  var month = toDate.getMonth() - fromDate.getMonth();
  if (month < 0) {
    month += 12;
    year -= 1;
  }
  return { year, month };
}

export function AddYear(from: Date, year: number) {
  from.setFullYear(from.getFullYear() + year);
  return from;
}
export function AddMonth(from: Date, month: number) {
  from.setMonth(from.getMonth() + month);
  return from;
}
export function AddDay(from: Date, days: number) {
  from.setDate(from.getDate() + days);
  return from;
}
/** {text:名稱,value: dayOfWeek} */
export function GetWeekText() {
  return [{ text: '星期一', value: 1 },
  { text: '星期二', value: 2 },
  { text: '星期三', value: 3 },
  { text: '星期四', value: 4 },
  { text: '星期五', value: 5 },
  { text: '星期六', value: 6 },
  { text: '星期日', value: 0 }];
}

export function GetChNumberText(number: number) {
  var str = number.toString();
  var ret = '';
  for (var c of str) {
    switch (c) {
      case '0': ret += '零'; break;
      case '1': ret += '一'; break;
      case '2': ret += '二'; break;
      case '3': ret += '三'; break;
      case '4': ret += '四'; break;
      case '5': ret += '五'; break;
      case '6': ret += '六'; break;
      case '7': ret += '七'; break;
      case '8': ret += '八'; break;
      case '9': ret += '九'; break;
    }
  }
  return ret;
}

export function RemoveAt<T1, T2>(array: T1[], item: T2, comparer?: (itemInArray: T1, item: T2) => boolean): T1[] {
  if (comparer == null) {
    comparer = (a, b) => (a as any) == (b as any);
  }
  return array.filter((a, i) => !comparer(a, item));
}
export function RemoveFirst<T1, T2>(array: T1[], item: T2, comparer?: (itemInArray: T1, item: T2) => boolean): T1[] {
  if (comparer == null) {
    comparer = (a, b) => (a as any) == (b as any);
  }
  var ind = array.findIndex((v)=>comparer(v,item));
  return array.filter((a, i) => i!=ind);
}

export function GroupBy<T>(array: T[], keySelector: (a: T) => string): { key: string, items: T[] }[] {
  const groups = array.reduce((acc: { [key: string]: T[] }, obj: T) => {
    const group = keySelector(obj);
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(obj);
    return acc;
  }, {});

  var ret: { key: string, items: T[] }[] = []
  for (var p in groups) {
    ret.push({ key: p, items: groups[p] });
  }
  return ret;
}

export function Sum<T>(array: T[], valueSelector: (a: T) => number): number {
  return array.reduce((sum: number, obj: T) => {
    const value = valueSelector(obj);
    return sum + value;
  }, 0);
}


export function verifyCID(input: string) {

}
export function verifyAllCID(input: string) {
  return verifyTwCID(input) || verifyNewResidentCId(input) || verifyOldResidentCId(input);
}
export function verifyAllCIDAndMsg(input: string) {
  var twCidResult = verifyTwCIDforMsg(input);
  var newCidResult = verifyNewResidentCIdAndMsg(input);
  var oldCidResult = verifyOldResidentCIdAndMsg(input);
  var feedback = { IsCid: false, Msg: '非正確身分證或居留證號格式' };
  if (twCidResult.IsCid || twCidResult.Msg.indexOf("最後一碼")>=0) {
    return twCidResult;
  } else {
    if (newCidResult.IsCid || newCidResult.Msg.indexOf("最後一碼")>=0) {
      return newCidResult;
    } else {
      if (oldCidResult.IsCid || oldCidResult.Msg.indexOf("最後一碼")>=0) {
        return oldCidResult;
      } else {
        return feedback;
      }
    }
  };
  //return verifyTwCIDforMsg(input) || verifyNewResidentCIdAndMsg(input) || verifyOldResidentCIdAndMsg(input);
}
/** 身分證 */
export function verifyTwCID(input: string) {
  // console.log('verifyTwCID', /^[A-Z][1,2]\d{8}$/.test(input));
  if (!/^[A-Z][1,2]\d{8}$/.test(input)) {
    return false;
  }
  return verifyTaiwanIdIntermediateString(input);
}
export function verifyTwCIDforMsg(input: string) {
  var feeback = { IsCid: false, Msg: '' };
  // console.log('verifyTwCID', /^[A-Z][1,2]\d{8}$/.test(input));
  if (!/^[A-Z][1,2]\d{8}$/.test(input)) {
    feeback.IsCid = false;
    feeback.Msg = '非標準身分證字號'
    return feeback;
  }
  return verifyTaiwanIdIntermediateStringAndMsg(input, true);
}
/** 新式居留證
 * 臺灣地區無戶籍國民、外國人、大陸地區人民及香港或澳門居民之專屬代號
*/
export function verifyNewResidentCId(input: string): boolean {
  const regex = /^[A-Z][8,9]\d{8}$/
  return regex.test(input) && verifyTaiwanIdIntermediateString(input)
}
export function verifyNewResidentCIdAndMsg(input: string) {
  const regex = /^[A-Z][8,9]\d{8}$/
  var isNewResident = regex.test(input)
  return verifyTaiwanIdIntermediateStringAndMsg(input, isNewResident)
}
/** 舊式居留證
 * 臺灣地區無戶籍國民、外國人、大陸地區人民及香港或澳門居民之專屬代號
*/
export function verifyOldResidentCId(input: string): boolean {
  const regex = /^[A-Z]{2}\d{8}$/
  return regex.test(input) && verifyTaiwanIdIntermediateString(input)
}
export function verifyOldResidentCIdAndMsg(input: string) {
  const regex = /^[A-Z]{2}\d{8}$/
  var isOldResident = regex.test(input)
  return verifyTaiwanIdIntermediateStringAndMsg(input, isOldResident)
}
// Copy from https://github.com/enylin/taiwan-id-validator/
export function verifyTaiwanIdIntermediateString(input: string): boolean {

  const intRadix = 10
  const TAIWAN_ID_LOCALE_CODE_LIST = [
    1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30
  ]
  const RESIDENT_CERTIFICATE_NUMBER_LIST = [
    0, 1, 2, 3, 4, 5, 6, 7, 4, 8, 9, 0, 1, 2, 5,
    3, 4, 5, 6, 7, 8, 9, 2, 0, 1, 3
  ]
  const getCharOrder = (s: string, i: number) =>
    s.charCodeAt(i) - 'A'.charCodeAt(0)

  const firstDigit = TAIWAN_ID_LOCALE_CODE_LIST[getCharOrder(input, 0)]

  const secondDigit = isNaN(parseInt(input[1], intRadix)) // if is not a number (舊版居留證編號)
    ? RESIDENT_CERTIFICATE_NUMBER_LIST[getCharOrder(input, 1)]
    : parseInt(input[1], intRadix)

  const rest = input
    .substring(2)
    .split('')
    .map(n => parseInt(n, intRadix))

  const idInDigits = [firstDigit, secondDigit, ...rest]

  // Step 2: 第 1 位數字 (只能為 1 or 2) 至第 8 位數字分別乘上 8, 7, 6, 5, 4, 3, 2, 1 後相加，再加上第 9 位數字

  const ID_COEFFICIENTS = [1, 8, 7, 6, 5, 4, 3, 2, 1, 1]


  function zipWith<T, R>(a1: T[], a2: T[], f: (v1: T, v2: T) => R): R[] {
    const length = Math.min(a1.length, a2.length)
    const result: R[] = []

    for (let i = 0; i < length; i++) result[i] = f(a1[i], a2[i])

    return result
  }

  function add(a: number, b: number) {
    return a + b
  }

  function multiply(a: number, b: number) {
    return a * b
  }

  const sum = zipWith(idInDigits, ID_COEFFICIENTS, multiply).reduce(add, 0)

  // Step 3: 如果該數字為 10 的倍數，則為正確身分證字號

  return sum % 10 === 0
}
// 此為客製化檢核身分證字號, 錯誤的話會顯示 feedback.Msg的訊息
export function verifyTaiwanIdIntermediateStringAndMsg(input: string, isTrueResident: boolean) {
  var feeback = { IsCid: false, Msg: '',validateNum:0};
  if (!isTrueResident) {
    feeback.IsCid = false;
    feeback.Msg = '非正確居留證'
    return feeback;
  }
  const intRadix = 10
  const TAIWAN_ID_LOCALE_CODE_LIST = [
    1, 10, 19, 28, 37, 46, 55, 64, 39, 73, 82, 2, 11, 20, 48, 29, 38, 47, 56, 65, 74, 83, 21, 3, 12, 30
  ]
  const RESIDENT_CERTIFICATE_NUMBER_LIST = [
    0, 1, 2, 3, 4, 5, 6, 7, 4, 8, 9, 0, 1, 2, 5,
    3, 4, 5, 6, 7, 8, 9, 2, 0, 1, 3
  ]
  const getCharOrder = (s: string, i: number) =>
    s.charCodeAt(i) - 'A'.charCodeAt(0)

  const firstDigit = TAIWAN_ID_LOCALE_CODE_LIST[getCharOrder(input, 0)]

  const secondDigit = isNaN(parseInt(input[1], intRadix)) // if is not a number (舊版居留證編號)
    ? RESIDENT_CERTIFICATE_NUMBER_LIST[getCharOrder(input, 1)]
    : parseInt(input[1], intRadix)

  const rest = input
    .substring(2)
    .split('')
    .map(n => parseInt(n, intRadix))

  const idInDigits = [firstDigit, secondDigit, ...rest]
  const idin = idInDigits.slice(0, idInDigits.length - 1);
  // console.log('idin', idin);
  // Step 2: 第 1 位數字 (只能為 1 or 2) 至第 8 位數字分別乘上 8, 7, 6, 5, 4, 3, 2, 1 後相加，再加上第 9 位數字

  const ID_COEFFICIENTS = [1, 8, 7, 6, 5, 4, 3, 2, 1, 1]


  function zipWith<T, R>(a1: T[], a2: T[], f: (v1: T, v2: T) => R): R[] {
    const length = Math.min(a1.length, a2.length)
    const result: R[] = []

    for (let i = 0; i < length; i++) result[i] = f(a1[i], a2[i])

    return result
  }

  function add(a: number, b: number) {
    return a + b
  }

  function multiply(a: number, b: number) {
    return a * b
  }

  const sum = zipWith(idInDigits, ID_COEFFICIENTS, multiply).reduce(add, 0)

  // Step 3: 如果該數字為 10 的倍數，則為正確身分證字號
  var calculatesum = sum % 10;
  if (calculatesum === 0) {
    feeback.IsCid = true;
    feeback.Msg = '';
  }
  else {
    feeback.IsCid = false;
    // 取前九碼的總和除以10之後的餘數 用餘數求最後一碼之正確的值
    let newNum = 10 - ((zipWith(idin, ID_COEFFICIENTS, multiply).reduce(add, 0)) % 10)
    if (newNum === 10) newNum = 0;
    feeback.Msg = '身分證最後一碼需為' + newNum;
    feeback.validateNum = newNum;
  }
  // return sum % 10 === 0
  return feeback
}

export function CreateXml(obj: any) {
  var xml = '<?xml version="1.0" encoding="Big5"?>\n';
  xml += ToXml(obj)
  return xml;
}
function ToXml(obj: any) {
  if (!(obj instanceof Object)) {
    return obj.toString();
  }
  var xml = '';
  for (let p in obj) {
    var value = obj[p];
    if (value instanceof Array) {
      // 陣列則連續產生同名Tag
      for (let v of value) {
        xml += `<${p}>\n`;
        xml += ToXml(v);
        xml += `</${p}>\n`;
      }
      continue;
    } else if (value instanceof Object) {
      var valueText = ToXml(value);
      if (valueText) {
        xml += `<${p}>\n`;
        xml += valueText;
        xml += `</${p}>\n`;
      }
    } else {
      if (value) {
        xml += `<${p}>`;
        xml += value;
        xml += `</${p}>\n`;
      }
      // 暫時先這樣 不確定慢籤 M23 不給TAG 會不會造成錯誤報表錯誤
      else if (value== '' && p == 'M23'){
        xml += `<${p}/>`
      }
    }

  }
  return xml;
}
/** 從後方移除連續特定字元 */
export function TrimEnd(str: string, char: string) {
  if(!str){
    return '';
  }
  var ret = str;
  while (ret.endsWith(char)) {
    ret = ret.substring(0, ret.length - char.length);
  }
  return ret
}

/** 數值轉千分位符號格式 */
export function ToThousandFixed(value){
  if (value) {
    const parts = value.toString();
    return parts.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,',');
  }
  return value;
}

export function deepCopy<T>(instance: T): T {
  if (instance == null) {
    return instance;
  }

  // handle Dates
  if (instance instanceof Date) {
    return new Date(instance.getTime()) as any;
  }

  // handle Array types
  if (instance instanceof Array) {
    var cloneArr = [] as any[];
    (instance as any[]).forEach((value) => { cloneArr.push(value) });
    // for nested objects
    return cloneArr.map((value: any) => deepCopy<any>(value)) as any;
  }
  // handle objects
  if (instance instanceof Object) {
    var copyInstance = {
      ...(instance as { [key: string]: any }
      )
    } as { [key: string]: any };
    for (var attr in instance) {
      if ((instance as Object).hasOwnProperty(attr))
        copyInstance[attr] = deepCopy<any>(instance[attr]);
    }
    return copyInstance as T;
  }
  // handling primitive data types
  return instance;
}

/** 將物件的key轉成小寫
 * 例如雲端查詢api新舊版回傳的json key為大小寫不一致，都轉小寫來判斷。
 */
export function keysToLowerCase(obj) {
  if(obj instanceof Array) {
      for (var i in obj) {
          obj[i] = keysToLowerCase(obj[i]);
      }
  }
  if (!(typeof(obj) === "object") || typeof(obj) === "string" || typeof(obj) === "number" || typeof(obj) === "boolean") {
      return obj;
  }
  var keys = Object.keys(obj);
  var n = keys.length;
  var lowKey;
  while (n--) {
      var key = keys[n];
      if (key === (lowKey = key.toLowerCase())) {
        if (obj[key] instanceof  Array) {
          obj[key] = keysToLowerCase(obj[key]);
        }
        continue;
      }
      obj[lowKey] = keysToLowerCase(obj[key]);
      delete obj[key];
  }
  return (obj);
}
