import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HcrConsoleComponent } from 'src/app/card-reader/hcr-console/hcr-console.component';
import { BaseConfig } from 'src/app/services/base-config.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { UserCache } from 'src/app/services/user-cache';
import { UserConfirmService } from 'src/app/services/user-confirm.service';

@Component({
  selector: 'app-console-icon',
  templateUrl: './console-icon.component.html',
  styleUrls: ['./console-icon.component.css']
})
export class ConsoleIconComponent implements OnInit {
  isHCREnable = false;
  showHCRDialog = false;
  nhiWebApiError = true;
  isHcrDemo = false;
  @ViewChild('hcrConsole', { static: true }) HcrConsoleComponent: HcrConsoleComponent;
   //若是正式台，則預設不顯示「展示模式」
  get isShowHcrDemo(){
    return BaseConfig.isDebug();
  };

  constructor(
      private hcrService:HcrService,
      private confirmService:UserConfirmService) {
    this.hcrService.SetConsoleIconComponent(this);
   }
  public UpdateConsoleState(st:boolean){
    setTimeout(async () => {
      this.isHCREnable = st;
    }, 0);

  }
  ngOnInit(): void {
    //若是正式台，則預設不顯示「展示模式」，但是若User有IsAdmin的Flag權限，則也可以看到「展示模式」的按鈕
    // if(this.isShowHcrDemo == false){
    //   if(UserCache.getLoginUser().IsAdmin){
    //     this.isShowHcrDemo = true;
    //   }
    // }
  }


  openHCR() {
    this.hcrService.Connect().catch(e => { });;
  }

  async closeHCR() {
    var ret = await this.confirmService.showConfirm({
      'msg': '確定要關閉連線?',
      'title': '關閉連線'
    })
    if (ret == true) {
      this.hcrService.Close();
    }

  }
}
