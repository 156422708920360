import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LabPrescriptionPageCollection } from 'src/app/services/api-service/hist/hist-api.model';
import { LabPrescriptionReportComponent } from '../lab-prescription-report/lab-prescription-report.component';

@Component({
  selector: 'app-lab-prescription-report-preview',
  templateUrl: './lab-prescription-report-preview.component.html',
  styleUrls: ['./lab-prescription-report-preview.component.css']
})
export class LabPrescriptionReportPreviewComponent implements OnInit {

  @ViewChild('detailPDF') detailPDF: LabPrescriptionReportComponent;

  @Input() reportData: LabPrescriptionPageCollection;

  constructor() { }

  ngOnInit(): void {
  }

  onReportPrint = () => {
    this.detailPDF.onPrint();
  }

}
