import { Clinic } from '../../system/clinic/models/clinic';
import { LoginMenu } from './login-menu';
import { LoginFunction } from './login-function';

export class LoginUser {
  Account:string;
  // Token
  AuthToken: string;
  SSOToken:string;
  ConsoleToken:string;
  Clinic: Clinic;
  ClinicName:string;
  // Company
  CompanyCode: string;
  CompanyName: string;

  ExpiresOn: Date;
  // User
  IsAdmin: boolean;
  IsSuper: boolean;
  UserId: number;
  Name: string;
  CId: string;
  MedLicense: string;
  //Email: string;
  
  // Clinic
  
  Menus: LoginMenu[];
  // 前端只管前端路由是否可存取
  Routes: LoginFunction[];

  get isExpired(): boolean {
    if (new Date(this.ExpiresOn) < new Date()) {
      return true;
    } else {
      return false;
    }
  }
}
