import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { RegisterEditDto } from 'src/app/services/api-service/register/RegisterEditDto';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';

@Component({
  selector: 'app-register-report',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;
  regist: RegisterEditDto;
  clinic: Clinic;

  cidMask = '1234567890';
  bitrhMask = 'YYY年MM月DD日';
  fontType: string;

  @Input()
  set regId(id) {
    this.setRegId(id);
  }
  constructor(private api: RegisterApi,
    private parameterApi: ParameterApi,
    private clinicDataService: ClinicDataService
  ) { }

  async ngOnInit() {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.reg-report table{ border:1px solid black; border-collapse: collapse;width:calc(100% - 40px);margin:5px 20px; }\n' +
      '.reg-report table td, table th { border: 1px solid black;padding: 5px;vertical-align: baseline;}\n' +
      '.reg-report table tr:first-child th { border-top: 0; }\n' +
      '.reg-report table tr:last-child td { border-bottom: 0; }\n' +
      '.reg-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.reg-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }
  get clinicName() {
    return this.clinic?.Name;
  }
  get patientNo() {
    return this.regist?.PatientNo;
  }
  get patientName() {
    return this.regist?.PatientFirstName;
  }
  get patientBirth() {
    return this.regist?.PatientBirth;
  }
  get regDate() {
    return this.regist?.RegDate;
  }
  get regSec() {
    return this.regist?.TimeSecName;
  }
  get room() {
    return this.regist?.RoomName;
  }
  get medDeptName() {
    return this.regist?.MedDeptName;
  }
  get doctorName() {
    return this.regist?.DoctorName;
  }
  get waitNo() {
    return this.regist?.WaitNo;
  }
  get remark() {
    return this.regist?.Remark;
  }
  get address() {
    return this.clinic.City + this.clinic.Area + this.clinic.Street;
  } get clinicPhone() {
    return this.clinic.Phone;
  }


  async setRegId(regId: number) {
    this.clinic = UserCache.getLoginUser().Clinic;
    this.regist = await this.api.Get(regId);
    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;
    this.bitrhMask = param.BirthdayHidePRN ?? this.bitrhMask;
    this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
  }
  getHtml(): string {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }

}
