import { Injectable } from "@angular/core";
import { TimeSecEnum } from "src/app/enums/TimeSecEnum";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

@Injectable({
    providedIn: 'root'
})
export class ScheduleApi {
  api: WebApiService;
  clinicApi: WebApiService

  constructor(
    private apiClient: WebApiClient) {
    this.api = this.apiClient.createHisService('schedule/ShiftSchedule');
    this.api.enableLoader = false;
  }
  async GetPharmarcist(date:Date,sec: TimeSecEnum):Promise<ValueTextPairNumberValue[]>{
      return await this.api.get('GetPharmarcist',{
          year:date.getFullYear(),
          month:date.getMonth()+1,
          day:date.getDate(),
          timeSec:sec
      }).toPromise<ValueTextPairNumberValue[]>();
  }
  async HasSchedule(date:Date,sec:TimeSecEnum):Promise<boolean>{
    return await this.api.get('HasSchedule',{
      year:date.getFullYear(),
      month:date.getMonth()+1,
      day:date.getDate(),
      timeSec:sec
    }).toPromise<boolean>();
  }
  async HasMonthSchedule(date:Date):Promise<boolean>{
    return await this.api.get('HasMonthSchedule',{
      year:date.getFullYear(),
      month:date.getMonth()+1,
      day:date.getDate(),
    }).toPromise<boolean>();
  }

  async GetData():Promise<ScheduleData>{
    return await this.api.get('GetData',null).toPromise<ScheduleData>();
  }
  async GenMonthFromWeek(startDate: Date,endDate:Date):Promise<void>{
    return await this.api.post('GenMonthFromWeek?start='+startDate.toLocaleDateString()
                                                +'&end='+endDate.toLocaleDateString(),null).toPromise();
  }
  async GetReserve(clinicId:number,sDate:Date,eDate:Date):Promise<ReserveScheduleDto[]>{
     return await this.api.get('GetReserve?clinicId='+clinicId+'&start='+sDate.toLocaleDateString()
                                                +'&end='+eDate.toLocaleDateString()).toPromise<ReserveScheduleDto[]>();
  }
}
export class ScheduleData{
  medRoomList:ValueTextPair[];
  dyDoctors:ValueTextPair[];
  dyPh:ValueTextPair[];
  timeSection:ValueTextPair[];
  lastGenMonthDate:Date;
}
export interface ReserveScheduleDto{
  Date:Date,
  Sec:TimeSecEnum,
  Reserves:{
    DoctorId:number,
    DoctorName:string,
    RoomCode:string,
    ReserveCount:number
  }[]
}