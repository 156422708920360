import { Directive, HostListener, ElementRef, Input } from '@angular/core';


@Directive({
  selector: '[appNumberOnly]'
})
export class NumberOnlyDirective {
  @Input()
  max: number;
  @Input()
  min: number;
  @Input()
  fixed: number = 1;
  /**
   *是否允許輸入負數
   *
   * @type {boolean}
   * @memberof NumberOnlyDirective
   */
  @Input()
  allowNegative: boolean = false;

  /**
   * 是否允許小數點
   *
   * @type {boolean}
   * @memberof NumberOnlyDirective
   */
  @Input()
  allowDecimal: boolean = true;


  constructor(private el: ElementRef<HTMLInputElement>) {
    if (!el.nativeElement.type || el.nativeElement.type.toLowerCase() != 'number') {
      el.nativeElement.type = 'tel';
    }
    el.nativeElement.autocomplete = "off";
  }


  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    const keysAllowed = [
      'Delete', 'Backspace', 'Tab', 'Escape', 'Enter',
      'ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown',
      'Home', 'End', 'Left', 'Right',
      '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      'Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4',
      'Numpad5', 'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9'
    ];

    if (this.allowDecimal) {
      keysAllowed.push('.'); // 允許小數點
      keysAllowed.push('NumpadDecimal');
    }

    if (this.allowNegative) {
      keysAllowed.push('-'); // 允許負號
    }

    if (
      keysAllowed.includes(e.key) ||
      (e.key.toUpperCase() === 'A' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key.toUpperCase() === 'C' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key.toUpperCase() === 'V' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key.toUpperCase() === 'X' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key.toUpperCase() === 'A' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key.toUpperCase() === 'C' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key.toUpperCase() === 'V' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key.toUpperCase() === 'X' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      const el = e.target as HTMLInputElement;
      const curValue = el.value;

      // 只能打一個小數點
      if (this.allowDecimal && e.key === '.') {
        if (curValue.includes('.')) {
          e.preventDefault();
        } else if (!curValue || el.selectionStart === 0) {
          e.target['value'] = '0' + curValue ?? '';
          el.selectionStart = 1;
          el.selectionEnd = 1;
        }
      }
      return;
    } else {
      e.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    var el = (e.target as HTMLInputElement);
    // 定義正則表達式來過濾非數字、非小數點和非負號的字符
    let nonDigitRegex = /[^0-9]+/g;

    if (this.allowDecimal) {
      nonDigitRegex = /[^0-9.]+/g
    }

    if (this.allowNegative) {
      nonDigitRegex = /[^0-9-]+/g
    }

    if (this.allowDecimal && this.allowNegative) {
      nonDigitRegex = /[^0-9.-]+/g
    }

    el.value = el.value.replace(nonDigitRegex, '');

    // 如果只有一個負號或者為空，直接返回
    if (el.value.length < 1 || el.value === '-') return;

    // 如果允許負號，確保負號只能出現在第一位
    if (this.allowNegative && el.value.includes('-')) {
      // 只保留第一個負號並移除其他位置的負號
      el.value = '-' + el.value.replace(/-/g, '');
    }

    if (this.allowDecimal) {
      // 留下第一個小數點，過濾其他小數點
      var expRet = el.value.match(/[0-9.]/g).filter((x, i, a) => x !== '.' || a.indexOf(x) === i);

      // 如果第一個字符是小數點，則在前面添加 '0'
      if (expRet[0] === '.') {
        expRet = ['0'].concat(expRet);
      }

      el.value = el.value.startsWith('-') ? '-' + expRet.join('') : expRet.join('');
    }


    // 解析數值並進行最大值和最小值檢查
    var value = parseFloat(el.value);
    if (this.max !== undefined && value > this.max) {
      el.value = this.max.toFixed(this.fixed);
      return;
    }
    if (this.min !== undefined && value < this.min) {
      el.value = this.min.toFixed(this.fixed);
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();

    // 獲取貼上的內容
    let pastedInput: string = event.clipboardData.getData('text/plain');

    // 根據是否允許負數和小數點進行過濾
    if (this.allowDecimal) {
      pastedInput = this.allowNegative ? pastedInput.replace(/[^0-9.-]+/g, '') : pastedInput.replace(/[^0-9.]+/g, '');
    } else {
      pastedInput = this.allowNegative ? pastedInput.replace(/[^0-9-]+/g, '') : pastedInput.replace(/[^0-9]+/g, '');
    }

    // 如果有負號，確保負號只在第一個字符位置
    if (this.allowNegative && pastedInput.includes('-')) {
      pastedInput = '-' + pastedInput.replace(/-/g, '');
    }

    const inputElement = event.target as HTMLInputElement;

    // 插入過濾後的內容，替代選中的文本或插入光標位置
    const start = inputElement.selectionStart || 0;
    const end = inputElement.selectionEnd || 0;

    inputElement.setRangeText(pastedInput, start, end, 'end');
  }
}



