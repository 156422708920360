import { Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subscriber } from 'rxjs';
import { isNullOrUndefined } from 'src/app/shared/utilities';

declare type option = { text: string, value: any };
declare type formatType = 'value' | 'text' | 'valuetext';
export const VIS_CB_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisComboboxComponent),
  multi: true
};
@Component({
  selector: 'app-vis-combobox',
  templateUrl: './vis-combobox.component.html',
  styleUrls: ['./vis-combobox.component.css'],
  providers: [VIS_CB_CONTROL_VALUE_ACCESSOR]
})
export class VisComboboxComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @ContentChild(TemplateRef)
  displayTmp: TemplateRef<any>;

  @Input()
  isDxFiled: Boolean;
  @Input()
  upperCase:boolean
  @Input()
  set data(value: option[]) {
    if (value) {
      this._data = value.map(x => Object.assign({}, x));
      this.changeOptions();
    } else {
      this._data = [].map(x => Object.assign({}, x));
    }
  }
  get data(): option[] {
    return this._data;
  }

  @Input()
  allowCustom: Boolean;
  @Input()
  allowInput: Boolean = true;
  @Input()
  filterable: Boolean;
  @Input()
  textField: string = 'text';
  @Input()
  valueField: string = 'value';
  @Input()
  valuePrimitive: Boolean;
  @Input()
  emitPrev: boolean;
  @Input()
  panelFitContent: Boolean;

  @Input()
  panelClass: string = 'a';
  /**
   * 顯示方式 value:只顯示值 text:只顯示文字 valuetext:顯示值+文字
   * @type {formatType} 'value'|'text'|'valuetext'
   * @memberof VisComboboxComponent
   */
  @Input()
  valueFormat: formatType;

  @Input()
  set value(v: any){
    this.writeValue(v);
  };
  @Input()
  defaultValue: any;
  @Input()
  set disabled(d: boolean){
    this.setDisabledState(d);
  }
  get disabled(){
    return this._disabled;
  }
  @Input()
  ignoreCase = true;

  @Input()
  // 處理輸入的值帶不在其 ComboBOX之data時
  set setNotInComboData(v: boolean) {
    this._setNotInComboData = v
  }

  @Input() customClass?: string = '';
  @Output()
  filterChange: EventEmitter<any> = new EventEmitter(false);
  @Output()
  valueChange: EventEmitter<any> = new EventEmitter(false);
  @Output()
  valueInput: EventEmitter<any> = new EventEmitter(false);

  @ViewChild('input')
  inputElement: ElementRef<HTMLInputElement>
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  _setNotInComboData: boolean;
  _currentValue: any;
  _prevValue: any;
  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean = false;
  _displayText = '';
  _class: string;
  _data: option[];
  filteredOptions: Observable<option[]>;
  options: Subscriber<option[]>;
  constructor(private elementRef: ElementRef) {
  }

  optInit = false;
  ngOnInit(): void {
    //this._disabled = this.disabled;
    if (this.defaultValue != null) this._currentValue = this.defaultValue;
    if (this._currentValue === null || this._currentValue === undefined) {
      this._currentValue =
        ((this.defaultValue === null || this.defaultValue === undefined) ? '' : this.defaultValue.value);
    }
    this.changeOptions();
  }

  changeOptions() {
    this.filteredOptions = new Observable(subscriber => {
      this.options = subscriber;
      this.options?.next(this.data?.filter(d => d.text?.indexOf(this._displayText) >= 0));
      if (this.data) {
        if (this.valueFormat != undefined) {
          for (var x of this.data) {
            if (x.text) {
              // string || number
              if ((typeof (x.value) == 'string' && !!x.value) || (typeof (x.value) == 'number')) {
                x.text = x.text.indexOf('|') > 0 ? x.text : x.value + "|" + x.text
              }
            }

            if (this._currentValue == x.value) {
              this.setDisplayText(this.valueFormat, x.text);
            }
          }
          if(this._setNotInComboData){
            var a = this._currentValue;
            this.setDisplayText(this.valueFormat,this._currentValue);
          }
        } else {
          var item = this.data.find(x => x.value == this._currentValue);
          var text = item?.text || (this._setNotInComboData?this._currentValue:'')
          this.setDisplayText(this.valueFormat,text);
        }
        this.options?.next(this.data);
        this.optInit = true;
      }
      // 沒資料的時候也要塞值
      else if(this._setNotInComboData){
        var a = this._currentValue;
        this.setDisplayText(this.valueFormat,this._currentValue);
      }
    });
  }

  ngOnDestroy() {
    this.options?.unsubscribe();
  }

  ngAfterContentChecked() {
    this._class = this.elementRef.nativeElement.className;
  }

  inputChange() {
    var text = this.inputElement.nativeElement.value;
    if (this.allowInput) {
      this.options?.next(this.data?.filter(d => d.text?.toLowerCase().includes(text?.toLowerCase())));
    } else {
      this.inputElement.nativeElement.value = this._displayText;
    }
    this.valueChange.emit(text);
  }

  onInputClick() {
    this.openPanel();
  }

  writeValue(obj: any): void {
    if (!isNullOrUndefined(obj)) {
      this._prevValue = this._currentValue;
      this._currentValue = obj;
      var item = this.data?.find(d => d.value == this._currentValue)
      var text = this.data?.find(d => d.value == this._currentValue)?.text ?? '';

      // 處理輸入的值帶不在其 ComboBOX之data時
      if (this._setNotInComboData && text == '') {

        text = this._currentValue;
      }
      // 還沒Init完成就跳過，等Init完成時會在設定一次顯示文字
      if (this.optInit) {
        this.setDisplayText(this.valueFormat, text);
      }
      this.options?.next(this.data?.filter(d => d.text?.indexOf(this._displayText) >= 0));

    } else {
      // clear
      this._prevValue = this._currentValue;
      this._currentValue = obj;
      this._displayText = '';
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }
  optSelected(evt: MatAutocompleteSelectedEvent) {
    this._prevValue = this._currentValue;
    this._currentValue = evt?.option?.value;
    var item = this.data.find(d => d.value == this._currentValue)
    setTimeout(() => {
      this.setDisplayText(this.valueFormat, item?.text ?? '');
    }, 0);

    if (this._onChange) {
      this._onChange(this._currentValue)
    }
    if (this.valuePrimitive) {
      if (this.emitPrev) {
        this.valueChange.emit({ value: evt?.option?.value, prev: this._prevValue });
      } else {
        this.valueChange.emit(evt?.option?.value);
      }
    } else {
      if (this.emitPrev) {
        var pItem = this.data.find(d => d.value == this._prevValue)
        this.valueChange.emit({ value: evt?.option, prev: pItem });
      } else {
        this.valueChange.emit(evt?.option);
      }
    }
  }

  onKeyDown(evt: KeyboardEvent) {
    if (evt.key == 'Enter') {
      if (this.isDxFiled) {
        var item: option = null;
        if (evt.currentTarget['value'] != undefined && evt.currentTarget['value'] != null && evt.currentTarget['value'] != '') {
          switch (this.valueFormat) {
            case 'valuetext':
              item = this.data.find(d => (d.value + '' + d.text)?.toLowerCase().includes(evt.currentTarget['value']?.toLowerCase()));
              break;
            case 'value':
              item = this.data.find(d => d.value?.toLowerCase().includes(evt.currentTarget['value']?.toLowerCase()));
              break;
            case 'text':
            default:
              item = this.data.find(d => d.text?.toLowerCase().includes(evt.currentTarget['value']?.toLowerCase()));
              break;
          }
        }
      }
      var value = evt.currentTarget['value']?.toLowerCase();
      if(this.upperCase){
        value = value.toUpperCase();
      }
      if (this.valuePrimitive) {
        if (this.emitPrev) {
          this.valueChange.emit({ value: value, prev: this._prevValue });
        } else {
          this.valueChange.emit(value);
        }
      } else {
        if (this.emitPrev) {
          var pItem = this.data.find(d => d.value == this._prevValue)
          this.valueChange.emit({ value: {value:value,text:''}, prev: pItem });
        } else {
          this.valueChange.emit({value:value,text:''});
        }
      }
      this.autoComplete.closePanel();
      this.options.next(this.data);
    }else{
      if(this.upperCase){
        (evt.target as HTMLInputElement).value = (evt.target as HTMLInputElement).value.toUpperCase();
      }
    }
  }


  setDisplayText(_format: formatType, text: string) {
    var _content = text;
    var ret = '';
    switch (_format) {
      case 'value':
        ret = _content?.split('|')[0]
        if (ret == '' && _content != '') {
          ret = _content?.split('|')[1]
        }
        break
      case 'text':
        ret = _content?.split('|')[1]
        if (ret == '' && _content != '') {
          ret = _content?.split('|')[0]
        }
        break
      default:
        ret = '' + _content
        break
    }
    // 因為選取同一個選項時 MAT-AUTOCOMPLETE會把VALUE設定到INPUT中，而透過[VALUE]去綁定 UI不會去更新一樣的值
    // 所以用比較暴力的方式直接設定ELEMENT的VALUE

    setTimeout(() => {
      this._displayText = ret;
      this.inputElement.nativeElement.value = this._displayText;
    }, 0);
  }

  openPanel() {
    if (this._disabled) {
      return;
    }
    // console.log(this.autoComplete)
    this.options.next(this.data);
    setTimeout(() => {
      this.autoComplete.openPanel();
    }, 0);
  }
}
