import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { PatientsDto, QueryPatient } from 'src/app/services/api-service/patient/patientsDto';
import { HcrService } from 'src/app/services/hcr-service.service';

@Component({
  selector: 'app-patients-patient-query-dialog',
  templateUrl: './patient-query-dialog.component.html',
  styleUrls: ['./patient-query-dialog.component.css']
})
export class PatientQueryDialogComponent implements OnInit {

  _isDialogOpened = false;
  @Input() set isDialogOpened(value: boolean) {
    this._isDialogOpened = value;
  }

  @Input()
  demoIc: boolean = false;

  @Output() onEmitResult = new EventEmitter<PatientListDto>();
  @Output() onEmitICResult = new EventEmitter<PatientListDto>();
  @Output() onEmitIC = new EventEmitter();
  @Output() onEmitQueryNotFound = new EventEmitter<QueryPatient>();

  selectedPatient: PatientListDto;

  constructor(private hcrService: HcrService) { }

  get isDisableBtnSelect() {
    if (!this.selectedPatient || !this.selectedPatient.Id) {
      return true;
    } else {
      return false;
    }
  }

  ngOnInit() {
  }

  onDialogClose(status: string) {
    if (status === 'ok') {
      this.onEmitResult.emit(this.selectedPatient);
    }
    // DemoMode才會開啟這個按鈕
    else if (status === 'ic') {
      this.hcrService.setDemoPatient({
        CName: this.selectedPatient.Name,
        Id: this.selectedPatient.Id,
        CId: this.selectedPatient.CId,
        Birthday: this.selectedPatient.Birthday,
        PatientNo: this.selectedPatient.PatientNo,
        Sex: this.selectedPatient.Sex
      })
      this.onEmitICResult.emit(this.selectedPatient);
    } else {
      // 這次沒選任何病患
      this.onEmitResult.emit(null);
    }
  }
  onDialogChose(event: PatientListDto) {
    this.onEmitResult.emit(event);
  }
  onQueryNotFound(event: QueryPatient) {
    this.onEmitQueryNotFound.emit(event);
  }

  receivePatientChange(event: PatientListDto) {
    this.selectedPatient = event;
    // NoPatient請參考patient-query的元件說明
    if (event != null && event.Id === 0) {
      this.onEmitResult.emit(event);
    }
  }
}
