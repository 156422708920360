export class H10Helper{

    /**
     *
     */
    constructor(public source:H10) {}
    /** [資料格式，請填"10"] */
    get t1_type (){ return this.source.e10tp;}
    /** [醫事服務機構代號] */
    get t2_nhiCode(){ return this.source.e10cd;}
    /** [費用年月，前三碼為年份後二碼為月份] */
    get t3_month(){ return this.source.e10ym;}
    /** [申報方式：1書面 2媒體 3連線] */
    get t4_declareWay(){ return this.source.e10mt;}
    /** [申報類別：1送核 2補報] */
    get t5_declareType(){ return this.source.e10kd;}
    /** [申報日期] */
    get t6_declareDate(){ return this.source.e10da;}
    /** [西醫一般案件件數] */
    get t7_caseCount(){ return this.source.e10n0;}
    /** [西醫一般案件申請點數] */
    get t8_casePoint(){ return this.source.e10n1;}
    /** [西醫專案案件件數] */
    get t9_projectCount(){ return this.source.e10n2;}
    /** [西醫專案案件申請點數] */
    get t10_projectPoint(){ return this.source.e10n3;}
    /** t11[洗腎案件件數] */
    get t11_KidneydialysisCount(){ return this.source.e10hn;}
    /** t12←[洗腎案件申請點數] */
    get t12_KidneydialysisPoint(){ return this.source.e10ha;}
    /** t13[精神疾病社區復健件數] */
    get t13_PsychiatryCount(){ return this.source.e10bn;}
    /** t14←[精神疾病社區復健申請點數] */
    get t14_PsychiatryPoint(){ return this.source.e10ba;}
    /** t15[結核病件數] */
    get t15_tuberculosisCount(){ return this.source.e10fn;}
    /** t16←[結核病申請點數] */
    get t16_tuberculosisPoint(){ return this.source.e10fa;}
    /** t17[西醫件數小計] */
    get t17_caseSum(){ return this.source.e10nn;}
    /** t18←[西醫申請點數小計] */
    get t18_casePointSum(){ return this.source.e10na;}
    /** t19[牙醫一般案件件數] */
    get t19_dentalCaseCount(){ return this.source.e10d0;}
    /** t20←[牙醫一般案件申請點數] */
    get t20_dentalCasePoint(){ return this.source.e10d1;}
    /** t21[牙醫專案案件件數] */
    get t21_dentalProjectCount(){ return this.source.e10d2;}
    /** t22←[牙醫專案案件申請點數] */
    get t22_dentalProjectPoint(){ return this.source.e10d3;}
    /** t23[牙醫件數小計] */
    get t23_dentalSum(){ return this.source.e10dn;}
    /** t24←[牙醫申請點數小計] */
    get t24_dentalPointSum(){ return this.source.e10dm;}
    /** t25[中醫一般案件件數] */
    get t25_chineseCaseCount(){ return this.source.e10c0;}
    /** t26←[中醫一般案件申請點數] */
    get t26_chineseCasePoint(){ return this.source.e10c1;}
    /** t27[中醫專案案件件數] */
    get t27_chineseProjectCount(){ return this.source.e10c2;}
    /** t28←[中醫專案案件申請點數] */
    get t28_chineseProjectPoint(){ return this.source.e10c3;}
    /** t29[中醫件數小計] */
    get t29_chineseSum(){ return this.source.e10cn;}
    /** t30←[中醫申請點數小計] */
    get t30_chinesePoint(){ return this.source.e10ca;}
    /** t31[預防保健件數] */
    get t31_HealthPreventCount(){ return this.source.e10pn;}
    /** t32←[預防保健申請點數] */
    get t32_HealthPreventPoint(){ return this.source.e10pa;}
    /** t33[慢性病連續處方調劑件數] */
    get t33_ChronicPrescriptCount(){ return this.source.e10ln;}
    /** t34←[慢性病連續處方調劑申請點數] */
    get t34_ChronicPrescriptPoint(){ return this.source.e10la;}
    /** t35[居家照護件數] */
    get t35_HomeCareCount(){ return this.source.e10rn;}
    /** t36←[居家照護申請點數] */
    get t36_HomeCarePoint(){ return this.source.e10ra;}
    /** t37[件數總計] */
    get t37_TotalSum(){ return this.source.e10tn;}
    /** t38←[申請點數總計] */
    get t38_PointTotalSum(){ return this.source.e10ta;}
    /** t39[部分負擔件數總計] */
    get t39_CopaymentCount (){ return this.source.e109n;}
    /** t40←[部分負擔點數總計] */
    get t40_CopaymentPoint (){ return this.source.e1098;}
    /** t41[此次連線申報起日期] */
    get t41_StartDate(){ return this.source.e10sd;}
    /** t42[此次連線申報迄日期] */
    get t42_EndDate(){ return this.source.e10ed;}
  }

/** 門診申報-總表段(H10) */
export interface H10 {

    /** table stoarge用 */
    PartitionKey: string;
    /** table stoarge用  */
    RowKey: string;
    /** table stoarge用 */
    Timestamp: Date;
    /** table stoarge用 */
    ETag: string;
    /** 診所id */
    clinicid: number;
    /** 健保欄位=(t1)長度F:2→2字元←[資料格式，請填"10"] */
    e10tp: string;
    /** 健保欄位=(t2)長度F:10→10字元←[醫事服務機構代號] */
    e10cd: string;
    /** 健保欄位=(t3)長度F:5→5字元←[費用年月，前三碼為年份後二碼為月份] */
    e10ym: string;
    /** 健保欄位=(t4)長度F:1→1字元←[申報方式：1書面 2媒體 3連線] */
    e10mt: string;
    /** 健保欄位=(t5)長度F:1→1字元←[申報類別：1送核 2補報] */
    e10kd: string;
    /** 健保欄位=(t6)長度F:7→7字元←[申報日期] */
    e10da: string;
    /** 健保欄位=(t7)長度N:1→6字元←[西醫一般案件件數] */
    e10n0: string;
    /** 健保欄位=(t8)長度N:1→10字元←[西醫一般案件申請點數] */
    e10n1: string;
    /** 健保欄位=(t9)長度N:1→6字元←[西醫專案案件件數] */
    e10n2: string;
    /** 健保欄位=(t10)長度N:1→10字元←[西醫專案案件申請點數] */
    e10n3: string;
    /** 健保欄位=(t11)長度N:1→6字元←[洗腎案件件數] */
    e10hn: string;
    /** 健保欄位=(t12)長度N:1→10字元←[洗腎案件申請點數] */
    e10ha: string;
    /** 健保欄位=(t13)長度N:1→6字元←[精神疾病社區復健件數] */
    e10bn: string;
    /** 健保欄位=(t14)長度N:1→10字元←[精神疾病社區復健申請點數] */
    e10ba: string;
    /** 健保欄位=(t15)長度N:1→6字元←[結核病件數] */
    e10fn: string;
    /** 健保欄位=(t16)長度N:1→10字元←[結核病申請點數] */
    e10fa: string;
    /** 健保欄位=(t17)長度N:1→8字元←[西醫件數小計] */
    e10nn: string;
    /** 健保欄位=(t18)長度N:1→10字元←[西醫申請點數小計] */
    e10na: string;
    /** 健保欄位=(t19)長度N:1→6字元←[牙醫一般案件件數] */
    e10d0: string;
    /** 健保欄位=(t20)長度N:1→10字元←[牙醫一般案件申請點數] */
    e10d1: string;
    /** 健保欄位=(t21)長度N:1→6字元←[牙醫專案案件件數] */
    e10d2: string;
    /** 健保欄位=(t22)長度N:1→10字元←[牙醫專案案件申請點數] */
    e10d3: string;
    /** 健保欄位=(t23)長度N:1→8字元←[牙醫件數小計] */
    e10dn: string;
    /** 健保欄位=(t24)長度N:1→10字元←[牙醫申請點數小計] */
    e10dm: string;
    /** 健保欄位=(t25)長度N:1→6字元←[中醫一般案件件數] */
    e10c0: string;
    /** 健保欄位=(t26)長度N:1→10字元←[中醫一般案件申請點數] */
    e10c1: string;
    /** 健保欄位=(t27)長度N:1→6字元←[中醫專案案件件數] */
    e10c2: string;
    /** 健保欄位=(t28)長度N:1→10字元←[中醫專案案件申請點數] */
    e10c3: string;
    /** 健保欄位=(t29)長度N:1→8字元←[中醫件數小計] */
    e10cn: string;
    /** 健保欄位=(t30)長度N:1→10字元←[中醫申請點數小計] */
    e10ca: string;
    /** 健保欄位=(t31)長度N:1→6字元←[預防保健件數] */
    e10pn: string;
    /** 健保欄位=(t32)長度N:1→10字元←[預防保健申請點數] */
    e10pa: string;
    /** 健保欄位=(t33)長度N:1→6字元←[慢性病連續處方調劑件數] */
    e10ln: string;
    /** 健保欄位=(t34)長度N:1→10字元←[慢性病連續處方調劑申請點數] */
    e10la: string;
    /** 健保欄位=(t35)長度N:1→6字元←[居家照護件數] */
    e10rn: string;
    /** 健保欄位=(t36)長度N:1→10字元←[居家照護申請點數] */
    e10ra: string;
    /** 健保欄位=(t37)長度N:1→8字元←[件數總計] */
    e10tn: string;
    /** 健保欄位=(t38)長度N:1→10字元←[申請點數總計] */
    e10ta: string;
    /** 健保欄位=(t39)長度N:1→8字元←[部分負擔件數總計] */
    e109n: string;
    /** 健保欄位=(t40)長度N:1→10字元←[部分負擔點數總計] */
    e1098: string;
    /** 健保欄位=(t39)長度N:1→8字元←[{洗腎}部分負擔件數總計] */
    e109nh: string;
    /** 健保欄位=(t40)長度N:1→10字元←[{洗腎}部分負擔點數總計] */
    e1098h: string;
    /** 健保欄位=(t41)長度S:0→7字元←[此次連線申報起日期] */
    e10sd: string;
    /** 健保欄位=(t42)長度S:0→7字元←[此次連線申報迄日期] */
    e10ed: string;
    /** 建立者 */
    creater: string;
    /** 建立時間 */
    createtime: Date;
}
