import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-rect-button',
  templateUrl: './rect-button.component.html',
  styleUrls: ['./rect-button.component.css']
})
export class RectButtonComponent implements OnInit, AfterViewInit {

 
  @Input()
  title:string = '';
  @Input()
  icon:string;
  @Input()
  iconPos:'before'|'after'='before';
  // @Input()
  // animateIcon:boolean=false;
  // @Input()
  // animateBtn:boolean=false;
  
  // @Input()
  // label:string;

  @Input()
  styleType:'primary'|'third-primary'|'assets'|'warning'|'cancel'|'' = '';

  @Input()
  btnCls:string = '';
  @Input()
  iconCls:string = '';

  @Input()
  disabled:boolean = false;
  // get _btnCls():string{
  //   return this.btnCls + (this.styleType=='primary'?' primary':'') + (this.animateBtn?' btn-animate' :'');
  // }
  // get _iconCls():string{
  //   return this.iconCls +(this.styleType=='warning'?' remove':'') + (this.animateIcon?' icon-animate' :'');
  // }
  @Output()
  click:EventEmitter<Event> = new EventEmitter();
  @Output()
  contextmenu:EventEmitter<Event> = new EventEmitter();
  constructor(private ele:ElementRef<HTMLElement>) {
    this.ele.nativeElement.style.display='block'
    
   }

  @ViewChild('btn')
  btn:ElementRef<HTMLButtonElement>;
  visible: 'hidden'|'visible' = 'hidden';
  //width: string = 'unset';
  ngOnInit(): void {
      if(this.styleType){
        this.btnCls += ' vis-btn-' + this.styleType;
      }
      if(this.iconPos == 'after'){
        this.btnCls+= ' icon-after';
      }
  }
  ngAfterViewInit(): void {
    var el = this.btn.nativeElement;
    var baseWidth = 48;
    
    if(el.clientWidth > 48){
      var add = Math.ceil((el.clientWidth - 48) / 16) * 16;
      baseWidth += add;
    }
    el.style.width = baseWidth+'px';
    el.style.visibility = 'visible';
    var text = el.childNodes.item(1)?.textContent;
    if(text && !this.title){
      this.title = text;
    }
  }
  onClick(evt:Event){
    evt.stopPropagation();
    this.click.emit(evt);
  }
  onContextmenu(evt:Event){
    evt.stopPropagation();
    this.contextmenu.emit(evt);
  }
}
