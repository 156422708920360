
<div class="d-flex">
  <ng-container *ngFor="let item of this.menuItems">
      <ng-container *ngIf="item?.children?.length>0;else elsetmp;" >
          <button [name]="item.text" mat-button
            class="app-menu-btn vis-menu w-100"
            [matMenuTriggerFor]="priMenu"
            (click)="$event.stopPropagation()"
            (menuOpened)="onMenuOpen(item.id,$event)"
            style="flex-grow: 1;"
            [matMenuTriggerData]="item.id">{{item.text}}</button>
          <mat-menu #priMenu="matMenu"  >
              <div>
              <ng-container *ngFor="let child of this.item.children">
                  <ng-container *ngIf="child?.children?.length>0;else elsetmp2;" >
                      <button mat-menu-item class="app-menu-btn" [matMenuTriggerFor]="secMenu">{{child.text}}</button>
                      <mat-menu #secMenu="matMenu" >
                          <ng-container *ngFor="let childchild of this.child.children">
                              <button mat-menu-item class="app-menu-btn" (click)="redirectTo(childchild)">{{childchild.text}}</button>
                          </ng-container>
                      </mat-menu>
                  </ng-container>
                  <ng-template #elsetmp2>
                      <button mat-menu-item class="app-menu-btn" (click)="redirectTo(child)">{{child?.text}}</button>
                  </ng-template>
              </ng-container>
          </div>
          </mat-menu>
      </ng-container>
      <ng-template #elsetmp>
          <button mat-button class="app-menu-btn vis-menu home-menu" (click)="redirectTo(item)">{{item?.text}}</button>
      </ng-template>
  </ng-container>
</div>
