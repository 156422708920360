import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ChildGrowDataChartService, ChildGrowDataType, GrowDataStat, PIndex, Sex } from 'src/app/services/child-grow-chart-service';


declare type TextSetting = {
  bold:'normal'|'bold'|'bolder',
  size:number,
  font:'arial'|'monospace',
  color:string
};

@Component({
  selector: 'app-child-grow-chart',
  templateUrl: './child-grow-chart.component.html',
  styleUrls: ['./child-grow-chart.component.css']
})
export class ChildGrowChartComponent implements OnInit {

  @ViewChild('canvas')
  canvasRef:ElementRef<HTMLCanvasElement>;
  get canvas(){
    return this.canvasRef.nativeElement;
  }
  _ctx: CanvasRenderingContext2D = null;
  getCtx2D(){
    if(!this._ctx){
      this._ctx = this.canvas.getContext('2d');
    }
    return this._ctx

  }
  _sex:Sex;
  @Input()
  set sex(v:Sex){
    this._sex = v;
    if(this.isInitial){
      this.reDraw();
    }
  };
  get sex(){
    return this._sex;
  }
  _dataType:ChildGrowDataType;
  @Input()
  set dataType(v:ChildGrowDataType){
    this._dataType = v;
    if(this.isInitial){
      this.reDraw();
    }
  };
  get dataType(){
    return this._dataType;
  }
  _month:number;
  @Input()
  set month(v:number){
    this._month = v;
    if(this.isInitial){
      this.reDraw();
    }
  };
  get month(){
    return this._month;
  }


  _value:number;
  @Input()
  set value(v:number){
    this._value = v;
    if(this.isInitial){
      this.reDraw();
    }
  };
  get value(){
    return this._value;
  }


  /** 顯示設定 */
  displaySetting = {
    // Canvas 寬
    cWidth: 400,
    // Canvas 高
    cHeight: 300,
    /** 左縮排，保留給左側刻度 */
    paddingLeft: 40,
    /** 右縮排，留給百分位跟右側刻度 */
    paddingRight: 60,
    /** 上縮排，保留給標題文字 */
    paddingTop: 40,
    /** 下縮排，保留給x軸標籤 */
    paddingButtom: 80,
    /** 百分位區域寬度 */
    percentileWidth: 35
  }
  /** 每個部份的字體設定 */
  private fontSize = {
    /** 上方標題 */
    title:{
      bold:'bold',
      size:20,
      font:'arial',
      color:'black'
    } as TextSetting,
    /** 軸名稱 */
    axisName:{
      bold:'normal',
      size:12,
      font:'arial',
      color:'black'
    } as TextSetting,
    /** 刻度 */
    label:{
      bold:'normal',
      size:10,
      font:'monospace',
      color:'black'
    } as TextSetting,
    /** 主刻度 */
    majorLabel:{
      bold:'bold',
      size:11,
      font:'monospace',
      color:'black'
    } as TextSetting,
    /** 選入資料的描述 */
    desc:{
      bold:'bold',
      size:12,
      font:'arial',
      color:'black'
    } as TextSetting
  }

  /** 邊界計算結果 */
  private get bounds(){
    var display = this.displaySetting;
    return {
      top: display.paddingTop,
      buttom: display.cHeight - display.paddingButtom,
      right: display.cWidth - display.paddingRight,
      left: display.paddingLeft,
      width: display.cWidth - display.paddingLeft - display.paddingRight,
      height:display.cHeight - display.paddingTop - display.paddingButtom,
      centerX: display.cWidth/2,
      centerY: display.cHeight/2,
      percentileRight: display.cWidth -display.paddingRight + display.percentileWidth
    }
  };


  /** 繪製參數，資料會變動的放這
   * 繪製完底圖以後會更新裡面的值，拿來繪製曲線用 */
  private chartParam={
    sex:'boy' as Sex,
    type: 'Height' as ChildGrowDataType,

    data:[] as GrowDataStat[],
    yAxis:{
      interval: 0,
      min:0,
      max:0,
    },
    xAxis:{
      interval: 0,
      min:0,
      max:0
    },
    bgColor:{
      chart:'white',
      percentileRegion:'white',//'RGB(223,223,223)',
      mainAxis:'#888',
      subAxis:'#888',
      minAxis:'#888'
    },
    getMainBg:()=>{
      return this.chartParam.sex == 'Boy'?'skyblue':'pink'
    },
    getDataName:()=>{
      var type = this.chartParam.type;
      return (type=='Height'?'身長 ∕ 身高':type=='Weight'?'體重':type=='BMI'?'BMI':'頭圍');
    },
    getDataShortName:(month)=>{
      var type = this.chartParam.type;
      return (type=='Height'?month>=24?'身長':'身高':type=='Weight'?'體重':type=='BMI'?'BMI':'頭圍');
    },
    getSexName:()=>{
      return this.chartParam.sex=='Boy'?'男孩':'女孩';
    },
    getUnit:()=>{
      return this.chartParam.type=='BMI'?'':this.chartParam.type=='Weight'?'公斤':'公分';
    },
    getTitle:()=>{
      return this.chartParam.getSexName() + '年齡別' + this.chartParam.getDataName() + '圖'
    },

    getAxisName:(x:'X'|'Y')=>{
      if(x=='Y'){
        return this.chartParam.getDataName() + '︵' + this.chartParam.getUnit() +'︶'
      }else{
        return '年齡(足月/年)'
      }
    }
  }

  constructor(private childGrowChartService:ChildGrowDataChartService,
    private el:ElementRef<HTMLElement>) {
    this.el.nativeElement.style.display = 'block';
    this.el.nativeElement.style.height =this.displaySetting.cHeight+ 'px';
    this.el.nativeElement.style.overflow = 'hidden';
   }

   isInitial = false;
  async ngOnInit() {
    this.reDraw();
    this.isInitial = true;

  }
  private reDraw(){
    setTimeout(() => {
      if(this.sex && this.dataType){
        this.draw(this.sex,this.dataType);
        if(this.month && this.value){
          this.findPercentile(this.month,this.value)
        }
      }
    }, 0);
  }

  private draw(sex:Sex,type:ChildGrowDataType){
    // 取出對應資料
    var data  = this.childGrowChartService.getData(sex,type);
    this.chartParam.data = data;
    this.chartParam.sex = sex;
    this.chartParam.type = type;
    this.drawBase();

    this.drawCurve(PIndex.P97,'#D22');
    this.drawCurve(PIndex.P85,'orange');
    this.drawCurve(PIndex.P50,'darkgreen');
    this.drawCurve(PIndex.P15,'blue');
    this.drawCurve(PIndex.P3,'purple');

  }
  public findPercentile(month:number,value:number){
    console.log(month,value)
    var ctx = this.getCtx2D();
    var p = this.mapToPoint(month,value);
    ctx.strokeStyle = '#f00f';
    ctx.lineWidth = 2;
    // 圖表範圍內
    if(p.y>=this.bounds.top && p.y<= this.bounds.buttom){
      // 挖掉鋪線, 不然不知道為啥會被底下的軸線影響
      ctx.clearRect(p.x,p.y,1,this.bounds.buttom - p.y)
      ctx.clearRect(this.bounds.left,p.y,p.x - this.bounds.left,1)
      this.lineTo(ctx,
        { x:p.x,y:this.bounds.buttom },
        { x:p.x,y:p.y});
      this.lineTo(ctx,
        { x:this.bounds.left,y: p.y },
        { x:p.x,y:p.y },true);
    }else if(p.y< this.bounds.top){
      ctx.clearRect(p.x,p.y,1,this.bounds.buttom - this.bounds.top)
      this.lineTo(ctx,
        { x:p.x,y:this.bounds.buttom },
        // 直線不過頂
        { x:p.x,y:this.bounds.top});
      //超出Y軸上限則橫線不畫

    }else{
      // 低於Y軸下限都不畫
    }



    var param = this.chartParam;
    var monthData = param.data.find((x,i)=> x.Month == month);
    var idxMax = monthData.Value.findIndex(v=>v>value);
    // 21個月大身長80公分的男孩,
    var text = month+ '個月大,'+param.getDataShortName(month)+' '+ value + param.getUnit()+'的'+param.getSexName();
    var headers = this.childGrowChartService.getHeaders();
    // 低於0.1%
    if(idxMax == 0){
      text+= ',位於百分位' + headers[0] + '以下';
    }
    // 超過99.9%
    else if(idxMax==-1){
      text+= ',位於百分位' + headers[monthData.Value.length-1] + '以上';
    }
    else{
      // 介於百分位a與b間
      text+= ',介於百分位' + headers[idxMax] + '與'+ headers[idxMax-1] + '間'
    }

    this.setText(ctx,this.fontSize.desc,'top','center');
    ctx.fillText(text,this.bounds.centerX, this.bounds.buttom+42);

    // 2個月大男童百分位分布
    var text2 ='正常範圍應於'+ monthData.Value[PIndex.P3] + param.getUnit()+'～'+ monthData.Value[PIndex.P97] + param.getUnit()+'間';
     this.setText(ctx,this.fontSize.desc,'top','center');
     ctx.fillText(text2,this.bounds.centerX, this.bounds.buttom+60);
  }



  //#region 畫底圖
  /** 畫底圖(背景，軸，標題) */
  private drawBase(){
    // x軸
    var xAsix = this.chartParam.data.map(x=>x.Month);
    // Y軸
    var allValue = [];
    this.chartParam.data.forEach(x=> allValue =  allValue.concat(x.Value));
    var maxValues = Math.max(...allValue);
    var minValues = Math.min(...allValue);
    maxValues = maxValues - maxValues%5 + 5;
    minValues -= minValues%5;
    // 包含頭尾
    var yAxis = Array(Math.ceil(maxValues-minValues+1)).fill(0).map((v,i)=> minValues +i);

    this.drawBackColor();
    this.drawTitle();
    this.drawXAxis(xAsix);
    this.drawYAxis(yAxis);
    this.getCtx2D().save()
  }
  /** 背景 */
  private drawBackColor(){
    var ctx = this.getCtx2D();
    ctx.globalAlpha = 1;
    ctx.globalCompositeOperation = 'source-over'
    ctx.fillStyle = this.chartParam.getMainBg();
    ctx.fillRect(0,0,this.displaySetting.cWidth,this.displaySetting.cHeight);
    ctx.fillStyle = this.chartParam.bgColor.chart;
    ctx.fillRect(this.bounds.left,this.bounds.top,this.bounds.width ,this.bounds.height);
    ctx.fillStyle = this.chartParam.bgColor.percentileRegion;
    ctx.fillRect(this.bounds.right,this.bounds.top,this.displaySetting.percentileWidth,this.bounds.height);
    ctx.fillStyle = 'black';

  }
  /** 畫標題 */
  private drawTitle(){
    var ctx = this.getCtx2D();

    // 上方標題
    var title = this.chartParam.getTitle();
    this.setText(ctx,this.fontSize.title,'top','center');
    ctx.fillText(title,this.bounds.centerX,10);

    // y軸名稱
    var yAxis = this.chartParam.getAxisName('Y');
    this.setText(ctx,this.fontSize.axisName,'middle','center');
    var eachWord = yAxis.split('').filter(x=> x.trim());
    eachWord.forEach((x,i)=>{
      ctx.fillText(x,10, this.bounds.centerY + (i - eachWord.length/2)*this.fontSize.axisName.size );
    });

    // x軸名稱
    var xAxis = this.chartParam.getAxisName('X');
    ctx.fillText(xAxis,this.bounds.centerX, this.bounds.buttom + 30);

    this.setText(ctx,this.fontSize.majorLabel,'bottom','left')
    ctx.fillText('百分位',this.bounds.right, this.bounds.top);
  }
  /** X軸 */
  private drawXAxis(xAxis:number[]){
    var ctx = this.getCtx2D();
    // Main Axis
    this.toMainAxis(ctx);
    this.lineTo(ctx,
      {x:this.bounds.left,y:this.bounds.buttom},
      {x:this.bounds.percentileRight,y:this.bounds.buttom}
    );
    // 刻度線
    this.toSubAxis(ctx);
    // 包含 x=0  所以算間距的Length - 1
    var axisInterval = (this.bounds.width)/ (xAxis.length-1);
    for(var i = 0;i<xAxis.length;i++){
      var xPos = this.bounds.left + axisInterval*i;
      var label = xAxis[i];

      if(i%12==0){
        // 處理標籤
        var text = (label/12).toString()+'歲';
        if(label==0){
          text = '出生'
        }
        this.setText(ctx,this.fontSize.majorLabel,'top','center');
        if(this.month==label){
          ctx.fillStyle='red';
        }
        ctx.fillText(text,xPos, this.bounds.buttom + 10);
        // 設定軸線樣式
        this.toMainAxis(ctx);
      }else if(i%2==0){
        // 處理標籤
        this.setText(ctx,this.fontSize.label,'top','center');
        if(this.month==label){
          ctx.fillStyle='red';
        }
        ctx.fillText((label%12).toString(),xPos, this.bounds.buttom + 2);
        // 設定軸線樣式
        this.toSubAxis(ctx);
      }else{
        // 設定軸線樣式
        this.toMinAxis(ctx);
      }
      if(this.month==label){
        ctx.strokeStyle = 'orangered';
      }
      var begin = {x:xPos,y:this.bounds.buttom};
      var to = {x:xPos,y:this.bounds.top};
      this.lineTo(ctx,begin,to)

    }
    // 百分位右方的直線
    this.toMainAxis(ctx);
    this.lineTo(ctx,
      {x:this.bounds.percentileRight,y:this.bounds.buttom},
      {x:this.bounds.percentileRight,y:this.bounds.top}
    );

    this.chartParam.xAxis.interval = axisInterval;
    this.chartParam.xAxis.min = xAxis[0];
    this.chartParam.xAxis.max = xAxis[xAxis.length-1];
  }
  /** Y軸 */
  private drawYAxis(axis:number[]){
    var ctx = this.getCtx2D();
    // Main Axis
    this.toMainAxis(ctx);
    this.lineTo(ctx,
      {x:this.bounds.left,y:this.bounds.buttom},
      {x:this.bounds.right,y:this.bounds.buttom}
    );
    // 刻度線
    this.toSubAxis(ctx);
    // 包含 x=0  所以算間距的Length - 1
    var yAxisInterval = (this.bounds.height)/ (axis.length-1);

    for(var i = 0;i<axis.length;i++){
      var yPos = this.bounds.buttom - yAxisInterval*i;
      var begin = {x:this.bounds.left,y:yPos};
      var to = {x:this.bounds.right,y:yPos};

      var label = axis[i];
      if(label%5==0){
        // 設定刻度標籤
        this.setText(ctx,this.fontSize.label,'middle','right');
        ctx.fillText(label.toString(),this.bounds.left-2,yPos);
        this.toSubAxis(ctx);
        // 畫長一點，連至右側刻度
        var to = {x:this.bounds.percentileRight,y:yPos};
        // 右側刻度標籤
        this.setText(ctx,this.fontSize.label,'middle','left');
        ctx.fillText(label.toString(),this.bounds.percentileRight + 2,yPos)
      }else{
        this.toMinAxis(ctx);
      }
      if(i == axis.length-1){
        this.toMainAxis(ctx);
      }

      this.lineTo(ctx,begin,to)
    }

    this.chartParam.yAxis.interval = yAxisInterval;
    this.chartParam.yAxis.min = axis[0];
    this.chartParam.yAxis.max = axis[axis.length-1];

  }
  //#endregion

  //#region 畫分佈線
  /** 繪製資料線 */
  private drawCurve(curveType:PIndex,color:string){
    var curve = this.chartParam.data.map(x=> {
      return {
        Month:x.Month,
        Value:x.Value[curveType]
      }
    });

    var ctx = this.getCtx2D();
    this.toCurve(ctx)
    ctx.strokeStyle = color;
    ctx.beginPath();
    var p = this.mapToPoint(curve[0].Month,curve[0].Value);
    ctx.moveTo(p.x,p.y);
    for(var i = 1; i< curve.length; i++){
      p = this.mapToPoint(curve[i].Month,curve[i].Value);
      ctx.lineTo(p.x,p.y);
    }
    ctx.stroke();
    ctx.fillStyle = this.chartParam.bgColor.percentileRegion;
    var fontSize = this.fontSize.majorLabel.size;
    ctx.fillRect(p.x+1,p.y-fontSize/2,this.displaySetting.percentileWidth-7,fontSize);
    ctx.fillStyle = 'black'
    this.setText(ctx,this.fontSize.majorLabel,'middle','left');
    ctx.fillText(this.childGrowChartService.getHeaders()[curveType],p.x+3,p.y);
  }
  /** 資料點轉換到畫布座標 */
  private mapToPoint(month:number,value:number):{x:number,y:number}{
    var x = this.bounds.left;
    x +=  month * this.chartParam.xAxis.interval;
    var y = this.bounds.buttom;
    y-= (value-this.chartParam.yAxis.min) * this.chartParam.yAxis.interval;
    return {x,y};
  }
  //#endregion


  //#region 畫圖動作
  private lineTo(ctx:CanvasRenderingContext2D, begin:{x:number,y:number}, to:{x:number,y:number},dot:boolean = false){
    ctx.beginPath();
    ctx.moveTo(begin.x,begin.y);
    ctx.lineTo(to.x,to.y);

    ctx.setLineDash(dot?[5,2]:[0,0]);
    ctx.stroke();
  }
  //#endregion


  //#region 設定樣式
  private toMainAxis(ctx:CanvasRenderingContext2D){
    ctx.lineWidth = 2;
    ctx.strokeStyle = this.chartParam.bgColor.mainAxis;
  }
  private toSubAxis(ctx:CanvasRenderingContext2D){
    ctx.lineWidth = 1.2;
    ctx.strokeStyle = this.chartParam.bgColor.subAxis;
  }
  private toMinAxis(ctx:CanvasRenderingContext2D){
    ctx.lineWidth = 0.5;
    ctx.strokeStyle = this.chartParam.bgColor.minAxis;
  }

  private toCurve(ctx:CanvasRenderingContext2D){
    ctx.lineWidth = 1;
  }
  private setText(ctx:CanvasRenderingContext2D,font:TextSetting,baseline:CanvasTextBaseline,align:CanvasTextAlign){
    ctx.font = (font.bold == 'bold'?'bold':font.bold=='bolder'?'bolder':'')+' '+
                font.size+'px ' +
                font.font;
    ctx.fillStyle = font.color;
    ctx.textBaseline = baseline;
    ctx.textAlign = align;
  }
  //#endregion
}
