<style>
  @media print {
    .container {height: 100%;width: 100%;}
    .page {display: block;margin: 40px auto;box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);box-sizing: border-box;}
    .page .content {overflow: auto;outline: 0;}
    .page .content .block {border: 1px solid rgba(0, 0, 0, 0);padding: 0.25rem;cursor: default;}
    .page .content .block:hover {border: 1px solid #dee2e6;border-radius: 0.25rem;}
    .page .content .block .title {font-weight: bold}
    .page .content .block .title {cursor: text;}
    .width-quarter {width: 33%;}
    .border-0 {border: 0}
    .border-right-0 {border-right: 0}
    .border-left-0 {border-left: 0}
    .a4-print-style {width: 210mm;height: 297mm;border: 1px solid #474647;background: white;}
    .a4-print-style-h {width: 307mm;height: 210mm;border: 1px solid #474647;background: white;}
    .h-auto {height: auto}
    .m-auto {margin: auto}
    .start-0 {left: 0;}
    .end-0 {right: 0;}
    .pl-2, .px-2 {padding-left: 0.5rem}
    .pr-2, .px-2 {padding-right: 0.5rem}
    .m-0 {margin: 0}
    .d-block {display: block}
    .bg-white {background-color: #fff}
    .font-weight-bolder {font-weight: bolder}
    .text-center {text-align: center}
    .mt-2, .my-2 {margin-top: 0.5rem}
    .w-100 w-100-print {width: 100%}
    .text-lg {font-size: 1.125rem }
    .text-right {text-align: right}
    .text-left {text-align: left}
    .multiLine-pre-line {white-space: pre-line;}
    .lh-xlg {line-height: 2.5rem }
    .max-w-20 {max-width: 5rem }
    .flex-grow-one {flex-grow: 1 }
  }
</style>
<div class="a4-print-style h-auto start-0 end-0 m-auto report-hist-index" #reportContainer>
  <div class="pres-report bg-white px-2 page m-0 m-auto d-block" id="page-01"
      style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{height: sizePage.height + 'cm',
        width: sizePage.width + 'cm',
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content" style="font-family:標楷體;">
      <!-- <div>
          <div class="w-100 text-center font-weight-bolder mt-2 print-title" *ngIf="data">{{data.clinicName}}
          </div>
          <span class="w-100 text-right d-block text-lg date-space-print">列印日：{{date | formatDate}}</span>
      </div> -->
      <div style="-webkit-text-size-adjust: none;">
        <table cellspacing="0" *ngIf="data" class="w-100 table-print" style="width: 100%;">
          <tr>
            <td colspan="5" class="text-center mt-2 print-title" style="border-bottom: 1px solid black;" *ngIf="data">{{data.clinicName}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter">病歷號：{{data.PatientNo.slice(-7)}}</td>
            <td             class="text-left pl-2 border-0 width-quarter">姓　名：{{data.CName}}</td>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter">性　別：{{this.findOptionText('Sex', data.Sex)}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0">身分證：{{data.CId}}</td>
            <td             class="text-left pl-2 border-0">生　日：{{data.Birthday | formatROCFullDate : '/'}}</td>
            <td colspan="2" class="text-left pl-2 border-0">身分別：{{this.findOptionText('C0010', data.ICode)}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0">血　型：{{this.findOptionText('Blood', data.BlType)}}(RH{{this.findOptionText('RH', data.BlRH)}})</td>
            <td             class="text-left pl-2 border-0">初診日：{{data.FirstDate | formatROCFullDate : '/'}}</td>
            <td colspan="2" class="text-left pl-2 border-0">電　話：{{data.Phone}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0">職　業：{{this.findOptionText('B0030', data.JobCode)}}</td>
            <td             class="text-left pl-2 border-0">教　育：{{this.findOptionText('B0040', data.EducationCode)}}</td>
            <td colspan="2" class="text-left pl-2 border-0">手　機：{{data.CellPhone}}</td>
          </tr>
          <tr>
            <td colspan="5" class="text-left pl-2 border-0">地　址：{{data.patientAddress}}</td>
          </tr>
          <!-- <tr>
            <th rowspan="2" class="w-contact">緊急聯絡</th>
            <td class="text-left pl-2 border-right-0" style="border-right: 0;">聯絡人：{{data.contactName}}</td>
            <td class="text-left pl-2 border-right-0 border-left-0" style="border-right: 0;border-left: 0;">關　係：{{data.relation}}</td>
            <td class="text-left pl-2 border-right-0 border-left-0" style="border-right: 0;border-left: 0;">電　話：{{data.contactOnePhone}}</td>
            <td class="text-left pl-2 border-left-0" style="border-left: 0;">手　機：{{data.contactOneMobile}}</td>
          </tr>
          <tr>
            <td class="text-left pl-2 border-right-0" style="border-right: 0;" colspan="4">聯絡人地址：{{data.contactsAddress}}</td>
          </tr> -->

          <!-- 避免日後說要生理量測先保留 <tr>
            <td class="text-left pl-2 border-right-0" style="border-right: 0;">職　業：{{data.JobCode}}</td>
            <td class="text-left pl-2 border-left-0" style="border-left: 0;">教　育：{{data.EducationCode}}</td>
            <td class="text-left pl-2 border-right-0" style="border-right: 0;">初診日：{{data.FirstDate | formatDate}}</td>
            <td class="text-left pl-2border-left-0" style="border-left: 0;">
                <div class="w-100 w-100-print d-flex measure-print" style="position:relative"
                  *ngIf="data.Height > 0 && data.Weight > 0">
                  <div class="flex-grow-one max-w-20 lh-xlg body-measure-left measure-auto">
                    <app-icon-man></app-icon-man> 量測：
                  </div>
                  <div class="flex-grow-one body-measure-right">
                    <div class="d-block w-100 w-100-print"><ng-container *ngIf="data.Height > 0">身高{{data.Height + 'cm' }} </ng-container>
                    </div>
                    <div class="d-block w-100 w-100-print"><ng-container *ngIf="data.Weight > 0">體重 {{data.Weight + 'kg'}}</ng-container>
                    </div>
                  </div>
                </div>
                <div class="w-100 w-100-print d-flex measure-print-one" style="position:relative"
                  *ngIf="data.Height == 0 || data.Weight == 0 || (data.Height == 0 && data.Weight == 0)">
                  <div class="flex-grow-one max-w-20 body-measure-left measure-auto">
                    <app-icon-man></app-icon-man> 量測：
                  </div>
                  <div class="flex-grow-one body-measure-right">
                    <div class="d-block w-100 w-100-print"><ng-container *ngIf="data.Height > 0">身高{{data.Height + 'cm' }} </ng-container>
                    </div>
                    <div class="d-block w-100 w-100-print"><ng-container *ngIf="data.Weight > 0">體重 {{data.Weight + 'kg'}}</ng-container>
                    </div>
                  </div>
                </div>
              </td>
          </tr> -->
          <tr>
            <td colspan="5" class="text-left pl-2 border-0 multiLine-pre-line">過敏史：<ng-container *ngIf="!hasDrugAllergy">無</ng-container><ng-container *ngIf="hasDrugAllergy"><br>[藥]:</ng-container>{{data.DrugAllergy}}</td>
          </tr>
          <tr>
            <td colspan="5" class="text-left pl-2 border-0 multiLine-pre-line">特別記載與過去病史：<ng-container *ngIf="!hasRemark">無<br></ng-container><br>{{data.Remark}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>
