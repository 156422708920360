import { APP_ID, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fail } from 'assert';
import { DailyReport, DailyReportIcodeSum, DailyReportItem } from 'src/app/payments/models/daily-report';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportDetailDTO, DeclareCaseReportPageDTO } from 'src/app/services/api-service/declare/declare-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { StartUpService } from 'src/app/services/start-up-service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import {NullOrEmpty } from 'src/app/shared/utilities';

@Component({
  selector: 'app-report-reg-free-pay-list',
  templateUrl: './report-reg-free-pay-list.component.html',
  styles: [`@media print {
    body {
      padding-top: 3cm;
      padding-bottom: 3cm;
      margin: 0;
      color: #000;
      background-color: #fff;
      font-size: 10pt;
    }

    .pres-report2 table td,
    table th {
      border-bottom: 5px double black;
      padding-left: 0px;
      padding-right: 0px;
    }
    .container {
      height: 100%;
      width: 100%;
    }
    .page {
      display: block;
      margin: 40px auto;
      box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
      box-sizing: border-box;
    }
    .page .content {
      overflow: auto;
      outline: 0;
    }
    .page .content .block {
      border: 1px solid rgba(0, 0, 0, 0);
      padding: 0.25rem;
      cursor: default;
    }
    .page .content .block:hover {
      border: 1px solid #dee2e6;
      border-radius: 0.25rem;
    }
    .page .content .block .title {
      font-weight: bold;
    }
    .page .content .block .title {
      cursor: text;
    }
  }`]
})
export class ReportRegFreePayListComponent implements OnInit {
  sizePage = {
    width: 20, //cm
    height: 28.2 //cm
};
paddingPage = {
    top: 0.5, //cm
    right: 0, //cm
    bottom: 0.75, //cm
    left: 0 //cm
};

heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
);
elContainer;
anchorsBlockValue;
pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

@ViewChild('reportContainer', { static: false })
reportContainer: ElementRef<HTMLDivElement>;
@ViewChild('viewContainer', { static: false })
viewContainer: ElementRef<HTMLDivElement>;
@Input() data;
/** 是否列印 */
@Input()
set isPrint(value) {
  if (value) {
    // this.onPrintAll();
    this.onPrint();
  }
}
@Input()
set isPreviewPrint(value) {
  console.log('valueReportRegFreePayList',value);
  if (value) {
      // const query = {
      //     startDate: DateHelper.getIsoStringNoTimeZone(new Date()),
      //     endDate: DateHelper.getIsoStringNoTimeZone(new Date()),
      //     timeSec: '',
      //     clinicId: '',
      //     reportType: 'detail',
      // }
      this.fetchData();
  }
}
@Input() set startMonth(value) {
  if (value) {
    this._sDate = value;
  }
}
@Input() set endMonth(value) {
  if (value) {
    this._eDate = value;
  }
}
@Input() set startDate(value) {
  if (value) {
    this._sDate = value;
  }
}
@Input() set endDate(value) {
  if (value) {
    this._eDate = value;
  }
}

@Output() previewPrintDone = new EventEmitter();

@Input()
set isExportAsExcel(value) {
  if (value) {
    this.exportAsExcel();
  }
}
@Input()
set toggleDoctor(value) {
  this.doctorType =value;
}
@Input() isShowTotal = false;
//   set isShowTotal(value) {
//   }

showDoctorNameColume: boolean;
monthStr: string = '';
nowStr: string = '';
timeStr: string = '';
totalRecord: number = 0;
pageNo: number = 1;
_sDate: Date;
_eDate: Date;
doctorType:number;
dailyReport: DailyReport;
// TODO API
doctorReport = [];
// doctorReport:DeclareCaseReportDTO[] = [];
// 清單物件
listItems: DailyReportItem[] = [];
listIcodeSum : DailyReportIcodeSum[] = [];
// api服務
api: WebApiService;
// 查詢條件;
queryFormGroup: FormGroup;
doctorName:string = '';

constructor(private webApiClient: WebApiClient,
    private declareApi: DeclareApi,
    private startUp: StartUpService,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService:HcrService,
    private elementRef: ElementRef) {
        this.api = this.webApiClient.createHisService('Payments/Payment');
    }


ngOnInit(): void {
    if (this.startUp.userData && this.startUp.userData.name) {
      this.doctorName = this.startUp.userData.name;
    }
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
        '.pres-report2 table{ border:0px solid black; }\n' +
        '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 0.1px;padding-right: 0.1px;}\n' +
        '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
        '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
        '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
        '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
        '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
        // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
}

ngAfterViewInit() {

}



// 取得統計期間的看診人次資料，並組出報表的html內容
async fetchData() {
    console.log('res', this.isShowTotal);

    // TODO API
    // this.doctorReport = await this.declareApi.GetDoctorRoomcodeDeclareReport(UserCache.getLoginClinicId(), this._sDate, this._eDate, this.doctorType,this.isShowTotal);
    this.doctorReport = [
      {Month: '11203',
       e11cs: '',
       Detail: [
          {
            PatientNo:'0000190',
            RegDate:'112/11/10',
            RoomCode:'1',
            TimeSec:'上午',
            BookingCode:'0001',
            TDUTY:'櫃檯掛號掛入',
            DeleteDate:'112/11/10',
            DeleteTime:'112/11/10',
            ComputerName:'ILoveVision'
          },
          {
            PatientNo:'0000225',
            RegDate:'112/11/10',
            RoomCode:'1',
            TimeSec:'上午',
            BookingCode:'0002',
            TDUTY:'門診候診掛入',
            DeleteDate:'112/11/10',
            DeleteTime:'112/11/10',
            ComputerName:'MoneyBoard'
          },
        ],
      Subtotal: []
    }
    ];
    setTimeout(() => {
      this.elContainer = this.viewContainer.nativeElement;
        if(this.doctorReport.length > 0){
            this.insertListData();
        }
        else
        {
            this.notification.showInfo('查無資料可供列印!');
            this.previewPrintDone.emit();

        }

    }, 0);


}

// 組報表的html內容
insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var isSum = false;
    var nowsearch = new Date();
    this.nowStr = this.onGetDateString(new Date(), true) + ' ' + this.onGetTimeString(new Date());
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
    // var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;

    var elTableContent = document.getElementById("table-" + iPage + "-content");
    for (let i = 0; i < this.doctorReport.length; i++) {
        // 加入 List 每一筆的內容
        var html_ListerHeader = this.createEndCount(this.doctorReport[0]?.Detail)+this.createHTMLListHeader( this.doctorReport[i].Month);   // List欄位顯示名稱
        elTableContent.innerHTML += html_ListerHeader;

        let doctorDetail = this.doctorReport[i].Detail;
        // console.log('doctorDetail',doctorDetail);
        // console.log('1233',elTableContent.innerHTML);




        doctorDetail = [...doctorDetail, this.doctorReport[i].Subtotal];
        // console.log('doctorDetail',i);
        if(doctorDetail.length > 0){
            // console.log('doctorDetail',doctorDetail);
            for (let  j= 0; j < doctorDetail.length; j++) {
                html_Block = this.createHTMLBlock(doctorDetail[j]);
                elTableContent.innerHTML += html_Block;
                var prevTableContent = elTableContent.innerHTML;

        // 判斷是否超出頁面範圍? -> 新增一頁
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageHeader(iPage);
            // html_header = this.createHTMLPageNoHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-content");
            elTableContent.innerHTML = html_ListerHeader;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            this.pageContent[iPage].push(iBlock);
        }
        iBlock += 1;
            }
        }


    }
    // elPageContent.innerHTML +=  html_finish;
    this.previewPrintDone.emit();
}

convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
}

convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
}

createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
}

createHTMLListHeader(month : string) {
    var doctype = this.doctorType === 2 ? '<th style="text-align: right;max-width: 2rem;min-width: 2rem;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">診醫別</th>' :'';
    return `
    <tr>
    <th style="width:12%;text-align: left;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    病歷號
    </th>`
    +doctype +
    `<th style="width:11%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    預約日期
    </th>
    <th style="width:9%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    預約診別
    </th>
    <th style="width:9%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    預約時段
    </th>
    <th style="width:9%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    預約號碼
    </th>
    <th style="width:14%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    TDUTY
    </th>
    <th style="width:11%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    刪除日期
    </th>
    <th style="width:11%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    刪除時間
    </th>
    <th style="width:18%;text-align: right;border-right: 0px solid black;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    電腦名稱
    </th>
</tr>`;
}

stringFactory(input:string) :string{
    return !NullOrEmpty(input)?input.trimRight() :' ';
}
numberFactory(input:number):string{
    let result = '';
    if(!isNaN(input)){
        result = input > 0 ? input.toString():'0';
    }
    let paddedString = this.padToLength(result, 4, " ");
    return paddedString;
}
padToLength(inputString: string, targetLength: number, paddingChar: string): string {
    const paddingLength = targetLength - inputString.length;
    if (paddingLength > 0) {
        return inputString + paddingChar.repeat(paddingLength);
    } else {
        return inputString;
    }
}

stringBFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length >= 5 ? str.slice(0, 5) :str;
    return inputSlice;
}
stringCFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length > 7 ? str.slice(-7):str;
    return inputSlice;
}
stringTimeZone(input:string) :string{
    let result = !NullOrEmpty(input)?input.trimRight() :' ';
    switch(input){
        case'早上':
        result = '早';
        break;
        case'下午':
        result = '午';
        break;
        case'晚上':
        result = '晚';
        break;
        default:
        result = ' ';
        break;
    }
    return result;
}


createHTMLBlock(data: ReportCancelReserveDetailDTO) {
    var summaryStyle = "border-top:2px double black;";
    var PatientNo = this.stringFactory(data?.PatientNo || '');
    var isTotal = PatientNo === '月計' || PatientNo === '總計';
    var RegDate =  this.stringFactory(data?.RegDate || '');
    var RoomCode = this.stringFactory(data?.RoomCode || '');
    var TimeSec = this.stringFactory(data?.TimeSec || '');
    var BookingCode = this.stringFactory(data?.BookingCode || '');
    var TDUTY = this.stringFactory(data?.TDUTY || '');
    var DeleteDate = this.stringFactory(data?.DeleteDate || '');
    var DeleteTime = this.stringFactory(data?.DeleteTime || '');
    var ComputerName = this.stringFactory(data?.ComputerName || '');

    return  '<tr>' +
                '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + PatientNo + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + RegDate + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + RoomCode + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + TimeSec + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + BookingCode + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + TDUTY + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + DeleteDate + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + DeleteTime + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + ComputerName + '</td>' +
            '</tr>' ;
}
createEndCount(data: ReportCancelReserveDetailDTO) {
  return '<tr>' +
          '<td colspan="4" style="text-align: left; font-size: 14pt;' + ' border-bottom: none;">'+ '列印日期：' + this.nowStr +'</td>' +
           '<td colspan="5" style="text-align: right; font-size: 14pt;' + ' border-bottom: none;">'+ '列印醫師：' + this.doctorName +'</td>' +
        '</tr>' ;
}
createHTMLPageHeader(iPage) {
    return `<div id="header" style="margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            免掛號及自付名冊
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            　　　　　　　 `  + `
                            </td>
                            <td colspan="2"; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            年月日起訖：` + this.stringCFactory(DateHelper.formatROCDate(this._sDate)) + ` 至 ` + this.stringCFactory(DateHelper.formatROCDate(this._eDate)) + `
                            </td>
                            <td colspan="2" ; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 1px;">
                <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12.5pt; border: none;">
                </table>
                </div>
            </div>`;
}
createHTMLPageFirstSumHeader(iPage){
    return `<br />
            <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 1px;">
                    <table id="table-` + iPage + `-sum" style="width: 100%; font-size: 11.5pt; border: none;">
                    </table>
                </div>
            </div>`;
}
createHTMLPageSumHeader(iPage) {
    return `<div id="header" style="margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            邱康承診所<br/>優免名冊(非報稅用)
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            日期 ` + this.nowStr + `
                            </td>
                            <td colspan="2"; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            時間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                            </td>
                            <td colspan="2" ; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 1px;">

                    <table id="table-` + iPage + `-sum" style="width: 100%; font-size: 11.5pt; border: none;">
                    </table>
                </div>
            </div>`;
}
createHTMLPageNoHeader(iPage) {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
            <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 1px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 11.5pt; border: none;">
                    </table>
                </div>
            </div>`;
}
createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
}
createNoDetailFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">查無資料</label>
            </div>`;
}
createHTMLTotal() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;"></label>
            </div>`;
}
getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
}

async onPrint() {
    console.log('免掛號及自付名冊');
    if (this.listItems.length == 0) {
        var reportName = '免掛號及自付名冊';
        const printContents = this.getHtml();
        var ret = await this.localPrintService.printHtml(444, '免掛號及自付名冊', printContents);
        if (ret.Successed) {
            this.notification.showSuccess(reportName + '已送出列印!', true);
        } else {
            this.notification.showError(reportName + '送出列印失敗!');
        }
    } else {
        this.notification.showInfo('查無資料可供列印!');

    }
    this.previewPrintDone.emit();

}

async exportAsExcel() {
    if (this.doctorReport.length > 0) {
        var reportName = '免掛號及自付名冊';
        const table = this.reportContainer.nativeElement;
        await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)

        // const uri = 'data:application/vnd.ms-excel;base64,';
        // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        //                     <head>
        //                         <!--[if gte mso 9]>
        //                         <xml>
        //                             <x:ExcelWorkbook>
        //                                 <x:ExcelWorksheets>
        //                                     <x:ExcelWorksheet>
        //                                         <x:Name>{worksheet}</x:Name>
        //                                         <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        //                                     </x:ExcelWorksheet>
        //                                 </x:ExcelWorksheets>
        //                             </x:ExcelWorkbook>
        //                         </xml>
        //                         <![endif]-->
        //                     </head>
        //                     <body>
        //                         <table>{table}</table>
        //                     </body>
        //                     </html>`;
        // const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        // const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        // const table = this.reportContainer.nativeElement;
        // const ctx = { worksheet: '免掛號及自付名冊', table: table.innerHTML };

        // var fileName =`免掛號及自付名冊` + this.onGetDateString(new Date(), false, '', false) + this.onGetTimeString(new Date(), '') + `.xls`;
        // var content = format(template, ctx);
        // var ret =await this.hcrService.SaveTextAsZipIfPwd(`爽約名單`+'\\'+fileName,content)
        // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);


        // const link = document.createElement('a');
        // link.download = `免掛號及自付名冊` + this.onGetDateString(new Date(), false, '', false) + this.onGetTimeString(new Date(), '') + `.xls`;
        // link.href = uri + base64(format(template, ctx));
        // link.click();
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
}

/** 轉換Date To String
 *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
 */
onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (isMonth) {
            return [year, month].join(symbol);
        } else {
            return [year, month, day].join(symbol);
        }
    }
}

/** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
*/
onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
        var d = new Date(ev);
        // 趕時間先簡單寫
        let hr = d.getHours().toString();
        if (d.getHours() < 10) {
            hr = '0' + hr;
        }
        let min = d.getMinutes().toString();
        if (d.getMinutes() < 10) {
            min = '0' + min;
        }
        let sec = d.getSeconds().toString();
        if (d.getSeconds() < 10) {
            sec = '0' + sec;
        }
        var timeStr = [hr, min, sec].join(timeSymbol);
        return timeStr;
    }
}

onGetDate(ev) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day].join('');
    }
  }
}
export class ReportCancelReserveDetailDTO {
  PatientNo: string;
  RegDate: string;
  RoomCode: string;
  TimeSec: string;
  BookingCode: string;
  TDUTY: string;
  DeleteDate: string;
  DeleteTime: string;
  ComputerName: string;
}
