import { VisitcountQueryDto, VisitcountReportData } from './../models/visitcount-report';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { VisitcountReportComponent } from 'src/app/system/report/components/visitcount-report/visitcount-report.component';


@Component({
  selector: 'app-hist-visitcount',
  templateUrl: './hist-visitcount.component.html',
  styleUrls: ['./hist-visitcount.component.css']
})
export class HistVisitcountComponent implements OnInit {


  queryFormGroup: FormGroup = this.fb.group({});
  visitcountReport: VisitcountReportData[];  //輸出結果
  caseOpt: ValueTextPair[];  //案類選擇
  defSearchMethod: string = '1';  //身分
  reportTypeList: ValueTextPair[] = [{ 'text': '彙總次數', 'value': '1' }]; //報表類型
  isPreviewOpened = false;
  isresult = false;

  @ViewChild(VisitcountReportComponent)
  visitcountReportComp:VisitcountReportComponent;

  constructor(
    private fb: FormBuilder,
    private histApi: HistApi,
    private clinicDataService: ClinicDataService) {
    }

   ngOnInit() {
    this.createQueryFormGroup();
    this.getQueryOptions();
  }

  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group({
      startDate: [new Date()],
      endDate: [new Date()],
      searchMethod: ['1'],
      haveADx: [false],
      overAmount: [null],
      selectedCaseItems: [[]],
      reportType: ['1'],
    });
  }

  async getQueryOptions() {
    var codes = await this.clinicDataService.getSystemCodes([ 'C0050'])
    this.caseOpt = [{ value: '', text: '' }].concat(codes['C0050']);
  }

  async onBtnQuery() {
    this.isPreviewOpened = true;
    var sDate =this.queryFormGroup.controls['startDate'].value;
    var eDate =this.queryFormGroup.controls['endDate'].value;
    let endDate = DateHelper.getFormatedDateString(eDate, false);
    let startDate = DateHelper.getFormatedDateString(sDate, false);

    let data = new VisitcountQueryDto();
    data.StartDate= startDate;
    data.EndDate= endDate;
    data.SearchMethod= this.queryFormGroup.controls['searchMethod'].value;
    data.HaveADx= this.queryFormGroup.controls['haveADx'].value;
    //data.selectedCaseItems= this.queryFormGroup.controls['selectedCaseItems'].value;
    data.SelectedCaseItems= (this.queryFormGroup.controls['selectedCaseItems'].value).join(',');
    data.OverAmount= this.queryFormGroup.controls['overAmount'].value ?? 0;
    data.ReportType= this.queryFormGroup.controls['reportType'].value;

    var result = await this.histApi.GetVisitcountReport(data);
        if (result.length > 0) {
          this.visitcountReport = result;
          this.isresult = true;
        } else {
          this.visitcountReport = [];
          this.isresult = false;
          //this.notification.showInfo('查無可列印的資料!');
        }
        this.visitcountReportComp.fetchData(this.visitcountReport,true,sDate,eDate)
  }

  onPreviewClose() {
    this.visitcountReportComp.clearData();
    this.isPreviewOpened = false;
  }

  onPrinted(evt: any) {
    this.visitcountReportComp.clearData();
    this.isPreviewOpened = false;
  }

  onBtnPrintClick() {
    this.visitcountReportComp.onPrint();
  }

  onBtnExportAsExcel() {
    this.visitcountReportComp.exportAsExcel();
    this.isPreviewOpened = false;

  }


  onReportTypeChange($event: any) {
  }

  onDtpStartDateChange($event: any) {
  }

  onDtpEndDateChange($event: any) {
  }

  onSearchMethodChange(evt: any) {
    this.defSearchMethod = evt.value;
    // this.checkChanged();
  }






}
