import { TimeSecEnum } from "src/app/enums/TimeSecEnum";
import { REG001Params } from "../parameters/REG001Params";

export class TimeSectionRange {
    /**
     *
     */
    constructor(params: REG001Params) {
        this.morningStart = parseInt(params.TSecMorningStart.replace(':',''));
        this.morningEnd = parseInt(params.TSecMorningEnd.replace(':',''));
        this.afternoonStart = parseInt(params.TSecAfternoonStart.replace(':',''));
        this.afternoonEnd = parseInt(params.TSecAfternoonEnd.replace(':',''));
        this.eveningStart = parseInt(params.TSecEveningStart.replace(':',''));
        this.eveningEnd = parseInt(params.TSecEveningEnd.replace(':',''));
    }
    morningStart: number;
    morningEnd: number;
    afternoonStart: number;
    afternoonEnd: number;
    eveningStart: number;
    eveningEnd: number;

    // 依據當下時間取得 查詢條件 TimeSec 的預設值
    static getSectionValue(range: TimeSectionRange) {
        const now = new Date();
        const hourMin = now.getHours() * 100 + now.getMinutes();
        if (hourMin > range.morningStart && hourMin <= range.morningEnd) {
            return 1;
        } else if (hourMin > range.afternoonStart && hourMin <= range.afternoonEnd) {
            return 2;
        } else if (hourMin > range.eveningStart && hourMin <= range.eveningEnd) {
            return 3;
        } else {
            return 0;
        }
    }
    
    static getSectionEnum(range: TimeSectionRange,date:Date):TimeSecEnum {
        
        const hourMin = date.getHours() * 100 + date.getMinutes();
        if (hourMin > range.morningStart && hourMin <= range.morningEnd) {
            return TimeSecEnum.Morning;
        } else if (hourMin > range.afternoonStart && hourMin <= range.afternoonEnd) {
            return TimeSecEnum.Afternoon;
        } else if (hourMin > range.eveningStart && hourMin <= range.eveningEnd) {
            return TimeSecEnum.Evening;
        } else {
            return TimeSecEnum.Night;
        }
    }
}
