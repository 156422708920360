import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportComponentService {

  constructor() { }

  getAllCss(container: HTMLDivElement){
    const styles = [];
    const componentElement = container;

    const ruleAppliesToElement = (rule: CSSStyleRule) => {
      try {
        if (componentElement.matches(rule.selectorText)) {
          return true;
        }
        const childElements = componentElement.querySelectorAll(rule.selectorText);
        return childElements.length > 0;
      } catch (e) {
        return false;
      }
    };

    for (let i = 0; i < document.styleSheets.length; i++) {
      const styleSheet = document.styleSheets[i] as CSSStyleSheet;
      try {
        if (styleSheet.cssRules) {
          for (let j = 0; j < styleSheet.cssRules.length; j++) {
            const rule = styleSheet.cssRules[j] as CSSStyleRule;
            if (ruleAppliesToElement(rule)) {
              styles.push(rule.cssText);
            }
          }
        }
      } catch (e) {
        console.warn('Access to stylesheet %s is denied. Ignoring...', styleSheet.href);
      }
    }
    return styles.join('\n');
  }
}
