import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { BasicdataReportComponent } from 'src/app/system/report/components/basicdata-report/basicdata-report.component';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { PatientApi,PatientListDto } from 'src/app/services/api-service/patient/patient-api';

@Component({
  selector: 'app-hist-basicdata-print-operation',
  templateUrl: './basicdata-print-operation.component.html',
  styleUrls: ['./basicdata-print-operation.component.css']
})
export class BasicdataPrintOperationComponent implements OnInit {

  @ViewChild(BasicdataReportComponent)
  basicdataReportComp:BasicdataReportComponent;


  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private PatientApi : PatientApi,
    private webApiClient: WebApiClient,
    private notification: EasyNotificationService) {
    this.api = this.webApiClient.createHisService('Patients/Patient');
  }

  // api服務
  api: WebApiService;

  // 查詢條件
  queryFormGroup: FormGroup;

  Seltype: ValueTextPair[] = [{ value: '1', text: '依病歷號排序' }, 
                              { value: '2', text: '依電話排序' },
                              { value: '3', text: '依生日排序' },
                              { value: '4', text: '依身分證排序' },
                              { value: '5', text: '依姓名排序' }];

  ngOnInit() { 
    this.createQueryFormGroup();
  }

  // 建立query form group物件與預設值
  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group({
      SortMethod:[this.Seltype[0].value], //初始值"依病歷號排序"
      startNum:[''],
      endNum:['']
    });
  }

  // btnQuery
  async onBtnQueryClick() {
    var SortMethod = this.queryFormGroup.controls['SortMethod'].value;
    var startNum = this.queryFormGroup.controls['startNum'].value;
    var endNum = this.queryFormGroup.controls['endNum'].value;
 
    var data : PatientListDto [] = await this.PatientApi.GetBasicData(startNum, endNum, SortMethod);
    this.basicdataReportComp.fetchData(data, startNum, endNum)
  }

  onBtnPrintClick() {
    this.basicdataReportComp.onPrint();
  }

  onBtnExportAsExcel() {
    this.basicdataReportComp.exportAsExcel();
  }

  
  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
  if (ev) {
    var d = new Date(ev);
    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    if (isMonth) {
      return [year, month].join(symbol);
    } else {
      return [year, month, day].join(symbol);
    }
  }
}

  
}
