import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SystemcodeApi } from "../api-service/system-code/systemcode-api";
import { GroupMsgData } from "../signalr-service";
import { ClinicDataService } from "../data-service/clinic-data-service";
import { EasyNotificationService } from "../easy-notification.service";
import { PricingApi } from "../api-service/pricing/pricing-api";


@Injectable({
  providedIn: 'root'
})
export class ClinicEventHandler {
  onWaitingListNeedRefresh = new EventEmitter();
  /**
   *
   */
  constructor(private clinicData: ClinicDataService,
    private notify: EasyNotificationService,
    private pricingApi: PricingApi,
  ) {
  }
  /** 執行事件 */
  Invoke(evt: GroupMsgData) {
    if (evt.Type == 'Action') {
      this.doAction(evt);
    } else if (evt.Type == 'Notify') {
      this.doNotify(evt);
    }
  }
  /** 動作事件 */
  private doAction(evt: GroupMsgData) {
    switch (evt.Act) {
      case "UpdateDx":
        this.clinicData.UpdateDx(evt.Arg);
        break;
      case "UpdateRx":
        this.clinicData.UpdateRx(evt.Arg);
        break;
      case "UpdateRs":
        this.clinicData.UpdateRs(evt.Arg);
        break;
      case "UpdateSystemCode":
        this.clinicData.UpdateSystemCode(evt.Arg);
        break;
      case "DeleteSystemCode":
        this.clinicData.DeleteSystemCode(evt.Arg);
        break;
      case "UpdateParameterProfile":
        this.clinicData.updateParam(evt.Arg);
        break;
      case "UpdateStat":
        this.clinicData.UpdateStat(evt.Arg);
        break;
      case "PharmacistChange":
        this.pricingApi.GetEditOptions(true);
        break;
      case "RefreshWaitList":
        this.onWaitingListNeedRefresh.emit();
        break;
    }
  }
  private doNotify(evt: GroupMsgData) {
    this.notify.showInfo(evt.Arg);
  }
}
