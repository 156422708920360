import { Component, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { PatientApi, PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { FirstFollowUpReportComponent } from 'src/app/system/report/components/first-followUp-report/first-followUp-report.component';

@Component({
  selector: 'app-initial-consultation-followUp-report',
  templateUrl: './initial-consultation-followUp-report.component.html',
  styleUrls: ['./initial-consultation-followUp-report.component.css']
})

export class InitialConsultationFollowUpReportComponent implements OnInit {
  @ViewChild(FirstFollowUpReportComponent)
  FirstFollowUpReportComponent: FirstFollowUpReportComponent;

  constructor(
    private fb: FormBuilder,
    private PatientApi: PatientApi,
    private webApiClient: WebApiClient,
    private route: ActivatedRoute,
    private notification: EasyNotificationService,) {
    this.api = this.webApiClient.createHisService('Patients/Patient');
  }

  // api服務
  api: WebApiService;

  // 查詢條件
  queryFormGroup: FormGroup;
  userConfirm: any;
  InitialConsultationFollowUpReportComponent: InitialConsultationFollowUpReportComponent;
  listItems: InitialConsultationFollowUpReportComponent[];
  Seltype: ValueTextPair[] = [
    { value: '1', text: '依病歷號排序' },
    { value: '2', text: '依初診日排序(由近到遠)' },
    { value: '3', text: '依初診日排序(由遠到近)' },
    { value: '4', text: '依診醫別排序(倒序)' },
    { value: '5', text: '依診醫別排序(正序)' },
    { value: '6', text: '依年齡排序(倒序)' },
    { value: '7', text: '依年齡排序(正序)' },]


  ngOnInit() {
    this.createQueryFormGroup();
  }

  // 建立query form group物件與預設值
  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group
      ({
        SortMethod: [this.Seltype[0].value], //初始值"依病歷號排序"
        startDate: [new Date(new Date().getFullYear(), new Date().getMonth())],
        endDate: [new Date(new Date())],
      });
  }

  // btnQuery
  async onBtnQueryClick() {
    var startDate = (new Date(this.queryFormGroup.controls['startDate'].value)).toLocaleDateString();
    var endDate = (new Date(this.queryFormGroup.controls['endDate'].value)).toLocaleDateString();
    var SortMethod = this.queryFormGroup.controls['SortMethod'].value;
    var data: PatientListDto[] = await this.PatientApi.GetFirstVisitedDates(startDate, endDate, SortMethod);
        if (data) {
            this.FirstFollowUpReportComponent.fetchData(data, startDate, endDate, false);
        }
    this.FirstFollowUpReportComponent.fetchData(data, startDate, endDate)
  }

  async onBtnQuery() {
    let valueBirth = this.queryFormGroup.controls['birthday'].value;
    let valueStartDate = this.queryFormGroup.controls['startDate'].value;
    let inputStartDate = valueStartDate ? DateHelper.getFormatedDateString(valueStartDate, false) : null;
    let valueEndDate = this.queryFormGroup.controls['endDate'].value;
    let inputEndDate = valueEndDate ? DateHelper.getFormatedDateString(valueEndDate, false) : null;
  }

  onBtnPrintClick() {
    this.FirstFollowUpReportComponent.onPrint();
  }

  onBtnExportAsExcel() {
    this.FirstFollowUpReportComponent.exportAsExcel();
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

}