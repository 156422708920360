import { Injectable, OnInit } from '@angular/core';
import { ValueTextPairNumberValue } from '../shared/models/value-text-pair';
import { ReserveApiService } from './api-service-dentist/reserve/reserve-api.service';
import { UserCache } from './user-cache';
import { TimeSecEnum } from '../enums/TimeSecEnum';
import { EasyNotificationService } from './easy-notification.service';
import { UserApi } from './api-service/user/user-api';
import { DateHelper } from '../shared/helpers/date-helper';
import { SexEnum } from '../enums/SexEnum';

@Injectable({
  providedIn: 'root'
})
export class ReserveService{

  doctorOpt:ValueTextPairNumberValue[] = [
    {text:'不指定',value:0},
    // {text:'魏醫師',value:1},
    // {text:'連醫師',value:2},
    // {text:'江醫師',value:3},
    // {text:'陳醫師',value:4},
    // {text:'方醫師',value:5},
    // {text:'許醫師',value:6},
    // {text:'鄭醫師',value:7},
  ]
  private defaultReserveList: ReserveItem[] = [
    
  ];
  constructor(private reserveApi:ReserveApiService,
    private userApi:UserApi,
    private notify:EasyNotificationService
  ) {
    // var getDate = (hour,min)=>{ 
    //   var d =new Date();
    //   d.setHours(hour,min,0,0);
    //   return d;
    // }
    // this.addReserve(new ReserveItem({
    //   Id: 1,
    //   IsCheckedIn: true,
    //   PatientId: 1,
    //   PatientNo: '000001146',
    //   PatientName: '楊素玲',
    //   PatientSex: '女',
    //   PatientAge: '48',
    //   PatientPhone: '0923818200',
    //   ReserveMatters: '根管',
    //   CheckInDate: getDate(10,0),
    //   CheckInTime:'10:00',
    //   EstimatedTime: 15,
    //   DoctorId:7,
    //   DoctorName: '鄭醫師',
    // })),
    // this.addReserve(new ReserveItem({
    //   Id: 2,
    //   IsCheckedIn: true,
    //   PatientId: 2,
    //   PatientNo: '000001196',
    //   PatientName: '蕭國倫',
    //   PatientSex: '男',
    //   PatientAge: '51',
    //   PatientPhone: '0975899495',
    //   ReserveMatters: '拔牙',
    //   CheckInDate:getDate(9,30),
    //   CheckInTime:'09:30',
    //   EstimatedTime: 25,
    //   DoctorId:1,
    //   DoctorName: '魏醫師',
    // }));
    // this.addReserve(new ReserveItem({
    //   Id: 3,
    //   IsCheckedIn: true,
    //   PatientId: 3,
    //   PatientNo: '000004455',
    //   PatientName: '牙予療',
    //   PatientSex: '男',
    //   PatientAge: '25',
    //   PatientPhone: '0975899495',
    //   ReserveMatters: '拔牙',
    //   CheckInDate:  getDate(10,30),
    //   CheckInTime:'10:30',
    //   EstimatedTime: 60,
    //   DoctorId:2,
    //   DoctorName: '連醫師',
    // }))
  }

  async init(){
    this.doctorOpt = await this.userApi.GetDoctorList()
  }
  
  public async getReserveList(doctorId:number,sDate:Date,eDate:Date){
    var a = await this.reserveApi.getAll(doctorId,sDate,eDate)
    a.forEach(x=>{
      x.RegDate = new Date(x.RegDate);
      x.CheckInDate = DateHelper.getDate(x.RegDate);
      x.CheckInTime = DateHelper.getHourMinTime(x.RegDate,true);
    });
    this.defaultReserveList = a;
    return this.defaultReserveList;
  }
  public async addReserve(model:ReserveItem){
    var clinic = UserCache.getLoginClinicId()
    if(!model.Id){
      try{
        await this.reserveApi.Reserve({
          ClinicId:clinic,
          DUTY:1,
          DoctorId:model.DoctorId||null, // 0轉null
          ICode:model.PatientICode,
          Id:0,
          PatientId:model.PatientId,
          RegDate:model.RegDate,
          Remark:'',
          ReserveMessage:model.ReserveMatters,
          RoomCode:model.RoomCode,
          TimeSec:model.TimeSec,
          EstimatedTime:model.EstimatedTime,
          ReserveMatters:model.ReserveMatters,
          ReminderText:model.ReminderText,
          ScheduleTime:model.ScheduleTime
        });
        this.notify.showSuccess('新增成功');
      }catch(e){
        this.notify.showError(e);
      }
      //model.Id = Math.max(...this.defaultReserveList.map(x=>x.Id))+1;
      //this.defaultReserveList.push(model);
    }else{
      this.notify.showError('失敗，資料已存在');
    }
  }
  public async updateReserve(model:ReserveItem){
    var clinic = UserCache.getLoginClinicId()
    if(model.Id){
      try{
        await this.reserveApi.Update({
          ClinicId:clinic,
          DUTY:1,
          DoctorId:model.DoctorId || null, // 0轉null,
          ICode:model.PatientICode,
          Id:model.Id,
          PatientId:model.PatientId,
          RegDate:model.RegDate,
          Remark:'',
          ReserveMessage:model.ReserveMatters,
          RoomCode:model.RoomCode,
          TimeSec:model.TimeSec,
          EstimatedTime:model.EstimatedTime,
          ReserveMatters:model.ReserveMatters,
          ReminderText:model.ReminderText,
          ScheduleTime:model.ScheduleTime
        })
        this.notify.showSuccess('更新成功');
      }catch(e){
        this.notify.showError(e);
      }
    }else{
      this.notify.showError('錯誤，要更新的資料不存在');
    }
  }
  public async cancelReserve(model:ReserveItem):Promise<boolean>{
    if(model.Id){
      try{
        await this.reserveApi.Cancel(model.Id)
        this.notify.showSuccess('取消成功');
        return true;
      }catch(e){
        this.notify.showError(e);
      }
    }else{
      this.notify.showError('錯誤，要更新的資料不存在');
    }
    return false;
  }
}


/**
 * 預約清單的物件
 */
export class ReserveItem {
  Id: number;
  /** 已報到 */
  IsCheckedIn: boolean
  /** 病患Id */
  PatientId: number;
  /** 病歷號 */
  PatientNo: string;
  /** 名字 */
  PatientCName: string;
  /** 身分證 */
  PatientCId: string;
  /** 性別 */
  PatientSex: SexEnum;
  SexName: string;
  /** 生日 */
  PatientBirthday: Date;
  /** 年齡 */
  PatientAge: string;
  /** 電話 */
  PatientPhone: string;
  /** 預約事項 */
  ReserveMatters: string;
  /** 報到時間 */
  CheckInDate: Date;
  /** 報到時間 */
  CheckInTime: string;
  /** 預估時間 */
  EstimatedTime: number;
  /** 醫師Id */
  DoctorId: number;
  /** 醫師名稱 */
  DoctorName: string;
  /** 診間代碼 */
  RoomCode:string;
  /** 預約人員 */
  Creater:string;
  /** 預約時間 */
  RegDate:Date;
  /** 保險身分 */
  PatientICode:string;
  /** 時段 */
  TimeSec:TimeSecEnum;
  /** 狀態 */
  StatusCode:string;
  /** 預約內容 */
  ReminderText:string;
  /** 排定預約內容發送時間 */
  ScheduleTime:Date;

  constructor(init?: Partial<ReserveItem>) {
    Object.assign(this, init);
  }
}
