import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
// import { VisitsReport, VisitsReportItem } from 'src/app/opd/opd-report/models/visits-report';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { HRxApiService } from 'src/app/services/api-service/opd/hrx-api.service';
// import { ReportApi } from '../../reportApi';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { RxApi } from 'src/app/services/api-service/rx/rx-api';
import { MainSetApi } from 'src/app/services/api-service/rx/mainset-api';
import { MainSetView } from 'src/app/services/api-service/rx/main-set-view';
// import { MainsetdataPrintComponent } from 'src/app/opd/opd-report/mainsetdata-print/mainsetdata-print.component';
import { RxSetDto } from 'src/app/services/api-service/rx/rx-set-dto';
//import { RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';

@Component({
  selector: 'app-mainsetdata-prt',
  templateUrl: './mainsetdata-prt.component.html',
  styleUrls: ['./mainsetdata-prt.component.css']
})
export class MainSetDataPrtComponent implements OnInit, AfterViewInit {

  reportName = '組合套餐資料檔列印';
  forExcel = true;

  sizePage = {
    width: 20, //cm
    height: 28.7 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]


  @ViewChild('reportContainer', { static: false } )
  reportContainer: ElementRef<HTMLDivElement>;
  upperboundIndex: number = 0;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  pageNo: number = 1;
  _sDate: string;
  _eDate: string;

  // 清單
  listItems: MainSetView[]; //MainSetComponent[];

  queryFormGroup: FormGroup;
  data: MainSetView[];
  totalRecord: number;
  RxType: ValueTextPair[] = [];  

  Dx1 :String;


  // BeginRxCode: any[];
  // EndRxCode: any[];
  // drugItems: RxOpt[] = [];
  // filterDrugItems: ValueTextPair[] = [];  
  // showDoctorNameColume: any;

  constructor(private route: ActivatedRoute,
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService) {
    //this.api = this.webApiClient.createHisService('Opd/MainSet');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }
  
  fetchData(data: MainSetView[], forExcel = true) {
    this.forExcel = forExcel;
    this.elContainer = document.getElementById("container");

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    // this.nowStr = this.onGetDateString(new Date(), true);
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    // this.timeStr = this.onGetTimeString(new Date());
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData();
  }

  // #region 組報表
  // 組報表的html內容
  insertListData() {
    // debugger
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
    
    // var html_total = this.createHTMLTotal();
    var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁    
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    // elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      
      // 加入 List 每一筆 header 的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMListDx(this.listItems[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }

      // 加入 List detail 的內容
      // prevTableContent = elTableContent.innerHTML;
      elTableContent.innerHTML += html_ListerHeader;
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }

      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i].Details);
      elTableContent.innerHTML += html_Block;
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }

      iBlock += 1;
    }
    // 總頁數計算
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }


  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="4" style="height: 46px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

//報表上半部
createHTMListDx(data: MainSetView){  
  var blankLine = `<tr><td colspan='9'><table width='100%'>
                    <tr>
                        <th
                        style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>      
                        <th
                          style="text-align: left; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                          &nbsp;
                        </th>
                        <th
                          style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                          &nbsp;
                        </th>
                        <th
                          style="text-align: left; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                          &nbsp;
                        </th>
                        <th
                          style="text-align: left; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                          &nbsp;
                        </th>
                        <th
                        style="text-align: left; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>
                        <th
                        style="text-align: left; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>
                        <th
                        style="text-align: left; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>
                        <th
                        style="text-align: left; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>
                        <th
                        style="text-align: right; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                        &nbsp;
                        </th>
                   </tr>`;
    var code = (!data.Code ? '&nbsp;' : data.Code);
    var dx1 = (!data.Dx1 ? '&nbsp;' : data.Dx1);
    var dx1Name = (!data.Dx1Name ? '&nbsp;' : data.Dx1Name);
    var dx2 = (!data.Dx2 ? '&nbsp;' : data.Dx2);
    var dx5 = (!data.Dx5 ? '&nbsp;' : data.Dx5);
    var sur1 = (!data.Sur1 ? '&nbsp;' : data.Sur1);
    var dx3 = (!data.Dx3 ? '&nbsp;' : data.Dx3);
    var dx6 = (!data.Dx6 ? '&nbsp;' : data.Dx6);
    var pe = (!data.PE ? '&nbsp;' : data.PE);
    var dx4 = (!data.Dx4 ? '&nbsp;' : data.Dx4);
    var name = (!data.Name ? '&nbsp;' : data.Name);
    var cc = (!data.CC ? '&nbsp;' : data.CC);

    var dxs = `<tr>
                  <td style="text-align: left; width: 5%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  組別
                  </td>
                  <td style="text-align: left; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  ` + code + `
                  </td>
                  <td style="text-align: left; width: 5%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  科別
                  </td>
                  <td style="text-align: left; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  ` + (!data.MedDeptCode ? '&nbsp;' : data.MedDeptCode) + `
                  </td>
                  <td style="text-align: left; width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  診斷1
                  </td>
                  <td style="text-align: left; width: 10%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  ` + dx1 + `
                  </td>
                  <td colspan="4" style="text-align: left; width: 44%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                  ` + dx1Name + `
                  </td>
              </tr>
              <tr>
              <td rowspan="3" style="text-align: left; vertical-align: top; width: 5%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              主訴  
              </td>
              <td colspan="3" rowspan="3" style="text-align: left; vertical-align: top; width: 35%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
                <span style="white-space: pre-line">` + cc + `</span>
              </td>
              <td style="text-align: left; vertical-align: top; width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              診斷2
              </td>
              <td style="text-align: left; vertical-align: top;width: 10%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + dx2 + `
              </td>
              <td style="text-align: left; vertical-align: top;width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              診斷5
              </td>
              <td style="text-align: left; vertical-align: top; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + dx5 + `
              </td>
              <td style="text-align: left; vertical-align: top; width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              手術ICD
              </td>
              <td style="text-align: left; vertical-align: top; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + sur1 + `
              </td>
          </tr>
          <tr style="text-align: left; vertical-align: top;">
              <td style="text-align: left; vertical-align: top; width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              診斷3
              </td>
              <td style="text-align: left; vertical-align: top;width: 10%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + dx3 + `
              </td>
              <td style="text-align: left; vertical-align: top;width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              診斷6
              </td>
              <td style="text-align: left; vertical-align: top; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + dx6 + `
              </td>
              <td style="text-align: left; vertical-align: top; width: 8%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              主治
              </td>
              <td style="text-align: left; vertical-align: top; width: 15%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + '&nbsp;' + `
              </td>
          </tr>
          <tr style="text-align: left; vertical-align: top;">
              <td style="text-align: left; vertical-align: top; width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              診斷4
              </td>
              <td style="text-align: left; vertical-align: top;width: 10%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + dx4 + `
              </td>
              <td style="text-align: left; vertical-align: top;width: 6%; height: 25px; border-right: 0px solid black; font-size: 16px; font-weight: bolder; border-left: 0px solid black; border-top: none; border-bottom: none;">
              說明
              </td>
              <td colspan="3" style="text-align: left; vertical-align: top; width: 38%; height: 25px; border-right: 0px solid black; font-size: 16px; border-left: 0px solid black; border-top: none; border-bottom: none;">
              ` + name + `
              </td>
          </tr>
          </table></td></tr>`;
    return blankLine.concat(dxs);
  }

  //報表下半部
  createHTMLListHeader() {
    return `<tr>   
               <th
                style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;N
              </th>      
              <th
                style="text-align: left; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;藥代
              </th>
              <th
                style="text-align: left; width: 15%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;健保碼
              </th>
              <th
                style="text-align: left; width: 40%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;藥名-一
              </th>
              <th
                style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;數量
              </th>
               <th
                style="text-align: left; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;頻率
              </th>
               <th
                style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;日份
              </th>
               <th
                style="text-align: left; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;總量
              </th>
               <th
                style="text-align: left; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black;">
                &nbsp;途徑
              </th>
            </tr>`;
  }
  createHTMLBlock(data: RxSetDto[]) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    
    var htmlString = "";
    data.forEach(item => {
      var temp = `
        <tr>
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${item.SpecialRule}</td> 
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${item.RxCode}</td> 
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${item.StdCode}</td> 
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${item.ProdName}</td> 
          <td style="text-align: right; ${commonStyle}" ${commonClassText}>${item.Dose ?? ''}</td> 
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${item.Frequency ?? ''}</td> 
          <td style="text-align: right; ${commonStyle}" ${commonClassText}>${item.Days ?? ''}</td> 
          <td style="text-align: right; ${commonStyle}" ${commonClassText}>${item.TotalDose ?? ''}</td> 
          <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${ item.Way ?? ''}</td>
        </tr>`
      htmlString += temp;
    });

    return htmlString;
  }

  createHTMLPageHeader(iPage) {
    var reportName = '組合套餐資料檔列印';
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="10" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td colspan="3" style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="4" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      
                      </td>
                      <td colspan="3" style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td colspan="3" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="4" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      
                      </td>
                      <td colspan="3" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage +`"></span>
                      </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = '組合套餐資料檔';
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const reportName ='組合套餐資料檔';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  onGetDate(ev) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day].join('');
    }
  }
}


