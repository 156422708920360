<div #reportContainer>
  <div class="container page-container"  *ngIf="arrearReceiptViewModel!=null">
    <div class="row custom-row-margin w-100">
      <div class="col-12" style="font-weight:bold;font-size: x-large;">【{{arrearReceiptViewModel.ClinicName}} 押金單】</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">生 日：{{arrearReceiptViewModel.PatientBirthDay}}</div>
      <div class="col-3-5 col-font-style">姓 名：{{arrearReceiptViewModel.PatientName}}</div>
      <div class="col-4 col-font-style">身分證：{{arrearReceiptViewModel.PatientCId}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">門診日：{{arrearReceiptViewModel.OutpatientDay}}</div>
      <div class="col-3-5 col-font-style">時 間：{{arrearReceiptViewModel.OutpatientTime}}</div>
      <div class="col-4 col-font-style">身分別：{{arrearReceiptViewModel.ICTypeName}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-12 col-font-style">押 金：{{arrearReceiptViewModel.MissingCardDeposit}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-12 col-font-style">(★★請務必於10日內(不含例假日)，前來還卡★★)</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-12 col-font-style">地 址：{{arrearReceiptViewModel.ClinicAddress}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-12 col-font-style">電 話：{{arrearReceiptViewModel.ClinicPhone}}</div>
    </div>
  </div>

</div>
