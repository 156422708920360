import { Directive, HostListener, ElementRef, Input } from '@angular/core';


@Directive({
  selector: '[appNumberIntegerOnly]'
})
export class NumberIntegerOnlyDirective {
  @Input()
  max: number;
  @Input()
  min: number;
  constructor(private el: ElementRef<HTMLInputElement>) {
    if (!el.nativeElement.type || el.nativeElement.type.toLowerCase() != 'number') {
      el.nativeElement.type = 'tel';
    }
    el.nativeElement.autocomplete = "off";
  }

  @HostListener('keyup', ['$event'])
  onKeyup(e: KeyboardEvent) {
    if (e.target instanceof HTMLInputElement) {
      var value = parseFloat(e.target.value);
      if (this.max && value > this.max) {
        e.target.value = this.max.toFixed(1);
        return;
      }
      if (this.min && value < this.min) {
        e.target.value = this.min.toFixed(1);
        return;
      }
    }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {

    if (
      ['Delete', 'Backspace', 'Tab', 'Escape', 'Enter'].includes(e.key) ||
      ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(e.key) ||
      ['Home', 'End', 'Left', 'Right'].includes(e.key) ||
      ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'].includes(e.key) ||
      ['Numpad0', 'Numpad1', 'Numpad2', 'Numpad3', 'Numpad4', 'Numpad5',
        'Numpad6', 'Numpad7', 'Numpad8', 'Numpad9', 'NumpadDecimal'].includes(e.key) ||
      (e.key.toUpperCase() === 'A' && e.ctrlKey === true) || // Allow: Ctrl+A
      (e.key.toUpperCase() === 'C' && e.ctrlKey === true) || // Allow: Ctrl+C
      (e.key.toUpperCase() === 'V' && e.ctrlKey === true) || // Allow: Ctrl+V
      (e.key.toUpperCase() === 'X' && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key.toUpperCase() === 'A' && e.metaKey === true) || // Cmd+A (Mac)
      (e.key.toUpperCase() === 'C' && e.metaKey === true) || // Cmd+C (Mac)
      (e.key.toUpperCase() === 'V' && e.metaKey === true) || // Cmd+V (Mac)
      (e.key.toUpperCase() === 'X' && e.metaKey === true) // Cmd+X (Mac)
    ) {
      // 只能打一個點
      var el = e.target as HTMLInputElement;
      var curValue = el.value;
      if (e.key == '.') {
        if (curValue.includes('.')) {
          e.preventDefault();
        } else if (!curValue || el.selectionStart == 0) {
          e.target['value'] = '0' + curValue ?? '';
          el.selectionStart = 1;
          el.selectionEnd = 1;
        }
      }
      return;  // let it happen, don't do anything
    } else {
      e.preventDefault();
    }
  }
  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    // console.log('k up', e)
    var el = (e.target as HTMLInputElement);
    // const mandarinphoneticsymbols = /[\u4E00-\u9FFF\u3100-\u312F']+/g;   //中文輸入法輸入的符號及文字都avoid
    const nonDigitRegex = /\D+/g; // get a digit-only string
    el.value = el.value.replace(nonDigitRegex, '');

    // 留下不是. 獲第一個.
    if (el.value.length < 1) return;
    console.log(el.value);
    var expRet = el.value.match(/[0-9]/g).filter((x, i, a) => x != '.' || a.indexOf(x) == i);
    if (expRet[0] == '.') {
      expRet = ['0'].concat(expRet);
    }
    el.value = expRet.join('');
  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D+/g, ''); // get a digit-only string
    document.execCommand('insertText', false, pastedInput);
  }

}



