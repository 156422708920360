export class NHIHistory{
    Id:number;
    ClinicId:number;
    PatientId:number;
    NHIRegICId:number;
    ClinicCode:string;
    PatientNo:string;
    Iuldt:Date;
    MainDisease:string;
    SecDisease1:string;
    SecDisease2:string;
    SecDisease3:string;
    SecDisease4:string;
    SecDisease5:string;
    MainSPC:string;
    SecSPC1:string;
    SecSPC2:string;
    MedicalFees:number;
    Deductible:number;
    HospitalFees:number;
    HdeductibleA:number;
    HdeductibleB:number;
    IBedNo:string;
    ICase:string;
    ITRDT:Date;
    HealthCare:string;
    HealthCareDate:string;
    HealthCareCode:string;
    PrenatalCare:string;
    PrenatalCode:string;
    Idays:number;
    IChrContDays:number;
    ICtrlDays:number;
    Idru:string;
    IGenFreq:number;
    IChrFreq:number;
    IChrContFreq:number;
    ICtrlFreq:number;
    ICtrlChrFreq:number;
    ICtrlContFreq:number;
    IPTDose	:number;
    IOTDose:number;
    ISTDose:number;
    IChrItem:string;
    ICtrlItem:string;
    IReTreatnum?:string;
    IPartOPD:number;
    IPartRx:number;
    IPartExam:number;
}
