import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BasePageListComponent } from 'src/app/shared/components/base/base.pagelist.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
// his - shared
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { ContractEditComponent } from '../../edit/edit.component';
// this module
import { Contract } from '../../models/contract';


@Component({
  selector: 'app-patients-expiring-contract-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ExpiringListComponent extends BasePageListComponent<Contract> implements OnInit {

  @ViewChild(ContractEditComponent,  { static: false })
  editComponent: ContractEditComponent;

  constructor(private fb: FormBuilder,
    private webApiClient: WebApiClient,
    private notificationDI: EasyNotificationService) {
    super();
    this.api = this.webApiClient.createHisService('patients/contract');
    this.notification = notificationDI;

  }
  // 查詢時需要的選項或數值
  editOptions = {
  };

  // 建立query form group
  makeQueryFormGroup() {
    return this.fb.group({
      patientNo: [''],
      patientCid: [''],
      endMonth: [new Date()],
    });
  }

  makeQueryConditions() {
    let endString = '';
    if (this.queryFormGroup.controls['endMonth'].value) {
      endString = DateHelper.getIsoStringNoTimeZone(this.queryFormGroup.controls['endMonth'].value);
    }
    // tslint:disable-next-line: no-shadowed-variable
    const query = {
      endMonth: endString,
      patientNo: this.queryFormGroup.controls['patientNo'].value,
      patientCid: this.queryFormGroup.controls['patientCid'].value,
    };
    return query;
  }

  ngOnInit() {
    super.ngOnInit();
    this.getEditOptions();
  }

  refreshList(bit) {
    this.refreshListAction = 'getexpiring';
    super.refreshList(bit);
  }

  // 取得查詢時需要的選項或數值
  private getEditOptions() {
    // 呼叫api
    // api的查詢條件
    this.api.get('getEditOptions').subscribe(
      (data) => {
        this.editOptions = data;
      },
      error => {
        this.notification.showGetDataFailed('expiring-contract');
      }
    );
  }
}

