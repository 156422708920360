<div class="row narrow-padding hist-visitcount">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <div style="display: flex; align-items: center;" class="lh-sm">
          <div><span> </span></div>
          <div style="flex-grow: 1;"></div>

          <button type="button" i18n="@@Preview" (click)="onBtnQuery()"
            class="vis-btn-config p-0 position-relative pl-2 w-24" title="預覽查詢">
            <mat-icon class="pref-icon print vis-combine-icon">print</mat-icon>
            <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">預覽查詢</span>
          </button>


        </div>
      </div>

      <div class="card-body">
        <div class="col-sm-12 row personalList" style="padding: 0 80px; padding-top: 20px; justify-content: center;">
          <form [formGroup]="queryFormGroup" class="vis-form extend-msg vis-form-narrow p-0">
            <div class="col-sm-12">
              <label>
                <span i18n="@@startDate">起始日期：</span>
                <app-vis-datepicker formControlName="startDate" style="vertical-align: middle;"
                  class="d-inline-flex position-relative -top-2" (valueChange)="onDtpStartDateChange($event)">
                </app-vis-datepicker>
              </label>
              <label>
                <span i18n="@@endDate">　結束日期：</span>
                <app-vis-datepicker formControlName="endDate" style="vertical-align: middle;"
                  class="d-inline-flex position-relative -top-2" (valueChange)="onDtpEndDateChange($event)">
                </app-vis-datepicker>
              </label>
            </div>

            <div class="col-sm-12">
              <label>
                <span i18n="@@searchMethod">統計身分：　</span>
                <mat-radio-group formControlName="searchMethod" (change)="onSearchMethodChange($event)"
                  style="top: 6px;line-height: 0px;" class=" vis-form-field position-relative">
                  <mat-radio-button [value]="'1'" [checked]="this.defSearchMethod=='1'">
                    健保&nbsp;　
                  </mat-radio-button>
                  <mat-radio-button [value]="'2'" [checked]="this.defSearchMethod=='2'">
                    自費　&nbsp;　
                  </mat-radio-button>
                  <mat-radio-button [value]="'3'" [checked]="this.defSearchMethod=='3'">
                    全部　&nbsp;　
                  </mat-radio-button>
                </mat-radio-group>
              </label>
            </div>
            <div class="col-sm-12">
              <label>
                <mat-checkbox style="margin-left: 10px;" [checked]="false" formControlName="haveADx">
                  只統計診察費 ＞ 0 　&nbsp;　
                </mat-checkbox>
              </label>
            </div>
            <div class="col-sm-12">
              <label>
                <span i18n="@@CaseCode">案類：　</span>
                <app-vis-multiselect [textField]="'value'" [valueField]="'text'" [valuePrimitive]="true"
                  [data]="caseOpt" formControlName="selectedCaseItems" class="multi-select-selector"
                  style="width: 400px;"></app-vis-multiselect>
              </label>
            </div>
            <div class="col-sm-12">
              <label>
                <span i18n="@@overAmount">看診超過(含)　</span>
                <app-vis-numerictextbox style="width: 80px;" class="ddlCus no-extend" formControlName='overAmount'
                  [min]="1" [format]="'n0'"></app-vis-numerictextbox>
                <span i18n="@@overAmount">次</span>
              </label>
            </div>
            <div class="col-sm-12">
              <label>
                <span i18n="@@reportType">請選擇要列印之報表：　</span>
                <app-vis-dropdownlist class="d-inline-block no-extend" [valuePrimitive]="true" style="width: 150px;"
                  [data]="reportTypeList" [textField]="'text'" [valueField]="'value'" formControlName="reportType"
                  (valueChange)="onReportTypeChange($event)">
                </app-vis-dropdownlist>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<app-vis-dialog title="看診次數統計報表預覽列印" [maxWidth]="'90vw'" [superWidth]="'90%'" [show]="isPreviewOpened" (close)="onPreviewClose()">

  <app-visitcount-report (printed)="onPrinted($event)"></app-visitcount-report>
  <app-vis-dialog-actions>
      <button type="button" *ngIf="isresult" (click)="onBtnPrintClick()" primary="true" i18n="@@Pprint">列印</button>
      <button type="button" *ngIf="isresult" (click)="onBtnExportAsExcel()" primary="true" i18n="@@Pprint">匯出Excel</button>
      <button type="button" (click)="onPreviewClose()" i18n="@@DialogClose">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
