<ng-template let-data let-col="col" let-row="index">
    <ng-container *ngIf="editRow==row;else elseTmp">
        <div class="w-100 ellipsis-content text-center" style="display: grid;">
            <ng-content></ng-content>
        </div>
    </ng-container>
    <ng-template #elseTmp>
        <div class="w-100 ellipsis-content" [ngClass]="[textCenter ? 'text-center' : 'text-left']">
            <ng-container *ngTemplateOutlet="viewTmp;context:{$implicit:data,index:row}"></ng-container>
            <ng-container *ngIf="!viewTmp">
                {{data[col._field]}}
            </ng-container>
        </div>
    </ng-template>
</ng-template>
