import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DualListSelector } from './models/setting';
import { QueryParam } from 'src/app/services/api-service/queryParam';
import { DualListSeelctorDirectionsEnum } from 'src/app/enums/DualListSelectorDirections';


/**
 * 雙列表選擇器的Component
 * 此Component內部不處理資料源，僅負責畫面渲染和動作事件的橋接
 * 資料源由父層組件控制
 * @template Left - 左側清單的類型
 * @template Right - 右側清單的類型
 */
@Component({
  selector: 'app-dual-list-selector',
  templateUrl: './dual-list-selector.component.html',
  styleUrls: ['./dual-list-selector.component.css']
})

export class DualListSelectorComponent<Left, Right> implements OnInit {

  constructor() { }

  ngOnInit(): void {

  }
  /**
   * 雙列表選擇器的內容。
   * @type {DualListSelector<Left,Right>}
   */
  @Input() content: DualListSelector<Left, Right>;

  /**
   * 左側網格是否顯示加載中狀態。
   * @type {boolean}
   */
  isShowLeftGirdLoading: boolean = false;

  /**
   * 右側網格是否顯示加載中狀態。
   * @type {boolean}
   */
  isShowRightGirdLoading: boolean = false;

  /**
   * 當左側網格需要刷新時發出的事件。
   * @type {EventEmitter<void>}
   */
  @Output()
  onLeftRefresh: EventEmitter<QueryParam> = new EventEmitter();

  /**
   * 當右側網格需要刷新時發出的事件。
   * @type {EventEmitter<void>}
   */
  @Output()
  onRightRefresh: EventEmitter<QueryParam> = new EventEmitter();

  /**
   * 左側網格的跳過項目數量，用於分頁。
   * @type {number}
   */
  leftGridSkip: number = 0;

  /**
   * 右側網格的跳過項目數量，用於分頁。
   * @type {number}
   */
  rightGridSkip: number = 0;

  /**
   * 處理左側網格的分頁變更事件。
   * @param {any} pageNum - 分頁事件。
   */
  leftGridChange(pageNum: any): void {
    this.leftGridSkip = pageNum;
    let pageSize = this.content.settings.pageSize;

    this.emitRefresh(DualListSeelctorDirectionsEnum.Left, {
      pageSize: pageSize,
      pageSkip: (pageNum - 1) * pageSize

    });
  }

  /**
   * 處理右側網格的分頁變更事件。
   * @param {any} event - 分頁事件。
   */
  rightGridChange(pageNum: any): void {
    this.rightGridSkip = pageNum;
    let pageSize = this.content.settings.pageSize;

    this.emitRefresh(DualListSeelctorDirectionsEnum.Right, {
      pageSize: pageSize,
      pageSkip: (pageNum - 1) * pageSize

    });
  }

  /**
   * 傳送重新整理的事件
   * @param {directions} direction - 刷新的方向（左或右）。
   */
  async emitRefresh(direction: DualListSeelctorDirectionsEnum, page: QueryParam): Promise<void> {
    if (direction === DualListSeelctorDirectionsEnum.Left) {
      this.onLeftRefresh.emit(page);
    } else if (direction === DualListSeelctorDirectionsEnum.Right) {
      this.onRightRefresh.emit(page);
    }
  }

  getOpAreaWidth(): string {
    const totalWidth = 100;
    const leftWidth = parseFloat(this.content.leftGrid.gridSetting.width);
    const rightWidth = parseFloat(this.content.rightGrid.gridSetting.width);
    const opAreaWidth = totalWidth - leftWidth - rightWidth;
    return `${opAreaWidth}%`;
  }

}
