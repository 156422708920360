import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UrlHelper } from 'src/app/shared/helpers/url-helper';

@Component({
  selector: 'app-login-directly',
  templateUrl: './login-directly.component.html',
  styleUrls: ['./login-directly.component.css']
})
export class LoginDirectlyComponent implements OnInit {

  // sample url:
  // http://192.168.97.248/clinic/care/loginDirectly?UserId=2454BC06-DAFE-471C-8720-F47ACAED5185&ClinicId=101EBF80-EA4A-463C-8ACC-8B46827BFA30&AuthKey=07841BF6-91B5-45F1-832D-C6011FD04A42&Target=WaitingList
  // http://localhost:4200/vision/loginDirectly?UserId=2454BC06-DAFE-471C-8720-F47ACAED5185&ClinicId=101EBF80-EA4A-463C-8ACC-8B46827BFA30&AuthKey=07841BF6-91B5-45F1-832D-C6011FD04A42&Target=WaitingList
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) {
      activatedRoute.paramMap.subscribe((q) => {
        this.companyCode = UrlHelper.getCompanyCodeFromRouteParamMap(q);
      });
    this.activatedRoute.queryParams.subscribe(params => {
      this.userId = params['UserId'];
      this.clinicId = params['ClinicId'];
      this.authKey = params['AuthKey'];
      this.target = params['Target'];
    });
  }
  companyCode = '';
  userId  = '';
  clinicId  = '';
  authKey  = '';
  target  = '';
  defaultPage = '/patients'; // '/system/announcement'
  isLogging = true;
  isLoginFailed = false;

  ngOnInit(): void {
    setTimeout(() => { this.loginDirectly(); }, 500);
  }

  private getTargetUrl() {
    if (this.target === 'WaitingList') {
      return '/' + this.companyCode + '/registers/opd-list';
    } else{
      return '/' + this.companyCode + this.defaultPage;
    }
  }
  private loginDirectly() {
    this.authenticationService
      .loginDirectly(this.userId, this.companyCode, this.clinicId, this.authKey)
      .subscribe(
        (data) => {
          if (data) {
            this.isLogging = false;
            const url = this.getTargetUrl();
            this.router.navigate([url]);
          } else {
            this.isLogging = false;
            this.isLoginFailed = true;
          }
        },
        (error) => {
          this.isLogging = false;
          this.isLoginFailed = true;
        }
      );
  }
}
