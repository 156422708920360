import { Pipe, PipeTransform } from '@angular/core';
import { ValueTextPair } from '../models/value-text-pair';

@Pipe({ name: 'valueToText' })
export class ValueToTextPipe implements PipeTransform {
  transform(value: any, valueText?: ValueTextPair[],showValue:boolean = false,textNotFound:string = ''): string {
    if(valueText instanceof Array){
      var source = valueText.map(v=>{
        return {
          value:v.value,
          text:v.text
        };
      })
      var text = source.find(vt=>vt.value == value)?.text || textNotFound
      return showValue&&value?`${value}|${text}` : text;
    }
    return value;
  }
}
