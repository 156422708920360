export class H12Helper{

    /**
     *
     */
    constructor(public source:H12) {}
    /** 商品名 */
    get ProdName(): string{ return this.source.ProdName};
    /** 成分名 */
    get IIName(): string{ return this.source.IIName};
    /** 原sq流水號 */
    get OriSequence(): string{ return this.source.sq};
    /** 資料格式 */
    get Type(): string{ return this.source.e12tp};
    /** 醫事服務機構代號 */
    get NHICode(): string{ return this.source.e12cd};
    /** 費用年月 */
    get Month(): string{ return this.source.e12ym};
    /** 申報類別 */
    get Kind(): string{ return this.source.e12kd};
    /** [案件分類] */
    get Case(): string{ return this.source.e12cs};
    /** [流水號] */
    get Sequence(): string{ return this.source.e12sq};
    /** [身分證號] */
    get CId(): string{ return this.source.e12id};
    /** [藥品給藥日份 (原E12R1取消)] */
    get p1_days(): string{ return this.source.e12ay1};
    /** [醫令調劑方式] */
    get p2_dispType(): string{ return this.source.e12em1};
    /** [醫令類別] */
    get p3_OrderType(): string{ return this.source.e12od1};
    /** [藥品(項目)代號] */
    get p4_rxCode(): string{ return this.source.e12rx1};
    /** [藥品用量 (原E12QT1取消)] */
    get p5_rxDose(): string{ return this.source.e1205};
    /** [診療之部位 (原E12QT1取消)] */
    get p6_part(): string{ return this.source.e12ps1};
    /** [藥品使用頻率 (原E12FQ1取消)] */
    get p7_freq(): string{ return this.source.e1207};
    /** [支付成數] */
    get p8_payPersentage(): string{ return this.source.e12mu1};
    /** [給藥途徑/作用部位] */
    get p9_way(): string{ return this.source.e12pt1};
    /** [總量] */
    get p10_total(): string{ return this.source.e12tq1};
    /** [單價] */
    get p11_price(): string{ return this.source.e12pr1};
    /** [點數] */
    get p12_point(): string{ return this.source.e12am1};
    /** [醫令序] */
    get p13_orderSn(): string{ return this.source.e12sn1};
    /** [執行時間-起] */
    get p14_startTime(): string{ return this.source.e12st1};
    /** [執行時間-迄] */
    get p15_endTime(): string{ return this.source.e12et1};
    /** [執行醫事人員代號] */
    get p16_doctorCode(): string{ return this.source.e12dx1};
    /** [慢性病連續處方箋、同一療程及排程檢查案件註記] */
    get p17_caseNote(): string{ return this.source.e12lo1};
    /** [影像來源] */
    get p18_imageSource(): string{ return this.source.e12pa1};
    /** [事前審查受理編號] */
    get p19_previewNo(): string{ return this.source.e12pn1};
    /** [就醫科別] */
    get p20_deptCode(): string{ return this.source.e12sb1};
    /** [自費特材群組序號] */
    get p21_materialSq(): string{ return this.source.e12bs1};
    /** [未列項註記] */
    get p22_unlistItemNote(): string{ return this.source.e12nl};
    /** [未列項名稱] */
    get p23_unlistItemName(): string{ return this.source.e12nn};
    /** [委託或受託執行轉（代）檢醫事機構代號] */
    get p24_entrustClinicCode(): string{ return this.source.e12or};
    /** 健保欄位=(p25)長度S:0→20字元←[藥品批號] */
    get p25_rxBatchNumber(): string{ return this.source.e1225};
    /** 健保欄位=(p26)長度S:0→20字元←[當次就醫之就醫識別碼(有刷卡之就醫識別碼)NHIRegIC.Itreatnum(M15)] */
    get p26_Itreatnum(): string{ return this.source.e12ic};
  }

 /** 門診申報-醫令清單段(H12) */
export interface H12 {

    /** table stoarge用 */
    PartitionKey: string;
    /** table stoarge用 */
    RowKey: string;
    /** table stoarge用 */
    Timestamp: Date;
    /** table stoarge用 */
    ETag: string;
    /** 診所id */
    ClinicId: number;
    /** 個案病歷號 */
    PatientNo: number;
    /** 病歷id */
    HistoryId: number;
    /** 原病歷id */
    Org_hisid: number;
    /** 原sq流水號 */
    sq: string;
    /** 健保欄位=(無)長度F:2→2字元←[資料格式] */
    e12tp: string;
    /** 健保欄位=(無)長度F:10→10字元←[醫事服務機構代號] */
    e12cd: string;
    /** 健保欄位=(無)長度F:5→5字元←[費用年月] */
    e12ym: string;
    /** 健保欄位=(無)長度F:1→1字元←[申報類別] */
    e12kd: string;
    /** 健保欄位=(無)長度F:2→2字元←[案件分類] */
    e12cs: string;
    /** 健保欄位=(無)長度F:6→6字元←[流水號] */
    e12sq: string;
    /** 健保欄位=(無)長度F:10→10字元←[身分證號] */
    e12id: string;
    /** 健保欄位=(p1)長度N:1→2字元←[藥品給藥日份 (原E12R1取消)] */
    e12ay1: string;
    /** 健保欄位=(p2)長度F:1→1字元←[醫令調劑方式] */
    e12em1: string;
    /** 健保欄位=(p3)長度F:1→1字元←[醫令類別] */
    e12od1: string;
    /** 健保欄位=(p4)長度S:0→12字元←[藥品(項目)代號] */
    e12rx1: string;
    /** 健保欄位=(p5)長度N:1→7字元←[藥品用量 (原E12QT1取消)] */
    e1205: string;
    /** 健保欄位=(p6)長度S:0→18字元←[診療之部位 (原E12QT1取消)] */
    e12ps1: string;
    /** 健保欄位=(p7)長度S:0→18字元←[藥品使用頻率 (原E12FQ1取消)] */
    e1207: string;
    /** 健保欄位=(p8)長度N:1→6字元←[支付成數] */
    e12mu1: string;
    /** 健保欄位=(p9)長度S:0→4字元←[給藥途徑/作用部位] */
    e12pt1: string;
    /** 健保欄位=(p10)長度N:1→7字元←[總量] */
    e12tq1: string;
    /** 健保欄位=(p11)長度N:1→10字元←[單價] */
    e12pr1: string;
    /** 健保欄位=(p12)長度N:1→8字元←[點數] */
    e12am1: string;
    /** 健保欄位=(p13)長度S:0→3字元←[醫令序] */
    e12sn1: string;
    /** 健保欄位=(p14)長度S:0→11字元←[執行時間-起] */
    e12st1: string;
    /** 健保欄位=(p15)長度S:0→11字元←[執行時間-迄] */
    e12et1: string;
    /** 健保欄位=(p16)長度S:0→10字元←[執行醫事人員代號] */
    e12dx1: string;
    /** 健保欄位=(p17)長度S:0→1字元←[慢性病連續處方箋、同一療程及排程檢查案件註記] */
    e12lo1: string;
    /** 健保欄位=(p18)長度S:0→1字元←[影像來源] */
    e12pa1: string;
    /** 健保欄位=(p19)長度S:0→13字元←[事前審查受理編號] */
    e12pn1: string;
    /** 健保欄位=(p20)長度F:2→2字元←[就醫科別] */
    e12sb1: string;
    /** 健保欄位=(p21)長度S:0→3字元←[自費特材群組序號] */
    e12bs1: string;
    /** 健保欄位=(p22)長度S:0→1字元←[未列項註記] */
    e12nl: string;
    /** 健保欄位=(p23)長度S:0→100字元←[未列項名稱] */
    e12nn: string;
    /** 健保欄位=(p24)長度S:0→10字元←[委託或受託執行轉（代）檢醫事機構代號] */
    e12or: string;
    /** 健保欄位=(p25)長度S:0→20字元←[藥品批號] */
    e1225: string;
    /** 健保欄位=(p26)長度S:0→20字元←[當次就醫之就醫識別碼(有刷卡之就醫識別碼)NHIRegIC.Itreatnum(M15)] */
    e12ic: string;
    /** 比對用之key 合併後 相同醫令 多筆 需用日期分再比對 */
    e12da: string;
    /** 建立者 */
    creater: string;
    /** 建立時間 */
    createtime: Date;
    /** 商品名 */
    ProdName:string;
    /** 成分名 */
    IIName:string;
}
