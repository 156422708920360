
export class PRN001Params{
  MEDFeeSection: number;
  IDHidePRN: string;
  BirthdayHidePRN: string;
  ReceiptType: number;
  /** 收據報表代碼 */
  ReceiptCode:string;
  CTRDrugPrintType: string[];
  DailyReportHideCol: string;
  IsPrintDispPresItreatnum: boolean;
  RepeatPresType: number;
  CTRDrugPrintMethod: number;
  DoubleSidedPrintType: number;
  ExamPresType: number;
  FontTypeForPrint: number;
  DoubleSidedPrintMedicationWayAndReceipt: boolean;
}
