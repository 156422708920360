import { PrescriptionDetail } from './prescription-detail';

export class Prescription {
      SexName: string;
      CId: string;
      Birthdate: string;
      AgeWhenRegister: Number;
      RoomName: string;
      DoctorName: string;
      RegDate: string;
      PharmacistId: string;
      PharmacistName: string;
      CC: string;
      PE: string;
      ChiTreatRule: string;
      ChiBianZheng: string;
      ChiPulse: string;
      ChiLook: string;
      ChiAsk: string;
      Diagnosis1: string;
      Diagnosis1Name: string;
      Diagnosis2: string;
      Diagnosis2Name: string;
      Diagnosis3: string;
      Diagnosis3Name: string;
      Diagnosis4: string;
      Diagnosis4Name: string;
      Diagnosis5: string;
      Diagnosis5Name: string;
      Diagnosis6: string;
      Diagnosis6Name: string;

      Id: number;
      RxNo: string;
      IsRxFilled: boolean;
      GetMedicineNo: number;
      IsGotMedicine: boolean;
      PaymentStatus: string;
      PatientNo: string;
      PatientName: string;
      EndTime: Date;
      DisplayEndTime: string;

      PrescriptionDetails: PrescriptionDetail[];

}
