<div class="row narrow-padding rxdata-report">
  <div class="col-sm-12">
      <div class="card">
          <div class="card-header">
              <form>
                <div [formGroup]="queryFormGroup" class="c-form-inline float-left w-120" style="padding-top:5px;">
                      <app-search-bar [Cols]="6" [Flex]="true">
                          <div searchBarField [fieldTitle]="'排序方式'">
                              <!-- <div class="form-control-mergin-left"> -->
                            <app-vis-dropdownlist formControlName="SortMethod" [data]="Seltype"
                                [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                                style="vertical-align: middle; width: 150px;">
                            </app-vis-dropdownlist>
                          </div>
                          <div searchBarField>
                            <label class="c-form-field">
                              <span i18n="@@Startnum" class="mr-1">起始病歷號</span>
                              <input class="vis-input" formControlName="startNum"  />
                            </label>
                            <label class="c-form-field">
                              <span i18n="@@Endnum" class="mr-1">終止病歷號</span>
                              <input class="vis-input" formControlName="endNum"  />
                            </label>
                          </div>
                          <div searchBarField>
                              <button type="button" (click)="onBtnQueryClick()" i18n="@@Query"
                                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="查詢">
                                  <mat-icon class="pref-icon vis-combine-icon">search</mat-icon>
                                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
                              </button>
                              <button type="button" (click)="onBtnPrintClick()" i18n="@@Print"
                                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="列印">
                                  <mat-icon class="pref-icon vis-combine-icon">printer</mat-icon>
                                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
                              </button>
                              <button type="button" (click)="onBtnExportAsExcel()" i18n="@@Excel"
                                  class="vis-btn-config p-0 position-relative pl-2 w-18" title="匯出Excel">
                                  <mat-icon class="pref-icon vis-combine-icon">file_open</mat-icon>
                                  <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯出</span>
                              </button>
                          </div>
                      </app-search-bar>
                  </div>
                  <div class="clearfix"></div>
              </form>
              <div class="card-body">
                  <ng-container>
                      <app-basicdata-report></app-basicdata-report>
                  </ng-container>
              </div>
          </div>
      </div>
  </div>
</div>