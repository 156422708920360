import { PageDTO } from "../page.model";

export enum LabPrescriptionAndHrxListOtherRangeQueryType {
  None,
  PatientNo,
  PatientPhone,
  PatientBirthday,
  PatientCId,
  PatientName
}

export enum LabPrescriptionAndHrxListRegType {
  All,
  NHI,
  NonNHI
}

export enum LabPrescriptionAndHrxListSpRule {
  All,
  Mark6 = 6,
  Mark7 = 7
}


export enum LabPrescriptionAndHrxListPrintTarget {
  LabPrescription,
  LabHrxList
}

export class LabPrescriptionAndHrxListQuery {
  beginDate: Date;
  endDate: Date;
  otherRangeQueryType: LabPrescriptionAndHrxListOtherRangeQueryType;
  otherRangeQueryBegin: string;
  otherRangeQueryEnd: string;
  doctorCode: string;
  hispidHId: string;
  sphcCode: string;
  dontPrintLackCard: boolean;
  spRule: LabPrescriptionAndHrxListSpRule;
  regType: LabPrescriptionAndHrxListRegType;
  printTarget: LabPrescriptionAndHrxListPrintTarget;
  pageSize: number;

}

export interface LabHRxListDTO {
  PatientNo: string;
  PatientName: string;
  PatientCId: string;
  PatientBirthDay: string;
  RegDate: string;
  RxName: string;
  Amount: string;
  DoctorName: string;
}

export class LabHRxListPageDTO implements PageDTO<LabHRxListDTO> {
  PageIndex: number;
  PageCount: number;
  Items: LabHRxListDTO[];
  PageTotalAmount: number;
}

export class LabHRxListPageCollection {
  ClinicName: string;
  Title: string;
  TotalPage: number
  Pages: LabHRxListPageDTO[]
}


export class LabPrescriptionHRxDTO {
  SRxCode: string;
  SRxName: string;
  HRxTotalDose: string;
  HRxRemark: string;
}

export class LabPrescriptionDTO {
  ClinicNHICode: string;
  ClinicName: string;
  PatientName: string;
  PatientSex: string;
  PatientBirthDay: string;
  PatientCId: string;
  Nhictp: string;
  CaseType: string;
  RegDate: string;
  Isqno: string;
  ApartCode: string;
  DxCode: string;
  DxName: string;
  ClinicCode: string;
  DoctorName: string;
  ClinicPhone: string;
  ClinicAddress: string;
  ClinicFax: string;
  HspidCode: string;
  HspidName: string;
  HspidPhone: string;
  HspidAddress: string;
  SourceRegDate: string;
  SourceItreatnum: string;
  HRxes: LabPrescriptionHRxDTO[];
}

export class LabPrescriptionPageCollection {
  Title: string;
  Pages: LabPrescriptionDTO[];
}
