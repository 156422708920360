import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';
import { AgeYearDiff, AgeYearMonthDiff, AgeYearTruthDiff } from '../utilities';

@Pipe({ name: 'age' })
export class AgePipe implements PipeTransform {
  transform(value: any, emptyWhenUnknown?: any): string {
    var d = DateHelper.createDate(value);

    if (d) {
      // 年減年
      var old = AgeYearTruthDiff(d, new Date());
      if (old > 2) {
        return old.toString().padStart(2, '0') + '歲';
      } else {
        var fAge = AgeYearMonthDiff(d, new Date);
        return fAge.year + '歲' + fAge.month + '月';
      }
    } else {
      return emptyWhenUnknown ? '' : '未知';
    }
  }
}

@Pipe({ name: 'realage'})
export class RealAgePipe implements PipeTransform {
  transform(value: any, emptyWhenUnknown?: any): string {
    var dob = DateHelper.createDate(value);
    if (dob) {
      var result = "";
      // collect input date
      var dobYear = dob.getFullYear();
      var dobMonth = dob.getMonth();
      var dobDate = dob.getDate();
  
      // get the current date from the system
      var now = new Date();
      // extract the year, month, and date from current date
      var currentYear = now.getFullYear();
      var currentMonth = now.getMonth();
      var currentDate = now.getDate();
  
      // get years
      var yearAge = currentYear - dobYear;
  
      // get months
      if (currentMonth >= dobMonth) {
        //get months when current month is greater
        var monthAge = currentMonth - dobMonth;
      } else {
        yearAge--;
        var monthAge = 12 + currentMonth - dobMonth;
      }
  
      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate;
      } else {
        monthAge--;
        // get the days in the last month
        var daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
        var dateAge = daysInLastMonth + currentDate - dobDate;
        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }
      var result = '';
      if (yearAge > 2) {
        result = yearAge + '歲';
      } else {
        result = yearAge + '歲' + monthAge + '月';
      }
      return result;
    } else {
      return emptyWhenUnknown ? '' : '未知';
    }
  }
}

@Pipe({ name: 'fullage' })
export class FullAgePipe implements PipeTransform {
  transform(src: any): string {
    
      if (src == null || src == '') {
        return '';
      }
  
      var result = "";
      // collect input date
      var dob = new Date(src);
      var dobYear = dob.getFullYear();
      var dobMonth = dob.getMonth();
      var dobDate = dob.getDate();
  
      // get the current date from the system
      var now = new Date();
      // extract the year, month, and date from current date
      var currentYear = now.getFullYear();
      var currentMonth = now.getMonth();
      var currentDate = now.getDate();
  
      // get years
      var yearAge = currentYear - dobYear;
  
      // get months
      if (currentMonth >= dobMonth) {
        //get months when current month is greater
        var monthAge = currentMonth - dobMonth;
      } else {
        yearAge--;
        var monthAge = 12 + currentMonth - dobMonth;
      }
  
      // get days
      if (currentDate >= dobDate) {
        // get days when the current date is greater
        var dateAge = currentDate - dobDate;
      } else {
        monthAge--;
        // get the days in the last month
        var daysInLastMonth = new Date(currentYear, currentMonth, 0).getDate();
        var dateAge = daysInLastMonth + currentDate - dobDate;
        if (monthAge < 0) {
          monthAge = 11;
          yearAge--;
        }
      }
      // group the age in a single variable
      result = yearAge + '歲' + monthAge + '月' + dateAge + '天';
      return result;
    }
}