import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HistHelperPadService, PadTab, SubTab } from '../services/hist-helper-pad.service';
import { HistService } from '../services/hist.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { WindowSizeAndPos } from 'src/app/shared/components/dialog/vis-window/vis-window.component';
import { HelpPadGroups, HelpPadTab } from 'src/app/services/api-service/hist/help-pad-api';

declare type GridViewItem = {
  code: string;
  name: string;
  // 子類別，RxType
  type: number;
  flagDescription?: string;
}

@Component({
  selector: 'app-hist-helper-pad',
  templateUrl: './helper-pad.component.html',
  styleUrls: ['./helper-pad.component.css']
})
export class HelperPadComponent implements OnInit{
  @Input()
  editData:HelpPadGroups;
  @Input()
  set isOpened(t: boolean) {
    this.restoreWindow();
    this._isOpened = t;
  }
  get isOpened(): boolean {
    return this._isOpened;
  }

  @Output() public emitClose = new EventEmitter<boolean>();
  @Output() public itemClick = new EventEmitter<{type:HelpPadTab,code:string}>();

//#region  Tab
  tab:PadTab=null;
  /** 目前的子項目, null表示全部 */
  subtab:SubTab = null;
//#endregion

//#region Window
  // window
  _isOpened = true;
  winTop = 30;
  /** 視窗位置，預設0，視窗移動時會儲存在本地,重啟開啟時如果有儲存的位置 */
  winLeft = 0;// document.body.clientWidth * 0.7125 - 5 - 12 - 20;
  winWidth = document.body.clientWidth - this.winLeft - 18;
  defaultHeight = 350;
  winHeight = this.defaultHeight;
  static windSizeStorageKey = 'padSizeAndPos';
//#endregion

  //#region Grid
  /**查詢過濾 */
  queryText: string = '';
  /** 符合Tab的資料來源的資料 */
  listItems = [];
  /** MAP成UI呈現的格式 */
  filteredItems: GridViewItem[] = [];
  //#endregion

  constructor(private notification: EasyNotificationService,
    private padService: HistHelperPadService,
    private histService: HistService,
    private localStorage: LocalStoragesService) {
  }

  ngOnInit() {
    this.tab = this.padService.currentTab;
    // 預設全部
    this.subtab = null;
    this.resetSize(60, 350, 0.7125);
    // 空值查詢
    this.clearQuery();
  }

  //#region get data ----------
  /** 子tab被點選時過濾資料 */
  changeSubtabType(sTab: SubTab) {
    if (this.subtab != sTab) {
      this.subtab = sTab;
      this.fetchData();
    }
  }
  private async fetchData() {
    try {
      this.listItems = await this.getCodes();
      this.setGridView();
    } catch(e) {
      this.notification.showError(e)//.showGetDataFailed();
    }
  }
  /** 案Tab的資料來源取得資料 */
  private async getCodes(): Promise<any[]> {
    if (!this.tab) {
      return [];
    }
    let inputValue = this.queryText;
    var ds = this.tab.dataSource;
    if (ds=='CC'){
      return await this.histService.getCCOptions(inputValue);
    } else if (ds=='PE'){
      return await this.histService.getPEOptions(inputValue);
    } else if (ds=='DX'){
      return await this.histService.getDiagList(inputValue);
    } else if (ds=='MS'){
      return await this.getRxList(inputValue,[0])
    } else if(ds=='RX'){//if (this.isTab( this.tabCode_Drug)) {
      var orderList = this.tab.subTab.map(x => x.value);
      if (this.subtab != null) {
        orderList = [this.subtab.value];
      }
      var ret = await this.getRxList(inputValue, orderList);
      if(this.subtab?.filter){
        ret = ret.filter(x=>this.subtab.filter(x));
      }
      return ret;
    }else{
      return [];
    }
  }
  async getRxList(code, types) {
    return await this.histService.getOrderList(code, types);
  }
  //#endregion

  //#region Btn / input event
  /** 清除查詢條件並重新查詢 */
  async clearQuery() {
    this.queryText = '';
    await this.onBtnQueryClick();
  }
  /** 查尋 */
  async onBtnQueryClick() {
    await this.fetchData();
  }
  /** 查詢按下Enter時處理 */
  async queryKeydown(evt:KeyboardEvent){
    if(evt.key == 'Enter'){
      await this.onBtnQueryClick();
    }
  }
  //#endregion

  //#region grid ----------
  setGridView() {
    this.filteredItems = this.listItems
      .map(it => {
        var code = it.RxCode || it.Code;
        return {
          code: code,
          name: it.Name || it.CName || it.ProdName,
          type: it.RxType ?? -1,
          flagDescription: this.tab.dataSource == 'RX' ? it.FlagDescription : ''
        }
      });
  }
  /** 清單項目被點選時 */
  onItemClick(evt: Event, code: string) {
    var selected = {
      type:this.editData.Tab,
      code:code
    }
    this.itemClick.emit(selected);
    evt.preventDefault();
    evt.stopPropagation();
  }
  //#endregion

  //#region Windows ----------
  closeWindow() {
    this.emitClose.emit(false);
  }
  restoreWindow() {
    this.resetSize(60, 350, 0.7125);
  }
  winSizeChanged(size: WindowSizeAndPos) {
    const sizeString = JSON.stringify(size);
    this.localStorage.setItem(HelperPadComponent.windSizeStorageKey, sizeString);
  }
  /** 載入使用者調整過後的大小/位置 */
  resetSize(winTop:number, defaultHeight:number, portion:number, itemKey = true) {
    let itemString = this.localStorage.getItem(HelperPadComponent.windSizeStorageKey);
    let item: WindowSizeAndPos;
    item = JSON.parse(itemString);
    if (item !== null && itemKey === true) {
      this.winHeight = item.Height;
      this.winWidth = item.Width;
      this.winLeft = item.Left;
      this.winTop = item.Top
    } else {
      this.winTop = winTop;
      this.winLeft = document.body.clientWidth * portion - 5 - 12 - 20;
      this.winWidth = document.body.clientWidth - this.winLeft - 18;
      this.defaultHeight = defaultHeight;
      this.winHeight = this.defaultHeight;
    }
  }
  //#endregion
}
