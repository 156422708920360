import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-plus',
  template: `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#474647"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title></title> <g id="Complete"> <g data-name="add" id="add-2"> <g> <line fill="none" stroke="#474647" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line> <line fill="none" stroke="#474647" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line> </g> </g> </g> </g></svg>`,
  styles: [`
  svg{
    width: 16px;
    height: 16px;
  }
  `]
})
export class IconPlusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
