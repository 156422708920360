import { contacts } from './../../../system/parameter-profile/models/parameter-profile';
import { NewbornNote } from "src/app/models/NewbornNote";
import { ContactDto } from "./contactDto";
import { SexEnum } from "src/app/enums/SexEnum";

export interface IPatient{
  Id:number;
  CId:string;
  CName:string;
  Birthday:Date;
  Sex:number;
  PatientNo:string;
}
export class PatientsDto {

  Id: number;
  IsEnable: boolean;

  ClinicId: string;
  PatientNo: string;
  Kcstmr: string;
  CName: string;
  MiddleName: string;
  LastName: string;
  Nickname: string;
  CId: string;
  Birthday: any;
  Sex: number;
  Country: string;
  BlRH: number;
  BlType: number;
  Married: number;
  PhoneArea: string;
  Phone: string;
  CellPhone: string;
  EMail: string;
  AddressId: number;
  MailingAddressId: number;
  FoodAllergy: string;
  DrugAllergy: string;
  DiseaseHistory: string;
  DiseaseHistoryRemark: string;
  Remark: string;
  DiscountId: number;
  DiscountCode: string;
  ICode: string;
  ZipCode: string;
  Nation: string;
  State: string;
  City: string;
  Area: string;
  Street: string;
  ResZipCode: string;
  ResCity: string;
  ResArea: string;
  ResStreet: string;
  IsSMS: boolean;
  IsAgreeMsg: boolean;
  IsCheckCId: boolean;
  // PatientHIS
  PatientHisId: number;
  Title: string;
  EstBirthYear: number;
  EducationCode: string;
  Family: string;
  JobCode: string;
  StatusCode: string;
  ShortNo: string;
  FirstDate: Date;
  LastDate: Date;
  LastMCDate: Date;
  DueDate: Date;
  SpcIdentity: string;
  SoldierEdt: Date;
  Handicapp: string;
  MissTimes: number;
  Height: number;
  Weight: number;
  BMI: number;
  SocialInusranceNo: string;
  RaceCode: string;
  MSTS: number;
  PaySysA: string;
  Contacts: ContactDto[];
  IsAlive: boolean;
  CanDelete: boolean = true;
  SupArea: string;
  DxCertRemark: string;
  Job: string;
  BirthCity: string;
  PrintName: string;
  EName: string;
  EAddress: string;
  Name: string;
  BirthsNum?: number;
  MonParentsNo?: string;
  RemarkOne?: string;
  FirstDialysisDate: Date;
  MajorInjuryTimes: number;
  MajorInjuryDate: Date;
  NeedToCheckCName?: boolean = true;
}

export class QueryPatient {
  Found: boolean;
  PatientName: string;
  PatientCId: string;
}

/** 基資列印 */
export class PatientBasicPrint {
  PatientNo: string;
  CName: string;
  Sex: string;
  ICode: string;
  CId: string;
  Birthday: Date | string;
  BlType: string;
  BlRH: string;
  Phone: string;
  CellPhone: string;
  contactName?: string;
  contactOnePhone?: string;
  contactOneMobile?: string;
  JobCode: string;
  EducationCode: string;
  FirstDate: Date | string;
  Height: number;
  Weight: number;
  DrugAllergy: string;
  Remark: string;
  clinicName:string;
  patientAddress: string;
  contactsAddress: string;
  relation: string;
}

export interface NewBornCreateDto {
  NewbornNote: Partial<NewbornNote>;
  Patient: Partial<PatientsDto>;
}

export interface NewbornViewDto {

  Id: number;
  PatientMastId: number;
  Name: string;
  PatientNo: string;
  Sex: SexEnum;
  Birth: Date;
  BirthsNum: number;
}
