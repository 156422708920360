export class HemodialysisEpoInjectionDataDto {
  queryMonth: Date;
  startDate: Date;
  endDate: Date;
  clinicId: number;
  startDateDisplay: Date;
  endDateDisplay: Date;
  ReportTime: Date;
  ClinicName: string;
  ClinicCity: string;
  ClinicAddress: string;
  ClinicPhone: string;
  NHICode: string;
  EPOTotalQty: number;
  Items: HemodialysisEpoInjectionDataItemDto[];
}

export class HemodialysisEpoInjectionDataItemDto {
  Sqno: string;
  Sdt: string;
  Name: string;
  Cid: string;
  Bdt: Date;
  EPOTotal: number;
  Bun: number;
  Cr: number;
  LHb: number;
  Hct: number;
  EPO: string;
  Hb: string;
  EPOList: Map<string, number>;
  HbList: Map<string, number>;
  PageNumber: number;
  IsSummary: boolean;
}
