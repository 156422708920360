import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SystemcodeApi } from "../api-service/system-code/systemcode-api";
import { GroupMsgData} from "../signalr-service";
import { ClinicDataService } from "../data-service/clinic-data-service";
import { EasyNotificationService } from "../easy-notification.service";
import { AuthenticationService } from "src/app/security/services/authentication.service";


@Injectable({
    providedIn: 'root'
  })
export class SystemEventHandler {
    
    /**
     *
     */
    constructor(
      private notify:EasyNotificationService) {
    }
    /** 執行事件 */
    Invoke(evt:GroupMsgData){
      if(evt.Type=='Action'){                
        this.doAction(evt);
      }else if(evt.Type== 'Notify'){
        this.doNotify(evt);
      }
    }
    /** 動作事件 */
    private doAction(evt:GroupMsgData){
      // if(evt.Act == 'Logout'){
      //   this.auth.logout();
      // }
    }
    private doNotify(evt:GroupMsgData){
      this.notify.showInfo(evt.Arg);
    }
}