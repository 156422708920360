<app-vis-dialog [title]="'匯入標準代碼'" [show]="show" (close)="handleClose()" [width]="800">
  <div class="inside-scrollbar px-1px leave-bottom" style="max-height: 455px;">
    <app-vis-grid [data]="stdListItems" [pageSize]="stdListpageSize" [skip]="stdListpageSkipNo" [pageable]="false"
      (pageChange)="stdListPageChange($event)" [selectByCheckOnly]="true" [multiselect]="true"
      [kendoGridSelectBy]="'SN'" [selectedKeys]="stdSelectedItems">
      <app-vis-grid-checkbox-column (checkChanged)="checkChanged($event)" [field]="'checked'" [checkOnSelect]="true"
        showSelectAll="true" [width]="25" class="text-center">
      </app-vis-grid-checkbox-column>
      <app-vis-grid-column field="TypeCode" title="類別" [width]="100" i18n-title="@@TypeCode">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="ellipsis-content"> {{ dataItem.TypeCode }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-editable-column field="Code" title="代碼" [width]="60" min-width="160" i18n-title="@@Code">
      </app-vis-grid-editable-column>
      <app-vis-grid-editable-column field="Name" title="名稱" [width]="120" i18n-title="@@Name">
      </app-vis-grid-editable-column>
      <app-vis-grid-editable-column field="IsEnabled" title="是否啟用" [width]="120" min-width="140"
        i18n-title="@@IsEnabled" [textCenter]="true">
        <ng-template let-data #viewTmp>{{data.IsEnabled?'是':'否'}}</ng-template>
      </app-vis-grid-editable-column>
    </app-vis-grid>
  </div>
  <div style="display: flex" class="m-2">
    <div style="flex-grow:1"></div>
    <ng-container *ngTemplateOutlet="customBtnTmp;context: {$implicit: typeCode}"></ng-container>

    <button type="button" (click)="importRows()" class="vis-btn-config p-0 position-relative pl-2 w-18" title="匯入">
      <mat-icon class="pref-icon add vis-combine-icon">checklist</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯入</span>
    </button>
    <button type="button" (click)="handleClose()" class="vis-btn-config p-0 position-relative pl-2 w-18" title="取消">
      <mat-icon class="pref-icon remove vis-combine-icon">cancel</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
    </button>
  </div>
</app-vis-dialog>
