export class VisitsReport {
    startDate: Date;
    endDate: Date;
    reportType: string;
    endDateDisplay: Date;
    ReportTime: Date;
    Items: VisitsReportItem[];
}

export class VisitsReportItem {
    Date: Date;
    DoctorName: string;
    Total: string;
    Patients: number;
    FirstVisitPatients: number;
    RevisitPatients: number;
    People: number;
    FirstVisitPeople: number;
    RevisitPeople: number;
    ClinicName: string;
    PatientsInMorning: number;
    PatientsInAfternoon: number;
    PatientsInEvening: number;
    PaidByHealthCare: number;
    PaidBySelf: number;
    RoomCode: string;
    IsDoctorTotal: boolean;
    IsSummary: boolean;
}
