import { Component, OnDestroy, OnInit } from '@angular/core';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { RegReserveService } from '../../../registers/reserve/reg-reserve-service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { TimeSecEnum } from 'src/app/enums/TimeSecEnum';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { WindowSizeService } from 'src/app/services/window-size-service';
import { Subscription } from 'rxjs';
declare type RestDay = {dt:Date,sec:number,text:string};
@Component({
  selector: 'app-reg-reserve-setting',
  templateUrl: './reg-reserve-setting.component.html',
  styleUrls: ['./reg-reserve-setting.component.css']
})
export class RegReserveSettingComponent implements OnInit,OnDestroy {
  bigRatio:boolean;
  restDays: RestDay[] = [];
  
  holidays: string[] = []

  selectedSection = 0;
  selectedDate:Date;
  holidayInput:string;
  
  secOption:ValueTextPairNumberValue[] = [];
  
  resizeSubscribe:Subscription
  constructor(
    private reserveService:RegReserveService,
    private sizeService:WindowSizeService){
      
  }

  ngOnInit(): void {
    this.secOption = this.reserveService.timeSecOption;
    this.refreshRest();
    this.refreshHoliday();
    this.bigRatio = this.sizeService.bigRatio;
    this.resizeSubscribe = this.sizeService.onWinResize.subscribe(()=>{
      setTimeout(() => {
        this.bigRatio = this.sizeService.bigRatio;  
      }, 0);
    })
  }
  ngOnDestroy(): void {
    this.resizeSubscribe.unsubscribe();
  }
  refreshRest(){
    this.restDays = [];
    var dateSec = this.reserveService.getCloseDateSec()
    var dateSecKeys:string[]=[]
    for(var dt in dateSec){
      dateSecKeys.push(dt);
    }
    dateSecKeys = dateSecKeys.sort();
    for(var dt of dateSecKeys){
      
      var dtStr = DateHelper.getROCDateString(new Date(dt));
      var secs = dateSec[dt]||[];
      secs = secs.sort();
      for(var sec of secs){
        var secName = this.secOption.find(x=>x.value==sec);
        this.restDays.push({
          dt:new Date(dt),
          sec:sec,
          text:`${dtStr} ${secName.text}`
        })
      }
    }
  }
  addRestTime(){
    this.reserveService.addCloseDateSec(this.selectedDate,this.selectedSection)
    this.refreshRest();
  }
  clearRestTime(){
    this.reserveService.clearCloseDateSec();
    this.refreshRest();
  }
  removeRestTime(item:RestDay){
    this.reserveService.removeCloseDateSec(item.dt, item.sec);
    this.refreshRest();
  }

  refreshHoliday(){
    this.holidays = [];
    var days = this.reserveService.getHolidays()
    
    days = days.sort();
    this.holidays = days;
  }
  addHoliday(){
    this.reserveService.addHoliday(this.holidayInput)
    this.refreshHoliday();
  }
  clearHoliday(){
    this.reserveService.clearHolidays();
    this.refreshHoliday();
  }
  removeHoliday(dt:string){
    this.reserveService.removeHoliday(dt);
    this.refreshHoliday();
  }
  
  
}
