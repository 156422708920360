import { Injectable } from "@angular/core";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

@Injectable({providedIn:'root'})
export class GpParaApi{
    apiService: WebApiService;
    /**
     *
     */
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('system/gppara');
    }
    async Get(): Promise<string>{
        var ret = await this.apiService.get('Get').toPromise<{gpPara:string}>();
        return ret.gpPara;
    }
    async Update(index:number,value :string): Promise<CustomResult>{
        return this.apiService.post(`Update?index=${index}&value=${value}`,null).toPromise();
    }
    async GetList(pageNum:number,pageSize:number,isDefind: boolean=false,gp:string,keyword:string): Promise<PageData>{
        return this.apiService.get(`GetList`,
        {
            defined:isDefind,
            gp:gp,
            keyword:keyword,
            pageSkip:(pageNum-1)*pageSize,
            pageSize:pageSize
        }).toPromise();
    }
}