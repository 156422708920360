
import { BaseConfig } from './base-config.service';
import { WebApiClient } from './web-api-client.service';
import { WebApiService } from './web-api.service';
import { WholeHist } from  'src/app/services/api-service/hist/whole-hist';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { PaymentMaster } from 'src/app/payments/models/payment-master';
import { UserCache } from './user-cache';
import { EasyNotificationService } from './easy-notification.service';
import { WebPrintService } from './web-print.service';
import { HcrService } from './hcr-service.service';
import { ReportType } from 'src/app/system/report/reportApi';
import { IBaseConfig } from '../shared/models/base-config';
import { DateHelper } from '../shared/helpers/date-helper';
import { PatientApi } from './api-service/patient/patient-api';
import { PatientsDto } from './api-service/patient/patientsDto';
import { RegisterEditDto } from './api-service/register/RegisterEditDto';
import { ReportHelperComponent } from '../system/report/report-helper/report-helper.component';
import { ParameterApi } from './api-service/parameters/parameter-api';
import { HistApi } from './api-service/hist/hist-api';
import { ClinicDataService } from './data-service/clinic-data-service';
import { PRN001Params } from './api-service/parameters/PRN001Params';

@Injectable({
  providedIn: 'root'
})
export class LocalPrintService {

  printHelper:ReportHelperComponent;
  config: IBaseConfig;
  private notification: EasyNotificationService;
  //private api: WebApiService;
  private hisApi: WebApiService;
  // debug模式先關閉送印
  enablePrinter = true;
  param: PRN001Params;

  constructor(
    private webApiClient: WebApiClient,
    private notificationDI: EasyNotificationService,
    private webPrintService: WebPrintService,
    private patientApi: PatientApi,
    private histApi:HistApi,
    private clinicDataService: ClinicDataService,
    private hcrService: HcrService) {
    this.config = BaseConfig.getConfig();
    this.notification = notificationDI;
    this.hcrService.SetPrintService(this);
  }

  private readonly reportType_register = 'Register';
  private readonly reportType_receipt = 'Receipt';
  private readonly reportType_rx = 'Rx';
  private readonly reportType_history = 'History';
  private readonly reportType_inject = 'Injection';
  private readonly reportType_lab = 'Lab';

  /** 列印 一般處方 */
  public async printPrescription(regId:number) {
    if (this.param.DoubleSidedPrintType != 1) {
      await this.printHelper.print(regId, [1]);
    }
  }

  /** 列印收據 明細 */
  public async printReceipt(regId) {
    await this.printHelper.print(regId, [2]);
  }

  /** 列印交付連處 5 */
  public async printRepeatPres(regId) {
    await this.printHelper.getPrePrint(regId, 5);
    await this.printHelper.print(regId, [5]);
  }

  /** 列印交付檢驗單 6 */
  public async printLabOutClinic(regId) {
    // await this.printHelper.getPrePrint(regId, 6);
    await this.printHelper.print(regId, [6]);
  }

  /** 列印藥袋 */
  public async printDrugbag(regId) {
    await this.printHelper.print(regId, [7]);
  }

  /** 列印交付連處 測試  81 */
  public async printRepeatPresTest(regId) {
    await this.printHelper.getPrePrint(regId, 81);
    await this.printHelper.print(regId, [81]);
  }
  /** 列印 掛號單 101 */
  public async printRegisterForm(regId:number) {
    await this.printHelper.print(regId, [101]);
  }

  // 列印病歷
  public printHistory(regId:number) {
    this.printHelper.print(regId, [1]);
  }

  // 列印注射單
  public printInjectionForm(wholeHist: WholeHist) {
    this.printHistoryLike(wholeHist, this.reportType_inject);
  }

  // 列印檢驗單
  public printLabForm(wholeHist: WholeHist) {
    this.printHistoryLike(wholeHist, this.reportType_lab);
  }

  // 列印管制藥品處方箋
  public printCtrdrugs(regId:number){
    //this.getCtrdrugs(regId);
    this.printHelper.print(regId, [809]);
  }

  /** 取得 一般處方 的 html */
  public getPrescription(regId: number) {
    return this.printHelper.getHtml(regId, 1);
  }

  /** 取得 收據 明細 的 html*/
  public getReceipt(regId: number) {
    return this.printHelper.getHtml(regId, 2);
  }

  /** 取得 藥袋 的 html*/
  public async getDrugbag(regId: number) {
    return this.printHelper.getHtml(regId, 7);
  }

  /** 取得 交付連處 的 html */
  public getRepeatPres(regId: number) {
    return this.printHelper.getHtml(regId, 5);
  }

  /** 取得 掛號單 的 html */
  public getRegisterForm(regId: number) {
    return this.printHelper.getHtml(regId, 101);
  }

  /** 取的 管制藥品處方箋的 html */
  public getCtrdrugs(regId:number){
    return this.printHelper.getHtml(regId,809);
  }

  // 列印病歷
  // public 取得History(regId: number) {
  //   this.printHelper.getHtml(regId, [1]);
  // }

  // ------------------------------------

  private printHistoryLike(hist: WholeHist, reportType: string) {
    const register = hist.Register;
    const patient = hist.Patient;
    const history = hist.Hist;
    const prescriptionData = {
      HistoryMaster: {
        PatientName: patient.CName,
        SexName: patient.SexName,
        CId: patient.CId,
        BirthDay: DateHelper.formatDate(patient.Birthday),
        PatientNo: patient.PatientNo,
        DoctorName: register.DoctorName,
        RegDate: DateHelper.formatDate(register.RegDate),
        ICode: register.ICode,
        PharmacistName: history.PharmacistName,
        CC: history.CC,
        PE: history.PE,
        Diagnosis1: history.Dx1,
        Diagnosis1Name: history.Dx1Name,
        Diagnosis2: history.Dx2,
        Diagnosis2Name: history.Dx2Name,
        ClinicName: '',
        ClinicPhone: '',
        ClinicFax: '',
        ClinicAddress: '',
      },
      HistoryDetails: []
    };
    // 資料分類過濾的部分做在pres-print.service內
    // if (reportType === this.reportType_rx) {
    //   hist.Hist.Orders = hist.Hist.Orders.filter(x =>
    //     x.NhiDrugType === NhiDrugTypeEnum.Oral ||
    //     x.NhiDrugType === NhiDrugTypeEnum.ExternalUse ||
    //     x.NhiDrugType === NhiDrugTypeEnum.ChineseSoap ||
    //     x.NhiDrugType === NhiDrugTypeEnum.ChineseMedicine);
    // } else if (reportType === this.reportType_lab) {
    //   hist.Hist.Orders = hist.Hist.Orders.filter(x =>
    //     x.NhiDrugType === NhiDrugTypeEnum.Lab ||
    //     x.NhiDrugType === NhiDrugTypeEnum.XRay);
    // } else if (reportType === this.reportType_inject) {
    //   hist.Hist.Orders = hist.Hist.Orders.filter(x =>
    //     x.NhiDrugType === NhiDrugTypeEnum.Injection);
    // }
    // 所有調劑先都印，後面再來改
    hist.Hist.HRxs.forEach((order, index) => {
      const prescriptionDetail = {
        Id: index + 1,
        DrugCode: order.RxCode,
        DrugName: order.ProdName,
        Dose: order.Dose,
        EnterUnit: order.Unit,
        Frequency: order.Freq,
        Way: order.Way,
        Days: order.Days,
        TotalDose: order.TotalDose,
        Remark: order.Remark
      };
      prescriptionData.HistoryDetails.push(prescriptionDetail);
    });
    const cln = UserCache.getLoginUser().Clinic;
    prescriptionData.HistoryMaster.ClinicName = cln.Name;
    prescriptionData.HistoryMaster.ClinicPhone = cln.Phone;
    prescriptionData.HistoryMaster.ClinicFax = cln.Fax;
    prescriptionData.HistoryMaster.ClinicAddress = cln.Street;
    //this.sendToPrinter(reportType, prescriptionData);
  }


  /** 送列印排程 */
  public async printHtml(tp: number, name: string, content: string) {
    // console.log(name + ' 送印中');
    return await this.hcrService.Print(tp, {
      PrintHtml: true,
      ReportName: name,
      Html: content
    });
  }

  public async printText(tp: number, name: string, content: string) {
    return await this.hcrService.Print(tp, {
      PrintHtml: false,
      ReportName: name,
      Html: content
    });
  }

  // ------------------------------------

  public async initialPrintClient(): Promise<Observable<any>> {
    // 在login呼叫時可能尚未即時取得config，所以這裡再抓一次
    this.config = BaseConfig.getConfig();
    if (!this.config.localPrint.isRunLocalReport) {
      return of();
    }
    this.param = await this.clinicDataService.getParam("PRN001");
    const obj: any = {};
    const loginUser = UserCache.getLoginUser();
    obj.ClinicId = loginUser.Clinic.Id;
    obj.CompanyId = loginUser.Clinic.CompanyId;
    obj.ClinicName = loginUser.Clinic.Name;
    obj.Account = loginUser.Account;
    // obj.ReportApi = this.config.localPrint.reportApi;
    // obj.ReportServer = this.config.localPrint.reportServer;
    // obj.GetTypeAction = "GetReportType";
    // obj.GetReportAction = "GetReport";
    obj.Token = loginUser.AuthToken;
    obj.UserId = loginUser.UserId;
    obj.ApiUrl = BaseConfig.getConfig().webApi.hisBaseUrl;
    // console.log('initialPrintClient',obj);
    // 初始化指令
    this.hcrService.Print(0, obj);
  }
  // private sendToPrinter(reportName: string, data: any, forceInitial: boolean = true) {
  //   this.config = BaseConfig.getConfig();
  //   if (this.config.localPrint.isRunLocalReport) {
  //     if (this.api == null) {
  //       this.api = this.webApiClient.createLocalPrintService('/report/');
  //     }
  //     // 送本機列印程式
  //     this.api.post(reportName, data).subscribe((result: any) => {
  //     }, error => {
  //       // REP001：未完成初始化(需要傳LoginUser參數給winform)
  //       if (error.code === 'REP001' && forceInitial) {
  //         this.initialPrintClient().subscribe(result => {
  //           this.sendToPrinter(reportName, data, false);
  //         });
  //       } else {
  //         this.notification.showWarning('local print failed');
  //       }
  //     });
  //   }

  //   // 送遠端列印伺服器
  //   this.sendToRemotePrintServer(reportName, data);
  //   // 用網頁列印方式
  //   this.doWebPrint(reportName, data);
  // }
  private sendToRemotePrintServer(reportName: string, data: any) {
    if (!this.config.localPrint.isEnableRemotePrint) {
      return;
    }

    const keys = ['companyCode', 'clinicCode', 'reportName', 'printData'];
    const user = UserCache.getLoginUser();
    const values = [user.CompanyCode, user.Clinic.Code, reportName, JSON.stringify(data)];
    // this.OpenWindowWithPost(this.config.localPrint.remoteReportServer, 'RemotePrintWindow', keys, values);
    this.OpenWindowWithPost(this.config.localPrint.remoteReportServer, reportName, keys, values);
  }

  private doWebPrint(reportName: string, data: any) {
    if (!this.config.localPrint.isEnableWebPrint) {
      return;
    }
    if (reportName === this.reportType_register) {
      this.webPrintService.printRegisterForm(data.RegisterReport);
    }
  }

  OpenWindowWithPost(url, name, keys, values) {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', url);
    form.setAttribute('target', name);
    for (let i = 0; i < keys.length; i++) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = keys[i];
      input.value = values[i];
      form.appendChild(input);
    }
    document.body.appendChild(form);
    window.open(url, `${name}`);
    form.submit();
    document.body.removeChild(form);
  }

  private async getPatientData(patientId: number): Promise<PatientsDto> {
    return await this.patientApi.Get(patientId);
  }

  private getClinicData(clinicId: number): Observable<Clinic> {
    if (this.hisApi == null) {
      this.hisApi = this.webApiClient.createHisService('');
    }
    this.hisApi.basePath = 'system/clinic';
    return this.hisApi.get('get/' + clinicId);
  }
}

class RegisterForm{
  patNumber:string;
  patName:string;
  age:number;
  regDate:string;
  tsec:string;
  room:string;
  doctorName:string;
  waitNo:string;
  remark:string;
  clinicAddress:string;
  clinicTel:string;
}
