<table class="dm-tb">
  <tr>
    <td class="top">
      <span class="patient-info">{{PatientName}}</span>
      <span style="padding-left: 20px; color: brown;">It is a demo function.</span>
      <div class="float-right">
        <button type="button">Add stage</button>
      </div>
    </td>
  </tr>
  <tr>
    <td class="right-side">
      <div>History of stages:</div>
      <app-vis-grid [data]="gridView" [pageSize]="9999" [pageable]="false">
        <app-vis-grid-column field="Stage" title="Stage" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="ReviewDate" title="Date" width="120">
        </app-vis-grid-column>
        <app-vis-grid-column field="BH" title="BH" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="BW" title="BW" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="BMI" title="BMI" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="SBP" title="SBP" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="DBP" title="DBP" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="AC" title="AC" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="PC" title="PC" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="HbA1C" title="HbA1C" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="HDL" title="HDL" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="LDL" title="LDL" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="SCR" title="SCR" width="50">
        </app-vis-grid-column>
        <app-vis-grid-column field="EGFR" title="EGFR" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="UACR" title="UACR" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="UPRO" title="UPRO" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="SGPT" title="SGPT" width="60">
        </app-vis-grid-column>
        <app-vis-grid-column field="SGOT" title="SGOT" width="60">
        </app-vis-grid-column>
      </app-vis-grid>
      <div class="section-title">Case and stage data:</div>
      <!-- kendo-tabstrip ****改用mat-tab改寫*** -->
      <!-- <kendo-tabstrip class="tab-group">
        <kendo-tabstrip-tab [title]="'Case Info.'" [selected]="true">
          <ng-template kendoTabContent>
            <div class="vis-form vis-form-narrow">
              <div class="row narrow-padding">
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Receive date</span>
                    <app-vis-datepicker [value]="rcvDate" ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Source</span>
                    <input class="vis-input" value="Outpatient" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Doctor</span>
                    <input class="vis-input" value="James Lin" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Height</span>
                    <input class="vis-input" value="159" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Weight</span>
                    <input class="vis-input" value="65" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>BMI</span>
                    <input class="vis-input" value="25.7" disabled />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Close date</span>
                    <input class="vis-input" value="" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Close reason</span>
                    <input class="vis-input" value="" />
                  </label>
                </div>
                <div class="col-sm-8">
                  <label class="vis-form-field">
                    <span>Remark</span>
                    <input class="vis-input" value="" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Diabetes type</span>
                    <app-vis-dropdownlist [data]="editOptions.dmType" value="I">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Diabetes disagnosis date</span>
                    <app-vis-datepicker [value]="rcvDate" ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Visit Reason</span>
                    <input class="vis-input" value="Outpatient" />
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Family history</span>
                    <input type="radio" value="true" name="f1" id="f1a" class="k-radio">
                    <label class="k-radio-label" for="f1a">Yes</label>
                    <input type="radio" value="false" name="f1" id="f1b" class="k-radio">
                    <label class="k-radio-label" for="f1b">No</label>
                    <input type="radio" value="false" name="f1" id="f1c" class="k-radio">
                    <label class="k-radio-label" for="f1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Drug history</span>
                    <input class="vis-input" value="" />
                  </label>
                </div>
                <div class="col-sm-12 line-title">
                  <label class="vis-form-field">
                    <span>Treatment</span>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Oral drug - start date</span>
                    <app-vis-datepicker ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Insulin - start date</span>
                    <app-vis-datepicker ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Education - start date</span>
                    <app-vis-datepicker ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-12 line-title">
                  <label class="vis-form-field">
                    <span>Related disease</span>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>DKA</span>
                    <input type="radio" value="true" name="d1" id="d1a" class="k-radio">
                    <label class="k-radio-label" for="d1a">Yes</label>
                    <input type="radio" value="false" name="d1" id="d1b" class="k-radio">
                    <label class="k-radio-label" for="d1b">No</label>
                    <input type="radio" value="false" name="d1" id="d1c" class="k-radio">
                    <label class="k-radio-label" for="d1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Obesity</span>
                    <input type="radio" value="true" name="e1" id="e1a" class="k-radio">
                    <label class="k-radio-label" for="e1a">Yes</label>
                    <input type="radio" value="false" name="e1" id="e1b" class="k-radio">
                    <label class="k-radio-label" for="e1b">No</label>
                    <input type="radio" value="false" name="e1" id="e1c" class="k-radio">
                    <label class="k-radio-label" for="e1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Hypotension</span>
                    <input type="radio" value="true" name="g1" id="g1a" class="k-radio">
                    <label class="k-radio-label" for="g1a">Yes</label>
                    <input type="radio" value="false" name="g1" id="g1b" class="k-radio">
                    <label class="k-radio-label" for="g1b">No</label>
                    <input type="radio" value="false" name="g1" id="g1c" class="k-radio">
                    <label class="k-radio-label" for="g1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>HHNK</span>
                    <input type="radio" value="true" name="h1" id="h1a" class="k-radio">
                    <label class="k-radio-label" for="h1a">Yes</label>
                    <input type="radio" value="false" name="h1" id="h1b" class="k-radio">
                    <label class="k-radio-label" for="h1b">No</label>
                    <input type="radio" value="false" name="h1" id="h1c" class="k-radio">
                    <label class="k-radio-label" for="h1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Coronary heart disease</span>
                    <input type="radio" value="true" name="i1" id="i1a" class="k-radio">
                    <label class="k-radio-label" for="i1a">Yes</label>
                    <input type="radio" value="false" name="i1" id="i1b" class="k-radio">
                    <label class="k-radio-label" for="i1b">No</label>
                    <input type="radio" value="false" name="i1" id="i1c" class="k-radio">
                    <label class="k-radio-label" for="i1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Dyslipidemia</span>
                    <input type="radio" value="true" name="j1" id="j1a" class="k-radio">
                    <label class="k-radio-label" for="j1a">Yes</label>
                    <input type="radio" value="false" name="j1" id="j1b" class="k-radio">
                    <label class="k-radio-label" for="j1b">No</label>
                    <input type="radio" value="false" name="j1" id="j1c" class="k-radio">
                    <label class="k-radio-label" for="j1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Hypertension</span>
                    <input type="radio" value="true" name="k1" id="k1a" class="k-radio">
                    <label class="k-radio-label" for="k1a">Yes</label>
                    <input type="radio" value="false" name="k1" id="k1b" class="k-radio">
                    <label class="k-radio-label" for="k1b">No</label>
                    <input type="radio" value="false" name="k1" id="k1c" class="k-radio">
                    <label class="k-radio-label" for="k1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Neuropathy</span>
                    <input type="radio" value="true" name="m1" id="m1a" class="k-radio">
                    <label class="k-radio-label" for="m1a">Yes</label>
                    <input type="radio" value="false" name="m1" id="m1b" class="k-radio">
                    <label class="k-radio-label" for="m1b">No</label>
                    <input type="radio" value="false" name="m1" id="m1c" class="k-radio">
                    <label class="k-radio-label" for="m1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Kidney disease</span>
                    <input type="radio" value="true" name="n1" id="n1a" class="k-radio">
                    <label class="k-radio-label" for="n1a">Yes</label>
                    <input type="radio" value="false" name="n1" id="n1b" class="k-radio">
                    <label class="k-radio-label" for="n1b">No</label>
                    <input type="radio" value="false" name="n1" id="n1c" class="k-radio">
                    <label class="k-radio-label" for="n1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-3">
                  <label class="vis-form-field">
                    <span>Stroke</span>
                    <input type="radio" value="true" name="o1" id="o1a" class="k-radio">
                    <label class="k-radio-label" for="o1a">Yes</label>
                    <input type="radio" value="false" name="o1" id="o1b" class="k-radio">
                    <label class="k-radio-label" for="o1b">No</label>
                    <input type="radio" value="false" name="o1" id="o1c" class="k-radio">
                    <label class="k-radio-label" for="o1c">Unknown</label>
                  </label>
                </div>
                <div class="col-sm-6">
                  <label class="vis-form-field">
                    <span>Other</span>
                    <input type="text" class="vis-input" value="">
                  </label>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'Stage'">
          <ng-template kendoTabContent>
            <div class="vis-form vis-form-narrow">
              <div class="row narrow-padding">
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Stage date</span>
                    <app-vis-datepicker [value]="stageDate" ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Stage type</span>
                    <app-vis-dropdownlist [data]="editOptions.stageType" value="Trace">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Doctor</span>
                    <input class="vis-input" value="James Lin" />
                  </label>
                </div>
                <div class="col-sm-4">
                  <label class="vis-form-field">
                    <span>Remark</span>
                    <input class="vis-input" value="" />
                  </label>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'[Drug History]'">
          <ng-template kendoTabContent>
            <app-vis-grid [data]="opdGridView" [pageSize]="9999" [pageable]="false">
              <app-vis-grid-column field="RegDate" title="Date" width="120">
              </app-vis-grid-column>
              <app-vis-grid-column field="Doctor" title="Type" width="120">
              </app-vis-grid-column>
              <app-vis-grid-column field="Diag1" title="Diagnosis 1" width="220">
              </app-vis-grid-column>
              <app-vis-grid-column field="Diag2" title="Dagnosis 2" width="220">
              </app-vis-grid-column>
            </app-vis-grid>
            <br />
            <app-vis-grid [data]="drugGridView" [pageSize]="9999" [pageable]="false">
              <app-vis-grid-column field="DrugName" title="Drug Name" width="260">
              </app-vis-grid-column>
              <app-vis-grid-column field="Type" title="Type" width="80">
              </app-vis-grid-column>
              <app-vis-grid-column field="Total" title="Total Qty." width="80">
              </app-vis-grid-column>
              <app-vis-grid-column field="Freq" title="Freq" width="60">
              </app-vis-grid-column>
              <app-vis-grid-column field="Way" title="Way" width="60">
              </app-vis-grid-column>
              <app-vis-grid-column field="Dose" title="Dose" width="60">
              </app-vis-grid-column>
              <app-vis-grid-column field="Days" title="Days" width="60">
              </app-vis-grid-column>
            </app-vis-grid>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'[Lab]'">
          <ng-template kendoTabContent>
            <app-vis-grid [data]="labGridView" [pageSize]="9999" [pageable]="false">
              <app-vis-grid-column field="ItemDate" title="Date" width="120">
              </app-vis-grid-column>
              <app-vis-grid-column field="ItemName" title="Name" width="280">
              </app-vis-grid-column>
              <app-vis-grid-column field="Value" title="Value" width="80">
              </app-vis-grid-column>
            </app-vis-grid>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'[Nursing]'">
          <ng-template kendoTabContent>
            <div class="vis-form vis-form-narrow">
              <div class="row narrow-padding">
                <div class="col-sm-12 line-title">
                  <label class="vis-form-field">
                    <span>Measurement</span>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Measuring date</span>
                    <app-vis-datepicker ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Height</span>
                    <input class="vis-input" value="159" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Weight</span>
                    <input class="vis-input" value="65" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>BMI</span>
                    <input class="vis-input" value="25.7" disabled />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>SBP</span>
                    <input class="vis-input" value="115" />
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>DBP</span>
                    <input class="vis-input" value="69" />
                  </label>
                </div>
                <div class="col-sm-12 line-title">
                  <label class="vis-form-field">
                    <span>Nursing</span>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Nursing date</span>
                    <app-vis-datepicker ></app-vis-datepicker>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Oral drug</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Insulin</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Sport</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>SMBG</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Hypoglycemia</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Hyperglycemia</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Smoking</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Alchol</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Water</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Weight loss</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Family support</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Foot check result</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-2">
                  <label class="vis-form-field">
                    <span>Eye check result</span>
                    <app-vis-dropdownlist [data]="editOptions.na">
                    </app-vis-dropdownlist>
                  </label>
                </div>
                <div class="col-sm-8">
                  <label class="vis-form-field">
                    <span>Plan</span>
                    <input class="vis-input" />
                  </label>
                </div>
              </div>
            </div>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'[Eye Check]'" [disabled]="true">
          <ng-template kendoTabContent>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab [title]="'[Foot Check]'" [disabled]="true">
          <ng-template kendoTabContent>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip> -->
    </td>
  </tr>
</table>
