import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ContractEditComponent } from './contract/edit/edit.component';
import { ExpiringListComponent } from './contract/expiring-contract/list/list.component';
import { ContractListComponent } from './contract/list/list.component';
import { PatientEditComponent } from './edit/patients-edit.component';
import { PatientListComponent } from './list/patients-list.component';

import { PatientQueryDialogComponent } from './patient-query-dialog/patient-query-dialog.component';
// code
import { PatientsRoutingModule } from './patients-routing.module';
import { PatientContactComponent } from './patient-contact/patient-contact.component';
import { ReportHelperModule } from '../system/report/report-helper.module';
import { SystemCodeModule } from '../system/system-code/system-code.module';

@NgModule({
  declarations: [
    PatientListComponent,
    PatientEditComponent,
    ContractListComponent,
    ContractEditComponent,
    ExpiringListComponent,
    PatientQueryDialogComponent,
    PatientContactComponent
  ],
  imports: [
    SharedModule,
    ReportHelperModule
  ],
  exports: [
    PatientListComponent,
    PatientEditComponent,
    ContractListComponent,
    ContractEditComponent,
    ExpiringListComponent,
    PatientQueryDialogComponent
  ],
  providers: [
  ]
})
export class PatientsComponentsModule { }

@NgModule({
  imports: [
    PatientsComponentsModule,
    PatientsRoutingModule
  ],
  exports: [
    PatientQueryDialogComponent,
    PatientEditComponent
  ],
  providers: [
  ]
})
export class PatientsModule { }

