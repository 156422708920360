import { AfterContentInit, Component, ContentChild, Directive, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { VisGridColumnComponent } from '../vis-grid-column/vis-grid-column.component';

@Component({
  selector: 'app-vis-grid-command-column',
  templateUrl: './vis-grid-command-column.component.html',
  styleUrls: ['./vis-grid-command-column.component.css'],
  providers: [{provide: VisGridColumnComponent, useExisting: forwardRef(() => VisGridCommandColumnComponent)}],
})
export class VisGridCommandColumnComponent extends VisGridColumnComponent {

  @ViewChild(TemplateRef)
  templateRef : TemplateRef<any>;
  @ContentChild(TemplateRef)
  contentRef : TemplateRef<any>;
  @Input()
  editRow: number = null;
  @Output()
  editRowChange = new EventEmitter<number>();

  @Input()
  hideEditBtn: boolean;

  @Input()
  deletable: boolean;
  @Output()
  delete = new EventEmitter<{data:any,row:number}>();
  @Output()
  edit = new EventEmitter<{data:any,row:number}>();
  @Output()
  save = new EventEmitter<{data:any,row:number}>();
  @Output()
  cancel = new EventEmitter<{data:any,row:number}>();
  constructor(protected elementRef: ElementRef,private userConfirm: UserConfirmService) {
    super(elementRef)
   }
  onedit(data,row){
    this.editRow = row;
    this.editRowChange.emit(row);
    this.edit.emit({data,row});
  }
  onsave(data,row){
    this.save.emit({data,row});
    this.editRow = null;
    this.editRowChange.emit(null);
  }
  oncancel(data,row){
    this.editRow = null;
    this.editRowChange.emit(null);
    this.cancel.emit({data,row});
  }
  
  async ondelete(data,row){
    var ret = await this.userConfirm.showConfirm({
      title:'刪除',
      msg:'確定刪除此筆資料？',
    });
    if(ret){
      this.delete.emit({data,row});
    }
  }
  ngOnInit(): void {
  }

}
