import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-notify-sound',
  templateUrl: './notify-sound.component.html',
  styleUrls: ['./notify-sound.component.css']
})
export class NotifySoundComponent implements OnInit {

  constructor() { }

  @ViewChild('notifyAudio', { static: false }) el: ElementRef;

  ngOnInit() {
  }

  play() {
    this.el.nativeElement.play();
  }
}
