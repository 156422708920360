import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DeclareClassDesc } from 'src/app/enums/DeclareClassEnum';
import { DeclareWayDesc } from 'src/app/enums/DeclareWayEnum';
import { NHILevelDesc } from 'src/app/enums/NHILevelEnum';
import { HasDialysisCase, IsOnlyDialysisCase, ReportDataSeparate } from 'src/app/nhi-declare/declare/models/declare-xml';
import { DeclareApi, OpdDeclareReportDTO } from 'src/app/services/api-service/declare/declare-api';
import { UserCache } from 'src/app/services/user-cache';

@Component({
  selector: 'app-opd-declare-summary',
  templateUrl: './opd-declare-summary.component.html',
  styleUrls: ['./opd-declare-summary.component.css']
})
export class OpdDeclareSummaryComponent implements OnInit {

  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;

  @Input()
  set declareMonth(value) {
    if (value) {
      this.setDeclareMonth(value);
    }
  }

  OpdSummaryData: OpdDeclareReportDTO;
  itemData: OpdDeclareReportDTO[] = [];
  /** 申報方式下拉選單Data - 從Enum來(後端定義好，自動生成前端相關Enum.ts) */
  declareData = DeclareWayDesc;
  /** 申報類別下拉選單Data - 從Enum來(後端定義好，自動生成前端相關Enum.ts) */
  declareClassData = DeclareClassDesc;
  /** 醫療院所層級下拉選單Data*/
  NHILevelData = NHILevelDesc;

  constructor(private declareApi: DeclareApi) { }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.pres-report table td, table th { border: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report table tr:first-child th { border-top: 0; }\n' +
      '.pres-report table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }
  /** 取得門診申報總表 報表資料 */
  async setDeclareMonth(monthDate: any) {
    this.OpdSummaryData = await this.declareApi.GetOpdDeclareReport(UserCache.getLoginClinicId(), monthDate);

    if (this.OpdSummaryData != null != null) {
      //醫療院所層級對應
      const level = this.NHILevelData.find(f => f.value.toString() == this.OpdSummaryData.NHILevel);
      if (level != undefined && level != null) {
        this.OpdSummaryData.CheckMedicine = level.group;
      }
    }

    if (this.OpdSummaryData != null && this.OpdSummaryData.H10 != null) {
      this.OpdSummaryData.H10.e10ym = this.GetDateString(this.OpdSummaryData.H10.e10ym);
      this.OpdSummaryData.H10.e10da = this.GetDateString(this.OpdSummaryData.H10.e10da);

      //申報方式
      if (this.OpdSummaryData.H10.e10mt == "3") {
        this.OpdSummaryData.H10.e10mt = this.OpdSummaryData.H10.e10mt + "-連線";
      } else {
        const way = this.declareData.find(f => f.value.toString() == this.OpdSummaryData.H10.e10mt);
        if (way != undefined && way != null) {
          this.OpdSummaryData.H10.e10mt = way.value + "-" + way.text;
        }
      }

      //申報類別
      const classKd = this.declareClassData.find(f => f.value.toString() == this.OpdSummaryData.H10.e10kd);
      if (classKd != undefined && classKd != null) {
        this.OpdSummaryData.H10.e10kd = classKd.value + "-" + classKd.text;
      }

      //起訖日
      if (this.OpdSummaryData.H10.e10sd != null && this.OpdSummaryData.H10.e10sd != "") {
        this.OpdSummaryData.H10.e10sd = this.GetDateString(this.OpdSummaryData.H10.e10sd);
      } else {
        this.OpdSummaryData.H10.e10sd = "　　　年　　　月　　　日";
      }
      if (this.OpdSummaryData.H10.e10ed != null && this.OpdSummaryData.H10.e10ed != "") {
        this.OpdSummaryData.H10.e10ed = this.GetDateString(this.OpdSummaryData.H10.e10ed);
      } else {
        this.OpdSummaryData.H10.e10ed = "　　　年　　　月　　　日";
      }
    }

    //調整先判斷是否有洗腎案件，若無則直接產生，若有則分開產生
    this.itemData = [];
    if (!HasDialysisCase(this.OpdSummaryData.H10)) {
      this.itemData.push(this.OpdSummaryData);
    } else {
      if (IsOnlyDialysisCase(this.OpdSummaryData.H10)) {
        var data = ReportDataSeparate(this.OpdSummaryData, true);
        this.itemData.push(data);
      } else {
        var data = ReportDataSeparate(this.OpdSummaryData, false);
        this.itemData.push(data);
        data = ReportDataSeparate(this.OpdSummaryData, true);
        this.itemData.push(data);
      }
    }

  }
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }
  GetDateString(val: string) {
    if (val && val.length >= 5) {
      const y = val.substring(0, 3);
      const m = val.substring(3, 5);
      if (val.length == 5) {
        return y + "年" + m + "月";
      } else if (val.length == 7) {
        const d = val.substring(5, 7);
        return y + "年" + m + "月" + d + "日";
      }
    }
    return val;
  }
}
