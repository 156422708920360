/** 醫囑主類別 */
export enum OrderTypeEnum {
    /** 掛號費 */
    T0_RegistrationFee = 0,
    /** 診察費 */
    T1_ConsultationFee = 1,
    /** 西醫：內服藥費，中醫：科中藥費 */
    T2_OralDrugFee = 2,
    /** 外用藥費 */
    T3_ExtDrugFee = 3,
    /** 針劑藥費 */
    T4_InjectionDrugFee = 4,
    /** 注射技術費 */
    T5_InjectionServiceFee = 5,
    /** 檢驗 */
    T6_Lab = 6,
    /** 西醫：放射線(X光)，中醫：針灸 */
    T7_XRay = 7,
    /** 西醫：復健費，中醫：傷科 */
    T8_RehabilitationFee = 8,
    /** 西醫：處置費，中醫：脫臼 */
    T9_TreatmentFee = 9,
    /** 手術費 */
    T10_SurgeryFee = 10,
    /** 麻醉費 */
    T11_AnesthesiaFee = 11,
    /** 特材費 */
    T12_SpecialMaterialFee = 12,
    /** 血液血漿費 */
    T13_BloodPlasmaFee = 13,
    /** 血液透析費 */
    T14_HemodialysisFee = 14,
    /** 病床/護理/治療/照護 */
    T15_CareFee = 15,
    /** 管灌費 */
    T16_TubeFeedingFee = 16,
    /** 嬰兒費 */
    T17_BabyFee = 17,
    /** 精神科治療費 */
    T18_PsychiatryTreatmentFee = 18,
    /** 代辦費 */
    T19_ChargeFee = 19,
    /** 自費 */
    T96_SelfPayment = 96,
    /** 藥費自付額 */
    T97_DrugFeeSelfPayment = 97,
    /** 部份負擔 */
    T98_Copayment = 98,
    /** 藥事服務 */
    T99_MedicineServiceFee = 99,
    /** 西醫：西藥組合，中醫：科中藥組合 */
    T202_DrugSet = 202,
    /** 西醫：自費藥品，中醫：水藥藥費 */
    T102_SelfPaymentDrug = 102,
    /** 西醫：保留不用，中醫：水藥組合 */
    T203_WaterMedicineSet = 203,
    /** 檢驗套組 */
    T206_LabSet = 206,
    /** 西醫：部位，中醫：針灸穴位 */
    T407_Acupuncture = 407,
    /** 西醫：治療/部位，中醫：手法/部位 =&gt; Rx.Type不計價定義值，預設為Type+400，如：復健Type=8，復健項目Type=408 */
    T408_TreatOrPart = 408,
    /** 西醫：治療/部位，中醫：手法/部位 */
    T409_TreatSlashPart = 409,
    /** 部位 */
    T410_Part = 410,
    /** 自費特殊 */
    T800_OwnSpecial = 800
}
export const OrderTypeDesc:  {text: string, value: number}[] =
    [{text: '掛號費', value: 0 },
     {text: '診察費', value: 1 },
     {text: '內服藥費', value: 2 },
     {text: '外用藥費', value: 3 },
     {text: '針劑藥費', value: 4 },
     {text: '注射技術費', value: 5 },
     {text: '檢驗', value: 6 },
     {text: '放射線(X光)', value: 7 },
     {text: '復健費', value: 8 },
     {text: '處置費', value: 9 },
     {text: '手術費', value: 10 },
     {text: '麻醉費', value: 11 },
     {text: '特材費', value: 12 },
     {text: '血液血漿費', value: 13 },
     {text: '血液透析費', value: 14 },
     {text: '病床/護理/治療/照護', value: 15 },
     {text: '管灌費', value: 16 },
     {text: '嬰兒費', value: 17 },
     {text: '精神科治療費', value: 18 },
     {text: '代辦費', value: 19 },
     {text: '自費額', value: 96 },
     {text: '藥費自付額', value: 97 },
     {text: '部份負擔', value: 98 },
     {text: '藥事服務', value: 99 },
     {text: '西藥組合', value: 202 },
     {text: '自費藥品', value: 102 },
     {text: '保留不用', value: 203 },
     {text: '檢驗套組', value: 206 },
     {text: '西醫：部位，中醫：針灸穴位', value: 407 },
     {text: '治療/部位1', value: 408 },
     {text: '治療/部位2', value: 409 },
     {text: '部位', value: 410 },
     {text: '自費特殊', value: 800 }];

