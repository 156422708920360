import { Injectable } from '@angular/core';
import { SystemcodeApi } from '../api-service/system-code/systemcode-api';
import { EasyNotificationService } from '../easy-notification.service';
import { DictionaryCacheService, ICacheService } from './icache-service';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';

export declare type EnumTypes = 'ClinicType' | 'Medical' | 'Country' | 'Sex' | 'SexExt' | 'RH' | 'Blood' | 'Married' | 'TimeSec' |
  'ParameterDataType' | 'ParameterGroup' | 'LabQueryType' | 'LabDataType' | 'LabClass' | 'LabStatus' | 'OrderType'
  | 'RegistrationCodeNo' | 'RegistrationCodeMType' | "FamilyCareCaseType" | "FamilyCareDetailReportOrderType"
  | "NormalDosageType" | "CodeType" | "DosageType" | "HRxReportOrderType";

@Injectable({
  providedIn: 'root'
})
export class EnumCachedService extends DictionaryCacheService<EnumTypes,ValueTextPairNumberValue[]> {

  constructor(private systemCodeApi: SystemcodeApi, private notifyService: EasyNotificationService) { 
    super(notifyService);
  }
  protected notifyMsgOnUpdated: string = '列舉定義已變更';
  protected notifyMsgOnReload: string = '已重載列舉定義'
  
  protected async getCacheObject(keys: EnumTypes[]): Promise<Map<EnumTypes, ValueTextPairNumberValue[]>> {
    var noCachedRet = await this.systemCodeApi.GetEnum(keys);
    var m = new Map();
    for(var k in noCachedRet){
      m.set(k,noCachedRet[k]);
    }
    return m;
  }
  
}
