import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';
import { SystemCodeEnum } from 'src/app/shared/enums/system-code-enum';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { VisGridComponent } from '../../grid/vis-grid/vis-grid.component';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

@Component({
  selector: 'app-shared-patient-remark-dialog',
  templateUrl: './patient-remark-dialog.component.html',
  styleUrls: ['./patient-remark-dialog.component.css']
})
export class PatientRemarkDialogComponent implements OnInit {
  @ViewChild('grid')
  grid:VisGridComponent;

  constructor(
    //private systemCodeApi: SystemcodeApi,
    private clinicData:ClinicDataService,
    private cd: ChangeDetectorRef) {
      //this.ngOnInit();
    }

  //#region 參數 --------------------
  //api: WebApiService;

  // window
  winWidth = 450;
  winHeight = 480;
  oringin = '';
  @Input() isDialogOpened = false;
  @Input()
  set selectedText(v: string){
    if(v) {
      this.oringin = v;
      if(this.allItems){
        this.parseInput();
      }
    } else {
      this.userInput = {
        additions:'',
        history:{}
      };
      this.codeTypeList.forEach(ct=>{
        this.userInput.additions+=`~{@${ct.text}@}~\n`
        this.userInput.history[ct.value] = [];
      });
    }
  };

  //@Input() codeTypeList: ValueTextPair[] = [];
  private pastHist = 'E0050';
  private familyHist = 'E0060';
  private opeHist = 'E0070';
  codeTypeList: ValueTextPair[] = [
    { text: '既往病史', value: this.pastHist }, //SystemCodeEnum.E0050_PastHist },
    { text: '家族病史', value: this.familyHist }, //SystemCodeEnum.E0060_FamilyHist },
    { text: '手術病史', value: this.opeHist }  //SystemCodeEnum.E0070_OpeHist }
  ];
  userInput:{history:{[key:string]:string[]},additions:string}
  @Output() emitResult = new EventEmitter<{items:{type: string,items:ValueTextPair[]}[],text:string}>();

  // 清單物件
  listItems: ValueTextPair[];
  allItems:{[key:string]:ValueTextPair[]}
  //#endregion 參數 --------------------

  //#region 參數 表格 --------------------

  // 表格-分頁
  gridView: any;
  // pageSize: number = BaseConfig.settings.ui.pageSize;
  pageSize = 5; // 讓資料在同一頁
  pageSkip = 0;
  page = 1;

  //#endregion 參數 表格 --------------------

  async ngOnInit() {
    await this.refreshList();
    this.parseInput();
  }

  async cleanData() {
    await this.refreshList();
    this.parseInput();
  }

  reloadData(d: any) {
    //await this.refreshList();
    this.selectedText = d;
    if(d) {
      this.oringin = d;
      if(this.allItems){
        this.parseInput();
      }
    } else {
      this.userInput = {
        additions:'',
        history:{}
      };
      this.codeTypeList.forEach(ct=>{
        this.userInput.additions+=`~{@${ct.text}@}~\n`
        this.userInput.history[ct.value] = [];
        if(this.allItems){
          this.allItems[ct.value].forEach(it=>it['checked'] = false);
        }
      });
    }
  }

  parseInput(){
    var lines = this.oringin.split('\n');
    var parseItems = (label: string)=>{
      var regexp = new RegExp('.*'+label+'.*：.?');
      var line = lines.filter(l => l.match(regexp))[0];
      if(line!=undefined){
        var items = line.substring(line.indexOf('：')+1).split(',').filter(t=>t);
        lines[lines.indexOf(line)] = `~{@${label}@}~`
        //lines.splice(lines.indexOf(line),1);
        return items;
      }
      lines = [`~{@${label}@}~`].concat(lines);
      return [];
    }

    this.userInput = {
      additions:lines.join('\n'),
      history:{}
    };
    // 因為會依序新增到最前面，所以要從後方算到前方
    for(var i = 2; i>=0; i--){
      var ct = this.codeTypeList[i];
      var items = parseItems(ct.text);
      this.allItems[ct.value].forEach(it=>it['checked'] = items.some(name=>name==it.text));

      this.userInput.history[ct.value] = items;
    }

    this.userInput.additions = lines.join('\n');
  }

  checkChanged(evt:{checked:boolean,data:any}){
    // console.log(evt);
    if(evt.checked==true){
      // Check all => 排除 NA
      if(evt.data == null){
        this.listItems.forEach(it=>{
          if(it.value === 'NA'){
            it['checked']=false;
          }
        });
      }
      if(evt.data.value==='NA'){
        this.listItems.forEach(it=>{
          if(it.value !== evt.data.value){
            it['checked']=false;
          }
        });
        this.userInput.history[this.currentTp]=[]
      }else{
        this.listItems.forEach(it=>{
          if(it.value === 'NA'){
            it['checked']=false;
          }
        });
      }
      this.grid.resetSelectStatus();
    }
  }

  // #region form and query --------------------
  currentTp:string = this.codeTypeList[0].value;
  changeCodeType(evt: MatRadioChange) {
    // 重設頁數
    this.page = 1;
    this.pageSkip = 0;
    this.currentTp = evt.value;
    // 設定新項目代碼
    this.listItems = this.allItems[evt.value];
        // 表格分頁
    this.setGridView();
  }
  // 更新清單 --使用 api GetCodeList()
  async refreshList() {
    this.gridView = {
      data: [],
      total: 0
    };
    this.allItems = await this.clinicData.getSystemCodes(this.codeTypeList.map(cl=>cl.value));
    this.listItems = this.allItems[this.pastHist];  //SystemCodeEnum.E0050_PastHist];
        // 表格分頁
    this.setGridView();

  }
  // #endregion form and query --------------------

  // #region grid --------------------
  // 表格分頁
  setGridView() {
    this.gridView = {
      data: this.listItems.slice((this.page-1)*this.pageSize, this.page* this.pageSize),
      total: this.listItems.length,
    };
    this.cd.markForCheck();
  }

  // 分頁切換事件
  pageChange(pageNum: any): void {
    this.page = pageNum; // kendo grid page用法
    this.pageSkip = (pageNum-1)*this.pageSize;
    this.setGridView();
  }
  // #endregion grid --------------------

  // #region dialog --------------------
  get isDisableBtnSelect() {
    var selectedAny = false;
    this.codeTypeList?.forEach(c=>{
      selectedAny = selectedAny || this.allItems[c.value].some(it=>it['checked']==true);
    });
    return !selectedAny;
  }

  onDialogClose(status: boolean) {
    if (status === true) {
      var ret = this.codeTypeList.map(ct=>{
        return { type: ct.value, items: this.allItems[ct.value].filter(it=>it['checked']==true) };
      });
      var retText = this.result();
      this.selectedText = '';
      this.oringin = '';
      //this.refreshList();
      this.currentTp = this.codeTypeList[0].value;
      this.emitResult.emit({items:ret,text:retText});
    } else {
      this.selectedText = '';  //this.oringin;
      this.oringin = '';
      //this.refreshList();
      this.currentTp = this.codeTypeList[0].value;
      // 這次沒選
      this.emitResult.emit(null);
      // 清除選取
      // this.selectedItems = [];
    }
  }

  result(){
    if(!this.userInput){
      return '';
    }
    var retText =  this.userInput.additions;
    this.codeTypeList.forEach(ct=>{
      var labelTxt = `[${ct.text}]：`;
      this.allItems[ct.value].forEach(it=>{
        var origin = this.userInput.history[ct.value];
        var oIdx = origin.indexOf(it.text);
        // 原本有，勾掉=>移除
        if(oIdx>=0 && it['checked']==false){
          origin.splice(oIdx,1);
        }
        // 原本無，勾選=>新增
        else if(oIdx<0 && it['checked']== true){
          origin.push(it.text);
        }
      })
      var itemTxt = this.userInput.history[ct.value].join(',');
      retText = retText.replace(`~{@${ct.text}@}~`,labelTxt + itemTxt)
    });
    return retText;
  }
  // #endregion dialog --------------------
}
