<div class="inside-scrollbar extend-msg" [ngStyle]="{maxHeight:bigRatio?'415px':'465px'}">
    <ng-container *ngIf="isHcrDemo;else hcrMode">
        <div style="display: flex;">
            <div style="flex-grow: 1;">病人資料：</div>
            <div>
                <mat-checkbox [checked]="prevDemoPatientWhenRand" (change)="prevPatChanged($event)">
                    ic掛號時沿用同一人
                </mat-checkbox>
            </div>
        </div>
        <table>
            <tr style="background-color: var(--vis-assets-blue-color);">
                <th style="width: 20%;" class="text-center font-weight-bold"> 姓名 </th>
                <th style="width: 30%;" class="text-center font-weight-bold"> 身分證 </th>
                <th style="width: 30%;" class="text-center font-weight-bold"> 生日 </th>
                <th style="width: 20%;" class="text-center font-weight-bold"> 性別 </th>
            </tr>
            <tr *ngIf="demoPatient">
                <td class="text-center">
                    <div class="ellipsis-content" [title]="demoPatient.CName">{{demoPatient.CName}}</div>
                </td>
                <td class="text-center">
                    <div class="ellipsis-content" [title]="demoPatient.CId">{{demoPatient.CId}}</div>
                </td>
                <td class="text-center">
                    <div class="ellipsis-content" [title]="demoPatient.Birthday">{{demoPatient.Birthday  | formatDate:''}}</div>
                </td>
                <td class="text-center">
                    <div class="ellipsis-content" [title]="demoPatient.Sex">{{demoPatient.Sex==0?'女':'男'}}</div>
                </td>
            </tr>
        </table>
        <div style="margin: 5px 0px;" *ngIf="isDebugMode()">
            檢驗測試
            <button (click)="CreateFakeResult()">產生今日檢驗報告到檢驗平台</button>
            <!-- <button (click)="TestITreat()">測試就醫識別馬</button> -->
        </div>
    </ng-container>
    <ng-template #hcrMode>
        <ng-container [ngSwitch]="isHCREnable">
            <div class="mt-3" *ngSwitchCase="true">
                <div style="height: 40px;margin-top: -20px; display:flex;align-items: center;">
                    <span style="flex-grow: 1;font-size: 16px;font-weight: bolder;color: #fe824c;">
                        展望主控台已連線。
                    </span>
                    <button *ngIf="consoleInfo" [appOpenDialog]="about">關於</button>
                </div>
                <mat-grid-list cols=4 rowHeight=30>
                    <mat-grid-tile colspan=4>
                        <hr style="background-color: #111111;margin: 0px;">
                        <div class="w-100 d-flex" style="text-align: start;">
                            <span>院所名稱：</span><span class="hcr-label-name" title="{{hospName}}">{{hospName}}</span>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4>
                        <div class="w-100 d-flex" style="text-align: start;">
                            <span>院所簡稱：</span><span class="hcr-label-name"
                                title="{{hospAbbName}}">{{hospAbbName}}</span>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4>
                        <div class="w-100 d-flex position-relative">
                            <span>健保署雲端控制軟體：</span>
                            <span class="hcr-label-name" style="width: calc(100% - 250px);"
                                title="{{dllVer + ' &nbsp;'}}">{{dllVer}}</span>
                            <button type="button" title="重置健保署雲端控制軟體" (click)="resetCRStatus($event)" style="width: 180px;"
                                class="lh-base background-none recard-btn p-0 position-absolute float-right end-0 mx-1 pl-2">
                                <mat-icon class="pref-icon vis-combine-icon">restart_alt</mat-icon>
                                <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">重置健保署雲端控制軟體</span>
                            </button>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4>
                        <div class="w-100 d-flex" style="display: flex;">
                            <span>醫事人員卡卡機：</span><span class="hcr-label-name"
                                title="{{cardReaderHPC + ' &nbsp;'}}">{{cardReaderHPC}}</span>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4>
                        <div class="w-100 d-flex" style="display: flex;">
                            <span>健保IC卡卡機：</span><span class="hcr-label-name"
                                title="{{cardReaderHC + ' &nbsp;'}}">{{cardReaderHC}}</span>
                        </div>
                    </mat-grid-tile>
                </mat-grid-list>
                <hr class="m-0">
                <mat-grid-list cols="10" rowHeight="40">
                    <mat-grid-tile colspan=10>
                        <div style="width: 100%;text-align: start;font-size: 16px;vertical-align: middle;"
                            class="position-relative">
                            <span style="vertical-align: text-bottom;" class="easy-form-msg">認證狀態：</span>
                            <button type="button" title="重新整理認證狀態" (click)="getCardStatus()"
                                class="lh-base background-none recard-btn p-0 position-relative w-24 mx-1 pl-2 float-right end-0 position-absolute">
                                <mat-icon class="pref-icon vis-combine-icon">refresh</mat-icon>
                                <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">重新整理</span>
                            </button>
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-assets-blue font-weight-bold">
                        <div class="text-center w-100">
                            安全模組檔
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4 class="bg-light-gray">
                        <div style="width: 100%;text-align: start;" class="lh-sm">
                            {{statusSAM}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-light-gray">
                        <button type="button" (click)="SamValidate()"
                            class="my-1 text-xs btn-grid px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="認證">verified</mat-icon>
                            <span class="text-xs-sm float-right">認證</span>
                        </button>
                        <button type="button" (click)="resetSAMStatus()"
                            class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="重置">restore</mat-icon>
                            <span class="text-xs-sm float-right">重置</span>
                        </button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-assets-blue font-weight-bold">
                        <div class="text-center w-100">
                            健保IC卡
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4 class="bg-light-gray">
                        <div style="width: 100%;text-align: start;" class="lh-sm">
                            {{statusHC}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-light-gray">
                        <button type="button" (click)="UpdateHC()"
                            class="my-1 text-xs btn-grid px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="更新">refresh</mat-icon>
                            <span class="text-xs-sm float-right">更新</span>
                        </button>
                        <button type="button" (click)="resetHCStatus()"
                            class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="重置">restore</mat-icon>
                            <span class="text-xs-sm float-right">重置</span>
                        </button>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-assets-blue font-weight-bold">
                        <div class="text-center w-100">
                            醫事人員卡
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=4 class="bg-light-gray">
                        <div style="width: 100%;text-align: start;" class="lh-sm">
                            {{statusHPC}}
                        </div>
                    </mat-grid-tile>
                    <mat-grid-tile colspan=3 class="bg-light-gray">
                        <button type="button" (click)="CheckPINHPC()"
                            class="my-1 text-xs btn-grid px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="PIN">pin</mat-icon>
                            <span class="text-xs-sm float-right">PIN</span>
                        </button>
                        <button type="button" (click)="resetHPCStatus()"
                            class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none lh-base">
                            <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="重置">restore</mat-icon>
                            <span class="text-xs-sm float-right">重置</span>
                        </button>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <!--未連線狀態-->
            <div *ngSwitchCase="false" class="mt-2 easy-form-msg" style="display: flex;column-gap: 10px;">
                尚未連線至卡機服務程式。
                <a href="javascript:;" (click)="openApp()">更新並啟動</a>
                <a href="javascript:;" (click)="openAppNoUpdate()">直接啟動</a>
            </div>
        </ng-container>
    </ng-template>
    
    <ng-container *ngIf="satelliteEnable">
        <hr style="margin: 10px 0px;">
        <div style="width: 100%;text-align: start;font-size: 16px;vertical-align: middle;" class="position-relative">
            <span class="easy-form-msg">衛星藥局：</span>
            <input [value]="satelliteHost" #hostIp>
            <mat-icon style="font-size: 20px;vertical-align: bottom;" 
            [ngStyle]="{color:satelliteStatus?'green':'red'}">{{satelliteStatus?'check':'error_outline'}}</mat-icon>
            <button style="margin-left: 10px;margin-right: 10px;" (click)="tryHost(hostIp.value)">測試</button>
            <!-- <button (click)="updateHost(hostIp.value)">儲存</button> -->
        </div>
    </ng-container>
    <ng-container *ngIf="isDebugMode() && isHCREnable">
        <div style="width: 100%;text-align: start;font-size: 16px;vertical-align: middle;" class="position-relative">
            <span class="easy-form-msg">虛擬健保卡：</span>
            <button [appOpenDialog]="qrcode">掃QR</button>
            <button (click)="clearVHC()">清除</button>
            <button (click)="VHCStatus()">讀取狀態</button>
            <button (click)="VHCBasic()">讀取基資</button>
            <button (click)="VHCRegBasic()">讀取掛號基資</button>
            <button (click)="VHCTreat()">讀取就醫資料</button>
            <button (click)="VHCTreat2()">讀取需醫師卡就醫資料</button>
            <button (click)="VHCIsq()">讀取最後一次就醫序號</button>
            {{vhcStatus}}
        </div>
        
        <hr style="margin: 10px 0px;">
        <div style="width: 100%;text-align: start;font-size: 16px;vertical-align: middle;" class="position-relative">
            <span class="easy-form-msg">API測試：</span>
            <!-- <button (click)="cnhieiiTest($event)">醫療資料傳輸共同介面</button>
            <button (click)="dayliUploadTest($event)">每日上傳</button> -->
            <a style="font-size: 14px;" class="mr-2 position-relative azure cursor-pointer" (click)="cnhieiiTest($event)">
              <mat-icon class="mat-icon position-absolute vis-combine-icon">storage</mat-icon>
              <div class="text-lg d-inline-flex" style="text-indent: 24px;">醫療資料傳輸共同介面</div>
            </a>
            <a style="font-size: 14px;" class="position-relative orange cursor-pointer" (click)="dayliUploadTest($event)">
              <mat-icon class="mat-icon position-absolute vis-combine-icon">upload_for_offline</mat-icon>
              <div class="text-lg d-inline-flex" style="text-indent: 22px;">每日上傳</div>
            </a>
            <div style="margin-left: 90px;">
                <mat-checkbox [checked]="useWebApi" (change)="useWebApiChecked($event)">就醫識別碼使用WebApi</mat-checkbox>
                <!-- <button (click)="getTreat()">取得就醫識別碼</button>
                {{tNum}} -->
            </div>
        </div>
        <div>
            就醫識別碼模擬器：    
        </div>
        <div style="display: flex;">
            <input style="width: 80px;" placeholder="機構碼(6)" [(ngModel)]="treat_hinno">
            <input style="width: 100px;" placeholder="身分證(10)" [(ngModel)]="treat_cid">
            <app-vis-datepicker placeholder="就診日期時間(yyyMMddHHmmss)" [(ngModel)]="treat_dt"></app-vis-datepicker>
            <app-vis-timepicker style="width: 100px;" [showSecond]="true" [(ngModel)]="treat_tm"></app-vis-timepicker>
            <button (click)="TestITreat()">產生</button>
            
        </div>
        <div style="display: flex;">
            結果：<input style="width: 200px;" [(ngModel)]="treat_no">(如有雷同純屬巧合)
            <button (click)="ParseTreat()">解析</button>
            <button (click)="ClearTreat()">清除</button>
        </div>
    </ng-container>

</div>


<app-vis-dialog [title]="'展望主控台'" [width]="300" (close)="about.show=false" #about>
    <div style="display: flex;margin: 5px 20px;">
        <div style="width: 100px;">電腦名稱：</div>
        <div style="flex-grow: 1;">{{consoleInfo?.ComputerName}}</div>
    </div>
    <div style="display: flex;margin: 5px 20px">
        <div style="width: 100px;">主控台版本：</div>
        <div style="flex-grow: 1;">{{consoleInfo?.Version}}</div>
    </div>
    <hr style="margin: 0px;">
    <div style="display: flex;margin: 5px 20px">
        <div style="width: 100px;">模組：</div>
    </div>
    <table style="margin: 5px 20px 20px 20px;width: calc(100% - 40px);">
        <tr *ngFor="let item of consoleInfo?.Mods">
            <td style="width: 150px;padding-left: 10px;">{{item.Name}}</td>
            <td style="text-align: center;">{{item.Ver}}</td>
        </tr>
    </table>
</app-vis-dialog>

<app-vis-dialog [title]="'QRCode掃描器'" [width]="300" (close)="qrcode.show=false" #qrcode>
    <app-qrcode-scanner *ngIf="qrcode.show" (onSuccess)="onQRCodeScanResult($event,qrcode)"></app-qrcode-scanner>
</app-vis-dialog>