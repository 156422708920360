<div class="row narrow-padding" style="height: 100%;">
  <div class="col-sm-5"  style="height: 100%;">
    <div class="card" [hidden]="!isShow()" [attr.disabled]="allDisabled">
      <!--./card-header-->
      <div class="card-body no-padding">
        <app-vis-grid [data]="gridData" [attr.disabled]="allDisabled" [pageSize]="pageSize" [skip]="0"
          [pageable]="true" (pageChange)="pageChange($event)" [selectable]="true" [customClass]="'extend'"
          [kendoGridSelectBy]="'Id'" [selectedKeys]="selectedItems" (cellClick)="gridCellClickHandler($event)">
          <app-vis-grid-column field="LabFormNo" title="檢驗單號" [width]="105" i18n-title="@@LabFormNo">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientNo" title="病歷號" [CellStyle]="{direction:'rtl'}" [width]="110" i18n-title="@@PatientNo">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientName" title="姓名"  [width]="100" i18n-title="@@PatientName" class="custom-name">
          </app-vis-grid-column>
          <app-vis-grid-column field="CreateDate" title="開立日期" [width]="110"  [dateTimeFormat]="'Date'" i18n-title="@@LabCreateDate" class="text-center custon-m-auto">
          </app-vis-grid-column>
          <app-vis-grid-column field="ReportDate" title="報告日期" [width]="110" [dateTimeFormat]="'Date'" i18n-title="@@LabReportDate" class="text-center custon-m-auto">
          </app-vis-grid-column>
          <app-vis-grid-column field="Status" title="狀態" [pipe]="queryOptions.statusType" [width]="66" i18n-title="@@Status">
          </app-vis-grid-column>
        </app-vis-grid>
      </div>
      <!--./card-body-->
    </div>
    <!--./card-->
  </div>
  <div class="col-sm-7"  style="height: 100%;">
    <app-labs-report-query-dialog-edit [editOptions]="editOptions">
    </app-labs-report-query-dialog-edit>
  </div>
</div>

