/**  */
export enum TimeSecEnum {
    /** 早上 */
    Morning = 1,
    /** 下午 */
    Afternoon = 2,
    /** 晚上 */
    Evening = 3,
    /** 夜間 */
    Night = 4
}
export const TimeSecDesc: {text: string, value: number}[] =
    [{text: '早上', value: 1 },
     {text: '下午', value: 2 },
     {text: '晚上', value: 3 },
     {text: '夜間', value: 4 }];

