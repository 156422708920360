import { Directive, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { VisDialogComponent } from '../components/dialog/vis-dialog/vis-dialog.component';

/** 
 * 這個驅動是拿來掛在Button上，令其click的時候的時候其指定的app-vis-dialog開啟，並在使用者點及x的時候關閉
 * 
 * 用法：
 * 
 * <app-vis-dialog #dialog></app-vis-dialog>
 * 
 * [appOpenDialog]="dialog"
 */
@Directive({
  selector: '[appOpenDialog]',
  exportAs:'appOpenDialog'
})
export class OpenDialogDirective {
    private _appDialog: VisDialogComponent;
    @Input()
    set appOpenDialog(d: VisDialogComponent){
        this._appDialog = d;
        this._appDialog.close.subscribe(()=>{
            this._appDialog.show = false;
        })
    };
    @Output()
    open = new EventEmitter();
    @Output()
    beforeOpen = new EventEmitter();
    constructor() {}

    @HostListener('click', ['$event'])
    onClick(e: Event) {        
        this.beforeOpen.emit();
        setTimeout(() => {
            this._appDialog.show = true;
            this.open.emit();    
        }, 0);
    }

}


@Directive({
    selector: '[appCloseDialog]',
    exportAs:'appCloseDialog'
  })
  export class CloseDialogDirective {
      private _appDialog: VisDialogComponent;

      @Input()
      set appCloseDialog(d: VisDialogComponent){
          this._appDialog = d;
      };
      
      @Output()
      onclose = new EventEmitter<{canClose:boolean}>();

      constructor() {}
  
      @HostListener('click', ['$event'])
      onClick(e: Event) {
          var arg = { canClose:true}
          this.onclose.emit(arg);
          this._appDialog.show = !arg.canClose;
      }
  
  }
  