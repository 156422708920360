import { H10 } from "src/app/models/H10";
import { H11 } from "src/app/models/H11";
import { H12 } from "src/app/models/H12";
import { OpdDeclareReportDTO, OPDxmlDataDTO } from "src/app/services/api-service/declare/declare-api";
import { CreateXml, deepCopy, NullOrEmpty, NullOrEmptyTrim, xmlFixSpecialChar } from "src/app/shared/utilities";

/** 產生門診申報xml */
export function CreateOpdXml(declareData: OPDxmlDataDTO) {
  var nhiH10: H10 = declareData.H10;
  var nhiH11: H11[] = declareData.H11List;
  return CreateXml({
    outpatient: {
      tdata: {
        t1: nhiH10.e10tp,
        t2: nhiH10.e10cd,
        t3: nhiH10.e10ym,
        t4: nhiH10.e10mt,
        t5: nhiH10.e10kd,
        t6: nhiH10.e10da,
        t7: optionalChangeVal(nhiH10.e10n0),
        t8: optionalChangeVal(nhiH10.e10n1),
        t9: optionalChangeVal(nhiH10.e10n2),
        t10: optionalChangeVal(nhiH10.e10n3),
        t11: optionalChangeVal(nhiH10.e10hn),
        t12: optionalChangeVal(nhiH10.e10ha),
        t13: optionalChangeVal(nhiH10.e10bn),
        t14: optionalChangeVal(nhiH10.e10ba),
        t15: optionalChangeVal(nhiH10.e10fn),
        t16: optionalChangeVal(nhiH10.e10fa),
        t17: optionalChangeVal(nhiH10.e10nn),
        t18: optionalChangeVal(nhiH10.e10na),
        t19: optionalChangeVal(nhiH10.e10d0),
        t20: optionalChangeVal(nhiH10.e10d1),
        t21: optionalChangeVal(nhiH10.e10d2),
        t22: optionalChangeVal(nhiH10.e10d3),
        t23: optionalChangeVal(nhiH10.e10dn),
        t24: optionalChangeVal(nhiH10.e10dm),
        t25: optionalChangeVal(nhiH10.e10c0),
        t26: optionalChangeVal(nhiH10.e10c1),
        t27: optionalChangeVal(nhiH10.e10c2),
        t28: optionalChangeVal(nhiH10.e10c3),
        t29: optionalChangeVal(nhiH10.e10cn),
        t30: optionalChangeVal(nhiH10.e10ca),
        t31: optionalChangeVal(nhiH10.e10pn),
        t32: optionalChangeVal(nhiH10.e10pa),
        t33: optionalChangeVal(nhiH10.e10ln),
        t34: optionalChangeVal(nhiH10.e10la),
        t35: optionalChangeVal(nhiH10.e10rn),
        t36: optionalChangeVal(nhiH10.e10ra),
        t37: nhiH10.e10tn,
        t38: nhiH10.e10ta,
        t39: optionalChangeVal(nhiH10.e109n),
        t40: optionalChangeVal(nhiH10.e1098),
        t41: optionalChangeVal(nhiH10.e10sd),
        t42: optionalChangeVal(nhiH10.e10ed),
      },
      ddata: nhiH11.map(x => createddata(x, x.H12List)),

    }
  });
}
/** 申報xml分開兩種(一種洗腎，另一種非洗腎) */
export function CreateOpdXmlSeparate(declareData: OPDxmlDataDTO, getDialysis: boolean) {
  var data = deepCopy<OPDxmlDataDTO>(declareData);
  data.H10 = DeclareSeparate(data.H10, getDialysis);
  data.H11List = getDialysis ?
    data.H11List.filter(x => x.e11cs == '05') :
    data.H11List.filter(x => x.e11cs != '05');
  return CreateOpdXml(data);
}
/** 報表資料分開兩種(一種洗腎，另一種非洗腎) */
export function ReportDataSeparate(opdDeclareReportDTO: OpdDeclareReportDTO, getDialysis: boolean) {
  var reportDTO = deepCopy<OpdDeclareReportDTO>(opdDeclareReportDTO);
  reportDTO.H10 = DeclareSeparate(reportDTO.H10, getDialysis);
  return reportDTO;
}
/** 申報資料分開 */
export function DeclareSeparate(h10: H10, getDialysis: boolean) {
  var nhiH10 = deepCopy<H10>(h10);
  nhiH10.e10n0 = getDialysis ? '0' : nhiH10.e10n0;
  nhiH10.e10n1 = getDialysis ? '0' : nhiH10.e10n1;
  nhiH10.e10n2 = getDialysis ? '0' : nhiH10.e10n2;
  nhiH10.e10n3 = getDialysis ? '0' : nhiH10.e10n3;
  nhiH10.e10hn = getDialysis ? nhiH10.e10hn : '0';
  nhiH10.e10ha = getDialysis ? nhiH10.e10ha : '0';
  nhiH10.e10bn = getDialysis ? '0' : nhiH10.e10bn;
  nhiH10.e10ba = getDialysis ? '0' : nhiH10.e10ba;
  nhiH10.e10fn = getDialysis ? '0' : nhiH10.e10fn;
  nhiH10.e10fa = getDialysis ? '0' : nhiH10.e10fa;
  nhiH10.e10nn = getDialysis ? nhiH10.e10hn : nhiH10.e10nn;
  nhiH10.e10na = getDialysis ? nhiH10.e10ha : nhiH10.e10na;
  nhiH10.e10d0 = getDialysis ? '0' : nhiH10.e10d0;
  nhiH10.e10d1 = getDialysis ? '0' : nhiH10.e10d1;
  nhiH10.e10d2 = getDialysis ? '0' : nhiH10.e10d2;
  nhiH10.e10d3 = getDialysis ? '0' : nhiH10.e10d3;
  nhiH10.e10dn = getDialysis ? '0' : nhiH10.e10dn;
  nhiH10.e10dm = getDialysis ? '0' : nhiH10.e10dm;
  nhiH10.e10c0 = getDialysis ? '0' : nhiH10.e10c0;
  nhiH10.e10c1 = getDialysis ? '0' : nhiH10.e10c1;
  nhiH10.e10c2 = getDialysis ? '0' : nhiH10.e10c2;
  nhiH10.e10c3 = getDialysis ? '0' : nhiH10.e10c3;
  nhiH10.e10cn = getDialysis ? '0' : nhiH10.e10cn;
  nhiH10.e10ca = getDialysis ? '0' : nhiH10.e10ca;
  nhiH10.e10pn = getDialysis ? '0' : nhiH10.e10pn;
  nhiH10.e10pa = getDialysis ? '0' : nhiH10.e10pa;
  nhiH10.e10ln = getDialysis ? '0' : nhiH10.e10ln;
  nhiH10.e10la = getDialysis ? '0' : nhiH10.e10la;
  nhiH10.e10rn = getDialysis ? '0' : nhiH10.e10rn;
  nhiH10.e10ra = getDialysis ? '0' : nhiH10.e10ra;
  nhiH10.e10tn = getDialysis ? nhiH10.e10hn : nhiH10.e10tn;
  nhiH10.e10ta = getDialysis ? nhiH10.e10ha : nhiH10.e10ta;
  nhiH10.e109n = getDialysis ? nhiH10.e109nh : nhiH10.e109n;
  nhiH10.e1098 = getDialysis ? nhiH10.e1098h : nhiH10.e1098;
  return nhiH10;
}
/** 產生ddata(點數清單段H11) */
function createddata(nhiH11: H11, nhiH12: H12[]) {
  return {
    dhead: {
      d1: nhiH11.e11cs,
      d2: stringToInt(nhiH11.e11sq),
    },
    dbody: {
      d3: nhiH11.e11id,
      d4: optionalChangeVal(nhiH11.e11i1),
      d5: optionalChangeVal(nhiH11.e11i2),
      d6: optionalChangeVal(nhiH11.e11i3),
      d7: optionalChangeVal(nhiH11.e11i4),
      d8: nhiH11.e11sb,
      d9: nhiH11.e11da,
      d10: nhiH11.e11ed,
      d11: nhiH11.e11bd,
      d12: optionalChangeVal(nhiH11.e1112),
      d13: optionalChangeVal(nhiH11.e1113),
      d14: optionalChangeVal(nhiH11.e11ds),
      d15: nhiH11.e11c8,
      d16: optionalChangeVal(nhiH11.e11rf),
      d17: nhiH11.e11r0,
      d18: nhiH11.e11so,
      d19: optionalChangeVal(nhiH11.e11n1),
      d20: optionalChangeVal(nhiH11.e11n2),
      d21: optionalChangeVal(nhiH11.e11n3),
      d22: optionalChangeVal(nhiH11.e11n4),
      d23: optionalChangeVal(nhiH11.e11n5),
      d24: optionalChangeVal(nhiH11.e11op),
      d25: optionalChangeVal(nhiH11.e11o1),
      d26: optionalChangeVal(nhiH11.e11o2),
      d27: optionalChangeVal(nhiH11.e11dy),
      d28: nhiH11.e11em,
      d29: nhiH11.e1129,
      d30: nhiH11.e11dt,
      d31: optionalChangeVal(nhiH11.e11dr),
      d32: optionalChangeVal(nhiH11.e11a2),
      d33: optionalChangeVal(nhiH11.e11a6),
      d34: optionalChangeVal(nhiH11.e11ab),
      d35: optionalChangeVal(nhiH11.e11c1),
      d36: optionalChangeVal(nhiH11.e11a1),
      d37: optionalChangeVal(nhiH11.e11c9),
      d38: optionalChangeVal(nhiH11.e1199),
      d39: nhiH11.e11tt,
      d40: nhiH11.e1198,
      d41: nhiH11.e11ta,
      d42: optionalChangeVal(nhiH11.e1142),
      d43: optionalChangeVal(nhiH11.e1143),
      d44: optionalChangeVal(nhiH11.e1144),
      d45: optionalChangeVal(nhiH11.e11bb),
      d48: optionalChangeVal(nhiH11.e11g9),
      d49: optionalChangeVal(replaceSpecialSymbols(nhiH11.e11nm)),
      d50: optionalChangeVal(nhiH11.e11rc),
      d51: optionalChangeVal(nhiH11.e11bm),
      d52: optionalChangeVal(nhiH11.e11la),
      d53: optionalChangeVal(nhiH11.e11ar),
      d54: optionalChangeVal(nhiH11.e11gd),
      d55: optionalChangeVal(nhiH11.e11sy),
      d56: optionalChangeVal(nhiH11.e11is),
      d57: nhiH11.e1157 != null && nhiH11.e1157 != '' ? nhiH11.e1157 : '0',  //2023.12.28 判斷若無部份負擔，△d57 基本部分負擔點數、△d58藥品部分負擔點數這些欄位，XML一樣送0出去
      d58: nhiH11.e1158 != null && nhiH11.e1158 != '' ? nhiH11.e1158 : '0',  //2023.12.28 判斷若無部份負擔，△d57 基本部分負擔點數、△d58藥品部分負擔點數這些欄位，XML一樣送0出去
      d59: optionalChangeVal(nhiH11.e1159),
      d60: optionalChangeVal(nhiH11.e11nic),
      d61: optionalChangeVal(nhiH11.e11lic),
      d62: optionalChangeVal(nhiH11.e11oic),
      pdata: nhiH12.map(y => creatpdata(y)),
    }
  }
}

/** 產生pdata(醫令H12) */
function creatpdata(nhiH12: H12) {
  return {
    p1: nhiH12.e12ay1 != null && nhiH12.e12ay1 != '' ? nhiH12.e12ay1 : '0',
    p2: nhiH12.e12em1,
    p3: nhiH12.e12od1,
    p4: nhiH12.e12rx1,
    p5: nhiH12.e12od1 == '1' ? stringToDecimal(nhiH12.e1205) : stringToDecimal(optionalChangeVal(nhiH12.e1205)),
    p6: nhiH12.e12ps1,
    p7: nhiH12.e12od1 == '1'|| nhiH12.e12od1 == '4'? replaceSpecialSymbols(nhiH12.e1207) : '', //若醫令為藥品時，本欄為必填欄位
    p8: stringToDecimal(NullOrEmptyTrim(nhiH12.e12mu1) ? 100 : nhiH12.e12mu1),
    p9: nhiH12.e12pt1,
    p10: stringToDecimal(NullOrEmptyTrim(nhiH12.e12tq1) ? 0 : nhiH12.e12tq1, 1),
    p11: stringToDecimal(NullOrEmptyTrim(nhiH12.e12pr1) ? 0 : nhiH12.e12pr1, 2),
    p12: nhiH12.e12am1,
    p13: nhiH12.e12sn1,
    p14: nhiH12.e12st1,
    p15: nhiH12.e12et1,
    p16: nhiH12.e12dx1,
    p17: nhiH12.e12lo1,
    p18: nhiH12.e12pa1,
    p19: nhiH12.e12pn1,
    p20: nhiH12.e12sb1,
    p21: nhiH12.e12bs1,
    p22: nhiH12.e12nl,
    p23: replaceSpecialSymbols(nhiH12.e12nn),
    p24: nhiH12.e12or,
    p25: nhiH12.e1225,
    p26: optionalChangeVal(nhiH12.e12ic),
  }
}

/** 非必填欄位處理成null */
function optionalChangeVal(val: any) {
  if (val) {
    //若為非必填，則0可以不用傳入
    if (typeof (val) === 'string') {
      var tmp = val.trim();
      if (!NullOrEmpty(tmp)) {
        if (!isNaN(tmp as any)) {
          var todec = stringToDecimal(tmp);
          if (todec > 0) {
            return val;
          } else {
            return null;
          }
        } else {
          return val;
        }
      }
      return null;
    } else {
      return val > 0 ? val : null;
    }
  } else {
    return null;
  }
}

/** string轉成int */
function stringToInt(val: any) {
  if (val) {
    if (!isNaN(val)) {
      return Number.parseInt(val);
    }
  }
  return val;
}

/** string轉成Decimal
 *  [參數] val:值，point:小數點幾位
 */
function stringToDecimal(val: any, point: number = 2) {
  if (!NullOrEmpty(val) || val == 0) {
    if (!isNaN(val)) {
      var f = Number.parseFloat(val);
      f = safeRound(f, point);
      var d = f.toFixed(point);
      return d;
    }
  }
  return val;
}

/** 四捨五入
 *  [參數] v:值，point:小數點幾位
 */
function safeRound(v, n) {
  if (v % 1 !== 0) {
    v = parseFloat(v.toPrecision(15));
  }
  var t = Math.pow(10, n);
  var nv = v * t;
  if (nv % 1 !== 0) {
    nv = parseFloat(nv.toPrecision(15));
  }
  return Math.round(nv) / t;
}


/** string處理特殊符號轉全形 例如&、<、>、' 單引、" 雙引*/
function replaceSpecialSymbols(val: any) {
  // if (val) {
  //   val=val.replaceAll("&","＆").replaceAll("<","＜").replaceAll(">","＞").replaceAll("'","＂").replaceAll('"','＂');
  // }
  // return val;
  return xmlFixSpecialChar(val, 'FULL');
}

/** 是否為獨立洗腎申報，判斷只有洗腎案件，沒有其他就是獨立，否則是混合 */
export function IsOnlyDialysisCase(h10: H10) {
  if (h10) {
    //洗腎案件件數 = 西醫件數小計 = 件數總計
    //洗腎案件申請點數 = 西醫申請點數小計 = 申請點數總計
    // if (h10.e10hn == h10.e10nn && h10.e10hn == h10.e10tn &&
    //     h10.e10ha == h10.e10na && h10.e10ha == h10.e10ta) {
    //西醫一般案件件數 無
    //西醫專案案件件數 無
    //洗腎案件件數 有
    //結核病件數 無
    if ((h10.e10n0 == '0' || h10.e10n0 == '') &&
        (h10.e10n2 == '0' || h10.e10n2 == '') &&
        (h10.e10hn != '0' && h10.e10hn != '') &&
        (h10.e10fn == '0' || h10.e10fn == '')) {
        return true;
    }
  }
  return false;
}

/** 判斷是否有洗腎案件 */
export function HasDialysisCase(h10: H10) {
  if (h10) {
    if (h10.e10hn != '0' && h10.e10hn != '') {
      return true;
    }
  }
  return false;
}
