import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, DefaultUrlSerializer, UrlSegmentGroup, UrlSegment } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import 'rxjs/add/observable/fromEvent';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { MainLayoutService } from './layout/services/main-layout.service';
import { AuthenticationService } from './security/services/authentication.service';
import { HotKeyService } from './services/hotkey-service';
import { CustomResult } from './shared/models/custom-result';
import { WebApiClient } from './services/web-api-client.service';
import { EasyNotificationService } from './services/easy-notification.service';
import { WebApiService } from './services/web-api.service';
import { UserRoleFunctionService } from './services/data-service/user-role-function.service';
import { filter, map } from 'rxjs/operators';
//import { BaseConfig } from './shared/services/base-config.service';


@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit {
  // api服務
  api: WebApiService;
  // 通知服務
  notification: EasyNotificationService;

  constructor(private route: ActivatedRoute, private router: Router,
    private mainLayoutService: MainLayoutService,
    private authenticationService: AuthenticationService,
    private titleService: Title,
    private hkService: HotKeyService,
    private webApiClient: WebApiClient,
    private notificationDI: EasyNotificationService,
    private userRoleFunctionService: UserRoleFunctionService,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Browser Title目前是固定不變
    this.titleService.setTitle('Cloud Clinics');
    this.api = this.webApiClient.createHisService('system/user');

    this.router.events.subscribe(e => {
      try {
        history.pushState(null, '', location.href);
      } catch { }
    })
    window.onpopstate = function () {
      history.go(1);
    };
    //卡一下過度縮放的上下限
    window.onresize = ()=>{
      var scale = window.devicePixelRatio>1.5? 1.5:window.devicePixelRatio<0.75?0.75:window.devicePixelRatio;
      this.zoom =scale/window.devicePixelRatio;
      document.body.style.zoom = this.zoom.toString();
    }
    var scale = window.devicePixelRatio>1.5? 1.5:window.devicePixelRatio<0.75?0.75:window.devicePixelRatio;
    this.zoom =scale/window.devicePixelRatio;
    document.body.style.zoom = this.zoom.toString();
  }
  zoom:number = 1;
  // dynamic load style
  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('link');
    style.id = styleName;
    style.rel = 'stylesheet';
    style.href = `${styleName}`;
    head.appendChild(style);
  }

  ngOnInit() {
    this.routerEventHandler();
  }


  @HostListener('window:beforeunload', ['$event'])
  winBeforeUnload($event) {
    // 為了安全，window.beforeunload時會把資料存到(覆蓋)localStorage
    // 詳見UserCache說明
    //UserCache.setLoginUserFromMemory();
  }

  private routerEventHandler() {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => {
        let pageTitle = this.generatePageTitle();
        let routeInfo = this.generateRouteInfo();
        return {
          pageTitle: pageTitle,
          url: routeInfo.url,
          data: routeInfo.data
        }
      })
      .subscribe((routeInfo) => {
        // this.mainLayoutService.setPageTitle(data);
        if (!routeInfo.data['ignoreActionAuth']) {
          this.getCurrentUserRoleFunctions(routeInfo.url);
        }

        this.mainLayoutService.showMenu(); // 預設show menu，每次都設不然某些畫面關閉後，這邊又要打開
      });
  }

  private async getCurrentUserRoleFunctions(path: string) {
    var result: CustomResult = await this.api.get(`GetCurrentUserRoleFunctions?path=${path}&functionCode=`, {}).toPromise();
    if (result.IsSuccess) {
      this.userRoleFunctionService.setUserRoleFunction(result.Object);
    }
    else
      this.notification.showError(result.Message);
  }


  private generatePageTitle(): string {
    const linkChar = '>';
    let pageTitle = '';
    let currentRoute = this.route;

    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      if (currentRoute.component === undefined
        && (currentRoute.data as any).value.title !== undefined) {
        pageTitle += ' ' + linkChar + ' ' + (currentRoute.data as any).value.title;
      }
    }
    pageTitle = pageTitle.trim();
    if (pageTitle.length > 0 && pageTitle.charAt(0) === linkChar) {
      pageTitle = pageTitle.slice(1, pageTitle.length);
    }
    return pageTitle;
  }

  private generateRouteInfo() {
    // 從根路由開始，遍歷到最深的活動子路由
    let currentRoute = this.route;
    let url = '';
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
      if (currentRoute.snapshot.url.length) {
        url += '/' + currentRoute.snapshot.url.map(segment => segment.path).join('/');
      }
    }

    return { url, data: currentRoute.snapshot.data };
  }

}

