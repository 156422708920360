<app-vis-dialog title="請確認" [show]="isDialogOpened" (close)="onDialogClose(false)" [minWidth]="250"
  [width]="400" i18n-title="@@DialogPleaseConfirm">
  <div style="padding: 10px 15px;" class="extend-msg">
    <ng-content></ng-content>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onDialogClose(true)" primary="true" i18n="@@DialogYes">是</button>
    <button type="button" (click)="onDialogClose(false)" i18n="@@DialogNo">否</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
