<div class="card-body no-padding watch-list" [style.padding]="'5px'" [style.width]="width">
  <div class="grid-title">{{title}}</div>
  <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true" tabindex="0"
    (pageChange)="pageChange($event)" [kendoGridSelectBy]="'Id'" [tableFullWidth]="'w-100'" [showStickyHead]="true"
    [showStickyHeight]="560" [class]="'no-border'" [customClass]="'overflow-x-hidden'" #reserveList>

    <app-vis-grid-column title="掛入" [width]="75" class="text-center">
      <ng-template let-dataItem>
        <div style="display: flex; flex-direction: column; gap: 3px;">
          <div style="display: flex; justify-content: center; gap: 3px;">
            <app-rect-button (click)="onBtnReadIC(dataItem)" [styleType]="'third-primary'" [iconCls]="'rotate-90'"
              [icon]="'sim_card'">IC</app-rect-button>
            <app-rect-button (click)="onBtnRegisterSelfPay(dataItem)" [styleType]="'assets'"
              [icon]="'how_to_reg'">自</app-rect-button>
          </div>
          <div style="display: flex; justify-content: center; gap: 3px;">
            <app-rect-button (click)="onBtnRegisterOweCard(dataItem)" [styleType]="'assets'"
              [icon]="'how_to_reg'">欠</app-rect-button>
            <app-rect-button (click)="onBtnAbnormalIC(dataItem)" [styleType]="'warning'"
              [icon]="'sim_card_alert'">異</app-rect-button>
          </div>
        </div>
      </ng-template>
    </app-vis-grid-column>


    <app-vis-grid-column [sortable]="true" field="PatientNo" [CellStyle]="{direction:'rtl'}" title="病歷號" [width]="75">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientName" title="病患姓名" [width]="70">
      <ng-template let-data>
        <div class="ellipsis-content" [ngClass]="data.PatientSex == '女' ? 'vis-color-purple' : ''"
          title="{{data.PatientName}}">
          {{data.PatientName}}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column field="PatientSex" title="性別" [width]="45" class="text-center custom-m-auto">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="PatientAge" title="年齡" [width]="40" class="text-center custom-m-auto">
      <ng-template let-data>
        <div class="ellipsis-content" title="{{data.PatientBirth| realage}}">
          {{data.PatientBirth| realage}}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="false" field="ReserveMatters" title="預約事項" [width]="80"
      i18n-title="@@ReserveMatters">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="EstimatedTime" title="估" [width]="35" class="text-center">
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="ReserveDateTime" title="時間" [width]="75" class="text-center">
      <ng-template let-data>
        <div class="ellipsis-content" [title]="data.ReserveDateTime | formatHourMinTime:':'">
          {{data.ReserveDateTime | formatHourMinTime:':' }}
        </div>
      </ng-template>
    </app-vis-grid-column>

    <app-vis-grid-column [sortable]="true" field="DoctorName" title="醫師" [width]="70"></app-vis-grid-column>
    <app-vis-grid-column [sortable]="false" field="PatientPhone" title="電話" [width]="70"></app-vis-grid-column>
  </app-vis-grid>
</div>

<!-- IC異掛視窗 -->
<app-vis-dialog title="異常就醫" [width]="1200" [height]="0" [show]="isOpenAbnormalIC" (close)="onAbnormalCloseDialog()"
  i18n-title="">
  <div class="inside-scrollbar">
    <app-abnormal-ic (onEmitResult)="onAbnormalICRet($event)" (statusChanged)="onAbnormalStatusChanged($event)"
      (closeDialog)="onAbnormalCloseDialog()"></app-abnormal-ic>
  </div>
</app-vis-dialog>
