export enum DecreeRxGroupEnum
{
  /** 戒菸 **/
  QuitSmoke = 1,
  /**疫苗?**/
  Vacine=2,
  /**專案輸注液*/
  專案輸注液 = 3,
  /**牙科牙位申報限制規則*/
  DentialRule = 4
}

export class decreeRx {
  StdCode: string;
  Group: DecreeRxGroupEnum;
  Type: number;
  DentalValidationRule?: string;
  BeginDate: Date;
  EndDate: Date;
}

export class DentalValidationRule {
  ToothPosLimited: string; 
  NeedToCheckToothPos: string;
  NeedToCheckQuantity: string;
  NeedToRemark: string;
  EndDate: Date;
}

export class dentalICD10CM {
  StdCode: string;
  DeclareCode: string;
  ICD10Code: string;
  EName: string;
  CName: string;
  Remark: string;
}

export class dentalICD10PCS {
  Id: number;
  StdCode: string;
  ICD10Code: string;
  EName: string;
  CName: string;
  Remark: string;
}
