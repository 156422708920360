import { Injectable } from "@angular/core";
import { ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { UserCache } from "src/app/services/user-cache";
import { WebApiClient } from "src/app/services/web-api-client.service";
import { WebApiService } from "src/app/services/web-api.service";
import { Clinic } from "../clinic/models/clinic";

@Injectable({
    providedIn: 'root'
  })
export class ReportApi{
    api:WebApiService;
    clinicApi:WebApiService
    /**
     *
     */
    constructor(private apiClient: WebApiClient) {
        this.api = apiClient.createHisService('system/report');
        this.clinicApi = apiClient.createHisService('system/clinic');
    }
    async getReportType(): Promise<ValueTextPairNumberValue[]>{
        return await this.api.get('GetReportType').toPromise();
    }
    async getClinics(): Promise<ValueTextPairNumberValue[]>{
        var ret = await this.clinicApi.get('GetAll').toPromise();
        var user = UserCache.getLoginUser();
        var clinicOpt: ValueTextPairNumberValue[] =  [{'text':user.CompanyName,value:0}].concat(
            ret.data.map(c=> {
                return {text: c.Name,value:c.Id}
            })
        );
          
        return clinicOpt;
    }
    async getReportTemplate(reportType:number, clinicId: number): Promise<ReportModel>{
        return await this.api.get(`GetTemplate?reportType=${reportType}&clinicId=${clinicId}`).toPromise();
    }
    async GetAvailableValueOption(reportType:number): Promise<any>{
        return await this.api.get(`GetAvailableValueOption?type=${reportType}`).toPromise();
    }
    async GetAvailableDataSource(reportType:number): Promise<any>{
        return await this.api.get(`GetAvailableDataSource?type=${reportType}`).toPromise();
    }
    async getPreview(report:ReportModel): Promise<string>{
        return await this.api.post('GetPreview',report).toPromise();
    }
    async SaveReport(report:ReportModel): Promise<boolean>{
        return await this.api.post('SaveReport',report).toPromise();
    }
}

export class ReportModel{
    Id:number;
    CompanyId: number;
    ClinicId: number;
    ReportType: number;
    ReportUrl: string;
    Template: string;
    IsAlive: boolean;

}

export enum ReportType{
    Regist = 1,
    Recipt = 2,
    Rx = 3,
    Hisotry = 4,
    Injection = 5,
    Lab = 6
  }