export class RegisterminiDto {
    SelfPay: number;
    Deposit: number;
    OwnFee: number;
    RegisterFee: number;
    nhiConsultConvert: number;
    OriHosp: string;
    OriDate: Date;
    BookingDate : Date;
    Duty: number;   //  1:櫃檯掛號掛入(報表顯示N)、2門診候診掛入(報表顯示T)

}
