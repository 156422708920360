import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DxApi } from 'src/app/services/api-service/dx/dx-api';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { LabPrescriptionAndHrxListQuery, LabPrescriptionAndHrxListOtherRangeQueryType, LabPrescriptionAndHrxListSpRule, LabPrescriptionAndHrxListRegType, LabPrescriptionAndHrxListPrintTarget, LabHRxListPageCollection, LabPrescriptionPageCollection } from 'src/app/services/api-service/hist/hist-api.model';
import { HspidApi } from 'src/app/services/api-service/hspid/hspid-api';
import { UserApi } from 'src/app/services/api-service/user/user-api';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';

@Component({
  selector: 'app-lab-prescription-and-hrx-list',
  templateUrl: './lab-prescription-and-hrx-list.component.html',
  styleUrls: ['./lab-prescription-and-hrx-list.component.css']
})
export class LabPrescriptionAndHrxListComponent implements OnInit {

  /**
   * 查詢物件
   *
   * @type {FormHelper<LabPrescriptionAndHrxListQuery>}
   * @memberof LabPrescriptionAndHrxListComponent
   */
  queryGroup: FormHelper<LabPrescriptionAndHrxListQuery>

  /**
   *範圍查詢
   *
   * @memberof LabPrescriptionAndHrxListComponent
   */
  otherRangeQueryType = LabPrescriptionAndHrxListOtherRangeQueryType;
  regType = LabPrescriptionAndHrxListRegType;
  printTarget = LabPrescriptionAndHrxListPrintTarget;

  isPreviewPrint: boolean = false;

  showType?: LabPrescriptionAndHrxListPrintTarget;
  labHRxListPageCollection?: LabHRxListPageCollection = null;
  labPrescriptionPageCollection?: LabPrescriptionPageCollection = null;

  doctorOption: ValueTextPair[];
  hspidOption: ValueTextPair[];
  sphcOption: ValueTextPair[];

  today: Date = new Date();

  spRuleOption: ValueTextPairNumberValue[] = [
    { value: LabPrescriptionAndHrxListSpRule.All, text: "全部" },
    { value: LabPrescriptionAndHrxListSpRule.Mark6, text: "6.檢驗外送代檢自行申報" },
    { value: LabPrescriptionAndHrxListSpRule.Mark7, text: "7.檢驗外送代檢代申報" }
  ];

  get rangeQueryName() {
    if (this.queryGroup && this.queryGroup.FormGroup && this.queryGroup.FormGroup.get("otherRangeQueryType")) {
      const otherAdvQueryType = this.queryGroup.FormGroup.get("otherRangeQueryType").value as LabPrescriptionAndHrxListOtherRangeQueryType;
      switch (otherAdvQueryType) {
        case LabPrescriptionAndHrxListOtherRangeQueryType.None:
          return "";
        case LabPrescriptionAndHrxListOtherRangeQueryType.PatientNo:
          return "病歷號";
        case LabPrescriptionAndHrxListOtherRangeQueryType.PatientPhone:
          return "電話";
        case LabPrescriptionAndHrxListOtherRangeQueryType.PatientBirthday:
          return "生日";
        case LabPrescriptionAndHrxListOtherRangeQueryType.PatientCId:
          return "身分證";
        case LabPrescriptionAndHrxListOtherRangeQueryType.PatientName:
          return "姓名";
      }
    }

    return "";
  }


  constructor(private formHelperService: FormHelperService,
    private userApi: UserApi,
    private hspidApi: HspidApi,
    private dxApi: DxApi,
    private histApi: HistApi,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.init();
  }

  makeQueryFormGroup() {
    const now = new Date();
    this.queryGroup = this.formHelperService.Create<LabPrescriptionAndHrxListQuery>().build({
      beginDate: [now, null],
      endDate: [now, null],
      otherRangeQueryType: [LabPrescriptionAndHrxListOtherRangeQueryType.None, null],
      otherRangeQueryBegin: ['', null],
      otherRangeQueryEnd: ['', null],
      doctorCode: ['', null],
      hispidHId: ['', null],
      sphcCode: ['', null],
      dontPrintLackCard: [false, null],
      spRule: [LabPrescriptionAndHrxListSpRule.All, null],
      regType: [LabPrescriptionAndHrxListRegType.All, null],
      printTarget: [LabPrescriptionAndHrxListPrintTarget.LabHrxList, null],
      pageSize: [50, null],
    });

    // 監聽 otherRangeQueryType 的變化
    this.queryGroup.FormGroup.get('otherRangeQueryType')?.valueChanges.subscribe(value => {
      this.queryGroup.FormGroup.get('otherRangeQueryBegin')?.setValue("");
      this.queryGroup.FormGroup.get('otherRangeQueryEnd')?.setValue("");
    });
  }



  async handlePrintForm() {
    this.labHRxListPageCollection = null;
    this.labPrescriptionPageCollection = null;
    this.changeDetectorRef.detectChanges();

    this.isPreviewPrint = true;


    if (this.queryGroup.Value.printTarget == LabPrescriptionAndHrxListPrintTarget.LabHrxList) {
      this.labHRxListPageCollection = await this.histApi.GenerateLabHrxListReport(this.queryGroup.Value);
      this.showType = LabPrescriptionAndHrxListPrintTarget.LabHrxList;

      console.log("handlePrintForm", this.labHRxListPageCollection);

    }
    else if (this.queryGroup.Value.printTarget == LabPrescriptionAndHrxListPrintTarget.LabPrescription) {
      this.labPrescriptionPageCollection = await this.histApi.GenerateLabPrescriptionReport(this.queryGroup.Value);
      this.showType = LabPrescriptionAndHrxListPrintTarget.LabPrescription;

      console.log("handlePrintForm", this.labPrescriptionPageCollection);
    }
  }

  isShowRangeQuery() {
    if (this.queryGroup && this.queryGroup.FormGroup && this.queryGroup.FormGroup.get("otherRangeQueryType")) {
      const otherRangeQueryType = this.queryGroup.FormGroup.get("otherRangeQueryType").value as LabPrescriptionAndHrxListOtherRangeQueryType;
      return otherRangeQueryType != LabPrescriptionAndHrxListOtherRangeQueryType.None;
    }

    return false;
  }

  labHRxListDialogClose() {
    this.isPreviewPrint = false;
    this.labHRxListPageCollection = null;
  }

  private async init() {
    this.makeQueryFormGroup();

    await this.initDoctorOption();
    await this.initHspidOption();
    await this.initsphcOption();
  }

  private async initDoctorOption() {
    const doctorList = await this.userApi.GetDoctorList();

    const doctors = doctorList.map(doc => {
      return {
        value: doc.extension,
        text: doc.text
      }
    });

    this.doctorOption = [{ value: "", text: "全部" }, ...doctors];
  }

  private async initHspidOption() {
    const hspidList = await this.hspidApi.GetAll();

    const hspids = hspidList.map(hspid => {
      return {
        value: hspid.HID,
        text: hspid.HNAME
      }
    })

    this.hspidOption = [{ value: "", text: "全部" }, ...hspids];
  }

  private async initsphcOption() {
    const sphcs = await this.dxApi.GetClinicSPHcOptions();
    this.sphcOption = [{ value: "", text: "全部" }, ...sphcs];
  }
}
