<div class="progress-container" [hidden]="!isUseTopLoader">
  <div [class]="classProgress()">
    <div [class]="classProgressBar()" >
    </div>
  </div>
</div>
<!--如果逾時等候的彈出跑出來，也先隱藏-->
<div [hidden]="!isUseCenterLoader || !isShowLoader || isWaitingConfirm" class="loader-wrapper">
  <div class="inner-align-center">
    <div class="loader-default"></div>
  </div>
</div>