<div style="display: flex;flex-direction: column;min-height: inherit;width: 100%;min-width:950px;" [ngStyle]="{height:cnhiEiiTestHeight}">
    <div matDialogTitle>
        <div style="width: calc(100% - 30px);">
            <span>醫療資料傳輸共通介面API測試</span>
        </div>
        <button type="button" tabindex="-1"
            style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
            id="easy-form-close-btn" class="easy-form-close-btn" mat-button (click)="close()">
            <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
        </button>
    </div>
    <div  style="flex-grow: 1; " class="pr-0 mr-0">
        <div class="inside-scrollbar" [ngStyle]="{height:cnhiEiiTestInsideHeight}">
            <div class="extend-msg" >
                <mat-button-toggle-group [(ngModel)]="apiType">
                    <mat-button-toggle [value]="'send'">資料上傳</mat-button-toggle>
                    <mat-button-toggle [value]="'download'">資料下載</mat-button-toggle>
                </mat-button-toggle-group>

                <div *ngIf="apiType=='send' && formSend" class="d-flex mt-3" style="column-gap: 20px;">
                    <div [formGroup]="formSend.FormGroup" class="api-container">
                        <div style="display: flex;">
                            <div style="width: 120px;">連接埠編號：</div>
                            <input type="number" [formControlName]="formSend.FieldName.portNo" />
                        </div>
                        <div style="display: flex;">
                            <div style="width: 120px;">完整檔案路徑：</div>
                            <input [formControlName]="formSend.FieldName.fileName" [title]="formSend.Value.fileName" />
                            <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height"  (click)="SendSelectFile()">選取</button>
                        </div>
                        <div style="display: flex;">
                            <div style="width: 120px;">上傳作業總類：</div>
                            <app-vis-combobox [formControlName]="formSend.FieldName.typeCode" [data]="SendTypeCodes"
                                [valueFormat]="'valuetext'"></app-vis-combobox>
                        </div>


                        <mat-divider style="margin: 10px;"></mat-divider>
                        <div>之作業辨識碼(執行結果)：</div>
                        <div style="display: block;">
                            <div>本機：{{formSend.Value.localId}}</div>
                            <div>IDC：{{formSend.Value.nhiId}}</div>
                        </div>
                        <div style="justify-content: center;">
                            <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height"  (click)="SendB()">執行</button>
                        </div>
                    </div>
                    <div  class="desc-container">
                        <div>醫療費用申報檔檔名格式說明：</div>
                        <div>(醫事機構代碼)(醫事類別)(費用年月)(申報類別)(月份註記)(中文碼).zip</div>
                        <table style="width: 100%;">
                            <thead>
                                <tr style="background-color: var(--vis-assets-blue-color);">
                                    <th class="text-center text-base" style="width: 120px;">欄位名稱</th>
                                    <th class="text-center text-base" style="width:60px">長度</th>
                                    <th class="text-center text-base">說明</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">醫事機構代碼</td>
                                    <td class="text-center">10碼</td>
                                    <td>需與讀卡機所屬之醫事機構代碼一致</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">醫事類別</td>
                                    <td class="text-center">2碼</td>
                                    <td>
                                        <div style="display: flex;flex-wrap: wrap;">
                                            <div *ngFor="let item of DownloadHospTypeCodes" style="width:25%">
                                                [{{item.value}}] {{item.text}}
                                            </div>
                                        </div> 
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">費用年月 </td>
                                    <td class="text-center">5碼</td>
                                    <td>民國年月﹙YYYMM﹚</td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">申報類別</td>
                                    <td class="text-center">1碼</td>
                                    <td>
                                        <div style="display: flex;flex-wrap: wrap;">
                                            <div *ngFor="let item of DeclareTypeCodes" style="width:25%">
                                                [{{item.value}}] {{item.text}}
                                            </div>
                                        </div> 
                                    </td>
                                        
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">月份註記</td>
                                    <td class="text-center">1碼</td>
                                    <td>
                                        <div style="display: flex;flex-wrap: wrap;">
                                            <div *ngFor="let item of MonthNoteTypeCodes" style="width:25%">
                                                [{{item.value}}] {{item.text}}
                                            </div>
                                        </div> 
                                    </td>
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">中文碼</td>
                                    <td class="text-center">1碼</td>
                                    <td>
                                        B-BIG5; E-EUC; C-CNS; N-通漢碼; 若為XML格式，必填「B」
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table style="width: 100%;font-size: 12px;text-align: center;">
                            <thead>
                                <tr style="background-color: var(--vis-assets-blue-color);">
                                    <th class="text-base" style="min-width:90px;">操作時間</th>
                                    <th class="text-base" style="width:40px;">種類</th>                                    
                                    <th class="text-base">檔名</th>
                                    <th class="text-base" style="width:90px;">LocalId</th>
                                    <th class="text-base" style="width:90px;">NHIID</th>
                                    <th class="text-base" style="width:75px;">機構代碼</th>
                                    <th class="text-base" style="width:100px;">醫事類別</th>
                                    <th class="text-base" style="width:40px;">年月</th>
                                    <th class="text-base" style="width:45px;">類別</th>
                                    <th class="text-base" style="width:45px;">月份</th>
                                    
                                </tr>
                            </thead>
                            <tbody >
                                <tr *ngFor="let item of send">
                                    <td>{{item.reqDt| formatDateTime}}</td>
                                    <td>{{item.typeCode}}</td>
                                    <td style="line-break: anywhere;">{{item.fileName}}</td>
                                    <td>{{item.localId}}</td>
                                    <td>{{item.nhiId}}</td>
                                    <ng-container *ngIf="item.hospId;else unknown">
                                        <td>{{item.hospId}}</td>
                                        <td>{{item.HOSP_DATA_TYPE}}</td>
                                        <td>{{item.FEE_YM}}</td>
                                        <td>{{item.APPL_TYPE}}</td>
                                        <td>{{item.Month_Note}}</td>
                                    </ng-container>
                                    <ng-template #unknown>
                                        <td colspan="5">無法解析檔名</td>
                                    </ng-template>
                                    
                                </tr>
                                <tr *ngIf="send.length==0">
                                    <td colspan="10">尚無資料</td>
                               </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


                <div *ngIf="apiType=='download'" style="display: flex;column-gap: 20px;">
                    <div [formGroup]="formDownload.FormGroup" class="api-container">

                        <div style="display: flex;">
                            <div style="width: 120px;">連接埠編號：</div>
                            <input type="number" [formControlName]="formDownload.FieldName.portNo" />
                        </div>
                        <div style="display: flex;">
                            <div style="width: 120px;">上傳作業總類：</div>
                            <app-vis-combobox [formControlName]="formDownload.FieldName.typeCode" [data]="DownloadTypeCodes"
                                [valueFormat]="'valuetext'"></app-vis-combobox>
                        </div>
                        <div>查詢條件：</div>
                        <div style="display: flex;">
                            <div style="width: 120px;">費用年月：</div>
                            <input [formControlName]="formDownload.FieldName.FEE_YM" placeholder="民國年月(YYYMM)">
                        </div>
                        <div style="display: flex;">
                            <div style="width: 120px;">申報類別</div>
                            <app-vis-combobox [formControlName]="formDownload.FieldName.APPL_TYPE" [data]="DeclareTypeCodes"
                                [valueFormat]="'valuetext'"></app-vis-combobox>
                        </div>

                        <div style="display: flex;">
                            <div style="width: 120px;">醫事類別</div>
                            <app-vis-combobox [formControlName]="formDownload.FieldName.HOSP_DATA_TYPE" [data]="DownloadHospTypeCodes"
                                [valueFormat]="'valuetext'"></app-vis-combobox>
                        </div>

                        <div style="display: flex;">
                            <div style="width: 120px;">申報日期</div>
                            <input [formControlName]="formDownload.FieldName.APPL_DATE" placeholder="民國年月(YYYMMDD)">
                        </div>
                        <mat-divider style="margin: 10px;"></mat-divider>
                        <div>之作業辨識碼(執行結果)：</div>
                        <div style="display: block;">
                            <div>本機：{{formDownload.Value.localId}}</div>
                            <div>IDC：{{formDownload.Value.nhiId}}</div>
                        </div>
                        <div style="justify-content: center;">
                            <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height" (click)="DownloadB()">執行</button>
                        </div>
                        <div>***共通平台收到下載申請檔後，由醫療系統進行處理，產製相
                            關檔案供下載，建議院所於確認上傳申請檔無誤後，至少
                            30 分鐘後再進行後續流程</div>
                    </div>
                    <div class="desc-container">
                        <div>檢核結果下載申請檔 檔名規範：</div>
                        <div style="margin-left: 20px;">(醫事機構代碼)-(系統日期)-(流水號).txt</div>


                        <div [formGroup]="formGet.FormGroup" >
                            <div style="display: flex;flex-grow: 1;">
                                <div style="width: 120px;">連接埠編號：</div>
                                <input type="number" [formControlName]="formGet.FieldName.portNo" />
                            </div>

                            <div style="display: flex;flex-grow: 1;">
                                <div style="width: 120px;">下載位置：</div>
                                <input [formControlName]="formGet.FieldName.downloadPath" [title]="formGet.Value.downloadPath" />
                                <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height" (click)="GetSelectFolder()">選取</button>
                            </div>
                        </div>

                        <mat-divider style="margin: 10px;"></mat-divider>
                        <table style="width: 100%;font-size: 12px;text-align: center;">
                            <thead>
                                <tr style="background-color: var(--vis-assets-blue-color);">
                                    <th class="text-base" style="width:40px;">種類</th>
                                    <th class="text-base" style="width:50px">年月</th>
                                    <th class="text-base" style="width:120px;">下載申請檔</th>
                                    <th class="text-base" style="width:90px;">操作時間</th>
                                    <th class="text-base">LocalId</th>
                                    <th class="text-base">NHIID</th>
                                    <th class="text-base">取得</th>
                                </tr>
                            </thead>
                            <tbody >
                                <tr *ngFor="let item of downloadRequest">
                                    <td>{{item.TypeCode}}</td>
                                    <td>{{item.DeclareMonth}}</td>
                                    <td>{{item.QueryFileName}}</td>
                                    <td>{{item.Timestamp}}</td>
                                    <td>{{item.sLocal_ID}}</td>
                                    <td>{{item.sNHI_ID}}</td>
                                    <td><button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height" (click)="GetB(item)">取得</button></td>
                                </tr>
                                <tr *ngIf="downloadRequest.length==0">
                                    <td colspan="7">尚無資料</td>
                               </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <!-- <div class="px-4 text-center">

                <button type="button" class="easy-form-btn sumbit vis-btn-primary mb-1 vis-btn-capsule mx-1"
                    (click)="close()">取消</button>

            </div> -->
        </div>
    </div>
