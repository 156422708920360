import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-vis-grid-row',
  templateUrl: './vis-grid-row.component.html',
  styleUrls: ['./vis-grid-row.component.css']
})
export class VisGridRowComponent implements OnInit {

  @ContentChild(TemplateRef)
  templateRef : TemplateRef<any>;
  constructor() { }

  ngOnInit(): void {
  }

}
