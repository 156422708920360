export class SystemCode {
  constructor(code: string, name: string) {
    this.ClinicId = 0;
    this.Code = code;
    this.Name = name;
    this.Name1 = '';
    this.Name2 = '';
    this.Attribute = '';
    this.Type = 0;
    this.TypeCode = '',
    this.Id = 0;  
    this.IsEnabled = false;
    this.SN = 0;
    this.checked = false;
  }
  public ClinicId: number;
  public Id: number;
  public Name: string;
  public Name1: string;
  public Name2: string;
  public Attribute: string;
  public Code: string;
  public Type: number;
  public TypeCode: string;
  public StandardCode: string;
  public Sort: number;
  public CanEditByClinic: boolean;
  public IsEnabled: boolean;
  public SN: number;
  public checked: boolean;

  static findNameByCode(codes: SystemCode[], code: string): string {
    if (!codes || !code || codes.length === 0 || code === '') {
      return null;
    }
    const r = codes.filter(x => x.Code === code);
    if (r && r.length > 0) {
      return r[0].Name;
    } else {
      return null;
    }
  }
}
