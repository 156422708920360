import { Injectable } from "@angular/core";
import { MainLayoutComponent } from "../layout/main-layout/main-layout.component";
import { PatientsDto } from "./api-service/patient/patientsDto";
import { PatientListDto } from "./api-service/patient/patient-api";
/** reload就沒了 */
@Injectable({
    providedIn: 'root'
})
export class PatientSelectService{
    mainLayoutCmp:MainLayoutComponent;
    
    constructor() {
    }
    public async SelectPatient():Promise<PatientListDto>{
        return await this.mainLayoutCmp.selectPatient();
    }
}