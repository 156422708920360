import { Injectable } from '@angular/core';
import { ObjectCacheService } from './icache-service';
import { ParamMap } from '../data-service/system-param-cache-define';
import { ParameterApi } from '../api-service/parameters/parameter-api';
import { EasyNotificationService } from '../easy-notification.service';

@Injectable({
  providedIn: 'root'
})

/**
 * 系統參數快取Service。
 * @implements {ICacheService<ParamType, Map<ParamType, any>>}
 */
export class ParameterProfileCachedService extends ObjectCacheService<ParamMap> {

  protected notifyMsgOnUpdated: string = '系統參數已變更…';
  protected notifyMsgOnReload: string = '已重載系統參數'
  /**
   * 建構子，初始化服務。
   * @param {ParameterApi} parameterApi - 用於獲取參數的 API 服務。
   * @param {EasyNotificationService} notifyService - 用於顯示通知的服務。
   */
  constructor(private parameterApi: ParameterApi, private notifyService: EasyNotificationService) {
    super(notifyService);
  }

  protected async getCacheObject<K extends keyof ParamMap>(keys: K[]): Promise<Map<K, ParamMap[K]>> {
    var m = new Map();
    for (let key of keys) {
      var o = await this.parameterApi.GetParams(key);
      m.set(key, o);
    }
    return m;
  }
}
