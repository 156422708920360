import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { MainLayoutComponent } from '../main-layout/main-layout.component';

@Injectable({
  providedIn: 'root'
})
export class MainLayoutService {

  mainlayoutComp:MainLayoutComponent;
  /** 啟用Loader mask的等候逾時 */
  EnableLoaderTimeout = true;
  constructor() { }
  public getMainHeight(){
    return this.mainlayoutComp?.mainContent?.nativeElement?.clientHeight??0;
  }

  private pageTitleSource = new BehaviorSubject<string>('');
  pageTitle$ = this.pageTitleSource.asObservable();

  private isShowMenuSubject = new BehaviorSubject<boolean>(true);
  isShowMenu$ = this.isShowMenuSubject.asObservable();

  private switchMenuSubject = new BehaviorSubject<boolean>(false);
  switchMenu$ = this.switchMenuSubject.asObservable();

  private isShowLoaderSubject = new BehaviorSubject<boolean>(false);
  isShowLoader$ = this.isShowLoaderSubject.asObservable();

  private isShowErrorSubject = new BehaviorSubject<any>(null);
  isShowError$ = this.isShowErrorSubject.asObservable();

  // 設定 page title
  setPageTitle(pageTitle: string) {
    this.pageTitleSource.next(pageTitle);
  }
  // show menu
  showMenu() {
    this.isShowMenuSubject.next(true);
  }
  // hide menu
  hideMenu() {
    this.isShowMenuSubject.next(false);
  }
  // switch menu
  switchMenu() {
    this.switchMenuSubject.next(true);
  }
  // show loader
  showLoader() {
    this.isShowLoaderSubject.next(true);
  }
  // show loader
  hideLoader() {
    this.isShowLoaderSubject.next(false);
  }
  showError(error: any) {
    this.isShowErrorSubject.next(error);
  }
}
