import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChildren, QueryList, ElementRef, ViewChild, HostListener, Output, Query, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { HistOrder } from '../models/hist-order';
import { HistService } from '../services/hist.service';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEvent } from '../models/helper-event';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { OrderData } from '../models/order-data';
import { OrderTab } from '../models/order-tab';
import { RxClass } from '../models/rx-class';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { HelperId } from '../models/helper-id';
import { OrderSpecialRuleDesc, OrderSpecialRuleEnum } from 'src/app/enums/OrderSpecialRuleEnum';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { MatMenuTrigger } from '@angular/material/menu';
import { HistFocusDirective } from '../directives/hist-focus.directive';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { AgeYearDiff, Distinct, Or, deepCopy } from 'src/app/shared/utilities';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { OrderTypeDesc, OrderTypeEnum } from 'src/app/enums/OrderTypeEnum';
import { HistApi, HrxInitResult } from 'src/app/services/api-service/hist/hist-api';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { NullOrEmpty } from 'src/app/shared/utilities';
import { UserCache } from 'src/app/services/user-cache';
import { RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

import { RxApi } from 'src/app/services/api-service/rx/rx-api';
import { MainSetApi } from 'src/app/services/api-service/rx/mainset-api';
import { HistHelperPadService, SetItemEvent } from '../services/hist-helper-pad.service';
import { HrxCalc } from '../models/hrx-calc';
import { OrderModel } from '../models/order-model';
import { homecareRx } from 'src/app/services/api-service/hist/homecareRx';
import { ValidateMsg } from '../models/hrx-validate-msg';
import { OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { DecreeRxGroupEnum } from 'src/app/services/api-service/hist/decreeRx';
import { RegisterConst } from 'src/app/shared/constants/register-const';
import { DebigNotifyService } from 'src/app/services/debug-notify.service';


@Component({
  selector: 'app-hist-order-grid',
  templateUrl: './order-grid.component.html',
  styleUrls: ['./order-grid.component.css', '../styles/hist.css']
})
export class OrderGridComponent implements OnInit, OnDestroy {

  @ViewChildren(MatMenuTrigger)
  menuBtns: QueryList<MatMenuTrigger>;
  @ViewChildren(MatMenuTrigger)
  menuTrigger: QueryList<MatMenuTrigger>;
  @ViewChild('odrTbElement', { static: true })
  odrTbElementRef: ElementRef;
  @ViewChild('data')
  batchInputEl: ElementRef<HTMLInputElement>;
  @ViewChild('rxDetail')
  rxDetail: TemplateRef<any>;
  @ViewChild(MatMenuTrigger)
  menuRemark: MatMenuTrigger;


  spRuleIdPrefix = HelperId.OdrGridMark;
  codeIdPrefix = HelperId.OdrGridCode;
  nameIdPrefix = HelperId.OdrGridName;
  qtyIdPrefix = HelperId.OdrGridQty; // enter quantity
  unitIdPrefix = HelperId.OdrGridUnit;
  freqIdPrefix = HelperId.OdrGridFreq;
  daysIdPrefix = HelperId.OdrGridDays;
  totalDoseIdPrefix = HelperId.OdrGridTotalDose;
  DoseIdPrefix = HelperId.OdrGridTotalDose;
  wayIdPrefix = HelperId.OdrGridWay;
  totalBoxIdPrefix = HelperId.OdrGridTotalBox;
  boxUnitIdPrefix = HelperId.OdrGridBoxUnit;
  dispensingIdPrefix = HelperId.OdrGridDispensing;
  remarkIdPrefix = HelperId.OdrGridRemark;
  medIdNamePrefix = HelperId.OdrGridMedIdName;
  sdatePrefix = HelperId.OdrGridSDate;
  stimePrefix = HelperId.OdrGridSTime;
  edatePrefix = HelperId.OdrGridEDate;
  etimePrefix = HelperId.OdrGridETime;
  calPricePrefix = HelperId.OdrGridCalPrice;

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private rxApi: RxApi,
    private mainsetApi:MainSetApi,
    private histApi: HistApi,
    private histService: HistService,
    private helperService: HistHelperService,
    private helperPadService:HistHelperPadService,
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private userConfirm: UserConfirmService,
    private formHelper: FormHelperService,
    private clinicData: ClinicDataService,
    private debug:DebigNotifyService) {
    this.makeFormGroup();
  }
  isHomeCare: boolean = true;
  notUseDispTP: boolean = false;
  //#region 參數 helper --------------------
  private unsubscribe: Subject<void> = new Subject();
  //#endregion 參數 helper --------------------

  areaId = HelperId.OrderArea;
  qtyRule: number = 1;
  showPrice: string;
  isSum: boolean = false;
  // 輸入資料:
  // setOrderData: 輸入資料 _tab & _orders
  // set editOption: 輸入編輯選項
  // 取回資料時:
  // get orders

  //#region 主要資料 --------------------
  _tab: OrderTab = new OrderTab();
  _orders: HistOrder[] = [];
  _dispOpts: ValueTextPair[];
  _remarkOpts: ValueTextPair[];


  @Input()
  isCopy: boolean = false;

  @Input()
  allergies: string[];
  @Input()
  hiddenWhenOpen: boolean = false;

  @Input()
  fillExecuteOnly: boolean = false;

  @Input()
  disabled: boolean = false;

  @Input()
  dragDisabled: boolean = false;
  @Input() isRecordDone : boolean = false;
  recordDone:boolean;
  @Input()
  set orderData(orderData: OrderData) {
    try {
      if (!orderData.tab || !orderData.orders || !orderData.tab.rxClass) {
        return;
      }

      // 重新設定資料時，該畫面資料要重製
      this.clear();

      // 設定資料
      this._tab = orderData.tab;
      this._orders = orderData.orders;
      if (this._orders && this._orders.length > 0) {
        this.qtyRule = this._orders[0].QtyRule;
      } else {
        this.qtyRule = Number(this.editOptions.histParams.DosageRule);
      }
      this.makeOrders_FormArray(this.numberOfOrder, this._tab);
      this.fillOrders_FormArray(this._orders, this._tab);
      // UI
      // 自動到最後一個空白Code
    } catch (e) {
      console.error(e);
      this.notification.showErrorById('MSG_HistOrders3');
    }
  }
  async setOrderDataAsync(orderData: OrderData){
    try {
      if (!orderData.tab || !orderData.orders || !orderData.tab.rxClass) {
        return;
      }

      // 重新設定資料時，該畫面資料要重製
      this.clear();

      // 設定資料
      this._tab = orderData.tab;
      this._orders = orderData.orders;
      if (this._orders && this._orders.length > 0) {
        this.qtyRule = this._orders[0].QtyRule;
      } else {
        this.qtyRule = Number(this.editOptions.histParams.DosageRule);
      }
      this.makeOrders_FormArray(this.numberOfOrder, this._tab);
      await this.fillOrders_FormArray(this._orders, this._tab);
      this.histService.UIReady('Rx');
      // UI
      // 自動到最後一個空白Code
    } catch (e) {
      console.error(e);
      this.notification.showErrorById('MSG_HistOrders3');
    }
  }
  refresh() {
    try {
      this.formHelpers.forEach(fh=>{
        var odr = this._orders.find(x=>x.RxCode == fh.Value.RxCode);
        if(odr && fh.Value.RxCode){
          fh.patchValue({
            SDate: odr.BeginDate,
            STime: DateHelper.getHourMinTime(odr.BeginDate),
            EDate: odr.EndDate,
            ETime: DateHelper.getHourMinTime(odr.EndDate),
            MedID: odr.MedID,
            MedIDName: odr.MedIDName,
            DispTP:odr.DispTP
          })
        }
      })
    } catch (e) {
      console.error(e);
      this.notification.showErrorById('MSG_HistOrders3');
    }
  }

  //#endregion 參數 source data --------------------

  //#region 參數 form and orders --------------------
  editFG: FormGroup = this.fb.group({});
  numberOfOrder = 60; // (最多)有幾筆醫囑
  // Default Grid

  //#endregion 參數 form and orders --------------------
  codeNotFoundKeyword = 'codeNotFound';
  bodyWidth = document.body.clientWidth;
  namePlusWidth: string = '';
  get editOptions() {
    return this.histService.EditOptions;
  }
  get enterLastColIdx() {
    if (!this.editOptions || !this.editOptions.histParams || !this.editOptions.histParams.EnterToNext) {
      return 2;
    }
    return this.editOptions.histParams.EnterToNext;
  }
  get editFV() {
    return this.editFG.value;
  }
  get orderArray() {
    return (this.editFG.get('orders') as FormArray);
  }
  // 所有可用dosage，計算dose時要用到，來自editOptions.dosage
  get dosages(): Dosage[] {
    return this.editOptions.dosage;
  }

  get isLab(): boolean {
    if (this._tab.rxClass === RxClass.Lab ||
      this._tab.rxClass === RxClass.XRay) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    this.isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    this.notUseDispTP = UserCache.getLoginUser().Clinic.NotUseDispTP;
    this._dispOpts = deepCopy<ValueTextPair[]>(this.editOptions.dispensing);
    var remarkOptSource = this.editOptions.remarks;
    this.filteredMedUser = this.editOptions.medUser.filter(x => x.value != null && x.value.length > 0);
    // 磨粉年齡
    if (/^[1-9A-F]{1}$/.test(this.editOptions.p253)) {
      var GDLimit = parseInt(this.editOptions.p253.charAt(0));
      if (isNaN(GDLimit)) {
        var GDLimit = this.editOptions.p253.charCodeAt(0) - 'A'.charCodeAt(0);
      }
      if (AgeYearDiff(this.histService.currentHist.Patient.Birthday, DateHelper.today) > GDLimit) {
        remarkOptSource = this.editOptions.remarks.filter(s => s.value !== 'GD');
      }
    }

    this._remarkOpts = remarkOptSource.map(v => {
      return { value: v.text, text: v.text };
    });
    this._remarkOpts.push({ value: '', text: '清除' });
    // 輔助輸入面板事件
    this.helperService.helperAction$.pipe(takeUntil(this.unsubscribe)).subscribe(
      async (evt: HelperEvent) => {
        if (!evt || !evt.source || !evt.selectedItem || evt.rowIndex < 0) {
          return;
        }
        if (evt.source === HelperSourceEnum.Order) {
          // 阻止blur進行額外檢查
          this.padClickSignal(true);
          await this.setCodeToRow(evt.rowIndex,evt.selectedItem);
        } else if (evt.source === HelperSourceEnum.Freq) {
          this.setFreqByPair(evt.rowIndex, evt.selectedItem);
        } else if (evt.source === HelperSourceEnum.Way) {
          this.setWayByPair(evt.rowIndex, evt.selectedItem);
        }
      }
    );
    this.spRulemenu = OrderSpecialRuleDesc.map(o => {
      //因為經過VisComboboxComponent.ts會組合成  value+"|"+text，且會reference OrderSpecialRuleDesc，因此判斷開頭value+"|"轉換成value+"."
      return { value: o.value, text: o.text.startsWith(o.value.toString() + "|") ? o.text.replace(o.value.toString() + "|", o.value.toString() + ".") : o.value.toString() + "." + o.text }
    });
    this.wayMenu = this.editOptions.way.map(w => w.Code)
    // 會在set orders時產生與填入FormGroup & FormArray，這邊就不再處理
    if (this.disabled) {
      this.editFG.controls.allDisabled?.disabled;
    }
    if (this.bodyWidth) {
      this.namePlusWidth = this.bodyWidth / 5 - 32 + "px";
    }
  }
  /** grid table width */
  tableWidth: string;



  /** 完診補空值 */
  completeOrder(){
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));
    for (let i = 0; i < ffhs.length; i++) {
      var fv = ffhs[i].Value;
      if(fv.RxCode){
        // 沒填完的直接清除 || 有填，但是改Code改一半(RxCode對不上)就觸發完診 也清除
        if(!fv.Rx || fv.RxCode!=fv.Rx.RxCode){
          this.clearRow(i);
        }else{
          var calc = this.createHrxCalc(fv);
          // 完診補值
          calc.completeFill();
          //更新回去
          ffhs[i].patchValue(calc.model);
        }
      }
    }
  }
  errTip(rowIndex:number, colType:string){
    if(this.showVaidateResult){
      var code = this.formHelpers[rowIndex].Value.RxCode;
      var validateResult = this.validateMsgs.get(rowIndex);
      if(validateResult){
        var er =  validateResult.filter(x=>x.colType==colType && x.rxCode == code);
        return er.map(x=>x.Message).join('\n');
      }
    }
    return '';
  }
  validateCls(rowIndex:number, colType:string){
    if(this.showVaidateResult){
      var code = this.formHelpers[rowIndex].Value.RxCode;
      var validateResult = this.validateMsgs.get(rowIndex);
      if(validateResult){
        var er =  validateResult.filter(x=>x.colType==colType && x.rxCode == code && x.Level=='ERROR');
        var warn =  validateResult.filter(x=>x.colType==colType && x.rxCode == code && x.Level=='WARNING');
        er.map(x=>x.Message).join('\n');
        return er.length>0?'validate-error':warn.length>0? 'validate-warning':'';
      }
    }
    return '';
  }
  showVaidateResult = false;
  validateMsgs: Map<number, ValidateMsg[]> = new Map();
  validateOrder():ValidateMsg[]{
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));
    var msgs: ValidateMsg[] = [];
    this.validateMsgs.clear();
    // 複製
    for (let i = 0; i < ffhs.length; i++) {
      var fv = ffhs[i].Value;
      if(fv.RxCode){
        var calc = this.createHrxCalc(fv);
        // 驗證
        var validateErr = calc.validate();
        //way在驗證錯誤訊息出現後要補值，如果流程可以省略則這行可移除...
        ffhs[i].patchValue(calc.model);
        msgs.push(...validateErr);
        this.validateMsgs.set(i,validateErr);
      }
    }
    msgs = Distinct(msgs,x=>x.Message);
    this.showVaidateResult = true;
    this.cd.detectChanges();
    return msgs;
  }
  /**
   * 增加一組async，原本的先不動。
   * @returns
   */
  async validateOrderExt():Promise<ValidateMsg[]>{
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));
    var msgs: ValidateMsg[] = [];
    this.validateMsgs.clear();
    // 複製
    for (let i = 0; i < ffhs.length; i++) {
      var fv = ffhs[i].Value;
      if(fv.RxCode){
        var calc = this.createHrxCalc(fv);
        // 驗證
        // var validateErr = calc.validate();
        var validateErr = await calc.validateExt();
        //way在驗證錯誤訊息出現後要補值，如果流程可以省略則這行可移除...
        ffhs[i].patchValue(calc.model);
        msgs.push(...validateErr);
        this.validateMsgs.set(i,validateErr);
      }
    }
    msgs = Distinct(msgs,x=>x.Message);
    this.showVaidateResult = true;
    this.cd.detectChanges();
    return msgs;
  }
  /** 完診時拉取醫令用 */
  fetchOrders(): HistOrder[] {
    this.completeOrder();

    const filtered = [];
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));
    console.log(`Fecth Order >>> N __RXCode__ QTY 天  i天  __頻率__ i頻 次 日 _總 __途勁__`)
    // 複製
    for (let i = 0; i < ffhs.length; i++) {
      let odr = new HistOrder();
      var fv = ffhs[i].Value;
      odr = Object.assign(odr, fv);
      // fv.SDate會是字串
      // stime etime 防呆
      fv.STime = fv.STime != null ? fv.STime : '';
      fv.ETime = fv.ETime != null ? fv.ETime : '';

      odr.BeginDate = fv.SDate ? new Date(new Date(fv.SDate).toLocaleDateString() + ' ' + fv.STime) : null;
      odr.EndDate = fv.EDate ? new Date(new Date(fv.EDate).toLocaleDateString() + ' ' + fv.ETime) : null;
      odr.SPRule = odr.SPRule?? OrderSpecialRuleEnum.A0_ApplyPay //拉值得時候 null 轉 0
      odr.DispTP = odr.DispTP?? OrderDispensingTypeEnum.T0_Clinic //拉值得時候 null 轉 '0'
      filtered.push(odr);
      var fixString = (x:any,fix:number)=> (x?.toString()??'').padStart(fix,' ');
      if(odr.RxCode){
        console.log(`Fecth Order >>> ${odr.SPRule} ${fixString(odr.RxCode,10)} `+
                    `${fixString(odr.QTY,3)} ${fixString(odr.Days,2)} ${fixString(odr.InfoDays,3)} `+
                    `${fixString(odr.Freq,8)} ${fixString(odr.FreqQTY,3)} `+
                    `${fixString(odr.Dose,2)} ${fixString(odr.DailyDose,2)} ${fixString(odr.TotalDose,3)} `+
                    `${fixString(odr.Way,8)}`)
      }
    }

    this._orders = HistOrder.autoFill(this.editOptions.histParams.AutoFill, filtered, this.editOptions.dosage);
    return this._orders;
  }
  /** 單純取得目前輸入內容 */
  fetchOrdersNoCheck(): HistOrder[] {

    const filtered = [];
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));

    // 複製
    for (let i = 0; i < ffhs.length; i++) {
      let odr = new HistOrder();
      var fv = ffhs[i].Value;
      odr = Object.assign(odr, fv);
      // fv.SDate會是字串
      // stime etime 防呆
      fv.STime = fv.STime != null ? fv.STime : '';
      fv.ETime = fv.ETime != null ? fv.ETime : '';

      odr.BeginDate = fv.SDate ? new Date(new Date(fv.SDate).toLocaleDateString() + ' ' + fv.STime) : null;
      odr.EndDate = fv.EDate ? new Date(new Date(fv.EDate).toLocaleDateString() + ' ' + fv.ETime) : null;
      odr.SPRule = odr.SPRule?? OrderSpecialRuleEnum.A0_ApplyPay //拉值得時候 null 轉 0
      odr.DispTP = odr.DispTP?? OrderDispensingTypeEnum.T0_Clinic //拉值得時候 null 轉 '0'
      filtered.push(odr);
    }
    return filtered;
  }
  //#region misc
  // 每個tab可用的OrderType不同
  getAllowedOrderTypes(): number[] {
    var rxTypes: number[] = [];
    rxTypes = Object.assign(rxTypes, this._tab.rxType);
    // var rxTypes = this._tab.rxType;
    rxTypes.push(0);

    return rxTypes;
  }
  //#endregion

  // #region value changes and calc --------------------

  /** c h s 成本計算 */
  computeShowPrice(){
    var c = 0;
    var h=0;
    var s = 0;
    var iCode = this.histService.currentHist.Register.ICode
    this.formHelpers.forEach(fh=>{
      var odr = fh.Value;
      var totalDose = !isNaN(odr.TotalDose) ? odr.TotalDose : 0;
      //健保價計算
      var hPrice = !isNaN(odr.IPrice) ? odr.IPrice : 0;
      let hRoundedNumber = Math.round(hPrice * totalDose);
      //自費價計算
      var sPrice = !isNaN(odr.Price) ? odr.Price : 0;
      let sRoundedNumber = Math.round(sPrice * totalDose);
      // 計算 c cost 成本; h 健保; s self 自費 cost 目前與健保價對齊 等庫存完備 以庫存為成本價計算
      c+=hRoundedNumber;
      // 按ICode==000(自費) 或者是N標記 (0/5/6計價, 2計自費價, 其餘不計價)
      if (iCode == '000' || odr.SPRule == 2){
        //無設定自費價則以健保價計入自費
        s+=sRoundedNumber || hRoundedNumber;
      }
      else if (odr.SPRule == null || odr.SPRule == 0 || odr.SPRule == 5 || odr.SPRule == 6){
        h+=hRoundedNumber;
      }
    });
    this.showPrice = `c ${c} h ${h} s ${s}`
    this.cd.detectChanges();
  }
  // 計算Dose相關欄位(新)
  // 日量法與總量法(只輸入日量和總量由系統協助算出總量和次量)
  calculateDose(index:number) {
    var fh = this.formHelpers[index];
    var odr = fh.Value
    if(!odr.RxCode){
      this.debug.Notify('計算量法時RxCode為空');
      return;
    }

    try{
      var hrxCalc = this.createHrxCalc(odr);
      hrxCalc.calcDose();

      // let price = !isNaN(odr.IPrice) ? odr.IPrice : 0;
      // let totalDose = !isNaN(hrxCalc.model.TotalDose) ? hrxCalc.model.TotalDose : 0;
      // let roundedNumber = Math.round(price * totalDose);
      // odr.CalPrice = roundedNumber;
      fh.patchValue(hrxCalc.model);
      this.computeShowPrice();
    }catch(e){
      this.debug.Notify('RegId='+this.histService.currentHist.Register.Id, odr,e);
    }
  }
  async calculateQty(index:number) {
    var fh = this.formHelpers[index];
    var odr = fh.Value
    if(!odr.RxCode){
      return;
    }
    var hrxCalc = this.createHrxCalc(odr);
    hrxCalc.calcQTY();

    // let price = !isNaN(odr.IPrice) ? odr.IPrice : 0;
    // let totalDose = !isNaN(hrxCalc.model.TotalDose) ? hrxCalc.model.TotalDose : 0;
    // let roundedNumber = Math.round(price * totalDose);
    // odr.CalPrice = roundedNumber;
    fh.patchValue(hrxCalc.model);
    this.computeShowPrice();
  }
  // #endregion value changes and calc --------------------

  // #region public function --------------------


  clear() {
    // 清畫面
    for(let i=0;i< this.formHelpers.length;i++){
      this.clearRow(i);
    }
    // 這個會清成每個欄位都null
    //this.orderArray.reset();
    this._orders = [];
    this.focusCode(0, false);
  }

  getFieldName(type: string): string {
    var ret = "";
    if (type == "QTY") {
      if (this.qtyRule == HistOrder.QtyRule_Dose) {
        ret = "次量";
      } else if (this.qtyRule == HistOrder.QtyRule_DailyDose) {
        ret = "日量";
      } else if (this.qtyRule == HistOrder.QtyRule_TotalDose) {
        ret = "總量";
      }
    } else {
      if (this.qtyRule == HistOrder.QtyRule_Dose || this.qtyRule == HistOrder.QtyRule_DailyDose) {
        ret = "總量";
      } else if (this.qtyRule == HistOrder.QtyRule_TotalDose) {
        ret = "次量";
      }
    }
    return ret;
  }
  // #endregion public function --------------------

  // #region form --------------------
  // 結構是 一個FormGroup裡面有一個FormArray，主要就是這個FormArray
  // 產生表單元件，並定義基本檢核規則，沒有填入資料
  makeFormGroup() {
    // 已經有就返回
    if (this.editFG.controls.orders) { return; }
    this.editFG = this.fb.group({
      orders: this.fb.array([]),
    });
  }

  // 產生FormArray，沒有填入資料
  // 若order數目超過預設最大數目，後須操作會錯誤
  makeOrders_FormArray(rowCount: number, tab: OrderTab) {
    if (this.editFG.controls.orders && (this.editFG.controls.orders as FormArray).length === rowCount) {
      // 如果已經有form array就不再產生
      return;
    } else {
      this.editFG.controls.orders = new FormArray([]);
      this.formHelpers = [];
      for (let i = 0; i < rowCount; i++) {
        const fh = this.makeOneOrder_FormGroup(tab);
        (this.editFG.controls.orders as FormArray).push(fh.FormGroup);
        this.formHelpers.push(fh);
      }
    }
  }

  formHelpers: FormHelper<OrderModel>[] = []
  // 產生單個Order元件，沒有填入資料
  makeOneOrder_FormGroup(tab: OrderTab): FormHelper<OrderModel> {
    var fh = this.formHelper.Create<OrderModel>().build({
      Id: [0, []],
      // 畫面顯示之欄位
      RxClass: [tab.rxClass, []],
      Sort: [0, []],
      DispTP: [null, []],
      SPRule: [null, []],
      RxId: [0, []],
      RxCode: ['', []],
      ProdName: ['', []],
      IPrice: [null, []],
      Price: [null, []],
      SyrupNote: ['', []],
      QtyRule: [this.qtyRule, []],  //[HistOrder.QtyRule_Dose, []],
      QTY: [null, []],
      Unit: ['', []],
      Dose: [null, []],
      DailyDose: [null, []],
      TotalDose: [null, []],
      Freq: [null, []],
      Way: [null, []],
      Days: [null, []],
      TotalBox: [null, []],
      BoxUnit: ['', []],
      Remark: ['', []],
      // 非畫面顯示之欄位
      StdCode: ['', []],
      FreqQTY: [null, []],
      InfoDays: [null, []],
      DrugsPerBox: [null, []],
      RxType: [null, []],
      ATC: ['', []],
      // 輔助欄位
      OrigOrderCode: ['', []], // OrderCode欄位focus時的值
      IsFromHelper: [false, []], // 資料是否來自Helper

      //SelfDispensing: [''],
      Additions: [{}, []],
      SDate: [new Date(this.histService.currentHist.Register.RegDate), []],
      EDate: [new Date(this.histService.currentHist.Register.RegDate), []],
      STime: ['', []],
      ETime: ['', []],
      MedID: [null, []],
      MedIDName: [null, []],
      FilterExecutor: [null, []],
      NeedExecutor: [false, []],
      NeedExecuteDate: [false, []],
      NeedExecuteTime: [false, []],
      RsCode: ['', []],
      CalPrice: [null, []],
      Rx:[null,[]],
      ChiFormCode:['',[]]
    });

    fh.Controls.SDate.disable();
    fh.Controls.EDate.disable();
    fh.Controls.STime.disable();
    fh.Controls.ETime.disable();
    // this.needSTime = false;
    // this.needETime = false;

    return fh;
  }

  // 填入表單資料，有重新產生FormArray
  async fillOrders_FormArray(orders: HistOrder[], tab: OrderTab) {
    if (!orders) { return; }
    var hrxInitDatas = await this.getHrxInitData(orders.map(x=>x.RxCode));
    for (let i = 0; i < this.orderArray.length; i++) {
      let odr: HistOrder = null;
      if (i < orders.length) {
        odr = orders[i];
        var initData = hrxInitDatas.find(x=>x.Rx.RxCode == odr.RxCode);
        this.fillOneOrder_FormGroup(i, odr, tab,initData);
      }else{
        this.clearRow(i);
      }
    }
    this.computeShowPrice()
    //setTimeout(() => {
    //this.cd.detectChanges();
    //}, 0);
    if (this.disabled) {
      if (this.editFG.controls.allDisabled) {
        this.editFG.controls.allDisabled.disabled;
      }
      if (this.editFG.controls.MedIDName) {
        this.editFG.controls.MedIDName.disabled;
      }
    }
  }

  // 將單筆Order填入FormGroup (批次填入流程的單筆處理)
  fillOneOrder_FormGroup(i: number, data: HistOrder, tab: OrderTab,initData:HrxInitResult) {
    var fh = this.getFormHelper(i);
    if (!fh) {
      return;
    }
    if (!data) {
      data = HistOrder.createEmpty();
      data.RxClass = tab.rxClass;
    }
    var hasBeginDate = !!data.BeginDate;
    var executeDate: boolean = hasBeginDate;
    var executeTime: boolean = hasBeginDate &&DateHelper.getHourMinTime(data.BeginDate) != '';

    //2333
    let price = !isNaN(data.IPrice) ? data.IPrice : 0;
    let priceSelf = !isNaN(data.Price)? data.Price : 0;
    price =  priceSelf > 0 && data.SPRule == 2 ? priceSelf :price;
    let totalDose = !isNaN(data.TotalDose) ? data.TotalDose : 0;
    let roundedNumber = Math.round(price * totalDose);
    data.CalPrice = roundedNumber;
    fh.patchValueNoEvent({
      Id: data.Id || 0,
      // 畫面顯示之欄位
      RxClass: data.RxClass || '',
      Sort: data.Sort || 0,
      DispTP: data.DispTP|| OrderDispensingTypeEnum.T0_Clinic,
      SPRule: data.SPRule == OrderSpecialRuleEnum.A0_ApplyPay ? null : data.SPRule,
      RxId: data.RxId || 0,
      RxCode: data.RxCode || '',
      ProdName: data.ProdName || '',
      IPrice: data.IPrice || 0,
      Price: data.Price || 0,
      SyrupNote: data.SyrupNote || '',
      QtyRule: data.QtyRule || null,
      Unit: data.Unit || '',
      QTY: data.QTY || null,
      Dose: data.Dose || null,
      DailyDose: data.DailyDose || null,
      TotalDose: data.TotalDose || null,
      Freq: data.Freq || null,
      Way: data.Way || null,
      Days: data.Days || null,
      TotalBox: data.TotalBox || null,
      BoxUnit: data.BoxUnit || '',
      Remark: data.Remark || '',
      // 非畫面顯示之欄位
      FreqQTY: data.FreqQTY || null,
      InfoDays: data.InfoDays || null,
      DrugsPerBox: data.DrugsPerBox || null,
      RxType: data.RxType || 0,
      ATC: data.ATC || '',
      OrigOrderCode: '',
      IsFromHelper: false,
      // UI
      //SelfDispensing: data.SelfDispensing || '',
      Additions: data.Additions || {},
      SDate: data.BeginDate,
      STime: DateHelper.getHourMinTime(data.BeginDate),
      EDate: data.EndDate,
      ETime: DateHelper.getHourMinTime(data.EndDate),
      MedID: data.MedID,
      MedIDName: null,
      // 從小時鐘併回來的會有FilterExecutor
      FilterExecutor: data.FilterExecutor || null,
      NeedExecutor: data.NeedExecutor || false,
      NeedExecuteDate: data.NeedExecuteDate || executeDate,
      NeedExecuteTime: data.NeedExecuteTime || executeTime,
      RsCode: data.RsCode,
      StdCode: data.StdCode || null,
      CalPrice: data.CalPrice || null,
      Rx: initData.Rx,//null
      ChiFormCode:data.ChiFormCode
    });
    // 沒有設定可選執行人員者的再判斷是否需要設定
    this.updateExecutorState(fh,initData.CommonSetting,!data.BeginDate);
  }
  // #endregion form --------------------

  // #region add/delete row --------------------
  // 找到最後一筆有資料的，且在rowIndex之後
  // 回傳-1代表沒有這樣的row
  findLastRowIndexAfter(rowIndex: number): number {
    let lastRowIndex = -1;
    for (let i = this.orderArray.controls.length - 1; i >= rowIndex; i--) {
      const fg = this.orderArray.controls[i] as FormGroup;
      if (fg.controls.RxCode.value) {
        lastRowIndex = i;
        break;
      }
    }
    return lastRowIndex;
  }

  findLastRowByRxType(rxType: number): OrderModel {
    for (let i = this.orderArray.controls.length - 1; i >= 0; i--) {
      const fh = this.getFormHelper(i);
      if (fh?.Controls.RxCode.value && fh?.Controls.RxType.value == rxType) {
        return fh.Value;
      }
    }
    return null;
  }

  insertRowAt(rowIndex: number) {
    // 若最後一筆有資料不可再插入
    const lastRow = this.orderArray.controls[this.orderArray.controls.length - 1] as FormGroup;
    if (lastRow.controls.RxCode.value && this.orderArray.controls.length == this.numberOfOrder) {
      this.notification.showErrorById('MSG_HistOrders4');
      return;
    }

    // 找到最後一筆有資料的，且在rowIndex之後
    const lastRowIndex = this.findLastRowIndexAfter(rowIndex);

    if (lastRowIndex === -1) {
      // rowIndex之後都沒資料，就不用插入了
      return;
    }

    // 搬資料，從最後一筆(有資料的)開始，到rowIndex為止
    for (let i = lastRowIndex; i >= rowIndex; i--) {
      const fg = this.orderArray.controls[i] as FormGroup;
      const nextFg = this.orderArray.controls[i + 1] as FormGroup;
      nextFg.patchValue(fg.value, { emitEvent: false });
    }

    // 自己這一筆則清空
    this.clearRow(rowIndex);

    this.cd.detectChanges();
  }
  /** 清除指定行, 下面往上移 */
  async deleteRowAt(rowIndex: number) {
    // 找到最後一筆有資料的，且在rowIndex之後
    const lastRowIndex = this.findLastRowIndexAfter(rowIndex);

    if (lastRowIndex === -1) {
      // rowIndex之後都沒資料，就只要刪自己
      this.clearRow(rowIndex);
      this.cd.detectChanges();
      return;
    }
    this.clearRow(rowIndex);

    this.formHelpers.splice(rowIndex,1);
    this.orderArray.removeAt(rowIndex);
    var fg = this.makeOneOrder_FormGroup(this._tab);
    this.orderArray.push(fg.FormGroup);;
    this.formHelpers.push(fg);
    this.computeShowPrice();
    this.cd.detectChanges();

    setTimeout(async () => {
      this.focusCode(rowIndex, false);
    }, 100);
  }
  // #endregion

  // #region code --------------------
  filteredMedUser?: ValueTextPair[] = [];
  /** 啟用執行時間控制，reset:是否已今天重置 */
  enableExecDate(fh:FormHelper<OrderModel>,reset:boolean){
    var now = new Date(this.histService.currentHist.Register.RegDate); // new Date();
    var isP醫令 =fh.Value.StdCode == 'P1015C' || fh.Value.StdCode == 'P1016C';
    fh.Controls.SDate.enable();
    fh.Controls.EDate.enable();
    fh.patchValue({
      NeedExecuteDate:true,
    });
    if(reset){
      if(isP醫令){
        fh.patchValue({
          SDate:null,
          EDate:null,
        })
      }else{
        var stTime = now;//new Date(this.histService.currentHist.Register.BeginTime);
        //耗時的欄位名稱借用DrugsPerBox
        var execMin = fh.Value.Rx.DrugsPerBox;
        var edTime = execMin?DateHelper.addMinute(stTime,execMin):stTime;
        fh.patchValue({
          SDate:DateHelper.getDate(stTime),
          EDate:DateHelper.getDate(edTime),
        })
      }
    }
  }
  disableExecDate(fh:FormHelper<OrderModel>){
    fh.Controls.SDate.disable();
    fh.Controls.EDate.disable();
    fh.patchValue({
      NeedExecuteDate:false,
      SDate:null,
      EDate:null,
    });
  }
  enableExecTime(fh:FormHelper<OrderModel>,reset:boolean){
    var isEmptyTime = (t)=>!(t != '0:0' && t != '00:00' && t != '');
    var isP醫令 =fh.Value.StdCode == 'P1015C' || fh.Value.StdCode == 'P1016C';
    fh.Controls.STime.enable();
    fh.Controls.ETime.enable();
    fh.patchValue({
      NeedExecuteTime:true
    });
    if(reset){
      if(this.isHomeCare||isP醫令){
        fh.patchValue({ STime:`${'00:00'}`,ETime:`${'00:00'}`});
      }else{
        var stTime = new Date(this.histService.currentHist.Register.RegDate);
        //耗時的欄位名稱借用DrugsPerBox
        var execMin = fh.Value.Rx.DrugsPerBox;
        var edTime = execMin?DateHelper.addMinute(stTime,execMin):stTime;
        if(isEmptyTime(fh.Value.STime)){
          fh.patchValue({ STime:`${stTime.getHours()}:${stTime.getMinutes()}`})
        }
        if(isEmptyTime(fh.Value.ETime)){
          fh.patchValue({ ETime:`${edTime.getHours()}:${edTime.getMinutes()}` })
        }
      }
    }
  }
  disableExecTime(fh:FormHelper<OrderModel>){
    fh.Controls.STime.disable();
    fh.Controls.ETime.disable();
    fh.patchValue({
      NeedExecuteTime:false,
      STime:'',
      ETime:''
    });
  }
  // 手動輸入病例醫令
  updateExecutorState(fh:FormHelper<OrderModel>,data:homecareRx,reset:boolean){
    if (data) {
      let positionGroup = data.PositionGroup;
      let needExecuteTime = data.NeedExecuteTime;
      //var rxCode = fh.Value.RxCode;
      if (positionGroup != null)
      {
        this.enableExecutor(fh,positionGroup,true);
      }else{
        this.disableExecutor(fh);
      }
      //有執行時間
      if(needExecuteTime!=null){
        this.enableExecDate(fh, reset);
        if (needExecuteTime == true) {
          this.enableExecTime(fh,reset);
        } else if(needExecuteTime == false) {
          this.disableExecTime(fh)
        }
      }
      else {
        this.disableExecDate(fh)
        this.disableExecTime(fh);
      }
    } else {   // 查不到 -> 全開但不給預設值 (JP 2023/07/18)
      if(![2,3,4].some(x=>x==fh.Value.RxType)){
        //設定成false不會觸發小時鐘，但是 MedId輸入的內容會在Blur被清空，結果上還是無法輸入
        //控制邏輯恐得再調整 -- 2024/3/29 cyy
        // fh.Controls.NeedExecutor.patchValue(false, { emitEvent: true });
        // this.filteredMedUser = this.editOptions.executor.filter(x => x.value != null && x.value.length > 0);
        // fh.Controls.FilterExecutor.patchValue(this.filteredMedUser, { emitEvent: false });
        // fh.Controls.MedIDName.enable();

        //可編輯
        this.enableExecutor(fh,99,false);
        this.enableExecDate(fh,false);
        this.enableExecTime(fh,false);
        //但flag為false 且清空
        fh.patchValue({
          MedID:'',
          NeedExecutor:false,
          NeedExecuteDate:false,
          NeedExecuteTime:false,
          SDate:null,
          EDate:null,
          STime:'',
          ETime:''
        })

      }else{
        this.disableExecutor(fh);
        this.disableExecDate(fh);
        this.disableExecTime(fh);
      }
    }
    if (this.disabled) fh.Controls.MedIDName.disable();
    this.cd.detectChanges();
  }

  enableExecutor(fh:FormHelper<OrderModel>, positionGroup:number,fillSelf:boolean){
    fh.Controls.MedIDName.enable();

    if (positionGroup == 99) {
      this.filteredMedUser = this.editOptions.executor.filter(x => x.value != null && x.value.length > 0);
      var enableUser = this.editOptions.enableExecutor.filter(x => x.value != null && x.value.length > 0);
    } else {
      this.filteredMedUser = this.editOptions.executor.filter(x => x.value != null && x.value.length > 0 && x.extension.includes(positionGroup.toString()));
       enableUser = this.editOptions.enableExecutor.filter(x => x.value != null && x.value.length > 0 && x.extension.includes(positionGroup.toString()));

    }

    fh.patchValue({
      NeedExecutor:true,
      FilterExecutor:enableUser
    })
    // 沒設定時 若自身為可執行人員便帶入
    if(!fh.Value.MedID && fillSelf){
      var cid = UserCache.getLoginUser().CId;
      var availableUser = this.filteredMedUser.find(x => x.value == cid)
      if (availableUser) {
        fh.Controls.MedID.setValue(availableUser.value);
      }
    }
    // 名字有缺的話補一下
    if(fh.Value.MedID && !fh.Value.MedIDName){
      var availableUser = this.filteredMedUser.find(x => x.value == fh.Value.MedID)
      if (availableUser) {
        fh.Controls.MedIDName.setValue(availableUser.text);
      }
    }
  }
  disableExecutor(fh:FormHelper<OrderModel>){
    fh.Controls.MedIDName.disable();
    fh.patchValue({
      NeedExecutor:false,
      FilterExecutor:[]
    })
  }

  getDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  getExecuteTimeSetting(rowIndex: number, type: number): boolean {
    var fh = this.getFormHelper(rowIndex);
    var retTime = fh.Controls.NeedExecuteTime.value;
    var retDate = fh.Controls.NeedExecuteDate.value;
    if (type == 2) {
      if (retTime) {
        fh.Controls.STime.enable();
        fh.Controls.ETime.enable();
        // this.needSTime = true;
        // this.needETime = true;
      } else {
        fh.Controls.STime.disable();
        fh.Controls.ETime.disable();
        // this.needSTime = false;
        // this.needETime = false;
      }
      return !retTime;
      // return false;  // 暫時先都打開 [disabled] = false
    } else {
      if (retDate) {
        fh.Controls.SDate.enable();
        fh.Controls.EDate.enable();
        // this.needSTime = true;
        // this.needETime = true;
      } else {
        fh.Controls.SDate.disable();
        fh.Controls.EDate.disable();
        // this.needSTime = false;
        // this.needETime = false;
      }
      return !retDate;
      // return false;  // 暫時先都打開 [disabled] = false
    }
  }

  checkDisabled(): boolean {
    return this.disabled||null;
  }

  inputDisabled(): boolean {
    if (this.disabled) {
      return true;
    } else {
      return null;
    }
  }

  getFilterExecutor(rowIndex: number): ValueTextPair[] {
    var fh = this.getFormHelper(rowIndex);
    return fh.Controls.FilterExecutor.value;
    // return this.editOptions.executor;
  }

  getNeedExecutor(rowIndex: number): boolean {
    var fh = this.getFormHelper(rowIndex);
    return fh.Controls.NeedExecutor.value;
  }

  // 重新設定Drug，如果缺少預設值，使用診所預設值
  getQtyRuleByTab(): number {
    return HistOrder.QtyRule_Dose;
  }

  /**
   * orders.component在收到輔助盤點選醫令事件後，會透過此方法來新增醫令
   * @param drug
   */
  async setCodeByDrugAtEmpty(drug: RxOpt,includes:string[],isInsert:boolean) {
    var rowIndex = this.findLastEmptyCodePosition();
    await this.setCodeToRow(rowIndex,drug,includes,isInsert);

  }
  createHrxCalc(rx:OrderModel){
    console.log('醫令相關參數： AutoFill=' + this.editOptions.histParams.AutoFill
                + ';沿用設定(p255)=' + this.editOptions.p255
                + ';進位規則(p135)=' + this.editOptions.p135
                + ';預設劑量=' + this.editOptions.histParams.DefaultDose
                + ';預設頻率=' + this.editOptions.histParams.DefaultFrequency
                + ';預設日分=' + this.editOptions.histParams.DefaultDays
                + ';預設途徑=' + this.editOptions.histParams.DefaultWay);
    return new HrxCalc(rx,rx.Rx,this.formHelpers.map(x=>x.Value),this.dosages,
            this.editOptions.histParams,this.editOptions.p255,
            this.editOptions.p135,
            this.editOptions.way,
            this.histService.currentHist.Patient,
            this.histService.currentHist.Register.RegDate,
            this.histApi,
            this.histService
          )
  }
  async batchSetCodeAtEmpy(rxCodes:HistOrder[]){
    var rowIndex = this.findLastEmptyCodePosition();
    var rxOpt = await this.clinicData.GetRxOption();
    var initDataRet = await this.getHrxInitData(rxCodes.map(x=>x.RxCode))
    var warningMsg: string[] = [];
    for(var copy of rxCodes){
      var rx = rxOpt.find(rx => rx.RxCode == copy.RxCode);
      if (rx) {
        var rx2 = Object.assign(new OrderModel(), rx);
        rx2.SPRule = copy.SPRule;
        rx2.QTY = copy.QTY;
        rx2.Dose = copy.Dose;
        rx2.DailyDose = copy.DailyDose;
        rx2.Days = copy.Days;
        rx2.Freq = copy.Freq;
        rx2.Way = copy.Way;
        rx2.TotalDose = copy.TotalDose;
        rx2.MedID = copy.MedID;
        rx2.DispTP = copy.DispTP;
        rx = Object.assign(new RxOpt(),rx2);
        var warning = await this.setCodeByOrder(rowIndex, rx, true,false,initDataRet.find(x=>x.Rx.RxCode==rx.RxCode));
        warningMsg = warningMsg.concat(warning);
        do{
          //展開遇到已經有東西的位置就跳過
          rowIndex++;
        }while(this.formHelpers[rowIndex].Value.RxCode)
      }
    }
    setTimeout(() => {
    this.computeShowPrice();
    }, 0);
    await this.showWarning(warningMsg);
  }
  /** 將指定醫令或套餐設定到指定行號，套餐展開會往下方找空行 */
  async setCodeToRow(idx:number, item:RxOpt, includes:string[]=['CC','PE','DX','RX'], isInsert:boolean=true) {
    // to check the item is RxSet or not?
    if (item.RsCode != null) {
      var rsOpt = await this.clinicData.GetRsOption();
      var rxOpt = await this.clinicData.GetRxOption();
      var rss = rsOpt.filter(x => x.RsCode == item.RsCode);
      // console.log('rxOpt',rxOpt,rss)
      if(rss.length>0){
        // 用第一筆的套餐Id撈資料
        var mainset = await this.mainsetApi.Get(rss[0].MainSetId);
        var rowIndex = idx;
        if(includes.indexOf('RX')>=0){
          // 套餐批次取得
          var initDataRet = await this.getHrxInitData(rss.map(x=>x.RxCode))
          var warningMsg:string[] =[];
          for(var i of rss){
            var rx = rxOpt.find(rx => rx.RxCode == i.RxCode);
            if (rx) {
              var set = mainset.Details.find(rx=>rx.RxId==i.RxId);
              if(set){
                var rx2 = Object.assign(new OrderModel(), rx);
                rx2.SPRule = set.SpecialRule;
                rx2.DailyDose = set.DailyDose;
                rx2.Days = set.Days;
                rx2.Dose = set.Dose;
                rx2.Freq = set.Frequency;
                rx2.TotalDose = set.TotalDose;
                rx2.Way = set.Way;
                rx2.DispTP = set.DispTP;
                rx = Object.assign(new RxOpt(),rx2);
              }
              var warning = await this.setCodeByOrder(rowIndex, rx, true,false,initDataRet.find(x=>x.Rx.RxCode==rx.RxCode));
              warningMsg = warningMsg.concat(warning);
              do{
                //展開遇到已經有東西的位置就跳過
                rowIndex++;
              }while(this.formHelpers[rowIndex].Value.RxCode)
            }
          }
          this.showWarning(warningMsg);
        }
        if(includes.indexOf('CC')>=0){
          // 設定主訴
          this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.CC,{Name:mainset.CC},isInsert,null));
        }
        if(includes.indexOf('PE')>=0){
          // 設定理學
          this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.PE,{Name:mainset.PE},isInsert,null));
        }
        if(includes.indexOf('DX')>=0){
          // 設定診斷
          var dxs = [mainset.Dx1,mainset.Dx2,mainset.Dx3,mainset.Dx4,mainset.Dx5,mainset.Dx6].filter(x=>x);
          var addDxCode = [];
          for(let dx of dxs){
            var dxopt = (await this.histService.getDiagList(dx)).find(x=>x.Code==dx)
            if(dxopt){
              addDxCode.push(dxopt);
              //this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.Diag,dxopt,isInsert,null));
            }
          }
          this.helperPadService.SetItem(new SetItemEvent(HelperSourceEnum.Diag,addDxCode,isInsert,null));
        }
        // 沒展出醫令(無醫令套餐)
        if(rowIndex == idx){
          this.deleteRowAt(rowIndex);
          //this.clearRow(rowIndex);
        }
        this.focusCode(idx,false);
      }
    } else {
      var warning = await this.setCodeByOrder(idx, item, true);
      await this.showWarning(warning);
    }
    this.computeShowPrice();
  }

  hrxInitResultsTemp:HrxInitResult[] = [];
  async getHrxInitData(codes:string[]){
    // 不在temp裡的再抓
    codes = codes.filter(c=>!this.hrxInitResultsTemp.map(x=>x.Rx.RxCode).some(x=>x==c))
    let psa = this.histService.currentHist.Patient.PaySysA;
    let dateQuery = this.histService.currentHist.Register.RegDate;
    if(codes.length>0){
      // 初始資料
      var initDataRet = await this.histApi.GetHrxInit({
        codes: codes,
        types: this.getAllowedOrderTypes(),
        dateQuery:dateQuery,
        paySysA:psa,
        checkStock:this.editOptions?.histParams?.IsAlertBelowSafeStock??false
      });
      this.hrxInitResultsTemp = this.hrxInitResultsTemp.concat(...initDataRet);
    }

    return this.hrxInitResultsTemp;
  }
  async showWarning(warningMsg:string[]){
    if(warningMsg.length>0){
      await this.userConfirm.showAlert('提示',warningMsg.join('\n'));
    }
  }
  // 把Drug資料設定到FormGroup
  // 注意預設是次劑量法
   async setCodeByOrder(rowIndex: number, order: RxOpt, isFromHelper: boolean, focus:boolean=true, initData:HrxInitResult = null):Promise<string[]> {
    if(!initData){
      var initDataRet = await this.getHrxInitData([order.RxCode]);
      initData = initDataRet.find(x=>x.Rx.RxCode == order.RxCode)
    }
    // 取得完整RX
    var rxFull = initData?.Rx;
    //var rxFull = await this.histApi.getOrderByCode(order.RxCode, this.getAllowedOrderTypes())
    if (rxFull == null) {
      this.codeNotFound(rowIndex);
      return [];
    }
    var warningMsg:string[] = [];
    if (!this.codeIsChecked) {
      // 是否有替代藥? 有替代藥 -> 帶入替代藥 Rx
      if (order.AlternativeRxId) {
        //var altRx = await this.rxApi.GetAlternativeRx(order.AlternativeRxId);
        var altRx = initData.AlternativeRx;// await this.rxApi.GetAlternativeRx(order.AlternativeRxId);
        var alrData: RxOpt = new RxOpt();
        alrData.Id = altRx.Id;
        alrData.IsByOral = altRx.IsByOral;
        alrData.RxCode = altRx.RxCode;
        alrData.ProdName = altRx.ProdName;
        alrData.Type = altRx.Type;
        alrData.StdCode = altRx.StdCode;
        alrData.MainSetId = altRx.MainSetId;
        alrData.RsCode = altRx.RsCode;
        alrData.IsDrugStatus = altRx.IsDrugStatus;
        alrData.AlternativeRxId = null;
        warningMsg.push('輸入的醫令 [' + order.RxCode + '] 有替代藥: ' + altRx.RxCode);
        //this.notification.showWarning('輸入的醫令[' + order.RxCode + ']有替代藥:' + altRx.RxCode, false);
        order = alrData;
        // initData換成替代藥
        var newInitDataRet = await this.getHrxInitData([order.RxCode]);
        initData = newInitDataRet.find(x => x.Rx.RxCode == order.RxCode);
        rxFull = initData?.Rx;
      }
    }
    // 檢查是否為停用藥?
    if (order.IsDrugStatus) {
      var stopDate = order.DrugStopDate ?? '';
      var ret = await this.userConfirm.showConfirm({
        title: '請確認以下訊息',
        msg: order.RxCode + '藥品設定為停止使用' + (stopDate == '' ? '' : '(' + DateHelper.getFormatedDateString(stopDate) + ')') + '\n\n是否仍要使用？',
        textYes: '是',
        textNo: '否',
        width: 300
      });
      if (!ret) {
        this.deleteRowAt(rowIndex);
        order = new RxOpt();
        return [];
      }
    }

    // RX設定進去的時候再檢查過敏藥是否要阻擋填值
    var skipAllergy = await this.confirmAllergy(order.RxCode)
    if (skipAllergy == false) {
      this.helperService.setWindowOpened(false);
      this.deleteRowAt(rowIndex);
      return warningMsg;
    }
    // 檢查是否已輸入同樣的醫令
    if (this.hasSameCode(order.RxCode, rowIndex)) {
      // this.deleteRowAt(rowIndex);
      warningMsg.push('已有相同醫令 [' + order.RxCode + '] ，請確認!');
      //await this.userConfirm.showAlert('提示', '已有相同醫令，請確認!');
      var referralRx = ['01037C', '01036C', '01038C', '01037', '01036', '01038'];
      if (referralRx.includes(order.RxCode)) {

        this.deleteRowAt(rowIndex);
        return warningMsg;
      }
    }
    // 確定藥品庫存
    if (this.editOptions?.histParams?.IsAlertBelowSafeStock ) {
      if(initData.TotalInventory < initData.SafeStock){
        warningMsg.push(`${order.RxCode} 的庫存量(${initData.TotalInventory}) 偏低(<${initData.SafeStock})`);

        //this.notification.showWarning(`${order.RxCode} 的庫存量(${initData.TotalInventory}) 偏低(<${initData.SafeStock})`)
      }
    }

    try {
      //var defaultDisp = this.defaultDispTP(order);
      var fh = this.getFormHelper(rowIndex);
      // RxOpt作為Model基礎
      var rx = Object.assign(Object.assign({},fh.Value), order);
      // Object.assign 會將 rxId 代入,但醫令欄位的Id 應該要以hrx為主 若為新增設為0
      rx.Id = fh.Value?.Id ?? 0;

      rx.Rx = rxFull;
      // 從RX複製其他完整內容
      rx.StdCode = rxFull.StdCode;
      rx.RxCode= rxFull.RxCode;
      rx.RsCode= rxFull.RsCode;
      rx.IPrice= rxFull.IPrice;
      rx.Price= rxFull.Price;
      rx.SyrupNote= rxFull.SyrupNote;
      rx.QtyRule= this.qtyRule,  // this.getQtyRuleByTab();
      rx.Unit= rxFull.UseUnit;
      rx.BoxUnit= rxFull.BoxUnit||rxFull.UseUnit;
      rx.DrugsPerBox= rxFull.DrugsPerBox;
      rx.RxType= rxFull.Type;
      rx.ATC= rxFull.ATC;
      rx.IsFromHelper= isFromHelper;
      rx.RxId= rxFull.Id;
      // 追加產品名
      if (rxFull.Spec &&  rx.RxType == OrderTypeEnum.T2_OralDrugFee) {
        rx.ProdName = rxFull.ProdName + ' ' + rxFull.Spec;
      }

      if(initData.Decree?.Group== DecreeRxGroupEnum.專案輸注液){
        if(this.histService.currentHist.Register.SameTreat == RegisterConst.SameTreat_0_洗腎){
          rx.ChiFormCode = 'G';
        }else{
          var r = await this.userConfirm.showConfirm({
            title:'請確認以下訊息',
            msg:'醫令有開立健保署專案輸注液，\n'+
                '此輸注液是否為手術/處置內含項目(申報不計價)？\n\n'+
                '【是】輸注液不計價(醫令類別G)'+
                '【否】輸注液計價',
            textYes:'是',
            textNo:'否'
          });
          if(r){
            rx.ChiFormCode = 'G';
          }

        }
      }

      //初始化劑量
      var hrxCalc = this.createHrxCalc(rx);
      hrxCalc.initDose();

      // 按規則處理完，沒有調劑方式才使用預設值補上
      //rx.DispTP ||= defaultDisp;
      console.log('新增醫令',rx);
      fh.patchValue(rx);
      var el = document.getElementById(this.dispensingIdPrefix + rowIndex) as HTMLInputElement;
      if (rx.DispTP == '0' && el != null){
        el.value = '';
      }

      this.updateExecutorState(fh,initData.CommonSetting,true);
      // 如果來自Helper把focus設在OrderCode
      if (isFromHelper  && focus) {
        this.focusCode(rowIndex, false);
      }
      fh.Controls.RxCode.markAsPending();

      this.cd.detectChanges();

    } catch (e) {
      console.error(e);
    }
    return warningMsg;
  }

  dispDisable(order:OrderModel){
    // if(this.notUseDispTP){
    //   return true;
    // }
    var type = order.RxType;
    var codeMap = this.histService.dispCodeMap.find(x=>x.types.some(y=>y==type))
    return !codeMap?true:null;
  }
  onDispMenuOpen(order:OrderModel){
    var o: RxOpt = Object.assign(new RxOpt(), {
      Type:order.RxType,
      SPRule:order.SPRule
    });
    this.defaultDispTP(o);
  }

  defaultDispTP(order: RxOpt) {
    var availableDisp = [];
    var rxTyep = order.Type;

    var defDispOpts: ValueTextPair[] = [];
    var codeMap = this.histService.dispCodeMap.find(x=>x.types.some(y=>y==rxTyep))
    if(codeMap){
      availableDisp  = codeMap.dispCode;
    }
    defDispOpts = this.editOptions.dispensing.filter(x=> availableDisp.some(y=>y==x.value));
    this._dispOpts = defDispOpts;
  }

  onSPRuleSelect(order: FormGroup, item: ValueTextPairNumberValue, id: string, rowIndex: number) {
    var el = document.getElementById(this.spRuleIdPrefix + rowIndex) as HTMLInputElement;
    var newRemark = this.onSPRuleChangeRemark(order,item);
    var val = item.value == OrderSpecialRuleEnum.A0_ApplyPay ? null : item.value;
    order.patchValue({ SPRule: val, Remark: newRemark });
    //el.value = val;
    // order.patchValue({ SPRule: item.value, Remark: newRemark });
    // el.value = item.value == 0 ? '' : item.value.toString();
    this.onSPRuleChenge(rowIndex,order);
    setTimeout(() => {
      document.getElementById(id).focus();
    }, 0);
  }

  onSPRuleKeyDown(event: KeyboardEvent, order: FormGroup, id: string, rowIndex: number) {
    if (['Backspace', 'Delete'].includes(event.key)) {
      return true;
    }
    var key = event.key;
    var el = (event.target as HTMLInputElement);

    if (key == 'Tab') {
      const elemId = this.codeIdPrefix + rowIndex;
      document.getElementById(elemId).focus();
    } else {
      var opt = this.spRulemenu.find(opt => opt.value.toString() == key)
      if (order.get('RxCode').value && opt) {
        var newRemark = this.onSPRuleChangeRemark(order,opt);
        order.patchValue({ SPRule: opt.value ,Remark: newRemark});
        el.value = opt.value == 0 ? '' : opt.value.toString();
        //spr
        return false;
      } else {
        if (key == ' ') {
          var btn = this.menuTrigger.find(m => m.menuData == id);
          // 中文輸入法下不等的話會沒辦法focuse進去
          setTimeout(() => {
            btn.openMenu();
          }, 0);
        }
        return false;
      }
    }
  }

  /** N欄位標記改變備註 2=自費，3=贈送 */
  onSPRuleChangeRemark(order: FormGroup, item: ValueTextPairNumberValue){

    var keepOrder = order.getRawValue();
    var keepRemark:string = keepOrder.Remark == undefined || keepOrder.Remark == null ? '' : keepOrder.Remark;
    var newRemark = '';
    if (item.value != null) {
      keepRemark=keepRemark.replace('[自費]','').replace('[贈送]','');
      if (item.value == OrderSpecialRuleEnum.A2_SelfPayment) {
        newRemark = '[自費]' +  keepRemark;
      } else if (item.value == OrderSpecialRuleEnum.A3_FreeAndPrint) {
        newRemark = '[贈送]' + keepRemark;
      } else {
        newRemark = keepRemark;
      }
    }
    this.computeShowPrice();
    return newRemark;
  }

  onCodeKeyUp(event, rowIndex: number) {
    const elemId: string = this.getCodeElemId(rowIndex);
    var rxTypes: number[] = [];
    rxTypes = Object.assign(rxTypes, this._tab.rxType);
    // rxTypes = this._tab.rxType;
    rxTypes.push(0);

    this.helperService.keyupForInput(elemId, event.target.value, event, rowIndex, rxTypes);

    const codeElem = this.getCodeElem(rowIndex) as HTMLInputElement;
    // 沒有code value就清除
    if (!codeElem.value) {
      this.clearRow(rowIndex);
      return;
    }
  }

  onCodeKeyDown(event: KeyboardEvent, idx: number) {
    // 按下Escape清空，helper重設
    if (event.key === 'Escape') {
      this.helperService.reset();
      this.clearRow(idx);
      event.preventDefault();
    } else if (event.key === 'Insert') {
      // 插入一行
      this.insertRowAt(idx);
      event.preventDefault();
    } else if (event.key === 'Delete') {
      // 刪除一行
      this.deleteRowAt(idx);
      // 因為已經由程式刪除一行資料，必須阻止原本的delete動作 => 刪除所選文字，不然下一行的內容被移上來時，可能被刪掉
      event.preventDefault();
    } else if (event.key === 'Tab') {
      const elemId = this.qtyIdPrefix + idx;
      document.getElementById(elemId).focus();
    } else {
      if (event.key === 'Enter' || event.key === 'F2') {  // F2為完診的快速鍵，在此處等同按下Enter並進入完診批價畫面
        this.onCodeBlur(event, idx);
      }
      this.helperService.keydownForInput(event);
    }
  }

  onClickAtName(idx: number) {
    this.focusCode(idx, false);
  }

  onClickHiddenWhenOpen(val: boolean) {
    this.isSum = val;
  }

  onQtyKeyDown(event: KeyboardEvent, idx: number) {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const elemId = this.freqIdPrefix + idx;
      document.getElementById(elemId).focus();
      setTimeout(() => {
        this.calculateDose(idx);
      }, 0);
    }
  }

  onQtyInput(event: KeyboardEvent,rowIndex: number) {
    console.log('onQtyInput value:', (event.target as HTMLInputElement) .value, 'formValue: ',this.formHelpers[rowIndex].Value.QTY )
  }

  onQtyChenge(event: KeyboardEvent,rowIndex: number) {
    console.log('onQtyChenge value', (event.target as HTMLInputElement) .value, 'formValue: ',this.formHelpers[rowIndex].Value.QTY )
    var skip = ['Escape','Tab','ArrowUp','ArrowDown' ,'ArrowLeft' ,'ArrowRight']
    if (skip.indexOf(event.code)>=0) {
      //skip
    } else {
      this.calculateDose(rowIndex);
    }
  }

  onSPRuleChenge(rowIndex: number,order: FormGroup) {
    //N標改變應該不需重算劑量吧= =?
    //應該不用 當初有用是劑量跟金額CHS計算擺在一起
    //this.calculateDose(rowIndex);
    var keepOrder = order.getRawValue();
    // console.log('keepOrder',keepOrder?.RxType)
    if (keepOrder?.RxType == 6 || keepOrder?.RxType == 7 ) {
      let disp = keepOrder.DispTP;
      if(keepOrder.SPRule == 6 ) disp = "4"
      else if (keepOrder.SPRule == 7 ) disp = "4"
      else if ((keepOrder.SPRule == 0 || keepOrder.SPRule ==1 || keepOrder.SPRule == 5)  && disp == "4") disp = "0"
      order.patchValue({ DispTP: disp});
    }
    var hrxCalc = this.createHrxCalc(keepOrder);
    hrxCalc.calcPrice();
    order.patchValue({ CalPrice: keepOrder.CalPrice});
    this.computeShowPrice();
  }

  onCodeFocus(event, rowIndex: number) {
    this.setOrigOrderCodeAsOrderCode(rowIndex);
    event.target.select();
  }

  private isNeedCheckCode(rowIndex: number): boolean {
    // 必須檢查時再抓code資料是因為，code可能是由helper選取然後填到欄位內，而非當下input內容，有時間差
    const rxCode = this.getCodeValue(rowIndex);

    var fh = this.getFormHelper(rowIndex);
    const origCode = fh.Value.OrigOrderCode;
    const isFromHelper = fh.Value.IsFromHelper;
    //const origCode = this.getControlValue('OrigOrderCode', rowIndex);
    //const isFromHelper = this.getControlValue('IsFromHelper', rowIndex);
    // (1)若資料來自Helper，不檢查 => isFromHelper = true
    // (2)若focus與blur時的值一樣，不檢查

    //與當前所綁RX的代碼不一致的時候
    var isChecked = fh.Value.RxCode == fh.Value.Rx?.RxCode;
    if (isChecked || fh.Controls.RxCode.pristine ) {
      return false;
    } else {
      return true;
    }
  }

  codeIsChecked: boolean = false;
  async onCodeBlur(event:Event, rowIndex: number) {
    this.codeIsChecked = false;
    const rxCode = this.getCodeValue(rowIndex);
    var correctRxCode = rxCode?.replace('$$__', '');

    // 沒有RxCode就清除
    if (!correctRxCode) {
      this.clearRow(rowIndex);
      return;
    }
    // RxCode一律轉大寫 改 後續案搜尋出的RxCode大小寫格式覆蓋
    //var fh = this.getFormHelper(rowIndex);
    //fh.Controls.RxCode.setValue(correctRxCode.toUpperCase(), { emitEvent: false });

    // 需不需要檢查
    if (!this.isNeedCheckCode(rowIndex)) {
      this.helperService.blur();
      this.helperService.setWindowOpened(false);
      return;
    }

    // var SecoundOfTouchPad
    // 這一個timeout為了要讓confirm的dialog能抓到下一個focus的input,讓確定完以後可以正確focus回來而留
    setTimeout(() => {
      this.padClickSignal(false);
    }, 300);

    var isPadClick = await new Promise((rs, rj) => {
      this.padClickSignal = rs;
    });

    if (!isPadClick) {
      this.codeIsChecked = true;

      this.checkCode(rowIndex);
      this.helperService.blur();
      this.helperService.setWindowOpened(false);
    }
  }

  hasSameCode(rxcode: string, rowIndex: number): boolean {
    // 指示用文字跳過檢查
    if(rxcode.startsWith('/')){
      return false;
    }
    for (let i = 0; i < this.orderArray.length; i++) {
      if (i != rowIndex) {
        var fg = this.orderArray.controls[i] as FormGroup;
        if (fg.value.RxCode == rxcode) {
          return true;
        }
      }
    }
    return false;
  }

  padClickSignal: (boolean) => void = null;
  // log(...msg){
  //   if(false){
  //     console.trace(msg)
  //   }
  // }
  /** 如果為過敏藥則提醒 */
  async confirmAllergy(code): Promise<boolean | string> {
    if (this.allergies.some(a => a?.toUpperCase() == code?.toUpperCase())) {
      var p = await this.userConfirm.showConfirm({
        'msg': '藥品代碼[' + code + ']為此患者的過敏藥物，是否繼續開立醫令？',
        'title': '提示',
        'focus': true
      });
      return p;
    } else {
      return true;
    }
  }

  // 檢查輸入的醫令
  async checkCode(rowIndex: number) {
    //const rxCode = this.getCodeValue(rowIndex);
    //var correctRxCode = rxCode.replace('$$__', '');
    var rxCode = this.formHelpers[rowIndex].Value.RxCode;
    // 如果檢查的時候該行已經被清除則跳過
    if(!rxCode){
      return;
    }
    var rxOpts = await this.histService.getOrderList(rxCode,this._tab.rxType);
    var rxOpt = rxOpts.find(x=>x.RxCode.toLowerCase()==rxCode.toLowerCase());
    var rsOpts = await this.histService.getOrderList(rxCode,[0]);
    var rsOpt = rsOpts?.find(x=>x.RxCode.toLowerCase()==rxCode.toLowerCase());
    var isSet = rsOpt!=null;
    try {
      if(isSet){
        await this.setCodeToRow(rowIndex,rsOpt);
      }else if(rxOpt){
        await this.setCodeToRow(rowIndex,rxOpt);
      }else{
        this.codeNotFound(rowIndex);
      }
    } catch (error) {
      console.log(error)
      this.notification.showErrorById('MSG_HistOrders1');
    }
  }

  codeNotFound(rowIndex: number) {
    this.notification.showErrorById('MSG_HistOrders2', true);
    // clear & focus
    this.clearRow(rowIndex);
    this.cd.detectChanges();
    this.focusCode(rowIndex, false);
    var fh = this.getFormHelper(rowIndex);
    fh.setValue(fh.FieldName.OrigOrderCode, this.codeNotFoundKeyword);
    //this.setControlValue('OrigOrderCode', rowIndex, this.codeNotFoundKeyword);
  }
  // #endregion

  // #region code sort --------------------
  codeSort() {
    // 因為效能的緣故
    // 不去直接移動FormArray的controls順序，而是去改變control的值
    // 因此
    // 先取回資料，然後排序
    // 再把排序好的值設定到FormArray
    if (this.disabled) return;
    let tmpOrders: HistOrder[] = [];
    if (this.orderArray.value && this.orderArray.value.length > 0) {
      const arrFiltered = this.orderArray.value.filter(x => x.RxCode !== null && x.RxCode !== '');
      tmpOrders = Object.assign([], arrFiltered);
    }
    // 排序
    let sortedOrder = [];
    sortedOrder = tmpOrders.sort(function (a, b) {
      // 先比OrderType，再比OrderSubType，再比ATC
      if (a.RxType === 0) {
        return 1;
      } if (a.RxType > b.RxType) {
        return 1;
      } else if (a.RxType === b.RxType) {
        // if (!a.OrderSubType) {
        //   return 1;
        // } else if (a.OrderSubType > b.OrderSubType) {
        //   return 1;
        // } else {
        if (!a.ATC) {
          return 1;
        } else if (a.ATC > b.ATC) {
          return 1;
        } else {
          return -1;
        }
        //}
      } else {
        return -1;
      }
    });
    // 把資料設定到FormArray
    this.fillOrders_FormArray(sortedOrder, this._tab);
  }
  // #endregion

  // #region freq --------------------
  freqMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.freqIdPrefix + rowId);
      input.focus();
    }, 0);
  }

  onFreqKeyDown(event: KeyboardEvent, order: FormGroup, rowIndex: number) {
    // 按下Escape清空，helper重設
    if (event.code === 'Escape') {
      this.helperService.reset();
    } else if (event.code === 'Space') {
      if (this.orderArray.controls[rowIndex].get('RxCode').value) {
        this.menuBtns.forEach(w => {
          if (w.menuData == this.freqIdPrefix + rowIndex) {
            setTimeout(() => {
              w.openMenu();
            }, 0);
          }
        })
      }
    } else if (event.key === 'Tab' || event.key === 'Enter') {
      var fh = this.formHelpers[rowIndex];
      // 有CODE就跳下一項 計算給BLUR處理
      if (fh.Value.RxCode){
        // enter強制重算
        this.fixFreq(rowIndex,true);
        const elemId = this.daysIdPrefix + rowIndex;
        var el = document.getElementById(elemId) as HTMLInputElement;
        el.focus();
      }
      // if(this.fixFreq(rowIndex)){
      //   const elemId = this.daysIdPrefix + rowIndex;
      //   var el = document.getElementById(elemId) as HTMLInputElement;
      //   el.focus();
      // }

    } else if (event.key === 'ArrowUp' || event.key === 'ArrowDown' ||
      event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      // if(this.fixFreq(rowIndex)){
      //     const elemId = this.daysIdPrefix + rowIndex;
      //     var el = document.getElementById(elemId) as HTMLInputElement;
      //     //el.focus();
      // }
    } else {
      // 送到helper只是要操作helper視窗的上,下,Enter,Escape
      this.helperService.keydownForDropdown(event);
    }
  }
  /** 修正頻率輸入值 forceUpdate會強制重算劑量 */
  fixFreq(rowIndex:number,forceUpdate:boolean = false){
    var fh = this.formHelpers[rowIndex];
    if(!fh.Controls.Freq.dirty){
      return;
    }
    fh.Controls.Freq.markAsPristine();
    //console.log('fh.Controls.Freq.dirty', fh.Controls.Freq.dirty);
    const feqElemID = this.freqIdPrefix + rowIndex;
    var oel = document.getElementById(feqElemID) as HTMLInputElement;
    if (!fh.Value.RxCode){
      fh.patchValue({ Freq: '' });
      oel.value = '';
      return;
    }

    // 檢查輸入的頻率是否在下拉選單中
    var opt = this.dosages.find(opt => opt.Code == oel.value);
    if (opt) {  // 有代碼
      // 代碼異動在重算
      //if(fh.Value.Freq!==opt.Code || forceUpdate){
        fh.patchValue({ Freq: opt.Code });
        this.calculateDose(rowIndex);
        return;
      //}
    } else {
      // 沒代碼 => 不允許
      if(!HistService.EnableFreqFree){
        fh.patchValue({ Freq: '' });
        oel.value = '';
        return;
      }else{
        //var oldOpt = this.dosages.find(opt => opt.Code == fh.Value.Freq);
        // 有選項的變沒選項的在重算 都沒選項會視為ASODR
        //if(oldOpt || forceUpdate){
          var v = oel.value.toUpperCase()
          fh.patchValue({ Freq: v });
          oel.value = v;
          this.calculateDose(rowIndex);
          return;
        //}
      }
    }
  }
  onFreqBlur(rowIndex: number) {
    this.fixFreq(rowIndex);
  }

  onDaysKeyDown(event: KeyboardEvent, rowIndex: number) {
    //不處理的話會觸發神奇的order-grid-input.directive的onkeydown focus到該directive指定的地方去
    if (event.key === 'Tab'||event.key=='Enter') {
      const elemId = this.totalDoseIdPrefix + rowIndex;
      document.getElementById(elemId).focus();
      setTimeout(() => {
        this.calculateDose(rowIndex);
      }, 0);
    }
  }

  onDaysChenge(event: KeyboardEvent,rowIndex: number) {
    var skip = ['Escape','Tab','ArrowUp','ArrowDown' ,'ArrowLeft' ,'ArrowRight']
    if (skip.indexOf(event.code)>=0) {
      //skip
    } else {
      this.calculateDose(rowIndex);
    }
  }

  onTotalDoseKeyDown(event: KeyboardEvent, rowIndex: number) {
    if (event.key === 'Tab') {
      const elemId = this.wayIdPrefix + rowIndex;
      document.getElementById(elemId).focus();
    }
  }
  onTotalDoseKeyUp(event: KeyboardEvent, rowIndex: number) {
    var fh = this.formHelpers[rowIndex]
    //var calc= this.createHrxCalc(fh.Value);
    // if(!qty)
    this.calculateQty(rowIndex)
    //calc.calcPrice();
    //fh.patchValue(calc.model);

    //this.computeShowPrice()

  }

  onTotalDoseBlur(event: KeyboardEvent, rowIndex: number) {
    var fh = this.formHelpers[rowIndex]
    //var calc= this.createHrxCalc(fh.Value);
    // if(!qty)
    this.calculateQty(rowIndex)
    //calc.calcPrice();
    //fh.patchValue(calc.model);

    //this.computeShowPrice()

  }
  onDoseKeyDown(event: KeyboardEvent, rowIndex: number) {
    if (event.key === 'Tab') {
      const elemId = this.wayIdPrefix + rowIndex;
      document.getElementById(elemId).focus();
    }
  }

  onDoseKeyUp(event: KeyboardEvent, rowIndex: number) {
    var fh = this.formHelpers[rowIndex]
    //var calc= this.createHrxCalc(fh.Value);
    // if(!qty)
    this.calculateQty(rowIndex)
    //calc.calcPrice();
    //fh.patchValue(calc.model);

    //this.computeShowPrice()

  }
  checkNeedExecutor(rowIndex: number): boolean {
    return !this.formHelpers[rowIndex].Value.NeedExecutor?true:null;
  }

  setFreqByPair(rowIndex: number, item: SystemCode) {
    const obj = this.orderArray.controls[rowIndex].get('Frequency');
    obj.setValue(item.Code);
    const elemId = this.freqIdPrefix + rowIndex;
    document.getElementById(elemId).focus();
  }
  // #endregion freq

  // #region Way --------------------
  onWayKeyDown(event: KeyboardEvent, order: FormGroup, rowIndex: number) {
    // 按下Escape清空，helper重設
    if (event.code === 'Escape') {
      this.helperService.reset();
    } if (event.code == 'Space') {
      if (this.orderArray.controls[rowIndex].get('RxCode').value) {
        this.menuBtns.forEach(w => {
          if (w.menuData == this.wayIdPrefix + rowIndex) {
            // 中文輸入法下不等的話會沒辦法focuse進去
            setTimeout(() => {
              w.openMenu();
            });
          }
        })
      }
    } else if (event.key === 'Tab' || event.key === 'Enter') {
      // if (event.key === 'Tab') {
      const wayElemId = this.wayIdPrefix + rowIndex;
      var oel = document.getElementById(wayElemId) as HTMLInputElement;
      // 檢查輸入的途徑是否在下拉選單中
      var opt = this.wayMenu.find(x => x == oel.value);
      if (order.get('RxCode').value && opt) {
        order.patchValue({ Way: opt });
        const elemId = this.dispensingIdPrefix + rowIndex;
        document.getElementById(elemId).focus();
      } else {
        oel.focus();
        order.patchValue({ Way: '' });
        oel.value = '';
        return;
      }
      // }
    } else {
      // 送到helper只是要操作helper視窗的上,下,Enter,Escape
      this.helperService.keydownForDropdown(event);
    }
  }
  wayMenu: string[]
  wayMenuOpen(odr) {
    this.wayMenu = this.editOptions.way.filter(w => w.Name1 == odr.value.RxType || !w.Name1).map(w => w.Code);
  }
  wayMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.wayIdPrefix + rowId);
      input.focus();
    }, 0);
  }

  setWayByPair(rowIndex: number, item: SystemCode) {
    const obj = this.orderArray.controls[rowIndex].get('Way');
    obj.setValue(item.Code);
    const elemId = this.wayIdPrefix + rowIndex;
    document.getElementById(elemId).focus();
  }
  // #endregion Way

  // #region Dispensing


  odrTbWidth: number;
  onDispensingKeyDown(event: KeyboardEvent, order: FormGroup, id: string, rowIndex: number) {
    var key = event.key.toUpperCase();
    if (order.get('RxCode').value && this._dispOpts.some(opt => opt.value == key)) {
      order.patchValue({ DispTP: key });
      //(event.target as HTMLInputElement).value = key == '0' ? '' : key;
      return false;
    } else {
      if (key == " ") {
        var btn = this.menuTrigger.find(m => m.menuData == id);
        // 中文輸入法下不等的話會沒辦法focuse進去
        setTimeout(() => {
          btn.openMenu();
        }, 0);
      } else if (event.key === 'Tab') {
        const elemId = this.remarkIdPrefix + rowIndex;
        document.getElementById(elemId).focus();
      }
      return false;
    }
  }

  dispMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.dispensingIdPrefix + rowId);
      input.focus();
    }, 0);
  }

  setDisp(order: FormGroup, value: string, restoreFocusId: string, rowIndex: number) {
    var el = document.getElementById(this.dispensingIdPrefix + rowIndex) as HTMLInputElement;
    //var v = value == OrderDispensingTypeEnum.T0_Clinic?null:value
    order.patchValue({ DispTP: value });
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
  }

  setWay(order: FormGroup, value: string, restoreFocusId: string) {
    order.patchValue({ Way: value });
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
  }

  setFreq(order: FormGroup, value: string, restoreFocusId: string,idx:number) {
    // console.log(value);
    // const feqElemID = this.freqIdPrefix + idx;
    // var oel = document.getElementById(feqElemID) as HTMLInputElement;
    // oel.value = value;
    var fh = this.formHelpers[idx];
    fh.Controls.Freq.patchValue(value);
    fh.Controls.Freq.markAsDirty();
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
    this.fixFreq(idx,false);
    //this.calculateDose(idx);
  }

  setMedID(order: FormGroup, value: string, text: string, restoreFocusId: string) {
    order.patchValue({
      MedID: value,
      MedIDName: text
    });
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
  }

  // #endregion
  //#region Remark

  onRemarkKeyDown(event: KeyboardEvent, order: FormGroup, id: string, rowIndex: number) {
    if (['Backspace', 'Delete'].includes(event.key)) {
      return true;
    }
    var key = event.key.toUpperCase();
    var el = (event.target as HTMLInputElement);
    if (order.get('RxCode').value && this._remarkOpts.some(opt => opt.value.toUpperCase().startsWith(el.value + key))) {
      order.patchValue({ Remark: el.value + key });
      el.value = el.value + key;
      return false;
    } else {
      if (key == " ") {
        var btn = this.menuTrigger.find(m => m.menuData == id);
        // 中文輸入法下不等的話會沒辦法focuse進去
        setTimeout(() => {
          btn.openMenu();
        });
        return false;
      } else if (key === 'Tab' || key === 'Enter') {
        const elemId = this.medIdNamePrefix + rowIndex;
        document.getElementById(elemId).focus();
      }
    }
    //其他允許輸入
    return true;
  }

  medIdNameMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.medIdNamePrefix + rowId);
      input.focus();
    }, 0);
  }

  onMedIDNameKeyDown(event: KeyboardEvent, rowIndex: number) {
    // 按下Escape清空，helper重設
    if (event.code === 'Escape') {
      this.helperService.reset();
    } else if (event.code === 'Space') {
      if (this.orderArray.controls[rowIndex].get('RxCode').value) {
        this.menuBtns.forEach(w => {
          if (w.menuData == this.medIdNamePrefix + rowIndex) {
            setTimeout(() => {
              w.openMenu();
            }, 0);
          }
        })
      }
    } else if (event.key === 'Tab' || event.key === 'Enter') {
      const elemId = this.sdatePrefix + rowIndex;
      document.getElementById(elemId).focus();
    } else {
      // 送到helper只是要操作helper視窗的上,下,Enter,Escape
      this.helperService.keydownForDropdown(event);
    }
  }

  medIDNameInputBlur(event: InputEvent, rowIndex: number) {
    if (this.orderArray.controls[rowIndex].get('NeedExecutor').value) {
      var d: string = this.orderArray.controls[rowIndex].get('MedIDName').value;
      if (d) {
        if (this.isRecordDone){
          var retDone = this.editOptions.executor.find(x => x.text == d)
          if (retDone) return;
        }
        var fh = this.getFormHelper(rowIndex).Controls.FilterExecutor.value;
        var ret = fh.map(x => {
          var array = x.text.split('.');
          return array[0] == d ? x : (x.text == d ? x : null);
        })
          .filter(r => r != null);

        if (ret && ret.length > 0) {
          this.orderArray.controls[rowIndex].patchValue({
            MedID: ret[0].value,
            MedIDName: ret[0].text
          });
        } else {
          this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
          this.orderArray.controls[rowIndex].get('MedID').setValue(null);
          this.notification.showError('查無此執行人員!');
        }
      } else {
        this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
        this.orderArray.controls[rowIndex].get('MedID').setValue(null);
      }
    } else {
      this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
      this.orderArray.controls[rowIndex].get('MedID').setValue(null);
    }
  }

  medIDNameInputChange(event: InputEvent, rowIndex: number) {   // 非必要輸入執行人員
    if (!this.orderArray.controls[rowIndex].get('NeedExecutor').value) {
      this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
      this.orderArray.controls[rowIndex].get('MedID').setValue(null);
    }
  }

  remarkMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.remarkIdPrefix + rowId);
      input.focus(); 
    }, 0);
  }

  setRemark(order: FormGroup, value: string, restoreFocusId: string) {
    order.patchValue({ Remark: value });
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
  }

  setAllRemark(value: string) {
    for (let i = 0; i < this.orderArray.length; i++) {
      var fg = this.orderArray.controls[i] as FormGroup;
      if (fg.value.RxCode && fg.value.RxType == 2) {
        fg.patchValue({ Remark: value });
      }
    }
  }
  //#endregion

  // #region ChiProcessCode --------------------


  // #endregion Way

  // #region cell --------------------
  /** 以空白覆蓋該行資料 要移除空白行請使用 deleteRowAt */
  clearRow(rowIndex: number) {
    var fh = this.getFormHelper(rowIndex);
    if (!fh) {
      return;
    }
    var data = new OrderModel();
    fh.patchValueNoEvent(data);
  }

  getFormHelper(rowIndex: number) {
    var fhs = this.formHelpers[rowIndex];
    return fhs;
  }
  setOrigOrderCodeAsOrderCode(rowIndex: number) {
    var fh = this.getFormHelper(rowIndex);
    const origValue = fh.Value.OrigOrderCode;
    //const origValue = this.getControlValue('OrigOrderCode', rowIndex);
    if (origValue && origValue === this.codeNotFoundKeyword) {
      // 先前是code not found，不要把orig設為跟目前一樣，後續才會檢查
      return;
    }

    fh.setValue(fh.FieldName.OrigOrderCode, fh.Value.RxCode)
    //this.setControlValue('OrigOrderCode', rowIndex, this.getControlValue('RxCode', rowIndex));
  }

  getCodeElemId(rowIndex: number): string {
    return this.codeIdPrefix + rowIndex;
  }

  getCodeElem(rowIndex: number): HTMLElement {
    const elemId = this.getCodeElemId(rowIndex);
    return document.getElementById(elemId);
  }

  getCodeValue(rowIndex: number): string {
    var fh = this.getFormHelper(rowIndex);
    if (fh.Value.RsCode != '' && fh.Value.RsCode != undefined && fh.Value.RsCode != null) {
      return '$$__' + fh.Value.RxCode;  // 套件
    } else {
      return fh.Value.RxCode;
    }
  }

  focusCode(rowIndex: number, triggerFocusEvent: boolean) {
    if (!rowIndex) {
      rowIndex = 0;
    }
    const elem: HTMLElement = this.getCodeElem(rowIndex);
    if (elem) {
      setTimeout(() => {
        if (!triggerFocusEvent) {
          HistFocusDirective.StopPadHelperEvent(0);
        }
        elem.focus();
      }, 200);
    }
  }

  setOrderNameValue(rowIndex: number, value: string) {
    var fh = this.getFormHelper(rowIndex);
    fh.setValue(fh.FieldName.ProdName, value);
  }
  // UI(grid)的Name呈現欄位，中藥的Code跟Name同一個欄位
  getNameElemId(rowIndex: number): string {
    return this.nameIdPrefix + rowIndex;
  }
  getOrderName(rowIndex: number) {
    return this.getFormHelper(rowIndex)?.Value.ProdName ?? '';
  }
  // #endregion cell

  //#region layout
  get layoutCssClass(): string {
    return 'default-layout';
  }
  //#endregion

  //#region tab --------------------
  findLastEmptyCodePosition(): number {
    if (this.orderArray === null || this.orderArray.controls === null) {
      return;
    }
    let lastEmptyRowIndex = 0;
    for (let i = 0; i < this.orderArray.controls.length; i++) {
      const fg = this.orderArray.controls[i] as FormGroup;
      if (!fg.controls.RxCode.value) {
        lastEmptyRowIndex = i;
        break;
      }
    }
    return lastEmptyRowIndex;
  }
  // 給Orders Component呼叫，用來定位到最後一個Code輸入欄位
  focusLastEmptyCode(triggerFocusEvent: boolean) {
    const rowIndex = this.findLastEmptyCodePosition();
    this.focusCode(rowIndex, triggerFocusEvent);
  }
  //#endregion

  SPRuleClick(evt: any) {
    console.log(3838, evt)
  }

  //val:number[] = [];
  spRulemenu: ValueTextPairNumberValue[];

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  editCol = '';
  styleLeftMask: any = { display: 'none' }
  styleRightMask: any = { display: 'none' }
  editControls: FormGroup[] = [];
  batchOpt: ValueTextPair[] = [];

  closeBatch() {
    this.editCol = '';
    this.styleLeftMask = { display: 'none' }
    this.styleRightMask = { display: 'none' }
  }

  saveBatch(value: string) {
    if (value != '') {
      var patchObj = {};
      patchObj[this.editCol] = value;
      // this.formHelpers.forEach((c,i) => {
      //   if (!NullOrEmpty(c.Value.RxCode)) {
      //     c.patchValue(patchObj);
      //     this.calculateDose(i);
      //   }
      // });
      this.editControls.forEach(c => {
        if (c.controls["RxCode"].value != null && c.controls["RxCode"].value != "") {
          var index =  this.formHelpers.findIndex(x=>x.FormGroup == c);
          c.patchValue(patchObj);
          this.calculateDose(index);
        }
      });
    }
    this.closeBatch();
  }

  batchInput(evt: KeyboardEvent) {
    if (evt.key == 'Enter') {
      this.saveBatch(evt.target['value']);
    } else if (evt.key == 'Escape') {
      this.closeBatch();
    }
  }

  batchRxtype: ValueTextPairNumberValue[] = [];
  getRxTypesInGrid() {
    this.batchRxtype = Distinct(this.formHelpers.map(x=>x.Value).filter(x=>x.RxCode), (x) => x.RxType)
      // 用Assign換掉物件參考，否則checkbox的狀態不會重置
      .map(x => Object.assign({}, OrderTypeDesc.find(o => o.value == x.RxType)));
    this.batchRxtype  = [{text:'全部',value:0}].concat(this.batchRxtype);
  }

  isBatchEditCheck(odr) {
    return this.editControls.includes(odr);
  }

  batchRxTypeCheckChange(evt: MatCheckboxChange, rxType: number) {
    this.orderArray.controls.filter(x =>rxType==0|| x.value.RxType == rxType).forEach(c => {
      this.batchCheckedChange(evt, c);
    });
  }

  openBatchEdit(evt: MouseEvent, colName) {
    if (this.disabled) return;
    this.getRxTypesInGrid()

    this.batchInputEl.nativeElement.value = '';
    this.editCol = colName;
    this.editControls = []; //this.orderArray.controls.filter(c=>c.value.RxCode).map(c=>(c as FormGroup));

    var el = (evt.target as HTMLTableElement)?.closest('th');
    var elGrid = (this.el.nativeElement as HTMLElement);
    var rect = el.getBoundingClientRect();

    var elGrid = (document.getElementById('order-edit-panel') as HTMLElement);
    var rectGrid = elGrid.getBoundingClientRect();
    var maskLeftWidth = (rect.left - rectGrid.left);
    this.styleLeftMask = {
      left: '0px',
      top: '15px',
      width: maskLeftWidth + 'px',
      height: rectGrid.height - 15 + 'px'
    };
    this.styleRightMask = {
      left: maskLeftWidth + rect.width + 'px',
      top: '15px',
      width: (rectGrid.width - maskLeftWidth - rect.width - 10) + 'px',
      height: rectGrid.height - 15 + 'px'
    };
    this.batchOpt = []
    if (colName == 'Freq') {
      this.batchOpt = this.editOptions.dosage.map(d => { return { text: d.Code, value: d.Code } });
    } else if (colName == 'Way') {
      this.batchOpt = this.editOptions.way.map(d => { return { text: d.Code, value: d.Code } });
    } else if (colName == 'DispTP') {
      this.batchOpt = this.editOptions.dispensing.map(d => { return { text: d.value + '|' + d.text, value: d.value } });;
    } else if (colName == 'SPRule') {
      this.batchOpt = OrderSpecialRuleDesc.map(d => { return { text: `${d.value}|${d.text}`, value: d.value.toString() } });
    }

    setTimeout(() => {
      this.batchInputEl.nativeElement.focus();
    }, 0);
    return false;
  }

  batchCheckedChange(evt: MatCheckboxChange, order) {
    if (evt.checked == false) {
      this.editControls = this.editControls.filter(c => c != order);
    } else {
      if (!this.editControls.includes(order)) {
        this.editControls.push(order);
      }
    }

    setTimeout(() => {
      this.batchInputEl.nativeElement.focus();
    }, 0);
  }

  hoverCheck: boolean = null;
  checkMouseDown(evt: MouseEvent, check: MatCheckbox, order) {
    this.hoverCheck = !check.checked;
    check.checked = !check.checked;
    this.batchCheckedChange({ 'checked': check.checked, 'source': check }, order);
  }

  checkMouseOver(evt: MouseEvent, check: MatCheckbox, order) {
    if (this.hoverCheck == null) {
      return;
    }
    check.checked = !check.checked;
    this.batchCheckedChange({ 'checked': check.checked, 'source': check }, order);
  }

  @HostListener('mouseup')
  mouseUp() {
    if (this.hoverCheck != null) {
      this.hoverCheck = null;
      setTimeout(() => {
        this.batchInputEl.nativeElement.focus();
      }, 0);
    }
  }

  getTableWidthResize() {
    if (window.innerWidth < 1300 && window.matchMedia('max-width:1300px')) {
      let numWork = this.bodyWidth / 5;
      this.tableWidth = 1200 + numWork + 'px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.tableWidth = '';
    this.getTableWidthResize();
  }

  drop(event: CdkDragDrop<string[]>) {
    // 順序變動的範圍
    var preIndex = event.previousIndex; // 原index
    var curIndex = event.currentIndex;  // 新index
    var movedFg = Object.assign({}, this.orderArray.controls[preIndex] as FormGroup); //this.deepCopy<FormGroup>(this.orderArray.controls[preIndex] as FormGroup); ;
    var minIdx = curIndex;
    var maxIdx = preIndex;
    var isForward = true;   // 是否為往前移
    if (preIndex == curIndex) {
      return;
    }
    if (preIndex < curIndex) {  // 往後移
      minIdx = preIndex;
      maxIdx = curIndex;
      isForward = false;
    }
    // 最後一筆有資料的 index
    const lastRowIndex = this.findLastRowIndexAfter(0);
    if (maxIdx > lastRowIndex) {  // 超出可移動陣列範圍
      maxIdx = lastRowIndex;
    }
    // 搬資料
    if (isForward) {
      for (let i = maxIdx; i >= minIdx; i--) {
        const fg = this.orderArray.controls[i] as FormGroup;
        // this.clearRow(i);
        var prevFg: FormGroup;
        if (i == minIdx) {
          prevFg = movedFg;
        } else {
          prevFg = this.orderArray.controls[i - 1] as FormGroup;
        }
        fg.patchValue(prevFg.value, { emitEvent: true });
      }
    } else {
      for (let i = minIdx; i <= maxIdx; i++) {
        const fg = this.orderArray.controls[i] as FormGroup;
        // this.clearRow(i);
        var prevFg: FormGroup;
        if (i == maxIdx) {
          prevFg = movedFg;
        } else {
          prevFg = this.orderArray.controls[i + 1] as FormGroup;
        }
        fg.patchValue(prevFg.value, { emitEvent: true });
      }
    }
    this.cd.detectChanges();
  }

  async viewDetail(order: FormGroup) {
    var rxFull = await this.histApi.getOrderByCode(order.value.RxCode, this._tab.rxType);
    this.userConfirm.showAlert(rxFull.RxCode, '', {
      width: 500,
      template: this.rxDetail,
      templateData: rxFull
    });
  }

  isActive(input: HTMLInputElement) {
    return document.activeElement == input;
  }

  menuRemarkPosition = { x: '0px', y: '0px' };
  onContextMenu(evt: MouseEvent, colName: string) {
    evt.preventDefault();
    this.getRxTypesInGrid();

    this.batchInputEl.nativeElement.value = '';
    this.editCol = colName;
    this.editControls = [];

    var el = (evt.target as HTMLTableElement)?.closest('th');
    var rect = el.getBoundingClientRect();

    this.menuRemarkPosition.x = rect.left + 50 + 'px'; // evt.clientX + 'px';
    this.menuRemarkPosition.y = rect.bottom + 'px'; //  evt.clientY + 'px';
    this.menuRemark.menu.focusFirstItem('mouse');
    this.menuRemark.openMenu();
  }
}
