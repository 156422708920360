<ng-container  *ngIf="isInitialized">
<div class="row narrow-padding">
  <div class="col-md-7 col-12">
    <div class="card border-0">
      <div>
        <mat-button-toggle-group [(ngModel)]="roomFilter" *ngIf="roomFilterOpt.length>2">
          <mat-button-toggle [value]="item.value" (click)="filterRoom(item.value)" *ngFor="let item of roomFilterOpt">{{item.text}}</mat-button-toggle>
        </mat-button-toggle-group></div>
      <div class="card-body p-0"   id="reserve-patient-list">
        <app-vis-grid [data]="gridView" [showStickyHead]="true" class="controlled-font-size-large"
        [selectable]="true" [cancelable]="false" [kendoGridSelectBy]="'Id'"
        (sort)="gridSort($event)" [showStickyTableRows]="5"
        (cellClick)="reserveListClick($event)">

          <app-vis-grid-column title="預" [width]="20" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="preview(d, dataItem.Id, false)" i18n="@@Preview" class="my-1 text-xs btn-grid px-1 background-none">
                <mat-icon  title="預覽{{reserveReceiptName}}">print</mat-icon>
              </button>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="印" [width]="20" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="print(d, dataItem.Id, false)" i18n="@@Print" class="my-1 text-xs btn-grid px-1 background-none">
                <mat-icon class="vis-combine-icon " title="列印{{reserveReceiptName}}">print</mat-icon>
              </button>
            </ng-template>
          </app-vis-grid-column>

          <app-vis-grid-column field="Name" title="姓名" [width]="90" [sortable]="true" i18n-title="@@PatientName">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientNo" title="病歷號" [sortable]="true" [width]="80" [CellStyle]="{direction:'rtl'}" i18n-title="@@PatientNo">
          </app-vis-grid-column>
          <app-vis-grid-column field="RegDate" title="預約日" [sortable]="true" [width]="100" i18n-title="@@BookingDate" class="text-center custom-m-auto">
            <ng-template let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="TimeSec" title="時段" [width]="40" i18n-title="@@TimeSec" class="text-center">
            <ng-template let-data>
              <div class="ellipsis-content">
                {{data.TimeSec | valueToText:this.timeSecOpt}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="ICode" title="身分別" [width]="60" i18n-title="@@ICode">
            <ng-template let-data>
              <div class="ellipsis-content" title="{{data.ICode | valueToText:this.icodeOpt:true}}">
                {{data.ICode | valueToText:this.icodeOpt:true}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="DoctorName" title="醫師" [width]="70" i18n-title="@@DoctorName">
          </app-vis-grid-column>
          <app-vis-grid-column field="WaitNo" title="預約號" [sortable]="true" [width]="65" i18n-title="@@WaitNo"
            class="px-0 text-center custom-m-auto">
          </app-vis-grid-column>
          <app-vis-grid-column field="RoomCode" title="診別" [width]="55" i18n-title="@@RoomCode"
            class="px-0 text-center custom-m-auto">
            <ng-template let-data>
              <div class="ellipsis-content">
                {{data.RoomCode | valueToText:this.roomOpt}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Remark" title="備註" [width]="50" i18n-title="@@Remark">
          </app-vis-grid-column>
          <div no-data *ngIf="gridView?.length==0" style="width: 100%;text-align: center;">無預約資料</div>
        </app-vis-grid>

      </div>
      <div class="card-header card-subtitle-light-blue r-header" >
        <h5 class="vis-title">
          <mat-icon class="pref-icon vis-combine-icon">account_circle</mat-icon>
          基本資料
        </h5>
        <app-vis-button [icon]="'search'" [label]="'查詢'" id="reserve-query-Btn"
          [styleType]="'primary'" [animateIcon]="true" [animateBtn]="true"
          (click)="selectPatient()"></app-vis-button>
      </div>
      <div class="card-body" [formGroup]="patientFormGroup" style="padding: 0px 5px 5px 5px;">
        <app-search-bar [Cols]="3">
          <input type="tel" class="vis-input" [formControlName]="formHelper.FieldName.PatientNo" searchBarField [fieldTitle]="'病歷號'" maxlength="10" disabled/>
          <input class="vis-input" [formControlName]="formHelper.FieldName.CName" searchBarField [fieldTitle]="'姓名'"  disabled/>
          <input class="vis-input" [formControlName]="formHelper.FieldName.CId"  searchBarField [fieldTitle]="'身分證'" maxlength="10"  disabled/>
          <app-vis-datepicker [formControlName]="formHelper.FieldName.Birthday" [maxWidth]="false" searchBarField [fieldTitle]="'生日'"  disabled [disabled]="true"></app-vis-datepicker>
          <input type="tel" class="vis-input" [formControlName]="formHelper.FieldName.Kcstmr" searchBarField [fieldTitle]="'自訂碼'" maxlength="10" disabled />
          <input type="tel" class="vis-input" [formControlName]="formHelper.FieldName.MissTimes" searchBarField [fieldTitle]="'爽約次'" maxlength="10" disabled/>
        </app-search-bar>
        <app-search-bar [Cols]="2" class="reserve-tel" [Flex]="true">
          <input type="tel" class="vis-input" [formControlName]="formHelper.FieldName.Phone" searchBarField [fieldTitle]="'電話'" maxlength="10" disabled/>
          <input type="tel" class="vis-input" [formControlName]="formHelper.FieldName.CellPhone" searchBarField [fieldTitle]="'手機'" maxlength="10" disabled/>
        </app-search-bar>
      </div>
      <div class="card-header card-subtitle-light-blue r-header">
        <h5 class="vis-title">
          <mat-icon class="pref-icon vis-combine-icon">how_to_reg</mat-icon>
          預約資料
        </h5>
        <!-- <span *ngIf="doctorId" class="d-inline-block text-base font-weight-bold flex-grow-one pt-1 ml-3">
          <mat-checkbox [(ngModel)]="showSelf" class="top-0 bottom-0 m-auto position-relative start-0" style="color: var(--indigo);">
            只顯示同醫師
          </mat-checkbox>
        </span> -->
        <div style="display: flex;column-gap: 5px;">
          <ng-container *ngIf="this.regFormHelper.Value.RegId;else editBtns">
            <app-vis-button [icon]="'cancel'" [label]="'取消'"
            (click)="cancel()"></app-vis-button>
            <app-vis-button [icon]="'delete'" [label]="'刪除'" [styleType]="'warning'"
            (click)="remove()"></app-vis-button>
            <app-vis-button [icon]="'save'" [label]="'存檔'"
            (click)="update()"></app-vis-button>
          </ng-container>
          <ng-template #editBtns>
            <app-vis-button [icon]="'how_to_reg'" [label]="'預約'" [styleType]="'warning'"
            (click)="save()" id="reserve-Btn"></app-vis-button>
          </ng-template>
        </div>
      </div>
      <div class="card-body" [formGroup]="regFormGroup" id="reserve-form" style="padding: 0px 5px;">
        <app-search-bar  [Cols]="3">
          <input class="vis-input red-placeholder" [placeholder]="enablePlaceHolder?'請點選右方時段代入':''" [formControlName]="regFormHelper.FieldName.RegDateStr" searchBarField [fieldTitle]="'預約日'" maxlength="10" disabled/>
          <input class="vis-input red-placeholder" [placeholder]="enablePlaceHolder?'請點選右方時段代入':''" searchBarField [fieldTitle]="'時段別'"
            [formControlName]="regFormHelper.FieldName.TimeSecStr"/>

          <app-vis-dropdownlist class="flex-grow-one" searchBarField [fieldTitle]="'醫師別'"
            [formControlName]="regFormHelper.FieldName.DoctorId"
            [data]="doctorOpt" (valueChange)="doctorChanged()">
          </app-vis-dropdownlist>
          <app-vis-dropdownlist class="flex-grow-one" searchBarField [fieldTitle]="'身分別'"
            [formControlName]="regFormHelper.FieldName.ICode"
            [data]="icodeOpt">
          </app-vis-dropdownlist>
          <div searchBarField [fieldTitle]="'預約號'" style="display: flex;">
            <input type="tel" class="vis-input" [formControlName]="regFormHelper.FieldName.WaitNo" maxlength="10" style="flex-grow: 1;width: 100px;"/>
            <button type="button" (click)="onGetWaiNoClick()" class="background-none btn-grid"
            style="min-width: 85px;display: flex;align-items: center;">
              <mat-icon class="vis-combine-icon " title="重給號">sync</mat-icon>
              <span>重給號</span>
            </button>
          </div>
          <input class="vis-input" searchBarField [fieldTitle]="'備註'"
            [formControlName]="regFormHelper.FieldName.Remark"/>
        </app-search-bar>
      </div>
      <div class="card-header card-subtitle-light-blue py-0 d-flex" style="height: 32px;">
        <h5 class="vis-title d-inline-block text-base font-weight-bold flex-grow-one lh-lg small-title">
          <mat-icon class="pref-icon vis-combine-icon">recent_actors</mat-icon>
          未看診預約資料
        </h5>
        <span class="d-inline-block text-base font-weight-bold flex-grow-one pt-1 ml-3">
          <mat-checkbox [(ngModel)]="showMiss" (change)="ShowMissChecked($event)" class="top-0 bottom-0 m-auto position-relative start-0" style="color: var(--indigo);">
            顯示過期未報到資料
          </mat-checkbox>
        </span>
        <div class="flex-grow-one text-right font-weight-bold pt-1 text-lg" style="width:calc(100% - 22rem); color: var(--indigo);">
            {{reserveGridView.length}}筆
        </div>
      </div>
      <div class="card-body p-0" id="reserve-unfinished-list">
        <!--[customStyle]="fontGreatService.getFontGreat()?'92px':'78px'"-->
        <app-vis-grid [data]="reserveGridView" [showStickyHead]="true"
        class="controlled-font-size-large"

        [selectable]="true" [kendoGridSelectBy]="'RegId'" [selectedKeys]="regSelectKeys"
        (selectionChange)="regSelected($event)"
        [showStickyTableRows]="2+addRows"
        (sort)="gridRegSort($event)">
          <!-- <app-vis-grid-checkbox-column class="text-center" title="修" [field]="'RegPeriod'" [width]="30">
          </app-vis-grid-checkbox-column>
          <app-vis-grid-column class="text-center" title="印" [field]="'Print'" [width]="30">
            <ng-template let-dataItem>
              <mat-icon i18n="@@Print"
                class="vis-combine-icon btn-scale-90 position-absolute m-auto start-0 end-0 top-0 bottom-0 cursor-pointer icon-hover"
                title="印">print
              </mat-icon>
            </ng-template>
          </app-vis-grid-column> -->

          <app-vis-grid-column title="預" [width]="20" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="preview(d, dataItem.RegId, true)" i18n="@@Preview" class="my-1 text-xs btn-grid px-1 background-none">
                <mat-icon class="vis-combine-icon position-absolute m-auto start-0 end-0 top-0 bottom-0" title="預覽{{reserveReceiptName}}">print</mat-icon>
              </button>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="印" [width]="20" class="text-center custom-m-auto">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="print(d, dataItem.RegId, true)" i18n="@@Print" class="my-1 text-xs btn-grid px-1 background-none">
                <mat-icon class="vis-combine-icon position-absolute m-auto start-0 end-0 top-0 bottom-0" title="列印{{reserveReceiptName}}">print</mat-icon>
              </button>
            </ng-template>
          </app-vis-grid-column>

          <app-vis-grid-column field="RegDate" title="預約日" [sortable]="true" [width]="100" i18n-title="@@BookingDate" class="text-center custom-m-auto">
            <ng-template let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Section" title="時段" [width]="40" i18n-title="@@TimeSec" class="text-center">
            <ng-template let-data>
              <div class="ellipsis-content">
                {{data.Section | valueToText:this.timeSecOpt}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="ICode" title="身分" [width]="60" i18n-title="@@ICode">
            <ng-template let-data>
              <div class="ellipsis-content" title="{{data.ICode | valueToText:this.icodeOpt:true}}">
                {{data.ICode | valueToText:this.icodeOpt:true}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="DoctorName" title="醫師" [width]="70" [sortable]="true" i18n-title="@@DoctorName">
          </app-vis-grid-column>
          <app-vis-grid-column field="WaitNo" title="預約號" [width]="46" [sortable]="true" i18n-title="@@WaitNo"
            class="px-0 text-center custom-m-auto">
          </app-vis-grid-column>
          <app-vis-grid-column field="RoomCode" title="診別" [width]="55" i18n-title="@@RoomCode"
            class="px-0 text-center custom-m-auto">
            <ng-template let-data>
              <div class="ellipsis-content">
                {{data.RoomCode | valueToText:this.roomOpt}}
              </div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="Remark" title="備註" [width]="50" i18n-title="@@Remark">
          </app-vis-grid-column>
          <app-vis-grid-column field="Creater"  title="輸入者" [width]="100" i18n-title="@@Creater">
          </app-vis-grid-column>
          <div no-data *ngIf="reserveGridView.length==0" style="width: 100%;text-align: center;">無預約資料</div>
        </app-vis-grid>

      </div>
      <div class="card-header card-subtitle-light-blue py-0 d-flex">
        <h5 class="vis-title d-inline-block text-base font-weight-bold flex-grow-one lh-lg small-title">
          <mat-icon class="pref-icon vis-combine-icon">import_contacts</mat-icon>
          歷史看診資料
        </h5>
        <div class="flex-grow-one text-right font-weight-bold pt-1 text-lg" style="width:calc(100% - 22rem); color: var(--indigo);">
            {{histGridView.length}}筆
        </div>
      </div>
      <div class="card-body p-0" id="reserve-hist-list">
        <!--[customStyle]="fontGreatService.getFontGreat()?nowWidth>=1920?'192px':'92px':nowWidth>=1920?'290px':'78px'"-->
        <app-vis-grid #histGrid [data]="histGridView" [showStickyHead]="true"
        class="controlled-font-size-large"
        (sort)="gridHistSort($event)"
        (onDataSetted)="HistDataSetted($event)"
        [showStickyTableRows]="2+addRows">
          <app-vis-grid-column field="RegDate" title="看診日" [width]="110" i18n-title="@@RegDate" class="text-center custom-m-auto"
          [sortable]="true">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.RegDate | formatDate:''">{{ dataItem.RegDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="症候" field="Dx1Name" i18n-title="@@DxName">
          </app-vis-grid-column>
          <app-vis-grid-column field="Days" title="日份" [width]="60" i18n-title="@@Days">
          </app-vis-grid-column>
          <app-vis-grid-column field="DoctorName" title="醫師" [width]="90" [sortable]="true" i18n-title="@@DoctorName">
          </app-vis-grid-column>
          <div *ngIf="histGridView.length==0" no-data style="width: 100%;text-align: center;">無歷史看診資料</div>
        </app-vis-grid>
      </div>
    </div>
  </div>
  <div class="col-md-5 col-12">
    <div class="card border-0">
      <div class="card-body p-0 px-1" id="reserve-list">

        <app-reg-reserve-list #reserveList [doctorId]="doctorId" [selectedDate]="selectedDate" [selectSec]="selectedSec"
          (sectionSelected)="onSectionSelected($event)" (dateSelected)="onDateSelected($event)">
          
          <app-vis-button style="min-width: 70px;" [icon]="'description'" [label]="'報表'" (click)="reserveDialogOpen(true)"></app-vis-button>
          <!-- <div style="display: flex; column-gap: 1px;margin-top: 5px;">
            <div style="flex-grow: 1;"></div>
            <button type="button" (click)="reserveDialogOpen(true)" id="reportBtn"
              class="vis-btn-config position-relative"
              title="報表">
              <mat-icon class="pref-icon vis-combine-icon">description</mat-icon>
              <span>報表</span>
            </button>
          </div> -->
        </app-reg-reserve-list>
      </div>
      <div class="card-footer">
        <!-- <div style="display: flex; column-gap: 1px;margin-top: 5px;">
            <div style="flex-grow: 1;"></div>
            <button type="button" (click)="reserveDialogOpen(true)" id="reportBtn"
              class="vis-btn-config p-0 position-relative lh-base w-18 pl-2"
              title="報表">
              <mat-icon class="btn-scale-90 pref-icon vis-combine-icon">description</mat-icon>
              <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">報表</span>
            </button>

        </div> -->
    </div>
    </div>
  </div>
</div>
<app-vis-dialog [title]="'預約報表'" [superWidth]="'90%'" [maxWidth]="'90vw'" [show]="isOpenReserveDialog" (close)="isOpenReserveDialog=false">
  <div class="inside-scrollbar">
      <div class="w-100">
        <div>
          <mat-button-toggle-group name="abnormalStatus">
            <mat-button-toggle [checked]="true" (change)="onUnGotSerial($event)">
              爽約名單
            </mat-button-toggle>
            <mat-button-toggle [checked]="false" (change)="onGotSerial($event)">
              預約退掛名單
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
        <div class="card card-body no-padding inside-scrollbar">
          <form class="vis-form">
            <div class="w-100 leave-bottom">
              <div *ngIf="unGotSerial" title="爽約名單">
                <form class="c-form-inline">
                  <div class="w-100 d-block">
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">起始日期：</span>
                        <app-vis-datepicker class="flex-grow-one" [maxWidth]="false"></app-vis-datepicker>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">截止日期：</span>
                        <app-vis-datepicker class="flex-grow-one" [maxWidth]="false"></app-vis-datepicker>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">時段別：</span>
                        <app-vis-combobox [data]="timeSecOpt"></app-vis-combobox>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">列印診別：</span>
                        <input class="vis-input"/>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">護理登錄：</span>
                        <app-vis-combobox [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" [valueFormat]="'value'"></app-vis-combobox>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">排序：</span>
                        <app-vis-combobox [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" [valueFormat]="'value'"></app-vis-combobox>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">輸出設備：</span>
                        <app-vis-combobox [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" [valueFormat]="'value'"></app-vis-combobox>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="isGotSerial" title="預約退掛名單">
                <form class="c-form-inline">
                  <div class="w-100 d-block">
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">預約開始日：</span>
                        <app-vis-datepicker class="flex-grow-one" [maxWidth]="false"></app-vis-datepicker>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">預約結束日：</span>
                        <app-vis-datepicker class="flex-grow-one" [maxWidth]="false"></app-vis-datepicker>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">病歷號：</span>
                        <input class="vis-input"/>
                      </label>
                    </div>
                    <div class="col-sm-3 d-inline-block">
                      <label class="vis-form-field">
                        <span class="label-name">輸出設備：</span>
                        <app-vis-combobox [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" [valueFormat]="'value'"></app-vis-combobox>
                      </label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
  <div class="my-3 text-center dialog-action-btn">
    <button>確定</button>
    <button disabled>預覽列印</button>
    <button disabled>列印</button>
    <button (click)="isOpenReserveDialog=false">取消</button>
  </div>
</app-vis-dialog>

</ng-container>


<app-vis-dialog (close)="d.show=false" [fitContent]="true" #d [height]="'95%'" [maxWidth]="'1200px'" [superWidth]="'95%'" title="{{reserveReceiptName}}預覽">
  <app-search-bar *ngIf="d.show">
      <button type="button"
        (click)="print(d, currentFindId, currentNotSeen)"
        class="vis-btn-config p-0 position-relative pl-2 w-18 m-1 mt-0"
        title="列印">
        <mat-icon class="pref-icon add vis-combine-icon">print</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
      </button>
  </app-search-bar>
  <mat-divider></mat-divider>
  <!-- <div style="height:470px;" class="p-0 w-100"> -->
  <div class="p-0 w-100">
      <ng-container *ngIf="d.show">
          <!-- [showStickyHeight]="470" -->
          <app-vis-grid
            [noHeader]="true"
            class="small-paginater"
            [scroll]="false"
            [showStickyHead]="true"
            [data]="previewData"
            [customClass]="'overflow-y-hidden'">
              <app-vis-grid-column>
                  <ng-template>
                    <!-- 此報表只會印一頁 -->
                    <!-- <app-reserve-receipt [data]="previewList()"></app-reserve-receipt> -->
                    <app-reserve-receipt [data]="previewData[0]"></app-reserve-receipt>
                  </ng-template>
              </app-vis-grid-column>
          </app-vis-grid>
      </ng-container>
  </div>
</app-vis-dialog>

<div style="display:none">
  <app-reserve-receipt #report></app-reserve-receipt>
</div>
