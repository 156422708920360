import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserConfirmService } from './user-confirm.service';

export interface IConfirmLeave{
  /** 是否可以離開，空白則可以，其他文字則顯示為Confirm提示 */
  confirmExit():string;
}
@Injectable({
  providedIn: 'root'
})
export class RouteInfoService {
  comp:IConfirmLeave = null;
  get CurrentUrl(){
    return this.router.url;
  }
  constructor(private router: Router,private userconfirm:UserConfirmService) {
    
   }
   /** 詢問操作的Comp是否為須確認才可離開的狀態 */
   async onMenuClick(){
    if(this.comp!=null){
      var warningMsg = this.comp.confirmExit();
      if(warningMsg){
        var confirm = await this.userconfirm.showConfirm({
          msg: warningMsg,
        });
        return confirm;
      }
    }
    return true;
   }
  // 取得目前route path中的company code，無則返回空
  getCompanyCode() {
    // this.router.routerState.snapshot.root.children[0].params.com;
    const nowUrl: string = this.router.url;
    const nowUrlParts: string[] = nowUrl.split('/');
    // nowUrlParts長度1以下(含)代表url格式有問題，沒有正確的company code，直接回傳url
    if (!nowUrlParts || nowUrlParts.length <= 1) {
      return '';
    }
    return nowUrlParts[1];
  }

  // 產生加上 company code 的完整 url
  makeFullUrl(url: string): string {
    if (!url) { return url; }

    const companyCode = this.getCompanyCode();
    if (!companyCode) { return url; }

    if (url.startsWith(companyCode)) { return url; }

    if (companyCode) {
      if (!url.startsWith('/')) {
        url = '/' + url;
      }
      return companyCode + url;
    }

    return url;
  }
  goHome(){
    this.router.navigate([this.getCompanyCode() + '/system/announcement']);
  }
}
