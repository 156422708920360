import { ValueTextPair } from "src/app/shared/models/value-text-pair";

export class PatientEditOption{
    
    //title: ValueTextPair[];
    //raceCode: ValueTextPair[];
    //contry: ValueTextPair[];
    
    //jobCode: ValueTextPair[];
    //educationCode: ValueTextPair[];
    //blType: ValueTextPair[];
    //blRH: ValueTextPair[];
    //relation:ValueTextPair[];
    //sex: ValueTextPair[];
    //married: ValueTextPair[];
    
    
    //histStatusCode: ValueTextPair[];
    //iCode: ValueTextPair[];
    //pastHistoryCode:ValueTextPair[];
    //familyHistoryCode:ValueTextPair[];
    //surgeryHistoryCode:ValueTextPair[];
    //foodAllergyHistoryCode:ValueTextPair[];
    discount:ValueTextPair[];
    
    spcIdentity:ValueTextPair[];
    // 新增預設值
    defaultData:any;
    //Country: [''],
    //DiscountId: [0],  
}