import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class WebPrintService {

    printRegisterForm(printData) {
        if (printData) {
            let styles = `<style>
            .print-area {
                margin: 5mm 5mm;
                font-size: 14pt;
            }
            .print-title {
                font-size: 16pt;
                height: 10mm;
                line-height: 10mm;
                border-bottom: solid 1px #666666;
            }
            .print-line {
                height: 8mm;
                line-height: 8mm;
            }
            .print-item-title {
                min-width: 100px;
                margin-right: 5px;
                display: inline-block;
            }
            .print-item-value {
                display: inline-block;
            }
            @media print {
                @page {
                    size: A4;
                    margin: 0;
                }
            }
            </style>`;
            let html = `<div class="print-area">` +
                `<div class="print-title">${printData.ClinicName} Registration</div>` +
                `<div class="print-line"><div class="print-item-title">病患</div><div class="print-item-value">${printData.PatientName}</div></div>` +
                `<div class="print-line"><div class="print-item-title">病歷號</div><div class="print-item-value">${printData.PatientNo}</div></div>` +
                `<div class="print-line"><div class="print-item-title">日期</div><div class="print-item-value">${printData.RegDate}</div></div>` +
                `<div class="print-line"><div class="print-item-title">時段</div><div class="print-item-value">${printData.TimeSecName}</div></div>` +
                `<div class="print-line"><div class="print-item-title">診間</div><div class="print-item-value">${printData.MedRoom}</div></div>` +
                `<div class="print-line"><div class="print-item-title">醫師</div><div class="print-item-value">${printData.DoctorName}</div></div>` +
                `<div class="print-line"><div class="print-item-title">候診號</div><div class="print-item-value">${printData.WaitNo}</div>` +
                `</div>`;

            var printPage = window.open("", "Printing", "");
            printPage.document.open();
            printPage.document.write("<HTML><head>" + styles + "</head><BODY onload='window.print();window.close();'>");
            //printPage.document.write("<HTML><head>" + styles + "</head><BODY>");
            printPage.document.write(html);
            printPage.document.write("</BODY></HTML>");
            printPage.document.close();
        }
    }
}
