<div class="reg-reserve-list">
  <div class="tool-bar" style="display: flex;align-items: center;">
    <app-rect-button [title]="'前'" (click)="setMonth(-1)" [icon]="'arrow_back'" [styleType]="'third-primary'">前</app-rect-button>
    
    <span class="text-lg now-month font-weight-bold">{{monthLabel}}</span>
    <app-rect-button [title]="'後'" [iconPos]="'after'" (click)="setMonth(+1)" [icon]="'arrow_forward'" [styleType]="'third-primary'">後</app-rect-button>
    <div style="flex-grow: 1;"></div>

    <app-vis-button  [appOpenDialog]="settingDialog" [icon]="'settings'" [label]="'設定'"></app-vis-button>
    
    <!-- <div style="flex-grow: 1;"></div> -->
    <ng-content></ng-content>
    <app-vis-button [icon]="'help'" [label]="'說明'" (click)="showTutorial()"></app-vis-button>
  </div>
  <div  style="display: flex;height: 25px;">
    <mat-button-toggle-group style="width: 240px;flex-shrink: 0;" [(ngModel)]="qSelect" id="reserve-list-quick-select">
      <mat-button-toggle (click)="intervalChange(item.value)" *ngFor="let item of weekList" [value]="item.value">
        {{item.text}}
      </mat-button-toggle>
    </mat-button-toggle-group>
    <input [(ngModel)]="qSelect" (change)="intervalChange(qSelect)" style="min-width: unset;width: 100%;flex-shrink: 1;flex-grow: 1;">
    <button type="submit" (click)="intervalChange(qSelect)" >
      <mat-icon style="width: 20px;height: 20px;font-size: 20px;">arrow_forward</mat-icon>
    </button>
  </div>
  <div class="week-table w-100">
    <!--星期 表頭-->
    <div class="w-100 p-0 m-0 d-flex week-table-header">
      <div class="flex-grow-one text-center days" *ngFor="let day of days">{{day}}</div>
    </div>
    <div class="week-table-body" [ngStyle]="{
      height:(this.bHeight-80)+'px',
      minHeight:'560px'
    }">
      <div class="one-day reserve-calendar" 
        [id]="data.isToday?'reserve-list-today':''"
        *ngFor="let data of calendarViewModel,let i = index" 
        [ngStyle]="data.style" 
        [ngClass]="data.class"
        (click)="dateClick(data,$event)">
        <div class="normal-days" [ngClass]="data.textClass">
          {{data.day}}
        </div>
        <ng-container *ngIf="data.isRest;else openTemp">
          <div class="time-section">
            <div>無</div>
          </div>
          <div class="time-section">
            <div>無</div>
          </div>
          <div class="time-section">
            <div>無</div>
          </div>
          <!-- <div class="empty-secw-100 d-flex pr-1">
            <div class="flex-grow-one text-center time-section-nums px-1 text-base grey"
            style="height: 22px;">
              無
            </div>
          </div> -->
          <!-- <div class="w-100 d-flex pr-1" [ngClass]="nowWidth>=1601?'my-1':''">
            <div class="flex-grow-one text-center time-section-nums px-1 text-base grey"
            style="height: 22px;">
              無
            </div>
          </div>
          <div class="w-100 d-flex pr-1">
            <div class="flex-grow-one text-center time-section-nums px-1 text-base grey"
            style="height: 22px;">
              無
            </div>
          </div> -->
        </ng-container>
        <ng-template #openTemp>
          <ng-container *ngFor="let secModel of data.secModel">
            <ng-container *ngIf="secModel.count>=0;else restSec">
              <div class="pr-1 time-section" [ngClass]="secModel.class" >
                <!-- <div class="flex-grow-one"></div> -->
                <div class="sec-selectable sec-text flex-grow-one" style="text-align: center;cursor: pointer;" 
                    (click)="sectionClick(data,secModel.sec,$event)" [id]="secModel.id"
                  >{{secModel.text}}</div>
                <!-- <div class="flex-grow-one"></div> -->
                <div class="text-right count text-blue-sky" [id]="secModel.id?(secModel.id+'-cnt'):''"  style="text-decoration: underline;"
                  (click)="secCountClick(data,secModel.sec,$event)">{{secModel.count}}</div>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #restSec>
  <div class="time-section">
    <div>無</div>
  </div>
</ng-template>

<app-vis-dialog [show]="showDetailOpen" (close)="showDetailOpen=false" [width]="300">
  <ng-container *ngIf="detail">
    <table style="width: 100%;" border="1">
      <tr>
        <td style="width: 33%;">醫師</td>
        <td style="width: 33%;">診間</td>
        <td style="width: 33%;">預約數</td>
      </tr>
      <tr *ngFor="let item of detail">
        <td>{{item.DoctorName}}</td>
        <td style="text-align: left;">{{item.RoomCode}}</td>
        <td style="text-align: right;">{{item.ReserveCount}}</td>
      </tr>
    </table>
    <app-vis-dialog-actions>
      <button (click)="showDetailOpen=false">關閉</button>
    </app-vis-dialog-actions>
  </ng-container>
</app-vis-dialog>

<!--預約相關設定-->
<app-vis-dialog [title]="'預約相關設定'" [drag]="true" [superWidth]="'90%'" [maxWidth]="'90vw'" #settingDialog>
  <app-reg-reserve-setting></app-reg-reserve-setting>
  <div class="my-3 text-center dialog-action-btn">
    <button (click)="saveSetting($event, settingDialog)">確定</button>
    <button [appCloseDialog]="settingDialog">取消</button>
  </div>
</app-vis-dialog>