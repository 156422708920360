import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserRoleFunction } from 'src/app/models/UserRoleFunction';

/**
 * @description
 * 服務：UserRoleFunctionService
 * 此服務提供一個中央化管理和訪問用戶角色功能權限的方式。
 * 它允許應用的不同部分更新和訂閱用戶的角色功能權限狀態。
 *
 * @remarks
 * 此服務使用 RxJS 的 BehaviorSubject 來持續跟蹤和發送用戶角色功能的最新狀態。
 * BehaviorSubject 需要一個初始值，在此為 null，表示在數據首次設置之前，用戶的角色功能狀態未定義。
 *
 * @example
 * 如何更新和訂閱用戶的角色功能權限：
 * ```typescript
 * // 訂閱用戶角色功能權限變化
 * userRoleFunctionService.userRoleFunction$.subscribe(roleFunction => {
 *   console.log(roleFunction);
 * });
 *
 * // 更新用戶角色功能權限
 * const newRoleFunction: UserRoleFunction = {
 *   Modify: true,
 *   View: true,
 *   Delete: false,
 *   ...
 * };
 * userRoleFunctionService.setUserRoleFunction(newRoleFunction);
 * ```
 */

@Injectable({
  providedIn: 'root'
})
export class UserRoleFunctionService {

  private userRoleFunctionSource = new BehaviorSubject<UserRoleFunction>(null);
  userRoleFunction$ = this.userRoleFunctionSource.asObservable();

  setUserRoleFunction(data: UserRoleFunction) {
    this.userRoleFunctionSource.next(data);
  }
}
