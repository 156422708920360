<style>
@media print {
    .a4-print-style {width: 210mm;height: 297mm;border: 1px solid #474647;background: white;}
    .a6-print-style {width: 105mm;height: 145mm;border: 1px solid #474647;background: white;}
  }
</style>
<div *ngIf="dataRpt?.Drugs?.length == 0" #reportContainer>[#NODATA#]</div>
<div *ngIf="dataRpt?.Drugs?.length > 0" class="a4-print-style" #reportContainer
  style="margin: 0px auto; overflow-y: auto;" [hidden]="isA6">
  <div class="pres-report2" style="background-color: white; height: 100%; width: 100%;">
    <div id="container" style=" height:100%;width:100%;">
      <div class="content" id="page-0-content">
        <div id="header" style="margin: 0px 0px;padding-top: 0.2cm;">
          <div style="width: 100%; line-height: 18px; text-align: center; font-weight: bolder; font-size: 16pt; ">管 制 藥 品 專 用 處 方 箋</div>
          <div style="width: 100%; line-height: 18px; font-size: 12pt; text-align: left;">處方日期：{{dataRpt.RegDate}}</div>
          <div style="width: 100%; line-height: 18px; font-size: 12pt; text-align: left;">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：{{dataRpt.PatientName}}&nbsp;&nbsp;&nbsp;&nbsp;性別：{{dataRpt.Sex}}&nbsp;&nbsp;&nbsp;&nbsp;生日：{{dataRpt.Birthday}}&nbsp;&nbsp;&nbsp;&nbsp;身分證：{{dataRpt.CID}}&nbsp;&nbsp;&nbsp;&nbsp;病歷號：{{dataRpt.PatientNo}}</div>
          <div style="width: 100%; line-height: 18px; font-size: 12pt; text-align: left;">疾病名稱：{{dataRpt.DxName}}</div>
        </div>
        <div style="-webkit-text-size-adjust: none; font-size: 12pt; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
          <!-- <div style="display: flex; column-gap: 5px;"> -->
            <table id="table-0-content" style="width: 100%; font-size: 12pt; text-align: center; border: none; table-layout: fixed; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <!-- <tbody> -->
                <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <th style="width: 120px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: left; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    藥品健保碼
                  </th>
                  <th style="text-align: left; vertical-align:bottom; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    管制藥品名稱及規格
                  </th>
                  <th style="width: 120px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    用量及服法
                  </th>
                  <th style="width: 50px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    次量
                  </th>
                  <th style="width: 55px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    單位
                  </th>
                  <th style="width: 55px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    單次調<br />劑日數
                  </th>
                  <th style="width: 70px; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                    單次調劑<br />總處方量
                  </th>
                </tr>
                <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <th colspan="7" style="border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden; white-space: nowrap; line-height: 0.9; font-weight: normal;">
                      ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                    </span>
                  </th>
                </tr>
              <!-- </tbody> -->
              <tbody *ngFor="let item of dataRpt.Drugs">
                <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.RxCode}}</td>
                  <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.RxName}}</td>
                  <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    {{(item.Way!=null&&item.Way!=""?item.Way+" ":"" )+ item.Freq}}</td>
                  <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.Dose}}</td>
                  <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.Unit}}</td>
                  <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.InfoDays}}</td>
                  <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.TotalDose}}</td>
                </tr>
              </tbody>
            </table>
          <!-- </div> -->
          <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden; white-space: nowrap; line-height: 0.9;">
            ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          </span>
        </div>
        <div style="display: flex;column-gap: 5px;margin:0px 0px 0px 0px;font-size: 12pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
          class="title2">
          開立處方醫療機構名稱 : {{dataRpt.NHICode}} {{dataRpt.ClinicName}}
        </div>
        <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 12pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;">
          <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
            <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑人員專業證<br/>書字號及簽章</td>
              <td></td>
              <td style="width: 20%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑<br/>日期&nbsp;&nbsp;</td>
              <td></td>
              <td style="width: 30%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑機構<br/>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;稱
              </td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">領受人<br/>簽&nbsp;&nbsp;&nbsp;&nbsp;名</td>
              <td> </td>
            </tr>
            <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{dataRpt.IsDispTP0?dataRpt.PharName:""}}</td>
              <td></td>
              <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{dataRpt.DispDate}}</td>
              <td></td>
              <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{dataRpt.IsDispTP0?dataRpt.ClinicName:""}}</td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;"></td>
              <td> </td>
            </tr>
          </table>
        </div>
        <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 12pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;"
          class="title2">
          領受人為
          <input type="checkbox" id="recipient1" name="recipient1" value="1" onclick="return false;">
          <label for="recipient1">本人</label>
          <input type="checkbox" id="recipient2" name="recipient2" value="2" onclick="return false;">
          <label for="recipient2">非本人</label>
          ,與病人關係:
          <input type="checkbox" id="relaction1" name="relaction1" value="3" onclick="return false;">
          <label for="relaction1">父母</label>
          <input type="checkbox" id="relaction2" name="relaction2" value="4" onclick="return false;">
          <label for="relaction2">子女</label>
          <input type="checkbox" id="relaction3" name="relaction3" value="5" onclick="return false;">
          <label for="relaction3">配偶</label>
          <input type="checkbox" id="relaction4" name="relaction4" value="6" onclick="return false;">
          <label for="relaction4">看護</label>
          <input type="checkbox" id="relaction5" name="relaction5" value="7" onclick="return false;">
          <label for="relaction5">護理人員</label>
          <input type="checkbox" id="relaction6" name="relaction6" value="8" onclick="return false;">
          <label for="relaction6">其他:
            <span
              style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          </label>
          <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden;white-space: nowrap; line-height: 0.9;">
            ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
          </span>
        </div>
        <div *ngIf="param?.CTRDrugPrintMethod == 1">
          <div *ngFor="let pDate of predates">
            <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 12pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;">
              <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑人員專業證<br/>書字號及簽章</td>
                  <td></td>
                  <td style="width: 20%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑<br/>日期&nbsp;&nbsp;</td>
                  <td></td>
                  <td style="width: 30%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">調劑機構<br/>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;稱
                  </td>
                  <td></td>
                  <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">領受人<br/>簽&nbsp;&nbsp;&nbsp;&nbsp;名</td>
                  <td> </td>
                </tr>
                <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{dataRpt.IsDispTP0?dataRpt.PharName:""}}</td>
                  <td></td>
                  <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{pDate}}</td>
                  <td></td>
                  <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;">{{dataRpt.IsDispTP0?dataRpt.ClinicName:""}}</td>
                  <td></td>
                  <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle;"></td>
                  <td> </td>
                </tr>
              </table>
            </div>
            <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 12pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;"
              class="title2">
              領受人為
              <input type="checkbox" id="recipient1" name="recipient1" value="1" onclick="return false;">
              <label for="recipient1">本人</label>
              <input type="checkbox" id="recipient2" name="recipient2" value="2" onclick="return false;">
              <label for="recipient2">非本人</label>
              ,與病人關係:
              <input type="checkbox" id="relaction1" name="relaction1" value="3" onclick="return false;">
              <label for="relaction1">父母</label>
              <input type="checkbox" id="relaction2" name="relaction2" value="4" onclick="return false;">
              <label for="relaction2">子女</label>
              <input type="checkbox" id="relaction3" name="relaction3" value="5" onclick="return false;">
              <label for="relaction3">配偶</label>
              <input type="checkbox" id="relaction4" name="relaction4" value="6" onclick="return false;">
              <label for="relaction4">看護</label>
              <input type="checkbox" id="relaction5" name="relaction5" value="7" onclick="return false;">
              <label for="relaction5">護理人員</label>
              <input type="checkbox" id="relaction6" name="relaction6" value="8" onclick="return false;">
              <label for="relaction6">其他:
                <span
                  style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </label>
              <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden;white-space: nowrap; line-height: 0.9;">
                ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
              </span>
            </div>
          </div>
        </div>
        <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 12pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
          class="title2">
          <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
            <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">處方(牙)醫師簽章</td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">管制藥品使用執照號碼</td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">(牙)醫師聯絡電話
              </td>
              <td></td>
            </tr>
            <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{dataRpt.DoctorName}}</td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{dataRpt.DrugControlNo}}</td>
              <td></td>
              <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{dataRpt.ClinicPhone}}
              </td>
              <td></td>
            </tr>
          </table>
        </div>
        <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; border-bottom:5px double black;font-size: 12pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
          class="title2">
          <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
            <tr style="line-height: 16px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <td colspan="2" style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">本處方箋限調劑一次</td>
              <td colspan="2" style="width: 20px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;"></td>
              <td colspan="2" style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">病患聯絡電話:{{dataRpt.Phone}}</td>
            </tr>
          </table>
        </div>
        <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size:10pt;" class="nobreak">
          <span style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size:10pt;">注意事項:</span>
          <ol class="nobreak" style="padding-left: 20px; margin: 0px 0px 0px 0px; padding-top: 0;">
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">本處方箋供門診及出院交付病患持向藥局領藥時使用。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">本處方箋與健保交付一般藥品調劑處方箋併用時，單次調劑應於同一藥局為之。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">本處方箋交付病患持向藥局領藥後，由藥局集中存放；若為連續處方箋，則每次調劑後，藥局須影印該連續處方箋留存備查，處方箋正本由病患收執。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">第一級及第二級管制藥品之專用處方箋，以調劑一次為限。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">處方之藥品屬麻醉藥品者，藥劑生不得調劑。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">處方內容有疑義或不完整時，調劑人員應與處方醫師聯絡確認後始予調劑。</li>
            <li style="line-height: 15px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">本處方得選擇在健保特約藥局或在就醫之醫療機構調劑。</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="dataRpt?.Drugs?.length > 0" class="a6-print-style" #reportContainerA6
  style="overflow-y: auto;" [hidden]="!isA6">
  
  <div style="background-color: white;height: 100%;width: 100%;" [ngStyle]="{
   width: sizePage.width + 'cm'}">

    <div class="pres-report2" style="background-color: white;">
      <div id="container">
        <div class="content" id="page-0-contentA6">
          <div id="header" style="margin: 0px 0px;padding-top: 0.2cm;">
            <div style="width: 100%; line-height: 10px; text-align: center; font-weight: bolder; font-size: 9pt; ">管 制 藥 品 專 用 處 方 箋</div>
            <div style="width: 100%; line-height: 10px; font-size: 6pt; text-align: left;">處方日期：{{dataRpt.RegDate}}</div>
            <div style="width: 100%; line-height: 10px; font-size: 6pt; text-align: left;">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名：{{dataRpt.PatientName}}&nbsp;&nbsp;&nbsp;&nbsp;性別：{{dataRpt.Sex}}&nbsp;&nbsp;&nbsp;&nbsp;生日：{{dataRpt.Birthday}}&nbsp;&nbsp;&nbsp;&nbsp;身分證：{{dataRpt.CID}}&nbsp;&nbsp;&nbsp;&nbsp;病歷號：{{dataRpt.PatientNo}}</div>
            <div style="width: 100%; line-height: 10px; font-size: 6pt; text-align: left;">疾病名稱：{{dataRpt.DxName}}</div>
          </div>
          <div style="-webkit-text-size-adjust: none; font-size:6pt; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <table id="table-0-content" style="width: 99%; font-size: 6pt; text-align: center; border: none; table-layout: fixed; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <th style="width: 17%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: left; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      藥品健保碼
                    </th>
                    <th style="text-align: left; vertical-align:bottom; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      管制藥品名稱及規格
                    </th>
                    <th style="width: 18%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      用量及服法
                    </th>
                    <th style="width: 8%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      次量
                    </th>
                    <th style="width: 8%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      單位
                    </th>
                    <th style="width: 10%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      單次調<br />劑日數
                    </th>
                    <th style="width: 10%; margin: 0px 0px 0px 0px; padding-bottom:0; padding-top:0; text-align: center; vertical-align:bottom; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 0px solid black;font-weight: normal;">
                      單次調劑<br />總處方量
                    </th>
                  </tr>
                  <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <th colspan="7" style="border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                      <span style="font-size: 6pt; line-height: 5px; width: 100%; display: inline-block; overflow: hidden; white-space: nowrap; font-weight: normal;">
                        ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                      </span>
                    </th>
                  </tr>
                <!-- </tbody> -->
                <tbody *ngFor="let item of dataRpt.Drugs">
                  <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.RxCode}}</td>
                    <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.RxName}}</td>
                    <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                      {{(item.Way!=null&&item.Way!=""?item.Way+" ":"" )+ item.Freq}}</td>
                    <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.Dose}}</td>
                    <td style="text-align: left; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.Unit}}</td>
                    <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.InfoDays}}</td>
                    <td style="text-align: right; border-top: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">{{item.TotalDose}}</td>
                  </tr>
                </tbody>
              </table>
            <span style="font-size: 6pt; line-height: 5px; width: 99%; display: inline-block; overflow: hidden; white-space: nowrap; line-height: 0.9;">
              ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            </span>
          </div>
          <div style="display: flex;column-gap: 5px;margin:0px 0px 0px 0px;font-size: 6pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
            class="title2">
            開立處方醫療機構名稱 : {{dataRpt.NHICode}} {{dataRpt.ClinicName}}
          </div>
          <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;">
            <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑人員專業證<br/>書字號及簽章</td>
                <td></td>
                <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑<br/>日期&nbsp;&nbsp;</td>
                <td></td>
                <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑機構<br/>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;稱
                </td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">領受人<br/>簽&nbsp;&nbsp;&nbsp;&nbsp;名</td>
                <td></td>
              </tr>
              <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{dataRpt.IsDispTP0?dataRpt.PharName:""}}</td>
                <td></td>
                <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{dataRpt.DispDate}}</td>
                <td></td>
                <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{dataRpt.IsDispTP0?dataRpt.ClinicName:""}}</td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;"></td>
                <td> </td>
              </tr>
            </table>
          </div>
          <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;"
            class="title2">
            領受人為
            <label for="recipient1">□本人</label>
            <label for="recipient2">□非本人</label>
            ,與病人關係:
            <label for="relaction1">□父母</label>
            <label for="relaction2">□子女</label>
            <label for="relaction3">□配偶</label>
            <label for="relaction4">□看護</label>
            <label for="relaction5">□護理人員</label>
            <label for="relaction6">□其他:
              <span
                style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </label>
            <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden;white-space: nowrap; line-height: 0.9;">
              ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
            </span>
          </div>
          <div *ngIf="param?.CTRDrugPrintMethod == 1">
            <div *ngFor="let pDate of predates">
              <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;">
                <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑人員專業證<br/>書字號及簽章</td>
                    <td></td>
                    <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑<br/>日期&nbsp;&nbsp;</td>
                    <td></td>
                    <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">調劑機構<br/>名&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;稱
                    </td>
                    <td></td>
                    <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">領受人<br/>簽&nbsp;&nbsp;&nbsp;&nbsp;名</td>
                    <td></td>
                  </tr>
                  <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{dataRpt.IsDispTP0?dataRpt.PharName:""}}</td>
                    <td></td>
                    <td style="width: 20%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{pDate}}</td>
                    <td></td>
                    <td style="width: 30%;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;">{{dataRpt.IsDispTP0?dataRpt.ClinicName:""}}</td>
                    <td></td>
                    <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; vertical-align: middle; font-size: 6pt;"></td>
                    <td> </td>
                  </tr>
                </table>
              </div>
              <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none;"
                class="title2">
                領受人為
                <label for="recipient1">□本人</label>
                <label for="recipient2">□非本人</label>
                ,與病人關係:
                <label for="relaction1">□父母</label>
                <label for="relaction2">□子女</label>
                <label for="relaction3">□配偶</label>
                <label for="relaction4">□看護</label>
                <label for="relaction5">□護理人員</label>
                <label for="relaction6">□其他:
                  <span
                    style="text-decoration: underline;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </label>
                <span style="line-height: 5px; width: 100%; display: inline-block; overflow: hidden;white-space: nowrap; line-height: 0.9;">
                  ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
                </span>
              </div>
            </div>
          </div>
          <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
            class="title2">
            <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">處方(牙)醫師簽章</td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">管制藥品使用執照號碼</td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">(牙)醫師聯絡電話
                </td>
                <td></td>
              </tr>
              <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <td style="width:25%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">{{dataRpt.DoctorName}}</td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">{{dataRpt.DrugControlNo}}</td>
                <td></td>
                <td style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">{{dataRpt.ClinicPhone}}
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          <div style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; border-bottom:5px double black;font-size: 6pt; text-align: left;  border-right: none; border-left: none; border-bottom: none; border-top: none;"
            class="title2">
            <table style="width: 100%; text-align: left; border: none; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <tr style="line-height: 7px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                <td colspan="2" style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">本處方箋限調劑一次</td>
                <td colspan="2" style="width: 20px; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;"></td>
                <td colspan="2" style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">病患聯絡電話:{{dataRpt.Phone}}</td>
              </tr>
            </table>
          </div>
          <div style="width: 99%; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;" class="nobreak">
            <span style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px; font-size: 6pt;">注意事項:</span>
            <ol class="nobreak" style="padding-left: 20px; margin: 0px 0px 0px 0px; padding-top: 0;">
              <li>本處方箋供門診及出院交付病患持向藥局領藥時使用。</li>
              <li>本處方箋與健保交付一般藥品調劑處方箋併用時，單次調劑應於同一藥局為之。</li>
              <li>本處方箋交付病患持向藥局領藥後，由藥局集中存放；若為連續處方箋，則每次調劑後，藥局須影印該連續處方箋留存備查，處方箋正本由病患收執。</li>
              <li>第一級及第二級管制藥品之專用處方箋，以調劑一次為限。</li>
              <li>處方之藥品屬麻醉藥品者，藥劑生不得調劑。</li>
              <li>處方內容有疑義或不完整時，調劑人員應與處方醫師聯絡確認後始予調劑。</li>
              <li>本處方得選擇在健保特約藥局或在就醫之醫療機構調劑。</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div  class="a6-print-style" #reportContainerEmpty
  style="margin: 0px auto; overflow-y: auto;" [hidden]="true">
  <div style="background-color: white;" [ngStyle]="{height: emtpysizePage.height + 'cm',
  width: emtpysizePage.width + 'cm',
  paddingTop: paddingPage.top + 'cm',
  paddingRight: paddingPage.right + 'cm',
  paddingBottom: paddingPage.bottom + 'cm',
  paddingLeft: paddingPage.left + 'cm'}">
    <div class="pres-report2" style="background-color: white;">
      <div id="container">

      </div>
    </div>
  </div>
</div> -->
