<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;

    }
  }
</style>

<div class="a4-print-style-h" #reportContainer style="overflow-y: auto;">
  <div class="pres-report" style="background-color: white; height: 100%; width: 100%;">
    <div id="container">
    </div>
  </div>
</div>

<!-- <div class="a4-print-style-h" #reportContainer>
  <div class="pres-report" style="background-color: white;height: 100%;width: 100%;">
    <div>
      <div style="text-align: center;font-weight: bolder; font-size: 16pt; ">健保申報明細摘要表
      </div>
    </div>
    <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;">
      <div style="font-size: 13pt;width: 100%;">
        <div style="width: 200px; display: inline-block;">頁數：第X頁</div>
        <div style="width: calc(100% - 400px); display: inline-block;">～{{data?.e11cs +" "+ data?.e11csName}}～</div>
        <div style="text-align:right; width: 200px;display: inline-block;padding-right: 5px;">製表日期：{{nowDate}}</div>
      </div>
      <div style="display: flex;column-gap: 5px;">
        <table style="width: 100%; font-size: 13pt; text-align: center;">
          <tr>
            <th style="width: 60px;">
              流水號
            </th>
            <th style="width: 72px;">
              起日期
            </th>
            <th style="width: 72px;">
              迄日期
            </th>
            <th style="width: 95px;">
              姓名
            </th>
            <th style="width: 109px;">
              身分證號
            </th>
            <th style="width: 48px;">
              卡序
            </th>
            <th style="max-width: 105px;">
              用量
            </th>
            <th>
              醫令
            </th>
            <th>
              傷病名稱
            </th>
            <th style="width: 45px;">
              日份
            </th>
            <th style="width: 95px;">
              診療費
            </th>
            <th style="width: 80px;">
              診察費
            </th>
            <th style="width: 105px;">
              總額
            </th>
            <th style="width: 80px;">
              部份負擔
            </th>
            <th style="width: 105px;">
              申請額
            </th>
            <th style="width: 60px;">
              醫師
            </th>
          </tr>
          <tr *ngFor="let item of data?.Detail">
            <td>
              {{item.e11sq}}
            </td>
            <td>
              {{item.e11da}}
            </td>
            <td>
              {{item.e11ed}}
            </td>
            <td>
              {{item.e11nm}}
            </td>
            <td>
              {{item.e11id}}
            </td>
            <td>
              {{item.e1129}}
            </td>
            <td>

            </td>
            <td style="text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 160px;max-width: 160px;">
              {{item.e12rx}}
            </td>
            <td style="text-align: left;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;max-width: 200px;">
              {{item.DxName}}
            </td>
            <td>
              {{item.e11dy}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{item.e11a6}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{item.e11a1}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{item.e11tt}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{item.e1198}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{item.e11ta}}
            </td>
            <td>
              {{item.Doctor}}
            </td>
          </tr>

          <tr>
            <td colspan="7">
            </td>
            <td colspan="2" style="text-align: right; padding-right: 15px;">
              <span style="margin-right: 10px;">合計：</span> {{data?.Subtotal.DxName}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{data?.Subtotal.e11a6}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{data?.Subtotal.e11a1}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{data?.Subtotal.e11tt}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{data?.Subtotal.e1198}}
            </td>
            <td style="text-align: right; padding-right: 5px;">
              {{data?.Subtotal.e11ta}}
            </td>
            <td>
            </td>
          </tr>

        </table>
      </div>
    </div>
  </div>

</div> -->
