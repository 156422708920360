<!--
  // ============ main layout =============
  // 1.top-area: patient and register info, function buttons
  // ---------------------------------------------
  // 2.middle
  // 2.1 left     | 2.2 inner middle | 2.3 right
  //      remark  |      vital signs |
  //      cc      |                  |
  //      chinese |      ?           |
  //      pe      |                  |
  //      diag    |                  |
  //      allergy |                  |
  // --------------------------------|------------
  // 3.bottom                        |
  //    orders                       | history list
  // =============================================
-->

<div orientation="vertical" class="splitter main-layout position-relative hist-record" style="height: 100%;"
  *ngIf="histRcd" [ngClass]="hasUploaded?'has-upload':''">
  <!-- top -->
  <div class="disabled-block2 position-absolute shadow" *ngIf="showBlock"
    [ngStyle]="{width:showBlock?'100%':'0%',height:showBlock? getDisabledBlockHeight():''}">
  </div>
  <div class="pane top-area" [ngClass]="[fontGreater?'font-great-on':'font-great-off',bigRatio===false?'':'big-ratio']">
    <div class="top-toolbar-area">
      <div class="disabled-block position-absolute shadow" *ngIf="isShowSearchDisabled" style="height: 62px;"
        [ngStyle]="{height:isShowSearchDisabled? getDisabledBlockHeight():''}">
      </div>

      <button type="button" style="display: none;" tabindex="-1" class="position-relative">Dummy - prevent enter
        click</button> <!-- [disabled]="detectUploaded" -->
      <button type="button" (click)="onBtnFinishClick()" [disabled]="hasUploaded" i18n="@@HistFinish"
        class="position-relative finish">完診<span [ngClass]="hasUploaded? 'disabled'
                              : 'vis-btn-block'">F2</span></button>
      <button type="button" (click)="onBtnSaveTemplateClick()" [disabled]="hasUploaded"
        [hidden]="entranceType=='Declare'" i18n="@@HistSaveAsTemplate" class="position-relative">組入<span
          [ngClass]="hasUploaded? 'disabled' : 'vis-btn-block'">F3</span></button>
      <button type="button" (click)="onBtnClearClick()" [disabled]="hasUploaded" [hidden]="entranceType=='Declare'"
        i18n="@@HistClear" class="position-relative">清除<span
          [ngClass]="hasUploaded? 'disabled' : 'vis-btn-block'">F4</span></button>
      <button type="button" (click)="onBtnLoadTemplateClick()" [disabled]="hasUploaded"
        [hidden]="entranceType=='Declare'" i18n="@@HistTemplate" class="position-relative">組合<span
          [ngClass]="hasUploaded? 'disabled' : 'vis-btn-block'">F6</span></button>
      <button type="button" (click)="onBtnRegEditClick()" [disabled]="hasUploaded" [hidden]="entranceType=='Declare'"
        i18n="@@RegisterEdit" class="position-relative">變更<span
          [ngClass]="hasUploaded? 'disabled' : 'vis-btn-block'">F7</span></button>
      <button type="button" (click)="onBtnLabReportClick()" [disabled]="hasUploaded" [hidden]="entranceType=='Declare'"
        i18n="@@HistLabReport" class="position-relative">檢驗</button>
      <button type="button" *ngIf="isEnabledPACS" (click)="onBtnLoadPACSClick()" [disabled]="hasUploaded"
        [hidden]="entranceType=='Declare'" i18n="@@LoadPACS" class="position-relative">影像</button>
      <button type="button" (click)="onBtnDMClick()" i18n="@@HistDiabetes" [hidden]="!IsEnableDiabetes"
        [disabled]="hasUploaded" class="position-relative">糖尿病</button>
      <!-- <button type="button" (click)="onBtnSaveClick()" [attr.disabled]="btnSaveDisabledAttr" i18n="@@HistSaveAndExit"
        class="position-relative">儲存並離開</button> -->
      <button type="button" (click)="onBtnExitClick()" i18n="@@HistExit" class="position-relative">中斷<span
          class="vis-btn-block">F9</span></button>
      <button *ngIf="useReferral" type="button" (click)="onBtnReferralClick()" i18n="@@HistReferral"
        class="position-relative" [disabled]="hasUploaded" [hidden]="entranceType=='Declare'">轉診</button>
      <button type="button" [appOpenDialog]="readIcDialog" i18n="@@ReadIC" class="position-relative"
        [disabled]="hasUploaded">讀IC</button>
      <button type="button" (click)="onBtnDiagCertClick()" i18n="@@DiagCert" class="position-relative"
        title="診斷證明書">診斷書</button>
      <button type="button" (click)="onBtnAppoinmentClick()" i18n="@@DiagCert" class="position-relative"
        title="預約掛號">預約</button>
      <div class="position-relative float-right" style="width:45%; white-space: nowrap; height: 25px; left: 5px; right: 50px;">
        <ng-container *ngFor="let item of nextRegisters">
          <button type="button" (click)="onNextRegClick(item)" i18n="@@ReadIC" class="position-relative"
            title="{{ getPatientInfo(item) }}"
            style="font-weight: bold; font-size: 18px; color: red; border-color: blue;">{{ getPatient(item.WaitNo,
            item.PatientName) }}</button>&nbsp;
        </ng-container>
      </div>

      <div class="position-absolute float-right" style="right: 1px; z-index: 1;">
        <ng-container>
          <button type="button" (click)="onBtnHelperPadClick(false)" (contextmenu)="onBtnHelperPadClick(true)"
            [disabled]="hasUploaded" i18n="@@HistHelperPad">配盤</button>
        </ng-container>
      </div>
    </div>

    <span class="info-item"
      [ngStyle]="{'cursor' : (histRcd.Register.IType === '0'||this.histRcd.Register.IType === '2'||histRcd.Register.IType==='3') || histRcd.Register.StatusCode == 'F'  ? 'pointer' : 'default'}"
      (click)="doChangeRegDate()">
      <ng-container i18n="@@Date">日期</ng-container>:
      {{ histRcd.Register.RegDate | formatDate:'' }} {{ getRegTime() }}
      <mat-icon
        *ngIf=" (histRcd.Register.IType === '0'||this.histRcd.Register.IType === '2'||histRcd.Register.IType==='3') || histRcd.Register.StatusCode == 'F'"
        class="mat-icon notranslate material-icons mat-icon-no-color text-sm top-2 position-relative"
        title="更改掛號日期">edit</mat-icon>
    </span>
    <span class="info-item">
      <ng-container i18n="@@RegWaitNo">看診號</ng-container>:
      {{histRcd.Register.WaitNo}}
    </span>
    <span class="info-item">
      <ng-container i18n="@@PatientNo">病歷號</ng-container>:
      {{histRcd.Patient.PatientNo | trim:'0':'start'}}
      <!-- <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color text-sm top-2 position-relative"
      title="診斷證明書">file_copy</mat-icon> -->
    </span>
    <span class="info-item info-name" style="cursor: pointer;" (click)="onBtnPatientClick()">
      {{histRcd.Patient.CName}}
      <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color text-sm top-2 position-relative"
        title="修基">edit</mat-icon>
    </span>
    <span class="info-item">
      {{histRcd.Patient.SexName}}
    </span>
    <span class="info-item">
      <ng-container i18n="@@RegWaitNo">出生日</ng-container>:
      {{histRcd.Patient.Birthday | formatDate:''}}
    </span>
    <span class="info-item info-name">
      {{histRcd.Patient.Birthday | fullage}}
    </span>
    <span class="info-item">
      {{histRcd.Register.ICodeName + (histRcd.Register.IType === '3'?'　欠卡':'')+(histRcd.Register.Icno
      ?'　'+histRcd.Register.Icno :'')}}
    </span>
    <span class="info-item">
      <button type="button" (click)="unlockAll($event)" [disabled]="!hasUploaded" class="position-relative"
        [ngClass]="hasUploaded?'has-upload-animate':''">解鎖</button>
    </span>
    <!-- 批次重匯EMR -->
    <span *ngIf="forEC" class="info-item">
      <button type="button" (click)="onBtnBatchEMR()" i18n="@@UseNhiimi" class="position-relative">EMR</button>
    </span>
    <!-- 批次重匯EMR -->
    <!-- <span class="info-item">
      <button type="button" (click)="onBtnCrossInqiryClick()" [disabled]="hasUploaded||(!useNhiimi)" i18n="@@UseNhiimi"
        class="position-relative">跨</button>
    </span> -->
    <span class="info-item pl-0">
      <button type="button" class="title-btn2 orders-btn background-none border-0" (click)="openExecutorAndDateTime()">
        <mat-icon title="小時鐘-醫令執行醫事人員及起訖時間輸入" class="cursor-pointer vis-combine-icon order-icons text-2xl"
          style="top: -8px;">access_time</mat-icon>
      </button>
    </span>
    <span class="info-item mt-0 mr-0">
      <ng-container *ngIf="enableTeleMed">
        <mat-icon title="呼叫-視訊看診" *ngIf="this.histRcd.Register.StatusCode !== 'F'" (click)="videoCall()"
          class="vis-combine-icon element-icon text-2xl cursor-pointer float-right">video_call</mat-icon>
        <mat-icon title="回放-視訊看診" *ngIf="this.histRcd.Register.StatusCode == 'F'" (click)="videoReplay()"
          class="vis-combine-icon element-icon text-2xl cursor-pointer float-right">video_call</mat-icon>
      </ng-container>
    </span>
    <span class="info-item mt-0 mr-0">
      <button type="button" class="title-btn2 orders-font-btn background-none border-0" title="家護" i18n="@@HouseCare"
        [ngStyle]="{color:priceControl.ishouseCare?'rgba(245, 36, 28, 0.884)':''}"
        (click)="onHouseCareClick()">家</button>
      <!-- <mat-icon title="家護"  [ngStyle]="{color:priceControl.ishouseCare?'green':''}" (click)="onHouseCareClick()" class="vis-combine-icon element-icon text-2xl cursor-pointer float-right">airline_seat_individual_suite</mat-icon> -->
    </span>
    <span>
      <ng-container *ngIf="(useNhiimi||useNhiimiSpe)&&(!hasUploaded)">
        <app-icon-cross title="雲端藥歷" (click)="onBtnCrossInqiryClick()" i18n="@@cloudHist"
          [disabled]="hasUploaded||(!useNhiimi&&!useNhiimiSpe)" [cssName]="openCloudHistLink ? 'open-cloud-hist' : ''"
          class="cursor-pointer ml-2 mr-1">
        </app-icon-cross>
      </ng-container>
    </span>
    <div class="position-absolute left-0 top-0 bg-hist overflow-y-auto end-0 panel-shadow" style="z-index: 1002;"
      [ngStyle]="{height:isShowSearchDisabled? getDisabledBlockHeight():'', width:isShowSearchDisabled?getSearchPadWidth():''}">
      <app-hist-search [sdxlist]="sdxListData" [histdiags]="histdiags" [dxList]="dxListData" #histSearch
        (searchedEmit)="getSearchedEmit($event);isShowSearch==$event" *ngIf="isShowSearchDisabled"></app-hist-search>
    </div>
  </div>
  <!-- middle -->
  <div class="pane middle-panel" [ngClass]="middleCls" style="display: flex; flex-direction: row;">
    <!-- subjective-panel (left panel) 主觀項目 -->
    <div class="pane subjective-panel border-bottom-0 border-right-0 position-relative" 
         style="height: 100%; flex-direction: row; display: flex; width: 70%; z-index:995;"
      [style.display]="pnlLeft.displayStyle">
      <div class="d-flex" style="height: 100%;">
        <div class="position-relative d-flex" style="flex-grow:1; z-index: 100;">
          <!-- 固定配盤  -->
          <div class="splitter position-relative d-inline-block" style="display: flex;flex-wrap: wrap;"
            [ngStyle]="{width:isHelperPadOpened?'calc(100% - 400px)':'100%'}">
            <!-- inner-middle panel -->
            <div class="d-flex" style="width: 100%; height: 40%; display: flex; flex-direction: row;">
              <div class="pane position-relative" style="flex-direction: column; display: flex;"
                [ngStyle]="{width:isHelperPadOpened?'':'calc(40%)'}">
                <div class="pane dental-remark-panel" style="width: 100%;" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                  [style.height]="pnlRm.heightStyle==='100%' ? '95%' : expandableHeight"
                  [ngStyle]="{minHeight:''}">
                  <div orientation="horizontal" class="splitter position-relative">
                    <div class="pane title-column d-inline-block">
                      <button type="button" class="title-btn2 position-relative" [disabled]="hasUploaded"
                        style="height: 20px;left: -1.5px;word-break: keep-all;"
                        (click)="openRemarkWindow($event)">特記</button>
                      <!-- <button type="button" class="title-btn2 orders-btn position-relative background-none border-0"
                        style=" min-height: 20px;left: 1px;" (click)="maxPanel(pnl_rm, $event)">
                        <ng-container *ngIf="maxRmOpen">
                          <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">call_to_action</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!maxRmOpen">
                          <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">web_asset</mat-icon>
                        </ng-container>
                      </button> -->
                    </div>
                    <div class="pane content-column one-line-panel">
                      <!-- component/input -->
                      <div style="height:100%;">
                        <textarea #remarkComp (focus)="focusToRemark()" (blur)="blurFromRemark()" class="rcd-textarea"
                          [disabled]="hasUploaded" [(ngModel)]="this.histRcd.Patient.Remark"
                          style="resize: none;"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- chief complaint -->
                <div class="pane dental-cc-panel" style="width: 100%;" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                  [style.height]="pnlCC.heightStyle==='100%' ? '95%' : expandableHeight"
                  [ngStyle]="{minHeight:''}">
                  <div orientation="horizontal" class="splitter position-relative">
                    <div class="pane title-column position-relative">
                      <span title="Chief Complaint" i18n="@@HistCC">主訴</span>
                      <!-- <button type="button" class="title-btn2 orders-btn position-relative background-none border-0"
                        style="left: 1px;top:-3px" (click)="maxPanel(pnl_cc, $event)">
                        <ng-container *ngIf="maxCCOpen">
                          <mat-icon
                            class="vis-combine-icon element-icon btn-scale-85 text-2xl">call_to_action</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!maxCCOpen">
                          <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">web_asset</mat-icon>
                        </ng-container>
                      </button> -->
                    </div>
                    <div class="pane content-column two-line-content-panel">
                      <!-- ===== CC Component ===== -->
                      <app-hist-helper-textarea-cc #ccComp [content]="histRcd.Hist.CC" [isFocusAfterInit]="true">
                      </app-hist-helper-textarea-cc>
                    </div>
                  </div>
                </div> 
  
                <!-- physical examination -->
                <div class="pane dental-pe-panel" style="width: 100%;" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                  [style.height]="pnlPE.heightStyle==='100%' ? '95%' : expandableHeight"
                  [ngStyle]="{minHeight:''}">
                  <div orientation="horizontal" class="splitter position-relative">
                    <div class="pane title-column position-relative">
                      <span title="Physical Examination position-relative" i18n="@@HistPE">理學</span>
                      <!-- <button type="button" class="title-btn2 orders-btn position-relative background-none border-0"
                        style="left: 1px;top: -3px;" (click)="maxPanel(pnl_cc, $event)">
                        <ng-container *ngIf="maxCCOpen">
                          <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">call_to_action</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="!maxCCOpen">
                          <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">web_asset</mat-icon>
                        </ng-container>
                      </button> -->
                    </div>
                    <div class="pane content-column two-line-content-panel">
                      <!-- ===== PE component ===== -->
                      <app-hist-helper-textarea-pe #peComp [content]="histRcd.Hist.PE" [disabled]="hasUploaded">
                      </app-hist-helper-textarea-pe>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- ===== 牙位圖 ===== [style.height]="pnlRm.heightStyle==='100%'||pnlMeasure.heightStyle==='100%'? '95%': expandableHeight" -->
              <div class="pane dental-panel" [ngStyle]="{width:isHelperPadOpened?'calc(95%)':'calc(60%)'}" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                [style.display]="pnlInnerMiddle.displayStyle">
                <div orientation="horizontal" class="splitter">
                  <div class="pane title-column d-inline-block position-relative">
                    <span title="Tooth Position Map" i18n="@@ToothPosMap">牙位</span>
                    <!-- <button type="button" class="title-btn2 orders-btn position-relative background-none border-0"
                      style="margin-top: -9px;left: 1px;" (click)="maxPanel(pnl_rm, $event)">
                      <ng-container *ngIf="maxRmOpen">
                        <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">call_to_action</mat-icon>
                      </ng-container>
                      <ng-container *ngIf="!maxRmOpen">
                        <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">web_asset</mat-icon>
                      </ng-container>
                    </button> -->
                  </div>
                  <div class="pane content-column one-line-panel overflow-hidden" 
                    style="display: table-cell; vertical-align: middle; text-align: center; border: solid 1px #aaa;">
                    <app-tooth-pos-map [test]="true" style="flex-grow: 1;" 
                    [isHelperPadOpened]="isHelperPadOpened?'is-helper-pad-opened':'is-helper-pad-close'"></app-tooth-pos-map>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="d-flex position-relative" style="width: 100%; height: 60%;">
              <div class="pane position-relative" style="flex-direction: column; display: flex; width: 72%;">
                <!-- diags [style.height]="(TotalDiagnosis*30/2).toString()+'px'"-->
                <div class="pane dx-panel w-100" style="width: 100%;" id="diagPanel" [style.height]="pnlDiag.heightStyle==='100%'?'40%':''">
                  <div orientation="horizontal" class="splitter">
                    <div class="pane title-column">
                      <span title="Diagnosis" i18n="@@HistDiag">
                        <button type="button" class="title-btn2 position-relative" [disabled]="hasUploaded"
                          style="height: 20px;left:-1.5px; word-break: keep-all;"
                          (click)="openDiagnosisWindow($event)">診斷</button>
                      </span>
                    </div>
                    <div class="pane content-column one-line-content-panel">
                      <!-- ===== Diags Component  ===== (emitShowSearch)="isShowSearchDisabled = $event;isHelperPadOpened = $event"-->
                      <app-hist-diags #diagsComp [diags]="histRcd.Hist.Diags" [disabled]="hasUploaded"
                        [isShowSearch]="isShowSearch" (codeChanged)="dxChagned($event)"></app-hist-diags>
                    </div>
                  </div>
                </div>
  
                <!-- allergy -->
                <div class="pane allergy-panel w-100" style="width: 100%;" [style.height]="pnlAlg.heightStyle">
                  <div orientation="horizontal" class="splitter">
                    <div class="pane title-column">
                      <span title="Drug Allergy">
                        <button type="button" class="title-btn2 position-relative" [disabled]="hasUploaded"
                          style="height: 20px;left:-1.5px; word-break: keep-all;"
                          (click)="openDrugAlgWindow($event)">過敏</button>
                      </span>
                    </div>
                    <div class="pane content-column one-line-content-panel">
                      <!-- component/input -->
                      <div style="height:100%;">
                        <!--[disabled]="hasUploaded" [(ngModel)]="this.histRcd.Patient.DrugAllergy" -->
                        <textarea #allergyComp (focus)="focusToAllergy()" (blur)="blurFromAllergy()" class="rcd-textarea"
                          [disabled]="true" [value]="displayAllergies()" style="resize: none;"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- 牙科處置 -->
                <div class="pane dx-panel w-100" style="width: 100%; height: calc(50% - 40px);">
                  <div orientation="horizontal" class="splitter">
                    <div class="pane orders-panel" [style.display]="pnlOrder9.displayStyle" [style.width]="pnlOrder9.widthStyle">
                      <div orientation="horizontal" class="splitter">
                        <div class="pane title-column">
                          <span title="Order9" i18n="@@HistOrder9">處置</span>
                        </div>
                        <div id="order-edit-panel" class="pane" style="border: solid 1px #aaa;" [ngStyle]="{width:isHelperPadOpened?getOrderListAdjustWidth():'calc(100% - 32px)'}">
                          <app-hist-orders #ordersDentalComp [allergies]="allergies" [disabled]="hasUploaded" [displayTabs]="false" 
                          [orders]="orderHrx" [hiddenWhenOpen]="isPricingWinOpened" [isCopy]="isCopy" [isDental]="true"></app-hist-orders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 

                <div class="pane dx-panel w-100" style="width: 100% !important; height: calc(50% - 33px);">
                  <div orientation="horizontal" class="splitter">
                    <div class="pane orders-panel" [style.display]="pnlOrder.displayStyle" [style.width]="pnlOrder.widthStyle">
                      <div orientation="horizontal" class="splitter">
                        <div class="pane title-column">
                          <span title="Orders" i18n="@@HistOrders">處方</span>
                        </div>
                        <div id="order-edit-panel" class="pane" style="border: solid 1px #aaa;" [ngStyle]="{width:isHelperPadOpened?getOrderListAdjustWidth():'calc(100% - 32px)'}">
                          <!-- 先 bind editOptions, 再 bind資料, 不然資料先進去render時會找不到 editOptions -->
                          <app-hist-orders #ordersComp [allergies]="allergies" [disabled]="hasUploaded" [displayTabs]="false" 
                          [orders]="orderHrx" [hiddenWhenOpen]="isPricingWinOpened" [isCopy]="isCopy"></app-hist-orders>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 

              </div>
              
              <div id="treatHistList" class="pane position-relative" style="flex-direction: column; display: flex; width: 28%; height: 100%;">
                <div class="pane" style="width: 100%; height: 50%; border: solid 1px #aaa" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                  [style.display]="pnlInnerMiddle.displayStyle">
                  <div class="title-row">
                    <span i18n="@@PeriodcItemList">週期性項目</span>
                  </div>
                  <div class="content-row overflow-y-hidden" style="background-color: #f3f3f3;">
                    <app-periodic-grid></app-periodic-grid>
                  </div>
                </div>
  
                <div class="pane" style="width: 100%; height: 50%; border: solid 1px #aaa" [ngClass]="[pnlLeft.isMin?'d-none':'d-inline-block']"
                  [style.display]="pnlInnerMiddle.displayStyle">
                  <div class="title-row">
                    <span i18n="@@ClinicHistoryList">院內病歷</span>
                  </div>
                  <div class="content-row overflow-y-hidden" style="background-color: #f3f3f3;">
                    <app-clinic-history-grid></app-clinic-history-grid>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="d-fex position-relative" *ngIf="isHelperPadOpened">
            <!-- <div class="position-absolute left-0 top-0 bg-hist overflow-y-auto" style="z-index: 1002;"
              [ngStyle]="{height:isShowSearchDisabled? getDisabledBlockHeight():'', width:isShowSearchDisabled?getSearchPadWidth():''}">
              <app-hist-search #histSearch (searchedEmit)="getSearchedEmit($event);isShowSearch==$event" *ngIf="isShowSearchDisabled"></app-hist-search>
            </div> -->
            <app-hist-helper-edit-pad class="pad-width-small">
            </app-hist-helper-edit-pad>
          </div>
        </div>
      </div>
    </div><!-- ./left panel -->
    <!-- right panel -->
    <div class="pane history-panel position-relative" style="height: 100%; flex-direction: column; display: flex; width: 30%;">
      <div class="pane" style="height: 50%;">
        <div class="title-row">
          <span i18n="@@HistHistory">病歷</span>
          <button type="button" class="title-btn2 orders-btn position-relative background-none border-0"
            style="left: 1px;top: -5px;transform: scale(0.8);" (click)="maxPanel(pnl_hist, $event)">
            <ng-container *ngIf="!minHist">
              <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">call_to_action</mat-icon>
            </ng-container>
            <ng-container *ngIf="minHist">
              <mat-icon class="vis-combine-icon element-icon btn-scale-85 text-2xl">web_asset</mat-icon>
            </ng-container>
          </button>
        </div>
        <div class="content-row history-layout"
          [ngClass]="[maxOrderOpen?'max-panel-height-open':'max-panel-height-close']">
          <!--歷史紀錄檢視-->
          <!-- ===== histView Component ===== -->
          <app-shared-hist-hist-view #histViewComp (emitInsert)="onHistViewEmitInsert($event)" [minHist]="minHist"
            [IsShowToolBar]="true" [disabled]="hasUploaded">
          </app-shared-hist-hist-view>
        </div>
      </div>
      
      <!-- history list panel -->
      <!-- <div class="pane history-list-panel" [style.display]="pnlHistList.displayStyle"
      [style.width]="pnlHistList.widthStyle"> -->
      <div class="pane history-list-panel" [style.display]="pnlHistList.displayStyle" style="width: 100%; height: 50%;">
        <div class="title-row">
          <span i18n="@@HistHistoryList">病歷清單</span>
        </div>
        <div class="content-row overflow-y-hidden" style="background-color: #f3f3f3;">
          <!--歷史紀錄清單-->
          <!-- ===== histList Component ===== -->
          <app-hist-list #histListComp [patient]="histRcd.Patient" (emitSelect)="onHistListEmitSelect($event)"
            (emitSelectToInsert)="onHistListEmitSelectToInsert($event)">
          </app-hist-list>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- sidebar -->
    <div class="pane sidebar">
      <div class="sidebar-btn" (click)="btnSidebarSwitchClick()">
        <span *ngIf="pnlRight.isMin" i18n="@@Open">開啟</span>
        <span *ngIf="!pnlRight.isMin" i18n="@@Close">關閉</span>
      </div>
    </div>
    
  </div><!-- ./middle -->
</div>
<!-- opd helper: app-vis-window需放在最上層主元件，因為是共用的，且有spliter遮蔽問題 -->
<!-- TODO edit window -->
<!-- [isOpened]="isHelperNewPadOpened" *ngIf="isHelperNewPadOpened" -->

<app-hist-helper></app-hist-helper>
<!-- 病患Remark選項視窗 -->
<app-shared-patient-remark-dialog *ngIf="this.histRcd" [isDialogOpened]="isRemarkWindowOpened" #PatientRemarkComp
  [selectedText]="this.histRcd.Patient.Remark" (emitResult)="getRemarkWindowResult($event)">
</app-shared-patient-remark-dialog>
<!-- 病患Drug Allergy選項視窗 -->
<app-shared-patients-drug-allergy *ngIf="this.histRcd" [isDialogOpened]="isDrugAlgWindowOpened" #DrugAllergyComp
  [selectedText]="this.histRcd.Patient.DrugAllergy" (emitResult)="getDrugAlgWindowResult($event)">
</app-shared-patients-drug-allergy>
<!-- 離開確認視窗 -->

<!-- 存入範本視窗-->
<app-template-save [isTmplWinOpened]="isTmplWinOpened" (winClose)="isTmplWinOpened=false"></app-template-save>
<!-- 取得範本視窗 -->
<app-vis-dialog title="載入範本" [height]="'98%'" [show]="isLoadTmplWinOpened" (close)="closeLoadTmplWin()"
  [superWidth]="'95%'" [maxWidth]="'98vw'" i18n-title="@@HistLoadTemplate">
  <app-hist-tmpl-view *ngIf="isLoadTmplWinOpened" [filterId]="packageId"
    (emitInsert)="onTmplViewEmitInsert($event)"></app-hist-tmpl-view>
</app-vis-dialog>

<!-- 轉診視窗 [height]="screenHeight - 100" -->
<app-vis-dialog title="轉診轉檢單" [maxWidth]="'80vw'" [superWidth]="'80%'" [height]="screenHeight - 100"
  [show]="isReferralWinOpened" (close)="closeReferralWin()" i18n-title="@@ReferralForm">
  <form [formGroup]="referralFormGroup">
    <div [ngStyle]="{height:getReferralDialogHeight()}" style="width: 100%; overflow-x: hidden; overflow-y: auto;">
      <app-referrals-referral-form-dialog-main *ngIf="isReferralWinOpened" [queryPatientNo]="histRcd.Patient.PatientNo"
        [oriRef]="referralOut" [histRcd]="refHistRcd" [referralFormGroup]="this.referralFormGroup">
      </app-referrals-referral-form-dialog-main>
    </div>
  </form>
  <app-vis-dialog-actions>
    <button type="button" (click)="ReferralSaveAs()" primary="true" i18n="@@Save">確定</button>
    <button type="button" (click)="closeReferralWin()" primary="true" i18n="@@Close">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 轉診單列印 -->
<div *ngIf="printServiceForReferral.isPrinting" id="toPrint">
  <app-print-layout></app-print-layout>
</div>

<!-- 批價視窗 -->
<app-rating-price *ngIf="isPricingWinOpened" [entranceType]="entranceType" [initPrintPara]="ratingPriceResult" [hst001Param]="hst001"
  [isPricingOpened]="isPricingWinOpened" [histRecord]="histRcd" [ischangeOwnByUrSerf]="isChangeOwn" [isSaving]="false"
  [isControlValue]="priceControl" (controlEvent)="isControlEvent($event)" (ischangePillDisp)="ischangePillDisp($event)"
  (onReferralOpen)="onBtnReferralClick()" (ischangeOwn)="isChangeOwnEvent($event)" (closed)="closePricingWin($event)"
  #ratingPrice></app-rating-price>
<!-- 檢驗視窗 -->
<app-vis-dialog title="檢驗報告" [show]="isLabReportWinOpened" (close)="closeLabReportWin()" [maxWidth]="'95vw'"
  [superWidth]="'95%'" [height]="'95%'" i18n-title="@@LabReport">
  <app-labs-report-query-dialog-main *ngIf="isLabReportWinOpened" [queryPatientNo]="histRcd.Patient.PatientNo"
    [queryPatientName]="histRcd.Patient.CName">
  </app-labs-report-query-dialog-main>
  <app-vis-dialog-actions>
    <button type="button" (click)="closeLabReportWin()" primary="true" i18n="@@Close">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
<!-- DM視窗 -->
<app-vis-dialog title="糖尿病" [show]="isDMWinOpened" (close)="closeDMWin()" [height]="'95%'" i18n-title="@@Diabetes">
  <app-hist-dm-demo *ngIf="isDMWinOpened&&histRcd" [PatientName]="histRcd.Patient.CName"></app-hist-dm-demo>
  <app-vis-dialog-actions>
    <button type="button" (click)="closeDMWin()" primary="true" i18n="@@Close">關閉</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
<!--msg text-->
<app-msg-text textId="MSG_HistRecord1" i18n="@@MSG_HistRecord1">存入範本需有主訴，理學或處方資料</app-msg-text>
<app-msg-text textId="MSG_HistErrorCode1" i18n="@@MSG_HistErrorCode1">診斷不能重複</app-msg-text>
<app-msg-text textId="MSG_HistErrorCode2" i18n="@@MSG_HistErrorCode2">處方需填入劑量，頻率，日數 -
  [[0]]
</app-msg-text>
<app-msg-text textId="MSG_HistErrorCode3" i18n="@@MSG_HistErrorCode3">藥品與處方不可重複 -
  [[0]]
</app-msg-text>
<app-msg-text textId="MSG_HistRecord2" i18n="@@MSG_HistRecord2">此處方已結帳</app-msg-text>
<app-msg-text textId="MSG_HistOrders1" i18n="@@MSG_HistOrders1">搜尋處方時發生錯誤</app-msg-text>
<app-msg-text textId="MSG_HistOrders2" i18n="@@MSG_HistOrders2">找不到代碼</app-msg-text>
<app-msg-text textId="MSG_HistOrders3" i18n="@@MSG_HistOrders3">設定處方資料時發生錯誤</app-msg-text>
<app-msg-text textId="MSG_HistOrders4" i18n="@@MSG_HistOrders4">已達處方數目上限，無法加入新處方!</app-msg-text>
<app-msg-text textId="MSG_HistRecord5" i18n="@@MSG_HistRecord5">中藥飲片頁箋已達數量上限.</app-msg-text>
<ng-template #reg>
  <app-register></app-register>
</ng-template>


<app-vis-dialog title="IC卡" [width]="0" [height]="icViewComp.getRealHeight()>455?'95%':''" [maxWidth]="'100vw'"
  [superWidth]="'98%'" [position]="{top:'5px', bottom:'', left:'', right:''}" #readIcDialog>
  <app-ic-view (close)="readIcDialog.show=false" #icViewComp></app-ic-view>
</app-vis-dialog>

<!-- 變更F7 -->
<app-vis-dialog title="掛號" [maxWidth]="'99vw'" [superWidth]="'99%'" [show]="isShowRegDialog"
  (close)="isShowRegDialog=false;">
  <div *ngIf="isShowRegDialog">
    <app-register [openRegId]="initRegId" [isFromCompEnter]="true" [changeToSelfPay]="changeToSelfPay"
      (ExitRegisterEvent)="onExitRegister($event)"></app-register>
  </div>
</app-vis-dialog>

<!-- 修改基資 -->
<app-vis-dialog title="病患基本資料" [maxWidth]="'98vw'" [superWidth]="'98%'" [height]="screenHeight - 100"
  [show]="isShowPatDialog" (close)="isShowPatDialog=false;">
  <app-patients-edit class="inside-scrollbar" *ngIf="isShowPatDialogEdit" [patientId]="patientId"
    [isFromCompEnter]="true" (ExitPatEvent)="onExitPatEvent($event)"></app-patients-edit>
</app-vis-dialog>

<!-- 診斷證明書列印 -->
<app-vis-dialog title="診斷證明書列印" [maxWidth]="'100vw'" [superWidth]="'100%'" [height]="screenHeight - 100"
  [show]="isShowDiagCertDiaglog" (close)="closeDiagCertClick()">
  <app-diagnosiscert class="inside-scrollbar" [patientData]="patData" *ngIf="isShowDiagCertDiaglog"></app-diagnosiscert>
</app-vis-dialog>

<!-- 更改掛號日期 -->
<app-vis-dialog title="更改看診日期/時間" [show]="isChangeRegDateDialogOpened" (close)="isChangeRegDateDialogOpened=false"
  [minWidth]="200" [width]="320" i18n-title="@@NewRegDate">
  <div class="card-body pb-0" style="font-size: 16px;">
    <form [formGroup]="RegDateFG">
      <div class="row">
        <div class="col-sm-12">
          <label class="vis-form-field">
            <span i18n="@@RegDate">看診日期</span>
            <app-vis-datepicker class="w-100" formControlName="RDate" autocomplete="off"
              style="display: inline-block;flex-grow: 1;" [max]="today" (blurChange)="onBlurDate($event)">
            </app-vis-datepicker>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-for m-field">
            <span i18n="@@RegTime">看診時間</span>
            <app-vis-timepicker class="w-100" style="display: inline-block;flex-grow: 1;" formControlName="RTime"
              autocomplete="off" [showSecond]="true">
            </app-vis-timepicker>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-for m-field">
            <span i18n="@@MRoomCode">診間</span>
            <app-vis-combobox formControlName="RoomCode" [data]="medRoom" [textField]="'text'" class="required"
              [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true"
              [customClass]="'bg-white d-inline-block max-w-18'">
            </app-vis-combobox>
          </label>
        </div>
        <div class="col-sm-12">
          <label class="vis-for m-field">
            <span i18n="@@Doctor">醫師</span>
            <app-vis-dropdownlist formControlName="DoctorId" [data]="doctors" [textField]="'text'"
              [valueField]="'value'" [valuePrimitive]="true" class=" d-inline-block max-w-28">
            </app-vis-dropdownlist>
          </label>
        </div>
        <label style="color: red;">注意：1.更改看診日期時間，可能會影響IC2.0每日上傳之就醫識別碼，造成檢核錯誤；2.醫師欄位，開放更改只在完診之後才能修正，且修改後需再次進行每日上傳</label>
      </div>
    </form>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onChangeRegDateDialogClose('sure')" i18n="@@Sure">確定</button>
    <button type="button" (click)="onChangeRegDateDialogClose('cancel')" i18n="@@RegCancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 醫令執行醫事人員及起訖時間輸入 -->
<app-vis-dialog title="醫令執行醫事人員及起訖時間輸入" [maxWidth]="'96%'" [superWidth]="'96vw'" [height]="'95%'"
  [show]="isShowRxDialog" (close)="isShowRxDialog=false;">
  <div *ngIf="isShowRxDialog" [ngClass]="'inside-scrollbar cusDialog-MazHeight'" class="pane orders-panel px-0">
    <div orientation="horizontal" class="splitter">
      <div style="width: 100%" [ngStyle]="{height:getOrderDialogHeight()}">
        <div style="min-height: 2rem;">
          <!-- <label  >
          </label> -->
          <span i18n="@@Urgent" class="w-20 ml-3 position-relative" *ngIf="!cln.TypeIsHomeCare">假日加成&nbsp;&nbsp;</span>
          <mat-checkbox [checked]="this.histRcd.Hist.Urgent=='V'" (change)="urgentChange($event)"
            *ngIf="!cln.TypeIsHomeCare"></mat-checkbox>
          <span i18n="@@UrgentNight" class="w-20 ml-3 position-relative"
            *ngIf="!cln.TypeIsHomeCare">夜間加成&nbsp;&nbsp;</span>
          <mat-checkbox [checked]="this.histRcd.Hist.UrgentNight=='V'" (change)="urgentNightChange($event)"
            *ngIf="!cln.TypeIsHomeCare"></mat-checkbox>
          <span i18n="@@UrgentHC" [ngClass]="[ErrorAndWarning.UrgentHC === 'W'?'error-and-warning':'']"
            *ngIf="cln.TypeIsHomeCare" class="w-20 ml-4 position-relative">居護加成&nbsp;&nbsp;</span>
          <mat-checkbox [checked]="this.histRcd.Hist.UrgentHC=='V'" (change)="urgentHCChange($event)"
            *ngIf="cln.TypeIsHomeCare"></mat-checkbox>
        </div>
        <app-hist-orders #ordersExecComp class="position-relative" [allergies]="allergies" [disabled]="hasUploaded"
          [fillExecuteOnly]="true" [orders]="hrxsExecutor" [isCopy]="false" [isShowRxDialog]="isShowRxDialog">
        </app-hist-orders>
      </div>
    </div>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="exeCompSure()" primary="true" i18n="@@Save">確定</button>
    <button type="button" (click)="closeRxExecutorWin()" primary="true" i18n="@@Close">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<!-- 醫令執行醫事人員及起訖時間輸入 -->
<app-vis-dialog title="預約掛號" [maxWidth]="'100%'" [superWidth]="'100%'" [height]="'96%'" [show]="isShowAppointmentDialog"
  (close)="isShowAppointmentDialog=false;">
  <div style="width: 100%" *ngIf="isShowAppointmentDialog">
    <app-reg-reserve [patientId]="this.patientId" [roomFilter]="this.roomCode"></app-reg-reserve>
  </div>
</app-vis-dialog>

<app-vis-dialog title="影像連結作業選擇" *ngIf="true" [show]="isShowPACSConnectDialog" [minWidth]="320" [width]="320"
  i18n-title="@@ConfirmAutoAddRxDialog" (close)="onPACSConnectDialogClose()">
  <app-vis-dialog-actions>
    <button type="button" (click)="onPACSConnectCreateDialog()" i18n="@@CreatePACSOrder">開單</button>
    <button type="button" (click)="onPACSConnectOpenDialog()" i18n="@@OpenPACSOrder">調閱</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<app-vis-dialog title="PACS開單" *ngIf="true" [show]="isShowPACSCreateDialog" [maxWidth]="'90%'" [superWidth]="'90%'"
  [height]="'90%'" (close)="onPACSCreateDialogClose()">
  <div class="card-body">
    <div class="row">
      <div class="col-sm-12">
        <app-pacscreate-order *ngIf="isShowPACSCreateDialog" [wholeHist]="histRcd" [triggerDate]="pACSCreateTriggerDate"
          [accessionNumber]="pACSCreateTriggerNumber" (onEmitClose)="onPACSCreateDialogClose()"
          (onEmitSendSelectedRxCodes)="handlePACSCreateEmitCode($event)"></app-pacscreate-order>
      </div>
    </div>
  </div>
</app-vis-dialog>
<app-vis-dialog title="家護成員設定" [maxWidth]="'95vw'" [superWidth]="'95%'" [show]="isFamilyCareDialog"
  (close)="isFamilyCareDialog = false">
  <div class="family-toolbar-area">
    <label class="">
      <span i18n="@@MRoomCode">年度指標</span>
      <app-vis-combobox [data]="medRoom" [textField]="'text'" class="required" [allowInput]="false"
        [valueField]="'value'" [valuePrimitive]="true" [customClass]="'bg-white d-inline-block max-w-18'">
      </app-vis-combobox>
    </label>
    <!-- formControlName="FamilyCareDoctor"  formControlName="YearPoint" -->
    <label class="">
      <span i18n="@@FamilyCareDoctor">收案醫師</span>
      <app-vis-combobox [data]="familyDoctors" [disabled]="true" [(ngModel)]="familyDoctor" [textField]="'text'"
        class="required" [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true"
        [customClass]="'bg-white d-inline-block max-w-18'">
      </app-vis-combobox>
    </label>
    <label>
      <span i18n="@@MRoomCode">健保署提供件數/收案件數</span>
      <input class="vis-input d-inline-flex w-24" [value]="" (change)="1" />
      /
      <input class="vis-input d-inline-flex w-24" [value]="familyTotal" />
    </label>
  </div>
  <div> <app-vis-grid [data]="familyCareGridview" [pageable]="false" [scroll]="true" [showStickyHeight]="200"
      [showMultiLineHead]="true" [showStickyHead]="true" [tableFullWidth]="'w-100'">
      <app-vis-grid-checkbox-column [field]="'IsEnabled'" class="text-center" [width]="30" [someChecked]="1"
        (checkChanged)="onFamilyCareCheckClick($event)"></app-vis-grid-checkbox-column>
      <app-vis-grid-column field="PatientNo" title="病歷號" [width]="85" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="CName" title="姓名" [width]="100" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="Phone" title="電話" [width]="80" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="BirthDay" title="生日" [width]="75" i18n-title="@@RegPeriod" class="text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="ellipsis-content" [title]="dataItem.BrithDay | formatDate:''">{{ dataItem.BrithDay | formatDate:''
            }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column field="CId" title="身分證" [width]="90" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column [width]="70" title="收案醫師" class="text-center custom-m-auto">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'"
            [data]="familyDoctors" (valueChange)="onFamilyDoctorChange(dataItem,$event)"
            [defaultValue]="dataItem.DoctorCId" [value]="dataItem.DoctorCId"></app-vis-combobox>
        </ng-template>

      </app-vis-grid-column>
      <app-vis-grid-column field="CaseDate" title="收案日" [width]="75" i18n-title="@@RegPeriod" class="text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="ellipsis-content" [title]="dataItem.CaseDate | formatDate:''">{{ dataItem.CaseDate | formatDate:''
            }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column [width]="100" field="MemberType" title="會員類別" class="text-center custom-m-auto">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'valuetext'"
            [data]="familyMemberTypeOpt" (valueChange)="onMemberTypeChange(dataItem,$event)"
            [defaultValue]="dataItem.MemberType" [value]="dataItem.MemberType"></app-vis-combobox>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column [width]="100" field="CaseType" title="個案類別" class="text-center custom-m-auto">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'valuetext'"
            [data]="familyCaseTypeOpt" (valueChange)="onCaseTypeChange(dataItem,$event)"
            [defaultValue]="dataItem.CaseType" [value]="dataItem.CaseType"></app-vis-combobox>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column [width]="100" field="CloseDate" title="結案日" class="text-center custom-m-auto">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="ellipsis-content" [title]="dataItem.CloseDate | formatDate:''">{{ dataItem.CloseDate |
            formatDate:'' }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column [width]="75" field="CloseReason" title="結案原因" class="text-center custom-m-auto">
      </app-vis-grid-column>
      <app-vis-grid-column [width]="80" field="P5001Date" title="全人照顧評估" class="text-center custom-m-auto">
      </app-vis-grid-column>
      <app-vis-grid-column [width]="100" field="Address" title="住址" class="text-center custom-m-auto">
      </app-vis-grid-column>
    </app-vis-grid></div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onFamilySearchPatientClick()" i18n="@@DialogClose">單筆新增</button>
    <button type="button" (click)="onFamilySaveClick()" i18n="@@DialogClose">儲存</button>
    <button type="button" (click)="isFamilyCareDialog = false" i18n="@@DialogClose">退出</button>
  </app-vis-dialog-actions>
</app-vis-dialog>


<app-vis-dialog title="家護成員新增" [maxWidth]="'95vw'" [superWidth]="'95%'" [show]="isFamilyCareNewDialog"
  (close)="isFamilyCareNewDialog = false">
  <div>  <app-vis-grid [data]="familyCareNewGridview" [pageable]="false"[scroll]="true"
    [showStickyHeight]="200"
    [showMultiLineHead]="true"
    [showStickyHead]="true"
    [tableFullWidth]="'w-100'"
    >

    <app-vis-grid-checkbox-column  [field]="'IsEnabled'" class="text-center"  [width]="30" [someChecked]="1"  (checkChanged)="onFamilyCareCheckClick($event)"></app-vis-grid-checkbox-column>
    <app-vis-grid-column field="SeqNo" title="序號" [width]="75" i18n-title="@@SeqNo" class="text-center">
    </app-vis-grid-column>
      <app-vis-grid-column field="PatientNo" title="病歷號" [width]="85" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="CName" title="姓名" [width]="100" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="Phone" title="電話" [width]="80" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column field="BirthDay" title="生日" [width]="75" i18n-title="@@RegPeriod" class="text-center">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="ellipsis-content" [title]="dataItem.BrithDay | formatDate:''">{{ dataItem.BrithDay | formatDate:''
            }}</div>
        </ng-template>
      </app-vis-grid-column>
      <app-vis-grid-column field="CId" title="身分證" [width]="90" i18n-title="@@RegPeriod" class="text-center">
      </app-vis-grid-column>
      <app-vis-grid-column [width]="70" title="收案醫師" class="text-center custom-m-auto">
        <ng-template kendoGridCellTemplate let-dataItem>
          <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'"
            [data]="familyDoctors" (valueChange)="onFamilyDoctorModifyChange(dataItem,$event)"  [defaultValue]="dataItem.DoctorCId" [value]="dataItem.DoctorCId"></app-vis-combobox>
          </ng-template>

      </app-vis-grid-column>
      <app-vis-grid-column [width]="100" field="Address" title="住址" class="text-center custom-m-auto">
      </app-vis-grid-column>
    </app-vis-grid></div>
    <app-vis-dialog-actions>
      <button type="button" (click)="isFamilyCareModifyDialog = true" i18n="@@DialogClose">整批修改</button>
      <button type="button" (click)="onFamilyNewSaveClick()" i18n="@@DialogClose">儲檔返回</button>
    </app-vis-dialog-actions>
</app-vis-dialog>
<app-vis-dialog title="整批設定" [maxWidth]="'60vw'" [superWidth]="'60%'" [show]="isFamilyCareModifyDialog" (close)="isFamilyCareModifyDialog = false">
  <div class="family-toolbar-area">
    <label class="">
      <span i18n="@@MRoomCode">序號：</span>
      <input class="vis-input d-inline-flex w-24" appNumberIntegerOnly [(ngModel)]="familyStartSeqNo"  (change)="1" />
      訖
      <input class="vis-input d-inline-flex w-24" appNumberIntegerOnly [(ngModel)]="familyEndSeqNo"  (change)="1" />
    </label>
    <!-- formControlName="FamilyCareDoctor"  formControlName="YearPoint" -->
    <label >
      <span i18n="@@FamilyCareDoctor">收案醫師</span>
      <app-vis-combobox  [data]="familyDoctors"  [(ngModel)]="familyModifyDoctor" [textField]="'text'" class="required"
        [allowInput]="false" [valueField]="'value'" [valuePrimitive]="true"
        [customClass]="'bg-white d-inline-flex max-w-30'">
      </app-vis-combobox>
    </label>
    <label >
      <span i18n="@@FamilyCareDoctor">收案類別</span>
      <app-vis-combobox  [panelFitContent]="true" [valueFormat]="'valuetext'"
      [data]="familyCaseTypeOpt"   [(ngModel)]="familyModifyCase"
      [customClass]="'bg-white d-inline-flex max-w-32'">
      ></app-vis-combobox>
    </label>
  </div>
  <app-vis-dialog-actions>
    <button type="button" (click)="onFamilyModifySaveClick()" i18n="@@DialogClose">確定</button>
    <button type="button" (click)="isFamilyCareModifyDialog = false" i18n="@@DialogClose">退出</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
  <!--搜尋病患視窗-->
  <app-patients-patient-query-dialog [isDialogOpened]="isFamilySearchDialog"
  (onEmitResult)="onPatientDialogClose($event)">
</app-patients-patient-query-dialog>

<ng-template let-data #nextRegTemp>
  <div>
    <div style="padding: 10px 15px;">
      <label style="color: red;">此儲存不會批價驗證跟不寫IC卡，若要重批請用完診(F2)</label>
    </div>
  </div>
</ng-template>

<app-medication-way-and-receipt-report [hidden]="true" (printed)="handlePrinted()" #medicationWayAndReceipt>
</app-medication-way-and-receipt-report>

