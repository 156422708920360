<div class="w-100 h-100 mt-2 mb-3">
  <div style="display:flex">
    <label class="vis-form-field mt-extend">
      <span i18n="@@Contact" class="card-header d-flex py-2 px-0 mt0 card-header-gradation">
        <mat-icon class="pref-icon btn-initial position-relative top-2 mr-1 ml-2">person</mat-icon>
        {{title}}
        <span class="ml-1" style="color:var(--vis-dark-blue-color)">{{validateMsg}}</span>
      </span>
    </label>
    <div style="flex-grow: 1"></div>
    <!-- <mat-icon class="pref-icon-delete remove position-relative" style="top:8px;" type="button" *ngIf="deletable" [ngClass]="!_enable?'d-none':''" (click)="onDelete()">
      delete
    </mat-icon> -->
    <button type="button"
      *ngIf="deletable" [ngClass]="!_enable?'d-none':''"
      (click)="onDelete()"
      class="vis-btn-config p-0 position-relative pl-2 w-20 contact-delete" title="刪除">
      <mat-icon class="btn-scale-90 position-relative vis-combine-icon pref-icon-delete remove float-left">delete</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">刪除</span>
    </button>
  </div>
</div>
<mat-grid-list cols="8" [rowHeight]="60" [gutterSize]="'5px'" *ngIf="form" [formGroup]="form">
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span i18n="@@Name">姓名</span>
      <input class="vis-input" formControlName="Name" #contactName />
      <!-- <span style="color: #f44336; font-size: 0.25rem;" *ngIf="!contactName?.value&&deletable">*姓名必填，或刪除此聯絡人</span> -->
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span i18n="@@Title">稱謂</span>
      <app-vis-dropdownlist formControlName="Title" [data]="titleOpt" [textField]="'text'"
        [valueField]="'value'" [valuePrimitive]="true">
      </app-vis-dropdownlist>
    </label>
  </mat-grid-tile>

  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span i18n="@@RelationCode">聯絡人關係</span>
      <app-vis-dropdownlist formControlName="RelationCode" [data]="relationOpt" [textField]="'text'"
        [valueField]="'value'" [valuePrimitive]="true">
      </app-vis-dropdownlist>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <div class="checkbox-label-ui"
      style="display: flex;width: 100%;height: 100%;align-items: end;justify-content: end;">
      <mat-checkbox [disabled]="!_enable" *ngIf="this.patientForm?.controls.Street.value" style="height:35px"
        [checked]="sameAddrFromPatient" (change)="onChkSameAddressClick($event)">同住者</mat-checkbox>
    </div>

  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span [class.required]="contactName.value" i18n="@@Phone(Home)">電話(家)</span>
      <input type="tel" class="vis-input phone-area border-right-0" maxLength="3" appNumberIntegerOnly
        formControlName="PhoneHomeArea" />
      <input type="tel" class="vis-input phone-main" maxLength="10" appNumberIntegerOnly [class.required]="contactName.value"
        formControlName="PhoneHome" />
      <app-validation-tip class="phone-area" controlName="PhoneHomeArea"></app-validation-tip>
      <app-validation-tip class="phone-main" controlName="PhoneHome"></app-validation-tip>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span i18n="@@Phone(Office)">電話(公司)</span>
      <input type="tel" class="vis-input phone-area border-right-0" maxLength="3" appNumberIntegerOnly
        formControlName="PhoneCompanyArea" />
      <input type="tel" class="vis-input phone-main" maxLength="10" appNumberIntegerOnly formControlName="PhoneCompany" />
      <app-validation-tip class="phone-area" controlName="PhoneCompanyArea"></app-validation-tip>
      <app-validation-tip class="phone-main" controlName="PhoneCompany"></app-validation-tip>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span [class.required]="contactName.value" i18n="@@Mobile">手機</span>
      <input type="tel" class="vis-input" appNumberIntegerOnly maxLength="15" [class.required]="contactName.value"
        formControlName="Mobile" />
      <app-validation-tip controlName="Mobile"></app-validation-tip>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="2">
    <label class="vis-form-field">
      <span i18n="@@EMail">EMail</span>
      <input class="vis-input" formControlName="EMail" />
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <label class="vis-form-field">
      <span i18n="@@ZipCode">郵遞區號</span>
      <input class="vis-input" appNumberIntegerOnly formControlName="ZipCode" #zipCode />
      <app-validation-tip controlName="ZipCode"></app-validation-tip>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <label class="vis-form-field">
      <span i18n="@@City">縣市</span>
      <app-vis-dropdownlist class="vis-input" [data]="city" formControlName="City"></app-vis-dropdownlist>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <label class="vis-form-field">
      <span i18n="@@Area">鄉鎮市區</span>
      <app-vis-dropdownlist class="vis-input" [data]="area" formControlName="Area"></app-vis-dropdownlist>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="5">
    <label class="vis-form-field">
      <span [class.required]="contactName.value" i18n="@@Street">地址</span>
      <input class="vis-input" [class.required]="contactName.value" formControlName="Street" />
      <app-validation-tip controlName="Street"></app-validation-tip>
    </label>
  </mat-grid-tile>
  <mat-grid-tile colspan="8" rowspan="2">
    <label class="vis-form-field">
      <span>聯絡人備註</span>
      <textarea class="vist-extarea" style="height: calc(100% - 24px);width: 100%;resize: none;"
        formControlName="Remark"></textarea>
    </label>
  </mat-grid-tile>
</mat-grid-list>
