import { PageData } from './../../../shared/models/page-data';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LabPrescriptionPageCollection } from 'src/app/services/api-service/hist/hist-api.model';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { ReportComponentService } from 'src/app/system/report/components/report-component.service';
import * as QRCode from 'qrcode';
import { Console } from 'console';

@Component({
  selector: 'app-lab-prescription-report',
  templateUrl: './lab-prescription-report.component.html',
  styleUrls: ['./lab-prescription-report.component.css']
})
export class LabPrescriptionReportComponent implements OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  @ViewChild('reportContainerHalf', { static: false })
  reportContainerHalf: ElementRef<HTMLDivElement>;


  @Input() reportData: LabPrescriptionPageCollection;

  isPrintItreatnum = true;
  isLetterHalf = false;

  constructor(private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private reportComponentService: ReportComponentService,
    private clinicDataService: ClinicDataService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.Init();

  }

  async Init() {
    var param = await this.clinicDataService.getParam("PRN001");
    this.isPrintItreatnum = param.IsPrintDispPresItreatnum ?? true;
    this.isLetterHalf = param.ExamPresType == 2 ? true : false;
  }

  onPrint = async () => {

    if (this.reportData && this.reportData.Pages && this.reportData.Pages.length > 0) {
      const reportName = '全民健康保險門診交付檢驗處方箋清單';

      let printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(813, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }


  getHtml = () => {
    let printTarget: HTMLDivElement = this.isLetterHalf
    ? this.reportContainerHalf.nativeElement
    : this.reportContainer.nativeElement;

    let cssText = this.reportComponentService.getAllCss(printTarget);

    const style = `
      <style>
        ${cssText}

        @page {
          size: '${this.isLetterHalf ? "21.5cm 18.62cm" : "A4"}';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    const html = printTarget.innerHTML;

    const fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm">${html}</body>
    </html>`;

    return fullHtml;
  }

  generateQRCode(text: string): string {
    let retUrl: string = "";
    QRCode.toDataURL(text, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }

      retUrl = url;
    });

    return retUrl;
  }

}
