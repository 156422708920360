export class ReceiptPersonal {
    PatientNo:string;
    Title: string;
    Name: string;
    PersonTitle : '先生' | '女士' ;
    Cid: string;
    StartDate: string; 
    EndDate: string; 
    Count: number; 
    FeeTotal: string; 
    ClinicName: string; 
    DoctorName: string;
    ClinicAddress: string;
    PrintDate: string;
  }
  export class ReceiptEnglishInfo {
    Name: string; 
    PatientNo:string;  // Record No.
    Age: number;  
    StartDate: string;    // Period
    EndDate: string;     // Period
    Count: number; //交付檢驗單
    Superintendent: string; 
    IssuedDate: string; 
    ReceiptNo:string; 

    FeeRegister :number; //1 Registration

    FeePartRx :number;  //  Copayment

    FeePartOPD :number; // Copayment

    FeePartExam :number; // Copayment

    FeeOwn :number;  // 自費

    FeeDx :number;   // 2

    FeeDrug :number;  // 4 

    FeeTx6 :number;  // 8

    FeeTx7 :number;  // 6

    FeeTx8 :number;  // 1
    FeeTx9 :number;    // 5

    FeeMAT :number;  // 7

    FeeMiscellaneous :number;  // 11.Miscellaneous
    Certificate :number;  // 11.Certificate
    PhysicalExam :number;  // 10.PhysicalExam
    Prescription :number;  // 3.Prescription
    FeeTot :number;   // NHI PAY
    FeeSubTotal : number;   // TOTAL
  }
  