import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EasyFormComponent } from "../shared/components/easy-form/easy-form.component";
import { FieldDefine, FormDefine, FormDefineT } from "../shared/components/easy-form/form-define";

@Injectable({
    providedIn: 'root'
  })
  export class EasyFormService {
      private formMap:Map<number,MatDialogRef<EasyFormComponent>> = new Map();
      private promiseMap:Map<number,(v:any)=>void> = new Map();
      constructor(private dialog: MatDialog){
          
      }
      /** 使用者點擊確認 => 觸發beforeSubmit => 回傳{[name:string]:value} , value為編輯過的內容
       *  使用者點擊離開 => 不觸發beforeSubmit => 觸發closeValue, 回傳結果或者表單預設 {[name:string]:value}
      */
      // show2(formDefine:FormDefine):Promise<any>{
      //   var formId = 1;
      //   while(this.formMap.has(formId)){
      //       formId =  Math.random()*1000000;
      //   }
      //   formDefine.formId = formId;
      //   var dg = this.dialog.open(EasyFormComponent,{
      //       'data':formDefine,
      //       'panelClass':'before-loader',
      //       'autoFocus':false,
      //       'disableClose':true,
      //   });
      //   this.formMap.set(formId,dg);
      //   var ret = new Promise<any>((res,rej)=>{
      //       this.promiseMap.set(formId,res);
      //   });
      //   return ret;
      // }

      show<T>(formDefine:FormDefineT<T>):Promise<T>{
        var formId = 1;
        while(this.formMap.has(formId)){
            formId = Math.random()*1000000;
        }
        formDefine.formId = formId;
        var fd = Object.assign({},formDefine as any) as FormDefine;
        fd.fields = [];
        for(var p in formDefine.fields){
           fd.fields.push(formDefine.fields[p]);
        }
        fd.fields.sort((a,b)=>a.order>b.order?1:a.order==b.order?0:-1);
        var dg = this.dialog.open(EasyFormComponent,{
            'width':fd.width?(fd.width+'px'):'',
            'height':fd.height?(fd.height+'px'):'',
            'data':fd,
            'panelClass':'before-loader',
            'autoFocus':false,
            'disableClose':true,
            
        });
        dg.componentInstance.onClose = this.formClose.bind(this);
        this.formMap.set(formId,dg);
        var ret = new Promise<T>((res,rej)=>{
            this.promiseMap.set(formId,res);
        });
        return ret;
      }
      /** 給EasyForm UI元件呼叫完成用 */
      formClose(formId:number,data:any){
        var res = this.promiseMap.get(formId);
        this.promiseMap.delete(formId);
        var dg = this.formMap.get(formId);
        this.formMap.delete(formId);
        dg.close();
        res(data);
      }
  }