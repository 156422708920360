import { TreatmentCode } from './TreatmentCode';
import { TreatmentFee } from './TreatmentFee';

import { PatientBaseInput } from './PatientBaseInput';
import { GetSeqNumber256 } from './GetSeqNumber256';
import { TreatmentData } from './TreatmentData';
import { PrescriptionSignWrite } from './PrescriptionSignWrite';
import { PrescriptionSignMultiWrite } from './PrescriptionSignMultiWrite';
import { CSHISResultWithData } from './CSHISResultWithData';
import { BasicData } from './BasicData';
import { RegisterBasic } from './RegisterBasic';
import { TreatmentNoNeedHPC } from './TreatmentNoNeedHPC';
import { TreatmentNeedHPC } from './TreatmentNeedHPC';
import { Prescription } from './Prescription';
import { OrganDonate } from './OrganDonate';
import { LastSeqNum } from './LastSeqNum';
import { DocIdData } from './DocIdData';
import { CSHISResult } from './CSHISResult';
import { CriticalIllnessData } from './CriticalIllnessData';
import { SeqNumber256 } from './SeqNumber256';
import { PrescriptionMain } from './PrescriptionMain';
import { SeqNumber256N1 } from './SeqNumber256N1';

import { IVisionHIS } from './IVisionHis';
import { HcrService, VisionApiResult } from 'src/app/services/hcr-service.service';
import { DateHelper } from '../helpers/date-helper';
import { VisionHIS } from './VisionHIS';
import { HttpClient } from '@angular/common/http';
import { PrescriptionDetailOutPatient } from './PrescriptionDetailOutPatient';
import { PrescriptionDetailLongTerm } from './PrescriptionDetailLongTerm';
import { PrescriptionDetailHVE } from './PrescriptionDetailHVE';
/**
    *
    */
export class VisionVHIS extends VisionHIS implements IVisionHIS {
    /**讀取不需個人 PIN 碼之基本資料
     * 
     * API name VHChisGetBasicData
     * 
     * 原 csHIS.dll 編號： 1.1
     * 
     * 原 csHIS.dll API 名稱： hisGetBasicData
     * 
     * 用途： 讀取不需個人 PIN 碼之基本資料
     * 
     * 輸入參數規格： 無輸入參數
     */
    public async GetBasicData(): Promise<CSHISResultWithData<BasicData>> {
        var ret = await this.CallApi<VNHIOutput & PatBasic>("/VHC/VHChisGetBasicDataJson", {

        });
        var rs = this.toCSHISResultWithData<BasicData>(ret);
        
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = {

            IcNo: ret.cardNo.trim(),
            Name: ret.name.trim(),
            CId: ret.ROCID.trim(),
            Birth: DateHelper.parseROCDate(ret.BirthDate.trim()),
            Sex: ret.sex,
            Issuedate: DateHelper.parseROCDate(ret.cardIssueDate),
            CancellationFlag: ret.cardStatusNote.trim(),
            RelationPhone: ret.emergentTel1.trim() + ret.emergentTel2.trim() // 不知道為啥他14馬的欄位拆成兩個
            // IcNo:this.take(ret.pBuffer,1,12),
            // Name:this.take(ret.pBuffer,13,32),
            // CId:this.take(ret.pBuffer,33,42),
            // Birth:DateHelper.parseROCDate(this.take(ret.pBuffer,43,49)),
            // Sex:this.take(ret.pBuffer,50,50),
            // Issuedate: DateHelper.parseROCDate(this.take(ret.pBuffer,51,57)),
            // CancellationFlag:this.take(ret.pBuffer,58,58),
            // RelationPhone:this.take(ret.pBuffer,59,72)
        }
        return rs;
    }
    /**
        * API name VHChisGetRegisterBasicJson
        * 
        * 原 csHIS.dll 編號： 1.2
        * 
        * 原 csHIS.dll API 名稱： hisGetRegisterBasic
        * 
        * 用途： 掛號或報到時讀取基本資料(以 Json 格式 UTF8 編碼)
        * 
        * 輸入參數規格： 無輸入參數
        */
    public async GetRegisterBasic(): Promise<CSHISResultWithData<RegisterBasic>> {
        var ret = await this.CallApi<VNHIOutput & RegBasic>("/VHC/VHChisGetRegisterBasicJson", {});
        var rs = this.toCSHISResultWithData<RegisterBasic>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = {
            IcNo: ret.cardNo.trim(),
            Name: ret.name.trim(),
            CId: ret.ROCID.trim(),
            Birth: DateHelper.parseROCDate(ret.birthDate.trim()),
            Sex: ret.sex.trim(),
            Issuedate: DateHelper.parseROCDate(ret.cardIssueDate.trim()),
            CancellationFlag: ret.CardStatusNote.trim(),
            ICode: ret.HICCode.trim(),
            InsurerFlag: ret.HICTypeNote.trim(),
            CardExpirationDate: DateHelper.parseROCDate(ret.cardExpireDate.trim()),
            AvailableCount: ret.AvailableClinicTimes.trim(),
            NewbornBirthDate: DateHelper.parseROCDate(ret.newBornDate.trim()),
            NewbornBirthNum: ret.NewBornNote.trim()
        }
        return rs;
    }


    /**
    * 讀取就醫資料不需HPC卡的部分
    * 
    * API name VHChisGetTreatmentNoNeedHPC
    * 
    * 原 csHIS.dll 編號： 1.5
    * 
    * 原 csHIS.dll API 名稱： hisGetTreatmentNoNeedHPC
    * 
    * 用途： 民眾就診時，HIS 讀取不需 HPC 卡的就醫資料部分
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async GetTreatmentNoNeedHPC(): Promise<CSHISResultWithData<TreatmentNoNeedHPC>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetTreatmentNoNeedHPC", {

        });
        var rs = this.toCSHISResultWithData<TreatmentNoNeedHPC>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }

        rs.Data = {
            MajorInjury1: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 1, 7)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 8, 14)),
            },
            MajorInjury2: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 15, 21)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 22, 28)),
            },
            MajorInjury3: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 29, 35)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 36, 42)),
            },
            MajorInjury4: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 43, 49)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 50, 56)),
            },
            MajorInjury5: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 57, 63)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 64, 70)),
            },
            MajorInjury6: {
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 71, 77)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 78, 84)),
            },
            TreatmentData1: {

                Itp: this.take(ret.pBuffer, 85, 86),
                NewbornFlag: this.take(ret.pBuffer, 85, 85),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 88, 100)),
                ReCardFlag: this.take(ret.pBuffer, 101, 101),
                Isqno: this.take(ret.pBuffer, 102, 105),
                ClinicCode: this.take(ret.pBuffer, 106, 115),
                MedicalFees: this.take(ret.pBuffer, 116, 123),
                Deductible: this.take(ret.pBuffer, 124, 131),
                HospitalFees: this.take(ret.pBuffer, 132, 139),
                HdeductibleA: this.take(ret.pBuffer, 140, 146),
                HdeductibleB: this.take(ret.pBuffer, 147, 153),
            },
            TreatmentData2: {
                Itp: this.take(ret.pBuffer, 154, 155),
                NewbornFlag: this.take(ret.pBuffer, 156, 156),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 157, 169)),
                ReCardFlag: this.take(ret.pBuffer, 170, 170),
                Isqno: this.take(ret.pBuffer, 171, 174),
                ClinicCode: this.take(ret.pBuffer, 175, 184),
                MedicalFees: this.take(ret.pBuffer, 185, 192),
                Deductible: this.take(ret.pBuffer, 193, 200),
                HospitalFees: this.take(ret.pBuffer, 201, 208),
                HdeductibleA: this.take(ret.pBuffer, 209, 215),
                HdeductibleB: this.take(ret.pBuffer, 216, 222),
            },
            TreatmentData3: {
                Itp: this.take(ret.pBuffer, 223, 224),
                NewbornFlag: this.take(ret.pBuffer, 225, 225),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 226, 238)),
                ReCardFlag: this.take(ret.pBuffer, 239, 239),
                Isqno: this.take(ret.pBuffer, 240, 243),
                ClinicCode: this.take(ret.pBuffer, 244, 253),
                MedicalFees: this.take(ret.pBuffer, 254, 261),
                Deductible: this.take(ret.pBuffer, 262, 269),
                HospitalFees: this.take(ret.pBuffer, 270, 277),
                HdeductibleA: this.take(ret.pBuffer, 278, 284),
                HdeductibleB: this.take(ret.pBuffer, 285, 291),
            },
            TreatmentData4: {
                Itp: this.take(ret.pBuffer, 292, 293),
                NewbornFlag: this.take(ret.pBuffer, 294, 294),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 295, 307)),
                ReCardFlag: this.take(ret.pBuffer, 308, 308),
                Isqno: this.take(ret.pBuffer, 309, 312),
                ClinicCode: this.take(ret.pBuffer, 313, 322),
                MedicalFees: this.take(ret.pBuffer, 323, 330),
                Deductible: this.take(ret.pBuffer, 331, 338),
                HospitalFees: this.take(ret.pBuffer, 339, 346),
                HdeductibleA: this.take(ret.pBuffer, 347, 353),
                HdeductibleB: this.take(ret.pBuffer, 354, 360),
            },
            TreatmentData5: {
                Itp: this.take(ret.pBuffer, 361, 362),
                NewbornFlag: this.take(ret.pBuffer, 363, 363),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 364, 376)),
                ReCardFlag: this.take(ret.pBuffer, 377, 377),
                Isqno: this.take(ret.pBuffer, 378, 381),
                ClinicCode: this.take(ret.pBuffer, 382, 391),
                MedicalFees: this.take(ret.pBuffer, 392, 399),
                Deductible: this.take(ret.pBuffer, 400, 407),
                HospitalFees: this.take(ret.pBuffer, 408, 415),
                HdeductibleA: this.take(ret.pBuffer, 416, 422),
                HdeductibleB: this.take(ret.pBuffer, 423, 429),
            },
            TreatmentData6: {
                Itp: this.take(ret.pBuffer, 430, 431),
                NewbornFlag: this.take(ret.pBuffer, 432, 432),
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 433, 445)),
                ReCardFlag: this.take(ret.pBuffer, 446, 446),
                Isqno: this.take(ret.pBuffer, 447, 450),
                ClinicCode: this.take(ret.pBuffer, 451, 460),
                MedicalFees: this.take(ret.pBuffer, 461, 468),
                Deductible: this.take(ret.pBuffer, 469, 476),
                HospitalFees: this.take(ret.pBuffer, 477, 484),
                HdeductibleA: this.take(ret.pBuffer, 485, 491),
                HdeductibleB: this.take(ret.pBuffer, 492, 498),
            }
        }
        return rs;
    }

    /**
    * 讀取就醫資料需要HPC卡的部份
    * API name VHChisGetTreatmentNeedHPC
    * 原 csHIS.dll 編號： 1.8
    * 原 csHIS.dll API 名稱： hisGetTreatmentNeedHPC
    * 用途： 診間就醫讀取資料時使用
    * ※註：若為押碼資料請透過 cshis.dll 進行解押碼。
    * 輸入參數規格： 無輸入參數
    */
    public async GetTreatmentNeedHPC(): Promise<CSHISResultWithData<TreatmentNeedHPC>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetTreatmentNeedHPC10", {

        });
        var rs = this.toCSHISResultWithData<TreatmentNeedHPC>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }

        rs.Data = {
            MajorInjury1: {
                Code: this.take(ret.pBuffer, 2, 8),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 10, 16)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 17, 23)),
            },
            MajorInjury2: {
                Code: this.take(ret.pBuffer, 25, 31),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 33, 39)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 40, 46)),
            },
            MajorInjury3: {
                Code: this.take(ret.pBuffer, 48, 54),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 56, 62)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 63, 69)),
            },
            MajorInjury4: {
                Code: this.take(ret.pBuffer, 71, 77),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 79, 85)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 86, 92)),
            },
            MajorInjury5: {
                Code: this.take(ret.pBuffer, 94, 100),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 102, 108)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 109, 115)),
            },
            MajorInjury6: {
                Code: this.take(ret.pBuffer, 117, 123),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 125, 131)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 132, 138)),
            },
            TreatmentData1: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 139, 151)),
                MainDisease: this.take(ret.pBuffer, 153, 159),
                SecDisease1: this.take(ret.pBuffer, 162, 168),
                SecDisease2: this.take(ret.pBuffer, 171, 177),
                SecDisease3: this.take(ret.pBuffer, 180, 186),
                SecDisease4: this.take(ret.pBuffer, 189, 195),
                SecDisease5: this.take(ret.pBuffer, 198, 204),
            },
            TreatmentData2: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 206, 218)),
                MainDisease: this.take(ret.pBuffer, 220, 226),
                SecDisease1: this.take(ret.pBuffer, 229, 235),
                SecDisease2: this.take(ret.pBuffer, 238, 244),
                SecDisease3: this.take(ret.pBuffer, 247, 253),
                SecDisease4: this.take(ret.pBuffer, 256, 262),
                SecDisease5: this.take(ret.pBuffer, 265, 271),
            },
            TreatmentData3: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 273, 285)),
                MainDisease: this.take(ret.pBuffer, 287, 293),
                SecDisease1: this.take(ret.pBuffer, 296, 302),
                SecDisease2: this.take(ret.pBuffer, 305, 311),
                SecDisease3: this.take(ret.pBuffer, 314, 320),
                SecDisease4: this.take(ret.pBuffer, 323, 329),
                SecDisease5: this.take(ret.pBuffer, 332, 338),
            },
            TreatmentData4: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 340, 352)),
                MainDisease: this.take(ret.pBuffer, 354, 360),
                SecDisease1: this.take(ret.pBuffer, 363, 369),
                SecDisease2: this.take(ret.pBuffer, 372, 378),
                SecDisease3: this.take(ret.pBuffer, 381, 387),
                SecDisease4: this.take(ret.pBuffer, 390, 396),
                SecDisease5: this.take(ret.pBuffer, 399, 405),
            },
            TreatmentData5: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 407, 419)),
                MainDisease: this.take(ret.pBuffer, 421, 427),
                SecDisease1: this.take(ret.pBuffer, 430, 436),
                SecDisease2: this.take(ret.pBuffer, 439, 445),
                SecDisease3: this.take(ret.pBuffer, 448, 454),
                SecDisease4: this.take(ret.pBuffer, 457, 463),
                SecDisease5: this.take(ret.pBuffer, 466, 472),
            },
            TreatmentData6: {
                ClinicDate: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 474, 486)),
                MainDisease: this.take(ret.pBuffer, 488, 494),
                SecDisease1: this.take(ret.pBuffer, 497, 503),
                SecDisease2: this.take(ret.pBuffer, 506, 512),
                SecDisease3: this.take(ret.pBuffer, 515, 521),
                SecDisease4: this.take(ret.pBuffer, 524, 530),
                SecDisease5: this.take(ret.pBuffer, 533, 539),
            }
        }
        return rs;
    }


    /**
    * 讀取處方箋作業
    * 
    * API name VHChisReadPrescription
    * 
    * 原 csHIS.dll 編號： 1.10
    * 
    * 原 csHIS.dll API 名稱： hisReadPrescription
    * 
    * 用途： 讀取 HC 的門診處方箋、長期處方箋及重要醫令資料
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async ReadPrescription(): Promise<CSHISResultWithData<Prescription>> {
        var ret = await this.CallApi<VNHIOutput & {
            pOutpatientPrescription: string,
            pLongTermPrescription: string,
            pImportantTreatmentCode: string,
            pIrritationDrug: string
        }>("/VHC/VHChisReadPrescription", {

        });
        var rs = this.toCSHISResultWithData<Prescription>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }

        var outPat: PrescriptionDetailOutPatient[] = []
        for (let i = 0; i < ret.pOutpatientPrescription.length; i += 61) {
            var r = this.take(ret.pOutpatientPrescription, i + 1, i + 61);
            var p: PrescriptionDetailOutPatient = {
                ClinicDateTime: DateHelper.parseROCDateTime(this.take(r, 1, 13)),
                OrderClass: this.take(r, 14, 14),
                NhiDrugCode: this.take(r, 15, 26),
                TreatmentSite: this.take(r, 27, 32),
                Frequency: this.take(r, 33, 50),
                Days: this.take(r, 51, 52),
                TotalDose: this.take(r, 53, 59),
                DispensingType: this.take(r, 60, 61),
            }
            outPat.push(p);
        }
        var longTerm: PrescriptionDetailLongTerm[] = [];
        for (let i = 0; i < ret.pLongTermPrescription.length; i += 44) {
            var r = this.take(ret.pLongTermPrescription, i + 1, i + 44);
            var p2: PrescriptionDetailLongTerm = {
                CreateDate: DateHelper.parseROCDate(this.take(r, 1, 7)),
                NhiDrugCode: this.take(r, 8, 17),
                Frequency: this.take(r, 18, 35),
                Days: this.take(r, 36, 37),
                TotalDose: this.take(r, 38, 44),
            }
            longTerm.push(p2);
        }
        var hve: PrescriptionDetailHVE[] = [];
        for (let i = 0; i < ret.pImportantTreatmentCode.length; i += 36) {
            var r = this.take(ret.pImportantTreatmentCode, i + 1, i + 36);
            var p3: PrescriptionDetailHVE = {
                ImplementDate: DateHelper.parseROCDate(this.take(r, 1, 7)),
                ClinicCode: this.take(r, 8, 17),
                TreatmentCode: this.take(r, 18, 23),
                ImplementPartCode: this.take(r, 24, 29),
                Total: this.take(r, 30, 36),
            }
            hve.push(p3);
        }
        rs.Data = {
            OutPatient: outPat,
            LongTerm: longTerm,
            HVE: hve,
            IrritationDrug: [this.take(ret.pIrritationDrug, 1, 40),
            this.take(ret.pIrritationDrug, 41, 80),
            this.take(ret.pIrritationDrug, 81, 120)
            ]
        }
        return rs;
    }



    /**
    * 讀取同意器官捐贈及安寧緩和醫療註記
    * 
    * VHChisGetOrganDonate (讀取同意器官捐贈及安寧緩和醫療註記資料)
    * 
    * API name VHChisGetOrganDonate
    * 
    * 原 csHIS.dll 編號： 1.12
    * 
    * 原 csHIS.dll API 名稱： hisGetOrganDonate
    * 
    * 用途： 讀取同意器官捐贈及安寧緩和醫療註記資料
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async GetOrganDonate(): Promise<CSHISResultWithData<OrganDonate>> {
        var ret = await this.CallApi<VNHIOutput & { organDonate: string }>("/VHC/VHChisGetOrganDonate", {});
        var rs = this.toCSHISResultWithData<OrganDonate>(ret);
        
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = {
            OrganDonateNote: ret.organDonate
        }
        return rs;

    }


    /**
    * 讀取最近一次就醫序號
    * API name VHChisGetLastSeqNum
    * 原 csHIS.dll 編號： 1.14
    * 原 csHIS.dll API 名稱： hisGetLastSeqNum
    * 用途： 讀出「最近一次就醫序號」資料
    * 輸入參數規格： 無輸入參數
    */
    public async GetLastSeqNum(): Promise<CSHISResultWithData<LastSeqNum>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetLastSeqNum", {});
        var rs = this.toCSHISResultWithData<LastSeqNum>(ret);
        
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = {
            LastSeqNumData: ret.pBuffer
        };
        return rs;

    }

    /**
    * 讀取卡片狀態(健保IC卡)
    * 
    * API name VHChisGetCardStatus
    * 原 csHIS.dll 編號 1.15
    * 原 csHIS.dll API 名稱 hisGetCardStatus
    * 用途 讀出卡片狀態
    * 輸入參數規格 Input(CardType: String 1 Bytes)
    * 輸入定義說明：
    * 1: 安全模組檔(不適用，請使用 csHIS.dll 讀取)
    * 2: 健保 IC 卡
    * 3: 醫事人員卡(不適用，請使用 csHIS.dll 讀取)

    */
    public async GetCardStatusHC(): Promise<CSHISResultWithData<string>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetCardStatus", {
            CardType: '2'
        });
        var rs = this.toCSHISResultWithData<string>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = rs.ErrorMsg;
        return rs;
    }


    /**
    * 就醫診療資料寫入作業
    * 
    * * API name VHChisWriteTreatmentCode10
    * 
    * 原 csHIS.dll 編號： 1.16
    * 
    * 原 csHIS.dll API 名稱： hisWriteTreatmentCode - 特別定義 ICD10 格式
    * 
    * 用途： 就醫診療資料寫入時使用
    * 
    * 輸入參數規格： Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
    *     pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)
    * 
    *     pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)
    * 
    *     pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)
    * 
    *     pDataWrite: 寫入之資料以 ICD-10 CM 格式寫入及其順序內容… 
    * 
    */
    public async WriteTreatmentCode(data: TreatmentCode): Promise<CSHISResultWithData<DocIdData>> {
        /**以 ICD-10 CM 格式寫入及其順序內容如下:
        補卡註記[HC 健 8-4] (1)
        主要診斷碼[HC 健 8-8] (3-9)
        次要診斷碼[HC 健 8-9] 第 1 組 (12-18)
        次要診斷碼[HC 健 8-9] 第 2 組 (21-27)
        次要診斷碼[HC 健 8-9] 第 3 組 (30-36)
        次要診斷碼[HC 健 8-9] 第 4 組 (39-45)
        次要診斷碼[HC 健 8-9] 第 5 組 (48-54)
        資料皆往左靠，不足處補空白。*/
        var dataWrite = '';
        dataWrite += data.ReCardFlag.padEnd(1, '\0');
        dataWrite += '\0';
        dataWrite += '\0' + data.MainDisease.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease1.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease2.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease3.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease4.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease5.padEnd(7, '\0') + '\0';

        var ret = await this.CallApi<VNHIOutput & { pBufferDocID: string }>("/VHC/VHChisWriteTreatmentCode10", {
            pDateTime: DateHelper.getROCDateTimeString(data.DateTime),
            pPatientID: data.CId,
            pPatientBirthDate: data.BirthDate,//已是民國年
            pDataWrite: dataWrite
        });
        var rs = this.toCSHISResultWithData<DocIdData>(ret);
        
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = { DocId: ret.pBufferDocID }
        return rs;

    }

    /**
    * 就醫費用資料寫入作業
    * 
    * API name VHChisWriteTreatmentFee
    * 
    * 原 csHIS.dll 編號： 1.17
    * 
    * 原 csHIS.dll API 名稱： hisWriteTreatmentFee
    * 
    * 用途： 將就醫費用資料寫入虛擬健保卡
    * 
    * 輸入參數規格： Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
    * pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)
    * 
    * pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)
    * 
    * pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)
    * 
    * pDataWrite: 寫入之資料
    * 
    * 門診醫療費用【當次】(1-8)
    * 
    * 門診部分負擔費用【當次】(9-16)
    * 
    * 住院醫療費用【當次】(17-24)
    * 
    * 住院部分負擔費用【當次急性 30 天， 慢性 180 天以下】(25-31)
    * 
    * 住院部分負擔費用【當次急性 31 天， 慢性 181 天以上】(32-38)
    */
    public async WriteTreatmentFee(data: TreatmentFee): Promise<CSHISResult> {
        /**門診醫療費用【當次】(1-8)
            門診部分負擔費用【當次】(9-16)
            住院醫療費用【當次】(17-24)
            住院部分負擔費用【當次急性 30 天， 慢性 180 天以下】(25-31)
            住院部分負擔費用【當次急性 31 天， 慢性 181 天以上】(32-38) */
        var dataWrite2 = '';
        dataWrite2 += data.MedicalFees.padEnd(8, '\0');
        dataWrite2 += data.Deductible.padEnd(8, '\0');
        dataWrite2 += data.HospitalFees.padEnd(8, '\0');
        dataWrite2 += data.HdeductibleA.padEnd(7, '\0');
        dataWrite2 += data.HdeductibleB.padEnd(7, '\0');
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisWriteTreatmentFee", {
            pDateTime: DateHelper.getROCDateTimeString(data.DateTime),
            pPatientID: data.PatientId,
            pPatientBirthDate: data.PatientBirthDate,//已是民國年
            pDataWrite: dataWrite2
        });
        var rt = this.toCSHISResult(ret);
        return rt;

    }

    // /**
    // * 開啟讀卡機連結埠<br />  呼叫 cshis.dll 內之任何一函數前，必須先呼叫此一函數確認讀卡機連接成功後，才可再呼叫其他函數
    // */
    // public OpenCom(comport: number): Promise<CSHISResult> {
    //     return this.CallCsHis("OpenCom", {
    //         Comport: comport
    //     });
    // }

    // /**
    // * 關閉讀卡機連結埠<br />  結束所有讀卡機作業時需呼叫此一函數， 將先前開啟之讀卡機連結埠關閉
    // */
    // public CloseCom(): Promise<CSHISResult> {
    //     return this.CallCsHis("CloseCom", {

    //     });
    // }


    /**
    * API name VHChisGetCriticalIllness10
    * 
    * 原 csHIS.dll 編號： 1.33
    * 
    * 原 csHIS.dll API 名稱： hisGetCriticalIllness - 特別定義 ICD 10 格式
    * 
    * 用途： 讀取重大傷病註記資料
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async GetCriticalIllness(): Promise<CSHISResultWithData<CriticalIllnessData>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetCriticalIllness10", null);
        var rs = this.toCSHISResultWithData<CriticalIllnessData>(ret);
        
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = {
            CriticalIllness1: {
                Code: this.take(ret.pBuffer, 2, 8),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 10, 16)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 17, 23)),
            },
            CriticalIllness2: {
                Code: this.take(ret.pBuffer, 25, 31),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 33, 39)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 40, 46)),
            },
            CriticalIllness3: {
                Code: this.take(ret.pBuffer, 48, 54),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 56, 62)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 63, 69)),
            },
            CriticalIllness4: {
                Code: this.take(ret.pBuffer, 71, 77),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 79, 85)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 86, 92)),
            },
            CriticalIllness5: {
                Code: this.take(ret.pBuffer, 94, 100),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 102, 108)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 109, 115)),
            },
            CriticalIllness6: {
                Code: this.take(ret.pBuffer, 117, 123),
                StartDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 125, 131)),
                EndDate: DateHelper.parseROCDate(this.take(ret.pBuffer, 132, 138)),
            },
        };
        return rs;
        // return {
        //     StatusCode:ret.ERRORCODE,
        //     ErrorMsg:this.ErrorMsg(ret.ERRORCODE),
        //     Data:{
        //         CriticalIllness1:{
        //             Code:this.take(ret.pBuffer,2,8),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,10,16)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,17,23)),
        //         },
        //         CriticalIllness2:{
        //             Code:this.take(ret.pBuffer,25,31),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,33,39)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,40,46)),
        //         },
        //         CriticalIllness3:{
        //             Code:this.take(ret.pBuffer,48,54),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,56,62)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,63,69)),
        //         },
        //         CriticalIllness4:{
        //             Code:this.take(ret.pBuffer,71,77),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,79,85)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,86,92)),
        //         },
        //         CriticalIllness5:{
        //             Code:this.take(ret.pBuffer,94,100),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,102,108)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,109,115)),
        //         },
        //         CriticalIllness6:{
        //             Code:this.take(ret.pBuffer,117,123),
        //             StartDate:DateHelper.parseROCDate( this.take(ret.pBuffer,125,131)),
        //             EndDate:DateHelper.parseROCDate(this.take(ret.pBuffer,132,138)),
        //         },
        //     }
        // }
    }

    /**
    * 讀取讀卡機日期時間(民國年)
    * 
    * API name VHCcsGetDateTime
    * 
    * 原 csHIS.dll 編號： 1.34
    * 
    * 原 csHIS.dll API 名稱： csGetDateTime
    * 
    * 用途： 讀取讀卡機日期時間
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async GetDateTime(): Promise<CSHISResultWithData<Date>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHCcsGetDateTime", {

        });
        var rs = this.toCSHISResultWithData<Date>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }
        rs.Data = DateHelper.parseROCDateTime(ret.pBuffer);
        return rs;
    }




    /**
    * VHChisGetSeqNumber256 取得就醫序號新版 (不含就醫識別碼)

    * API name VHChisGetSeqNumber256

    * 原 csHIS.dll 編號： 1.37

    * 原 csHIS.dll API 名稱： hisGetSeqNumber256

    * 用途： 取得就醫序號時使用

    * 註 1：有關虛擬健保卡 QR CODE 憑證(Token)取就醫序號及安全簽章之使用限制：

    * 同一組虛擬健保卡 QR CODE 僅能取一次就醫序號，若就醫類別非 01-09 及補卡註記為 2，則同一組虛擬健保卡 QR CODE 可取多次序號(空白)及安全簽章。

    * 註 2：透過虛擬健保卡整合視訊診療門診邏輯亦相同。

    * ※請院所於呼叫此功能取得就醫序號前必須確認民眾身分。

    * 輸入參數規格 Input(cTreatItem: String 3 Bytes, cBabyTreat: String 2 Bytes, cTreatAfterCheck: String 1 Bytes)

    * 輸入定義說明：

    * cTreatItem: String 3 Bytes 為就醫類別(HC 健 8-1)，為英數字，長度需可存放三個char(包括尾端之\0)

    * cBabyTreat: String 2 Bytes 為新生兒就醫註記(HC 健 8-2)，為英數字，長度需可存放兩個 char(包括尾端之\0)。

    * cTreatAfterCheck: String 1 Bytes 為補卡註記，傳入'1'表正常、'2'表補卡，長度需可存放一個 char    
        */
    public async GetSeqNumber256(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetSeqNumber256", {
            cTreatItem: args.TreatItem.padEnd(3, '\0'),
            cBabyTreat: args.BabyTreat.padEnd(2, '\0'),
            cTreatAfterCheck: args.TreatAfterCheck
        });
        var rs = this.toCSHISResultWithData<SeqNumber256>(ret);
        if (rs.StatusCode != 0) {
            return rs;
        }

        /**
         * (pBuffer: String 296 Bytes)
            就診日期時間(1-13)
            就醫序號(14-17) Vxxx
            醫療院所代碼(18-27)
            安全簽章(28-283) (此 256Bytes 為安全簽章為 RSA 所產生之亂數，再經過轉換所呈現之數值)
            SAM ID(284-295) <= VHCcsOpenCom 傳入之 SAMID
            是否同日就診(296)，'Y'表同日就診，'N'表非同日就診
         */
        rs.Data = {
            ClinicDateTime: DateHelper.parseROCDateTime(this.take(ret.pBuffer, 1, 13)),
            Number: this.take(ret.pBuffer, 14, 17),
            ClinicCode: this.take(ret.pBuffer, 18, 27),
            SecuritySeal: this.take(ret.pBuffer, 28, 283),
            SamId: this.take(ret.pBuffer, 284, 295),
            SameDay: this.take(ret.pBuffer, 296, 296)
        }

        return rs;
    }




    /**
    * API name VHCcsUnGetSeqNumber
    * 
    * 原 csHIS.dll 編號： 1.39
    * 
    * 原 csHIS.dll API 名稱： csUnGetSeqNumber
    * 
    * 用途： 回復就醫資料累計值(退掛) 
    * 
    * 輸入參數規格： (pUnTreatDate：String 14 Bytes) (含 null char) 傳入欲退掛之「就診日期時間」(HC 健 8-3)
    */
    public async UnGetSeqNumber(pUnTreatDate: string): Promise<CSHISResult> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHCcsUnGetSeqNumber", {
            pUnTreatDate: pUnTreatDate
        });
        return this.toCSHISResult(ret);
    }


    /**
    * API name VHChisReadPrescriptMain
    * 
    * 原 csHIS.dll 編號： 1.41
    * 
    * 原 csHIS.dll API 名稱： hisReadPrescriptMain
    * 
    * 用途： 診間就醫讀取資料時使用
    * 
    * 輸入參數規格： Input(iStartPos : int, iEndPos : int)
    * 
    * 輸入定義說明：
    * 
    * iStartPos : 傳入欲讀取的起始位置，1 表第一筆。CS 會利用此buffer 傳出讀取的起始位置。
    * 
    * iEndPos : 傳入欲讀取的終止位置，60 表最後一筆。CS 會利用此buffer 傳出讀取的終止位置。
    */
    public async ReadPrescriptMain(iStartPos: number, iEndPos: number, iRecCount: number): Promise<CSHISResultWithData<PrescriptionMain>> {
        var ret = await this.CallApi<VNHIOutput & { pOutpatientPrescription: string }>("/VHC/VHChisReadPrescription", {
            iStartPos: iStartPos,
            iEndPos: iEndPos,
            //iRecCount: iRecCount
        });
        /**每組 61 Bytes，共 60 組門診處方箋每 1 組
            就診日期時間(1-13)
            醫令類別(14)
            診療項目代號(15-26)
            診療部位(27-32)
            用法(33-50)
            天數(51-52)
            總量(53-59)
            交付處方註記(60-61) */
            var rs = this.toCSHISResultWithData<PrescriptionMain>(ret);
        

        if (rs.StatusCode != 0) {
            return rs;
        }
        var rec: PrescriptionDetailOutPatient[] = []
        for (let i = 0; i < ret.pOutpatientPrescription.length; i += 61) {
            var r = this.take(ret.pOutpatientPrescription, i + 1, i + 61);
            var p: PrescriptionDetailOutPatient = {
                ClinicDateTime: DateHelper.parseROCDateTime(this.take(r, 1, 13)),
                OrderClass: this.take(r, 14, 14),
                NhiDrugCode: this.take(r, 15, 26),
                TreatmentSite: this.take(r, 27, 32),
                Frequency: this.take(r, 33, 50),
                Days: this.take(r, 51, 52),
                TotalDose: this.take(r, 53, 59),
                DispensingType: this.take(r, 60, 61),
            }
            rec.push(p);
        }
        rs.Data = {
            OutPatient: rec,
            RecCount: rec.length
        };
        return rs;
    }





    /**
     * 合併虛擬健保卡API中的就醫診療料寫入與就醫費用寫入
     * 
    * API name VHChisWriteTreatmentCode10
    * 
    * 原 csHIS.dll 編號： 1.16
    * 
    * 原 csHIS.dll API 名稱： hisWriteTreatmentCode - 特別定義 ICD10 格式
    * 
    * 用途： 就醫診療資料寫入時使用
    * 
    * 輸入參數規格： Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
    *     pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)
    * 
    *     pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)
    * 
    *     pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)
    * 
    *     pDataWrite: 寫入之資料以 ICD-10 CM 格式寫入及其順序內容… 
    * 
    * -------------------------------
    * 
    * API name VHChisWriteTreatmentFee
    * 
    * 原 csHIS.dll 編號： 1.17
    * 
    * 原 csHIS.dll API 名稱： hisWriteTreatmentFee
    * 
    * 用途： 將就醫費用資料寫入虛擬健保卡
    * 
    * 輸入參數規格： Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
    * pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)
    * 
    * pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)
    * 
    * pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)
    * 
    * pDataWrite: 寫入之資料
    * 
    */
    public async WriteTreatmentData(data: TreatmentData): Promise<CSHISResultWithData<DocIdData>> {
        /**以 ICD-10 CM 格式寫入及其順序內容如下:
            補卡註記[HC 健 8-4] (1)
            主要診斷碼[HC 健 8-8] (3-9)
            次要診斷碼[HC 健 8-9] 第 1 組 (12-18)
            次要診斷碼[HC 健 8-9] 第 2 組 (21-27)
            次要診斷碼[HC 健 8-9] 第 3 組 (30-36)
            次要診斷碼[HC 健 8-9] 第 4 組 (39-45)
            次要診斷碼[HC 健 8-9] 第 5 組 (48-54)
            資料皆往左靠，不足處補空白。*/
        var dataWrite = '';
        dataWrite += data.ReCardFlag.padEnd(1, '\0');
        dataWrite += '\0';
        dataWrite += '\0' + data.MainDisease.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease1.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease2.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease3.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease4.padEnd(7, '\0') + '\0';
        dataWrite += '\0' + data.SecDisease5.padEnd(7, '\0') + '\0';

        var ret = await this.CallApi<VNHIOutput & { pBufferDocID: string }>("/VHC/VHChisWriteTreatmentCode10", {
            pDateTime: DateHelper.getROCDateTimeString(data.DateTime),
            pPatientID: data.PatientId,
            pPatientBirthDate: data.PatientBirthDate,//已是民國年
            pDataWrite: dataWrite
        });
        var result = this.toCSHISResultWithData<DocIdData>(ret);
        if (ret.pBuffer) {
            result.Data = { DocId: ret.pBufferDocID };
        }
        if (result.StatusCode != 0) {
            return result;
        }
        /**門診醫療費用【當次】(1-8)
            門診部分負擔費用【當次】(9-16)
            住院醫療費用【當次】(17-24)
            住院部分負擔費用【當次急性 30 天， 慢性 180 天以
            下】(25-31)
            住院部分負擔費用【當次急性 31 天， 慢性 181 天以
            上】(32-38) */
        var dataWrite2 = '';
        dataWrite2 += data.MedicalFees.padEnd(8, '\0');
        dataWrite2 += data.Deductible.padEnd(8, '\0');
        dataWrite2 += data.HospitalFees.padEnd(8, '\0');
        dataWrite2 += data.HdeductibleA.padEnd(7, '\0');
        dataWrite2 += data.HdeductibleB.padEnd(7, '\0');
        var ret2 = await this.CallApi<VNHIOutput>("/VHC/VHChisWriteTreatmentFee", {
            pDateTime: DateHelper.getROCDateTimeString(data.DateTime),
            pPatientID: data.PatientId,
            pPatientBirthDate: data.PatientBirthDate,//已是民國年
            pDataWrite: dataWrite2
        });
        var r2 = this.toCSHISResult(ret2);
        if (r2.StatusCode != 0) {
            return Object.assign(result, r2);
        }
        return result;
    }

    /**
    * API name VHChisWritePrescriptionSign
    * 
    * 原 csHIS.dll 編號： 1.48
    * 
    * 原 csHIS.dll API 名稱： hisWritePrescriptionSign
    * 
    * 用途： 將門診處方箋或/及長期處方箋或/及重要醫令資料寫入虛擬健保卡，並取得 API 回傳之簽章
    * 
    * 輸入參數規格： Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
        pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)

        pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)

        pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)

        pDataWrite:門診處方箋之資料其順序內容如下:

        就診日期時間(1-13)

        診療項目之醫令類別(14)

        診療項目之診療項目代號(15-26)

        診療項目之診療部位(27-32)

        診療項目之用法(33-50)

        診療項目之天數(51-52)

        診療項目之總量(53-59)

        診療項目之交付處方註記(60-61)

        各欄位資料往左靠，不足處補空白，長度依卡片存放內容規定。
    */
    public async WritePrescriptionSign(data: PrescriptionSignWrite): Promise<CSHISResultWithData<string>> {
        var dataWrite = '';
        var x = data.Prescription;
        /**就診日期時間(1-13)
        醫令類別(14)
        診療項目代號(15-26)
        診療部位(27-32)
        用法(33-50)
        天數(51-52)
        總量(53-59)
        交付處方註記(60-61)
        下一組 */
        dataWrite += DateHelper.formatROCDateTime(x.ClinicDateTime).padEnd(13, '\0');;
        dataWrite += x.OrderClass.padEnd(1, '\0');;
        dataWrite += x.NhiDrugCode.padEnd(12, '\0');
        dataWrite += x.TreatmentSite.padEnd(6, '\0');
        dataWrite += x.Frequency.padEnd(18, '\0');
        dataWrite += x.Days.padEnd(2, '\0');
        dataWrite += x.TotalDose.padEnd(7, '\0');
        dataWrite += x.DispensingType.padEnd(2, '\0');

        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisWriteMultiPrescriptSign", {
            pDateTime: DateHelper.formatROCDateTime(data.DateTime, false, true),
            pPatientID: data.PatientId,
            pPatientBirthDate: DateHelper.formatROCDateTime(data.PatientBirthDate),
            pDataWrite: dataWrite,
        });
        var result = this.toCSHISResultWithData<string>(ret);
        
        result.Data = '';
        if (ret.pBuffer) {
            result.Data = ret.pBuffer;
        }
        return result;
    }

    /**
    * API name VHChisWriteMultiPrescriptSign
    * 
    * 原 csHIS.dll 編號： 1.49
    * 
    * 原 csHIS.dll API 名稱： hisWriteMultiPrescriptSign
    * 
    * 用途： 將門診處方箋或/及長期處方箋或/及重要醫令資料寫入虛擬健保卡，並取得 API 回傳之簽章
    * 
    * 輸入參數規格 Input(pDateTime: String 14 Bytes, pPatientID: String 11 Bytes, pPatientBirthDate: String 8 Bytes, pDataWrite: String)
    * 
    * 輸入定義說明：
    * 
    *   pDateTime: String 14 Bytes 「就診日期時間」(HC 健 8-3)
    * 
    *   pPatientID: String 11 Bytes 民眾「身分證號」(HC 基 3)
    * 
    *   pPatientBirthDate: String 8 Bytes 民眾「出生日期」(HC 基 4)
    * 
    *   pDataWrite: 門診處方箋之資料（多組或一組）其順序內容如下:
    *     門診處方箋第 1 組
    * 
            就診日期時間(1-13)

            醫令類別(14)

            診療項目代號(15-26)

            診療部位(27-32)

            用法(33-50)

            天數(51-52)

            總量(53-59)

            交付處方註記(60-61)

          門診處方箋第 2 組

            ….  

    * 各欄位資料往左靠，不足處補空白，長度依卡片存放內容規定。

    *   iWriteCount: 傳入欲寫入組數，傳出寫入卡片實際組數。
    */
    public async WriteMultiPrescriptSign(data: PrescriptionSignMultiWrite): Promise<CSHISResultWithData<string[]>> {
        var dataWrite = '';
        data.Prescriptions.forEach(x => {
            /**就診日期時間(1-13)
            醫令類別(14)
            診療項目代號(15-26)
            診療部位(27-32)
            用法(33-50)
            天數(51-52)
            總量(53-59)
            交付處方註記(60-61)
            下一組 */
            dataWrite += DateHelper.formatROCDateTime(x.ClinicDateTime).padEnd(13, '\0');;
            dataWrite += x.OrderClass.padEnd(1, '\0');;
            dataWrite += x.NhiDrugCode.padEnd(12, '\0');
            dataWrite += x.TreatmentSite.padEnd(6, '\0');
            dataWrite += x.Frequency.padEnd(18, '\0');
            dataWrite += x.Days.padEnd(2, '\0');
            dataWrite += x.TotalDose.padEnd(7, '\0');
            dataWrite += x.DispensingType.padEnd(2, '\0');
        })
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisWriteMultiPrescriptSign", {
            pDateTime: DateHelper.formatROCDateTime(data.DateTime, false, true),
            pPatientID: data.PatientId,
            pPatientBirthDate: DateHelper.formatROCDateTime(data.PatientBirthDate),
            pDataWrite: dataWrite,
            iWriteCount: data.WriteCount
        });
        var result = this.toCSHISResultWithData<string[]>(ret);
        result.Data = [];
        if (ret.pBuffer) {
            for (let i = 1; i <= ret.pBuffer.length; i += 40) {
                result.Data.push(this.take(ret.pBuffer, i, i + 40 - 1))
            }
        }
        return result
    }

    /**
    * API name VHChisGetCriticalIllness10
    * 
    * 原 csHIS.dll 編號： 1.33
    * 
    * 原 csHIS.dll API 名稱： hisGetCriticalIllness - 特別定義 ICD 10 格式
    * 
    * 用途： 讀取重大傷病註記資料
    * 
    * 輸入參數規格： 無輸入參數
    */
    public async GetCriticalIllnessID(data: PatientBaseInput): Promise<CSHISResultWithData<CriticalIllnessData>> {
        return this.GetCriticalIllness()
    }

    /**
    * API name VHChisGetSeqNumber256N1
    * 
    * 原 csHIS.dll 編號： 1.53
    * 
    * 原 csHIS.dll API 名稱： hisGetSeqNumber256N1
    * 
    * 用途： 取得就醫序號時使用-新增加取就醫序號同時取就醫識別碼
    * 
    *       註 1：有關虛擬健保卡 QR CODE 憑證(Token)取就醫序號及安全簽章之使用限制：
    *           同一組虛擬健保卡 QR CODE 僅能取一次就醫序號，若就醫類別非 01-09 及補卡註記為 2，則同一組虛擬健保卡 QR CODE 可取多次序號(空白)及安全簽章。
    * 
    *       註 2：透過虛擬健保卡整合視訊診療門診邏輯亦相同。
                ※請院所於呼叫此功能取得就醫序號前必須確認民眾身分。

    * 輸入參數規格： Input(cTreatItem: String 3 Bytes, cBabyTreat: String 2 Bytes, cTreatAfterCheck: String 1 Bytes)
    * 輸入定義說明：
    * 
        cTreatItem: String 3 Bytes 為就醫類別(HC 健 8-1)，為英數字，長度需可存放三個char(包括尾端之\0)

        cBabyTreat: String 2 Bytes 為新生兒就醫註記(HC 健 8-2)，為英數字，長度需可存放兩個 char(包括尾端之\0)。

        cTreatAfterCheck: String 1 Bytes 為補卡註記，傳入'1'表正常、'2'表補卡，長度需可存放一個 cha
    */
    public async GetSeqNumber256N1(args: GetSeqNumber256): Promise<CSHISResultWithData<SeqNumber256N1>> {
        var ret = await this.CallApi<VNHIOutput>("/VHC/VHChisGetSeqNumber256N1", {
            cTreatItem: args.TreatItem,
            cBabyTreat: args.BabyTreat,
            cTreatAfterCheck: args.TreatAfterCheck
        });
        var result = this.toCSHISResultWithData<SeqNumber256N1>(ret);
        
        result.Data = new SeqNumber256N1();
        if (ret.pBuffer) {
            result.Data.ClinicDateTime = DateHelper.parseROCDateTime(this.take(ret.pBuffer, 1, 13), true);
            result.Data.Number = this.take(ret.pBuffer, 14, 17);
            result.Data.ClinicCode = this.take(ret.pBuffer, 18, 27);
            result.Data.SecuritySeal = this.take(ret.pBuffer, 28, 283);
            result.Data.SamId = this.take(ret.pBuffer, 284, 295);
            result.Data.SameDay = this.take(ret.pBuffer, 296, 296);
            result.Data.TreatId = this.take(ret.pBuffer, 297, 316);
        }
        return result;
        /*就診日期時間(1-13)
就醫序號(14-17) Vxxx
醫療院所代碼(18-27)
安全簽章(28-283) (此 256Bytes 為安全簽章為 RSA 所產生
之亂數，再經過轉換所呈現之數值)
SAM ID(284-295) <= VHCcsOpenCom 傳入之 SAMID
是否同日就診(296)，'Y'表同日就診，'N'表非同日就診
就醫識別碼(297-316*/
    }

    /**
    * 取得就醫識別碼
    */
    // public async GetTreatNumNoICCard(patientId: string, hospCode: string): Promise<CSHISResultWithData<TreatNumNoICCard>> {

    //     return this.CallCsHis("GetTreatNumNoICCard", {
    //         patientId: patientId,
    //         hospCode: hospCode
    //     });
    // }

    /**
    * API name VHChisGetQuickInsurence
    * 
      原 csHIS.dll 編號： 1.55

      原 csHIS.dll API 名稱： hisGetQuickInsurence

      用途： 取得民眾在保狀態查核時使用

      輸入參數規格： 無輸入參數
    */
    public async QuickInsurence(cTreatItem: string): Promise<CSHISResult> {
        var ret = await this.CallApi<VNHIOutput & { RtnCode: string }>("/VHC/VHChisGetQuickInsurence", null);

        return {
            StatusCode: parseInt(ret.errorCode) || ret.ERRORCODE || parseInt(ret.RtnCode),
            ErrorMsg: ret.message || this.ErrorMsg(ret.ERRORCODE)
        }
    }


    /**
    * API name getSDKVersion
    * 
    * 用途： 取得虛擬健保卡 SDK 版本資訊
    * 
    * 輸入參數規格： 無
    */
    public async GetAPIVersion(): Promise<CSHISResultWithData<string>> {
        var ret = await this.CallApi<{ version: string }>('/utility/getSDKVersion', null);
        return {
            ErrorMsg: '',
            StatusCode: 0,
            Data: ret.version
        }
    }

    /**
     *
     */
    constructor(protected hcrService: HcrService, private httpClient: HttpClient) {
        super(hcrService);
    }
    baseUrl = 'http://localhost:3033'
    private async CallApi<T>(path: string, body: any): Promise<T> {
        try{
            var ret = await this.httpClient.post<T>(this.baseUrl + path, body, {
                headers: { "Content-Type": "application/json" }
            }).toPromise()
            console.log('虛擬健保卡SDK呼叫>>> ', path)
            console.log('虛擬健保卡SDK回傳<<< ', ret)
            return ret;
        }catch(ex){
            console.log(ex)
            return  {ERRORCode:-1,message:'虛擬健保卡API呼叫失敗'} as any
        }
    }

    public async uploadqrcode(code: string): Promise<CSHISResult> {
        var ret = await this.CallApi<VNHIOutput>("/QRCodeReader/uploadqrcode", {
            QRCodeString: code
        })
        return this.toCSHISResult(ret)
    }
    /**
     * /QRCodeReader/VHCcleanQRCode API 重置並清除已掃描 QRCode 資料。
     * 
     * API name VHCcleanQRCode
     * 
     * 用途 重置並清除已掃描 QRCode 資料。
     * 
     * 輸入參數規格 無
     */
    public async cleanQRCode(): Promise<CSHISResult> {
        var ret = await this.CallApi<VNHIOutput>("/QRCodeReader/VHCcleanQRCode", {
        })
        return this.toCSHISResult(ret);
    }







    /** 更新可用次數
     *  
     *  虛擬卡沒有這玩意，直接給過
     */
    public UpdateHCContents(): Promise<CSHISResult> {
        return Promise.resolve({
            StatusCode:0,
            ErrorMsg:''
        })
    }





    private toCSHISResult(ret: VNHIOutput): CSHISResult {
        return {
            StatusCode: ret.ERRORCODE || parseInt(ret.errorCode) || 0,
            ErrorMsg: ret.message || this.ErrorMsg(ret.ERRORCODE || parseInt(ret.errorCode) || 0),
        }
    }
    private toCSHISResultWithData<T>(ret: VNHIOutput): CSHISResultWithData<T> {
        return {
            StatusCode: ret.ERRORCODE || parseInt(ret.errorCode) || 0,
            ErrorMsg: ret.message || this.ErrorMsg(ret.ERRORCODE || parseInt(ret.errorCode) || 0),
            Data:null
        }
    }
    private ErrorMsg(errorCode: number): string {
        switch (errorCode) {


            case 0: return '成功';
            case 1001: return '向雲端 server 取雲端藥歷失敗';
            case 1002: return '向雲端 server 取 vhcCloudToken 失敗';
            case 1003: return '向雲端 server 取處方箋簽章失敗';
            case 1004: return '向雲端 server 取健保卡資料失敗';
            case 1005: return '向雲端 server 取 QRCodeToken 失敗';
            case 1006: return '向雲端 server 更新健保卡資料失敗';
            case 1007: return '向雲端 server 取就醫序號失敗';
            case 1009: return '向雲端 server 退掛失敗';
            case 1010: return '向雲端 server 清除健保卡資料失敗';
            case 1011: return '取 HospID & SAMID 錯誤';
            case 1012: return '清除虛擬健保卡資料失敗';
            case 1013: return '認證碼編號無效，請重新安裝或聯絡虛擬健保卡技術團隊';
            case 1014: return '清除計時器失敗';
            case 1015: return 'Device Token 過期，請關閉程式後再重新啟動';
            case 1016: return 'Device Token 失效，請關閉程式後再重新啟動';
            case 1017: return 'Device Token 不存在，請關閉程式後再重新啟動';
            case 1018: return 'QRCode 過期，請重刷 QRCode';
            case 1019: return 'QRCode 不存在，請重刷 QRCode';
            case 1020: return '重複使用 QRCode，請就診民眾重新整理頁面產生新的 QRCode';
            case 1021: return 'QRCode Token 過期，請重刷 QRCode';
            case 1022: return 'QRCode Token 失效，請重刷 QRCode';
            case 1023: return 'QRCode Token 不存在，請重刷 QRCode';
            case 1024: return '裝置認證金鑰錯誤';
            case 1025: return '設置檔無認證編碼';
            case 1026: return '設置檔無 Device Token';
            case 1027: return '取重大傷病資料失敗';
            case 1028: return '取 ICD10 押碼失敗';
            case 1029: return '取 ICD10 解押碼失敗';
            case 1030: return '重大傷病組數異常';
            case 1033: return '計算就醫診療日期個數失敗';
            case 1034: return '計算民眾身分證個數失敗';
            case 1035: return '更新資料庫單一就診民眾就醫診療資料物件失敗';
            case 1036: return '更新資料庫就醫診療資料失敗';
            case 1037: return '刪除資料庫就醫資料失敗';
            case 1038: return '處方箋資料離線寫入資料庫失敗';
            case 1039: return '依狀態取資料庫離線處方箋資料失敗';
            case 1040: return '更新資料庫處方箋資料失敗';
            case 1041: return '刪除處方箋資料失敗';
            case 1042: return '醫事機構未參與虛擬健保卡試辦案';
            case 1043: return '版本不正確';
            case 1045: return '無登錄資料且離線，請使用者接上網路進行安裝';
            case 1046: return '離線使用認證已過期，請連上網路更新';
            case 1050: return '註冊失敗';
            case 1051: return '註冊失敗';
            case 1053: return '註冊失敗';
            case 1054: return '重複註冊';
            case 1055: return '雲端伺服器錯誤';
            case 1056: return '雲端伺服器錯誤';
            case 1057: return '登錄資料錯誤';
            case 1058: return '登錄資料錯誤';
            case 1059: return '登錄資料有效時間異常';
            case 1099: return '離線不提供此 API 服務';
            case 1101: return '裝置認證金鑰 Device Token 已過期';
            case 1102: return '裝置認證 Device Token 不存在';
            case 1103: return 'QRCODE TOKEN 已過期，請重新讀取上傳 QRCode';
            case 1104: return 'QRCODE TOKEN 不存在，請重新讀取上傳 QRCode';
            case 1105: return 'SAMID 參數不能為空';
            case 1106: return 'vhcCloudToken 不存在';
            case 1107: return 'vhcCloudToken 已到期';
            case 1108: return '日期格式有誤';
            case 1109: return '加密失敗，請求 IP 或請求日期有誤';
            case 1110: return 'QRCODE 不存在';
            case 1111: return '視訊診療，限制同一個 QRCODE 只能取一次就醫序號';
            case 1112: return '查詢不到符合的 clientSeqNum 與 signkey';
            case 1113: return 'SAMID 與伺服器端的資料不符';
            case 1114: return '醫事機構代碼參數與舊的不符';
            case 1116: return 'Cloud Token 已轉換回 QRCode Token，該 Cloud Token 已失效';
            case 1017: return 'Device Token 不存在，請關閉程式後再重新啟動';
            case 4013: return '未置入虛擬健保卡';
            case 5005: return '讀取雲端 server 時間失敗';
            case 5015: return '虛擬健保卡「門診處方箋」讀取失敗。';
            case 5020: return '要寫入的資料和虛擬健保卡不是屬於同一人。';
            case 5022: return '找不到虛擬健保卡「就醫資料登錄」中的該組資料。';
            case 5023: return '虛擬健保卡「就醫資料登錄」寫入失敗。';
            case 2000: return '離線狀態下成功讀取 QRCode';
            case 2101: return 'QRCode 已過有效期限';
            case 2102: return '系統時間不一致，請調整電腦系統時間';
            case 2103: return 'SDK 必須是離線狀態';
            case 2105: return '就診日期時間錯誤';
            case 2106: return 'SDK 目前是離線狀態';
            case 2107: return '離線上傳失敗';
            case 2108: return 'QRCode 解密錯誤';
            case 2109: return '設置檔無 Device Token';
            case 2201: return '就醫資料離線寫入資料庫失敗';
            case 2202: return '計算民眾身分證個數失敗';
            case 2203: return '處方箋資料離線寫入資料庫失敗';
            case 2204: return '依狀態取資料庫離線就醫資料失敗';
            case 2205: return '依狀態取資料庫離線處方箋資料失敗';
            case 2206: return '依狀態和就醫日期取資料庫離線處方箋資料失敗';
            case 2207: return '更新資料庫就醫診療資料失敗';
            case 2208: return '更新資料庫處方箋資料失敗';
            case 2209: return '刪除資料庫就醫資料失敗';
            case 2210: return '刪除處方箋資料失敗';
            case 4000: return '讀卡機 timeout';
            case 4012: return '未置入安全模組卡';
            case 4013: return '未置入健保 IC 卡';
            case 4014: return '未置入醫事人員卡';
            case 4029: return 'IC 卡權限不足';
            case 4032: return '所插入非安全模組卡';
            case 4033: return '所置入非健保 IC 卡';
            case 4034: return '所置入非醫事人員卡';
            case 4042: return '醫事人員卡 PIN 尚未認證成功';
            case 4050: return '安全模組尚未與 IDC 認證';
            case 4051: return '安全模組與 IDC 認證失敗';
            case 4061: return '網路不通';
            case 4071: return '健保 IC 卡與 IDC 認證失敗';
            case 5001: return '就醫可用次數不足';
            case 5002: return '卡片已註銷';
            case 5003: return '卡片已過有限期限';
            case 5004: return '非新生兒一個月內就診';
            case 5005: return '讀卡機的日期時間讀取失敗';
            case 5006: return '讀取安全模組內的「醫療院所代碼」失敗';
            case 5007: return '寫入一組新的「就醫資料登錄」失敗';
            case 5008: return '安全模組簽章失敗';
            case 5009: return '無寫入就醫相關紀錄之權限';
            case 5010: return '同一天看診兩科(含)以上';
            case 5012: return '此人未在保或欠費';
            case 5015: return '「門診處方箋」讀取失敗。';
            case 5016: return '「長期處方箋」讀取失敗。';
            case 5017: return '「重要醫令」讀取失敗。';
            case 5020: return '要寫入的資料和健保 IC 卡不是屬於同一人。';
            case 5022: return '找不到「就醫資料登錄」中的該組資料。';
            case 5023: return '「就醫資料登錄」寫入失敗。';
            case 5028: return 'HC 卡「就醫費用紀錄」寫入失敗。';
            case 5033: return '「門診處方箋」寫入失敗。';
            case 5051: return '新生兒註記寫入失敗';
            case 5052: return '有新生兒出生日期，但無新生兒胞胎註記資料';
            case 5056: return '讀取醫事人員 ID 失敗';
            case 5057: return '過敏藥物寫入失敗。';
            case 5061: return '同意器官捐贈及安寧緩和醫療註記寫入失敗寫入失敗';
            case 5062: return '放棄同意器官捐贈及安寧緩和醫療註記輸入';
            case 5067: return '安全模組卡「醫療院所代碼」讀取失敗';
            case 5068: return '預防保健資料寫入失敗';
            case 5071: return '緊急聯絡電話寫失敗。';
            case 5078: return '產前檢查資料寫入失敗';
            case 5079: return '性別不符，健保 IC 卡記載為男性';
            case 5081: return '最近 24 小時內同院所未曾就醫，故不可取消就醫';
            case 5082: return '最近 24 小時內同院所未曾執行產檢服務紀錄，故不可取消產檢';
            case 5083: return '最近 6 次就醫不含就醫類別 AC，不可單獨寫入預防保健或產檢紀錄';
            case 5084: return '最近 24 小時內同院所未曾執行保健服務項目紀錄，故不可取消保健服務';
            case 5087: return '刪除「孕婦產前檢查(限女性)」全部 11 組的資料失敗。';
            case 5093: return '預防接種資料寫入失敗';
            case 5102: return '使用者所輸入之 pin 值，與卡上之 pin 值不合';
            case 5105: return '原 PIN 碼尚未通過認證';
            case 5107: return '使用者輸入兩次新 PIN 值，兩次 PIN 值不合';
            case 5108: return '密碼變更失敗';
            case 5109: return '密碼輸入過程按『取消』鍵';
            case 5110: return '變更健保 IC 卡密碼時, 請移除醫事人員卡';
            case 5111: return '停用失敗，且健保 IC 卡之 Pin 碼輸入功能仍啟用';
            case 5122: return '被鎖住的醫事人員卡仍未解開';
            case 5130: return '更新健保 IC 卡內容失敗。';
            case 5141: return '未置入醫事人員卡, 僅能讀取重大傷病有效起訖日期';
            case 5150: return '卡片中無此筆就醫記錄';
            case 5151: return '就醫類別為數值才可退掛';
            case 5152: return '醫療院所不同，不可退掛';
            case 5153: return '本筆就醫記錄已經退掛過，不可重覆退掛';
            case 5154: return '退掛日期不符合規定';
            case 5160: return '就醫可用次數不合理';
            case 5161: return '最近一次就醫年不合理';
            case 5162: return '最近一次就醫序號不合理';
            case 5163: return '住診費用總累計不合理';
            case 5164: return '門診費用總累計不合理';
            case 5165: return '就醫累計資料年不合理';
            case 5166: return '門住診就醫累計次數不合理';
            case 5167: return '門診部分負擔費用累計不合理';
            case 5168: return '住診急性 30 天、慢性 180 天以下部分負擔費用累計不合理';
            case 5169: return '住診急性 31 天、慢性 181 天以上部分負擔費用累計不合理';
            case 5170: return '門診+住診部分負擔費用累計不合理';
            case 5171: return '[門診+住診(急性 30 天、慢性 180 天以下)]部分負擔費用累計不合理';
            case 5172: return '門診醫療費用累計不合理';
            case 5173: return '住診醫療費用累計不合理';
            case 5174: return '取就醫識別碼失敗';
            case 6005: return '安全模組卡的外部認證失敗';
            case 6006: return 'IDC 的外部認證失敗';
            case 6007: return '安全模組卡的內部認證失敗';
            case 6008: return '寫入讀卡機日期時間失敗';
            case 6014: return 'IDC 驗證簽章失敗';
            case 6015: return '檔案大小不合或檔案傳輸失敗';
            case 6016: return '記憶體空間不足';
            case 6017: return '權限不足無法開啟檔案或找不到檔案';
            case 6018: return '傳入參數錯誤';
            case 9001: return '送至 IDC Message Header 檢核不符';
            case 9002: return '送至 IDC 語法不符';
            case 9003: return '與 IDC 作業逾時';
            case 9004: return 'IDC 異常無法 Service';
            case 9010: return 'IDC 無法驗證該卡片';
            case 9011: return 'IDC 驗證健保 IC 卡失敗';
            case 9012: return 'IDC 無該卡片資料';
            case 9013: return '無效的安全模組卡';
            case 9014: return 'IDC 對安全模組卡認證失敗';
            case 9015: return '安全模組卡對 IDC 認證失敗';
            case 9020: return 'IDC 驗章錯誤';
            case 9030: return '無法執行卡片管理系統的認證';
            case 9040: return '無法執行健保 IC 卡 Applet Perso 認證';
            case 9041: return '健保 IC 卡 Applet Perso 認證失敗';
            case 9050: return '無法執行安全模組卡世代碼更新認證';
            case 9051: return '安全模組卡世代碼更新認證失敗';
            case 9060: return '安全模組卡遭停約處罰';
            case 9061: return '安全模組卡不在有效期內';
            case 9062: return '安全模組卡合約逾期或尚未生效';
            case 9070: return '上傳資料大小不符無法接收檔案';
            case 9071: return '上傳日期與 Data Center 不一致';
            case 9081: return '卡片可用次數大於 3 次, 未達可更新標準';
            case 9082: return '此卡已被註銷, 無法進行卡片更新作業';
            case 9083: return '不在保';
            case 9084: return '停保中';
            case 9085: return '已退保';
            case 9086: return '個人欠費';
            case 9087: return '負責人欠費';
            case 9088: return '投保單位欠費';
            case 9089: return '個人及單位均欠費';
            case 9090: return '欠費且未在保';
            case 9091: return '聲明不實';
            case 9092: return '其他';
            case 9100: return '藥師藥局無權限';
            case 9101: return '所置入非醫師卡';
            case 9102: return '此功能不支援';
            case 9103: return '保留項目';
            case 9104: return '保留項目';
            case 9105: return '保留項目';
            case 9106: return '保留項目';
            case 9107: return '保留項目';
            case 9108: return '保留項目';
            case 9109: return '保留項目';
            case 9110: return '保留項目';
            case 9111: return '保留項目';
            case 9112: return '保留項目';
            case 9113: return '保留項目';
            case 9114: return '保留項目';
            case 9115: return '保留項目';
            case 9116: return '保留項目';
            case 9117: return '保留項目';
            case 9118: return '保留項目';
            case 9119: return '保留項目';
            case 9120: return '保留項目';
            case 9121: return '保留項目';
            case 9122: return '保留項目';
            case 9123: return '保留項目';
            case 9124: return '保留項目';
            case 9125: return '保留項目';
            case 9126: return '保留項目';
            case 9127: return '保留項目';
            case 9128: return '保留項目';
            case 9129: return '持卡人於非限制院所就診';
            case 9130: return '醫事卡失效';
            case 9140: return '醫事卡逾效期';
            case 9200: return '安全模組檔目錄錯誤或不存在或數量超過一個以上';
            case 9201: return '初始安全模組檔讀取異常，請在 C:\NHI\SAM\COMX1 目錄下放置健保署正確安全模組檔。';
            case 9202: return '安全模組檔讀取異常，已在其它電腦使用過，請在C:\NHI\SAM\COMX1 目錄下放置健保署正確安全模組檔。';
            case 9203: return '卡片配對錯誤，正式卡與測試卡不能混用';
            case 9204: return '找不到讀卡機，或 PCSC 環境異常';
            case 9205: return '開啟讀卡機連結埠失敗';
            case 9210: return '健保 IC 卡內部認證失敗';
            case 9211: return '雲端安全模組(IDC)對健保 IC 卡認證失敗';
            case 9212: return '健保 IC 卡對雲端安全模組認證失敗';
            case 9213: return '雲端安全模組卡片更新逾時';
            case 9220: return '醫事人員卡內部認證失敗';
            case 9221: return '雲端安全模組(IDC)驗證醫事人員卡失敗';
            case 9230: return '安全模組檔「醫療院所名稱」讀取失敗';
            case 9231: return '安全模組檔「醫療院所簡稱」讀取失敗';
            case 9240: return '雲端安全模組主控台沒起動';
            case 4043: return '健保卡讀取/寫入作業異常';
            case 5174: return '取就醫識別碼失敗';
            case 6019: return '醫事人員卡密碼不能為空白';
            case 9244: return '醫事機構卡 PIN 碼卡尚未認證';
            case 9245: return '無效 HCA 憑證';
            case 9250: return '虛擬醫師驗 PIN 失敗';
            case 9251: return '虛擬醫師卡逾時，請重新登入';
            case 9260: return '虛擬醫師卡 Session HPC ID 為空值';
            case 9261: return '虛擬醫師卡 HPC ID 與 Session 中的 HPC ID 不一致';
            case 9262: return '虛擬醫師卡 HPC 狀態資料不存在';
            case 9263: return '虛擬醫師卡 HPC 狀態資料中的 Session ID 不一致';
            case 9264: return '虛擬醫師卡 HPC 狀態資料中目前狀態不為 2(SAM-HPC 已驗證)';
            case 9265: return '虛擬醫師卡 HPC 狀態已逾期';
            case 9266: return '虛擬醫師卡 HPC 狀態資料中目前狀態不為 3(PC PIN 已驗證)';
            case 9267: return '同一醫師卡在另外一台電腦登入';
            case 9268: return 'IDC 驗證虛擬醫師卡失敗';
            case 9206: return '設定 csSetComConfig()連結埠失敗';
            case 9207: return '取得 csGetComConfig()連結埠失敗';
            case 9093: return '即時查保-投保身分不一致';
            case 9094: return '即時查保-停保或退保';
            case 9095: return '即時查保-欠費';
            case 9102: return '此功能不支援';
            case 9056: return 'HCA 讀卡機或憑證未安裝';
            case 9039: return 'HCA 密碼(PIN 碼)不正確';
            case 9043: return 'HCA PIN 碼已鎖住';
            case 9042: return 'HCA 密碼已過期';
            case 9045: return 'HCA 憑證狀態為已被撤銷';
        }
    }
    take(str: string, start: number, end: number) {
        var sIndex = start - 1;
        var eIndex = end;
        // //var takeLen = end - start+1;
        // var sb = str.substring(sIndex,eIndex);
        // for(let c of sb){
        //     if(/[\u4E00-\u9FFF]/g.test(c)){
        //         eIndex--;
        //     }
        // }
        return str.substring(sIndex, eIndex);
    }
}
declare type VNHIOutput = {
    ERRORCODE: number,
    pBuffer: string,

    // 錯誤的話長這樣
    errorCode: string;
    message: string
}
interface PatBasic {
    cardNo: string
    name: string
    ROCID: string
    BirthDate: string
    sex: string
    cardIssueDate: string
    cardStatusNote: string
    emergentTel1: string
    emergentTel2: string
}
interface RegBasic {
    /** 卡片號碼*/
    cardNo: string,
    /**姓名 */
    name: string,
    /**身分證號或身分證明文件號碼 */
    ROCID: string,
    /**出生日期 */
    birthDate: string,
    /**性別 */
    sex: string,
    /**發卡日期 */
    cardIssueDate: string,
    /**卡片註銷註記 「預留欄位，尚未提供資料」 */
    CardStatusNote: string,
    /** 保險人代碼 「預留欄位，尚未提供資料」 */
    HICCode: string,
    /**保險對象身份註記 */
    HICTypeNote: string,
    /**卡片有效期限 */
    cardExpireDate: string,
    /**就醫可用次數「預留欄位，尚未提供資料」 */
    AvailableClinicTimes: string,
    /**新生兒依附註記之新生兒出生日期 「預留欄位，尚未提供資料」 */
    newBornDate: string,
    /** 新生兒依附註記之新生兒胞胎註記 「預留欄位，尚未提供資料」 */
    NewBornNote: string,
}

