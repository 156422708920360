import { OrderTypeEnum } from "src/app/enums/OrderTypeEnum";
import { HrxCalc } from "./hrx-calc";
import { ValidateMsg } from "./hrx-validate-msg";
import { OrderSpecialRuleEnum } from "src/app/enums/OrderSpecialRuleEnum";
import { CalcRuleFactory } from "./hrx-calcrule.factory";

/** 完診自動補值Model */
export class AutoFillData {
    InfoDays: number;
    FreqQty: number;
    Dose: number;
    DailyDose: number;
    TotalDose: number;
}
/** 新增醫令初始值Model */
export class FillData {
    spRule: OrderSpecialRuleEnum;
    /** 次劑量 */
    dose: number;
    /** 頻率 */
    freq: string;
    /** 天數 */
    days: number;
    /** 途徑 */
    way: string;
    /** 調劑 */
    disp: string;
    /** 總量 */
    totalDose: number;
    constructor() {
        this.dose = null;
        this.freq = null;
        this.days = null;
        this.way = null;
        this.disp = null;
        this.totalDose = null;
    }
};

/** 根據不同歸屬的補值規則 */
export interface IRxTypeFillRule {
    /** 初始值
     *    此動作會發生在 套餐值帶入且p255規則執行後，將尚為空的欄位進行填補
     */
    init(): FillData;
    /** 完診補值
     *    此動作會發生在AutoFill處理完畢後，將尚為空的欄位進行填補
     */
    autoFill(): AutoFillData;
    /** 資料完整性驗證
     *    此動作會在完診資料填補完後進行
     *    AutoFill的驗證不再此(同歸屬第一/最後項必填)
     */
    validate(): ValidateMsg[];
}
export class RxFillRuleFactory{
    static GetFillRule(c:HrxCalc):IRxTypeFillRule{
        // 特殊1 輸入即總/次
        if(c.rxCodeQtyAsTotal.indexOf(c.model.StdCode)>=0){
            return new POrderByDaysFillRule(c);
        }
        if(c.model.RxType == OrderTypeEnum.T2_OralDrugFee || c.rx?.IsByOral){
            return new OralFillRule(c);
        }else if(c.model.RxType == OrderTypeEnum.T3_ExtDrugFee){
            return new ExtFillRule(c);
        }else if(c.model.RxType == OrderTypeEnum.T14_HemodialysisFee){
            return new HemodialysisFillRule(c);
        }else if(c.rxTypeInj.indexOf(c.model.RxType)>=0){
            return new InjFillRule(c);
        }else if(c.model.RxType == OrderTypeEnum.T6_Lab){
            return new LabFillRule(c);
        }
        else if(c.rxTypeOther.indexOf(c.model.RxType)>=0){
            return new OtherFillRule(c);
        }else{
            return new NoFillRule(c);
        }
    }
}
/** 口服藥
 *  初始值：無
 *  自動補值：
 *      當有日分/頻率計算值下
 *      有輸入量無總量=>重新計算總量
 *      有總量無輸入量=>回推輸入量
 *  驗證：  
 *      輸入量不為空
 *      總量輸入量不為空
 *      途徑不為健保定義
 */
export class OralFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        if (this.hrxCalc.rx && this.hrxCalc.rx.SyrupNote == 'a1' && this.hrxCalc.rx.SpecUnit) {
            this.hrxCalc.model.BoxUnit = this.hrxCalc.rx.UseUnit;
            this.hrxCalc.model.Unit = this.hrxCalc.rx.SpecUnit;
        }
        return new FillData();
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        var model = this.hrxCalc.model;
        //特殊規則 列印指示用文字 歸屬也為2
        if(model.RxCode.startsWith('/')){
            ret.DailyDose=1;
            ret.Dose= 1;
            ret.FreqQty = 1;
            ret.InfoDays = 1;
            ret.TotalDose =1;
            return ret;
        }
        // 有缺則不補
        if(!model.InfoDays||!model.FreqQTY){
            return ret;
        }
        var rule = CalcRuleFactory.getCalcRule(this.hrxCalc);
        if(rule.IsTargetEmpty()){
            rule.QtyToDose();
        }
        // if (!model.TotalDose && model.QTY) {
        //     this.hrxCalc.getCalcRule().QtyToDose();
        // }
        // 有總無次 補次 非藥水補次
        else if (!rule.IsTargetEmpty() && !model.QTY && (this.hrxCalc.rx &&this.hrxCalc.rx.SyrupNote != 'a1')) {
            rule.DoseToQty();
        }
        return ret;
    }

    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        if (!model.TotalDose) {
            ret.push(new ValidateMsg(model.RxCode, 'TOTAL', 'ERROR'))
        }
        return ret;
    }
}
/** 外用
 *  初始值：
 *         輸入輛:1
 *         總量:1
 *         途徑:EXT
 *  自動補值：
 *      日分(計算):1
 *      頻率(計算):1
 *      總量:1
 *  驗證：  
 *      輸入量不為空
 *      途徑不為健保定義
 */
export class ExtFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        fillData.dose = 1;
        fillData.totalDose = 1;
        fillData.way = 'EXT';
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        ret.TotalDose = 1;
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        
        return ret;
    }
}
/** 注射(與特材)
 *  初始值：
 *         輸入量:1
 *         途徑:IM
 *  自動補值：
 *      日分(計算):1
 *      頻率(計算):1
 *      總量:同輸入量
 *  驗證：  
 *      輸入量不為空
 *      途徑不為健保定義
 */
export class InjFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        fillData.dose = 1;
        fillData.way = this.hrxCalc.model.RxType === 4 ? 'IM' : null;
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        if (this.hrxCalc.model.QTY) {
            ret.TotalDose = this.hrxCalc.model.QTY;
        }
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        
        return ret;
    }
}
/** 其他(歸屬7~11)
 *  初始值：總量:1
 *         次量:1
 *  自動補值：
 *      日分(計算):1
 *      頻率(計算):1
 *  驗證：  
 *      輸入量為空或0時提醒
 *      總量為空時提醒
 */
export class OtherFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        fillData.totalDose = 1;
        fillData.dose = 1;
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        if (model.TotalDose==null) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'ERROR');
            ret.push(m)
        }
        if (model.TotalDose==0) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為0，會影響批價金額，建議填入總量重批`)
            ret.push(m)
        }
        if (model.TotalDose != 1) {
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為 ${model.TotalDose}！`)
            ret.push(m)

        }
        return ret;
    }
}
/** 其他(歸屬6 檢驗)
 *  初始值：總量:1
 *         次量:1
 *  n欄位為 6/7 且 dispTp 為空 時 dispTp 補 4
 *  自動補值：
 *      日分(計算):1
 *      頻率(計算):1
 *  驗證：  
 *      輸入量為空或0時提醒
 *      總量為空時提醒
 */
export class LabFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        var model = this.hrxCalc.model;
        if ((model.SPRule == 6 ) && (model.DispTP == '' || model.DispTP == null)) {
            fillData.disp = '4'
        }
        else if ((model.SPRule == 7 ) && (model.DispTP == '' || model.DispTP == null))
        {
            fillData.disp = '4'
        }
        fillData.totalDose = 1;
        fillData.dose = 1;
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        if (model.TotalDose==null) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'ERROR');
            ret.push(m)
        }
        if (model.TotalDose==0) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為0，會影響批價金額，建議填入總量重批`)
            ret.push(m)
        }
        if (model.TotalDose != 1) {
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為 ${model.TotalDose}！`)
            ret.push(m)

        }
        return ret;
    }
}
/** 按日P醫令 類似P1015C P1016C */
export class POrderByDaysFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        
        fillData.dose = 1;
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }

        if (!model.TotalDose) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為0，會影響批價金額，建議填入總量重批`)
            ret.push(m)
        }
        return ret;
    }
}
export class HemodialysisFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        fillData.totalDose = 1;
        fillData.dose = 1;
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        ret.InfoDays = 1;
        ret.FreqQty = 1;
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }
        if (model.TotalDose==null) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'ERROR');
            ret.push(m)
        }
        if (model.TotalDose==0) {
            //ret.push(new ValidateMsg(model.RxCode,'TOTAL','ERROR'))
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為0，會影響批價金額，建議填入總量重批`)
            ret.push(m)
        }
        if (model.TotalDose != 1) {
            var m = new ValidateMsg(model.RxCode, 'TOTAL', 'WARNING');
            m.setMseeage(`醫令 ${model.RxCode} 的 [總量] 為 ${model.TotalDose}！`)
            ret.push(m)

        }
        return ret;
    }
}
/** 不填補
 * 
 *  初始值：無
 * 
 *  自動補值：無
 * 
 *  驗證：  
 *      輸入量必填
 *      頻率必填
 *      日分必填
 *      總量必填
 */
export class NoFillRule implements IRxTypeFillRule {
    constructor(private hrxCalc: HrxCalc) {
    }
    init(): FillData {
        var fillData: FillData = new FillData();
        return fillData;
    }
    autoFill(): AutoFillData {
        var ret = new AutoFillData();
        return ret;
    }
    validate(): ValidateMsg[] {
        var ret: ValidateMsg[] = [];
        var model = this.hrxCalc.model;
        if (!model.QTY) {
            ret.push(new ValidateMsg(model.RxCode, 'QTY', 'ERROR'))
        }

        if (!model.Days) {
            ret.push(new ValidateMsg(model.RxCode, 'DAY', 'ERROR'))
        }

        if (!model.FreqQTY) {
            ret.push(new ValidateMsg(model.RxCode, 'FREQ', 'ERROR'))
        }
        if (!model.TotalDose) {
            ret.push(new ValidateMsg(model.RxCode, 'TOTAL', 'ERROR'))
        }
        return ret;
    }
}