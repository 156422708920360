import { Component, OnInit } from '@angular/core';
import { browser, by, until } from 'protractor';

@Component({
  selector: 'app-icon-sim',
  template: `
  <div style="overflow: hidden;
    width: 19px;
    height: 16px;
    position: relative">
    <svg style="position: absolute;left: -16px;top: -2px;"
         fill="#474647" stroke="#474647" stroke-width=".228"  height="46" width="55">
         <path fill="#FFD05B" stroke-linejoin="round" d="M33.553 18.118a1.342 1.342 0 0 1 1.342 1.342v12.079a1.342 1.342 0 0 1 -1.342 1.342H17.447a1.342 1.342 0 0 1 -1.342 -1.342v-9.73l3.691 -3.691h13.757Zm-2.684 2.684H20.132a1.342 1.342 0 0 0 -1.342 1.342v8.053a1.342 1.342 0 0 0 1.342 1.342h10.737a1.342 1.342 0 0 0 1.342 -1.342v-8.052a1.342 1.342 0 0 0 -1.342 -1.342Zm-10.737 0.671h4.634c-0.517 0.242 -0.955 0.729 -1.243 1.342h-4.062v-0.671a0.671 0.671 0 0 1 0.671 -0.671Zm10.737 0a0.671 0.671 0 0 1 0.671 0.671v0.671h-4.061c-0.289 -0.613 -0.726 -1.1 -1.243 -1.342h4.632Zm0.671 2.013v2.013h-3.825c0.087 -0.315 0.134 -0.654 0.134 -1.007 0 -0.346 -0.045 -0.686 -0.128 -1.007h3.818Zm0 2.684v2.013H27.5a3.544 3.544 0 0 0 -0.434 -1.439c0.148 -0.169 0.278 -0.362 0.389 -0.574h4.084Zm0 2.684v1.342a0.671 0.671 0 0 1 -0.671 0.671h-4.341c0.53 -0.443 0.896 -1.151 0.971 -2.013h4.04Zm-11.408 2.013a0.671 0.671 0 0 1 -0.671 -0.671v-1.342h3.37c0.076 0.862 0.442 1.57 0.971 2.013h-3.67Zm-0.671 -2.684V26.17h4.087l0.052 0.097c-0.42 0.483 -0.705 1.157 -0.77 1.916h-3.369Zm0 -2.684v-2.013h3.82a4.018 4.018 0 0 0 -0.129 1.007c0 0.353 0.047 0.691 0.134 1.007h-3.825Zm6.039 -3.523c0.926 0 1.678 1.219 1.678 2.516 0 0.961 -0.412 1.788 -1.003 2.151 0.406 0.428 0.668 1.109 0.668 1.875 0 1.297 -0.752 2.181 -1.678 2.181s-1.678 -0.882 -1.678 -2.179c0 -0.961 0.413 -1.788 1.003 -2.151 -0.405 -0.429 -0.668 -1.109 -0.668 -1.876 0 -1.297 0.752 -2.516 1.678 -2.516Z"/>
    </svg>
  </div>
  `,
  
  styles: [`
  svg{
    margin-top: -16px;
  }
  `]
})
export class IconSimComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  // TODO
  // async openConsole(){
  //   var icon = await browser.wait(until.elementLocated(by.css('app-icon-sim')),5000);
  //   await icon.click();
  // }

}
