export enum RegisterWaitingListQueryTypeEnum{
  None,
  PatientNo,
  Birthday,
  Name,
  CellPhone,
  CId,
  Kcstmr,
  Doctor,
  WaitNo
}
