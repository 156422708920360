<app-std-system-code-import [typeCode]="systemCodeImportTargetType" (importDone)="handleSystemImportDone()" (onClose)="handleSystemImportClose()">
  <ng-template let-data #customBtn>
    <button type="button" (click)="onBtnSystemCodeAddClick(data)" class="vis-btn-config p-0 position-relative pl-2 w-18" title="新增">
      <mat-icon class="pref-icon add vis-combine-icon">add_circle</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">新增</span>
    </button>
  </ng-template>
</app-std-system-code-import>

<app-system-code-add [show]="showCreate" [addSystemCode]="addData" (onClose)="closeAdd()" (onAdded)="handleAddDone()"></app-system-code-add>
