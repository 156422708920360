import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';
import { NullOrEmpty } from '../utilities';

@Pipe({
  name: 'formatDateTime'
})
export class FormatDateTimePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    return DateHelper.formatDateTime(value);
  }
}

@Pipe({
  name: 'formatROCTime'
})
export class FormatROCTimePipe implements PipeTransform {

  transform(value: any, args?: any): string {
    let sepetator = '/';
    if (!NullOrEmpty(args) && args.toLowerCase().includes("bar")) {
      sepetator = '-';
    }

    if (!NullOrEmpty(value)) {
      if (!NullOrEmpty(args) && (args.toLowerCase().includes("ispadstart"))) {
        var tmp = value;
        if (typeof (value) === 'string') {
          tmp = new Date(value);
        }
        var timeSepetator=":";
        var combinSepetator=" ";
        if (!NullOrEmpty(args) && args.toLowerCase().includes("nosepetator")) {
          sepetator = '';
          timeSepetator = '';
        }
        var date = DateHelper.getROCDateStringWithSepetator(tmp, sepetator);
        var time = DateHelper.getTimeString(tmp,timeSepetator,true );
        if (!NullOrEmpty(args) && (args.toLowerCase().includes("timetohourmin"))) {
          time = DateHelper.getHourMinTime(tmp,true );
        }
        return date + combinSepetator + time;
      } else {
        return DateHelper.formatROCDateTime(value, false, true);
      }
    } else {
      return '';
    }
  }
}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {

  transform(value: Date, timeSepetator: string = ''): string {
    if (!value) { return ''; }
    const newDate = new Date(value);
    return DateHelper.getTimeString(newDate, timeSepetator, true);
  }
}
