import { PACSReceiver } from "src/app/shared/models/pACS-receiver";

export enum PACSStatusEnum {
  PENDING,
  SUCCESS,
  TIMEOUT,
  UNKNOW_ERROR
}

export interface PACSEmitterRecordDTO extends PACSReceiver  {
  /**
   * 掛號單序號
   */
  RegisterId: number;

  /**
   * PACS傳輸狀態
   */
  Status: PACSStatusEnum;
}
