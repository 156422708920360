<div class="container">
  <div class="row">
    <div class="col-9">
      <mat-form-field appearance="full" style="width: 100%;">
        <mat-label>{{title}}</mat-label>
        <mat-chip-list #chipList>
          <mat-chip *ngFor="let option of selectedOptions" [removable]="true" (removed)="remove(option)">
            {{ option.text }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input matInput [formControl]="control" [matAutocomplete]="auto" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" placeholder="{{placeHolder}}" #inputElement>
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="select($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.value"
            [disabled]="isSelected(option)">
            {{ option.text }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="col-3">
      <button type="button" class="btn btn-primary mb-2" style="font-size: 12px;" (click)="selectAll()">選擇全部</button>
      <button type="button" class="btn btn-danger" style="font-size: 12px;" (click)="clearAll()">清除全部</button>
    </div>
  </div>
</div>
