<div class="p-2 w-100 common-container d-flex justify-content-center" *ngIf="!isLetterHalf">
  <div class="page page-container" #reportContainer>
    <div class="content page-container" *ngIf="!reportData || !this.reportData.Pages || this.reportData.Pages.length == 0">
      <div style="width: 100%; text-align: center; padding: 20px; border: 1px solid black; margin-top: 20px;">
        查無資料
      </div>
    </div>

    <div class="content page-container" *ngIf="reportData && this.reportData.Pages && this.reportData.Pages.length > 0">
      <div class="page-content" *ngFor="let page of reportData.Pages;let i=index">
        <div class="page-detail">
          <div style="text-align: center;font-weight: bolder;">{{reportData.Title}}</div>
          <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" class="pres-report">
            <div style="display: flex;column-gap: 5px;">
              <table style="width: 100%">
                <tr>
                  <td style="width: 20%;">
                    特約醫院、診所 醫事服務機構代號及名稱
                  </td>
                  <td style="width: 80%;">
                    <div>{{page.ClinicNHICode}}</div>
                    <div>{{page.ClinicName}}</div>
                  </td>
                </tr>
              </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:20%">姓名：{{page.PatientName}}</td>
                <td style="width:10%">性別： {{page.PatientSex}}</td>
                <td style="width:45%">身分證字號：{{page.PatientCId}}</td>
                <td style="width:25%">出生日期：{{page.PatientBirthDay}} </td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:20%">就醫科別：{{page.Nhictp}}</td>
                <td style="width:10%">案類： {{page.CaseType}}</td>
                <td style="width:45%">就醫日期： {{page.RegDate}}</td>
                <td style="width:25%">健保卡就醫序號：{{page.Isqno}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:75%">免部分負擔代碼及原因：{{page.ApartCode}}</td>
                <td style="width:25%"></td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td>國際疾病分類代碼：{{page.DxCode}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td>傷病名稱及主要症候：{{page.DxName}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:60%;">
                  醫事檢驗、醫事放射檢查名稱
                </td>
                <td style="width:16%;">
                  總數量
                </td>
                <td style="width:14%;">
                  備註
                </td>
              </tr>
              <tr *ngFor="let hrx of page.HRxes">
                <td style="width:60%;border-bottom: 0;border-top: 0;">
                  {{hrx.SRxCode}} {{hrx.SRxName}}
                </td>
                <td style="width:16%;border-bottom: 0;border-top: 0;">
                  <div style="width:50%;display: inline-block;">{{hrx.HRxTotalDose}}</div>
                </td>
                <td style="width:14%;border-bottom: 0;border-top: 0;">
                  {{hrx.HRxRemark}}
                </td>
              </tr>

            </table>

            <table style="width: 100%;">
              <tr>
                <td style="width: 50%;border-bottom: 0;" rowspan="1">
                  診治醫生代號：{{page.ClinicNHICode}}<br>
                </td>
                <td style="width: 50%;border-bottom: 0;">
                  執行檢驗(查)醫事人員代號：
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">

                </td>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">
                  簽章：
                </td>
              </tr>

              <tr>
                <td style="width: 50%;border-top: 0;">
                  簽章：{{page.DoctorName}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  檢驗(查)日期：
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-bottom: 0;" rowspan="1">
                  特約醫院、診所 核章
                </td>
                <td style="width: 50%;border-bottom: 0;">
                  特約醫事檢驗、特約醫事放射機構 核章
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">
                  (醫事服務機構地址、電話及傳真) 電話： {{page.ClinicPhone}}
                </td>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">
                  (醫事服務機構代號、名稱、地址及電話) 電話： {{page.HspidPhone}}
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;">
                  {{page.ClinicAddress}} 傳真：{{page.ClinicFax}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  {{page.HspidCode}} {{page.HspidName}} {{page.HspidAddress}}
                </td>
              </tr>

            </table>
          </div>
          <div>
            <div style="display: flex;">
              <div style="-webkit-writing-mode: vertical-lr;writing-mode: vertical-lr;width: 3%;margin-top: 10px;">
                <div>注意事項</div>
              </div>
              <table style="width: 95%;margin-top: 5px; border: none;font-size: 12px;">
                <tr>
                  <td>
                    <div>(一)本處方如有塗改，需由原治醫師之蓋章確認，否則無效。</div>

                    <div>(二)本交付檢驗(查)處方為一式兩份，一份交病患供檢驗(查)用，另一份由處方特約醫事機構留存備查。</div>

                    <div>(三)交付檢驗(查)之處方箋自就醫日起一百八十日內有效。</div>
                  </td>
                </tr>
              </table>
            </div>
            <div *ngIf="page.SourceItreatnum && this.isPrintItreatnum" style="display: flex;">
              <table style="width: 40%; margin-top: 10px; margin-left: 20px; border: none; font-size: 12px;">
                <tr>
                  <td>
                    <div>原處方就醫識別碼：{{page.SourceItreatnum}}</div>
                    <div>原處方就醫時間：{{page.SourceRegDate}}</div>
                  </td>
                </tr>
              </table>
              <ngx-barcode6 style="padding-top: 20px;" [bc-format]="'CODE39'" [bc-width]="0.9" [bc-height]="30"
                [bc-value]="page.SourceItreatnum"></ngx-barcode6>
              <div class="con">
                <img style="width:80px;height: auto;padding-top: 0px;" [src]="generateQRCode(page.SourceItreatnum)">
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="p-2 w-100 common-container" *ngIf="isLetterHalf">
  <div class="page page-container letter-half" #reportContainerHalf>
    <div class="content page-container letter-half"
      *ngIf="reportData && this.reportData.Pages && this.reportData.Pages.length > 0">
      <div class="page-content" *ngFor="let page of reportData.Pages;let i=index">
        <div class="page-detail">
          <div style="text-align: center;font-weight: bolder;">{{reportData.Title}}</div>
          <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;" class="pres-report">
            <div style="display: flex;column-gap: 2px;">
              <table style="width: 100%">
                <tr>
                  <td style="width: 41%;">
                    特約醫院、診所 醫事服務機構代號及名稱
                  </td>
                  <td style="width: 59%;">
                    <div>{{page.ClinicNHICode}}</div>
                    <div>{{page.ClinicName}}</div>
                  </td>
                </tr>
              </table>
            </div>
            <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:20%">姓名:{{page.PatientName}}</td>
                <td style="width:45%">身分證字號:{{page.PatientCId}} 生日:{{page.PatientBirthDay}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:29%">科別:{{page.Nhictp}}</td>
                <td style="width:71%">就醫日期:{{page.RegDate}} 卡號:{{page.Isqno}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:100%">傷病名:{{page.SPHcName}} 疾病碼:{{page.SPHcCode}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:70%">免部分負擔代碼及原因:{{page.ApartCode}}</td>
                <td style="width:30%">案類:{{page.CaseType}}</td>
              </tr>
            </table>
            <table style="width: 100%;border-bottom: 0;">
              <tr>
                <td style="width:70%;">
                  醫事檢驗、醫事放射檢查名稱
                </td>
                <td style="width:16%;">
                  總數量
                </td>
                <td style="width:14%;">
                  備註
                </td>
              </tr>
              <tr *ngFor="let hrx of page.HRxes">
                <td style="width:70%;border-bottom: 0;border-top: 0;">
                  {{hrx.SRxCode}} {{hrx.SRxName}}
                </td>
                <td style="width:16%;border-bottom: 0;border-top: 0;">
                  <div style="width:50%;display: inline-block;">{{hrx.HRxTotalDose}}</div>
                </td>
                <td style="width:14%;border-bottom: 0;border-top: 0;">
                  {{hrx.HRxRemark}}
                </td>
              </tr>

            </table>

            <table style="width: 100%;">
              <tr>
                <td style="width: 50%;border-bottom: 0;" rowspan="1">
                  診治醫生代號:{{page.ClinicNHICode}}<br>
                </td>
                <td style="width: 50%;border-bottom: 0;">
                  執行檢驗(查)醫事人員代號:
                </td>
              </tr>

              <tr>
                <td style="width: 50%;border-top: 0;">
                  簽章:{{page.DoctorName}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  檢驗(查)日期: 簽章:
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-bottom: 0;" rowspan="1">
                  特約醫院、診所 核章
                </td>
                <td style="width: 50%;border-bottom: 0;">
                  特約醫事檢驗、特約醫事放射機構 核章
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">
                  醫事務機構地址
                </td>
                <td style="width: 50%;border-top: 0;border-bottom: 0;">
                  {{page.HspidName}}
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;">
                  {{page.ClinicAddress}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  {{page.HspidAddress}}
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;">
                  電話:{{page.ClinicPhone}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  {{page.HspidPhone}}
                </td>
              </tr>
              <tr>
                <td style="width: 50%;border-top: 0;">
                  {{page.ClinicFax}}
                </td>
                <td style="width: 50%;border-top: 0;">
                  {{page.HspidCode}}
                </td>
              </tr>
            </table>
          </div>
          <div>
            <div style="display: flex;">
              <table style="width: 100%;margin-top: 1px;margin-left: 0.3cm; border: none;font-size: 12px;">
                <tr>
                  <td>
                    <div>注意事項: (一)本處方如有塗改，需由原治醫師之蓋章確認，否則無效。</div>

                    <div>(二)本交付檢驗(查)處方為一式兩份，一份交病患供檢驗(查)用，另一份由處方特約醫事機構留存備查。</div>

                    <div>(三)交付檢驗(查)之處方箋自就醫日起一百八十日內有效。</div>
                  </td>
                </tr>
              </table>
            </div>
            <div *ngIf="page.SourceItreatnum && this.isPrintItreatnum" style="display: flex;">
              <table style="width: 100%; margin-top: 0; border: none;">
                <tr>
                  <td>
                    <table style="width: 100%; margin-top: 0px; border: none; font-size: 8pt;">
                      <tr>
                        <td>
                          <div>原處方就醫識別碼:{{page.SourceItreatnum}}</div>
                          <div>原處方就醫時間:{{page.SourceRegDate}}</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ngx-barcode6 [bc-format]="'CODE39'" [bc-width]="0.7" [bc-height]="25"
                            [bc-value]="page.SourceItreatnum"></ngx-barcode6>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <div class="con">
                      <img style="width:80px;height: auto;padding-top: 0px;"
                        [src]="generateQRCode(page.SourceItreatnum)">
                    </div>
                  </td>
                </tr>
              </table>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>
