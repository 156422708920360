<div class="app-v-select v-control position-relative" style="display: flex; height: 25px;padding-top: 1px;">
  <input type="text" class="border-0 bg-transparent" style="width:calc(100% - 1.3rem);" aria-label="Number" matInput
    [(ngModel)]="inputDisplayText" (click)="onInputClick($event)" (input)="onInputInput($event)" (blur)="onInputblur($event)"
    (keydown)="onInputKeyDown($event)" [matAutocomplete]="auto" [disabled]="disabled" #input>
  <!-- 使用自定義模板，如果未傳遞則使用默認模板 -->
  <ng-container>
    <ng-container *ngIf="customIcon; else defaultIcon" [ngTemplateOutlet]="customIcon"></ng-container>
    <ng-template #defaultIcon>
      <div class="h-100 position-absolute cursor-pointer d-inline-flex" style="right: 6px;">
        <div class="mat-select-arrow m-auto bottom-0 top-0"></div>
      </div>
    </ng-template>
  </ng-container>
  <mat-autocomplete [panelWidth]="panelWidth" (optionSelected)="onAutoCompleteOptionSelected($event)"
    #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredOptions$ | async" [value]="item" [disabled]="isMaxItemCountReached">
      {{ getOptionText(item) }}
    </mat-option>
  </mat-autocomplete>
</div>
