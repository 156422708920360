export enum HelperEventTypeEnum {
  NA = 0,

  // --- 以下是各種輸入元件的事件，包含CC、PE、Diag、Order
  Value = 1,
  Keyboard = 2, // 可能不是全部的keyboard event，可能是被service過濾後，需要由target window處理的keyboard event
  Focus = 3,
  ClickForDropdown = 4,
  OrderClass = 5,
  Reset = 9,

  // --- 以下是HelperPad的事件
  HelperSelect = 10, // 可能來自Helper與HeperPad
  HelperTab = 11, // 目前只來自HeperPad

  // --- 以下是歷史紀錄清單的事件
  HistListSelect = 21,

  // --- 其他跨元件溝通事件
  /** 診斷代碼改變 */
  DiagChanged = 101,
}
