import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { LabHRxListPageCollection } from 'src/app/services/api-service/hist/hist-api.model';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { ReportComponentService } from 'src/app/system/report/components/report-component.service';

@Component({
  selector: 'app-lab-hrx-list-report',
  templateUrl: './lab-hrx-list-report.component.html',
  styleUrls: ['./lab-hrx-list-report.component.css']
})
export class LabHrxListReportComponent implements OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  @Input() reportData: LabHRxListPageCollection;

  constructor(private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService: HcrService,
    private reportComponentService: ReportComponentService) { }

  ngOnInit(): void {
  }

  onPrint = async () => {
    if (this.reportData && this.reportData.TotalPage > 0) {
      const reportName = '全民健康保險門診交付檢驗醫令清單';

      let printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(812, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }

    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  getHtml = () => {
    let cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    cssText = cssText.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    cssText = cssText.replace('box-sizing: border-box;', '');

    const style = `
      <style>
        ${cssText}

        @page {
          size: '21.5cm 27.94cm;';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    const html = this.reportContainer.nativeElement.innerHTML;

    const fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm">${html}</body>
    </html>`;

    return fullHtml;
  }

  getExcelHtml = (): { style: string, table: string } => {
    const cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    const style = `
      <style>
        ${cssText}

        .excel-format-text {
          mso-number-format: "@";
        }
      </style>
    `;

    const html = this.reportContainer.nativeElement.innerHTML;

    return {
      style: style,
      table: html
    };
  }


}
