<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;

    }
    .pres-report2 table td,
    table th {
      border-bottom: 5px double black;
      padding-left: 5px;
      padding-right: 5px;
    }
    .th-btm{
      border-right: 0px solid black;
      border-left: 0px solid black;
      border-bottom: 5px double black;
    }
    .subT-btm{
      border-bottom: 5px double blue !important;
    }
    .sum-btm{
      border-bottom: 1px solid black !important; padding-top: 10px;
    }
    .txt-right{
      text-align: right;
    }
  }
</style>
  <div class="a4-print-style-h" #reportContainer style="margin:  0; overflow-y: auto;">
    <div class="pres-report2" style=" height: 100%; width: 100%;">
      <div id="container"  id="container" style="padding: 0px;">
        <!-- <div class="content" id="page-0-content">
          <table style="width: 100%; border: none;">
            <tr>
                <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  同療程月報表(日期)
                </td>
            </tr>
            <tr>
                <td colspan="2" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                列印時間： {{printTime}}
                </td>
                <td colspan="2" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  期間：{{periodYYYYMM}}
                </td>
                <td colspan="2" style="font-size: 14px; text-align: right; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                  頁 {{i + 1}}
                </td>
            </tr>
        </table>
          <div style="-webkit-text-size-adjust: none; font-size: 10.5pt; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
              <table id="table-0-content" style="width: 100%; font-size: 10.5pt; text-align: center; border: none; table-layout: fixed; margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;  border-collapse: collapse;">
                                  <tr  style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <th style="width: 65px;line-height: 12px;margin: 0px 0px 0px 0px; padding: 6px 2px 6px 2px;border: 1px solid black;">
                      病歷號
                    </th>
                    <th style="width: 85px;line-height: 12px;text-align:left;border: 1px solid black;">
                      姓名
                    </th>
                      <th style="line-height: 12px;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;border: 1px solid black;" *ngFor="let i of indexArray">{{ i+1 }}</th>
                  </tr>
                <tbody *ngFor="let treat of listItems.slice(i*40, (i+1)*40)" style=" margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                  <tr style=" margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
                    <td style="line-height: 12px;margin: 0px 0px 0px 0px;  padding: 6px 2px 6px 2px;border: 1px solid black;">{{ patientNoFactory(treat.PatientNo)}}</td>
                    <td style="line-height: 12px;text-align:left;border: 1px solid black;">{{treat.PatientName}}</td>
                    <td style="line-height: 12px;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;border: 1px solid black;" *ngFor="let i of indexArray">{{timesFactory(treat.CusNoPerMonth[i][1])}}</td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div> -->
      </div>
    </div>
  </div>
