import { Injectable } from "@angular/core";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { Position } from "src/app/system/position/models/position";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { PageData2 } from "src/app/shared/models/page-data";
import { LabFormList, LabFormTable } from "src/app/labs/models/labForm-list";
import { LabForm } from "src/app/labs/models/labForm";
import { DateHelper } from "src/app/shared/helpers/date-helper";
import { BatchSetImportSourceDto, LabItem, LabItemResultSource, LabItemSource } from "src/app/labs/lab-item/models/lab-item";
import { SetSaveDTO } from "src/app/labs/lab-item-set/models/set-save-dto";

export interface LabEditOption {
  labQueryTypeEnum: ValueTextPairNumberValue[],
  labStatusEnum: ValueTextPairNumberValue[],
  testResultCodes: ValueTextPair[],
  testResultCodesForText: ValueTextPair[]
}
export interface LabItemEditOption {
  orderTypeLab: ValueTextPairNumberValue[];
  dataTypes: ValueTextPairNumberValue[];
  labClasses: ValueTextPairNumberValue[];
}
export interface LabQuery {
  operationType: number,
  patientNo: string,
  labFormNo: string,
  timeStart: Date,
  timeEnd: Date,
  statusType: number,
  pageSkip: number,
  pageSize: number
}

export class LabImportLog {
  Id: number;
  ClinicId: number;
  ClinicCode: string;
  /** 生日 */
  PatientBirthday: Date;
  /** 身分證 */
  PatientCId: string;
  /**病歷號 */
  PatientNo: string;
  /**患者名 */
  PatientName: string;
  /**檢驗單號 */
  No: string;
  /**就診日期 */
  RegDate: Date;
  /**檢驗日期 */
  ExamDate: Date;
  /**收單時間 */
  RecieveDate: Date;
  /**報告日期時間 */
  ReportDateTime: Date;
  /**檢驗機構健保碼*(不一定正確) */
  LabId: string;
  /** 檢驗機構名稱 */
  LabName: string;
  /**最後更新時間 */
  LastUpdateTime: Date;
  Imported: boolean;
  Results: LabImportLogDetail[]
}
export class LabImportLogDetail {
  MasterId: string;
  /** 健保代碼 */
  NHICode: string;
  /**檢驗名稱 */
  Name: string;
  /**低限 */
  Low: string;
  /** 高限 */
  High: string;
  /**檢驗值 */
  Value: string;
  /**單位 */
  Unit: string;
  /**判讀結果 N:Normal, A:Abnormal, H:High, L:Low ...? */
  Result: string;
  /**檢驗值類型 N:Number, C:Charectors? 文字? */
  ValueType: string;
  /**排序 */
  SN: number;
  /**建議範圍說明 */
  NormalValue: string;
  /**檢驗項目代碼 */
  ItemCode: string;

}

export interface LabSetQuery {
  ItemCode: string,
  ItemName: string,
  PageSize: number;
  PageNumber: number
}
export interface LabItemListDto {
  Id: number;
  ItemCode: string;
  ItemName: string;
  LabClass: number;
  LOINC: string;
  Sort: number;
  CreateTime: Date;
}

export interface LabItemSimpleDto {
  Id: number;
  ItemCode: string;
  ItemName: string;
  NhiCode: string;
  TypeId: number;
  DataType: number;
  DataValue: string;
}

export class LabItemSimpleDtoWithCheck implements LabItemSimpleDto {
  Id: number;
  ItemCode: string;
  ItemName: string;
  NhiCode: string;
  TypeId: number;
  DataType: number;
  DataValue: string;
  check: boolean = false;
}

export interface LabSetComp {
  Code: string;
  ItemId: number;
  Name: string;
}
@Injectable({ providedIn: 'root' })
export class LabApi {
  public apiService: WebApiService;
  public setApiService: WebApiService;

  public itemApiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = this.apiClient.createHisService('labs/lab')
    this.setApiService = this.apiClient.createHisService('labs/labItemSet')
    this.itemApiService = this.apiClient.createHisService('labs/labItem')
    this.apiService.enableLoader = false;
  }
  async get(id: number): Promise<LabForm> {
    return await this.apiService.get('get/' + id).toPromise<LabForm>();
  }
  async update(model: LabForm): Promise<boolean> {
    return await this.apiService.put('update', model).toPromise<boolean>();
  }
  async getEditOption(): Promise<LabEditOption> {
    return await this.apiService.get('getEditOptions').toPromise<LabEditOption>();
  }

  async getAll(query: LabQuery): Promise<PageData2<LabFormList>> {
    var q = Object.assign({}, query) as any;
    if (query.timeStart) {
      q.timeStart = query.timeStart.toLocaleDateString();
    }
    if (query.timeEnd) {
      q.timeEnd = query.timeEnd.toLocaleDateString();
    }
    return await this.apiService.get('getAll', q).toPromise<PageData2<LabFormList>>();
  }
  async getTable(query: LabQuery): Promise<PageData2<LabFormTable>> {
    var q = Object.assign({}, query) as any;
    if (query.timeStart) {
      q.timeStart = query.timeStart.toLocaleDateString();
    }
    if (query.timeEnd) {
      q.timeEnd = query.timeEnd.toLocaleDateString();
    }
    return await this.apiService.get('GetTable', q).toPromise<PageData2<LabFormTable>>();
  }
  async UpdateToCollectStatus(id: number) {
    return await this.apiService.put('UpdateToCollectStatus?id=' + id, null).toPromise()

  }

    async ImportLab(nhiCode:string):Promise<{ImportCount:number,LabForms:number[]}>{
        return await this.apiService.get('ImportLab?nhiCode='+nhiCode).toPromise<{ImportCount:number,LabForms:number[]}>();
    }

    async ImportLabFrom(companyCode:string,nhiCode:string,st:Date,ed:Date):Promise<{ImportCount:number,LabForms:number[]}>{
      return await this.apiService.get(`ImportLabFrom?companyCode=${companyCode}&nhiCode=${nhiCode}&st=${st.toLocaleDateString()}&ed=${ed.toLocaleDateString()}`).toPromise<{ImportCount:number,LabForms:number[]}>();
    }
    async GetImportLog(regDate:Date):Promise<LabImportLog[]>{
        var dt  =DateHelper.formatDate(regDate);
        return await this.apiService.get('GetImportLog?RegDate=' + dt ).toPromise<LabImportLog[]>();
    }
    async ImportLabLog(formId:number,logId:number):Promise<boolean>{
        return await this.apiService.post('ImportLabLog',{
            Source:formId,
            Target:logId
        }).toPromise<boolean>();
    }
    /** 產生今天檢驗報告(假)到平台 */
    async CreateFakeResult():Promise<boolean>{
        return await this.apiService.post('CreateFakeResult',null).toPromise<boolean>();
    }

    //#region LabSet

    async getPageSet(query:LabSetQuery):Promise<PageData2<LabItemListDto>>{
        return await this.setApiService.post('getPageSet', query).toPromise<PageData2<LabItemListDto>>();
    }
    async getPageNotSet(query:LabSetQuery):Promise<PageData2<LabItemListDto>>{
        return await this.setApiService.post('GetPageNotSet', query).toPromise<PageData2<LabItemListDto>>();
    }
    async getSetComponents(id:number):Promise<LabSetComp[]>{
        return await this.setApiService.get('GetSetComponents', {setId : id}).toPromise<LabSetComp[]>()
    }
    async saveModify(model: SetSaveDTO){
        return await this.setApiService.post('SaveModify', model).toPromise()
    }
    //#endregion

    //#region LabItem
    async getItemEditOption():Promise<LabItemEditOption>{
        return await this.itemApiService.get('getEditOptions').toPromise<LabItemEditOption>();
    }
    async createtLabSet(model:{ItemCode:string,ItemName:string,LabClass:number}):Promise<LabItem>{
        return await this.itemApiService.post('CreateLabSet', model).toPromise<LabItem>()
    }
    private labItemSourceCache:Map<string,LabItemSource[]> = new Map()
    async getLabItemSource(hinno:string):Promise<LabItemSource[]>{
        if(this.labItemSourceCache.has(hinno)){
            return this.labItemSourceCache.get(hinno)
        }else{
            var data = await this.itemApiService.get('GetLabItemSource?hinno='+hinno).toPromise<LabItemSource[]>();

            this.labItemSourceCache.set(hinno,data);
            return data;
        }
    }


    async batchSetImportSourcePreview(hinno:string):Promise<BatchSetImportSourceDto[]>{
      var data = await this.itemApiService.get('batchSetImportSourcePreview?hinno='+hinno).toPromise<BatchSetImportSourceDto[]>();
      return data;
    }

    async batchSetImportSourceSave(data:BatchSetImportSourceDto[]):Promise<number>{
      var ret = await this.itemApiService.post('batchSetImportSourceSave',data).toPromise<number>();
      return ret;
    }

    //#endregion
}
