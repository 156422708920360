/**
 * 一個Helper函數，用於讓 Promise 和超時時間競賽。
 * 如果 Promise 沒有在指定的超時時間內 resolve 或 reject，則返回的 Promise 會被拒絕並拋出超時錯誤。
 *
 * @template T - Promise resolve 的值的類型。
 * @param {Promise<T>} promise - 要與超時時間競賽的 Promise。
 * @param {number} timeoutMs - 超時時間（毫秒）。
 * @returns {Promise<T>} - 一個 Promise，會 resolve 為輸入 Promise 的值或拒絕為超時錯誤。
 */

export const TimeRaceHelper = async <T>(promise: Promise<T>, timeoutMs: number): Promise<T> => {
  return new Promise<T>((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'));
    }, timeoutMs);

    promise.then(
      (result) => {
        clearTimeout(timeoutId);
        resolve(result);
      },
      (error) => {
        clearTimeout(timeoutId);
        reject(error);
      }
    );
  });
};
