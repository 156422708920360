import { Injectable } from "@angular/core";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { ParamGroupEnum, ParamMap, ParamType, ParamGroupEnumKeyMap } from "../../data-service/system-param-cache-define";
import { ParameterProfile, ParameterProfileDefine } from "src/app/system/parameter-profile/models/parameter-profile";
import { PageData2 } from "src/app/shared/models/page-data";
import { UserCache } from "../../user-cache";
import { FileService } from "../file/file.service";

@Injectable({ providedIn: 'root' })
export class ParameterApi {

  apiService: WebApiService;

  /**
 * 建構子，初始化 WebApiService。
 * @param apiClient - WebApiClient 的實例。
 */
  constructor(private apiClient: WebApiClient,private file:FileService) {
    this.apiService = apiClient.createHisService('system/ParameterProfile');
  }

  /**
 * 更新指定參數組的參數。
 * @template K - ParamMap 中的鍵類型。
 * @param group - 要更新的參數組。
 * @param params - 要更新的參數。
 * @returns 返回一個 Promise，表示更新操作的完成。
 */
  public async UpdateParamGroup<K extends keyof ParamMap>(
    group: ParamGroupEnum | K,
    params: ParamMap[K]
  ): Promise<void> {
    var groupKey = '';
    if(typeof group === 'number'){
      groupKey = ParamGroupEnumKeyMap[group];
    }else{
      groupKey = group;
    }
    return this.apiService.put(`Update/${groupKey}`, params).toPromise();
  }

  /**
  * 獲取指定類型的參數。
  * @template T - ParamType 中的鍵類型。
  * @param type - 要獲取的參數類型。
  * @returns 返回一個 Promise，表示獲取操作的完成，並包含對應類型的參數。
  */
  async GetParams<T extends ParamType>(type: T): Promise<ParamMap[T]> {
    return await this.Load<ParamMap[T]>(`Get${type}`);
  }

  /**
 * 載入指定 API 名稱的數據。
 * @template T - 返回數據的類型。
 * @param apiName - 要調用的 API 名稱。
 * @returns 返回一個 Promise，表示載入操作的完成，並包含類型為 T 的數據。
 */
  private async Load<T>(apiName: string): Promise<T> {
    var ret = await this.apiService.get(apiName).toPromise<T>();
    return ret;
  }
  /** 取得參數描述 */
  public async GetDesc(group: ParamType): Promise<ParameterProfileDefine[]> {
    var ret = await this.apiService.get('GetDesc?group='+group).toPromise<ParameterProfileDefine[]>();
    return ret;
  }

  /** 取得參數 */
  public async GetAll(query: ParamterQuery): Promise<PageData2<ParamterList>> {
    return this.apiService.get('getAll', query).toPromise<PageData2<ParamterList>>();
    
  }
  /** 匯出參數 */
  public async Export(query: ParamterQuery,fileName:string): Promise<void> {
    var resp = await this.apiService.getFile('export', query).toPromise();
    this.file.downloadAs(resp,fileName);
    // let blob = new Blob([resp], { type: 'text/plan' });
    // //建a    
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download =  fileName;
    // //點a    
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
  }
  
  public async Import(data:ParamterImport[]){
    return this.apiService.post('import',data).toPromise();
  }

}
export class ParamterQuery{
  clinicId:number;
  group:number;
  key: string;
  hideDefault:boolean;
  pageSize: number;
  pageSkipNo: number;
};

export class ParamterList{
  Id:number;
  Group:number;
  Key:string;
  Name:string;
  Value:string;
  DefaultValue:string;
  Description:string;
  Type:number;
  PossibleValue:string;
  GroupName:string;
};

export class ParamterImport{
  Group:number;
  Key:string;
  Name:string;
  Value:string;
  Type:number;
  GroupName:string;
};
