import { query } from '@angular/animations';
import { Injectable } from "@angular/core";
import { CustomResult } from "src/app/shared/models/custom-result";
import { PageData } from "src/app/shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { MedLackArea } from "./medlackarea";

export class GetMedLackAreaQuery {
    pageSize: number;
    pageSkip: number;
    /** 查詢類別  醫療資源缺乏=""、肝炎治療="BC"，山地離島="EC" */
    queryType: string;
    /** 查詢業務組別 */
    hno_type: string;
    /** 查詢地區名稱 */
    area: string;
    /** 查詢地區代碼 */
    zipcode: string;
}

export class GetSMedLackAreaQuery {
    pageSize: number;
    pageSkip: number;
    /** 查詢類別  醫療資源缺乏=""、肝炎治療="BC"，山地離島="EC" */
    queryType: string;
    /** 查詢業務組別 */
    hno_type: string;
    /** 查詢地區名稱 */
    area: string;
    /** 查詢地區代碼 */
    zipcode: string;
}

@Injectable({ providedIn: 'root' })
export class MedLackAreaApi {
    apiService: WebApiService;

    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('Opd/MedLackArea');
        this.apiService.enableLoader = false;
    }

    async Get(): Promise<MedLackArea> {
        var ret = await this.apiService.get(`Get`).toPromise<MedLackArea>();
        return ret;
    }

    /**
     * 取得所有相關醫缺或山地離院內檔資料 (依照查詢類別)
     * @param {number} type 查詢類別  醫療資源缺乏=""、肝炎治療="BC"，山地離島="EC"
     * @return {*}  {Promise<MedLackArea[]>}
     * @memberof MedLackAreaApi
     */
    async GetAll(dataType = ""): Promise<MedLackArea[]> {
        var ret = await this.apiService.get(`GetAll`,
        {
            dataType: dataType,
        }).toPromise();
        return ret;
    }

    async GetSMedLackAreaAll(query: GetSMedLackAreaQuery): Promise<PageData> {
        return await this.apiService.get(`GetSMedLackAreaAll`, query).toPromise<PageData>();
    }

    async GetMedLackAreaAll(query: GetMedLackAreaQuery): Promise<PageData> {
        return await this.apiService.get(`GetMedLackAreaAll`, query).toPromise<PageData>();
    }

    async Update(id: number, newChono: number): Promise<CustomResult> {
        return await this.apiService.get(`ResortChoNo`,
            {
                id: id,
                newChono: newChono
            }).toPromise();
    }
    /**
     * 以地址的城市區域取得醫缺或山地離郵遞區號
     * @param city
     * @param area
     * @param queryType 查詢類別  醫療資源缺乏=""、肝炎治療="BC"，山地離島="EC"
     * @returns
     */
    async GetZipCodeByCityArea(city: string, area: string, isNeedRemote = false, queryType = ""): Promise<string> {
        if (!isNeedRemote) return '';
        else return await this.apiService.get(`GetZipCodeByCityArea`,
            {
                city: city,
                area: area,
                queryType: queryType,
            }).toPromise<string>();
    }
}
