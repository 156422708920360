import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { UrlHelper } from 'src/app/shared/helpers/url-helper';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiService } from 'src/app/services/web-api.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { LoginUser } from 'src/app/permission/models/login-user';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router, private webApiFactory: WebApiClient,
    private authenticationService: AuthenticationService
  ) { }

  async canActivate(routeSnapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // 檢核user cache的companyCode與path的companyCode是否一致
    let companyCode = '';
    // 產出所有Route
    // 要從登入畫面登入才能取到全部的Route
    // this.getAppRoutes();

    // 取得url裡的companyCode
    if (routeSnapshot.pathFromRoot.length > 1) {
      companyCode = UrlHelper.getCompanyCodeFromRouteSnapshot(routeSnapshot);
    }

    var ssoToken = routeSnapshot.queryParams['s'];

    if (!this.authenticationService.isLoginToCompany(companyCode)) {
      //#region  若還未登入過
      // 看是否有sso參數
      if (ssoToken) {
        return await this.ssoTokenLogin(ssoToken,companyCode,state.url);
      } else {
        this.router.navigate(['/' + companyCode + '/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }
      //#endregion
    } else {
      //#region  若已經登入過
      // 若已經登入過，還是要看是否有sso參數，若有還是需要重新取得loginUser資訊，避免從A系統切換User登入，B系統沒有跟著一起切換人
      if (ssoToken) {
       return await this.ssoTokenLogin(ssoToken,companyCode,state.url);
      } else {
        return true;
      }
      //#endregion
    }

    // 檢核是否有該路由的權限
    // 先檢查user cache內有沒有權限資料(檢查loginUser的Routes)，
    // (1)有的話，進行檢核
    // (2)沒有的話，以token向server端取得資料，存入user cache，然後檢核
    // 沒有的原因可能是網頁reload，service資料清空
    // 檢核失敗就return false
    // ps增加一個BaseConfig.env.isValidateRoute的參數決定是否檢核

  }

   /** 使用ssoToken方式登入 */
  async ssoTokenLogin(ssoToken, companyCode, returnUrl) {
    var ssoService = this.webApiFactory.createSSOService();
    ssoService.enableLoader = false;
    var loginUser = await ssoService.get('GetAuth?ssoToken=' + ssoToken).toPromise<LoginUser>();

    if (loginUser) {
      UserCache.setLoginUser(loginUser);
      return true;
    } else {
      this.router.navigate(['/' + companyCode + '/login'], { queryParams: { returnUrl: returnUrl } });
      return false;
    }
  }

  private getAppRoutes() {
    const appRouting = this.router.config;
    let count = 0;
    let routeString = '';
    appRouting.forEach(app => {
      if (app.children) {
        app.children.forEach((prefix: any) => {
          if (prefix) {
            if (prefix._loadedConfig) {
              prefix._loadedConfig.routes.forEach(midfix => {
                if (midfix) {
                  if (midfix._loadedConfig) {
                    midfix._loadedConfig.routes.forEach(postfix => {
                      if (postfix) {
                        routeString += app.path + '/' + prefix.path + '/' + midfix.path + '/' + postfix.path + '\r\n';
                        count++;
                      } else {
                        routeString += app.path + '/' + prefix.path + '/' + midfix.path + '\r\n';
                        count++;
                      }
                    });
                  } else {
                    routeString += app.path + '/' + prefix.path + '/' + midfix.path + '\r\n';
                    count++;
                  }
                } else {
                  routeString += app.path + '/' + prefix.path + '\r\n';
                  count++;
                }
              });
            } else {
              routeString += app.path + '/' + prefix.path + '\r\n';
              count++;
            }
          } else {
            routeString += app.path + '\r\n';
            count++;
          }
        });
      } else {
        routeString += app.path + '\r\n';
        count++;
      }
    });
    console.log(routeString);
    console.log('count = ' + count);
  }
}
