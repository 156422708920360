import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { HostListener, ViewChild, ElementRef } from '@angular/core';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperEvent } from '../models/helper-event';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { HistService } from '../services/hist.service';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { DxOpt, HistEditOption, RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { RxClass } from '../models/rx-class';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

enum TemplateTypeEnum {
  NA = 0,
  Default = 1,
  Diag = 2,
  OrderCode = 3
}

@Component({
  selector: 'app-hist-helper',
  templateUrl: './helper.component.html',
  styleUrls: ['./helper.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelperComponent implements OnInit, OnDestroy {
  constructor(
    private helperService: HistHelperService,
    private histApi: HistApi,
    private histService: HistService,
    private cd: ChangeDetectorRef,
    private clinicDataService: ClinicDataService,
  ) {
  }

  //#region subscribe --------------------
  private unsubscribe: Subject<void> = new Subject();
  //#endregion subscribe --------------------

  //#region 參數 --------------------


    get editOptions(): HistEditOption{
      return this.histService.EditOptions;
    }

  dispensingOption: SystemCode[] = [
    new SystemCode('Y', 'Y'),
    new SystemCode('N', 'N'),
  ];

  debounceTime = 50;
  helperEvent: HelperEvent;
  listItems: any[] = [];
  displayStopDrugsFromFloat: boolean = true;

  get source(): HelperSourceEnum {
    if (!this.helperEvent) {
      return HelperSourceEnum.NA;
    } else {
      return this.helperEvent.source;
    }
  }
  get rowCount():number{
    return (this.source === HelperSourceEnum.Diag)?9:10;
  }
  get sourceId(): string {
    if (!this.helperEvent) {
      return '';
    } else {
      return this.helperEvent.sourceId;
    }
  }
  get templateTypeEnum() {
    return TemplateTypeEnum;
  }
  get templateType() {
    if(this.isLoading){
      return this.templateTypeEnum.NA;
    }
    if (this.source === HelperSourceEnum.Diag) {
      return TemplateTypeEnum.Diag;
    } else if (this.source === HelperSourceEnum.Order ||
      this.source === HelperSourceEnum.ChiOrder) {
      return TemplateTypeEnum.OrderCode;
    } else {
      return TemplateTypeEnum.Default;
    }
  }
  //#endregion 參數 --------------------

  //#region 參數 window --------------------
  @ViewChild('itemWrapper') private itemWrapper: ElementRef;
  @ViewChild('itemWrapperDiag') private itemWrapperDiag: ElementRef;
  @ViewChild('itemWrapperRx') private itemWrapperRx: ElementRef;

  eachRowHeight = 26;
  winTop = 0;
  winLeft = 0;
  winWidth = 450;
  winMaxHeight = 265; // 預設高度，也是最大高度
  winHeight = this.winMaxHeight;
  wrapperHeight = this.winMaxHeight - 8; // 8 = window (margin 3px + border 1px) *2
  focusRowIndex = 0; // 目前游標或按鍵指到的項目
  empty= ''
  get wrapperHeightStyle() {
    return this.wrapperHeight + 'px';
  }
  get wrapperHeightStyleNoHeader(){
    return (this.wrapperHeight - 26) + 'px';
  }

  _isOpened = false;
  get isOpened() {
    if (this._isOpened) {
      // 改為在收到open指令時就先調整好位置
      // this.setWindowPosition();
      return true;
    } else {
      return false;
    }
  }
  //#endregion 參數 window --------------------

  async ngOnInit() {
    // 移除tabIndex是因為不希望這個window取得focus
    this.subscribeUiEvent_Helper();

    let hst001 = await this.clinicDataService.getParam("HST001");
    this.displayStopDrugsFromFloat = hst001.DisplayStopDrugsFromFloat;
  }

  //#region event --------------------
  private subscribeUiEvent_Helper() {
    this.helperService.fromSource$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(
      (evt: HelperEvent) => {
        if (evt.type === HelperEventTypeEnum.NA) {
          return;
        }
        this.setHelperEvent(evt);
        if (evt.type === HelperEventTypeEnum.Reset) {
          this.closeWindow();
        } else if (evt.type === HelperEventTypeEnum.Keyboard) {
          this.handleKeyboardEvent(evt.key);
        } else if (evt.type === HelperEventTypeEnum.Value) {
          this.handleValueEvent(evt);
        } else if (evt.type === HelperEventTypeEnum.ClickForDropdown) {
          this.handleClickForDropdownEvent(evt);
        }
      }
    );
  }
  private setHelperEvent(evt: HelperEvent) {
    if (!evt) {
      return;
    }

    // 這幾個類型是新開始，所以設定資料，並把游標移到0
    // dropdown: Click
    // helper: Value
    // Focus, Reset都是清除
    if (evt.type === HelperEventTypeEnum.Focus ||
      evt.type === HelperEventTypeEnum.Reset) {
      this.helperEvent = null;
      this.focusRowIndex = 0;
    } else if (evt.type === HelperEventTypeEnum.ClickForDropdown ||
      evt.type === HelperEventTypeEnum.Value) {
      this.helperEvent = evt;
      this.focusRowIndex = 0;
    }
  }
  private handleClickForDropdownEvent(evt: HelperEvent) {
    this.fetchData(evt);
  }
  private handleValueEvent(evt: HelperEvent) {
    this.fetchData(evt);
  }
  isLoading = false;
  private async fetchData(evt: HelperEvent) {
    // 先開窗
    this.isLoading = true;
    this.checkForOpenWindow();
    // 載入資料
    var data = await this.getCodes(evt);
    //醫令不顯示停用，參數判斷是否啟用此判斷。
    if (!this.displayStopDrugsFromFloat && evt.source === HelperSourceEnum.Order) {
      this.listItems = data.filter(x => !x.IsDrugStatus);
    } else {
      this.listItems = data;
    }
    // 設定內容
    this.isLoading = false;
    this.checkForOpenWindow();
  }

  private handleKeyboardEvent(key: string) {

    if (key === 'PageUp') {
      if (this.focusRowIndex > 0) {
        this.focusRowIndex -= this.focusRowIndex>this.rowCount?this.rowCount:this.focusRowIndex;
        this.cd.detectChanges();
        this.scrollWin(this.focusRowIndex,'PageUp');
      }
    }else if (key === 'PageDown') {
      if (this.focusRowIndex < this.listItems.length - 1) {
        this.focusRowIndex = Math.min(this.focusRowIndex+this.rowCount,this.listItems.length - 1);
        this.cd.detectChanges();
        this.scrollWin(this.focusRowIndex,'PageDown');
      }

    }else if (key === 'ArrowUp') {
      if (this.focusRowIndex > 0) {
        this.focusRowIndex--;
        this.cd.detectChanges();
        this.scrollWin(this.focusRowIndex,'Up');
      }
    } else if (key === 'ArrowDown') {
      if (this.focusRowIndex < this.listItems.length - 1) {
        this.focusRowIndex++;
        this.cd.detectChanges();
        this.scrollWin(this.focusRowIndex,'Down');
      }
    } else if (key === 'Enter') {
      this.returnSelected(this.listItems[this.focusRowIndex]);
      this.cd.markForCheck();
      this.closeWindow();
    }
  }
  //#endregion
  currentInput:string;
  //#region get data --------------------
  private async getCodes(evt: HelperEvent):  Promise<any[]> {
    console.log('getCodes');
    if (!evt) {
      return [];
    }
    if (evt.type === HelperEventTypeEnum.Value && !evt.inputValue) {
      return [];
    }
    const src: HelperSourceEnum = evt.source;
    const inputValue: string = evt.inputValue;
    this.currentInput = inputValue.toLowerCase();
    if (src === HelperSourceEnum.CC) {
      return await this.histService.getCCOptions(inputValue,true);
    } else if (src === HelperSourceEnum.PE) {
      return await this.histService.getPEOptions(inputValue,true);
    } else if (src === HelperSourceEnum.Diag) {
      return await this.histService.getDiagList(inputValue,true);
    } else if (src === HelperSourceEnum.ChiAsk) {
      return await this.histApi.getChiAskOptions(inputValue);
    } else if (src === HelperSourceEnum.ChiLook) {
      return await this.histApi.getChiLookOptions(inputValue);
    } else if (src === HelperSourceEnum.ChiPulse) {
      return await this.histApi.getChiPulseOptions(inputValue);
    } else if (src === HelperSourceEnum.ChiBianZheng) {
      return await this.histApi.getChiBianZhengOptions(inputValue);
    } else if (src === HelperSourceEnum.ChiTreatRule) {
      return await this.histApi.getChiTreatRuleOptions(inputValue);
    } else if (src === HelperSourceEnum.Order) {
      // var types: number[];
      // if (orderClass === RxClass.Drug) {
      //   types = this.editOptions.histParams.OrderClassDrug;
      // } else if (orderClass === RxClass.Inject) {
      //   types = this.editOptions.histParams.OrderClassInj;
      // } else if (orderClass === RxClass.Lab) {
      //   types = this.editOptions.histParams.OrderClassLab;
      // } else if (orderClass === RxClass.XRay) {
      //   types = this.editOptions.histParams.OrderClassXRay;
      // }
      return await this.histService.getOrderList(inputValue, evt.rxType, true);

    // } else if (src === HelperSourceEnum.ChiOrder) {
    //   var types: number[];
    //   if (orderClass === RxClass.CnSp1) {
    //     types = this.editOptions.histParams.OrderClassCnSp1;
    //   } else if (orderClass === RxClass.CnSp2) {
    //     types =  this.editOptions.histParams.OrderClassCnSp2;
    //   } else if (orderClass === RxClass.CnSp3) {
    //     types =  this.editOptions.histParams.OrderClassCnSp3;
    //   } else if (orderClass === RxClass.CnMed) {
    //     types =  this.editOptions.histParams.OrderClassCnMed;
    //   } else if (orderClass === RxClass.Acu) {
    //     types =  this.editOptions.histParams.OrderClassAcu;
    //   } else if (orderClass === RxClass.Tuina) {
    //     types =  this.editOptions.histParams.OrderClassTuina;
    //   } else if (orderClass === RxClass.CnTrt) {
    //     types =  this.editOptions.histParams.OrderClassCnTrt;
    //   }
    //   if(types){
    //     return await this.histService.getOrderList(inputValue, types,true);
    //   }
    } else if (src === HelperSourceEnum.Freq) {
      return await this.getFreqOptions().toPromise();
    } else if (src === HelperSourceEnum.Way) {
      if (evt.subSourceType === HelperSourceEnum.WayPart) {
        return await this.getPartOptions().toPromise();
      } else {
        return await this.getWayOptions().toPromise();
      }
    } else if (src === HelperSourceEnum.Dispensing) {
      return await this.getDispensingOptions().toPromise();
    } else if (src === HelperSourceEnum.ChiProcess) {
      return await this.getChiProcessOptions().toPromise();
    } else {
      return [];
    }

  }
  private getFreqOptions(): Observable<SystemCode[]> {
    if (this.editOptions) {
      const codes: SystemCode[] = [];
      this.editOptions.dosage.forEach(ds => {
        const p = new SystemCode(ds.Code, ds.Code);
        codes.push(p);
      });
      return of(codes);
    }
    return of([]);
  }
  private getWayOptions(): Observable<SystemCode[]> {
    if (this.editOptions) {
      return of(this.editOptions.way);
    }
    return of([]);
  }
  private getPartOptions(): Observable<SystemCode[]> {
    if (this.editOptions) {
      return of(this.editOptions.part);
    }
    return of([]);
  }
  private getDispensingOptions(): Observable<SystemCode[]> {
    if (this.dispensingOption) {
      return of(this.dispensingOption);
    }
    return of([]);
  }
  private getChiProcessOptions(): Observable<SystemCode[]> {
    if (this.editOptions) {
      return of(this.editOptions.chiProcess);
    }
    return of([]);
  }
  //#endregion --------------------

  //#region Escape keyboard event --------------------
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    this.callKeyFunction(event.key);
  }
  callKeyFunction(key: string) {
    switch (key) {
      case 'Escape':
        this.closeWindow();
        break;
      default:
        break;
    }
  }
  //#endregion

  //#region window control --------------------
  scrollWin(focusRowNo: number,dir:'Up'|'Down'|'PageUp'|'PageDown') {
    try {
      var wrapper = this.source === HelperSourceEnum.Diag?this.itemWrapperDiag:
                    this.source === HelperSourceEnum.Order?this.itemWrapperRx:this.itemWrapper;
      var currentScrollTop = wrapper.nativeElement.scrollTop;
      // End在同一頁最後一筆 所以-1
      var currentViewableEnd = wrapper.nativeElement.scrollTop + (this.rowCount -1 )*this.eachRowHeight;
      var focusItemPos = (focusRowNo) * this.eachRowHeight
      console.log(focusRowNo,currentScrollTop,currentViewableEnd,focusItemPos)
      // 項目在可視範圍中不捲動
      if(focusItemPos>= currentScrollTop && focusItemPos <= currentViewableEnd){
        return;
      }

        // 預設是10行高度
        // this.phraseWrapper.nativeElement.scrollTop = this.phraseWrapper.nativeElement.scrollHeight;
        if(dir=='PageUp'){
          wrapper.nativeElement.scrollTop = currentScrollTop - this.rowCount * this.eachRowHeight;
        }else if(dir=='PageDown'){
          wrapper.nativeElement.scrollTop = currentScrollTop + this.rowCount * this.eachRowHeight;
        }else if(dir=='Down'){
          // 同一頁第一筆 所以+1
          wrapper.nativeElement.scrollTop = (focusRowNo - this.rowCount+1) * this.eachRowHeight;
        }else{
          wrapper.nativeElement.scrollTop = (focusRowNo) * this.eachRowHeight;
        }


    } catch {

    }
  }
  setWindowPosition() {
    if (!this.sourceId) {
      return;
    }

    // 在open前調整視窗位置與寬高
    // 調整寬度
    if (this.source === HelperSourceEnum.Freq || this.source === HelperSourceEnum.Way) {
      this.winWidth = 80;
    } else if (this.source === HelperSourceEnum.Dispensing) {
      this.winWidth = 50;
    } else if (this.source === HelperSourceEnum.ChiProcess) {
      this.winWidth = 80;
    } else if (this.source === HelperSourceEnum.Diag) {
      this.winWidth = 820;
    } else if (this.source === HelperSourceEnum.Order || this.source === HelperSourceEnum.ChiOrder) {
      this.winWidth = 550;
    } else {
      this.winWidth = 475;
    }

    // 調整位置
    const leftPanel = document.getElementById(this.sourceId);
    const rect = leftPanel.getBoundingClientRect();
    if (leftPanel) {
      if (this.source == HelperSourceEnum.Order) {
        this.winLeft = rect.width + rect.left + 16;
      } else {
        this.winLeft = rect.width + rect.left - 13; //-14 消除Mainlayout的left padding +1只是要保持一點距離，免得壓到了元件的邊框
      }

      // if (this.source == HelperSourceEnum.Order) {
        this.winTop = rect.top + 26;
      // } else {
      //   this.winTop = rect.top - 35;   // 消除Mainlayout的top padding
      // }
    } else {
      // console.log('this.anchorElementId:' + this.anchorElementId);
      this.winLeft = document.body.clientWidth / 2;
      this.winTop = 0; // 100
    }

    if (this.winTop < 0) { this.winTop = 0; }
    if (this.winLeft < 0) { this.winLeft = 0; }
    // 若winLeft設定後，右邊剩下的寬度小於helper視窗寬度，為了避免視窗被遮住，需調整winLeft
    const minWinWidth = this.winWidth + 10; // 包含10px邊界
    if (this.winLeft > document.body.clientWidth - minWinWidth) {
      this.winLeft = document.body.clientWidth - minWinWidth;
    }
    let titleRowNumber = (this.source === HelperSourceEnum.Diag || this.source === HelperSourceEnum.Order || this.source === HelperSourceEnum.ChiOrder) ?1 : 0;
    // 調整高度，不希望多出太多空白
    const rowTotalHeight = (this.listItems?.length??10 + titleRowNumber) * this.eachRowHeight;
    if (rowTotalHeight >= this.winMaxHeight) {
      this.winHeight = this.winMaxHeight;
    } else {
      this.winHeight = rowTotalHeight + 8;
    }
    // 如果是醫令高度+26
    if (this.templateType === this.templateTypeEnum.OrderCode) this.winHeight += 26;

    this.wrapperHeight = this.winHeight - 8;

    // 調整方向，預設是向下，若下方不足或較少，改為向上
    if (document.body.clientHeight - this.winTop < this.winHeight) {
      this.winTop = this.winTop - this.winHeight + rect.height - 58;  // -30
    }
  }

  rowClass(i: number): string {
    if (i === this.focusRowIndex) {
      return 'item item-focus';
    } else {
      return 'item';
    }
  }
  returnSelected(item: any) {
    const backEvent: HelperEvent = Object.assign(new HelperEvent(), this.helperEvent);
    backEvent.type = HelperEventTypeEnum.HelperSelect;
    backEvent.selectedItem = item;
    this.helperService.helperReturn(backEvent);
    this.closeWindow();
  }
  onItemClick(item: any) {
    this.returnSelected(item);
  }
  checkForOpenWindow() {
    if ((this.listItems && this.listItems.length > 0) || this.isLoading) {
      this.openWindow();
    } else {
      this.closeWindow();
    }
  }
  openWindow() {
    this.setWindowPosition();
    this._isOpened = true;
    this.cd.detectChanges();
    this.helperService.setWindowOpened(true);
    setTimeout(() => {
      document.getElementById('histHelper').removeAttribute('tabIndex');
    }, 0);
  }
  closeWindow() {
    this._isOpened = false;
    this.listItems = null;
    this.cd.detectChanges();
    this.helperService.setWindowOpened(false);
  }

  //#endregion window control --------------------

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  display(item:SystemCode){
    var ind = item.Code?.toLowerCase().indexOf(this.currentInput);
    if(ind>=0){
      return {
        isCodeMatch:true,
        before:item.Code.substring(0,ind),
        match:item.Code.substring(ind,ind+this.currentInput.length),
        after:item.Code.substring(ind+this.currentInput.length),
      }
    }else{
      ind = item.Name?.toLowerCase().indexOf(this.currentInput);
      return {
        isCodeMatch:false,
        before:item.Name?.substring(0,ind)||'',
        match:item.Name?.substring(ind,ind+this.currentInput.length)||'',
        after:item.Name?.substring(ind+this.currentInput.length)||'',
      }
    }
  }
  displayDiag(item:DxOpt){
    var matchType:'ICD9'|'Code'|'Apply'|'Name'='Name';
    var matchText = item.CName;
    try{
      if(item.ICD9.toLowerCase().indexOf(this.currentInput)>=0){
        matchType = 'ICD9';
        matchText = item.ICD9;
      }else if(item.Code.toLowerCase().indexOf(this.currentInput)>=0){
        matchType = 'Code';
        matchText = item.Code;
      }else if(item.ApplyCode.toLowerCase().indexOf(this.currentInput)>=0){
        matchType = 'Apply';
        matchText = item.ApplyCode;
      }else if(item.CName.toLowerCase().indexOf(this.currentInput)>=0){
        matchType = 'Name';
        matchText = item.CName;
      }else{
        return {
          isMatch:matchType,
          before:matchText,
          match:'',
          after:'',
        }
      }
      var ind = matchText.toLowerCase().indexOf(this.currentInput);
      return {
        isMatch:matchType,
        before:matchText.substring(0,ind),
        match:matchText.substring(ind,ind+this.currentInput.length),
        after:matchText.substring(ind+this.currentInput.length),
      }
  }catch(e){
debugger
throw e
  }
  }
  displayRx(item:RxOpt){
    var matchType:'Code'|'Name'='Name';
    var matchText = item.ProdName;
    var ind = item.RxCode.toLowerCase().indexOf(this.currentInput);
    if(ind==0){
      matchType = 'Code';
      matchText = item.RxCode;
    }else{
      ind = item.ProdName.toLowerCase().indexOf(this.currentInput);
      if(ind>=0){
        matchType = 'Name';
        matchText = item.ProdName;
      }else{
        return {
          isMatch:matchType,
          before:matchText,
          match:'',
          after:'',
        }
      }
    }
    return {
      isMatch:matchType,
      before:matchText.substring(0,ind),
      match:matchText.substring(ind,ind+this.currentInput.length),
      after:matchText.substring(ind+this.currentInput.length),
    }
  }
}
