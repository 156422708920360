import { PageData2 } from './../../../shared/models/page-data';
import { Injectable } from '@angular/core';
import { WebApiService } from '../../web-api.service';
import { WebApiClient } from '../../web-api-client.service';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { HRXReportDTO, HRxReportQuery } from './hrx-api.model';

@Injectable({
  providedIn: 'root'
})
export class HRxApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('opd/HRx');
    this.apiService.enableLoader = false;
  }

  async GenerateHRXReport(param: HRxReportQuery): Promise<HRXReportDTO> {
    return this.apiService.post('GenerateHRXReport', param).toPromise<HRXReportDTO>();
  }

}
