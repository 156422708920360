import { Component, OnInit, Input } from '@angular/core';


class CaseItem {
  Stage: string;
  ReviewDate: string;
  BH: string;
  BW: string;
  BMI: string;
  SBP: string;
  DBP: string;
  AC: string;
  HbA1C: string;
  LDL: string;
  EGFR: string;
  SGPT: string;
  SGOT: string;
}
class OpdItem {
  RegDate: string;
  Doctor: string;
  Diag1: string;
  Diag2: string;
}
class DmDrug {
  DrugName: string;
  Type: string;
  Total: string;
  Dose: string;
  Freq: string;
  Way: string;
  Days: string;
}
class LabItem {
  ItemDate: string;
  ItemName: string;
  Value: string;
}

@Component({
  selector: 'app-hist-dm-demo',
  templateUrl: './dm-demo.component.html',
  styleUrls: ['./dm-demo.component.css']
})
export class DmDemoComponent implements OnInit {

  constructor() { }

  @Input()
  PatientName = '';

  editOptions = {
    na: [],
    caseType: [],
    dmType: [],
    stageType: [],
  };

  listItems: CaseItem[];
  opdItems: OpdItem[];
  drugItems: DmDrug[];
  labItems: LabItem[];

  gridView: any; // GridDataResult;
  opdGridView: any; // GridDataResult;
  drugGridView: any; // GridDataResult;
  labGridView:any; // GridDataResult;

  rcvDate = new Date(2016, 9, 3);
  stageDate = new Date(2019, 4, 1);

  ngOnInit() {
    this.craeteData();
    this.setGridView();
  }

  craeteData() {
    this.editOptions.na = ['', 'Option 1', 'Option 2'];
    this.editOptions.caseType = ['', 'New', 'Trace', 'Annual'];
    this.editOptions.dmType = ['', 'I', 'II', 'Other'];
    this.editOptions.stageType = ['', 'New', 'Trace', 'Annual'];

    this.listItems = [
      {
        Stage: 'Trace',
        ReviewDate: '2019-05-01',
        BH: '159',
        BW: '65',
        BMI: '25.7',
        SBP: '115',
        DBP: '69',
        AC: '121',
        HbA1C: '7.7',
        LDL: '93',
        EGFR: '134.7',
        SGPT: '40',
        SGOT: '34',
      },
      {
        Stage: 'Trace',
        ReviewDate: '2019-02-01',
        BH: '',
        BW: '',
        BMI: '',
        SBP: '',
        DBP: '',
        AC: '134',
        HbA1C: '7.9',
        LDL: '96',
        EGFR: '132.5',
        SGPT: '41',
        SGOT: '36',
      },
      {
        Stage: 'Annual',
        ReviewDate: '2018-11-01',
        BH: '159',
        BW: '65',
        BMI: '25.7',
        SBP: '119',
        DBP: '68',
        AC: '102',
        HbA1C: '7.1',
        LDL: '89',
        EGFR: '112.1',
        SGPT: '39',
        SGOT: '35',
      }];
    // opd
    this.opdItems = [
      {
        RegDate: '2019-05-01',
        Doctor: 'James Lin',
        Diag1: 'E11.5 Type 2 diabetes mellitus with circulatory complications',
        Diag2: 'I11.0 Hypertensive heart disease with heart failure',
      },
    ];
    // drug
    this.drugItems = [
      {
        DrugName: 'BISO 5mg',
        Type: 'Drug',
        Total: '14',
        Dose: '0.5',
        Freq: 'MP',
        Way: 'QD',
        Days: '28'
      },
      {
        DrugName: 'SIGMART 5mg',
        Type: 'Drug',
        Total: '56',
        Dose: '1',
        Freq: 'PC',
        Way: 'BI',
        Days: '28'
      },
      {
        DrugName: 'TRAJENTA 5mg',
        Type: 'Drug',
        Total: '28',
        Dose: '1',
        Freq: 'MP',
        Way: 'QD',
        Days: '28'
      },
      {
        DrugName: 'WEICHILIN 150mg',
        Type: 'Drug',
        Total: '56',
        Dose: '1',
        Freq: 'MN',
        Way: 'BI',
        Days: '28'
      },
      {
        DrugName: 'ATOVER F.C. 10mg',
        Type: 'Drug',
        Total: '14',
        Dose: '0.5',
        Freq: 'DW',
        Way: '1D',
        Days: '28'
      },
    ];
    // lab
    this.labItems = [
      {
        ItemDate: '2019-05-01',
        ItemName: 'HbA1C',
        Value: '7.7'
      },
      {
        ItemDate: '2019-05-01',
        ItemName: 'LDL',
        Value: '93'
      },
      {
        ItemDate: '2019-05-01',
        ItemName: 'EGFR',
        Value: '134.7'
      },
      {
        ItemDate: '2019-05-01',
        ItemName: 'SGPT',
        Value: '40'
      },
      {
        ItemDate: '2019-05-01',
        ItemName: 'SGOT',
        Value: '34'
      },
    ];
  }
  setGridView() {
    this.gridView = {
      data: this.listItems,
      total: this.listItems.length
    };
    this.opdGridView = {
      data: this.opdItems,
      total: this.opdItems.length
    };
    this.drugGridView = {
      data: this.drugItems,
      total: this.drugItems.length
    };
    this.labGridView = {
      data: this.labItems,
      total: this.labItems.length
    };
  }
}
