<div class="p-2 w-100 common-container d-flex justify-content-center">
  <div class="page page-container" #reportContainer>
    <div class="content" *ngIf="reportData.TotalPage===0">
      <table class="header-table">
        <tr class="title">
          <td colspan="11" style="text-align: center;">
            {{reportData.ClinicName}}
          </td>
        </tr>
        <tr class="title">
          <td colspan="11" style="text-align: center;">
            {{reportData.Title}}
          </td>
        </tr>
      </table>
      <div style="width: 100%; text-align: center; padding: 20px; border: 1px solid black; margin-top: 20px;">
        查無資料
      </div>
    </div>
    <div class="content page-container" *ngIf="reportData.TotalPage>0">
      <div class="page-content" *ngFor="let page of reportData.Pages;let i=index">
        <div class="page-detail">
          <table class="header-table">
            <tr class="title">
              <td colspan="11" style="text-align: center;">
                {{reportData.ClinicName}}
              </td>
            </tr>
            <tr class="title">
              <td colspan="11" style="text-align: center;">
                {{reportData.Title}}
              </td>
            </tr>
          </table>
          <div style="-webkit-text-size-adjust: none; font-size: 8pt;">
            <table class="main-table">
              <tr>
                <th style="width: 11%">
                  病歷號
                </th>
                <th style="width: 7%; ">
                  姓名
                </th>
                <th style="width: 11%;">
                  身分證
                </th>
                <th style="width: 9%;">
                  生日
                </th>
                <th style="width: 9%;">
                  就醫日
                </th>
                <th style="width: 24%;">
                  醫令
                </th>
                <th style="width: 5%;">
                  金額
                </th>
                <th style="width: 7%;">
                  醫師
                </th>
                <th style="width: 7%;">
                  申報費用
                </th>
              </tr>
              <tbody>
                <tr *ngFor="let item of page.Items">
                  <td>{{item.PatientNo}}</td>
                  <td>{{item.PatientName}}</td>
                  <td>{{item.PatientCId}}</td>
                  <td>{{item.PatientBirthDay}}</td>
                  <td>{{item.RegDate}}</td>
                  <td>{{item.RxName}}</td>
                  <td>{{item.Amount}}</td>
                  <td>{{item.DoctorName}}</td>
                </tr>
              </tbody>
            </table>

            <div class="container common-container">
              <div class="row" *ngIf="page.Items && page.Items.length>0">
                <div class="col-4">
                  <label style="font-size: 12pt;">本頁總計： {{page.Items.length}} 筆</label>
                </div>
                <div class="col-4" style="text-align: right;margin-left: 64px;">
                  <label style="font-size: 12pt;">本頁總金額： {{page.PageTotalAmount}}</label>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>
