import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { HistPrintCompressDataDto } from 'src/app/hist/models/hist-print-operation-data';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { CidMaskPipe } from 'src/app/shared/pipes/cid-mask.pipe';

@Component({
  selector: 'app-report-hist-print-compress',
  templateUrl: './report-hist-print-compress.component.html',
  styleUrls: ['./report-hist-print-compress.component.css'],
  providers: [CidMaskPipe]
})
export class ReportHistPrintCompressComponent implements OnInit, AfterViewInit {

  @Output()
  printed = new EventEmitter<boolean>();

  sizePage = {
    width: 20, //cm
    height: 28.5 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: any;
  pageContent = [[]];

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  commonStyle = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black; border-top: 0px solid black; vertical-align: middle;';
  commonStyle1 = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
  commonStyle2 = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black; border-top: 1px solid black; vertical-align: middle;';
  commonClass = '';
  commonClassText = '';
  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  pageNo: number = 1;
  reportData: HistPrintCompressDataDto[];
  reportName: string = '';
  shortReportName: string = '';
  forExcel: boolean = false;
  icodes: ValueTextPair[] = [];
  cidMask: string = '1234567890';
  excludeSpecialMark: boolean = true;

  constructor(
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService: HcrService,
    private clinicDataService: ClinicDataService,
    private cidMaskPipe: CidMaskPipe
  ) {}

  async ngOnInit() {
    var codes = await this.clinicDataService.getSystemCodes(['C0010']);
    this.icodes = [{ value: '', text: '' }].concat(codes['C0010']);
    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;

    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 0px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '.pres-report2 table tr { line-height: 20px; }\n' +//調整每行高度
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);

  }

  ngAfterViewInit() {

  }

  clearData() {
    this.totalRecord = 0;
    if (this.elContainer)
      this.elContainer.innerHTML = '';
  }

  fillData(data: HistPrintCompressDataDto[], excludeSpecialMark: boolean) {
    this.elContainer = document.getElementById("container");
    this.reportData = data;
    this.excludeSpecialMark = excludeSpecialMark;
    this.reportName = '病　歷　表';
    this.shortReportName = '濃縮病歷表';
    this.forExcel = true;
    this.commonClass = this.forExcel ? 'class="excel-format"' : '';
    this.commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    this.totalRecord = this.reportData.length;
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);

    // 組報表的html內容
    this.insertReportData();
  }

  insertReportData() {
    var listItems = this.reportData;
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);                                 // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);                                      // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader(listItems[0]);                         // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                                               // 報表結束 -- 以下空白
    var elPageContent = document.getElementById("page-" + iPage + "-content");               // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    var j: number = 0;
    for (let i = 0; i < listItems.length; i++) {
        // 加入 List 每一筆的內容
        prevTableContent = elTableContent.innerHTML;
        if (afterSummary) {
            elTableContent.innerHTML += this.createHTMLListHeader(listItems[i]);
            afterSummary = false;
        }
        if (listItems[i].IsSummary) {
            html_Block = this.createHTMLSummary(listItems[i].SummaryAmount, j);
            afterSummary = true;
            j = 0;
        } else {
            html_Block = this.createHTMLBlock(listItems[i]);
            j++;
        }
        elTableContent.innerHTML += html_Block;
        // 判斷是否超出頁面範圍? -> 新增一頁
        //IsMasterFirst=不同Patient跳頁用
        if ((i > 0 && listItems[i].IsMasterFirst) || elPageContent.offsetHeight > this.heightPageWithoutPadding) {
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = this.createHTMLPageHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-content");
            //若是資料多而跳頁時不用印list header
            if (listItems[i].IsMasterFirst) {
              if (listItems[i].IsSummary) {
                  elTableContent.innerHTML = this.createHTMLListHeader(listItems[i-1]);
              } else {
                  elTableContent.innerHTML = this.createHTMLListHeader(listItems[i]);
              }
            }
            elTableContent.innerHTML += html_Block;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            this.pageContent[iPage].push(iBlock);
        }

        iBlock += 1;
    }
    elPageContent.innerHTML +=  html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 14pt;
          }
          .excel-format-text {
            font-size: 14pt;
            mso-number-format: "@";
          }
        </style>`;
    }
    //margin: 0px auto; 若設5px，只印一頁時，輸出pdf會多一頁空白。
    page += `
      <div class="page" id="page-` + iPage + `"
        style="
        height: ` + this.sizePage.height + `cm;
        width: ` + this.sizePage.width + `cm;
        padding-top: ` + this.paddingPage.top + `cm;
        padding-right: ` + this.paddingPage.right + `cm;
        padding-bottom: ` + this.paddingPage.bottom + `cm;
        padding-left: ` + this.paddingPage.left + `cm;
        display: block;
        margin: 0px auto;
        box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        background-color: white;
        ">
        <div class="content" id="page-` + iPage + `-content">
        </div>
      </div>`;
    return page;
  }

  createHTMLPageHeader(iPage) {
    return `
      <div id="header" style="margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table style="width: 100%; text-align: center; border: none;">
            <tr>
              <td colspan="9" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 細明體;">
              ${this.reportName}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div style="-webkit-text-size-adjust: none; font-size: 8pt; zoom: 0.8; margin: 0px 2px; text-align: center;">
        <label style="font-weight: bold; height: 10px;">　</label>
      </div>
      <div style="-webkit-text-size-adjust: none; font-size: 12pt; zoom: 0.8; margin: 0px 10px; font-family: 細明體;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;"></table>
        </div>
      </div>`;
  }

  createHTMLListHeader(dto?: HistPrintCompressDataDto) {
    let data = dto.Master;
    let birthday = data.Birthday ? DateHelper.getROCFullDateString(new Date(data.Birthday), '/') : '';
    let cid = this.cidMaskPipe.transform(data.CId, this.cidMask);
    let showSpecialMark = this.excludeSpecialMark ? '' : `
          <tr>
            <td colspan="5" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>特別記載：${data.PatientRemark}</td>
          </tr>
    `;

    return `
      <tr><td>
        <table style="width:100%; font-size: 14pt;">
          <tr>
            <td             style="text-align: left; width: 23%; ${this.commonStyle}" ${this.commonClass}>掛號證號：${data.PatientNo}</td>
            <td             style="text-align: left; width: 25%; ${this.commonStyle}" ${this.commonClass}>身分證號：${cid}</td>
            <td             style="text-align: left; width: 18%; ${this.commonStyle}" ${this.commonClass}>姓名：${data.CName}</td>
            <td             style="text-align: left; width: 12%; ${this.commonStyle}" ${this.commonClass}>性別：${data.SexName}</td>
            <td             style="text-align: left; width: 22%; ${this.commonStyle}" ${this.commonClass}>出生日期：${birthday}</td>
          </tr>
          <tr>
            <td             style="text-align: left; ${this.commonStyle}" ${this.commonClass}>電　　話：${data.CellPhone}</td>
            <td colspan="4" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>住　　址：${data.Address}</td>
          </tr>
          ${showSpecialMark}
        </table>
      </td></tr>
      `;
  }

  createHTMLBlock(dto: HistPrintCompressDataDto) {
    let data = dto.Master;
    let detail = dto.Detail;
    let regDate = data.Date ? DateHelper.getROCFullDateString(new Date(data.Date), '/') : '';
    let iCodeName = this.icodes.find(i => i.value === data.ICode)?.text ?? data.ICode;
    let cardSerial = data.Isqno + (data.CusNo ? '-' + data.CusNo : '');
    let showPE = data.PE ? `
          <tr>
            <td colspan="5" style="text-align: left; white-space: pre-wrap; ${this.commonStyle}" ${this.commonClass}>PE:${data.PE}</td>
          </tr>
    ` : '';

    //HRx的表頭(含NHIDeclare資料)
    let detailFirst = !dto.IsDetailFirst ? '' : `
      <tr><td>
        <table style="width:100%; font-size: 14pt;">
          <tr>
            <td             style="text-align: left; width: 23%; padding-top: 10px; ${this.commonStyle2}" ${this.commonClass}>病歷日期：${regDate}</td>
            <td             style="text-align: left;             padding-top: 10px; ${this.commonStyle2}" ${this.commonClass}>病歷號碼：${data.PatientNoAndName}</td>
            <td             style="text-align: left;             padding-top: 10px; ${this.commonStyle2}" ${this.commonClass}>醫師：${data.DoctorName}</td>
            <td             style="text-align: left;             padding-top: 10px; ${this.commonStyle2}" ${this.commonClass}>藥師：${data.PharmacistName}</td>
            <td             style="text-align: left; width: 16%; padding-top: 10px; ${this.commonStyle2}" ${this.commonClass}>卡序：${cardSerial}</td>
          </tr>
          <tr>
            <td colspan="5" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>門診登錄：${iCodeName} ${data.AllDxName}</td>
          </tr>
          <tr>
            <td colspan="5" style="text-align: left; white-space: pre-wrap; ${this.commonStyle}" ${this.commonClass}>${data.CC}</td>
          </tr>
          ${showPE}
          <tr>
            <td             style="text-align: left; ${this.commonStyle}" ${this.commonClass}>　</td>
          </tr>
          <tr>
            <td colspan="4" style="text-align: left; ${this.commonStyle}" ${this.commonClass}>■ 處方內容：</td>
            <td             style="text-align: left; ${this.commonStyle}" ${this.commonClass}>科別：${data.Dept}</td>
          </tr>
        </table>
      </td></tr>

      <tr><td>
        <table style="width:100%; font-size: 14pt;">
          <tr>
            <td style="text-align: center; width:   4%; ${this.commonStyle1}" ${this.commonClass}>N</td>
            <td style="text-align: left;   width:  10%; ${this.commonStyle1}" ${this.commonClass}>藥材</td>
            <td style="text-align: left;                ${this.commonStyle1}" ${this.commonClass}>處置項目</td>
            <td style="text-align: right;  width:   6%; ${this.commonStyle1}" ${this.commonClass}>數量</td>
            <td style="text-align: left;   width:   6%; ${this.commonStyle1}" ${this.commonClass}>UN</td>
            <td style="text-align: left;   width:   9%; ${this.commonStyle1}" ${this.commonClass}>頻率</td>
            <td style="text-align: right;  width:   6%; ${this.commonStyle1}" ${this.commonClass}>日</td>
            <td style="text-align: right;  width:   8%; ${this.commonStyle1}" ${this.commonClass}>總量</td>
            <td style="text-align: left;   width:   6%; ${this.commonStyle1}" ${this.commonClass}>途徑</td>
            <td style="text-align: left;   width:  10%; ${this.commonStyle1}" ${this.commonClass}>RM</td>
          </tr>
        </table>
      </td></tr>
      `;

    let detailText = `
      <tr><td>
        <table style="width:100%; font-size: 14pt;">
          <tr>
            <td style="text-align: center; width:   4%; ${this.commonStyle}" ${this.commonClass}>${detail.SPRule !== 0 ? detail.SPRule : ''}</td>
            <td style="text-align: left;   width:  10%; ${this.commonStyle}" ${this.commonClassText}>${detail.RxCode}</td>
            <td style="text-align: left;                ${this.commonStyle}" ${this.commonClassText}>${detail.ProdName}</td>
            <td style="text-align: right;  width:   6%; ${this.commonStyle}" ${this.commonClass}>${detail.QTY}</td>
            <td style="text-align: left;   width:   6%; ${this.commonStyle}" ${this.commonClass}>${detail.Unit}</td>
            <td style="text-align: left;   width:   9%; ${this.commonStyle}" ${this.commonClass}>${detail.Freq}</td>
            <td style="text-align: right;  width:   6%; ${this.commonStyle}" ${this.commonClass}>${detail.Days}</td>
            <td style="text-align: right;  width:   8%; ${this.commonStyle}" ${this.commonClass}>${detail.TotalDose}</td>
            <td style="text-align: left;   width:   6%; ${this.commonStyle}" ${this.commonClass}>${detail.Way}</td>
            <td style="text-align: left;   width:  10%; ${this.commonStyle}" ${this.commonClassText}>${detail.Remark.slice(0, 8)}</td>
          </tr>
        </table>
      </td></tr>
      `;

    //同一Patient, NHIDeclare, HRx 最後印出機構資料
    //不用給commonClass因為font-size用12pt
    let masterLast = !dto.IsMasterLast ? '' : `
      <tr><td>
        <table style="width:100%; font-size: 12pt;">
          <tr>
            <td style="text-align: right; width:  25%; ${this.commonStyle2}">醫事機構代號及名稱：</td>
            <td style="text-align: left;               ${this.commonStyle2}">${data.NHICode}  ${data.ClinicName}</td>
            <td style="text-align: left; width:   30%; ${this.commonStyle2}">院所電話：${data.ClinicPhone}</td>
          </tr>
          <tr>
            <td style="text-align: right;              ${this.commonStyle}">地址：</td>
            <td colspan="2" style="text-align: left;   ${this.commonStyle}">${data.ClinicAddress}</td>
          </tr>
        </table>
      </td></tr>
    `;

    let text = detailFirst + detailText + masterLast;
    return text;
  }

  createHTMLSummary(summary: number, records: number) {
    return '';

    var html = `<tr>
                    <td colspan='3' style="text-align: right; width: 33%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        小計:
                    </td>
                    <td style="text-align: right; width: 17%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        ` + (Math.round(summary*100)/100).toFixed(2) + `
                    </td>
                    <td style="text-align: left; width: 12%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        　
                    </td>
                    <td colspan='2' style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        筆數:
                    </td>
                    <td style="text-align: right; width: 18%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        ` + records + `筆
                    </td>
                    <td style="text-align: left; width: 2%; font-size: 14px; font-weight: bolder; border-right: 0px solid black; border-left: 0px solid black; border-top: 1px solid black; border-bottom: 1px solid black;">
                        　
                    </td>
                </tr>`;
    return html;
  }

  createHTMLFinish() {
      // return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: none;">
      //             <label style="font-weight: bold;">以下空白</label>
      //         </div>`;
      return '';
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      //去除邊框, 實際印出到pdf
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
      html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
    }
    return html;
  }

  async onPrint() {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.shortReportName, printContents);
      if (ret.Successed) {
          this.notification.showSuccess(this.shortReportName + '已送出列印!', true);
      } else {
          this.notification.showError(this.shortReportName + '送出列印失敗!');
      }
      this.printed.emit(true);
  }

  async exportAsExcel() {
    if (this.reportData.length > 0) {
      const table = this.reportContainer.nativeElement;

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      await this.hcrService.ExportTableToExcel(this.shortReportName, html);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;

    this.printed.emit(true);
  }

}
