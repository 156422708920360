import { Injectable } from "@angular/core";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";
import { MemoryCache } from "../memory-cache-service";
import { WebApiClient } from "../web-api-client.service";
import { WebApiService } from "../web-api.service";

export class PostArea{
    City:string;
    Area: string;
    Road:string;
}

@Injectable({providedIn:'root'})
export class PostAreaAPI{
    apiService: WebApiService;
    cacheKey = 'PostAreaCity';
    private locker: Promise<boolean>;
    private res: (b:boolean)=>void;
    //private static cityAreaCache:{city:string[],area:ValueTextPair[]};
    /**
     *
     */
    constructor(private apiClient: WebApiClient,
        private memoryCache:MemoryCache) {
        this.apiService = this.apiClient.createHisService('system/PostArea');
        this.apiService.enableLoader = false;
    }
    async GetCityArea(): Promise<{city:string[],area:ValueTextPair[]}>{
        if(this.locker==null){
            this.locker = new Promise((res,rej)=>{
                this.res = res;
            });
        }else{
            await this.locker;
        }
        if(this.memoryCache.has(this.cacheKey)){
            this.res(false);
            this.locker = null;
            return this.memoryCache.get(this.cacheKey);
        }
        // if(PostAreaAPI.cityAreaCache){
        //   return PostAreaAPI.cityAreaCache;
        // }
        try{
            var ret = await this.apiService.get('GetCityArea').toPromise<{city:string[],area:ValueTextPair[]}>();
            this.memoryCache.set(this.cacheKey,ret);
            //PostAreaAPI.cityAreaCache = ret;
            this.res(true);
            this.locker = null;
            return ret;
        }catch(ex){
            this.res(false);
            this.locker = null;
            throw ex;
        }
    }
    async GetByZip(zipCode:string): Promise<PostArea>{
        var ret = await this.apiService.get('GetByZip',{zipcode:zipCode}).toPromise<PostArea>();
        return ret;
    }
    async GetZip(city:string,area:string): Promise<string>{
        var ret = await this.apiService.get('GetZip',{city,area}).toPromise<string>();
        return ret;
    }
}