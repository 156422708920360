import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { PatientApi, PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { StatusFinishWithoutPrescriptionReportComponent } from 'src/app/system/report/components/status-finish-without-prescription-report/status-finish-without-prescription-report.component';
import { ScheduleApi } from 'src/app/services/api-service/schedule/schedule-api';
import { UserApi } from 'src/app/services/api-service/user/user-api';

@Component({
  selector: 'app-status-finish-without-prescription',
  templateUrl: './status-finish-without-prescription.component.html',
  styleUrls: ['./status-finish-without-prescription.component.css']
})
export class StatusFinishWithoutPrescriptionComponent implements OnInit {

  @ViewChild(StatusFinishWithoutPrescriptionReportComponent)
  StatusFinishWithoutPrescriptionReportComponent: StatusFinishWithoutPrescriptionReportComponent;

  constructor(
    private fb: FormBuilder,
    private PatientApi: PatientApi,
    private ScheduleApi : ScheduleApi,
    private UserApi : UserApi,
    private webApiClient: WebApiClient,
    private route: ActivatedRoute,
    private notification: EasyNotificationService,) {
    // this.api = this.webApiClient.createHisService('Patients/Patient');
    this.api = webApiClient.createHisService('schedule/shiftschedule');
    ValueTextPairNumberValue
  }

  // api服務
  api: WebApiService;

  // 查詢條件
  rooms: ValueTextPair[];
  roomSelected: any;
  roomsDropdown: ValueTextPair[] = [];
  doctorsDropdown : ValueTextPairNumberValue[];
  DoctorList: ValueTextPairNumberValue[] = [];
  queryFormGroup: FormGroup;
  userConfirm: any;
  StatusFinishWithoutPrescriptionComponent: StatusFinishWithoutPrescriptionComponent;
  listItems: StatusFinishWithoutPrescriptionComponent[];
  Seltype: ValueTextPair[] = [
    { value: '1', text: '依病歷號排序' },
    { value: '2', text: '依就診日排序(由近到遠)' },
    { value: '3', text: '依就診日排序(由遠到近)' },]
    
  ngOnInit() {
    this.createQueryFormGroup();
    this.getQueryOptions();
  }

  // 建立query form group物件與預設值
  createQueryFormGroup() {
    this.queryFormGroup = this.fb.group
      ({
        RoomId:[], 
        DoctorId:[], 
        SortMethod: [this.Seltype[0].value], //初始值"依病歷號排序"
        startDate: [new Date(new Date().getFullYear(), new Date().getMonth())],
        endDate: [new Date(new Date())],
      });
  }

    // 由serve 取得查詢選項
    async getQueryOptions() {
      var RoomCodedata = await this.ScheduleApi.GetData(); //診間代號
      this.doctorsDropdown = await this.UserApi.GetDoctorList(); //醫師別代號
      this.rooms = RoomCodedata.medRoomList;
      this.roomsDropdown = this.rooms;
      this.queryFormGroup.controls.RoomId.setValue(this.roomsDropdown[0].value); //塞診別代號預設值
      this.queryFormGroup.controls.DoctorId.setValue(this.doctorsDropdown[0].extension); //塞醫師別代號預設值
    }

  // btnQuery
  async onBtnQueryClick() {
    var RoomId = this.queryFormGroup.controls['RoomId'].value;
    var DoctorId = this.queryFormGroup.controls['DoctorId'].value;
    var startDate = (new Date(this.queryFormGroup.controls['startDate'].value)).toLocaleDateString();
    var endDate = (new Date(this.queryFormGroup.controls['endDate'].value)).toLocaleDateString();
    var SortMethod = this.queryFormGroup.controls['SortMethod'].value;
    var data: PatientListDto[] = await this.PatientApi.GetStatusFinishWithoutPrescriptionReport(startDate, endDate, RoomId, DoctorId,SortMethod);
    var Seledoctor = this.doctorsDropdown.find(doc => doc.extension === DoctorId);
    var doctorName = Seledoctor ? Seledoctor.text : "未找到醫師"
    this.StatusFinishWithoutPrescriptionReportComponent.fetchData(data, RoomId, DoctorId, startDate, endDate, doctorName)
  }

  // async onBtnQuery() {
  //   let valueBirth = this.queryFormGroup.controls['birthday'].value;
  //   let valueStartDate = this.queryFormGroup.controls['startDate'].value;
  //   let inputStartDate = valueStartDate ? DateHelper.getFormatedDateString(valueStartDate, false) : null;
  //   let valueEndDate = this.queryFormGroup.controls['endDate'].value;
  //   let inputEndDate = valueEndDate ? DateHelper.getFormatedDateString(valueEndDate, false) : null;
  // }

  onBtnPrintClick() {
    this.StatusFinishWithoutPrescriptionReportComponent.onPrint();
  }

  onBtnExportAsExcel() {
    this.StatusFinishWithoutPrescriptionReportComponent.exportAsExcel();
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }
}
