import { ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { formatType, option } from 'src/app/shared/models/dropdown-list';
import { isNullOrUndefined } from 'src/app/shared/utilities';

export const VIS_CB_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisComboboxRealTimeOptionComponent),
  multi: true
};
@Component({
  selector: 'app-vis-combobox-real-time-option',
  templateUrl: './vis-combobox-real-time-option.component.html',
  styleUrls: ['./vis-combobox-real-time-option.component.css'],
  providers: [VIS_CB_CONTROL_VALUE_ACCESSOR]

})
export class VisComboboxRealTimeOptionComponent implements OnInit, OnDestroy, ControlValueAccessor {

  myControl = new FormControl();

  private _data: option[];

  @ContentChild(TemplateRef)
  displayTmp: TemplateRef<any>;

  @Input()
  set data(value: option[]) {
    if (value) {
      this._data = value.map(x => Object.assign({}, x));
      this.options$.next(this._data);
    } else {
      this._data = [].map(x => Object.assign({}, x));
    }
  }
  get data(): option[] {
    return this._data;
  }

  @Input()
  allowCustom: Boolean;
  @Input()
  allowInput: Boolean = true;
  @Input()
  filterable: Boolean;
  @Input()
  textField: string = 'text';
  @Input()
  valueField: string = 'value';
  /**
   *是否使用原始資料
   *
   * @type {Boolean}
   * @memberof VisComboboxRealTimeOptionComponent
   */
  @Input()
  valuePrimitive: boolean;
  @Input()
  emitPrev: boolean;
  @Input()
  panelFitContent: Boolean;
  /**
   * 顯示方式 value:只顯示值 text:只顯示文字 valuetext:顯示值+文字
   * @type {formatType} 'value'|'text'|'valuetext'
   * @memberof VisComboboxComponent
   */
  @Input()
  valueFormat: formatType;

  @Input()
  defaultValue: any;

  @Input()
  disabled: boolean = false;

  @Input()
  ignoreCase: boolean = true;

  /**是否多選
   * 開啟時，多選的項目會整理成一串字串，以指定的分隔符號區隔
   */
  @Input()
  isMultiple: boolean = false;

  /**多選分隔符號
   * 開啟時，多選的項目會整理成一串字串，以指定的分隔符號區隔
   */
  @Input()
  multipleSeparator: string = ',';

  @Input()
  multiMaxItemCount?: number;

  @Input()
  setNotInComboData: boolean;

  @Input() customClass?: string = '';
  @Output()
  filterChangeEmit: EventEmitter<any> = new EventEmitter(false);
  @Output()
  valueChangeEmit: EventEmitter<any> = new EventEmitter(false);

  /**
   * 設定按鈕被按下時觸發的發射器
   * 會傳送傳送源的Option
   *
   * @type {EventEmitter<option[]>}
   * @memberof VisComboboxRealTimeOptionComponent
   */
  @Output()
  onSetting: EventEmitter<option[]> = new EventEmitter(null);

  @ViewChild('input')
  inputElement: ElementRef<HTMLInputElement>
  @ViewChild('input', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  currentSelectOption: option[];
  prevSelectOption: option[];
  displayText = '';

  class: string;
  filteredOptions: Observable<option[]>;
  options$: BehaviorSubject<option[]> = new BehaviorSubject<option[]>([]);

  _onChange: (value) => {};
  _onTouched: () => {};
  constructor(private elementRef: ElementRef, private ngZone: NgZone, private changeDetectorRef: ChangeDetectorRef) {
  }

  writeValue(obj: any): void {
    this.optSingleSelected(obj);
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnInit(): void {
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      switchMap(value => this.options$.pipe(
        map(options => this._filter(value, options))
      ))
    );

    if (this.defaultValue != null) {
      this.currentSelectOption = this.displayTextToOption(this.defaultValue);
      this.optSelectedDirect(this.currentSelectOption.map(x => x.value).join(","));
    }
  }

  private _filter(value: string, options: option[]): any[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option[this.textField].toLowerCase().includes(filterValue));
  }

  ngOnDestroy() {
    this.options$.complete();
  }

  ngAfterContentChecked() {

    this.class = this.elementRef.nativeElement.className;
  }

  inputChange() {
    var text = this.inputElement.nativeElement.value;
    if (this.allowInput) {
      this.options$.next(this.data?.filter(d => d.text?.toLowerCase().includes(text?.toLowerCase())));
    } else {
      this.inputElement.nativeElement.value = this.displayText;
    }
  }

  onInputClick() {
    this.openPanel();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }


  optSelected(evt: MatAutocompleteSelectedEvent) {
    if ((this.multiMaxItemCount) || !evt || !evt.option) {
      if (this.currentSelectOption.length < this.multiMaxItemCount) {
        this.optSelectedDirect(this.generateDisplayText(this.currentSelectOption));
      }
    }
    else {
      this.optSingleSelected(evt.option.value);
    }
  }

  optSingleSelected(value: string) {
    let targetOption = this.getOption(value);
    let mergeOption = targetOption;
    if (this.isMultiple) {
      mergeOption = [...this.currentSelectOption, ...targetOption];
    }

    this.optSelectedDirect(this.generateDisplayText(mergeOption));
  }

  optSelectedDirect(selectVal: string) {
    var retOpts = this.displayTextToOption(selectVal);

    this.prevSelectOption = this.currentSelectOption;
    this.currentSelectOption = retOpts;

    let currentDisplayText = this.generateDisplayText(this.currentSelectOption);
    let prevDisplayText = this.generateDisplayText(this.prevSelectOption);

    if (retOpts) {

      this.displayText = currentDisplayText;
    }

    if (this._onChange) {
      this._onChange(this.currentSelectOption.map(x => x.value).join(','));
    }

    if (this.valuePrimitive) {

      if (this.emitPrev) {
        this.valueChangeEmit.emit({ value: this.currentSelectOption, prev: this.prevSelectOption });
      } else {
        this.valueChangeEmit.emit(this.currentSelectOption);
      }
    } else {
      if (this.emitPrev) {
        this.valueChangeEmit.emit({ value: currentDisplayText, prev: prevDisplayText });
      } else {
        this.valueChangeEmit.emit(currentDisplayText);
      }
    }

  }

  valueChange(evt: Event) {
    let evtValue: string = evt.target['value'];
    this.optSelectedDirect(evtValue);
    this.options$.next(this.data);
    this.autoComplete.closePanel();
  }

  generateDisplayText(opts: option[]) {
    if (!opts || opts.length == 0) {
      return '';
    }

    if (this.multiMaxItemCount) {
      if (opts.length > this.multiMaxItemCount) {
        opts = opts.slice(0, this.multiMaxItemCount);
      }
    }

    switch (this.valueFormat) {
      case 'valuetext':
        return opts.map(x => x.value || x.text ? `${x.value} | ${x.text}` : "").join(',');
      case 'value':
        return opts.map(x => x.value).join(',');
      case 'text':
      default:
        return opts.map(x => x.text).join(',');
    }
  }

  isOptionMatch(d: option, val: string): boolean {
    switch (this.valueFormat) {
      case 'valuetext':
        return (d.value + ' | ' + d.text).toLowerCase() === val.toLowerCase();
      case 'value':
        return d.value.toLowerCase() === val.toLowerCase();
      case 'text':
      default:
        return d.text.toLowerCase() === val.toLowerCase();
    }
  };

  displayTextToOption(displayText: string): option[] {
    if (this.isMultiple && displayText && displayText.includes(',')) {
      const actVals = displayText.split(',');
      return this.data.filter(d => actVals.some(val => this.isOptionMatch(d, val)));
    } else {
      const actVal = displayText ? displayText.toLowerCase() : '';
      return actVal ? this.data.filter(d => this.isOptionMatch(d, actVal)) : [];
    }
  }

  getOption(value: string): option[] {
    return this.data.filter(d => d.value == value);

  }


  openPanel() {
    if (this.disabled) {
      return;
    }
    this.options$.next(this.data);
    this.ngZone.run(() => {
      this.autoComplete.openPanel();
    });

  }

  onSettingClick() {
    if (this.disabled) {
      return;
    }
    this.onSetting.emit(this.data);
  }

}
