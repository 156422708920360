import { CdkDrag } from '@angular/cdk/drag-drop';
import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { VisDialogActionsComponent } from '../vis-dialog-actions/vis-dialog-actions.component';

@Component({
  selector: 'app-vis-window',
  templateUrl: './vis-window.component.html',
  styleUrls: ['./vis-window.component.css']
})
export class VisWindowComponent implements OnInit {

  @ViewChild('temp',{static:false})
  template: TemplateRef<any>
  @ContentChild(VisDialogActionsComponent)
  actionBar:VisDialogActionsComponent;

  @Input()
  title:string;
  @Input()
  hideTitle:boolean = false;
  //#region @Input() Width
  _width = 300;
  @Input()
  set width(v: number){
    if(v>0){
      this._width = v;
    }else{
      this._width = 300;
    }
    this.setSize(this._height,this._width);
  };
  //#endregion
  //#region @Input() Height
  _height = 300;
  @Input()
  set height(v: number){
    if(v>0){
      this._height = v;
    }else{
      this._height = 300;
    }
    this.setSize(this._height,this._width);
  };
  //#endregion
  //#region @Input() MinWidth
  _minWidth = 0;
  @Input()
  set minWidth(v: number){
    if(v>0){
      this._minWidth = v;
    }else{
      this._minWidth = 0;
    }
    this.setSize(this._height,this._width);
  };
  //#endregion
  //#region @Input() MinHeight
  _minHeight = 0;
  @Input()
  set minHeight(v: number){
    if(v>0){
      this._minHeight = v;
    }else{
      this._minHeight = 0;
    }
    this.setSize(this._height,this._width);
  };
//#endregion
  //#region Position
  _left: number = 0;
  @Input()
  set left(v: number){
    if(v>0){
      this._left = v;
    }else{
      this._left = 0;
    }
    this.setPos(this._top,v);
  };
  _top:number= 0;
  @Input()
  set top(v: number){
    if(v>0){
      this._top = v;
    }else{
      this._top = 0;
    }
    this.setPos(this._top,this._left);
  };
  //#endregion
  @Input()
  resizable:Boolean = true;

  @Output()
  sizeChanged = new EventEmitter<WindowSizeAndPos>()

  @Output()
  close = new EventEmitter<any>(false);

  sizeStyle:any = {};
  sizeChangeTimeout: any;
  _dialogRef:MatDialogRef<any,any>;
  constructor(private elementRef: ElementRef) {
    console.log(elementRef.nativeElement.style);
    elementRef.nativeElement.style.top = '0px';
    elementRef.nativeElement.style.position = 'absolute';
    //top: 0px;
    //position: absolute;
  }

  ngOnInit(): void {
    this.setSize(this._height,this._width);
    this.setPos(this._top,this._left);

  }
  ngAfterContentInit(){
    //this.dialog.open(this.template)
  }

  ngAfterViewInit(): void {

  }
  _onClose(){
    this.close.emit();
  }

  startX = 0;
  startY = 0;
  onDragStart(event: DragEvent) {
    this.startX = event.clientX;
    this.startY = event.clientY;
  }
  onDrag(direct:('Up'|'Down'|'Left'|'Right')[] ,event: DragEvent) {
    var movedX = event.clientX- this.startX;
    var movedY = event.clientY- this.startY;
    this.startX = event.clientX;
    this.startY = event.clientY;
    var boundary = document.getElementsByClassName('main-content')[0];
    var boudaryWidth = boundary.clientWidth;
    var boudaryHeight = boundary.clientHeight;
    if(direct.some(v=>v=='Up')){
      if(event.clientY > 0){
        if(this._top + movedY<0){
          movedY = -this._top;
        }
        if(this._height-movedY<this._minHeight)
        {
          movedY = this._height - this._minHeight
        }
        this.setSize(this._height - movedY, this._width);
        this.setPos(this._top + movedY,this._left);
      }
    }
    if(direct.some(v=>v=='Down')){
      if(event.clientY > 0){
        if(this._top + this._height + movedY > boudaryHeight){
          movedY = boudaryHeight - this._height - this._top;
        }
        this.setSize(this._height + movedY, this._width);
      }
    }
    if(direct.some(v=>v=='Left')){
      if(event.clientX > 0){
        if(this._width-movedX<this._minWidth)
        {
          movedX = this._width - this._minWidth
        }
        this.setSize(this._height, this._width-movedX);

        this.setPos(this._top,this._left+movedX);
      }
    }
    if(direct.some(v=>v=='Right')){
      if(event.clientX > 0){
        if(this._left + this._width + movedX > boudaryWidth ){
          movedX = boudaryWidth - this._width - this._left;
        }
        this.setSize(this._height, this._width + movedX);
      }
    }
    if(this.sizeChangeTimeout!=null){
      clearTimeout(this.sizeChangeTimeout);
    }
    this.sizeChangeTimeout = setTimeout(() => {
      this.sizeChanged.emit({
        Height: this._height,
        Width: this._width,
        Top: this._top,
        Left: this._left
      });
    }, 100);
  }
  onDragEnd(direct:'Up'|'Down'|'Left'|'Right',event: DragEvent) {
    console.log(this._height)
    // if(direct == 'Up'){
    //   var moved = event.clientY- this.startY;
    //   this._height = this._height - moved;
    //   this._top += moved;
    //   this.setSize(this._height,this._width);
    //   this.setPos(this._top,this._left);
    // }

  }
  setSize(height: number,width:number){
    this._height = height;
    this._width = width;
    this.sizeStyle.height = height + 'px';
    this.sizeStyle.width = width+'px';
    this.sizeStyle.minHeight = this._minHeight+'px';
    this.sizeStyle.minWidth = this._minWidth+'px';
  }
  setPos(top:number,left:number){
    this._top = top;
    this._left = left;

    var offSetEl = (this.elementRef.nativeElement as HTMLElement).offsetParent as HTMLElement;
    var allOffSetLeft = 0;
    var allOffSetTop = 0;
    while(offSetEl){
      allOffSetLeft+=offSetEl.offsetLeft;
      allOffSetTop+=offSetEl.offsetTop;
      offSetEl = offSetEl.offsetParent as HTMLElement;
    }

    this.sizeStyle.top = (top - allOffSetTop) + 'px';
    this.sizeStyle.left = (left - allOffSetLeft) +'px';
  }
  winDragEnded(evt:{source: CdkDrag, distance: {x:number,y:number}}){
    this._left+= evt.distance.x;
    var boundary = document.getElementsByClassName((<string>evt.source.boundaryElement).substring(1))[0];
    var boudaryWidth = boundary.clientWidth;
    var boudaryHeight = boundary.clientHeight;
    

    if(this._left<0) this._left = 0;
    if(this._left> boudaryWidth - this._width){
      this._left = boudaryWidth - this._width;
    }
    this._top+= evt.distance.y;
    if(this._top<0) this._top = 0;
    if(this._top> boudaryHeight - this._height){
      this._top = boudaryHeight - this._height;
    }
    var offSetEl = (this.elementRef.nativeElement as HTMLElement).offsetParent as HTMLElement;
    var allOffSetLeft = 0;
    var allOffSetTop = 0;
    while(offSetEl){
      allOffSetLeft+=offSetEl.offsetLeft;
      allOffSetTop+=offSetEl.offsetTop;
      offSetEl = offSetEl.offsetParent as HTMLElement;
    }
    this.sizeStyle.top = (this._top - allOffSetTop) + 'px';
    this.sizeStyle.left = (this._left - allOffSetLeft) +'px';
    // this.sizeStyle.top = this._top + 'px';
    // this.sizeStyle.left = this._left +'px';
    evt.source.reset();

    this.sizeChanged.emit({
      Height: this._height,
      Width: this._width,
      Top: this._top,
      Left: this._left
    });
  }
}

export class WindowSizeAndPos {
  Left: number;
  Top: number;
  Width: number;
  Height: number;
}
