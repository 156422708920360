import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { SystemCodeType } from 'src/app/services/api-service/system-code/system-code-type';
import { SystemCodeEditOption, SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { BaseConfig } from 'src/app/services/base-config.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { PageData } from 'src/app/shared/models/page-data';

@Component({
  selector: 'app-system-code-list-grid-view',
  templateUrl: './system-code-list-grid-view.component.html',
  styleUrls: ['./system-code-list-grid-view.component.css']
})
export class SystemCodeListGridViewComponent implements OnInit {

  //查詢參數
  @Input()
  clinicId: number;

  @Input()
  systemCodeType: string;

  @Input()
  systemTypeData: SystemCodeType[] = [];

  @Input()
  importTargetSystemCode: string;

  @Output()
  onSave: EventEmitter<void> = new EventEmitter();

  @Output()
  onDelete: EventEmitter<void> = new EventEmitter();

  @Output()
  onImportFinish: EventEmitter<void> = new EventEmitter();

  @Output()
  checkIsCanEdit: EventEmitter<boolean> = new EventEmitter(false);

  //表格資料
  gridView: any;

  //單頁大小
  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  //單頁跳過編號
  pageSkipNo = 0;


  IsCanEdit: boolean = false;
  listItems: PageData;
  editRowId = null;
  editData = new SystemCode('', '');
  showCreate = false;

  selectedItems: string[] = [];


  constructor(
    private systemCodeApi: SystemcodeApi,
    private notification: EasyNotificationService,
    private cdRef: ChangeDetectorRef // 注入 ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.handleQuery();
  }

  async pageChange(pageNum: any): Promise<void> {
    this.pageSkipNo = (pageNum - 1) * this.pageSize;
    await this.refreshList();
  }

  async refreshList() {
    const query = this.makeQueryConditions();
    query.pageSize = this.pageSize;
    query.pageSkip = this.pageSkipNo;

    this.cdRef.detectChanges();
    let codeObj = this.systemTypeData.find(x => x.Type == this.systemCodeType);
    if (codeObj) {
      this.IsCanEdit = codeObj.CanEdit;
    }
    else {
      this.IsCanEdit = false;
    }

    console.log("refreshList", {
      systemTypeData: this.systemTypeData,
      systemCodeType: this.systemCodeType,
      codeObj: codeObj,
    })

    this.checkIsCanEdit.emit(this.IsCanEdit);
    this.cdRef.detectChanges();

    try {
      // var data: PageData = await this.api.GetAll(query);
      var data: PageData = await this.systemCodeApi.GetAllFormCompanyMaintain(query);
      this.listItems = data;
      this.setGridView();
    } catch (ex) {
      this.notification.showGetDataFailed();
    }
  }

  makeQueryConditions(): any {
    const query = {
      clinicId: this.clinicId,
      type: this.systemCodeType,
      pageSize: this.pageSize
    };
    return query;
  }

  setGridView() {
    this.gridView = {
      data: this.listItems.data,
      total: this.listItems.total
    };
  }

  async handleQuery() {
    this.pageSkipNo = 0;
    await this.refreshList();
  }


  editRow(data: SystemCode, row: number) {
    this.editRowId = row;
    this.editData = Object.assign(this.editData, data);
  }

  cancelRow() {
    this.editRowId = null;
    this.editData = new SystemCode('', '');
  }

  addRow() {
    this.showCreate = true;
    this.editData = new SystemCode('', '')
    var type = this.systemTypeData.find(d => d.Type == this.systemCodeType);
    this.editData.Type = type.Id;
    this.editData.TypeCode = type.Type;
    this.editData.ClinicId = this.clinicId;
    this.editData.IsEnabled = true;
  }

  closeAdd() {
    this.editData = new SystemCode('', '');
    this.showCreate = false;
  }

  async saveRow() {
    this.editRowId = null;
    try {
      if (this.editData.Id == 0) {
        await this.systemCodeApi.Create(this.editData);

      } else {
        await this.systemCodeApi.Update(this.editData);
      }

      this.notification.showOk();
      this.onSave.emit();
      await this.refreshList();
    } catch (error) {
      this.notification.showFailed()
    }


    this.showCreate = false;
  }

  async deleteRow(data: any) {
    try {
      await this.systemCodeApi.Delete(data.Id);
      this.notification.showOk();
      this.editRowId = null;
      this.onDelete.emit();
      await this.refreshList();
    } catch {
      this.notification.showFailed()
    }

  }

  async handleSystemImportDone() {
    this.onImportFinish.emit();
    await this.refreshList();
  }
}
