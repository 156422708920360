import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';

@Component({
  selector: 'app-pacscreate-order-tab-content',
  templateUrl: './pacscreate-order-tab-content.component.html',
  styleUrls: ['./pacscreate-order-tab-content.component.css']
})
export class PACSCreateOrderTabContentComponent implements OnInit {

  @Input() options: ValueTextPairNumberValue[];
  @Input() selectRegCodeId: number[] = [];
  @Output() onEmitOptionChecked = new EventEmitter<{ checked: boolean, value: number }>();

  constructor() { }

  ngOnInit(): void {
  }

  onCheckboxChange(event: any, value: number): void {
    this.onEmitOptionChecked.emit({
      checked: event.checked,
      value: value
    });
  }

  checkIsSelected = (optionValue: number):boolean => {
    return this.selectRegCodeId.includes(optionValue);

  }

}
