
<ng-container [ngSwitch]="isHCREnable">
  <div style="position: relative;" [ngClass]="isHcrDemo?'demo-mode':''">
    <img *ngSwitchCase="true" src="../../../assets/images/VisionHCR-chip.ico" (click)="showHCRDialog=true"
    style="width: 20px;cursor: pointer;" />
    <img *ngSwitchDefault src="../../../assets/images/VisionHCR-chip-denied.ico" (click)="showHCRDialog=true"
      style="width: 20px;cursor: pointer;" />
    <ng-container *ngIf="isHcrDemo">
      <span >(DEMO)</span>
    </ng-container>
  <ng-container *ngIf="nhiWebApiError">
    <mat-icon class="vpn-error" title="健保WebAPI連線異常">
      warning
    </mat-icon>
  </ng-container>
  </div>
  
</ng-container>


<app-vis-dialog title="卡機控制軟體設定" [show]="showHCRDialog" (close)="showHCRDialog=false" [width]="600" [height]="HcrConsoleComponent.getRealHeight()>500?555:(HcrConsoleComponent.bigRatio?'95%':HcrConsoleComponent.getRealHeight())">
    <app-hcr-console #hcrConsole></app-hcr-console>
    <app-vis-dialog-actions>
      <ng-container [ngSwitch]="isHCREnable">
        <button type="button" *ngSwitchCase="true" warning="true" (click)="closeHCR()">關閉連線</button>
        <button type="button" *ngSwitchCase="false" primary="true" (click)="openHCR()">開啟連線</button>
        <mat-button-toggle class="hcr-toggle" [checked]="false" (change)="hcrConsole.enableDemo($event)" [hidden]="!isShowHcrDemo">展示模式</mat-button-toggle>
      </ng-container>

    </app-vis-dialog-actions>
  </app-vis-dialog>
