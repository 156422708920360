import { FormGroup } from '@angular/forms';

export class DateValidation {
  public static dateCheck(start: string, end: string) {
    // tslint:disable-next-line: no-shadowed-variable
    return (group: FormGroup): {[key: string]: any} => {
      const s = group.controls[start];
      const e = group.controls[end];
      if (e.value) {
        if (e.value < s.value) {
          return {error : end + ' must be greater than ' + start };
        }
      }
    };
  }
}
