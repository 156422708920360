<mat-select
class="app-v-select v-control" style="width: inherit; padding: 0px 5px 0px 5px;vertical-align: middle"
multiple
[ngClass]="_class"
[value]="_currentValue"
(selectionChange)="_valueChange($event)"
[class.v-disabled]="_disabled"
    [disabled]="_disabled">
    <mat-option *ngFor="let item of data" [value]="item.value">
        {{item.text}}
    </mat-option>
</mat-select>

