import { Injectable } from "@angular/core";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { Hspid, HspidSimpleDTO } from "./hspid";
import { Shspid } from "./Shspid";

export class GetHspidQuery {
  hid: string;
  hname: string;
  harea: string;
  pageNumber: number;
  pageSkip: number;
}

export class GetShspidQuery {
  hid: string;
  hname: string;
  harea: string;
  pageNumber: number;
  pageSkip: number;
}

@Injectable({ providedIn: 'root' })
export class HspidApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = apiClient.createHisService('opd/hspid');
    this.apiService.enableLoader = false;
  }

  async Get(): Promise<Hspid> {
    var ret = await this.apiService.get('Get').toPromise<Hspid>();
    return ret;
  }

  async GetAll(hid: string = '', hname: string = '', harea: string = ''): Promise<Hspid[]> {
    return this.apiService.get(`GetAll`,
      {
        hid: hid,
        hname: hname,
        harea: harea,
      }).toPromise();
  }

  async GetShspidAll(query: GetShspidQuery): Promise<PageData> {
    return this.apiService.get(`GetShspidAll`, query).toPromise<PageData>();
  }

  async GetHspidAll(query: GetHspidQuery): Promise<PageData> {
    return this.apiService.get(`GetHspidAll`, query).toPromise<PageData>();
  }
  async GetHspidAllFromHist(query: any): Promise<PageData> {
    return this.apiService.get(`GetHspidAll`, query).toPromise<PageData>();
  }
  async Update(id: number, newHno: number, htype: string): Promise<CustomResult> {
    return this.apiService.get(`ResortHno`,
      {
        id: id,
        newHno: newHno,
        htype: htype,
      }).toPromise();
  }

  async GetAllInspectionOffices(): Promise<HspidSimpleDTO[]> {
    return this.apiService.get(`GetAllInspectionOffices`).toPromise<HspidSimpleDTO[]>();
  }
}
