<style>
  @media print {
    .container {height: 100%;width: 100%;}
    .page {display: block;margin: 40px auto;box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);box-sizing: border-box;}
    .page .content {overflow: auto;outline: 0;}
    .page .content .block {border: 1px solid rgba(0, 0, 0, 0);padding: 0.25rem;cursor: default;}
    .page .content .block:hover {border: 1px solid #dee2e6;border-radius: 0.25rem;}
    .page .content .block .title {font-weight: bold}
    .page .content .block .title {cursor: text;}
    .width-quarter width-quarter-print {width: 50%;}
    .border-right-0 {border-right: 0}
    .border-left-0 {border-left: 0}
    .a4-print-style {width: 210mm;height: 297mm;border: 1px solid #474647;background: white;}
    .a4-print-style-h {width: 307mm;height: 210mm;border: 1px solid #474647;background: white;}
    .a4-print-style-4 {width: 210mm;height: 74mm;border: 1px solid #474647;background: white;}
    .a6-print-style {width: 105mm;height: 148mm;border: 1px solid #474647;background: white;}
    .h-auto {height: auto}
    .m-auto {margin: auto}
    .start-0 {left: 0;}
    .end-0 {right: 0;}
    .pl-2, .px-2 {padding-left: 0.5rem}
    .pr-2, .px-2 {padding-right: 0.5rem}
    .m-0 {margin: 0}
    .d-block {display: block}
    .bg-white {background-color: #fff}
    .font-weight-bolder {font-weight: bolder}
    .text-center {text-align: center}
    .mt-2, .my-2 {margin-top: 0.5rem}
    .w-100 w-100-print {width: 100%}
    .text-lg {font-size: 1.125rem }
    .text-right {text-align: right}
    .text-left {text-align: left}
    .multiLine-pre-line {white-space: pre-line;}
    .lh-xlg {line-height: 2.5rem }
    .max-w-20 {max-width: 5rem }
    .flex-grow-one {flex-grow: 1 }
  }
</style>
<div class="a6-print-style h-auto start-0 end-0 m-auto reserve-receipt-styles" #reportContainer>
  <div class="pres-report bg-white px-2 page m-0 m-auto d-block" id="page-01"
      style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{height: sizePage.height + 'cm',
        width: sizePage.width + 'cm',
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content">
      <div style="-webkit-text-size-adjust: none; font-family: '微軟正黑體';">
        <table cellspacing="0" *ngIf="data" class="w-100 font-weight-bolder table-print border-0">
          <tr>
            <td colspan="2" class="text-left pl-2 border-top-0 border-left-0 border-right-0 print-title">{{data.ClinicName}}　預約掛號單</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">診間名稱：{{data.RoomName}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">看診醫師：{{data.DoctorName}}</td>
          </tr>
          <tr>
            <td class="text-left pl-2 border-0 width-quarter-print width-quarter">門診日期：{{getYearZero(data.RegDate)}}{{data.RegDate | formatDate}}</td>
            <td class="text-left pl-2 border-0 width-quarter-print width-quarter" style="width: 50%;">病歷號碼：{{data.PatientNo}}</td>
          </tr>
          <tr>
            <td class="text-left pl-2 border-0 width-quarter-print width-quarter">門診時段：{{data.TimeName}}</td>
            <td class="text-left pl-2 border-0 width-quarter-print width-quarter" style="width: 50%;">姓名：{{data.Name}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">預約序號：{{data.WaitNo}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">備註</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter" style="height: 60px;">{{data.Remark}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">院所地址：{{data.ClinicAddress}}</td>
          </tr>
          <tr>
            <td colspan="2" class="text-left pl-2 border-0 width-quarter-print width-quarter">聯絡電話：{{data.ClinicPhone}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

</div>

