import { Injectable } from '@angular/core';
import { WebApiService } from '../../web-api.service';
import { WebApiClient } from '../../web-api-client.service';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { PACSEmitterRecordDTO, PACSStatusEnum } from './pacsemitter-record-api.model';

@Injectable({
  providedIn: 'root'
})
export class PACSEmitterRecordApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('hist/PACSEmitterRecord');
    this.apiService.enableLoader = false;
  }

  async Create(param: PACSEmitterRecordDTO): Promise<CustomResult> {
    return this.apiService.post('Create', param).toPromise<CustomResult>();
  }

  async UpdateStatus(id: number, status: PACSStatusEnum): Promise<CustomResult> {
    return this.apiService.put(`UpdateSataus/${id}/${status}`, null).toPromise<CustomResult>();
  }

  async GetLatest(registerId: number): Promise<PACSEmitterRecordDTO> {
    return this.apiService.get(`GetLatest?registerId=${registerId}`).toPromise<PACSEmitterRecordDTO>();
  }
}
