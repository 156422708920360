import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';

@Component({
  selector: 'app-system-code-add',
  templateUrl: './system-code-add.component.html',
  styleUrls: ['./system-code-add.component.css']
})
export class SystemCodeAddComponent implements OnInit {

  @Input()
  addSystemCode: SystemCode = new SystemCode('', '');

  private _show: boolean = false;
  get show() { return this._show; }
  @Input()
  set show(v: boolean) {
    if (v == true) {
      this._show = true;
    } else {
      this._show = false;
    }
  }

  @Output()
  onClose: EventEmitter<void> = new EventEmitter();

  @Output()
  onAdded: EventEmitter<void> = new EventEmitter();

  constructor(private systemCodeApi: SystemcodeApi, private notification: EasyNotificationService) { }

  ngOnInit(): void {
  }

  async save() {
    try {
      await this.systemCodeApi.Create(this.addSystemCode);
      this.notification.showOk();
      this.onClose.emit();
      this.onAdded.emit();
    } catch {
      this.notification.showFailed()
    }
  }

  async close() {
    this.onClose.emit();
  }
}

