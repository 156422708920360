import { PageData2 } from './../../../shared/models/page-data';
import { Injectable } from '@angular/core';
import { WebApiService } from '../../web-api.service';
import { WebApiClient } from '../../web-api-client.service';
import { RegistrationCodeView, RegistrationCodeCommandParam, RegistrationCodeQuery, StandardPACSSupperItemSimple, StandardRegistrationCodeListView } from './registration-code-api.model';
import { CustomResult } from 'src/app/shared/models/custom-result';

@Injectable({
  providedIn: 'root'
})
export class RegistrationCodeApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('system/RegistrationCode');
    this.apiService.enableLoader = false;
  }

  async Create(param: RegistrationCodeCommandParam): Promise<CustomResult> {
    return this.apiService.post('Create', param).toPromise<CustomResult>();
  }

  async Update(id: number, param: RegistrationCodeCommandParam): Promise<CustomResult> {
    return this.apiService.put(`Update/${id}`, param).toPromise<CustomResult>();
  }

  async Get(id: number): Promise<RegistrationCodeView> {
    return this.apiService.get(`Get?id=${id}`).toPromise<RegistrationCodeView>();
  }

  async GetPageData(query: RegistrationCodeQuery): Promise<PageData2<RegistrationCodeView>> {
    return this.apiService.get('GetPageData', query).toPromise<PageData2<RegistrationCodeView>>();
  }

  async GetAll(query: RegistrationCodeQuery): Promise<RegistrationCodeView[]> {
    return this.apiService.get('GetAll', query).toPromise<RegistrationCodeView[]>();
  }

  async Delete(id: number): Promise<CustomResult> {
    return this.apiService.delete(`Delete/${id}`).toPromise<CustomResult>();
  }

  GetPACSSupperItems = async (): Promise<StandardPACSSupperItemSimple[]> => {
    return this.apiService.get('GetPACSSupperItems').toPromise<StandardPACSSupperItemSimple[]>();
  }

  GetRegistrationCodeListViewPage = async (): Promise<StandardRegistrationCodeListView[]> => {
    return this.apiService.get('GetRegistrationCodeListViewPage').toPromise<StandardRegistrationCodeListView[]>();

  }

  Import = async (param: number[]): Promise<CustomResult> => {
    return this.apiService.post('ImportRegistrationCode', param).toPromise<CustomResult>();
  }


}
