
<app-vis-window id="histHelper"  *ngIf="true||isOpened" title="" class="opd-helper"
  [hidden]="!isOpened" [minWidth]="winWidth" [width]="winWidth" [minHeight]="0"
  [height]="winHeight" [left]="winLeft" [top]="winTop" [hideTitle]="true" [resizable]="false">

  <div class="item-wrapper" #itemWrapper [style.height]="wrapperHeightStyle">
    <div class="item-row-set" [style.margin-top]="marginTop" [style.margin-left]="marginLeft">
      <!-- Loading-->
      <ng-container *ngIf="templateType === templateTypeEnum.NA">
        <div>資料載入中…</div>
      </ng-container>
      <!-- template 1 -->
      <ng-container *ngIf="templateType === templateTypeEnum.Default">
        <ng-container *ngFor="let item of listItems; let i = index">
          <!--model: SystemCode -->
          <div [class]="rowClass(i)" (click)="onItemClick(item)" >
            <ng-container *ngTemplateOutlet="name; context: {$implicit: display(item)}"></ng-container>

            <ng-template let-data #name>
              <div style="display: flex;" *ngIf="data.isCodeMatch">
                <div style="width: 40px;">
                  [{{data.before}}<span>{{data.match}}</span>{{data.after}}]
                </div>
                <div style="font-style: italic;overflow-x: hidden;">
                  {{item.Name}}
                </div>
              </div>
              <ng-container *ngIf="!data.isCodeMatch">
                {{data.before}}<span>{{data.match}}</span>{{data.after}}
              </ng-container>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
      <!-- template 2 -->
      <ng-container *ngIf="templateType === templateTypeEnum.Diag">
        <div class="item-header">
          <div class="item-text-70px">ICD-9</div>
                <div class="item-text-70px">院內代碼</div>
                <div class="item-text-70px">ICD-10</div>
                <div class="item-text-verylongstring-overhide">診斷名(中)</div>
                <!-- <div class="item-text-20px">{{empty}}</div> -->
                <div class="item-text-verylongstring-overhide">診斷名(英)</div>
        </div>
        <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperDiag>
          <ng-container *ngFor="let item of listItems; let i = index">
            <!--model: Diagnosis -->
            <div [class]="rowClass(i)" (click)="onItemClick(item)">
              <ng-container *ngTemplateOutlet="diag; context: {$implicit: displayDiag(item)}"></ng-container>
              <ng-template let-data #diag>
                <ng-container *ngIf="data.isMatch=='ICD9'">
                  <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <div class="item-text-70px">{{item.Code}}</div>
                  <div class="item-text-70px">{{item.ApplyCode}}</div>
                  <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                  <!-- <div class="item-text-20px">{{empty}}</div> -->
                  <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
                </ng-container>
                <ng-container *ngIf="data.isMatch=='Code'">
                  <div class="item-text-70px">{{item.ICD9}}</div>
                  <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <div class="item-text-70px">{{item.ApplyCode}}</div>
                  <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                  <!-- <div class="item-text-20px">{{empty}}</div> -->
                  <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
                </ng-container>
                <ng-container *ngIf="data.isMatch=='Apply'">
                  <div class="item-text-70px">{{item.ICD9}}</div>
                  <div class="item-text-70px">{{item.Code}}</div>
                  <div class="item-text-70px">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
                  <!-- <div class="item-text-20px">{{empty}}</div> -->
                  <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
                </ng-container>
                <ng-container *ngIf="data.isMatch=='Name'">
                  <div class="item-text-70px">{{item.ICD9}}</div>
                  <div class="item-text-70px">{{item.Code}}</div>
                  <div class="item-text-70px">{{item.ApplyCode}}</div>
                  <div class="item-text-verylongstring-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <!-- <div class="item-text-20px">{{empty}}</div> -->
                  <div class="item-text-verylongstring-overhide">{{item.EName}}</div>
                </ng-container>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- template 3 -->
      <ng-container *ngIf="templateType === templateTypeEnum.OrderCode">
        <div [disabled]="true" class="item2">
          <ng-container>
            <div class="rx-code">
              <div class="item-text-70px">代碼</div>
              <div class="item-text-green">健保價</div>
              <div class="item-text-longstring">名稱</div>
              <div class="item-text-green">歸屬</div>
              <div class="item-text-70px">備註</div>
            </div>
          </ng-container>
        </div>
        <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperRx>
          <ng-container *ngFor="let item of listItems; let i = index">
            <!--model: Order -->
            <div [class]="rowClass(i)" (click)="onItemClick(item)">
              <ng-container *ngTemplateOutlet="rx; context: {$implicit: displayRx(item)}"></ng-container>
              <ng-template let-data #rx>
                <ng-container *ngIf="data.isMatch=='Code'">
                  <div class=" rx-code">
                    <div class="item-text-70px-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                    <div class="item-text-green">{{(item.IPrice || 0) | NumberFixed:2}}</div>
                    <div class="item-text-longstring-overhide">{{item.ProdName}}</div>
                    <div class="item-text-green">{{item.Type}}</div>
                    <ng-container *ngTemplateOutlet="flag; context: {$implicit: item}"></ng-container>
                  </div>
                </ng-container>
                <ng-container *ngIf="data.isMatch=='Name'">
                  <div class="item-text-70px-overhide">{{item.RxCode}}</div>
                  <div class="item-text-green">{{(item.IPrice || 0) | NumberFixed:2}}</div>
                  <div class="item-text-longstring-overhide">{{data.before}}<span>{{data.match}}</span>{{data.after}}</div>
                  <div class="item-text-green">{{item.Type}}</div>
                  <ng-container *ngTemplateOutlet="flag; context: {$implicit: item}"></ng-container>
                </ng-container>
              </ng-template>
              <ng-template #flag let-item>
                <div class="item-text-70px">
                  <div *ngIf="item.FlagDescription?.includes('STOP;')" class="item-text-red-overhide">[停用]</div>
                  <div *ngIf="item.FlagDescription?.includes('ALTERNATIVE;')" class="item-text-blue-overhide">[替代]</div>
                  <div *ngIf="item.FlagDescription?.includes('a1;')" class="item-text-purple-overhide">[瓶藥]</div>
                  <div *ngIf="item.FlagDescription?.includes('MAINSET;')" class="item-text-black-overhide">[套餐]</div>
                </div>
              </ng-template>
              <!--<div class="item-text-1">{{item.RxCode}}</div> -
              <div class="item-text-3">{{item.ProdName}}</div> -->
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- template 4 牙位 -->
      <ng-container *ngIf="templateType === templateTypeEnum.ToothPos" [style.height]="wrapperHeightStyleNoHeader" style="align-items: center; text-align: center;">
        <div style="flex-direction: row; display: flex; width: 95%; height: 40px;">
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%; justify-content: right;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('FM')==true?'btn-radius-disabled':(isToothPosSelected('FM')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('FM')" [disabled]="isInvalid('FM')">FM</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('99')==true?'btn-radius-disabled':(isToothPosSelected('99')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('99')" [disabled]="isInvalid('99')">99</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px; width: 30px;">
              &nbsp;
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('UA')==true?'btn-radius-disabled':(isToothPosSelected('UA')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('UA')" [disabled]="isInvalid('UA')">UA</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('UR')==true?'btn-radius-disabled':(isToothPosSelected('UR')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('UR')" [disabled]="isInvalid('UR')">UR</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('UL')==true?'btn-radius-disabled':(isToothPosSelected('UL')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('UL')" [disabled]="isInvalid('UL')">UL</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('UB')==true?'btn-radius-disabled':(isToothPosSelected('UB')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('UB')" [disabled]="isInvalid('UB')">UB</button>
            </div>
          </div>
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('LA')==true?'btn-radius-disabled':(isToothPosSelected('LA')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('LA')" [disabled]="isInvalid('LA')">LA</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('LR')==true?'btn-radius-disabled':(isToothPosSelected('LR')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('LR')" [disabled]="isInvalid('LR')">LR</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('LL')==true?'btn-radius-disabled':(isToothPosSelected('LL')==true?'btn-selected-radius':'btn-radius')" (click)="onItemClick('LL')" [disabled]="isInvalid('LL')">LL</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('LB')==true?'btn-radius-disabled':(isToothPosSelected('LB')==true?'btn-selected-radius':'btn-radius')"  (click)="onItemClick('LB')" [disabled]="isInvalid('LB')">LB</button>
            </div>
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="height: 15px;">
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('55')==true?'btn-spec-radius-diabled':(isToothPosSelected('55')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('55')" [disabled]="isInvalid('55')">55</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('54')==true?'btn-spec-radius-diabled':(isToothPosSelected('54')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('54')" [disabled]="isInvalid('54')">54</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('53')==true?'btn-spec-radius-diabled':(isToothPosSelected('53')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('53')" [disabled]="isInvalid('53')">53</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('52')==true?'btn-spec-radius-diabled':(isToothPosSelected('52')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('52')" [disabled]="isInvalid('52')">52</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('51')==true?'btn-spec-radius-diabled':(isToothPosSelected('51')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('51')" [disabled]="isInvalid('51')">51</button>
            </div>
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('61')==true?'btn-spec-radius-diabled':(isToothPosSelected('61')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('61')" [disabled]="isInvalid('61')">61</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('62')==true?'btn-spec-radius-diabled':(isToothPosSelected('62')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('62')" [disabled]="isInvalid('62')">62</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('63')==true?'btn-spec-radius-diabled':(isToothPosSelected('63')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('63')" [disabled]="isInvalid('63')">63</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('64')==true?'btn-spec-radius-diabled':(isToothPosSelected('64')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('64')" [disabled]="isInvalid('64')">64</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('65')==true?'btn-spec-radius-diabled':(isToothPosSelected('65')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('65')" [disabled]="isInvalid('65')">65</button>
            </div>
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; height: 10px; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('19')==true?'btn-spec-radius-diabled':(isToothPosSelected('19')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('19')" [disabled]="isInvalid('19')">19</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('18')==true?'btn-spec-radius-diabled':(isToothPosSelected('18')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('18')" [disabled]="isInvalid('18')">18</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('17')==true?'btn-spec-radius-diabled':(isToothPosSelected('17')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('17')" [disabled]="isInvalid('17')">17</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('16')==true?'btn-spec-radius-diabled':(isToothPosSelected('16')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('16')" [disabled]="isInvalid('16')">16</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('15')==true?'btn-spec-radius-diabled':(isToothPosSelected('15')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('15')" [disabled]="isInvalid('15')">15</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('14')==true?'btn-spec-radius-diabled':(isToothPosSelected('14')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('14')" [disabled]="isInvalid('14')">14</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('13')==true?'btn-spec-radius-diabled':(isToothPosSelected('13')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('13')" [disabled]="isInvalid('13')">13</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('12')==true?'btn-spec-radius-diabled':(isToothPosSelected('12')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('12')" [disabled]="isInvalid('12')">12</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('11')==true?'btn-spec-radius-diabled':(isToothPosSelected('11')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('11')" [disabled]="isInvalid('11')">11</button>
            </div>
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('21')==true?'btn-spec-radius-diabled':(isToothPosSelected('21')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('21')" [disabled]="isInvalid('21')">21</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('22')==true?'btn-spec-radius-diabled':(isToothPosSelected('22')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('22')" [disabled]="isInvalid('22')">22</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('23')==true?'btn-spec-radius-diabled':(isToothPosSelected('23')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('23')" [disabled]="isInvalid('23')">23</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('24')==true?'btn-spec-radius-diabled':(isToothPosSelected('24')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('24')" [disabled]="isInvalid('24')">24</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('25')==true?'btn-spec-radius-diabled':(isToothPosSelected('25')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('25')" [disabled]="isInvalid('25')">25</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('26')==true?'btn-spec-radius-diabled':(isToothPosSelected('26')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('26')" [disabled]="isInvalid('26')">26</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('27')==true?'btn-spec-radius-diabled':(isToothPosSelected('27')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('27')" [disabled]="isInvalid('27')">27</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('28')==true?'btn-spec-radius-diabled':(isToothPosSelected('28')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('28')" [disabled]="isInvalid('28')">28</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('29')==true?'btn-spec-radius-diabled':(isToothPosSelected('29')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('29')" [disabled]="isInvalid('29')">29</button>
            </div>
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; height: 10px; align-items: center; width: 50%; border-right: 2px solid #000000; border-bottom: 2px solid #000000; justify-content: right">
          </div>
          <div style="flex-direction: row; display: flex; width: 50%; border-bottom: 2px solid #000000;">
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; height: 10px; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('49')==true?'btn-spec-radius-diabled':(isToothPosSelected('49')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('49')" [disabled]="isInvalid('49')">49</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('48')==true?'btn-spec-radius-diabled':(isToothPosSelected('48')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('48')" [disabled]="isInvalid('48')">48</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('47')==true?'btn-spec-radius-diabled':(isToothPosSelected('47')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('47')" [disabled]="isInvalid('47')">47</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('46')==true?'btn-spec-radius-diabled':(isToothPosSelected('46')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('46')" [disabled]="isInvalid('46')">46</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('45')==true?'btn-spec-radius-diabled':(isToothPosSelected('45')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('45')" [disabled]="isInvalid('45')">45</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('44')==true?'btn-spec-radius-diabled':(isToothPosSelected('44')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('44')" [disabled]="isInvalid('44')">44</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('43')==true?'btn-spec-radius-diabled':(isToothPosSelected('43')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('43')" [disabled]="isInvalid('43')">43</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('42')==true?'btn-spec-radius-diabled':(isToothPosSelected('42')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('42')" [disabled]="isInvalid('42')">42</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('41')==true?'btn-spec-radius-diabled':(isToothPosSelected('41')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('41')" [disabled]="isInvalid('41')">41</button>
            </div>
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('31')==true?'btn-spec-radius-diabled':(isToothPosSelected('31')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('31')" [disabled]="isInvalid('31')">31</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('32')==true?'btn-spec-radius-diabled':(isToothPosSelected('32')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('32')" [disabled]="isInvalid('32')">32</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('33')==true?'btn-spec-radius-diabled':(isToothPosSelected('33')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('33')" [disabled]="isInvalid('33')">33</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('34')==true?'btn-spec-radius-diabled':(isToothPosSelected('34')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('34')" [disabled]="isInvalid('34')">34</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('35')==true?'btn-spec-radius-diabled':(isToothPosSelected('35')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('35')" [disabled]="isInvalid('35')">35</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('36')==true?'btn-spec-radius-diabled':(isToothPosSelected('36')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('36')" [disabled]="isInvalid('36')">36</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('37')==true?'btn-spec-radius-diabled':(isToothPosSelected('37')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('37')" [disabled]="isInvalid('37')">37</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('38')==true?'btn-spec-radius-diabled':(isToothPosSelected('38')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('38')" [disabled]="isInvalid('38')">38</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('39')==true?'btn-spec-radius-diabled':(isToothPosSelected('39')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('39')" [disabled]="isInvalid('39')">39</button>
            </div>
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; height: 10px; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('85')==true?'btn-spec-radius-diabled':(isToothPosSelected('85')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('85')" [disabled]="isInvalid('85')">85</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('84')==true?'btn-spec-radius-diabled':(isToothPosSelected('84')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('84')" [disabled]="isInvalid('84')">84</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('83')==true?'btn-spec-radius-diabled':(isToothPosSelected('83')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('83')" [disabled]="isInvalid('83')">83</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('82')==true?'btn-spec-radius-diabled':(isToothPosSelected('82')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('82')" [disabled]="isInvalid('82')">82</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('81')==true?'btn-spec-radius-diabled':(isToothPosSelected('81')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('81')" [disabled]="isInvalid('81')">81</button>
            </div>
          </div>
          <div style="flex-direction: row; display: flex; width: 50%;">
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('71')==true?'btn-spec-radius-diabled':(isToothPosSelected('71')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('71')" [disabled]="isInvalid('71')">71</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('72')==true?'btn-spec-radius-diabled':(isToothPosSelected('72')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('72')" [disabled]="isInvalid('72')">72</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('73')==true?'btn-spec-radius-diabled':(isToothPosSelected('73')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('73')" [disabled]="isInvalid('73')">73</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('74')==true?'btn-spec-radius-diabled':(isToothPosSelected('74')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('74')" [disabled]="isInvalid('74')">74</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="isInvalid('75')==true?'btn-spec-radius-diabled':(isToothPosSelected('75')==true?'btn-spec-selected-radius ':'btn-spec-radius')" (click)="onItemClick('75')" [disabled]="isInvalid('75')">75</button>
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <div style="margin-left: 5px; margin-right: 5px;">
              <button type="button" [ngClass]="'btn-OK'" (click)="onToothPosSureClick()">OK</button>
            </div>
          </div>
        </div>
        <div style="flex-direction: row; display: flex; width: 95%;">
          <div style="flex-direction: row; display: flex; height: 10px; align-items: center; width: 50%; border-right: 2px solid #000000; justify-content: right;">
          </div> 
          <div style="flex-direction: row; display: flex; width: 50%; justify-content: right">
          </div>
        </div>
      </ng-container>
      <!-- template 5 牙面 -->
      <ng-container *ngIf="templateType === templateTypeEnum.ToothFace">
        <div class="item2" style="background-color: rgb(219, 219, 219);">
          <ng-container>
            <div class="rx-code">
              <div class="item-text-35px">代碼</div>
              <div class="item-text-70px">名稱</div>
              <div class="item-text-25px" style="text-align: right; justify-items: right;">
                <button type="button" [ngClass]="'btn-Close'" (click)="onToothFaceSureClick()">X</button>
              </div>
            </div>
          </ng-container>
        </div>
        <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperToothFace>
          <ng-container *ngFor="let item of toothface; let i = index">
            <div [ngClass]="toothFaceRowClass(i)" (click)="onItemClick(item)">
              <div style="font-size: 14px; color: #333333; font-family: '微軟正黑體'; width: 35px;">{{item.value}}</div>
              <div style="font-size: 14px; color: #333333; font-family: '微軟正黑體'; width: 70px;">{{item.text}}</div>
              <div>&nbsp;</div>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- template 6 PCS -->
      <ng-container *ngIf="templateType === templateTypeEnum.PCS">
        <div class="item2">
          <ng-container>
            <div class="rx-code">
              <div class="item-text-70px">代碼</div>
              <div class="item-text-verylongstring-overhide">名稱</div>
            </div>
          </ng-container>
        </div>
        <div style="overflow: auto;" [style.height]="wrapperHeightStyleNoHeader" #itemWrapperPCS>
          <ng-container *ngFor="let item of listItems; let i = index">
            <div [ngClass]="PCSRowClass(item.Id)" (click)="onItemClick(item)">
              <div class="item-text-70px">{{item.ICD10Code}}</div>
              <div class="item-text-verylongstring-overhide">{{item.CName}}</div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</app-vis-window>
