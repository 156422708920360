<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;

    }
  }
</style>
<div class="a4-print-style-h h-auto" #reportContainer>
  <div class="pres-report2 bg-white px-2 page m-0 m-auto d-block page-shadow" id="page-01"
    style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content m-auto" [ngStyle]="{height: sizePage.height + 'cm', width: sizePage.width + 'cm'}">
      <div>
          <div class="w-100 w-100-print text-center font-weight-bolder my-2 mb-2mm" style="font-size: 16pt; height: 50px; line-height: 60px; ">衛生福利部中央健康保險署特約醫事服務機構復健治療項目專案申報表
          </div>
      </div>
      <div style="-webkit-text-size-adjust: none;">
        <!-- PIPE{{someNum|NumberThousandFixed}}-->
        <table cellspacing="0" class="border-solid w-100 w-100-print">
          <tr>
            <td class="text-left pl-2" style="width: 10%;">院所代號</td>
            <td class="text-left pl-2" style="width: 17%;">3501195369</td>
            <td class="text-left pl-2" style="width: 10%;">院所名稱</td>
            <td class="text-left pl-2" style="width: 29%;">松柏復健科診所</td>
            <td class="text-left pl-2" style="width: 10%;">費用年月</td>
            <td class="text-left pl-2" style="width: 8%;">11207</td>
            <td class="text-left pl-2" style="width: 8%;">印信</td>
            <td class="text-left pl-2" style="width: 8%;"></td>
          </tr>
        </table>
        <p class="mb-1 mt-3 text-left">一、物理治療人員實施物理治療、職能治療或語言治療案件</p>
        <table cellspacing="0" class="border-solid w-100 w-100-print">
            <tr>
              <td rowspan="2" style="width: 11%;"> 當月在職專任物理治療人員數(A) </td>
              <td rowspan="2" style="width: 16%;"> 專任物理治療人員實際執行物理治療總日數(B) </td>
              <td rowspan="2" style="width: 16%;"> 專任物理治療人員實際執行物理治療總人數(C) </td>
              <td rowspan="2" style="width: 18%;"> 專任物理治療人員實際執行物理治療總申請點數(D) </td>
              <td rowspan="2" style="width: 13%;"> 可申報人次上限數(E=B*45) </td>
              <td colspan="2" style="width: 26%;"> 超出上限核扣部分 </td>
            </tr>
            <tr>
              <td style="width: 11%;"> 核扣人次 (F=C-E) </td>
              <td style="width: 15%;"> 核扣點數 (G=F/C*D) </td>
            </tr>
            <tr>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
            </tr>
            <tr>
              <td rowspan="2"> 當月在職專任職能治療人員數(A) </td>
              <td rowspan="2"> 專任職能治療人員實際執行職能治療總日數(B) </td>
              <td rowspan="2"> 專任職能治療人員實際執行職能治療總人數(C) </td>
              <td rowspan="2">專任職能治療人員實際執行職能治療總申請點數(D)</td>
              <td rowspan="2"> 可申報人次上限數(E=B*45) </td>
              <td colspan="2"> 超出上限核扣部分 </td>
            </tr>
            <tr>
              <td> 核扣人次 (F=C-E) </td>
              <td> 核扣點數 (G=F/C*D) </td>
            </tr>
            <tr>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
            </tr>
            <tr>
              <td rowspan="2"> 當月在職專任語言治療人員數(A) </td>
              <td rowspan="2"> 專任語言治療人員實際執行語言治療總日數(B) </td>
              <td rowspan="2"> 專任語言治療人員實際執行語言治療總人數(C) </td>
              <td rowspan="2">專任語言治療人員實際執行語言治療總申請點數(D)</td>
              <td rowspan="2"> 可申報人次上限數(E=B*45) </td>
              <td colspan="2"> 超出上限核扣部分 </td>
            </tr>
            <tr>
              <td> 核扣人次 (F=C-E) </td>
              <td> 核扣點數 (G=F/C*D) </td>
            </tr>
            <tr>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
              <td class="text-right pr-2">&nbsp;</td>
            </tr>
          </table>
      </div>
    </div>
  </div>
  <div class="pres-report2 bg-white px-2 page m-0 m-auto d-block page-shadow" id="page-02"
    style="box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);box-sizing: border-box;"
      [ngStyle]="{
        paddingTop: paddingPage.top + 'cm',
        paddingRight: paddingPage.right + 'cm',
        paddingBottom: paddingPage.bottom + 'cm',
        paddingLeft: paddingPage.left + 'cm'}">
    <div class="content m-auto" [ngStyle]="{height: sizePage.height + 'cm', width: sizePage.width + 'cm'}">
      <div>
          <div class=" w-100  w-100-print text-center font-weight-bolder my-2 mb-2mm" style="font-size: 16pt; height: 50px; line-height: 60px; ">衛生福利部中央健康保險署特約醫事服務機構復健治療項目專案申報表
          </div>
      </div>
      <div style="-webkit-text-size-adjust: none;">
        <!-- PIPE{{someNum|NumberThousandFixed}}-->
        <p class="mb-1 mt-1 text-left">二、復健科專科醫師親自實施物理治療、職能治療或語言治療案件</p>
        <table cellspacing="0" class="border-solid w-100 w-100-print">
          <tr>
            <td rowspan="2" style="width: 6%;"> 序號 </td>
            <td rowspan="2" style="width: 14%;"> 醫師身分證號 </td>
            <td rowspan="2" style="width: 11%;"> 親自執行復健治療總日數(H) </td>
            <td rowspan="2" style="width: 10%;"> 親自執行復健治療總人次(I) </td>
            <td rowspan="2"> 親自執行復健治療總申請點數(J) </td>
            <td rowspan="2" style="width: 9%;"> 可申報人次上限數 (K=H*45) </td>
            <td rowspan="2" style="width: 17%;"> 納入門診 合理量計算件數 (L=I或K取其小值) </td>
            <td colspan="2" style="width: 23%;"> 超出上限核扣部分 </td>
          </tr>
          <tr>
            <td style="width: 11%;"> 核扣人次 (M=I-K) </td>
            <td style="width: 12%;"> 核扣點數 (N=M/I*J) </td>
          </tr>
          <tr *ngFor="let data of mockData">
            <td>{{data.caseNo}}</td>
            <td class="text-center px-2">{{data.cId}}</td>
            <td class="text-right pr-2">{{data.caseDays}}</td>
            <td class="text-right pr-2">{{data.caseCnt|NumberThousandFixed}}</td>
            <td class="text-right pr-2">{{data.casePoint|NumberThousandFixed}}</td>
            <td class="text-right pr-2">{{data.limitPeople|NumberThousandFixed}}</td>
            <td class="text-right pr-2">{{data.caseLimits|NumberThousandFixed}}</td>
            <td class="text-right pr-2">{{data.minusCnt|NumberThousandFixed}}</td>
            <td class="text-right pr-2">{{data.minusPoint|NumberThousandFixed}}</td>
          </tr>
          <tr>
            <td> 總計 </td>
            <td colspan="8" class="text-left pl-2">
              {{getCases()|NumberThousandFixed}}<span class="ml-2">納入門診合理量計算件數總計(X=L1+L2+L3+L4+L5...)</span>
            </td>
          </tr>
        </table>
          <div class="w-100 w-100-print d-flex mt-3">
            <div class="flex-grow-one notify-text">註：
            </div>
            <div class="flex-grow-one" style="width: calc(100% - 30px);">
                <ol class="pl-3 ml-0">
                    <li data-list-text="1.">實施復健治療項目之特約醫事服務機構於每月費用申報時，應並同費用申請總表檢送本表。</li>
                    <li data-list-text="2.">本表所列物理治療件數或復健治療件數均指「人次」，並含門住診。</li>
                  </ol>
            </div>
        </div>
      </div>
    </div>
  </div>

</div>
