// 一般性的結果
export class CustomResult {

  // 配合後端元件所以用大寫
  IsSuccess: boolean;
  Code: string;
  Message: string;
  Args: string[];
  Object: any;
  data: any;

  // message pattern特別標示符號為[[0]]，跟後端不一樣，因為{}在前端會parse error
  private readonly leftBracket = '[[';
  private readonly rightBracket = ']]';

  // code比message前面的原因是，多國語言的環境下，會以code來決定訊息內容而非message
  constructor(isSuccess: boolean, code?: string, args?: string[]) {
    this.IsSuccess = isSuccess;
    if (code) {
      this.Code = code;
    }
    if (args) {
      this.Args = args;
    }
  }

  public static GenerateCustomReuslt(exctption: any): CustomResult {
    return exctption as CustomResult;
  }

  public static Error(code: string, args?: string[]): CustomResult {
    return new CustomResult(false, code, args);
  }
  public static Success(): CustomResult {
    return new CustomResult(true);
  }

  // 依據message的pattern與args重新產生message
  // message: This is a [[0]]
  // args: cat
  // new message: This is a cat
  public CreateFormatMessage(message: string) {
    if (this.Args) {
      for (let i = 0; i < this.Args.length; i++) {
        const tag = this.leftBracket + i + this.rightBracket;
        message = message.replace(tag, this.Args[i]);
      }
      return message;
    } else {
      return message;
    }
  }
}


export declare type DialogResult<A, T> = { action: A, data?: T }
