import { Injectable } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { SPCSxListDto, SPCSxQuery } from './spcsx.api.model';
import { PageData2 } from 'src/app/shared/models/page-data';

@Injectable({
  providedIn: 'root'
})
export class SPCSxApiService {
  apiService: WebApiService;

  constructor(private webApiFactory: WebApiClient) {
    this.apiService = this.webApiFactory.createHisService('opd/SPCSx');
    this.apiService.enableLoader = false;
  }

  async GetSPCSxPageList(param: SPCSxQuery): Promise<PageData2<SPCSxListDto>> {
    return this.apiService.get('GetSPCSxPageList', param).toPromise<PageData2<SPCSxListDto>>();
  }
}
