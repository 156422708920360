<style>
  @media print {
    body {
      margin: 0;
      color: #000;
      background-color: #fff;
    }
  }
</style>
<div #reportContainer>
  <div class="a4-print-style-h" *ngFor="let item of itemData">
    <div class="pres-report" style="background-color: white;height: 100%;width: 100%;">
      <div>
        <div style="display: inline-block; width: calc(100% - 200px);">
          <div style="text-align: center;font-weight: bolder; font-size: 16pt; ">特約醫事服務機構門診醫療服務點數申報總表
          </div>
        </div>
        <div style=" display: inline-block; text-align: center;margin-top: 5px;">
          <table style="font-size: 10pt; width: 190px;">
            <tr>
              <th style="width: 50px; padding: 0px 2px 0px 2px;">西醫</th>
              <th style="width: 50px; padding: 0px 2px 0px 2px;">牙醫</th>
              <th style="width: 50px; padding: 0px 2px 0px 2px;">中醫</th>
            </tr>
            <tr>
              <td style="height: 20px; text-align: center;">{{item?.CheckMedicine==1?"V":""}}</td>
              <td style="text-align: center;">{{item?.CheckMedicine==2?"V":""}}</td>
              <td style="text-align: center;">{{item?.CheckMedicine==3?"V":""}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="-webkit-text-size-adjust: none;font-size: 12px;zoom: 0.8;margin: 0px 10px;">
        <div style="display: flex;column-gap: 5px;">
          <table style="width: 100%; font-size: 14pt; text-align: center;">
            <tr>
              <th style="width: 10%;" colspan="2">
                t1資料格式
              </th>
              <th style="width: 25%;" colspan="2">
                t2服務機構
              </th>
              <th style="width: 10%;">
                t3費用年月
              </th>
              <th style="width: 10%;">
                t4申報方式
              </th>
              <th style="width: 10%;">
                t5申報類別
              </th>
              <th style="width: 10%;">
                t6申報日期
              </th>
              <th style="width: 10%;">
                收文日期
              </th>
            </tr>
            <tr>
              <td>
                10
              </td>
              <td>
                門診申報總表
                <!-- <div>{{clinic?.NHICode}}</div>
              <div>{{clinic?.Name}}</div> -->
              </td>
              <td style="width: 7%;">
                (代號)<br>{{item?.H10?.e10cd}}
              </td>
              <td>
                (名稱)<br>{{item?.ClinicName}}
              </td>
              <td>
                {{item?.H10?.e10ym}} ■全月
              </td>
              <td>
                {{item?.H10?.e10mt}}
              </td>
              <td>
                {{item?.H10?.e10kd}}
              </td>
              <td>
                {{item?.H10?.e10da}}
              </td>
              <td>
                　　　 年　　　 月　　　 日
              </td>
            </tr>
          </table>
        </div>

        <div style="display: flex;column-gap: 5px; margin-top: 5px;">
          <table style="width: 100%; font-size: 14pt; text-align: center;">
            <tr>
              <th colspan="2" style="width: 21%;">
                類　　別
              </th>
              <th colspan="2" style="width: 20%;">
                件　　數
              </th>
              <th colspan="2" style="width: 25%;">
                申請點數(扣除部分負擔後淨額)
              </th>
              <th rowspan="20" style="width: 34%; text-align: left;vertical-align: top; font-weight: normal;">
                <div>
                  <br>
                  負責醫師姓名：{{item?.OwnerName}}
                  <br>
                  醫事服務機構地址：{{item?.ClinicAddress}}
                  <br>
                  電話：{{item?.ClinicPhone}}
                  <br>
                  印信：
                </div>
              </th>
            </tr>
            <tr>
              <td rowSpan="5" style="width: 3%;">
                西醫
              </td>
              <td>
                一般案件
              </td>
              <td style="width: 3%;">
                t7
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10n0|NumberThousandFixed}}
              </td>
              <td style="width: 3%;">
                t8
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10n1|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                專案案件
              </td>
              <td>
                t9
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10n2|NumberThousandFixed}}
              </td>
              <td>
                t10
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10n3|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                洗&emsp;&emsp;腎
              </td>
              <td>
                t11
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10hn|NumberThousandFixed}}
              </td>
              <td>
                t12
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ha|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                結核病
              </td>
              <td>
                t15
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10fn|NumberThousandFixed}}
              </td>
              <td>
                t16
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10fa|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                小計
              </td>
              <td>
                t17
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10nn|NumberThousandFixed}}
              </td>
              <td>
                t18
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10na|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                牙醫
              </td>
              <td>
                一般案件
              </td>
              <td>
                t19
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10d0|NumberThousandFixed}}
              </td>
              <td>
                t20
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10d1|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                專案案件
              </td>
              <td>
                t21
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10d2|NumberThousandFixed}}
              </td>
              <td>
                t22
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10d3|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                小計
              </td>
              <td>
                t23
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10dn|NumberThousandFixed}}
              </td>
              <td>
                t24
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10dm|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                中醫
              </td>
              <td>
                一般案件
              </td>
              <td>
                t25
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10c0|NumberThousandFixed}}
              </td>
              <td>
                t26
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10c1|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                專案案件
              </td>
              <td>
                t27
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10c2|NumberThousandFixed}}
              </td>
              <td>
                t28
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10c3|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td>
                小計
              </td>
              <td>
                t29
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10cn|NumberThousandFixed}}
              </td>
              <td>
                t30
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ca|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                預防保健
              </td>
              <td>
                t31
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10pn|NumberThousandFixed}}
              </td>
              <td>
                t32
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10pa|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                慢性病連續處方箋調劑
              </td>
              <td>
                t33
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ln|NumberThousandFixed}}
              </td>
              <td>
                t34
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10la|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                居家照護
              </td>
              <td>
                t35
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10rn|NumberThousandFixed}}
              </td>
              <td>
                t36
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ra|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                精神疾病社區復健
              </td>
              <td>
                t13
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10bn|NumberThousandFixed}}
              </td>
              <td>
                t14
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ba|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                總計
              </td>
              <td>
                t37
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10tn|NumberThousandFixed}}
              </td>
              <td>
                t38
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e10ta|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                部分負擔
              </td>
              <td>
                t39
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e109n|NumberThousandFixed}}
              </td>
              <td>
                t40
              </td>
              <td style="text-align: right; padding-right: 10px;">
                {{item?.H10?.e1098|NumberThousandFixed}}
              </td>
            </tr>
            <tr>
              <td colspan="2">
                本次連線申報起迄日期
              </td>
              <td>
                t41
              </td>
              <td>
                本次連線申報起日期 <br> {{item?.H10?.e10sd}}
              </td>
              <td>
                t42
              </td>
              <td>
                本次連線申報迄日期 <br> {{item?.H10?.e10ed}}

              </td>
            </tr>
            <tr>
              <td>
                注意事項
              </td>
              <td colspan="5" style="text-align: left;">
                一、使用本表免另行辦函，請填送一式二份。 <br>
                二、書面申報醫療費用者，應檢附本表及醫療服務點數清單暨醫令清單。 <br>
                三、媒體申報醫療費用者，僅需填本表及送媒體(磁片或磁帶)。 <br>
                四、連線申報醫療費用者，僅需填寫本表。 <br>
                五、(一)一般案件係指特約診所之日劑藥費申報案件（即案件分類：01、11、21）。 <br>
                <span style="padding-left: 37px;">(二)西醫專案案件範圍請參閱媒體申報格式之填表說明。</span> <br>
                六、本表各欄位請按照媒體申報格式之填表說明填寫。 <br>
              </td>
            </tr>
          </table>
        </div>
        <!-- <table style="margin-top: 5px;width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:25%">姓名：{{hist?.Patient.MiddleName}}</td>
                  <td style="width:12.5%">性別： {{hist?.Patient.SexName}}</td>
                  <td style="width:37.5%">身分證字號：{{hist?.Patient.CId |CIdMask:cidMask}}</td>
                  <td style="width:25%">出生日期：{{hist?.Patient.Birthday| formatROCDate:true:bitrhMask}} </td>
              </tr>
          </table>
          <table style="width: 100%;border-bottom: 0;">
              <tr>
                  <td style="width:12.5%">就醫科別：{{hist?.MedDeptCode}}</td>
                  <td style="width:25%">就醫日期： {{hist?.Register.RegDate | formatROCDate:true}}</td>
                  <td style="width:25%">健保卡就醫序號：{{hist?.Register.Icno}}</td>
                  <td style="width:12.5%">案類： {{hist?.Hist.Case}}</td>
                  <td style="width:25%">給藥日份：{{hist?.Hist.Days}}</td>
              </tr>
          </table>
          <table style="border-top: 0;width: 100%;">
              <tr>
                  <td colspan="7">
                      傷病名稱及主要症候：{{dxNames}}
                  </td>
                  <td rowspan="2" colspan="2">
                      國際疾病分類代碼：<br>
                      {{dxCodes}}
                  </td>
              </tr>
              <tr>
                  <td colspan="7">免部分負擔代碼及原因：{{hist?.Register.IType}} ITypeName
                  </td>
              </tr>
              <tr>
                  <td colspan="2">
                      藥品名稱及規格 (劑型、劑量)
                  </td>
                  <td colspan="5">
                      用法及用量
                  </td>
                  <td style="width:16%;">
                      <div style="width:50%;display: inline-block;">總數量</div>
                      <div style="width:50%;display: inline-block">金額小計</div>
                  </td>
                  <td style="width:9%">
                      備註
                  </td>
              </tr>
              <tr *ngFor="let hrx of hist.Hist.HRxs">
                  <td style="width:10%;border: 0;">
                      {{hrx.RxCode}}
                  </td>
                  <td style="width:30%;border: 0;border-right: 1px solid black;">
                      {{hrx.ProdName}}
                  </td>
                  <td style="width:7%;text-align: right; padding-right: 10px;border: 0;">
                      {{hrx.Dose| NumberFixed:2 }}/
                  </td>
                  <td style="width:7%;border: 0;">
                      {{hrx.Unit}}
                  </td>
                  <td style="width:7%;border: 0;">
                      {{hrx.Way}} {{hrx.Freq}}
                  </td>
                  <td style="width:7%;border: 0;"></td>
                  <td style="width:7%;border:0">
                      {{hrx.Days}}日
                  </td>
                  <td style="width:16%;border: 0;border-left: 1px solid black;">
                      <div style="width:50%;display: inline-block;">{{hrx.TotalDose}}</div>
                      <div style="width:50%;display: inline-block">{{hrx.Price*hrx.TotalDose}}</div>
                  </td>
                  <td style="width:9%;border: 0;border-left: 1px solid black;">
                      {{hrx.Remark}}
                  </td>
              </tr>
          </table>
          <table style="width: 100%;margin-top: 5px;">
              <tr>
                  <td style="width: 32.5%;border-bottom: 0;" rowspan="1">
                      診治醫生代號：{{hist.Hist.DoctorId}}<br>
                  </td>
                  <td style="width: 10%;">
                      處方醫院診所
                  </td>
                  <td style="width: 22.5%;padding-left: 20px;">
                      一般處方箋專用
                  </td>
                  <td colspan="2" style="width: 25%;">
                      慢性病連續處方箋專用 可調劑{{hist?.Hist.CntPresTot}}次
                  </td>
              </tr>
              <tr>
                  <td rowspan="2" style="border-top: 0;">
                      <br>
                      簽章：{{hist.Hist.DoctorId}}
                  </td>
                  <td style="width: 10%;">
                      核章
                  </td>
                  <td style="width: 22.5%;">
                      調劑藥師(生)代號：
                  </td>
                  <td rowspan="2" style="width: 12.5%;">
                      1 調劑日期1<br>
                  </td>
                  <td rowspan="2" style="width: 12.5%;">
                      3 調劑日期1<br>
                  </td>
              </tr>
              <tr>
                  <td rowspan="3">

                  </td>
                  <td rowspan="3">
                      簽章：<br>
                      調劑日期：<br>
                  </td>

              </tr>
              <tr>
                  <td>
                      聯絡電話：{{clinic.Phone}}
                  </td>
                  <td rowspan="2">
                      2 調劑日期2<br>
                  </td>
                  <td rowspan="2">
                      4 調劑日期4<br>
                  </td>
              </tr>
              <tr>
                  <td style="border-right: 1px solid;">傳真：{{clinic.Fax}}</td>
              </tr>
          </table> -->
      </div>
    </div>
  </div>
</div>
