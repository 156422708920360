import { Directive, HostListener, ElementRef, Input, Renderer2 } from '@angular/core';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperId } from '../models/helper-id';

@Directive({
  selector: '[appOrderGridInput]'
})
export class OrderGridInputDirective {

  constructor(private renderer: Renderer2, private elem: ElementRef,
    private helperService: HistHelperService) {
  }

  lastDataRowIndex = -1; // 最後一筆資料的rowIndex

  cellIdSep = '_';
  cellIdPrefix = HelperId.OdrGrid + this.cellIdSep;
  drugCodeIdPrefix = HelperId.OdrGridCode;
  // column
  firstColIdx = 1; // 第1個column的index
  lastColIdx = 13; // 最後1個column的index，可能大於最後一個Enter的column index
  skipColIdx = [3, 5, 10, 11]; // 這些column index需跳過不focus
  // row
  firstRowIdx = 0; // 第1個row的index
  lastRowIdx = 59; // 最後第1個row的index
  // enter
  enterFirstColIdx = 2; // 第1個Enter可作用的column的index -> Drug code
  @Input()
  enterLastColIdx = 9; // 最後第1個Enter可作用的column的index -> Way

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // 如果這時候 helper 視窗開啟，不要做任何動作，讓 helper 視窗接收按鍵
    if ( this.helperService && this.helperService.isWindowOpened() ) {
      return;
    }

    // get col and row index
    const id: string = this.elem.nativeElement.id;
    const idParts = this.parseId(id);
    if (idParts === null) {
      return;
    }
    const colIdx = parseInt(idParts[1], 10);
    const rowIdx = parseInt(idParts[2], 10);

    this.lastDataRowIndex = this.getLastDataRowIndex(rowIdx);

    // get next id by key
    let nextId = '';
    if (event.key === 'ArrowRight') {
      nextId = this.getLeftRightId(colIdx, rowIdx, true);
    } else if (event.key === 'ArrowLeft') {
      nextId = this.getLeftRightId(colIdx, rowIdx, false);
    } else if (event.key === 'ArrowUp') {
      nextId = this.getUpDownId(colIdx, rowIdx, true);
    } else if (event.key === 'ArrowDown') {
      nextId = this.getUpDownId(colIdx, rowIdx, false);
    } else if (event.key === 'Enter') {
      nextId = this.getNextEnterId(colIdx, rowIdx);
    } else {
      return;
    }
    if (!nextId) {
      return;
    }

    // focus next
    const nextElem = document.getElementById(nextId) as HTMLInputElement;
    if (nextElem == null) {
      return;
    }
    nextElem.focus();
    nextElem.select();
    event.preventDefault();
  }

  private parseId(id: string): string[] {
    if (!id || !id.startsWith(this.cellIdPrefix)) {
      return null;
    }
    const idParts = id.split(this.cellIdSep);
    if (idParts.length !== 3) {
      return null;
    }
    return idParts;
  }
  private getLastDataRowIndex(nowRowIdx: number): number {
    let idx = -1;
    for (let i = nowRowIdx; i < this.lastRowIdx ; i ++) {
      const thisRowDrugCodeId = this.drugCodeIdPrefix + i;
      const drugCode = (<HTMLInputElement>document.getElementById(thisRowDrugCodeId)).value;
      if (!drugCode) {
        // drug code為空的上一行是最後一行有資料的行數
        idx = i - 1;
        break;
      }
    }
    if (idx < 0) { idx = 0; }
    return idx;
  }

  //#region right left
  // 如果nextColIdx是要skip的欄位，就跳下一個
  private skipCol(nextColIdx: number, isRight: boolean): number {
    if (this.skipColIdx.indexOf(nextColIdx) > -1) {
      if (isRight) {
        nextColIdx ++;
      } else {
        nextColIdx --;
      }
      return this.skipCol(nextColIdx, isRight);
    }
    return nextColIdx;
  }
  private getLeftRightId(colIdx: number, rowIdx: number, isRight: boolean): string {
    let nextColIdx: number = colIdx;
    if (isRight) {
      nextColIdx ++;
    } else {
      nextColIdx --;
    }
    // skipCol只管往下一個跳，不管是否超出最前或最後一筆(col)，所以skip完要再檢查
    nextColIdx = this.skipCol(nextColIdx, isRight);
    // 保護不能超出表格
    if (nextColIdx < this.firstColIdx) {
      nextColIdx = colIdx;
    }
    if (nextColIdx > this.lastColIdx) {
      nextColIdx = colIdx;
    }
    return this.cellIdPrefix + nextColIdx + this.cellIdSep + rowIdx;
  }
  //#endregion

  //#region up down
  private getUpDownId(colIdx: number, rowIdx: number, isUp: boolean): string {
    let nextRowIdx: number = rowIdx;
    if (isUp) {
      // 到最上面時(row=0)，跳到最後一筆資料
      if (rowIdx === this.firstRowIdx &&  this.lastDataRowIndex > -1) {
        nextRowIdx = this.lastDataRowIndex;
      } else {
        nextRowIdx --;
      }
    } else {
      // this.lastDataRowIndex = -1時，代表沒資料
      // this.lastDataRowIndex + 1 代表最後一筆資料的下方(空白)格子，這時要回頭到第一筆
      if (rowIdx === (this.lastDataRowIndex + 1) && this.lastDataRowIndex > -1) {
        nextRowIdx = this.firstRowIdx;
      } else {
        nextRowIdx ++;
      }
    }
    // 保護不能超出表格
    if (nextRowIdx < this.firstRowIdx) {
      nextRowIdx = this.firstRowIdx;
    }
    if (nextRowIdx > this.lastRowIdx) {
      nextRowIdx = this.lastRowIdx;
    }
    return this.cellIdPrefix + colIdx + this.cellIdSep + nextRowIdx;
  }
  //#endregion

  //#region enter
  private getNextEnterId(colIdx: number, rowIdx: number): string {
    if (colIdx === this.enterLastColIdx || colIdx === this.lastColIdx) {
      // 是最後一個Enter欄位，或這一橫行最後一個欄位，就跳到下一行的第1個Enter欄位
      return this.getUpDownId(this.enterFirstColIdx, rowIdx, false);
    } else {
      if (rowIdx > this.lastDataRowIndex) {
        // 如果在最後一列資料的下面，也就是新的一列，不要移動
        return '';
      } else {
        // 其餘多數狀況，往右邊一格
        return this.getLeftRightId(colIdx, rowIdx, true);
      }
    }
  }
  //#endregion
}
