import { DRG001Params } from "../api-service/parameters/DRG001Params";
import { DRG002Params } from "../api-service/parameters/DRG002Params";
import { HST001Params } from "../api-service/parameters/HST001Params";
import { LAB001Params } from "../api-service/parameters/LAB001Params";
import { OPD001Params } from "../api-service/parameters/OPD001Params";
import { OPD002Params } from "../api-service/parameters/OPD002Params";
import { PAT001Params } from "../api-service/parameters/PAT001Params";
import { PAY001Params } from "../api-service/parameters/PAY001Params";
import { PRN001Params } from "../api-service/parameters/PRN001Params";
import { PRN002Params } from "../api-service/parameters/PRN002Params";
import { PRN003Params } from "../api-service/parameters/PRN003Params";
import { REG001Params } from "../api-service/parameters/REG001Params";
import { REG002Params } from "../api-service/parameters/REG002Params";
import { SCH001Params } from "../api-service/parameters/SCH001Params";
import { STK001Params } from "../api-service/parameters/STK001Params";
import { SYS101Params } from "../api-service/parameters/SYS101Params";

export enum ParamGroupEnum {
  /** 病患參數 */
  PAT001 = 1001,
  /** 掛號參數*/
  REG001 = 2001,
  REG002 = 2002,
  /** 排班參數 */
  SCH001 = 2100,

  /**藥品設定參數 */
  DRG001 = 3001,
  /** 處方參數 */
  DRG002 = 3002,

  /**門診醫令參數 */
  HST001 = 4001,

  /**支付參數 */
  PAY001 = 5001,

  /**檢驗參數 */
  LAB001 = 6001,

  /** 庫存參數 */
  STK001 = 7001,

  /**診斷參數 */

  OPD002 = 8001,

  /**健保相關參數 */
  OPD001 = 8002,
  /**列印參數 */
  PRN001 = 8101,
  /**列印參數 */
  PRN002 = 8102,
  /** 藥袋參數 */
  PRN003 = 8103,
  /**系統參數 */
  SYS101 = 9101
}

export type ParamType = keyof typeof ParamGroupEnum;
export type ParamObjType = ParamMap[keyof ParamMap]
export type ParamMapKey = keyof ParamMap;
export interface ParamMap {
  PAT001: PAT001Params;
  REG001: REG001Params;
  REG002: REG002Params;
  PAY001: PAY001Params;
  OPD001: OPD001Params;
  OPD002: OPD002Params;
  HST001: HST001Params;
  DRG001: DRG001Params;
  DRG002: DRG002Params;
  SCH001: SCH001Params;
  SYS101: SYS101Params;
  PRN001: PRN001Params;
  PRN002: PRN002Params;
  PRN003: PRN003Params;
  LAB001: LAB001Params;
  STK001: STK001Params
}

// 定義一個映射關係，從數值映射到字符串鍵
export const ParamGroupEnumKeyMap: { [key in ParamGroupEnum]: keyof ParamMap } = {
  [ParamGroupEnum.PAT001]: 'PAT001',
  [ParamGroupEnum.REG001]: 'REG001',
  [ParamGroupEnum.REG002]: 'REG002',
  [ParamGroupEnum.SCH001]: 'SCH001',
  [ParamGroupEnum.DRG001]: 'DRG001',
  [ParamGroupEnum.DRG002]: 'DRG002',
  [ParamGroupEnum.HST001]: 'HST001',
  [ParamGroupEnum.PAY001]: 'PAY001',
  [ParamGroupEnum.LAB001]: 'LAB001',
  [ParamGroupEnum.STK001]: 'STK001',
  [ParamGroupEnum.OPD001]: 'OPD001',
  [ParamGroupEnum.OPD002]: 'OPD002',
  [ParamGroupEnum.PRN001]: 'PRN001',
  [ParamGroupEnum.PRN002]: 'PRN002',
  [ParamGroupEnum.PRN003]: 'PRN003',
  [ParamGroupEnum.SYS101]: 'SYS101'
};
