import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';

export const VIS_MS_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VisMultiselectComponent),
  multi: true
};
@Component({
  selector: 'app-vis-multiselect',
  templateUrl: './vis-multiselect.component.html',
  styleUrls: ['./vis-multiselect.component.css'],
  providers: [VIS_MS_CONTROL_VALUE_ACCESSOR]
})
export class VisMultiselectComponent implements OnInit, ControlValueAccessor {
  @Input()
  data: { text: string, value: any }[];
  @Input()
  allowCustom: Boolean;
  @Input()
  filterable: Boolean;
  @Input()
  textField: string;
  @Input()
  valueField: string;
  @Input()
  valuePrimitive: Boolean;
  // @Output()
  // filterChange: EventEmitter<any> =new EventEmitter(false);

  @Output()
  valueChange: EventEmitter<any> = new EventEmitter(false);

  _currentValue: any;
  _onChange: (value) => {};
  _onTouched: () => {};
  _disabled: boolean;

  _class: string;
  constructor(private elementRef: ElementRef) {
  }

  ngAfterContentChecked() {

    this._class = this.elementRef.nativeElement.className;
  }



  writeValue(obj: any): void {
    if (obj) {
      this._currentValue = obj;
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
  }

  ngOnInit(): void {
  }


  _valueChange(evt: MatSelectChange) {
    this._currentValue = evt.value;
    if (this._onChange) {
      this._onChange(evt.value)
    }
    setTimeout(() => {
      if (this.valuePrimitive) {
        this.valueChange.emit(evt.value);
      } else {
        var choseArray = (evt.value as Array<any>)
        var seletcedItems = this.data.filter(d => choseArray.includes(d.value));
        this.valueChange.emit(seletcedItems);
      }
    },0)
  }
}
