import { APP_ID, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fail } from 'assert';
import { DailyReport, DailyReportIcodeSum, DailyReportItem } from 'src/app/payments/models/daily-report';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportDetailDTO, DeclareCaseReportPageDTO } from 'src/app/services/api-service/declare/declare-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { StartUpService } from 'src/app/services/start-up-service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import {NullOrEmpty } from 'src/app/shared/utilities';
import { ReportCancelReserveDetailDTO } from '../report-cancel-reserve/report-cancel-reserve.component';
import { HcrService } from 'src/app/services/hcr-service.service';

@Component({
  selector: 'app-report-rehabilitate',
  templateUrl: './report-rehabilitate.component.html',
  styles: [`
        @media only screen{
          .second-border-top-print{
            border-top:1px black solid
          }
          .page-shadow {
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
          }
          p {
          font-size: 14pt;
        }
        table {
          width: 100%; font-size: 14pt; text-align: center;
        }
        td {
          line-height: 22px;
          padding: 11px 0;
        }
        .border-solid {
            border: 1px solid;
        }
        .border-solid td {
            border-bottom: 1px solid;
            border-right: 1px solid;
        }
        .border-solid td:last-child {
            border-right: 0;
        }
        .border-solid td.border-right {
            border-right: 1px solid!important;
        }
        .border-solid tr:last-child td {
            border-bottom: 0;
        }
        .pres-report2 table td,
          table th {

            padding-left: 0px;
            padding-right: 0px;
          }
          .container {
            height: 100%;
            width: 100%;
          }
          .page {
            display: block;
            margin: 40px auto;
            box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
            box-sizing: border-box;
          }
          .page .content {
            overflow: auto;
            outline: 0;
          }
          .page .content .block {
            border: 1px solid rgba(0, 0, 0, 0);
            padding: 0.25rem;
            cursor: default;
          }
          .page .content .block:hover {
            border: 1px solid #dee2e6;
            border-radius: 0.25rem;
          }
          .page .content .block .title {
            font-weight: bold;
          }
          .page .content .block .title {
            cursor: text;
          }
          .page-shadow {
            box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
            box-sizing: border-box;
          }
        }
        `],'encapsulation':ViewEncapsulation.None
})
export class ReportRehabilitateComponent implements OnInit {
  sizePage = {
    width: 20, //cm
    height: 28.2 //cm
};
paddingPage = {
    top: 0.305, //cm
    right: 0, //cm
    bottom: 0.305, //cm
    left: 0 //cm
};

heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
);
elContainer;
anchorsBlockValue;
pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]
@Input() data;
@ViewChild('reportContainer', { static: false })
reportContainer: ElementRef<HTMLDivElement>;

showDoctorNameColume: boolean;
monthStr: string = '';
nowStr: string = '';
timeStr: string = '';
totalRecord: number = 0;
pageNo: number = 1;
_sDate: Date;
_eDate: Date;
doctorType:number;
dailyReport: DailyReport;
// TODO API
doctorReport = [];
// doctorReport:DeclareCaseReportDTO[] = [];
// 清單物件
listItems: DailyReportItem[] = [];
listIcodeSum : DailyReportIcodeSum[] = [];
// api服務
api: WebApiService;
// 查詢條件;
queryFormGroup: FormGroup;
doctorName:string = '';


constructor(private webApiClient: WebApiClient,
    private declareApi: DeclareApi,
    private startUp: StartUpService,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService:HcrService,
    private elementRef: ElementRef) {
        this.api = this.webApiClient.createHisService('Payments/Payment');
    }

  /** 是否列印 */
  @Input()
  set isPrint(value) {
    if (value) {
      // this.onPrintAll();
      this.onPrint();
    }
  }
  @Input()
  set isPreviewPrint(value) {
    console.log('value22',value);
    if (value) {
        // const query = {
        //     startDate: DateHelper.getIsoStringNoTimeZone(new Date()),
        //     endDate: DateHelper.getIsoStringNoTimeZone(new Date()),
        //     timeSec: '',
        //     clinicId: '',
        //     reportType: 'detail',
        // }
        this.fetchData();
    }
  }
  @Input() set startMonth(value) {
    if (value) {
      this._sDate = value;
    }
  }
  @Input() set endMonth(value) {
    if (value) {
      this._eDate = value;
    }
  }
  @Input() set startDate(value) {
    if (value) {
      this._sDate = value;
    }
  }
  @Input() set endDate(value) {
    if (value) {
      this._eDate = value;
    }
  }

  @Output() previewPrintDone = new EventEmitter();

  @Input()
  set isExportAsExcel(value) {
    if (value) {
      this.exportAsExcel();
    }
  }
  @Input()
  set toggleDoctor(value) {
    this.doctorType =value;
  }
  @Input() isShowTotal = false;
//   set isShowTotal(value) {
//   }
mockData:any[] = [
  {
    caseNo:1,
    cId:'C120888951',
    caseDays:11,
    caseCnt:30,
    casePoint:10000,
    limitPeople:450,
    caseLimits:30,
    minusCnt:20,
    minusPoint:600
  },
  {
    caseNo:2,
    cId:'A252369417',
    caseDays:21,
    caseCnt:55,
    casePoint:13000,
    limitPeople:450,
    caseLimits:55,
    minusCnt:44,
    minusPoint:770
  },
  {
    caseNo:3,
    cId:'A257572249',
    caseDays:12,
    caseCnt:33,
    casePoint:7000,
    limitPeople:450,
    caseLimits:33,
    minusCnt:33,
    minusPoint:620
  },
  {
    caseNo:4,
    cId:'C157910829',
    caseDays:18,
    caseCnt:20,
    casePoint:3000,
    limitPeople:450,
    caseLimits:20,
    minusCnt:5,
    minusPoint:200
  },
  {
    caseNo:5,
    cId:'F103640244',
    caseDays:10,
    caseCnt:12,
    casePoint:8000,
    limitPeople:450,
    caseLimits:12,
    minusCnt:7,
    minusPoint:300
  },
  {
    caseNo:6,
    cId:'L195104859',
    caseDays:21,
    caseCnt:10,
    casePoint:2000,
    limitPeople:450,
    caseLimits:10,
    minusCnt:10,
    minusPoint:330
  },
  {
    caseNo:7,
    cId:'D199629502',
    caseDays:19,
    caseCnt:38,
    casePoint:9000,
    limitPeople:450,
    caseLimits:38,
    minusCnt:18,
    minusPoint:800
  }
]
ngOnInit(): void {
    if (this.startUp.userData && this.startUp.userData.name) {
      this.doctorName = this.startUp.userData.name;
    }
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.mb-2mm {margin-bottom:2mm}\n' +
      '.page-shadow {box-shadow:none;box-sizing: border-box;}\n' +
      '.border-solid {border: 1px solid;}\n' +
      '.border-solid td {border-bottom: 1px solid;border-right: 1px solid;}\n' +
      '.border-solid td:last-child {border-right: 0;}\n' +
      '.border-solid tr:last-child td {border-bottom: 0;}\n' +
      '.border-solid.border-bottom-0{ border-bottom: 0}\n' +
      '.second-border-top-print{border-top:0}\n' +
      '.m-t-1mm-print { margin-top:1mm; }\n' +
      '.container {height: 100%;width: 100%;}\n' +
      '.page {display: block;margin: 0 auto;box-shadow: 0 0 0cm rgba(0, 0, 0, 0.5);box-sizing: border-box;}\n' +
      '.page .content {overflow: auto;outline: 0;}\n' +
      '.page .content .block {border: 1px solid rgba(0, 0, 0, 0);padding: 0.25rem;cursor: default;}\n' +
      '.page .content .block:hover {border: 1px solid #dee2e6;border-radius: 0.25rem;}\n' +
      '.page .content .block .title {font-weight: bold}\n' +
      '.page .content .block .title {cursor: text;}\n' +
      '.width-quarter-print {min-width: 68mm;}\n' +
      '.border-right-0 {border-right: 0}\n' +
      '.border-left-0 {border-left: 0}\n' +
      '.a4-print-style {width: 210mm;height: 297mm;border: 1px solid #474647;background: white;}\n' +
      '.a4-print-style-h {width: 307mm;height: 210mm;border: 1px solid #474647;background: white;max-width: 950px;}\n' +
      '.h-auto {height: auto}\n' +
      '.m-auto {margin: auto}\n' +
      '.start-0 {left: 0;}\n' +
      '.end-0 {right: 0;}\n' +
      '.pl-2, .px-2 {padding-left: 1mm}\n' +
      '.pr-2, .px-2 {padding-right: 1mm}\n' +
      '.m-0 {margin: 0}\n' +
      '.d-block {display: block}\n' +
      '.bg-white {background-color: #fff}\n' +
      '.font-weight-bolder {font-weight: bolder}\n' +
      '.text-center {text-align: center}\n' +
      '.mt-2, .my-2 {margin-top: 1mm}\n' +
      '.w-100-print {min-width: 100%}\n' +
      '.text-lg {font-size: 1.125rem }\n' +
      '.text-right {text-align: right}\n' +
      '.text-left {text-align: left}\n' +
      '@page { size: A4; margin: 0.45cm 0.45cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
  ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
}

ngAfterViewInit() {

}



// 取得統計期間的看診人次資料，並組出報表的html內容
async fetchData() {
    this.elContainer = document.getElementById("container");
    console.log('res', this.isShowTotal);

    // TODO API
    // this.doctorReport = await this.declareApi.GetDoctorRoomcodeDeclareReport(UserCache.getLoginClinicId(), this._sDate, this._eDate, this.doctorType,this.isShowTotal);
    this.doctorReport = [
      {Month: '11203',
       e11cs: '',
       Detail: [
          {
            PatientNo:'0000190',
            RegDate:'112/11/10',
            RoomCode:'1',
            TimeSec:'上午',
            BookingCode:'0001',
            TDUTY:'櫃檯掛號掛入',
            DeleteDate:'112/11/10',
            DeleteTime:'112/11/10',
            ComputerName:'ILoveVision'
          },
          {
            PatientNo:'0000225',
            RegDate:'112/11/10',
            RoomCode:'1',
            TimeSec:'上午',
            BookingCode:'0002',
            TDUTY:'門診候診掛入',
            DeleteDate:'112/11/10',
            DeleteTime:'112/11/10',
            ComputerName:'MoneyBoard'
          },
        ],
      Subtotal: []
    }
    ];
    if(this.doctorReport.length > 0){
        this.insertListData();
    }
    else
    {
        this.notification.showInfo('查無資料可供列印!');
        this.previewPrintDone.emit();

    }


}

// 組報表的html內容
insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var isSum = false;
    var nowsearch = new Date();
    this.nowStr = this.onGetDateString(new Date(), true) + ' ' + this.onGetTimeString(new Date());
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = '';     // 頁表頭
    // var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;

    var elTableContent = document.getElementById("table-" + iPage + "-content");
    for (let i = 0; i < this.doctorReport.length; i++) {
        // 加入 List 每一筆的內容
        var html_ListerHeader = this.createEndCount(this.doctorReport[0]?.Detail);   // List欄位顯示名稱
        elTableContent.innerHTML += html_ListerHeader;

        let doctorDetail = this.doctorReport[i].Detail;
        // console.log('doctorDetail',doctorDetail);
        // console.log('1233',elTableContent.innerHTML);




        doctorDetail = [...doctorDetail, this.doctorReport[i].Subtotal];
        // console.log('doctorDetail',i);
        if(doctorDetail.length > 0){
            // console.log('doctorDetail',doctorDetail);
            for (let  j= 0; j < doctorDetail.length; j++) {
                html_Block ='';
                elTableContent.innerHTML += html_Block;
                var prevTableContent = elTableContent.innerHTML;

        // 判斷是否超出頁面範圍? -> 新增一頁
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
            elTableContent.innerHTML = prevTableContent;
            iPage += 1;
            this.elContainer.innerHTML += this.createHTMLPage(iPage);
            elPageContent = document.getElementById("page-" + iPage + "-content");
            // [頁表頭][List欄位顯示名稱]
            html_header = '';
            // html_header = this.createHTMLPageNoHeader(iPage);
            elPageContent.innerHTML += html_header;
            elTableContent = document.getElementById("table-" + iPage + "-content");
            elTableContent.innerHTML = html_ListerHeader;

            this.pageContent[iPage] = [];
            this.pageContent[iPage].push(iBlock);
        } else {
            this.pageContent[iPage].push(iBlock);
        }
        iBlock += 1;
            }
        }


    }
    // elPageContent.innerHTML +=  html_finish;
    this.previewPrintDone.emit();
}

convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
}

convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
}

createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
}


stringFactory(input:string) :string{
    return !NullOrEmpty(input)?input.trimRight() :' ';
}
numberFactory(input:number):string{
    let result = '';
    if(!isNaN(input)){
        result = input > 0 ? input.toString():'0';
    }
    let paddedString = this.padToLength(result, 4, " ");
    return paddedString;
}
padToLength(inputString: string, targetLength: number, paddingChar: string): string {
    const paddingLength = targetLength - inputString.length;
    if (paddingLength > 0) {
        return inputString + paddingChar.repeat(paddingLength);
    } else {
        return inputString;
    }
}

stringBFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length >= 5 ? str.slice(0, 5) :str;
    return inputSlice;
}
stringCFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length > 7 ? str.slice(-7):str;
    return inputSlice;
}
stringTimeZone(input:string) :string{
    let result = !NullOrEmpty(input)?input.trimRight() :' ';
    switch(input){
        case'早上':
        result = '早';
        break;
        case'下午':
        result = '午';
        break;
        case'晚上':
        result = '晚';
        break;
        default:
        result = ' ';
        break;
    }
    return result;
}



createEndCount(data: ReportCancelReserveDetailDTO) {
  return '<tr>' +
          '<td colspan="4" style="text-align: left; font-size: 14pt;' + ' border-bottom: none;">'+ '列印日期：' + this.nowStr +'</td>' +
           '<td colspan="5" style="text-align: right; font-size: 14pt;' + ' border-bottom: none;">'+ '列印醫師：' + this.doctorName +'</td>' +
        '</tr>' ;
}

createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
}
createNoDetailFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">查無資料</label>
            </div>`;
}
createHTMLTotal() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;"></label>
            </div>`;
}
getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
}

async onPrint() {
    console.log('復健治療項目專案申報表');
    if (this.listItems.length == 0) {
        var reportName = '復健治療項目專案申報表';
        const printContents = this.getHtml();
        var ret = await this.localPrintService.printHtml(444, '復健治療項目專案申報表', printContents);
        if (ret.Successed) {
            this.notification.showSuccess(reportName + '已送出列印!', true);
        } else {
            this.notification.showError(reportName + '送出列印失敗!');
        }
    } else {
        this.notification.showInfo('查無資料可供列印!');

    }
    this.previewPrintDone.emit();

}

async exportAsExcel() {
    if (this.doctorReport.length > 0) {
      var reportName = '復健治療項目專案申報表';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)

        // const uri = 'data:application/vnd.ms-excel;base64,';
        // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        //                     <head>
        //                         <!--[if gte mso 9]>
        //                         <xml>
        //                             <x:ExcelWorkbook>
        //                                 <x:ExcelWorksheets>
        //                                     <x:ExcelWorksheet>
        //                                         <x:Name>{worksheet}</x:Name>
        //                                         <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        //                                     </x:ExcelWorksheet>
        //                                 </x:ExcelWorksheets>
        //                             </x:ExcelWorkbook>
        //                         </xml>
        //                         <![endif]-->
        //                     </head>
        //                     <body>
        //                         <table>{table}</table>
        //                     </body>
        //                     </html>`;
        // const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        // const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        // const table = this.reportContainer.nativeElement;
        // const ctx = { worksheet: '復健治療項目專案申報表', table: table.innerHTML };

        // var fileName =`復健治療項目專案申報表` + this.onGetDateString(new Date(), false, '', false) + this.onGetTimeString(new Date(), '') + `.xls`;
        // var content = format(template, ctx);
        // var ret =await this.hcrService.SaveTextAsZipIfPwd(`復健治療項目專案申報表`+'\\'+fileName,content)
        // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);

        // const link = document.createElement('a');
        // link.download = `復健治療項目專案申報表` + this.onGetDateString(new Date(), false, '', false) + this.onGetTimeString(new Date(), '') + `.xls`;
        // link.href = uri + base64(format(template, ctx));
        // link.click();
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
}

/** 轉換Date To String
 *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
 */
onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (isMonth) {
            return [year, month].join(symbol);
        } else {
            return [year, month, day].join(symbol);
        }
    }
}

/** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
*/
onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
        var d = new Date(ev);
        // 趕時間先簡單寫
        let hr = d.getHours().toString();
        if (d.getHours() < 10) {
            hr = '0' + hr;
        }
        let min = d.getMinutes().toString();
        if (d.getMinutes() < 10) {
            min = '0' + min;
        }
        let sec = d.getSeconds().toString();
        if (d.getSeconds() < 10) {
            sec = '0' + sec;
        }
        var timeStr = [hr, min, sec].join(timeSymbol);
        return timeStr;
    }
}

onGetDate(ev) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [month, day].join('');
    }
  }

getCases():number {
  let allCase = this.mockData.reduce((total, data) => total + data.caseLimits, 0);
  return allCase;
}
}
