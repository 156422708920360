import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EasyNotificationService } from '../../easy-notification.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private globals: { [key:string]: any } = {
    ongoing_request_count: 0,
    loading_animation_control: new Subject<any>(),
    banner_control: new Subject<any>()
  };

  constructor(
    private http: HttpClient,
    private notification:EasyNotificationService
  ) { }

  error_messages = {
    // service_failure: 'Our apologies, this site is experiencing some technical difficulties. Please refresh the page or try again.',
    service_failure: '抱歉，網站遇到一些技術困難。請重新整理頁面或重試。',
    validation: {
      // percentage: 'Enter a percentage with max 2 decimal places.'
      percentage: '輸入最多保留 2 位小數的百分比。'
    }
  };

  serviceWrapper(
    HTTP_method: string,
    API_URL: string,
    responseProcessing: any,
    request_data?: any,
    skip_loading_animation?: string
  ): Subject<any> {

    let response_subject = new Subject<any>();

    // If it has not been explicitly mentioned to not show the loader, please show.
    if(!!!skip_loading_animation){
      this.globals.ongoing_request_count ++;
      this.globals.loading_animation_control.next(true);
    }

    // Hide snackbars and banners if any
    //this.hideSnackbar();
    this.hideBanner();

    // For local API requests, fetch the JSON file instead
    //if(!environment.production || environment.dummy_JSONs)
    if(!environment.production)
    {
      HTTP_method = 'GET';
      API_URL += '.json';
    }

    this.http.request(
      HTTP_method,
      API_URL,
      request_data
    ).pipe(
      finalize(
        () => {
          if(!!!skip_loading_animation){
            if(this.globals.ongoing_request_count > 0){
              this.globals.ongoing_request_count --;
            }
            // Hiding the loading animation
            this.globals.loading_animation_control.next(false);
          }
        }
      )
    ).subscribe(
      (response: any) => {
        // If this is an error object directly send it across
        if(!!response['errorCode']){
          response_subject.error(response);
        }else{
          let processed_response = responseProcessing(response);
          if(!!processed_response.error){
            response_subject.error(processed_response.error);
          }else{
            response_subject.next(processed_response.data);
          }
        }
      },
      (error) => {
        let error_object = {
          'message' : this.error_messages.service_failure
        };
        response_subject.error(error_object);
      }
    );

    return response_subject;
  }

  uploadFile(form_data: any, API: string){
    return this.serviceWrapper(
      'POST',
      API,
      (response: any)=>{
        return {'data': { 'message': response.uploadStatus}};
      },
      {
        body: form_data
      },
      'skip_loader_animation'
    );
  }

  // displayAlertDialog(options?: any){
  //   let global_options = {
  //     autoFocus: false,
  //     panelClass: 'modhyobitto-dialog-container',
  //     scrollStrategy: this.overlay.scrollStrategies.noop()
  //   };
  //   let dialog_config = {...global_options, ...options};
  //   let dialog_ref = this.dialog.open(
  //     ModhyobittoDialogComponent,
  //     dialog_config
  //   );
  //   return dialog_ref;
  // }

  scrollToElement(element_ref: any, offset = 10){
    setTimeout(()=>{
      let is_selector = (typeof element_ref) == 'string';
      let element = (is_selector)? document.querySelector(element_ref) : element_ref;
      // let scroll_extent = element.getBoundingClientRect().top + window.pageYOffset - offset;
      let scroll_extent = element.getBoundingClientRect().top - offset;
      window.scrollTo(0, scroll_extent);
    }, 200);
  }

  unsubscribeAll(subs: Subscription[]){
    let sub_count = subs.length;
    for(let i=0; i < sub_count; i++){
      let current_sub = subs[i];
      if(!!current_sub){
        current_sub.unsubscribe();
      }
    }
  }

  settings = {
    API_full_hostname : document.location.origin+'/assets/data/'
  };

  service_URLs: { [key:string]: string } = {
    'login': 'loginservice',
    'file_download': 'file_download',
    'file_upload': 'file_upload',
    'table_row_add': 'table_row_add',
    'table_row_edit': 'table_row_edit'
  };

  getAPI(key: string){
    let complete_URL = this.settings.API_full_hostname+this.service_URLs[key];
    return complete_URL;
  }

  // showSnackbar(message?: string){
  //   setTimeout(()=>{
  //     let snackar_ref = this.snackbar.open(
  //       message || this.error_messages.service_failure,
  //       'OK',
  //       {panelClass: 'modhyobitto-snackbar'});
  //     this.setGlobalData('global_snackbar',snackar_ref);
  //   },1200);
  // }

  // setGlobalData(key: string, value: any){
  //   this.globals[key] = value;
  // }

  // getGlobalData(key: string){
  //   return this.globals[key];
  // }

  // hideSnackbar(){
  //   this.getGlobalData('global_snackbar')?.dismiss();
  // }

  hideBanner(){
    let options = {
      to_show: false
    };
    this.globals.banner_control.next(options);
  }

  downloadAs(content:string,fileName:string){
    let blob = new Blob([content], { type: 'text/plan' });
    //建a    
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download =  fileName;
    //點a    
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
  async upload(...ext:string[]){
    return await new Promise<string>((rs,rj)=>{
      var fileSelect = document.createElement('input');
      fileSelect.type = 'file';
      fileSelect.onchange = async (event:any)=>{
          const file:File = event.target.files[0];
          if(ext?.length>0 && !ext.some(x=>file.name.endsWith('.'+x))){
            //this.notification.showWarning('請選擇副檔名為.json的檔案');
            rj('請選擇副檔名為'+ext.map(x=>'.'+x).join(', ')+'的檔案')
            return;
          }
          var t = await file.text();
          rs(t);
      }
      fileSelect.click();
    });
  }
}
