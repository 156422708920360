import { Injectable } from "@angular/core";
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { Position } from "src/app/system/position/models/position";
import { User } from "src/app/system/user/models/user";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { PageData2 } from "src/app/shared/models/page-data";
import { CustomResult } from "src/app/shared/models/custom-result";

export class FullUser {
  name: string;
  ename: string;
  account: string
  roles: ValueTextPair[];
  positions: ValueTextPair[];
  clinics: ValueTextPair[];
  needChangePwd: boolean;
  prefs: UserPreference;
}
export class UserPreference {
  UserId: number;
  HistCC: string[];
  HistPE: string[];
  HistDx: string[];
  HistRx: string[];
  HistEx: string[];
  Menu: string[];
  HistDxFreq: 'Week' | 'Month' | 'Season';
  HistDxSrc: 'Self' | 'Clinic';
  HistRxFreq: 'Week' | 'Month' | 'Season' | 'Dx';
  HistRxSrc: 'Self' | 'Clinic';
  HistCCPEFreq: 'Week' | 'Month' | 'Season' | 'Dx';
  HistCCPESrc: 'Self' | 'Clinic';
  HistCCPECode: boolean;
  HistPageSize: number;
  RecentFromDate: Date;
  FontGreat: boolean;
  HistPadHide: boolean;
  CustomHelpPadTab: string;
}
@Injectable({ providedIn: 'root' })
export class UserApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = this.apiClient.createHisService('system/user');
    this.apiService.enableLoader = false;
  }
  async get(id: number): Promise<User> {
    return this.apiService.get('get/' + id).toPromise();
  }
  async getOptions(): Promise<CustomResult> {
    return this.apiService.get('GetOptions').toPromise();
  }
  async getClinicAllUserOption(): Promise<UserListDto[]> {
    return this.apiService.get('GetClinicAllUserOption').toPromise<UserListDto[]>();
  }
  async getFullUser(id: any): Promise<FullUser> {
    return this.apiService.get('GetFullUser?id=' + id).toPromise<FullUser>();
  }
  async changePwd(oldPwd: string, newPwd: string): Promise<void> {
    return this.apiService.put('changepassword?' + 'oldpassword=' + oldPwd + '&newpassword=' + newPwd, {}).toPromise()
  }
  async UpdatePrefs(model: UserPreference): Promise<void> {
    return this.apiService.post('UpdatePrefs', model).toPromise()
  }
  async getPositions(id: number): Promise<void> {
    return this.apiService.get('get/' + id).toPromise()
  }
  async getAll(name: string, account: string, cid: string, positionId: number[]): Promise<PageData2<UserListDto>> {
    return this.apiService.get('GetAll', {
      name, account, cid, positionId: positionId.join(',')
    }).toPromise()
  }
  async getCompanyAll(q: UserListQuery): Promise<PageData2<UserListDto>> {
    return this.apiService.get('GetCompanyAll', q).toPromise()
  }
  async getCompanyUserPositions(): Promise<ValueTextPair[]> {
    return this.apiService.get('GetCompanyUserPositions').toPromise()
  }
  async login() {
    await this.apiService.post('Login', null).toPromise();
  }
  async GetDoctors(): Promise<ValueTextPairNumberValue[]> {
    return this.apiService.get('GetDoctors').toPromise()
  }
  async GetPharmarcist(): Promise<ValueTextPair[]> {
    return this.apiService.get('GetPharmarcist').toPromise()
  }
  async GetDoctorAndNurse(isShowNurse: boolean, isShowLeave: boolean, date = null): Promise<ValueTextPairNumberValue[]> {
    return this.apiService.get('GetDoctorAndNurse?' + 'isShowNurse=' + isShowNurse + '&isShowLeave=' + isShowLeave + '&date=' + date).toPromise()
  }
  async GetDoctorList():Promise<ValueTextPairNumberValue[]>{
    return this.apiService.get('GetDoctorList').toPromise()
}
}
export declare type UserListQuery = {
  account: string,
  name: string,
  cid: string,
  positionId: string;
  pageSkip: number;
  pageSize: number;
}
export type UserListDto = {
  Id: number,
  Account: string,
  Name: string,
  IsDoctor: number,
  Positions: string
}