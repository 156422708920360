export class HistRecordPanel {
  readonly status_max = 'max';
  readonly status_min = 'min';
  readonly status_default = 'default';

  status = this.status_default;
  defaultSize = '';

  constructor(defaultSize?: string) {
    this.defaultSize = defaultSize;
    this.status = this.status_default;
  }
  setMax() {
    this.status = this.status_max;
  }
  setMin() {
    this.status = this.status_min;
  }
  setDefault() {
    this.status = this.status_default;
  }
  get isMax() {
    if (this.status === this.status_max) {
      return true;
    } else {
      return false;
    }
  }
  get isMin() {
    if (this.status === this.status_min) {
      return true;
    } else {
      return false;
    }
  }
  get isDefault() {
    if (this.status === this.status_default) {
      return true;
    } else {
      return false;
    }
  }
  get displayStyle() {
    if (this.isMin) {
      return 'none';
    } else {
      return 'block';
    }
  }
  get heightStyle() {
    if (this.isMax) {
      return '100%';
    } else {
      return this.defaultSize;
    }
  }
  get widthStyle(){
    if (this.isMax) {
      return '100%';
    } else {
      return this.defaultSize;
    }
  }
}
