<app-vis-dialog title="選擇項目" [show]="isDialogOpened" (close)="onDialogClose(false)"
  [width]="winWidth" [height]="winHeight" i18n-title="@@SelectOptions">
  <div style="display: flex;flex-direction: column;height: 430px;" *ngIf="this.allItems">
    <div style="width: 100%;display: flex;" class="py-2 pr-2">
      <div style="flex-grow: 1;"> <!-- "codeTypeList[0].value" -->
        <mat-button-toggle-group class="ml-1"
          [value]="currentTp"
          (change)="changeCodeType($event)">
          <ng-container *ngFor="let code of codeTypeList;">
            <mat-button-toggle class="patient-remark w-24"
              [value]="code.value">
              {{code.text}}
            </mat-button-toggle>
          </ng-container>
        </mat-button-toggle-group>
      </div>
      <button type="button"
        (click)="onDialogClose(true)"
        i18n="@@BringIn"
        class="vis-btn-config p-0 position-relative pl-2 w-18"
        title="帶入">
        <mat-icon class="pref-icon vis-combine-icon">arrow_circle_down</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">帶入</span>
      </button>
      <button type="button"
        (click)="onDialogClose(false)"
        i18n="@@BringIn"
        class="vis-btn-config p-0 position-relative pl-2 w-18"
        title="關閉">
        <mat-icon class="pref-icon vis-combine-icon">cancel</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
      </button>
    </div>
    <div>
      <app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkip"
        [pageable]="true" [selectable]="true" [multiselect]="true" [selectByCheckOnly]="true"
        [kendoGridSelectBy]="'value'" (pageChange)="pageChange($event)" #grid >
        <app-vis-grid-checkbox-column (checkChanged)="checkChanged($event)" [field]="'checked'" showSelectAll="true" [width]="30"></app-vis-grid-checkbox-column>
        <app-vis-grid-column field="text" title="名稱" i18n-title="@@Name"></app-vis-grid-column>
      </app-vis-grid>
    </div>
    <div class="p-2" style="white-space: pre-wrap;overflow-y: auto;flex-grow: 1;">
      <div class="extend-msg">{{result()}}</div>
    </div>
  </div>
</app-vis-dialog>

