import { Injectable } from "@angular/core";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { NhiimiSetting } from "src/app/system/clinic/models/nhiimiSetting";
import { Role } from "src/app/permission/role/models/IRole";
import { ConsoleInfo } from "../../hcr-service.service";
import { Announcement, AnnouncementGroup, AnnouncementListDto } from "./announcement-model";

@Injectable({providedIn:'root'})
export class AnnouncementApi{
    apiService: WebApiService;
    /**
     *
     */
    constructor(private apiClient: WebApiClient) {
        this.apiService = apiClient.createHisService('system/Announcement');
    }
    async Get(id:number): Promise<Announcement>{
        var ret = await this.apiService.get('Get/'+id).toPromise<Announcement>();
        return ret;
    }
    async Create(model :Announcement,fileIds:number[],dataScope:number): Promise<CustomResult>{
        return this.apiService.post('Create',{
            Announcement:model,
            FileIds:fileIds,
            DataScope:dataScope
        }).toPromise();
    }

    async Update(model :Announcement): Promise<CustomResult>{
        return this.apiService.post('Update',model).toPromise();
    }

    async getAllClinic(): Promise<Clinic[]>{
        return this.apiService.get('GetAllClinic').toPromise<Clinic[]>()
    }

    async getGroups(): Promise<AnnouncementGroup[]>{
        return this.apiService.get('GetGroups').toPromise<AnnouncementGroup[]>()
    }
    async createGroups(model :AnnouncementGroup): Promise<CustomResult>{
        return this.apiService.post('CreateGroups',model).toPromise();
    }

    async updateGroups(model :AnnouncementGroup): Promise<CustomResult>{
        return this.apiService.post('UpdateGroups',model).toPromise();
    }
    async removeGroups(model :AnnouncementGroup): Promise<CustomResult>{
        return this.apiService.post('RemoveGroups',model).toPromise();
    }
    
    async getSysAlert(): Promise<AnnouncementListDto[]>{
        return this.apiService.get('GetSysAlert').toPromise<AnnouncementListDto[]>()
    }
}