import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
// shared module
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { GridData } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';
import { LabFormTable } from '../../models/labForm-list';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { LAB001Params } from 'src/app/services/api-service/parameters/LAB001Params';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-labs-report-query-dialog-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit, OnDestroy {

  constructor(
    private webApiClient: WebApiClient,
    private notification: EasyNotificationService,
    private clinicData:ClinicDataService,
  ) {
    this.api = this.webApiClient.createHisService('labs/reportQueryTable');
    this.apiReportQuery = this.webApiClient.createHisService('labs/reportQuery');
    this.paramChangeSub = clinicData.onParamChanged.subscribe(x=>{
      if(x.some(y=>y=='LAB001')){
        // 重設定顏色
        this.getTestValueColors(this.displayView.data).then(()=>{
          //重畫
          this.redrawTable();
        });
      }
      
    })
  }
  paramChangeSub:Subscription;

  //#region 變數 --------------------

  getInspItemsSuccess = new EventEmitter();
  // api服務
  api: WebApiService;
  apiReportQuery: WebApiService;

  inspItems = [];
  //#endregion --------------------

  noPatientName = '';
  reportContents = {
    xTitles: [],
    rows: [],
  };

  testValueColors:LAB001Params;
  gridData:GridData;
  // 
  displayView:GridData = {data:[],total:0};
  labItems:ValueTextPair[]=[];
  @Input() set data(v:GridData){
    this.gridData = v;
    if (this.gridData?.data?.length > 0) {
      var labItems = []
      var displayData = []
      // 取得所有檢驗項目名稱
      this.gridData.data.forEach((x:LabFormTable)=>{
        for(let itemCode in x.Columns){
          var itemName = x.Columns[itemCode];
          if(!labItems.some(z=>z.value == itemCode)){
            labItems.push({value:itemCode,text:itemName,extension:true});
          }  
        }
      })
      //資料轉置
      this.gridData.data.forEach((x:LabFormTable)=>{
        var d:any = { RegDate:x.RegDate};
        for(let itemCode in x.Values){
          var v = x.Values[itemCode];
          v['Color'] = this.GetTestValueColor(v.Result as any)||'black';
          d[itemCode] = v;// `${v.Value} (${v.Result})`;
        }       
        for(let labItem of labItems){
          if(!d[labItem.value]){
            d[labItem.value] = {Value:'',Result:'',Color:''};
          }
        }
        displayData.push(d);
      })
      
      this.labItems = [];
      this.displayView = null;
      this.getTestValueColors(displayData).then(()=>{
        this.labItems = labItems;
        this.displayView = {data:displayData,total:displayData.length};  
      });
      
    }
  }
  async ngOnInit() {
    
  }
  ngOnDestroy(): void {
    this.paramChangeSub.unsubscribe();
  }
  async getTestValueColors(displayData:any[]) {
    try {
      this.testValueColors = await this.clinicData.getParam('LAB001');
      if(displayData){
        displayData.forEach(x=>{
          for(var itemCode in x){
            if(x[itemCode].Result){
              x[itemCode].Color = this.GetTestValueColor(x[itemCode].Result)||'black';
            }
          }
        });
      }
    } catch (err) {
      this.notification.showGetDataFailed('report-query-dialog-table:getTestValueColors');
    }
  }
  /** 
   * N:正常 A:異常 D:危險 L:偏低 M:逾最低異常值 H:偏高 I:逾最高異常值
   */
  GetTestValueColor(testResult:'N'|'A'|'D'|'L'|'M'|'H'|'I') {
    if(!this.testValueColors){
      return 'black';
    }
    switch (testResult) {
      case 'N':
        return this.testValueColors.ValueColorNormal;
      case 'A':
        return this.testValueColors.ValueColorAbnormal;
      case 'D':
        return this.testValueColors.ValueColorDanger;
      case 'L':
        return this.testValueColors.ValueColorLow;
      case 'M':
        return this.testValueColors.ValueColorDangerLow;        
      case 'H':
        return this.testValueColors.ValueColorHigh;
      case 'I':
        return this.testValueColors.ValueColorDangerHigh;        
      default:
        return 'black'
    }
  }

  checkChange(col:ValueTextPair, check:MatCheckboxChange){
    col.extension = check.checked;
  }
  clearDisplayCol(){
    this.labItems.forEach(x=>x.extension = false);
  }
  allDisplayCol(){
    this.labItems.forEach(x=>x.extension = true);
  }
  updateDisplayCol(){
    this.redrawTable();
  }
  redrawTable(){
    var dv = this.displayView;
    this.displayView = null;
    // grid的動態col異動時會先新增在移除，新增的時候會出現col name duplicate，所以等一偵先讓其清空後再設定
    setTimeout(() => {
      this.displayView = {data:dv.data,total:dv.total};
    }, 0);
  }

}
