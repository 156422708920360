import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter, AfterViewInit, HostListener } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEvent } from '../models/helper-event';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperPadEvent, HistHelperPadService, ItemSelectedEvent, AreaSelectedEvent, SetItemEvent } from '../services/hist-helper-pad.service';
import { HelperEventTypeEnum } from '../enums/helper-event-type-enum';
import { HistFocusDirective } from '../directives/hist-focus.directive';
import { HistService } from '../services/hist.service';
import { HelperId } from '../models/helper-id';

@Component({
  selector: 'app-hist-helper-textarea',
  templateUrl: './helper-textarea.component.html',
  styleUrls: ['./helper-textarea.component.css', '../styles/hist.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelperTextareaComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(public fb: FormBuilder,
    public helperService: HistHelperService,
    public padService: HistHelperPadService,
    protected histService:HistService,
    public cd: ChangeDetectorRef) { }

  //#region 參數 main --------------------
  private unsubscribe: Subject<void> = new Subject();

  editFG: FormGroup = this.fb.group({});
  get editFV() {
    return this.editFG.value;
  }
  //#endregion

  //#region 參數 input ui --------------------
  @ViewChild(HistFocusDirective, { static: false })
  private helperTextarea: HistFocusDirective;

  // 請給以下3個參數
  areaId = ''; // 請定義於HelperId: 這個area的Id -> by areaId, 用來決定目前哪個區塊是得到focus
  elemId = ''; // 請定義於HelperId: 這個textarea的Id -> 一些資料操作
  sourceType: HelperSourceEnum = HelperSourceEnum.NA; // 這個textarea有異動發出通知時，告訴接收者我是哪一種HelperSourceEnum

  @Input() isFocusAfterInit: boolean;

  // @Input() disabled: boolean = false;
  private _disabled = false;
  @Input()
  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabled();
  }

  private _content = '';
  @Input()
  set content(content: string) {
    // console.log('set content:[' + content + ']');
    this._content = content;
    this.fillFormData();
    this.onLoaded();
  }

  get content() {
    return this.editFV.content;
  }
  get seperator(){
    return this.histService.SentenceWordDelimiter;
  }
  //#endregion
  compositionSt:'start'|'end'='end';
  ngOnInit() {
    this.subscribe_helper();
    this.subscribe_helperPad();
    this.editFG = this.makeFormGroup();
    this.fillFormData();
    this.padService.setRecord(this.elemId,[]);
    setTimeout(() => {
      this.element.addEventListener('drop',(evt)=>{
        this.onDrop(evt);
        return true;
      });
      this.element.addEventListener('dragstart',(evt)=>{
        this.onDrag(evt);
        return true;
      });
      this.element.addEventListener('compositionstart',(evt)=>{
        //console.log('compositionstart',evt);
        this.compositionSt = 'start';
        return true;
      });
      this.element.addEventListener('compositionupdate',(evt)=>{
        //console.log('compositionupdate',evt);
        return true;
      });
      this.element.addEventListener('compositionend',(evt:CompositionEvent)=>{
        // console.log('compositionend',evt);
        // 當因為Focus跑掉或者是滑鼠點擊而導致中斷的時候，會觸發一次oninput[deleteContentBackward]
        // 加上TimeOut讓這個自訂的Input發生在Delete之後
        setTimeout(() => {
          // 意外結束的時候 oninput[deleteContentBackward]那邊會將組字狀態設定為結束
          // 此時還會有另一個原生的insertText事件(內容會排除未完成的組字，才符合TextArea會真正插入的文字)
          // 因此這種情況下不在發送另一個insertText
          if(this.compositionSt!='end'){
            var inputEvt = new InputEvent('input',{
              'inputType':'insertText',
              'data':evt.data
            });  
            this.compositionSt = 'end';
            this.onInput(inputEvt);  
          }
        }, 0);
        
        evt.preventDefault();
        evt.stopPropagation();
        
        return false;
      });
    }, 0);

    this.setDisabled();
    this.onLoaded();
  }

  onLoaded(){}
  setDisabled() {
    if (this._disabled) {
      this.editFG.disable();
    } else {
      this.editFG.enable();
    }
  }

  subscribe_helper() {
    this.helperService.helperAction$.pipe(takeUntil(this.unsubscribe)).subscribe(
      (evt: HelperEvent) => {
        if (!evt || !evt.source || !evt.selectedItem) {
          return;
        }
        if (evt.source === this.sourceType) {
          // model: SystemCode
          this.insertText(evt.inputValue, evt.selectedItem.Name,false,true,true);
        }
      }
    );
  }
  subscribe_helperPad() {
    this.padService.fromPad.pipe(takeUntil(this.unsubscribe)).subscribe(
      (evt: HelperPadEvent) => {

        if (evt instanceof AreaSelectedEvent) {
          if (evt.tab == this.sourceType) {
            this.focusAtLast();
          }
        } else if (evt instanceof ItemSelectedEvent) {
          if (evt.type == this.sourceType) {
            this.histService.UIUnReady(this.sourceType)
            if (evt.isInsert) {
              this.insertText('', evt.item.Name,true,evt.focus,true);
            } else {
              this.clear();
              this.insertText('', evt.item.Name,true,evt.focus,true);
            }
            this.histService.UIReady(this.sourceType)
          }
        }else if (evt instanceof SetItemEvent) {
          if (evt.type == this.sourceType) {
            this.histService.UIUnReady(this.sourceType)
            if (evt.isInsert) {
              this.insertText('', evt.item.Name,true,false,false);
            } else {
              this.clear();
              this.insertText('', evt.item.Name,true,false,false);
            }
            this.histService.UIReady(this.sourceType)
          }
        }

        // if (!evt || !evt.source || evt.source !== this.sourceType) {
        //   return;
        // }
        // if (evt.type === HelperEventTypeEnum.HelperTab) {
        //   this.focusAtLast();
        // } else if (evt.type === HelperEventTypeEnum.HelperSelect && evt.selectedItem) {
        //   if (evt.isInsert) {
        //     this.insertText(evt.inputValue, evt.selectedItem.Name);
        //   } else {
        //     this.clear();
        //     this.insertText(evt.inputValue, evt.selectedItem.Name);
        //   }
        // }
      }
    );
  }
  // #region form --------------------
  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      content: [''],
    });
    return fg;
  }
  // 填入表單資料
  fillFormData() {
    if (!this.editFG.controls.content) { return; }
    this.editFG.setValue({
      content: this._content || '',
    });
  }
  // #endregion

  //#region public function --------------------
  clear() {
    var rc = this.padService.setRecord(this.elemId,[]);
    this.editFG.controls.content.setValue('');
  }
  //#endregion public function --------------------

  //#region input, receive handle --------------------
  private getPreviousChar(): string {
    const nativeElem = this.helperTextarea.elem.nativeElement;
    const startPos = nativeElem.selectionStart;
    if (startPos === 0) {
      return '';
    } else {
      return nativeElem.value.substring(startPos - 1, startPos);
    }
  }
  onKeyPress(event: KeyboardEvent) {
    this.helperService.keypressForTextarea(this.elemId, event.key, this.getPreviousChar());
    
  }
  pasteText = '';
  cutText = '';
  dropText = '';
  dragText = '';
  onPaste(evt:ClipboardEvent){
    // console.log(evt)
    this.pasteText = evt.clipboardData.getData('text/plain');
  }
  onCut(evt:ClipboardEvent){
    // console.log(evt)
    this.cutText = this.element.value.substring(this.selectStart, this.selectEnd);
  }
  onDrop(evt:DragEvent){
    // console.log(evt)
    this.dropText = evt.dataTransfer.getData('text/plain');
    return true;
  }
  onDrag(evt:DragEvent){
    // console.log(evt)
    this.dragText = evt.dataTransfer.getData('text/plain');
    return true;
  }
  onInput(event: InputEvent){
    if(event.inputType == 'historyUndo' || event.inputType == 'historyRedo')
    {
      //先不處理
      event.preventDefault();
      return false;
    }
    // console.log(event);
    if(this.compositionSt!='end'){
      // 組字還沒結束觸發的刪除=>滑鼠點擊或Focus跑掉引起
      if(event.inputType == 'deleteContentBackward'){
        //此時先結束組字狀態, 讓組字End事件判斷是否意外結束，是則不丟額外的InsertText
        this.compositionSt = 'end';
      }
      return;
    }
    // console.log('TYPE: ',event.inputType,',','DATA:',event.data,',','COMPOSED:',event.composed);
    
    // 動作處理
    var posFix = 0;
    var insertText = event.data||'';
    var isSelectRange = false;
    var selectionLength = 0;
    //deleteByDrag deleteByCut deleteContentForward deleteContentBackward
    var isDelete = event.inputType.startsWith('deleteBy') || event.inputType.startsWith('deleteContent');
    if(event.inputType == 'deleteByDrag'){
      isSelectRange = true;
      selectionLength  = this.dragText.length;
    }else if(event.inputType == 'deleteByCut'){
      isSelectRange = true;
      selectionLength =  this.cutText.length;
    }
    
    if(event.inputType.startsWith('insertFromPaste')){
      posFix = this.pasteText.length
      insertText = this.pasteText;
    }
    // 放下的話Start會在文字前方
    else if(event.inputType.startsWith('insertFromDrop')){
      posFix = 0;
      insertText = this.dropText;
    }else if(event.inputType.startsWith('insert')){
      posFix = insertText.length;
    }
    if(this.selectedOnEvent && this.selectedOnEvent.end > this.selectedOnEvent.start){
      isSelectRange = true;
      selectionLength = this.selectedOnEvent.end - this.selectedOnEvent.start;
    }
    
    var rc = this.padService.getRecords(this.elemId);    
    // 在輸入位置之後的代入 調整pos
    rc.forEach(x=>{      
      // 範圍插入的話要先扣除插入字串的長度在判斷
      if(x.start>=this.selectStart-posFix){
        // 將範圍選取得長度扣除
        if(isSelectRange){
          x.start -= selectionLength;
        }        
        // 刪除,範圍不扣1
        if(isDelete){
          x.start -= isSelectRange?0:1;
        }
        // 貼上,放下,輸入,IME輸入完成
        else{
          x.start += insertText.length;
        }
        
      }
    });
    var text = this.element.value as string;
    // 位置有跑掉的都移除(避免範圍選取以後再新增)
    rc = rc.filter(x=>text.substring(x.start,x.start+x.text.length)==x.text);
    this.padService.setRecord(this.elemId, rc);
    this.clearSelectionOnEvent('input');
  }
  onBlur() {
    this.clearSelectionOnEvent('blur');
    this.helperService.blur();
  }
  onFocus(event) {
    this.clearSelectionOnEvent('focus');
    // foucs已統一到hist-focus.directive內處理
  }
  onClick() {
    this.clearSelectionOnEvent('click');
    // click要再reset一次的原因是要把helper關掉
    this.helperService.reset();
  }
  onKeyDown(event: KeyboardEvent) {
    this.selectedOnEvent = {start:this.selectStart,end:this.selectEnd};
    this.helperService.keydownForTextarea(event);
  }
  insertText(keyValue: string, text: string,fromPad:boolean,focus:boolean,removeDuplicate:boolean) {
    this.insertAtCursor(keyValue, text, fromPad,focus,removeDuplicate);
  }
  
  get element(): HTMLTextAreaElement {
    return this.helperTextarea.elem.nativeElement;
  }
  get contentValue(): string {
    return this.element.value
  }
  set contentValue(text) {
    this.element.value = text;
  }
  get selectStart(): number {
    return this.element.selectionStart;
  }
  set selectStart(pos) {
    this.element.selectionStart = pos;//.innetText
  }
  get selectEnd(): number {
    return this.element.selectionEnd
  }
  set selectEnd(pos) {
    this.element.selectionEnd = pos;
  }
  /**
   * 在游標處插入文字
   * @param nativeElem 
   * @param keyValue 輔助輸入的已經輸入部分的文字
   * @param text 要寫入的整段文字
   */
  insertAtCursor(keyValue: string, text: string,fromPad:boolean,focus:boolean,removeDuplicate = true) {
    // console.log(keyValue, text);
    text += this.seperator;
    // not support IE
    var startPos = this.selectStart - keyValue.length;
    const endPos = this.selectEnd;
    let newContent = '';
    // 有游標的情況，插入
    if (this.selectStart || this.selectStart == 0) {
      // 沒有特別選取的話，start會==end
      var rc = this.padService.getRecords(this.elemId)
      // 重複清除
      var exist = rc.find(x => x.text == text)
      // console.log(exist,startPos,text.length)
      if (exist && fromPad ) {
        // 游標前重複項清除
        if(exist.start+exist.text.length==endPos && removeDuplicate){
          var textBeforeCursor: string = this.contentValue.substring(0, exist.start)
          var textAfterCursor: string = this.contentValue.substring(exist.start+text.length, this.contentValue.length);
          newContent = textBeforeCursor + textAfterCursor;
          rc = rc.filter(x => x !== exist);
          rc.forEach(x => {
            if (x.start > exist.start) {
              x.start -= text.length;
            }
          });
          this.padService.setRecord(this.elemId,rc)
          startPos = textBeforeCursor.length;
          text = '';
        }else{
          // 非游標選取
          this.selectStart = exist.start;// + exist.text.length;
          this.selectEnd = this.selectStart+ exist.text.length;
          if(focus){
            this.element.blur();
            this.element.focus();
          }
          return;
        }
      }
      // 進行插入
      else {
        var textBeforeCursor: string = this.contentValue.substring(0, startPos)
        var textAfterCursor: string = this.contentValue.substring(endPos, this.contentValue.length);
        // 把暫存項目與插入區間相衝的移除
        rc = rc.filter(x=>!(x.start< endPos  && (x.start+x.text.length) >startPos));
        // 使用者選取範圍，進行範圍的覆蓋
        if (startPos != endPos) {
          newContent = textBeforeCursor + text + textAfterCursor;
          rc.forEach(x => {
            if (x.start > startPos) {
              x.start += text.length-(endPos-startPos);
            }
          });
          if(exist ){
            exist.start = startPos;
          }else{
            rc.push({ start: startPos, text: text })
          }
          
          this.padService.setRecord(this.elemId,rc)
        } else {
          // 游標前段文字以要插入的內容結尾時，進行刪除, 且結尾項非有紀錄的同名文字
          var textBeforeInRecord = rc.some(x=>textBeforeCursor.endsWith(x.text)) ;
          if (textBeforeCursor.endsWith(text) && !textBeforeInRecord && removeDuplicate) {
            newContent = textBeforeCursor.substring(0, textBeforeCursor.length - text.length) + textAfterCursor;
            rc = rc.filter(x => x.start !== startPos)
            rc.forEach(x => {
              if (x.start > startPos) {
                x.start -= text.length;
              }
            });
            this.padService.setRecord(this.elemId,rc)
          } else {
            // 游標處插入文字
            newContent = textBeforeCursor + text + textAfterCursor;
            
            rc.forEach(x => {
              if (x.start >= startPos) {
                x.start += text.length;
              }
            });
            rc.push({ start: startPos, text: text })
            this.padService.setRecord(this.elemId,rc)
          }
        }
      }
    }
    // 無Focus，加至尾端
    else {
      newContent = this.contentValue += text;
      rc.push({ start: startPos, text: text })
      rc.forEach(x => {
        if (x.start > startPos) {
          x.start += text.length;
        }
      });
      this.padService.setRecord(this.elemId,rc)
    }
    this.editFG.controls.content.setValue(newContent);
    // 游標定位到插入文字的最後面
    this.selectStart = startPos + text.length;
    this.selectEnd = this.selectStart;
    if(focus){
      this.element.blur();
      this.element.focus();
    }
    
  }
  focusAtLast() {
    this.helperTextarea.setFocus(false);
    //(this.helperTextarea.elem.nativeElement as HTMLTextAreaElement).focus();
  }
  //#endregion

  //#region after view init
  ngAfterViewInit() {
    if (this.isFocusAfterInit) {
      setTimeout(() => { 
        this.helperTextarea.setFocus(true); 
        //this.padService.currentTab = HelperId.getHelpTab(HelperId.getSourceById(this.areaId))
      }, 300);
    }
  }
  //#endregion

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  selectedOnEvent:{start:number,end:number} = null;
  sChange(evt){
    
  }
  clearSelectionOnEvent(s){
    //console.log('clearSelectionOnEvent ',s)
    this.selectedOnEvent = null;
  }
}
