import { Injectable } from '@angular/core';
import { AuthenticationService } from '../security/services/authentication.service';
import { LocalStoragesService } from './local-storages.service';
// import { DailyReportColControl } from '../payments/models/daily-report';

interface SessionData{
  abnormalIC:string;
  patientHistdata:{patientNo:string};
  doctorPinCode: {Uesr:string,PinCode:string};
  hcaModule:{InitModule:number,InitSession:number};
  writeCardData: boolean;
  useSatellite: boolean;
  // DailyReportHideCol : DailyReportColControl[]
  useEmr: boolean;
  AnnAlert:{sec:number,ann:number[]}[]
  ToccAlert:{sec:number,user:number,patient:number}[]
}


@Injectable({
  providedIn: 'root'
})
/** 登入後記錄一些操作資訊使用，登出後即清除 */
export class SessionService {

  sessionData: any = {};
  constructor(private localStorage: LocalStoragesService,
    private auth: AuthenticationService) {
    this.loadSessionData();
    // 登出時清空
    this.auth.logoutEvent.subscribe(() => {
      // 登出後要保留的例外
      var keep = {};            
      if(this.sessionData[this.KEY_AbnormalIC] == 'G000'){
        keep[this.KEY_AbnormalIC] = 'G000'
      }
      this.sessionData = keep;
      this.saveSessionData();
    })
  }

  //#region 異常掛號
  private KEY_AbnormalIC = 'abnormalIC';
  setAbnormalIC(code) {
    this.sessionData[this.KEY_AbnormalIC] = code;
    this.saveSessionData();
  }

  getAbnormalIC() {
    return this.sessionData[this.KEY_AbnormalIC];
  }
  //#endregion

  //#region 藥師選擇
  private KEY_Pharmacy = 'phar';
  setPhar(ph) {
    this.sessionData[this.KEY_Pharmacy] = ph;
    this.saveSessionData();
  }
  getPhar() {
    return this.sessionData[this.KEY_Pharmacy];
  }
  //#endregion

  setData<K extends keyof SessionData>(key: K,value: SessionData[K]) {
    this.sessionData[key] = value;
    this.saveSessionData();
  }

  getData<K extends keyof SessionData>(key:K):SessionData[K] {
    return this.sessionData[key];
  }
    
  //#region 方法Private  
  private loadSessionData() {
    var session = this.localStorage.getItem('SessionData');
    if (session == null) {
      session = {}
      this.localStorage.setItem('SessionData', {});
    };
    this.sessionData = session;
  }

  private saveSessionData() {
    this.localStorage.setItem('SessionData', this.sessionData);
  }
  //#endregion
}
