import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter, Query, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { TmplView } from '../models/tmpl-view';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSetApi } from 'src/app/services/api-service/rx/mainset-api';
import { MainSetView } from 'src/app/services/api-service/rx/main-set-view';

@Component({
  selector: 'app-hist-tmpl-view',
  templateUrl: './tmpl-view.component.html',
  styleUrls: ['./tmpl-view.component.css'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class TmplViewComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private webApiFactory: WebApiClient,
    private mainsetApi:MainSetApi,
    private cd: ChangeDetectorRef) {
      //this.api = this.webApiFactory.createHisService('hist/histview');
    }

  //#region 參數 --------------------
  private unsubscribe: Subject<void> = new Subject();

  pageSkipNo = 0;
  pageSize = 6;
  pageData: PageData2<MainSetView>;

  copyFG: FormGroup;
  bodyHeight = document.body.clientHeight;
  icViewScrollHeight:string = '';
  get copyFV() {
    return this.copyFG.value;
  }
  //#endregion 參數 --------------------

  //#region opd-record --------------------
  @Input() filterId:number = null;
  @Output() emitInsert = new EventEmitter<any>();
  //#endregion opd-record --------------------


  async ngOnInit() {
    this.copyFG = this.makeCopyFG();
    await this.getTmplList();
  }

  // 建立query form group
  makeCopyFG() {
    return this.fb.group({
      isInsert: [true],
      isIncludeCC: [true],
      isIncludePE: [true],
      isIncludeDiag: [true],
      isIncludeOrder: [true],
      isClinic:[true],
      code:[''],
      name:[''],
      dx1:['']
    });
  }

  // #region get data --------------------
  async getTmplList(newQuery = false) {
    if(newQuery) {
      this.pageSkipNo = 0;
    }
    const query: any = {};
    query.code = this.copyFV.code;
    query.name = this.copyFV.name;
    query.dx = this.copyFV.dx1;
    query.isClinic = this.copyFV.isClinic;
    query.pageSkip = this.pageSkipNo;
    query.pageSize = this.pageSize;
    if(this.filterId){
      query.id = this.filterId;
    }
    var data = await this.mainsetApi.GetMainSetViews(query);
    this.pageData = data;
    //this.pageSkipNo = 0;
    //this.setGridView();
  }

  // #endregion get data --------------------

  // #region action --------------------
  onCopyClick(mainSet: MainSetView) {
    const tmplView = new TmplView();
    tmplView.RsCode = mainSet.Code;
    tmplView.IsInsert = this.copyFV.isInsert;
    tmplView.IsIncludeCC = this.copyFV.isIncludeCC;
    tmplView.IsIncludePE = this.copyFV.isIncludePE;
    tmplView.IsIncludeDiag = this.copyFV.isIncludeDiag;
    tmplView.IsIncludeOrder = this.copyFV.isIncludeOrder;
    this.emitInsertTmpl(tmplView);
  }
  // #endregion action --------------------

  //#region opd-record --------------------
  emitInsertTmpl(tmplView: TmplView) {
    this.emitInsert.emit(tmplView);
  }
  //#endregion opd-record --------------------
  async onPageChange(e: any) {
    console.log(e)
    this.pageSkipNo = (e-1)*this.pageSize;
    this.pageSize = this.pageSize;
    await this.getTmplList();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  /** 取捲軸高 */
  getViewScrollHeight(): string {
    let scrollHeight= this.bodyHeight - 80;
    this.icViewScrollHeight = scrollHeight + 'px';
    return this.icViewScrollHeight;
  }

  getSemiScrollHeight(): string {
    let scrollHeight= this.bodyHeight - 80;
    let semiScrollHeight = scrollHeight/2 - 37 + 'px';
    return semiScrollHeight;
  }
}
