<div>
    <mat-select class="app-v-select v-control" 
      style="width: 100%;padding: 0px 5px 0px 5px;vertical-align: bottom;"
      [ngClass]="_class"
      [value]="_currentValue"
      (selectionChange)="_valueChange($event)"
      [class.readOnly]="_readonly"
      [disabled]="_disabled"
      [disableOptionCentering]="true"
      [class.v-disabled]="_disabled"
      [panelClass]="panelClass">
        <mat-option *ngFor="let item of data" [value]="item!=undefined&&item!=null?item[valueField]:''">
            <ng-container *ngIf="displayTmp;else defaultTmp">
                <ng-container *ngTemplateOutlet="displayTmp;context:{$implicit:item}"></ng-container>
            </ng-container>
            <ng-template #defaultTmp>{{item!=undefined&&item!=null?item[textField]:''}}</ng-template>
        </mat-option>
    </mat-select>
</div>
