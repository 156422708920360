import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

declare type formatType = 'value' | 'text' | 'valuetext';

@Component({
  selector: 'app-vis-combo-box-v2',
  templateUrl: './vis-combo-box-v2.component.html',
  styleUrls: ['./vis-combo-box-v2.component.css']
})
export class VisComboBoxV2Component implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  /**搜尋框是否啟用
   * @type {boolean}
   * @memberof VisComboBoxV2Component
   */
  @Input() disabled: boolean = false;

  /**從外部傳入的option，為該物件的原始選項清單
   * @type {ValueTextPair[]}
   * @memberof VisComboBoxV2Component
   */
  @Input() set option(value: ValueTextPair[]) {
    this.optionsSubject.next(value || []);
  }

  /** 搜尋框已選擇項目文字的格式
   *有三種可以選擇：value、text和valuetext
   *valuetext會將value和text透過inputValueFormatSeparator串接起來
   * @type {formatType}
   * @memberof VisComboBoxV2Component
   */
  @Input() inputValueFormat: formatType = 'valuetext';

  /** 搜尋框項目文字串接的符號
 *只有在inputValueFormat=valuetext時才會生效
 * @type {formatType}
 * @memberof VisComboBoxV2Component
 */
  @Input() inputValueFormatSeparator: string = ' | ';

  /** 下拉選單選項文字的格式
   *有三種可以選擇：value、text和valuetext
   *valuetext會將value和text透過optionFormatSeparator串接起來
   * @type {formatType}
   * @memberof VisComboBoxV2Component
   */
  @Input() optionFormat: formatType = 'valuetext';

  /** 下拉選單選項文字串接的符號
   *只有在optionFormat=valuetext時才會生效
   * @type {formatType}
   * @memberof VisComboBoxV2Component
   */
  @Input() optionFormatSeparator: string = ' | ';

  /** 查詢的目標
   *有三種可以選擇：value、text和valuetext
   * @type {formatType}
   * @memberof VisComboBoxV2Component
   */
  @Input() queryTarget: formatType = 'valuetext';

  /** 已選擇的選項
  * @type {ValueTextPair[]}
  * @memberof VisComboBoxV2Component
  */
  @Input() set selectedOptions(value: ValueTextPair[]) {
    this.selectedOptionsSubject.next(value || []);

    this.updateDisplayText();
    this.onSelectedEmit.emit(this.selectedOptions);
  }

  get selectedOptions(): ValueTextPair[] {
    return this.selectedOptionsSubject.value;
  }

  @Output() selectedOptionsChange = new EventEmitter<ValueTextPair[]>();


  /**是否多選
   * 開啟時，多選的項目會整理成一串字串，以指定的分隔符號區隔
   */
  @Input()
  isMultiple: boolean = false;

  /**多選分隔符號
   */
  @Input()
  multipleSeparator: string = ',';

  /**多選時，最多允許幾個項目，null則為無限制
   */
  @Input()
  multiMaxItemCount?: number;

  /**自定義下拉選單右側圖示
   * @type {TemplateRef<any>}
   * @memberof VisComboBoxV2Component
   */
  @Input() customIcon: TemplateRef<any>;


  /**
   *選擇項目後的發射器
   *
   * @type {EventEmitter<ValueTextPair[]>}
   * @memberof VisComboBoxV2Component
   */
  @Output() onSelectedEmit: EventEmitter<ValueTextPair[]> = new EventEmitter();

  /**
   *下拉選單的版面配置，預設為fit-content
   *
   * @type {(string | number)}
   * @memberof VisComboBoxV2Component
   * @see [相關文檔](https://material.angular.io/components/autocomplete/api)
   */
  panelWidth: string | number = 'fit-content';

  /**
   *篩選後的選項
   *
   * @type {Observable<ValueTextPair[]>}
   * @memberof VisComboBoxV2Component
   */
  filteredOptions$: Observable<ValueTextPair[]>;

  /**
   *input顯示的文字
   *
   * @type {string}
   * @memberof VisComboBoxV2Component
   */
  inputDisplayText: string = "";

  get isMaxItemCountReached(): boolean {
    return this.multiMaxItemCount != null && this.selectedOptions.length >= this.multiMaxItemCount;
  }

  private selectedOptionsSubject = new BehaviorSubject<ValueTextPair[]>([]);
  private optionsSubject = new BehaviorSubject<ValueTextPair[]>([]);
  private queryTextSubject = new BehaviorSubject<string>("");

  constructor(private cdr: ChangeDetectorRef) {
    this.filteredOptions$ = combineLatest([
      this.optionsSubject,
      this.selectedOptionsSubject,
      this.queryTextSubject
    ]).pipe(
      map(([options, selectedOptions, queryText]) => this.filterOptions(options, selectedOptions, queryText))
    );
  }

  ngOnInit(): void {
  }

  onInputClick(evt?: Event) {
    this.onInputOpenPanel();
  }

  onInputInput(evt?: Event) {
    const inputValue = this.inputDisplayText;
    let inputValueSp = [inputValue];
    if (inputValue.includes(",")) {
      inputValueSp = inputValue.split(",");
    }

    if (this.optionsSubject && this.optionsSubject.value && this.optionsSubject.value.length > 0) {
      const completeInputStrs = inputValueSp.filter(iv => this.optionsSubject.value.some(opt => opt.value == iv));
      this.selectedOptions = this.optionsSubject.value.filter(cmp => {
        let checkTarget = this.generateDisplayText(cmp, this.inputValueFormat, this.inputValueFormatSeparator);
        return completeInputStrs.includes(checkTarget)
      });

      const nonCompleteInputStrs = inputValueSp.filter(iv => !this.optionsSubject.value.some(opt => opt.value == iv));
      if (nonCompleteInputStrs && nonCompleteInputStrs.length > 0) {
        const queryValue = nonCompleteInputStrs[nonCompleteInputStrs.length - 1];
        this.queryTextSubject.next(queryValue);
      }
    }

  }

  onInputblur(evt?: Event) {
    const inputValue = this.inputDisplayText;
    let inputValueSp = [inputValue];
    if (inputValue.includes(",")) {
      inputValueSp = inputValue.split(",");
    }

    if (this.optionsSubject && this.optionsSubject.value && this.optionsSubject.value.length > 0) {
      const completeInputStrs = inputValueSp.filter(iv => this.optionsSubject.value.some(opt => {
        const completeInputStr = this.generateDisplayText(opt, this.inputValueFormat, this.inputValueFormatSeparator);
        return iv == completeInputStr;
      }));

      if (completeInputStrs.length > 0) {
        this.inputDisplayText = completeInputStrs.join(",");
      }
      else {
        this.inputDisplayText = "";
      }
    }
  }

  onInputKeyDown(evt?: Event) {
  }

  onInputOpenPanel(evt?: Event) {
    this.autocompleteTrigger.openPanel();
  }

  onAutoCompleteOptionSelected(evt?: Event) {
    const matOption = evt["option"] as MatOption;
    const selectOption = matOption.value as ValueTextPair;

    this.handleOptionSelected(selectOption);
    this.updateDisplayText();
    this.onSelectedEmit.emit(this.selectedOptions);
  }


  onOptionSelected(newOption: ValueTextPair) {
    const updatedOptions = [...this.selectedOptions, newOption];
    this.selectedOptionsSubject.next(updatedOptions);
    this.selectedOptionsChange.emit(updatedOptions); // 發射變更事件
  }

  onOptionDeselected(optionToRemove: ValueTextPair) {
    const updatedOptions = this.selectedOptions.filter(option => option !== optionToRemove);
    this.selectedOptionsSubject.next(updatedOptions);
    this.selectedOptionsChange.emit(updatedOptions); // 發射變更事件
  }


  /** 取得選項的文字
   * @param option  選單項目
   * @returns
   */
  getOptionText(option: ValueTextPair) {
    return this.generateDisplayText(option, this.optionFormat, this.optionFormatSeparator);
  }

  /** 產生顯示文字
   * @param option 選單項目
   * @param formatType 文字格式
   * @param separator 間格符號
   * @returns
   */
  generateDisplayText(option: ValueTextPair, formatType: formatType, separator: string) {
    switch (formatType) {
      case 'value':
        return option.value;
      case 'text':
        return option.text;
      case 'valuetext':
      default:
        return `${option.value}${separator}${option.text}`;
    }
  }

  /** 更新顯示的文字
   * @returns
   */
  private updateDisplayText(): void {
    if (!this.selectedOptions || this.selectedOptions.length == 0)
      return;

    let displayOptionTexts: string[] = [];

    this.selectedOptions.forEach(option => {
      let text = this.generateDisplayText(option, this.inputValueFormat, this.inputValueFormatSeparator);
      displayOptionTexts = [...displayOptionTexts, text];
    });

    this.inputDisplayText = displayOptionTexts.join(this.multipleSeparator);
    if (this.selectedOptions.length < this.multiMaxItemCount) {
      this.inputDisplayText += ",";
    }

  }

  /** 處理選項被選擇後的行為
   * @param option
   */
  private handleOptionSelected(option: ValueTextPair) {
    if (!this.selectedOptions || this.selectedOptions.length == 0) {
      this.selectedOptions = [option];
    }
    else {
      if (this.isMultiple) {
        if (this.multiMaxItemCount > this.selectedOptions.length) {
          this.selectedOptions = [...this.selectedOptions, option];
        }
        else {
          this.selectedOptions = [...this.selectedOptions];
        }
      }
      else {
        this.selectedOptions = [option];
      }

    }
  }

  /** 依條件過濾選項
   * 會依照以下條件順序進行過濾
   * 1.過濾已選項目
   * 2.依照查詢文字過濾
   * @param options 選項清單
   * @param selectedOptions 已選選項
   * @param queryText 查詢文字
   * @returns
   */
  private filterOptions(options: ValueTextPair[], selectedOptions: ValueTextPair[], queryText: string): ValueTextPair[] {
    let ret = options;

    if (selectedOptions && selectedOptions.length > 0) {
      ret = ret.filter(option => !selectedOptions.some(selected => selected.value === option.value));
    }

    if (queryText) {
      const lowerCaseQuery = queryText.toLowerCase();

      switch (this.queryTarget) {
        case 'text':
          ret = ret.filter(option => option.text.toLowerCase().includes(lowerCaseQuery));
          break;
        case 'value':
          ret = ret.filter(option => option.value.toLowerCase().includes(lowerCaseQuery));
          break;
        case 'valuetext':
          ret = ret.filter(option => option.text.toLowerCase().includes(lowerCaseQuery) || option.value.toLowerCase().includes(lowerCaseQuery));
          break;
      }
    }


    return ret;
  }

}
