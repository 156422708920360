import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { SalesOrderRecepipt } from 'src/app/services/api-service/sales/sales-order/sales-order-api.model';
import { ReportComponentService } from '../report-component.service';
import { SalesOrderApiService } from 'src/app/services/api-service/sales/sales-order/sales-order-api.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

@Component({
  selector: 'app-sales-order-receipt',
  templateUrl: './sales-order-receipt.component.html',
  styleUrls: ['./sales-order-receipt.component.css']
})
export class SalesOrderReceiptComponent implements OnInit {
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  @Input() salesOrderId: number;

  salesOrderRecepipt: SalesOrderRecepipt;
  fontType: string;
  salesOrderCodeReceiptShowRemark: boolean;

  constructor(private reportComponentService: ReportComponentService,
    private salesOrderApiService: SalesOrderApiService,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private clinicDataService: ClinicDataService,
  ) { }

  async ngOnInit() {
    var param = await this.clinicDataService.getParam("PRN001");
    this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
    this.getData();
  }

  async getData() {
    this.salesOrderRecepipt = await this.salesOrderApiService.GetSalesOrderReceipt(this.salesOrderId);
  }

  async onPrint() {
    const reportName = '其他收支收據';
    const printContents = this.getHtml();

    var ret = await this.localPrintService.printHtml(811, reportName, printContents);  // 'Consultation Volume Statistic'
    if (ret.Successed) {
      this.notification.showSuccess(reportName + '已送出列印!', true);
    } else {
      this.notification.showError(reportName + '送出列印失敗!');
    }
  }

  getHtml(): string {
    let cssText = this.reportComponentService.getAllCss(this.reportContainer.nativeElement);

    let style = `
      <style>
        ${cssText}

        @page {
          size: '21.5cm 9.4cm;';
          margin: 0cm;
          padding: 0cm;
        }
      </style>
    `;

    var html = this.reportContainer.nativeElement.innerHTML;

    let fullHtml = `
    <html>
      <head>${style}</head>
      <body style="margin: 0cm;padding:0cm;">${html}</body>
    </html>`;

    return fullHtml;
  }


}
