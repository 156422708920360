
  <div [id]="areaId" *ngIf="diags" [class]="'diags-wrapper'" class="position-relative" style="z-index: 2; border: solid 1px #aaa;">
    <ng-container *ngFor="let diag of fhs; let idx = index">
      <form [formGroup]="diag.FormGroup" style="display: contents;">
      <div class="diag-item" style="padding-top: 3px;">
        <div class="diag-label">{{diag.Value.no}}</div>

        <div class="diag-code">
           <!-- *ngIf="isShowSearch" -->
          <input [id]="codeIdPrefix + idx"
            [ngClass]="diag.Value.acuteNote== 2?'diag-acute':''"
            class="vis-input w-100 border-right-0 dx-input" placeholder="Code" formControlName="code" autocomplete="off"
            (focus)="onCodeFocus($event)" (blur)="onCodeBlur(idx)"
            (keyup)="onCodeKeyUp($event, idx)" (keydown)="onCodeKeyDown($event, idx)" appHistFocus />
        </div>
        <div class="diag-name">
          <input [id]="nameIdPrefix + idx"
              [ngClass]="diag.Value.acuteNote== 2?'diag-acute':''"
              style="border-right: 1px solid #aaa;" formControlName="name" title="{{getNameTooltip(idx)}}"
            readonly="readonly" tabindex="-1" class="vis-input w-100 v-disabled area-input" (click)="onCodeKeyDownAtName(idx)" />
        </div>
      </div>
    </form>
    </ng-container>
  </div>

<app-msg-text textId="MSG_HistDiags1" i18n="@@MSG_HistDiags1">診斷不能重複</app-msg-text>
<app-msg-text textId="MSG_HistDiags2" i18n="@@MSG_HistDiags2">搜尋診斷時發生錯誤</app-msg-text>
<app-msg-text textId="MSG_HistDiags3" i18n="@@MSG_HistDiags3">找不到代碼</app-msg-text>
<app-msg-text textId="MSG_HistDiags4" i18n="@@MSG_HistDiags4">設定診斷資料時發生錯誤</app-msg-text>
