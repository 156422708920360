export class HCVisitsOrderReportDto {
    queryMonth: Date;
    startDate: Date;
    endDate: Date;
    clinicId: number;
    startDateDisplay: Date;
    endDateDisplay: Date;
    ReportTime: Date;
    Items: HCVisitsOrderReportItemDto[];
}

export class HCVisitsOrderReportItemDto {
    ExecutorCId: string;
    ExecutorName: string;
    PositionCode: string;
    PositionName: string;
    DrugName: string;
    PatientCount: number;
    OrderUsageCount: number;
    TotalPatientAmount: number;
    TotalUsageAmount: number;
    IsSummary: boolean;
}