<div class="mb-3 p-4" #reportContainer>
    <div style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1.2;padding-left: 2px;" *ngIf="hist && clinic" >
        <div style="display:flex">
            <div style="width: 154px;">
                <!-- 30 + 10*6 + 12*6 = -->
                姓名:{{hist.Patient.PatientNo}}{{hist.Patient.CName}}
            </div>
            <div style="width: 24px;">
                {{hist.Patient.SexName}}
            </div>
            <div style="width: 103px;">
              身分證:{{hist.Patient.CId| CIdMask:cidMask}}
            </div>
            <div style="width: 60px;">
                年齡:{{hist.Patient.Birthday | age}}
            </div>
        </div>
        <div style="display:flex">
            <div style="width: 96px;">
                就診日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
            </div>
            <div style="width: 78px;">
                身分:{{hist.Register.ICodeName}}
            </div>
            <div style="width: 84px;">
                卡序:{{(hist.Register.ICodeName=='自費診' ? '' : hist.Register.Icno)}}
            </div>
            <div style="width: 72px;">
                生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}
            </div>
        </div>
        <div style="width:354px; height: 15px;">

        </div>
        <div style="display:flex">
            <div style="width: 78px;">
                科別:{{ getMedDeptName(hist.Register.MedDeptCode) }}
            </div>
            <div style="width: 60px;">
                案類:{{hist.Hist.Case}}
            </div>
            <div style="width: 60px;">
                日份:{{hist.Hist.Days}}
            </div>
            <div style="width: 60px;">
                調劑:{{hist.Hist.DispTP}}
            </div>
        </div>
        <div style="width:354px">
            {{hist.Hist.CC}}
        </div>
        <div style="width:354px">
            {{hist.Hist.PE}}
        </div>
        <div style="display: flex;width: 353px; overflow: hidden;">
            傷病代:{{dxs()}}　病名:{{hist.Hist.Dx1Name}}
        </div>
        <table style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';font-size: 13px;line-height: 1;">
            <tr>
                <td style="width: 12px;">N</td>
                <td style="width: 114px;">藥品名稱</td>
                <td style="width: 42px;">藥代</td>
                <td style="width: 36px;">劑量</td>
                <td style="width: 24px;">UN</td>
                <td style="width: 30px;">頻率</td>
                <td style="width: 18px;">日</td>
                <td style="width: 30px;">總量</td>
                <td style="width: 48px;">註</td>

            </tr>
            <tr>
                <td colspan="9">
                    -----------------------------------------------------------
                </td>
            </tr>
            <ng-container *ngFor="let hrx of hist.Hist.HRxs">
                <tr>
                    <td>{{hrx.SPRule == 0 ? '' : hrx.SPRule}}</td>
                    <td style="max-width: 114px;overflow: hidden;white-space: nowrap;">{{hrx.ProdName}}</td>
                    <td>{{hrx.RxCode}}</td>
                    <td>{{hrx.Dose}}</td>
                    <td>{{hrx.Unit}}</td>
                    <td>{{hrx.Freq}}</td>
                    <td>{{hrx.Days}}</td>
                    <td>{{hrx.TotalDose}}</td>
                    <td>{{hrx.Remark}}</td>
                </tr>
            </ng-container>
            <tr>
                <td colspan="9" style="height: 15px;">

                </td>
            </tr>
        </table>
        <div>
            請遵醫囑服用,若未使用完畢,請予丟棄
        </div>
        <div style="display: flex;">
            <div style="width: 160px;">
                醫師:{{hist.Register.DoctorCId| CIdMask:cidMask}}{{hist.Register.DoctorName}}
                <!-- 醫師:{{CIdMask(hist.Register.DoctorCId) + hist.Register.DoctorName}} -->
            </div>
            <div style="width: 160px;">
                藥師: {{hist.Hist.PharmacistName}}
            </div>
        </div>
        <div style="display: flex;width: 354px;">
            <div style="width: 66px;">
                診察 {{hist.Hist.ADx}}
            </div>
            <div style="width: 66px;">
                藥費 {{hist.Hist.ARx}}
            </div>
            <div style="width: 66px;">
                治療 {{hist.Hist.ATx8}}
            </div>
            <div style="width: 66px;">
                藥事 {{hist.Hist.AMed}}
            </div>
            <div style="width: 90px;">
                申請 {{hist.Hist.ADcl}}
            </div>

        </div>
        <div style="display: flex;width: 354px;">
            <div style="width: 66px;">
                掛費 {{hist.Register.RegisterFee}}
            </div>
            <div style="width: 66px;">
                自付 {{hist.Register.OwnFee}}
            </div>
        </div>
        <div style="display: flex;width: 354px;">
            調劑日:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}
        </div>
    </div>
</div>
