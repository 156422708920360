import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { EditModeEnum } from '../../enums/edit-mode-enum';
import { BaseComponent } from '../base/base.component';
// import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

// tslint:disable-next-line: class-name
class controlButton {
  constructor() { }

  hasButton = true; // 是否有此按鈕，false的話，從頭到尾都不會出現
  isShowForMode = true; // hasButton=true時，isShowForMode=false可讓此按鈕在某些Mode下不出現
  isEnable = true; // 該按鈕是否呈現enable/disable狀態

  isShow(): boolean {
    return this.hasButton && this.isShowForMode;
  }
}

@Component({
  selector: 'app-shared-edit-control-buttons',
  templateUrl: './edit-control-buttons.component.html',
  styleUrls: ['./edit-control-buttons.component.css']
})

export class EditControlButtonsComponent extends BaseComponent implements OnInit {

  constructor() {
    super();
  }

  // 模式
  editMode: EditModeEnum = EditModeEnum.view;

  btnAdd: controlButton = new controlButton();
  btnSave: controlButton = new controlButton();
  btnCancel: controlButton = new controlButton();
  btnDelete: controlButton = new controlButton();
  btnEdit: controlButton = new controlButton();
  btnClear: controlButton = new controlButton();
  btnFinish: controlButton = new controlButton();

  // 是否有此按鈕，false的話，從頭到尾都不會出現
  @Input() hasAddButton = false; // 預設不出現
  @Input() hasSaveButton = true;
  @Input() hasCancelButton = true;
  @Input() hasDeleteButton = true;
  @Input() hasEditButton = true;
  @Input() hasClearButton = false; // 預設不出現
  @Input() hasFinishButton = false; // 預設不出現
  @Input() showDeleteConfirm = true;
  @Input() deleteWords = '';
  @Input() deleteIcon = '';

  // EventEmitter
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitAdd = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitSave = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitCancel = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitDelete = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitEdit = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitClear = new EventEmitter();
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onEmitFinish = new EventEmitter();

  // 刪除確認視窗
  isDeleteDialogOpened = false;

  // 在父元件呼叫之前不要顯示，以避免閃動效果
  isReadyToShow = false;

  // OnInit時就立刻顯示, 不然預設模式是呼叫了toAdd, toEdit, toView以後才會呈現
  @Input() isShowOnInit = false;

  displayMode = 2; // 1:不可用時，按鈕以disable呈現, others:不可用時hide

  ngOnInit() {
    if (this.isShowOnInit) {
      this.isReadyToShow = true;
    }
    this.btnAdd.hasButton = this.hasAddButton;
    this.btnSave.hasButton = this.hasSaveButton;
    this.btnCancel.hasButton = this.hasCancelButton;
    this.btnDelete.hasButton = this.hasDeleteButton;
    this.btnEdit.hasButton = this.hasEditButton;
    this.btnClear.hasButton = this.hasClearButton;
    this.btnFinish.hasButton = this.hasFinishButton;
    this.enableButtons();
    if (this.displayMode === 1) {
      this.showAllForMode();
    }
  }

  // 模式處理
  // 轉換到add模式
  toAddMode() {
    this.editMode = EditModeEnum.add;
    if (this.displayMode === 1) {
      this.showAllForMode();
      this.enableButtons();
      this.btnAdd.isEnable = false;
      this.btnEdit.isEnable = false;
      this.btnDelete.isEnable = false;
      this.btnCancel.isEnable = true;
      this.btnSave.isEnable = true;
      this.btnClear.isEnable = true;
      this.btnFinish.isEnable = true;
    } else {
      this.enableButtons();
      this.btnAdd.isShowForMode = false;
      this.btnEdit.isShowForMode = false;
      this.btnDelete.isShowForMode = false;
      this.btnCancel.isShowForMode = true;
      this.btnSave.isShowForMode = true;
      this.btnClear.isShowForMode = true;
      this.btnFinish.isShowForMode = true;
    }
    this.isReadyToShow = true;
  }
  // 轉換到edit模式
  // 新增存檔成功後應該回到編輯模式
  toEditMode(canDelete: boolean = true) {
    this.editMode = EditModeEnum.edit;
    if (this.displayMode === 1) {
      this.showAllForMode();
      this.enableButtons();
      this.btnAdd.isEnable = false;
      this.btnEdit.isEnable = false;
      if (canDelete) {
        this.btnDelete.isEnable = true;
      } else {
        this.btnDelete.isEnable = false;
      }
      this.btnCancel.isEnable = true;
      this.btnSave.isEnable = true;
      this.btnClear.isEnable = true;
      this.btnFinish.isEnable = true;
    } else {
      this.enableButtons();
      this.btnAdd.isShowForMode = false;
      this.btnEdit.isShowForMode = false;
      this.btnDelete.isShowForMode = true;
      if (canDelete) {
        this.btnDelete.isShowForMode = true;
        this.btnDelete.isEnable = true;
      } else {
        this.btnDelete.isShowForMode = false;
        this.btnDelete.isEnable = false;
      }
      this.btnCancel.isShowForMode = true;
      this.btnSave.isShowForMode = true;
      this.btnClear.isShowForMode = true;
      this.btnFinish.isShowForMode = true;
    }
    this.isReadyToShow = true;
  }
  // 轉換到view模式
  // 隱藏編輯按鈕
  toViewMode() {
    this.editMode = EditModeEnum.view;
    if (this.displayMode === 1) {
      this.showAllForMode();
      this.enableButtons();
      this.btnAdd.isEnable = true;
      this.btnEdit.isEnable = true;
      this.btnDelete.isEnable = false;
      this.btnCancel.isEnable = false;
      this.btnSave.isEnable = false;
      this.btnClear.isEnable = false;
      this.btnFinish.isEnable = false;
    } else {
      this.enableButtons();
      this.btnAdd.isShowForMode = true;
      this.btnEdit.isShowForMode = true;
      this.btnDelete.isShowForMode = false;
      this.btnCancel.isShowForMode = false;
      this.btnSave.isShowForMode = false;
      this.btnClear.isShowForMode = false;
      this.btnFinish.isShowForMode = false;
    }
    this.isReadyToShow = true;
  }
  // 轉換到none模式
  // 隱藏所有按鈕
  toNoneMode() {
    this.editMode = EditModeEnum.none;
    this.hideAllForMode();
    this.disableButtons();
    this.isReadyToShow = true;
  }

  // 發送事件到parent component
  // 新增
  // 按下新增後進入Process模式
  // 後續處理-進入Add模式
  onBtnAddClick() {
    this.disableButtons();
    this.onEmitAdd.emit();
  }
  // 發送事件到parent component
  // 存檔
  // 按下存檔後進入Process模式
  // 後續處理-存檔成功的話，進入Edit模式。原本模式可能為Add或Edit
  // 後續處理-存檔失敗的話，回到Add或Edit模式，看原本模式為Add或Edit
  onBtnSaveClick() {
    this.disableButtons();
    this.onEmitSave.emit();
  }
  onBtnFinishClick() {
    this.disableButtons();
    this.onEmitFinish.emit();
  }
  // 取消
  onBtnCancelClick() {
    this.disableButtons();
    this.onEmitCancel.emit();
  }
  // 刪除
  // 按下刪除後進入Process模式
  // 後續處理-刪除成功的話，應該離開作業，變為Cancel模式
  // 後續處理-刪除失敗的話，應該留在Edit模式
  onBtnDeleteClick() {
    this.disableButtons();
    if(this.showDeleteConfirm){
      this.openDeleteDialog();
    }else{
      this.onEmitDelete.emit();
      this.enableButtons();
    }

  }

  onBtnEditClick() {
    this.toEditMode();
    this.onEmitEdit.emit();
  }

  onBtnClearClick() {
    this.onEmitClear.emit();
  }

  showAllForMode() {
    this.btnAdd.isShowForMode = true;
    this.btnEdit.isShowForMode = true;
    this.btnDelete.isShowForMode = true;
    this.btnCancel.isShowForMode = true;
    this.btnSave.isShowForMode = true;
    this.btnClear.isShowForMode = true;
    this.btnFinish.isShowForMode = true;
  }
  hideAllForMode() {
    this.btnAdd.isShowForMode = false;
    this.btnEdit.isShowForMode = false;
    this.btnDelete.isShowForMode = false;
    this.btnCancel.isShowForMode = false;
    this.btnSave.isShowForMode = false;
    this.btnClear.isShowForMode = false;
    this.btnFinish.isShowForMode = false;
  }
  // 按鈕控制
  // 啟用全部按鈕
  enableButtons() {
    this.btnSave.isEnable = true;
    this.btnCancel.isEnable = true;
    this.btnDelete.isEnable = true;
    this.btnAdd.isEnable = true;
    this.btnEdit.isEnable = true;
    this.btnClear.isEnable = true;
    this.btnFinish.isEnable = true;
  }
  // 停用全部按鈕
  disableButtons() {
    this.btnSave.isEnable = false;
    this.btnCancel.isEnable = false;
    this.btnDelete.isEnable = false;
    this.btnAdd.isEnable = false;
    this.btnEdit.isEnable = false;
    this.btnClear.isEnable = false;
    this.btnFinish.isEnable = false;
  }
  // 開啟視窗
  openDeleteDialog() {
    this.isDeleteDialogOpened = true;
  }
  // 關閉視窗
  closeDeleteDialog() {
    this.isDeleteDialogOpened = false;
  }
  // 接收視窗關閉時的結果
  onDeleteDialogResult(isYes: boolean) {
    if (isYes) {
      this.onEmitDelete.emit();
    }
    this.enableButtons();
    this.closeDeleteDialog();
  }
}
