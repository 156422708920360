import { Injectable } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
/** FormGroup小幫手 */
@Injectable({
  providedIn: 'root'
})
export class FormHelperService {
  constructor(private fb: FormBuilder) {
  }
  public Create<T>(): FormHelper<T> {
    return new FormHelper<T>(this.fb);
  }
}

type PartialRecord<T> = {
  [K in keyof T]?: [T[K], ValidatorFn[]];
};

export type CompleteRecord<T> = PartialRecord<T> & {
  [K in keyof T]: [T[K], ValidatorFn[]];
};

type PatchRecord<T> = {
  [K in keyof T]?: T[K];
};
export type FormRecord<T> = {
  [K in keyof T]?: K;
};
export class FormHelper<T> {
  FieldName: FormRecord<T>;
  Controls: Record<keyof T, FormControl | FormGroup>;
  private fg: FormGroup;

  get Value(): T {
    return this.fg.getRawValue() as T
  }

  /** 用entity有資料的欄位產生FieldName */
  public parseFieldName(entity: T) {
    var obj = {} as FormRecord<T>;
    for (let p in entity) {
      var name = p.toString();
      obj[name] = name;
    }
    this.FieldName = obj;
    return this;
  }

  /** 進行型別檢查的建立表單，所有欄位皆要設定 */
  public build(formValidators: CompleteRecord<T>): FormHelper<T> {
    this.fg = this.fb.group(formValidators);
    var obj = {} as FormRecord<T>;
    var ctrl = {} as Record<keyof T, FormControl | FormGroup>;
    for (let p in formValidators) {
      var name = p.toString();
      obj[name] = name;
      ctrl[name] = this.fg.controls[name];

    }
    this.FieldName = obj;
    this.Controls = ctrl;
    return this;
  }

  /** 進行型別檢查的建立表單，只須設定部分欄位 */
  public buildPartial(formValidators: PartialRecord<T>): FormHelper<T> {
    this.fg = this.fb.group(formValidators);
    var obj = {} as FormRecord<T>;
    for (let p in formValidators) {
      var name = p.toString();
      obj[name] = name;
    }
    this.FieldName = obj;
    return this;
  }

  public clear() {
    this.fg.reset();
  }

  public patchValue(value: PatchRecord<T>) {
    for (var p in value) {
      var v = (value as any)[p] as string;
      if (/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{7}[+-]\d{2}:\d{2}$/.test(v)) {
        (value as any)[p] = new Date(v);
      }
    }
    this.fg.patchValue(value);
  }

  public patchValueNoEvent(value: PatchRecord<T>) {
    this.fg.patchValue(value, { emitEvent: false });
  }

  public getValue<K extends keyof T>(field: K): T[K] {
    var fieldName = field.toString();
    return this.fg.controls[fieldName].value;
  }

  public setValue<K extends keyof T>(field: K, value: T[K]) {
    var fieldName = field.toString();
    this.fg.controls[fieldName].setValue(value);
  }

  public setValueNoEvent<K extends keyof T>(field: K, value: T[K]) {
    var fieldName = field.toString();
    this.fg.controls[fieldName].setValue(value, { emitEvent: false });
  }

  public get FormGroup() {
    return this.fg;
  }

  public disableControls() {
    this.fg.disable();
  }

  public enableControls() {
    this.fg.enable();
  }
  /**
   *
   */
  constructor(private fb: FormBuilder) {

  }
}
