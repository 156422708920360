<div class="login-wrapper">
  <div class="login-form" [hidden]="isLoadingCompanyName">
    <mat-grid-list cols="1" rowHeight="13:1">
      <mat-grid-tile></mat-grid-tile>

      <mat-grid-tile rowspan="11">
        <div class="logon-card-frame">
          <mat-card class="logon-card">
            <mat-card-title class="login-title">
              <span>{{ companyName }}</span>
            </mat-card-title>
            <mat-card-content>
              <form class="logon-form" [formGroup]="loginForm" action="">
                <mat-grid-list cols="4" rowHeight="2:1" class="grid-list-shell">
                  <mat-grid-tile colspan="1" >
                    <label i18n="@@LoginUserName" class="text-inside-grid">帳號<span class="required"></span></label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3">
                    <mat-form-field class="form-Field" appearance="outline">
                      <input type="text" matInput formControlName="username" [appEnterTo]="pwd"
                        class="input-inside-grid login-username"
                        (blur)="onAccountBlur()" (change)="onAccountChange()" />
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1" >
                    <label i18n="@@LoginPassword" class="text-inside-grid">密碼<span class="required"></span></label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3">
                    <mat-form-field class="form-Field" appearance="outline">
                      <div style="display: flex;">
                        <input type="password" matInput formControlName="password"
                        class="input-inside-grid login-pwd" [appEnterTo]="loginBtn" #pwd/>
                        <mat-icon *ngIf="capsLockEnabled" style="width: 18px;height: 18px;font-size: 18px;" title="大寫已鎖定">keyboard_capslock</mat-icon>
                        <button tabindex="-1" class="background-none m-0 p-0 mx-1 box-shadow-none" (click)="pwd.type=pwd.type=='password'?'input':'password'">
                          <ng-container *ngIf="pwd.type=='password';else elstmp">
                            <mat-icon style="width: 16px;height: 16px;font-size: 16px;">visibility</mat-icon>
                          </ng-container>
                          <ng-template #elstmp>
                            <mat-icon style="width: 16px;height: 16px;font-size: 16px;">visibility_off</mat-icon>
                          </ng-template>
                        </button>

                      </div>
                    </mat-form-field>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="1" >
                    <label i18n="@@LoginUnit" class="text-inside-grid">單位<span class="required"></span></label>
                  </mat-grid-tile>
                  <mat-grid-tile colspan="3">
                    <mat-form-field class="form-Field" appearance="outline">
                      <mat-select formControlName="clinicCode">
                        <mat-option *ngFor="let clinic of clinics" [value]="clinic.Code">
                          {{clinic.Name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-grid-tile>
                </mat-grid-list>
              </form>
            </mat-card-content>
            <mat-divider inset></mat-divider>
            <mat-card-actions style="position: relative" class="cardActionRight mt-2 mb-3">
              <div *ngIf="loginConfirmMessage"
                style="font-size: 16px;width: calc(100% - 140px);position: absolute;left: 20px;top: 14px;
                  text-align: center;vertical-align: baseline;">
                  {{loginMessage}}
                  <span style="color: #f44336;" *ngIf="loginConfirmMessage">{{loginConfirmMessage}}</span>
                </div>
              <button type="button" class="vis-btn-primary py-2 px-4 px-5 vis-btn-capsule mr-2 login-btn" (click)="onLoginClick()" #loginBtn i18n="@@LogIn">登入</button>
            </mat-card-actions>
          </mat-card>
        </div>

      </mat-grid-tile>

      <mat-grid-tile>
        <ng-container *ngIf="isLoadingCompanyName">
          <div class="login-init-loading">
            <div *ngIf="!isLoadCompanyNameFailed" i18n="@@MSG_SystemLoginLoadingCompany">載入中..... </div>
            <div class="error-color" *ngIf="isLoadCompanyNameFailed" i18n="@@MSG_SystemLoginLoadInformationFailed">載入資訊失敗.</div>
          </div>
        </ng-container>
        <div class="login-form-top-logo">
        </div>
      </mat-grid-tile>
    </mat-grid-list>

  </div>
</div>


<app-msg-text textId="MSG_LoginReason401" i18n="@@MSG_LoginReason401">登入驗證失敗</app-msg-text>
<app-msg-text textId="MSG_SystemLoginFetchingClinics" i18n="@@MSG_SystemLoginFetchingClinics">正在取得診所…</app-msg-text>
<app-msg-text textId="MSG_SystemLoginLogging" i18n="@@MSG_SystemLoginLogging">登入中...</app-msg-text>
<app-msg-text textId="MSG_SystemLoginNoClinicSelected" i18n="@@MSG_SystemLoginNoClinicSelected">請選擇一個診所</app-msg-text>
<app-msg-text textId="MSG_SystemLoginInputError" i18n="@@MSG_SystemLoginInputError">請填入帳號與密碼</app-msg-text>
<app-msg-text textId="MSG_SystemLoginFetchClinicsFailed" i18n="@@MSG_SystemLoginFetchClinicsFailed">無法取得診所</app-msg-text>
<app-msg-text textId="MSG_SystemLoginNoClinic" i18n="@@MSG_SystemLoginNoClinic">沒有診所資料，請重新整理</app-msg-text>
<app-msg-text textId="MSG_SystemLoginAccountNoClinic" i18n="@@MSG_SystemLoginAccountNoClinic">此帳號沒有可存取的診所  </app-msg-text>
<app-msg-text textId="MSG_SystemLoginFailed" i18n="@@MSG_SystemLoginFailed">帳號或密碼錯誤</app-msg-text>
<app-msg-text textId="MSG_SystemLoginWorkingCheck" i18n="@@MSG_SystemLoginWorkingCheck">該帳號尚未到職或已離職</app-msg-text>
<app-msg-text textId="MSG_SystemLoginCompanyNotEffective" i18n="@@MSG_SystemLoginCompanyNotEffective">公司無效  </app-msg-text>
<app-msg-text textId="MSG_SystemLoginClinicNotEffective" i18n="@@MSG_SystemLoginClinicNotEffective">診所無效  </app-msg-text>
