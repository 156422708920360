<div class="view-toolbar">
  <form [formGroup]="copyFG" *ngIf="copyFG" style="display: flex;align-items: center;" class="mt-1 px-1">
    <div style="display: flex;width: 50%;margin-right: 5px">
      <mat-radio-group class="tml-radio-group vis-form-field w-auto" formControlName="isInsert">
        <mat-radio-button [value]="true">插入</mat-radio-button>
        <mat-radio-button [value]="false">取代</mat-radio-button>
      </mat-radio-group>
      <mat-checkbox formControlName="isIncludeCC" style="margin-right: 10px;">主訴</mat-checkbox>
      <mat-checkbox formControlName="isIncludePE" style="margin-right: 10px;">理學</mat-checkbox>
      <mat-checkbox formControlName="isIncludeDiag" style="margin-right: 10px;">診斷</mat-checkbox>
      <mat-checkbox formControlName="isIncludeOrder">處方</mat-checkbox>
    </div>
    <app-search-bar style="margin-left: 10px;" [static]="true">
      <input formControlName="code" searchBarField="代碼" />
      <input formControlName="name" searchBarField="名稱" />
      <input formControlName="dx1" searchBarField="主診斷" />
      <mat-radio-group (change)="getTmplList(true)" class="tml-radio-group vis-form-field w-auto" searchBarField="" formControlName="isClinic">
        <mat-radio-button [value]="true">全院</mat-radio-button>
        <mat-radio-button [value]="false">個人</mat-radio-button>
      </mat-radio-group>
      <button type="submit"
        (click)="getTmplList()"
        style="padding: 4px 0;"
        title="查詢"
        class="my-0 vis-btn-third-primary px-1 mr-1 lh-xs vis-btn-max-height">
        <mat-icon class="-top-4 btn-scale-90 pref-icon vis-combine-icon">search</mat-icon>
        <span class="position-relative">查詢</span>
      </button>
    </app-search-bar>
  </form>
</div>
  <app-vis-grid
    [data]="pageData"
    [pageable]="true"
    [pageSize]="this.pageSize"
    [skip]="this.pageSkipNo"
    [customClass]="'extend overflow-y-auto inside-scrollbar p-0 tml-view'"
    (pageChange)="onPageChange($event)"
    [customStyle]="getViewScrollHeight()"
    class="view-list">
    <app-vis-grid-column>
      <ng-template let-dataItem>
          <div style="width:100%; border: solid 1px #ccc;display: flex;align-items: center;">
            <div style="display: block;flex-grow: 1; width: auto;padding: 6px 0px 4px 4px;">
              {{dataItem.Code}} - {{dataItem.Name}}
            </div>
            <button type="button"
              style="transform-origin: 100% 100%;"
              (click)="onCopyClick(dataItem)"
              i18n="@@Load"
              class="my-1 text-xs btn-grid px-1 background-none">
              <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="載入">cloud_download</mat-icon>
              <span class="text-xs-sm float-right">載入</span>
            </button>
            <div class="clear"></div>
          </div>
          <div class="view-content" style="overflow:auto;"  [ngStyle]="{maxHeight: getSemiScrollHeight()}">
            <app-shared-hist-view-content
              [customClass]="'tmpl-view'"
              class="view-list-in-template"
              
              [MainSet]="dataItem">
            </app-shared-hist-view-content>
          </div>
      </ng-template>
    </app-vis-grid-column>
  </app-vis-grid>
