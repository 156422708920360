import { CustomResult } from 'src/app/shared/models/custom-result';
import { NHIRegIC, fillNHIRegIC } from 'src/app/services/api-service/register/nhiRegIc';
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { TimeSecEnum } from 'src/app/enums/TimeSecEnum';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { TimeSectionRange } from 'src/app/services/api-service/register/time-section-range';
import { PaymentInfoDto } from './PaymentInfoDto';
import { RegisterCountDto, RegisterEditDto, SupTransferDto } from './RegisterEditDto';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { REG001Params } from '../parameters/REG001Params';
import { RegisterConst } from 'src/app/shared/constants/register-const';
import { HistToICCardData } from 'src/app/hist/services/hist.service';
import { PageData, PageData2 } from 'src/app/shared/models/page-data';
import { Arrears } from 'src/app/payments/models/card-arrears';
import { Rx } from 'src/app/models/rx';
import { WholeHist } from '../hist/whole-hist';
import { MutiTreatmentListDto } from './mutiTreatmentListDto';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { UserSimplifyInfoDTO } from '../user/uesrSimpleInfo';
import { PrescriptionSignMultiWrite } from 'src/app/shared/hcr-lib/PrescriptionSignMultiWrite';
import { PrescriptionSave } from 'src/app/hist/models/prescription-save';
import { promise } from 'selenium-webdriver';
import { NHIOrder } from '../NHI/nhiOrder';
import { VitalSign } from '../hist/vital-sign';
import { WholeLargeHist } from '../hist/whole-large-hist';
import { Discount } from 'src/app/payments/discount/models/discount';
import { PresDateCheckInfo } from './presdatecheckinfo';
import { SexEnum } from "src/app/enums/SexEnum";
import { RegisterWaitingListQueryTypeEnum } from 'src/app/enums/RegisterWaitingListQueryTypeEnum';

export class ReturnICCardDto {
  registerDto: RegisterEditDto;
  psDtos: PrescriptionSave[];
}

export class RegisterQueryOptions {
  //clinicId: string;
  // 預設診間
  room: string;
  timeSection: ValueTextPairNumberValue[];
  remark: ValueTextPair[];
  medRoom: ValueTextPair[];
  insurance: ValueTextPair[];
  visitType: ValueTextPair[];
  registerType: ValueTextPair[];
  //inote: ValueTextPair[];
  status: ValueTextPair[];
  timeSectionRange: TimeSectionRange;
  paramList: REG001Params;
}

export class RegistEditOpt {
  clinicId: number = 0;
  timeSection: ValueTextPairNumberValue[] = [];
  insurance: ValueTextPair[] = [];
  statusCode: ValueTextPair[] = [];
  medRoom: ValueTextPair[] = [];
  visitType: ValueTextPair[] = [];
  section: ValueTextPair[] = [];
  registerType: ValueTextPair[] = [];
  inote: ValueTextPair[] = [];
  fullStatusCode: ValueTextPair[] = [];
  blType: ValueTextPair[] = [];
  blRH: ValueTextPair[] = [];
  sex: ValueTextPair[] = [];
  discount: ValueTextPair[] = [];
  regParam: REG001Params = null;
  isBeforeCharge: boolean=false;
  payWay: string = '';
  timeSectionRange: TimeSectionRange = null;
  IsPrintReceipt: boolean = false;
  MaxAppointmentDate: Date = new Date();
}

/** 掛號-卡序選項 */
export class ICNoPHOption {
  text: string;
  value: string;
  iccode: string;
  desc: string;
  needCard?:boolean;
  warning?:string;
  iNote?:string;
}

export class GetRegisterForAbnormalQuery {
  startDate?: Date | string;
  endDate?: Date | string;
  icno?: string
}
export interface GetAllQuery {
  // 日期部分 所選即所得 這樣可以避開時區問題
  regDate?: Date | string;
  startDate?: Date | string;
  endDate?: Date | string;
  room?: string;
  timeSec?: number;
  statusValues?: string;
  qType?: RegisterWaitingListQueryTypeEnum;
  qCondition?: string;
}

export class HCCaseListDto {
  Id: number;
  CaseWorkDate: Date;
  ClinicId: number;
  PatientId: number;
  PatientName: string;
  PatientSexName: string;
  PatientAges: number;
  Rx: Rx[];
}

export class RegHistListDto {
  RegId: number;
  RegDate: Date|string;
  Isqno: string;
  Case: string;
  Dx1Name: string;
  Days: number;
  Deposit: number;
  Dept: string;
  Account: number;
  DoctorName?: string;
  ICode?: string;
  SameTreat?: string;
  IsPres?:boolean;
}

export class emrDto {
  regId: number;
  clinicId: number;
  emrContent: string;
}

export class RegHistCheckDto extends  RegHistListDto {
  Checked:boolean = false
}

export interface ReserveDto{
  /** patientId */
  Id: number;
  /** 病歷號 */
  PatientNo: string;
  /** 姓名 */
  Name: string;
  /** 身分證 */
  CId: string;
  /** 生日 */
  Birthday: Date;
  /** 保險身分 */
  ICode:string;
  /** 自訂碼 */
  Kcstmr: string;
  /** 爽約次 */
  MissTimes: number;
  /** 電話 */
  PhoneNo: string;
  /** 手機 */
  Mobile: string;
  Regs:ReserveRegDetails[];
  Hists:ReserveHistDetails[];
}

export interface ReserveRegDetails
{
    RegId: number;
    RegDate: Date;
    Section: TimeSecEnum;
    ICode: string;
    DoctorId: number;
    DoctorName: string;
    WaitNo: number;
    RoomCode: string;
    Remark: string;
    Creater: string;
}

export interface ReserveHistDetails
{
    HistId: number;
    RegDate: Date;
    Dx1Name: string;
    Days: number;
    DoctorId:number;
    DoctorName: string;
}

export interface ReserveCreateDto
  {
    /** 掛號Id */
    Id:number;
    /** 院所id */
    ClinicId:number;
    /**患者id */
    PatientId:number;
    /** 醫師id */
    DoctorId:number;
      /**掛號日期 */
    RegDate: Date;
      /**掛號時段 */
    TimeSec: TimeSecEnum;
    /** 掛入來源(1櫃台2門診) */
    DUTY:number;
    /** 診間 */
    RoomCode: string;
    /** 保險身分 */
    ICode: string;
    /** 備註 */
    Remark: string;
}

export interface ReserveListDto{
  Id:number;
  Name:string;
  PatientNo:string;
  PatientId:number;
  RoomCode:string;
  Remark:string;
  ICode:string;
  DoctorId:number;
  DoctorName:string;
  WaitNo:number;
  RegDate:Date
  BookingDate:Date
  TimeSec:TimeSecEnum;
}

/** 預約單列印 */
export class ReserveReceiptPrint {
  Name:string;
  PatientNo:string;
  RoomName:string;
  Remark:string;
  DoctorName:string;
  WaitNo:number;
  RegDate:Date
  TimeName: string;
  ClinicName:string;
  ClinicAddress: string;
  ClinicPhone: string;
}

/** 掛號欠卡登入表 */
export class RegisterSubListDto {
  Id:number;
  Icno:string; //欠卡序號-卡序
  Roomcode:string; //診別
  DoctorName:string; //醫師名
  PatientNo:string; //病歷號
  LastName:string; //姓氏
  MiddleName:string; //名
  PrintName:string; //外籍人士的中文名字
  CName:string; //卡名
  CId:string; //身分證
  CellPhone:string; //電話
  Phone:string;
  Subtotal:number; //押金
  ICode:string; //身分
  LackCardData:string; //欠卡日期
}

/** 2年內未就診報表 */
export class NoreportWithinTwoYearsListDto {
  Id:number;
  RegDate:string;//看診日
  PatientNo:string;//病歷號
  CName:string;//健保卡上的名字
  Birthday:string;//生日
  Icno:string;//卡序
  DxCode:string;//病代
  DxCName:string;//病名
  Phone:string;//電話
  ZipCode:string;//郵遞區號
  State:string;//地址
  City:string;
  Area:string;
  Street:string;
  Address:string;
}

/** 慢性處方箋未回報表 */
export class UnreportedPrescriptionListDto
{
  Id:number;
  oriDate:string;//原處方日
  cntPresNo:string;//次別
  cntPresTot:string;//總次
  days:number;//日份
  Icno:string;//卡序
  morethanTot:string;//拿藥或回診
  morethanDays:number;//逾日
  DxCode:string;//病代
  DxCName:string;//病名
  PatientNo:string;//病歷號
  CName:string;//健保卡上的名字
  Birthday:string;//生日
  sex:SexEnum;//性別
  Phone:string;//電話
  ZipCode:string;//郵遞區號
  Address:string;//地址
  State:string;
  City:string;
  Area:string;
  Street:string;
}

/** 手術分析報表 */
export class pscxAnalyzeReportListDto
{
  Code:string;//手術代碼 in PCSx / SPCSx
  CName:string;//手術名稱
  averInfoDays:number;//平均總日期
  mancount:number;//男性
  womancount:number;//女性
  unknowCount:number;// 未知性別
  peoplecount:number;//男+女+未知
  averAge:number;//平均年齡
}

export class PrescriptionTrackingListDto
{
  RegDate:string;
  days:number;  
  nextRegDate:string;//RegDate + days
  SameTreat:string;//案類
  PatientNo:string;
  Kcstmr:string;//自訂號
  CName:string;
  Dx1:string;
  Dx1Name:string;
  Dx2:string;
  Phone:string;
}

@Injectable({
  providedIn: 'root'
})

export class RegisterApi {
  api: WebApiService;
  clinicApi: WebApiService

  constructor(
    private apiClient: WebApiClient) {
    this.api = this.apiClient.createHisService('registers/register');
    this.api.enableLoader = false;
  }
  /** 取得掛號 */
  public async Get(id: number): Promise<RegisterEditDto> {
    var data = await this.api.get('Get/' + id).toPromise<RegisterEditDto>();
    // 因為此DTO帶有方法，所以要另外NEW
    return Object.assign(new RegisterEditDto(), data);
  }
  /** 取得掛號清單 */
  public async GetAll(query: GetAllQuery): Promise<PageData> {
    query.regDate = query.regDate ? new Date(query.regDate).toLocaleDateString() : "";
    query.startDate = query.startDate ? new Date(query.startDate).toLocaleDateString() : "";
    query.endDate = query.endDate ? new Date(query.endDate).toLocaleDateString() : "";
    var data = await this.api.get(`GetAll?`, query).toPromise<PageData>();
    data.data = data.data.map(d => {
      // 因為此DTO帶有方法，所以要另外NEW
      return Object.assign(new RegisterEditDto(), d);
    });
    return data;
  }

  public async GetAllRegisterOfClinic(startDate: Date, endDate: Date, patientNo: string, clinicId: number): Promise<number[]> {
    const query = {
      startDate: startDate.toLocaleDateString(),
      endDate: endDate.toLocaleDateString(),
      patientNo: patientNo,
      clinicId: clinicId
    };
    return await this.api.get(`GetAllRegisterOfClinic?`, query).toPromise<number[]>();
  }

  public async UpdateEMR(data: emrDto): Promise<any> {
    var ret = await this.api.post(`UpdateEMR`, data).toPromise<any>();
    return ret;
  }

  /** 衛星藥局補傳清單 */
  public async GetSupTranserList(query: GetAllQuery): Promise<PageData> {
    query.regDate = query.regDate ? new Date(query.regDate).toLocaleDateString() : "";
    query.startDate = query.startDate ? new Date(query.startDate).toLocaleDateString() : "";
    query.endDate = query.endDate ? new Date(query.endDate).toLocaleDateString() : "";
    return await this.api.get(`GetSupTranserList?`, query).toPromise<PageData>();
  }

  /** 取得後診人數，完診人數 */
  public async GetCount(query: GetAllQuery): Promise<RegisterCountDto> {
    query.regDate = query.regDate ? new Date(query.regDate).toLocaleDateString() : "";
    query.startDate = query.startDate ? new Date(query.startDate).toLocaleDateString() : "";
    query.endDate = query.endDate ? new Date(query.endDate).toLocaleDateString() : "";
    return await this.api.get('GetCount?', query).toPromise<RegisterCountDto>();
  }

  public async getHCAll(Bdate: string, Edate: string): Promise<PageData> {
    var data = await this.api.get('GetHCAll?Bdate=' + Bdate + '&Edate=' + Edate).toPromise<PageData>();
    return data;
  }

  public async getRangeData(Bdate: string, Edate: string, PatientId: string): Promise<RegisterEditDto[]> {
    var data = await this.api.get('GetRangeData?startDate=' + Bdate + '&endDate=' + Edate + '&patientId=' + PatientId).toPromise<RegisterEditDto[]>();
    data = data.map(d => {
      return Object.assign(new RegisterEditDto(), d)
    });
    return data;
  }

  /** 取得例外就醫名冊清單 */
  public async GetRegisterForAbnormal(query: GetRegisterForAbnormalQuery): Promise<RegisterEditDto[]> {
    query.startDate = query.startDate ? new Date(query.startDate).toLocaleDateString() : "";
    query.endDate = query.endDate ? new Date(query.endDate).toLocaleDateString() : "";
    return await this.api.get(`GetRegisterForAbnormal?`, query).toPromise();
  }

  /** 取得新增掛號的初始資料 */
  public async GetInitialDataForAdd(patientId: number, room: string): Promise<RegisterEditDto> {
    var data = await this.api.get('GetInitialDataForAdd?patientId=' + patientId + '&room=' + room).toPromise<RegisterEditDto>();
    // 因為此DTO帶有方法，所以要另外NEW
    return Object.assign(new RegisterEditDto(), data);
  }
  /** 新增 */
  public async Create(data: RegisterEditDto): Promise<number> {
    return await this.api.post('Create', data).toPromise<number>()
  }
  /** 新增預約掛號 */
  public async Reserve(data: ReserveCreateDto): Promise<number> {
    return await this.api.post('Reserve', data).toPromise<number>()
  }
  /** 更新預約掛號 */
  public async updateReserve(data: ReserveCreateDto): Promise<number> {
    return await this.api.post('UpdateReserve', data).toPromise<number>()
  }
  /** 刪除更新預約掛號 */
  public async deleteReserve(id:number): Promise<void> {
    return await this.api.delete('DeleteReserve?id='+id).toPromise()
  }
  /** 取得生理量測值列表 */
  public async GetVitalSign(pId: number): Promise<PageData2<VitalSign>> {
    return await this.api.get('GetVitalSign?pId=' + pId).toPromise<PageData2<VitalSign>>()
  }

  /** 取得患的病歷清單 */
  public async GetHistoryList(pId: number): Promise<RegHistListDto[]> {
    return await this.api.get('GetHistoryList?pId=' + pId).toPromise<RegHistListDto[]>();
  }

  /** 複製慢箋病歷 */
  public async CloneChronicPresNearest(data: WholeLargeHist): Promise<HistToICCardData> {
    return await this.api.post('CloneChronicPresNearest', data).toPromise<HistToICCardData>()
  }
  /** 複製過往病歷 */
  public async CloneHistFromStatus(data: RegisterEditDto,regId:number,cntNo:string,cntPresNo:string): Promise<HistToICCardData> {
    return await this.api.post(`CloneHistFromStatus?regId=${regId}&cntNo=${cntNo}&cntPresNo=${cntPresNo}`,data
    ).toPromise<HistToICCardData>()
  }
  /**修改 */
  public async Update(data: RegisterEditDto): Promise<RegisterEditDto> {
    var data = await this.api.put('Update', data).toPromise<RegisterEditDto>()
    // 因為此DTO帶有方法，所以要另外NEW
    return Object.assign(new RegisterEditDto(), data);
  }

  /** 取得查詢條件 */
  public async GetQueryOptions(): Promise<{room:string}> {
    var ret = await this.api.get('GetQueryOptions').toPromise<{room:string}>();
    //ret.timeSectionRange = new TimeSectionRange(ret.paramList);
    return ret;
  }

  /** 取得編輯選項 */
  public async GetEditOptions(): Promise<RegistEditOpt> {
    var opt = await this.api.get('GetEditOptions').toPromise<RegistEditOpt>();
    var today = DateHelper.today;
    today.setDate(today.getDate() + opt.regParam.MaxAppointmentDays);
    //從參數來的
    opt.MaxAppointmentDate = today;
    opt.IsPrintReceipt = opt.regParam.IsPrintReceipt;
    opt.timeSectionRange = new TimeSectionRange(opt.regParam);
    // 掛號櫃台修改掛號時，只能選擇部分狀態
    opt.fullStatusCode = opt.statusCode;
    opt.statusCode = opt.statusCode.filter(
      x => RegisterConst.StatusCodeList_CanSelect.indexOf(x.value) > -1);
    opt
    return opt;
  }

  /** 退掛不寫IC
  *
  * @param id
  */
  public async CancelRegister(id: number, multiReg: boolean, revertIcdt: Date = null, revertSecuritySeal: string = null): Promise<void> {
    var urlStrign = 'CancelRegister?id=' + id + '&multiReg=' + multiReg;
    if (revertIcdt) urlStrign += '&reverIcdt=' + revertIcdt;
    if (revertSecuritySeal) urlStrign += '&revertSecuritySeal=' + revertSecuritySeal;
    var ret = await this.api.put(urlStrign, null).toPromise();
    return ret;
  }

  public async GetWaitNo(date: Date | string, section: number, roomCode: string, isAppointment: boolean): Promise<number> {
    return await this.api.get('GetWaitNo', {
      date, section, roomCode, isAppointment
    }).toPromise<number>();
  }
  public async UpdateGiveUpNo(date: Date | string, section: number, roomCode: string, isAppointment: boolean, number: number): Promise<any> {
    return await this.api.put('UpdateGiveUpNo', {
      date, section, roomCode, isAppointment, number
    }).toPromise();
  }

  /**取得掛號計價費用(前納)
   *
   */
  async GetRegisterPaymentInfo(clinicId: number, isFirstVisit: string, discountCode: string, iType: string, ICode: string, sameTreat: string,doctorId:number = 0,birthDay:string,regDate:string): Promise<PaymentInfoDto> {
    const query = {
      clinicId: clinicId,
      isFirstVisit: isFirstVisit,
      discountCode: discountCode,
      ICode: ICode,
      SameTreat: sameTreat,
      IType:iType, //NhiConsultConvert: changeFlag,
      DoctorId:doctorId,
      BirthDay:birthDay,
      RegDate:regDate,
    };
    return this.api.post('GetRegisterPaymentInfo', query).toPromise<PaymentInfoDto>();
  }

  async GetDiscountByCode(code: string): Promise<Discount> {
    var ret: CustomResult = await this.api.get(`GetDiscountByCode?code=${code}`, {}).toPromise<CustomResult>();
    return ret.IsSuccess ? ret.Object : null;
  }

  async GetAgeDiscount(): Promise<Discount[]> {
    var ret = await this.api.get(`GetAgeDiscount`).toPromise<Discount[]>();
    return ret;
  }
  /**取得可執行預防保健項目
   *
   */
  async GetProphylacticHealth(clinicid: number, patientid: number, regdate: string): Promise<ICNoPHOption[]> {
    return this.api.get(`GetProphylacticHealth?clinicid=${clinicid}&patientid=${patientid}&regdate=${regdate}`, {}).toPromise<ICNoPHOption[]>();
  }
  async GetOtherIcodeSetting(data:string,regDate:string,spcIdentity:string= ''): Promise<any> {
    // console.log('reg',regDate);
    return this.api.get(`GetOtherIcodeSetting?birthDay=${data}&regDate=${regDate}&spcIdentity=${spcIdentity}`, {}).toPromise();
  }

  async GetAllProphylacticHealthByRegIcno(icno: string): Promise<string> {
    var ret = await this.api.get(`GetAllProphylacticHealthByRegIcno?icno=${icno}`, {}).toPromise<ICNoPHOption>();

    return  ret?.iccode || '';
  }

  async GetAllProphylacticHealth(): Promise<ICNoPHOption[]> {
    return this.api.get(`GetAllProphylacticHealth`, {}).toPromise<ICNoPHOption[]>();
  }
  async GetICNoOpts(patientid: number, regdate: string): Promise<{ph:ICNoPHOption[],icode:{[key:string]:ICNoPHOption[]}}> {
      return this.api.get(`GetICNoOpts?patientid=${patientid}&regdate=${regdate}`, {}).toPromise<{ph:ICNoPHOption[],icode:{[key:string]:ICNoPHOption[]}}>();
  }

  /**
   *更新欠卡的掛號資訊
   *
   */
  async ReturnICCard(data: RegisterEditDto): Promise<HistToICCardData> {
    var retuenICCardDto = new RegisterEditDto();
    retuenICCardDto = data;
    return this.api.post('ReturnICCard', retuenICCardDto).toPromise<HistToICCardData>();
  }
  /** 取得同療程清單 */
  async GetEveryTreatmentList(patientid: number, regdate: string,sameTreat:string): Promise<MutiTreatmentListDto[]> {

    var ret: CustomResult = await this.api.get(`GetEveryTreatmentList?patientid=${patientid}&regdate=${regdate}&sameTreat=${sameTreat}`, {}).toPromise<CustomResult>();
    return ret.IsSuccess ? ret.Object : '';
  }
  /**
   * 退卡後補NHIRegIC紀錄 (3i) -- 棄用
   *
   */
  // async RevertNHIRegIC(data: RegisterEditDto): Promise<any> {
  //   var ret = await this.api.post('RevertNHIRegIC', data).toPromise();
  //   return ret;
  // }

  async ChangeToSelfPay(regId: number, hasMultipltRegister: boolean, revertIcdt: any, revertSecuritySeal: any, withRevert: boolean): Promise<string> {
    const query = { id: regId, withRevert: withRevert }
    var ret: CustomResult = await this.api.get(`ChangeToSelfPay?id=${regId}&withRevert=${withRevert}`, {}).toPromise<CustomResult>();
    return ret.IsSuccess ? '' : ret.Object as string;
  }

  async GetInitHRx (data:HistOrder[]):Promise<HistOrder[]>{
    var ret: CustomResult = await this.api.post('GetInitHRx', data).toPromise<CustomResult>();
    console.log('retret ',ret,ret.Object);
    return ret.IsSuccess ? ret.Object : '';
  }

  // async UpdateNHIOrder(dto: RegisterEditDto, dtos: PrescriptionSave[]): Promise<NHIOrder[]> {
  //   var retuenICCardDto = new ReturnICCardDto();
  //   retuenICCardDto.registerDto = dto;
  //   retuenICCardDto.psDtos = dtos;
  //   var ret = await this.api.post('UpdateNHIOrder', retuenICCardDto).toPromise<CustomResult>();
  //   return ret.IsSuccess ? ret.Object : null;
  // }

  /**
   *取得欠卡資訊與款項
   *
   */
  async getArrearsData(clinicId: number, cId: string, date:string) {
    const query = { clinicId: clinicId, cId: cId,date:date };
    return this.api.get('GetArrearsList', query).toPromise();
  }

  async GetRehabitMedUesrs():Promise<UserSimplifyInfoDTO[]> {
    return this.api.get('GetRehabitMedUesrs').toPromise<UserSimplifyInfoDTO[]>();
  }

  async GetReserveData(patientId:number):Promise<ReserveDto>{
    return this.api.get('GetReserveData?id='+patientId).toPromise<ReserveDto>();
  }

  public async GetReserveByDate(date: Date): Promise<ReserveListDto[]> {

    var data = await this.api.get(`GetReserves?date=${date.toLocaleDateString()}`).toPromise<ReserveListDto[]>();

    return data;
  }

  /** H8 || 慢籤最近一次提示訊息 */
  public async GetLastPreDrugInfo(patientId: number, regDate: string): Promise<PresDateCheckInfo> {
    var data = await this.api.get('GetLastPreDrugInfo?patientId=' + patientId + '&regDate=' + regDate).toPromise<PresDateCheckInfo>();
    return Object.assign(new RegisterEditDto(), data);
  }

  public async GetRegisterSub(startDate: string, endDate: string, SortMethod: string): Promise<RegisterSubListDto[]>{
    return await this.api.get('GetRegisterSub', { startDate: startDate, endDate: endDate, sortBy: SortMethod }).toPromise<RegisterSubListDto[]>();
  }

  public async GetNoreportWithinTwoYears(RegDate : string): Promise<NoreportWithinTwoYearsListDto[]>{
    return await this.api.get('GetNoreportWithinTwoYears', { RegDate : RegDate }).toPromise<NoreportWithinTwoYearsListDto[]>();
  }

  public async GetUnreportedPrescription(startDate: string, endDate: string, SortMethod: string): Promise<UnreportedPrescriptionListDto[]>{
    return await this.api.get('GetUnreportedPrescription', { startDate: startDate, endDate: endDate, IsAgreeMsg: SortMethod }).toPromise<UnreportedPrescriptionListDto[]>();
  }

  /** 免掛號費及自付名冊報表之掛號檔PaymentMaster */
  public async GetRegFeebyPaymentOne(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyPaymentOne', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  public async GetRegFeebyPaymentTwo(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyPaymentTwo', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  public async GetRegFeebyPaymentThree(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyPaymentThree', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  /** 免掛號費及自付名冊報表之批價檔NHIDeclare */
  public async GetRegFeebyNHIDeclareOne(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyNHIDeclareOne', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  public async GetRegFeebyNHIDeclareTwo(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyNHIDeclareTwo', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  public async GetRegFeebyNHIDeclareThree(startDate: string, endDate: string, ICodeselect: string, Otherselect: string, chooseReport: string, chooseList: string, doctorType: boolean): Promise<Discount[]>{
    return await this.api.get('GetRegFeebyNHIDeclareThree', { startDate: startDate, endDate: endDate, ICodeselect:ICodeselect, Otherselect:Otherselect, chooseReport:chooseReport,chooseList:chooseList, doctorType:doctorType })
    .toPromise<Discount[]>();
  }

  /** 手術分析報表 */
  public async GetPSCxAnalyzeReport(startDate: string, endDate: string): Promise<pscxAnalyzeReportListDto[]>{
    return await this.api.get('GetPSCxAnalyzeReport', { startDate: startDate, endDate: endDate}).toPromise<pscxAnalyzeReportListDto[]>();
  }

  /** 慢病用藥追蹤報表 */
  public async GetPrescriptionTracking(startDate: string, endDate: string, isPrescriptionEnd: string, morethanDays: number, dx1Choose: string): Promise<PrescriptionTrackingListDto[]>{
    return await this.api.get('GetPrescriptionTracking', { startDate: startDate, endDate: endDate, isPrescriptionEnd: isPrescriptionEnd, morethanDays: morethanDays, dx1Choose: dx1Choose}).toPromise<PrescriptionTrackingListDto[]>();
  }
}
