<ng-template #checkColumn let-data let-col="col" let-row="index">
  <div class="multi-btns text-center custom-m-auto" style="flex-grow:1">
    <button type="button" (click)="onedit(data,row)" class="my-1 text-xs btn-grid px-1 background-none"
      *appAllowPermissionIf="[[this.UserPermissions.Modify],null,(editRow===row && !hideEditBtn) || hideEditBtn]">
      <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="編輯">edit</mat-icon>
      <span class="text-xs-sm float-right">編輯</span>
    </button>
    <button type="button" *ngIf="editRow===row && !hideEditBtn" (click)="oncancel(data,row)"
      class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none">
      <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="取消">cancel</mat-icon>
      <span class="text-xs-sm float-right">取消</span>
    </button>
    <button type="button" *ngIf="editRow===row && !hideEditBtn" (click)="onsave(data,row)"
      class="my-1 text-xs btn-grid px-1 background-none">
      <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="儲存">save</mat-icon>
      <span class="text-xs-sm float-right">儲存</span>
    </button>
    <button type="button" (click)="ondelete(data,row)"
      class="my-1 text-xs btn-grid btn-grid-cancel px-1 background-none"
      *appAllowPermissionIf="[[this.UserPermissions.Delete],null,editRow!==row && deletable && !hideEditBtn]">
      <mat-icon class="vis-combine-icon d-inline-flex mr-1" title="刪除">delete</mat-icon>
      <span class="text-xs-sm float-right">刪除</span>
    </button>
    <ng-container *ngTemplateOutlet="contentRef;context: {$implicit: data,col:col,index:row}"></ng-container>
  </div>
</ng-template>
