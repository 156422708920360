import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EasyFormComponent } from "../shared/components/easy-form/easy-form.component";
import { FieldDefine, FormDefine, FormDefineT } from "../shared/components/easy-form/form-define";
import { AnnouncementApi } from "./api-service/announcement/announcement-api";
import { UserConfirmService } from "./user-confirm.service";
import { WholeHist } from "./api-service/hist/whole-hist";
import { SessionService } from "./session.service";
import { ValueTextPair } from "../shared/models/value-text-pair";
import { AgeYearDiff } from "../shared/utilities";
import { UserCache } from "./user-cache";
import { ParameterApi } from "./api-service/parameters/parameter-api";
import { TimeSectionRange } from "./api-service/register/time-section-range";

@Injectable({
    providedIn: 'root'
})
export class AnnounceAlertService {

    private announcementAlert: AnnouncementAlert[];
    private timeSectionRange: TimeSectionRange;
    constructor(private annoApi: AnnouncementApi,
        private userConfirm: UserConfirmService,
        private ses: SessionService,
        private paramApi: ParameterApi
    ) {

    }
    /** 初始化 */
    async init() {
        var a = await this.annoApi.getSysAlert();
        this.announcementAlert = a.map(x => {
            return {
                Id: x.Id,
                Title: x.Title,
                Content: x.Content,
                AlertOn: JSON.parse(x.AlertOn || '[]')
            }
        });
        var par = await this.paramApi.GetParams('REG001');
        this.timeSectionRange = new TimeSectionRange(par);

    }
    /** 登入顯示公告 */
    async alertOnLogin() {
        var needAlert = this.announcementAlert.filter(x => x.AlertOn.some(y => y.AlertOn == 'LOGIN'));
        for (let a of needAlert) {
            await this.userConfirm.showAlert('[系統公告] ' + a.Title, a.Content, { width: 450 });
        }
    }
    /** 門診顯示公告 */
    async alertOnOpd(hist: WholeHist) {
        var alerted = [];
        // 每次
        var announceOPD = this.announcementAlert.filter(x => x.AlertOn.some(y => y.AlertOn == 'OPD'));
        // 如果滿足條件則叫
        alerted = [...alerted, ...await this.alertOpd(hist, announceOPD, alerted,'OPD')];

        var sec = TimeSectionRange.getSectionValue(this.timeSectionRange);
        var announceOpd_sec = this.announcementAlert.filter(x => x.AlertOn.some(y => y.AlertOn == 'OPD_SEC'));
        var a = this.ses.getData('AnnAlert')
        if (!a) {
            a = [{ sec: sec, ann: [] }]
            this.ses.setData('AnnAlert', a);
        }
        // 排除掉時段已經叫過的
        var secAlert = a.find(x => x.sec == sec);
        var secAlerted = secAlert.ann;
        announceOpd_sec = announceOpd_sec.filter(x => !secAlerted.some(id => id == x.Id))

        var newAlert = await this.alertOpd(hist, announceOpd_sec, alerted,'OPD_SEC');
        // 回存回session中叫過的
        alerted = [...alerted, ...newAlert];
        secAlerted = [...secAlerted, ...newAlert];
        secAlert.ann = secAlerted;
        this.ses.setData('AnnAlert', a);


    }
    private async alertOpd(hist: WholeHist, announceOPD: AnnouncementAlert[], alerted: number[],alertOn:AlertOnType) {
        var newAlert = [];
        for (let a of announceOPD) {
            if (alerted.some(x => x == a.Id)) {
                continue;
            }
            var needAlert = false;
            var cond = a.AlertOn.filter(x => x.AlertOn == alertOn);
            for (let c of cond) {
                var condVal = this.getValueFromCond(hist, c)
                if (this.compareValueFromCond(condVal, c)) {
                    needAlert = true;
                    break;
                }
            }
            if (needAlert) {
                await this.userConfirm.showAlert('[系統公告] ' + a.Title, a.Content, { width: 450 });
                newAlert.push(a.Id);
            }
        }
        return newAlert;
    }
    private getValueFromCond(hist: WholeHist, c: AlertOn) {
        var condVal: any = null;

        if (c.AlertCondition == 'patient') {
            if (c.AlertConditionCol == 'streat') {
                condVal = hist.Patient.City + hist.Patient.Area + hist.Patient.Street;
            } else if (c.AlertConditionCol == 'age') {
                condVal = AgeYearDiff(hist.Patient.Birthday, hist.Register.RegDate);
            }
        } else if (c.AlertCondition == 'clinic') {
            var clinic = UserCache.getLoginUser().Clinic;
            if (c.AlertConditionCol == 'streat') {
                condVal = clinic.City + clinic.Area + clinic.Street;
            } else if (c.AlertConditionCol == 'nhiType') {
                condVal = clinic.NHIType;
            } else if (c.AlertConditionCol == 'clinicType') {
                condVal = clinic.Type;
            } else if (c.AlertConditionCol == 'medDepts') {
                condVal = clinic.MedDeptCode;
            }
        }
        return condVal;
    }
    private compareValueFromCond(v: any, c: AlertOn): boolean {
        if (c.AlertCondition == '') {
            return true;
        }
        switch (c.AlertConditionOp) {
            case '>': return v > c.AlertConditionValue;
            case '>=': return v >= c.AlertConditionValue;
            case '==': return v == c.AlertConditionValue;
            case '<=': return v <= c.AlertConditionValue;
            case '<': return v < c.AlertConditionValue;
            case '!=': return v != c.AlertConditionValue;
            case 'contain': return (v.toString() as string).indexOf(c.AlertConditionValue) >= 0;
        }

        return false;
    }
}

export declare type AlertOnType = '' | 'LOGIN' | 'REG' | 'OPD' | 'OPD_SEC';
export interface AlertOn {
    AlertOn: AlertOnType;
    AlertCondition: string;
    AlertConditionCol: string;
    AlertConditionOp: string;
    AlertConditionValue: string;
    AlertConditionOpt?: ValueTextPair[];
    AlertConditionColOpt?: ValueTextPair[];
    AlertConditionOpOpt?: ValueTextPair[];

}

export var alertOnOpt: ValueTextPair[] = [
    { value: '', text: '無' },
    { value: 'LOGIN', text: '登入時' },
    { value: 'REG', text: '掛號時' },
    { value: 'OPD', text: '門診每次' },
    { value: 'OPD_SEC', text: '門診每時段第一次' },
]
export var alertConditionOpdOpt: ValueTextPair[] = [
    { value: '', text: '無' },
    { value: 'patient', text: '患者' },
    { value: 'clinic', text: '診所' },
]
export var alertConditionPatOpt: ValueTextPair[] = [
    { value: 'streat', text: '地址', extension: 'str' },
    { value: 'age', text: '年齡', extension: 'number' },
]
export var alertConditionClinicOpt: ValueTextPair[] = [
    { value: 'streat', text: '地址', extension: 'str' },
    { value: 'nhiType', text: '特約類別', extension: 'enum' },
    { value: 'clinicType', text: '診所類別', extension: 'enum' },
    { value: 'medDepts', text: '科別', extension: 'enum' },
]

export var alertConditionOperatorStrOpt: ValueTextPair[] = [
    { value: '==', text: '等於', extension: 'enum' },
    { value: '!=', text: '不等於', extension: 'enum' },
    { value: 'contain', text: '包含', extension: 'enum' },
]
export var alertConditionOperatorNumberOpt: ValueTextPair[] = [
    { value: '>', text: '超過' },
    { value: '>=', text: '大於', extension: 'enum' },
    { value: '==', text: '等於', extension: 'enum' },
    { value: '<=', text: '小於', extension: 'enum' },
    { value: '<', text: '未滿', extension: 'enum' },
    { value: '!=', text: '不等於', extension: 'enum' },
]
export var alertConditionOperatorEnumOpt: ValueTextPair[] = [
    { value: '==', text: '等於', extension: 'enum' },
    { value: '!=', text: '不等於', extension: 'enum' },
]
export interface AnnouncementAlert {
    Id: number;
    Title: string;
    Content: string;
    AlertOn: AlertOn[]
}
