<div >
    <mat-icon style="width: 18px;height: 18px;font-size: 18px;vertical-align: middle;" title="國王的小幫手" (click)="showChat=!showChat" >
        chat_bubble_outline
    </mat-icon>
    <div *ngIf="showChat" style="position: absolute;right: 10px;display: flex;flex-direction: column;" class="mwt_border main_border">
        <span class="arrow_t_int"></span>
        <span class="arrow_t_out"></span>
        <div style="height: calc(100% - 40px);overflow: auto;" #chatContainer>
            <div *ngFor="let m of msg">
                <ng-container *ngIf="m.sender=='Bot'">
                    <ng-container *ngTemplateOutlet="bot;context: {$implicit: m}"></ng-container>
                </ng-container>
                <ng-container *ngIf="m.sender=='User'">
                    <ng-container *ngTemplateOutlet="user;context: {$implicit: m}"></ng-container>
                </ng-container>
            </div>
        </div>
        <hr style="width: 100%;height: 1px;color: black;margin: 0px;">
        <div style="height: 40px;display: flex;">
            <input style="flex-grow: 1;height: 100%;border-radius: 0px 0px 0px 10px;line-height: 1;"
                     (keyup)="keyup($event)" #chatInput/>
            <button (click)="submit()"><mat-icon>send</mat-icon></button>
        </div>
    </div>
</div>


<ng-template let-data #bot>
    <div class="chat_border bot-chat">
        <span class="arrow_l_int"></span>
        <span class="arrow_l_out"></span>
        {{data.text}}
    </div>
</ng-template>

<ng-template let-data #user>
    <div class="chat_border user-chat">
        <span class="arrow_r_int"></span>
        <span class="arrow_r_out"></span>
        {{data.text}}
    </div>
</ng-template>
