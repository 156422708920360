import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({
    selector: 'app-vis-button[submit], app-rect-button[submit]'
})
export class SubmitButtonDirective implements OnInit{
    
    constructor(public elementRef: ElementRef<HTMLElement>) {
        
    }
    ngOnInit(): void {
        var btn = this.elementRef.nativeElement.firstElementChild as HTMLButtonElement
        btn.type ='submit';
    }
}
