import { Injectable, TemplateRef } from '@angular/core';
import { MainLayoutComponent } from 'src/app/layout/main-layout/main-layout.component';

export declare type ConfirmBtn = {text:string,return: string,disabled?:boolean}
export class ConfirmData{
  title?: string;
  msg: string;
  template?: TemplateRef<any>
  templateData?:any;
  textYes?: string;
  hideYes?:boolean = false;
  disableYes?:boolean = false;
  textNo?: string;
  hideNo?:boolean = false;
  disableNo?:boolean = false;
  /** null: 不設定focus, true/false: focus到yes/no, string focus到自訂的return符合第一個 */
  focus?:any = null;
  width?:number = 250;
  /**  */
  customBtnStart?:ConfirmBtn[]
  customBtnMid?:ConfirmBtn[]
  customBtnEnd?:ConfirmBtn[]
  fitContent?:boolean = false;
  /** 是否啟用輸入，true則解除keydown的綁定，不然如果template有input會不能用 */
  enableInput? = false;
  /** 是否顯示等候中的轉圈圈 */
  wait?:'wait'|'none'|'done' = 'none';
}

export class AlertOpt{
  template?: TemplateRef<any>
  templateData?:any;
  textYes?: string;
  width?:number = 250;
  fitContent?:boolean = false;
  enableInput?:boolean = false
}

@Injectable({
  providedIn: 'root'
})
export class UserConfirmService {
  mainlayout: MainLayoutComponent;
  constructor() { }
  async showConfirm(data?: ConfirmData){
    return await this.mainlayout.showCustomConfirm(data);
  }
  async showAlert(title:string, msg:string, opt?: AlertOpt){
    var cf: ConfirmData= {
      msg:msg,
      title:title,
      hideNo:true,
      textYes:'確認',
      focus:true,
    };
    if(opt){
      Object.assign(cf,opt);
    }
    return await this.mainlayout.showCustomConfirm(cf);
  }
  async showWaiter(title:string, msg:string, opt?: AlertOpt){
    var cf: ConfirmData= {
      msg:msg,
      title:title,
      hideNo:true,
      disableYes: true,
      textYes:'確認',
      focus:true,
      wait:'wait'
    };
    if(opt){
      Object.assign(cf,opt);
    }
    await new Promise<void>((rs,rj)=>{
      this.mainlayout.showCustomConfirm(cf);
      setTimeout(() => {
        rs();    
      }, 200);
    })
    return cf;
  }
}
