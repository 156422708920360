import { Dosage } from 'src/app/opd/dosage/models/dosage';
import NP from 'number-precision';
import { OrderTypeDesc, OrderTypeEnum } from 'src/app/enums/OrderTypeEnum';
import { HistService } from '../services/hist.service';
import { Rx } from 'src/app/services/api-service/rx/rx';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';
import { AgeYearMonthDiff, AgeYearTruthDiff, Compare, isNullOrUndefined } from 'src/app/shared/utilities';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { FillData, IRxTypeFillRule, RxFillRuleFactory } from './hrx-fillrule';
import { ValidateMsg } from './hrx-validate-msg';
import { OrderSpecialRuleEnum } from 'src/app/enums/OrderSpecialRuleEnum';
import { CheckPrescribeDto, HistApi } from 'src/app/services/api-service/hist/hist-api';
import { OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { AutoFillRule, IHRx, IHrxCalc, IPatient, QTYRule } from './hrx-calcr.models';
import { CalcRuleFactory } from './hrx-calcrule.factory';
import { WholeHistRegisterDto } from 'src/app/services/api-service/hist/whole-hist';
import { RegisterConst } from 'src/app/shared/constants/register-const';
import { PreventiveHealthCareDesc } from 'src/app/enums/RegisterTypeEnum';

export class HrxCalc implements IHrxCalc {
  // getCalcRule(): ICalcRule {
  //   // 特殊1 需要總次同步的P醫令
  //   if(this.rxCodeQtyAsTotal.indexOf(this.model.StdCode)>=0 ){
  //     return new CalcRuleDoseAsTotal(this.model);
  //   }
  //   // 特殊2 不適用於計算的情況
  //   if ( this.isSkipCalcDose()) {
  //     return new CalcRuleNoAct(this.model);
  //   }

  //   // 口服藥用的規則

  //   // 各量法
  //   if (this.model.QtyRule == QTYRule.Dose) {
  //     return new CalcRuleDose(this.model, this);
  //   } else if (this.model.QtyRule == QTYRule.DailyDose) {
  //     return new CalcRuleDaily(this.model,this);
  //   } else if (this.model.QtyRule == QTYRule.TotalDose) {
  //     return new CalcRuleTotal(this.model,this);
  //   }
  // }
  private rxFillRule: IRxTypeFillRule;
  /**
   *
   */
  constructor(
    public model: IHRx,
    public rx: Rx,
    private hrxs: IHRx[],
    private dosages: Dosage[],
    private histParams: HST001Params,
    private fillByPrevType: string,
    public decimalRule: string,
    public wayOptions: SystemCode[],
    public patient: IPatient,
    public regDate: Date,
    public histApi: HistApi,
    public histService:HistService,
    public weight?:number,
    public dosageMapType?:'age'|'weight'
  ) {
    this.rxFillRule = RxFillRuleFactory.GetFillRule(this)
  }
  /** 可使用診所預設劑量的歸屬 */
  rxTypesCanFillByClinicDefault = [OrderTypeEnum.T2_OralDrugFee] // 2
  /** 針劑4/特材11 */
  rxTypeInj = [OrderTypeEnum.T4_InjectionDrugFee, OrderTypeEnum.T12_SpecialMaterialFee];
  /** 其他歸屬 6~11 */
  rxTypeOther = [ OrderTypeEnum.T6_Lab, OrderTypeEnum.T7_XRay,  //6 7
                  OrderTypeEnum.T8_RehabilitationFee, OrderTypeEnum.T9_TreatmentFee, //8 9
                  OrderTypeEnum.T10_SurgeryFee, OrderTypeEnum.T11_AnesthesiaFee] // 10 11
  /** 輸入即次量即總量的標準碼 */
  rxCodeQtyAsTotal = ['P1015C','P1016C'];

  public findDosage(): Dosage {
    if (!this.dosages || !this.model.Freq) {
      return null;
    }
    let ds1 = this.dosages.find(ds => ds.Code.toUpperCase() === this.model.Freq.toUpperCase());

    // 自由輸入視為按醫囑 [AsOrder]
    if (HistService.EnableFreqFree && ds1 == null) {
      ds1 = this.dosages.find(ds => ds.Code.toUpperCase() === 'ASORD');
    }
    return ds1;
  }
  /** 輸入量/頻率/日數 是否為空 */
  private checkQTYEmpty() {
    if (!this.model.QTY ) {
      return true;
    }
    return false;
  }
  private checkFreqDayEmpty() {
    if ((!this.model.Freq && !this.model.FreqQTY) ||
      (!this.model.Days && !this.model.InfoDays)) {
      return true;
    }
    return false;
  }
  //#region 量法初始值
  /** 初始化輸入量 */
  initDose() {
    // 套餐帶的資料在此之前已經會放在model內

    //特殊規則
    var referralRx = ['01037C', '01036C', '01038C', '01037', '01036', '01038'];  // 轉診醫令
    var is列印指示用文字醫令 =  this.model.RxCode.startsWith('/')
    if(is列印指示用文字醫令){
      this.initDoseOnePrintOrder()
    }else if (referralRx.includes(this.model.StdCode)) {
      this.initDoseOneForAll();
    } else {
      // 最初的資料如果QTY空的，則案量法先填上對應來源的值
      if(!this.model.QTY){
        //設定對應劑量
        if(this.rx.RxDosages?.length>0){
          var d = this.rx.RxDosages.map(x=>x).sort((a,b)=>Compare(a.Range,b.Range));
          //P.79!=2 且有填體重 
          if(this.dosageMapType=='weight' &&this.weight){
            // 小到大排序後，找出第一個小於等於的
            var dose = d.find(x=>this.weight <= x.Range)?.Dose;
            // 有找到 && 有設定不為0 設定至輸入量初始值
            if(dose){
              this.model.QTY = dose;
            }
          }else if(this.dosageMapType == 'age'){
            var ageMonth = AgeYearMonthDiff(this.patient.Birthday,this.regDate);
            var totalMonth = ageMonth.year*12 + ageMonth.month;
            // 小到大排序後，找出第一個大於的
            var dose = d.find(x=>x.Range>=totalMonth)?.Dose;
            // 有找到 && 有設定不為0 設定至輸入量初始值
            if(dose){
              this.model.QTY = dose;
            }
          }
        }

        //上面沒設定或者是設定後為空或0，下面會繼續填上

        if (this.model.QtyRule == QTYRule.Dose) {
          this.model.QTY ||= this.model.Dose;
        }
        // 總量法補值規則
        else if (this.model.QtyRule == QTYRule.TotalDose) {
          this.model.QTY ||= this.model.TotalDose;
        }
        else if (this.model.QtyRule == QTYRule.DailyDose) {
          this.model.QTY ||= this.model.DailyDose;
        }
      }
      // 否則 反向修正 (有使用者會先打量在打醫令)
      else{
        if (this.model.QtyRule == QTYRule.Dose) {
          this.model.Dose = this.model.QTY;
        }
        // 總量法補值規則
        else if (this.model.QtyRule == QTYRule.TotalDose) {
          this.model.TotalDose = this.model.QTY;
        }
        else if (this.model.QtyRule == QTYRule.DailyDose) {
          this.model.DailyDose = this.model.QTY;
        }
      }

      // 以下方法皆為欄位為null才補值
      // RX設定
      this.fillByRx();
      // 符合條件的再以院所設定補
      this.fillByClinic();

      // 案同歸屬前筆
      this.fillByPrev()

      // 歸屬自己的規則
      this.fillByRxType()

      var ds = this.findDosage();
      if(ds){
        this.updateFreqQTY(ds);
      }
      // 次量法&口服才需要補上重算總量
      if (!this.model.TotalDose && this.model.RxType == 2 && (this.model.QtyRule == QTYRule.Dose || this.model.QtyRule == QTYRule.DailyDose )) {
        this.calcDose();
      }
    }
    this.calcPrice();
    this.fixDisplayValue();
  }
  /** 取得符合醫令歸屬的預設調劑方式 */
  defaultDispTP():string {
    var defValue = OrderDispensingTypeEnum.T0_Clinic;
    var rxType = this.model.RxType;
    var codeMap = this.histService.dispCodeMap.find(x=>x.types.some(y=>y==rxType))
    if(codeMap){
      defValue = codeMap.default(this.rx);
    }
    return defValue;
  }
  calcPrice() {
    let price = !isNaN(this.model.IPrice) ? this.model.IPrice : 0;
    let priceSelf = !isNaN(this.model.Price)? this.model.Price : 0;
    price =  priceSelf > 0 && this.model.SPRule == 2 ? priceSelf :price;
    let totalDose = !isNaN(this.model.TotalDose) ? this.model.TotalDose : 0;
    let roundedNumber = Math.round(price * totalDose);
    this.model.CalPrice = roundedNumber;
  }
  /** 通通設定為1 */
  private initDoseOneForAll() {
    this.model.QTY = 1
    this.model.Dose = 1;
    this.model.DailyDose = 1;
    this.model.TotalDose = 1;
    this.model.Days = 1;
    this.model.InfoDays = 1;
    this.model.Freq = '1';
    this.model.FreqQTY = 1;
  }

  private initDoseOnePrintOrder() {
    this.model.QTY ||= 1
    this.model.Dose ||= 1;
    this.model.DailyDose ||= 1;
    this.model.TotalDose ||= 1;
    //this.model.Days = 1;
    this.model.InfoDays ||= 1;
    //this.model.Freq = '1';
    this.model.FreqQTY ||= 1;
  }
  /** 使用診所定義的預設值填空,根據rxTypesCanFillByClinicDefault 與 IsByOral決定是否套用 */
  private fillByClinic() {
    // console.log('updateDrugByClinicDefault >>>>>', drug);
    var clinicDefault = this.histParams;
    if (this.rxTypesCanFillByClinicDefault.indexOf(this.model.RxType) >= 0 || this.rx?.IsByOral) {
      var fillData: FillData = {
        dose: clinicDefault.DefaultDose,
        days: clinicDefault.DefaultDays,
        freq: clinicDefault.DefaultFrequency,
        // 只有規則為口服途徑就不初始化
        way:  this.rx?.IsByOral?null:clinicDefault.DefaultWay,

        disp: null,
        spRule:null,
        totalDose: null
      }
      this.fill(fillData);
    }
  }
  /** 使用RX設定值填空 */
  private fillByRx() {
    var fillData: FillData = {
      spRule: this.rx?.SPRule??null,
      dose: this.rx?.DefaultDose,
      days: this.rx?.DefaultDays,
      freq: this.rx?.DefaultFrequency,
      way: this.rx?.DefaultWay,
      disp: this.rx?.DispTP,
      totalDose: this.rx?.DefaultTotalDose
    }
    this.fill(fillData);
  }
  /** 各歸屬預設值 */
  private fillByRxType() {
    var fillData: FillData = this.rxFillRule.init();
    fillData.disp = this.defaultDispTP();
    this.fill(fillData);
  }
  /** 用前筆同歸屬醫令補值 */
  private fillByPrev() {
    var lastSameTypeOrder = this.hrxs.reverse().find(x => x.RxType == this.model.RxType && x.RxCode != this.model.RxCode);
    if (!lastSameTypeOrder) {
      return;
    }
    var fillData: FillData = new FillData();
    switch (this.fillByPrevType) {
      // 1: 內服/外用藥 均使用前一筆同類型的帶入
      case '1':
        if (this.model.RxType == OrderTypeEnum.T2_OralDrugFee || this.model.RxType == OrderTypeEnum.T3_ExtDrugFee) {
          fillData.days = lastSameTypeOrder.Days;
          fillData.freq = lastSameTypeOrder.Freq;
          fillData.dose = lastSameTypeOrder.Dose;
          //fillData.totalDose = lastSameTypeOrder.TotalDose;
        }
        break;
      // 2: 僅外用藥 均使用前一筆同類型的帶入
      case '2':
        if (this.model.RxType == OrderTypeEnum.T3_ExtDrugFee) {
          fillData.days = lastSameTypeOrder.Days;
          fillData.freq = lastSameTypeOrder.Freq;
          fillData.dose = lastSameTypeOrder.Dose;
        }
        break;
      // 3: 內服/外用藥 劑量固定1, 其餘預設
      case '3':
        if (this.model.RxType == OrderTypeEnum.T2_OralDrugFee || this.model.RxType == OrderTypeEnum.T3_ExtDrugFee) {
          fillData.dose = 1;
        }
        break;
      // 4: 內服/外用藥 天數固定1, 其餘預設
      case '4':
        if (this.model.RxType == OrderTypeEnum.T2_OralDrugFee || this.model.RxType == OrderTypeEnum.T3_ExtDrugFee) {
          fillData.days = 1;
        }
        break;
    }
    this.fill(fillData);
  }
  /** 若為空則填入 */
  private fill(fillData: FillData) {
    var rule = this.model.QtyRule;
    // 0||=null 會變 null
    this.model.Freq ||= fillData.freq;
    this.model.Days ||= fillData.days;
    this.model.Way ||= fillData.way;
    this.model.Dose ||= fillData.dose
    this.model.TotalDose ||= fillData.totalDose;
    //以下null才處理 0有意義
    this.model.DispTP = (this.model.DispTP == null || this.model.DispTP == '')? fillData.disp:this.model.DispTP;
    this.model.SPRule = this.model.SPRule == null ? fillData.spRule:this.model.SPRule;
    // 根據規則決定輸日量要用哪個來源
    // 次量法補值規則
    if (rule == QTYRule.Dose) {
      this.model.QTY ||= fillData.dose;
    }
    // 總量法補值規則
    else if (rule == QTYRule.TotalDose) {
      this.model.QTY ||= fillData.totalDose;
    }
  }
  //#endregion

  //#region 量法計算
  /** 根據QtyDose重算對應劑量 */
  calcDose() {
    //特殊規則
    var referralRx = ['01037C', '01036C', '01038C', '01037', '01036', '01038'];  // 轉診醫令
    if (referralRx.includes(this.model.StdCode)) {
      this.model.Dose = 1;
      this.model.DailyDose = 1;
      this.model.TotalDose = 1;
      this.model.Days = 1;
      this.model.InfoDays = 1;
      this.model.Freq = '1';
      this.model.FreqQTY = 1;
    }
    else {
      var ds = this.findDosage();
      this.updateFreqQTY(ds);
      this.model.InfoDays = this.model.Days;
      var rule = CalcRuleFactory.getCalcRule(this);
      // 清除 (計算時 有頻日為空時) (口服ONLY)
      if (this.checkQTYEmpty() || this.checkFreqDayEmpty() && (this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.rx?.IsByOral)) {
        rule = CalcRuleFactory.getClearRule(this.model);
      }
      // 進行覆蓋式計算
      rule.QtyToDose()
    }
    this.calcPrice();
    this.fixDisplayValue()
  }
  /** 修正顯示內容
   *  N標記0時顯示空值
   *  (X)調劑自調時顯示空值
   *  註記額外文字
   */
  private fixDisplayValue(){
    if(this.model.SPRule == OrderSpecialRuleEnum.A0_ApplyPay){
      this.model.SPRule = null;
    }
    // if(this.model.DispTP == OrderDispensingTypeEnum.T0_Clinic){
    //   this.model.DispTP = null;
    // }
    // 備註
    this.model.Remark = this.model.Remark.replace('[自費]','').replace('[贈送]','')
    if (this.model.SPRule?.toString() == '2') {
      this.model.Remark = '[自費]' + this.model.Remark;
    } else if (this.model.SPRule?.toString() == '3') {
      this.model.Remark = '[贈送]' + this.model.Remark;
    }
  }
  calcQTY() {
    //特殊規則
    var referralRx = ['01037C', '01036C', '01038C', '01037', '01036', '01038'];  // 轉診醫令
    if (referralRx.includes(this.model.StdCode)) {
      this.model.Dose = 1;
      this.model.DailyDose = 1;
      this.model.TotalDose = 1;
      this.model.Days = 1;
      this.model.InfoDays = 1;
      this.model.Freq = '1';
      this.model.FreqQTY = 1;
      this.model.QTY = 1;
    }
    else {
      var rule =  CalcRuleFactory.getCalcRule(this);
      // 計算目標不為空時 可返算
      if(!rule.IsTargetEmpty()){
        var ds = this.findDosage();
        this.updateFreqQTY(ds);
        this.model.InfoDays = this.model.Days;
        // 跳過 (計算時 有頻日為空時) (口服ONLY)
        if (this.checkFreqDayEmpty() && (this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.rx?.IsByOral)) {
          //
          return;
        }
        // 反算覆蓋性看量法規則內定義
        rule.DoseToQty()
      }
    }
    this.calcPrice();
  }
  //#endregion


  //#region 補空
  /** 填完補值 */
  completeFill() {
    // 補頻率日份計算值
    this.autoFillByParam()
    // 補算
    this.autoFillByRxType()
  }

  /** (儲存前)按照參數的AutoFill來取得頻率日份計算值 */
  autoFillByParam() {

    // UI沒填得先把計算值清空, 最後檢查用
    if (!this.model.Days) {
      this.model.InfoDays = null;
    }else{
      this.model.InfoDays = this.model.Days;
    }
    if (!this.model.Freq) {
      this.model.FreqQTY = null;
    }else{
      this.updateFreqQTY(this.findDosage());
    }
    // 已有 不補
    if (this.model.InfoDays && this.model.FreqQTY) {
      return;
    }
    var autoFillRule = this.histParams.AutoFill;
    var sameRxType = this.hrxs.filter(x => x.RxType == this.model.RxType);
    var rowIndex = sameRxType.findIndex(x => x.Id == this.model.Id);
    switch (autoFillRule) {
      // 不補
      case AutoFillRule.NoFill: break;
      // 上往下
      case AutoFillRule.TopToEnd:
        // 當筆往上找
        for (let index = rowIndex - 1; index >= 0; index--) {
          var hrx = sameRxType[index];
          // 自己為空的才填
          if (hrx.InfoDays && !this.model.InfoDays) {
            this.model.InfoDays = hrx.InfoDays;
          }
          if (hrx.FreqQTY && !this.model.FreqQTY) {
            this.model.FreqQTY = hrx.FreqQTY;
          }
          // 皆有值則完成
          if (this.model.InfoDays && this.model.FreqQTY) {
            return;
          }
        }
        break;
      case AutoFillRule.EndToTop:
        // 當筆往下找
        for (let index = rowIndex + 1; index < sameRxType.length; index++) {
          var hrx = sameRxType[index];
          // 自己為空的才填
          if (hrx.InfoDays && !this.model.InfoDays) {
            this.model.InfoDays = hrx.InfoDays;
          }
          if (hrx.FreqQTY && !this.model.FreqQTY) {
            this.model.FreqQTY = hrx.FreqQTY;
          }
          // 皆有值則完成
          if (this.model.InfoDays && this.model.FreqQTY) {
            return;
          }
        }
        break;
    }

  }
  /** (儲存前)根據不同藥材歸屬來補缺值 */
  autoFillByRxType() {
    var fillData = this.rxFillRule.autoFill();
    this.model.Dose ||= fillData.Dose;
    this.model.DailyDose ||= fillData.DailyDose;
    this.model.FreqQTY ||= fillData.FreqQty;
    this.model.InfoDays ||= fillData.InfoDays;
    if (!this.model.TotalDose) {
      if (fillData.TotalDose) {
        this.model.TotalDose = fillData.TotalDose;
      }
      //this.fixTotal();
      this.calcPrice();
    }
  }

  //#endregion

  //#region validate
  /** 驗證並取得結果 */
  validate(): ValidateMsg[] {
    var msgs = [];
    // 自動填補來源未填
    msgs.push(...this.validateAutoFill());
    // 驗證
    msgs.push(...this.validateByRxType());
    // 檢查輸入規則有沒有掉
    msgs.push(...this.validateQtyRule());
    // 驗證執行人員
    msgs.push(...this.validateExecuter());
    // 判斷醫令限制(如: 年齡)
    msgs.push(...this.validateRxLimit());
    // 判斷醫令途徑
    msgs.push(...this.validateWay());
    return msgs;
  }
/**
 *檢查警告(呼叫api)
 */
async validateExt(): Promise<ValidateMsg[]> {
    var msgs = [];

    msgs.push(...this.validate());
    //每(月)用藥警告量
    msgs.push(...await this.validateRxMonthWarning());
    //間隔天數超過警告
    msgs.push(...await this.validateRxIntervalDay());
    msgs.push(...await this.validateRxAdultPHWarning());

    
    return msgs;
  }

  private async validateRxMonthWarning() {
    var ret: ValidateMsg[] = [];

    //用藥警告量/月
    //HIS: 已超出當月設定最大用量，讀當月HRx加總總量。
    let totalDose = Number(this.model.TotalDose) ?? 0;
    let rxMonthWarning = this.rx?.MonthWarning ?? 0;
    if (rxMonthWarning > 0) {
      let data = new CheckPrescribeDto();
      data.Id = this.model.Id;
      data.PatientId = this.patient.Id;
      data.RxCode = this.rx.RxCode;
      data.RegDate = new Date(this.regDate);
      let monthTotalDoseExcludeCurrent = await this.histApi.CheckMonthMaxPrescribeQty(data);
      let monthTotalDose = monthTotalDoseExcludeCurrent + totalDose;
      if (monthTotalDose >= rxMonthWarning) {
        ret.push(new ValidateMsg(data.RxCode, 'EVERYMONTH', 'WARNING', rxMonthWarning.toString(), monthTotalDose.toString()));
      }
    }

    return ret;
  }
  /** 判斷診所做成人健檢 N欄位不能為7 (申報需由醫院及診所申報) */
  private async validateRxAdultPHWarning() {
    var ret: ValidateMsg[] = [];
    var icnos = PreventiveHealthCareDesc.getAdultCareIcnos();
    var m = new ValidateMsg('', 'PHWrongSPRule','WARNING');
    var isExam = [OrderTypeEnum.T6_Lab, OrderTypeEnum.T7_XRay].indexOf(this.model.RxType) >= 0
    let isPH = this.histService.currentHist?.Register?.SameTreat == RegisterConst.SameTreat_2_預防保健 && icnos.indexOf(this.histService.currentHist?.Register?.Icno) >= 0
    let isNotDelcareByItSelf = this.model.SPRule == OrderSpecialRuleEnum.A7_ExtTest
    if (isNotDelcareByItSelf && isPH && isExam) {
      m.setMseeage(`成健及BC肝篩檢限由醫院或診所申報，請修正　N欄位標記！`)
      ret.push(m)
        // ret.push(new ValidateMsg('', 'EVERYMONTH', 'WARNING', rxMonthWarning.toString(), monthTotalDose.toString()));
    }
    return ret;
  }
  private async validateRxIntervalDay() {
    var ret: ValidateMsg[] = [];

    //間隔天數超過警告
    //HIS: 開此醫令間隔日期不足，設定>0判斷間隔天數，設定=0就檢查曾經開過。
    //WebHIS因初始值都是0，變成每筆都要去判斷曾經開過，所以此處先只做>0的判斷。
    let rxIntervalDays = this.rx?.IntervalDays;
    if (!isNullOrUndefined(rxIntervalDays) && rxIntervalDays > 0) {
      let data = new CheckPrescribeDto();
      data.Id = this.model.Id;
      data.PatientId = this.patient.Id;
      data.RxCode = this.rx.RxCode;
      data.RegDate = new Date(this.regDate);
      data.IntervalDays = this.rx.IntervalDays;
      let lastDate = await this.histApi.CheckLastPrescribeDate(data);
      if (lastDate) {
        lastDate = DateHelper.getROCFullDateString(new Date(lastDate), '/');
        ret.push(new ValidateMsg(data.RxCode, 'INTERVALDAYS', 'WARNING', rxIntervalDays.toString(), lastDate));
      }
    }

    return ret;
  }

  /** 判斷醫令限制(如: 年齡) */
  private validateRxLimit(): ValidateMsg[] {
    var ret: ValidateMsg[] = []
    var item = this.model;

    var birthday = this.patient.Birthday;
    var age = AgeYearTruthDiff(birthday, new Date());//判斷方式同pipe age
    //年齡低於幾歳不可使用
    var rxBelowtheAge = this.rx?.BelowtheAge ?? 0;
    if (rxBelowtheAge > 0 && !isNullOrUndefined(age) && age < rxBelowtheAge) {
      ret.push(new ValidateMsg(item.RxCode, 'BELOWAGE', 'ERROR', rxBelowtheAge.toString()));
    }
    //年齡高於幾歳不可使用
    var rxHigherthanAge = this.rx?.HigherthanAge ?? 0;
    if (rxHigherthanAge > 0 && !isNullOrUndefined(age) && age > rxHigherthanAge) {
      ret.push(new ValidateMsg(item.RxCode, 'HIGHERAGE', 'ERROR', rxHigherthanAge.toString()));
    }

    var qty = Number(item.QTY) ?? 0;
    var infoDays = Number(item.InfoDays) ?? 0;
    var totalDose = Number(item.TotalDose) ?? 0;
    //用藥警告量/次
    //HIS: 設定最大劑量
    var rxNumberWarning = this.rx?.NumberWarning ?? 0;
    if (rxNumberWarning > 0 && qty >= rxNumberWarning) {
      ret.push(new ValidateMsg(item.RxCode, 'EVERYTIME', 'WARNING', rxNumberWarning.toString(), qty.toString()));
    }
    //用藥警告量/日
    //HIS: 設定當日最大用量
    //量法規則=日量時，直接判斷輸入量QTY，否則是總量/總日數
    var dayQty = (this.model.QtyRule == QTYRule.DailyDose) ? qty : (infoDays == 0) ? 0 : Math.round(totalDose / infoDays * 100) / 100;
    var rxDayWarning = this.rx?.DayWarning ?? 0;
    if (rxDayWarning > 0 && dayQty >= rxDayWarning) {
      ret.push(new ValidateMsg(item.RxCode, 'EVERYDAY', 'WARNING', rxDayWarning.toString(), dayQty.toString()));
    }
    //超過天數警告
    //HIS: 設定最大天數
    var rxWarningDays = this.rx?.WarningDays ?? 0;
    if (rxWarningDays > 0 && infoDays >= rxWarningDays) {
      ret.push(new ValidateMsg(item.RxCode, 'EVERYINFODAYS', 'WARNING', rxWarningDays.toString(), infoDays.toString()));
    }

    return ret;
  }

  /** 驗證執行人員的資料完整 */
  private validateExecuter(): ValidateMsg[] {
    var ret: ValidateMsg[] = []
    var item = this.model;
    if (item.NeedExecuteDate == true && (isNullOrUndefined(item.SDate) || isNullOrUndefined(item.EDate))) {
      ret.push(new ValidateMsg(item.RxCode, 'EXECDT', 'WARNING'));
    }
    if (item.NeedExecuteTime == true && (item.STime.length == 0 || item.ETime.length == 0 || isNullOrUndefined(item.STime) || isNullOrUndefined(item.ETime))) {
      ret.push(new ValidateMsg(item.RxCode, 'EXECTIME', 'WARNING'));
    }
    if (item.NeedExecutor == true && (isNullOrUndefined(item.MedID) || item.MedID == "")) {
      ret.push(new ValidateMsg(item.RxCode, 'EXEC', 'WARNING'))
    }
    return ret;
  }
  /** 驗證途徑是否符合規定 */
  private validateWay(): ValidateMsg[] {
    //model.RxType
    var ret: ValidateMsg[] = []
    var rxTypeName = OrderTypeDesc.find(x=>x.value == this.model.RxType)?.text??this.model.RxType;
    var ways = this.wayOptions.filter(x => x.Name1 == this.model.RxType.toString()).map(x => x.Code);
    // 有設定途徑->歸屬的才進行檢查
    if (ways.length>0 && !ways.includes(this.model.Way?.toUpperCase())) {
        var msg = `藥代：${this.model.RxCode}  名稱：${this.model.ProdName}  途徑：${this.model.Way}  歸屬：${rxTypeName}`;
        if (!this.model.Way) {
            this.model.Way = ways[0];
            msg += '   * 預設給藥途徑:'+ ways[0];
        }
        var m = new ValidateMsg(this.model.RxCode, 'WAY', 'WARNING')
        ret.push(m)
        m.setMseeage(msg);
    }
    
    return ret;
  }
  /** 檢查輸入規則有沒有掉 */
  private validateQtyRule(): ValidateMsg[] {
    var ret: ValidateMsg[] = []
    var m = new ValidateMsg(this.model.RxCode, 'QTY','WARNING');
    if (this.model.QtyRule == QTYRule.Dose) {
      if(this.model.QTY!=this.model.Dose){
        m.setMseeage(`[${this.model.RxCode}]的輸入量: ${this.model.QTY} 與 次量: ${this.model.Dose} 不一致!`)
        ret.push(m)
      }
    } else if (this.model.QtyRule == QTYRule.DailyDose) {
      if(this.model.QTY!=this.model.DailyDose){
        m.setMseeage(`[${this.model.RxCode}]的輸入量: ${this.model.QTY} 與 日量: ${this.model.DailyDose} 不一致!`)
        ret.push(m)
      }
    } else if (this.model.QtyRule == QTYRule.TotalDose) {
      if(this.model.QTY!=this.model.TotalDose){
        m.setMseeage(`[${this.model.RxCode}]的輸入量: ${this.model.QTY} 與 總量: ${this.model.TotalDose} 不一致!`)
        ret.push(m)
      }
    }
    return ret;
  }
  /** 驗證可作為AutoFill來源的資料完整 */
  private validateAutoFill(): ValidateMsg[] {
    var ret: ValidateMsg[] = []
    var autoFillRule = this.histParams.AutoFill;
    var sameRxType = this.hrxs.filter(x => x.RxType == this.model.RxType);
    var fillSource = this.model;
    switch (autoFillRule) {
      // 上往下
      case AutoFillRule.TopToEnd:
        fillSource = sameRxType[0];
        break;
      case AutoFillRule.EndToTop:
        fillSource = sameRxType[sameRxType.length - 1];
        break;
    }

    if (fillSource) {
      if (!fillSource.InfoDays) {
        ret.push(new ValidateMsg(fillSource.RxCode, 'DAY', 'ERROR'))
      }
      if (!fillSource.FreqQTY) {
        ret.push(new ValidateMsg(fillSource.RxCode, 'FREQ', 'ERROR'))
      }
    }
    return ret;
  }
  /** (儲存前)根據不同藥材歸屬來補檢查未填 */
  private validateByRxType(): ValidateMsg[] {
    return this.rxFillRule.validate();
  }
  //#endregion

  //#region 其他一些方法


  /** 是否要跳過重算總量 */
  private isSkipCalcDose(): boolean {
    var ds = this.findDosage();
    // 補值得時候會出現Freq為空(ds==null) 但this.model.FreqQTY有值的情況
    if (!ds && !this.model.FreqQTY) return true;
    var cond = [
      (odr: IHRx) => odr.RxType != 800,
      (odr: IHRx) => ds?.Code != 'ASORD', //按吩咐
      (odr: IHRx) => ds?.Code != 'STAT', //按吩咐
      (odr: IHRx) => ds?.Code != 'PRN',   //需要時
      //(odr:HistOrder)=>odr.Freq !='STAT',  //現在立刻馬上
    ]
    var updateTotalDose = true;
    cond.forEach(x => updateTotalDose = updateTotalDose && x(this.model));
    return !updateTotalDose
  }

  /** 從Dosage找並更新 FreqQTY 為0者(無法明確定義數字者(PRN STAT ASODR ...))回傳FALSE */
  private updateFreqQTY(ds: Dosage): Dosage {
    this.model.FreqQTY = 0;
    if (this.model.Freq) {
      // step 1. 先查找代碼是否存在?
      // 有代碼 => 用Dosage資料得到InfoFrequency
      if (ds && ds.TMDay && ds.TMDay !== 0) {
        this.model.FreqQTY = NP.divide(ds.TMQty, ds.TMDay);
      } else {
        // 沒代碼 => 檢查是否為數字?
        if (!(isNaN(this.model.Freq as any))) {  // 是數字 => parse成數字
          this.model.FreqQTY = parseFloat(this.model.Freq);
        }
      }
    }
    if (this.model.FreqQTY === 0) {
      // 這裡Frequency不是null，但InfoFrequency = 0，代表是無法定義的狀態，如STAT，因此不去更動Total，讓user自行輸入
      return null;
    }
    return ds;
  }
  //#endregion
}

