export const FileUploadInsertKey = 'insertfile_';
export const FileUploadDeleteKey = 'deletefile_';

export class FileUploadSelected {
  id: number;
  file: any;
  is_upload_in_progress: boolean;
  upload_result: any;
  mark_result: any;

  constructor() {
    this.is_upload_in_progress = false;
    this.mark_result = '';
  }
}

export class FileUploadConfig {
  API: string;
  MIME_types_accepted: string;
  is_multiple_selection_allowed: boolean;
  max_file_limit: number;
  max_file_size_mb: number;
  selected_files: FileUploadSelected[];
  to_be_delete_files: FileUploadSelected[];
  is_new: boolean;
  is_edit: boolean;
};
