<div #reportContainer style="height: 600px; margin: 0px auto; overflow-y: auto;" *ngIf="reportType==='1'">
    <div style="background-color: white; height: 100%; width: 100%; font-family: '細明體';">
        <div id="container">
            <div style="margin: 10px auto;
                        height: 28.7cm;
                        width: 20.0cm;
                        padding-top: 0.5cm;
                        padding-right: 1.0cm;
                        padding-bottom: 0.5cm;
                        padding-left: 1.0cm;
                        display: block;
                        box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.3);
                        box-sizing: border-box;">
                <table style="width: 680px; border-spacing: 0px;">
                    <tr border="0">
                        <td colspan="3" style="height: 20px; padding-left: 5px; padding-right: 5px; font-family: '細明體'; font-size: 28px; text-align: center; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>{{ clinicName }}　</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td colspan="3" style="height: 20px; padding-left: 5px; padding-right: 5px; font-family: '細明體'; font-size: 26px; text-align: center; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>{{'診　斷　證　明　書'}}　</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 48%; padding-left: 5px; padding-right: 5px; font-family: '細明體'; font-size: 18px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>姓名：{{ patientName }}</strong>
                        </td>
                        <td style="width: 48%; padding-left: 5px; padding-right: 5px; font-family: '細明體'; font-size: 18px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>病歷號碼：{{ patientNo }}</strong>
                        </td>
                        <td rowspan="16" style="width: 4%; padding-left: 5px; padding-right: 5px; font-family: '細明體'; font-size: 18px; vertical-align: middle; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>◎本證明書須加蓋本院所印章否則無效◎</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>年齡：{{ (patientBirthday|age).replace('歲', '') }}</strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>性別：{{ patientSexName }}　職業：{{ patientJob }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>出生日期：{{ patientBirthday | formatROCDate:true:false }}</strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>身分證號：{{ patientCId }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>出生地：{{ patientBirthCity }} 　住址：{{ patientAddress }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 60px; padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>
                                應診日期：{{ diagnosisDate }}<br>
                                應診科別：{{ medDep }}
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 60px; padding-left: 5px; padding-right: 5px; font-size: 18px; vertical-align: top; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong style="word-break: break-all; font-size: 14px;">
                                {{ dates }}
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 160px; padding-left: 5px; padding-right: 5px; font-size: 14px; vertical-align: top; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <span style="white-space: pre-line"><strong>診斷病名：{{ dxName }}</strong></span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 160px; padding-left: 5px; padding-right: 5px; font-size: 14px; vertical-align: top; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <span style="white-space: pre-line"><strong>醫師囑言：{{ CCs }}</strong></span>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 50px; padding-left: 5px; padding-right: 5px; font-size: 14px; vertical-align: top;  text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>備註說明：{{ dexcertRemark }}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>以上病人經本院(所)醫師診斷屬實特予證明</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="height: 10px; padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            　　
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>院長：{{ clinicChief }}</strong>
                        </td>
                        <td rowspan="3" style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <div style="vertical-align: middle;">
                                <div style="float: left; display: inline-block;">院章：　</div>
                                <div style="float: left; display: inline-block; width: 140px; height: 140px; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                                    　　
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>開業執照：{{ businessLicense }}</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>診治醫師：{{ docName }}</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="vertical-align: middle; padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>證書字號：{{ medLicense }}</strong>
                        </td>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>
                                電話：{{ clinicPhone }}<br>
                                院址：{{ clinicAddr }}
                            </strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>開立日：{{ issuseDate | formatROCDate:true:false }}</strong>
                        </td>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>列印日：{{ issuseDate | formatROCDate:true:false }}</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td colspan="3" style="padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: center; font-family: '細明體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>{{'行政院衛生署92,10,14衛署醫字第0920213833號函　核定　'}}　</strong>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<div #enReportContainer style="height: 600px; margin: 0px auto; overflow-y: auto;" *ngIf="reportType==='2'">
    <div style="background-color: white; height: 100%; width: 100%; font-family: '微軟正黑體';">
        <div id="container">
            <div style="margin: 10px auto;
                        height: 28.7cm;
                        width: 20.0cm;
                        padding-top: 0.5cm;
                        padding-right: 1.0cm;
                        padding-bottom: 0.5cm;
                        padding-left: 1.0cm;
                        display: block;
                        box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.3);
                        box-sizing: border-box;">
                <table style="width: 680px; border-spacing: 0px;">
                    <tr border="0">
                        <td colspan="3" style="height: 20px; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 28px; text-align: center; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>{{ clinicName }}　</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td colspan="3" style="height: 20px; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 26px; text-align: center; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>{{'MEDICAL　DIAGNOSTIC　REPORT'}}　</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="width: 48%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>NAME：<span style="font-size: 14px;">{{ patientName }}</span></strong>
                        </td>
                        <td style="width: 48%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; text-align: left; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>CHART NO：<span style="font-size: 14px;">{{ patientNo }}</span></strong>
                        </td>
                        <td rowspan="16" style="width: 4%; padding-left: 5px; padding-right: 5px; font-family: '微軟正黑體'; font-size: 16px; vertical-align: middle; text-align: left; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>　</strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>AGE：<span style="font-size: 14px;">{{ (patientBirthday|age).replace('歲', '') }}</span></strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>GENDER：<span style="font-size: 14px;">{{ patientSexName }}</span>　JOB：<span style="font-size: 14px;">{{ patientJob }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>BIRTHDAY：<span style="font-size: 14px;">{{ getBirthday() }}</span></strong>
                        </td>
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>ID/PASSPORT NO：<span style="font-size: 14px;">{{ patientCId }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>NATIONALITY/BIRTH CITY：<span style="font-size: 14px;">{{ patientBirthCity }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>ADDRESS：<span style="font-size: 14px;">{{ patientAddress }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 60px; padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>
                                DATE OF VISIT：<span style="font-size: 14px;">{{ diagnosisDate }}</span><br>
                                DEPARTMENT：<span style="font-size: 14px;">{{ medDep }}</span>
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 60px; padding-left: 5px; padding-right: 5px; font-size: 16px; vertical-align: top; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong style="word-break: break-all; font-size: 16px;">
                                <span style="font-size: 14px;">{{ dates }}</span>
                            </strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 160px; padding-left: 5px; padding-right: 5px; font-size: 16px; vertical-align: top; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>DIAGNOSIS：<span style="font-size: 14px;">{{ dxName  }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 160px; padding-left: 5px; padding-right: 5px; font-size: 16px; vertical-align: top; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>COMMENT：<span style="font-size: 14px;">{{ CCs }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="height: 50px; padding-left: 5px; padding-right: 5px; font-size: 16px; vertical-align: top;  text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>NOTE：<span style="font-size: 14px;">{{ dexcertRemark }}</span></strong>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 14px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                            <strong>THIS CERTIFICATES THAT THE ABOVE NAMED PATIENT SUFFERED FROM THE DISEASE STATED ABOVE.</strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="height: 10px; padding-left: 5px; padding-right: 5px; font-size: 18px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            　　
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>SUPERINTENDENT：<span style="font-size: 14px;">{{ clinicChief }}</span></strong>
                        </td>
                        <td rowspan="3" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <div style="vertical-align: middle;">
                                <div style="float: left; display: inline-block;">SIGNATURE：　</div>
                                <div style="float: left; display: inline-block; width: 140px; height: 140px; border-right: 1px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">
                                    　　
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>LICENSE NO：<span style="font-size: 14px;">{{ businessLicense }}</span></strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>ATTENDING PHYSICIAN：<span style="font-size: 14px;">{{ docName }}</span></strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td style="vertical-align: middle; padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>CERTIFICATE NO：<span style="font-size: 14px;">{{ medLicense }}</span></strong>
                        </td>
                        <td colspan="2" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>
                                TEL：<span style="font-size: 14px;">+886&nbsp;{{ clinicPhone }}</span><br>
                                ADDR：<span style="font-size: 14px;">{{ clinicAddr }}</span>
                            </strong>
                        </td>
                    </tr>
                    <tr border="0">
                        <td colspan="3" style="padding-left: 5px; padding-right: 5px; font-size: 16px; text-align: left; font-family: '微軟正黑體'; font-weight: bold; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            <strong>DATE OF ISSUE：<span style="font-size: 14px;">{{ engIssueDate }}</span></strong>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>