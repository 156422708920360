import { AfterViewInit, Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair, ValueTextPairNumberValue } from "src/app/shared/models/value-text-pair";
import { WebApiService } from 'src/app/services/web-api.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { PaymentListDto } from 'src/app/services/api-service/register/PaymentInfoDto';
import { InsuranceDeclarationComponent } from 'src/app/nhi-assist/insurance-declaration/insurance-declaration.component';

@Component({
  selector: 'app-insurance-declaration-report',
  templateUrl: './insurance-declaration-report.component.html',
  styleUrls: ['./insurance-declaration-report.component.css']
})
export class InsuranceDeclarationReportComponent implements OnInit, AfterViewInit {

  reportName = '保險資料檔';
  forExcel = true;

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );

  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  upperboundIndex: number = 0;
  // showDoctorNameColume: boolean;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  // totalRecord: number = 0;
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  startDate: String;
  endDate: String;

  // 清單
  queryFormGroup: FormGroup;
  listItems: PaymentListDto[] = [];
  data: PaymentListDto[];
  InsuranceDeclarationComponent: InsuranceDeclarationComponent;
  totalRecord: number;
  api: WebApiService;
  localPrintService: any;

  Seltype: ValueTextPair[] = [
    { value: '1', text: '依病歷號排序' },
    { value: '2', text: '依初診日排序(由近到遠)' },
    { value: '3', text: '依初診日排序(由遠到近)' },]

    ReportType: ValueTextPair[] = [
      { value: '1', text: '列印金額核對表' },
      { value: '2', text: '列印01案類之病患' },
      { value: '3', text: '列印非01案類之病患' },
      { value: '4', text: '列印沒有自負額之病患' },
      { value: '5', text: '列印有檢查處置之病患' }];


  constructor(private webApiClient: WebApiClient,
    private hcrService: HcrService,
    private notification: EasyNotificationService) {
    this.api = this.webApiClient.createHisService('payments/payment');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  // 取得保險資料
  async fetchData(data: PaymentListDto[],startDate: string, endDate: string,forExcel = true) {
    this.forExcel = forExcel;
    this.elContainer = document.getElementById("container");
     //顯示印表期間(已將格式改成y/m/d)
     this.startDate = startDate;
     this.endDate = endDate;
     this.startDate = startDate ? DateHelper.getROCDateStringWithSepetator(new Date(startDate)) : "";
     this.endDate = endDate ? DateHelper.getROCDateStringWithSepetator(new Date(endDate)) : "";
    // this.startDate = startDate;
    // this.endDate = endDate;
    // this.startDate = this.startDate ? this.onGetDateString(this.startDate, true, '', false) : "";
    // this.endDate = this.endDate ? this.onGetDateString(this.endDate, true, '', false) : "";

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    // this.nowStr = this.onGetDateString(new Date(), true);
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    // this.timeStr = this.onGetTimeString(new Date());
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    // this._sDate = query.startDate;
    this._sDate = DateHelper.today;
    // this._eDate = query.endDate;
    this._eDate = DateHelper.today;

    this.listItems = data;
    this.totalRecord = this.listItems.length;
    this.insertListData();
}

  // #region 組報表
  // 組報表的html內容
  insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
    var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白

    // var html_total = this.createHTMLTotal();
    var html_blank = this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;

    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listItems[i],);

      elTableContent.innerHTML += html_Block;
  
      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上總計
      if (i == this.listItems.length - 1) {
        prevTableContent = elTableContent.innerHTML;
        // elTableContent.innerHTML += html_total;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          // elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      // if ((this.listItems[i].IsSummary || this.listItems[i].IsDoctorTotal) && !(i == this.listItems.length - 1)) 
      {
        // afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }

    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
    this.upperboundIndex = iPage;
    for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
    }
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    return `<tr>   
<th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                來診日
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                姓名
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                身分
              </th>
              <th
                style="text-align: center; width: 6%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                案類
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                診察費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                手術費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                麻醉費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                材料費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                內服費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                輸血費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                外用費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                注射費
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                注射技術費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                檢驗費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                X光費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                理療費
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;">
                處理費
              </th>   
            </tr>`;
  }
  createHTMLBlock(data: PaymentListDto) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var RegDate = data.RegDate ? this.onGetDateString(data.RegDate, true, '', false) : "";
    var ICode = (data.ICode == "000") ? '自費診' : '健保診';
    // var SameTreat 
    //  if (data.SameTreat == "NILL")
    //   SameTreat = '一般門診'


    // data.XXX== undefined ? '' : data.XXX 拿掉ubdefined顯示空值
    return `
      <tr>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${RegDate}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Name}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${ICode}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.SameTreat== undefined ? '一般門診' :data.SameTreat}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ADx== undefined ? '' :data.ADx}</td>  
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx10== undefined ? '' :data.ATx10}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx11== undefined ? '' :data.ATx11}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.AMat== undefined ? '' :data.AMat}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ARx2== undefined ? '' :data.ARx2}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx13== undefined ? '' :data.ATx13}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ARx3== undefined ? '' :data.ARx3}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ARx4== undefined ? '' :data.ARx4}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx5== undefined ? '' :data.ATx5}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx6== undefined ? '' :data.ATx6}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx7== undefined ? '' :data.ATx7}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx8== undefined ? '' :data.ATx8}</td>
        <td style="text-align: right;  ${commonStyle}" ${commonClassText}>${data.ATx9== undefined ? '' :data.ATx9}</td>
      </tr>`;
  }


  createHTMLPageHeader(iPage) {
    var reportName = '保險資料列印';
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                         期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                      
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = '保險資料列印';
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const reportName = '保險資料列印';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

   //就診日切割
 formatRegDates(RegDate: string): string {
  if (RegDate.length === 7) {
      // 切割年份、月份與日期
      const year = RegDate.substring(0, 3);
      const month = RegDate.substring(3, 5);
      const day = RegDate.substring(5, 7);

      // 返回格式化後的字串
      return `${year}/${month}/${day}`;
  } else {
      // 如果 birthday 不是7碼長，返回原始字串或空字串
      return RegDate;
  }
}

 //生日切割
  formatBirthday(birthday: string): string {
    if (birthday.length === 7) {
        // 切割年份、月份與日期
        const year = birthday.substring(0, 3);
        const month = birthday.substring(3, 5);
        const day = birthday.substring(5, 7);

        // 返回格式化後的字串
        return `${year}/${month}/${day}`;
    } else {
        // 如果 birthday 不是7碼長，返回原始字串或空字串
        return birthday;
    }
}
  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }
}
