import { Injectable } from "@angular/core";

import { NewBornCreateDto, NewbornViewDto, PatientsDto } from "./patientsDto";
import { DateHelper } from "../../../shared/helpers/date-helper";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { PatientEditOption } from "./patient-edit-option";
import { ContactDto } from "./contactDto";
import { RegisterEditDto } from "../register/RegisterEditDto";
import { isNullOrUndefined, onGetDateString } from "src/app/shared/utilities";
import { SexEnum } from "src/app/enums/SexEnum";
import { PatientPageICodeDto } from 'src/app/val-addapp/model/patientmultiedit';

declare type QueryObj = {
    PatientNo?: string,
    Name?: string,
    RegDate?: Date | string,
    Birthday?: Date | string,
    CId: string,
    Icno?: string,
    RoomCode?: string,
    DoctorId?: number,
    Icode?: string,
    Case?: string,
    SearchType?: string,
    SearchTypeReg?: string,
  }


export interface PatientHistDto
{
    Id:number;
    RegDate:Date;
    ClinicName:string;
    ClinicCode:string;
    RegId:number;
    PatientName:string;
    PatientNo:string;
    Icode:string;
    Icno:string;
    SameTreat:string;
    DoctorId?:number;
    DoctorName:string;
    RoomCode:string;
    Case:string;
    TreatCount:string;
    MedCode:string;
    DispTP:string;
    HistoryId:number;
    CC:string;
    Dx1:string;
    Dx2:string;
    Dx1Name:string;
    Dx2Name:string;
    Dx1EName:string;
    Dx2EName:string;
    Checked:boolean;
    ADcl?:number;
    AOwn?:number;
    Isqno:string;
    NhiId?:number;
    BeginTime:Date;
    /** 看診醫師身分證 */
    DoctorCId:string;
    /** 申報醫師身分證 */
    DeclareDrID:string;
    /** 已讀 */
    IsRead:boolean;

}

//統計結果
export interface DeclareStatisticsDto
{
  StatDate: string;//日期
  TotalCount: number;//總數
  LackCard: number;//欠卡
  Apply: number;//申請
  Case09: number;//09專
  CaseChronic: number;//慢專
  CaseChronic04: number;//04慢專
  CaseChronic08: number;//08慢專
  CaseOther: number;//它專
  SimpleForm: number;//簡表
  Normal: number;//一般
  Prepaid: number;//預保
  Special: number;//特件
  NoMedicine: number;//無藥
  HighMedicine: number;//高藥
  HighTotal: number;//高總
  ApplyAmount: number;//申請
}

//匯出Excel
export interface HistoryListDto
{
  Id: number;
  PatientNo: string;//病歷號
  PatientName: string;//姓名
  Birthday: Date;//生日
  CId: string;//身分證號
  Isqno?: string;//卡號
  Days?: number;//日份
  RegDate: Date;//就診日
  Icode?: string;//身
  DispTP?: string;//調劑
  Case?: string;//案類
  SameTreat?: string;//TP
  ARx?: number;//藥費
  Aother?: number;//其他
  AMed?: number;//藥事
  AOwn?: number;//自付
  ADcl?: number;//總額
  Condition?: string;//狀況
  HousePhone?: string;//電話
  CellPhone?: string;//手機
}

@Injectable({ providedIn: 'root' })
export class PatientHisApi {
  private apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
      this.apiService = this.apiClient.createHisService('patients/PatientHist');
  }

  async GetPatientHis(patientNo: string,pageSize: number,pageSkip: number) {
      const query = {
          // 時間轉換為ISO格式再回傳server
          PatientNo: patientNo,
          pageSize:pageSize,
          pageSkip:pageSkip,
      };
      // 查詢當天當診掛號紀錄
      var res = await this.apiService.get('GetAll', query).toPromise<PageData2<PatientHistDto>>();
      return res;
  }

  async GetAll(query: QueryObj): Promise<PageData2<PatientHistDto>> {
      return await this.apiService.get('GetAll', query).toPromise<PageData2<PatientHistDto>>();
  }

  async GetAll2(query: QueryObj): Promise<PageData2<PatientHistDto>> {
    return await this.apiService.post('GetAll2', query).toPromise<PageData2<PatientHistDto>>();
  }
  async GetSameTreatFourPerMonth(query: any): Promise<PatientPageICodeDto> {
    return await this.apiService.post('GetSameTreatFourPerMonth', query).toPromise<PatientPageICodeDto>();
  }

  async GetDeclareStatistics(query: any): Promise<PageData2<DeclareStatisticsDto>> {
    return await this.apiService.post('GetDeclareStatistics', query).toPromise<PageData2<DeclareStatisticsDto>>();
  }

  async GetHistoryList(query: any): Promise<HistoryListDto[]> {
    return await this.apiService.post('GetHistoryList', query).toPromise<HistoryListDto[]>();
  }

  async GetDeletedHistList(query: any): Promise<PageData2<PatientHistDto>> {
    return await this.apiService.post('GetDeletedHist', query).toPromise<PageData2<PatientHistDto>>();
  }
  async UpdateIsRead(id:number,readed: boolean): Promise<boolean> {
    return await this.apiService.post(`UpdateIsRead?id=${id}&readed=${readed}`,null).toPromise<boolean>();
  }

}
