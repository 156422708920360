import { Entity } from "src/app/shared/models/entity";

export class ContactDto extends Entity {
    /**
     *
     */
    constructor(patientId:number) {
      super();
      this.PatientMastId = patientId;
      this.Id = 0;
      this.Occupation = 0;
    }
    Id: number;
    CreateTime: Date;
    Creater: string;
    EditTime: Date;
    Editer: string;
    IsEnabled: boolean;
    IsAlive: boolean;

    PatientMastId: number;
    Name: string;
    Title: string;
    PhoneHomeArea: string;
    PhoneHome: string;
    PhoneCompanyArea: string;
    PhoneCompany: string;
    Mobile: string;
    EMail: string;
    ZipCode: string;
    Country: string;
    State: string;
    City: string;
    Area: string;
    Street: string;
    RelationCode: string;
    ProviderRelationCode: string;
    Occupation: number;
    OccupationName: string;
    Remark: string;
    MainPerson: boolean;
  }