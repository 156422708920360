import { QueryParam } from "../../queryParam";

export class PCSxEditDto {
  Id: number;
  Code: string;
  CName: string;
  EName: string;
  Short: string;
  IsEnabled: boolean
}

export class PCSxQuery extends QueryParam {
  Code?: string;
  CName?: string;
  ApplyCode?: string;
  IsPage?: boolean = true;
}

export class PCSxKeywordQuery extends QueryParam {
  Keyword?: string;
}

export class PCSxListDto {
  Id: number;
  Code: string;
  ApplyCode: string
  CName: string;
  EName: string;
  IsChecked: boolean = false;
}

export class PCSxAllocationQuery extends QueryParam {
  Code?: string;
  CName?: string;
  ApplyCode?: string;
  FilterContainsPCSx?: boolean;
}
