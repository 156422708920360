import { CompleteRecord } from "src/app/services/formhelper";
import { PageData2 } from "src/app/shared/models/page-data";

/**
 * 表示雙列表選擇器的類別。
 * @template T
 */
export class DualListSelector<Left, Right> {
  leftGrid: DualListSelectorGridContent<Left>;
  rightGrid: DualListSelectorGridContent<Right>;
  settings: DualListSelectorSetting<Left, Right>;

  constructor() {
    this.leftGrid = new DualListSelectorGridContent<Left>();
    this.rightGrid = new DualListSelectorGridContent<Right>();
    this.settings = new DualListSelectorSetting<Left, Right>();
  }
}

/**
 * 表示雙列表選擇器網格內容的類別。
 * @template T
 */
class DualListSelectorGridContent<T> {
  /**
   * 網格的數據。
   * @type {T[]}
   */
  data: PageData2<T>;

  /**
   * 網格的設置。
   * @type {DualListSelectorContentGridSetting}
   */
  gridSetting: DualListSelectorContentGridSetting;

  /**
   * 網格的列設置。
   * @type {DualListSelectorContentColumnSetting<T>[]}
   */
  columnSetting: DualListSelectorContentColumnSetting<T>[];

  constructor() {
    this.columnSetting = [];
  }
}

/**
 * 表示雙列表選擇器內容的網格設置類別。
 */
class DualListSelectorContentGridSetting {
  /**
   * Kendo grid 應該根據哪個屬性選擇項目。
   * @type {string}
   */
  kendoGridSelectBy?: string = '';

  /**
   * 已選擇項目的鍵值。
   * @type {string[]}
   */
  selectedKeys?: number[] = [];

  /**
   *CheckBox Key的名稱
   *
   * @type {string}
   * @memberof DualListSelectorContentGridSetting
   */
  checkBoxKey?: string;

  width?: string = "48%"
}

/**
 * 表示雙列表選擇器內容的列設置類別。
 * @template T
 */
class DualListSelectorContentColumnSetting<T> {
  /**
   * 列的字段名稱。
   * @type {string}
   */
  field: string;

  /**
   * 列的標題。
   * @type {string}
   */
  title: string;

  /**
   * 列的寬度。
   * @type {number}
   */
  width?: number = 40;

  /**
   * 國際化標題。
   * @type {string}
   */
  i18n?: string = "";

  /**
   * 指示這是否為基礎列。
   * @type {boolean}
   */
  isBase?: boolean = true;

  /**
   * 指示該列是否可編輯。
   * @type {boolean}
   * @default true
   */
  isCanEdit?: boolean = false;

  /**
   * 該列的驗證器。
   * @type {CompleteRecord<T>}
   */
  validators?: CompleteRecord<T> = null;
}

/**
 * 表示雙列表選擇器設置的類別。
 * @template T
 */
class DualListSelectorSetting<Left, Right> {
  /**
   * 移動到右側網格的設置。
   * @type {DualListSelectorMoveSetting<Right>}
   */
  rightMoveSetting: DualListSelectorMoveSetting<Right>;

  /**
   * 移動到左側網格的設置。
   * @type {DualListSelectorMoveSetting<Left>}
   */
  leftMoveSetting: DualListSelectorMoveSetting<Left>;

  /**
   * 指示右側網格是否展開。
   * @type {boolean}
   */
  isExpandRight?: boolean = false;

  /**
   * 指示左側網格是否展開。
   * @type {boolean}
   */
  isExpandLeft?: boolean = false;

  /**
   * 指示是否啟用分頁。
   * @type {boolean}
   * @default true
   */
  pageable?: boolean = true;

  /**
   * 每頁的項目數量。
   * @type {number}
   * @default 50
   */
  pageSize?: number = 50;

  /**
   * 指示是否啟用多選。
   * @type {boolean}
   * @default true
   */
  multiselect?: boolean = true;

  constructor() {
    this.rightMoveSetting = new DualListSelectorMoveSetting<Right>();
    this.leftMoveSetting = new DualListSelectorMoveSetting<Left>();
  }
}

/**
 * 表示雙列表選擇器的移動設置類別。
 * @template T
 */
class DualListSelectorMoveSetting<T> {
  /**
   * 指示是否啟用移動項目。
   * @type {boolean}
   * @default true
   */
  isEnable: boolean = true;

  /**
   * 當項目被移動時調用的函數。
   * @param {T} target - 目標項目。
   */
  onMove: (target: PageData2<T>, selectKeys: number[]) => void = () => { }; // 預設行為
}
