import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { OPD002Params } from 'src/app/services/api-service/parameters/OPD002Params';
import { HST001Params } from 'src/app/services/api-service/parameters/HST001Params';

export declare type DxOpt = {
  Code: string;
  ApplyCode: string;
  Short: string;
  CName: string;
  EName: string;
  ICD9: string;
}
// export declare type RxOpt = {
export class RxOpt {
  Id: number;
  RxCode: string;
  StdCode: string;
  Type: number;
  ProdName: string;
  IsByOral: boolean;
  MainSetId: number;
  RsCode: string;
  SPRule: number;
  CTRDrugs: string;
  IPrice: number;
  Price: number;
  IsDrugStatus: boolean;
  DrugStopDate: Date;
  AlternativeRxId?: number;
  PaymentType?: string;
  FlagDescription?: string;
}
// export declare type RsOpt = {
export class RsOpt {
  MainSetId: number;
  RxId: number;
  RxCode: string;
  StdCode: string;
  ProdName: string;
  RsCode: string;
  AlternativeRxId?: number;
}
export declare type statDetail = {
  Month: string[],
  Season: string[],
  Week: string[]
}
export declare type userStat = {
  ClinicStat: statDetail,
  UserStat: statDetail
}
export class HistEditOption {
  cc: SystemCode[];
  pe: SystemCode[];
  //dx:DxOpt[];
  //rx:RxOpt[];
  //rs:RsOpt[];
  statDx: userStat;
  statRx: userStat;
  statCC: userStat;
  statPE: userStat;

  dosage: Dosage[];
  way: SystemCode[];
  part: SystemCode[];
  chiProcess: SystemCode[];
  chiForm: SystemCode[];
  dispensing: ValueTextPair[];
  remarks: ValueTextPair[];
  histParams: HST001Params;
  opdParams: OPD002Params;
  // pharmarcist : ValueTextPairNumberValue[]; 暫無用到
  /** 預設調劑方式 */
  //defaultSelfDispensing: string;
  isShowPricingWindow: boolean;
  isAlertPaid: boolean;
  // 磨粉年齡
  p253: string;
  // 醫令新增預設劑量模式
  p255: string;
  // 門診預設Focus區域
  p15: string;
  // 交付慢箋加印張數
  p37: string;
  // 有無排班表
  p289: string;
  // 進位規則
  p135: string;

  doctor: ValueTextPairNumberValue[];
  executor?: ValueTextPair[];
  enableExecutor ?: ValueTextPair[];
  medUser: ValueTextPair[];
}
