import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-shared-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  @Input() isDialogOpened = false;

  // 點OK按鈕時emit true，Close或Cancel按鈕emit false
  @Output() emitResult = new EventEmitter<boolean>();

  ngOnInit() {
  }

  // 交給父元件用this.isDialogOpened來控制視窗開關，不自己關閉
  // 對話視窗
  onDialogClose(isOk: boolean) {
    this.emitResult.emit(isOk);
  }

}
