import { Injectable } from "@angular/core";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { Company } from "src/app/system/company/models/company";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

@Injectable({ providedIn: 'root' })
export class CompanyApi {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = this.apiClient.createHisService('system/company');
  }
  async CreateStd(): Promise<void> {
    var ret = await this.apiService.post('CreateStd', null).toPromise<void>();
    return ret;
  }
  async Update(company: Company): Promise<CustomResult> {
    return this.apiService.post('Update', company).toPromise();
  }
  async Create(company: Company): Promise<string> {
    return this.apiService.post('Create', company).toPromise<string>();
  }
  async GetAll(code: string, name: string, pageNum: number, pageSize: number): Promise<PageData2<Company>> {
    return this.apiService.get(`GetAll`,
      {
        code: code,
        name: name,
        pageSkip: (pageNum - 1) * pageSize,
        pageSize: pageSize
      }).toPromise();
  }
}
