import { Injectable } from '@angular/core';
import { WebApiClient } from '../../web-api-client.service';
import { WebApiService } from '../../web-api.service';
import { FamilyCareDto, FamilyCareMutliDto } from './familycaredto';

@Injectable({
  providedIn: 'root'
})
export class FamilycaryApi {
  api: WebApiService;


  constructor(
    private webApiFactory: WebApiClient) {
    this.api = this.webApiFactory.createHisService('nhi/FamilyCare');
    this.api.enableLoader = false;
  }
  async CreateFamilyCareCsv(data: any[]): Promise<any> {
    return await this.api.post('CreateFamilyCareCsv', data).toPromise<any>();
  }
  async GetFamilyCareByHistPatientId(patientId: number, inputRegDate: Date, isNew = false): Promise<FamilyCareMutliDto> {
    var regdate = new Date(inputRegDate).toLocaleDateString();
    console.log('isNew',isNew);
    return await this.api.get('GetFamilyCareByHistPatientId?patientId=' + patientId + '&regdate=' + regdate + '&isNew=' + isNew,{}).toPromise();
  }
  async GetFamilyCareSettingEditOptions(): Promise<any> {
    return await this.api.get('GetFamilyCareSettingEditOptions',{}).toPromise();
  }
  async CreateFamilyCareByDtoAsync(data: FamilyCareDto[]): Promise<any> {
    return await this.api.post('CreateFamilyCareByDtoAsync', data).toPromise();
  }
}
