import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HemodialysisEpoInjectionDataDto, HemodialysisEpoInjectionDataItemDto } from 'src/app/nhi-works/models/hemodialysis-epo-injection-data';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { AgeYearTruthDiff } from 'src/app/shared/utilities';

@Component({
  selector: 'app-report-hemodialysis-epo-injection',
  templateUrl: './report-hemodialysis-epo-injection.component.html',
  styleUrls: ['./report-hemodialysis-epo-injection.component.css']
})
export class ReportHemodialysisEpoInjectionComponent implements OnInit {

  reportName = '行政院衛生署中央健康保險局特約醫事服務機構透析日期及ＥＰＯ注射記錄表';
  shortReportName = '洗腎EPO注射記錄表';
  forExcel = false;

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
      top: 0, //cm
      right: 0, //cm
      bottom: 0.5, //cm
      left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  upperboundIndex: number = 0;
  nowStr: string = '';
  timeStr: string = '';
  totalRecord: number = 0;
  yearMonth: Date;
  dataReport: HemodialysisEpoInjectionDataDto;
  listItems: HemodialysisEpoInjectionDataItemDto[] = [];

  api: WebApiService;

  constructor(
    private webApiClient: WebApiClient,
    private localPrintService: LocalPrintService,
    private hcrService:HcrService,
    private notification: EasyNotificationService,
  ) {
    this.api = this.webApiClient.createHisService('declares/Declare');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 1px;padding-right: 1px;}\n' +//padding 5改1px
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '.pres-report2 tbody tr.header { display: none !important; }\n' +
      '.pres-report2 tbody:nth-child(1) tr.header { display: contents!important; }\n' +
      '.pres-report2 tbody th.tr-space { height:5px!important; }\n' +
      '.pres-report2 tbody tr.header-line{ border-bottom: 1px solid #777;height:5px!important; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  async fetchData(query: any) {
    this.yearMonth = query.queryMonth;
    this.elContainer = document.getElementById("container");
    this.dataReport = new HemodialysisEpoInjectionDataDto();
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);

    this.api.get('GetHemodialysisEpoInjectionReport', { clinicId: UserCache.getLoginClinicId(), declareMonth: DateHelper.getFormatedDateString(this.yearMonth) })
      .subscribe((data: HemodialysisEpoInjectionDataDto) => {
      this.dataReport = data;
      // this.listItems = data.Items.filter(x => x.IsSummary == false);
      this.listItems = data.Items;
      this.totalRecord = this.listItems.filter(x => x.IsSummary == false).length;
      // 將查詢的資料組成報表html
      this.insertListData();
    },
    error => {
      //清除預覽畫面
      this.listItems = [];
      this.elContainer.innerHTML = '';

      const haveMessage =  !!(error.message?.errMessage);
      const msg = haveMessage ? error.message.errMessage : error.message;
      if (msg) {
        this.notification.showError(msg);
      }
      else {
        // this.notification.showError(msg);
        this.notification.showGetDataFailed('report-hemodialysis-epo-injection');
      }
    });
  }

  // #region 組報表
  // 組報表的html內容
  insertListData(forExcel = false) {
    this.forExcel = forExcel;

    //轉Excel不要出現每頁合計(IsSummary每頁合計)
    if (this.forExcel) {
      this.listItems =  this.dataReport.Items.filter(x => x.IsSummary == false);
    }
    else {
      this.listItems =  this.dataReport.Items;
    }

    if (this.listItems.length == 0) {
      return;
    }

    // debugger
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var html_header = this.createHTMLPageHeader(iPage);         // 頁表頭
    var html_ListerHeader = this.createHTMLListHeader();        // List欄位顯示名稱
    var html_finish = this.forExcel ? '' : this.createHTMLFinish();                  // 報表結束 -- 以下空白
    // var html_total = this.forExcel ? '' : this.createHTMLTotal(this.dataReport.Items.filter(x => x.IsSummary == true)[0]);
    var html_total = this.createHTMLTotal(this.dataReport.Items.filter(x => x.IsSummary == true)[0], 'PAGESUM');
    var html_blank = this.forExcel ? '' : this.createHTMLBlankLine();
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var prevTableContent: any;
    var afterSummary = false;
    // 組報表內容
    // [頁表頭][List欄位顯示名稱]
    elPageContent.innerHTML = html_header;
    var elTableContent = document.getElementById("table-" + iPage + "-content");
    elTableContent.innerHTML = html_ListerHeader;
    for (let i = 0; i < this.listItems.length; i++) {
      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
        elTableContent.innerHTML += html_ListerHeader;
        afterSummary = false;
      }

      //單筆的html
      if (!this.listItems[i].IsSummary)
        html_Block = this.createHTMLBlock(this.listItems[i]);
      else {
        //總計的html
        if (i == this.listItems.length-1)
          html_Block = this.createHTMLTotal(this.listItems[i], 'TOTAL');
        //每頁合計的html
        else
          html_Block = this.createHTMLTotal(this.listItems[i], 'PAGESUM');
      }
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (!this.forExcel && elPageContent.offsetHeight > this.heightPageWithoutPadding) {
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        html_header = this.createHTMLPageHeader(iPage);
        elPageContent.innerHTML += html_header;
        elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        elTableContent.innerHTML += html_Block;

        this.pageContent[iPage] = [];
        this.pageContent[iPage].push(iBlock);
      } else {
        this.pageContent[iPage].push(iBlock);
      }
      // 最後一筆要加上合計 和 總計
      //改成產生Excel才跑這段，預覽則由上面IsSummary控制。
      // if (!this.forExcel && i == this.listItems.length-1) {
      if (this.forExcel && i == this.listItems.length-1) {
        // 總計
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_total;
        if (!this.forExcel && (elPageContent.offsetHeight > this.heightPageWithoutPadding)) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_total;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      // 報表畫面設計因素，如果遇到合計，後面要空一行再加入清單欄位表頭
      if (!this.forExcel && (this.listItems[i].IsSummary) && !(i == this.listItems.length-1)) {
        afterSummary = true;
        prevTableContent = elTableContent.innerHTML;
        elTableContent.innerHTML += html_blank;
        if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_blank;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
        } else {
          this.pageContent[iPage].push(iBlock);
        }
      }
      iBlock += 1;
    }
    elPageContent.innerHTML += html_finish;
    this.upperboundIndex = iPage;
  }

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    var page = '';
    //強制儲存格轉文字mso-number-format
    if (this.forExcel) {
      page += `
        <style>
          .excel-format {
            font-size: 10pt;
          }
          .excel-format-text {
            font-size: 10pt;
            mso-number-format: "@";
          }
        </style>`;
    }
    page += `
      <div class="page" id="page-` + iPage + `"
          style="
          height: ` + this.sizePage.height + `cm;
          width: ` + this.sizePage.width + `cm;
          padding-top: ` + this.paddingPage.top + `cm;
          padding-right: ` + this.paddingPage.right + `cm;
          padding-bottom: ` + this.paddingPage.bottom + `cm;
          padding-left: ` + this.paddingPage.left + `cm;
          display: block;
          margin: 0px auto;
          box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
          box-sizing: border-box;
          ">
          <div class="content" id="page-` + iPage + `-content">
          </div>
      </div>`;
      return page;
    }

  createHTMLBlankLine() {
    // return `<tr class="header-line d-contents">
    //           <th colspan="6" class="d-block tr-space" style="border-right: none;  border-left: none; border-bottom: none; border-top: none;"></th>
    //         </tr>`;
    return '';
  }

  createHTMLListHeader() {
    // let commonStyle = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 5px double black;';
    let commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; vertical-align: middle;';
    let commonStyle1 = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 0px solid black; border-top: 1px solid black; vertical-align: middle;';
    let commonStyle2 = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
    let commonStyle3 = 'border-right: 0px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
    let commonStyle4 = 'border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
    let commonStyle5 = 'border-right: 1px solid black;  border-left: 0px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
    let commonClass = this.forExcel ? 'class="excel-format"' : '';
    return `
      <thead>
        <tr>
          <th              style="text-align: center; width:   4%; color: blue; ${commonStyle1}" ${commonClass}>流水號</th>
          <th colspan="3"  style="text-align: center; width: 6.5%; color: blue; ${commonStyle1}" ${commonClass}>第一次洗腎</th>
          <th              style="text-align: left;   width:   7%; color: blue; ${commonStyle1}" ${commonClass}>姓名</th>
          <th              style="text-align: left;   width: 6.5%; color: blue; ${commonStyle1}" ${commonClass}>出生年月日</th>
          <th              style="text-align: center; width:   3%; color: blue; ${commonStyle1}" ${commonClass}>透析日期</th>
          <th colspan="15" style="text-align: center;                           ${commonStyle}"  ${commonClass}>透析日期及注射記錄</th>
          <th              style="text-align: right;                            ${commonStyle1}" ${commonClass}></th>
          <th colspan="6"  style="text-align: center;                           ${commonStyle}"  ${commonClass}>檢驗</th>
          <th rowspan="2"  style="text-align: center; width:   3%; color: blue; ${commonStyle}"  ${commonClass}>BUN或Cr值</th>
          <th rowspan="2"  style="text-align: center; width:   3%; color: blue; ${commonStyle}"  ${commonClass}>上月最後一次Hb值</th>
        </tr>
        <tr>
          <th style="text-align: center;              ${commonStyle2}" ${commonClass}></th>
          <th style="text-align: center; color: blue; ${commonStyle3}" ${commonClass}>年</th>
          <th style="text-align: center; color: blue; ${commonStyle4}" ${commonClass}>月</th>
          <th style="text-align: center; color: blue; ${commonStyle5}" ${commonClass}>日</th>
          <th style="text-align: left;   color: blue; ${commonStyle2}" ${commonClass}>身分證編號</th>
          <th style="text-align: right;  color: blue; ${commonStyle2}" ${commonClass}>年齡</th>
          <th style="text-align: center; color: blue; ${commonStyle2}" ${commonClass}>EPO劑量</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>1</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>2</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>3</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>4</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>5</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>6</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>7</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>8</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>9</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>10</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>11</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>12</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>13</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>14</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>15</th>
          <th style="text-align: right;  width: 5%;   ${commonStyle2}" ${commonClass}>EPO總量</th>
          <th style="text-align: center; width: 3%;   ${commonStyle}"  ${commonClass}>次數</th>
          <th style="text-align: center; width: 2.8%;   ${commonStyle}"  ${commonClass}>1</th>
          <th style="text-align: center; width: 2.8%;   ${commonStyle}"  ${commonClass}>2</th>
          <th style="text-align: center; width: 2.8%;   ${commonStyle}"  ${commonClass}>3</th>
          <th style="text-align: center; width: 2.8%;   ${commonStyle}"  ${commonClass}>4</th>
          <th style="text-align: center; width: 2.8%;   ${commonStyle}"  ${commonClass}>5</th>
        </tr>
      </thead>
      `;
  }

  createHTMLBlock(data: HemodialysisEpoInjectionDataItemDto) {
    // let summaryStyle = data.IsSummary? "border-top: 1px dotted #777;" : "border-top: none;";
    let commonStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black; vertical-align: middle;';
    let commonStyle1 = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 0px solid black; border-top: 1px solid black; vertical-align: middle;';
    let commonStyle2 = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 0px solid black; vertical-align: middle;';
    let commonClass = this.forExcel ? 'class="excel-format"' : '';
    let commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    let year = data.Sdt.substring(0, 3) ?? '';
    let month = data.Sdt.substring(3, 5) ?? '';
    let day = data.Sdt.substring(5, 7) ?? '';
    let age = AgeYearTruthDiff(data.Bdt, new Date());
    let birthday = DateHelper.getROCFullDateString(new Date(data.Bdt), '/');
    let [ext1Key, ] = this.createHTMLBlockExtend(data, 'EPO', 'KEY');
    let [ext1Value, notMatch] = this.createHTMLBlockExtend(data, 'EPO', 'VALUE');
    let [ext2Key, ] = this.createHTMLBlockExtend(data, 'HB', 'KEY');
    let [ext2Value, ] = this.createHTMLBlockExtend(data, 'HB', 'VALUE');

    return `
      <tr>
        <td             style="text-align: center; ${commonStyle1}" ${commonClassText}>${data.Sqno ?? ''}</td>
        <td rowspan="2" style="text-align: center; ${commonStyle}"  ${commonClassText}>${year}</td>
        <td rowspan="2" style="text-align: center; ${commonStyle}"  ${commonClassText}>${month}</td>
        <td rowspan="2" style="text-align: center; ${commonStyle}"  ${commonClassText}>${day}</td>
        <td             style="text-align: left;   ${commonStyle}"  ${commonClass}    >${data.Name ?? ''}</td>
        <td             style="text-align: left;   ${commonStyle1}" ${commonClassText}>${birthday}</td>
        <td             style="text-align: center; ${commonStyle}"  ${commonClass}    >日期</td>
        ${ext1Key}
        <td             style="text-align: right;  ${commonStyle1}" ${commonClass}    ></td>
        <td             style="text-align: center; ${commonStyle}"  ${commonClass}    >日期</td>
        ${ext2Key}
        <td             style="text-align: right;  ${commonStyle1}" ${commonClass}    >${data.Bun}</td>
        <td             style="text-align: right;  ${commonStyle1}" ${commonClass}    ></td>
      </tr>
      <tr>
        <td style="height: 35px; line-height: 35px; text-align: center; ${commonStyle2}" ${commonClassText}></td>
        <td style="text-align: left;   ${commonStyle}"  ${commonClassText}>${data.Cid ?? ''}</td>
        <td style="text-align: right;  ${commonStyle2}" ${commonClass}    >${age}</td>
        <td style="text-align: center; ${commonStyle}"  ${commonClass}    >劑量</td>
        ${ext1Value}
        <td style="text-align: right;  ${commonStyle2}  ${notMatch}" ${commonClass}    >${data.EPOTotal}</td>
        <td style="text-align: center; ${commonStyle}"  ${commonClass}    >Hb</td>
        ${ext2Value}
        <td style="text-align: right;  ${commonStyle2}" ${commonClass}    >${data.Cr}</td>
        <td style="text-align: right;  ${commonStyle2}" ${commonClass}    >${data.LHb}</td>
      </tr>
      `;
  }

  createHTMLBlockExtend(data: HemodialysisEpoInjectionDataItemDto, type: 'EPO'|'HB', show: 'KEY'|'VALUE'): [string, string] {
    // let summaryStyle = data.IsSummary? "border-top: 1px dotted #777;" : "border-top: none;";
    let summaryStyle = 'border-right: 1px solid black;  border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;';
    let commonClass = this.forExcel ? 'class="excel-format"' : '';
    let commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    let extData = type === 'EPO' ? data.EPOList : data.HbList;
    let limit = type === 'EPO' ? 15 : 5;//橫向最大格數
    let htmlExtend = '';
    let count = 0;
    let total = 0;
    Object.keys(extData).sort().forEach(key => {
      if (count++ <= limit) {
        let value = extData[key] ?? 0;
        total += value;
        value = value === 0 ? '/' : value;
        htmlExtend += `<td style="text-align: center; ${summaryStyle}" ${commonClassText}>${show === 'KEY' ? key ?? '' : value}</td>`;
      }
    });
    if (count < limit) {
      for (let i = count + 1; i <= limit; i++) {
        htmlExtend += `<td style="text-align: center; ${summaryStyle}" ${commonClassText}></td>`;
      }
    }

    return [htmlExtend, type === 'EPO' && total !== data.EPOTotal ? 'color: red;' : ''];
  }

  createHTMLTotal(data: HemodialysisEpoInjectionDataItemDto, type: 'PAGESUM'|'TOTAL') {
    let total = `
      <tr>
      `;
    //總計
    if (type == 'TOTAL') {
      total += `
        <td colspan="17" style="text-align: left; border: 0px solid black; border-style: none;"></td>
        <td              style="text-align: center; border: 1px solid black;">總計</td>
        <td colspan="2"  style="text-align: right; border: 1px solid black;">${this.dataReport.EPOTotalQty}</td>
      `;
    }
    //每頁合計
    else {
      total += `
        <td colspan="20" style="text-align: left; border: 0px solid black; border-style: none;"></td>
      `;
    }
    total += `
        <td              style="text-align: center; border: 1px solid black;">合計</td>
        <td colspan="2"  style="text-align: right; border: 1px solid black;">${this.forExcel ? this.dataReport.EPOTotalQty : data.EPOTotal}</td>
        <td colspan="5"  style="text-align: left; border-right: 0px solid black; border-left: 1px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">總金額NT$</td>
        <td colspan="2"  style="text-align: right; border-right: 1px solid black; border-left: 0px solid black; border-bottom: 1px solid black; border-top: 1px solid black;">元整</td>
        <td              style="text-align: center; border: 1px solid black;"></td>
      </tr>
      <tr>
        <td              style="text-align: center; border: 0px solid black; border-style: none; vertical-align: top;">附註：</td>
        <td colspan="30" style="text-align: left; border: 0px solid black; border-style: none;">
        1.CAPD患者註明EPO投予日期即可，並在備註欄寫CAPD。<br>
        2.本紀錄表請於費用申報時與門診處方及治療明細一併填報。<br>
        3.限門診洗腎患者申報，住院期間使用者，日期、劑量仍請註明填入，影印一份附於住院醫療費用醫令清單另行申報，不得與門診併計費用。<br>
        4.EPO使用者每次洗腎日均請填明劑量欄，使用時註明劑量，未使用時以(/)註明。<br>
        5.用量合計務必填寫清楚。<br>
        6.流水號欄請依中央健康保險局特約醫事服務機構門診處方及治療明細之流水號碼填字。
        </td>
      </tr>
      `;
    return total;
  }

  createHTMLPageHeader(iPage) {
    var pageHeader = '';
    // if (!this.forExcel) {
    if (true) {
      pageHeader += `
        <div id="header" style="margin: 0px 10px; ">
          <div style="display: flex;column-gap: 5px;">
            <table style="width: 100%; text-align: center; border: none; color: blue; ">
              <tr>
              <td colspan="31" style="height: 30px; text-align: center; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 標楷體">
              ${this.reportName}
              </td>
              </tr>
              <tr>
                  <td colspan="6" style="width: 25%; font-size: 11pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 細明體;">
                  申報年月： ${DateHelper.getROCDateString(new Date(this.yearMonth)).slice(0, 3)} 年 ${DateHelper.getROCDateString(new Date(this.yearMonth)).slice(3, 5)} 月份
                  </td>
                  <td colspan="23" style="width: 60%; font-size: 11pt; text-align: left; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 細明體;">
                  院所代號 / 名稱： ${this.dataReport.NHICode ?? ''} / ${this.dataReport.ClinicName ?? ''}
                  </td>
                  <td colspan="2" style="width: 15%; font-size: 11pt; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none; font-family: 細明體;">
                  </td>
              </tr>
            </table>
          </div>
        </div>
        `;
        //        頁次：${(iPage + 1)}
        // <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
        //   <label style="font-weight: bold; height: 14px;">　</label>
        // </div>
        // `;
    }
    pageHeader += `
      <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 0px; font-family: 細明體;">
        <div style="display: flex;column-gap: 5px;">
          <table id="table-${iPage}-content" style="width: 100%; font-size: 11pt; text-align: center; border: none; ">
          </table>
        </div>
      </div>
      `;
    return pageHeader;
  }

  createHTMLFinish() {
    // return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
    //             <label style="font-weight: bold;">以下空白</label>
    //         </div>`;
    return '';
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      //去除邊框, 實際印出到pdf
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
      html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
      html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, this.shortReportName, printContents);
      if (ret.Successed) {
        this.notification.showSuccess(this.shortReportName + '已送出列印!', true);
      } else {
        this.notification.showError(this.shortReportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const table = this.reportContainer.nativeElement;

      //html已有<table>經由ExportTableToExcel()後最外層會加上<table>，導致Excel第一行會空白，所以轉出時把table轉div。
      const html = this.forExcel ? table.innerHTML.replace('table', 'div') : table.innerHTML;

      await this.hcrService.ExportTableToExcel(this.shortReportName, html);
    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }

    this.forExcel = false;
  }

}
