import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
// his - shared
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { ValidationTipComponent as ValidTip } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
// this module
import { LabForm } from '../../models/labForm';
import { LabResult } from '../../models/lab-result';

import { DomSanitizer } from '@angular/platform-browser';

import { LabDataTypeEnum } from '../../models/lab-const';
import { LabApi } from 'src/app/services/api-service/labs/lab-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { LAB001Params } from 'src/app/services/api-service/parameters/LAB001Params';

@Component({
  selector: 'app-labs-report-query-dialog-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private webApiFactory: WebApiClient,
    private labApi:LabApi,
    private clinicData:ClinicDataService,
    private notification: EasyNotificationService,
    private domSanitizer: DomSanitizer
  ) {
    this.api = this.webApiFactory.createHisService('labs/reportQuery');
  }

  //#region 變數 ----------
  // api服務
  api: WebApiService;

  initInsp: LabForm;
  //#endregion ----------

  //#region 變數 表單 ----------
  // 編輯時需要的選項或數值
  @Input() editOptions: any;

  //#endregion 變數 表單 ----------

  get LabDataTypeNum() {
    return LabDataTypeEnum.Num;
  }

  get LabDataTypeIndex() {
    return LabDataTypeEnum.Index;
  }

  get LabDataTypeShortText() {
    return LabDataTypeEnum.ShortText;
  }

  get LabDataTypeLongText() {
    return LabDataTypeEnum.LongText;
  }


  async ngOnInit() {
    await this.getTestValueColors();
    // console.log(this.testValueColors);
  }

  // #region 表單物件-建立,銷毀
  
  //#endregion

  //#region View
  //#endregion

  view(id: number) {
    if (!id) {
      this.notification.showParameterError(); // 缺少必要參數，顯示錯誤訊息
      return;
    }
    this.getOneRecord(id); // 由server端抓取此筆資料
  }

  // 由server取得單筆資料並填充到表格
  async getOneRecord(id: number) {
    var data = await this.labApi.get(id)
    this.initInsp = data;
  }
  //#endregion

  testValueColors:LAB001Params;
  // 填入表單資料
  async getTestValueColors() {
    try {
      this.testValueColors = await this.clinicData.getParam('LAB001');
      
    } catch (err) {
      this.notification.showGetDataFailed('report-query-dialog-table:getTestValueColors');
    }
  }
  /** 
   * N:正常 A:異常 D:危險 L:偏低 M:逾最低異常值 H:偏高 I:逾最高異常值
   */
  GetTestValueColor(testResult:'N'|'A'|'D'|'L'|'M'|'H'|'I') {
    if(!this.testValueColors){
      return 'black';
    }
    switch (testResult) {
      case 'N':
        return this.testValueColors.ValueColorNormal;
      case 'A':
        return this.testValueColors.ValueColorAbnormal;
      case 'D':
        return this.testValueColors.ValueColorDanger;
      case 'L':
        return this.testValueColors.ValueColorLow;
      case 'M':
        return this.testValueColors.ValueColorDangerLow;        
      case 'H':
        return this.testValueColors.ValueColorHigh;
      case 'I':
        return this.testValueColors.ValueColorDangerHigh;        
      default:
        return 'black'
    }
  }
}
