import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import * as QRCode from 'qrcode';
import { HcrService } from 'src/app/services/hcr-service.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { promise } from 'selenium-webdriver';
import { SessionService } from 'src/app/services/session.service';
import { HCASignRequestData, HcaInitData } from 'src/app/shared/hcr-lib/HcaInitData';
import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';
import { HistService } from 'src/app/hist/services/hist.service';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { RegisterApi } from 'src/app/services/api-service/register/register-api';
import { NHIRegIC } from 'src/app/services/api-service/register/nhiRegIc';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HistOrder } from 'src/app/hist/models/hist-order';
import { Hspid } from 'src/app/services/api-service/hspid/hspid';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { fixSpecialChar, isSpecialChar } from 'src/app/shared/utilities';


/** 連續處方箋 */
@Component({
  selector: 'app-labs-outclinic-report',
  templateUrl: './labs-outclinic-report.component.html',
  styleUrls: ['./labs-outclinic-report.component.css']
})
export class LabsOutclinicReportComponent implements OnInit {
  //連續報表紙寬度一半
  sizePageLetterHalf = {
    // width: 28.7, //cm
    // height: 20 //cm
    // width: 11, //cm
    width: 9.8, //cm
    height: 14 //cm
  };
  paddingPageLetterHalf = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0, //cm
    left: 0 //cm
  };

  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;
  @ViewChild('reportContainerLetterHalf')
  reportContainerLetterHalf: ElementRef<HTMLDivElement>;
  hist: WholeHist;
  ic: NHIRegIC;
  clinic: Clinic;
  @ViewChild('qrcodeImg')
  qrcodeImg: ElementRef<HTMLImageElement>
  @ViewChild('qrcode2Img')
  qrcode2Img: ElementRef<HTMLImageElement>
  @ViewChild('qrcodeItreatnumImg')
  qrcodeItreatnumImg: ElementRef<HTMLImageElement>
  @ViewChild('qrcodeItreatnum2Img')
  qrcodeItreatnum2Img: ElementRef<HTMLImageElement>

  isopenQRcode = false;
  textToEncode = 'sadasdas233dasdADD';
  normalCheck: string = 'V';
  cntPresCheck: string = '';
  repeatprehcaModule: { InitModule: number, InitSession: number }
  medcodeName: string;
  regdateString: string;
  clinicAddress: string;
  space = '　';
  checkSeven = false;
  isPrintItreatnum = true;
  temphrx: HistOrder[] = [];
  labHospInfo: Hspid;
  isLetterHalf = false;
  @Input()
  set regId(id) {
    this.setRegId(id);
  }
  constructor(private printer: LocalPrintService,
    private api: HistApi,
    private regApi: RegisterApi,
    private cdr: ChangeDetectorRef,
    private hcrService: HcrService,
    private histService: HistService,
    private notification: EasyNotificationService,
    private sessionService: SessionService,
    private parameterApi: ParameterApi,
    private clinicDataService: ClinicDataService) {
  }
  get editOptions(): HistEditOption {
    return this.histService.EditOptions;
  }
  get dosages(): Dosage[] {
    return this.editOptions.dosage;
  }

  async ngOnInit() {

    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.pres-report table td, table th { border: 1px solid black; padding-left: 2px;padding-right: 2px;}\n' +
      '.pres-report table tr:first-child th { border-top: 0; }\n' +
      '.pres-report table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
      this.reportContainerLetterHalf.nativeElement.appendChild(st);
    }, 0);
    //st.outerHTML
  }
  async setRegId(regId: number) {
    //1=A4, 2=LetterHalf
    var param = await this.clinicDataService.getParam("PRN001");
    this.isLetterHalf = param?.ExamPresType == 2 ? true : false;
    this.isPrintItreatnum = param.IsPrintDispPresItreatnum ?? true;
    this.clinic = UserCache.getLoginUser().Clinic;
    this.clinicAddress = (this.clinic.City || '') + (this.clinic.Area || '') + (this.clinic.Street || '');

    let wholeHist = await this.api.getLabOutClinicReport(regId, this.clinic);
    this.hist = wholeHist.WholeHist;
    this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => x.RxType == 6);
    this.ic = wholeHist.NHIRegIC;
    //有發生案例是就醫識別碼中有特殊字元，所以要處理，不然會造成BarCode印製失敗。
    //案例: g  F128Y9S3BCQCWC\u0000\u0000\u0000
    if (isSpecialChar(this.ic.Itreatnum)) {
      this.ic.Itreatnum = fixSpecialChar(this.ic.Itreatnum);
    }
    this.labHospInfo = wholeHist.HspidList.length > 0 ? wholeHist.HspidList[0] : new Hspid();
  }
  getHtml() {
    if (this.ic && this.ic.Itreatnum) {
      this.generateQRCode(this.ic?.Itreatnum, 3);
    }

    let html = '';
    if (this.isLetterHalf) {
      html = this.reportContainerLetterHalf.nativeElement.innerHTML;
    } else {
      html = this.reportContainer.nativeElement.innerHTML;
    }
    return html;
  }

  dateString(input: Date): string {
    var d = new Date(input);
    var month = (d.getMonth() + 1);
    var day = d.getDate();
    var year = d.getFullYear();
    return year + '/' + month + '/' + day;
  }
  get dxNames() {
    return [this.hist?.Hist.Dx1Name, this.hist?.Hist.Dx2Name, this.hist?.Hist.Dx3Name,
    this.hist?.Hist.Dx4Name, this.hist?.Hist.Dx5Name, this.hist?.Hist.Dx6Name].filter(x => x).join(',');
  }
  get dxCodes() {
    return [this.hist?.Hist.Dx1, this.hist?.Hist.Dx2, this.hist?.Hist.Dx3,
    this.hist?.Hist.Dx4, this.hist?.Hist.Dx5, this.hist?.Hist.Dx6].filter(x => x).join(',');
  }

  generateQRCode(text: string, type: number): void {
    QRCode.toDataURL(text, (err, url) => {
      if (err) {
        console.error(err);
        return;
      }
      // 将生成的二维码图片URL存储在一个变量中，或者直接在模板中使用
      // this.qrcodeImg.nativeElement.src = url;
      switch (type) {
        // 病歷
        case 1:
          this.qrcodeImg.nativeElement.src = url;
          break;
        //收據
        case 2:
          this.qrcode2Img.nativeElement.src = url;
          break;
        //就醫識別碼
        case 3:
          this.qrcodeItreatnumImg.nativeElement.src = url;
          this.qrcodeItreatnum2Img.nativeElement.src = url;
          break;
        //連處
        //連處
        default:
          break;
      }
    });
  }

}
