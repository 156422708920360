import { EasyNotificationService } from "../easy-notification.service";

/**
 * 快取服務介面。
 * @template K - 快取中使用的鍵的類型。
 * @template T - 快取存儲的類型。
 */
export interface ICacheService<K, T> extends ICacheReset{
  //cache: {[key:K]:T};  Map<K,T>;

  /**
   * 根據指定的鍵載入快取內容。
   * @param {K[]} keys - 要檢索值的鍵。
   * @returns {Promise<void>}
   */
  load(keys: K[]): Promise<void>;
  
  /**
   * 根據指定的鍵更新值。
   * @param {K[]} keys - 要更新值的鍵。
   * @returns {Promise<void>}
   */
  update(keys: K[]): Promise<void>;

  delete(keys: K[]): Promise<void>;

  get(key:K):Promise<T>;
}
export interface ICacheReset{
  /**
   * 重載目前已快取的項目
   */
  reload(): Promise<void>;
  /**
   * 清除目前已快取的項目
   */
  clearCache(): void;
}
export  abstract class DictionaryCacheService<K, T> implements ICacheService<K, T> {
  protected cache: Map<K,T> = new Map();
  constructor(private notify:EasyNotificationService) { }
  
  protected abstract getCacheObject(key:K[]):Promise<Map<K,T>>;
  protected abstract notifyMsgOnUpdated:string;
  protected abstract notifyMsgOnReload:string;

  async load(keys: K[]): Promise<void> {
    let noCached = keys.filter(paraTp=>!this.cache.has(paraTp));
    if(noCached?.length>0){
      const noCachedRet = await this.getCacheObject(noCached);
      noCachedRet.forEach((v,k,m)=>{
        this.cache.set(k, v);
      });
    }
  }

  async reload(): Promise<void> {
    // 取得已經紀錄的項目
    var keys = Array.from(this.cache.keys());
    // 清除快取
    this.cache.clear();
    // 重撈
    if(keys?.length>0){
      const noCachedRet = await this.getCacheObject(keys);
      noCachedRet.forEach((v,k,m)=>{
        this.cache.set(k, v);
      });
      this.notify.showSuccess(this.notifyMsgOnReload);
    }
  }
  async update(keys: K[]): Promise<void> {
    let existingTypes = keys.filter(key => this.cache.has(key));
    
    if (existingTypes && existingTypes.length > 0) {
      for (let type of existingTypes) {
        // 移除現有的值
        this.cache.delete(type);
      }
      // 重新取得
      await this.load(existingTypes);
      this.notify.showInfo(this.notifyMsgOnUpdated);
    }
  }

  async delete(keys: K[]): Promise<void> {
    for (let type of keys) {
      if(this.cache.has(type)){
        // 移除現有的值
        this.cache.delete(type);
      }
    }
  }

  async get(key: K): Promise<T> {
    await this.load([key]);
    return this.cache.get(key);
  }

  clearCache(){
    this.cache.clear();
  }
}

export abstract class ObjectCacheService<T> implements ICacheService<keyof T,T[keyof T]> {
  
  constructor(private notify:EasyNotificationService) { }
  protected cache: any = {};

  protected abstract getCacheObject<K extends keyof T>(key:K[]):Promise<Map<K,T[K]>>;
  protected abstract notifyMsgOnUpdated:string;

  async load(keys: (keyof T)[]): Promise<void> {
    let noCached = keys.filter(paraTp=>!this.cache[paraTp]);
    if(noCached?.length>0){
      const noCachedRet = await this.getCacheObject(noCached);
      noCachedRet.forEach((v,k,m)=>{
        this.cache[k] = v;
      });
    }
  }

  async reload(): Promise<void> {
    // 取得以紀錄的項目
    var keys = [];
    for(let k in this.cache){
      keys.push(k);
    }
    // 重置
    this.cache = {};
    // 重撈
    if(keys?.length>0){
      const noCachedRet = await this.getCacheObject(keys);
      noCachedRet.forEach((v,k,m)=>{
        this.cache[k] = v;
      });
    }
  }
  async update(keys: (keyof T)[]): Promise<void> {
    let existingTypes = keys.filter(key => !!this.cache[key]);
    
    if (existingTypes && existingTypes.length > 0) {
      for (let type of existingTypes) {
        // 移除現有的值
        this.cache[type] = null;
      }
      // 重新取得
      await this.load(existingTypes);
      this.notify.showInfo(this.notifyMsgOnUpdated);
    }
  }

  async delete(keys: (keyof T)[]): Promise<void> {
    for (let type of keys) {
      if(!!this.cache[type]){
        // 移除現有的值
        this.cache[type] = null;
      }
    }
  }

  async get<K extends keyof T>(key: K): Promise<T[K]> {
    await this.load([key]);
    return this.cache[key];
  }

  clearCache(){
    this.cache = {};
  }
}