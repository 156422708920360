import { Directive, HostListener, Input } from '@angular/core';
/** 預設參數為按下Enter後會Focus到的Input或Click的Btn
 */
@Directive({
  selector: '[appEnterTo]'
})
export class EnterToDirective {
  @Input()
  appEnterTo: HTMLInputElement|HTMLButtonElement;
  constructor() {}

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    var el = e.target as HTMLInputElement;
    if (e.key == 'Enter') {
      if(this.appEnterTo instanceof HTMLInputElement){
        this.appEnterTo.focus();
      }else if (this.appEnterTo instanceof HTMLButtonElement){
        this.appEnterTo.click();
      }
    }
    
  }
}
