import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { VisitsReport, VisitsReportItem } from 'src/app/opd/opd-report/models/visits-report';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { PatientApi, PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { RxOpt } from 'src/app/services/api-service/hist/hist-edit-option';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { ActivatedRoute } from '@angular/router';
import { HRxApiService } from 'src/app/services/api-service/opd/hrx-api.service';
import { BasicdataPrintOperationComponent } from 'src/app/hist/basicdata-print-operation/basicdata-print-operation.component';
import { UserCache } from 'src/app/services/user-cache';

@Component({
    selector: 'app-cumulative-medical-visits',
    templateUrl: './cumulative-medical-visits.component.html',
    styleUrls: ['./cumulative-medical-visits.component.css']
})
export class CumulativeMedicalVisitsComponent implements OnInit, AfterViewInit {

    reportName = '醫師別病患明細';
    forExcel = true;

    sizePage = {
        width: 28.7, //cm
        height: 20 //cm
    };
    paddingPage = {
        top: 0, //cm
        right: 0, //cm
        bottom: 0.5, //cm
        left: 0 //cm
    };

    heightPageWithoutPadding = this.convertCmtoPx(
        this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
    );
    elContainer: HTMLElement;
    pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]


    @ViewChild('reportContainer', { static: false })
    reportContainer: ElementRef<HTMLDivElement>;
    upperboundIndex: number = 0;
    monthStr: string = '';
    nowStr: string = '';
    timeStr: string = '';
    pageNo: number = 1;
    _sDate: Date;
    _eDate: Date;

    // 清單
    listItems: PatientListDto[]; //RxdataReportComponent[];
    queryFormGroup: FormGroup;
    data: PatientListDto[];
    BasicdataReport: BasicdataPrintOperationComponent;
    totalRecord: number;
    roomid: String = '';
    doctorid: String = '';
    startDate: String = '';
    endDate: String = '';
    reportType: String = '';
    doctorName: String = '';

    constructor(private route: ActivatedRoute,
        private webApiClient: WebApiClient,
        private patientApi: PatientApi,
        private histApi: HistApi,
        private hRxApiService: HRxApiService,
        private localPrintService: LocalPrintService,
        private hcrService: HcrService,
        private notification: EasyNotificationService) {
        //this.api = this.webApiClient.createHisService('Opd/OpdReport');
    }

    ngOnInit(): void {
        var st = document.createElement('style');
        st.appendChild(document.createTextNode(
            '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
            '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
            '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
            '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
            '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
            '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
            '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
            '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
            // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
        ));
        setTimeout(() => {
            this.reportContainer.nativeElement.appendChild(st);
        }, 0);
    }

    ngAfterViewInit() {

    }
    async fetchData(data: PatientListDto[], roomid: string, doctorid: string, startDate: string, endDate: string, reportType: String, doctorName: String, forExcel = true) {
        this.forExcel = forExcel;
        this.elContainer = document.getElementById("container");
        this.roomid = roomid;
        this.doctorid = doctorid;
        // this.startDate = startDate;
        // this.endDate = endDate;
        // this.startDate = this.startDate ? this.onGetDateString(this.startDate, true, '', false) : "";
        // this.endDate = this.endDate ? this.onGetDateString(this.endDate, true, '', false) : "";
        //顯示印表期間(已將格式改成y/m/d)
        this.startDate = startDate;
        this.endDate = endDate;
        this.startDate = startDate ? DateHelper.getROCDateStringWithSepetator(new Date(startDate)) : "";
        this.endDate = endDate ? DateHelper.getROCDateStringWithSepetator(new Date(endDate)) : "";
        this.reportType = reportType;
        this.doctorName = doctorName;

        //以下是報表表頭欄位, 目前沒用到, 先給預設值。
        // this.nowStr = this.onGetDateString(new Date(), true);
        this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
        // this.timeStr = this.onGetTimeString(new Date());
        this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
        // this._sDate = query.startDate;
        this._sDate = DateHelper.today;
        // this._eDate = query.endDate;
        this._eDate = DateHelper.today;


        this.listItems = data;
        this.totalRecord = this.listItems.length;
        if (this.reportType.toString() == "1") {
            this.insertListData1(); //醫師別報表
        } else if (this.reportType.toString() == "2") {
            this.insertListData2(); //就醫病患名冊
        } else if (this.reportType.toString() == "3") {
            this.insertListData3(); //累積就醫次數
        } else if (this.reportType.toString() == "4") {
            this.totalRecord = 0;
            for (let i = 0; i < this.listItems.length; i++) {
                if (this.listItems[i].ICode == "A") {
                    this.totalRecord++;
                }
            }
            this.insertListData4(); //健保就醫病患名冊
        }

    }

    // #region 組報表
    // 組報表的html內容
    // 醫師別報表
    insertListData1() {
        var html_Block = "";
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);
        var html_header = this.createHTMLPageHeader(iPage);
        var html_ListerHeader = this.createHTMLListHeader();
        var html_finish = this.createHTMLFinish();
        var html_blank = this.createHTMLBlankLine();
        var elPageContent = document.getElementById("page-" + iPage + "-content");
        var prevTableContent: any;
        var afterSummary = false;

        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;

        for (let i = 0; i < this.listItems.length; i += 3) {
            prevTableContent = elTableContent.innerHTML;
            if (afterSummary) {
                elTableContent.innerHTML += html_ListerHeader;
                afterSummary = false;
            }

            let data1 = this.listItems[i];
            let data2 = this.listItems[i + 1];
            let data3 = this.listItems[i + 2];
            html_Block = this.createHTMLBlock(data1, data2, data3);
            elTableContent.innerHTML += html_Block;

            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                html_header = this.createHTMLPageHeader(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;
                elTableContent.innerHTML += html_Block;
                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
        }

        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var totalPage = document.getElementById("totalPage-" + i);
            totalPage.innerHTML = (this.upperboundIndex + 1).toString();
        }
    }

    // #region 組報表
    // 組報表的html內容
    // 病患就醫名冊
    insertListData2() {
        var html_Block = "";
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
        var html_header = this.createHTMLPageHeader2(iPage);       // 頁表頭
        var html_ListerHeader = this.createHTMLListHeader2();      // List欄位顯示名稱
        var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
        var index = 0;  // 新增的序號索引
        var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
        var prevTableContent: any;
        var afterSummary = false;

        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        for (let i = 0; i < this.listItems.length; i++) {

            // 加入 List 每一筆的內容
            prevTableContent = elTableContent.innerHTML;
            if (afterSummary) {
                elTableContent.innerHTML += html_ListerHeader;
                afterSummary = false;
            }
            html_Block = this.createHTMLBlock2(this.listItems[i], index);
            elTableContent.innerHTML += html_Block;
            index++;  // 更新序號索引

            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader2(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;
                elTableContent.innerHTML += html_Block;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
        }
        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var totalPage = document.getElementById("totalPage-" + i);
            totalPage.innerHTML = (this.upperboundIndex + 1).toString();
        }
    }
    // #region 組報表
    // 組報表的html內容
    // 累積就醫次數
    insertListData3() {
        var html_Block = "";
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
        var html_header = this.createHTMLPageHeader3(iPage);       // 頁表頭
        var html_ListerHeader = this.createHTMLListHeader3();      // List欄位顯示名稱
        var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
        var index = 0;  // 新增的序號索引
        var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
        var prevTableContent: any;
        var afterSummary = false;

        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        for (let i = 0; i < this.listItems.length; i++) {

            // 加入 List 每一筆的內容
            prevTableContent = elTableContent.innerHTML;
            if (afterSummary) {
                elTableContent.innerHTML += html_ListerHeader;
                afterSummary = false;
            }
            html_Block = this.createHTMLBlock3(this.listItems[i], index);
            elTableContent.innerHTML += html_Block;
            index++;  // 更新序號索引

            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader3(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;
                elTableContent.innerHTML += html_Block;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
        }
        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var totalPage = document.getElementById("totalPage-" + i);
            totalPage.innerHTML = (this.upperboundIndex + 1).toString();
        }
    }
    // #region 組報表
    // 組報表的html內容
    // 健保就醫病患名冊
    insertListData4() {
        var html_Block = "";
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
        var html_header = this.createHTMLPageHeader4(iPage);       // 頁表頭
        var html_ListerHeader = this.createHTMLListHeader4();      // List欄位顯示名稱
        var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
        var index = 0;  // 新增的序號索引
        var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
        var prevTableContent: any;
        var afterSummary = false;

        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        for (let i = 0; i < this.listItems.length; i++) {
            if (this.listItems[i].ICode == "A") {  // 只選擇 ICode 為 "A" 的項目
                // 加入 List 每一筆的內容
                prevTableContent = elTableContent.innerHTML;
                if (afterSummary) {
                    elTableContent.innerHTML += html_ListerHeader;
                    afterSummary = false;
                }
                html_Block = this.createHTMLBlock4(this.listItems[i], index);
                elTableContent.innerHTML += html_Block;
                index++;  // 更新序號索引

                // 判斷是否超出頁面範圍? -> 新增一頁
                if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                    elTableContent.innerHTML = prevTableContent;
                    iPage += 1;
                    this.elContainer.innerHTML += this.createHTMLPage(iPage);
                    elPageContent = document.getElementById("page-" + iPage + "-content");
                    // [頁表頭][List欄位顯示名稱]
                    html_header = this.createHTMLPageHeader4(iPage);
                    elPageContent.innerHTML += html_header;
                    elTableContent = document.getElementById("table-" + iPage + "-content");
                    elTableContent.innerHTML = html_ListerHeader;
                    elTableContent.innerHTML += html_Block;

                    this.pageContent[iPage] = [];
                    this.pageContent[iPage].push(iBlock);
                } else {
                    this.pageContent[iPage].push(iBlock);
                }
            }
        }
        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var totalPage = document.getElementById("totalPage-" + i);
            totalPage.innerHTML = (this.upperboundIndex + 1).toString();
        }
    }


    convertPxToCm(px) {
        return Math.round(((px * 2.54) / 96) * 100) / 100;
    }

    convertCmtoPx(cm) {
        return Math.round((cm * 96) / 2.54);
    }

    createHTMLPage(iPage) {
        return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
    }

    createHTMLBlankLine() {
        return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
    }

  //數字靠右文字靠左
    createHTMLListHeader() {
        return `<tr>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                來診日期
              </th>
              <th
                style="text-align: center; width:11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                姓名&nbsp;
              </th>
              <!-- 插入空白來分隔 -->
              <th
                style="text-align: center; width: 1%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black;">
                &nbsp;
              </th>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                來診日期
              </th>
              <th
                style="text-align: center; width:11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                姓名
              </th>
              <!-- 插入空白來分隔 -->
              <th
                style="text-align: center; width: 1%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 0px solid black;">
                &nbsp;
              </th>
              <th
                style="text-align: center; width: 11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                來診日期
              </th>
              <th
                style="text-align: center; width:11%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px double black;">
                姓名
              </th>
            </tr>`;
    }

    createHTMLListHeader2() {
        return `<tr>
              <th style="text-align: center; width: 6%; border: 1px solid black;">序號</th>
              <th style="text-align: center; width: 11%; border: 1px solid black;">姓名</th>
              <th style="text-align: center; width: 11%; border: 1px solid black;">身份證字號</th>
              <th style="text-align: center; width: 6%; border: 1px solid black;">性別</th>
              <th style="text-align: center; width: 8%; border: 1px solid black;">生日</th>
              <th style="text-align: center; width: 16%; border: 1px solid black;">聯絡電話</th>
              <th style="text-align: center; width: 42%; border: 1px solid black;">聯絡地址</th>
            </tr>`;
    }

    createHTMLListHeader3() {
        return `<tr>
              <th style="text-align: center; width: 6%; border: 1px solid black;">序號</th>
              <th style="text-align: center; width: 6%; border: 1px solid black;">次數</th>
              <th style="text-align: center; width: 11%; border: 1px solid black;">姓名</th>
              <th style="text-align: center; width: 11%; border: 1px solid black;">身份證字號</th>
              <th style="text-align: center; width: 4%; border: 1px solid black;">性別</th>
              <th style="text-align: center; width: 7%; border: 1px solid black;">生日</th>
              <th style="text-align: center; width: 15%; border: 1px solid black;">聯絡電話</th>
              <th style="text-align: center; width: 40%; border: 1px solid black;">聯絡地址</th>
            </tr>`;
    }
    createHTMLListHeader4() {
        return `<tr>
              <th style="text-align: center; width: 5%; border: 1px solid black;">序號</th>
              <th style="text-align: center; width: 10%; border: 1px solid black;">姓名</th>
              <th style="text-align: center; width: 10%; border: 1px solid black;">聯絡電話</th>
              <th style="text-align: center; width: 75%; border: 1px solid black;">聯絡地址</th>
            </tr>`;
    }

    createHTMLBlock(data1: PatientListDto, data2?: PatientListDto, data3?: PatientListDto) {
        var commonStyle = 'border-top: none; border-bottom: none;';
        var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
        var regdate1 = data1.RegDate ? this.onGetDateString(data1.RegDate, true, '', false) : "";
        var regdate2 = data2 ? (data2.RegDate ? this.onGetDateString(data2.RegDate, true, '', false) : "") : "";
        var regdate3 = data3 ? (data3.RegDate ? this.onGetDateString(data3.RegDate, true, '', false) : "") : "";

        if (regdate1.includes('-')) regdate1 = "";
        if (regdate2.includes('-')) regdate2 = "";
        if (regdate3.includes('-')) regdate3 = "";

        return `
       <tr>
       <td style="text-align: left; ${commonStyle}" ${commonClassText}>${regdate1}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data1.PatientNo}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data1.Name}</td>

        <td style="text-align: left; border: none;">&nbsp;</td>

        ${data2 ? `
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${regdate2}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data2.PatientNo}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data2.Name}</td>
        ` : '<td colspan="3" style="border: none;"></td>'}

        <td style="text-align: left; border: none;">&nbsp;</td>

        ${data3 ? `
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${regdate3}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data3.PatientNo}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data3.Name}</td>
        ` : '<td colspan="3" style="border: none;"></td>'}
      </tr>`;
    }

    createHTMLBlock2(data: PatientListDto, index: number) {
        var commonStyle = 'border: 1px solid black; padding: 10px 0;';;
        var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
        var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
        var formatbirthday = birthday ? this.formatBirthday(birthday) : "";
        var gender = data.Sex === 1 ? '男' : data.Sex === 0 ? '女' : '未知';
        var phone = data.PhoneNo ? data.PhoneNo : '';
        var addr = data.Address ? data.Address : (data.City + data.Area + data.Street);
        return `
       <tr>
       <td style="text-align: center; ${commonStyle}" ${commonClassText}>${index + 1}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data.Name}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data.CId}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${gender}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${formatbirthday}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${phone}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${addr}</td>
      </tr>`;
    }

    createHTMLBlock3(data: PatientListDto, index: number) {
        var commonStyle = 'border: 1px solid black; padding: 10px 0;';;
        var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
        var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
        var formatbirthday = birthday ? this.formatBirthday(birthday) : "";
        var gender = data.Sex === 1 ? '男' : data.Sex === 0 ? '女' : '未知';
        var phone = data.PhoneNo ? data.PhoneNo : '';
        var addr = data.Address ? data.Address : (data.City + data.Area + data.Street);

        return `
       <tr>
       <td style="text-align: center; ${commonStyle}" ${commonClassText}>${index + 1}</td>
        <td style="text-align: center; ${commonStyle}" ${commonClassText}>${data.times}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data.Name}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data.CId}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${gender}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${formatbirthday}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${phone}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${addr}</td>
      </tr>`;
    }

    createHTMLBlock4(data: PatientListDto, index: number) {
        var commonStyle = 'border: 1px solid black; padding: 10px 0;';;
        var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
        var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
        var formatbirthday = birthday ? this.formatBirthday(birthday) : "";
        var gender = data.Sex === 1 ? '男' : data.Sex === 0 ? '女' : '未知';
        var phone = data.PhoneNo ? data.PhoneNo : '';
        var addr = data.Address ? data.Address : (data.City + data.Area + data.Street);
        return `
       <tr>
       <td style="text-align: center; ${commonStyle}" ${commonClassText}>${index + 1}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${data.Name}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${phone}</td>
        <td style="text-align: left; ${commonStyle}" ${commonClassText}>${addr}</td>
      </tr>`;
    }

    formatBirthday(birthday: string): string {
        if (birthday.length === 7) {
            // 切割年份、月份與日期
            const year = birthday.substring(0, 3);
            const month = birthday.substring(3, 5);
            const day = birthday.substring(5, 7);

            // 返回格式化後的字串
            return `${year}/${month}/${day}`;
        } else {
            // 如果 birthday 不是7碼長，返回原始字串或空字串
            return birthday;
        }
    }


    createHTMLPageHeader(iPage) {
        var reportName = '醫師別病患明細';
        return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      診別：` + this.roomid + `&nbsp;&nbsp;&nbsp;&nbsp;醫師：` + this.doctorName + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>

                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
    }

    createHTMLPageHeader2(iPage) {
        var clinic = UserCache.getLoginUser().Clinic;
        var clinicName = clinic.Name;
        var clinicTelephone = clinic.Phone;
        var reportName = '就醫病患名冊';
        return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + clinicName + `&nbsp; ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      診別：` + this.roomid + `&nbsp;&nbsp;&nbsp;&nbsp;醫師：` + this.doctorName + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                  </tr>
                  <tr>
                    <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    </td>
                    <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    </td>
                    <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      連絡電話： ` + clinicTelephone + `
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
    }


    createHTMLPageHeader3(iPage) {
        var reportName = '累積就醫次數';
        return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      診別：` + this.roomid + `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;醫師：` + this.doctorName + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>

                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
    }

    createHTMLPageHeader4(iPage) {
        var clinic = UserCache.getLoginUser().Clinic;
        var clinicName = clinic.Name;
        var clinicTelephone = clinic.Phone;
        var reportName = '健保就醫病患名冊';
        return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + clinicName + `&nbsp; ` + reportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      診別：` + this.roomid + `&nbsp;&nbsp;&nbsp;&nbsp;醫師：` + this.doctorName + `
                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      期間：` + this.startDate + ` 至 ` + this.endDate + `
                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                      </td>
                  </tr>
                  <tr>
                    <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    </td>
                    <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    </td>
                    <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      連絡電話： ` + clinicTelephone + `
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
    }

    createHTMLFinish() {
        return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                    <label style="font-weight: bold;">以下空白</label>
                </div>`;
      }

    // #endregion
    getHtml() {
        var html = this.reportContainer.nativeElement.innerHTML;
        for (var i = 0; i <= this.upperboundIndex; i++) {
            html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
        }
        return html;
    }

    async onPrint(reportType: string) {
        var reportName;
        if (this.listItems.length > 0) {
            if (this.reportType == "1") {
                reportName = '醫師別病患明細';
            } else if (this.reportType == "2") {
                reportName = '就醫病患名冊';
            } else if (this.reportType == "3") {
                reportName = '累積就醫次數';
            } else if (this.reportType == "4") {
                reportName = '健保就醫病患名冊';
            }
            const printContents = this.getHtml();
            var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
            if (ret.Successed) {
                this.notification.showSuccess(reportName + '已送出列印!', true);
            } else {
                this.notification.showError(reportName + '送出列印失敗!');
            }
        } else {
            this.notification.showInfo('查無資料可供列印!');
        }
    }

    async exportAsExcel(reportType: string) {
        var reportName;
        if (this.listItems.length > 0) {
            if (this.reportType == "1") {
                reportName = '醫師別病患明細';
            } else if (this.reportType == "2") {
                reportName = '就醫病患名冊';
            } else if (this.reportType == "3") {
                reportName = '累積就醫次數';
            } else if (this.reportType == "4") {
                reportName = '健保就醫病患名冊';
            }
            const table = this.reportContainer.nativeElement;
            await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


        } else {
            this.notification.showInfo('查無資料可供匯出!');
        }
    }

    /** 轉換Date To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
    */
    onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();
            var years = year.toString();
            if (years.length < 2)
                years = '00' + year; //民國6年7月5日出生 (0060705)
            if (years.length < 3)
                years = '0' + year;
            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if (isMonth) {
                return [years, month].join(symbol);
            } else {
                return [years, month, day].join(symbol);
            }
        }
    }

    /** 轉換Time To String
      *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
    */
    onGetTimeString(ev, timeSymbol = ':') {
        if (ev) {
            var d = new Date(ev);
            // 趕時間先簡單寫
            let hr = d.getHours().toString();
            if (d.getHours() < 10) {
                hr = '0' + hr;
            }
            let min = d.getMinutes().toString();
            if (d.getMinutes() < 10) {
                min = '0' + min;
            }
            let sec = d.getSeconds().toString();
            if (d.getSeconds() < 10) {
                sec = '0' + sec;
            }
            var timeStr = [hr, min, sec].join(timeSymbol);
            return timeStr;
        }
    }

    onGetDate(ev) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day].join('');
        }
    }
}


