import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { TrimEnd } from 'src/app/shared/utilities';
import { DxOpt, HistEditOption, RxOpt, userStat } from 'src/app/services/api-service/hist/hist-edit-option';
import { Compare, NullOrEmpty, Or, StringEqual } from 'src/app/shared/utilities';
import { MultiPrescriptWrite } from 'src/app/shared/hcr-lib/MultiPrescriptWrite';
import { TreatmentData } from 'src/app/shared/hcr-lib/TreatmentData';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { Rx } from 'src/app/services/api-service/rx/rx';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { PrescriptionSignMultiWrite } from 'src/app/shared/hcr-lib/PrescriptionSignMultiWrite';
import { RxApi } from 'src/app/services/api-service/rx/rx-api';
import { TmplView } from '../models/tmpl-view';
import { UserCache } from 'src/app/services/user-cache';
import { OrderDispensingTypeEnum } from 'src/app/enums/DispensingTypeEnum';
import { OrderTypeEnum } from 'src/app/enums/OrderTypeEnum';
import { LocalStoragesService } from 'src/app/services/local-storages.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { Hist } from '../models/hist';
import { RegisterConst } from 'src/app/shared/constants/register-const';


export class HistToICCardData {
  MultiPrescriptWrite: MultiPrescriptWrite;
  PrescriptionSignMultiWrite: PrescriptionSignMultiWrite;
  TreatmentData: TreatmentData;
  ErrorMsg: string;
  RegicId: number;
  RegId: number;
  HistoryId?:number;
}
export class HistReady {
  get All() { return this.Dx && this.Rx && this.PE && this.CC };
  Dx: boolean = false;
  Rx: boolean = false;
  CC: boolean = false;
  PE: boolean = false;

}

@Injectable({
  providedIn: 'root'
})
export class HistService {

  constructor(private histApi: HistApi,
    private clinicData: ClinicDataService,
    private localStorage: LocalStoragesService,
    private rxApi: RxApi,
    private userConfirmService:UserConfirmService,
    private router: Router,
    private routeInfo: RouteInfoService) {
  }
  public static EnableFreqFree = true;
  public currentHist: WholeHist;
  public histForRegChange: WholeHist;
  public onHistLoaded: EventEmitter<WholeHist> = new EventEmitter<WholeHist>()
  public onHistReady: EventEmitter<HistReady> = new EventEmitter<HistReady>()
  public onHistFetch: EventEmitter<(a: WholeHist) => void> = new EventEmitter<(a: WholeHist) => void>()
  public onTmplViewEmitInsert: EventEmitter<TmplView> = new EventEmitter<TmplView>();
  /** 醫令類別與可用調劑選項與預設值判斷, 沒列出的歸屬在UI上會被鎖住 */
  public dispCodeMap = [{
    types: [
      OrderTypeEnum.T2_OralDrugFee,
      OrderTypeEnum.T3_ExtDrugFee,
      OrderTypeEnum.T12_SpecialMaterialFee],
    dispCode: [
      OrderDispensingTypeEnum.T0_Clinic,
      OrderDispensingTypeEnum.T1_OutOfClinic],
    default: (order: RxOpt) => {
      var hasPharmacist = UserCache.getLoginUser().Clinic.HasPharmacist;
      var ishomecare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
      var IsrecoveryHome = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
      //康家居護的特材為自調
      if (order.Type == OrderTypeEnum.T12_SpecialMaterialFee && (ishomecare || IsrecoveryHome)) {
        return OrderDispensingTypeEnum.T0_Clinic;
      }
      else {
        return hasPharmacist ? OrderDispensingTypeEnum.T0_Clinic : OrderDispensingTypeEnum.T1_OutOfClinic;
      }
    }
  }, {
    // 只可自調/交付的
    types: [OrderTypeEnum.T4_InjectionDrugFee,// 針劑除胰島素相關的 會有委外 其他多為自調
    OrderTypeEnum.T8_RehabilitationFee,
    OrderTypeEnum.T800_OwnSpecial
    ],
    dispCode: [
      OrderDispensingTypeEnum.T0_Clinic,
      OrderDispensingTypeEnum.T1_OutOfClinic],
    default: (order: RxOpt) => {
      return OrderDispensingTypeEnum.T0_Clinic;
    }
  }, {
    types: [
      OrderTypeEnum.T6_Lab,
      OrderTypeEnum.T7_XRay
    ],
    dispCode: [
      OrderDispensingTypeEnum.T0_Clinic,
      OrderDispensingTypeEnum.T1_OutOfClinic,
      OrderDispensingTypeEnum.T2_RefLabHosp,
      OrderDispensingTypeEnum.T3_RefLabClinic,
      OrderDispensingTypeEnum.T4_LabHosp,
      OrderDispensingTypeEnum.T5_LabHospClinic
    ],
    default: (order: RxOpt) => {
      if (order.SPRule == 6) {
        return OrderDispensingTypeEnum.T4_LabHosp;
      }
      else if (order.SPRule == 7) {
        return OrderDispensingTypeEnum.T4_LabHosp;
      }
      else if (this.currentHist?.Register?.INote == RegisterConst.INote_代檢 && this.currentHist?.Register?.ICode == RegisterConst.ICode_009_Other) {
        return OrderDispensingTypeEnum.T5_LabHospClinic;
      }
      else {
        return OrderDispensingTypeEnum.T0_Clinic
      }
    }
  }];
  public emitTmplViewInsert(tmpl: TmplView) {
    this.onTmplViewEmitInsert.emit(tmpl);
  }
  //public onHistFinish: EventEmitter<WholeHist> = new EventEmitter<WholeHist>()
  public async loadHist(regId: number) {
    this.histUIState = new HistReady();
    this.currentHist = await this.histApi.getWholeHist({ regId: regId });
    this.onHistLoaded.emit(this.currentHist);
  }
  public async fetchHist() {
    var newHist = await new Promise<WholeHist>((rs, rj) => {
      this.onHistFetch.emit(rs);
    })
    this.currentHist = newHist;
    return this.currentHist;
  }

  public async loadHistForRegChange(regId: number) {
    this.histForRegChange = await this.histApi.getWholeHist({ regId: regId });
  }

  public async FinishHist() {
    this.onHistLoaded.emit(this.currentHist);
  }

  isHist: boolean = false;

  histUIState: HistReady = new HistReady()
  public UIReset() {
    this.histUIState = new HistReady()
    this.onHistReady.emit(this.histUIState);
  }
  public UIReady(tp: 'Dx' | 'Rx' | 'CC' | 'PE' | HelperSourceEnum) {
    switch (tp) {
      case 'Dx': this.histUIState.Dx = true; break;
      case 'Rx': this.histUIState.Rx = true; break;
      case 'CC': this.histUIState.CC = true; break;
      case 'PE': this.histUIState.PE = true; break;
    }
    console.log(tp, 'ready');
    this.onHistReady.emit(this.histUIState);
  }
  public UIUnReady(tp: 'Dx' | 'Rx' | 'CC' | 'PE' | HelperSourceEnum) {
    switch (tp) {
      case 'Dx': this.histUIState.Dx = false; break;
      case 'Rx': this.histUIState.Rx = false; break;
      case 'CC': this.histUIState.CC = false; break;
      case 'PE': this.histUIState.PE = false; break;
    }
    console.log(tp, 'lock');
    this.onHistReady.emit(this.histUIState);
  }

  //#region 參數設定相關
  // StartUp 載入完成後才會載入Component,所以大概會有資料
  get EditOptions() {
    return this.histApi.EditOpt;
  }
  get IsEnableFrontValidation() {
    return this.EditOptions.histParams.IsEnableFrontValidation
  }
  get IsShowPricingWindow() {
    return this.EditOptions.isShowPricingWindow;
  }
  get IsChineseMode() {
    // 這邊要改為判斷Clinic相關設定 或者是科別?
    return false; //this.EditOptions.histParams.IsChineseMode;
  }
  /** 進入門診畫面要Focus的項目 */
  get FirstFocus(): HelperSourceEnum {
    var focusOpt = this.EditOptions.p15;
    return focusOpt == '1' ? HelperSourceEnum.Diag :
      focusOpt == '2' ? HelperSourceEnum.Order :
        HelperSourceEnum.CC;
  }
  get TotalDiagnosis(): number {
    // 診斷數目
    return this.EditOptions.opdParams.TotalDiagnosis;
  }
  get SentenceWordDelimiter(): string {
    // 文字分隔符號
    return this.EditOptions.opdParams.SentenceWordDelimiter || ' ';
  }
  get IsEnableDiabetes() {
    return this.EditOptions.histParams.IsEnableDiabetes;
  }

  //#region Promise寫法

  async getCCOptions(codeName?: string, sortByMatch: boolean = false): Promise<SystemCode[]> {
    var editopt = await this.histApi.getEditOptions();
    var emptyCode = NullOrEmpty(codeName);
    if (emptyCode) {
      return editopt.cc;
    }
    // 不排序(按照server端下來的Code順序)
    if (sortByMatch == false) {
      return editopt.cc.filter(c => this.containWithoutCase(c.Code, codeName) || this.containWithoutCase(c.Name, codeName));
    } else {
      var codeMatch = editopt.cc.filter(c => this.containWithoutCase(c.Code, codeName)).sort((a, b) => Compare(a.Code.indexOf(codeName), b.Code.indexOf(codeName)));
      var nameMatch = editopt.cc.filter(c => !codeMatch.some(x => x.Code.toLowerCase() == c.Code.toLowerCase()) && this.containWithoutCase(c.Name, codeName)).sort((a, b) => Compare(a.Name.indexOf(codeName), b.Name.indexOf(codeName)));
      return codeMatch.concat(nameMatch);
    }

    // let query = 'getCCOptions?name=' + codeName;
    // query = this.queryAddCount(query, count);
    // return this.api.get(query);
  }
  containWithoutCase(text: string, search: string) {
    return text.toLowerCase().indexOf(search.toLowerCase()) >= 0;
  }
  async getPEOptions(codeName?: string, sortByMatch: boolean = false): Promise<SystemCode[]> {
    // console.log('getPEOptions');
    var editopt = await this.histApi.getEditOptions();
    var emptyCode = NullOrEmpty(codeName);
    if (emptyCode) {
      return editopt.pe;
    }
    // 不排序(按照server端下來的Code順序)
    if (sortByMatch == false) {
      return editopt.pe.filter(c => this.containWithoutCase(c.Code, codeName) || this.containWithoutCase(c.Name, codeName));
    } else {
      var codeMatch = editopt.pe.filter(c => this.containWithoutCase(c.Code, codeName)).sort((a, b) => Compare(a.Code.indexOf(codeName), b.Code.indexOf(codeName)));
      var nameMatch = editopt.pe.filter(c => !codeMatch.some(x => x.Code.toLowerCase() == c.Code.toLowerCase()) && this.containWithoutCase(c.Name, codeName)).sort((a, b) => Compare(a.Name.indexOf(codeName), b.Name.indexOf(codeName)));
      return codeMatch.concat(nameMatch);
    }
    // let query = 'getPEOptions?name=' + codeName;
    // query = this.queryAddCount(query, count);
    // return this.api.get(query);
  }

  /** 比較邏輯：Code:從頭 || Short:從頭 || 名稱:包含 */
  async getDiagList(code: string, sortByMatch: boolean = false): Promise<DxOpt[]> {
    //console.log('getDiagList');
    var dx = await this.clinicData.GetDxOption();
    var emptyCode = NullOrEmpty(code);
    if (emptyCode) {
      return dx;
    }
    code = code.toLowerCase();
    // 不排序(按照server端下來的Code順序)
    if (sortByMatch == false) {
      return dx.filter(c => emptyCode ? true
        : c.Code?.toLowerCase().startsWith(code)
        || c.ICD9?.toLowerCase().startsWith(code)
        || c.ApplyCode?.toLowerCase().startsWith(code)
        || c.Short?.toLowerCase().startsWith(code)
        || c.CName?.toLowerCase().indexOf(code) >= 0
        || c.EName?.toLowerCase().indexOf(code) >= 0);
    } else {
      // 院內代碼符合的顯示在前方 並排序
      var matched = dx.filter(c => c.Code?.toLowerCase().startsWith(code))
        .sort((a, b) => Compare(a.Code.toLowerCase(), b.Code.toLowerCase()));
      // 緊接著ICD10符合的項目 並排序
      matched = [...matched, ...dx.filter(c => !matched.some(dx => dx.Code == c.Code) // 排除掉已經符合的項目
        && c.ApplyCode?.toLowerCase().startsWith(code))
        .sort((a, b) => Compare(a.ApplyCode.toLowerCase(), b.ApplyCode.toLowerCase()))]
      // 緊接著ICD9符合的項目 並排序
      matched = [...matched, ...dx.filter(c => !matched.some(dx => dx.Code == c.Code) // 排除掉已經符合的項目
        && c.ICD9?.toLowerCase().startsWith(code))
        .sort((a, b) => Compare(a.ICD9.toLowerCase(), b.ICD9.toLowerCase()))]
      // 緊接著名稱符合的項目 並排序
      matched = [...matched, ...dx.filter(c => !matched.some(dx => dx.Code == c.Code) // 排除掉已經符合的項目
        && (c.Short?.toLowerCase().startsWith(code)
          || c.CName?.toLowerCase().indexOf(code) >= 0
          || c.EName?.toLowerCase().indexOf(code) >= 0))
        .sort((a, b) => Compare(a.CName?.toLowerCase().indexOf(code), b.CName?.toLowerCase().indexOf(code)
          || Compare(a.Code?.toLowerCase(), b.Code?.toLowerCase())))]
      //
      // var nameMatch = dx.filter(c => !matched.some(dx => dx.Code == c.Code) && (
      //   c.Short?.toLowerCase().startsWith(code)
      //   || c.CName?.toLowerCase().indexOf(code) >= 0
      //   || c.EName?.toLowerCase().indexOf(code) >= 0))
      //   .sort((a, b) => Compare(a.CName?.toLowerCase().indexOf(code), b.CName?.toLowerCase().indexOf(code)
      //     || Compare(a.Code?.toLowerCase(), b.Code?.toLowerCase())));
      // return codeMatch.concat(nameMatch);
      return matched
    }
  }

  async checkAlternative(rxId: number) {
    var data = await this.rxApi.GetRxById(rxId);
    if (data) {
      return data.AlternativeRxId;
    } else {
      return null;
    }
  }

  async getOrderList(code: string, typeArray: number[], sortByMatch: boolean = false): Promise<RxOpt[]> {
    //var editopt = await this.histApi.getEditOptions();
    //console.log(editopt);
    var rx = await this.clinicData.GetRxOption();
    var rsInData = await this.clinicData.GetRsOption();
    rsInData = rsInData.sort((a, b) => a.MainSetId - b.MainSetId); // 因為網路傳輸導致資料未按MainSetId排序，所以要先排序一下
    var needRxSet = typeArray.includes(0);
    //var rsInData = editopt.rs.sort((a, b) => a.MainSetId - b.MainSetId);  // 因為網路傳輸導致資料未按MainSetId排序，所以要先排序一下
    var rxInType: RxOpt[] = [];
    if (rsInData.length > 0 && needRxSet) {
      var oId = 0;
      var nId = 0;
      for (let index = 0; index < rsInData.length; index++) {
        const item = rsInData[index];
        nId = item.MainSetId;
        if (nId != oId) {
          var d: RxOpt = new RxOpt();
          d.Id = item.RxId;
          d.IsByOral = false;
          d.RxCode = item.RsCode; //item.RxCode;
          d.ProdName = item.ProdName;
          d.Type = 0;
          d.StdCode = item.StdCode;
          d.MainSetId = item.MainSetId;
          d.RsCode = item.RsCode;
          d.IsDrugStatus = false;
          d.AlternativeRxId = item.AlternativeRxId;
          d.FlagDescription = 'MAINSET;';
          rxInType.push(d);
        }
        oId = nId;
      }
    }

    // var rxInType = editopt.rx;
    //editopt.rx.forEach(x => {
    rx.forEach(x => {
      rxInType.push(x);
    });
    if (typeArray.length > 0) {
      // rxInType = editopt.rx.filter(c => typeArray.includes(c.Type) || c.Type == 0);
      rxInType = rxInType.filter(c => typeArray.includes(c.Type));
    }

    var emptyCode = NullOrEmpty(code);
    if (emptyCode) {
      return rxInType;
    }

    code = code.toLowerCase()
    if (sortByMatch) {
      var codeMatch = rxInType.filter(c => c.RxCode?.toLowerCase().startsWith(code))
        .sort((a, b) => Compare(a.RxCode.toLowerCase(), b.RxCode.toLowerCase()));
      var nameMatch = rxInType.filter(c => !codeMatch.some(rx => rx.RxCode == c.RxCode) && c.ProdName?.toLowerCase().indexOf(code) >= 0)
        .sort((a, b) => Compare(a.ProdName?.toLowerCase().indexOf(code), b.ProdName?.toLowerCase().indexOf(code)));
      return codeMatch.concat(nameMatch);
    } else {
      var data = rxInType.filter(c => (c.RxCode?.toLowerCase().startsWith(code) || c.RsCode?.toLowerCase().startsWith(code) || c.ProdName?.toLowerCase().indexOf(code) >= 0));
      return data;
    }
    // const types = this.convertToString(typeArray);
    // let query = 'GetOrderList?code=' + code + '&types=' + types;
    // query = this.queryAddCount(query, count);
    // return this.api.get(query);
  }

  //#endregion


  // #region route service --------------------
  routeToOpdList() {
    this.clearRestore(this.currentHist?.Register?.Id);
    const url = this.routeInfo.makeFullUrl('/registers/opd-list');
    this.router.navigate([url]);
  }

  routeToHistList() {
    this.clearRestore(this.currentHist?.Register?.Id);
    const url = this.routeInfo.makeFullUrl('/patients/patient-hist');
    this.router.navigate([url]);
  }

  changePatientHist(rId: number) {
    this.clearRestore(this.currentHist?.Register?.Id);
    var url = this.routeInfo.makeFullUrl('/hist');
    this.router.navigateByUrl(url, { skipLocationChange: true }).then(() => {
      url = this.routeInfo.makeFullUrl('/hist/record');
      this.router.navigate([url, { regId: rId }])
    });
  }
  // #endregion route service
  updateDrugByClinicDefault(drug: Rx): Rx {
    // console.log('updateDrugByClinicDefault >>>>>', drug);
    var clinicDefault = this.EditOptions.histParams
    drug = Rx.fromData(drug);
    // ------------------------------------------------------
    if (Rx.isCommonDrug(drug)) {
      if (!drug.DefaultDose) {
        drug.DefaultDose = clinicDefault.DefaultDose;
      }
      if (!drug.DefaultFrequency) {
        drug.DefaultFrequency = clinicDefault.DefaultFrequency;
      }
      if (!drug.DefaultDays) {
        drug.DefaultDays = clinicDefault.DefaultDays;
      }
      if (!drug.DefaultWay) {
        drug.DefaultWay = clinicDefault.DefaultWay;
      }
    } else {
      drug.DefaultDose = drug.DefaultDose ?? 1;
      drug.DefaultFrequency = drug.DefaultFrequency ?? '1';
      drug.DefaultDays = drug.DefaultDays ?? 1;
      drug.DefaultWay = drug.DefaultWay ?? '';
    }

    return drug;
  }
  //#region 病歷編輯暫存
  storeHistTimer: any;
  storeHistInterval = 5000;
  enableStoreHist() {
    return BaseConfig.isDebug()
  }
  /** 開始準備暫存 10秒 */
  public beginStoreHist(getHist: () => WholeHist) {
    if (!this.enableStoreHist()) {
      return;
    }
    // console.log('beginStoreHist');
    this.storeHistTimer = setTimeout(() => {
      var h = getHist();
      if (!h) {
        //取病歷方法為空則終止
        //this.clearRestore();
        return;
      }
      this.storeHist(h);
      this.beginStoreHist(getHist);
    }, this.storeHistInterval);
  }
  /** 暫存編輯中病歷 */
  storeHist(h: WholeHist) {
    if (!this.enableStoreHist()) {
      return;
    }
    // 前次儲存的
    var h2: WholeHist = this.localStorage.getItem('HistRcd_' + h.Register.Id);
    // 當前編輯中的

    // 有差異才更新進LocalStorage
    if (!this.checkNeedStore(h, h2)) {
      // console.log('Data No need store');
      return;
    } else {
      this.localStorage.setItem('HistRcd_' + h.Register.Id, h);
      console.log('Data stored');
    }

  }
  // 比對有無差異
  checkNeedStore(d1: WholeHist, d2: WholeHist) {
    if (!d2) {
      return true;
    }
    Or
    if (!StringEqual(d1.Hist.CC, d2.Hist.CC)
      || !StringEqual(d1.Hist.PE, d2.Hist.PE)
      || !StringEqual(d1.Hist.Dx1, d2.Hist.Dx1)
      || !StringEqual(d1.Hist.Dx2, d2.Hist.Dx2)
      || !StringEqual(d1.Hist.Dx3, d2.Hist.Dx3)
      || !StringEqual(d1.Hist.Dx4, d2.Hist.Dx4)
      || !StringEqual(d1.Hist.Dx5, d2.Hist.Dx5)
      || !StringEqual(d1.Hist.Dx6, d2.Hist.Dx6)) {
      return true;
    }
    for (let i = 0; i < d1.Hist.HRxs.length; i++) {
      let hrx = d1.Hist.HRxs[i]
      var x = d2.Hist.HRxs[i];
      // 其中一邊有值 且非完全相等
      if ((x.RxCode || hrx.RxCode) && !(StringEqual(x.RxCode, hrx.RxCode) && x.SPRule == hrx.SPRule
        && x.QTY == hrx.QTY && StringEqual(x.Freq, hrx.Freq) && x.Days == hrx.Days
        && x.DailyDose == hrx.DailyDose && x.TotalDose == hrx.TotalDose
        && StringEqual(x.Way, hrx.Way) && x.DispTP == hrx.DispTP && StringEqual(x.Remark, hrx.Remark)
        && x.BeginDate == hrx.BeginDate && x.EndDate == hrx.EndDate && x.MedID == hrx.MedID)
      ) {
        return true
      }
    }
    if (d1.VitalSign.BT != d1.VitalSign.BT ||
      d1.VitalSign.DBP != d1.VitalSign.DBP ||
      d1.VitalSign.SBP != d1.VitalSign.SBP ||
      d1.VitalSign.DueDate != d1.VitalSign.DueDate ||
      d1.VitalSign.Head != d1.VitalSign.Head ||
      d1.VitalSign.Height != d1.VitalSign.Height ||
      d1.VitalSign.IsPregnant != d1.VitalSign.IsPregnant ||
      d1.VitalSign.LMPDate != d1.VitalSign.LMPDate ||
      d1.VitalSign.Pulse != d1.VitalSign.Pulse ||
      d1.VitalSign.VSDateTime != d1.VitalSign.VSDateTime ||
      d1.VitalSign.Weight != d1.VitalSign.Weight) {
      return true;
    }

    return false;
  }
  /** 暫存病歷還原 */
  public async restore(d: WholeHist): Promise<WholeHist> {
    if (!this.enableStoreHist()) {
      return;
    }
    var h: WholeHist = this.localStorage.getItem('HistRcd_' + d.Register.Id);

    if (h && this.checkNeedStore(d, h)) {
      var res = await this.userConfirmService.showConfirm({
        msg: '此病歷有編輯中的版本，是否要還原？',
        title: '發現未儲存的內容',
      })
      if (res) {
        var col: (keyof Hist)[] = ['CC', 'PE',
          'Dx1', 'Dx2', 'Dx3', 'Dx4', 'Dx5', 'Dx6',
          'Dx1Name', 'Dx2Name', 'Dx3Name', 'Dx4Name', 'Dx5Name', 'Dx6Name'];
        col.forEach((x: string) => d.Hist[x] = h.Hist[x]);

        d.Hist.HRxs = h.Hist.HRxs;
        d.VitalSign = h.VitalSign;
      } else {
        this.clearRestore(d.Register.Id);
        return null;
      }
    }
    this.clearRestore(d.Register.Id);
    return d
  }
  private clearRestore(id: number) {
    if (!this.enableStoreHist()) {
      return;
    }
    this.localStorage.removeItem('HistRcd_' + id);
    clearTimeout(this.storeHistTimer);
  }

  // 比對Rx, Dx有無差異
  RxDxChanged(d1: WholeHist, d2: WholeHist) {
    // if (!d2) {
    //   return true;
    // }
    // Or
    if (!StringEqual(TrimEnd(d1.Hist.Dx1, ' '), TrimEnd(d2.Hist.Dx1, ' '))
      || !StringEqual(TrimEnd(d1.Hist.Dx2, ' '), TrimEnd(d2.Hist.Dx2, ' '))
      || !StringEqual(TrimEnd(d1.Hist.Dx3, ' '), TrimEnd(d2.Hist.Dx3, ' '))
      || !StringEqual(TrimEnd(d1.Hist.Dx4, ' '), TrimEnd(d2.Hist.Dx4, ' '))
      || !StringEqual(TrimEnd(d1.Hist.Dx5, ' '), TrimEnd(d2.Hist.Dx5, ' '))
      || !StringEqual(TrimEnd(d1.Hist.Dx6, ' '), TrimEnd(d2.Hist.Dx6, ' '))) {
      // console.log('different dx');
      return true;
    }

    var ubound = d1.Hist.HRxs.length;
    if (d2.Hist.HRxs.length > d1.Hist.HRxs.length) ubound = d2.Hist.HRxs.length;
    for (let i = 0; i < ubound; i++) {
      let hrx = d1.Hist.HRxs[i];
      var x = d2.Hist.HRxs[i];
      if (hrx && x) {
        // 兩邊都有值 且非完全相等
        var BeginDate1 = new Date(hrx.BeginDate);
        var BeginDate2 = new Date(x.BeginDate);
        var EndDate1 = new Date(hrx.EndDate);
        var EndDate2 = new Date(x.EndDate);
        if (!(StringEqual(TrimEnd(x.RxCode ?? '', ' '), TrimEnd(hrx.RxCode ?? '', ' ')) || x.SPRule != hrx.SPRule
          || x.QTY != hrx.QTY && StringEqual(x.Freq ?? '', hrx.Freq ?? '') || x.Days != hrx.Days
          || x.DailyDose != hrx.DailyDose || x.TotalDose != hrx.TotalDose
          || !StringEqual(x.Way ?? '', hrx.Way ?? '') || x.DispTP != hrx.DispTP && !StringEqual(x.Remark ?? '', hrx.Remark ?? '')
          && BeginDate1 != BeginDate2 && EndDate1 != EndDate2 && x.MedID != hrx.MedID)) {
          console.log('different Rx 1');
          return true
        }
      } else if (!(!hrx && !x)) { // 其中一邊有值
        // console.log('different Rx 2');
        return true
      }
    }
    // console.log('All same');
    return false;
  }
  //#endregion
}
