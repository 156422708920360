<div [ngStyle]="sizeStyle" class="app-vis-window-panel" style="position: absolulte;">
    <!--縮放處理-->
    <ng-container *ngIf="resizable">
        <!--上-->
        <div class="resizer" style="position: absolute;top: 0px; height: 6px;width: 100%;cursor: n-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Up'],$event)"></div>
        <!--下-->
        <div class="resizer" style="position: absolute;bottom: 0px;height: 6px;width: 100%;cursor: s-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Down'],$event)"></div>
        <!--左-->
        <div class="resizer" style="position: absolute;left: 0px;height: 100%;width: 6px;cursor: w-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Left'],$event)"></div>
        <!--右-->
        <div class="resizer" style="position: absolute;right: 0px; height: 100%;width: 6px;cursor: e-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Right'],$event)"></div>
        <!--左上-->
        <div class="resizer" style="position: absolute;top:0px; left: 0px;height: 6px;width: 6px;cursor:nw-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Up','Left'],$event)"></div>
        <!--右上-->
        <div class="resizer" style="position: absolute;top:0px; right: 0px; height: 6px;width: 6px;cursor:ne-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Up','Right'],$event)"></div>
        <!--左下-->
        <div class="resizer" style="position: absolute;bottom:0px; left: 0px;height: 6px;width: 6px;cursor:sw-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Down','Left'],$event)"></div>
        <!--右下-->
        <div class="resizer" style="position: absolute;bottom:0px; right: 0px;height: 6px;width: 6px;cursor:se-resize;"
            draggable="true" (dragstart)="onDragStart($event)" (drag)="onDrag(['Down','Right'],$event)"></div>

    </ng-container>

    <!--本體-->
    <div style="margin: 3px;background-color: #f1f1f1;border: 1px solid #999;height: calc(100% - 6px);width: calc(100% - 6px);overflow: auto;display:flex;flex-direction: column;">
        <!--標題-->
        <div *ngIf="!hideTitle" cdkDrag cdkDragHandle cdkDragRootElement=".app-vis-window-panel" cdkDragBoundary=".main-content" (cdkDragEnded)="winDragEnded($event)"
            style="width: 100%;background-color:#154579;display: flex;height: 30px;" class="vis-window-titlebar">
            <div style="width: calc(100% - 30px);">
                <ng-content select="app-vis-window-titlebar">
                </ng-content>
            </div>
            <button
                style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
                mat-button  (click)="_onClose()">
                <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
            </button>
        </div>
        <!--Content-->
        <div style="flex-grow: 1;" class="overflow-hidden">
            <ng-content select=":not(app-vis-window-titlebar)">
            </ng-content>
        </div>
    </div>
</div>

<!--
<ng-template *ngIf="_show" #temp>
    <ng-container>
        <div matDialogTitle
            cdkDrag
            cdkDragRootElement=".cdk-overlay-pane"
            cdkDragHandle>
            <div style="width: calc(100% - 30px);">
                <ng-content select="app-vis-window-title">
                </ng-content>
            </div>
            <button type="button" style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
            mat-button  (click)="_onClose()">
                <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
            </button>
        </div>
        <div matDialogContent style="height: calc(100% - 65px);">
            <ng-content   select=":not(app-vis-dialog-actions)">
            </ng-content>
        </div>

        <div *ngIf="actionBar">
            <hr style="margin:0px">
            <ng-content select="app-vis-dialog-actions">
            </ng-content>
        </div>
    </ng-container>
</ng-template> -->
