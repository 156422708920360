<app-vis-dialog [title]="getTitle(histRecord)" [show]="showOpen" [drag]="true" [width]="0" [height]="0" [maxWidth]="'100vw'"
  [position]="{top:'0px', bottom:'', left:'', right:''}" [superWidth]="'99%'"  [showCloseBtn]="false"
  i18n-title="@@HistPricing">
  <div class="card price-dialog" style="padding:2px 0px;">
    <div class="card-header top-toolbar-area-sub w-100 d-block">
      <div class="float-left">
        <button type="button" (click)="saving(true)" [disabled]="isLoading" i18n="@@Save" class="position-relative">
          確認<span class="underline text-xs pb-1 px-1 vis-btn-block position-relative -top-2">F2</span>
        </button>
        <button type="button" (click)="saving(false)" [disabled]="isLoading" i18n="@@Save" class="position-relative">
          存檔不印<span class="underline text-xs pb-1 px-1 vis-btn-block position-relative -top-2">F4</span>
        </button>
        <button type="button" (click)="cancel()" i18n="@@Cancel" class="position-relative">
          返回<span class="underline text-xs pb-1 px-1 vis-btn-block position-relative -top-2">F8</span>
        </button>
        <!-- <button type="button" (click)="optionRefresh()" i18n="@@Refersh" class="position-relative">
          選項重整<span class="underline text-xs pb-1 px-1 vis-btn-block position-relative -top-2">F9</span>
        </button> -->
      </div>
      <div class="float-right" >
        <div style="align-self: center;" class="print-toolbar d-block m-0 ml-2">
          <ng-container i18n="@@Print">列印</ng-container> :
          <mat-checkbox [checked]="true"  [(ngModel)]="result.IsPrintPrescription">門診處方</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintDetail">門診明細及收據</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintWay">服法及收據</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintExam">院內檢驗單</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintDispPrescription" [disabled]="!canprintDisp">交付調劑處方箋</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintDispExam" [disabled]="!hasExam">交付檢驗單</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintDrugBag" [disabled]="!hasPharmacist">藥袋</mat-checkbox>
          <mat-checkbox [checked]="false" [(ngModel)]="result.IsPrintCtrDrugs" [disabled]="!isUseCTRDrugs" >管制藥品處方箋</mat-checkbox>
          <mat-checkbox class="position-relative end-0 float-right" [checked]="false" (change)="setToNext($event)"
            [labelPosition]="'before'">繼續下一位</mat-checkbox>
        </div>
      </div>
    </div>
    <!--(click)="closePricingWin(true)" primary="true"-->

    <div class="inside-scrollbar sun-good-price-padding" style="max-height: 320px;">
      <div class="vis-form extend extend-price">
        <mat-grid-tile colspan="1">
            <span i18n="@@Name">主治</span>
            <input class="vis-input" [value]="specItem" wordKeepCaptial (change)="SpecItemChanged($event)" id="specItem" />
          </mat-grid-tile>
        <mat-grid-list class="cashier-detail" *ngIf="histRecord && currentCashier" [rowHeight]="30"
          style="margin: 0px 0px;" cols="10" [gutterSize]="'5px'">
          <mat-grid-tile colspan="4">
            <span i18n="@@Name" class="w-16
            ">主診斷</span>
            <input class="vis-input w-26" [ngClass]="histRecord.Hist.Dx1Acute == 2? 'diag-acute' : '' " [(ngModel)]="histRecord.Hist.Dx1" (change)="dx1Changed($event)" />
            <input style="width: calc(100% - 10rem);" [ngClass]="histRecord.Hist.Dx1Acute == 2? 'diag-acute' : '' " readonly class="vis-input" [value]="histRecord.Hist.Dx1Name" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span [ngClass]="[histRecord.Hist.IsEdit=='1' ?fontGreater?'timeslargeSpan':'timesSpan':'']" i18n="@@Name">
              <mat-icon class="btn-scale-lock case-lock" title="批價已編輯，此欄位停用自動重新計算"
                 *ngIf="histRecord.Hist.IsEdit=='1'">lock</mat-icon>案類
            </span>
            <app-vis-combobox  class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [data]="caseOpt" [(ngModel)]="histRecord.Hist.Case" (valueChange)="caseChagne()"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">科別</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'" [data]="dept"
              [(ngModel)]="histRecord.Hist.Dept" (valueChange)="deptChange($event)"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">來源</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [data]="emerOpt" [(ngModel)]="histRecord.Hist.Emer" (valueChange)="emerChange($event)"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">給類</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [data]="payOpt" [(ngModel)]="histRecord.Hist.PayTP"
              (valueChange)="payTPChange($event)"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">勞職</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [data]="labOpt" [(ngModel)]="histRecord.Hist.LABTP"
              (valueChange)="labTyChange($event)"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">日份</span>
            <input class="vis-input" type="number" readonly [(ngModel)]="histRecord.Hist.Days"
              (change)="dayChange($event)">
          </mat-grid-tile>

          <mat-grid-tile colspan="4" *ngIf="!isPH && this.histRcd.Register.SameTreat != '0'">
            <span  i18n="@@Name" style="width:12.5%;min-width: 4rem">次診斷</span>
            <input class="vis-input" [ngClass]="subDxAcute? 'diag-acute' : '' " [value]="subDx" (change)="subDxChanged($event)" />
            <span i18n="@@Name"  style="width:18%;min-width: 6rem" >主次手術</span>
            <app-vis-combo-box-v2 class="vis2-input no-extend" [option]="pcsxOption" [inputValueFormat]="'value'" [isMultiple]="true" [multiMaxItemCount]="3"
             [queryTarget]="'value'" (onSelectedEmit)="surChanged($event)" [customIcon]="pcsxCustomIconTemplate" [(selectedOptions)]="pcsxSelectedOptions">
            </app-vis-combo-box-v2>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" *ngIf="!isPH && this.histRcd.Register.SameTreat == '0'">
            <span  class="sp-span"   i18n="@@Name" >次診斷</span>
            <input  class="vis2-input"    [ngClass]="subDxAcute? 'diag-acute' : '' " [value]="subDx" (change)="subDxChanged($event)" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="isPH">
            <span  i18n="@@Name">檢代</span>
            <input class="vis-input" [value]="histRecord.Hist.PHCResult" (change)="pHCResultChanged($event)" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="isPH">
            <span  i18n="@@Name">項目</span>
            <input class="vis-input"  [value]="histRecord.Hist.PHCScCode" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="!isPH">
            <span i18n="@@Name">主治</span>
            <app-vis-multicombobox  class="vis2-input no-extend" [valueFormat]="'multiCommaValue'"
              [data]="spcOpt" [value]="specItem" [othersetvalue]="specItem" [defaultValue]="specItem"
              wordKeepCaptial (valueChange)="SpecItemChanged($event)">
            </app-vis-multicombobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span [ngClass]="[histRecord.Register.StatusCode=='F' ?fontGreater?'timeslargeSpan':'timesSpan':'']" i18n="@@Name">
              <mat-icon   class="btn-scale-lock case-lock" *ngIf="histRecord.Register.StatusCode=='F'"
                title="已完診，此欄位停用自動重新計算">lock</mat-icon>調劑
            </span>
            <app-vis-combobox  class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [data]="dispOpt" [(ngModel)]="histRecord.Hist.DispTP" [disabled]="isDispDisabled"
              (valueChange)="dispChange($event)"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">轉診</span>
            <button type="button" tabindex="-1" [disabled]="false" style="height: 24px;width: 24px; padding: 0px;" (click)="openReferral()">
              <mat-icon style="font-size: 12px;line-height: unset;">edit</mat-icon>
            </button>
            <app-vis-combobox class="vis-input no-extend" style="width: 35%;" [panelFitContent]="true"
              [valueFormat]="'value'" [(ngModel)]="histRecord.Hist.Referral" [data]="referralOpt"></app-vis-combobox>
          </mat-grid-tile>

          <mat-grid-tile colspan="1">
            <span style="width: 52.5%"  [ngClass]="[fontGreater?'timeslargeSpan':'timesSpan']" [title]="'同療次別'" i18n="@@Name">同療次別</span>
            <div style="width: 47.5%;display: flex;">
              <input class="vis-input" style="width: 50%;" [(ngModel)]="histRecord.Hist.CusNo" appNumberOnly />
              <span style="margin:0px 3px;width: auto;">/</span>
              <input class="vis-input" style="width: 50%;" [(ngModel)]="histRecord.Hist.CusTot" type="tel" />
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span style="width: 52.5%"
              *ngIf="!forceFreeDrugPartFee; else forceFree"
              [ngClass]="[fontGreater?'timeslargeSpan':'timesSpan']"
              [title]="getCntPresTitle()" i18n="@@Name">慢箋次別</span>
            <ng-template #forceFree><span class="text-red" [title]="'強制慢箋'">強制慢箋</span></ng-template>
            <div style="width: 47.5%;display: flex;">
              <div style="flex-grow: 1;">
                {{histRecord.Hist.CntPresNo||'-'}}/{{histRecord.Hist.CntPresTot||'-'}}</div>

              <button #presButton type="button" [disabled]="!allowChronicPres" style="height: 24px;width: 24px; padding: 0px;"
                (click)="openCntPres(tmpCnt,$event)">
                <mat-icon style="font-size: 12px;line-height: unset;">edit</mat-icon>
              </button>

              <ng-template #tmpCnt>
                <div style="display: block;padding: 10px;">
                  總共可調劑次數<br>
                  <mat-radio-group style="width: 100%;display: grid;" [(ngModel)]="cnt" (change)="onGroupChange($event)">
                    <mat-radio-button [disabled]="cntDisabled[0]" [value]="'2'">2次
                    </mat-radio-button>
                    <mat-radio-button [disabled]="cntDisabled[1]" [value]="'3'">3次
                    </mat-radio-button>
                    <mat-radio-button [disabled]="cntDisabled[2]" [value]="'4'">4次
                    </mat-radio-button>
                    <mat-radio-button [disabled]="cntDisabled[3]" [value]="null">取消
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-checkbox #f style="display: none;" [(ngModel)]="forceFreeDrugPartFee" (change)="onForceFreeChange($event)">強制慢箋免藥自付<br />(停經症候群開藥21日)</mat-checkbox>
                  <div style="display: flex;">
                    用藥開始日：
                    <app-vis-datepicker style="flex-grow: 1;"  [max]="presDateMax" [disabled]="disablecntDate" [(ngModel)]="cntDate"></app-vis-datepicker>
                    <button type="button" title="設為今日"  [disabled]="disablecntDate" style="width: 25px;height: 25;padding: 0px;"
                      (click)="cntToday()">今</button>
                  </div>
                  <div (click)="stopp($event)">
                    <button type="button" (click)="setCnt(cnt,f.checked,null)">確定</button>
                    <button type="button" (click)="closeCnt()">取消</button>
                  </div>
                </div>
              </ng-template>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">藥師</span>
            <app-vis-dropdownlist class="vis-input text-base" [disabled]="isPharmacistDisabled()" [data]="phOpt"
              [(ngModel)]="histRecord.Hist.PharmacistId" (valueChange)="pharmChange($event)">
              <ng-template let-data>
                {{data.text}}<span
                [ngClass]="[isOverQuota(data.extension)?'text-red':'text-black']">({{data.extension}})</span>
              </ng-template>
            </app-vis-dropdownlist>
          </mat-grid-tile>

          <mat-grid-tile colspan="4">
            <span class="w-10" i18n="@@Name">備註</span>
            <input style="width: calc(100% - 2.5rem);" class="vis-input" name="Oper1" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">註記</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'value'"
              [valueFormat]="'value'" [(ngModel)]="histRecord.Hist.SpecAreaSV" (valueChange)="itinerantConsultationChange($event)"  [data]="consultationOpt"></app-vis-combobox>
          </mat-grid-tile>

          <mat-grid-tile colspan="1">
            <span i18n="@@Name" [ngClass]="[fontGreater?'timeslargeSpan':'timesSpan']" style="width: 88%;">支援地區代碼</span>
            <!-- <mat-checkbox [disabled]="!isHomeCare || !isNeedRemote" [checked]="supportAreaIsChecked" (change)="supportAreaChange($event)"></mat-checkbox> -->
          </mat-grid-tile>
          <mat-grid-tile colspan="1" >
            <app-vis-combobox *ngIf="histRecord.Hist.SpecAreaSV == '01' || isNullOrEmpty(histRecord.Hist.SpecAreaSV)" class="vis-input no-extend" style="width: 100%;" [textField]="'text'"
            [valueField]="'value'" [(ngModel)]="histRecord.Hist.SupArea" (valueChange)="supAreaChange($event)"
            [disabled]="!supportAreaIsChecked" [valuePrimitive]="false" [data]="[]"></app-vis-combobox>
            <app-vis-combobox *ngIf="histRecord.Hist.SpecAreaSV == '02'" class="vis-input no-extend" style="width: 100%;" [textField]="'text'"
              [valueField]="'value'" [(ngModel)]="histRecord.Hist.SupArea" (valueChange)="supAreaChange($event)"
               [valuePrimitive]="false" [data]="medLack02Areas"></app-vis-combobox>
              <app-vis-combobox *ngIf="histRecord.Hist.SpecAreaSV == '03'" class="vis-input no-extend" style="width: 100%;" [textField]="'text'"
              [valueField]="'value'" [(ngModel)]="histRecord.Hist.SupArea" (valueChange)="supAreaChange($event)"
               [valuePrimitive]="false" [data]="medLack03Areas"></app-vis-combobox>
              <app-vis-combobox *ngIf="histRecord.Hist.SpecAreaSV == '04'" class="vis-input no-extend" style="width: 100%;" [textField]="'text'"
              [valueField]="'value'" [(ngModel)]="histRecord.Hist.SupArea" (valueChange)="supAreaChange($event)"
               [valuePrimitive]="false" [data]="medLack04Areas"></app-vis-combobox>
          </mat-grid-tile>

          <mat-grid-tile colspan="1" *ngIf="!this.isHomeCare  && !this.isRecoveryHome">
            <div>
              <span i18n="@@Urgent">假日加成</span>
              <mat-checkbox (change)="UrgentChange($event)" [(ngModel)]="isUrgent"></mat-checkbox>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="!this.isHomeCare && !this.isRecoveryHome">
            <div>
              <span i18n="@@UrgentNight">夜間加成</span>
              <mat-checkbox (change)="UrgentNightChange($event)" [(ngModel)]="isUrgentNight"></mat-checkbox>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="this.isHomeCare">
            <span i18n="@@UrgentHC" [ngClass]="[ErrorAndWarning.UrgentHC === 'W'?'error-and-warning':'']" class="w-20">居護加成</span>
            <mat-checkbox (change)="UrgentHCChange($event)" [(ngModel)]="isUrgentHC"></mat-checkbox>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" *ngIf="!isDisabledLab">
            <span i18n="@@Name">代檢機構</span>
            <button type="button" tabindex="-1" [disabled]="isDisabledLab" style="height: 24px;width: 24px; padding: 0px;" (click)="openLablist()">
              <mat-icon style="font-size: 12px;line-height: unset;">檢</mat-icon>
            </button>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'"
            [data]="labAddrCodeOpt" [(ngModel)]="histRecord.Hist.LabHosp" [disabled]="true" (valueChange)="1"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="2" *ngIf="this.isInJail" >
            <span i18n="@@Name">矯正機關
            </span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'" [setNotInComboData]="true"
            [data]="correctionalInstitution"  (valueChange)="onJaComboBoxChange('JailAddressCode',$event)" [(ngModel)]="JailInfo.JailAddressCode" ></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="this.isInJail">
            <span i18n="@@Name">呼號</span>
            <input class="vis-input"   [value]="JailInfo.PensonalCode"
            (change)="onJaInputChange('PersonalCode',$event)">
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="this.isInJail">
            <span i18n="@@Name">場舍</span>
            <input class="vis-input"   [value]="JailInfo.JailHouse"
            (change)="onJaInputChange('JailHouse',$event)">
          </mat-grid-tile>
          <!-- <mat-grid-tile colspan="1" *ngIf="!this.isInJail">
          </mat-grid-tile> -->
          <mat-grid-tile colspan="3" *ngIf="this.histRcd.Register.SameTreat == '0'">
            <span i18n="@@Name">HCT</span>
            <input class="vis-input"  [value]="kidneyDialysisDto.HCT"
            (change)="onKDInputChange('HCT',$event)"/>
            <span i18n="@@Name">Hb</span>
            <input class="vis-input"   [value]="kidneyDialysisDto.Hb"
            (change)="onKDInputChange('HB',$event)">
          </mat-grid-tile>

            <mat-grid-tile colspan="1" *ngIf="this.histRcd.Register.SameTreat == '0'">
              <span i18n="@@Name">Cr.</span>
              <input class="vis-input"   [value]="kidneyDialysisDto.Cr"
              (change)="onKDInputChange('CR',$event)">
            </mat-grid-tile>
            <mat-grid-tile colspan="1" *ngIf="this.histRcd.Register.SameTreat == '0'">
              <span i18n="@@Name">A.K.</span>
              <input class="vis-input"  [value]="kidneyDialysisDto.AK"
              (change)="onKDInputChange('AK',$event)">
            </mat-grid-tile>
            <mat-grid-tile colspan="1" *ngIf="this.histRcd.Register.SameTreat == '0'" >
              <span i18n="@@Name">BUN</span>
              <input class="vis-input" [value]="kidneyDialysisDto.BUN"
                (change)="onKDInputChange('BUN',$event)">
            </mat-grid-tile>
            <mat-grid-tile colspan="1" *ngIf="this.histRcd.Register.SameTreat == '0'">
              <span i18n="@@Name">Time</span>
              <app-vis-timepicker [(ngModel)]="kidneyDialysisDto.Time" (valueChange)="translateSPEString('TIME',$event)" class="odr-cell" autocomplete="off" appNumberOnly>
              </app-vis-timepicker>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" *ngIf="this.histRcd.Register.SameTreat == '0'">
              <span i18n="@@Name">EPO</span>
              <input class="vis-input"  readonly  [value]="kidneyDialysisDto.EPO"
              (change)="onKDInputChange('EPO',$event)">
            </mat-grid-tile>
            <mat-grid-tile colspan="2" *ngIf="this.histRcd.Register.SameTreat == '0'">
              <span i18n="@@FirstDialysisDate">初次來診洗腎日期</span>
              <app-vis-datepicker [(ngModel)]="this.histRcd.Patient.FirstDialysisDate" [maxWidth]="false">
              </app-vis-datepicker>
            </mat-grid-tile>

          <mat-grid-tile colspan="1" *ngIf="this.isHomeCare">
            <span i18n="@@Name">居護收案</span>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'" [title]="histRecord.Hist.PaySysA+' | '+(histRecord.Hist.PaySysA|valueToText:paySysAOpt)"
              [data]="paySysAOpt" [(ngModel)]="histRecord.Hist.PaySysA"></app-vis-combobox>
          </mat-grid-tile>
          <mat-grid-tile colspan="10">
            <div style="width: 95%;color: red;" i18n="@@Name" [title]="'病名：'+illWord">病名：{{illWord}}</div>
          </mat-grid-tile>

          <mat-grid-tile colspan="10">
            <div *ngIf="!isPH" style="width: 95%;color: red;" i18n="@@Name" [title]="'主治描述：'+specItemword">主治描述：{{specItemword}}</div>
            <div *ngIf="isPH" style="width: 95%;color:green;" i18n="@@Name" [title]="'檢代：'+checkCodeWord">檢代：{{checkCodeWord}}</div>
          </mat-grid-tile>

          <mat-grid-tile colspan="7" >
            <div *ngIf="!isPH && this.histRcd.Register.SameTreat != '0'" style="width: 95%;color: red;" i18n="@@surDescription"
                [title]="'手術描述：'+surDescription">手術描述：{{surDescriptionCut}}</div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span><mat-icon>sim_card</mat-icon></span>
            <mat-slide-toggle (change)="ICCardChange($event)" color="accent" [disabled]="isNotUseCheckCard"
            [(ngModel)]="histRecord.Hist.IsCardWrite" labelPosition="after"> <!-- 無卡或沒有卡機都會disabled -->
              寫IC卡
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span><mat-icon>swap_vert</mat-icon></span>
            <mat-slide-toggle (change)="EMRChange($event)" color="accent"
            [(ngModel)]="histRecord.Hist.useEmr" labelPosition="after">
              電子病歷
            </mat-slide-toggle>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span><mat-icon>satellite_alt</mat-icon></span>
            <mat-slide-toggle (change)="UseSatellitePharmacy($event)" color="accent" [disabled]="!histRecord.Hist.hasSatellitePharmacyIP"
            [(ngModel)]="histRecord.Hist.useSatellitePharmacy" labelPosition="after"> <!-- 沒有設定衛星傳送URL會disabled -->
              衛星傳送
            </mat-slide-toggle>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list class="cashier-detail count-a" *ngIf="histRecord && currentCashier" [rowHeight]="30"
          style="margin: 0px 0px;" cols="6" [gutterSize]="'5px'">
          <mat-grid-tile colspan="1" *ngIf="currentCashier.DiagFee" #countA>
            <span i18n="@@Name">診察費</span>
            <input class="vis-input" readonly [value]="currentCashier.DiagFee" />
          </mat-grid-tile>
          <!--藥費-->
          <mat-grid-tile colspan="1" *ngIf="currentCashier.Oral" #countA>
            <span i18n="@@Name">內服藥費</span>
            <input class="vis-input" readonly [value]="currentCashier.Oral" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.External" #countA>
            <span i18n="@@Name">外用藥費</span>
            <input class="vis-input" readonly [value]="currentCashier.External" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.Injection" #countA>
            <span i18n="@@Name">注射藥費</span>
            <input class="vis-input" readonly [value]="currentCashier.Injection" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.InjectionService" #countA>
            <span i18n="@@Name">注射技術費</span>
            <input class="vis-input" readonly [value]="currentCashier.InjectionService" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.Lab" #countA>
            <span i18n="@@Name">檢驗費</span>
            <input class="vis-input" readonly [value]="currentCashier.Lab" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.XRay" #countA>
            <span i18n="@@Name">X光費</span>
            <input class="vis-input" readonly [value]="currentCashier.XRay" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.RehabilitationFee" #countA>
            <span i18n="@@Name">理療費</span>
            <input class="vis-input" readonly [value]="currentCashier.RehabilitationFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.TreatmentFee" #countA>
            <span i18n="@@Name">處置費</span>
            <input class="vis-input" readonly [value]="currentCashier.TreatmentFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.SurgeryFee" #countA>
            <span i18n="@@Name">手術費</span>
            <input class="vis-input" readonly [value]="currentCashier.SurgeryFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.AnesthesiaFee" #countA>
            <span i18n="@@Name">麻醉費</span>
            <input class="vis-input" readonly [value]="currentCashier.AnesthesiaFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.SpecialMaterialFee" #countA>
            <span i18n="@@Name">特材費</span>
            <input class="vis-input" readonly [value]="currentCashier.SpecialMaterialFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.BloodPlasmaFee" #countA>
            <span i18n="@@Name">輸血費</span>
            <input class="vis-input" readonly [value]="currentCashier.BloodPlasmaFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.HemodialysisFee" #countA>
            <span i18n="@@Name">透析費</span>
            <input class="vis-input" readonly [value]="currentCashier.HemodialysisFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.CareFee" #countA>
            <span i18n="@@Name">病房費</span>
            <input class="vis-input" readonly [value]="currentCashier.CareFee" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.ChargeFee" #countA>
            <span i18n="@@Name">代辦費</span>
            <input class="vis-input" readonly [value]="currentCashier.ChargeFee||null" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.PsychiatryTreatmentFee" #countA>
            <span i18n="@@Name">精神治療費</span>
            <input class="vis-input" readonly [value]="currentCashier.PsychiatryTreatmentFee||null" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.OpdPart || currentCashier.OpdPartFromReg" #countA>
            <span i18n="@@Name">部份負擔</span>
            <input class="vis-input" readonly [title]="'掛號部分負擔：'+currentCashier.OpdPartFromReg" [value]="currentCashier.OpdPart" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.DrugPart" #countA>
            <span i18n="@@Name">藥費部份負擔</span>
            <input class="vis-input" readonly [value]="currentCashier.DrugPart" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.ExamPart" #countA>
            <span i18n="@@Name">檢驗部份負擔</span>
            <input class="vis-input" readonly [value]="currentCashier.ExamPart" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="currentCashier.MedicalService" #countA>
            <span i18n="@@Name">藥事服務費</span>
            <input class="vis-input" readonly [value]="currentCashier.MedicalService" />
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list class="cashier-detail total mt-1" *ngIf="histRecord && currentCashier" [rowHeight]="30"
          style="margin: 0px 0px;" cols="6" [gutterSize]="'5px'">
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">小計</span>
            <input class="vis-input" readonly [value]="currentCashier.SubSum" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">總額</span>
            <input class="vis-input" readonly [value]="currentCashier.Total" />
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <span i18n="@@Name">自費</span>
            <input class="vis-input" (change)="selfcashChange($event)" [value]="histRecord.Hist.AOwn" />
          </mat-grid-tile>
        </mat-grid-list>
        <div class="position-absolute float-left rounded-3 p-1" style="line-height: 1rem;width: 1.6rem;background: #a6e4f4;bottom: 2px; left:0.2rem;
      ">金額預覽</div>
      </div>
    </div>
  </div>
</app-vis-dialog>


<app-vis-dialog title="代檢醫令明細" [maxWidth]="'60vw'" [superWidth]="'60%'" [show]="isShowRegDialog" (close)="closeLablist()">
  <div>
    <app-vis-grid [data]="labgridview" [pageable]="false"[scroll]="true"
      [showStickyHeight]="200"
      [showMultiLineHead]="true"
      [showStickyHead]="true"
      [tableFullWidth]="'w-100'"
      >
        <app-vis-grid-column field="RxCode" title="代碼" [width]="45" i18n-title="@@RegPeriod" class="text-center">
        </app-vis-grid-column>
        <app-vis-grid-column field="ProdName" title="名稱" [width]="100" i18n-title="@@RegPeriod" class="text-center">
        </app-vis-grid-column>

        <app-vis-grid-column [width]="70" field="TransOri" title="代檢機構" class="text-center custom-m-auto">
          <ng-template kendoGridCellTemplate let-dataItem>
            <app-vis-combobox class="vis-input no-extend" [panelFitContent]="true" [valueFormat]="'text'"
            [data]="labAddrCodeOpt" (valueChange)="labAddrCodeChange($event,dataItem)"  [defaultValue]="dataItem.TransOri" [value]="dataItem.TransOri"></app-vis-combobox>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
  </div>
    <app-vis-dialog-actions>
      <mat-checkbox [checked]="true"  (change)="labCheckBoxChange($event)" [value]="isChangingToGether" >同代檢機構一同置換</mat-checkbox>
      <button type="button" (click)="closeLablist()" i18n="@@DialogClose">回批價框</button>
    </app-vis-dialog-actions>
</app-vis-dialog>


<app-vis-dialog title="手術處置代碼檢索" [maxWidth]="'80vw'" [superWidth]="'80%'" [show]="isShowPCSxAllocationDialog" (close)="isShowPCSxAllocationDialog=false">
  <app-pcsx-allocation *ngIf="isShowPCSxAllocationDialog" (selectEmit)="handlePCSxSelect($event)" (closeEmit)="isShowPCSxAllocationDialog=false" [(selectedOptions)]="pcsxSelectedOptions"></app-pcsx-allocation>
</app-vis-dialog>

<ng-template #pcsxCustomIconTemplate>
  <button type="button" mat-icon-button class="setting-button" (click)="onPCSxSettingClick()">
    <mat-icon>settings</mat-icon>
  </button>
</ng-template>
