import { Entity } from "src/app/shared/models/entity";

export class HistDiag extends Entity {

  constructor() {
    super();
  }

  no: number;
  code: string;
  applyCode: string;
  name: string;
  acuteNote: number;
  // static create(no: number, code: string, name: string): HistDiag {
  static create(no: number, code: string, name: string, acuteNote: number): HistDiag {
    const diag = new HistDiag();
    diag.no = no;
    diag.code = code;
    diag.name = name;
    diag.acuteNote = acuteNote;
    return diag;
  }
  static createEmpty(): HistDiag {
    const diag = new HistDiag();
    diag.no = 0;
    diag.code = '';
    diag.name = '';
    diag.acuteNote = 0;
    return diag;
  }
  copy(diag: HistDiag) {
    if (!diag) {
      return;
    }
    this.no = diag.no;
    this.code = diag.code;
    this.name = diag.name;
    this.acuteNote = diag.acuteNote;
  }
}
