 <!-- component/input -->
  <div style="height:calc(100% - 1px); border: 1px solid #aaa; overflow-x: hidden;" [ngClass]="[((isShowVSOption('LMP') || isShowVSOption('EDD')))?'overflow-y-auto':'',isHelperPadOpened]">
    <form style="height: 100%;" [ngStyle]="{height:outRmOpen?'100%':(!(isShowVSOption('EDD')&&isShowVSOption('LMP')))?'120px':''}" [formGroup]="editFG" *ngIf="displayOrder.length">
    <div [class]="'vs-wrapper displayOrder vital-area'" [id]="areaId" [ngClass]="[showVitalSignPanel ? 'd-block' : 'd-none']">
      <div class="vs-line">
          <span class=" min-width-label vs-date-time lh-base lh-lg">測量日期</span>
          <span class=" lh-base lh-lg">
            <ng-container *ngIf="editFG?.controls?.VSDateTime.value">&nbsp;民</ng-container>{{editFG?.controls?.VSDateTime.value | formatDate}}
          </span>
        </div>
      <ng-container *ngFor="let l of displayOrder; let i = index">
          <div class="displayOrder d-flex">
            <ng-container *ngFor="let cell of l;let j = index">
              <ng-container [ngSwitch]="cell.code">
                <div class="vs-line" *ngSwitchCase="'WT'||'HT'||'BMI'">
                  <div *ngIf="isShowVSOption('HT')" style="width: 33%;" class="flex-grow-one d-flex">
                    <span class="min-width-label" class="flex-grow-one d-flex word-keep-all">身高&nbsp;</span>
                    <span [ngClass]="notifyClass('Height')" class="flex-grow-one d-flex">
                      <input appHistFocus numberTrimPreZero
                        class="w-50 measure bg-white"
                        [class.v-disabled]="hasUploaded"
                        maxlength="5"
                        [appKeydownTo]="cell.code"
                        [nextTo]="nextCodes(i,j)"
                        [prevTo]="preCodes(i,j)"
                        (appKeyDown)="focusBack($event,i,j)"
                        formControlName="Height"
                        [id]="heightId" />
                    </span>
                  </div>
                  <div *ngIf="isShowVSOption('WT')" style="width: 33%;" class=" flex-grow-one d-flex">
                      <span class="  min-width-label" class="flex-grow-one d-flex word-keep-all">體重&nbsp;</span>
                      <span [ngClass]="notifyClass('Weight')" class="flex-grow-one d-flex">
                        <input appHistFocus numberTrimPreZero
                          class="w-50 measure bg-white"
                          [class.v-disabled]="hasUploaded"
                          maxlength="5"
                          [appKeydownTo]="cell.code"
                          [nextTo]="nextCodes(i,j)"
                          [prevTo]="preCodes(i,j)"
                          (appKeyDown)="focusBack($event,i,j)"
                          formControlName="Weight"
                          [id]="weightId" />
                      </span>
                  </div>
                  <div class="vt-bmi-none" *ngIf="isShowVSOption('BMI')" style="width: 33%;" class="flex-grow-one d-flex">
                      <span class="min-width-label d-flex flex-grow-one">BMI:&nbsp;
                      <span *ngIf="editFG?.controls?.Height.value && editFG?.controls?.Weight.value" [ngClass]="notifyClass('BMI')" class="w-50">{{ BMIVal }}</span>
                      <span *ngIf="!BMIVal" class="flex-grow-one d-flex word-keep-all">--</span>
                      </span>
                  </div>
                 </div>
                 <div class="vs-line" *ngSwitchCase="'BT'||'HC'">
                    <div *ngIf="isShowVSOption('BT')" style="width: 33%;" class="flex-grow-one d-flex">
                      <span class="min-width-label" class="flex-grow-one d-flex word-keep-all">體溫&nbsp;</span>
                      <span [ngClass]="notifyClass('BT')" class="flex-grow-one d-flex">
                        <input [class.v-disabled]="hasUploaded"
                          class="w-50 measure bg-white" numberTrimPreZero
                          maxlength="4"
                          formControlName="BT"
                          [id]="btId"
                          [appKeydownTo]="cell.code"
                          [nextTo]="nextCodes(i,j)"
                          [prevTo]="preCodes(i,j)"
                          (appKeyDown)="focusBack($event,i,j)"
                          appHistFocus />
                      </span>
                    </div>
                    <div *ngIf="isShowVSOption('HC')&&nowAge<7" style="width: 33%;" class="flex-grow-one d-flex">
                       <!-- (click)="openChildGrow('Head')" -->
                      <span class="  min-width-label" class="flex-grow-one d-flex word-keep-all">頭圍&nbsp;</span>
                      <span [ngClass]="notifyClass('Head')" class="flex-grow-one d-flex">
                        <input appHistFocus numberTrimPreZero
                          [class.v-disabled]="hasUploaded"
                          class="w-50 measure bg-white"
                          [appKeydownTo]="cell.code"
                          [nextTo]="nextCodes(i,j)"
                          [prevTo]="preCodes(i,j)"
                          (appKeyDown)="focusBack($event,i,j)"
                          oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                          maxlength="4"
                          formControlName="Head"
                          [id]="headId" />
                      </span>
                     </div>
                     <div *ngIf="isShowVSOption('BT') && isShowVSOption('BT')" class="vt-hc-none" [ngStyle]="{width:nowAge<7? '33%':'66%'}" class="flex-grow-one">
                    </div>
                  </div>
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
          <!-- -->
          <ng-container *ngFor="let l of displayOrder;let i = index">
           <ng-container *ngFor="let cell of l;let j = index">
            <ng-container [ngSwitch]="cell.code">
              <div class="vs-line" *ngSwitchCase="'BP'">
                <!-- <div class="vs-line"  >
                  <label class="vs-label" title="血壓" i18n-title="@@VSBP" i18n="@@VSBP">血壓<span class="measure-note">(收縮壓/舒張壓/脈搏)</span></label>
                </div> -->
                <span title="收縮壓/舒張壓/脈搏" class="min-width-label" class="flex-grow-one d-flex word-keep-all" style="max-width: 25%;">血壓&nbsp;</span>
                <span [ngClass]="notifyClass('SBP')" class="flex-grow-one d-flex">
                    <input appHistFocus
                      placeholder="收縮"
                      [class.v-disabled]="hasUploaded"
                      class="bg-white w-50"
                      [appKeydownTo]="'SBP'"
                      [nextTo]="'DBP'"
                      [prevTo]="preCodes(i,j)"
                      oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                      maxlength="3"
                      formControlName="SBP"
                      [id]="sbpId" />
                  </span>
                  <span [ngClass]="notifyClass('DBP')" class="flex-grow-one d-flex">
                    <input appHistFocus
                      placeholder="舒張"
                      [class.v-disabled]="hasUploaded"
                      class="bg-white w-50"
                      [appKeydownTo]="'DBP'"
                      [nextTo]="'Pulse'"
                      [prevTo]="'SBP'"
                      oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                      maxlength="3"
                      formControlName="DBP"
                      [id]="dbpId"
                      appHistFocus />
                  </span>
                  <span [ngClass]="notifyClass('Pulse')" class="flex-grow-one d-flex">
                    <input appHistFocus
                      placeholder="脈搏"
                      [class.v-disabled]="hasUploaded"
                      class="bg-white w-50"
                      [appKeydownTo]="'Pulse'"
                      [nextTo]="nextCodes(i,j)"
                      [prevTo]="'DBP'"
                      (appKeyDown)="focusBack($event,i,j)"
                      oninput="value=value.replace(/^(0+)|[^\d]+/g,'')"
                      maxlength="3"
                      formControlName="Pulse"
                      [id]="pulseId" />
                  </span>
              </div>
              <ng-container *ngIf="initVS.isFemale">
                <div class="vs-line" *ngSwitchCase="'LMP'||'EDD'">
                  <ng-container *ngIf="isShowEDD">
                    <mat-checkbox class="vs-check ml-1" formControlName="IsPregnant" (change)="pregnantChange($event)" appHistFocus>懷孕</mat-checkbox>
                  </ng-container>
                </div>
                <div class="vs-line" *ngSwitchCase="'LMP'" >
                  <ng-container *ngIf="isShowLMP">
                    <span class="  vs-date-time min-width-label lh-base lh-lg">最後來經</span>
                    <app-vis-datepicker appHistFocus
                        [customClass]="'bg-white'"
                        [maxlength]="'w-16'"
                        [attr.disabled]="hasUploaded"
                        [appKeydownTo]="cell.code"
                        [nextTo]="nextCodes(i,j)"
                        [prevTo]="preCodes(i,j)"
                        (appKeyDown)="focusBack($event,i,j)"
                        formControlName="LMPDate"
                        [id]="lmpId"
                        #VSLMP>
                      </app-vis-datepicker>
                  </ng-container>
                </div>
                <div class="vs-line" *ngSwitchCase="'EDD'" >
                  <ng-container *ngIf="isShowEDD">
                        <span i18n="@@VSDueDate" class="vs-date-time min-width-label lh-base lh-lg">預產日期</span>
                        <app-vis-datepicker appHistFocus
                          [customClass]="'bg-white'"
                          [maxlength]="'w-16'"
                          [attr.disabled]="hasUploaded"
                          class="position-relative top-2"
                          [appKeydownTo]="cell.code"
                          [nextTo]="nextCodes(i,j)"
                          [prevTo]="preCodes(i,j)"
                          (appKeyDown)="focusBack($event,i,j)"
                          formControlName="DueDate">
                        </app-vis-datepicker>
                  </ng-container>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>

    </div><!-- ./Vital Signs -->
  </form>
  </div>

  <ng-template let-data #childGrowTmp>
    <div style="width:95%; height:95%">
      <div class="d-flex">
        <div class="d-flex flex-grow-one"><app-child-grow-chart [sex]="data.sex" [dataType]="'Height'" [month]="getFullMonthAge()" [value]="editFV.Height"></app-child-grow-chart></div>
        <div class="d-flex flex-grow-one"><app-child-grow-chart [sex]="data.sex" [dataType]="'Weight'" [month]="getFullMonthAge()" [value]="editFV.Weight"></app-child-grow-chart></div>
      </div>
      <div class="d-flex">
        <div class="d-flex flex-grow-one"><app-child-grow-chart [sex]="data.sex" [dataType]="'BMI'" [month]="getFullMonthAge()" [value]="calcBMI()"></app-child-grow-chart></div>
        <div class="d-flex flex-grow-one"><app-child-grow-chart [sex]="data.sex" [dataType]="'Head'" [month]="getFullMonthAge()" [value]="editFV.Head"></app-child-grow-chart></div>
      </div>
    </div>
  </ng-template>


  <ng-template #vtHist>
    <div class="inside-scrollbar p-0" style="max-height: 455px;">
      <app-vis-grid class="small-paginater"
        [data]="vtRecords"
        [customClass]="'extend'"
        [pageable]="true"
        [pageSize]="vtPageSize"
        [skip]="vtPageSkip"
        (pageChange)="vtDetailPageChange($event)" >
        <app-vis-grid-column [field]="'VSDateTime'" [title]="'日期'" [width]="100" class="text-center custom-m-auto">
          <ng-template let-rec><div class="ellipsis-content" [title]="rec.VSDateTime|formatDate">{{rec.VSDateTime|formatDate}}</div></ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'Height'" [title]="'身高'" [width]="75" class="text-right">
          <ng-template let-rec>
            <div style="display: flex;">
              <div class="vt-rec-cell ellipsis-content">{{rec.Height||' --- '}}</div>
              <div>cm</div>
            </div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'Weight'" [title]="'體重'" [width]="75" class="text-right">
          <ng-template let-rec>
            <div style="display: flex;">
              <div  class="vt-rec-cell ellipsis-content">{{rec.Weight||' --- '}}</div>
              <div>kg</div>
            </div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'BT'" [title]="'體溫'" [width]="75" class="text-right">
          <ng-template let-rec>
            <div style="display: flex;">
              <div  class="vt-rec-cell ellipsis-content">{{rec.BT||' --- '}}</div>
              <div>°C</div>
            </div>
          </ng-template>
        </app-vis-grid-column>
        <app-vis-grid-column [field]="'SPB'" [title]="'血壓(收縮壓/舒張壓/脈搏)'" class="text-center custom-m-autu">
          <ng-template let-rec>
            <div style="display: flex;">
              <div  class="vt-rec-cell ellipsis-content">{{rec.SBP||' --- '}}</div> /
              <div  class="vt-rec-cell ellipsis-content">{{rec.DBP||' --- '}}</div> /
              <div  class="vt-rec-cell ellipsis-content">{{rec.Pulse||' --- '}}</div>
            </div>
          </ng-template>
        </app-vis-grid-column>
      </app-vis-grid>
    </div>
  </ng-template>
