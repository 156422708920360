import { Component, OnInit,Inject,EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalFileInfo } from 'src/app/services/hcr-service.service';

@Component({
  selector: 'app-local-file-selector',
  templateUrl: './local-file-selector.component.html',
  styleUrls: ['./local-file-selector.component.css']
})
export class LocalFileSelectorComponent implements OnInit {
  onSelected:EventEmitter<LocalFileInfo> = new EventEmitter();
  onClose:EventEmitter<void> = new EventEmitter();

  folderSelect:boolean;

  parentNode:LocalFileInfo;
  nodePath:LocalFileInfo[];
  currentNode:LocalFileInfo;
  currentFiles:LocalFileInfo[];
  constructor(@Inject(MAT_DIALOG_DATA) public files:LocalFileInfo) {
    // root
    this.setCurrentNode(files);
    
  }
  setCurrentNode(node: LocalFileInfo){
    
    this.currentNode = node;
    this.currentFiles = node.Children;
    this.nodePath = [];
    this.nodePath.push(this.currentNode);
    this.parentNode = this.findParentNode(this.currentNode,this.files);
    
    this.nodePath = this.nodePath.reverse();
    if(this.nodePath.length==0){
      
    }
  }
  trySetPath(path:string){
    if(!path) return
    if(path.startsWith(this.files.FullName)){
      var dirs = path.substring(this.files.FullName.length).split('\\');
      var node = this.files;
      // 爬資料夾 斷掉就當作找不到
      for(var i = 0;i <dirs.length;i++){
        var nodeName = dirs[i]
        node = node.Children?.find(x=>x.Name==nodeName);
        if(node==null){
          return;
        }
      }
      this.setCurrentNode(node);
    }
    
  }
  findParentNode(node:LocalFileInfo,check:LocalFileInfo){
    if(check.Children.some(x=>x.FullName==node.FullName)){
      this.nodePath.push(check);
      return check;
    }else{
      var parent = check.Children.find(x=>x.Children && this.findParentNode(node,x)!=null);
      if(parent){
        this.nodePath.push(check);
      }
      return parent;
    }
  }
  ngOnInit(): void {
  }

  close(){
    this.onClose.emit();
  }
  select(item:LocalFileInfo){
    this.onSelected.emit(item);
  }
}
