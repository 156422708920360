import { NgModule } from '@angular/core';
import { IconTimesComponent } from '../../components/svg/icon-times.component';
import { IconPlusComponent } from '../../components/svg/icon-plus.component';
import { IconEtcComponent } from '../../components/svg/icon-etc.component';
import { IconSearchComponent } from '../../components/svg/icon-search.component';
import { IconVisLogoComponent } from '../../components/svg/icon-vis-logo.component';
import { IconCrossComponent } from '../../components/svg/icon-cross.component';
import { IconFontComponent } from '../../components/svg/icon-font.component';
import { IconSimComponent } from '../../components/svg/icon-sim.component';
import { IconManComponent } from '../../components/svg/icon-man.component';

const COMPONENTS = [
  IconTimesComponent,
  IconPlusComponent,
  IconEtcComponent,
  IconSearchComponent,
  IconVisLogoComponent,
  IconCrossComponent,
  IconFontComponent,
  IconSimComponent,
  IconManComponent
]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [],
  exports: [...COMPONENTS],
})
export class SvgShareModule { }
