<style>
    @media print {
      body {
        padding-top: 3cm;
        padding-bottom: 3cm;
        margin: 0;
        color: #000;
        background-color: #fff;
        font-size: 10pt;
      }

      .pres-report2 table td,
      table th {
        border-bottom: 5px double black;
        padding-left: 0px;
        padding-right: 0px;
      }
      .container {
        height: 100%;
        width: 100%;
      }
      .page {
        display: block;
        margin: 40px auto;
        box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
        box-sizing: border-box;
      }
      .page .content {
        overflow: auto;
        outline: 0;
      }
      .page .content .block {
        border: 1px solid rgba(0, 0, 0, 0);
        padding: 0.25rem;
        cursor: default;
      }
      .page .content .block:hover {
        border: 1px solid #dee2e6;
        border-radius: 0.25rem;
      }
      .page .content .block .title {
        font-weight: bold;
      }
      .page .content .block .title {
        cursor: text;
      }
    }
  </style>
  <div class="a4-print-style-h" #reportContainer style="height: 600px; margin: 0px auto; overflow-y: auto;">
    <div class="pres-report2" style="background-color: white; height: 100%; width: 100%;">
      <div class="container" id="container">
      </div>
    </div>
  </div>
