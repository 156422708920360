import { Injectable } from "@angular/core";
import { PageData } from "src/app/shared/models/page-data";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { SystemCodeShare } from "./system-code-share";
import { SystemCodeType } from "src/app/services/api-service/system-code/system-code-type";
import { ValueTextPair } from "../../../shared/models/value-text-pair";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

export interface SystemCodeShareGetAllQuery{
    typecode: string;
    pageSize: number;
    pageSkip: number;
  }

  @Injectable({
    providedIn: 'root'
  })
  
  export class SystemcodeshareApi {
    private api: WebApiService;
    constructor(
      private apiClient: WebApiClient) {
      this.api = apiClient.createHisService('system/SystemCodeShare');
    }
  
    async GetAll(query:SystemCodeShareGetAllQuery): Promise<PageData>{
      return this.api.get('GetAll', query).toPromise<PageData>();
    }
  }