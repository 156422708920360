import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';

declare type FormatType = 'value' | 'text' | 'valuetext';

@Component({
  selector: 'app-vis-combo-box-multipie',
  templateUrl: './vis-combo-box-multipie.component.html',
  styleUrls: ['./vis-combo-box-multipie.component.css']
})
export class VisComboBoxMultipieComponent implements OnInit {

  @ViewChild('inputElement') inputElement: ElementRef;
  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger

  @Input()
  set options(value: ValueTextPair[]) {
    if (value) {
      this._options = value.map(x => Object.assign({}, x));
    } else {
      this._options = [];
    }

    this.bindFilteredOptions();
  }
  get options(): ValueTextPair[] {
    return this._options;
  }


  @Input() title: string = "選擇項目";
  @Input() placeHolder: string = "搜尋項目...";
  @Input() showSelectAllBtn: boolean = false;
  @Input() showClearAllBtn: boolean = false;

  @Input() selectedShowMode: FormatType = "value";

  @Output() valueChange: EventEmitter<ValueTextPair[]> = new EventEmitter(false);

  control = new FormControl();
  private _options: ValueTextPair[];

  @Input()
  selectedOptions: ValueTextPair[] = [];

  private selectedOptionValues = [];

  filteredOptions: Observable<any[]> = null;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  displayFn(option: any): string {
    return option && option.viewValue ? option.viewValue : '';
  }

  private _filter(name: string): any[] {
    return this.options.filter(option => option.text.toLowerCase().includes(name));
  }

  select(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (!this.selectedOptions || this.selectedOptions.length == 0) {
      this.selectedOptions = [];
      this.pushSelectedOption(value);
    }
    else {
      if (!this.selectedOptions.find(opt => opt.value === value)) {
        this.pushSelectedOption(value);
      }
    }

    this.handleValueChangeEmit();

    this.control.setValue(''); // 保持控件值為空，以避免顯示問題
    this.updateAutocompletePosition();

    Promise.resolve().then(() => this.trigger.openPanel());
  }

  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
      this.selectedOptionValues.splice(index, 1);
      this.updateAutocompletePosition();
    }

    this.handleValueChangeEmit();

  }



  pushSelectedOption(value) {
    let option = this.options.find(opt => opt.value === value);
    this.selectedOptionValues.push(option.value);
    this.selectedOptions.push(option);
  }


  isSelected(option: any): boolean {
    if (this.selectedOptionValues && this.selectedOptionValues.length > 0)
      return this.selectedOptionValues.some(o => o === option.value);
    else
      return false;
  }

  updateAutocompletePosition(): void {
    this.cdr.detectChanges();
    setTimeout(() => {
      this.trigger.updatePosition();
    });
  }

  selectAll() {
    if (!this.selectedOptions)
      this.selectedOptions = [];

    this.options.filter(opt => opt.text != "").forEach(x => {
      if (!this.selectedOptions.find(opt => opt.value == x.value)) {
        this.pushSelectedOption(x.value);
      }
    })

    this.handleValueChangeEmit();
  }

  clearAll() {
    this.selectedOptions = [];
    this.selectedOptionValues = [];
    this.handleValueChangeEmit();
  }

  bindFilteredOptions() {
    this.filteredOptions = this.control.valueChanges
      .pipe(
        startWith(''),
        map((value) => typeof value === 'string' ? value : value.viewValue),
        map(name => name ? this._filter(name) : this.options.slice())
      );
  }

  handleValueChangeEmit() {
    let options = this.options.filter(opt => this.selectedOptionValues.includes(opt.value));
    this.valueChange.emit(options);
  }

}
