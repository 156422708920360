import { DateHelper } from './date-helper';
import { Injectable } from "@angular/core";
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { HcrService } from "src/app/services/hcr-service.service";
import { GetSeqNumber256 } from 'src/app/shared/hcr-lib/GetSeqNumber256';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { SeqNumber256N1 } from '../hcr-lib/SeqNumber256N1';
import { TreatNumNoICCard } from '../hcr-lib/TreatNumNoICCard';
import { SexEnum } from 'src/app/enums/SexEnum';
import { CSHISResultWithData } from '../hcr-lib/CSHISResultWithData';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { IPatient, PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { RegisterBasic } from '../hcr-lib/RegisterBasic';
import { IICHelper } from './ic-helper';
import { UserCache } from 'src/app/services/user-cache';
import { CriticalIllnessData } from '../hcr-lib/CriticalIllnessData';
import { EmergentTel } from '../hcr-lib/EmergentTel';
import { OrganDonate } from '../hcr-lib/OrganDonate';

declare type keyValue = { text: string, value: string };

@Injectable({
    providedIn: 'root'
})

export class DemoIcHelper implements IICHelper {

    constructor(
        private notification: EasyNotificationService,
        private hcrService: HcrService,
        private patientapi: PatientApi,
        private mainLayoutService: MainLayoutService,) {
    }
    getICConn(): Promise<boolean> {
        return Promise.resolve(true);
    }
    getICConnEx(): boolean {
        return true;
    }
    getICConnOnlyCheck(): Promise<boolean> {
        return Promise.resolve(true);
    }
    async GetRegisterBasicRawData(): Promise<RegisterBasic> {
        var r = await this.hcrService.VisionApi.GetRegisterBasic();
        return r.Data;
    }
    getBabyTreat(birthNum: number, sex: SexEnum): string {
        return '';
    }
    async unGetSeqNumber(rDate: string): Promise<number> {
        var r = await this.hcrService.VisionApi.UnGetSeqNumber(rDate);
        return r.StatusCode;
    }
    async getEmergentTel(): Promise<EmergentTel> {
        var r = await this.hcrService.VisionApi.GetEmergentTel();
        return r.Data;
    }
    async getOrganDonate(): Promise<OrganDonate> {
        var r = await this.hcrService.VisionApi.GetOrganDonate();
        return r.Data;
    }
    async getCriticalIllness(): Promise<CriticalIllnessData> {
        var r = await this.hcrService.VisionApi.GetCriticalIllness();
        return r.Data;
    }
    async getNewTreatIdNoIC(pId: string): Promise<TreatNumNoICCard> {
        var r = await this.hcrService.VisionApi.GetTreatNumNoICCard(pId,UserCache.getLoginUser().Clinic.NHICode);
        return r.Data;
    }

    async updateAvailable() {
        this.notification.showInfo("更新可用次數...");
        let processResult = await this.hcrService.VisionApi.UpdateHCContents();
    }
    patientId: number
    public setPatient(patientId: number){
        this.patientId = patientId;
    }
    /** 讀取最近一次就醫序號做加1，顯示欄位上 (展示模式)
      *
      */
    async getLastSeqNum() {
        this.notification.showInfo("查詢最近一次就醫序號...");
        var resp = await this.patientapi.GetLastSeqNo(this.patientId);
        let lastSeqNumber: number = !isNaN(Number(resp?.Isqno)) ? Number(resp?.Isqno) : 0;
        let latestSeqNumber: number = !isNaN(Number(resp?.Isqno)) ? Number(resp?.Isqno) + 1 : 1;
        let lastSeqNum = (Array(4).join('0') + lastSeqNumber.toString()).slice(-4);
        let latestSeqNum = (Array(4).join('0') + latestSeqNumber.toString()).slice(-4);

        return {
            lastSeqNum: lastSeqNum,
            latestSeqNum: latestSeqNum,
        }
    }

    /** 從掛號或報到時讀取基本資料，取得「就醫可用次數」、...
     *
     */
    async getRegisterBasic() {
        // if (!this.getICConn())
        //     return null
        let patientsDto = await this.patientapi.Get(this.patientId);
        // console.log('atientsDto.CId', patientsDto.CId);
        this.notification.showInfo("取得卡片基本資料...");
        return {
            availablecnt: Math.ceil(Math.random() * 6),
            insurerflag: '3',
            IcNo: ('90' + (patientsDto.CId.slice(-4) || '').padStart(10, '0')),
            Icdt: DateHelper.today,
            CId: patientsDto.CId,
            Name: patientsDto.CName,
            Birth: patientsDto.Birthday,
            Sex: patientsDto.Sex == 0 ? 'F' : (patientsDto.Sex == 1 ? 'M' : '')
        }

    }
    async getRegisterBasicByDto(patientsDto: IPatient) {
        this.notification.showInfo("取得卡片基本資料...");
        return {
            availablecnt: Math.ceil(Math.random() * 6),
            insurerflag: '3',
            IcNo: ('90' + (patientsDto.CId.slice(-4) || '').padStart(10, '0')),
            Icdt: DateHelper.today,
            CId: patientsDto.CId,
            Birth: patientsDto.Birthday,
        }

    }
    /**
   * 取得就醫序號(展示取號) -- 展示模式
   */
    async getSeqNumber(itp: string, babyTreat: string): Promise<SeqNumber256N1> {

        this.notification.showInfo("取得就醫序號中...");
        let args: GetSeqNumber256 = new GetSeqNumber256();
        args.TreatItem = itp;
        args.BabyTreat = " ";
        args.TreatAfterCheck = "1";
        var ret = new CSHISResultWithData<SeqNumber256N1>();
        ret.StatusCode = 0;
        ret.Data = new SeqNumber256N1();
        ret.Data.ClinicCode = '7777777777';
        ret.Data.ClinicDateTime = new Date();
        ret.Data.SamId = '123456789ABC';
        ret.Data.Number = (await this.getLastSeqNum()).latestSeqNum;
        
        ret.Data.SameDay = 'N';
        ret.Data.TreatId = this.genTreatId();
        ret.Data.SecuritySeal = 'DemoSign';
        ret.ErrorMsg = '';
        return ret.Data;
    }
    /**異常掛號取得就醫識別碼 */
    async getTreatIdNoIC(cID: string): Promise<TreatNumNoICCard> {

        this.notification.showInfo("取得離線就醫識別碼中...");
        var ret = new TreatNumNoICCard();
        ret.DateTime = new Date();
        ret.HospCode = '7777777777';
        ret.TreatNum = this.genTreatId();
        return ret;
    }
    /**單獨取得就醫識別碼(欠卡) 回傳就醫識別碼*/
    async getTreatId(cid:string, date: string): Promise<TreatNumNoICCard> {
        this.notification.showInfo("取得離線就醫識別碼中...");
        var treatNum = this.genTreatId();
        return {
            DateTime:new Date(date),
            TreatNum:treatNum,
            HospCode:UserCache.getLoginUser().Clinic.NHICode
        }
    }
    private getITreatString(n: number) {
        var char = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ';
        var calcTemp = Math.floor(n);
        var treatIdBuild = ''
        do {
            var a = calcTemp % char.length;
            treatIdBuild = char[a] + treatIdBuild;
            calcTemp = Math.floor(calcTemp / char.length)
        }
        while (calcTemp > 0)
        return treatIdBuild;
    }
    private genTreatId() {
        var c1 = '4S8TMPY126T5Q';
        var now = new Date();
        var secDiff = (now.getTime() - new Date(2023, 0, 1, 0, 0, 0).getTime()) / 1000
        var c2 = this.getITreatString(secDiff);
        //'4S8TMPY126T5Q'
        var tp = 'J0';
        var getlen = 20 - c1.length - c2.length; //預估要抓tp長度
        var gen = c1 + tp.substring(0, getlen) + c2; //依長度組起來

        //防呆，最多20碼
        if (gen.length > 20) {
            gen = gen.substring(0, 20);
        }

        return gen;
    }

    
}
