import { OrderTypeEnum } from "src/app/enums/OrderTypeEnum";
import NP from 'number-precision';
import { ICalcRule, IHRx, IHrxCalc, QTYRule } from "./hrx-calcr.models";

/** 次量法 */
export class CalcRuleDose implements ICalcRule {
    constructor(private model: IHRx, private hrxCalc: IHrxCalc) {
    }
    IsTargetEmpty():boolean{
        return !this.model.TotalDose;
    }
    QtyToDose() {
        if (this.model.QTY) {
            // 口服計算
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc.rx?.IsByOral){
                // 次量法 日 = 輸 * 頻
                this.model.DailyDose = NP.times(this.model.QTY, this.model.FreqQTY);
                // 次量法 總 = 輸 * 頻 * 天
                this.model.TotalDose = NP.round(NP.times(this.model.QTY, this.model.FreqQTY, this.model.InfoDays), 1);
                // 次量法 次 = 輸
                this.model.Dose = this.model.QTY;
                this.fixTotal();
            }else{
                //非口服設定對應欄位
                this.model.Dose = this.model.QTY;
            }
            
        }
    }
    DoseToQty(){
        // 次量法計算規則下 輸入量未填才使用反算
        if(this.model.TotalDose && (this.hrxCalc.rx&&this.hrxCalc.rx.SyrupNote!='a1') && !this.model.QTY){
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc.rx?.IsByOral){
                // 次量法之總量回推 日 = 總 / 天
                this.model.DailyDose = NP.round(NP.divide(this.model.TotalDose, this.model.InfoDays), 1);
                // 次量法之總量回推 總 = 總
                this.model.TotalDose = this.model.TotalDose;
                // 次量法之總量回推 次 = 總 / 天 / 頻
                this.model.Dose = NP.round(NP.divide(this.model.TotalDose, this.model.InfoDays, this.model.FreqQTY), 1);
                this.model.QTY = this.model.Dose;
                this.fixTotal();
            }
        }
    }
    /** 總量換瓶/進位 */
    public fixTotal() {
        var hrxCalc = this.hrxCalc;
        if (!hrxCalc.model.TotalDose) {
            return;
        }
        if (hrxCalc.rx && hrxCalc.rx.SyrupNote == 'a1' && hrxCalc.rx.SpecAmount) {
            // 換瓶
            hrxCalc.model.TotalDose = Math.ceil(NP.divide(hrxCalc.model.TotalDose, hrxCalc.rx.SpecAmount));
            hrxCalc.model.BoxUnit = hrxCalc.rx.UseUnit;
            hrxCalc.model.Unit = hrxCalc.rx.SpecUnit;
        } else {
            // 處理進位規則
            hrxCalc.model.TotalDose = this.fixDecimal(hrxCalc.model.TotalDose);
        }
    }
    /** 進位法處理 */
    private fixDecimal(value: number) {
        var hrxCalc = this.hrxCalc;
        var s = value >= 0 ? 1 : -1;
        var abs = Math.abs(value);
        var point = NP.minus(abs, Math.floor(abs));
        if (hrxCalc.decimalRule == '1') {
            if (point <= 0.1) {
                return NP.times(Math.floor(abs), s);
            } else if (point >= 0.9) {
                return NP.times(Math.ceil(abs), s);
            } else {
                return value;
            }
        } else if (hrxCalc.decimalRule == '2') {
            return NP.times(Math.ceil(abs), s);
        } else {
            return value;
        }
    }
}
/** 日量法 */
export class CalcRuleDaily implements ICalcRule {
    constructor(private model: IHRx, private hrxCalc: IHrxCalc) {
    }
    IsTargetEmpty():boolean{
        return !this.model.TotalDose;
    }
    QtyToDose() {
        if (this.model.QTY) {
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc?.rx.IsByOral){
                // 日量法 日 = 輸
                this.model.DailyDose = this.model.QTY;
                // 日量法 總 = 輸 / 天
                this.model.TotalDose = NP.round(NP.times(this.model.DailyDose, this.model.Days), 1);
                // 日量法 次 = 輸 / 頻
                this.model.Dose = NP.round(NP.divide(this.model.DailyDose, this.model.FreqQTY), 2);
            
                this.fixTotal();
            }else{
                this.model.DailyDose = this.model.QTY;
            }
        }
    }
    DoseToQty() {
        if (this.model.TotalDose && !this.model.QTY) {
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc.rx?.IsByOral){
                // 日量法之總量回推 日 = 總 / 天
                this.model.DailyDose = NP.round(NP.divide(this.model.TotalDose, this.model.InfoDays), 1),
                // 日量法之總量回推 總 = 總
                this.model.TotalDose = this.model.TotalDose,
                // 日量法之總量回推 次 = 總 / 天 / 頻
                this.model.Dose = NP.round(NP.divide(this.model.TotalDose, this.model.InfoDays, this.model.FreqQTY), 1)
                this.model.QTY = this.model.DailyDose;
            
                this.fixTotal();
            }
        }
    }
    
    /** 總量換瓶/進位 */
    public fixTotal() {
        var hrxCalc = this.hrxCalc;
        if (!hrxCalc.model.TotalDose) {
            return;
        }
        if (hrxCalc.rx && hrxCalc.rx.SyrupNote == 'a1' && hrxCalc.rx.SpecAmount) {
            // 換瓶
            hrxCalc.model.TotalDose = Math.ceil(NP.divide(hrxCalc.model.TotalDose, hrxCalc.rx.SpecAmount));
            hrxCalc.model.BoxUnit = hrxCalc.rx.UseUnit;
            hrxCalc.model.Unit = hrxCalc.rx.SpecUnit;
        } else {
            // 處理進位規則
            hrxCalc.model.TotalDose = this.fixDecimal(hrxCalc.model.TotalDose);
        }
    }

    /** 進位法處理 */
    private fixDecimal(value: number) {
        var hrxCalc = this.hrxCalc;
        var s = value >= 0 ? 1 : -1;
        var abs = Math.abs(value);
        var point = NP.minus(abs, Math.floor(abs));
        if (hrxCalc.decimalRule == '1') {
            if (point <= 0.1) {
                return NP.times(Math.floor(abs), s);
            } else if (point >= 0.9) {
                return NP.times(Math.ceil(abs), s);
            } else {
                return value;
            }
        } else if (hrxCalc.decimalRule == '2') {
            return NP.times(Math.ceil(abs), s);
        } else {
            return value;
        }
    }
}
/** 總量法 */
export class CalcRuleTotal implements ICalcRule {
    constructor(private model: IHRx, private hrxCalc: IHrxCalc) {
    }
    IsTargetEmpty():boolean{
        return !this.model.Dose;
    }
    QtyToDose() {
        if (this.model.QTY) {
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc.rx?.IsByOral){
                // 總量法 日 = 輸 / 天
                this.model.DailyDose = NP.round(NP.divide(this.model.QTY, this.model.Days), 1);
                // 總量法 總 = 輸
                this.model.TotalDose = this.model.QTY;
                // 總量法 次 = 輸 / 天 / 頻
                this.model.Dose = NP.round(NP.divide(this.model.QTY, this.model.InfoDays, this.model.FreqQTY), 2)
                this.fixUnit()
            }else{
                this.model.TotalDose = this.model.QTY;
            }
        }
        //  else if (this.model.Dose) {
        //     // 總量法之次量回推 日 = 次 * 天
        //     this.model.DailyDose = NP.times(this.model.Dose, this.model.InfoDays),
        //     // 總量法之次量回推 總 = 次 * 天 * 頻
        //     this.model.TotalDose = NP.times(this.model.Dose, this.model.InfoDays, this.model.FreqQTY),
        //     // 總量法之次量回推 次 = 次
        //     this.model.Dose = this.model.Dose
        // }
    }
    DoseToQty() {
        if (this.model.Dose && !this.model.QTY)  {
            if(this.model.RxType==OrderTypeEnum.T2_OralDrugFee || this.hrxCalc.rx?.IsByOral){
                // 總量法之次量回推 日 = 次 * 天
                this.model.DailyDose = NP.times(this.model.Dose, this.model.InfoDays),
                // 總量法之次量回推 總 = 次 * 天 * 頻
                this.model.TotalDose = NP.times(this.model.Dose, this.model.InfoDays, this.model.FreqQTY),
                // 總量法之次量回推 次 = 次
                this.model.Dose = this.model.Dose
                this.model.QTY = this.model.TotalDose;
                this.fixUnit()
            }
        }
    }
    fixUnit(){
        var hrxCalc = this.hrxCalc;
        if (hrxCalc.rx && hrxCalc.rx.SyrupNote == 'a1' && hrxCalc.rx.SpecAmount) {
            // 換瓶
            hrxCalc.model.BoxUnit = hrxCalc.rx.UseUnit;
            hrxCalc.model.Unit = hrxCalc.rx.SpecUnit;
        }
    }
}
/** 清除量法 */
export class CalcRuleClear implements ICalcRule {
    constructor(private model: IHRx) {
    }
    IsTargetEmpty():boolean{
        return true;
    }
    QtyToDose() {
        // 清除計算值
        this.model.InfoDays = null;
        this.model.FreqQTY = null;
        this.model.DailyDose = null;
        this.model.TotalDose = null;
        this.model.Dose = null;
    }
    DoseToQty() {
        // 清除計算值
        this.model.InfoDays = null;
        this.model.FreqQTY = null;
        this.model.DailyDose = null;
        this.model.TotalDose = null;
        this.model.Dose = null;
    }
}
/** 不計算 指更新QTY對應欄位*/
export class CalcRuleNoAct implements ICalcRule {
    constructor(private model: IHRx) {
    }
    IsTargetEmpty():boolean{
        return false;
    }
    QtyToDose() {
        if(this.model.QtyRule==  QTYRule.Dose){
            this.model.Dose = this.model.QTY;
        }else if(this.model.QtyRule==  QTYRule.DailyDose){
            this.model.DailyDose = this.model.QTY;
        }else if(this.model.QtyRule==  QTYRule.TotalDose){
            this.model.TotalDose = this.model.QTY;
        }
        
    }
    DoseToQty() {
        if(this.model.QtyRule==  QTYRule.Dose){
            this.model.QTY = this.model.Dose;
        }else if(this.model.QtyRule==  QTYRule.DailyDose){
            this.model.QTY = this.model.DailyDose;
        }else if(this.model.QtyRule==  QTYRule.TotalDose){
            this.model.QTY = this.model.TotalDose;
        }
    }
}

/** 次總同步 */
export class CalcRuleDoseAsTotal implements ICalcRule {
    constructor(private model: IHRx) {
    }
    IsTargetEmpty():boolean{
        return !this.model.TotalDose && !this.model.Dose;
    }
    QtyToDose() {
        this.model.TotalDose = this.model.QTY;
        this.model.DailyDose = this.model.QTY;
        this.model.Dose = this.model.QTY;
    }
    DoseToQty(){
        if(this.model.QtyRule == QTYRule.Dose){
            this.model.Dose = this.model.TotalDose;
        }else if(this.model.QtyRule == QTYRule.TotalDose){
            this.model.TotalDose = this.model.Dose;
        }else if(this.model.QtyRule == QTYRule.DailyDose){
            this.model.Dose = this.model.TotalDose;
        }
        this.model.DailyDose = this.model.Dose;
        this.model.TotalDose = this.model.Dose;
        this.model.QTY = this.model.Dose;
    }
}