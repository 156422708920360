import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BaseConfig } from '../../../services/base-config.service';
import { AuthenticationService } from 'src/app/security/services/authentication.service';
import { UserCache } from 'src/app/services/user-cache';
import { UrlHelper } from '../../helpers/url-helper';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private router: Router,private activatedRoute:ActivatedRoute) {
    console.log(this.router.url);
   }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMessage: any = {};
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`;
          } else {
            // server-side error
            let apiError: any;
            if (error.error) {
              apiError = error.error;
            }
            
            // 固定的錯誤格式 (errErrorId api處理過的錯誤特有欄位)
            if (apiError && typeof (apiError) === 'object' && apiError.errErrorId!=undefined) {
              errorMessage = { 
                status: error.status, 
                code: apiError.Code, 
                message: apiError.MessageShow || 'Error code: ' + apiError.Code
              };
            } else if (error.status === 200) {
              return of(new HttpResponse());
            } else if (error.status === 400) {
              errorMessage = { status: error.status, code: 'Http error ' + error.status, message: apiError};
            } else if (error.status === 401 || error.status === 302) {
              errorMessage = { status: error.status, code: 'AUT004', message: error.error.Message };
              //this.logout(error);
            } else if (error.status === 500) {
              errorMessage = { status: error.status, code: 'Http error ' + error.status, message: 'Internal server error' };
            } else {
              if (error.status) {
                errorMessage = { status: error.status, code: 'Http error ' + error.status, message: 'Other error: ' + error.status };
              } else {
                console.log(error)
                // 被Chrome政策擋掉彈出來的錯誤之類的
                errorMessage = { status: -1, code: '-1', message: 'Exception error, maybe Cef error'};
              }
            }
          }
          if (errorMessage && errorMessage.status !== -1) {
            this.logError(error);
          }
          return throwError(errorMessage);
        })
      );
  }
  private logout(error: HttpErrorResponse) {
    let companyCode = UserCache.getLoginUser().CompanyCode;// error.headers.get('CompanyCode');
    // 先使用Url的CompanyCode
    if (!companyCode) {
      console.log('CompanyCode of Login User Not Found.', companyCode);
      companyCode = UrlHelper.getCompanyCodeFromRoute(this.router);
    }
    if (!companyCode) {
      console.log('CompanyCode of Url Not Found.', companyCode,this.router.url)
      console.log(this.activatedRoute);
      console.log(window.location.href);
      // 使用任何一個不存在的company即可
      companyCode = 'anyNotExistCompanyCode';
    }
    this.router.navigate([ companyCode + '/logout']);
  }
  private logError(info: any) {
    if (info && BaseConfig.isDebug()) {
      console.error(info);
    }
  }
}
