<div style="display: flex;background-color: inherit;">
  <div class="app-v-dateinput v-control"  [class.v-disabled]="_disabled" [ngClass]="customClass"
      style=" display: flex;;padding: 1px 2px 1px 2px;width: 100%;line-height: 21px;margin-top: 0px;font-size: 14px;" >
      <input matInput [matDatepicker]="picker" (dateChange)="onDateChanged($event)"  [min]="min" [max]="max"
      style="position: absolute; visibility: hidden;padding: 0px;margin: 0px;">
      <span [ngClass]="[prefix!=='民前'?'w-4':'w-8']">{{prefix}}</span>
      <input type="tel" autocomplete="off" [disabled]="_disabled" class="year" style="border: none;text-align: center; max-width: 37px; " maxlength="4"
            (keyup)="yearUp($event)" (keydown)="yearDown($event)"
            (blur)="onblur($event)" (focus)="onfocus($event)"
            #year/>
      <span style="color: #aaaaaa;">年</span>
      <input type="tel" autocomplete="off" [disabled]="_disabled" style="border: none;max-width: 20px; text-align: center;" maxlength="2"
            (keyup)="monthUp($event)"  (keydown)="monthDown($event)"
            (blur)="onblur($event)" (focus)="onfocus($event)"
            #month/>
      <span style="color: #aaaaaa;">月</span>
      <input [hidden]="true" type="tel" autocomplete="off" [disabled]="_disabled" style="border: none;max-width: 20px;" maxlength="2"
            (keyup)="dayUp($event)"  (keydown)="dayDown($event)"
            (blur)="onblur($event)" (focus)="onfocus($event)"
            #day/>
  </div>
  <button type="button" (click)="openPicker()" tabindex="-1" [disabled]="_disabled" style=" height: 25px;width: 25px; font-size: 10px;line-height: 26px;padding: 0px;">
    <mat-icon style="font-size: 16px;margin-left: -1px;margin-top: 2px;">date_range</mat-icon>
  </button>
  <button type="button" *ngIf="showToday" style="margin-left: 0px;font-size: 16px;min-width: 25px;padding: 0px;text-align: center;" (click)="onBtnTodayClick()" title="Today">T</button>
  <!-- <mat-datepicker-toggle style="height: 20px;width: 20px;" matSuffix [for]="picker"></mat-datepicker-toggle> -->
  <mat-datepicker [touchUi]="false" #picker  startView="year" (monthSelected)="setMonthAndYear($event, picker)" panelClass="example-month-picker"
  [calendarHeaderComponent]="exp">

  </mat-datepicker>
</div>
