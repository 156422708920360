<div class="row narrow-padding">
  <div class="col-sm-12">
    <div class="card" [attr.disabled]="allDisabled" >
      <div class="card-header">
        <form [formGroup]="queryFormGroup" class="c-form-inline">
          <div>
              <label class="c-form-field">
                <span i18n="@@PatientNo">Patient No</span>
                <input class="vis-input" formControlName="patientNo" />
              </label>
              <label class="c-form-field">
                <span i18n="@@PatientCid">Patient CID</span>
                <input class="vis-input" formControlName="patientCid" />
              </label>  
              <label class="c-form-field">
                <span i18n="@@EndMonth">End Month</span>
                <app-vis-datepicker style="width: 150px;" [format]="'MMMM yyyy'" formControlName="endMonth" ></app-vis-datepicker>
              </label>
            <div class="float-right">
              <!-- <button type="button" (click)="onBtnAddClick()" i18n="@@Add" [disabled]="!selectedContractType">Add</button> -->
              <button type="button" (click)="onBtnQueryClick()" i18n="@@Query">Query</button>
            </div>
          </div> 
        </form>       
      </div><!--./card-header-->
      <div class="card-body no-padding">
        <app-vis-grid [data]="gridView" [attr.disabled]="allDisabled"
          [pageSize]="pageSize"
          [skip]="pageSkipNo"
          [pageable]="true"
          (pageChange)="pageChange($event)"
          [selectable]="{mode: 'single'}"
          (cellClick)="gridCellClickHandler($event)"
          class="no-border">
          <app-vis-grid-column field="ContractType" title="Contract Type" width="130" i18n-title="@@ContractType">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientNo" title="Patient No" width="100" i18n-title="@@PatientNo">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientName" title="Patient Name" min-width="160" i18n-title="@@PatientName">
          </app-vis-grid-column>
          <app-vis-grid-column field="Sex" title="Sex" width="60" i18n-title="@@Sex">
          </app-vis-grid-column>
          <app-vis-grid-column field="Phone" title="Phone" min-width="140" i18n-title="@@Phone">
          </app-vis-grid-column>
          <app-vis-grid-column field="ContractStatus" title="Status" width="130"  i18n-title="@@ContractStatus">
          </app-vis-grid-column>
          <app-vis-grid-column field="StartDate" title="Start" width="130" i18n-title="@@StartDate">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.StartDate | formatDate:'' }}
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="EndDate" title="End" width="130" i18n-title="@@EndDate">
            <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.EndDate | formatDate:'' }}
            </ng-template>
          </app-vis-grid-column>
          <!-- <app-vis-grid-column title="Function" width="80">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="onBtnRenewClick(dataItem)" i18n="@@Renew">Renew</button>
              <button type="button" (click)="onBtnDeleteClick(dataItem)" i18n>Delete</button>
            </ng-template>
          </app-vis-grid-column> -->
        </app-vis-grid>
      </div><!--./card-body-->
    </div><!--./card-->
  </div>
</div>

