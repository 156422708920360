import { AfterContentInit, AfterViewInit, ChangeDetectorRef, Component, ContentChildren, ElementRef, HostListener, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { SearchBarFieldDirective } from './search-bar-field-directive';
import { FontGreatService } from 'src/app/services/font-great.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css']
})
export class SearchBarComponent implements OnInit , AfterContentInit,AfterViewInit,OnDestroy {
  /** 使用Flex Layout */
  @Input()
  Flex:boolean = false;
  /** 每行的間格蘭 */
  @Input()
  RowGap:string = '';
  /** 每行的蘭位數, btn用同一格 */
  @Input()
  Cols:number = null;
  /** 不受到resize影響 */
  @Input()
  static:boolean = false;
  /** 按鈕靠左對齊 */
  @Input()
  buttonLeft:boolean = false;

  @Input() fieldWidthMax?:boolean = false;
  @Input() flexArray?:[]=[];

  @ContentChildren(SearchBarFieldDirective)
  children:QueryList<SearchBarFieldDirective>;

  childTable: SearchBarFieldDirective[][] = [];

  @ViewChildren('container')
  containers:QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('title')
  title:QueryList<ElementRef<HTMLDivElement>>;
  sub:Subscription;
  constructor(private el: ElementRef,private fg: FontGreatService,
    private cdr:ChangeDetectorRef
  ) {
    this.sub = fg.onFontGreatChanged.subscribe(b=>{
      var styleMap =(this.title?.first.nativeElement as any ).computedStyleMap();
      var styleValue = styleMap.get('font-size') as {unit:string,value:number}
      this.titleFontSize = styleValue?.value??(this.fg.getFontGreat()? 18:14);
    })
   }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  get fieldWidthStyle(){
    if(this.Flex){
      return { flexGrow:1};
    }
    return {
      maxWidth: 100/this.Cols + '%'
    };
  }
  titleSize = 2;
  titleFontSize = 16;
  get titleWidthStyle(){
    return {
      
      width:this.titleSize * this.titleFontSize + 'px'
    };
  }

  @HostListener('window:resize',['$event'])
  onResize(){
    if(this.static){
      return;
    }
    this.computeCols();
    this.computeRowElements();
    setTimeout(() => {
      this.mapChildElement()
    }, 0);
  }
  ngOnInit(): void {
  }

  computeCols(){
    var w = this.el.nativeElement.offsetWidth;
    if(!this.Cols){
      this.Cols =Math.min(5, Math.floor(w/175));
    }
    if(this.Flex){
      this.Cols = 100;// this.children.length;
    }
  }
  computeRowElements(){
    var j = 0;
    this.childTable = [[]];
    for(var i=0;i<this.children.length;i++){
      var el = this.children.get(i);
      if(el.displayTitle && el.fieldTitle.length>this.titleSize){
        this.titleSize = el.fieldTitle.length;
      }
      this.childTable[j].push(el);
      if((i+1)%this.Cols==0){
        j++;
        this.childTable.push([]);
      }
    }
    if(this.Cols>0 && this.Flex==false){
      // 最後一行滿了就多一行給按鈕列用
      if(this.childTable[j].length==this.Cols){
        this.childTable.push(Array(this.Cols-1).fill(null))
      }else{
        // 把中間沒滿的補齊到剩一格
        for(var k = 0; k< (this.Cols-1) - this.childTable[j].length;k++ ){
         this.childTable[j].push(null)
        }
      }
    }
  }
  mapChildElement(){
    this.containers.forEach(c=>{
      c.nativeElement.appendChild(this.children.find(ch=>c.nativeElement.classList.contains(ch.cls)).elementRef.nativeElement);
    })
    
    //debugger
    if(this.title?.first){
      var styleMap =(this.title?.first.nativeElement as any ).computedStyleMap();
      var styleValue = styleMap.get('font-size') as {unit:string,value:number}
      this.titleFontSize = styleValue?.value??(this.fg.getFontGreat()? 18:14);
    }
    
  }
  ngAfterContentInit(): void {
  
    this.computeCols();
    this.computeRowElements();
    
    setTimeout(() => {
      
      this.mapChildElement()
    }, 0);
  }
  ngAfterViewInit(): void {
    //setTimeout(() => {
      
    //}, 0);
    
  }
}
