import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ElementRef, AfterViewInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { NhiRecord } from 'src/app/services/api-service/NHI/nhiRecord';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { UserCache } from 'src/app/services/user-cache';
import { BasicData } from 'src/app/shared/hcr-lib/BasicData';
import { CriticalIllnesNoHPC } from 'src/app/shared/hcr-lib/CriticalIllnesNoHPC';
import { CriticalIllness } from 'src/app/shared/hcr-lib/CriticalIllness';
import { Cumulative } from 'src/app/shared/hcr-lib/Cumulative';
import { InoculateData } from 'src/app/shared/hcr-lib/InoculateData';
import { PrescriptionDetailHVE } from 'src/app/shared/hcr-lib/PrescriptionDetailHVE';
import { PrescriptionDetailLongTerm } from 'src/app/shared/hcr-lib/PrescriptionDetailLongTerm';
import { PrescriptionDetailOutPatient } from 'src/app/shared/hcr-lib/PrescriptionDetailOutPatient';
import { RegisterPregnantSubset } from 'src/app/shared/hcr-lib/RegisterPregnantSubset';
import { RegisterPreventSubset } from 'src/app/shared/hcr-lib/RegisterPreventSubset';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { GroupBy, Sum } from 'src/app/shared/utilities';
import { HistService } from '../services/hist.service';
import { Subscription } from 'rxjs';
import { StartUpService } from 'src/app/services/start-up-service';
import { __await } from 'tslib';

declare type CriIll = { Code: string, Name: string, StartDate: string, EndDate: string };
declare type Treatment = {
  Date: string,
  Isqno: string,
  Clinic: string,
  Type: string,
  NewBorn: string,
  Recard: string,
  // 門診部分負擔
  Deductible: string,
  // 門診醫療費用
  MedicalFees: string,
  // 住院醫療費用
  HospitalFees: string,
  // 住院部分負擔(短)
  HdeductibleA: string,
  // 住院部分負擔(長)
  HdeductibleB: string
}
declare type TreatmentHPC = {
  Date: string,
  Dx1: string,
  Dx2: string,
  Dx3: string,
  Dx4: string,
  Dx5: string,
  Dx6: string
}
export class MajorIll{
  CardCount:number;
  MajDate:Date;
}
@Component({
  selector: 'app-ic-view',
  templateUrl: './ic-view.component.html',
  styleUrls: ['./ic-view.component.css']
})
export class IcViewComponent implements OnInit, OnDestroy {

  private nhiRecord:NhiRecord;

  onHistLoadedSub:Subscription;

  bodyHeight = document.body.clientHeight;
  icViewScrollHeight:string = '';
  constructor(private hcrService: HcrService,
  private notify: EasyNotificationService,
  private mainLayoutService:MainLayoutService,
  private histApi:HistApi,
  public elementRef:ElementRef,
  private startUpService:StartUpService,
  private histService:HistService) { }


  async ngOnInit() {
    this.onHistLoadedSub = this.histService.onHistLoaded.subscribe(async (h) => {
      this.nhiRecord = await this.histApi.GetNHIRecord(h.Patient.Id);
      if (this.nhiRecord.PatientId == 0) {
        this.nhiRecord = new NhiRecord();
      } else {
        this.loadPastData();
      }
      if (this.histService.currentHist == null) { this.histService.currentHist = h; }
      if(this.hcrService.HasCard && this.startUpService.vpnState){
        await this.getPatient();
      }
    });

  }

  /** 取捲軸高 */
  getViewScrollHeight(): string {
    let scrollHeight= this.bodyHeight - 145;
    this.icViewScrollHeight = scrollHeight + 'px';
    return this.icViewScrollHeight;
  }

  loadPastData() {
    // 重大傷病
    if (this.nhiRecord.MajorInj) {
      var majInj = JSON.parse(this.nhiRecord.MajorInj);
      this.criIll = majInj.filter(x => x && x.Code);
    }

    // 主次診斷
    if (this.nhiRecord.Diagno) {
      var treatmentDataHPC = JSON.parse(this.nhiRecord.Diagno);
      this.treatmentHPC = treatmentDataHPC.filter(x => x)
      .map(x => {
        return {
          Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
          Dx1: x.MainDisease,
          Dx2: x.SecDisease1,
          Dx3: x.SecDisease2,
          Dx4: x.SecDisease3,
          Dx5: x.SecDisease4,
          Dx6: x.SecDisease5,
        }
      });
    }

    // 重要醫令
    if (this.nhiRecord.ImpOrder) {
      var impOrder = JSON.parse(this.nhiRecord.ImpOrder);
      this.prescriptionDetailHVE = impOrder.HVE;  //.filter(x=>x.ClinicCode);
      this.pagedHVE = {
        data: this.prescriptionDetailHVE.filter((x,i)=>i<5),
        total: this.prescriptionDetailHVE.length
      }
    }

    // 過敏藥物
    if (this.nhiRecord.AllergyMed) {
      var AllergyMed = JSON.parse(this.nhiRecord.AllergyMed);
      this.allergicDrug1 = AllergyMed.AllergicDrug[0];
      this.allergicDrug2 = AllergyMed.AllergicDrug[1];
      this.allergicDrug3 = AllergyMed.AllergicDrug[2];
    }

    // 門診醫令
    if (this.nhiRecord.OPTOrder) {
      var OPTOrder = JSON.parse(this.nhiRecord.OPTOrder);
      this.prescriptionDetailOutPatient= OPTOrder.OutPatient.filter(x => x.NhiDrugCode);
      this.pagedOutPatient = {
        data: this.prescriptionDetailOutPatient.filter((x, i) => i < 10),
        total: this.prescriptionDetailOutPatient.length
      }
    }

    // 長期醫令
    if (this.nhiRecord.LongOrder) {
      var LongOrder = JSON.parse(this.nhiRecord.LongOrder);
      this.prescriptionDetailLongTerm = LongOrder.LongTerm.filter(x => x.CreateDate);
      this.pagedLongTerm = {
        data:this.prescriptionDetailLongTerm.filter((x, i) => i < 5),
        total:this.prescriptionDetailLongTerm.length
      }
    }

    /** 無醫師卡就醫資料/費用 */
    if (this.nhiRecord.MedInfo) {
      var medInfo = JSON.parse(this.nhiRecord.MedInfo);
      this.criIllNoHPC = [medInfo.MajorInjury1, medInfo.MajorInjury2, medInfo.MajorInjury3,
        medInfo.MajorInjury4, medInfo.MajorInjury5, medInfo.MajorInjury6]
        .filter(x => x);
      this.treatmentNoHPC = [medInfo.TreatmentData1, medInfo.TreatmentData2, medInfo.TreatmentData3,
        medInfo.TreatmentData4, medInfo.TreatmentData5, medInfo.TreatmentData6]
          .filter(x => x)
          .map(x => {
            return {
              Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
              Isqno: x.Isqno,
              Clinic: x.ClinicCode,
              Type: x.Itp,
              NewBorn: x.NewbornFlag,
              Recard: x.ReCardFlag,
              // 門診部分負擔
              Deductible: x.Deductible,
              // 門診醫療費用
              MedicalFees: x.MedicalFees,
              // 住院醫療費用
              HospitalFees: x.HospitalFees,
              // 住院部分負擔(短)
              HdeductibleA: x.HdeductibleA,
              // 住院部分負擔(長)
              HdeductibleB: x.HdeductibleB
            }
          });
    }

    // 就醫費用
    if (this.nhiRecord.MedFee) {
      var medFee = JSON.parse(this.nhiRecord.MedFee);
      this.cumulatives = medFee as Cumulative[];
    }

    // 預防保健資料
    if (this.nhiRecord.HealthCare) {
      var healthCare = JSON.parse(this.nhiRecord.HealthCare);
      this.registerPreventSubset = [healthCare.Set1, healthCare.Set2, healthCare.Set3, healthCare.Set4, healthCare.Set5, healthCare.Set6];
    }

    // 產檢資料
    if (this.nhiRecord.PrenatalCare) {
      var prenatalCare = JSON.parse(this.nhiRecord.PrenatalCare);
      this.registerPregnantSubset = prenatalCare.Data as RegisterPregnantSubset[];
      this.pagedRegisterPregnant = {
        data: this.registerPregnantSubset.filter((x, i) => i < 5),
        total:this.registerPregnantSubset.length
      }
    }

    // 疫苗接種資料
    if (this.nhiRecord.Vaccination) {
      var vaccination = JSON.parse(this.nhiRecord.Vaccination);
      this.inoculateData = vaccination.Data as InoculateData[];
      this.pagedInoculateData =  {
        data:this.inoculateData.filter((x, i) => i < 5),
        total:this.inoculateData.length
      }
    }
  }

  getRealHeight():number {
   return this.elementRef.nativeElement.offsetHeight
  }

  ngOnDestroy(): void {
    this.onHistLoadedSub.unsubscribe();
  }
  closeLoader() {
    this.mainLayoutService.hideLoader();
  }
  showLoader() {
    this.mainLayoutService.showLoader();
  }
  async reloadAll(){
    this.showLoader();
    if(this.loadItems.criIll || this.loadItems.treatment){
      await this.getTreatment(false);
    }
    if(this.loadItems.rxHVE){
      await this.ReadPrescriptHVE(false);
    }
    if(this.loadItems.allergy){
      await this.ReadPrescriptAllergic(false);
    }
    if(this.loadItems.rxMain){
      await this.ReadPrescriptMain(false);
    }
    if(this.loadItems.rxLong){
      await this.ReadPrescriptLongTerm(false);
    }
    this.closeLoader();
    this.showLoader();
    if(this.loadItems.criIllNoHpc || this.loadItems.treatmentNoHpc || this.loadItems.fee){
      await this.getTreatmentNoNeedHPC(false);
    }
    if(this.loadItems.prevent){
      await this.getRegisterPrevent(false);
    }
    if(this.loadItems.pregnent){
      await this.getRegisterPregnant(false);
    }
    if(this.loadItems.inoculate){
      await this.getInoculateData(false);
    }
    await this.saveRecord();
    this.closeLoader();
  }
  @Output()
  close = new EventEmitter();
  // @Output()
  // callParentToMajorinj= new EventEmitter<boolean>();
  // @Output()
  // callBackMajorinj= new EventEmitter<MajorIll>();

  // 帶測試OK 開放
  // @Input ()
  // set isKidneyDialyis(val : number){
  //   if(!val){
      
  //   }
  //   else if (val === 1){
  //     this.saveRecord(); return;
  //   }
  //   else if (val === 2)
  //   {
  //     console.log('3223');
  //     setTimeout(async () => {
  //       if(!await this.autoCheckHPC()){
  //         await this.getTreatmentNoNeedHPC(false);
  //       }
  //       else
  //       {
  //         await this.getTreatment(false, true);
  //       }
  //       await this.saveRecord();
  //     }, 0);

  //   }
  // }
  loadItems={
    criIll:false,
    treatment:false,
    rxHVE:false,
    rxMain:false,
    rxLong:false,
    allergy:false,

    criIllNoHpc:false,
    treatmentNoHpc:false,
    fee:false,
    prevent:false,
    pregnent:false,
    inoculate:false
  }


  criIllNoHPC: CriticalIllnesNoHPC[] = [];
  criIll: CriticalIllness[] = [];
  treatmentNoHPC: Treatment[] = [];
  cumulatives:Cumulative[]=[];
  registerPreventSubset:RegisterPreventSubset[] = [];
  registerPregnantSubset:RegisterPregnantSubset[] = [];

  pagedRegisterPregnant = {
    data: [],
    total: 0
  };
  inoculateData:InoculateData[] = [];

  pagedInoculateData = {
    data: [],
    total: 0
  };
  treatmentHPC: TreatmentHPC[] = [];
  patient: BasicData = null;
  prescriptionDetailHVE:PrescriptionDetailHVE[] = [];
  prescriptionDetailOutPatient:PrescriptionDetailOutPatient[] = [];
  pagedHVE = {
    data: [],
    total: 0
  };
  pagedOutPatient = {
    data: [],
    total: 0
  };
  pagedLongTerm = {
    data: [],
    total: 0
  };
  prescriptionDetailLongTerm:PrescriptionDetailLongTerm[] = [];
  allergicDrug1 = '';
  allergicDrug2 = '';
  allergicDrug3 = '';

  OrganDonate = '';

  treatmentHPCError = '';
  async saveRecord(){
    this.nhiRecord.PatientId = this.histService.currentHist.Patient.Id;
    this.nhiRecord.PatientNo = this.histService.currentHist.Patient.PatientNo;
    this.nhiRecord.ClinicId = UserCache.getLoginUser().Clinic.Id;
    this.nhiRecord.ClinicCode = UserCache.getLoginUser().Clinic.Code;
    this.nhiRecord.AgainDate = new Date();
    this.histApi.SaveNHIRecord(this.nhiRecord);
  }
  async getPatient() {
    var ret = await this.hcrService.VisionApi.GetBasicData();

    this.patient = ret.Data;
    var oRet = await this.hcrService.VisionApi.GetOrganDonate();
    this.OrganDonate = oRet.Data == null ? '' : oRet.Data.OrganDonateNote;
    if (this.nhiRecord) {
      this.nhiRecord.OrganDon = oRet.Data == null ? '' : JSON.stringify(oRet.Data);
    }
    await this.saveRecord();
  }
  /** 無醫師卡就醫資料/費用 */
  async getTreatmentNoNeedHPC(fromBtn = true) {
    if(fromBtn){
      this.showLoader();
    }
    this.notify.showInfo('就醫資料讀取中');
    var data = await this.hcrService.VisionApi.GetTreatmentNoNeedHPC();
    if (data.StatusCode == 0) {
      this.criIllNoHPC = [data.Data.MajorInjury1, data.Data.MajorInjury2, data.Data.MajorInjury3,
      data.Data.MajorInjury4, data.Data.MajorInjury5, data.Data.MajorInjury6]
        .filter(x => x);

      this.treatmentNoHPC = [data.Data.TreatmentData1, data.Data.TreatmentData2, data.Data.TreatmentData3,
      data.Data.TreatmentData4, data.Data.TreatmentData5, data.Data.TreatmentData6]
        .filter(x => x)
        .map(x => {
          return {
            Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
            Isqno: x.Isqno,
            Clinic: x.ClinicCode,
            Type: x.Itp,
            NewBorn: x.NewbornFlag,
            Recard: x.ReCardFlag,
            // 門診部分負擔
            Deductible: x.Deductible,
            // 門診醫療費用
            MedicalFees: x.MedicalFees,
            // 住院醫療費用
            HospitalFees: x.HospitalFees,
            // 住院部分負擔(短)
            HdeductibleA: x.HdeductibleA,
            // 住院部分負擔(長)
            HdeductibleB: x.HdeductibleB
          }
        });
      // 就醫費用
      this.cumulatives = GroupBy([data.Data.TreatmentData1, data.Data.TreatmentData2, data.Data.TreatmentData3,
        data.Data.TreatmentData4, data.Data.TreatmentData5, data.Data.TreatmentData6]
        .filter(x => x)
        .map(x=>{
        return {
          year: new Date(x.ClinicDate).getFullYear(),
          Deductible: x.Deductible,// 門診部分負擔
          MedicalFees: x.MedicalFees,// 門診醫療費用
          HospitalFees: x.HospitalFees,// 住院醫療費用
          HdeductibleA: x.HdeductibleA,// 住院部分負擔(A)
          HdeductibleB: x.HdeductibleB// 住院部分負擔(B)
        }
      }),a=>a.year.toString())
      .map(x=>{
        return {
          Year: x.key,/***年*/
          CimulativeCount: x.items.length.toString(),/** * 門住診就醫累計次數*/
          MedicalFeesSum: Sum(x.items,x=> parseFloat(x.MedicalFees)??0).toString(),/*** 門診醫療費用累計*/
          HospitalFeesSum: Sum(x.items,x=> parseFloat(x.HospitalFees)??0).toString(),/*** 住診醫療費用累計*/
          DeductibleSum:  Sum(x.items,x=> parseFloat(x.Deductible)??0).toString(),/*** 門診部分負擔醫療費用累計*/
          HdeductibleASum: Sum(x.items,x=> parseFloat(x.HdeductibleA)??0).toString(),/*** 住診急性30天、慢性180天以下部分負擔費用累計*/
          HdeductibleBSum: Sum(x.items,x=> parseFloat(x.HdeductibleB)??0).toString(),/** * 住診急性31天、慢性181天以上部分負擔費用累計*/
          MHdeductibleSum: Sum(x.items,x=> (parseFloat(x.HdeductibleA)??0)+(parseFloat(x.Deductible)??0)+(parseFloat(x.HdeductibleB))).toString(),/*** 門診+住診部分負擔費用累計*/
          MHdeductibleASum: Sum(x.items,x=> (parseFloat(x.HdeductibleA)??0)+(parseFloat(x.Deductible)??0)).toString(),/** * 【門診+住診(急性30天、慢性180以下)】部分負擔費用累計 */
        }
      });
      this.nhiRecord.MedInfo = JSON.stringify(data.Data);
      this.nhiRecord.MedFee = JSON.stringify(this.cumulatives);
    }
    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 就醫累計費用 */
  async getCumulativeData() {
    var data = await this.hcrService.VisionApi.GetCumulativeData();
    if(data.StatusCode==0){
      this.cumulatives = [data.Data.Set1,data.Data.Set2]
    }else{
      this.notify.showError('取得就醫累計費用失敗('+data.ErrorMsg+')')
    }

  }
  /** 預防保健資料 */
  async getRegisterPrevent(fromBtn = true) {
    if(fromBtn){
      this.showLoader();
    }

    this.notify.showInfo('預防保健讀取中');
    var data = await this.hcrService.VisionApi.GetRegisterPrevent();
    if(data.StatusCode==0){
      this.registerPreventSubset = [data.Data.Set1,data.Data.Set2,data.Data.Set3,data.Data.Set4,data.Data.Set5,data.Data.Set6]
      this.nhiRecord.HealthCare = JSON.stringify(data.Data);
    }else{
      this.notify.showError('取得預防保健資料失敗('+data.ErrorMsg+')')
    }
    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 產檢資料 */
  async getRegisterPregnant(fromBtn=true) {
    if(fromBtn){
      this.showLoader();
    }

    this.notify.showInfo('產檢資料讀取中');
    var data = await this.hcrService.VisionApi.GetRegisterPregnant();
    if(data.StatusCode==0){
      this.registerPregnantSubset = data.Data.Data;
      this.pagedRegisterPregnant = {
        data:this.registerPregnantSubset.filter((x,i)=>i<5),
        total:this.registerPregnantSubset.length
      }
      this.nhiRecord.PrenatalCare = JSON.stringify(data.Data);
    }else{
      this.notify.showError('取得產檢資料失敗('+data.ErrorMsg+')')
    }
    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 產檢分頁 */
  registerPregnantPageChanged(page){
    this.pagedRegisterPregnant = {
      data:this.registerPregnantSubset.filter((x,i)=>i>=(page-1)*5 && i<(page)* 5),
      total:this.registerPregnantSubset.length
    }
  }
  /** 疫苗接種資料 */
  async getInoculateData(fromBtn = true) {
    if(fromBtn){
      this.showLoader();
    }
    this.notify.showInfo('疫苗接種讀取中');
    var data = await this.hcrService.VisionApi.GetInoculateData();
    if(data.StatusCode==0){
      this.inoculateData = data.Data.Data;
      this.pagedInoculateData =  {
        data:this.inoculateData.filter((x,i)=>i<5),
        total:this.inoculateData.length
      }
      this.nhiRecord.Vaccination = JSON.stringify(data.Data);
    }else{
      this.notify.showError('取得產檢資料失敗('+data.ErrorMsg+')')
    }
    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 疫苗分頁 */
  inoculateDataPageChanged(page){
    this.pagedInoculateData = {
      data:this.inoculateData.filter((x,i)=>i>=(page-1)*5 && i<(page)* 5),
      total:this.inoculateData.length
    }
  }
  //#region  HPN NEED
  /** 重大傷病 主次診斷 */
  async getTreatment(fromBtn=true) {
    if(! await this.checkHPC()){
      return;
    }
    if(fromBtn){
      this.showLoader();
    }
    this.notify.showInfo('重大傷病讀取中');
    var ret = await this.hcrService.VisionApi.GetTreatmentNeedHPC();
    if(ret.StatusCode!=0){
      this.notify.showError('重大傷病讀取失敗('+ret.ErrorMsg+')');
    } else {
      if (ret.Data) {
        var majInj = [ret.Data.MajorInjury1, ret.Data.MajorInjury2, ret.Data.MajorInjury3,
          ret.Data.MajorInjury4, ret.Data.MajorInjury5, ret.Data.MajorInjury6]
        this.criIll = majInj.filter(x => x&&x.Code);

        var diag = [ret.Data.TreatmentData1, ret.Data.TreatmentData2, ret.Data.TreatmentData3,
          ret.Data.TreatmentData4, ret.Data.TreatmentData5, ret.Data.TreatmentData6]
        this.treatmentHPC = diag.filter(x => x)
          .map(x => {
            return {
              Date: DateHelper.formatROCDate(x.ClinicDate, false, true),
              Dx1: x.MainDisease,
              Dx2: x.SecDisease1,
              Dx3: x.SecDisease2,
              Dx4: x.SecDisease3,
              Dx5: x.SecDisease4,
              Dx6: x.SecDisease5,
            }
          });

        this.nhiRecord.MajorInj = JSON.stringify(majInj);
        this.nhiRecord.Diagno  = JSON.stringify(diag);
      }
    }
    if(fromBtn){
      await this.saveRecord()
      this.closeLoader();
    }
  }
  /** 重大醫令 */
  async ReadPrescriptHVE(fromBtn=true){
    if(! await this.checkHPC()){
      return;
    }
    if(fromBtn){
      this.showLoader();
    }
    this.notify.showInfo('重大醫令讀取中');
    var ret = await this.hcrService.VisionApi.ReadPrescriptHVE(1,10,1);
    if(ret.StatusCode==0){
      this.prescriptionDetailHVE= ret.Data.HVE;  //.filter(x=>x.ClinicCode);
      this.pagedHVE = {
        data:this.prescriptionDetailHVE.filter((x,i)=>i<5),
        total:this.prescriptionDetailHVE.length
      }
      this.nhiRecord.ImpOrder = JSON.stringify(ret.Data);
    }else{
      this.notify.showError('重大醫令讀取失敗('+ret.ErrorMsg+')');
    }

    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 重大分頁 */
  HVEPageChanged(page){
    this.pagedHVE = {
      data:this.prescriptionDetailHVE.filter((x,i)=>i>=(page-1)*5 && i<(page)* 5),
      total:this.prescriptionDetailHVE.length
    }
  }
  /** 過敏藥物 */
  async ReadPrescriptAllergic(fromBtn=true){
    if(! await this.checkHPC()){
      return;
    }
    if(fromBtn){
      this.showLoader();
    }
    var ret = await this.hcrService.VisionApi.ReadPrescriptAllergic(1,3,3);
    if(ret.StatusCode==0){
      this.allergicDrug1 = ret.Data.AllergicDrug[0];
      this.allergicDrug2 = ret.Data.AllergicDrug[1];
      this.allergicDrug3 = ret.Data.AllergicDrug[2];
      this.nhiRecord.AllergyMed = JSON.stringify(ret.Data);
    }else{
      this.notify.showError('過敏藥物讀取失敗('+ret.ErrorMsg+')');
    }

    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /**門診醫令 */
  async ReadPrescriptMain(fromBtn=true){
    if(! await this.checkHPC()){
      return;
    }
    if(fromBtn){
      this.showLoader();
    }
    var ret = await this.hcrService.VisionApi.ReadPrescriptMain(1,60,60);
    if(ret.StatusCode==0){
      this.prescriptionDetailOutPatient= ret.Data.OutPatient.filter(x=>x.NhiDrugCode);
      this.pagedOutPatient = {
        data:this.prescriptionDetailOutPatient.filter((x,i)=>i<10),
        total:this.prescriptionDetailOutPatient.length
      }
      this.nhiRecord.OPTOrder = JSON.stringify(ret.Data);
    }else{
      this.notify.showError('門診醫令讀取失敗('+ret.ErrorMsg+')');
    }

    if(fromBtn){
      await this.saveRecord()
      this.closeLoader();
    }
  }
  /** 門診醫令分頁 */
  outpatientPageChanged(page){
    this.pagedOutPatient = {
      data:this.prescriptionDetailOutPatient.filter((x,i)=>i>=(page-1)*10 && i<(page)* 10),
      total:this.prescriptionDetailOutPatient.length
    }
  }
  /** 長期醫令 */
  async ReadPrescriptLongTerm(fromBtn=true){
    if(! await this.checkHPC()){
      return;
    }
    if(fromBtn){
      this.showLoader();
    }

    this.notify.showInfo('長期醫令讀取中');
    var ret = await this.hcrService.VisionApi.ReadPrescriptLongTerm(1,30,30);
    if(ret.StatusCode==0){
      this.prescriptionDetailLongTerm= ret.Data.LongTerm.filter(x=>x.CreateDate);
      this.pagedLongTerm = {
        data:this.prescriptionDetailLongTerm.filter((x,i)=>i<5),
        total:this.prescriptionDetailLongTerm.length
      }
      this.nhiRecord.LongOrder = JSON.stringify(ret.Data);
    }else{
      this.notify.showError('長期醫令讀取失敗('+ret.ErrorMsg+')');
    }
    if(fromBtn){
      await this.saveRecord();
      this.closeLoader();
    }
  }
  /** 長期分頁 */
  longTermPageChanged(page){
    this.pagedLongTerm = {
      data:this.prescriptionDetailLongTerm.filter((x,i)=>i>=(page-1)*5 && i<(page)* 5),
      total:this.prescriptionDetailLongTerm.length
    }
  }
  //#endregion
  /** 檢查pin */
  async checkHPC(){
    var ret = await this.hcrService.VisionApi.GetHPCStatus();
    if(ret.StatusCode==0){
      var st = await this.hcrService.VisionApi.GetCardStatusHPC();
      if(st.Data.includes('PIN 尚未認證')){
        //
        var retPin = await this.hcrService.VisionApi.VerifyHPCPIN()
        var pinValid =  retPin.StatusCode==0;
        if(!pinValid){
          this.notify.showError("醫師卡尚未進行PIN認證("+retPin.ErrorMsg+")");
        }
        return pinValid;
      }else if(!st.Data.includes('認證成功')){
        this.notify.showError("醫師卡尚未進行認證("+st.ErrorMsg+")");
        return false;
      }else{
        return true;
      }
    }else{
      this.notify.showError("醫師卡尚未進行PIN認證("+ret.ErrorMsg+")");
    }

  }
  async autoCheckHPC(){
    var ret = await this.hcrService.VisionApi.GetHPCStatus();
    console.log('ret',ret);
    if(ret.StatusCode==0){
      var st = await this.hcrService.VisionApi.GetCardStatusHPC();
      if(st.Data.includes('PIN 尚未認證')){
        //
        var retPin = await this.hcrService.VisionApi.VerifyHPCPIN()
        var pinValid =  retPin.StatusCode==0;
        if(!pinValid){
          this.notify.showError("醫師卡尚未進行PIN認證("+retPin.ErrorMsg+")");
        }
        return pinValid;
      }else if(!st.Data.includes('認證成功')){
        this.notify.showError("醫師卡尚未進行認證("+st.ErrorMsg+")");
        return false;
      }else{
        return true;
      }
    }else{
      return false;
    }
    return false;
  }

  needHpcAll(evt:MatCheckboxChange){
    this.loadItems.criIll = evt.checked;
    this.loadItems.treatment = evt.checked;
    this.loadItems.rxHVE = evt.checked;
    this.loadItems.rxMain = evt.checked;
    this.loadItems.rxLong = evt.checked;
  }
  noNeedHpcAll(evt:MatCheckboxChange){
    this.loadItems.criIllNoHpc = evt.checked;
    this.loadItems.treatmentNoHpc = evt.checked;
    this.loadItems.fee = evt.checked;
    this.loadItems.prevent = evt.checked;
    this.loadItems.pregnent = evt.checked;
    this.loadItems.inoculate = evt.checked;
  }
}
