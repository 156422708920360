import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { UrlHelper } from 'src/app/shared/helpers/url-helper';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor( private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    const companyCode = UrlHelper.getCompanyCodeFromRoute(this.router);
    this.authenticationService.logout(companyCode);
  }
}
