import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// shared
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { BaseEditComponent } from 'src/app/shared/components/base/base.edit.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValidationTipComponent as ValidTip } from 'src/app/shared/components/validation-tip/validation-tip.component';
// module
import { Contract } from 'src/app/patients/contract/models/contract';
import { IdHelper } from 'src/app/shared/helpers/guid-helper';
import { IfStmt } from '@angular/compiler';
import { group } from '@angular/animations';
import { DateValidation } from 'src/app/services/date-validation.service';

@Component({
  selector: 'app-patients-contract-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class ContractEditComponent extends BaseEditComponent<Contract> implements OnInit {

  constructor(private fb: FormBuilder,
    private webApiFactory: WebApiClient,
    private notificationDI: EasyNotificationService,
    private changeDetectorRefDI: ChangeDetectorRef) {
    super();
    this.api = this.webApiFactory.createHisService('patients/contract');
    this.notification = notificationDI;
    this.changeDetectorRef = changeDetectorRefDI;
  }

  // 編輯時需要的選項或數值
  @Input() editOptions;
  // @Input() queryContractType;
  @Input() set queryContractType(Type) {
    if (this.editFormGroup) {
      // 處理一開始沒有選Type的情況
      if (Type !== undefined) {
        // 控制list的Type選取 對edit的DoctorId驗證
        if (Type === 'CnHCare') {
          this.editFormGroup.controls.DoctorId.setValidators([Validators.required]);
        } else {
          this.editFormGroup.controls.DoctorId.setValidators(null);
        }
      }
    }
  }
  @Input() set selectedPatientId(Id) {
    if (Id !== undefined) {
      this.editFormGroup.controls['PatientId'].setValue(Id);
    }
  }
  @Input() selectedPatientName: string;
  @Input() isAddMode;
  selectedItemContractType: string;         // edit的Type選單

  ngOnInit() {
    this.emitEnterEdit.subscribe(d => {
      if (!this.isAddMode) {
        this.editFormGroup.get('TypeCode').disable();
      } else {
        this.editFormGroup.controls.Id.setValue(IdHelper.IdEmpty);
        this.editFormGroup.controls.ClinicId.setValue(IdHelper.IdEmpty);
      }
    });
  }

  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      Id: [''],
      // tslint:disable-next-line: max-line-length
      PatientId: ['', [Validators.required]],
      ClinicId: [''],
      TypeCode: ['', [Validators.required, Validators.maxLength(10)]],
      DoctorId: [''],
      Status: ['', [Validators.required]],
      StartDate: ['', [Validators.required]],
      EndDate: ['', [Validators.required]],
      CloseReason: ['', [Validators.maxLength(50)]],
      CloseDate: [''],
      IdentityType: ['', [Validators.maxLength(10)]],
      IdentityTypeOther: ['', [Validators.maxLength(50)]],
      CareLevel: ['', [Validators.maxLength(10)]],
      PaymentType: ['', [Validators.maxLength(10)]],
      PaymentTypeOther: ['', [Validators.maxLength(50)]],
      IsVerify: [Boolean],
      VerifyNo: ['', [Validators.maxLength(10)]],
      Remark: ['', [Validators.maxLength(200)]],
    }, {validator: DateValidation.dateCheck('StartDate', 'EndDate')});
    return fg;
  }

  // 填入表單資料
  fillFormData(data) {
    if (!data) {
      this.selectedPatientName = '';
    } else if (!data.data) {
      this.editFormGroup.setValue({
        Id: data.Id || IdHelper.IdEmpty,
        PatientId: data.PatientId,
        ClinicId: data.ClinicId || IdHelper.IdEmpty,
        TypeCode: data.TypeCode,
        DoctorId: data.DoctorId || '',
        Status: `${data.Status}`,
        StartDate: data.StartDate == null ? null : DateHelper.createDate(data.StartDate),
        EndDate: data.EndDate == null ? null : DateHelper.createDate(data.EndDate),
        CloseReason: data.CloseReason || '',
        CloseDate: data.CloseDate == null ? null : DateHelper.createDate(data.CloseDate),
        IdentityType: data.IdentityType || '',
        IdentityTypeOther: data.IdentityTypeOther || '',
        CareLevel: data.CareLevel || '',
        PaymentType: data.PaymentType || null,
        PaymentTypeOther: data.PaymentTypeOther || null,
        IsVerify: `${data.IsVerify}` || null,
        VerifyNo: data.VerifyNo || null,
        Remark: data.Remark || null,
      });
    } else {
    // for edit動作時 家護合約 醫生欄位驗證
    if (this.selectedItemContractType === 'CnHCare') {
      this.editFormGroup.controls.DoctorId.setValidators([Validators.required]);
    } else {
      this.editFormGroup.controls.DoctorId.setValidators(null);
    }
    this.selectedPatientName = data.name;
    this.editFormGroup.setValue({
      Id: data.data.Id || IdHelper.IdEmpty,
      PatientId: data.data.PatientId,
      ClinicId: data.data.ClinicId || IdHelper.IdEmpty,
      TypeCode: data.data.TypeCode,
      DoctorId: data.data.DoctorId || '',
      Status: `${data.data.Status}`,
      StartDate: data.data.StartDate == null ? null : DateHelper.createDate(data.data.StartDate),
      EndDate: data.data.EndDate == null ? null : DateHelper.createDate(data.data.EndDate),
      CloseReason: data.data.CloseReason || '',
      CloseDate: data.data.CloseDate == null ? null : DateHelper.createDate(data.data.CloseDate),
      IdentityType: data.data.IdentityType || '',
      IdentityTypeOther: data.data.IdentityTypeOther || '',
      CareLevel: data.data.CareLevel || '',
      PaymentType: data.data.PaymentType || null,
      PaymentTypeOther: data.data.PaymentTypeOther || null,
      IsVerify: `${data.data.IsVerify}` || null,
      VerifyNo: data.data.VerifyNo || null,
      Remark: data.data.Remark || null,
    });
    }

  }

  afterAddAction() {
    this.editFormGroup.controls['Status'].setValue( 'true' );
    this.editFormGroup.controls['IsVerify'].setValue( 'false');
    this.editFormGroup.controls['TypeCode'].setValue(this.queryContractType);
    this.editFormGroup.controls['StartDate'].setValue(new Date());
    // magic string
    if (this.queryContractType === 'CnHCare') {
      this.editFormGroup.controls['EndDate'].setValue(new Date(new Date().getFullYear(), 11, 31));
    }
  }

  selectContractType(value) {
    this.selectedItemContractType = value;
    if (this.selectedItemContractType === 'CnHCare') {
      this.editFormGroup.controls['EndDate'].setValue(new Date(new Date().getFullYear(), 11, 31));
      this.editFormGroup.controls.DoctorId.setValidators([Validators.required]);
    } else {
      this.editFormGroup.controls.EndDate.setValue( null );
      this.editFormGroup.controls.DoctorId.setValidators(null);
      this.editFormGroup.controls.DoctorId.setValue('');
    }
  }

  delete () {
    super.delete();
    this.selectedPatientName = '';
  }

  save() {
    if (!this.editFormGroup) {
      return;
    }
    // 註記所有欄位為touched以進行Validator檢查
    if (this.validate) {
      this.editFormGroup.markAllAsTouched();
    }
    if (this.editFormGroup.valid && this.customValidateBeforeSave()) {
      // 驗證成功
      if (this.isAdd()) {
        const saveData = this.prepareAdditionDataBeforeSave(this.editFormGroup.getRawValue(), true);
        // 新增
        this.api.post('create', saveData).subscribe(
          (data) => {
            if (this.hasAfterSaveAction) { // 若有特定的存檔後行為
              this.afterSaveAction(data);
            } else {
              this.notification.showOk(); // 顯示存檔成功
              // console.log('create done');
              if (data) {
                this.fillFormData(data); // 填充表格資料
              }
              if (!data || !data.Id || IdHelper.isNullOrEmpty(data.Id)) {
                console.warn('新增回傳的資料為null或沒有Id，很可能會影響新增後直接編輯或刪除動作');
                // this.notification.showParameterError();
              }
              this.viewFromSave();
              this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
            }
          },
          error => {
            this.notification.showError(error); // 顯示存檔失敗
            this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
          }
        );
      } else {
        const saveData = this.prepareAdditionDataBeforeSave(this.editFormGroup.getRawValue(), false);
        // 更新
        this.api
          .put(
            'update',
            saveData
          ).subscribe(
            (data) => {
              if (this.hasAfterSaveAction) { // 若有特定的存檔後行為
                this.afterSaveAction(data);
              } else {
                this.notification.showOk(); // 顯示存檔成功
                this.fillFormData(data); // 填充表格資料
                this.viewFromSave();
                // 不通知父元件是為了要停在這一筆
                this.onUpdated.emit(); // 通知父元件(父元件更新清單內容)
              }
            },
            error => {
              this.notification.showFailed(); // 顯示存檔失敗
              this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
            }
          );
      }
    } else {
      // 驗證失敗
      this.notification.showValidateFailed();
      this.editControlButtons.enableButtons(); // 操作完畢(不論成功或失敗)，[按鈕列]解除鎖定
    }
  }

  cancel() {
    this.selectedPatientName = '';
    super.cancel();
  }
}
