import { EventEmitter, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

import { Router, NavigationEnd } from '@angular/router';
import { isNullOrUndefined } from '../shared/utilities';

/*
sample code 1:
this.easyNotificationService.showSaveOk();
-----------------------------
sample code 2 - 直接給予訊息，必須已處理i18n，用在來自server的訊息，因為server已處理i18n:
this.easyNotificationService.showError('Error occurs');
-----------------------------
sample code 3 - 用在client的訊息，所以必須搭配<app-msg-text>來處理i18n:
用#開頭，會去讀取#xxx該textId的內容，然後顯示。
this.easyNotificationService.showErrorById('MSG_MainLayout1');
<app-msg-text textId="MSG_MainLayout1" i18n="MSG_MainLayout1">Test message.</app-msg-text>
*/
declare type MsgObj ={
  id:string,
  content:string,
  panelCss:string[],
  isAutoHide:boolean
}
@Injectable({
  providedIn: 'root'
})
export class EasyNotificationService {
  //notifications: NotificationRef[] = [];
  constructor(
    private _snackBar: MatSnackBar,
    //private notificationService: NotificationService,
    private route: Router) {
    route.events.subscribe((val) => {
      if ( val instanceof NavigationEnd ) {

        // this.notifications.forEach((n: NotificationRef) => {
        //   n.notification.destroy();
        // });
        // this.notifications = [];
       }
    });
  }

  // MSeconds
  hideAfter = 2000;

  // 固定動作顯示之訊息，包含預設訊息內容
  getTextById(textId: string) {
    try {
      const txt = document.getElementById(textId).innerHTML;
      return txt;
    } catch {
      throw new Error('can not find appMsgText by textId: ' + textId);
    }
  }
  // 取得資料失敗
  showGetDataFailed(tag='') {
    console.log('取得資料失敗的訊息-來源：',tag);
    this.showErrorById('MSG_MainLayout1');
  }
  // 成功
  showOk() {
    this.showSuccessById('MSG_MainLayout2');
  }
  // 失敗
  showFailed() {
    this.showErrorById('MSG_MainLayout3');
  }
  // 驗證失敗
  showValidateFailed() {
    this.showWarningById('MSG_MainLayout4');
  }
  // 參數錯誤
  showParameterError() {
    this.showWarningById('MSG_MainLayout5');
  }

  // 預設true
  private getDefaultTrue(isAutoHide?: boolean): boolean {
    // 預設true
    if (isNullOrUndefined(isAutoHide)) {
      return true;
    } else {
      return isAutoHide;
    }
  }
  // 顯示訊息，差別在於style不同
  showInfoById(textId: string, isAutoHide?: boolean) {
    if (!textId) { return; }
    const content = this.getTextById(textId);
    this.showInfo(content, this.getDefaultTrue(isAutoHide));
  }
  showInfo(content: any, isAutoHide?: boolean) {
    if (!content) { return; }
    var msgId = this.showMessage(content, 'msg-default msg-info', this.getDefaultTrue(isAutoHide));
    return msgId;
  }
  showTip(content: any) {
    if (!content) { return; }
    var msgId = this.showMessage(content, 'msg-default msg-tip', false);
    return msgId;
  }
  showSuccessById(textId: string, isAutoHide?: boolean) {
    if (!textId) { return; }
    const content = this.getTextById(textId);
    return this.showSuccess(content, this.getDefaultTrue(isAutoHide));
  }
  showSuccess(content: any, isAutoHide?: boolean) {
    if (!content) { return; }
    return this.showMessage(content, 'msg-default msg-success', this.getDefaultTrue(isAutoHide));
  }
  showWarningById(textId: string, isAutoHide?: boolean) {
    if (!textId) { return; }
    const content = this.getTextById(textId);
    this.showWarning(content, this.getDefaultTrue(isAutoHide));
  }
  showWarning(content: any, isAutoHide?: boolean) {
    if (!content) { return; }
    this.showMessage(content, 'msg-default msg-warning', this.getDefaultTrue(isAutoHide));
  }
  showErrorById(textId: string, isAutoHide?: boolean) {
    if (!textId) { return; }
    const content = this.getTextById(textId);
    this.showError(content, isAutoHide);
  }
  showError(content: any, isAutoHide?: boolean) {
    console.trace('ERROR:'+content);
    if (!content) {
      this.showFailed();
      return;
    }
    var msg = (content.message || content.Message || content).toString();
    if(msg == {}.toString()){
      msg = JSON.stringify(content);
    }
    this.showMessage(msg, 'msg-default msg-error', isAutoHide);
  }
  showErrorWithPrefix(preFix:string, content: any, isAutoHide?: boolean,consolePrint:boolean = true) {
    if(consolePrint){
      console.trace('ERROR:'+content);
    }
    
    if (!content) {
      this.showFailed();
      return;
    }
    var msg = (content.message || content.Message || content).toString();
    if(msg == {}.toString()){
      msg = JSON.stringify(content);
    }
    this.showMessage(preFix+ msg, 'msg-default msg-error', isAutoHide);
  }

  closeMessage(id:string){
    this.OnCloseMessage.emit(id);
  }
  // showWhiteInfo(content: any, isAutoHide?: boolean) {
  //   this.showMessage(content, 'msg-default msg-white', isAutoHide);
  // }
  // showLightInfo(content: any, isAutoHide?: boolean) {
  //   this.showMessage(content, 'msg-default msg-light', isAutoHide);
  // }

  // 顯示訊息之基本方法
  snackType:'Mat'|'Vision' = 'Vision';
  public OnMessage: EventEmitter<NotifyMessage> = new EventEmitter<NotifyMessage>()
  public OnCloseMessage: EventEmitter<string> = new EventEmitter<string>();
  private showMessage(content: any, cssClass: string, isAutoHide?: boolean):string {
    if (!cssClass) { 
      cssClass = 'msg-default'; 
    }
    let panelCss = cssClass.split(' ');
    let id = crypto['randomUUID']();
    if(this.snackType == 'Mat'){
      this.msgQueue.push({id:id, content:content,panelCss:panelCss,isAutoHide:isAutoHide});
      if(this.snackBarRef==null){
        this.showNext();
      }
    }else if(this.snackType == 'Vision'){
      this.OnMessage.emit({id,content,cssClass,isAutoHide})
    }
    return id;
  }
  snackBarRef: MatSnackBarRef<TextOnlySnackBar>;
  msgQueue:MsgObj[] = [];
  showNext() {
    if (this.msgQueue.length === 0) {
      this.snackBarRef = null;
      return;
    }

    let message = this.msgQueue.shift();
    if(!message){
      this.snackBarRef = null;
    }
    if (message.isAutoHide) {
      this.snackBarRef =this._snackBar.open(message.content,null,{
        'verticalPosition':'top',
        'panelClass':message.panelCss,
        'duration':this.hideAfter,
        // 螢幕閱讀器用
        //'politeness':'assertive',
        //'announcementMessage':'1234'
      })

    } else {
      this.snackBarRef = this._snackBar.open(message.content,'Ok',{
        'verticalPosition':'top',
        'panelClass':message.panelCss,

      })
    }
    this.snackBarRef.afterDismissed().subscribe(() => {
      this.showNext();
    });
  }
}

export class NotifyMessage{
  id:string;
  content: string;
  cssClass: string;
  isAutoHide?: boolean;
}
