import { Injectable } from '@angular/core';
import { WebApiService } from './web-api.service';
import { BaseConfig } from './base-config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { UserCache } from './user-cache';
import { IBaseConfig } from '../shared/models/base-config';
import { ConsoleInfo } from './hcr-service.service';
import { LocalStoragesService } from './local-storages.service';
@Injectable({
  providedIn: 'root'
})
export class WebApiClient {

  // 系統參數
  config: IBaseConfig;
  token: string;
  //companyCode: string;
  //clinicId: string;
  constructor(public http: HttpClient,private localStorage:LocalStoragesService,
    private mainLayoutService: MainLayoutService) {
  }

  private setHeader() {
    const user = UserCache.getLoginUser();
    if (user !== null) {
      this.token = user.AuthToken;
      //this.companyCode = user.CompanyCode;
      //this.clinicId = user.ClinicId;
    }
  }
  // 產生web api service，並依據設定檔設定his(本系統) web api的host, contentType, xDevKey參數
  createHisService(basePath: string): WebApiService {
    // load config from BaseConfig
    this.config = BaseConfig.getConfig();
    this.setHeader();

    const apiService = new WebApiService(this.http, this.mainLayoutService);
    var localinfo: ConsoleInfo = this.localStorage.getItem('ConsoleInfo') ?? {}
    apiService.baseUrl = this.config.webApi.hisBaseUrl;
    apiService.basePath = basePath;
    apiService.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': this.config.webApi.hisXDevKey,
        'Authorization':  'Bearer ' +this.token,
        'ComputerName':localinfo?.ComputerName??'Unknown',
        //'Token': this.token,
        //'CompanyCode': this.companyCode,
        //'ClinicId': this.clinicId
      }),
      params: {},
    };
    return apiService;
  }
  // 產生web api service，並依據設定檔設定his(本系統) web api的host, contentType, xDevKey參數
  createDentistService(basePath: string): WebApiService {
    // load config from BaseConfig
    this.config = BaseConfig.getConfig();
    this.setHeader();

    const apiService = new WebApiService(this.http, this.mainLayoutService);
    var localinfo: ConsoleInfo = this.localStorage.getItem('ConsoleInfo') ?? {}
    apiService.baseUrl = this.config.webApi.dentistBaseUrl;
    apiService.basePath = basePath;
    apiService.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': this.config.webApi.hisXDevKey,
        'Authorization':  'Bearer ' +this.token,
        'ComputerName':localinfo?.ComputerName??'Unknown',
        //'Token': this.token,
        //'CompanyCode': this.companyCode,
        //'ClinicId': this.clinicId
      }),
      params: {},
    };
    return apiService;
  }
  // 產生web api service，並依據設定檔設定his(本系統) web api的host, contentType, xDevKey參數
  createSSOService(): WebApiService {
    // load config from BaseConfig
    this.config = BaseConfig.getConfig();
    this.setHeader();

    const apiService = new WebApiService(this.http, this.mainLayoutService);
    
    apiService.baseUrl = this.config.webApi.visionAuthUrl;
    apiService.basePath = 'api/Auth';
    apiService.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': this.config.webApi.hisXDevKey,
      }),
      params: {},
    };
    return apiService;
  }
  // 產生一個web api service，沒有設定任何host、DevKey等參數
  createService() {
    return new WebApiService(this.http, this.mainLayoutService);
  }

  // createLocalPrintService(basePath: string): WebApiService {
  //   // load config from BaseConfig
  //   this.config = BaseConfig.getConfig();
  //   this.setHeader();

  //   const apiService = new WebApiService(this.http, this.mainLayoutService);
  //   apiService.baseUrl = this.config.localPrint.baseUrl;
  //   apiService.basePath = basePath;
  //   apiService.httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'X-DEVKEY': this.config.webApi.hisXDevKey
  //     }),
  //     params: {}
  //   };

  //   return apiService;
  // }

}
