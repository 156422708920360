import { group } from '@angular/animations';
/** 醫療院所層級 Enum */
export enum NHILevelEnum {
  /** 西醫(醫學中心) */
  A,
  /** 西醫(區域醫院) */
  B,
  /** 西醫(地區醫院) */
  C,
  /** 西醫(基層院所) */
  D,
  /** 牙醫(地區醫院) */
  G,
  /** 牙醫(基層院所) */
  H,
  /** 西醫基層-山地離島地區 */
  L,
  /** 中醫(地區醫院之中醫或中醫醫院) */
  R,
  /** 中醫(基層院所) */
  S,
  /** 藥局 */
  U,
}
//group 用來判斷 1.西醫、2.牙醫、3.中醫、4.藥局
export const NHILevelDesc: { text: string, value: string, group: number }[] =
  [{ text: '西醫(醫學中心)', value: 'A', group: 1 },
  { text: '西醫(區域醫院)', value: 'B', group: 1 },
  { text: '西醫(地區醫院)', value: 'C', group: 1 },
  { text: '西醫(基層院所)', value: 'D', group: 1 },
  { text: '牙醫(地區醫院)', value: 'G', group: 2 },
  { text: '牙醫(基層院所)', value: 'H', group: 2 },
  { text: '西醫基層-山地離島地區', value: 'L', group: 1 },
  { text: '中醫(地區醫院之中醫或中醫醫院)', value: 'R', group: 3 },
  { text: '中醫(基層院所)', value: 'S', group: 3 },
  { text: '藥局', value: 'U', group: 4 }];

