import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';

@Pipe({
  name: 'TextPadStart'
})
export class TextPadStartPipe implements PipeTransform {

  transform(value: any, maxLength: number, fillStr: string): string {
    return (value.toString() as string).padStart(maxLength, fillStr);
  }

}

@Pipe({
  name: 'TextPadEnd'
})
export class TextPadEndPipe implements PipeTransform {

  transform(value: any, maxLength: number, fillStr: string): string {
    return (value.toString() as string).padEnd(maxLength, fillStr);
  }
}


@Pipe({
  name: 'NumberFixed'
})
export class NumberFixedPipe implements PipeTransform {

  transform(value: any, digits: number): string {
    if(value){
      return (value as number).toFixed(digits);
    }
    return value;
  }
}

@Pipe({
  name: 'NumberThousandFixed'
})
export class NumberThousandFixedPipe implements PipeTransform {

  transform(value: any): string {
    if (value) {
      const parts = value.toString();
      return parts.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,',');
    }
    return value;
  }
}
