import { EventEmitter, Injectable } from "@angular/core";
/** 功能鍵 */
export declare type FnKey = 'F1' | 'F2' | 'F3' | 'F4' | 'F5' | 'F6' | 'F7' | 'F8' | 'F9' | 'F10' | 'F11'
/** 組合鍵類型 */
export declare type CombineType = 'Fn'|'Ctrl' | 'Alt' | 'None';
/* 數字越小越優先，為了避免可能會插入其他的級別，所以保留10個間距 */
export enum Priority{
    /** 批價 */
    Cashier = 10,
    /** 病歷(看診) */
    Hist = 15,
    Hight = 100,
    Normal = 200,
    Low = 300
}
@Injectable({
    providedIn: 'root'
})
export class HotKeyService {
    private blockedFnKey = ['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11']
    private allowCtrlKey = ['A','C', 'V', 'X']
    private fnId = 0;
    private fnIdMap: Map<number,{priority:Priority,combineType:CombineType}> = new Map();
    private onKeyPressFn: Map<Priority,{id:number,fn:(k: FnKey)=>void}[]> = new Map();
    private onKeyPressAlt: Map<Priority,{id:number,fn:(k: string)=>void}[]> = new Map();
    private onKeyPressCtrl: Map<Priority,{id:number,fn:(k: string)=>void}[]> = new Map();
    private onKeyPress: Map<Priority,{id:number,fn:(k: string)=>void}[]> = new Map();
    /**
     *
     */
    constructor() {
        // keyup處理熱鍵
        window.onkeyup = (evt: KeyboardEvent) => {
            if (this.blockedFnKey.includes(evt.key)) {
                this.handlePrioriry(this.onKeyPressFn,evt.key as FnKey);
            }else if(evt.altKey){
                this.handlePrioriry(this.onKeyPressAlt,evt.key);
            }else if(evt.ctrlKey){
                if(this.allowCtrlKey.includes(evt.key.toUpperCase())){
                    return;
                }
                this.handlePrioriry(this.onKeyPressCtrl,evt.key);
            }else{
                this.handlePrioriry(this.onKeyPress,evt.key);
            }
        }
        // key down擋預設行為
        window.onkeydown = (evt: KeyboardEvent) => {
            if (this.blockedFnKey.includes(evt.key)) {
                evt.preventDefault();
                
            }else if(evt.altKey){
                evt.preventDefault();
                
            }else if(evt.ctrlKey){
                if(this.allowCtrlKey.includes(evt.key.toUpperCase())){
                    return;
                }
                evt.preventDefault();
            }
        }

    }
    regist(priority:Priority,type:CombineType, fn:((k:string)=>void) | ((k:FnKey)=>void)): number{
        var fId =  this.handleResist(priority, this.getMapByType(type),fn);
        this.fnIdMap.set(fId,{priority:priority,combineType:type});
        return fId;
    }
    unRegist(fnId:number){
        var fnIdRecord = this.fnIdMap.get(fnId);
        if(fnIdRecord){
            return this.handleUnregist(fnIdRecord.priority, this.getMapByType(fnIdRecord.combineType),fnId);
        }
    }
    public triggerFnKey(key:FnKey){
        this.handlePrioriry(this.onKeyPressFn,key);
    }
    private getMapByType(type:CombineType){
        switch(type){
            case 'Fn':
                return this.onKeyPressFn;
            case 'Alt':
                return this.onKeyPressAlt;
            case 'Ctrl':
                return this.onKeyPressCtrl;
            case 'None':
                return this.onKeyPress;
        }
    }
    private handlePrioriry<T>(registMap:Map<Priority,{id:number,fn:((k:T)=>void)}[]>,key: T){
        // 取得優先序
        var order = [];
        for(var p in Priority){
            if(/\d/.test(p)){
                var v: Priority = parseInt(p);
                order.push(v);
            }
        }
        // 排序 小到大
        order = order.sort();
        for(var prio of order){
            var fnList = registMap.get(prio);
            // 有層級中有註冊的方法就執行並掠過優先度以下的層級
            if(fnList?.length>0){
                fnList.forEach(fnItem=>fnItem.fn(key))
                break;
            }
        }
    }
    private handleResist<T>(priority:Priority,registMap:Map<Priority,{id:number,fn:T}[]>, fn:T): number{

        var fnList = registMap.get(priority);
        if(!fnList){
            fnList = [];
            registMap.set(priority,fnList);
        }

        var exist = fnList.find(f=>f.fn == fn);
        if(!exist){
            fnList.push({id:++this.fnId,fn: fn});
            return this.fnId;
        }else{
            return exist.id;
        }
    }
    private handleUnregist(priority:Priority,registMap:Map<Priority,{id:number,fn:any}[]>,fnId:number){
        var fnList = registMap.get(priority);
        var existIdx = fnList?.findIndex(f=>f.id == fnId);
        if(existIdx>=0){
            fnList.splice(existIdx,1);
        }
    }
}
