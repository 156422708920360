import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-search',
  template: `<svg class="{{cssName}}" alt="Search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style="transform:scale(1)" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" fill="#000"></path></svg>`,
  styles: [`
  svg{
    width: 15px;
    height: 15px;
  }
  svg.sky-blue path{
    fill: #06c;
  }
  `]
})
export class IconSearchComponent implements OnInit {
  @Input() cssName?:string ='';

  constructor() { }

  ngOnInit(): void {
  }

}
