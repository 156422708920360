import { SeqNumber } from '../../../shared/hcr-lib/SeqNumber';



export class FamilyCareDto {
    SeqNo : number;
    ClinicId: number;
    ClinicCode:string;
    PatientId: number;
    PatientNo:string;
    CaseDate: Date;  
    CId: string;
    CName: string;
    Phone: string;
    DoctorCId: string;
    DoctorName: string;

    MemberType: string;
    CaseType: string;
    CloseDate?: Date;
    CloseReason: string;
    P5001Date: Date;
    IsAtPeriod: boolean; 
    IsEnabled :boolean;
}


export class FamilyCareMutliDto {

    FamilyCareList: FamilyCareDto[] =[];
    Total: number;
    IsOld = false;
}