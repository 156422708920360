<div class="row narrow-padding">
  <div class="col-sm-8">
    <div class="card" [attr.disabled]="allDisabled">
      <div class="card-header">
        <form [formGroup]="queryFormGroup" class="c-form-inline">
          <div>
            <label class="c-form-field">
              <span i18n="@@ContractType">類型</span>
              <app-vis-dropdownlist formControlName="contractType" [data]="editOptions.contractAllOptions"
                [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                (valueChange)="selectContract($event)">
              </app-vis-dropdownlist>
            </label>
            <label class="c-form-field">
              <span i18n="@@PatientNo">病歷號</span>
              <input class="vis-input" formControlName="patientNo" />
            </label>
            <label class="c-form-field">
              <span i18n="@@PatientCid">身分證號</span>
              <input class="vis-input" formControlName="patientCid" maxlength="10" />
            </label>
          </div>
          <div>
            <div class="c-form-field">
              <span i18n="@@Condition">狀況查詢</span>
              <input type="radio" formControlName="condition" id="condCustom" value="custom" class="k-radio">
              <label class="k-radio-label" for="condCustom" i18n="@@Custom">自行設定</label>
              <input type="radio" formControlName="condition" id="condEffective" value="effective" class="k-radio">
              <label class="k-radio-label" for="condEffective" i18n="@@Effective">有效</label>
              <input type="radio" formControlName="condition" id="condExpired" value="expired" class="k-radio">
              <label class="k-radio-label" for="condExpired" i18n="@@Expired">已過期</label>
              <input type="radio" formControlName="condition" id="condWillExpire1" value="willExpire1" class="k-radio">
              <label class="k-radio-label" for="condWillExpire1" i18n="@@ExpiredTheMonth">這個月到期</label>
              <input type="radio" formControlName="condition" id="condWillExpire2" value="willExpire2" class="k-radio">
              <label class="k-radio-label" for="condWillExpire2" i18n="@@ExpiredNextMonth">下個月到期</label>
            </div>
          </div>
          <div>
            <label class="c-form-field">
              <span i18n="@@Status">狀態</span>
              <app-vis-dropdownlist formControlName="status" [data]="editOptions.contractStatusOptions2"
                [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true"
                (valueChange)="onStatusChange($event)">
              </app-vis-dropdownlist>
            </label>
          </div>
          <div style="display:inline-block;">
            <div class="c-form-field">
              <span i18n="@@StartDate">起始日期</span>
              <app-vis-datepicker formControlName="startDate1" ></app-vis-datepicker> ~
              <app-vis-datepicker formControlName="startDate2" ></app-vis-datepicker>
            </div>
            <div class="c-form-field">
              <span i18n="@@EndDate">結束日期</span>
              <app-vis-datepicker formControlName="endDate1" ></app-vis-datepicker> ~
              <app-vis-datepicker formControlName="endDate2" ></app-vis-datepicker>
            </div>
          </div>
          <div class="float-right">
            <!-- <button type="button" (click)="onBtnAddClick()" i18n="@@Add" [disabled]="!selectedContractType">Add</button> -->
            <button type="button" (click)="onBtnAddContract()" i18n="@@Add">新增</button>
            <button type="button" (click)="onBtnQueryClick()" i18n="@@Query">查詢</button>
          </div>
        </form>
      </div>
      <!--./card-header-->
      <div class="card-body no-padding">
        <app-vis-grid [data]="gridView" [attr.disabled]="allDisabled" [pageSize]="pageSize" [skip]="pageSkipNo"
          [pageable]="true" (pageChange)="pageChange($event)" [selectable]="{mode: 'single'}"
          [kendoGridSelectBy]="'Id'" [selectedKeys]="selectedItems" (cellClick)="gridCellClickHandler($event)" class="no-border">
          <app-vis-grid-column field="ContractType" title="合約類型" width="100" i18n-title="@@ContractType">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientNo" title="病歷號" width="85" i18n-title="@@PatientNo">
          </app-vis-grid-column>
          <app-vis-grid-column field="PatientName" title="姓名" min-width="160" i18n-title="@@PatientName">
          </app-vis-grid-column>
          <app-vis-grid-column field="Sex" title="性別" width="60" i18n-title="@@Sex">
          </app-vis-grid-column>
          <app-vis-grid-column field="Phone" title="手機" min-width="140" i18n-title="@@Phone">
          </app-vis-grid-column>
          <app-vis-grid-column field="ContractStatus" title="狀態" width="60" i18n-title="@@ContractStatus">
          </app-vis-grid-column>
          <app-vis-grid-column field="StartDate" title="起始日期" [width]="100" i18n-title="@@StartDate">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.StartDate | formatDate:''">{{ dataItem.StartDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column field="EndDate" title="結束日期" [width]="100" i18n-title="@@EndDate">
            <ng-template kendoGridCellTemplate let-dataItem>
              <div class="ellipsis-content" [title]="dataItem.EndDate | formatDate:''">{{ dataItem.EndDate | formatDate:'' }}</div>
            </ng-template>
          </app-vis-grid-column>
          <app-vis-grid-column title="" width="60">
            <ng-template kendoGridCellTemplate let-dataItem>
              <button type="button" (click)="onBtnEditClick($event,dataItem)" i18n="@@Edit">編輯</button>
              <!-- <button type="button" (click)="onBtnDeleteClick(dataItem)" i18n>Delete</button> -->
            </ng-template>
          </app-vis-grid-column>
        </app-vis-grid>
      </div>
      <!--./card-body-->
    </div>
    <!--./card-->
  </div>
  <div class="col-sm-4">
    <app-patients-contract-edit [editOptions]="editOptions" [queryContractType]="queryContractType"
      [selectedPatientId]="selectedPatientId" [selectedPatientName]="selectedPatientName" [isAddMode]="isAdd"
      (onUpdated)="onEditUpdated()" (emitEnterEdit)="onChildEnterEdit()" (emitEnterView)="onChildEnterView()">
    </app-patients-contract-edit>
  </div>
  <!--搜尋病患視窗-->
  <app-patients-patient-query-dialog [isDialogOpened]="isPatientDialogOpened"
    (onEmitResult)="onPatientDialogClose($event)">
  </app-patients-patient-query-dialog>
</div>
