import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { DeclareClassDesc } from 'src/app/enums/DeclareClassEnum';
import { DeclareWayDesc } from 'src/app/enums/DeclareWayEnum';
import { NHILevelDesc } from 'src/app/enums/NHILevelEnum';
import { DeclareApi, DeclareCaseReportDTO } from 'src/app/services/api-service/declare/declare-api';
import { ReserveReceiptPrint } from 'src/app/services/api-service/register/register-api';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';

@Component({
  selector: 'app-reserve-receipt',
  templateUrl: './reserve-receipt.component.html',
  //styleUrls: ['./reserve-receipt.component.css']
  styles: [`
        .reserve-receipt-styles table {
          width: 100%; font-size: 10pt; text-align: center;
        }
        .reserve-receipt-styles td {
          // line-height: 22px;
          padding: 2px 0;
        }
        .reserve-receipt-styles .border-solid {
            border: 1px solid;
        }
        .reserve-receipt-styles .border-solid td {
            border-bottom: 1px solid;
            border-right: 1px solid;
        }
        .reserve-receipt-styles .border-solid td:last-child {
            border-right: 0;
        }
        .reserve-receipt-styles .reserve-receipt-styles .border-solid td.border-right {
            border-right: 1px solid;
        }
        .reserve-receipt-styles .border-solid tr:last-child td {
            border-bottom: 0;
        }
        .reserve-receipt-styles .width-quarter {
          width: 50%!important;
        }
        .reserve-receipt-styles .border-right-0 {
          border-right: 0;
        }
        .reserve-receipt-styles .border-left-0 {
          border-left: 0;
        }
        .reserve-receipt-styles .border-0 {
          border: 0;
        }
        .reserve-receipt-styles .border-top-0 {
          border-top: 0;
        }
        .reserve-receipt-styles .print-title{
          font-size: 12pt;
          // height: 40px;
          // line-height: 30px;
        }
        .w-contact{
          word-break: break-all; width:1rem;
        }`]
      ,'encapsulation':ViewEncapsulation.None
})
export class ReserveReceiptComponent implements OnInit {
  SummaryData: [];
  /** 申報方式下拉選單Data - 從Enum來(後端定義好，自動生成前端相關Enum.ts) */
  declareData = DeclareWayDesc;
  /** 申報類別下拉選單Data - 從Enum來(後端定義好，自動生成前端相關Enum.ts) */
  declareClassData = DeclareClassDesc;
  /** 醫療院所層級下拉選單Data*/
  NHILevelData = NHILevelDesc;

  constructor(private declareApi: DeclareApi,
    private localPrintService: LocalPrintService,
    private cdr:ChangeDetectorRef,
    private hcrService:HcrService,
    private notification: EasyNotificationService) {
  }

  someNum:number = 1108495;
  sizePage = {
    // width: 28.7, //cm
    // height: 20 //cm
    width: 10, //cm
    height: 14 //cm
  };
  paddingPage = {
      top: -0.5, //cm
      right: 0, //cm
      bottom: 0.2, //cm
      left: 0 //cm
  };

  pages:number = 3;

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer;            // 整份報表的html內容
  anchorsBlockValue;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;

  @Input() data:ReserveReceiptPrint;

  /** 是否列印 */
  @Input()
  set isTestPrint(value) {
    if (value) {
      this.onPrint();
    }
  }

  @Input()
  set isExportAsExcel(value) {
    if (value) {
      this.exportAsExcel();
    }
  }

  /** 換頁=>指定換到第幾頁 */
  @Input() set pageNum(val) {
    this.goToPage(val);
  }
  @Output() DataTotalPageEvent = new EventEmitter();

  upperboundIndex: number = 0;
  Data: DeclareCaseReportDTO[];
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  declareMonthValue = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
  isByDoctor = false;
  doctorName: string = '';
  dataSource: any;

  date: Date = new Date();

  remark:string = '';
  /** 有過敏藥 */
  hasDrugAllergy: boolean;

  hasRemark: boolean;

  ngOnInit(): void {
    //以下是列印到pdf用的參數, html是預覽的參數
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}\n' +
      '.table-print{font-size:10pt;width: 95mm}\n' +
      '.w-100-print.table-print{font-size:10pt;width: 95mm}\n' +
      // '.pres-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.pres-report table{ border: 0; }\n' +
      // '.pres-report table td, table th { border: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      // '.pres-report table td, table th { border: 1px solid black;}\n' +
      '.pres-report table td, table th { border: 0;}\n' +
      // '.pres-report table tr:first-child th { border-top: 0; }\n' +
      // '.pres-report table tr:last-child td { border-bottom: 0; }\n' +
      // '.pres-report table tr td:first-child,.pres-report table tr th:first-child { border-left: 0; }\n' +
      // '.pres-report table tr td:last-child,.pres-report table tr th:last-child { border-right: 0; }\n' +
      '.pres-report table tr:first-child td { border-bottom: 1px solid black; }\n' +
      '.print-title { font-size: 12pt; }\n' +
      '.container {height: 100%;width: 100%;}\n' +
      '.page {display: block;margin: 40px auto;box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);box-sizing: border-box;}\n' +
      '.page .content {overflow: auto;outline: 0;}\n' +
      '.page .content .block {border: 1px solid rgba(0, 0, 0, 0);padding: 0.25rem;cursor: default;}\n' +
      '.page .content .block:hover {border: 1px solid #dee2e6;border-radius: 0.25rem;}\n' +
      '.page .content .block .title {font-weight: bold}\n' +
      '.page .content .block .title {cursor: text;}\n' +
      '.width-quarter-print {width: 50%;}\n' +
      '.border-right-0 {border-right: 0}\n' +
      '.border-left-0 {border-left: 0}\n' +
      '.a4-print-style {width: 210mm;height: 297mm;border: 1px solid #474647;background: white;}\n' +
      '.a4-print-style-h {width: 307mm;height: 210mm;border: 1px solid #474647;background: white;}\n' +
      '.a4-print-style-4 {width: 210mm;height: 74mm;border: 1px solid #474647;background: white;}\n' +
      '.a6-print-style {width: 105mm;height: 148mm;border: 1px solid #474647;background: white;}\n' +
      '.h-auto {height: auto}\n' +
      '.m-auto {margin: auto}\n' +
      '.start-0 {left: 0;}\n' +
      '.end-0 {right: 0;}\n' +
      '.pl-2, .px-2 {padding-left: 0.5rem}\n' +
      '.pr-2, .px-2 {padding-right: 0.5rem}\n' +
      '.m-0 {margin: 0}\n' +
      '.d-block {display: block}\n' +
      '.bg-white {background-color: #fff}\n' +
      '.font-weight-bolder {font-weight: bolder}\n' +
      '.text-center {text-align: center}\n' +
      '.mt-2, .my-2 {margin-top: 0.5rem}\n' +
      '.w-100-print {min-width: 100%}\n' +
      '.text-lg {font-size: 1.125rem }\n' +
      '.text-right {text-align: right}\n' +
      '.text-left {text-align: left}\n' +
      '.multiLine-pre-line {white-space: pre-line;}\n' +
      '.measure-auto {position:absolute;margin:auto;top:4mm;bottom:0}\n' +
      '.measure-print {height:16mm}\n' +
      '.measure-print-one {}\n' +
      '.measure-print-one.measure-auto {top:0mm}\n' +
      '.lh-xlg {line-height: 10mm }\n' +
      '.max-w-20 {max-width: 10mm }\n' +
      '.body-measure-left{max-width: 24mm; display:inline-block!important}\n' +
      '.body-measure-right{max-width: calc(100% - 24mm); display:inline-block!important;position:absolute; left:24mm}\n' +
      '.measure-print-one .body-measure-right{position:relative; left:0}\n' +
      '.measure-print-one .measure-auto{position:relative;top:initial;bottom:initial;}\n' +
      '.flex-grow-one {flex-grow: 1 }\n' +
      'svg{width: 4mm;height: 4mm;}\n' +
      '.w-contact {word-break: break-all; width:1.5mm;}\n' +
      '.date-space-print {padding-right:4mm }\n' +
      '@page { size: A6; margin: 0.5cm 0.5cm; }'

      // '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
      // '.reg-report table{ border:1px solid black; border-collapse: collapse;width:calc(100% - 40px);margin:5px 20px; }\n'+
      // '.reg-report table td, table th { border: 1px solid black;padding: 5px;vertical-align: baseline;}\n'+
      // '.reg-report table tr:first-child th { border-top: 0; }\n'+
      // '.reg-report table tr:last-child td { border-bottom: 0; }\n'+
      // '.reg-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n'+
      // '.reg-report table tr td:last-child, table tr th:last-child { border-right: 0; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);

    if (this.data?.Remark && this.data?.Remark.length>0) {
      this.hasRemark = true;
    } else {
      this.hasRemark = false;
    }
  }


  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }

  // 組報表的html內容(this.Data)
  insertListData() {
    var html_block = "";
    var iPage = 0;
    var iBlock = 0;
    // var html_ListerHeader = this.createHTMLListHeader();  // List欄位顯示名稱
    // var html_finish = this.createHTMLFinish();            // 報表結束 -- 以下空白
    var prevTableContent: any;
  }
  /** 多Page換頁 */
  goToPage(val) {
    var result = this.dataSource;
    if (result != null && result.length > 0) {
      let idx = val - 1;
      idx = idx > 0 ? idx : 0;
      this.Data = result[idx].Detail;
      this.doctorName = result[idx].DrName;
    }
  }

  getHtml() {
    if(this.reportContainer){
      var html = this.reportContainer.nativeElement.innerHTML;
      for (var i = 0; i <= this.upperboundIndex; i++) {
        //去除邊框, 實際印出到pdf
        html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
        html = html.replace('box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0.15cm;','');
        html = html.replace('box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);','');
        html = html.replace('box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 0.5cm;','');
      }
      return html;
    }
  }
  GetDateString(val: string) {
    if (val && val.length >= 5) {
      const y = val.substring(0, 3);
      const m = val.substring(3, 5);
      if (val.length == 5) {
        return y + "年" + m + "月";
      } else if (val.length == 7) {
        const d = val.substring(5, 7);
        return y + "年" + m + "月" + d + "日";
      }
    }
    return val;
  }

  /** 單張列印 */
  async onPrint() {
    if (this.dataSource.length > 0) {
      var data = this.dataSource;
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(101, '掛號預約單', printContents);  // 'Declare Case Statistics'
      if (ret.Successed) {
        this.notification.showSuccess('掛號預約單 已送出列印!', true);
      } else {
        this.notification.showError('掛號預約單 送出列印失敗!');
      }
      setTimeout(() => {
        this.DataTotalPageEvent.emit({ totalPage: data.length, currentPage: data.length, isPrint: false });
      }, 1000);
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    const reportName = '掛號預約單_' + this.monthStr;
    if (this.dataSource.length > 0) {
        const table = this.reportContainer.nativeElement;
        await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)

        // const uri = 'data:application/vnd.ms-excel;base64,';
        // const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
        //                     <head>
        //                         <!--[if gte mso 9]>
        //                         <xml>
        //                             <x:ExcelWorkbook>
        //                                 <x:ExcelWorksheets>
        //                                     <x:ExcelWorksheet>
        //                                         <x:Name>{worksheet}</x:Name>
        //                                         <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
        //                                     </x:ExcelWorksheet>
        //                                 </x:ExcelWorksheets>
        //                             </x:ExcelWorkbook>
        //                         </xml>
        //                         <![endif]-->
        //                     </head>
        //                     <body>
        //                         <table>{table}</table>
        //                     </body>
        //                     </html>`;
        // const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        // const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        // const table = this.reportContainer.nativeElement;
        // const ctx = { worksheet: reportName, table: table.innerHTML };

        // var fileName = reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`
        // var content = format(template, ctx);
        // var ret =await this.hcrService.SaveTextAsZipIfPwd(reportName+'\\'+fileName,content)
        // await this.hcrService.ConfirmOpenDir('下載完成','檔案已下載至'+ret.Returns,ret.Returns);

        // const link = document.createElement('a');
        // link.download = reportName + `_` + this.onGetTimeString(new Date(), '') + `.xls`;
        // link.href = uri + base64(format(template, ctx));
        // link.click();
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
  }

  /** 多頁列印 */
  async onPrintAll() {
    if (this.dataSource.length > 0) {
      var data = this.dataSource;
      var h = '';
      for (let index = 0; index < data.length; index++) {
        var rs;
        var p = new Promise((a, b) => { rs = a; });
        this.goToPage(index+1);
        setTimeout(() => {
          h += this.getHtml();
          rs();
        }, 0);
        await p;
      }
      var ret = await this.localPrintService.printHtml(444, 'Declare Case Statistics', h);
      if (ret.Successed) {
        this.notification.showSuccess('掛號預約單 已送出列印!', true);
      } else {
        this.notification.showError('掛號預約單 送出列印失敗!');
      }
      setTimeout(() => {
        this.DataTotalPageEvent.emit({ totalPage: data.length, currentPage: data.length, isPrint: false });
      }, 1000);
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  printBasicData:ReserveReceiptPrint;
  async setData(data:ReserveReceiptPrint){

    this.printBasicData = data;
    // console.log('data',this.printBasicData);
    this.cdr.markForCheck();
    setTimeout(() => {
      var hasSt = false;
      for(let i = 0; i< this.reportContainer.nativeElement.children.length; i++){
        var el = this.reportContainer.nativeElement.children[i];
        if(el.tagName.toLowerCase() == 'style') {
          hasSt=  true;
          break;
        }
      }
      if(!hasSt){
        var st = document.createElement('style');
        st.appendChild(document.createTextNode(
          '@page { size: A4 landscape; margin:0cm; }\n'+
          '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n'+
          '.pres-report table{ border:1px solid black; border-collapse: collapse;font-size:12px; }\n'+
          '.pres-report table td,.pres-report table th { border: 1px solid black; padding-left: 2px;padding-right: 2px;}\n'+
          '.pres-report table tr:first-child th { border-top: 0; }\n'+
          '.pres-report table tr:last-child td { border-bottom: 0; }\n'+
          '.pres-report table tr td:first-child,.pres-report table tr th:first-child { border-left: 0; }\n'+
          '.pres-report table tr td:last-child,.pres-report table tr th:last-child { border-right: 0; }'
        ));
          this.reportContainer.nativeElement.appendChild(st);

      }
      this.cdr.markForCheck();
    }, 0);
  }

  getYearZero(v: Date | null): string {
    if (v instanceof Date && v.getUTCFullYear() < 2011) {
        return '0';
    } else {
        return '';
    }
  }

}
