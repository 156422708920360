<!-- app-dual-list-selector.component.html -->
<div class="dual-list-selector-container">
  <div class="grid-container" [ngStyle]="{'width': content.leftGrid.gridSetting.width}">
    <!-- <ng-container *ngTemplateOutlet="leftSelectTmp"></ng-container> -->
    <ng-content select="[leftSearchBar]"></ng-content>

    <app-vis-grid *ngIf="content && content.leftGrid.data" [showMask]="isShowLeftGirdLoading" [data]="content.leftGrid.data"
      [pageSize]="content.settings.pageSize" [skip]="leftGridSkip" [pageable]="content.settings.pageable"
      (pageChange)="leftGridChange($event)" [multiselect]="content.settings.multiselect"
      [kendoGridSelectBy]="content.leftGrid.gridSetting.kendoGridSelectBy"
      [selectedKeys]="content.leftGrid.gridSetting.selectedKeys">
      <app-vis-grid-checkbox-column [field]="content.leftGrid.gridSetting.checkBoxKey" showSelectAll="true" [width]="40" style="vertical-align: middle;" class="text-center">
      </app-vis-grid-checkbox-column>
      <ng-container *ngFor="let column of content.leftGrid.columnSetting">
        <app-vis-grid-column [field]="column.field" [title]="column.title" [width]="column.width"
          [i18n-title]="column.i18n">
        </app-vis-grid-column>
      </ng-container>
    </app-vis-grid>
  </div>

  <div class="op-area" [ngStyle]="{'width': getOpAreaWidth()}"
    [hidden]="content.settings.isExpandLeft||content.settings.isExpandRight">
    <button type="button"
      (click)="content.settings.leftMoveSetting.onMove(content.leftGrid.data,content.leftGrid.gridSetting.selectedKeys)"
      [disabled]="!content.settings.leftMoveSetting.isEnable"
      class="mat-icon-button mat-calendar-next-button m-1"></button>
    <button type="button"
      (click)="content.settings.rightMoveSetting.onMove(content.rightGrid.data,content.rightGrid.gridSetting.selectedKeys)"
      [disabled]="!content.settings.rightMoveSetting.isEnable"
      class="mat-icon-button mat-calendar-previous-button m-1"></button>
  </div>



  <div class="grid-container" [ngStyle]="{'width': content.leftGrid.gridSetting.width}">
    <!-- <ng-container *ngTemplateOutlet="rightSelectTmp"></ng-container> -->
    <ng-content select="[rightSearchBar]"></ng-content>
    <app-vis-grid *ngIf="content && content.leftGrid.data" [showMask]="isShowRightGirdLoading" [data]="content.rightGrid.data"
      [pageSize]="content.settings.pageSize" [skip]="rightGridSkip" [pageable]="content.settings.pageable"
      (pageChange)="rightGridChange($event)" [multiselect]="content.settings.multiselect"
      [kendoGridSelectBy]="content.rightGrid.gridSetting.kendoGridSelectBy"
      [selectedKeys]="content.rightGrid.gridSetting.selectedKeys">
      <app-vis-grid-checkbox-column [field]="content.rightGrid.gridSetting.checkBoxKey" showSelectAll="true" [width]="40" style="vertical-align: middle;"
        class="text-center">
      </app-vis-grid-checkbox-column>
      <ng-container *ngFor="let column of content.rightGrid.columnSetting">
        <app-vis-grid-column [field]="column.field" [title]="column.title" [width]="column.width"
          [i18n-title]="column.i18n">
        </app-vis-grid-column>
      </ng-container>
    </app-vis-grid>
  </div>
</div>
