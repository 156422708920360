<div class="col-sm-12 card">
  <form [formGroup]="queryFormHelper.FormGroup" class="c-form-inline">
    <app-search-bar>
      <input class="vis-input" [formControlName]="queryFormHelper.FieldName.ApplyCode" searchBarField [fieldTitle]="'標準碼'" maxlength="20" />
      <input class="vis-input" [formControlName]="queryFormHelper.FieldName.Code" searchBarField [fieldTitle]="'院內碼'" />
      <input class="vis-input" [formControlName]="queryFormHelper.FieldName.CName" searchBarField [fieldTitle]="'名稱'" maxlength="20"/>
      <mat-checkbox [formControlName]="queryFormHelper.FieldName.FilterContainsPCSx">過濾已選</mat-checkbox>

      <button type="button"  (click)="handleSelect()" i18n="@@Add"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="選入">
        <mat-icon class="pref-icon add vis-combine-icon">add_circle</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">選入</span>
      </button>
      <button type="submit" (click)="handleQuery()" i18n="@@Query"
        class="vis-btn-config p-0 position-relative pl-2 w-18" title="查詢">
        <mat-icon class="pref-icon add vis-combine-icon">search</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">查詢</span>
      </button>
    </app-search-bar>
    <div class="clearfix"></div>
  </form>

  <div class="row" style="margin-top:1vh">
      <app-vis-grid [data]="topGridData" [pageSize]="pageSize" [skip]="topPageSkip" [pageable]="true"
        (pageChange)="topPageChange($event)" [kendoGridSelectBy]="'Code'" #topGrid>
      <app-vis-grid-checkbox-column [field]="'IsChecked'" [showSelectAll]="false" [width]="20" style="vertical-align: middle;" class="text-center"></app-vis-grid-checkbox-column>
      <app-vis-grid-column [field]="'Code'" [title]="'院內碼'" [width]="40" i18n-title="'@@Code'"></app-vis-grid-column>
      <app-vis-grid-column [field]="'CName'" [title]="'名稱'" [width]="130" i18n-title="'@@CName'"></app-vis-grid-column>
      <app-vis-grid-column [field]="'ApplyCode'" [title]="'標準碼'" [width]="40"
        i18n-title="'@@ApplyCode'"></app-vis-grid-column>
    </app-vis-grid>
  </div>

  <div class="row" style="margin-top:1vh">
    <app-vis-grid [data]="bottomGridData" [pageSize]="pageSize" [skip]="bottomPageSkip" [pageable]="true"
      (pageChange)="bottomPageChange($event)" [kendoGridSelectBy]="'Code'" #bottomGrid>
      <app-vis-grid-checkbox-column [showSelectAll]="false" [width]="20"  style="vertical-align: middle;" class="text-center"></app-vis-grid-checkbox-column>
      <app-vis-grid-column [field]="'Code'" [title]="'標準碼'" [width]="40" i18n-title="'@@Code'"></app-vis-grid-column>
      <app-vis-grid-column [field]="'CName'" [title]="'名稱'" [width]="130" i18n-title="'@@CName'"></app-vis-grid-column>
    </app-vis-grid>
  </div>
</div>
