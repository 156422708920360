import { Entity } from "src/app/shared/models/entity";

export class VitalSign extends Entity {
  Id: number;
  PatientId: number;
  VSDateTime: Date;
  Height: number;
  Weight: number;
  BT: number;
  Head: number;
  Pulse: number;
  SBP: number;
  DBP: number;
  LMPDate: Date;
  DueDate: Date;
  IsPregnant: boolean;

  // extend properties
  isFemale = false;
  birthday = new Date();
  //VitalSignDisplay: string;

  // #region calc --------------------



  // #endregion calc --------------------
}
