import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MatCalendar, MatCalendarCell } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Moment } from 'moment';

/** Custom header component for datepicker. */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class MonthPickerCalendarHeader<D> implements OnDestroy, OnInit {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef,
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => {
      //cdr.markForCheck();
      setTimeout(() => {
        if (_calendar.currentView == 'multi-year') {
          this.updateMultiYearInit();
        }
        this.updateLabel();
      }, 0);
    });
  }

  get activeDate(): Moment {
    return this._calendar.activeDate as any;
  }
  get currentView() {
    return this._calendar.currentView;
  }
  get multiYearView() {
    return this._calendar.multiYearView;
  }

  ngOnInit(): void {
    this.updateLabel();
    // 選年份後把月份一起帶過去
    this._calendar.yearSelected.subscribe((y: any) => {
      this._calendar.selected = this._dateAdapter.createDate((y as Moment).year(), this.activeDate.month(), 1);
    })
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
  periodLabel: string = '';

  private updateLabel() {
    setTimeout(() => {
      var year = this.activeDate.year() - 1911;
      var textYear = (year < 0) ? ('民前' + (year * -1)) : year;
      if (this.currentView == 'multi-year') {
        if (this.multiYearView?._years) {
          var ys = this.multiYearView?._years;
          this.periodLabel = ys[0][0].displayValue + '~' + ys[ys.length - 1][ys[ys.length - 1].length - 1].displayValue;
        } else {
          this.periodLabel = textYear + '年';
        }
      } else {
        this.periodLabel = `${textYear}年`;//${date.month()+1}月`
      }
      this.cdr.markForCheck();
    }, 0);
  }
  private updateMultiYearInit() {
    if (this.multiYearView) {
      this.multiYearView._init = () => {
        // debugger
        // 只作尾數計算就好
        var year = (this.activeDate as any).year() - 11;
        var min = this._dateAdapter.addCalendarYears(this._calendar.activeDate, - (year % 20));
        // override _init
        this.multiYearView._todayYear = this._dateAdapter.getYear(this._dateAdapter.today());
        this.multiYearView._years = [];
        for (let i = 0, row = []; i < 20; i++) {
          const date = this._dateAdapter.addCalendarYears(min, i);
          const yearName = this._dateAdapter.getYearName(date);
          var dateYear = (date as any).year();
          row.push(new MatCalendarCell(dateYear, yearName, yearName,
            (this.multiYearView as any)._shouldEnableYear(dateYear), undefined));
          if (row.length == 4) {
            this.multiYearView._years.push(row);
            row = [];
          }
        }
        (this.multiYearView as any)._changeDetectorRef.markForCheck();
      }
      this.multiYearView._init();
    }
  }

  currentPeriodClicked() {
    if (this.currentView == 'multi-year') {
      this._calendar._goToDateInView(this._calendar.activeDate, 'year')
    } else if (this.currentView == 'year') {
      this._calendar._goToDateInView(this._calendar.activeDate, 'multi-year')
    }
    this.updateLabel();
  }
  previousClicked() {
    if (this.currentView === 'year') {
      var prevDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
      if (prevDate >= this._calendar.minDate ||!this._calendar.minDate) {
        this._calendar.activeDate = prevDate;
        this._calendar.selected = this._calendar.activeDate;
      }
    } else {
      var prevDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, -20);

      if (prevDate >= this._calendar.minDate || !this._calendar.minDate) {
        this._calendar.activeDate = prevDate;
      } else {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, (this._calendar.minDate as any).year() - this.activeDate.year())
      }

      this._calendar.selected = this._calendar.activeDate;
    }
    this.updateLabel();
  }
  nextClicked() {
    if (this.currentView === 'year') {
      var prevDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
      if (prevDate <= this._calendar.maxDate || !this._calendar.maxDate) {
        this._calendar.activeDate = prevDate;
        this._calendar.selected = this._calendar.activeDate;
      }
    } else {
      var prevDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, 20);

      if (prevDate <= this._calendar.maxDate || !this._calendar.maxDate) {
        this._calendar.activeDate = prevDate;
      } else {
        this._calendar.activeDate = this._dateAdapter.addCalendarYears(this._calendar.activeDate, (this._calendar.maxDate as any).year() - this.activeDate.year())
      }


      this._calendar.selected = this._calendar.activeDate;
    }
    this.updateLabel();
  }
}