

/**
 * 
 */ 
export class RegisterBasic {
    /**
     * 卡片號碼
     */ 
    public IcNo: string = null;
    /**
     * 姓名
     */ 
    public Name: string = null;
    /**
     * 身分證號或身分證明文件號碼
     */ 
    public CId: string = null;
    /**
     * 出生日期
     */ 
    public Birth: Date = new Date(0);
    /**
     * 性別
     */ 
    public Sex: string = null;
    /**
     * 發卡日期
     */ 
    public Issuedate: Date = new Date(0);
    /**
     * 卡片註銷註記
     */ 
    public CancellationFlag: string = null;
    /**
     * 保險人代碼
     */ 
    public ICode: string = null;
    /**
     * 保險對象身分註記
     */ 
    public InsurerFlag: string = null;
    /**
     * 卡片有效期限
     */ 
    public CardExpirationDate: Date = new Date(0);
    /**
     * 就醫可用次數
     */ 
    public AvailableCount: string = null;
    /**
     * 新生兒依附註記之新生兒出生日期
     */ 
    public NewbornBirthDate: Date = new Date(0);
    /**
     * 新生兒依附註記之新生兒胞胎註記
     */ 
    public NewbornBirthNum: string = null;
}
