import { Injectable } from "@angular/core";
import { MainLayoutComponent } from "../layout/main-layout/main-layout.component";
import { PatientsDto } from "./api-service/patient/patientsDto";
import { PatientListDto } from "./api-service/patient/patient-api";

export interface TutorialTip {
    content:string;
    target:string;
    cssCls:string;
}
/** 教學Service */
@Injectable({
    providedIn: 'root'
})
export class TutorialService{
    mainLayoutCmp:MainLayoutComponent;
    
    tutorialMap: Map<string,TutorialQueue> = new Map();
    constructor() {
    }

    public async setTip(tip:TutorialTip,name:string,isLast:boolean){
        return await this.mainLayoutCmp.setTip(tip,name,isLast);
    }
    public async RegisterTips(name:string,tips:TutorialTip[]){
        var q = new TutorialQueue(tips);
        this.tutorialMap.set(name,q);
    }
    public async Start(name:string){
        if(this.tutorialMap.has(name)){
            var q = this.tutorialMap.get(name);
            q.reset();
            var t = q.next();
            this.setTip(t,name,false);
        }
    }
    public async Next(name:string){
        if(this.tutorialMap.has(name)){
            var q = this.tutorialMap.get(name);
            var t = q.next();
            this.setTip(t,name,!q.hasNext());
        }
    }
}
class TutorialQueue{
    tutorials: TutorialTip[]=[];
    /**
     *
     */
    constructor(tutorials: TutorialTip[]) {
        this.tutorials = tutorials;
    }
    private current:number = -1;
    public reset(){
        this.current = -1;
    }
    public next(){
        return this.tutorials[++this.current];
    }
    public hasNext(){
        return !!this.tutorials[this.current+1];
    }
}