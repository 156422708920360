import { DateHelper } from './date-helper';
import { Injectable } from "@angular/core";
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { HcrService } from "src/app/services/hcr-service.service";
import { GetSeqNumber256 } from 'src/app/shared/hcr-lib/GetSeqNumber256';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { SeqNumber256N1 } from '../hcr-lib/SeqNumber256N1';
import { TreatNumNoICCard } from '../hcr-lib/TreatNumNoICCard';
import { SexEnum } from 'src/app/enums/SexEnum';
import { CSHISResultWithData } from '../hcr-lib/CSHISResultWithData';
import { IVisionHIS } from '../hcr-lib/IVisionHis';
import { VisionVHIS } from '../hcr-lib/VisionVHIS';
import { RegisterBasic } from '../hcr-lib/RegisterBasic';
import { EmergentTel } from '../hcr-lib/EmergentTel';
import { OrganDonate } from '../hcr-lib/OrganDonate';
import { CriticalIllnessData } from '../hcr-lib/CriticalIllnessData';
import { PrescriptionSave } from 'src/app/hist/models/prescription-save';
import { HistApi, HistToICCardData } from 'src/app/services/api-service/hist/hist-api';
import { ConfirmData, UserConfirmService } from 'src/app/services/user-confirm.service';

declare type keyValue = { text: string, value: string };

@Injectable({
  providedIn: 'root'
})

export class IcHelper implements IICHelper {

  
  /** 虛擬健保卡模式 */
  public vhcMode = false;
  constructor(
    private histApi:HistApi,
    private notification: EasyNotificationService,
    private userConfirmService: UserConfirmService,
    private hcrService: HcrService,
    private mainLayoutService: MainLayoutService,) {
  }
  recall = false;

  private getAPI():IVisionHIS{
    if(this.vhcMode){
      return this.hcrService.VHCApi;
    }else{
      return this.hcrService.VisionApi;
    }

  }
  async updateAvailable() {
    this.notification.showInfo("更新可用次數...");
    let processResult = await this.getAPI().UpdateHCContents();
  }

  async getICConn() {
    if(this.vhcMode){
      try{
        await (this.getAPI() as VisionVHIS).GetAPIVersion()
        return true;
      }catch(ex){
        console.log(ex);
        return false
      }
    }else{
      if (!this.hcrService.ConnectStatus) {
        console.log('1')
        this.notification.showInfo("連線展望主控台失敗...");
        return false;
      }
      if (!this.hcrService.HasCard) {
        console.log('2')
        this.notification.showInfo("未置入健保卡");
        return false;
      }

      return this.hcrService.ConnectStatus
    }
  }
  /** 無卡檢查主控台連線 */
  getICConnEx() {
    if(this.hcrService.isDemoMode){
      return true;
    }
    if (!this.hcrService.ConnectStatus) {
      this.notification.showInfo("連線展望主控台失敗...");
      return false;
    }

    return this.hcrService.ConnectStatus
  }
  /** 檢查連線狀態且有卡 */
  async getICConnOnlyCheck() {
    if(this.vhcMode){
      return (await this.getAPI().GetCardStatusHC()).StatusCode==0
    }else{
      if (!this.hcrService.ConnectStatus) {
        return false;
       }
       if (!this.hcrService.HasCard) {
         return false;
       }
       return this.hcrService.ConnectStatus
    }

  }
  /** 讀取最近一次就醫序號做加1，顯示欄位上
    *
    */
  async getLastSeqNum() {

    if (!await this.getICConn())
      return null

    this.notification.showInfo("查詢最近一次就醫序號...");
    let processResult = await this.getAPI().GetLastSeqNum();
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      // 虛擬健保卡 yyyVxxx , 把V取代成0
      let oriLastSeqNumber: number = + (this.vhcMode?data.LastSeqNumData.replace('V','0'):data.LastSeqNumData)
      // 跨年度要重新啟算
      var currentYear = (new Date().getFullYear() - 1911).toString();
      var icYear = data.LastSeqNumData.substring(0, 3);
      var latestSeqNumber: number = 0;
      if (currentYear != icYear) {
        latestSeqNumber = 1;
      } else {
        latestSeqNumber = oriLastSeqNumber + 1;
      }
      let lastSeqNum = (Array(4).join('0') + oriLastSeqNumber.toString()).slice(-4);
      let latestSeqNum = (Array(4).join('0') + latestSeqNumber.toString()).slice(-4);
      if(this.vhcMode){
        lastSeqNum = 'V'+lastSeqNum.substring(1);
        latestSeqNum = 'V'+latestSeqNum.substring(1);
      }
      return {
        lastSeqNum: lastSeqNum,
        latestSeqNum: latestSeqNum,
      }
    }
  }

  /** 從掛號或報到時讀取基本資料，取得「就醫可用次數」、...
   *
   */
  async getRegisterBasic() {
    if (!await this.getICConn())
      return null

    this.notification.showInfo("取得卡片基本資料...");
    let processResult = await this.getAPI().GetRegisterBasic();
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;

      return {
        availablecnt: + data.AvailableCount,
        insurerflag: data.InsurerFlag,
        IcNo: data.IcNo,
        Icdt: DateHelper.today,
        CId: data.CId,
        Name: data.Name,
        Birth: data.Birth,  // yyyy-MM-dd
        Sex: data.Sex       // M, F
      }
    } else {
      this.notification.showError(processResult.ErrorMsg);
    }
  }

  /** 從掛號或報到時讀取基本資料，取得「就醫可用次數」、...
   *
   */
  async GetRegisterBasicRawData() {

    if (!await this.getICConn())
      return null

    this.notification.showInfo("取得卡片基本資料...");
    let processResult = await this.getAPI().GetRegisterBasic();
    console.log('processResult',processResult);
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      return data
    } else {
      this.notification.showError(processResult.ErrorMsg);
    }
  }

  getBabyTreat(birthNum: number, sex: SexEnum) {
    // debugger
    var codes = sex == SexEnum.SexMan ? 'ABCDEFGHI' : 'abcdefghi';
    return codes.charAt(Math.min(birthNum - 1, Math.max(0, 8)));
  }
  /**
 * 取得就醫序號(真取號)
 */
  async getSeqNumber(itp: string, babyTreat: string): Promise<SeqNumber256N1> {
    if (!await this.getICConn()) {
      return null
    }
    this.notification.showInfo("取得就醫序號中...");
    let args: GetSeqNumber256 = new GetSeqNumber256();
    args.TreatItem = itp;
    args.BabyTreat = " ";
    args.TreatAfterCheck = "1";

    let processResult = await this.getAPI().GetSeqNumber256N1(args);
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      var treatNum = processResult?.Data?.TreatId
      // 識別碼異常
      if(!/^[A-Z0-9]{20}$/.test(treatNum))
      {
        // 能通
        if(HcrService.useWebApi) {
          var basicData = await this.getAPI().GetBasicData();
          var webapiTreatNum = await this.hcrService.VisionApi.webApiGetTreatNum(
                                      basicData.Data.CId, data.ClinicCode,
                                      DateHelper.getROCDateTimeString(data.ClinicDateTime));
          if(webapiTreatNum.StatusCode == 0){
            data.TreatId = webapiTreatNum.Data.TreatNum;
          }
        } else {
          // web不通且dll取值會出錯時暫且無解
        }
      }
      return data;
    } else if(processResult.StatusCode == 9093 || processResult.StatusCode == 5003) {  // 寫卡回應投保身分不一致(9093)時或卡片已過有限期限(5003) 更新後再刷一次
      let updateResult = await this.getAPI().UpdateHCContents();
      if (updateResult.StatusCode != 0) {
        this.notification.showWarning(updateResult.ErrorMsg);
        return null;
      } else {
        this.notification.showSuccess('IC更新完成，重新取得就醫序號中...');
        let processResult2nd = await this.getAPI().GetSeqNumber256N1(args);
        if (processResult2nd.StatusCode == 0) {
          let data = processResult2nd.Data;
          let treatNum2nd = processResult2nd?.Data?.TreatId
          // 識別碼異常  20240426 追加
          if(!/^[A-Z0-9]{20}$/.test(treatNum2nd))
            {
              // 能通
              if(HcrService.useWebApi) {
                var basicData = await this.getAPI().GetBasicData();
                var webapiTreatNum = await this.hcrService.VisionApi.webApiGetTreatNum(
                                            basicData.Data.CId, data.ClinicCode,
                                            DateHelper.getROCDateTimeString(data.ClinicDateTime));
                if(webapiTreatNum.StatusCode == 0){
                  data.TreatId = webapiTreatNum.Data.TreatNum;
                }
              }
            }
          return data;
        }
        else
        {
          this.notification.showError(processResult.ErrorMsg);
          return null;
        }
      }

    } else {
      this.notification.showError(processResult.ErrorMsg);
      return null;
    }
  }

  /**異常掛號取得就醫識別碼 */
  async getTreatId(cID: string, lackDate:string): Promise<TreatNumNoICCard> {
    // cID = 'A123456789';
    if (this.getICConnEx()) {
      this.notification.showInfo("取得離線就醫識別碼中...");
      var clinicCode = await this.getAPI().GetHospID();
      if (clinicCode.StatusCode !== 0) {
        this.notification.showError(clinicCode.ErrorMsg);
        return null;
      }
      let processResult:CSHISResultWithData<TreatNumNoICCard>;
      if(HcrService.useWebApi){
        processResult = await this.hcrService.VisionApi.webApiGetTreatNum(cID, clinicCode.Data,lackDate);
        if(processResult.StatusCode!=0){
          processResult = await this.getAPI().GetTreatNumNoICCard(cID, clinicCode.Data);
        }
      }else{
        processResult = await this.getAPI().GetTreatNumNoICCard(cID, clinicCode.Data);
        /* TASK 12634
        var treatNum = processResult?.Data?.TreatNum;
        if(/^[A-Z0-9]{20}$/.test(treatNum))
        {
          // web不通且dll取值會出錯時暫且無解
        }*/
      }
      //let processResult = await this.hcrService.VisionApi.GetTreatNumNoICCard(cID, clinicCode.Data);
      //let processResult = await this.hcrService.VisionApi.webApiGetTreatNum(cID, clinicCode.Data,lackDate);
      if (processResult.StatusCode == 0) {
        let data = processResult.Data;
        return data;
      } else {
        // this.notification.showError(processResult.ErrorMsg);
        this.notification.showError(processResult.ErrorMsg + ': 請確認患者身分證號或醫事機構識別碼！');
        return null;
      }
    }
    return null;
  }
  /**無卡取得就醫識別碼(欠卡/異掛) 回傳就醫識別碼*/
  async getTreatIdNoIC(pId:string): Promise<TreatNumNoICCard> {
    // cID = 'A123456789';
    // debugger
    if (this.getICConnEx()) {
      this.notification.showInfo("取得欠卡/異掛就醫識別碼中...");
      var hospId = await this.getAPI().GetHospID();
      // debugger
      if (hospId.StatusCode !== 0) {
        this.notification.showError(hospId.ErrorMsg);
        return null;
      }
      let processResult:CSHISResultWithData<TreatNumNoICCard>;
      if(HcrService.useWebApi){
        processResult = await this.hcrService.VisionApi.webApiGetTreatNumNoIC(pId,hospId.Data);
        if(processResult.StatusCode!=0){
          processResult = await this.getAPI().GetTreatNumNoICCard(pId,hospId.Data);
        }
      } else {
        // debugger
        processResult = await this.getAPI().GetTreatNumNoICCard(pId,hospId.Data);
        /* TASK 12634
        var treatNum = processResult?.Data?.TreatNum;
        if(/^[A-Z0-9]{20}$/.test(treatNum))
        {
          // web不通且dll取值會出錯時暫且無解
        }*/
      }
      if (processResult.StatusCode == 0) {
        let data = processResult.Data;
        return data;
      } else {
        // this.notification.showError(processResult.ErrorMsg);
        this.recall = false;
        this.notification.showError(processResult.ErrorMsg + ': 請確認患者身分證號或醫事機構識別碼！');
        return null;
      }
    }

    return null;
  }

  /**
  * 就醫序號退掛
  */
  async unGetSeqNumber(rDate: string) {
    this.showLoading();

    if (!await this.getICConn())
      return null

    this.notification.showInfo("就醫序號退掛中...");
    let processResult = await this.getAPI().UnGetSeqNumber(rDate + '\0');
    this.hideLoading();

    if (processResult.StatusCode != 0) {
      this.notification.showError(processResult.ErrorMsg);
    }
    // 暫時解法
    return processResult.StatusCode;

    // if (processResult.StatusCode == 0) {
    //   return true
    // }
    // return false
  }

  /** 讀取緊急聯絡電話 */
  async getEmergentTel() {
    if (!await this.getICConn())
      return null;

    this.notification.showInfo("取得緊急聯絡電話...");
    let processResult = await this.getAPI().GetEmergentTel();
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      return data;
    } else {
      this.notification.showError(processResult.ErrorMsg);
    }
  }

  /** 讀取同意器官捐贈及安寧緩和醫療註記 */
  async getOrganDonate() {
    if (!await this.getICConn())
      return null;

    this.notification.showInfo("讀取同意器官捐贈及安寧緩和醫療註記...");
    let processResult = await this.getAPI().GetOrganDonate();
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      return data;
    } else {
      this.notification.showError(processResult.ErrorMsg);
    }
  }

  /** 讀取重大傷病註記資料 */
  async getCriticalIllness() {
    if (!await this.getICConn())
      return null;

    this.notification.showInfo("讀取重大傷病註記資料...");
    let processResult = await this.getAPI().GetCriticalIllness();
    if (processResult.StatusCode == 0) {
      let data = processResult.Data;
      return data;
    } else {
      this.notification.showError(processResult.ErrorMsg);
    }
  }

  /** 就醫識別碼編碼異常重取 */
  async getNewTreatIdNoIC(pId: string): Promise<TreatNumNoICCard> {
    var hasBeenConn = false;
    if (this.hcrService.isDemoMode) {
      hasBeenConn = true;
    } else {
      if (this.hcrService.ConnectStatus) {
        hasBeenConn = true;
      }
    }

    if (!hasBeenConn) {
      return null;
    } else {
      var hospId = await this.getAPI().GetHospID();
      if (hospId.StatusCode !== 0) {
        return null;
      }
      let processResult:CSHISResultWithData<TreatNumNoICCard>;
      if(HcrService.useWebApi) {
        processResult = await this.hcrService.VisionApi.webApiGetTreatNumNoIC(pId, hospId.Data);
        if(processResult.StatusCode != 0){
          processResult = await this.getAPI().GetTreatNumNoICCard(pId, hospId.Data);
        }
      } else {
        processResult = await this.getAPI().GetTreatNumNoICCard(pId, hospId.Data);
      }

      if (processResult.StatusCode == 0) {
        let data = processResult.Data;
        return data;
      } else {
        this.recall = false;
        return null;
      }
    }
  }



  async WriteCard(data:HistToICCardData,cf:ConfirmData){
    try {
      let prescriptionSaveDataList: PrescriptionSave[] = [];
      // 有需要更新異動才打api
      if (data.PrescriptionSignMultiWrite?.Prescriptions?.length > 0) {
        //this.notification.showWarning('IC卡醫令寫入中...請勿拔出病患卡片，以確保醫令簽章正常產生!');
        
        cf.msg += '\nIC卡醫令寫入中...\n請勿拔出健保卡，以確保醫令簽章正常產生!'
        var ret = await this.hcrService.VisionApi.WriteMultiPrescriptSign(data.PrescriptionSignMultiWrite);

        if (ret.StatusCode != 0) {
          cf.msg += '\n\n健保卡寫入錯誤：' + ret.ErrorMsg;
          //cf.disableYes = false;
        }
        else if (ret.StatusCode == 0 && ret.Data) {
          
          let pescriptionSign = data.PrescriptionSignMultiWrite.Prescriptions;
          
          for (let i = 0; i < pescriptionSign.length; i++) {
            let prescriptionSaveData = new PrescriptionSave();
            prescriptionSaveData.RegicId = data.RegicId;
            prescriptionSaveData.NhiDrugCode = pescriptionSign[i].NhiDrugCode;
            prescriptionSaveData.ClinicDateTime = pescriptionSign[i].ClinicDateTime;
            prescriptionSaveData.Isgn = ret?.Data[i] ?? '';
            prescriptionSaveDataList = [...prescriptionSaveDataList, prescriptionSaveData];
          }
          await this.histApi.saveIgsn(prescriptionSaveDataList);
        }
      }

      cf.msg += '\nIC卡診斷及費用寫入中...\n請勿拔出健保卡，以避免資料寫入不完全';
      var ret2 = await this.hcrService.VisionApi.WriteTreatmentData(data.TreatmentData);
      // 完診後，再次修改>完診 會寫入錯誤(是否是因為測試卡?)
      // 仿 HIS 不攔截寫卡失敗錯誤 5022 和 5023
      if (ret2.StatusCode != 0 && ret2.StatusCode != 5022 && ret2.StatusCode != 5023 && ret2.StatusCode != 5028) {
        //this.notification.showError('健保卡寫入錯誤：' + ret2.ErrorMsg);
        cf.msg += '\n\n健保卡寫入錯誤：' + ret2.ErrorMsg;
      //cf.disableYes = false;
      }else{
        // 更新寫卡時間
        await this.histApi.UpdateICWriteTime(data.RegId);
        cf.msg += '\n\n健保卡寫入完成。'
      }
      
      //this.mainLayoutService.hideLoader();
    } catch (ex) {
      cf.msg += '\n\n健保卡寫入錯誤：' + ex;
      
    }
  }
  private showLoading() {
    this.mainLayoutService.showLoader();
  }
  private hideLoading() {
    this.mainLayoutService.hideLoader();
  }
}


export interface IICHelper {

  updateAvailable() 

  getICConn():Promise<boolean>;
  /** 無卡檢查主控台連線 */
  getICConnEx():boolean;
  /** 檢查連線狀態且有卡 */
  getICConnOnlyCheck():Promise<boolean>;
  /** 讀取最近一次就醫序號做加1，顯示欄位上
    *
    */
  getLastSeqNum():Promise<{ lastSeqNum: string, latestSeqNum: string }>
  /** 從掛號或報到時讀取基本資料，取得「就醫可用次數」、...
   *
   */
  getRegisterBasic():Promise<{
    availablecnt: number,
    insurerflag: string,
    IcNo: string,
    Icdt: Date,
    CId: string,
    Name: string,
    Birth: Date,  // yyyy-MM-dd
    Sex: string       // M, F
  }>

  /** 從掛號或報到時讀取基本資料，取得「就醫可用次數」、...
   *
   */
  GetRegisterBasicRawData():Promise<RegisterBasic> ;

  getBabyTreat(birthNum: number, sex: SexEnum) :string;
  /**
 * 取得就醫序號(真取號)
 */
  getSeqNumber(itp: string, babyTreat: string): Promise<SeqNumber256N1> 

  /**異常掛號取得就醫識別碼 */
  getTreatId(cID: string, lackDate:string): Promise<TreatNumNoICCard> 
  /**無卡取得就醫識別碼(欠卡/異掛) 回傳就醫識別碼*/
  getTreatIdNoIC(pId:string): Promise<TreatNumNoICCard> 
  /**
  * 就醫序號退掛
  */
  unGetSeqNumber(rDate: string):Promise<number>

  /** 讀取緊急聯絡電話 */
  getEmergentTel():Promise<EmergentTel>

  /** 讀取同意器官捐贈及安寧緩和醫療註記 */
  getOrganDonate():Promise<OrganDonate>

  /** 讀取重大傷病註記資料 */
  getCriticalIllness():Promise<CriticalIllnessData>

  /** 就醫識別碼編碼異常重取 */
  getNewTreatIdNoIC(pId: string): Promise<TreatNumNoICCard> 

}
