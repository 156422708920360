import { Directive, HostListener, ElementRef, Input } from '@angular/core';


@Directive({
  selector: '[wordKeepCaptial]'
})
export class WordKeepCaptialDirective {
  @Input()
  max: number;
  @Input()
  min: number;
  constructor(private el: ElementRef<HTMLInputElement>) {
    if (!el.nativeElement.type || el.nativeElement.type.toLowerCase() != 'number') {
      el.nativeElement.type = 'tel';
    }
    el.nativeElement.autocomplete = "off";
  }

  @HostListener('keyup', ['$event'])
  onKeyup(e: KeyboardEvent) {
    if (e.target instanceof HTMLInputElement) {
      var value = parseFloat(e.target.value);
      if (this.max && value > this.max) {
        e.target.value = this.max.toFixed(1);
        return;
      }
      if (this.min && value < this.min) {
        e.target.value = this.min.toFixed(1);
        return;
      }
    }
  }
//   @HostListener('keydown', ['$event'])
//   onKeyDown(e: KeyboardEvent) {
    
// var el = (e.target as HTMLInputElement);
// el.value = el.value.replace(/[a-z]/g, (match) => match.toUpperCase());
//   }
  @HostListener('keyup', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    // console.log('k up', e)
    var el = (e.target as HTMLInputElement);
    el.value = el.value.replace(/[a-z]/g, (match) => match.toUpperCase());

  }
  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/[a-z]/g, (match) => match.toUpperCase());
    document.execCommand('insertText', false, pastedInput);
  }

}