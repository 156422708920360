import { Injectable } from '@angular/core';
import { CryptoService } from './crypto-service';
import { HcrService } from './hcr-service.service';
import { ClinicDataService } from './data-service/clinic-data-service';
import { UserCache } from './user-cache';
import { DateHelper } from '../shared/helpers/date-helper';
import { BaseConfig } from './base-config.service';
import { VPNState } from './start-up-service';
import { DengueFormDto, DengueUploadDto, ToccApi } from './api-service/hist/tocc-api';
import { HandleErrorService } from './handle-error.service';
import { EasyNotificationService } from './easy-notification.service';
import { MainLayoutService } from '../layout/services/main-layout.service';
import { WholeHist } from './api-service/hist/whole-hist';

export interface verifyMac {
  name: string,
  code: string,
  mac: string
}

export interface queryParameter {
  regInstitutionCode: string,
  personalIdNumber: string,
  newbornFlag: boolean
}

export interface queryMain {
  mac: verifyMac,
  data: queryParameter
}

export interface pagingInfo {
  pages: number,
  previous: number,
  next: number,
  page: number,
  rowsPerPage: number,
  totalItems: number
}

export interface queryResult {
  uid: string,
  name: string,
  personalIdNumber: string,
  newbornBirthDate: string,
  regInstitutionCode: string,
  regInstitutionName: string,
  homeCity: string,
  homeDistrict: string,
  homeVillage: string,
  homeAddress: string,
  workCity: string,
  workDistrict: string,
  workVillage: string,
  workAddress: string,
  symptoms: string,
  countries: string,
  mosquito: string,
  sprayArea: string,
  othersFever: string,
  createTime: string
}

export interface queryResponse {
  code: number,
  message: string,
  result: queryResult,
  paging: pagingInfo
}

export interface uploadParameter {
  name?: string,
  personalIdNumber?: string,
  birthDate?: string,
  newbornFlag?: boolean,
  address?: string,
  icdMain?: string,
  icdSub?: string,
  regInstitutionCode: string,
  regInstitutionName: string,
  opdDate: string,
  medicalRecordTime: string,
  hisKey: string,
  dengueUid?: string,
  toccFlag: boolean
}

export interface uploadMain {
  mac: verifyMac,
  data: uploadParameter
}

export interface uploadResponse {
  code: number,
  message: string,
  paging: pagingInfo
}

export interface bulletinParameter {
  regInstitutionCode: string
}

export interface bulletinMain {
  mac: verifyMac,
  data: bulletinParameter
}

export interface bulletinResult {
  content: string,
  type: string,
  institutionAreaList: string[],
  institutionList: string[],
  institutionExcludeList: string[],
  patientAreaList: string[],
  updateTime: string
}

export interface bulletinResponse {
  code: number,
  message: string,
  result: bulletinResult[],
  paging: pagingInfo
}

enum siteType {
  Internet = 'Internet',
  VPN = 'VPN',
  Test = 'Test'
}

enum endPointType {
  Query = 'Query',
  Upload = 'Upload',
  Bulletin = 'Bulletin'
}

@Injectable({
  providedIn: 'root'
})
export class ToccService {

  constructor(
    private cryptoService: CryptoService,
    private hcrService: HcrService,
    private clinicDataService: ClinicDataService,
    private handleErrorService: HandleErrorService,
    private notification: EasyNotificationService,
    private mainLayoutService: MainLayoutService,
    private toccApi: ToccApi
  ) { }

  private readonly VendorName = 'ASIAVISION'; //翰鼎指定的廠商名稱
  private readonly VendorCode = '7054410400'; //自訂編號(建議10字元以上)
  private InternetSite = 'https://tocc-api.kchb.gov.tw';//後續讀參數設定
  private VPNSite = 'https://tocc-api-vpn.kchb.gov.tw';//後續讀參數設定
  private TestSite = 'https://tocc-api-preprod.hding.com.tw';//後續讀參數設定
  private QueryEndPoint = '/v1/his/dengue-form';
  private UploadEndPoint = '/v1/his/patient-visit-record';
  private BulletinEndPoint = '/v1/his/bulletin';
  vpnState: VPNState;

  get isDemoMode(): boolean {
    return this.hcrService.isDemoMode;
  }

  async isEnabledTOCC(vpnState: VPNState): Promise<boolean> {
    this.vpnState = vpnState;
    var cln = UserCache.getLoginUser().Clinic;
    var enable = false;
    const paramHst001 = await this.clinicDataService.getParam("HST001");
    if (paramHst001) {
      if (paramHst001.TOCCInternetSite) this.InternetSite = paramHst001.TOCCInternetSite;
      if (paramHst001.TOCCVPNSite) this.VPNSite = paramHst001.TOCCVPNSite;
      if (paramHst001.TOCCTestSite) this.TestSite = paramHst001.TOCCTestSite;

      if (paramHst001.TOCCEnable && paramHst001.TOCCClinicArea) {
        var toccAreas = paramHst001.TOCCClinicArea.split(',').filter(x => x);
        var clinicAddr = (cln.City??'') + (cln.Area??'') + (cln.Street??'');
        if (toccAreas.some(x => clinicAddr.indexOf(x) >= 0)) {
          if (paramHst001.TOCCEndDate) {
            if (DateHelper.today <= DateHelper.getDate(paramHst001.TOCCEndDate)) {
              enable = paramHst001.TOCCEnable;
            }
          } else {
            enable = paramHst001.TOCCEnable;
          }
        }
      }
    }
    return enable;
  }

  public async getDengueForm(parameter: queryParameter) {
    var name = this.VendorName;
    var code = this.VendorCode;
    var data = parameter.regInstitutionCode;
    // var result: queryResponse;
    // await this.cryptoService.generateHMAC(code, data).then(async (mac) => {
    //   var verify: verifyMac = { name: name, code: code, mac: mac };
    //   var content: queryMain = { mac: verify, data: parameter };
    //   result = await this.callDengueForm(content);
    // });
    var mac = await this.cryptoService.generateHMAC(code, data);
    var verify: verifyMac = { name: name, code: code, mac: mac };
    var content: queryMain = { mac: verify, data: parameter };
    var result = await this.callToccApi(content, endPointType.Query);
    return result;
  }

  public async uploadPatientVisitRecord(parameter: uploadParameter) {
    var name = this.VendorName;
    var code = this.VendorCode;
    var data = parameter.regInstitutionCode;
    var mac = await this.cryptoService.generateHMAC(code, data);
    var verify: verifyMac = { name: name, code: code, mac: mac };
    var content: uploadMain = { mac: verify, data: parameter };
    var result = await this.callToccApi(content, endPointType.Upload);
    return result;
  }

  public async getBulletin(parameter: bulletinParameter) {
    var name = this.VendorName;
    var code = this.VendorCode;
    var data = parameter.regInstitutionCode;
    var mac = await this.cryptoService.generateHMAC(code, data);
    var verify: verifyMac = { name: name, code: code, mac: mac };
    var content: bulletinMain = { mac: verify, data: parameter };
    var result = await this.callToccApi(content, endPointType.Bulletin);
    return result;
  }

  private async callToccApi(content: any, apiType: endPointType) {
    var [url, apiUrlType] = this.getUrl('Auto', apiType);
    // [url, apiUrlType] = this.getUrl(siteType.Internet, apiType);//整合測試當時用正式站台，正式上線需註解
    var jsonContent = JSON.stringify(content);
    var ret = await this.hcrService.sendMsg({ Call: 'SendFromUrl', Args: { Url: url, Content: jsonContent } });
    if (ret.Successed) {
      return [ret.Returns, jsonContent, apiUrlType];
    } else {
      throw [ret.Returns, jsonContent, apiUrlType];
    }
  }

  private getUrl(envType: siteType | 'Auto', apiType: endPointType) {
    var site = '';
    if (this.isDemoMode) {
      site = this.TestSite;
    } else {
      switch (envType) {
        case siteType.Internet:
          site = this.InternetSite;
          break;
        case siteType.VPN:
          site = this.VPNSite;
          break;
        case siteType.Test:
          site = this.TestSite;
          break;
        case 'Auto':
          // site = environment.production ? this.VPNSite : this.TestSite;
          // site = BaseConfig.isDebug() ? this.TestSite : this.VPNSite;
          site = BaseConfig.isDebug() ? this.TestSite : (this.vpnState === 'Fail' ? this.InternetSite : this.VPNSite);
          break;
        default:
          site = this.TestSite;
          break;
      }
    }

    var apiUrlType = '';
    if (site === this.InternetSite) {
      apiUrlType = siteType.Internet;
    } else if (site === this.VPNSite) {
      apiUrlType = siteType.VPN;
    } else if (site === this.TestSite) {
      apiUrlType = siteType.Test;
    }

    var endPoint = '';
    switch (apiType) {
      case endPointType.Query:
        endPoint = this.QueryEndPoint;
        break;
      case endPointType.Upload:
        endPoint = this.UploadEndPoint;
        break;
      case endPointType.Bulletin:
        endPoint = this.BulletinEndPoint;
        break;
      default:
        endPoint = '';
        break;
    }
    return [site + endPoint, apiUrlType];
  }

  public async getHMAC(data: string) {
    var result = '';
    await this.cryptoService.generateHMAC(this.VendorCode, data).then((hmac) => {
      result = hmac;
    });
    return result;
  }

  public async queryDengue(isEnabledTOCC: boolean, histRcd: WholeHist, needRead: boolean):
    Promise<[string, string, DengueFormDto, ('Y' | 'N' | ''), string]> {
    var dengueResult = '';
    var dengueFlag: ('Y' | 'N' | '') = '';
    var dengueFormDto = this.newDengueFormDto(
      histRcd.Patient.PatientNo,
      histRcd.Patient.Id,
      histRcd.Patient.CName,
      histRcd.Patient.CId ? histRcd.Patient.CId : histRcd.Patient.MomCId
    );
    var toccMessage = '';
    var originalTOCC = '';

    if (isEnabledTOCC && needRead) {
      this.mainLayoutService.showLoader();
      try {
        var cln = UserCache.getLoginUser().Clinic;
        var parameter: queryParameter = {
          regInstitutionCode: cln.NHICode,
          personalIdNumber: dengueFormDto.PersonalIdNumber,
          newbornFlag: (!histRcd.Patient.CId && !!histRcd.Patient.MomCId)
        };

        dengueFormDto.QueryTime = new Date();

        var [retResult, retParameter, retApiUrlType] = await this.getDengueForm(parameter);

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        var dengueForm = (retResult ? JSON.parse(retResult) as queryResponse : null);
        if (dengueForm != null) {
          toccMessage = dengueForm?.message.replace(/\n/g, ' ');
          if (!toccMessage) toccMessage = 'CC:TOCC：空白訊息。';

          dengueFormDto.QueryReturnCode = dengueForm?.code;
          dengueFormDto.QueryReturnMessage = toccMessage;

          if (dengueForm.code != 200) {
            this.notification.showError('TOCC查詢問卷失敗: ' + '(' + dengueForm.code.toString() + ')' + toccMessage);
          } else {
            var historyId = histRcd.Hist.Id;
            if (historyId) {
              try {
                var lastQueryReturnMessage = await this.toccApi.GetLastQueryReturnMessage(historyId);
                if (lastQueryReturnMessage) {
                  dengueFormDto.HistoryId = lastQueryReturnMessage.HistoryId;
                  dengueFormDto.HISKey = lastQueryReturnMessage.HISKey;
                  originalTOCC = lastQueryReturnMessage.QueryReturnMessage.trim();
                }
              }
              catch (ex) {
                // this.notification.showError('讀取TOCC問卷紀錄發生錯誤: ' + ex);
              }
            }

            dengueResult = dengueForm.result ? JSON.stringify(dengueForm.result) : '';
            dengueFlag = dengueForm.result ? 'Y' : 'N';

            var form: queryResult = (dengueResult ? JSON.parse(dengueResult) as queryResult : null);
            dengueFormDto.UId = form?.uid;
            dengueFormDto.Name = form?.name ?? histRcd.Patient.CName;
            dengueFormDto.PersonalIdNumber = form?.personalIdNumber ?? parameter.personalIdNumber;
            dengueFormDto.NewbornBirthDate = form?.newbornBirthDate ? DateHelper.getDate(form.newbornBirthDate) : null;
            dengueFormDto.FormCreateTime = form?.createTime ? new Date(form.createTime) : null;
            dengueFormDto.TOCCFlag = dengueFlag === 'Y';
          }
        } else {
          dengueFormDto.QueryReturnCode = -1;
          dengueFormDto.QueryReturnMessage = 'TOCC查詢問卷失敗';

          this.notification.showError(dengueFormDto.QueryReturnMessage);
        }
      } catch (ex) {
        var [retResult, retParameter, retApiUrlType] = Array.isArray(ex) ? ex : [ex, null, null];

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        dengueFormDto.QueryReturnCode = -99;
        dengueFormDto.QueryReturnMessage = 'TOCC查詢問卷發生錯誤: ' + retResult;

        this.notification.showError(dengueFormDto.QueryReturnMessage);
      } finally {
        await this.writeDengueForm('Create', dengueFormDto);
        this.mainLayoutService.hideLoader();
      }
    }

    return [toccMessage, originalTOCC, dengueFormDto, dengueFlag, dengueResult];
  }

  public async uploadDengue(dxChanged: boolean, manual: boolean,
    dengueUploadDto: DengueUploadDto, dengueFormDto: DengueFormDto, dengueFlag: ('Y' | 'N' | ''), dengueResult: string):
    Promise<[boolean, Date, DengueFormDto, ('Y' | 'N' | '')]> {
    var result = false;

    var haveLast = false;
    //既有病歷修改且有改主次診斷，則讀取TOCC問卷紀錄，且紀錄是有填TOCC問卷，則需再次上傳患者就醫紀錄。
    //或是手動再次上傳
    if (!dengueFlag) {
      if (dxChanged || manual) {
        try {
          var lastDengueForm = await this.toccApi.GetLastDengueForm(dengueUploadDto.HistoryId);
          if (lastDengueForm) {
            if (lastDengueForm.TOCCFlag || manual) {
              dengueFormDto = lastDengueForm;
              dengueFormDto.Id = null;//清空Id後續跑新增紀錄，就不是修改紀錄
              dengueFlag = lastDengueForm.TOCCFlag ? 'Y' : 'N';
              haveLast = true;
            }
          } else {
            dengueFormDto = this.newDengueFormDto(dengueUploadDto.PatientNo, dengueUploadDto.PatientId, dengueUploadDto.Name, dengueUploadDto.PersonalIdNumber);
            dengueFlag = 'N';
          }
        }
        catch (ex) {
          this.notification.showError('讀取TOCC問卷紀錄發生錯誤: ' + ex);
        }
      }
    }

    if (dengueFlag) {
      var cln = UserCache.getLoginUser().Clinic;
      var fill = (dengueFlag === 'Y');
      //另一種產生UUID的方式
      // let uuid = uuidv4(); //import {v4 as uuidv4} from 'uuid';
      var uuid = dengueFormDto.HISKey ? dengueFormDto.HISKey : crypto['randomUUID']();
      var parameter: uploadParameter = {
        regInstitutionCode: cln.NHICode,
        regInstitutionName: cln.Name,
        opdDate: DateHelper.getFormatedDateString(dengueUploadDto.RegDate, false, '-', false),
        medicalRecordTime: DateHelper.getFormatedDateString(dengueUploadDto.EditTime, false, '-', false) + ' ' + DateHelper.getTimeString(dengueUploadDto.EditTime, ':', true),
        hisKey: uuid,
        toccFlag: fill
      };
      //讀取TOCC問卷時->回應有填寫TOCC(toccFlag=true)->上傳患者就醫紀錄的參數(才需要填以下欄位)
      if (fill) {
        var form: queryResult = (dengueResult ? JSON.parse(dengueResult) as queryResult : null);
        var personalIdNumber = haveLast ? dengueFormDto.PersonalIdNumber : form?.personalIdNumber;
        var dengueUid = haveLast ? dengueFormDto.UId : form?.uid;
        var sub = [];
        if (dengueUploadDto.Dx2) sub.push(dengueUploadDto.Dx2 + dengueUploadDto.Dx2Name);
        if (dengueUploadDto.Dx3) sub.push(dengueUploadDto.Dx3 + dengueUploadDto.Dx3Name);
        if (dengueUploadDto.Dx4) sub.push(dengueUploadDto.Dx4 + dengueUploadDto.Dx4Name);
        if (dengueUploadDto.Dx5) sub.push(dengueUploadDto.Dx5 + dengueUploadDto.Dx5Name);
        if (dengueUploadDto.Dx6) sub.push(dengueUploadDto.Dx6 + dengueUploadDto.Dx6Name);
        parameter.name = dengueUploadDto.Name;
        parameter.personalIdNumber = personalIdNumber;
        parameter.birthDate = DateHelper.getFormatedDateString(dengueUploadDto.Birthday, false, '-', false);
        parameter.newbornFlag = (!dengueUploadDto.PersonalIdNumber && !!dengueUploadDto.MomCId);
        parameter.address = (dengueUploadDto.City??'') +(dengueUploadDto.Area??'') + (dengueUploadDto.Street??'');
        parameter.icdMain = dengueUploadDto.Dx1 + dengueUploadDto.Dx1Name;
        parameter.icdSub = sub.join(',');
        parameter.dengueUid = dengueUid;
      }

      dengueFormDto.HistoryId = dengueUploadDto.HistoryId;
      dengueFormDto.RecordTime = new Date();
      dengueFormDto.HISKey = uuid;
      dengueFormDto.TOCCFlag = fill;

      try {
        var [retResult, retParameter, retApiUrlType] = await this.uploadPatientVisitRecord(parameter);

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.RecordParameter = retParameter;
        dengueFormDto.RecordResponse = retResult;

        var visitRecord = (retResult ? JSON.parse(retResult) as uploadResponse : null);
        if (visitRecord != null) {
          dengueFormDto.RecordReturnCode = visitRecord?.code;
          dengueFormDto.RecordReturnMessage = visitRecord?.message;

          var toccMessage = visitRecord.message ? visitRecord.message : '';
          if (visitRecord.code != 200) {
            this.notification.showError('TOCC上傳患者就醫紀錄失敗: ' + '(' + visitRecord.code.toString() + ')' + toccMessage);
          } else {
            result = true;

            this.notification.showInfo('TOCC上傳患者就醫紀錄成功');
          }
        } else {
          dengueFormDto.RecordReturnCode = -1;
          dengueFormDto.RecordReturnMessage = 'TOCC上傳患者就醫紀錄失敗';

          this.notification.showError(dengueFormDto.RecordReturnMessage);
        }
      } catch (ex) {
        var [retResult, retParameter, retApiUrlType] = Array.isArray(ex) ? ex : [ex, null, null];

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.RecordParameter = retParameter;
        dengueFormDto.RecordResponse = retResult;

        dengueFormDto.RecordReturnCode = -99;
        dengueFormDto.RecordReturnMessage = 'TOCC上傳患者就醫紀錄發生錯誤: ' + retResult;

        this.notification.showError(dengueFormDto.RecordReturnMessage);
      }

      var ret = await this.writeDengueForm('Update', dengueFormDto);
    }

    return [result && ret, dengueFormDto.RecordTime, dengueFormDto, dengueFlag];
  }

  private newDengueFormDto(patientNo: string, patientId: number, name: string, personalIdNumber: string): DengueFormDto {
    var cln = UserCache.getLoginUser().Clinic;
    var dengueFormDto = new DengueFormDto();
    dengueFormDto.ClinicId = cln.Id;
    dengueFormDto.ClinicCode = cln.Code;
    dengueFormDto.PatientNo = patientNo;
    dengueFormDto.PatientId = patientId;
    dengueFormDto.RegInstitutionCode = cln.NHICode;
    dengueFormDto.RegInstitutionName = cln.Name;

    dengueFormDto.Name = name;
    dengueFormDto.PersonalIdNumber = personalIdNumber;
    return dengueFormDto;
  }

  private async writeDengueForm(type: 'Create' | 'Update', dengueFormDto: DengueFormDto): Promise<boolean> {
    try {
      if (type === 'Create') {
        var ret = await this.toccApi.CreateDengueForm(dengueFormDto);
        dengueFormDto.Id = ret.Id;
      } else if (type === 'Update') {
        if (dengueFormDto.Id) {
          await this.toccApi.UpdateDengueForm(dengueFormDto);
        } else {
          await this.toccApi.CreateDengueForm(dengueFormDto);
        }
      }

      return true;
    }
    catch (ex) {
      let errorMsg = this.handleErrorService.handleError(ex);
      this.notification.showError('TOCC問卷紀錄: ' + errorMsg);
    }

    return false;
  }
}
