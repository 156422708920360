import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { WholeHist } from "src/app/services/api-service/hist/whole-hist";
import { SystemCode } from "src/app/services/api-service/system-code/system-code";
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { EasyNotificationService } from "src/app/services/easy-notification.service";
import { PageData } from "src/app/shared/models/page-data";
import { LoginUser } from "src/app/permission/models/login-user";
import { DispensingTypeEnum } from "src/app/enums/DispensingTypeEnum";
import { Referral } from "src/app/services/api-service/referral/referral";
import { HistApi } from "src/app/services/api-service/hist/hist-api";
import { HistDiag } from "src/app/hist/models/hist-diag";
import { DxOpt } from "src/app/services/api-service/hist/hist-edit-option";
import { ValueTextPair } from "src/app/shared/models/value-text-pair";
import { HistService } from "src/app/hist/services/hist.service";
import { resolve } from "dns";
import { VisComboboxComponent } from "src/app/shared/components/dropdownlist/vis-combobox/vis-combobox.component";

@Component({
    selector: 'app-referrals-referral-form-dialog-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

    @ViewChild(VisComboboxComponent, { static: true })
    dx: VisComboboxComponent;
    @ViewChild(VisComboboxComponent, { static: true })
    dx1: VisComboboxComponent;
    @ViewChild(VisComboboxComponent, { static: true })
    dx2: VisComboboxComponent;

    @Input() queryPatientNo = ''
    @Input() histRcd: WholeHist;
    @Input() referralFormGroup: FormGroup;
    @Input() oriRef: Referral;

    loginUser: LoginUser;
    typeList: string[] = ['C0250','C0251','C0252','C0253','C0254','C0255'];
    systemCode0250: SystemCode[];   // -> Objects -> 為 'A', ReferralPur 必填
    systemCode0251: SystemCode[];   // -> SpeNote
    systemCode0252: SystemCode[];   // -> SpeCaseNote
    systemCode0253: SystemCode[];   // -> UrgPatNote
    systemCode0254: SystemCode[];   // -> ReferralPur 填'01' -> Objects 需為 C
    systemCode0255: SystemCode[];   // -> UrgPatRea
    systemCode250: SystemCode;
    systemCode251: SystemCode;
    systemCode252: SystemCode;
    systemCode253: SystemCode;
    systemCode254: SystemCode;
    systemCode255: SystemCode;
    a_selected: boolean = false;
    b_selected: boolean = false;
    c_selected: boolean = false;
    urgPatRea07_selected: boolean = false;
    object6_selected: boolean = false;
    objectA_selected: boolean = false;
    isHelperPadOpened: boolean = false;
    illWord: string = '';
    allDx: DxOpt[];
    dxOpt: ValueTextPair[];
    dx1Opt: ValueTextPair[];
    dx2Opt: ValueTextPair[];

    constructor(private api: SystemcodeApi,
        private histApi: HistApi,
        private histService: HistService,
        private notification: EasyNotificationService) { }

    async ngOnInit() {
        this.getSystemCodeList();
        this.allDx = await this.histService.getDiagList('');
        this.dxOpt = this.allDx.map(x => { return { text: x.ICD9 + '　' + x.Code + '　' + x.CName, value: x.Code } });
        this.dx1Opt = this.allDx.map(x => { return { text: x.ICD9 + '　' + x.Code + '　' + x.CName, value: x.Code } });
        this.dx2Opt = this.allDx.map(x => { return { text: x.ICD9 + '　' + x.Code + '　' + x.CName, value: x.Code } });
    }

    fillForm() {
        if (this.oriRef) {
            this.referralFormGroup.controls.CC.setValue(this.oriRef.CC);
            this.referralFormGroup.controls.CC.disable();
            this.referralFormGroup.controls.Dx.setValue(this.oriRef.Dx);
            this.setDx(this.oriRef.Dx, 'dx');
            this.referralFormGroup.controls.Dx1.setValue(this.oriRef.Dx1);
            this.setDx(this.oriRef.Dx1, 'dx1');
            this.referralFormGroup.controls.Dx2.setValue(this.oriRef.Dx2);
            this.setDx(this.oriRef.Dx2, 'dx2');
            // this.referralFormGroup.controls.RecOrderDate.setValue(this.oriRef.RegDate);
            // this.referralFormGroup.controls.RecLISDate.setValue(this.oriRef.RegDate);
            //去除時間
            let regDate = new Date(this.oriRef.RegDate);
            regDate = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate());
            this.referralFormGroup.controls.RecOrderDate.setValue(regDate);
            this.referralFormGroup.controls.RecLISDate.setValue(regDate);
            this.referralFormGroup.controls.HRx.setValue(this.processHRx());
            this.referralFormGroup.controls.RecLISResult.setValue(this.oriRef.RecLISResult);
            this.referralFormGroup.controls.Objects.setValue(this.oriRef.Objects);
            this.referralFormGroup.controls.ObjectsNote.setValue(this.oriRef.ObjectsNote);
            this.referralFormGroup.controls.ObjectsNote.disable();
            if (this.oriRef.Objects == "6") this.referralFormGroup.controls.ObjectsNote.enable();
            var selected250 = this.systemCode0250.find(x => x.Code == this.oriRef.Objects);
            this.referralFormGroup.controls.Objects.setValue(selected250.Code + " " + selected250.Name);
            if (this.oriRef.SpeNote || this.oriRef.SpeNote == '') {
                var selected251 = this.systemCode0251.find(x => x.Code == this.oriRef.SpeNote);
                this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
            }
            if (this.oriRef.UrgPatNote) {
                var selected253 = this.systemCode0253.find(x => x.Code == this.oriRef.UrgPatNote);
                this.referralFormGroup.controls.UrgPatNote.setValue(selected253.Code + " " + selected253.Name);
            }
            if (this.oriRef.ReferralPur) {
              var selected254 = this.systemCode0254.find(x => x.Code == this.oriRef.ReferralPur);
              this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
            }
            if (this.oriRef.UrgPatRea) {
                var selected255 = this.systemCode0255.find(x => x.Code == this.oriRef.UrgPatRea);
                this.referralFormGroup.controls.UrgPatRea.setValue(selected255.Code + " " + selected255.Name);
            }
            this.referralFormGroup.controls.UrgPatOth.setValue(this.oriRef.UrgPatOth);
            if (this.oriRef.SpeCaseNote) {
                var selected252 = this.systemCode0252.find(x => x.Code == this.oriRef.SpeCaseNote);
                this.referralFormGroup.controls.SpeCaseNote.setValue(selected252.Code + " " + selected252.Name);
            }
        } else {
            this.referralFormGroup.controls.CC.setValue(this.histRcd.Hist.CC + this.histRcd.Hist.PE);
            this.referralFormGroup.controls.CC.disable();
            this.referralFormGroup.controls.Dx.setValue(this.histRcd.Hist.Diags[0].code);
            this.setDx(this.histRcd.Hist.Diags[0].code, 'dx');
            this.referralFormGroup.controls.DxName.setValue(this.histRcd.Hist.Diags[0].name);
            this.referralFormGroup.controls.Dx1.setValue(this.histRcd.Hist.Diags[1].code);
            this.setDx(this.histRcd.Hist.Diags[1].code, 'dx1');
            this.referralFormGroup.controls.DxName1.setValue(this.histRcd.Hist.Diags[1].name);
            this.referralFormGroup.controls.Dx2.setValue(this.histRcd.Hist.Diags[2].code);
            this.setDx(this.histRcd.Hist.Diags[2].code, 'dx2');
            this.referralFormGroup.controls.DxName2.setValue(this.histRcd.Hist.Diags[2].name);
            // 去除時間
            // this.referralFormGroup.controls.RecOrderDate.setValue(this.histRcd.Register.RegDate);
            let regDate = new Date(this.histRcd.Register.RegDate);
            regDate = new Date(regDate.getFullYear(), regDate.getMonth(), regDate.getDate());
            this.referralFormGroup.controls.RecOrderDate.setValue(regDate);
            var selected250 = this.systemCode0250.find(x => x.Code == '4');
            this.referralFormGroup.controls.Objects.setValue(selected250.Code + " " + selected250.Name);
            // this.referralFormGroup.controls.RecLISDate.setValue(this.histRcd.Register.RegDate);
            this.referralFormGroup.controls.RecLISDate.setValue(regDate);
            this.referralFormGroup.controls.HRx.setValue(this.processHRx());
            this.referralFormGroup.controls.ObjectsNote.disable();
        }

        this.optionControl(
          !this.referralFormGroup.controls.Objects.value ? '' : this.referralFormGroup.controls.Objects.value.split(" ")[0],
          !this.referralFormGroup.controls.SpeNote.value ? '' : this.referralFormGroup.controls.SpeNote.value.split(" ")[0],
          !this.referralFormGroup.controls.ReferralPur.value ? '' : this.referralFormGroup.controls.ReferralPur.value.split(" ")[0],
          !this.referralFormGroup.controls.UrgPatRea.value ? '' : this.referralFormGroup.controls.UrgPatRea.value.split(" ")[0],
          !this.referralFormGroup.controls.UrgPatNote.value ? '' : this.referralFormGroup.controls.UrgPatNote.value.split(" ")[0],
          !this.referralFormGroup.controls.UrgPatOth.value ? '' : this.referralFormGroup.controls.UrgPatOth.value.split(" ")[0],
          !this.referralFormGroup.controls.SpeCaseNote.value ? '' : this.referralFormGroup.controls.SpeCaseNote.value.split(" ")[0]
        );
    }

    processHRx(): string {
        var displayHRx: string = "藥材/治療                數量  UN  頻率  日  總量  途徑\n";
        this.histRcd.Hist.HRxs.forEach(hrx => {
            displayHRx += hrx.ProdName.substring(0, 8) + " \t\t";
            displayHRx += hrx.QTY + " \t";
            displayHRx += hrx.Unit + " \t";
            displayHRx += hrx.Freq + " \t";
            displayHRx += hrx.Days + " \t";
            displayHRx += hrx.TotalDose + " \t";
            displayHRx += hrx.Way + " \t\n";
            // switch(hrx.DispTP)
            // {
            //     case DispensingTypeEnum.Clinic:
            //         displayHRx += "Clinic\n";
            //         break;
            //     case DispensingTypeEnum.OutOfClinic:
            //         displayHRx += "OutOfClinic\n";
            //         break;
            //     case DispensingTypeEnum.NoNeed:
            //         displayHRx += "NoNeed\n";
            //         break;
            //     case DispensingTypeEnum.EmerSelf:
            //         displayHRx += "EmerSelf\n";
            //         break;
            // }
        });
        return displayHRx;
    }

    processHRxTest(): string {
        var displayHRx: string = "藥材/治療  數量  UN  頻率  日  總量  途徑       調劑\n";
        // this.histRcd.Hist.HRxs.forEach(hrx => {
            displayHRx += "Tamiflu \t\t";
            displayHRx += "1 \t";
            displayHRx += "瓶 \t";
            displayHRx += "3 \t";
            displayHRx += "1 \t";
            displayHRx += "7 \t";
            displayHRx += "口服 \t";
            // displayHRx += "Clinic\n";
            var test = "1";
            switch(test)
            {
                case DispensingTypeEnum.Clinic:
                    displayHRx += "Clinic\n";
                    break;
                case DispensingTypeEnum.OutOfClinic:
                    displayHRx += "OutOfClinic\n";
                    break;
                case DispensingTypeEnum.NoNeed:
                    displayHRx += "NoNeed\n";
                    break;
                case DispensingTypeEnum.EmerSelf:
                    displayHRx += "EmerSelf\n";
                    break;
            }
        // });
        return displayHRx;
    }

    async getSystemCodeList() {
        try {
            var data = await this.api.GetSystemCode(this.typeList);
            // Grouping
            this.systemCode0250 = (data.filter(x => x.TypeCode == 'C0250')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            this.systemCode0251 = (data.filter(x => x.TypeCode == 'C0251')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            this.systemCode0252 = (data.filter(x => x.TypeCode == 'C0252')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            this.systemCode0253 = (data.filter(x => x.TypeCode == 'C0253')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            this.systemCode0254 = (data.filter(x => x.TypeCode == 'C0254')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            this.systemCode0255 = (data.filter(x => x.TypeCode == 'C0255')).filter((i, p, s) => s.findIndex(v => v.Name === i.Name) === p);
            var defaultData = new SystemCode('', '無');
            this.systemCode0251.unshift(defaultData);
            //this.referralFormGroup.controls.SpeNote.setValue('');
        } catch(ex) {
            this.notification.showGetDataFailed('referral-form-dialog-edit');
        }
        // debugger
        // 補this.histRcd.Hist.Diags
        var diagLength = this.histRcd.Hist.Diags.length;
        if (diagLength < 3) {
            var d = 3 - diagLength;
            for(var i = 0; i < d; i++) {
                var elm = new HistDiag();
                elm.code = '';
                elm.name = '';
                this.histRcd.Hist.Diags.push(elm);
            }
        }
        this.fillForm();
    }

    /**
     * 集中控制各選項，不能選的就關閉，方便輸入，日後擴充維護。
     */
    optionControl(objectChoseItem: any, speNoteChoseItem: any, referralPurChoseItem: any, urgPatReaChoseItem: any,
      urgPatNoteChoseItem: any, urgPatOthChoseItem: any, speCaseNoteChoseItem: any) {
      // -------------------------------------------------------------------------------------------
      // 控制操作
      // h18 Objects systemCode0250 轉診目的
      // -------------------------------------------------------------------------------------------
      if (objectChoseItem !== undefined) {
        this.systemCode0251.forEach(s => s.IsEnabled = true);
        this.referralFormGroup.controls.SpeNote.setValue('');

        this.systemCode0254.forEach(s => s.IsEnabled = true);
        this.referralFormGroup.controls.ReferralPur.setValue('');
        this.referralFormGroup.controls.ReferralPur.disable();

        // 若h18欄位填入A，h42本欄為必填。h42又與h39連動，因此h39此時也必填。
        this.objectA_selected = (objectChoseItem == "A");

        // h18轉診目的7 和 8 限定 緊急傷病患使用(<h39>= A)
        if (objectChoseItem == '7' || objectChoseItem == '8') {
          this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'A');
          let selected251 = this.systemCode0251.find(x => x.Code == 'A');
          this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          this.optionControl(undefined, 'A', undefined, undefined, undefined, undefined, undefined);
        }
        // 若h18欄位填入A，h42本欄為必填。
        else if (objectChoseItem == 'A') {
          this.referralFormGroup.controls.ReferralPur.enable();
          // 帶入原本的值
          if (referralPurChoseItem) {
            let selected254 = this.systemCode0254.find(x => x.Code == referralPurChoseItem);
            this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          }
          this.optionControl(undefined, undefined, referralPurChoseItem == undefined ? '' : referralPurChoseItem, undefined, undefined, undefined, undefined);
        }
        else {
          this.systemCode0251.forEach(s => s.IsEnabled = (s.Code == '' || s.Code == 'A' || s.Code == 'B'));
        }

        // 不是7不是8要保持原本的值
        if (objectChoseItem != '7' && objectChoseItem != '8') {
          // 帶入原本的值
          if (speNoteChoseItem || speNoteChoseItem == '') {
            let selected251 = this.systemCode0251.find(x => x.Code == speNoteChoseItem);
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
          this.optionControl(undefined, speNoteChoseItem == undefined ? '' : speNoteChoseItem, undefined, undefined, undefined, undefined, undefined);
        }

        // h18轉診目的為「6：其他」者， h19此 欄位 為必填
        // h18若轉診目的非選擇「6 ：其他」h19則不可填寫此欄位
        if (objectChoseItem == "6") {
          this.object6_selected = true;
          this.referralFormGroup.controls.ObjectsNote.enable();
        } else {
            this.object6_selected = false;
            this.referralFormGroup.controls.ObjectsNote.setValue("");
            this.referralFormGroup.controls.ObjectsNote.disable();
        }
      }

      // -------------------------------------------------------------------------------------------
      // 控制操作
      // h39 SpeNote systemCode0251 特定註記
      // -------------------------------------------------------------------------------------------
      if (speNoteChoseItem !== undefined) {
        this.systemCode0253.forEach(s => s.IsEnabled = false);
        this.referralFormGroup.controls.UrgPatNote.setValue('');
        this.referralFormGroup.controls.UrgPatNote.disable();

        this.systemCode0255.forEach(s => s.IsEnabled = false);
        this.referralFormGroup.controls.UrgPatRea.setValue('');
        this.referralFormGroup.controls.UrgPatRea.disable();

        this.systemCode0252.forEach(s => s.IsEnabled = false);
        this.referralFormGroup.controls.SpeCaseNote.setValue('');
        this.referralFormGroup.controls.SpeCaseNote.disable();

        this.a_selected = (speNoteChoseItem == 'A');
        this.b_selected = (speNoteChoseItem == "B");

        // 若h39欄位填入A，h41本欄為必填。
        // 若h39欄位填入A，h43本欄為必填。
        // 若h39欄位填入A，h45本欄為必填。沒有顯示此欄位，直接給值。
        if (speNoteChoseItem == 'A') {// -> UrgPatNote, UrgPatRea, UrgPatTime 必填
          this.systemCode0253.forEach(s => s.IsEnabled = true);
          // 帶入原本的值
          if (urgPatNoteChoseItem) {
            let selected253 = this.systemCode0253.find(x => x.Code == urgPatNoteChoseItem);
            this.referralFormGroup.controls.UrgPatNote.setValue(selected253.Code + " " + selected253.Name);
          }
          this.referralFormGroup.controls.UrgPatNote.enable();

          this.systemCode0255.forEach(s => s.IsEnabled = true);
          // 帶入原本的值
          if (urgPatReaChoseItem) {
            let selected255 = this.systemCode0255.find(x => x.Code == urgPatReaChoseItem);
            this.referralFormGroup.controls.UrgPatRea.setValue(selected255.Code + " " + selected255.Name);
          }
          this.referralFormGroup.controls.UrgPatRea.enable();
        }
        // 若h39欄位填入B，h40本欄為必填 。
        else if (speNoteChoseItem == 'B') {// -> SpeCaseNote 必填
          this.systemCode0252.forEach(s => s.IsEnabled = true);
          // 帶入原本的值
          if (speCaseNoteChoseItem) {
            let selected252 = this.systemCode0252.find(x => x.Code == speCaseNoteChoseItem);
            this.referralFormGroup.controls.SpeCaseNote.setValue(selected252.Code + " " + selected252.Name);
          }
          this.referralFormGroup.controls.SpeCaseNote.enable();
        }
        // 若h42本欄填入試辦計畫代碼01，h39欄位必須為C。
        else if (speNoteChoseItem == 'C') {
          let selected254 = this.systemCode0254.find(x => x.Code == '01');
          this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          this.optionControl(undefined, undefined, '01', undefined, undefined, undefined, undefined);
        }
        // 若h42本欄填入試辦計畫代碼02~04、06、07，h39欄位必須為D
        else if (speNoteChoseItem == 'D') {
          //先預設在02
          let selected254 = this.systemCode0254.find(x => x.Code == '02');
          this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          this.optionControl(undefined, undefined, '02', undefined, undefined, undefined, undefined);
        }
        // 若h42本欄填入試辦計畫代碼08，h39欄位必須 為E。
        else if (speNoteChoseItem == 'E') {
          let selected254 = this.systemCode0254.find(x => x.Code == '08');
          this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          this.optionControl(undefined, undefined, '08', undefined, undefined, undefined, undefined);
        }
        // 若h42本欄填入試辦計畫代碼09，h39欄位必須為F。
        else if (speNoteChoseItem == 'F') {
          let selected254 = this.systemCode0254.find(x => x.Code == '09');
          this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          this.optionControl(undefined, undefined, '09', undefined, undefined, undefined, undefined);
        }
        // 若h42本欄填入試辦計畫代碼10，h39欄位必須為G。
        else if (speNoteChoseItem == 'G') {
          let selected254 = this.systemCode0254.find(x => x.Code == '10');
          this.referralFormGroup.controls.ReferralPur.setValue(selected254.Code + " " + selected254.Name);
          this.optionControl(undefined, undefined, '10', undefined, undefined, undefined, undefined);
        }
      }

      // -------------------------------------------------------------------------------------------
      // 控制操作
      // h42 ReferralPur systemCode0254 轉診目的(轉介其他試辦計畫或方案)
      // -------------------------------------------------------------------------------------------
      if (referralPurChoseItem !== undefined) {
        // 若h18欄位填入A，h42本欄為必填。
        if (this.objectA_selected) {
          this.systemCode0251.forEach(s => s.IsEnabled = (s.Code == 'C' || s.Code == 'D' || s.Code == 'E' || s.Code == 'F' || s.Code == 'G'));
          this.referralFormGroup.controls.SpeNote.setValue('');

          // 若h42本欄填入試辦計畫代碼01，h39欄位必須為C。
          if (referralPurChoseItem == '01') {
            this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'C');
            let selected251 = this.systemCode0251.find(x => x.Code == 'C');
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
          // 若h42本欄填入試辦計畫代碼02~04、06、07，h39欄位必須為D
          else if (referralPurChoseItem == '02' || referralPurChoseItem == '03' || referralPurChoseItem == '04' || referralPurChoseItem == '06' || referralPurChoseItem == '07') {
            this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'D');
            let selected251 = this.systemCode0251.find(x => x.Code == 'D');
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
          // 若h42本欄填入試辦計畫代碼08，h39欄位必須 為E。
          else if (referralPurChoseItem == '08') {
            this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'E');
            let selected251 = this.systemCode0251.find(x => x.Code == 'E');
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
          // 若h42本欄填入試辦計畫代碼09，h39欄位必須為F。
          else if (referralPurChoseItem == '09') {
            this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'F');
            let selected251 = this.systemCode0251.find(x => x.Code == 'F');
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
          // 若h42本欄填入試辦計畫代碼10，h39欄位必須為G。
          else if (referralPurChoseItem == '10') {
            this.systemCode0251.forEach(s => s.IsEnabled = s.Code == 'G');
            let selected251 = this.systemCode0251.find(x => x.Code == 'G');
            this.referralFormGroup.controls.SpeNote.setValue(selected251.Code + " " + selected251.Name);
          }
        }
      }

      // -------------------------------------------------------------------------------------------
      // 控制操作
      // h43 UrgPatRea systemCode0255 緊急傷病轉診原因
      // -------------------------------------------------------------------------------------------
      if (urgPatReaChoseItem !== undefined) {
        //h43緊急傷病轉診原因為「 07 其他」者，h44此欄位為必填
        //h43若 緊急傷病轉診原因非 填入 「 07 ：其他」h44則不可填寫此欄位。
        this.urgPatRea07_selected = (urgPatReaChoseItem == "07");
        if (this.urgPatRea07_selected) {
          // 帶入原本的值
          if (urgPatOthChoseItem) {
            this.referralFormGroup.controls.UrgPatOth.setValue(urgPatOthChoseItem);
          }
          this.referralFormGroup.controls.UrgPatOth.enable();
        } else {
          this.referralFormGroup.controls.UrgPatOth.setValue("");
          this.referralFormGroup.controls.UrgPatOth.disable();
        }
      }
    }

    radio250Change(evt: any, code: string = null): void {
        var selValue = code;
        if (!selValue) {
            selValue = evt.value.split(" ")[0];
        }

        // this.objectA_selected = (selValue == "A");
        // this.c_selected = this.objectA_selected;

        // if (this.objectA_selected) {
        //     const selectedItem = this.systemCode0251.find(x => x.Code == "C");
        //     this.referralFormGroup.controls.SpeNote.setValue(selectedItem.Code + " " + selectedItem.Name);      // -> SystemCode0254.Code = "01"
        //     this.a_selected = !this.objectA_selected;
        //     this.b_selected = !this.objectA_selected;
        // }

        // if (selValue == "6") { // 其他 -> ObjectsNote 必填
        //     this.object6_selected = true;
        //     this.referralFormGroup.controls.ObjectsNote.enable();
        // } else {
        //     this.object6_selected = false;
        //     this.referralFormGroup.controls.ObjectsNote.setValue("");
        //     this.referralFormGroup.controls.ObjectsNote.disable();
        // }

        this.optionControl(selValue, undefined, undefined, undefined, undefined, undefined, undefined);
    }

    radio251Change(evt: any, code: string = null): void {
        var selValue = code;
        if (!selValue) {
            selValue = evt.value.split(" ")[0];
        }

        // this.a_selected = (selValue == "A");   // -> UrgPatNote, UrgPatRea, UrgPatTime 必填
        // this.b_selected = (selValue == "B");   // -> SpeCaseNote 必填
        // this.c_selected = (selValue == "C");
        // this.object6_selected = (selValue == "");
        // if (!this.c_selected && this.objectA_selected) {
        //     this.referralFormGroup.controls.Objects.setValue("");
        // }
        // if (this.object6_selected) {
        //     this.referralFormGroup.controls.Objects.setValue("6 其他");
        //     this.referralFormGroup.controls.ObjectsNote.enable();
        // } else {
        //     this.referralFormGroup.controls.Objects.setValue("A 轉介其他試辦計畫或方案");
        //     this.referralFormGroup.controls.ObjectsNote.disable();
        // }
        // if (!this.a_selected) {
        //     this.urgPatRea07_selected = false;
        // }
        //只要有變動選擇就清空下方設定重選
        //if (selValue == '') {
            // this.resetRadio251();
        //}

        this.optionControl(undefined, selValue, undefined, undefined, undefined, undefined, undefined);
    }

    resetRadio251() {
        this.referralFormGroup.controls.UrgPatNote.setValue("");
        this.referralFormGroup.controls.UrgPatRea.setValue("");
        this.referralFormGroup.controls.UrgPatOth.setValue("");
        this.referralFormGroup.controls.SpeCaseNote.setValue("");
        //this.referralFormGroup.controls.ReferralPur.disable();
        this.referralFormGroup.controls.ReferralPur.setValue(""); // <-- 是指C0254，無畫面欄位
    }

    radio252Change(evt: any): void {
        // this.systemCode0252 = evt.value;
    }

    radio253Change(evt: any): void {
        // this.systemCode0253 = evt.value;
    }

    radio254Change(evt: any): void {
      const selValue = evt.value.split(" ")[0];
      // this.systemCode0254 = evt.value;
        this.optionControl(undefined, undefined, selValue, undefined, undefined, undefined, undefined);
    }

    radio255Change(evt: any): void {
        const selValue = evt.value.split(" ")[0];
        // this.urgPatRea07_selected = (selValue == "07");
        this.optionControl(undefined, undefined, undefined, selValue, undefined, undefined, undefined);
    }

    btnEditClick() {
        if (this.referralFormGroup.controls.CC.disabled) {
            this.referralFormGroup.controls.CC.enable();
            this.isHelperPadOpened = true;
        } else {
            this.referralFormGroup.controls.CC.disable();
            this.isHelperPadOpened = false;
        }
    }

    isExist: number;
    async dxChanged(evt: ValueTextPair, withDx: string) {
        var code = evt?.value.toUpperCase();
        this.isExist = this.histRcd.Hist.Diags.findIndex(x => x.code == code);
        await this.setDx(code, withDx);
    }

    resortDx() {
        // 移除空的元素
        this.histRcd.Hist.Diags = this.histRcd.Hist.Diags.reduce((elem, i) => (i.code == '' || i.code == null || i.code == undefined) ? elem : [...elem, i], []);
        // 補滿List
        var diagLength = this.histRcd.Hist.Diags.length;
        if (diagLength < 3) {
            var d = 3 - diagLength;
            for(var i = 0; i < d; i++) {
                var elm = new HistDiag();
                elm.code = '';
                elm.name = '';
                this.histRcd.Hist.Diags.push(elm);
            }
        }
        // 指定給畫面控制項
        for (var i = 0; i < this.histRcd.Hist.Diags.length; i++) {
            var hd = this.histRcd.Hist.Diags[i];
            if (i == 0) {
                this.histRcd.Hist.Dx1Name = hd?.name || '';
                this.histRcd.Hist.Dx1 = hd?.code || '';
                this.referralFormGroup.controls.Dx.setValue(hd?.code || '');
                this.referralFormGroup.controls.DxName.setValue(hd?.name || '');
            } else if (i == 1) {
                this.histRcd.Hist.Dx2Name = hd?.name || '';
                this.histRcd.Hist.Dx2 = hd?.code || '';
                this.referralFormGroup.controls.Dx1.setValue(hd?.code || '');
                this.referralFormGroup.controls.DxName1.setValue(hd?.name || '');
            } else if (i == 2) {
                this.histRcd.Hist.Dx3Name = hd?.name || '';
                this.histRcd.Hist.Dx3 = hd?.code || '';
                this.referralFormGroup.controls.Dx2.setValue(hd?.code || '');
                this.referralFormGroup.controls.DxName2.setValue(hd?.name || '');
            }
        }
    }

    async setDx(code: string, withDx: string) {
        if (!code || code == '') {
            return;
        }
        if (this.isExist >= 0) {  // 已存在=>code清空使其查不到
            this.notification.showInfo('輸入的醫令:' + code + '已存在');
            code = '';
        }
        var dx = await this.histApi.getDiagWithICD9ByCode(code || '');
        // 檢查是否在this.dxOpt裡面
        if (dx && code && code != '') {
            var hasCode = this.dxOpt.findIndex(x => x.value.toUpperCase() == code);
            if (hasCode < 0) {
                var d = { text: dx.ICD9 + '　' +  dx.Code + '　' + dx.CName, value: dx.Code };
                this.dxOpt = [...this.dxOpt, d];
                this.dx1Opt = [...this.dx1Opt, d];
                this.dx2Opt = [...this.dx2Opt, d];
            }
        } else {
            this.notification.showInfo('輸入的醫令:不存在');
            code = '';
        }

        if (code == '') {
            if (withDx == 'dx') {
                this.histRcd.Hist.Dx1Name = '';
                this.histRcd.Hist.Dx1 = '';
                this.histRcd.Hist.Diags[0].name = '';
                this.histRcd.Hist.Diags[0].code = '';
                this.referralFormGroup.controls.Dx.setValue('');
                this.referralFormGroup.controls.DxName.setValue('');
            } else if (withDx == 'dx1') {
                this.histRcd.Hist.Dx2Name = '';
                this.histRcd.Hist.Dx2 = '';
                this.histRcd.Hist.Diags[1].name = '';
                this.histRcd.Hist.Diags[1].code = '';
                this.referralFormGroup.controls.Dx1.setValue('');
                this.referralFormGroup.controls.DxName1.setValue('');
            } else if (withDx == 'dx2') {
                this.histRcd.Hist.Dx3Name = '';
                this.histRcd.Hist.Dx3 = '';
                this.histRcd.Hist.Diags[2].name = '';
                this.histRcd.Hist.Diags[2].code = '';
                this.referralFormGroup.controls.Dx2.setValue('');
                this.referralFormGroup.controls.DxName2.setValue('');
            }
        } else {
            if (withDx == 'dx') {
                this.histRcd.Hist.Dx1Name = dx?.CName || '';
                this.histRcd.Hist.Dx1 = dx?.Code || '';
                this.histRcd.Hist.Diags[0].name = dx?.CName || '';
                this.histRcd.Hist.Diags[0].code = dx?.Code || '';
                this.referralFormGroup.controls.Dx.setValue(this.histRcd.Hist.Diags[0].code);
                this.referralFormGroup.controls.DxName.setValue(this.histRcd.Hist.Diags[0].name);
            } else if (withDx == 'dx1') {
                this.histRcd.Hist.Dx2Name = dx?.CName || '';
                this.histRcd.Hist.Dx2 = dx?.Code || '';
                this.histRcd.Hist.Diags[1].name = dx?.CName || '';
                this.histRcd.Hist.Diags[1].code = dx?.Code || '';
                this.referralFormGroup.controls.Dx1.setValue(this.histRcd.Hist.Diags[1].code);
                this.referralFormGroup.controls.DxName1.setValue(this.histRcd.Hist.Diags[1].name);
            } else if (withDx == 'dx2') {
                this.histRcd.Hist.Dx3Name = dx?.CName || '';
                this.histRcd.Hist.Dx3 = dx?.Code || '';
                this.histRcd.Hist.Diags[2].name = dx?.CName || '';
                this.histRcd.Hist.Diags[2].code = dx?.Code || '';
                this.referralFormGroup.controls.Dx2.setValue(this.histRcd.Hist.Diags[2].code);
                this.referralFormGroup.controls.DxName2.setValue(this.histRcd.Hist.Diags[2].name);
            }

            this.resortDx();
        }
    }
}
