import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ElementRef, HostListener } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { HistListItem } from '../models/hist-list-item';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HistHelperPadService } from '../services/hist-helper-pad.service';
import { HelperEvent } from '../models/helper-event';
import { WholeHistPatientsDto } from 'src/app/services/api-service/hist/whole-hist';

@Component({
  selector: 'app-hist-list',
  templateUrl: './hist-list.component.html',
  styleUrls: ['./hist-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistListComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private helperPadService: HistHelperPadService,
    private webApiFactory: WebApiClient,
    private cd: ChangeDetectorRef,
    private el:ElementRef) {
    this.api = this.webApiFactory.createHisService('hist/histview');
    }

  //#region 參數 --------------------
  private unsubscribe: Subject<void> = new Subject();
  // api服務
  api: WebApiService;
  //#endregion 參數 --------------------

  //#region 參數 grid --------------------
  selectedMemberId = 0;
  familyMemberName:{name:string,id:number}[];
  familyMemberNameDisplay:{name:string,id:number}[];
  familyMemberNameDisplayCount = 8;
  showPrev = false;
  showNext = false;
  // 全部
  allItems: HistListItem[];
  // 清單物件
  listItems: HistListItem[];

  // 表格-所選項目
  selectedItems: string[] = [];
  // 表格-分頁
  gridView: any;
  pageSize = 31;
  pageSkipNo = 0;
  //#endregion 參數 grid --------------------

  //#region opd-record --------------------
  @Input() patient: WholeHistPatientsDto;
  @Output() emitSelect = new EventEmitter<string>();
  @Output() emitSelectToInsert = new EventEmitter<string>();
  // @Output() emitHistList = new EventEmitter<HistListItem[]>();
  //#endregion opd-record --------------------

  ngOnInit() {
    this.refreshList();
  }
  @HostListener('window:resize',['$event'])
  onResize(){
    this.resetMemberDisplay();
  }

  //#region 清單
  // 更新清單
  refreshList() {
    if (!this.patient?.Id) {
      return;
    }
    this.api.get('getAll/' + this.patient?.Id).pipe(takeUntil(this.unsubscribe)).subscribe(
      (data: HistListItem[]) => {
        this.listItems = this.handleListData(data);
        // 表格分頁
        this.setGridView();
        // this.emitHistList.emit(this.listItems);
        if (this.listItems && this.listItems[0]) {
          this.emitSelectHist(this.listItems[0].Id, false);
        }
      },
      error => this.notification.showGetDataFailed('hist-list') // 失敗訊息
    );
  }

  handleListData(data: HistListItem[]) {
    this.allItems = data;
    // 測試用
    //this.familyMemberName = Array(10).fill({}).map((x,i)=>{return {name:this.patient.CName+(i?i:''),id:this.patient.Id+i*100};});
    this.familyMemberName = [];//[{name:this.patient.CName,id:this.patient.Id}];
    data.forEach(d=>{
      if(d.PatientId!=this.patient.Id && !this.familyMemberName.find(f=>f.id==d.PatientId)){
        this.familyMemberName.push({name:d.Name,id:d.PatientId});
      }
    });

    this.resetMemberDisplay();
    this.selectedMemberId = this.patient?.Id;
    return data.filter(d=>d.PatientId == this.patient?.Id);
  }
  resetMemberDisplay(){
    this.familyMemberNameDisplayCount = Math.ceil((this.el.nativeElement.offsetWidth - 50) / 50);
    this.familyMemberNameDisplay = this.familyMemberName.slice(0,this.familyMemberNameDisplayCount);
    // console.log('familyMemberNameDisplayCount',this.familyMemberNameDisplayCount);
  }
  changeList(id:number){
    this.selectedMemberId = id;
    this.listItems = this.allItems.filter(d=>d.PatientId == id);
    this.pageSkipNo = 0;
    this.setGridView();
  }
  prev(){
    var firstItem = this.familyMemberNameDisplay[0];
    var itemIndex = this.familyMemberName.indexOf(firstItem);
    var prevInd = itemIndex-1;
    if(prevInd<0){
      return ;
    }
    this.familyMemberNameDisplay = this.familyMemberName.slice(prevInd,prevInd+this.familyMemberNameDisplayCount);
    this.cd.detectChanges();
  }
  next(){
    var firstItem = this.familyMemberNameDisplay[0];
    var itemIndex = this.familyMemberName.indexOf(firstItem);
    var nextInd = itemIndex+1;
    if(nextInd + this.familyMemberNameDisplayCount > this.familyMemberName.length){
      return ;
    }
    this.familyMemberNameDisplay = this.familyMemberName.slice(nextInd,nextInd+this.familyMemberNameDisplayCount);
    this.cd.detectChanges();
  }
  //#endregion

  //#region grid --------------------
  // 表格分頁
  setGridView() {
    console.log("表格分頁",this.listItems);
    this.gridView = {
      data: this.listItems.slice(this.pageSkipNo, this.pageSkipNo + this.pageSize),
      total: this.listItems.length
    };
    this.cd.detectChanges();
  }
  // 分頁切換事件
  pageChange(event: any): void {
    this.pageSkipNo = event.skip; // kendo grid page用法
    this.setGridView(); // 因為目前沒分頁，資料也不多，不再向後端要資料
    // this.refreshList(); // 更新清單，因為server端資料可能異動
  }

  //#endregion grid --------------------

  //#region grid edit --------------------
  cellClickHandler(evt): void {
    if(evt.selectedRows.length>0){
      this.emitSelectHist(evt.selectedRows[0].dataItem.Id);
    }
  }

  cellDblClickHandler(evt): void {
    if(evt.selectedRows.length>0){
      this.emitSelectToInsertHist(evt.selectedRows[0].dataItem.Id);
    }
  }
  //#endregion grid edit --------------------

  //#region opd-record --------------------
  emitSelectHist(histId: string, isSendToPad = true) {
    this.emitSelect.emit(histId);
    // 通知helper pad元件
    if (isSendToPad) {
      //this.helperPadService.sendEvent(HelperEvent.HistListSelectEvent());
      this.helperPadService.Command_Close();
    }
  }

  emitSelectToInsertHist(histId: string, isSendToPad = true) {
    this.emitSelectToInsert.emit(histId);
    // 通知helper pad元件
    if (isSendToPad) {
      //this.helperPadService.sendEvent(HelperEvent.HistListSelectEvent());
      this.helperPadService.Command_Close();
    }
  }
  //#endregion opd-record --------------------

  bodyHeight = document.body.clientHeight;
  /** 取得就病歷列表合適卷軸高度 */
  getHistListHeight(): number {
    let histListHeight = this.bodyHeight*.38 - 12;

    /** 1920 */
    if (window.innerWidth >= 1920 ) {
      return histListHeight - 1;
    } else return histListHeight;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
