<!-- 存入範本視窗 -->
<app-vis-dialog title="儲存範本" [show]="isTmplWinOpened" (close)="onTmplWinClose(false)" [width]="350"
  i18n-title="@@HistSaveTemplate">
  <form *ngIf="formHelper" class="vis-form extend-msg vis-form-narrow" [formGroup]="tmplFG">
    <div class="row narrow-padding">
      <div class="col-sm-12 vis-form-field">
        <span i18n="@@HistTemplateName">範本名稱</span>
        <input class="vis-input" formControlName="name" (change)="onTmplNameChange($event)">
        <app-validation-tip controlName="name"></app-validation-tip>
      </div>
      <div class="col-sm-12 vis-form-field">
        <span i18n="@@HistTemplateCode">範本代號</span>
        <input class="vis-input" formControlName="code" (change)="onTmplCodeChange($event)">
        <app-validation-tip controlName="code"></app-validation-tip>
      </div>
      <div class="col-sm-12 vis-form-field">
        <span i18n="@@HistTemplateCode">儲存內容</span>
        <div style="display: flex;column-gap: 20px;padding-left: 20px;">
          <mat-checkbox formControlName="includeCC" [labelPosition]="'after'">主訴</mat-checkbox>
          <mat-checkbox formControlName="includePE" [labelPosition]="'after'">理學</mat-checkbox>
          <mat-checkbox formControlName="includeDX" [labelPosition]="'after'">診斷</mat-checkbox>
          <mat-checkbox formControlName="includeRX" [labelPosition]="'after'">醫令</mat-checkbox>
        </div>
      </div>
      <div class="col-sm-12 vis-form-field">
        <mat-checkbox id="isTemplateShared" formControlName="isShared"
          [labelPosition]="'before'">是否為院所共用範本</mat-checkbox>
      </div>
      <div class="col-sm-12 vis-form-field" *ngIf="formHelper.Value.errorMessage">
        <div class="error-color">
          {{formHelper.Value.errorMessage}}
        </div>
      </div>
    </div>
    <!--./row-->
  </form>
  <app-vis-dialog-actions>
    <button type="button" (click)="onTmplWinClose(true)" [disabled]="lockSaveTmp" primary="true"
      i18n="@@Save">儲存</button>
    <button type="button" (click)="onTmplWinClose(false)" i18n="@@Cancel">取消</button>
  </app-vis-dialog-actions>
</app-vis-dialog>

<ng-template let-data #tmplView>
    <div class="extend-label">是否以 <span style="color: deepskyblue;">[{{data.current.Code}} - {{data.current.Name}}]</span>
      覆蓋同代碼範本 <span style="color: red">[{{data.tmp.Code}} - {{data.tmp.Name}}]</span>？</div>
    <div style="display: flex;" class="inside-scrollbar px-0" style="max-height: 500px;">
      <div style="padding: 10px;width:50%;border: 2px solid deepskyblue;" class="float-left">
        <span style="color: deepskyblue;" class="extend-label"> 新 [{{data.current.Code}} - {{data.current.Name}}]</span>
        <app-shared-hist-view-content style="display: block;" class="view-list-in-template"
          [MainSet]="data.current"></app-shared-hist-view-content>
      </div>
      <div style="padding: 10px;width:50%;border: 2px solid red;" class="float-left">
        <span style="color: red;" class="extend-label"> 原 [{{data.tmp.Code}} - {{data.tmp.Name}}]</span>
        <app-shared-hist-view-content style="display: block;" class="view-list-in-template"
          [MainSet]="data.tmp"></app-shared-hist-view-content>
      </div>
    </div>
  </ng-template>