export class MedicalVisitPatientDataDto {
  queryMonth: Date;
  startDate: Date;
  endDate: Date;
  clinicId: number;
  startDateDisplay: Date;
  endDateDisplay: Date;
  ReportTime: Date;
  ClinicName: string;
  ClinicCity: string;
  ClinicAddress: string;
  ClinicPhone: string;
  NHICode: string;
  Items: MedicalVisitPatientDataItemDto[];
}

export class MedicalVisitPatientDataItemDto {
  SerialNumber: string;
  VisitDate: Date;
  PatientMastCId: string;
  PatientMastCName: string;
  NHIDeclareCase: string;
  PatientHISRemarkOne: string;
  ClinicCity: string;
  VisitPerson: string;
  DoctorCId: string;
  VisitTime: string;
  IsSummary: boolean;
}
