/** 診所類別 enum */
export enum ClinicTypeEnum {
    /** 西醫 */
    WesternClinic = 1,
    /** 中醫 */
    ChineseClinic = 2,
    /** 牙醫 */
    DentistClinic = 3,
    /** 牙醫 */
    WesternAndDentistClinic = 4,
    /** 醫院降級 */
    HospitalDowngrade = 5,
    /** 醫美 */
    MedicalBeauty = 6,
    /** 居護 */
    HomeCare = 10,
    /** 產後護理之家(月子中心) */
    PostpartumCare = 11,
    /** 長期照護 */
    LongTermCare = 12,
    /** 日照中心 */
    DayCare = 13,
    /** 藥局 */
    Drugstore = 20,
    /** 其他交付機構(NHIType=9,A)  檢驗所/物理治療所 */
    Delivery = 21,
    /** 其他類型(NHIType=8,B)  助產/放射 */
    Other = 21,
    /** 公司 */
    Company = 90
}
export const ClinicTypeDesc: {text: string, value: number}[] =
    [{text: '西醫', value: 1 },
     {text: '中醫', value: 2 },
     {text: '牙醫', value: 3 },
     {text: '西牙醫', value: 4 },
     {text: '醫院降級', value: 5 },
     {text: '醫美', value: 6 },
     {text: '居護', value: 10 },
     {text: '產後護理之家(月子中心)', value: 11 },
     {text: '長期照護', value: 12 },
     {text: '日照中心', value: 13 },
     {text: '藥局', value: 20 },
     {text: '其他交付機構', value: 21 },
     {text: '其他類型', value: 21 },
     {text: '公司', value: 90 }];
/** 銀行別 */
export enum BankAccountTypeEnum {
    /** 臺灣銀行 */
    B004 = 4,
    /** 土地銀行 */
    B005 = 5,
    /** 合庫商銀 */
    B006 = 6,
    /** 第一銀行 */
    B007 = 7,
    /** 華南銀行 */
    B008 = 8,
    /** 彰化銀行 */
    B009 = 9,
    /** 上海銀行 */
    B011 = 11,
    /** 台北富邦 */
    B012 = 12,
    /** 國泰世華 */
    B013 = 13,
    /** 高雄銀行 */
    B016 = 16,
    /** 兆豐商銀 */
    B017 = 17,
    /** 農業金庫 */
    B018 = 18,
    /** 花旗銀行 */
    B021 = 21,
    /** 美國銀行 */
    B022 = 22,
    /** 首都銀行 */
    B025 = 25,
    /** 澳盛銀行 */
    B039 = 39,
    /** 臺灣企銀 */
    B050 = 50,
    /** 渣打商銀 */
    B052 = 52,
    /** 台中銀行 */
    B053 = 53,
    /** 京城商銀 */
    B054 = 54,
    /** 德意志銀行 */
    B072 = 72,
    /** 東亞銀行 */
    B075 = 75,
    /** 匯豐銀行 */
    B081 = 81,
    /** 瑞興銀行 */
    B101 = 101,
    /** 華泰銀行 */
    B102 = 102,
    /** 臺灣新光商銀 */
    B103 = 103,
    /** 陽信銀行 */
    B108 = 108,
    /** 板信銀行 */
    B118 = 118,
    /** 三信銀行 */
    B147 = 147,
    /** 中華郵政 */
    B700 = 700,
    /** 聯邦銀行 */
    B803 = 803,
    /** 遠東銀行 */
    B805 = 805,
    /** 元大銀行 */
    B806 = 806,
    /** 永豐銀行 */
    B807 = 807,
    /** 玉山銀行 */
    B808 = 808,
    /** 凱基銀行 */
    B809 = 809,
    /** 星展銀行 */
    B810 = 810,
    /** 台新銀行 */
    B812 = 812,
    /** 大眾銀行 */
    B814 = 814,
    /** 日盛銀行 */
    B815 = 815,
    /** 安泰銀行 */
    B816 = 816,
    /** 中國信託 */
    B822 = 822,
    /** 其他 */
    Other = 999
}
export const BankAccountTypeDesc: {text: string, value: number}[] =
    [{text: '臺灣銀行', value: 4 },
     {text: '土地銀行', value: 5 },
     {text: '合庫商銀', value: 6 },
     {text: '第一銀行', value: 7 },
     {text: '華南銀行', value: 8 },
     {text: '彰化銀行', value: 9 },
     {text: '上海銀行', value: 11 },
     {text: '台北富邦', value: 12 },
     {text: '國泰世華', value: 13 },
     {text: '高雄銀行', value: 16 },
     {text: '兆豐商銀', value: 17 },
     {text: '農業金庫', value: 18 },
     {text: '花旗銀行', value: 21 },
     {text: '美國銀行', value: 22 },
     {text: '首都銀行', value: 25 },
     {text: '澳盛銀行', value: 39 },
     {text: '臺灣企銀', value: 50 },
     {text: '渣打商銀', value: 52 },
     {text: '台中銀行', value: 53 },
     {text: '京城商銀', value: 54 },
     {text: '德意志銀行', value: 72 },
     {text: '東亞銀行', value: 75 },
     {text: '匯豐銀行', value: 81 },
     {text: '瑞興銀行', value: 101 },
     {text: '華泰銀行', value: 102 },
     {text: '臺灣新光商銀', value: 103 },
     {text: '陽信銀行', value: 108 },
     {text: '板信銀行', value: 118 },
     {text: '三信銀行', value: 147 },
     {text: '中華郵政', value: 700 },
     {text: '聯邦銀行', value: 803 },
     {text: '遠東銀行', value: 805 },
     {text: '元大銀行', value: 806 },
     {text: '永豐銀行', value: 807 },
     {text: '玉山銀行', value: 808 },
     {text: '凱基銀行', value: 809 },
     {text: '星展銀行', value: 810 },
     {text: '台新銀行', value: 812 },
     {text: '大眾銀行', value: 814 },
     {text: '日盛銀行', value: 815 },
     {text: '安泰銀行', value: 816 },
     {text: '中國信託', value: 822 },
     {text: '其他', value: 999 }];

