import { OrderTab } from './order-tab';
import { HistOrder } from './hist-order';
import { ValidateMsg } from './hrx-validate-msg';

export class OrderData {
    constructor() {
        this.tab = new OrderTab();
        this.orders = [];
        this.err = []
    }
    tab: OrderTab;
    orders: HistOrder[];
    triggerFocusEvent: boolean;
    err:ValidateMsg[];
}
