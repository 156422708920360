import { AfterViewInit, ChangeDetectorRef,Component,Input, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { CtrDrugsReport, CtrDrugsReportItem } from 'src/app/opd/opd-report/models/ctrdrugs-report';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { DeclareApi, SameTreatPerMonthListDTO } from 'src/app/services/api-service/declare/declare-api';
import { setDate } from 'ngx-bootstrap/chronos/utils/date-setters';

@Component({
    selector: 'app-report-sameTreat-per-month',
    templateUrl: './sameTreat-per-month.component.html',
    styleUrls: ['./sameTreat-per-month.component.css'],

})
export class SameTreatPerMonthReportComponent implements OnInit, AfterViewInit {
  sizePage = {
    width: 29.2,//20.5, //cm
    height: 20.5//28.2 //cm
};
paddingPage = {
    top: -0.2, //cm
    right: 0, //cm
    bottom: 0.2, //cm
    left: 0 //cm
};
heightPageWithoutPadding = this.convertCmtoPx(
  this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)-0.5
);
  @ViewChild('reportContainer', { static: false })
  reportContainer: ElementRef<HTMLDivElement>;
  @Input() data;

  @Input()
  set regId(id){
    this.setRegId(id);
  }
  @Input()
  set declareMonth(value) {
    if (value) {
      this.declareMonthValue = value;
    }
  }
  elContainer;
  upperboundIndex: number = 0;
  declareMonthValue: Date;
  ipage= '';
  printTime = '';
  periodYYYYMM = '';
  clinic: Clinic;
  listItems: SameTreatPerMonthListDTO[] = [];
  pageContent = [[]];

  // 查詢條件;
  queryFormGroup: FormGroup;
  // data: ConsultationVolumeReportDTO[];

  constructor(private cdr: ChangeDetectorRef,
              private webApiClient: WebApiClient,
              private declareApi: DeclareApi,
              private localPrintService: LocalPrintService,
              private notification: EasyNotificationService) {

  }
  indexArray: number[];
  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
        '.pres-report2 table{ border:0px solid black; font-size:12pt;}\n' +
        // '.pres-report2 table td, table th { border: 1px solid black;}\n' +
        '@page { size: A4 landscape; margin: 0cm 0cm; }\n'
        // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
    this.indexArray = Array.from({length: 31}, (_, i) => i);
  }

  ngAfterViewInit() {

  }
  @Input()
  set isPrint(value) {
    if (value) {
      this.onPrint();
    }
  }
  @Input()
  set isPreviewPrint(value) {
    if (value) {
        this.fetchData();
    }
  }

  public async setRegId(regId:number)
  {
    this.clinic = UserCache.getLoginUser().Clinic;

    const query = {
      ClinicId: this.clinic.Id,
      RegId: regId
    };
    await this.fetchData();

  }

  sameTreatPerMonthList : SameTreatPerMonthListDTO[] = [];
  firstDay = '';
  // 取得統計期間的看診人次資料
   async fetchData() {
    this.listItems = await this.declareApi.GetSameTreatPerMonthList( this.declareMonthValue,'0');
    this.elContainer = document.getElementById("container");
    if (this.listItems.length > 0){
      let lastDay = new Date(this.declareMonthValue);
      let dayOfWeek = lastDay.getDay();
      this.firstDay = this.daysOfWeek[dayOfWeek];
      lastDay.setMonth(this.declareMonthValue.getMonth() + 1);
      lastDay.setDate(0);
      let sDate = DateHelper.formatROCDate(this.declareMonthValue);
      let eDate = DateHelper.formatROCDate(lastDay);
      let printTime = DateHelper.formatROCDate(new Date(),true,true);
      this.printTime = printTime;
      this.periodYYYYMM = sDate +' 至 ' + eDate;
      this.insertListData();
    }
    else
    {
      this.printTime = '';
      this.periodYYYYMM = '';
    }
    // console.log('result',this.sameTreatPerMonthList);
    // this.cdr.markForCheck();
  }
  insertListData() {
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
      /** 用a4 能列印的筆數算的 目前能容納46筆資料 如果字型變大 這裡也要修改*/
    var lineheight = 0;
    var prevTableContent: any;
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
        var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
        elTableContent.innerHTML += html_ListerHeader;
        for (let i = 0; i < this.listItems.length; i++) {
            // 加入 List 每一筆的內容
            prevTableContent = elTableContent.innerHTML;
            elTableContent.innerHTML += this.createHTMLBlock(this.listItems[i]);
            lineheight +=1;
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (lineheight >= 32) {
              lineheight = 0;
              // console.log("逐筆+++++", iPage);
              elTableContent.innerHTML = prevTableContent;
              iPage++;

                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
            iBlock += 1;
    }
    this.upperboundIndex = iPage;
}
createHTMLPageHeader(iPage) {
  return `<div id="header" >
              <div style="display: flex;">
                <table style="width: 100%; border: none;">
                  <tr>
                      <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        同療程月報表(日期)
                      </td>
                  </tr>
                  <tr>
                      <td colspan="1" style="width:450px; font-size: 12pt; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      列印時間： ` + this.printTime + `
                      </td>
                      <td colspan="3" style="font-size: 12pt; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                        期間： ` + this.periodYYYYMM + `
                      </td>
                      <td colspan="2" style="font-size: 12pt; text-align: right; padding-top: 5px;padding-right: 7.5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                          頁 ` + (iPage + 1) + `
                      </td>
                  </tr>
                </table>
              </div>
          </div>
          <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.3;margin: 0px 5px; text-align: center;">
              <label style="font-weight: bold; height: 23px;">　</label>
          </div>
          <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 5px;">
              <div style="display: flex;column-gap: 1px;">
              <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; border: none;border-collapse: collapse;">
              </table>
              </div>
          </div>`;
}
createHTMLBlock(data: SameTreatPerMonthListDTO) {
  var patientNo = this.patientNoFactory(data?.PatientNo);
  let addrow = ''
  let sum=0;
    for(var i =0 ;i < this.indexArray.length;i++){
      if(data.PatientNo=="累計次數"){
        sum+= data.CusNoPerMonth[i][1];
      }
       addrow += '<td style="line-height: 12px;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;border: 1px solid black;text-align: center;">' +(data.PatientNo=="累計次數"?data.CusNoPerMonth[i][1]:this.timesFactory(data.CusNoPerMonth[i][1])) + '</td>'

    }
  return  '<tr>' +
              '<td style="line-height: 12px;margin: 0px 0px 0px 0px;  padding: 6px 2px 6px 2px;border: 1px solid black;">'+patientNo+'</td>'+
              '<td style="line-height: 12px;text-align:left;border: 1px solid black;">'+data.PatientName+'</td>'+
              addrow +
              '<td style="line-height: 12px;text-align:right;border: 1px solid black;">'+(data.PatientNo=="累計次數"?sum:'')+'</td>'+
          '</tr>' ;
}
  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.6);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
}
daysOfWeek = ['日', '一', '二', '三', '四', '五', '六'];
createHTMLListHeader() {
    let row = '';
    let dayInd = this.daysOfWeek.indexOf(this.firstDay);
    for(var i =0 ;i < this.indexArray.length;i++){
      let weeeekDay = '';
      if (i == 0) weeeekDay =  this.firstDay;
      else {
         dayInd++;
         if (dayInd == 7)dayInd = 0;
         weeeekDay = this.daysOfWeek[dayInd];
      }
      //console.log(dayInd,weeeekDay);
      let num = this.indexArray[i] +1 > 9 ? (this.indexArray[i] +1).toString() : ' '+(this.indexArray[i] +1).toString();
      row += '<th style="line-height: 20px;min-width:15px;max-width: 15px;margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;border: 1px solid black;text-align: center;">'+ num+'<br>' +weeeekDay+'</th>'
    }

    return `<tbody>
    <tr  style="margin: 0px 0px 0px 0px; padding: 0px 0px 0px 0px;">
    <th style="width: 70px;line-height: 20px;margin: 0px 0px 0px 0px;border: 1px solid black;">
      病歷號
    </th>
    <th style="width: 85px;line-height: 20px;text-align:left;border: 1px solid black;">
      姓名
    </th>` + row +
    `<th style="width: 50px;line-height: 20px;text-align:left;border: 1px solid black;">
    日<br>星期
    </th>`+ `
  </tr></tbody>`;
}

  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
      return Math.round((cm * 96) / 2.54);
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;

    return html;
  }
  timesFactory(item : number):string{
    let time = '';
    time = item > 0 ? item.toString(): '';
    return time;
  }
  patientNoFactory(item : string):string{
    let pNo = '';
    pNo = item.length > 7 ? item.substring(item.length - 7): item;
    return pNo;
  }
  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = '同療程次數月報表';
      let printContents = this.getHtml();
      for (var i = 0; i <= this.upperboundIndex; i++) {
        printContents = printContents.replace('box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.6);','');
        printContents = printContents.replace('box-sizing: border-box;','');
      }
      0.7
      // printContents = printContents.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }
}
