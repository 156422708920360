import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { DeclareApi, NHIDownloadRequestDTO, NHISendRequestDTO } from 'src/app/services/api-service/declare/declare-api';
import { CnhiEiiService } from 'src/app/services/cnhi-eii.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { UserCache } from 'src/app/services/user-cache';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { ValidationTipComponent } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { Compare } from 'src/app/shared/utilities';

@Component({
  selector: 'app-cnhi-eii-test',
  templateUrl: './cnhi-eii-test.component.html',
  styleUrls: ['./cnhi-eii-test.component.css']
})
export class CnhiEiiTestComponent implements OnInit {

  onClose:EventEmitter<true> = new EventEmitter();

  apiType:'send'|'download'|'get' = 'send'

  formSend: FormHelper<SendBDto>;
  formDownload: FormHelper<DownloadBDto>;
  formGet: FormHelper<GetBDto>;


  SendTypeCodes:ValueTextPair[]=[
    { value:'03',text:'醫費申報資料 XML 格式'},
    { value:'05',text:'預檢醫費申報資料 XML 格式'},
    { value:'07',text:'醫療費用電子申復資料'},
    { value:'09',text:'預檢醫療費用電子申復資料'},
    { value:'26',text:'檢驗(查)每日上傳資料 XML 格式'},
  ]

  DownloadTypeCodes:ValueTextPair[]=[
    { value:'03',text:'醫費申報'},
    { value:'07',text:'申復醫費申報'},

    { value:'05',text:'預檢醫費申報'},
    { value:'09',text:'預檢申復醫費申報'},

    { value:'06',text:'抽樣回饋'},
  ]

  DownloadHospTypeCodes:ValueTextPair[]=[
    {value:'11',text:'門診西醫診所'},
    {value:'12',text:'門診西醫醫院'},
    {value:'13',text:'門診牙醫'},
    {value:'14',text:'門診中醫'},
    {value:'15',text:'門診洗腎'},
    {value:'19',text:'門診其他醫事機構'},
    {value:'21',text:'住診西醫診所'},
    {value:'22',text:'住診西醫醫院'},
    {value:'29',text:'住診其他醫事機構'},
    {value:'30',text:'特約藥局'},
    {value:'40',text:'物理治療所'},
    {value:'50',text:'特約檢驗所'},
  ]
  DeclareTypeCodes:ValueTextPairNumberValue[]=[
    { value:1,text:'送核'},
    { value:2,text:'補報'},
    { value:4,text:'申復送核'},
    { value:5,text:'申復補報'}
  ]

  MonthNoteTypeCodes:ValueTextPair[]=[
    { value:'1',text:'上半月'},
    { value:'2',text:'下半月'},
    { value:'3',text:'全月'},
    { value:'0',text:'不分(申復使用)'}
  ]
  downloadRequest:NHIDownloadRequestDTO[] = [];
  
  send:SendListModel[] = [];
  constructor(private fs:FormHelperService,
    private hcrService:HcrService,
    private notification:EasyNotificationService,
    private mainLayout:MainLayoutService,
    private cnhi_eii:CnhiEiiService,
    private declareApi:DeclareApi,
    private userConfirm:UserConfirmService) {

   }

  async ngOnInit(){
    var hospId = await this.hcrService.VisionApi.GetHospID();

    this.formSend = this.fs.Create<SendBDto>().buildPartial({
      fileName:['',[Validators.required]],
      portNo:[0,[Validators.required]],
      typeCode:['03',[Validators.required]],
    })
    this.formDownload = this.fs.Create<DownloadBDto>().buildPartial({
      fileName:['',[Validators.required]],
      portNo:[0,[Validators.required]],
      typeCode:['03',[Validators.required]],
      FEE_YM:['',[Validators.required]],
      hospId:[hospId.Data,[Validators.required]],
      APPL_TYPE:['03',[Validators.required]],
      HOSP_DATA_TYPE:['11',[Validators.required]],
      APPL_DATE:['',[Validators.required]],
      seq:['001',[Validators.required]]
    })
    this.formGet = this.fs.Create<GetBDto>().buildPartial({
      downloadPath:['',[Validators.required]],
      portNo:[0,[Validators.required]],
    })
    this.getCnhiEiiTestHeight();
    await this.getSend();
    await this.getDownload();
  }
  async getSend(){
    var clinicId = UserCache.getLoginClinicId();
    var ret = await this.declareApi.GetNHISendRequest(clinicId)
    
    this.send = ret.sort((a,b)=>Compare(a.RequestTime,b.RequestTime)).map(x=>{
      var fileName = x.Path.substring(x.Path.lastIndexOf('\\')+1);
      
      var hospId = ''
      var hospTp = '不明';
      var feeYM = '不明';
      var applyTp = '不明';
      var monthNote = '不明';
      if(fileName.length>19){
        hospId = fileName.substring(0,10);
        hospTp = fileName.substring(10,12);
        hospTp = `[${hospTp}]${this.DownloadHospTypeCodes.find(y=>y.value==hospTp).text}`;
        feeYM = fileName.substring(12,17);
        applyTp = fileName.substring(17,18);
        applyTp = `[${applyTp}]${this.DeclareTypeCodes.find(y=>y.value.toString()==applyTp).text}`;
        monthNote = fileName.substring(18,19);
        monthNote = `[${monthNote}]${this.MonthNoteTypeCodes.find(y=>y.value.toString()==monthNote).text}`;
      }
      
      return {
        typeCode:x.TypeCode,
        reqDt:x.RequestTime,
        fileName:x.Path,
        localId:x.sLocal_ID,
        nhiId:x.sNHI_ID,
        hospId:hospId,
        HOSP_DATA_TYPE:hospTp,
        FEE_YM:feeYM,
        APPL_TYPE:applyTp,
        Month_Note:monthNote,
      }
    })
    this.downloadRequest.forEach(x=>x.Timestamp = DateHelper.formatDateTime(new Date(x.Timestamp)))
  }
  async getDownload(){
    var clinicId = UserCache.getLoginClinicId();
    this.downloadRequest = await this.declareApi.GetNHIDownloadRequest(clinicId)
    this.downloadRequest.forEach(x=>x.Timestamp = DateHelper.formatDateTime(new Date(x.Timestamp)))
  }
  async SendSelectFile(){
    var file = await this.hcrService.SelectFiles();
    if(file){
      this.formSend.patchValue({
        fileName:file.FullName
      });
    }
  }

  async SendB(){

    var clinicId = UserCache.getLoginClinicId();
    var port = this.formSend.Value.portNo;
    var typeCode = this.formSend.Value.typeCode;
    var path = this.formSend.Value.fileName;
    var fileName = path.substring(path.lastIndexOf('\\'));
    var check = await this.declareApi.CheckNHISend(clinicId,fileName);
    if(check==true){
      var conf = await this.userConfirm.showConfirm({
        title:'上傳作業',
        msg:'檔案名稱與先前的作業有重複，是否繼續進行',
      });
      if(!conf){
        return;
      }
    }
    this.mainLayout.EnableLoaderTimeout = false;
    this.mainLayout.showLoader();
    var ret = await this.hcrService.VisionApi.CNHIEiiSend(port,typeCode,path)
    if(ret.StatusCode!=0){
      this.notification.showError(ret.ErrorMsg);
    }else{
      this.formSend.patchValue({
        localId:ret.Data.LocalID,
        nhiId:ret.Data.NHIID
      });
      // 寫!
      await this.declareApi.NHISendRequest(clinicId,typeCode,path,ret.Data.LocalID,ret.Data.NHIID);
      await this.getSend();
    }
    this.mainLayout.hideLoader();
    this.mainLayout.EnableLoaderTimeout = true;
  }

  async DownloadB(){

    var port = this.formDownload.Value.portNo;
    var typeCode = this.formDownload.Value.typeCode;
    var path = this.formDownload.Value.fileName;
    var hospId = this.formDownload.Value.hospId;
    var FEE_YM = this.formDownload.Value.FEE_YM;
    var HOSP_DATA_TYPE = this.formDownload.Value.HOSP_DATA_TYPE;
    var APPL_TYPE = this.formDownload.Value.APPL_TYPE;
    var APPL_DATE = this.formDownload.Value.APPL_DATE;
    var dt = DateHelper.formatROCDate(new Date(),false,false).padStart(7,'0');
    var seq = this.formDownload.Value.seq.padStart(3,'0');
    this.mainLayout.EnableLoaderTimeout = false;
    this.mainLayout.showLoader();

    var accessDateTime = DateHelper.formatROCDate(new Date(),false,true)
    var queryFileName = `${hospId}-${dt}-${seq}.txt`;
    // 查詢條件寫檔
    var saveRet = await this.hcrService.SaveTextAs(`declare_tmp\\${accessDateTime}\\${queryFileName}`,
      `FEE_YM=${FEE_YM}\n`+
      `APPL_TYPE=${APPL_TYPE}\n`+
      `HOSP_DATA_TYPE=${HOSP_DATA_TYPE}\n`+
      `APPL_DATE=${APPL_DATE}`);

    var path:string = saveRet.Returns;

    // 提交下載條件
    var ret = await this.hcrService.VisionApi.CNHIEiiDownload(port, typeCode, path)

    if(ret.StatusCode!=0){
      this.notification.showError(ret.ErrorMsg);
    }else{
      // 寫!
      var clinicId = UserCache.getLoginClinicId();
      await this.declareApi.CreateNHIDownloadRequest(clinicId,typeCode,FEE_YM,ret.Data.LocalID,ret.Data.NHIID,queryFileName)
      // 拿
      //var downloadFolder =path.substring(0,path.lastIndexOf('\\'));
      // var getRet = await this.hcrService.VisionApi.CNHIEiiGet(0,downloadFolder,ret.Data.LocalID,ret.Data.NHIID);

      // if(getRet.StatusCode!=0){
      //   this.notification.showError(getRet.ErrorMsg);
      //   return false;
      // }
      await this.getDownload();
    }
    this.mainLayout.hideLoader();
    this.mainLayout.EnableLoaderTimeout = true;
  }
  async GetB(item:NHIDownloadRequestDTO){
      var c= await this.userConfirm.showConfirm({
        title:'警告！',
        msg:'使用API進行檔案取得需約5分鐘左右的等待，期間將暫時無法進行其他操作(重新整理網頁無法也取消)。是否要繼續進行？'
      })
      if(!c){
        return
      }
      var port = this.formGet.Value.portNo;
      var downloadFolder = this.formGet.Value.downloadPath;
      var localId = item.sLocal_ID;
      var nhiid = item.sNHI_ID;
      this.mainLayout.EnableLoaderTimeout = false;
      this.mainLayout.showLoader();
      var getRet = await this.hcrService.VisionApi.CNHIEiiGet(port,downloadFolder,localId,nhiid);

      if(getRet.StatusCode!=0){
        this.notification.showError(getRet.ErrorMsg);

      } else{
        this.notification.showSuccess('下載至 ' + downloadFolder);
      }

      this.mainLayout.hideLoader();
      this.mainLayout.EnableLoaderTimeout = true;
  }
  async GetSelectFolder(){
    var file = await this.hcrService.SelectFolder();
    if(file){
      this.formGet.patchValue({
        downloadPath:file.FullName
      });
    }
  }
  close(){
    this.onClose.emit(true);
  }

  bodyHeight = document.body.clientHeight;
  cnhiEiiTestHeight:string = '';
  cnhiEiiTestInsideHeight:string = '';
  getCnhiEiiTestHeight() {
    const useBodyHeight = this.bodyHeight - 60;
    this.cnhiEiiTestHeight = useBodyHeight + 'px';
    this.cnhiEiiTestInsideHeight = useBodyHeight - 30 + 'px';
  }
  hasResize;
  @HostListener('window:resize', ['$event'])
	onResize(event) {
    this.bodyHeight = document.body.clientHeight;
		this.getCnhiEiiTestHeight();
	}
}

interface SendBDto{
  portNo:number;
  fileName:string;
  typeCode:string;
  localId:string;
  nhiId:string;
}

interface DownloadBDto{
  portNo:number;
  fileName:string;
  typeCode:string;
  localId:string;
  nhiId:string;
  FEE_YM:string;
  APPL_TYPE:string;
  HOSP_DATA_TYPE:string;
  APPL_DATE:string;
  hospId:string
  seq:string;
}

interface GetBDto{
  portNo:number;
  downloadPath:string;
  localId:string;
  nhiId:string;
}


interface SendListModel{
  fileName:string;
  typeCode:string;
  localId:string;
  nhiId:string;
  FEE_YM:string;
  APPL_TYPE:string;
  HOSP_DATA_TYPE:string;
  hospId:string;
  reqDt:string;
  Month_Note:string;
}