import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { OrderConsumptionReport, OrderConsumptionReportItem } from 'src/app/opd/opd-report/models/order-consumption-report';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import JSZip from 'jszip';
// import sha256 from 'crypto-js/sha256';
// import hmacSHA512 from 'crypto-js/hmac-sha512';
// import Base64 from 'crypto-js/enc-base64';

import * as CryptoJS from 'crypto-js';
// import * as archiver from 'archiver';
import * as fs from 'fs';
import { pipeline, Transform } from 'stream';
import { HcrService } from 'src/app/services/hcr-service.service';

@Component({
    selector: 'app-order-dosage',
    templateUrl: './order-dosage.component.html',
    styleUrls: ['./order-dosage.component.css']
})
export class OrderDosageComponent implements OnInit, AfterViewInit {

    sizePage = {
        width: 20, //cm
        height: 28.5 //cm
      };
      paddingPage = {
          top: 0, //cm
          right: 0, //cm
          bottom: 0.5, //cm
          left: 0 //cm
      };

    heightPageWithoutPadding = this.convertCmtoPx(
        this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
    );
    elContainer;
    anchorsBlockValue;
    pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]

    @ViewChild('reportContainer', { static: false })
    reportContainer: ElementRef<HTMLDivElement>;

    upperboundIndex: number = 0;
    showDoctorNameColume: boolean;
    monthStr: string = '';
    nowStr: string = '';
    timeStr: string = '';
    totalRecord: number = 0;
    pageNo: number = 1;
    _sDate: Date;
    _eDate: Date;
    orderConsumptionReport: OrderConsumptionReport;
    // 清單物件
    listItems: OrderConsumptionReportItem[] = [];
    totalSummary: number = 0;
    totalBoxSummary: number = 0;
    // api服務
    api: WebApiService;
    // 查詢條件;
    queryFormGroup: FormGroup;

    constructor(private webApiClient: WebApiClient,
        private localPrintService: LocalPrintService,
        private notification: EasyNotificationService,
        private hcrService:HcrService,
        private elementRef: ElementRef) {
            this.api = this.webApiClient.createHisService('Opd/OpdReport');
    }

    ngOnInit(): void {

        var st = document.createElement('style');
        st.appendChild(document.createTextNode(
            '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
            '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
            '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
            '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
            '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
            '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
            '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
            '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
            // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
        ));
        setTimeout(() => {
            this.reportContainer.nativeElement.appendChild(st);
        }, 0);
    }

    ngAfterViewInit() {

    }

    // 取得統計期間的看診人次資料，並組出報表的html內容
    async fetchData(query: any) {
        this.elContainer = document.getElementById("container");
        this.orderConsumptionReport = new OrderConsumptionReport();
        this.nowStr = this.onGetDateString(new Date(), true);
        this.timeStr = this.onGetTimeString(new Date());
        this._sDate = query.startDate;
        this._eDate = query.endDate;
        if (query.reportType == 'doctor') {
            this.showDoctorNameColume = true;
        } else {
            this.showDoctorNameColume = false;
        }

        this.api.get('GetOrderConsumptionReport', query).subscribe(
            (data: OrderConsumptionReport) => {
                this.orderConsumptionReport = data;
                this.listItems = data.Items;
                this.totalRecord = this.listItems.filter(x => x.IsSummary == false).length;
                var prevName = '';
                this.listItems.forEach(x => {
                    var currName = '';
                    if (this.showDoctorNameColume) {
                        currName = x.DoctorName;
                    } else {
                        currName = x.ClinicName;
                    }

                    if (currName == prevName) {
                        if (this.showDoctorNameColume) {
                            x.DoctorName = '';
                        } else {
                            x.ClinicName = '';
                        }
                    } else {
                        prevName = currName;
                    }
                });
                // 將查詢的資料組成報表html
                this.insertListData();
            }
        );
    }

    // 組報表的html內容
    insertListData() {
        var html_Block = "";
        this.totalSummary = 0;
        this.totalBoxSummary = 0;
        var iPage = 0;
        var iBlock = 0;
        this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
        var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
        var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
        var html_finish = this.createHTMLFinish();              // 報表結束 -- 以下空白
        var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        elTableContent.innerHTML = html_ListerHeader;
        for (let i = 0; i < this.listItems.length; i++) {
            // 加入 List 每一筆的內容
            html_Block = this.createHTMLBlock(this.listItems[i]);
            var prevTableContent = elTableContent.innerHTML;
            elTableContent.innerHTML += html_Block;
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;
                elTableContent.innerHTML += html_Block;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
            iBlock += 1;

            if (i == this.listItems.length - 1) {
                // 最後一筆=>加上總計
                var html_Total = this.createHtmlTotalSummary();
                var prevTableContent = elTableContent.innerHTML;
                elTableContent.innerHTML += html_Total;
                // 判斷是否超出頁面範圍? -> 新增一頁
                if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
                    elTableContent.innerHTML = prevTableContent;
                    iPage += 1;
                    this.elContainer.innerHTML += this.createHTMLPage(iPage);
                    elPageContent = document.getElementById("page-" + iPage + "-content");
                    // [頁表頭][List欄位顯示名稱]
                    html_header = this.createHTMLPageHeader(iPage);
                    elPageContent.innerHTML += html_header;
                    elTableContent = document.getElementById("table-" + iPage + "-content");
                    elTableContent.innerHTML = html_ListerHeader;
                    elTableContent.innerHTML += html_Total;

                    this.pageContent[iPage] = [];
                    this.pageContent[iPage].push(iBlock);
                } else {
                    this.pageContent[iPage].push(iBlock);
                }
            }
        }
        elPageContent.innerHTML += html_finish;
        this.upperboundIndex = iPage;
        for (var i = 0; i <= iPage; i++) {
            var tPage = document.getElementById("totalPage-" + i);
            tPage.innerHTML = "" + (this.upperboundIndex + 1);
        }
    }

    convertPxToCm(px) {
        return Math.round(((px * 2.54) / 96) * 100) / 100;
    }

    convertCmtoPx(cm) {
        return Math.round((cm * 96) / 2.54);
    }

    createHTMLPage(iPage) {
        return `<div class="page" id="page-` + iPage + `"
                    style="
                    height: ` + this.sizePage.height + `cm;
                    width: ` + this.sizePage.width + `cm;
                    padding-top: ` + this.paddingPage.top + `cm;
                    padding-right: ` + this.paddingPage.right + `cm;
                    padding-bottom: ` + this.paddingPage.bottom + `cm;
                    padding-left: ` + this.paddingPage.left + `cm;
                    display: block;
                    margin: 0px auto;
                    box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                    box-sizing: border-box;
                    background-color: white;
                    ">
                    <div class="content" id="page-` + iPage + `-content">
                    </div>
                </div>`;
    }

    createHTMLListHeader() {
        var doctoColumn = this.showDoctorNameColume ? "醫師名" : "診所名稱";
        return `<tr>
                    <th
                    style="text-align: left; width: 24%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                     ` + doctoColumn + `
                    </th>
                    <th
                    style="text-align: left; width: 12%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                    院內醫令代碼
                    </th>
                    <th
                    style="text-align: left; width: 40%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                    醫令名稱
                    </th>
                    <th
                    style="text-align: left; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                    年月
                    </th>
                    <th
                    style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                    使用量
                    </th>
                    <th
                    style="text-align: right; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-top: none; border-bottom: 5px double black;">
                    總盒數
                    </th>
                </tr>`;
    }

    createHTMLBlock(data: OrderConsumptionReportItem) {
        var summaryStyle = data.IsSummary ? "border-top: 1px dotted #777;" : "border-top: none;";
        var summaryFontStyle = data.IsSummary ? "font-weight: bold" : "";
        var doctorNameColumn = data.IsSummary ? " " : (this.showDoctorNameColume ? data.DoctorName : data.ClinicName );
        var yearMonth = data.IsSummary ? "" : this.onGetDateString(data.UsageYear + '/' + data.UsageMonth + '/1', true, '', true);
        var showDrugCode = data.IsSummary ? '' : data.DrugCode;
        var showDrugName = data.IsSummary ? '小計' : data.DrugName;
        this.totalSummary += (data.IsSummary ? data.TotalUsageAmount : 0);
        this.totalBoxSummary += (data.IsSummary ? data.TotalBox : 0);
        var blankLine = data.IsSummary ? '<tr><td colspan="6" style="height: 30px; border-right: none; border-left: none; border-bottom: none; border-top: none;">　</td></tr>' : '';
        return '<tr>' +
                    '<td style="text-align: left; font-weight: bold; ' + summaryStyle + ' border-bottom: none;">' + doctorNameColumn + '</td>' +
                    '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + showDrugCode + '</td>' +
                    '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none; ' + summaryFontStyle + '">' + showDrugName + '</td>' +
                    '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + yearMonth + '</td>' +
                    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + data.TotalUsageAmount + '</td>' +
                    '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none; ">' + data.TotalBox+ '</td>' +
                '</tr>' + blankLine;
    }

    createHtmlTotalSummary() {
        return '<tr>' +
                    '<td style="text-align: left; font-weight: bold; border-top: 1px dotted #777; border-bottom: none;">　</td>' +
                    '<td style="text-align: left; border-top: 1px dotted #777; border-bottom: none;">　</td>' +
                    '<td style="text-align: left; border-top: 1px dotted #777; border-bottom: none; font-weight: bold">總計</td>' +
                    '<td style="text-align: left; border-top: 1px dotted #777; border-bottom: none;">　</td>' +
                    '<td style="text-align: right; border-top: 1px dotted #777; border-bottom: none;">' + this.totalSummary + '</td>' +
                    '<td style="text-align: right; border-top: 1px dotted #777; border-bottom: none; ">' + this.totalBoxSummary + '</td>' +
                '</tr>' +
                '<tr><td colspan="6" style="height: 30px; border-right: none; border-left: none; border-bottom: none; border-top: none;">　</td></tr>';
    }

    createHTMLPageHeader(iPage) {
        return `<div id="header" style="margin: 0px 10px;">
                    <div style="display: flex;column-gap: 5px;">
                        <table style="width: 100%; text-align: center; border: none;">
                            <tr>
                                <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                    醫令用量統計報表
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                印表日期 ` + this.nowStr + `
                                </td>
                                <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                期間：` + DateHelper.formatROCDate(this._sDate) + ` 至 ` + DateHelper.formatROCDate(this._eDate) + `
                                </td>
                                <td colspan="2" style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                總筆數 ` + this.totalRecord + `
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                印表時間 ` + this.timeStr + `
                                </td>
                                <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                                </td>
                                <td colspan="2" style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                                頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage + `"></span>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                    <label style="font-weight: bold; height: 23px;">　</label>
                </div>
                <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                    <div style="display: flex;column-gap: 5px;">
                        <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                        </table>
                    </div>
                </div>`;
    }

    createHTMLFinish() {
        return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                    <label style="font-weight: bold;">以下空白</label>
                </div>`;
    }

    getHtml() {
        var html = this.reportContainer.nativeElement.innerHTML;
        for (var i = 0; i <= this.upperboundIndex; i++) {
            html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);','');
        }
        return html;
    }

    async onPrint() {
        if (this.listItems.length > 0) {
            var reportName = '醫令用量統計表';
            const printContents = this.getHtml();
            var ret = await this.localPrintService.printHtml(444, reportName, printContents); // urlEncode 'Order Dosage Statistics'
            if (ret.Successed) {
                this.notification.showSuccess(reportName + '已送出列印!', true);
            } else {
                this.notification.showError(reportName + '送出列印失敗!');
            }
        } else {
            this.notification.showInfo('查無資料可供列印!');
        }
    }

    async exportAsExcel() {
        if (this.listItems.length > 0) {
            var reportName = '醫令用量統計表';
            const table = this.reportContainer.nativeElement;
            await this.hcrService.ExportTableToExcel(reportName,table.innerHTML)
        } else {
            this.notification.showInfo('查無資料可供匯出!');
        }
    }

    async exportAsExcelInZip() {
        if (this.listItems.length > 0) {
            const uri = 'data:application/zip;base64,';
            const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                                <head>
                                    <!--[if gte mso 9]>
                                    <xml>
                                        <x:ExcelWorkbook>
                                            <x:ExcelWorksheets>
                                                <x:ExcelWorksheet >
                                                    <x:Name>{worksheet}</x:Name>
                                                    <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                                                </x:ExcelWorksheet>
                                            </x:ExcelWorksheets>
                                        </x:ExcelWorkbook>
                                    </xml>
                                    <![endif]-->
                                </head>
                                <body>
                                    <table>{table}</table>
                                </body>
                                </html>`;
            const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
            const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
            const table = this.reportContainer.nativeElement;
            const ctx = { worksheet: '醫令用量統計表', table: table.innerHTML };
            const fileName = `醫令用量統計表_` + this.onGetTimeString(new Date(), '');

            // 產生Excel > 壓縮 > 加密碼
            try {
                // The content of the Excel
                // var xlsContent = format(template, ctx);
                // const output = fs.createWriteStream(fileName + '.zip');
                // const archive = archiver('zip');
                // archive.pipe(output);

                // const encryptTransform = new Transform({
                //     transform(chunk, encoding, callback) {
                //         // Encrypt the chunk using the given encryption key
                //         const encryptedChunk = CryptoJS.AES.encrypt(xlsContent, 'aa1234').toString();
                //         this.push(encryptedChunk);
                //         callback();
                //     },
                // });
                // archive.appendChild()

                var xlsContent = format(template, ctx);
                // var encryptedZipContent = CryptoJS.AES.encrypt(xlsContent, 'aa1234').toString();
                let zip = new JSZip();
                zip.file(fileName + `.xls`, xlsContent);
                const options: JSZip.JSZipGeneratorOptions<'blob'> = {
                    type: 'blob',
                    compression: 'DEFLATE',
                  };
                var zipContent = await zip.generateAsync(options);
                // var encryptedZipContent = CryptoJS.AES.encrypt(zipContent, 'aa1234').toString();
                const link = document.createElement('a');
                link.download = fileName + `.zip`;
                // link.href = uri + encryptedZipContent;
                // link.href = uri + zipContent;
                link.href = window.URL.createObjectURL(zipContent);
                link.click();

                // zip.generateAsync({type: "base64"})
                // .then(function(content) {
                //     const link = document.createElement('a');
                //     link.download = fileName + `.zip`;
                //     link.href = uri + content;
                //     link.click();
                // });

                /*
                let zip = new JSZip();
                // 將網頁報表轉為Excel格式後用JSzip壓縮
                var xlsContent = format(template, ctx);
                zip.file(fileName + `.xls`, format(template, ctx));

                zip.generateAsync({type: "base64"})
                    .then(function(content) {
                        // debugger
                        // var CryptoJS = require("crypto-js");
                        // var encryptedZipContent = CryptoJS.AES.encrypt(content, "a1234");

                        const link = document.createElement('a');
                        link.download = fileName + `.zip`;
                        link.href = uri + content;
                        link.click();
                    });
                    */
            } catch (e) {
                this.notification.showError(e);
            }


            // var zipContent = zip.generateAsync({type:"blob"});
            // 加密
            // var CryptoJS = require("crypto-js");
            // var encryptedZipContent = CryptoJS.AES.encrypt(zipContent, "aa1234")

            // const link = document.createElement('a');
            // link.download = `醫令用量統計表_` + this.onGetTimeString(new Date(), '') + `.zip`;

            // link.href = uri + base64(encryptedZipContent);
            // link.click();
        } else {
            this.notification.showInfo('查無資料可供匯出!');
        }
    }

    /** 轉換Date To String
     *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
     */
    onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();
            var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            if (isMonth) {
                return [year, month].join(symbol);
            } else {
                return [year, month, day].join(symbol);
            }
        }
    }

    /** 轉換Time To String
        *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
    */
    onGetTimeString(ev, timeSymbol = ':') {
        if (ev) {
            var d = new Date(ev);
            // 趕時間先簡單寫
            let hr = d.getHours().toString();
            if (d.getHours() < 10) {
                hr = '0' + hr;
            }
            let min = d.getMinutes().toString();
            if (d.getMinutes() < 10) {
                min = '0' + min;
            }
            let sec = d.getSeconds().toString();
            if (d.getSeconds() < 10) {
                sec = '0' + sec;
            }
            var timeStr = [hr, min, sec].join(timeSymbol);
            return timeStr;
        }
    }

    onGetDate(ev) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day].join('');
        }
    }
}
