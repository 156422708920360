import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
// shared module
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { BaseConfig } from 'src/app/services/base-config.service';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PageData } from 'src/app/shared/models/page-data';
// this module
import { LabFormList } from '../../models/labForm-list';
import { EditComponent } from '../edit/edit.component';
import { NullOrEmpty, isNullOrUndefined } from 'src/app/shared/utilities';
import { LabApi, LabEditOption } from 'src/app/services/api-service/labs/lab-api';
import { GridData } from 'src/app/shared/components/grid/vis-grid/vis-grid.component';


@Component({
  selector: 'app-labs-report-query-dialog-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css']
})
export class ListComponent implements OnInit {

  constructor(
    private labApi:LabApi,
  ) {
    //this.api = this.webApiClient.createHisService('labs/reportQuery');
  }

  //#region 變數 --------------------
  @Input() queryPatientNo = '';
  @Input() queryPatientName = '';
  @Input() isLockPatientNo = false;
  @Input() set data(v:GridData){
    this.gridData = v;
    if (this.gridData?.data?.length > 0) {
      const firstRowId = this.gridData.data[0].Id;
      this.selectedItems[0] = firstRowId;
      setTimeout(() => {
        this.editComponent.view(firstRowId);  
      }, 0);
      
    }
  }
  gridData:GridData;

  pageSize: number = BaseConfig.getConfig().ui.pageSize;
  @Output() onPageChange:EventEmitter<number> = new EventEmitter()
  patientName = '';
  // api服務
  //api: WebApiService;

  // Child元件
  @ViewChild(EditComponent, { static: false })
  editComponent: EditComponent;

  isChildInEdit = false;
  get allDisabled() {
    if (this.isChildInEdit) {
      return 'disabled';
    } else {
      return null;
    }
  }

  // 清單物件

  // 表格-所選項目
  selectedItems: number[] = [];

  // 表格-分頁

  queryOptions = {
    statusType: [
      { value: 3, text: '完成' },
      { value: 99, text: '全部' },
    ],
  };

  // 編輯時需要的選項或參數 ddl
  editOptions:LabEditOption = {
    labQueryTypeEnum:[],
    testResultCodesForText:[],
    labStatusEnum: [],
    testResultCodes: []
  };

  noPatientName = '';
  //#endregion --------------------

  //#region View --------------------
  // 是否顯示
  isShow(): boolean {
    if (true) {
      return true;
    } else {
      return false;
    }
  }
  //#endregion --------------------

  ngOnInit() {
    // createQueryFormGroup必須在refreshList之前，因為refreshList會用到form group參數

    // 先取參數再顯示清單
    this.getEditOptions();

  }

  //#region edit options of child edit component --------------------
  // 幫子元件先抓此參數，後續子元件第一次進入就不用抓，速度比較快，也不用每次抓
  // 未來會改為其他方法
  async getEditOptions() {
    // 呼叫api
    this.editOptions = await this.labApi.getEditOption()
    
  }
  //#endregion --------------------


  //#region 清單

  // 更新清單
  // isFirstEnter: 是否為第一次進入
  

  // 產生查詢條件
  

  //#endregion --------------------

  //#region 表格 --------------------
  // 表格分頁
  // 分頁切換事件
  pageChange(pNo: number): void {
    this.onPageChange.emit(pNo)
    // this.pageSkipNo = event.skip; // kendo grid page用法
    // this.refreshList(); // 更新清單，因為server端資料可能異動
  }
  // 處理grid cell點擊，預設view
  public gridCellClickHandler({ isEdited, dataItem, rowIndex }): void {
    this.editComponent.view(dataItem.Id);
  }
  // highlight
  setGridHighlight(data: LabFormList) {
    this.selectedItems[0] = data.Id;
  }

  

}
