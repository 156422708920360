

/**
 * 取得就醫序號新版參數
 */ 
export class GetSeqNumber256 {
    /**
     * 就醫類別
     */ 
    public TreatItem: string = null;
    /**
     * 新生兒就醫註記
     */ 
    public BabyTreat: string = null;
    /**
     * 補卡註記
     */ 
    public TreatAfterCheck: string = null;
}
