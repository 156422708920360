export class Referral {
    Id: number;
    Type: number;
    RegId: number;
    RegDate: Date;
    ClinicId: number;
    ClinicCode: string;
    PatientId: number;
    PatientNo: string;
    ContactName: string;
    CC: string;
    Dx: string;
    Dx1: string;
    Dx2: string;
    RecLISDate: Date;
    RecLISResult: string;
    RecLISReport: string;
    RecOrder: string;
    RecOrderDate: Date;
    AllergyHistory: string;
    Objects: string;
    ObjectsNote: string;
    IssueDate: Date;
    SchMedDate: Date;
    SchMedDep: string;
    SchMedDiag: string;
    SchMedNum: string;
    InHspid: string;
    InDep: string;
    InDrName: string;
    InHsAddrw: string;
    InHsPhone: string;
    DrTrans: string;
    ExpiryDate: Date;
    NewBornNote: string;
    SerialNum: string;
    PilotProject: string;
    ReferralNum: string;
    SpeNote: string;
    SpeCaseNote: string;
    UrgPatNote: string;
    ReferralPur: string;
    UrgPatRea: string;
    UrgPatOth: string;
    UrgPatTime: Date;
    keepPhoneNo?: string;
}