export class HcaInitData
{
    public InitModule: number;
    public InitSession: number;

    public UserPassword: string;

}

export class HCASignRequestData
{
    public InitModule: number;
    public InitSession: number;

    public QRCodeData: string;

}