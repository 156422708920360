import { Component, OnInit, Input, OnDestroy, ViewChildren, QueryList, Output, EventEmitter } from '@angular/core';
import { ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { HistHelperService } from '../services/hist-helper.service';
import { HelperPadEvent, HistHelperPadService, ItemSelectedEvent, AreaSelectedEvent, SetItemEvent } from '../services/hist-helper-pad.service';
import { of, Subject } from 'rxjs';
import { HistDiag } from '../models/hist-diag';
import { HistService } from '../services/hist.service';
import { Dx } from 'src/app/services/api-service/dx/dx';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HelperSourceEnum } from '../enums/helper-source-enum';
import { HelperEvent } from '../models/helper-event';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';
import { Hist } from '../models/hist';
import { HelperId } from '../models/helper-id';
import { HistFocusDirective } from '../directives/hist-focus.directive';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { Sum } from 'src/app/shared/utilities';

@Component({
  selector: 'app-hist-diags',
  templateUrl: './diags.component.html',
  styleUrls: ['./diags.component.css', '../styles/hist.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiagsComponent implements OnInit, OnDestroy {

  @ViewChildren(HistFocusDirective)
  codeInputs: QueryList<HistFocusDirective>

  @Input()
  public set diags(diags: HistDiag[]) {
    try {
      this._diags = diags;
      this.fillFormData(this._diags);
      this.histService.UIReady('Dx');
    } catch (e) {
      console.log('error', e);
      this.notification.showErrorById('MSG_HistDiags4');
    }
  }
  @Input()
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.setDisabled();
  }

  @Input()
  public isShowSearch?: boolean = false;


  @Output()
  public emitShowSearch = new EventEmitter<boolean>();
  @Output()
  codeChanged = new EventEmitter<Dx>()

  areaId = HelperId.DiagArea;
  codeIdPrefix = HelperId.Diag + '_code_';
  nameIdPrefix = HelperId.Diag + '_name_';

  private dxMinCount = 2;

  private _disabled = false;
  _diags: HistDiag[] = [];
  private unsubscribe: Subject<void> = new Subject();
  private fhs: FormHelper<HistDiag>[] = []
  //#region 參數 form and orders --------------------

  // get arrDiags() {
  //   return this.fhs.map(x=>x.Value);
  // }

  public get diags() {
    return this.fhs.map(x => x.Value);
  }
  //#endregion 參數 form and orders --------------------

  constructor(private fb: FormBuilder,
    private histApi: HistApi,
    private histService: HistService,
    private notification: EasyNotificationService,
    private helperService: HistHelperService,
    private padService: HistHelperPadService,
    private formHelperService: FormHelperService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscribe_Helper();
    this.subscribe_HelperPad();
    this.dxMinCount = this.histService.TotalDiagnosis || 2;
    this.makeForms()
    this.fillFormData(this._diags);
    this.padService.diag = this;
    this.setDisabled();
  }

  //#region Public  開給門診主元件呼叫


  /** 門診主元件取回診斷 */
  public async pullDiags(): Promise<HistDiag[]> {
    this.helperService.blur();

    for (let i = this.dxMinCount - 1; i >= 0; i--) {
      const code = this.fhs[i].Value.code;
      if (code) {
        // 診斷補完或清除
        var data = await this.histApi.getDiagByCode(code);
        if (data) {
          this.setCode(i, data);
        } else {
          this.clearRow(i, false);
        }
      };

    }
    // 清除空行
    this.clearEmpty()
    return this.diags;
  }
  /** 門診主元件清除 */
  public clear() {
    this.fhs.forEach(x => {
      this.clearFh(x);
    });
    this.cd.detectChanges();
  }

  /** 插入到空處 */
  public setCodeAtFirstEmpty(item: Dx) {
    const idx = this.findEmptyRowIndex();
    if (idx < 0) {
      this.notification.showWarning('Reach max number of diagnosis.');
      return;
    }
    this.setCode(idx, item);
  }
  //#endregion

  private setDisabled() {
    if (this._disabled) {
      this.fhs.forEach(x => x.FormGroup.disable());
    } else {
      this.fhs.forEach(x => x.FormGroup.enable());
    }
  }


  //#region 表單控制

  /** 產生表單元件，並定義基本檢核規則 */
  private makeForms() {
    this.fhs = [];
    for (let i = 0; i < this.dxMinCount; i++) {
      var fh = this.formHelperService.Create<HistDiag>().buildPartial({
        no: [0, null],
        code: ['', null],
        name: ['', null],
        acuteNote: [0, null],
        Id: [0, null],
        applyCode: ['', null],
      })
      this.fhs.push(fh);
    }

  }
  /** 填入表單資料 */
  private fillFormData(diags: HistDiag[]) {
    if (this.fhs.length == 0) {
      this.makeForms();
    }
    this.fhs.forEach((fh, i) => {
      if (i >= diags.length) {
        fh.patchValue({
          code: '',
          name: '',
          acuteNote: 0
        });
      } else {
        fh.patchValue(diags[i])
      }
      fh.patchValue({
        no: i + 1
      });
    })
    this.cd.detectChanges();
  }
  /** 清空表單輸入值 */
  private clearFh(fh: FormHelper<HistDiag>) {
    fh.patchValue({
      code: '',
      name: '',
      acuteNote: 0
    })
  }
  /** 清除空項目 */
  private clearEmpty(focusTo: number = null) {
    var values: HistDiag[] = this.diags;
    var items = values.filter(v => v.code);
    var hasEmpty = values.slice(0, items.length).filter(v => v.code).length != items.length;
    if (hasEmpty) {
      this.diags = items;
      if (focusTo >= 0) {
        setTimeout(() => {
          // 清空行的時候如果是點到同樣dxInput才focus回來
          if (document.activeElement.classList.contains('dx-input')) {
            this.focusTo(focusTo);
          }
        }, 0);

      }
    }
  }
  /** 清空指定行 */
  private clearRow(rowIndex: number, focus: boolean) {
    // 有值清除
    if (this.diags[rowIndex].name) {
      this.codeChanged.emit(null);
    }
    this.fhs[rowIndex].patchValue({
      code: '',
      name: '',
      acuteNote: 0
    });

    if (focus) {
      setTimeout(() => {
        if (document.activeElement.classList.contains('dx-input')) {
          this.focusTo(rowIndex);
        }
      }, 0);
    }
  }
  /** 清空全部 */
  private clearAllRow() {
    for (let i = 0; i < this.diags.length; i++) {
      this.clearRow(i, false);
    }
  }



  private setCode(rowIndex: number, item: Dx) {
    if (this.checkDuplicate()) {
      return;
    }
    try {
      this.fhs[rowIndex].patchValue({
        code: item.ApplyCode,
        acuteNote: item.AcuteNote,
        name: item.CName
      });
    } catch (e) {
      console.log(e);
    }
  }
  /** 檢查是否重複*/
  private checkDuplicate(): boolean {
    if (this.diags && this.diags.length > 0) {
      const codes = this.diags.map(x => x.code);
      const result = Hist.findDuplicated(codes);
      if (result) {
        this.notification.showErrorById('MSG_HistDiags1');
        var ind = codes.lastIndexOf(result.split(',')[0])
        this.clearRow(ind, true);
        return true
      }
    }
    return false
  }

  //#endregion

  //#region Private 方法
  /** focus到指定位置 */
  private focusTo(idx) {
    var id = this.codeIdPrefix + (idx);
    var next = this.codeInputs.find(c => (c.elem.nativeElement as HTMLInputElement).id == id);
    if (!next) {
      var idpre = this.codeIdPrefix + (idx - 1);
      next = this.codeInputs.find(c => (c.elem.nativeElement as HTMLInputElement).id == idpre);
    }
    setTimeout(() => {
      next?.setFocus(false);
    }, 0);
  }
  /**非同步檢查代碼並等待*/
  private checkCodeAsync(rowIndex: number): Promise<void> {
    // 因為blur時，可能是滑鼠去點helper，也可能不是，
    // 若是滑鼠去點helper，會有時間差，
    // 譬如，input內容是A，滑鼠去點helper的A00項目
    // 因為時間差會去檢查A，得到不存在的警告
    // 等待一下，讓A00的內容回到input再去檢查
    return new Promise<void>((rs, rj) => {
      setTimeout(async () => {
        await this.checkCode(rowIndex);
        rs();
      }, 300);
    })
  }
  /** 檢查代碼 */
  private async checkCode(rowIndex: number) {
    // 檢查時再抓code資料是因為，code可能是由helper選擇到的項目，而非當下input內容
    var code = this.fhs[rowIndex].Value.code;
    //const code = this.arrDiags.controls[rowIndex].get('code').value;
    if (!code) {
      this.clearRow(rowIndex, false);
      return;
    }
    try {
      var data = await this.histApi.getDiagByCode(code);
      if (data) {
        this.setCode(rowIndex, data)

        this.codeChanged.emit(data);
      } else {
        this.codeNotFound(rowIndex);
      }
      this.clearEmpty(rowIndex);
    } catch (error) {
      console.log('error', error)
      this.notification.showErrorById('MSG_HistDiags2');
    }

  }
  /** 找不到 */
  private codeNotFound(rowIndex: number) {
    this.notification.showErrorById('MSG_HistDiags3', true);
    // clear & focus
    this.clearRow(rowIndex, true);
    document.getElementById(this.codeIdPrefix + rowIndex).classList.add('warn');
  }
  
  /** 找空處 */
  private findEmptyRowIndex(): number {
    for (let i = 0; i < this.dxMinCount; i++) {
      if (!this.fhs[i].Value.code) {
        return i;
      }
    }
    // 取代最後一筆
    return this.dxMinCount - 1;
  }
  /** 焦點到空處 */
  private focusAtFirstEmpty() {
    let idx = this.findEmptyRowIndex();
    if (idx < 0) {
      idx = 0;
    }
    this.focusTo(idx);
    //document.getElementById(this.codeIdPrefix + idx).focus();
  }
  //#endregion


  //#region 選盤/輔助盤事件
  subscribe_Helper() {
    this.helperService.helperAction$.pipe(takeUntil(this.unsubscribe)).subscribe(
      (evt: HelperEvent) => {
        if (!evt || !evt.source || !evt.selectedItem || evt.rowIndex < 0) {
          return;
        }
        if (evt.source === HelperSourceEnum.Diag) {
          this.setCode(evt.rowIndex, evt.selectedItem);
        }
      }
    );
  }
  p: Promise<boolean>;
  subscribe_HelperPad() {
    this.padService.fromPad.pipe(takeUntil(this.unsubscribe)).subscribe(
      async (evt: HelperPadEvent) => {
        if (evt instanceof AreaSelectedEvent) {
          if (evt.tab == HelperSourceEnum.Diag) {
            this.focusAtFirstEmpty();
          }
        } else if (evt instanceof ItemSelectedEvent) {
          if (evt.type == HelperSourceEnum.Diag) {

            this.histService.UIUnReady('Dx')

            if (this.p) {
              await this.p;
            }
            var rs;
            this.p = new Promise((_rs, rj) => {
              rs = _rs
            })
            if (evt.isInsert) {
              var codes = this.diags.map(x => x.code);
              var ind = codes.lastIndexOf(evt.item.Code)
              if (ind >= 0) {
                this.clearRow(ind, false);
                this.clearEmpty();
              } else {
                this.setCodeAtFirstEmpty(evt.item);
              }
              codes = this.diags.map(x => x.code);
              if (evt.focus) {
                this.focusTo(codes.filter(c => c).length)
              }
            } else {
              this.clearAllRow();
              this.setCode(0, evt.item);
            }
            this.cd.detectChanges();
            rs(true);
            this.p = null;
            this.histService.UIReady('Dx')
          }
        } else if (evt instanceof SetItemEvent) {
          if (evt.type == HelperSourceEnum.Diag) {
            this.histService.UIUnReady('Dx')
            if (this.p) {
              await this.p;
            }
            var rs;
            this.p = new Promise((_rs, rj) => {
              rs = _rs
            })
            if (evt.isInsert) {
              var codes = this.diags.map(x => x.code);
              var insert = (dx: Dx) => {
                var ind = codes.lastIndexOf(dx.Code)
                if (ind >= 0) {
                  // 已經有了 不動作
                } else {
                  this.setCodeAtFirstEmpty(dx);
                }
              }
              if (evt.item instanceof Array) {
                for (var i = 0; i < evt.item.length; i++) {
                  insert(evt.item[i]);
                }
              } else {
                insert(evt.item);
              }

            } else {
              this.clearAllRow();
              if (evt.item instanceof Array) {
                for (var i = 0; i < evt.item.length; i++) {
                  this.setCode(i, evt.item[i]);
                }
              } else {
                this.setCode(0, evt.item);
              }

            }
            this.cd.detectChanges();
            rs(true);
            this.p = null;
            this.histService.UIReady('Dx')
          }
        }

        // if (evt.type === HelperEventTypeEnum.HelperTab) {
        //   this.focusAtFirstEmpty();
        // } else if (evt.type === HelperEventTypeEnum.HelperSelect && evt.selectedItem) {
        //   if (evt.isInsert) {
        //     this.setCodeAtFirstEmpty(evt.selectedItem);
        //   } else {
        //     this.clearAllRow();
        //     this.setCode(0, evt.selectedItem);
        //   }
        //   this.cd.detectChanges();
        // }
      }
    );
  }
  //#endregion

  

  getNameTooltip(idx: number) {
    var fv = this.fhs[idx].Value;
    return fv.code + ' ' + fv.name;
  }

  

  // #region code input event --------------------

  onCodeKeyUp(event, rowIndex: number) {
    const elemId = this.codeIdPrefix + rowIndex;
    this.helperService.keyupForInput(elemId, event.target.value, event, rowIndex);
    if (!event.target.value) {
      this.clearRow(rowIndex, true);
    }
  }
  onCodeKeyDown(event: KeyboardEvent, idx: number) {
    var tar = event.target as HTMLInputElement;
    // 按下Escape清空，helper重設
    if (event.key === 'Escape') {
      this.helperService.reset();
      this.clearRow(idx, false);
    } else if (event.code == 'ArrowRight') {
      if (tar.selectionEnd == tar.value.length) {
        this.focusTo(idx + 1);
      }
    } else if (event.code == 'ArrowLeft') {
      if (tar.selectionEnd == 0) {
        this.focusTo(idx - 1);
      }
    } else if (event.key == 'Enter') {
      if (tar.selectionEnd == tar.value.length) {
        this.helperService.keydownForInput(event);
        // 自己不為空且前面有空值 改blur來清空位
        if (!!tar.value && this.fhs.some((x, i) => i < idx && !x.Value.code)) {
          this.onCodeBlur(idx);
        } else {
          this.focusTo(idx + 1);
        }

      }
    } else {
      this.helperService.keydownForInput(event);
    }
  }
  onCodeKeyDownAtName(idx: number) {
    this.focusTo(idx);
  }
  focusLocker: any;
  onCodeFocus(event) {
    clearTimeout(this.focusLocker)
    this.focusLocker = setTimeout(() => {
      event.target.select();
    }, 0);
  }
  async onCodeBlur(rowIndex: number) {
    this.fhs[rowIndex].patchValue({
      code: this.fhs[rowIndex].Value.code?.toUpperCase() ?? ''
    })
    var empty = Sum(this.fhs.filter((x, i) => i < rowIndex), x => !x.Value.code ? 1 : 0);
    if (empty > 0) {
      rowIndex -= empty;
    }
    // 在這邊清除空欄位會比檢查DxCode還快，造成搬移後，檢查的結果無法setValue
    this.clearEmpty(rowIndex);
    var code = this.fhs[rowIndex].Value.code;
    //const code = this.arrDiags.controls[rowIndex].get('code').value;
    if (!code) {
      this.clearRow(rowIndex, false);
    } else {
      await this.checkCodeAsync(rowIndex);
    }


    this.helperService.blur();
  }
  //#endregion
  

  // #region cell --------------------
  
  getSearch() {
    this.emitShowSearch.emit(true);
  }
  // #endregion cell

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
