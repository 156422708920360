import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './security/services/auth-guard';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { NoCompanyComponent } from './security/no-company/no-company.component';
import { LoginComponent } from './security/login/login.component';
import { LogoutComponent } from './security/logout/logout.component';
import { LoginDirectlyComponent } from './security/login-directly/login-directly.component';
import { NhiPHModule } from './nhi-ph/nhi-ph.module';
import { ValAddAppModule } from './val-addapp/val-addapp.module';

const routes: Routes = [
  { path: '', component: NoCompanyComponent, pathMatch: 'full' }, /* no company */
  { path: ':com', redirectTo: '/:com/login', pathMatch: 'full' }, /* first route */
  {
    path: ':com',
    component: MainLayoutComponent,
    children: [
      {
        path: 'system', loadChildren: () => import('./system/system.module').then(m => m.SystemModule),
        data: { title: 'System' }, canActivate: [AuthGuard]
      },
      {
        path: 'opd', loadChildren: () => import('./opd/opd.module').then(m => m.OpdModule),
        data: { title: 'Outptient Department' }, canActivate: [AuthGuard]
      },
      {
        path: 'registers', loadChildren: () => import('./registers/registers.module').then(m => m.RegistersModule),
        data: { title: 'Register' }, canActivate: [AuthGuard]
      },
      {
        path: 'referrals', loadChildren: () => import('./referrals/referral.module').then(m => m.ReferralModule),
        data: { title: 'Referrals' }, canActivate: [AuthGuard]
      },
      {
        path: 'hist', loadChildren: () => import('./hist/hist.module').then(m => m.HistModule),
        data: { title: 'Medical Record' }, canActivate: [AuthGuard]
      },
      {
        path: 'permission',
        loadChildren: () => import('./permission/permission.module').then(m => m.PermissionModule),
        data: { title: 'Permission' },
        canActivate: [AuthGuard]
      },
      {
        path: 'patients', loadChildren: () => import('./patients/patients.module').then(m => m.PatientsModule),
        data: { title: 'Patient' }, canActivate: [AuthGuard]
      },
      {
        path: 'schedule', loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule),
        data: { title: 'Schedule' }, canActivate: [AuthGuard]
      },
      {
        path: 'payments',
        loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule),
        data: { title: 'Payment' },
        canActivate: [AuthGuard],
      },
      {
        path: 'val-addapp',
        loadChildren: () => import('./val-addapp/val-addapp.module').then(m => m.ValAddAppModule),
        data: { title: 'Val-addapp' },
        canActivate: [AuthGuard],
      },
      {
        path: 'labs', loadChildren: () => import('./labs/lab.module').then(m => m.LabModule),
        data: { title: 'Labs' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-declare', loadChildren: () => import('./nhi-declare/nhi-declare.module').then(m => m.NhiDeclareModule),
        data: { title: 'nhi-declare' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-relatedaffair', loadChildren: () => import('./nhi-relatedaffair/nhi-relatedaffair.module').then(m => m.NhiRelatedaffairModule),
        data: { title: 'nhi-relatedaffair' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-works', loadChildren: () => import('./nhi-works/nhi-works.module').then(m => m.NhiWorksModule),
        data: { title: 'nhi-works' }, canActivate: [AuthGuard]
      },
      {
        path: 'emr', loadChildren: () => import('./emr/emr.module').then(m => m.EmrModule),
        data: { title: 'EMR' }, canActivate: [AuthGuard]
      },
      {
        path: 'stock', loadChildren: () => import('./stock/stock.module').then(m => m.StockModule),
        data: { title: 'Stock' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-assist', loadChildren: () => import('./nhi-assist/nhi-assist.module').then(m => m.NhiAssistModule),
        data: { title: 'nhi-assist' }, canActivate: [AuthGuard]
      },
      {
        path: 'hepatsick', loadChildren: () => import('./hepatsick/hepatsick.module').then(m => m.HepatSickModule),
        data: { title: 'HepatSick' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-ph', loadChildren: () => import('./nhi-ph/nhi-ph.module').then(m => m.NhiPHModule),
        data: { title: 'nhi-ph' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-additional', loadChildren: () => import('./nhi-additional/nhi-additional.module').then(m => m.NhiAdditionalModule),
        data: { title: 'nhi-additional' }, canActivate: [AuthGuard]
      },
      {
        path: 'nhi-plan', loadChildren: () => import('./nhi-plan/nhi-plan.module').then(m => m.NhiPlanModule),
        data: { title: 'nhi-plan' }, canActivate: [AuthGuard]
      },
      {
        path: 'sales',
        loadChildren: () => import('./sales/sales.module').then(m => m.SalesModule),
        data: { title: 'sales' }
      },
      {
        path: 'api-simulate', loadChildren: () => import('./test-sample/api-simulate/api-simulate.module').then(m => m.ApiSimulateModule),
        data: { title: 'api-simulate' }, canActivate: [AuthGuard]
      },
      {
        path: 'test-sample',
        loadChildren: () => import('./test-sample/test-sample.module').then(m => m.TestSampleModule),
        data: { title: 'TestSample' }
      },
      {
        path: 'regreport', loadChildren: () => import('./regreport/regreport.module').then(m => m.RegreportModule),
        data: { title: 'regreport' }, canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: ':com/loginDirectly', component: LoginDirectlyComponent, data: { ignoreActionAuth: true }
  },
  {
    path: ':com/login', component: LoginComponent, data: { ignoreActionAuth: true }
  },
  {
    path: ':com/logout', component: LogoutComponent, data: { ignoreActionAuth: true }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'legacy'
  })],
  // 佈署用 (上一行要註解)
  // imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
