export class RegisterConst {

  /** 自費掛號 */
  static readonly IType_0_SelfPay = '0';
  /** 插卡掛號 */
  static readonly IType_1_IC = '1';
  /** 異常掛號 */
  static readonly IType_2_Abnormal = '2';
  /** 欠卡掛號 */
  static readonly IType_3_OweCard = '3';
  /** 欠卡掛號(不可還卡) */
  static readonly IType_4_OweCardNoReturn = '4';

  /** 保險身分-自費 */
  static readonly ICode_000_SelfPay = '000';
  /** 保險身分-健保 */
  static readonly ICode_A_健保 = 'A';
  /** 保險身分-自費 */
  static readonly ICode_001_重傷病 = '001';
  /** 保險身分-其他 */
  static readonly ICode_009_Other = '009';

  /** 類別-一般看診 */
  static readonly SameTreat__一般看診 = '';
  /** 類別-洗腎 */
  static readonly SameTreat_0_洗腎 = '0';
  /** 類別-復健 */
  static readonly SameTreat_1_復健 = '1';
  /** 類別-預防保健 */
  static readonly SameTreat_2_預防保健 = '2';
  /** 類別-換藥 */
  static readonly SameTreat_3_換藥 = '3';
  /** 類別-月療 */
  static readonly SameTreat_4_月療 = '4';
  /** 類別-全療 */
  static readonly SameTreat_5_全療 = '5';
  /** 類別-內科同療 */
  static readonly SameTreat_6_內科同療 = '6';
  /** 類別-同日同醫 */
  static readonly SameTreat_7_同日同醫 = '7';
  /** 類別-慢箋領藥 */
  static readonly SameTreat_8_慢箋領藥 = '8';


  /** 保險身分-其他 */
  static readonly INote_代檢 = '+7';
//O有簽章 X簽章異常 P正常不需寫卡或補正 U 待診中  F 改自費  H退掛 D刪除病歷未退掛
  static readonly TPK2M_O有簽章 = 'O'
  static readonly TPK2M_X簽章異常  = 'X'
  static readonly TPK2M_P正常不需寫卡或補正  = 'P'
  static readonly TPK2M_U待診中  = 'U'
  static readonly TPK2M_F改自費 = 'F'
  static readonly TPK2M_H退掛 = 'H'
  static readonly TPK2M_D刪除病歷未退掛 = 'D'

  // 狀態碼: A一般候診、0就診中、D保留排後、E預約、F完診、G拿藥、H取消、*急診、C已掛號未付款
  // code definition: 對應server端的定義，兩邊是一樣的
  /** 就診中 */
  static readonly Code_Status_Examining = '0';
  /** 候診 */
  static readonly Code_Status_Waiting = 'A';
  /** 排1 */
  static readonly Code_Status_Queue1 = '1';
  /** 已掛號未付款(不可顯示候診) */
  static readonly Code_Status_RegNotPay = 'C';
  /** 保留排後 */
  static readonly Code_Status_ReservedLater = 'D';
  /** 預約 */
  static readonly Code_Status_Appointment = 'E';
  /** 完診 */
  static readonly Code_Status_Finish = 'F';
  /** 拿藥 */
  static readonly Code_Status_PickUpDrug = 'G';
  /** 取消 */
  static readonly Code_Status_Cancel = 'H';
  /** 急診 */
  static readonly Code_Status_Emergency = '*';
  /** 複製 */
  static readonly Code_Status_Duplication = 'J';

  // 櫃台 候診清單 狀態預設選項 :
  static readonly StatusCodeList_ForDesk: string = '0,A,D,G,*,1,Z';
  // 醫師 候診清單 狀態預設選項 :
  static readonly StatusCodeList_ForDoctor: string = '0,A,D,*,1,Z';
  // 全部 候診清單 狀態預設選項 :
  static readonly StatusCodeList_All: string = '0,A,D,E,G,*,F,C,1,Z';
  // 門診登錄顯示的後續候診清單
  static readonly StatusCodeList_ForExamining: string = 'A,D,*,1,Z';
  // 掛號櫃台修改掛號時，可選擇那些狀態
  static readonly StatusCodeList_CanSelect: string[] = ['A', 'G', 'E', '*', 'H', 'J', 'Z'];
  static readonly StatusCodeList_CanSelectButCannotSelectH: string[] = ['A', 'G', 'E', '*', 'J', 'Z'];

  // 候診清單咬顯示的人數統計
  static readonly StatusCodeList_Count: string = 'A,D,*,1,F,G,E,0';

  // payment status
  static readonly Code_Payment_Registered = '0'; // 已掛號未批價
  static readonly Code_Payment_Priced = '1'; // 已批價未付款
  static readonly Code_Payment_Paying = '2'; // 結帳中
  static readonly Code_Payment_Paid = '3'; // 已付款
  static readonly Code_Payment_Debt = '4'; // 賒欠
  static readonly Code_Payment_Refund = '5'; // 退費

  // signal
  static readonly SignalGroupName = 'register';

  // signal
  static readonly Mode_Reg = 'REG'; // 掛號櫃台清單模式
  static readonly Mode_Opd = 'OPD'; // 醫師門診清單模式
  static readonly Mode_Resv = 'RESV'; // 醫師門診預約選入模式
}


export class RegisterVaccineConst {

  /** Covid 疫苗 */
  static readonly Isqno_Vaccine_Covid = 'VU';
  /** 肺炎鏈球菌多醣體疫苗 */
  static readonly Isqno_Vaccine_Pneumococcal = 'DU';
  /** 流感疫苗 */
  static readonly Isqno_Vaccine_Flu = 'AU';
    /** 疫情視訊看診 */
  static readonly Isqno_VideoCall_Covid = 'HVIT';
      /** 兒童常規疫苗 */
  static readonly Isqno_Vaccine_Child = 'CU';
  /** 疫苗集合for VU,DU,AU,CU */
  static readonly vaccineCodes: string[] = [
    RegisterVaccineConst.Isqno_Vaccine_Covid,
    RegisterVaccineConst.Isqno_Vaccine_Pneumococcal,
    RegisterVaccineConst.Isqno_Vaccine_Flu,
    RegisterVaccineConst.Isqno_Vaccine_Child
  ];
}
