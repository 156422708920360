import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SystemCode } from 'src/app/services/api-service/system-code/system-code';
import { SystemCodeType } from 'src/app/services/api-service/system-code/system-code-type';
import { SystemcodetypeApi } from 'src/app/services/api-service/system-code/systemcodetype-api';
import { option } from '../../../models/dropdown-list';

declare type SystemCodeImportTargetType = "" | "C0010" | "B0030" | "B0040" | "B0270" | "B0060";

@Component({
  selector: 'app-std-system-code-import-with-add',
  templateUrl: './std-system-code-import-with-add.component.html',
  styleUrls: ['./std-system-code-import-with-add.component.css']
})
export class StdSystemCodeImportWithAddComponent implements OnInit {

  addData = new SystemCode('', '');
  showCreate: boolean = false;

  @Input()
  systemCodeImportTargetType: SystemCodeImportTargetType = "";

  @Output()
  onImported: EventEmitter<void> = new EventEmitter();

  @Output()
  onAdded: EventEmitter<void> = new EventEmitter();

  @Output()
  onClosed: EventEmitter<void> = new EventEmitter();

  constructor(private systemcodetypeApi: SystemcodetypeApi) { }

  ngOnInit(): void {
  }

  closeAdd() {
    this.addData = new SystemCode('', '');
    this.showCreate = false;
  }

  async onBtnSystemCodeAddClick(codeType: any) {
    this.showCreate = true;
    this.addData = new SystemCode('', '')
    let systemTypeDataResult = await this.systemcodetypeApi.GetAll(codeType)
    let systemTypeDatas = systemTypeDataResult.data as SystemCodeType[];

    var type = systemTypeDatas[0];
    this.addData.Type = type.Id;
    this.addData.TypeCode = type.Type;
    this.addData.IsEnabled = true;
  }

  handleSystemImportClose() {
    this.systemCodeImportTargetType = "";
    this.onClosed.emit();

  }

  handleSystemImportDone() {
    this.systemCodeImportTargetType = "";
    this.onImported.emit();
  }

  handleAddDone() {
    this.systemCodeImportTargetType = "";
    this.onAdded.emit();
  }
}
