import { Injectable } from "@angular/core";
import { PageData } from "src/app/shared/models/page-data";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { SystemCodeType } from "src/app/services/api-service/system-code/system-code-type";
import { SystemCode } from "src/app/services/api-service/system-code/system-code";
import { ValueTextPair } from "../../../shared/models/value-text-pair";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { EnumTypes } from "../../data-service/clinic-data-service";
import { CustomResult } from "src/app/shared/models/custom-result";

export interface SystemCodeGetAllQuery {
  clinicId: number;
  type: number;
  withCopy?: boolean;
  pageSize: number;
  pageSkip: number;
}
export interface SystemCodeEditOption {
  companyId: number;
  clinicId: number;
  clinicList: Clinic[];
  systemCodeTypeList: SystemCodeType[];
  stdClinicId: number;
}

@Injectable({
  providedIn: 'root'
})

export class SystemcodeApi {
  private api: WebApiService;
  constructor(
    private apiClient: WebApiClient) {
    this.api = apiClient.createHisService('system/SystemCode');
  }

  async GetCodes(types: string[]): Promise<{ [key: string]: ValueTextPair[] }> {
    return this.api.get(`GetCodes?types=${types.join(',')}`, {}).toPromise();
  }

  async GetSystemCode(types: string[]): Promise<SystemCode[]> {
    return this.api.get(`GetSystemCode?types=${types.join(',')}`, {}).toPromise();
  }

  async GetAll(query: SystemCodeGetAllQuery): Promise<PageData> {
    return this.api.get('GetAll', query).toPromise<PageData>();
  }

  async GetAllSimple(query: SystemCodeGetAllQuery): Promise<PageData> {
    return this.api.get('GetAllSimple', query).toPromise<PageData>();
  }

  async GetCodeForAbnormal(): Promise<SystemCode[]> {
    return this.api.get('GetCodeForAbnormal').toPromise<SystemCode[]>();
  }
  async GetAllFormCompanyMaintain(query: SystemCodeGetAllQuery): Promise<PageData> {
    return this.api.get('GetAllFormCompanyMaintain', query).toPromise<PageData>();
  }
  async GetEditOptions(type: number): Promise<SystemCodeEditOption> {
    return this.api.get('GetEditOptions?systemCodeType=' + type).toPromise<SystemCodeEditOption>();
  }
  async Create(data: SystemCode): Promise<SystemCode> {
    return await this.api.post('Create', data).toPromise();
  }
  async Update(data: SystemCode): Promise<SystemCode> {
    var result = await this.api.put('Update', data).toPromise<SystemCode>();
    return result;
  }

  async UpdateEngDetpName(data: SystemCode): Promise<CustomResult> {
    var result = await this.api.put('UpdateEngDetpName', data).toPromise<CustomResult>();
    return result;
  }
  async Delete(id: number): Promise<SystemCode> {
    return await this.api.delete('Delete/' + id).toPromise();
  }

  async GetEnum(types: EnumTypes[]): Promise<{ [key in EnumTypes]: ValueTextPair[] }> {
    return this.api.get(`GetEnum?types=${types.join(',')}`, {}).toPromise();
  }

  async Import(datas: number[]): Promise<CustomResult> {
    return await this.api.post('Import', datas).toPromise();
  }

}
