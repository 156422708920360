import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { VitalSign } from '../../services/api-service/hist/vital-sign';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AgeYearMonthDiff } from 'src/app/shared/utilities';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { ChildGrowDataChartService, ChildGrowDataType, PIndex } from 'src/app/services/child-grow-chart-service';
import { HotKeyService } from 'src/app/services/hotkey-service';
import { appKeydownCode } from 'src/app/shared/directives/keydown-to.directive';
import { HelperId } from 'src/app/hist/models/helper-id';


@Component({
  selector: 'app-reg-sign',
  templateUrl: './reg-sign.component.html',
  styleUrls: ['./reg-sign.component.css']
})
export class RegSignComponent implements OnInit {

  isChild = true;
  isShowHC: boolean = false;
  isShowLMP: boolean =false;
  isShowEDD: boolean  = false;
  isTodayVT = true;
  //#region 參數 main --------------------
  editFG: FormGroup;

  constructor(private fb: FormBuilder,
    private userConfirmService:UserConfirmService,
    private childGrowService:ChildGrowDataChartService,
    private paraApi:ParameterApi,
    private hotkeyService:HotKeyService
  ) {

  }

  get editFV() {
    var data = this.editFG.getRawValue();
    data.VSDateTime= DateHelper.formatDateTime(data.VSDateTime); //注意時間傳回後端可能有時區問題，因此先toString()
    return data;
  }
  //#endregion

  //#region 參數 input ui --------------------
  initVS: VitalSign = new VitalSign();

  @Input() isHelperPadOpened?:string='';

  @Input()birthDay?:Date;

  @Input()
  set opdRecord(rcd: VitalSign) {
    if(rcd) {
      this.initVS = rcd;
      if (!this.initVS.LMPDate) {
        this.initVS.LMPDate = null;
      }
      if (!this.initVS.DueDate) {
        this.initVS.DueDate = null;
      }
      this.fillFormData(this.initVS);
    }
  }

  
  @Input() isAdd?:boolean = false;
  @Input() canEdit:boolean;
  @Input() histParams:any;
  //@Input()
  displayOrder:{code:string,width:number}[][] = [];
  
  //#endregion

  async ngOnInit() {
    //var histParams = await this.paraApi.GetHST001();
    this.displayOrder = this.histParams.VitalSignDisplay.split(';')
                                    .map(x=>x.split(',')
                                      .map((s,i,a)=>{
                                        return {
                                          code:s,
                                          width:1/a.length
                                        }
                                      }));
    this.editFG = this.makeFormGroup();
    this.fillFormData(this.initVS);
    
    this.isShowHC = this.isShowVSOption('HC') && this.isChild;
    this.isShowLMP = this.initVS.isFemale?this.isShowVSOption('LMP'):false;
    this.isShowEDD = this.initVS.isFemale?this.isShowVSOption('EDD'):false;

    if(!this.isAdd){
      this.nowDate = new Date(this.editFG.value.VSDateTime);
    } else this.nowDate = new Date();
  }

  nowDate = new Date();
  getDisabled(){
    if(!this.canEdit){
      return 'v-disabled';
    }
  }

  getFullDisabled(){
    if(!this.canEdit){
      return 'full-disabled';
    }
  }

  // #region form --------------------
  // 產生表單元件，並定義基本檢核規則
  makeFormGroup(): FormGroup {
    const fg: FormGroup = this.fb.group({
      Id: [null],
      PatientId: [null],
      VSDateTime: [null],
      Height: [null],
      Weight: [null],
      BT: [null],
      Head: [null],
      Pulse: [null],
      SBP: [null],
      DBP: [null],
      LMPDate: [null],
      DueDate: [null],
      IsPregnant: [false],
      isFemale: [false],
      birthday:[null]
    });
    return fg;
  }
  // 填入表單資料
  fillFormData(rcd: VitalSign) {
    if (!this.editFG) { return; }
    this.editFG.patchValue(rcd);
    if(rcd.VSDateTime==undefined) {
      // rcd.VSDateTime = new Date();
      this.editFG.patchValue({
        VSDateTime: new Date(),
      });
    }

    this.editFG.patchValue({
      Height: rcd.Height == 0 ? null : rcd.Height,
      Weight: rcd.Weight == 0 ? null : rcd.Weight,
      BT: rcd.BT == 0 ? null : !this.isAdd ?rcd.BT:null,
      Head: rcd.Head == 0 ? null : !this.isAdd ?rcd.Head:null,
      Pulse: rcd.Pulse == 0 ? null : !this.isAdd?rcd.Pulse:null,
      SBP: rcd.SBP == 0 ? null : !this.isAdd?rcd.SBP:null,
      DBP: rcd.DBP == 0 ? null : !this.isAdd?rcd.DBP:null,
      IsPregnant: rcd.IsPregnant == null ? null :!this.isAdd? rcd.IsPregnant:null ,
      LMPDate: rcd.LMPDate == null ? null : !this.isAdd?DateHelper.createDate(rcd.LMPDate):null,
      DueDate: rcd.DueDate == null ? null : !this.isAdd?DateHelper.createDate(rcd.DueDate):null
    });
    var age= AgeYearMonthDiff(this.birthDay,new Date());
    this.isChild = age.year*12+age.month<=60;
  }
  // #endregion

  isShowVSOption(code: string): boolean {

    if (this.displayOrder && this.displayOrder.some(x=>x.some(a=>a.code==code))) {
      return true;
    } else {
      return false;
    }
  }
  pregnantChange(evt:MatCheckboxChange){
    if(evt.checked){
      if(this.editFG.controls.LMPDate.value && !this.editFG.controls.DueDate.value){
        var d = this.editFG.controls.LMPDate.value as Date;
        this.editFG.controls.DueDate.setValue(new Date(d.getFullYear(),d.getMonth(),d.getDate()+280));
      }
    }else{
      this.editFG.controls.DueDate.setValue(null);
    }
  }
  getFullMonthAge(){
    var age = AgeYearMonthDiff(this.birthDay,new Date());
    return age.year*12+age.month;
  }

  notifyClass(type){
    var value = parseFloat(type=='Height'? this.editFV.Height:
                type=='Weight'?this.editFV.Weight:
                type=='Head'?this.editFV.Head:'');
    var month = this.getFullMonthAge();
    if(value && month<=60){
      var ret = this.childGrowService.validate(this.initVS.isFemale?'Girl':'Boy',type,month,value)
      if(ret > PIndex.P97|| (ret as number) == -1){
        return 'too-high Child'
      }
      if(ret < PIndex.P3){
        return 'too-low Child'
      }
      return '';
    }else{
      if(type=='BT'){
        var bt = parseFloat(this.editFV.BT);
        if(!bt){
          return;
        }
        if(bt>37.5 && bt<38){
          return 'high BT';
        }else if(bt>=38){
          return 'too-high BT';
        }else{
          return 'normal BT';
        }
      }
      if(type=='SBP'){

        var BP1 = parseFloat(this.editFV.SBP);
        if(!BP1){
          return;
        }
        if( BP1<90){
          return 'too-low BP';
        }else if(BP1>120 && BP1<140){
          return 'high BP';
        }else if(BP1>=140){
          return 'too-high BP';
        }
      }

      if(type=='DBP'){
        var BP2 = parseFloat(this.editFV.DBP);
        if(!BP2){
          return;
        }
        if(BP2<60){
          return 'too-low BP';
        }else if(BP2>80 && BP2<90){
          return 'high BP';
        }else if(BP2>=90){
          return 'too-high BP';
        }
      }
      if(type=='Pulse'){
        var ps = parseFloat(this.editFV.Pulse);
        if(!ps){
          return;
        }
        if(ps>100){
          return 'too-high ps';
        }else if(ps>85){
          return 'high ps';
        }else if(ps < 60){
          return 'too-low ps';
        }
      }
    }
  }

  nextCodes(row:number,col:number):string
  {
    var nextAll:string[] = []
    for(var i=row;i<this.displayOrder.length;i++){
      var cRow = this.displayOrder[i];
      var colIdx = i==row?col+1:0;
      for(var j=colIdx;j<cRow.length;j++){
        if(cRow[j].code=='BP'){
          nextAll.push('SBP');
        }else{
          nextAll.push(cRow[j].code)
        }
      }
    }
    return nextAll.join(',');
  }

  /** 取得位置之前的所有ColCode appKeyDown會自動跳過找不到的 */
  preCodes(row:number,col:number):string
  {
    var prevAll:string[] = []
    for(var i=row;i>=0;i--){
      var cRow = this.displayOrder[i];
      var lastColIdx = i==row?col-1:cRow.length-1;
      for(var j=lastColIdx;j>=0;j--){
        if(cRow[j].code=='BP'){
          prevAll.push('Pulse');
        }else{
          prevAll.push(cRow[j].code)
        }
      }
    }
    return prevAll.join(',');
  }
  
}
