export class FilesizeHelper {

  // constructor() { }

  static fileSizeKB (size: number, digit: number = 0): number {
    var toDigit = digit;
    if (toDigit < 0)
      toDigit = 0;
    if (toDigit > 8)
      toDigit = 8;
    return Math.round( ( (size + Number.EPSILON) / 1024 ) * Math.pow( 10, digit ) ) / Math.pow( 10, digit );
  }

  static fileSizeMB (size: number, digit: number = 1): number {
    var toDigit = digit;
    if (toDigit < 0)
      toDigit = 0;
    if (toDigit > 8)
      toDigit = 8;
    return Math.round( ( (size + Number.EPSILON) / (1024 * 1024) ) * Math.pow( 10, digit ) ) / Math.pow( 10, digit );
  }

  static fileSizeGB (size: number, digit: number = 1): number {
    var toDigit = digit;
    if (toDigit < 0)
      toDigit = 0;
    if (toDigit > 8)
      toDigit = 8;
    return Math.round( ( (size + Number.EPSILON) / (1024 * 1024 * 1024) ) * Math.pow( 10, digit ) ) / Math.pow( 10, digit );
  }

  static fileSizeTB (size: number, digit: number = 1): number {
    var toDigit = digit;
    if (toDigit < 0)
      toDigit = 0;
    if (toDigit > 8)
      toDigit = 8;
    return Math.round( ( (size + Number.EPSILON) / (1024 * 1024 * 1024 * 1024) ) * Math.pow( 10, digit ) ) / Math.pow( 10, digit );
  }

}
