import { APP_ID, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fail } from 'assert';
import { DailyReport, DailyReportIcodeSum, DailyReportItem } from 'src/app/payments/models/daily-report';
import { ReceiptEnglishInfo, ReceiptPersonal } from 'src/app/payments/period-receipt/model/receiptpersonal';
import { PrintQueryDto } from 'src/app/payments/period-receipt/model/receiptquerydto';
import { ReceiptNewQueryDto, ReceiptQueryListDto } from 'src/app/payments/period-receipt/model/receiptquerylistdto';
import { DeclareApi, DeclareCaseReportDTO, DeclareCaseReportDetailDTO, DeclareCaseReportPageDTO } from 'src/app/services/api-service/declare/declare-api';
import { PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { PatientsDto } from 'src/app/services/api-service/patient/patientsDto';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { HcrService } from 'src/app/services/hcr-service.service';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import {NullOrEmpty } from 'src/app/shared/utilities';
import { Clinic } from 'src/app/system/clinic/models/clinic';

export interface ChineseNumerals {
    [key: number]: string;
}

@Component({
  selector: 'app-period-receipt-report',
  templateUrl: './period-receipt-report.component.html',
  styleUrls: ['./period-receipt-report.component.css']
})


export class PeriodReceiptReportcomponent implements OnInit {
  sizePage = {
    width: 20, //cm
    height: 28.2 //cm
};
paddingPage = {
    top: 0.5, //cm
    right: 0, //cm
    bottom: 0.75, //cm
    left: 0 //cm
};
personal:ReceiptPersonal = {
    Title: '醫　療　費　用　證　明',
    Name: 'XXX',
    PersonTitle : '女士',
    PatientNo:'0000011',
    Cid:'A123456789',
    StartDate: '112年01月01日',
    EndDate: '113年01月01日',
    Count: 3,
    FeeTotal: '二百五十', // 列印藥袋
    ClinicName: '展望亞洲科技', // 列印病歷單
    DoctorName: '方醫師',
    ClinicAddress: '台北市復興北路166號10樓',
    PrintDate: '112年12月06日'
};
heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
);
elContainer;
anchorsBlockValue;
pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]
isEnglish = false;
@ViewChild('reportContainer', { static: false })
reportContainer: ElementRef<HTMLDivElement>;
@ViewChild('enReportContainer', { static: false })
enReportContainer: ElementRef<HTMLDivElement>;
@ViewChild('reportUseContainer', { static: false })
reportUseContainer: ElementRef<HTMLDivElement>;

showDoctorNameColume: boolean;
monthStr: string = '';
nowStr: string = '';
timeStr: string = '';
totalRecord: number = 0;
pageNo: number = 1;
_sDate: Date;
_eDate: Date;
clinic:Clinic;
patient: PatientListDto;

doctorType:number;
receiptData:ReceiptQueryListDto;
enInfo :ReceiptEnglishInfo;
// api服務
api: WebApiService;
// 查詢條件;
queryFormGroup: FormGroup;

constructor(private webApiClient: WebApiClient,
    private declareApi: DeclareApi,
    private localPrintService: LocalPrintService,
    private notification: EasyNotificationService,
    private hcrService:HcrService,
    private elementRef: ElementRef) {
        this.api = this.webApiClient.createHisService('Payments/Payment');
    }

  /** 是否列印 */
  @Input()
  set isPrint(value) {
    if (value) {
      // this.onPrintAll();
      this.onPrint();
    }
  }
  @Input()
  set isPreviewPrint(value) {
    if (value) {
        // const query = {
        //     startDate: DateHelper.getIsoStringNoTimeZone(new Date()),
        //     endDate: DateHelper.getIsoStringNoTimeZone(new Date()),
        //     timeSec: '',
        //     clinicId: '',
        //     reportType: 'detail',
        // }
        this.fetchData(null);
    }
  }
  @Input() set startMonth(value) {
    if (value) {
      this._sDate = value;
    }
  }
  @Input() set endMonth(value) {
    if (value) {
      this._eDate = value;
    }
  }

  @Output() PrintDone = new EventEmitter<boolean>();

  @Input()
  set isExportAsExcel(value) {
    if (value) {
      this.exportAsExcel();
    }
  }
  @Input()
  set toggleDoctor(value) {
    this.doctorType =value;
  }
  @Input() isShowTotal = false;
//   set isShowTotal(value) {
//   }




ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
        '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
        '.pres-report2 table{ border:0px solid black; }\n' +
        '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 0.1px;padding-right: 0.1px;}\n' +
        '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
        '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
        '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
        '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
        '@page { size: A4; margin: 0.5cm 0.5cm; }\n'
        // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
        this.reportContainer.nativeElement.appendChild(st);
    }, 0);
}

ngAfterViewInit() {

}



// 取得統計期間的看診人次資料，並組出報表的html內容
async fetchData(dto:PrintQueryDto) {
    if(!this.isEnglish)this.elContainer = this.reportUseContainer.nativeElement;
    // this.takePersonaL();


    if(!this.isEnglish) this.insertListData(dto);
}

takeEnglish(){
    var total = this.receiptData.Data.find(x => x.Isqno == '總計');
    this.enInfo =  new ReceiptEnglishInfo();

    this.enInfo.PatientNo = this.patient.PatientNo;
    this.enInfo.Certificate = 0;
    this.enInfo.Name = this.patient.Name;
    var sDate = new Date(this.receiptData.StartRegDate);
    var eDate = new Date(this.receiptData.EndRegDate);
    var now = new Date();
    var birth = new Date(this.patient.Birthday);

    this.enInfo.Age = now.getFullYear() - birth.getFullYear();
    this.enInfo.StartDate = sDate.toDateString();
    this.enInfo.EndDate = eDate.toDateString();
    this.enInfo.FeeDrug = total?.FeeDrug ?? 0;
    this.enInfo.FeeDx = total?.FeeDx?? 0;
    this.enInfo.FeeMAT = total?.FeeMAT?? 0;
    this.enInfo.FeeOwn = total?.FeeOwn?? 0;
    this.enInfo.FeePartExam = total?.FeePartRx?? 0;
    this.enInfo.FeePartRx = total?.FeePartRx?? 0;
    this.enInfo.FeeRegister = total?.FeeRegister?? 0;
    this.enInfo.FeeSubTotal = total?.FeeSubTotal?? 0;
    this.enInfo.FeeTx6 = total?.FeeTx6?? 0;
    this.enInfo.FeeTx7 = total?.FeeTx7?? 0;
    this.enInfo.FeeTx8 = total?.FeeTx8?? 0;
    this.enInfo.FeeTx9 = total?.FeeTx9?? 0;
    this.enInfo.Superintendent = this.clinic?.OwnerName;
    this.enInfo.FeeMiscellaneous = total?.FeeMed;
    this.enInfo.Prescription = 0;
    this.enInfo.PhysicalExam = 0;
    this.enInfo.IssuedDate = now.toDateString();

}
takeBeforeFetch(dto:PrintQueryDto){
    this.clinic = UserCache.getLoginUser().Clinic;
    if(!this.isEnglish) this.takePersonaL(dto);
    else this.takeEnglish();
}
takePersonaL(dto:PrintQueryDto){


    let userName = UserCache.getLoginUser().Name;
    this.personal = new ReceiptPersonal();
    switch (dto?.OutPutTitle)
    {
        case '1':
        this.personal.Title = '醫　療　費　用　收　據';
            break;
        case '2':
        this.personal.Title = '醫　療　費　用　證　明';
            break;
        case '3':
        this.personal.Title = '醫　療　費　用　明　細';
            break;
        default:
        this.personal.Title = '醫　療　費　用　證　明';
            break;
    }
    this.personal.Title = '醫　療　費　用　收　據';
    this.personal.PersonTitle = this.patient.Sex == 0 ?'女士':'先生';
    this.personal.Name = this.patient.Name;
    this.personal.Cid = this.patient.CId;
    this.personal.PatientNo = this.patient.PatientNo;
    this.personal.ClinicAddress = this.clinic.Address ??'';
    this.personal.ClinicName = this.clinic.Name;
    this.personal.Count = this.receiptData?.Data?.length ?? 0;
    this.personal.FeeTotal = this.numberToChinese(this.receiptData?.FeeSubTotal)
    this.personal.DoctorName = userName;
    this.personal.StartDate =  this.formatDateString(this.receiptData?.StartRegDate);
    this.personal.EndDate =  this.formatDateString(this.receiptData?.EndRegDate);
    this.personal.PrintDate = this.formatDateString(new Date());
    setTimeout(() => {
        this.isopenform = true;
    }, 1);
}
isopenform = false;
// 組報表的html內容
insertListData(dto:PrintQueryDto) {


    let detail = this.receiptData?.Data ?? [];
    var html_Block = "";
    var iPage = 0;
    var iBlock = 0;
    var isSum = false;
    var nowsearch = new Date();
    this.nowStr = this.onGetDateString(new Date(), true);
    this.elContainer.innerHTML = this.createHTMLPage(iPage);    // 整份報表的html內容
    var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
    var lineheight = 0;


    if (dto?.OutPutType != '2' && dto?.OutPutType != '3')
    {
        var html_header = this.createHTMLPageHeader(iPage);     // 頁表頭
        // 組報表內容
        // [頁表頭][List欄位顯示名稱]
        elPageContent.innerHTML = html_header;
        var elTableContent = document.getElementById("table-" + iPage + "-content");
        var html_ListerHeader = this.createHTMLListHeader();    // List欄位顯示名稱
        elTableContent.innerHTML += html_ListerHeader;
        lineheight = 7;
        for (let i = 0; i < detail.length; i++) {
            // 加入 List 每一筆的內容
            html_Block = this.createHTMLBlock(detail[i]);
            elTableContent.innerHTML += html_Block;
            var prevTableContent = elTableContent.innerHTML;
            lineheight +=1;
            // 判斷是否超出頁面範圍? -> 新增一頁
            if (lineheight >= 60 ) {
                elTableContent.innerHTML = prevTableContent;
                iPage += 1;
                lineheight = 7;
                this.elContainer.innerHTML += this.createHTMLPage(iPage);
                elPageContent = document.getElementById("page-" + iPage + "-content");
                // [頁表頭][List欄位顯示名稱]
                html_header = this.createHTMLPageHeader(iPage);
                // html_header = this.createHTMLPageNoHeader(iPage);
                elPageContent.innerHTML += html_header;
                elTableContent = document.getElementById("table-" + iPage + "-content");
                elTableContent.innerHTML = html_ListerHeader;

                this.pageContent[iPage] = [];
                this.pageContent[iPage].push(iBlock);
            } else {
                this.pageContent[iPage].push(iBlock);
            }
            iBlock += 1;
                }
    }




    if (lineheight >= 40){
        elTableContent.innerHTML = prevTableContent;
        iPage += 1;
        this.elContainer.innerHTML += this.createHTMLPage(iPage);
        elPageContent = document.getElementById("page-" + iPage + "-content");
        // [頁表頭][List欄位顯示名稱]
        var last = document.getElementById("containerlast");
        elPageContent.innerHTML += last.innerHTML;
        html_header = this.createHTMLPageHeader(iPage);
    }
    else{
        var last = document.getElementById("containerlast");
        elPageContent.innerHTML += last.innerHTML;
    }
    // var last = document.getElementById("containerlast");
    // elPageContent.innerHTML += last.innerHTML;
    // elPageContent.innerHTML +=  html_finish;

}

convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
}

convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
}

createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.2cm rgba(0, 0, 0, 0.05);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
}

createHTMLListHeader() {
    return `
    <tr>
    <th style="text-align: left;max-width: 2.25rem;min-width: 2.25rem;border-left: none;border-right: none;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    日期
    </th>
    <th style="text-align: left;max-width: 1.5rem;min-width: 1.5rem;border-left: none;border-right: none;border-left: 0px solid black;border-top: none;border-bottom: 5px double black;">
    身分
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    掛號
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    自付
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    藥付
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    檢付
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    診察
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    藥費
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    注技
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    檢驗
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    X光
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    理療
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    處置
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    手術
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    麻醉
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    材料
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    藥事
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    其他
    </th>
    <th style="text-align: right;max-width: 2rem;min-width: 2rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    自費
    </th>
    <th style="text-align: right;max-width: 2.5rem;min-width: 2.5rem;border-left: none;border-right: none;border-top: none;border-bottom: 5px double black;">
    申請
    </th>
</tr>`;
}

stringFactory(input:string) :string{
    return !NullOrEmpty(input)?input.trimRight() :' ';
}

numberFactory(input:number):string{
    let result = '';
    if(!isNaN(input)){
        result = input > 0 ? input.toString():'0';
    }
    let paddedString = this.padToLength(result, 4, " ");
    return paddedString;
}
padToLength(inputString: string, targetLength: number, paddingChar: string): string {
    const paddingLength = targetLength - inputString.length;
    if (paddingLength > 0) {
        return inputString + paddingChar.repeat(paddingLength);
    } else {
        return inputString;
    }
}

stringBFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length >= 5 ? str.slice(0, 5) :str;
    return inputSlice;
}
stringCFactory(input:string) :string{
    let str = !NullOrEmpty(input)?input :'';
    let inputSlice = str.length > 7 ? str.slice(-7):str;
    return inputSlice;
}
stringTimeZone(input:string) :string{
    let result = !NullOrEmpty(input)?input.trimRight() :' ';
    switch(input){
        case'早上':
        result = '早';
        break;
        case'下午':
        result = '午';
        break;
        case'晚上':
        result = '晚';
        break;
        default:
        result = ' ';
        break;
    }
    return result;
}


createHTMLBlock(data: ReceiptNewQueryDto) {
    var summaryStyle = "border-top: none;";
    var date = DateHelper.formatROCDate(data.RegDate);
    if (data.Isqno === '總計'){

        date = '總計';
        summaryStyle = "border-top:2px double black;";
    }
    if (date === '總計'){
        var feeDrug = this.numberFactory(data.FeeDrug);
        console.log('data',data,feeDrug);

    }
    var icode =  this.stringFactory(data?.ICodeName || '');
    var feeOwn = this.numberFactory(data?.FeeOwn);
    var feeReg = this.numberFactory(data?.FeeRegister);
    var feeOPD = this.numberFactory(data?.FeePartOPD);
    var feeApartRx = this.numberFactory(data?.FeePartRx);
    var feeAPartExam = this.numberFactory(data?.FeePartExam);
    var feeDx = this.numberFactory(data?.FeeDx);
    var feeDrug = this.numberFactory(data.FeeDrug);
    var feeTx5 = this.numberFactory(data?.FeeTx5);
    var feeTx6 = this.numberFactory(data?.FeeTx6);
    var feeTx7 = this.numberFactory(data?.FeeTx7);
    var feeTx8 = this.numberFactory(data?.FeeTx8);
    var feeTx9 = this.numberFactory(data?.FeeTx9);
    var feeTx10 = this.numberFactory(data?.FeeTx10);
    var feeTx11 = this.numberFactory(data?.FeeTx11);
    // var FeeLAMT = this.numberFactory(data?.FeeLAMT);
    var feeMAT = this.numberFactory(data?.FeeMAT);
    var feeMed = this.numberFactory(data?.FeeMed);
    var feeOther = '';
    var feeTotal = this.numberFactory(data?.FeeTot);



    return  '<tr>' +
                '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + date + '</td>' +
                '<td style="text-align: left; ' + summaryStyle + ' border-bottom: none;">' + icode + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeOPD + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeReg + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeApartRx + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeAPartExam + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeDx + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeDrug + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx5 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx6 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx7 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx8 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx9 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx10 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTx11 + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeMAT + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeMed + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeOther + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeOwn + '</td>' +
                '<td style="text-align: right; ' + summaryStyle + ' border-bottom: none;">' + feeTotal + '</td>' +


            '</tr>' ;
}
createHTMLPageHeader(iPage) {
    return `<div id="header" style="margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table style="width: 100%; border: none;">
                        <tr>
                            <td colspan="6" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            ` + (this.clinic?.Name || '') + `
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            日期 ` + DateHelper.formatROCDate(this.receiptData?.StartRegDate)+'至'+ DateHelper.formatROCDate(this.receiptData?.EndRegDate) + `
                            </td>
                            <td colspan="2"; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            </td>
                            <td colspan="2" ; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                            頁 ` + (iPage + 1) + `
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 10px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 1px;">
                <table id="table-` + iPage + `-content" style="width: 100%; font-size: 10.5pt; border: none;">
                </table>
                </div>
            </div>`;
}



createNoDetailFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 10pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">查無資料</label>
            </div>`;
}

getHtml() {
    if (!this.isEnglish) var html = this.reportContainer.nativeElement.innerHTML;
    else var html = this.enReportContainer.nativeElement.innerHTML;
    return html;
}

async onPrint() {
    if (this.receiptData?.Data.length > 0) {
        console.log('this.receiptData',this.receiptData);
        var reportName = '期間收據';
        const printContents = this.getHtml();
        var ret = await this.localPrintService.printHtml(444, reportName, printContents);
        if (ret.Successed) {
            this.notification.showSuccess(reportName + '已送出列印!', true);
            this.PrintDone.emit(true);
        } else {
            this.notification.showError(reportName + '送出列印失敗!');
        }
    } else {
        this.notification.showInfo('查無資料可供列印!');

    }

}

exportAsExcel() {
    if (this.receiptData) {
        const uri = 'data:application/vnd.ms-excel;base64,';
        const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
                            <head>
                                <!--[if gte mso 9]>
                                <xml>
                                    <x:ExcelWorkbook>
                                        <x:ExcelWorksheets>
                                            <x:ExcelWorksheet>
                                                <x:Name>{worksheet}</x:Name>
                                                <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions>
                                            </x:ExcelWorksheet>
                                        </x:ExcelWorksheets>
                                    </x:ExcelWorkbook>
                                </xml>
                                <![endif]-->
                            </head>
                            <body>
                                <table>{table}</table>
                            </body>
                            </html>`;
        const base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) };
        const format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) };
        const table = this.reportContainer.nativeElement;
        const ctx = { worksheet: '期間收據', table: table.innerHTML };

        const link = document.createElement('a');
        link.download = `期間收據` + this.onGetDateString(new Date(), false, '', false) + this.onGetTimeString(new Date(), '') + `.xls`;
        link.href = uri + base64(format(template, ctx));
        link.click();
    } else {
        this.notification.showInfo('查無資料可供匯出!');
    }
}

/** 轉換Date To String
 *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
 */
onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
        var d = new Date(ev);
        var month = '' + (d.getMonth() + 1);
        var day = '' + d.getDate();
        var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        if (isMonth) {
            return [year, month].join(symbol);
        } else {
            return [year, month, day].join(symbol);
        }
    }
}

numberToChinese(num: number): string {
    if (!num)return'零';
    const chineseNumerals: string[] = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'];
    const unitPosition: string[] = ['', '拾', '佰', '仟'];
    const unitSection: string[] = ['', '萬', '億', '兆'];

    const numStr: string = num.toString();
    const numLen: number = numStr.length;
    const chunks: number = Math.ceil(numLen / 4); // 每一節4位數

    const convertChunk = (chunk: number): string => {
        const chunkStr: string = chunk.toString();
        const chunkLen: number = chunkStr.length;
        let result: string = '';

        for (let i = 0; i < chunkLen; i++) {
            const digit: number = parseInt(chunkStr[i]);
            if (digit !== 0) {
                result += chineseNumerals[digit] + unitPosition[chunkLen - i - 1];
            }
        }

        return result;
    };

    let result: string = '';
    for (let i = 0; i < chunks; i++) {
        const chunk: number = parseInt(
            numStr.slice(Math.max(numLen - (i + 1) * 4, 0), numLen - i * 4)
        );
        if (chunk !== 0) {
            result = convertChunk(chunk) + unitSection[i] + result;
        }
    }

    return result || chineseNumerals[0];
}
/** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
*/
onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
        var d = new Date(ev);
        // 趕時間先簡單寫
        let hr = d.getHours().toString();
        if (d.getHours() < 10) {
            hr = '0' + hr;
        }
        let min = d.getMinutes().toString();
        if (d.getMinutes() < 10) {
            min = '0' + min;
        }
        let sec = d.getSeconds().toString();
        if (d.getSeconds() < 10) {
            sec = '0' + sec;
        }
        var timeStr = [hr, min, sec].join(timeSymbol);
        return timeStr;
        }
    }
    formatDateString(input: Date): string {
        // 建立日期物件
        const date = new Date(input);

        // 取得年、月、日
        const year = date.getFullYear() - 1911; // 減去1911以得到民國年
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份需加1，並補0
        const day = String(date.getDate()).padStart(2, '0'); // 補0

        // 構建格式化字串
        const formattedDate = `${year}年${month}月${day}日`;

        return formattedDate;
    }

    onGetDate(ev) {
        if (ev) {
            var d = new Date(ev);
            var month = '' + (d.getMonth() + 1);
            var day = '' + d.getDate();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [month, day].join('');
        }
    }

}
