import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/catch';

import { tap, takeUntil } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { MainLayoutService } from 'src/app/layout/services/main-layout.service';
import { LoginUser } from 'src/app/permission/models/login-user';
import { UserCache } from './user-cache';

@Injectable({
  providedIn: 'root'
})
export class WebApiService {

  constructor(private http: HttpClient,
    private mainLayoutService: MainLayoutService) {

  }
  baseUrl = ''; // scheme + host + port + application root, e.g. http://localhost/cloudhis/
  basePath = ''; // application controller path, e.g. system/company
  loaderAutoHide = true;
  enableLoader = true;
  httpOptions = {
    headers: new HttpHeaders(),
    params: {},
  };
  // 更新token與ClinicId
  updateAuthData() {
    const loginUser: LoginUser = UserCache.getLoginUser();
    this.httpOptions.headers = this.httpOptions.headers.set('Authorization',  'Bearer ' + loginUser.AuthToken);
    // this.httpOptions.headers = this.httpOptions.headers.set('companycode', 'UIoperate');
    //this.httpOptions.headers = this.httpOptions.headers.set('Token', loginUser.AuthToken);
    //this.httpOptions.headers = this.httpOptions.headers.set('ClinicId', loginUser.ClinicId);
  }

  // paramObject:
  // let paramObject = { code: '1', name: 'test' }
  get(url: string, paramObject?: object, isShowLoader: boolean = true, isText = false): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    this.httpOptions.params = paramObject;
    if (isShowLoader) {
      this.showLoader();
    }
    return this.pipeResult(this.http.get<any>(url, this.httpOptions));
  }

  getFile(url: string, paramObject?: any, isShowLoader: boolean = true): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    this.httpOptions.params = paramObject;
    if (isShowLoader) {
      this.showLoader();
    }
    return this.pipeResult(this.http.get(url,
      {
        headers: this.httpOptions.headers,
        params: this.httpOptions.params,
        responseType: 'blob',
        observe: 'body'
      }
    ));
  }

  post(url: string, data: any, isShowLoader: boolean = true): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    if (isShowLoader) {
      this.showLoader();
    }
    // console.log('data .... >>>>>', data);
    return this.pipeResult(this.http.post<any>(url, data, this.httpOptions));
  }

  postAdv(url: string, data: any, isShowLoader: boolean = true, httpOptions?: { headers?: HttpHeaders; params?: HttpParams; responseType?: any }): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    if (isShowLoader) {
      this.showLoader();
    }
    const options = { ...this.httpOptions, ...httpOptions };
    return this.pipeResult(this.http.post<any>(url, data, options));
  }

  put(url: string, data: any, isShowLoader: boolean = true): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    this.httpOptions.params = {};
    if (isShowLoader) {
      this.showLoader();
    }
    return this.pipeResult(this.http.put<any>(url, data, this.httpOptions));
  }
  delete(url: string, isShowLoader: boolean = true): Observable<any> {
    this.updateAuthData();
    url = this.getFullUrl(url);
    if (isShowLoader) {
      this.showLoader();
    }
    return this.pipeResult(this.http.delete<any>(url, this.httpOptions));
  }
  private getFullUrl(url: string) {
    if (url && (url.startsWith('http:') || url.startsWith('https:'))) {
      return url;
    } else {
      return this.combineUrl(this.combineUrl(this.baseUrl, this.basePath), url);
    }
  }
  combineUrl(url: string, path: string): string {
    if (path) {
      if (!url.endsWith('/')) { url = url + '/'; }
      if (path.startsWith('/')) { path = path.substr(1, path.length - 1); }
      url = url + path;
    }
    return url;
  }
  private log(data: any) {
    // console.log(data);
  }
  private showLoader() {
    if(this.enableLoader){
      this.mainLayoutService.showLoader();
    }

  }
  private hideLoader() {
    if(this.enableLoader && this.loaderAutoHide){
      this.mainLayoutService.hideLoader();
    }

  }
  // 因為每個動作要處理的事情一樣 所以放一起
  private pipeResult(obs: Observable<any>) {
    return obs.pipe(
        tap(
          d => {
            this.hideLoader();
            // console.log('+++++', d);
            return d;
          },
          error => {
            // console.log(error);
            this.hideLoader();
          },
          () => {
            this.hideLoader();
            this.httpOptions.params = {};
          }
        ),
      );
  }
}
