<!-- component/input -->
<!-- histRcd.Patient.SexName==='女'&& -->

<form [formGroup]="editFG" *ngIf="displayOrder.length" style="width: max-content;" class="d-flex reg-sign extend-msg"
  [ngClass]="getFullDisabled()">
  <div class="flex-grow-one px-2 py-1 text-center sign-item sign-item" style="max-width:6.5rem">
    <label class="w-100 flex-grow-one mb-0">最新測量</label>
    <span>{{nowDate | formatDate}}
    </span>
  </div>
  <ng-container *ngFor="let l of displayOrder;let i = index">
    <ng-container *ngFor="let cell of l;let j = index">
      <ng-container [ngSwitch]="cell.code">
        <ng-container *ngSwitchCase="'HT'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:4.5rem">
            <label class="w-100 flex-grow-one mb-0">身高</label>

            <span [ngClass]="notifyClass('Height')">
              <input appHistFocus numberTrimPreZero class="w-100 measure bg-white" maxlength="5"
                [appKeydownTo]="cell.code" [nextTo]="nextCodes(i,j)" [prevTo]="preCodes(i,j)"
                formControlName="Height" [attr.disabled]="getDisabled()"
                [ngClass]="getDisabled()"/>
            </span>

          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'WT'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:4.5rem">
            <label class="w-100 flex-grow-one mb-0">體重</label>

            <span [ngClass]="notifyClass('Weight')">
              <input appHistFocus numberTrimPreZero class="w-100 measure bg-white" maxlength="5"
                [appKeydownTo]="cell.code" [nextTo]="nextCodes(i,j)" [prevTo]="preCodes(i,j)"
                formControlName="Weight" [attr.disabled]="getDisabled()"
                [ngClass]="getDisabled()"/>
            </span>

          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'BT'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:3.5rem">
            <label class="w-100 flex-grow-one mb-0">體溫</label>
            <span [ngClass]="notifyClass('BT')">
              <input class="w-100 measure bg-white" numberTrimPreZero maxlength="5" formControlName="BT"
                [attr.disabled]="getDisabled()" [ngClass]="getDisabled()" [appKeydownTo]="cell.code"
                [nextTo]="nextCodes(i,j)" [prevTo]="preCodes(i,j)" appHistFocus />
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'HC'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:3rem" *ngIf="isShowHC">
            <label class="w-100 flex-grow-one mb-0">頭圍</label>
            <span [ngClass]="notifyClass('Head')">
              <input class="w-100 measure bg-white" numberTrimPreZero maxlength="5" formControlName="Head"
                [attr.disabled]="getDisabled()" [ngClass]="getDisabled()" [appKeydownTo]="cell.code"
                [nextTo]="nextCodes(i,j)" [prevTo]="preCodes(i,j)" appHistFocus />
            </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'BP'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item">
            <label class="w-100 flex-grow-one d-flex mb-0">
              <span class="flex-grow-one text-center blood-4">收縮</span>
              <span class="flex-grow-one text-center blood-3">舒張</span>
              <span class="flex-grow-one text-center blood-3">脈搏</span>
            </label>
            <div class="d-flex w-100" style="max-width:10rem">
              <span [ngClass]="notifyClass('SBP')" class="flex-grow-one blood-4">
                <input appHistFocus placeholder="收縮" class="bg-white w-100" [appKeydownTo]="'SBP'" [nextTo]="'DBP'"
                  [prevTo]="preCodes(i,j)" appNumberOnly maxlength="5" formControlName="SBP"
                  [attr.disabled]="getDisabled()" [ngClass]="getDisabled()" title="收縮壓" />
              </span>
              <span [ngClass]="notifyClass('DBP')" class="flex-grow-one blood-3">
                <input appHistFocus placeholder="舒張" class="bg-white w-100" [appKeydownTo]="'DBP'" [nextTo]="'Pulse'"
                  [prevTo]="'SBP'" appNumberOnly maxlength="5" formControlName="DBP" [attr.disabled]="getDisabled()"
                  [ngClass]="getDisabled()" title="舒張壓" appHistFocus />
              </span>
              <span [ngClass]="notifyClass('Pulse')" class="flex-grow-one blood-3">
                <input appHistFocus placeholder="脈搏" class="bg-white w-100" [appKeydownTo]="'Pulse'"
                  [nextTo]="nextCodes(i,j)" [prevTo]="'DBP'" appNumberOnly
                  maxlength="5" formControlName="Pulse" [attr.disabled]="getDisabled()" [ngClass]="getDisabled()"
                  title="脈搏" />
              </span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'LMP'||'EDD'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:3.5rem" *ngIf="initVS.isFemale">
            <label class="w-100 flex-grow-one mb-0" style="word-break: keep-all;">懷孕</label>
            <mat-checkbox class="vs-check ml-1" formControlName="IsPregnant" (change)="pregnantChange($event)"
              appHistFocus [attr.disabled]="getDisabled()" [disabled]="getDisabled()"></mat-checkbox>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'LMP'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:10rem" *ngIf="isShowLMP">
            <label class="w-100 flex-grow-one mb-0">最後來經</label>
            <app-vis-datepicker appHistFocus [customClass]="'bg-white re-sign-input'" [maxlength]="'w-16'"
              [appKeydownTo]="cell.code" [nextTo]="nextCodes(i,j)" [prevTo]="preCodes(i,j)"
               formControlName="LMPDate" [attr.disabled]="getDisabled()"
              [disabled]="getDisabled()" #VSLMP>
            </app-vis-datepicker>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'EDD'">
          <div class="flex-grow-one px-2 py-1 text-center sign-item" style="max-width:10rem" *ngIf="isShowEDD">
            <label class="w-100 flex-grow-one mb-0">預產日期</label>
            <app-vis-datepicker appHistFocus [customClass]="'bg-white re-sign-input'" [maxlength]="'w-16'"
              class="position-relative top-2" [appKeydownTo]="cell.code" [nextTo]="nextCodes(i,j)"
              [prevTo]="preCodes(i,j)" formControlName="DueDate"
              [attr.disabled]="getDisabled()" [disabled]="getDisabled()">
            </app-vis-datepicker>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</form>