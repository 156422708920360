export enum DecreeRxGroupEnum
{
  /** 戒菸 **/
  QuitSmoke = 1,
  /**疫苗?**/
  Vacine=2,
  /**專案輸注液*/
  專案輸注液 = 3,
}

export class decreeRx {
  StdCode:string;
  Group:DecreeRxGroupEnum;
  Type:number;
  BeginDate:Date;
  EndDate:Date;
}