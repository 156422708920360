
export class PrescriptionSave {

    public RegicId : number;
    /**
     * 就診日期時間
     */ 
    public ClinicDateTime: Date = new Date(0);
    /**
     * 診療項目代號
     */ 
    public NhiDrugCode: string = null;

    public Isgn: string = null;
}
export class PrescriptionFeedBack{
    public IsOK : boolean = true;
    public ErrorMessege : string = null;

}