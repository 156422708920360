export class ParameterProfile {
  Id: string;
  CreateTime: Date;
  Creater: string;
  EditTime: Date;
  Editor: string;
  IsEnable: boolean;

  CompanyId: string;
  ClinicId: string;
  ClinicName: string;
  Type: number;
  Group: number;
  Key: string;
  Value: any;
  Name: string;
  Description: string;
  IsHidden: boolean;
}
export enum ParameterDataType{
  /** 數值型態*/
  Int = 1,
  /** Boolean型態*/
  Boolean = 2,
  /** 時間型態*/
  Datetime = 3,
  /** 文字型態*/
  String = 4,
  /** 開關型態*/
  Switch = 5
}
export class ParameterProfileDefine {
  /** 資料類型 */
  DataType:ParameterDataType;
  /** 參數群組 */
  Group: number;
  /** 參數代碼 */
  Key: string;
  /** 參數值 */
  Value: string;
  /** 參數名稱 */
  Name: string;
  /** 參數說明 */
  Description: string;
  /** 可能值 */
  PossibleValue:string;
  /** 是否隱藏 */
  IsHidden: boolean;
}

export class PatientForPrint {
  Birthday: string;
  BlRH:number;
  BlType:number;
  CName: string;
  CId: string;
  ClinicId: string;
  Contacts?: contacts[];
  DrugAllergy: string;
  EducationCode: string;
  Height:number;
  JobCode: string;
  PatientNo: string;
  PatientId: string;
  PhoneArea: string;
  Phone: string;
  Remark: string;
  CellPhone: string;
  Sex:number;
  Weight:number;
}
export class contacts {
  Id:number;
  PatientMastId:number;
  Name: string;
  PhoneHomeArea:number;
  PhoneHome:number;
  Mobile:number;
  MainPerson	:	boolean;
  IsAlive	:	boolean;
}
