<ng-template *ngIf="show" #temp #content >
    <div style="display: flex;flex-direction: column; min-height: inherit; height:calc(100% - 1px);" [ngClass]="fontGreater?'font-great-on':'font-great-off'">
        <div matDialogTitle *ngIf="drag === true" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div style="width: calc(100% - 30px); cursor: move;">
                <span >{{title}}</span>
            </div>
            <button type="button" *ngIf="showCloseBtn" style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
            mat-button  (click)="_onClose()">
                <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
            </button>
        </div>
        <div matDialogTitle *ngIf="drag === false">
          <div style="width: calc(100% - 30px);">
              <span >{{title}}</span>
          </div>
          <button type="button" *ngIf="showCloseBtn" style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
          mat-button (click)="_onClose()">
              <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
          </button>
      </div>
        <div matDialogContent [class.fit-content]="fitContent" style="flex-grow: 1;">
            <ng-content select=":not(app-vis-dialog-actions)">
            </ng-content>
        </div>

        <div *ngIf="actionBar" class="pt-3" style="overflow-y: hidden;">
            <hr class="m-auto" style="width: calc(100% - 30px);">
            <div class="pb-3">
              <ng-content select="app-vis-dialog-actions">
              </ng-content>
            </div>
        </div>
    </div>
</ng-template>
