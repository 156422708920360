import { OnInit, OnDestroy, Directive } from '@angular/core';
import { IEntity } from '../../models/i-entity';
import { BaseListComponent } from './base.list.component';
import { PageData } from '../../models/page-data';
// 支援分頁表格的基底型別，多了處理api回傳分頁資料格式的部分
@Directive()
export abstract class BasePageListComponent<T extends IEntity> extends BaseListComponent<T> implements OnInit {

  // 需要的services:EasyNotificationService
  constructor() {
    super();
  }
  pageData: PageData;
  private _query: any

  //#region 清單
  // 更新清單
  expandQuery(query) {
    if (this._query != undefined) {
      this._query.pageSkip = this.pageSkipNo;
      this._query.pageSize = this.pageSize;
      return this._query
    }
    else {
      query.pageSkip = this.pageSkipNo;
      query.pageSize = this.pageSize;
      return query;
    }
  }
  parseResult(result: any): T[] {
    this.pageData = result;
    return result.data;
  }
  //#endregion

  //#region 表格
  // 表格分頁
  setGridView() {
    this.gridView = {
      data: this.pageData.data,
      total: this.pageData.total
    };
  }
  //#endregion
}
