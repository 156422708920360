import { Component, Input, OnInit } from '@angular/core';
import { GridData } from '../../grid/vis-grid/vis-grid.component';
import { ReserveService } from 'src/app/services/reserve.service';
import { RegisterReserveDTO } from 'src/app/services/api-service-dentist/register/register-api.model';

@Component({
  selector: 'app-reserve-grid',
  templateUrl: './reserve-grid.component.html',
  styleUrls: ['./reserve-grid.component.css']
})
export class ReserveGridComponent implements OnInit {

  @Input() title: string;
  @Input() pageSize: number = 10;
  @Input() pageSkipNo: number = 0;
  @Input() width: string = '100%';
  @Input() data: RegisterReserveDTO[];

  gridView: GridData;
  selectedItems: number[] = [];
  expandIndex?: number = null;

  constructor(private reserveService: ReserveService) { }

  ngOnInit(): void {
    this.refreshList();
  }

  pageChange(page: any): void {
    this.pageSkipNo = (page - 1) * this.pageSize;  // 跳過的資料筆數
    this.refreshList();
  }

  async refreshList() {
    //如果沒有帶資料進來，會取得所有預約資料
    if (!this.data) {
      const defaultData = await this.reserveService.getReserveList(0, new Date(), new Date());
      this.data = RegisterReserveDTO.fromReserveItem(defaultData);
    }

    this.gridView = {
      data: this.data,
      total: this.data.length
    };
  }

  onBtnCheckInClick(data: any) {
    console.log("onBtnCheckInClick", data);

  }
}
