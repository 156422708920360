<!-- [formGroup]="editFormGroup" -->
<div class="hist-search">
  <div class="w-100 d-flex" style="margin-bottom: -4px;">
    <div class="flex-glow-one w-16 text-center">
      <button type="button" class="search-btn w-100 rounded-0 check pl-0 pr-1" (click)="onSaveClick()">
        <mat-icon class="vis-combine-icon d-inline-flex mr-1 btn-scale-90" title="確定">save</mat-icon>
        確定
      </button>
      <button type="button" class="search-btn w-100 rounded-0 return pl-0 pr-1" (click)="onCancelClick()">
        <mat-icon class="vis-combine-icon d-inline-flex mr-1 btn-scale-90" title="取消">cancel</mat-icon>
        取消
      </button>
    </div>
    <div class="flex-glow-one" style="width: calc(100% - 8rem);">
      <textarea class="w-100" style="resize: none;" draggable="false" rows="2" [value]="diagWord" disabled></textarea>
    </div>
    <div class="flex-glow-one w-16 text-center d-flex">
      <mat-checkbox class="top-0 bottom-0 m-auto position-relative start-0" [value]="isLong" (change)="onlongillName($event)" >長病名</mat-checkbox>
    </div>
  </div>
  <div class="w-100 d-flex">
    <div class="flex-glow-one w-16 text-center lh-base">病　代</div>
    <div class="flex-glow-one d-flex" style="width: calc(100% - 8rem);">
      <input class="flex-glow-one col-2" (change)="inputChange($event,0)" [value]="dxArray[0]" >
      <input class="flex-glow-one col-2"(change)="inputChange($event,1)" [value]="dxArray[1]">
      <input class="flex-glow-one col-2"(change)="inputChange($event,2)" [value]="dxArray[2]">
      <input class="flex-glow-one col-2"(change)="inputChange($event,3)" [value]="dxArray[3]">
      <input class="flex-glow-one col-2" [disabled]="true">
      <input class="flex-glow-one col-2" [disabled]="true">
    </div>
  </div>
  <div class="w-100 d-flex">
    <div class="flex-glow-one w-16 text-center lh-base">內　容</div>
    <div class="flex-glow-one" style="width: calc(50% - 4rem);">
      <input class="flex-glow-one w-100" (change)="keyin($event)"  value="">
    </div>
    <div class="flex-glow-one w-16 text-center">
      <button type="button" (click)="normarlsearch()" class="search-btn w-100 rounded-0 pl-0 pr-1 position-relative top-1">
        <mat-icon class="vis-combine-icon d-inline-flex mr-1 btn-scale-90" title="查詢">search</mat-icon>
        查詢</button>
    </div>
    <div class="flex-glow-on text-left ml-2" style="width: calc(50% - 8rem);">
      <mat-checkbox [value]="isEngLish" (change)="engChange($event)" >只查詢英文名</mat-checkbox>
    </div>
    <div class="flex-glow-one w-12">
      <!-- <button type="button" class="search-btn w-100 rounded-0 pl-0 pr-1">
        <mat-icon class="vis-combine-icon d-inline-flex mr-1 btn-scale-90" title="設">settings</mat-icon>
        設
      </button> -->
    </div>
  </div>
  <div class="w-100 d-flex border-bottom-1 px-2">
    <mat-checkbox class="mx-1 disePartName" (change)="searchcheck($event,disePartName.name,disePartName.type)" *ngFor="let disePartName of disePartName1">{{disePartName.name}}</mat-checkbox>
  </div>
  <div class="w-100 d-flex border-bottom-1 px-2 bg-hist-strong">
    <mat-checkbox class="mx-1 disePartName" (change)="searchcheck($event,disePartName.name,disePartName.type)" *ngFor="let disePartName of disePartName2">{{disePartName.name}}</mat-checkbox>
  </div>
  <div class="w-100 d-flex border-bottom-1 px-2">
    <mat-checkbox class="mx-1 disePartName" (change)="searchcheck($event,disePartName.name,disePartName.type)" *ngFor="let disePartName of disePartName3">{{disePartName.name}}</mat-checkbox>
  </div>
  <div class="w-100 d-flex border-bottom-1 px-2 bg-hist-strong">
    <mat-checkbox class="mx-1 disePartName" (change)="searchcheck($event,disePartName.name,disePartName.type)" *ngFor="let disePartName of disePartName4">{{disePartName.name}}</mat-checkbox>
  </div>
  <div class="w-100 d-flex border-bottom-1 px-2" style="flex-flow: wrap;">
    <mat-checkbox class="mx-1 disePartName" (change)="searchcheck($event,disePartName.name,disePartName.type)"  *ngFor="let disePartName of disePartName5">{{disePartName.name}}</mat-checkbox>
  </div>
  <!-- lists -->
  <div class="w-100 d-flex panel-title self-clinic position-relative" [hidden]="maxPublicClinicOpen">
    <div class="flex-grow-one w-20">
      <button type="button" class="search-btn large w-100 rounded-0 check pl-0 pr-1 border-top-0 text-base" (click)="getSelfClinicToggle($event)">
        <mat-icon class="vis-combine-icon d-inline-flex mr-0 btn-scale-90" title="本院">medical_services</mat-icon>
        本院
      </button>
    </div>
    <div class="flex-grow-one multiLine-pre-line mb-2 ml-2 title-name">
      <span class="d-block w-100 text-left">{{selfClinicName}}</span>
      <span class="d-block w-100 text-left">{{selfClinicEName}}</span>
    </div>
    <!-- <div class="flex-glow-one w-20 text-center d-flex">
      <mat-checkbox class="top-0 bottom-0 m-auto position-relative start-0" [(ngModel)]="removeWork">勾選移除</mat-checkbox>
    </div> -->
    <div class="flex-grow-one w-8">
      <button type="button" class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none my-2" style="min-height: 20px;">
        <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getSelfClinicToggle($event)">
          <ng-container *ngIf="maxSelfClinicOpen">call_to_action</ng-container>
          <ng-container *ngIf="!maxSelfClinicOpen">web_asset</ng-container>
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="w-100 d-flex panel-table overflow-y-auto" [hidden]="maxPublicClinicOpen"
    [ngStyle]="{height:maxSelfClinicOpen?getSelfClinicHeight():getSliceHelfHeight()}">
    <!-- TODO[showStickyHead]固定Header:bug:lastRow 會看不到 -->
    <app-vis-grid #firstTable [data]="selfClinicData" [customClass]="'extend'" class="w-100" [showStickyHead]="true"
     [customStyle]="maxSelfClinicOpen?getSelfClinicHeight():getSliceHelfHeight()" [kendoGridSelectBy]="'Code'"
     [pageSize]="dxPageSize"
     [skip]="dxPageSkipNo"
     [showMask]="dxLoading"
     [pageable]="true"
     (pageChange)="dxPageChange($event)"
     [selectable]="true" [kendoGridSelectBy]="'Code'"
     [selectedKeys]="selectedItems"
     (cellClick)="gridCellClickHandler($event,true)"
     >
      <!-- <app-vis-grid-checkbox-column class="text-center" [setDisable]="isDisabled" [field]="'Checked'" [width]="30"
      [someChecked]="isSelfClinicSomeChecked()"   (checkChanged)="removeWork?uploadSelfClinicDataCheckChanged($event):checkChanged($event)">
      </app-vis-grid-checkbox-column> -->
      <app-vis-grid-column  title="選" [width]="25"
      class="text-center">
      <ng-template let-dataItem>
        <mat-icon i18n="@@RegListConsult" (click)="pickedSelfClinic(dataItem)"
          class="vis-combine-icon cursor-pointer icon-hover mat-icon-20"
          title="選入">check_circle
        </mat-icon>
      </ng-template>
    </app-vis-grid-column>
      <app-vis-grid-column  [title]="'病代'" [field]="'Code'" [width]="66"></app-vis-grid-column>
      <app-vis-grid-column   [title]="'病名'" [field]="'CName'" [width]="isLong?450:200"></app-vis-grid-column>
      <!-- <app-vis-grid-column [hide]="isLong" [title]="'病名'" [field]="'CName'" [width]="450"></app-vis-grid-column> -->
      <app-vis-grid-column field="EName" title="英文名"></app-vis-grid-column>
    </app-vis-grid>
  </div>
  <!-- End of 本院醫令 -->
  <div class="w-100 d-flex panel-title position-relative" [hidden]="maxSelfClinicOpen">
    <div class="flex-grow-one w-20">
      <button type="button" class="search-btn large w-100 rounded-0 check pl-0 pr-1 text-base" (click)="getPublicClinicToggle($event)">
        <mat-icon class="vis-combine-icon d-inline-flex mr-0 btn-scale-90" title="標準">menu_book</mat-icon>
        標準
      </button>
    </div>
    <div class="flex-grow-one multiLine-pre-line mb-2 ml-2 title-name">
      <span class="d-block w-100 text-left">{{publicClinicName}}</span>
      <span class="d-block w-100 text-left">{{publicClinicEName}}</span>
    </div>
    <!-- <div class="flex-glow-one w-8 text-center d-flex">
      <mat-icon class="vis-combine-icon element-icon btn-scale-85 m-auto top-0 botton-0 cursor-pointer" (click)="pushAdd();">north</mat-icon>
    </div> -->
    <!-- <div class="flex-glow-one w-20 text-center d-flex">
      <mat-checkbox class="top-0 bottom-0 m-auto position-relative start-0" [(ngModel)]="addWork">勾選新增</mat-checkbox>
    </div> -->
    <div class="flex-grow-one w-8">
      <button type="button" class="btn-scale-80 title-btn2 orders-btn position-absolute p-0 end-0 background-none my-2" style="min-height: 20px;">
        <mat-icon class="vis-combine-icon element-icon btn-scale-85" (click)="getPublicClinicToggle($event);">
          <ng-container *ngIf="maxPublicClinicOpen">call_to_action</ng-container>
          <ng-container *ngIf="!maxPublicClinicOpen">web_asset</ng-container>
        </mat-icon>
      </button>
    </div>
  </div>
  <div class="w-100 d-flex panel-table overflow-y-auto"  [hidden]="maxSelfClinicOpen"
    [ngStyle]="{height:maxPublicClinicOpen?getPublicClinicHeight():getSliceHelfHeight()}">
    <app-vis-grid [data]="publicClinicData" [kendoGridSelectBy]="'Code'" [customClass]="'extend'" class="w-100" [showStickyHead]="true" [customStyle]="maxPublicClinicOpen?getPublicClinicHeight():getSliceHelfHeight()"
    [pageSize]="sdxPageSize"
    [skip]="sdxPageSkipNo"
    [pageable]="true"
    [showMask]="sdxLoading"
    (pageChange)="sdxPageChange($event)"
    [selectable]="true" [kendoGridSelectBy]="'Code'"
    [selectedKeys]="sdxselectedItems"
    (cellClick)="gridCellClickHandler($event)"
    >
      <!-- <app-vis-grid-checkbox-column class="text-center" [setDisable]="isDisabled" [field]="'Checked'" [width]="30"
      [someChecked]="isPublicClinicSomeChecked()" (checkChanged)="addWork?uploadPublicClinicDataCheckChanged($event):checkChanged($event)">
      </app-vis-grid-checkbox-column> -->
      <app-vis-grid-column  title="選" [width]="25"
      class="text-center">
      <ng-template let-dataItem>
        <mat-icon i18n="@@RegListConsult" 	(click)="pickedPublicClinic(dataItem)"
          class="vis-combine-icon cursor-pointer icon-hover mat-icon-20" style="width: 100%;"
          title="選入">check_circle
        </mat-icon>
      </ng-template>
    </app-vis-grid-column>
      <app-vis-grid-column [title]="'病代'" [field]="'Code'" [width]="66"></app-vis-grid-column>
      <!-- <app-vis-grid-column  [hide]="!isLong" [title]="'病名'" [field]="'CName'" [width]="200"></app-vis-grid-column> -->
      <app-vis-grid-column   [title]="'病名'" [field]="'CName'" [width]="isLong?450:200"></app-vis-grid-column>
      <!-- <app-vis-grid-column  [hide]="isLong" [title]="'病名'" [field]="'CName'" [width]="450"></app-vis-grid-column> -->
      <app-vis-grid-column field="EName" title="英文名"></app-vis-grid-column>
    </app-vis-grid>
  </div>
  <!-- End of 標準醫令 -->
</div>
