import { Injectable } from "@angular/core";
import { Clinic } from "src/app/system/clinic/models/clinic";
import { Company } from "src/app/system/company/models/company";
import { CustomResult } from "../../../shared/models/custom-result";
import { PageData, PageData2 } from "../../../shared/models/page-data";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { Entity } from "src/app/shared/models/entity";

export class CmsReport extends Entity{
    /** 公司(集團)識別碼 */
    CompanyId:number;
    /** 機構識別碼 */
    ClinicId:number;
    /** 代碼 */
    Code:string
    /** 說明 */
    Desc:string
    /** 樣板 */
    Template:string
    /** 類型 0報表, 1框架 */
    Type:number;
}
@Injectable({ providedIn: 'root' })
export class CmsReportApi  {
  apiService: WebApiService;
  /**
   *
   */
  constructor(private apiClient: WebApiClient) {
    this.apiService = this.apiClient.createHisService('system/Report');
  }
  
  
  async GetAllTemplate(): Promise<CmsReport[]> {
    return this.apiService.get(`GetAllTemplate`).toPromise<CmsReport[]>();
  }
  async GetAll(): Promise<CmsReport[]> {
    return this.apiService.get(`GetAll`).toPromise<CmsReport[]>();
  }
  async GetLayoutTemplate(): Promise<CmsReport[]> {
    return this.apiService.get(`GetLayoutTemplate`).toPromise<CmsReport[]>();
  }
  async GetClinicLayout(): Promise<CmsReport[]> {
    return this.apiService.get(`GetClinicLayout`).toPromise<CmsReport[]>();
  }
  
  async Get(id:number): Promise<CmsReport> {
    return this.apiService.get(`Get?id=`+id).toPromise<CmsReport>();
  }
  async GetByCode(code:string): Promise<CmsReport> {
    return this.apiService.get(`GetByCode?code=`+code).toPromise<CmsReport>();
  }
  async Create(model: CmsReport): Promise<CmsReport> {
    return this.apiService.post('Create', model).toPromise<CmsReport>();
  }
  async Update(model: CmsReport): Promise<CmsReport> {
    return this.apiService.post('Update', model).toPromise<CmsReport>();
  }
  async Delete(id: number): Promise<CmsReport> {
    return this.apiService.delete('Delete?id='+id).toPromise<CmsReport>();
  }
}
