<div #reportContainer>
  <div class="container page-container" *ngIf="medicationWayReportViewModel" [ngStyle]="{'font-family': fontFamily}">
    <div class="row custom-row-margin w-100">
      <div class="col-12" style="font-weight:bold;font-size: x-large;text-align: center;">
        {{medicationWayReportViewModel.ClinicName}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">掛號證：{{medicationWayReportViewModel.PatientNo}}</div>
      <div class="col-8 col-font-style">身分證：{{medicationWayReportViewModel.PatientCId}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-3-5 col-font-style">姓名：{{medicationWayReportViewModel.PatientName}}</div>
      <div class="col-4 col-font-style">身分：{{medicationWayReportViewModel.RegisterICode}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">就診日：{{medicationWayReportViewModel.RegisterDate}}</div>
      <div class="col-3-5 col-font-style">看診號：{{medicationWayReportViewModel.RegisterWaitNo}}</div>
      <div class="col-4 col-font-style">卡序：{{(medicationWayReportViewModel.RegisterICode == '自費診' ?
                                               '' : medicationWayReportViewModel.RegisterIcno)}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <table class="table">
        <thead>
          <tr class="">
            <th scope="col-2" class="table-th table-font">院內碼</th>
            <th scope="col-4" class="table-th table-font">處方名稱</th>
            <th scope="col-1" class="table-th table-font">次量</th>
            <th scope="col-2" class="table-th table-font">頻率</th>
            <th scope="col-1" class="table-th table-font">服法</th>
            <th scope="col-1" class="table-th table-font">日</th>
            <th scope="col-1" class="table-th table-font">總量</th>
          </tr>
        </thead>
        <tbody>
          <tr class="" *ngFor="let hrx of medicationWayReportViewModel.HRxes">
            <td class="table-td table-font">{{hrx.RxCode}}</td>
            <td class="table-td table-font">{{hrx.RxName}}</td>
            <td class="table-td table-font">{{hrx.Dose}}</td>
            <td class="table-td table-font">{{hrx.Freq}}</td>
            <td class="table-td table-font">{{hrx.Way}}</td>
            <td class="table-td table-font">{{hrx.Days}}</td>
            <td class="table-td table-font">{{hrx.TotalDose}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">醫師：{{medicationWayReportViewModel.DoctorName}}</div>
      <div class="col-4 col-font-style">藥師：{{medicationWayReportViewModel.PharmacistName}}</div>

    </div>
  </div>

</div>
