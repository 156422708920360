<div class="vis-form" [ngClass]="bigRatio?'inside-scrollbar p-0':'pt-3 px-3 pb-0'" [ngStyle]="{maxHeight:bigRatio?'400px':''}">
    <div class="card-header card-subtitle-light-blue py-0 d-flex">
      <h5 class="vis-title d-inline-block text-base font-weight-bold flex-grow-one lh-md lh-base small-title mt-0">
        <mat-icon class="pref-icon vis-combine-icon btn-scale-90 mr-1">date_range</mat-icon>
        休診相關設定
      </h5>
    </div>
    <div class="card-body px-0 pt-1">
      <div class="w-100 d-block">
        <div class="col-12">
          <label class="vis-form-field d-flex">
            <span class="label-name w-28">額外休診時間：</span>
            <div class="d-inline-flex"  style="width: 150px;">
              <app-vis-datepicker [(ngModel)]="selectedDate"></app-vis-datepicker>
            </div>
            <mat-button-toggle-group class="w-48 btn-max-height" [(ngModel)]="selectedSection">
              <mat-button-toggle *ngFor="let item of secOption" i18n="@@FullDay" [value]="item.value" class="p-0">
                {{item.text}}
              </mat-button-toggle>
            </mat-button-toggle-group>
            <button type="button" (click)="addRestTime()"
              class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-warning min-w-32">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left ">
                <span class="material-icons">add_circle_outline</span>
              </mat-icon>新增
            </button>
            <button type="button" class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-cancel min-w-32" (click)="clearRestTime()">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left ">
                <span class="material-icons">cancel</span>
              </mat-icon>清除
            </button>
            <div class="flex-grow-one"></div>
          </label>
        </div>
        <div class="col-12">
          <label class="vis-form-field d-flex mb-2 inside-scrollbar p-0 overflow-y-auto" style="max-height:6rem;">
            <span class="label-name flex-grow-one w-28"></span>
            <div class="flex-grow-one pl-0 d-inline-block" style="width:calc(100% - 6.15rem)">
              <div class="d-inline-block px-2 py-1 mr-1 mb-1 rest-day-time" *ngFor="let restDay of restDays; let i = index">
                <span>{{restDay.text}}</span>
                <app-icon-times class="ml-1 cursor-pointer" (click)="removeRestTime(restDay)"></app-icon-times>
              </div>
              <div class="d-inline-block px-2 py-1 mr-1 mb-1 rest-day-time" *ngIf="!restDays?.length">
                <span>尚未添加額外休診時間</span>
              </div>
            </div>
          </label>
        </div>
        <!-- <div class="col-12">
          <label class="vis-form-field d-flex">
            <span class="label-name flex-grow-one w-28"></span>
            <button type="button" class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-warning min-w-32" (click)="getRestTime(specialDays, false)">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left">
                <span class="material-icons">add_circle_outline</span>
              </mat-icon>新增例外日期
            </button>
            <button type="button" class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-cancel min-w-32" (click)="getCancelAllTime(specialDays)">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left">
                <span class="material-icons">cancel</span>
              </mat-icon>清除所有時間
            </button>
            <div class="flex-grow-one" style="width:calc(100% - 20.5rem)"></div>
          </label>
        </div>
        <div class="col-12">
          <label class="vis-form-field d-flex mb-2 inside-scrollbar p-0 overflow-y-auto" style="max-height:6rem;">
            <span class="label-name flex-grow-one w-28"></span>
            <div class="flex-grow-one pl-0 d-inline-block" style="width:calc(100% - 6.15rem)">
              <div class="d-inline-block px-2 py-1 mr-1 mb-1 rest-day-time" *ngFor="let specialDay of specialDays; let i = index">
                <span>{{specialDay}}</span>
                <app-icon-times class="ml-1 cursor-pointer" (click)="getCancel(i,specialDays)"></app-icon-times>
              </div>
            </div>
          </label>
        </div> -->
        <hr/>
        <div class="col-12">
          <label class="vis-form-field d-flex">
            <span class="label-name w-28">常設節日</span>
            <div class="d-inline-flex" style="width: 150px;">
              <input placeholder="請輸入節日 ex:0101" [(ngModel)]="holidayInput" appNumberOnly/>
            </div>
            <button type="button" class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-warning min-w-32" (click)="addHoliday()">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left">
                <span class="material-icons">add_circle_outline</span>
              </mat-icon>常設每年節日
            </button>
            <button type="button" class="w-32 p-0 btn-max-height ml-1 lh-base vis-btn-cancel min-w-32" (click)="clearHoliday()">
              <mat-icon class="vis-combine-icon btn-scale-90 float-left">
                <span class="material-icons">cancel</span>
              </mat-icon>清除所有節日
            </button>
            <div class="flex-grow-one"></div>
          </label>
        </div>
        <div class="col-12">
          <label class="vis-form-field d-flex mb-2 inside-scrollbar p-0 overflow-y-auto" style="max-height:6rem;">
            <span class="label-name flex-grow-one w-28"></span>
            <div class="flex-grow-one pl-0 d-inline-block" style="width:calc(100% - 6.15rem);">
              <div class="d-inline-block px-2 py-1 mr-1 mb-1 rest-day-time" *ngFor="let holiday of holidays; let i = index">
                <span>{{holiday}}</span>
                <app-icon-times class="ml-1 cursor-pointer" (click)="removeHoliday(holiday)"></app-icon-times>
              </div>
              <div class="d-inline-block px-2 py-1 mr-1 mb-1 rest-day-time" *ngIf="!holidays?.length">
                <span>尚未添加常設節日</span>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
    <!--其他設定顯示模式先不做-->
    <!-- <div class="card-header card-subtitle-light-blue py-0 d-flex">
      <h5 class="vis-title d-inline-block text-base font-weight-bold flex-grow-one lh-md lh-base small-title mt-0">
        <mat-icon class="pref-icon vis-combine-icon btn-scale-90 mr-1">settings</mat-icon>
        其他設定
      </h5>
    </div>
    <div class="card-body pb-0 px-0">
      <div class="col-12">
        <label class="vis-form-field d-flex mb-0">
          <span class="label-name flex-grow-one w-28">診間預約表：</span>
          <app-vis-combobox [textField]="'text'" [valueField]="'value'" [valuePrimitive]="true" [data]="SendTypeCodes"
            [valueFormat]="'text'" [customClass]="'w-32 flex-grow-one text-base'">
          </app-vis-combobox>
          <div class="flex-grow-one" style="width:calc(100% - 15rem)"></div>
        </label>
      </div>
    </div> -->
  </div>