import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

import { PageData } from '../../models/page-data';
import { VisGridColumnSort, VisGridComponent } from './vis-grid/vis-grid.component';
import { Compare } from '../../utilities';
/** 處理本地分頁Grid用 [localPageSource]="{size:number,data:any[]}"為資料來源 */
@Directive({
    selector: '[appLocalPageBar]'
})
export class GridPageDirective implements OnInit {
    
    /** 本地分頁 */
    @Input()
    set localPageSource(data:any[]){
        this._localPageSource = data;
        if(data){
            this.pageSkip = 0;
            this.el.skip = 0;
            if(this.initialed){
                this.pagingSourceToGrid();
            }
        }
    }
    @Input()
    set size(s:number){
        if(s){
            this._size = s;
            this.el.pageSize = this._size;
            this.el.skip = 0;
            this.pageSkip = 0;
            if(this.initialed){
                this.pagingSourceToGrid();
            }            
        }
    }
    @Output()
    pageData = new EventEmitter<any[]>();


    _localPageSource:any[];
    el: VisGridComponent;
    _size = 10;    
    pageSkip = 0;
    initialed = false;
    sortBy:VisGridColumnSort = null;
    constructor(el: VisGridComponent) {
        this.el = el;
        this.el.pageable = true;
        this.el.skip = 0;
    }
    ngOnInit(): void {
        this.el.pageChange.subscribe(page => {
            this.pageSkip = (page - 1) * this._size;
            this.pagingSourceToGrid();
        });
        if(this._localPageSource){
            this.pagingSourceToGrid();
            
        }
        this.initialed = true;
        this.el.sort.subscribe(sortEvt=>{
            this.sortBy=sortEvt;
        })
        
    }

    pagingSourceToGrid(){
        var listData = [].concat(this._localPageSource);
        if(this.sortBy){
            listData = listData.sort((a,b)=>Compare(a[this.sortBy.column],b[this.sortBy.column])*(this.sortBy.sortOrder=='desc'?-1:1))
        }
        
        var sliceData = listData.slice(this.pageSkip,this.pageSkip + this._size)
        this.el.data = {
            data:sliceData,
            total:this._localPageSource.length
        };
        setTimeout(() => {
            this.pageData.emit(sliceData);    
        }, 0);
        
    }
}
