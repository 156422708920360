import { ActivatedRouteSnapshot, ParamMap, ActivatedRoute, Route, Router } from '@angular/router';

export class UrlHelper {
    static readonly routeParamOfCompanyCode = 'com';
    static combineUrl(url: string, path: string): string {
        if (path) {
            if (!url.endsWith('/')) { url = url + '/'; }
            if (path.startsWith('/')) { path = path.substr(1, path.length - 1); }
            url = url + path;
        }
        return url;
    }
    static getCompanyCodeFromRouteSnapshot(routeSnapshot: ActivatedRouteSnapshot): string {
        return routeSnapshot.pathFromRoot[1].params[this.routeParamOfCompanyCode];
    }
    static getCompanyCodeFromRouteParamMap(paramMap: ParamMap): string {
        return paramMap.get(this.routeParamOfCompanyCode);
    }
    static getCompanyCodeFromRoute(router: Router): string {
        return router.routerState.snapshot.root.children[0].params.com;
        //return route.snapshot.paramMap.get(this.routeParamOfCompanyCode);
    }
}
