import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { EasyFormService } from 'src/app/services/easy-form-service';
import { FnKey, HotKeyService, Priority } from 'src/app/services/hotkey-service';
import { BtnAction, FormDefine } from './form-define';
import { FontGreatService } from 'src/app/services/font-great.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';

@Component({
  selector: 'app-easy-form',
  templateUrl: './easy-form.component.html',
  styleUrls: ['./easy-form.component.css']
})
export class EasyFormComponent implements OnInit,AfterViewInit {

  fg:FormGroup;
  origin:any;
  fnId = 0;
  constructor(@Inject(MAT_DIALOG_DATA) public formDefine: FormDefine,
    private fb:FormBuilder,
    //private fService:EasyFormService,
    private fontGreatService:FontGreatService,
    private notify:EasyNotificationService,
    private keyService:HotKeyService) {
      this.fnId = this.keyService.regist(Priority.Low,'None',(k:string)=>{
        if(k=='Escape'){
          this.close();
        }
      })
  }

  @ViewChild('btn')
  btn: ElementRef<HTMLButtonElement>;

  onClose:(s:number,d:any)=>void;
  ngOnInit(): void {
    this.origin = {}
    this.formDefine.fields.forEach(f=>{
      this.origin[f.name] = f.value;
    });

    this.fg = this.fb.group(this.origin);
    this.formDefine.fields.forEach(f=>{
      var validators = [];
      if(f.required){
        validators.push(Validators.required)
      }
      if(f.validator){
        validators.push(...f.validator);
      }
      var control = this.fg.controls[f.name];
      control.setValidators(validators);
      control.markAllAsTouched();
      control.updateValueAndValidity();
    });

    setTimeout(() => {

      var el = document.getElementById( 'ez-form-'+this.formDefine.fields[0].name);
      //el.focus();
    }, 1000);
  }
  fontGreater:boolean = false;
  ngAfterViewInit(): void {
    this.btn.nativeElement.focus();
    this.fontGreater = this.fontGreatService.getFontGreat();
  }

  /** 取消回傳原始值 */
  close(){
    this.keyService.unRegist(this.fnId);
    if(this.formDefine.closeValue){
      var fixed = this.formDefine.closeValue(this.fg.value);
      this.onClose(this.formDefine.formId,fixed)
      //this.fService.formClose(this.formDefine.formId,fixed);
    }else{
      this.onClose(this.formDefine.formId,this.origin)
      //this.fService.formClose(this.formDefine.formId,this.origin);
    }
  }
  isWaiting = false;
  async submit(){
    if(this.fg.valid){
      // 有定義此事件時，進行呼叫，回傳true才可以關閉
      if (this.formDefine.beforeSubmit) {
        //防連點
        this.isWaiting = true;
        try{
          var canSubmit = await this.formDefine.beforeSubmit(this.fg.value);
          this.isWaiting = false;
          if(!canSubmit){
            return;
          }
        }catch(ex){
          console.log(ex);
          this.notify.showError('表單驗證處理失敗');
        }
        
      }
      var numField = this.formDefine.fields.filter(x=>x.type=='number');
      numField.forEach(x=>this.fg.controls[x.name].patchValue(Number(this.fg.value[x.name])));
      this.keyService.unRegist(this.fnId);
      this.onClose(this.formDefine.formId,this.fg.value);
      //this.fService.formClose(this.formDefine.formId,this.fg.value);
    }
  }

  async doBtn(btn:{callback:(data)=>BtnAction}){
    setTimeout(async () => {
      if(btn.callback){
        var ret = await btn.callback(this.fg.value);
        if(ret?.action=='Patch'){
          this.fg.patchValue(ret.data,{emitEvent:false});
        }
      }  
    }, 0);
    
  }
  @Input() classList?:string = '';
}
