import { AfterContentChecked, AfterContentInit, AfterViewInit, Component, ContentChildren, ElementRef, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef, ViewRef } from '@angular/core';
import { Button } from 'selenium-webdriver';

@Component({
  selector: 'app-vis-dialog-actions',
  templateUrl: './vis-dialog-actions.component.html',
  styleUrls: ['./vis-dialog-actions.component.css']
})
export class VisDialogActionsComponent implements OnInit  {

  constructor() { }

  ngOnInit(): void {
  }
  
}
