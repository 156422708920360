import { Pipe, PipeTransform } from '@angular/core';
import { mas } from 'process';

@Pipe({
  name: 'CIdMask'
})
export class CidMaskPipe implements PipeTransform {

  transform(value: any, mask: string=null): string {
    if(value==null||value==undefined){
      return "";
    }
    if(mask==null) {
      return value.substring(0,3) + '*****' + value.substring(8);
    } else {
      var ret = '';
      for (var i = 0; i < mask.length; i++) {
        if (i >= value.length) {
          return ret;
        }
        var char = mask.charAt(i);
        // 0-9使用原本字元
        if (/[0-9]/.test(char)) {
          ret += value.charAt(i);
        } else {
          //其他使用替代字元
          ret += char;
        }
      }
    }
    return ret;
  }
}
