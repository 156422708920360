<app-vis-grid [data]="gridView" [pageSize]="pageSize" [skip]="pageSkipNo" [pageable]="true"
  (pageChange)="pageChange($event)" [selectable]="true" [kendoGridSelectBy]="'Id'" [selectedKeys]="selectedItems">
  <app-vis-grid-column field="Type" title="類別" [width]="80" i18n-title="@@Type">
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="ellipsis-content" [title]="dataItem.Type"> {{ dataItem.Type }}</div>
    </ng-template>
  </app-vis-grid-column>
  <app-vis-grid-editable-column [editRow]="editRowId" field="Code" title="代碼" i18n-title="@@Code" [width]="300">
    <input [(ngModel)]="editData.Code" />
  </app-vis-grid-editable-column>
  <app-vis-grid-editable-column [editRow]="editRowId" field="Name" title="名稱" [width]="300" i18n-title="@@Name">
    <input [(ngModel)]="editData.Name" />
  </app-vis-grid-editable-column>
  <!-- <app-vis-grid-column field="Name" title="名稱"  i18n-title="@@Name">
</app-vis-grid-column> -->
  <app-vis-grid-editable-column [editRow]="editRowId" [textCenter]="true" [width]="130" field="IsEnabled" title="是否啟用"
    style="width: 16%;" i18n-title="@@IsEnabled" class="text-center custom-m-auto">
    <ng-template let-data #viewTmp>
      {{data.IsEnabled?'是':'否'}}
    </ng-template>
    <mat-radio-group [(ngModel)]="editData.IsEnabled" class="vis-form-field" style="height: 1.33rem;">
      <mat-radio-button [value]="true">是</mat-radio-button>
      <mat-radio-button [value]="false">否</mat-radio-button>
    </mat-radio-group>
  </app-vis-grid-editable-column>
  <app-vis-grid-editable-column [editRow]="editRowId" [textCenter]="true" field="SN" title="排序" [width]="60"
    i18n-title="@@SN" style="width: 16%;">
    <input [(ngModel)]="editData.SN" />
  </app-vis-grid-editable-column>
  <app-vis-grid-command-column [width]="180" [(editRow)]="editRowId" [deletable]="true" [hideEditBtn]="!IsCanEdit"
    (edit)="editRow($event.data,$event.row)" (save)="saveRow()" class="text-center custom-m-auto"
    (delete)="deleteRow($event.data)">
  </app-vis-grid-command-column>
</app-vis-grid>

<app-system-code-add [show]="showCreate" [addSystemCode]="editData" (onClose)="closeAdd()" (onAdded)="refreshList()"></app-system-code-add>

<app-std-system-code-import [typeCode]="importTargetSystemCode" (importDone)="handleSystemImportDone()" (onClose)="handleSystemImportDone()"></app-std-system-code-import>
