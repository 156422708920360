import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConsoleIconComponent } from './console-icon/console-icon.component';
import { HeaderComponent } from './header/header.component';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { LoaderComponent } from './loader/loader.component';
import { MenuComponent } from './menu/menu.component';
import { HcrConsoleModule } from '../card-reader/hcr-console/hcr-console.module';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { SecurityModule } from '../security/security.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReportHelperModule } from '../system/report/report-helper.module';
import { PatientsComponentsModule } from '../patients/patients.module';
import { DebugToolComponent } from './debug-tool/debug-tool.component';
import { ChatComponent } from './chat/chat.component';
@NgModule({
  declarations: [ConsoleIconComponent,HeaderComponent,MainLayoutComponent,LoaderComponent,MenuComponent, DebugToolComponent, ChatComponent],
  imports: [
    RouterModule,
    SecurityModule,
    ReactiveFormsModule,
    MatMenuModule,
    SharedModule,
    HcrConsoleModule,
    ReportHelperModule,
    PatientsComponentsModule,
    CommonModule,
  ]
})
export class LayoutModule { }
