import { Pipe, PipeTransform } from '@angular/core';
import { DateHelper } from '../helpers/date-helper';

@Pipe({
  name: 'formatMonth'
})
export class FormatMonthPipe implements PipeTransform {

  transform(value: any, args?: any): string {
    return DateHelper.formatMonth(value);
  }

}
