
<div [formGroup]="referralFormGroup" class="c-form-inline card" style="margin-left: 0rem;">
    <div class="card-header">
        <mat-grid-list [rowHeight]="55" cols="4" [gutterSize]="'5px'">
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@DoctorName">醫師名</span>
                    <input class="vis-input" formControlName="DoctorName" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@IdNo">醫師身分證</span>
                    <input class="vis-input" formControlName="DoctorCid" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@Iden">醫師連絡電話</span>
                    <input class="vis-input" formControlName="DoctorPhone" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@ClinicEmail">院所電子信箱</span>
                    <input class="vis-input" formControlName="ClinicEmail" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@PatientNo">病歷號</span>
                    <input class="vis-input" formControlName="PatientNo" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@PatientName">姓名</span>
                    <input class="vis-input" formControlName="PatientName" class="required" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@PatientSex">性別</span>
                    <mat-radio-group formControlName="PatientSex" class="extend-label">
                        <mat-radio-button [value]="1" i18n="@@Male">男</mat-radio-button>
                        <mat-radio-button [value]="0" i18n="@@Female">女</mat-radio-button>
                    </mat-radio-group>
                    <app-validation-tip controlName="PatientSex"></app-validation-tip>
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@ReferralDate">轉診日</span>
                    <app-vis-datepicker [maxWidth]="false" formControlName="ReferralDate" (valueChange)="onReferralDateChange($event)" class="required"></app-vis-datepicker>
                    <!-- <app-vis-datepicker [value]="histRcd.Register.RegDate"></app-vis-datepicker> -->
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@PatientIdNo">身分證</span>
                    <input class="vis-input" formControlName="PatientId" class="required" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@PatientBirthday">生日</span>
                    <app-vis-datepicker formControlName="Birthday" [maxWidth]="false"></app-vis-datepicker>
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@PatientTelNo">電話</span>
                    <input type="tel" maxlength="10" class="vis-input" formControlName="PhoneNo" class="required" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span class="required" i18n="@@ExpiryDate">有效日</span>
                    <app-vis-datepicker formControlName="ExpiryDate" class="required" [maxWidth]="false"></app-vis-datepicker>
                </label>
            </mat-grid-tile>
            <mat-grid-tile>
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@ContactName">聯絡人</span>
                    <input class="vis-input"  formControlName="ContactName" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="3">
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@Address">地址</span>
                    <input class="vis-input" formControlName="Address" />
                </label>
            </mat-grid-tile>
            <mat-grid-tile colspan="4">
                <label class="vis-form-field less-full-width-base">
                    <span i18n="@@AllergyHistory">過敏</span>
                    <input class="vis-input" formControlName="AllergyHistory" />
                </label>
            </mat-grid-tile>
        </mat-grid-list>
    </div>
</div>  
