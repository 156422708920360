<app-vis-edit-window title=""
  class="opd-helper-pad"
  *ngIf="isOpened"
  [minWidth]="460"
  [width]="winWidth"
  [minHeight]="200"
  [ngClass]="_class"
  [height]="winHeight">
  <form [formGroup]="queryFG" *ngIf="queryFG" class="help-show-scollbar">
    <div class="tab-row" style="display: flex;width: 100%;">
      <div *ngFor="let t of tabs"
        class="tab-btn pt-1"
        [class.selected]="isTab(t.id)"
        (click)="changeTabFromUI(t)"
        i18n="@@HistCC" >
        {{t.name}}
      </div>
      <div style="flex-grow: 1;"></div>
      <div class="end-0 position-absolute cursor-pointer" style="right: 2px;">
        <!-- ↓↓↓ 隱藏功能 -->
        <mat-icon style="transform: scale(.8); display: none;" [appOpenDialog]="tabSetting">edit</mat-icon>
        <mat-icon style="transform: scale(.8);" (click)="prefSetting()">settings</mat-icon>
      </div>

    </div>
    <!--次選項-->
    <div _ngcontent-cloud-clinics-c360="" class="toolbar p-0 d-flex w-100">
      <mat-button-toggle-group name="MessageFilter" class="w-100 message-filter">
        <mat-button-toggle class="col-6" [checked]="recentSource==1"  (click)="typeChange(1)"
          i18n="@@All">院內</mat-button-toggle>
        <mat-button-toggle class="col-6" [checked]="recentSource==0" (click)="typeChange(0)"
          i18n="@@Error">自己</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="toolbar" style="padding: 0px;display: flex;width: 100%;">
      <div class="toolbar p-0 d-flex" style="flex-grow: 1;">
        <mat-radio-group class="vis-form-field w-auto px-1 pt-0 pb-0 is-insert" formControlName="isInsert">
          <mat-radio-button [value]="false">取代 </mat-radio-button>
          <mat-radio-button [value]="true">插入 </mat-radio-button>
        </mat-radio-group>
        <div class="search-icon position-relative">
          <app-icon-search class="position-absolute m-auto ml-1 top-0 bottom-0 cursor-pointer" style="height: 20px;"></app-icon-search>
          <mat-icon  (click)="clearQuery()" i18n="@@Query" role="img" title="清除" class="position-absolute m-auto bottom-0 cursor-pointer mat-icon float-left d-inline-flex" aria-hidden="true" data-mat-icon-type="font"
          style="font-size: 1.2rem;right: 0px;top: 6px;;">clear</mat-icon>
            <input class="vis-input"
              formControlName="codeName"
              class="tab-input px-3 w-100"
              (keypress)="onQueryKeypress($event)"
              style="height: 21px;"  />
        </div>
      </div>
    </div>
  </form>
  <div style=" height:calc(100% - 53px);" [ngStyle]="{display:'block'}">
    <!-- 左側偏好區-->
    <div *ngIf="tab" class="d-inline-block float-left position-relative" [ngStyle]="{height:padHeight}"
    style="background: rgb(229, 229, 229);border-right: 1px solid rgb(119, 119, 119); ; width: 74px;">

        <div class="favorite-btn px-1 w-100 float-left text-center"
        [class.selected]="!this.selectedItem&& !editHelpPadGroup" (click)="recentClick()" i18n="@@HistDiag">
          近期常用
        </div>
        <!--偏好-->
        <div class="w-100 cdk-drop-list" style="display: flex;flex-direction: column;" cdkDropList (cdkDropListDropped)="dropGroup($event)">
          <div class="favorite-btn accordion float-left text-center"
            *ngFor="let group of tab.prefTab" cdkDrag
            [class.selected]="this.selectedItem==group && (editHelpPadGroup?.Id!=0)"
            (click)="groupClick(group )"
            i18n="@@HistDiag">
            {{group.name||'未命名'}}
          </div>
        </div>
        <!--新增-->
        <ng-container >
          <!--[ngClass]="{'favorite-btn-focus':focusIndex===i}"-->
          <div i18n="@@HistDiag" (click)="addGroupClick()"
          class="favorite-btn accordion w-100 float-left text-center add">新增偏好</div>
        </ng-container>
        <!--預設-->
        <div class="favorite-btn accordion w-100 float-left text-center"
          *ngFor="let subTab of tab.subTab"
          [class.selected]="this.selectedItem==subTab && (editHelpPadGroup?.Id!=0)"
          (click)="changeRxType(subTab)"
          [ngClass]="[subTab.name === ''?'d-none':'']"
          i18n="@@HistDiag">
          {{subTab.name}}
        </div>
    </div>
    <div style="display: flex;flex-direction: column;height: 100%;position: relative;" class="help-show-scollbar">
      <div class="helper-edit-title w-100 d-flex" style="flex-grow:1; height: 25px;">
        <div class="title-name d-flex pl-2">
          <ng-container *ngIf="editHelpPadGroup" >
            <span style="width: 80px;">偏好名稱:</span>
            <input style="width: 100%;" [maxLength]="10" [(ngModel)]="editHelpPadGroup.GroupName" #groupNameEdit/>
          </ng-container>
          <ng-container *ngIf="!editHelpPadGroup" >
            <ng-container *ngIf="!this.selectedItem;else gName">
              近期常用
            </ng-container>
            <ng-template #gName>
              {{selectedItem.name }}
            </ng-template>
          </ng-container>
        </div>
        <div class="d-flex">
          <!--Add-->
          <ng-container *ngIf="isEditGroup&&editHelpPadGroup.Id==0" >
            <mat-icon class="pref-icon btn-scale-90" (click)="addGroup()">save</mat-icon>
            <mat-icon class="pref-icon btn-scale-90" (click)="cancelEdit()">cancel</mat-icon>
          </ng-container>
          <!--Update-->
          <ng-container *ngIf="isEditGroup&&editHelpPadGroup.Id>0" >
            <mat-icon class="pref-icon btn-scale-90" (click)="updateGroup()">save</mat-icon>
            <mat-icon class="pref-icon btn-scale-90" (click)="deleteGroup()">delete</mat-icon>
            <mat-icon class="pref-icon btn-scale-90" (click)="cancelEdit()">cancel</mat-icon>
          </ng-container>
          <ng-container *ngIf="!isEditGroup&&selectedItem?.value>0&&!this.rxType" >
            <mat-icon *ngIf="!isEditGroup" class="pref-icon btn-scale-90" (click)="editGroupClick()">edit</mat-icon>
          </ng-container>

        </div>
      </div>
      <ng-container>
        <div class="pad-grid lock-height cdk-drop-list"
              [hidden]="isTabAcuPic"
              style="flex-grow: 1;position: relative;z-index: 10;" [ngStyle]="{height:padEditHeight}"
              cdkDropListGroup >
          <ng-container *ngFor="let item of prefItem;let i = index">
            <div class="wid50item preferItem"  style="position: relative;overflow: hidden;height: 25px;"
              cdkDropList [cdkDropListSortingDisabled]="true" (cdkDropListDropped)="prefItemDropped($event,item)"
              [id]="'pad-prefitem-'+item.code" [cdkDropListConnectedTo]="'dd-list-pad'" >
              <div class="dragItem" cdkDrag [cdkDragData]="item" [cdkDragDisabled]="!isEditGroup"
                (cdkDragStarted)="dragStart($event)" (cdkDragEnded)="dragEnd($event)" >
                <ng-container *ngTemplateOutlet="gridItem;context:{$implicit:item}"></ng-container>
              </div>
              <!--上面被拖拉離開的時候顯示下面這個-->
              <div class="bgItem">
                <div class="grid-item w-100" style="display: flex;background-color: #aafd !important;" >
                  <div class="preventHover enable-hover" style="max-height: 21px;">
                    <mat-icon class="pref-icon remove">
                      remove_circle
                    </mat-icon>
                  </div>
                  <div style="display:flex;flex-grow: 1;overflow: hidden; " class="grid-item-content">
                    <div class="ellipsis-content"  [class.inserted]="isInsert(item)" style="flex-grow: 1;padding-left: 5px;" [title]="item.name">
                      {{item.displayCode ? (item.code + "-" + item.name) : item.name}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </ng-container>

    </div>
    <!--快速點選選單-->
    <div *ngIf="openRxMenu" id="hist-quick-rx" class="quick-rx"
          [style.left]="menuTopLeftPosition.x"
          [style.top]="menuTopLeftPosition.y" >
      <div style="position: absolute;top: 5px;right: 5px;">
        <button class="close-btn" tabindex="-1" type="button"
                mat-button  (click)="closeMenu()">
                <mat-icon>close</mat-icon>
        </button>
      </div>
      <div>[{{editItem.RxCode}}] {{editItem.ProdName}}</div>
      <div>數量：</div>
      <div class="opt-container">
        DefaultDose :{{this.editItem.DefaultDose}}
        DefaultDailyDose :{{this.editItem.DefaultDailyDose}}
        <div class="tab-btn"  *ngFor="let item of commonQty"
              [class.selected]="this.editItem.DefaultDose==item"
              (click)="this.editItem.DefaultDose=item">{{item}}</div>
      </div>
      <div>頻率：</div>
      <div class="opt-container">
        <div class="tab-btn" [class.selected]="this.editItem.DefaultFrequency==item" (click)="this.editItem.DefaultFrequency=item" *ngFor="let item of commonFreq">{{item}}</div>
      </div>
      <div>日份:
      </div>
      <div class="opt-container">
        <div class="tab-btn" [class.selected]="this.editItem.DefaultDays==item" (click)="this.editItem.DefaultDays=item" *ngFor="let item of commonDay">{{item}}</div>
      </div>
      <div style="width: 100%;text-align: center;">
        <button type="button" (click)="completeEdit()">輸入</button>
      </div>
    </div>
    <!-- 針灸圖 -->
    <!-- <ng-container *ngIf="isTabAcuPic">
      <app-hist-acu-chart (acuPointClicked)="acuPointClicked($event)"></app-hist-acu-chart>
    </ng-container> -->
  </div>
</app-vis-edit-window>
<!--清單項目-->
<ng-template let-item #gridItem>
  <div (contextmenu)="openMenu($event,item)" (click)="clickGridItem(item)" class="grid-item w-100" style="display: flex" >
    <div *ngIf="isEditGroup" class="preventHover enable-hover" style="max-height: 21px;" (click)="groupItemRemove($event, item.code)">
      <mat-icon class="pref-icon remove">
        remove_circle
      </mat-icon>
    </div>
    <div style="display:flex;flex-grow: 1;overflow: hidden; " class="grid-item-content">
      <div class="ellipsis-content"  [class.inserted]="isInsert(item)" style="flex-grow: 1;padding-left: 5px;" [title]="item.name">
        <span *ngIf="item.flagDescription?.includes('STOP;')" style="color: red;">停|</span>
        <span *ngIf="item.flagDescription?.includes('ALTERNATIVE;')" style="color: blue;">替|</span>
        <span *ngIf="item.flagDescription?.includes('a1;')" style="color: var(--light-purple-color);">瓶|</span>
        {{item.displayCode ? (item.code + "-" + item.name) : item.name}}
      </div>
    </div>
  </div>
</ng-template>


<app-hist-helper-pad [isOpened]="isHelperNewPadOpened" *ngIf="isHelperNewPadOpened"
            [editData]="this.editHelpPadGroup"
            (itemClick)="groupItemAdd($event)" (emitClose)="closeHelperNewPadWin()">
</app-hist-helper-pad>

<app-vis-dialog [width]="800" [height]="600" (close)="tabSetting.show=false" #tabSetting>
  <div>
    <mat-button-toggle-group>
      <mat-button-toggle (click)="onCTabEdit(cTab)" *ngFor="let cTab of cTabs">{{cTab.name||'尚未命名'}}</mat-button-toggle>
      <mat-button-toggle (click)="onCTabAdd()" >+新增</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <app-search-bar *ngIf="editCTab" [Cols]="4">
    <input [searchBarField]="'name'" [fieldTitle]="'頁籤文字'" [(ngModel)]="editCTab.name">
    <input [searchBarField]="'id'" [fieldTitle]="'代碼'" [(ngModel)]="editCTab.id">
    <app-vis-dropdownlist [searchBarField]="'dataSource'" [fieldTitle]="'資料來源'" [(ngModel)]="editCTab.dataSource"
    [valuePrimitive]="true" [data]="dataSourceDD"></app-vis-dropdownlist>
    <input [searchBarField]="'sort'" [fieldTitle]="'排序'" [(ngModel)]="editCTab.sort">
  </app-search-bar>
  <app-vis-grid *ngIf="editCTab" [data]="editCTab?.subTabs">
    <app-vis-grid-column [width]="80" [field]="'name'" [title]="'顯示名稱'">
      <ng-template let-data>
        <input style="width: 100%;" [(ngModel)]="data.name" />
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column [width]="80" [field]="'value'" [title]="'歸屬'">
      <ng-template let-data>
        <app-vis-dropdownlist style="width: 100%;"  [data]="orderTypeDD" [(ngModel)]="data.value" [valuePrimitive]="true"></app-vis-dropdownlist>
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column  [width]="80" [field]="'filterCol'" [title]="'篩選欄'">
      <ng-template let-data>
        <app-vis-dropdownlist style="width: 100%;"
          [data]="getPropDD()"
          (valueChange)="colChange(data)"
          [(ngModel)]="data.filterCol" [valuePrimitive]="true"></app-vis-dropdownlist>
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column [width]="80" [field]="'filterOp'"  [title]="'篩選方式'">
      <ng-template let-data>
        <app-vis-dropdownlist style="width: 100%;"
          [data]="operatorDD"
          [(ngModel)]="data.filterOp" [valuePrimitive]="true"></app-vis-dropdownlist>
      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column [width]="80" [field]="'filterValue'"  [title]="'篩選值'">
      <ng-template let-data>
        <ng-container *ngIf="data.filterCol=='PaymentType';else els">
          <app-vis-dropdownlist style="width: 100%;"
          [data]="paymentTypeCodes"
          [(ngModel)]="data.filterValue" [valuePrimitive]="true"></app-vis-dropdownlist>
        </ng-container>
        <ng-template #els>
          <input style="width: 100%;" [(ngModel)]="data.filterValue" />
        </ng-template>

      </ng-template>
    </app-vis-grid-column>
    <app-vis-grid-column [width]="80" [field]="'sort'"  [title]="'排序'">
      <ng-template let-data>
        <input style="width: 100%;" [(ngModel)]="data.sort" />
      </ng-template>
    </app-vis-grid-column>
    <div  style="text-align: center;" no-data><button (click)="appendCSubTab()">新增</button></div>
  </app-vis-grid>
  <app-vis-dialog-actions>
    <button (click)="updateCTabs(tabSetting)">更新</button>
  </app-vis-dialog-actions>
</app-vis-dialog>
