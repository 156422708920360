import { Injectable, OnDestroy } from '@angular/core';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';

import { Router } from '@angular/router';
import { RouteInfoService } from 'src/app/services/route-info.service';
import { MultiPrescriptWrite } from 'src/app/shared/hcr-lib/MultiPrescriptWrite';
import { TreatmentData } from 'src/app/shared/hcr-lib/TreatmentData';
import { NhiUploadParameter, WholeNHIData } from './wholeNHIData';
import { ValueTextPairAnyValue } from 'src/app/shared/models/value-text-pair';

export class HistToICCardData {
  MultiPrescriptWrite:MultiPrescriptWrite;
  TreatmentData:TreatmentData;
}

@Injectable({
  providedIn: 'root'
})
export class NHIApi  {
  
  constructor(private webApiFactory: WebApiClient,
    private router: Router, private routeInfo: RouteInfoService) {
    this.api = this.webApiFactory.createHisService('registers/NHIRegIC');
    this.api.enableLoader = false;
  }
  api: WebApiService;
  async GetNHIData(regId: number):Promise<WholeNHIData>{
    return await this.api.get('GetNHIData?registerId='+regId).toPromise<WholeNHIData>();
  }
  async HasReferral(regId: number):Promise<boolean> {
    return await this.api.get('HasReferralAsync?registerId='+regId).toPromise<boolean>();
  }
  async GetNHIDataNoUpload(sDate:Date,eDate:Date):Promise<WholeNHIData[]>{
    return await this.api.get('GetNHIDataNoUpload?sDate='+(sDate?.toLocaleDateString()||'')+'&eDate='+(eDate?.toLocaleDateString()||'')).toPromise<WholeNHIData[]>();
  }
  async GetNHIDataLastUploadDateTime():Promise<Date>{
    return await this.api.get('GetNHIDataLastUploadDateTime').toPromise<Date>();
  }
  async UpdateNHIUploadDate(nhiRegIds:number[]):Promise<boolean>{
    return await this.api.post('UpdateNHIUploadDate',{ ids:nhiRegIds}).toPromise<boolean>();
  }
  async SetNHIDisable(regId:number,disable:boolean):Promise<boolean>{
    return await this.api.get('SetNHIDisable?id='+regId+'&diabled='+disable).toPromise<boolean>();
  }
  async GetNHIDataUploadDatesAsync():Promise<ValueTextPairAnyValue[]>{    
    return await this.api.get('GetNHIDataUploadDatesAsync').toPromise<ValueTextPairAnyValue[]>();
  }
  async GetNHIDataUploadAsync(date:Date):Promise<WholeNHIData[]>{    
    return await this.api.get('GetNHIDataUploadAsync?date='+new Date(date).toLocaleDateString()).toPromise<WholeNHIData[]>();
  }
  
  async GetNHIUploadParameterAsync(): Promise<NhiUploadParameter> {
      return await this.api.get('GetNHIUploadParameterAsync').toPromise<NhiUploadParameter>();
  }
}
