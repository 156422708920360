import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalPrintService } from 'src/app/services/local-print.service';
import { FormGroup } from '@angular/forms';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HcrService } from 'src/app/services/hcr-service.service';
import { PatientApi,PatientListDto } from 'src/app/services/api-service/patient/patient-api';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { ActivatedRoute } from '@angular/router';
import { HRxApiService } from 'src/app/services/api-service/opd/hrx-api.service';

@Component({
  selector: 'app-prevent-care-report',
  templateUrl: './prevent-care-report.component.html',
  styleUrls: ['./prevent-care-report.component.css']
})
export class PreventCareReportComponent implements OnInit, AfterViewInit {

  reportName = '預保本年度未檢追蹤';
  forExcel = true;

  sizePage = {
    width: 28.7, //cm
    height: 20 //cm
  };
  paddingPage = {
    top: 0, //cm
    right: 0, //cm
    bottom: 0.5, //cm
    left: 0 //cm
  };

  heightPageWithoutPadding = this.convertCmtoPx(
    this.sizePage.height - (this.paddingPage.top + this.paddingPage.bottom)
  );
  elContainer: HTMLElement;
  pageContent = [[]];     // Ex: [[0, 1, 2, 3], [4, 5]]


  @ViewChild('reportContainer', { static: false } )
  reportContainer: ElementRef<HTMLDivElement>;
  upperboundIndex: number = 0;
  monthStr: string = '';
  nowStr: string = '';
  timeStr: string = '';
  pageNo: number = 1;
  _sDate: Date;
  _eDate: Date;
  SeleAge: String;
  ReportName: String;
  // 清單
  listItems: PatientListDto[];
  listdata1: PatientListDto[] = [];
  listdata2: PatientListDto[] = [];

  queryFormGroup: FormGroup;
  data: PatientListDto[];
  totalRecord: number;

  constructor(private route: ActivatedRoute,
    private webApiClient: WebApiClient,
    private patientApi: PatientApi,
    private histApi: HistApi,
    private hRxApiService: HRxApiService,
    private localPrintService: LocalPrintService,
    private hcrService: HcrService,
    private notification: EasyNotificationService) {
    //this.api = this.webApiClient.createHisService('Opd/OpdReport');
  }

  ngOnInit(): void {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.pres-report2 table{ border:0px solid black; border-collapse: collapse; }\n' +
      '.pres-report2 table td, table th { border-bottom: 1px solid black; padding-left: 5px;padding-right: 5px;}\n' +
      '.pres-report2 table tr:first-child th { border-top: 0; vertical-align: bottom; padding: 0px }\n' +
      '.pres-report2 table tr:last-child td { border-bottom: 0; }\n' +
      '.pres-report2 table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.pres-report2 table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4 landscape; margin: 0.5cm 0.5cm; }\n'
      // '#pageHeader:after { counter-increment: page; content: counter(page); }\n'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  ngAfterViewInit() {

  }

  async fetchData(data: PatientListDto[], SeleAge: String, forExcel = true) {
    this.SeleAge = SeleAge;
    this.forExcel = forExcel;
    this.elContainer = document.getElementById("container");

    //以下是報表表頭欄位, 目前沒用到, 先給預設值。
    // this.nowStr = this.onGetDateString(new Date(), true);
    this.nowStr = DateHelper.getROCDateStringWithSepetator(DateHelper.today);
    // this.timeStr = this.onGetTimeString(new Date());
    this.timeStr = DateHelper.getTimeString(new Date(), ':', true);
    // this._sDate = query.startDate;
    this._sDate = DateHelper.today;
    // this._eDate = query.endDate;
    this._eDate = DateHelper.today;

    this.listItems = data;
    if(this.SeleAge == "1"){
      this.ReportName = "預保40~65歲本年度未檢追蹤報表";
      this.listdata1 = [];
      for(let i = 0; i < this.listItems.length; i++){
        if(this.listItems[i].Age>=40 && this.listItems[i].Age<65){
          this.listdata1.push(this.listItems[i]); //listdata1：40~65歲的資料
        }
      }
      this.totalRecord = this.listdata1.length;
      this.insertListData();
    }else if(this.SeleAge == "2"){
      this.ReportName = "預保65歲以上本年度未檢追蹤報表";
      this.listdata2 = [];
      for(let i = 0; i < this.listItems.length; i++){
        if(this.listItems[i].Age>=65){
          this.listdata2.push(this.listItems[i]); //listdata1：40~65歲的資料
        }
      }
      this.totalRecord = this.listdata2.length;
      this.insertListData2();
    }


    // this.listItems = data;
    // this.totalRecord = this.listItems.length;
    // this.insertListData();
  }

  // #region 組報表
  // 組報表的html內容
  insertListData() {
      var html_Block = "";
      var iPage = 0;
      var iBlock = 0;
      this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
      var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
      var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
      var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
      var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
      var prevTableContent: any;
      var afterSummary = false;

      // 組報表內容
      // [頁表頭][List欄位顯示名稱]
      elPageContent.innerHTML = html_header;
      var elTableContent = document.getElementById("table-" + iPage + "-content");
      elTableContent.innerHTML = html_ListerHeader;
      for (let i = 0; i < this.listdata1.length; i++) {

          // 加入 List 每一筆的內容
          prevTableContent = elTableContent.innerHTML;
          if (afterSummary) {
              elTableContent.innerHTML += html_ListerHeader;
              afterSummary = false;
          }
          html_Block = this.createHTMLBlock(this.listdata1[i]);
          elTableContent.innerHTML += html_Block;

          // 判斷是否超出頁面範圍? -> 新增一頁
          if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
              elTableContent.innerHTML = prevTableContent;
              iPage += 1;
              this.elContainer.innerHTML += this.createHTMLPage(iPage);
              elPageContent = document.getElementById("page-" + iPage + "-content");
              // [頁表頭][List欄位顯示名稱]
              html_header = this.createHTMLPageHeader(iPage);
              elPageContent.innerHTML += html_header;
              elTableContent = document.getElementById("table-" + iPage + "-content");
              elTableContent.innerHTML = html_ListerHeader;
              elTableContent.innerHTML += html_Block;

              this.pageContent[iPage] = [];
              this.pageContent[iPage].push(iBlock);
          } else {
              this.pageContent[iPage].push(iBlock);
          }
      }
      elPageContent.innerHTML += html_finish;
      this.upperboundIndex = iPage;
      for (var i = 0; i <= iPage; i++) {
          var totalPage = document.getElementById("totalPage-" + i);
          totalPage.innerHTML = (this.upperboundIndex + 1).toString();
      }
}

insertListData2() {
  var html_Block = "";
  var iPage = 0;
  var iBlock = 0;
  this.elContainer.innerHTML = this.createHTMLPage(iPage);  // 整份報表的html內容
  var html_header = this.createHTMLPageHeader(iPage);       // 頁表頭
  var html_ListerHeader = this.createHTMLListHeader();      // List欄位顯示名稱
  var html_finish = this.createHTMLFinish();                // 報表結束 -- 以下空白
  var elPageContent = document.getElementById("page-" + iPage + "-content");  // 取得某頁的content，每次查詢後會取第0頁
  var prevTableContent: any;
  var afterSummary = false;

  // 組報表內容
  // [頁表頭][List欄位顯示名稱]
  elPageContent.innerHTML = html_header;
  var elTableContent = document.getElementById("table-" + iPage + "-content");
  elTableContent.innerHTML = html_ListerHeader;
  for (let i = 0; i < this.listdata2.length; i++) {

      // 加入 List 每一筆的內容
      prevTableContent = elTableContent.innerHTML;
      if (afterSummary) {
          elTableContent.innerHTML += html_ListerHeader;
          afterSummary = false;
      }
      html_Block = this.createHTMLBlock(this.listdata2[i]);
      elTableContent.innerHTML += html_Block;

      // 判斷是否超出頁面範圍? -> 新增一頁
      if (elPageContent.offsetHeight > this.heightPageWithoutPadding) {
          elTableContent.innerHTML = prevTableContent;
          iPage += 1;
          this.elContainer.innerHTML += this.createHTMLPage(iPage);
          elPageContent = document.getElementById("page-" + iPage + "-content");
          // [頁表頭][List欄位顯示名稱]
          html_header = this.createHTMLPageHeader(iPage);
          elPageContent.innerHTML += html_header;
          elTableContent = document.getElementById("table-" + iPage + "-content");
          elTableContent.innerHTML = html_ListerHeader;
          elTableContent.innerHTML += html_Block;

          this.pageContent[iPage] = [];
          this.pageContent[iPage].push(iBlock);
      } else {
          this.pageContent[iPage].push(iBlock);
      }
  }
  elPageContent.innerHTML += html_finish;
  this.upperboundIndex = iPage;
  for (var i = 0; i <= iPage; i++) {
      var totalPage = document.getElementById("totalPage-" + i);
      totalPage.innerHTML = (this.upperboundIndex + 1).toString();
  }
}


  convertPxToCm(px) {
    return Math.round(((px * 2.54) / 96) * 100) / 100;
  }

  convertCmtoPx(cm) {
    return Math.round((cm * 96) / 2.54);
  }

  createHTMLPage(iPage) {
    return `<div class="page" id="page-` + iPage + `"
                style="
                margin: 0px auto;
                height: ` + this.sizePage.height + `cm;
                width: ` + this.sizePage.width + `cm;
                padding-top: ` + this.paddingPage.top + `cm;
                padding-right: ` + this.paddingPage.right + `cm;
                padding-bottom: ` + this.paddingPage.bottom + `cm;
                padding-left: ` + this.paddingPage.left + `cm;
                display: block;
                margin: 0px auto;
                box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);
                box-sizing: border-box;
                ">
                <div class="content" id="page-` + iPage + `-content">
                </div>
            </div>`;
  }

  createHTMLBlankLine() {
    return `<tr>
              <th colspan="4" style="height: 12px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
              </th>
            </tr>`;
  }

  createHTMLListHeader() {
    return `<tr>
              <th
                style="text-align: center; width:10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                病歷號
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                姓名
              </th>
              <th
                style="text-align: center; width: 7%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                生日
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                電話
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                身分證
              </th>
              <th
                style="text-align: center; width: 10%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                手機
              </th>
              <th
                style="text-align: center; width: 8%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                最後來診日
              </th>
              <th
                style="text-align: center; width: 30%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                地址
              </th>
              <th
                style="text-align: center; width: 5%; border-right: 0px solid black;  border-left: 0px solid black; border-bottom: 2px solid black;">
                郵遞區號
              </th>
            </tr>`;
  }
  createHTMLBlock(data: PatientListDto) {
    var commonStyle = 'border-top: none; border-bottom: none;';
    var commonClassText = this.forExcel ? 'class="excel-format-text"' : '';
    var birthday = data.Birthday ? this.onGetDateString(data.Birthday, true, '', false) : "";
    var regdate = data.RegDate ? this.onGetDateString(data.RegDate, true, '', false) : "";
    // var age = this.calculateAge(data.Birthday);
    // const today = new Date();
    // const ageInMilliseconds = today.getTime() - data.Birthday.getTime();
    // const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

    if(regdate.includes('-'))
      regdate = "";

    var gender ;
    if(data.Sex == 1)
      gender = '男'
    if(data.Sex == 0)
      gender = '女'
    if(data.Sex == 999)
      gender = '未知'

    return `
      <tr>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PatientNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Name}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${birthday}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.PhoneNo== undefined ? '' : data.PhoneNo}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.CId}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Mobile== undefined ? '' : data.Mobile}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${regdate}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.Address == undefined ? '' : data.Address}</td>
        <td style="text-align: left;  ${commonStyle}" ${commonClassText}>${data.ZipCode== undefined ? '' : data.ZipCode}</td>
      </tr>`;
  }

  createHTMLPageHeader(iPage) {
    // var reportName = '預保本年度未檢追蹤';
    return `<div id="header" style="margin: 0px 20px;">
              <div style="display: flex;column-gap: 5px;">
                <table style="width: 100%; text-align: center; border: none;">
                  <tr>
                    <td colspan="4" style="height: 30px; text-align: center; font-weight: bolder; font-size: 16pt; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                    ` + this.ReportName + `
                    </td>
                  </tr>
                  <tr>
                      <td style="width: 33%; font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表日期 ` + this.nowStr + `
                      </td>
                      <td colspan="2" style="width: 34%; font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="width: 33%; font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      總筆數 ` + this.totalRecord + `
                      </td>
                  </tr>
                  <tr>
                      <td style="font-size: 14px; text-align: left; padding-top: 5px; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      印表時間 ` + this.timeStr + `
                      </td>
                      <td colspan="2" style="font-size: 14px; text-align: center; border-right: none; border-left: none; border-bottom: none; border-top: none;">

                      </td>
                      <td style="font-size: 14px; text-align: right; border-right: none; border-left: none; border-bottom: none; border-top: none;">
                      頁 ` + (iPage + 1) + `/<span id="totalPage-` + iPage +`"></span>
                      </td>

                  </tr>
                </table>
              </div>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center;">
                <label style="font-weight: bold; height: 23px;">　</label>
            </div>
            <div style="-webkit-text-size-adjust: none; font-size: 12px;zoom: 0.8;margin: 0px 10px;">
                <div style="display: flex;column-gap: 5px;">
                    <table id="table-` + iPage + `-content" style="width: 100%; font-size: 12pt; text-align: center; border: none;">
                    </table>
                </div>
            </div>`;
  }

  createHTMLFinish() {
    return `<div style="-webkit-text-size-adjust: none; font-size: 12pt;zoom: 0.8;margin: 0px 10px; text-align: center; border-top: 5px double black;">
                <label style="font-weight: bold;">以下空白</label>
            </div>`;
  }

  // #endregion
  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    for (var i = 0; i <= this.upperboundIndex; i++) {
      html = html.replace('box-shadow: 0 0 0.15cm rgba(0, 0, 0, 0.15);', '');
    }
    return html;
  }

  async onPrint() {
    if (this.listItems.length > 0) {
      const reportName = '預保本年度未檢追蹤';
      const printContents = this.getHtml();
      var ret = await this.localPrintService.printHtml(444, reportName, printContents);  // 'Consultation Volume Statistic'
      if (ret.Successed) {
        this.notification.showSuccess(reportName + '已送出列印!', true);
      } else {
        this.notification.showError(reportName + '送出列印失敗!');
      }
    } else {
      this.notification.showInfo('查無資料可供列印!');
    }
  }

  async exportAsExcel() {
    if (this.listItems.length > 0) {
      const reportName ='預保本年度未檢追蹤';
      const table = this.reportContainer.nativeElement;
      await this.hcrService.ExportTableToExcel(reportName, table.innerHTML)


    } else {
      this.notification.showInfo('查無資料可供匯出!');
    }
  }

  /** 轉換Date To String
  *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();
      var years = year.toString();
      if(years.length < 2)
        years = '00' + year; //民國6年7月5日出生 (0060705)
      if(years.length < 3)
        years = '0' + year;
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [years, month].join(symbol);
      } else {
        return [years, month, day].join(symbol);
      }
    }
  }

  /** 轉換Time To String
    *  [參數] ev:值，isRoc:是否轉換成民國年，symbol:分隔符號，isMonth:是否只顯示到月份
  */
  onGetTimeString(ev, timeSymbol = ':') {
    if (ev) {
      var d = new Date(ev);
      // 趕時間先簡單寫
      let hr = d.getHours().toString();
      if (d.getHours() < 10) {
        hr = '0' + hr;
      }
      let min = d.getMinutes().toString();
      if (d.getMinutes() < 10) {
        min = '0' + min;
      }
      let sec = d.getSeconds().toString();
      if (d.getSeconds() < 10) {
        sec = '0' + sec;
      }
      var timeStr = [hr, min, sec].join(timeSymbol);
      return timeStr;
    }
  }

  onGetDate(ev) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [month, day].join('');
    }
  }
}


