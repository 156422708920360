import { CalcRuleDoseAsTotal, CalcRuleNoAct, CalcRuleDose, CalcRuleDaily, CalcRuleTotal, CalcRuleClear } from "./hrx-calcrule";
import { ICalcRule, IHRx, IHrxCalc, QTYRule } from "./hrx-calcr.models";


export class CalcRuleFactory{
    private static rxCodeQtyAsTotal = ['P1015C','P1016C'];
    private static isSkipCalcDose(hrxCalc:IHrxCalc): boolean {
        var ds = hrxCalc.findDosage();
        // 補值得時候會出現Freq為空(ds==null) 但this.model.FreqQTY有值的情況
        if (!ds && !hrxCalc.model.FreqQTY) return true;
        var cond = [
          (odr: IHRx) => odr.RxType != 800,
          (odr: IHRx) => ds?.Code != 'ASORD', //按吩咐
          (odr: IHRx) => ds?.Code != 'STAT', //按吩咐
          (odr: IHRx) => ds?.Code != 'PRN',   //需要時
          //(odr:HistOrder)=>odr.Freq !='STAT',  //現在立刻馬上
        ]
        var updateTotalDose = true;
        cond.forEach(x => updateTotalDose = updateTotalDose && x(hrxCalc.model));
        return !updateTotalDose
      }
    public static getCalcRule(hrxCalc:IHrxCalc): ICalcRule {
        // 特殊1 需要總次同步的P醫令
        if(this.rxCodeQtyAsTotal.indexOf(hrxCalc.model.StdCode)>=0 ){
          return new CalcRuleDoseAsTotal(hrxCalc.model);
        }
        // 特殊2 不適用於計算的情況
        if ( this.isSkipCalcDose(hrxCalc)) {
          return new CalcRuleNoAct(hrxCalc.model);
        }
    
        // 口服藥用的規則
    
        // 各量法
        if (hrxCalc.model.QtyRule == QTYRule.Dose) {
          return new CalcRuleDose(hrxCalc.model, hrxCalc);
        } else if (hrxCalc.model.QtyRule == QTYRule.DailyDose) {
          return new CalcRuleDaily(hrxCalc.model,hrxCalc);
        } else if (hrxCalc.model.QtyRule == QTYRule.TotalDose) {
          return new CalcRuleTotal(hrxCalc.model,hrxCalc);
        }
      }
      public static getClearRule(model:IHRx):ICalcRule{
        return new CalcRuleClear(model)
      }
}