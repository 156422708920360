import { AfterContentChecked, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { VisGridColumnHeaderComponent } from '../vis-grid-column-header/vis-grid-column-header.component';
import { ValueTextPair, ValueTextPairNumberValue } from 'src/app/shared/models/value-text-pair';
import { BaseComponent } from '../../base/base.component';

export declare type SortOrder = 'desc'|'asc'|'none';
@Component({
  selector: 'app-vis-grid-column',
  templateUrl: './vis-grid-column.component.html',
  styleUrls: ['./vis-grid-column.component.css']
})
export class VisGridColumnComponent extends BaseComponent implements OnInit,AfterContentChecked {

  @ContentChild(TemplateRef)
  templateRef : TemplateRef<any>;
  @ContentChild(VisGridColumnHeaderComponent)
  templateRefHeader : VisGridColumnHeaderComponent;

  @Input()
  width: number| string;
  @Input()
  field: string;
  @Input()
  title: string;
  @Input()
  multiLine = false;
  @Input()
  hide = false;
  @Input()
  sortable: boolean = false;
  /** 定住左欄不捲動 */
  @Input()
  sticky?: boolean = false;

  @Input()
  pipe?:ValueTextPair[]|ValueTextPairNumberValue[] = null;
  
  @Input()
  dateTimeFormat:'Date'|'Time'|'DateTime'|'' = ''
  _field:string;
  _width:string;
  _minWidth:number;
  _class:string = '';
  /** 給grid設定用的欄位 */
  _classFromGrid:string = '';
  @Input()
  CellStyle:CSSStyleDeclaration;
  _cellStyle:CSSStyleDeclaration;
  _style:any={};
  @Input()
  sortOrder:SortOrder = 'none';

  _hasDefaultOrder = false;
  _defaultOrder:SortOrder = 'none';

  _isTemplate:boolean;
  _hasHeaderTemplate:boolean;

  constructor(protected elementRef: ElementRef) {
    super();
      this._minWidth = this.elementRef.nativeElement.getAttribute('min-width');
      //this._style = this.elementRef.nativeElement.style;
  }
  rowClick(row:number,data:any){

  }
  ngOnInit(): void {
    this._hasDefaultOrder = this.sortOrder!='none';
    this._defaultOrder = this.sortOrder;
  }
  ngAfterContentChecked(){
    this._cellStyle = Object.assign({},this.CellStyle);
    this._isTemplate = this.templateRef?true:false;
    this._hasHeaderTemplate = this.templateRefHeader?.templateRef?true:false;
    if(this._minWidth){
      this._style.minWidth = this._minWidth+'px';
    }else{
      this._style.minWidth = this.width+'px';
    }
    this._cellStyle.minWidth = this._style.minWidth;
    if(this.width){
      if(typeof(this.width) == 'number' || parseFloat(this.width)){
        this._style.width = this.width+'px';
      }else{
        this._style.width = this.width;
      }

      //this._style.setProperty('width','\'200px\'');
    }else{

    }
    this._class = this.elementRef.nativeElement.className;
    if(this.sticky && this._class.indexOf('position-sticky')==-1){
      this._class+= ' position-sticky';
    }
  }
  onSort(){
    if(this.sortable){
      this.sortOrder = this.nextSort();
      // switch(this.sortOrder){
      //   case 'none':
      //     this.sortOrder = 'desc';
      //     break;
      //   case 'desc':
      //     this.sortOrder = 'asc';
      //     break;
      //   case 'asc':
      //     this.sortOrder = 'none';
      //     break;
      // }
    }
  }
  setSortDefault(){
    this.sortOrder = this._defaultOrder;
  }
  nextSort(){
    var order:SortOrder[] = ['desc','asc','none','desc']
    var next = order[(order.findIndex(x=>x==this.sortOrder)+1)];
    if(this._hasDefaultOrder && next=='none'){
      return this._defaultOrder;
    }
    return next
  }
}
