/** (病歷檔)調劑方式 */
export enum DispensingTypeEnum {
    /** 診所調劑 */
    Clinic = '0',
    /** 非診所調劑 */
    OutOfClinic = '1',
    /** 未開(藥品)處方 */
    NoNeed = '2',
    /** 緊急自調 */
    EmerSelf = '6'
}


/** (醫令)調劑方式 */
export enum OrderDispensingTypeEnum {
    /** 自行調劑、檢驗(查)或物理治療 */
    T0_Clinic = '0',
    /** 交付調劑、檢驗(查)或物理治療 */
    T1_OutOfClinic = '1',
    /** 委託其他醫事機構轉檢 */
    T2_RefLabHosp = '2',
    /** 接受其他院所委託轉檢 */
    T3_RefLabClinic = '3',
    /** 委託其他醫事機構代檢 */
    T4_LabHosp = '4',
    /** 接受其他院所委託代檢 */
    T5_LabHospClinic = '5',
}
export const DispensingTypeDesc: {text: string, value: number}[] =
    [{text: '診所調劑', value: 0 },
     {text: '非診所調劑', value: 1 }];

