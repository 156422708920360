import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-vis-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class VisButtonComponent implements OnInit {

  @Input()
  title:string = null;
  @Input()
  icon:string;
  @Input()
  animateIcon:boolean=false;
  @Input()
  animateBtn:boolean=false;
  @Input()
  label:string;

  @Input()
  styleType:'primary'|'warning'|'none' = 'none';

  @Input()
  btnCls:string = '';
  @Input()
  iconCls:string = '';

  get _btnCls():string{
    return this.btnCls + (this.styleType=='primary'?' primary':'') + (this.animateBtn?' btn-animate' :'');
  }
  get _iconCls():string{
    return this.iconCls +(this.styleType=='warning'?' remove':'') + (this.animateIcon?' icon-animate' :'');
  }
  @Output()
  click:EventEmitter<Event> = new EventEmitter();
  constructor(private ele:ElementRef<HTMLElement>) {
    this.ele.nativeElement.style.display='inline-block'
   }

  ngOnInit(): void {
    if(this.title==null){
      this.title = this.label;
    }
  }
  onClick(evt:Event){
    evt.stopPropagation();
    this.click.emit(evt);
  }
}
