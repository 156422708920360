/**
 * 看診次數統計查詢條件
 */
export class VisitcountQueryDto {
  /**
   *開始日期
   *
   * @type {string}
   * @memberof VisitcountQueryDto
   */
  StartDate: string;
  /**
   *結束日期
   *
   * @type {string}
   * @memberof VisitcountQueryDto
   */
  EndDate: string;
  /**
   *身分
   *
   * @type {string}
   * @memberof VisitcountQueryDto
   */
   SearchMethod: string;
  /**
   *統計診察費用>0
   *
   * @type {boolean}
   * @memberof VisitcountQueryDto
   */
  HaveADx: boolean;
  /**
   *排除案類
   *
   * @type {string}
   * @memberof VisitcountQueryDto
   */
   SelectedCaseItems: string;
  /**
   *統計超過?次就診病患
   *
   * @type {number}
   * @memberof VisitcountQueryDto
   */
  OverAmount: number;
  /**
   *報表類型
   *
   * @type {string}
   * @memberof VisitcountQueryDto
   */
  ReportType: string;
}
/**
 * 看診次數統計結果
 */
export class VisitcountReportData {
  /**
   *病歷號
   *
   * @type {string}
   * @memberof VisitcountReportData
   */
  PatientNo: string;
  /**
   *姓名
   *
   * @type {string}
   * @memberof VisitcountReportData
   */
  PatientName: string;
  /**
   *合計次數
   *
   * @type {number}
   * @memberof VisitcountReportData
   */
  PatientCount: number;
}
