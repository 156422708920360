import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, EventEmitter, Output } from "@angular/core";
import { WholeHist } from "src/app/services/api-service/hist/whole-hist";
import { HspidApi } from "src/app/services/api-service/hspid/hspid-api";
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Hspid } from "src/app/services/api-service/hspid/hspid";
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api'; 
import { ValueTextPairAnyValue } from "src/app/shared/models/value-text-pair";
import { Referral } from "src/app/services/api-service/referral/referral";

@Component({
    selector: 'app-referrals-referral-form-dialog-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private fb: FormBuilder, 
    private notification: EasyNotificationService,
    private syscodeApi: SystemcodeApi,
    private hspidApi: HspidApi) { 
    }

  @Input() referralFormGroup: FormGroup;
  @Input() queryPatientNo = '';
  @Input() isLockPatientNo = false;
  @Input() histRcd: WholeHist;
  @Input() oriRef: Referral;
  
  hspid: Hspid[] = [];
  medDeptCodes: ValueTextPairAnyValue[] = [];
  arrangeFormGroup: FormGroup;
  isArrangeWinOpened: boolean = false;

  ngOnInit() {
    // console.log('oriRef >>>>>', this.oriRef);
    this.getHspid();

    this.arrangeFormGroup = this.makeArrangeFormGroup();
  }

  async getHspid() {
    var data = await this.hspidApi.GetAll();
    var oriHspid = Hspid.mapLevel(data);
    this.hspid = oriHspid.filter(x => x.Htype == 1);

    this.getDepartment();
  }

  async getDepartment() {  // TypeCode = C0020
    const typeList: string[] = ['C0020'];
    this.medDeptCodes = (await this.syscodeApi.GetCodes(typeList))['C0020'];

    this.fillForm();
  }

  fillForm() {
    if (this.oriRef) {
      this.referralFormGroup.controls.InDep.setValue(this.oriRef.SchMedDep);
      this.referralFormGroup.controls.InHspid.setValue(this.oriRef.InHspid);
      this.onHspidChange(this.oriRef.InHspid); 
      this.onDepChange(this.oriRef.SchMedDep);
      if (this.oriRef.SchMedDate.toLocaleString().substring(0, 4) != '0001') {
        this.referralFormGroup.controls.SchMedDate.setValue(this.oriRef.SchMedDate); 
      }
    } else {
      this.referralFormGroup.controls.SchMedDate.setValue(null);
    }
  }

  onHspidChange(src: any) {
    var selItem = this.hspid.find(x => x.HID == src);
    this.referralFormGroup.controls.InHspidName.setValue(selItem.HNAME);
    this.referralFormGroup.controls.InHspidAddress.setValue(selItem.HAREA);
    this.referralFormGroup.controls.InHsPhone.setValue(selItem.HTEL);
    var level = selItem.HLEVEL ? selItem.HLEVEL : '';
    this.referralFormGroup.controls.InHspLevel.setValue(level);
    this.referralFormGroup.controls.InHspid.setValue(src);
  }

  onDepChange(src: any) {
    var selItem = this.medDeptCodes.find(x => x.value == src);
    this.referralFormGroup.controls.InDepName.setValue(selItem.text);
    this.referralFormGroup.controls.SchMedDep.setValue(selItem.value);
    
    var exDep = this.medDeptCodes.find(x => x.value == this.histRcd.Register.MedDeptCode);
    this.referralFormGroup.controls.ExDepName.setValue(exDep.text);
  }

  async btnArrangeClick() {
    this.isArrangeWinOpened = true;
    this.arrangeFormGroup.controls.InHsAddrw.setValue(this.referralFormGroup.controls.InHspidAddress.value);
    this.arrangeFormGroup.controls.InHsPhone.setValue(this.referralFormGroup.controls.InHsPhone.value);
  }

  makeArrangeFormGroup() {
    const fg: FormGroup = this.fb.group({
      SchMedDiag: [''],                                                     // 安排就醫診別(h26)
      SchMedNum: [''],                                                      // 安排就醫號碼(h27)
      InHsAddrw: [''],         // 轉入院所地址(h31)
      InHsPhone: [''],         // 轉入院所電話(h32)
      DrTrans: [''],                                                        // 醫師交班注意事項(h33)
    });
    return fg;
  }

  ArrangeInSure() {
    this.isArrangeWinOpened = false;
    this.referralFormGroup.controls.SchMedDiag.setValue(this.arrangeFormGroup.controls.SchMedDiag.value);
    this.referralFormGroup.controls.SchMedNum.setValue(this.arrangeFormGroup.controls.SchMedNum.value);
    this.referralFormGroup.controls.InHspidAddress.setValue(this.arrangeFormGroup.controls.InHsAddrw.value);
    this.referralFormGroup.controls.InHsPhone.setValue(this.arrangeFormGroup.controls.InHsPhone.value);
    this.referralFormGroup.controls.DrTrans.setValue(this.arrangeFormGroup.controls.DrTrans.value);
  }

  ArrangeInDiagClose() {
    this.isArrangeWinOpened = false;
  }
}
