<div class="row narrow-padding overflow-hidden">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <div style="display: flex;flex-wrap: wrap;column-gap: 10px;padding: 5px 20px;">
          <ng-container *ngFor="let col of labItems">
            <div style="width: 12%;display: flex;">
              <mat-checkbox  class="ellipsis-label" style="width: 100%;" (change)="checkChange(col,$event)" [(ngModel)]="col.extension">{{col.text}}</mat-checkbox>
              <!-- <div [title]="col.text" style="flex-grow: 1;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">{{col.text}}</div> -->
            </div>
          </ng-container>
          <div style="width: 200px;flex-grow: 1;text-align: end;">
            <app-vis-button [icon]="'check'" [label]="'全選'" (click)="allDisplayCol()"></app-vis-button>
            <app-vis-button [icon]="'clear'" [label]="'清除'" (click)="clearDisplayCol()"></app-vis-button>
            <app-vis-button [icon]="'search'" [label]="'查詢'" (click)="updateDisplayCol()"></app-vis-button>
          </div>
        </div>
        
      </div>
      <!--./card-header-->
      <div class="card-body">
        
        <app-vis-grid *ngIf="displayView" [data]="displayView" [scroll]="true" [showStickyHead]="true" [stickyColumnCount]="1">
          <app-vis-grid-column [width]="200" [field]="'RegDate'" [dateTimeFormat]="'DateTime'" [title]="'就診時間'"> 
            
          </app-vis-grid-column>
          <ng-container *ngFor="let col of labItems">
            <ng-container *ngIf="col.extension==true">
              <app-vis-grid-column [width]="200" [field]="col.value" [title]="col.text">
                <ng-template let-data>
                  <ng-container *ngIf="!data[col.value]?.Result;else elseVal"></ng-container>
                  <ng-template #elseVal>
                    (<span [ngStyle]="{color:data[col.value].Color}">{{data[col.value].Result}}</span>){{data[col.value].Value}}
                  </ng-template>
                </ng-template>  
                </app-vis-grid-column>
            </ng-container>
            <div noData>沒有可用的記錄. </div>
          </ng-container>
        </app-vis-grid>
      </div>
      <!--./card-body-->
    </div>
    <!--./card-->
  </div>
</div>
