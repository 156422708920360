export class IdHelper {
  static readonly IdEmpty = 0;
 // private static readonly GuidEmpty2 = '00000000000000000000000000000000';

  static isNullOrEmpty(id: number): boolean {
    if (!id) {
      return true;
    }
    if (id === IdHelper.IdEmpty ) {
      return true;
    }
    return false;
  }
}
