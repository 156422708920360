<app-vis-dialog [title]="'新增代碼'" [show]="show" [width]="280" (close)="close()">
  <div class="card-body extend-msg">
    <div style="margin-top: 5px; display: flex">
      <div class="w-14">類別</div>
      <div class="w-100">
        {{addSystemCode.Type}}
      </div>
    </div>
    <div style="margin-top: 5px; display: flex">
      <div class="w-14">代碼</div>
      <input [(ngModel)]="addSystemCode.Code" class="w-100"/>
    </div>
    <div style="margin-top: 5px;display: flex">
      <div class="w-14">名稱</div>
      <input  [(ngModel)]="addSystemCode.Name" class="w-100"/>
    </div>
    <div style="margin-top: 5px;display: flex">
      <div class="w-14">排序</div>
      <input  [(ngModel)]="addSystemCode.SN" class="w-100"/>
    </div>
    <div style="margin-top: 5px;display: flex">
      <div class="w-14">啟用</div>
      <div class="w-100" class="vis-form-field" >
        <mat-radio-group style="display: flex;" [(ngModel)]="addSystemCode.IsEnabled">
          <mat-radio-button [value]="true">是</mat-radio-button>
          <mat-radio-button [value]="false">否</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
  <div class="d-flex m-2">
    <div style="flex-grow:1"></div>
    <button type="button"
      (click)="save()"
      class="vis-btn-config p-0 position-relative pl-2 w-18"
      title="儲存">
      <mat-icon class="pref-icon add vis-combine-icon">save</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">儲存</span>
    </button>
    <button type="button"
      (click)="close()"
      class="vis-btn-config p-0 position-relative pl-2 w-18"
      title="取消">
      <mat-icon class="pref-icon remove vis-combine-icon">cancel</mat-icon>
      <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">取消</span>
    </button>
  </div>
</app-vis-dialog>
