import { EventEmitter } from "@angular/core";
import { AbstractControl } from "@angular/forms";
import { PostAreaAPI } from "src/app/services/api-service/postarea-api";
import { UserConfirmService } from "src/app/services/user-confirm.service";
import { ValueTextPair } from "../models/value-text-pair";

export class ZipCodeHelper {

    private city: ValueTextPair[] = [];
    private area: ValueTextPair[] = [];
    private areaFilterd: ValueTextPair[];
    private status: boolean = true;
    private cityControl: AbstractControl;
    public onAreaChange = new EventEmitter<{ City: ValueTextPair[], Area: ValueTextPair[] }>();
    public onChanged = new EventEmitter<{ City: string, Area: string, Zip: string, Street?: string }>();
    public disable() {
        this.status = false;
    }
    public enable() {
        this.areaFilterd = this.area.filter(a => a.value == this.cityControl.value)
            .map(v => { return { text: v.text, value: v.text }; });
        this.status = true;
        this.onAreaChange.emit({ City: this.city, Area: this.areaFilterd });
    }
    constructor(
        public postAreaApi: PostAreaAPI,
        userConfirm: UserConfirmService,
        zipcodeEl: HTMLInputElement,
        zipcodeControl: AbstractControl,
        cityControl: AbstractControl,
        areaControl: AbstractControl,
        streetControl: AbstractControl) {
        this.cityControl = cityControl;
        var emit = () => {
            this.onChanged.emit({
                City: cityControl.value,
                Area: areaControl.value,
                Zip: zipcodeControl.value,
                Street: streetControl.value
            });
        }
        cityControl.valueChanges.subscribe((value) => {
            if (value && this.status) {
                this.areaFilterd = this.area.filter(a => a.value == value).map(v => { return { text: v.text, value: v.text }; });
                this.onAreaChange.emit({ City: this.city, Area: this.areaFilterd });
                if (!this.areaFilterd.find(v => v.text == areaControl.value)) {
                    areaControl.setValue('', { emitEvent: false });
                }
                // console.log('set city to', value)
                emit();
            }
            //this.updateIsMountain();
        });

        areaControl.valueChanges.subscribe(async value => {
            //this.updateIsMountain();
            if (cityControl.value && areaControl.value && this.status) {
                var zipCode = await postAreaApi.GetZip(cityControl.value, areaControl.value);
                var zipstr = zipcodeControl.value ? zipcodeControl.value.toString() : '';
                if (!zipstr.startsWith(zipCode)) {
                    zipcodeControl.setValue(zipCode, { emitEvent: false });
                }
                // console.log('set area to', value, zipCode)
                emit();
            }

        });

        zipcodeEl.onchange = async value => {
            if (!zipcodeControl.value || !this.status) {
                return;
            }
            var postArea = await postAreaApi.GetByZip(zipcodeControl.value);
            if (!postArea) {
                return;
            }
            // 其中一個有值 而且縣市/鄉鎮市區沒完全一樣
            if ((cityControl.value || areaControl.value) &&
                !(cityControl.value == postArea.City &&
                    areaControl.value == postArea.Area)) {

                var msg = `是否將縣市/鄉鎮市區變更為[${postArea.City}]/[${postArea.Area}]？`
                if (!(postArea.Area + postArea.City)) {
                    msg = '查無郵遞區號資料，是否清除縣市/鄉鎮欄位？'
                }

                var ret = await userConfirm.showConfirm({
                    msg: msg,
                    width: 400,
                });
                if (ret == false) {
                    return;
                }
            }

            cityControl.setValue(postArea.City, { emitEvent: false });
            this.areaFilterd = this.area.filter(a => a.value == cityControl.value).map(v => { return { text: v.text, value: v.text }; });
            this.onAreaChange.emit({ City: this.city, Area: this.areaFilterd });
            setTimeout(() => {

                areaControl.setValue(postArea.Area, { emitEvent: false });
                if (!streetControl.value) {
                    streetControl.setValue(postArea.Road, { emitEvent: false });
                }
                emit();
            }, 0);

            //await this.updateIsMountain();
        };
    }
    async init() {
        var ret = await this.postAreaApi.GetCityArea();
        this.city = ret.city.map(s => { return { value: s, text: s }; });
        this.area = ret.area;
        this.areaFilterd = this.area.filter(a => a.value == this.cityControl.value)
            .map(v => { return { text: v.text, value: v.text }; });
        this.onAreaChange.emit({ City: this.city, Area: this.areaFilterd });

    }

}
