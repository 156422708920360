import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HcrConsoleComponent } from './hcr-console.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PatientsModule } from 'src/app/patients/patients.module';
import { CnhiEiiTestComponent } from '../cnhi-eii-test/cnhi-eii-test.component';

import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { QrcodeScannerComponent } from '../qrcode-scanner/qrcode-scanner.component';


@NgModule({
  declarations: [
    HcrConsoleComponent,CnhiEiiTestComponent,QrcodeScannerComponent
  ],
  exports:[HcrConsoleComponent,CnhiEiiTestComponent],
  imports: [
    CommonModule,
    ZXingScannerModule,
    SharedModule
  ]
})
export class HcrConsoleModule { }
