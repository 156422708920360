import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRoleFunctionService } from 'src/app/services/data-service/user-role-function.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserPermissions } from 'src/app/enums/UserPermssionEnum';
import { UserRoleFunction } from 'src/app/models/UserRoleFunction';
import { WebApiService } from 'src/app/services/web-api.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { CustomResult } from '../models/custom-result';

const defaultUserRoleFunction: UserRoleFunction = {
  UserId: 0,
  ClinicId: 0,
  IsView: false,
  IsAdd: false,
  IsModify: false,
  IsDelete: false,
  IsExport: false,
  IsPrint: false
}

/**
 * 指令 `appAllowPermissionIf` 允許基於用戶的權限來動態控制元素的顯示或隱藏。
 * 此指令依賴於用戶角色與功能的對應關係來判斷是否渲染相關的 DOM 元素。
 *
 * @Directive
 * @selector [appAllowPermissionIf]
 *
 * @usageNotes
 * 將此指令應用於任何模板元素，並傳入必要的權限和功能代碼，來控制元素的顯示狀態。
 * - permissions: 指定需要的權限。
 * - targetFunctionCode: 與權限相關聯的功能代碼。
 * - forceHidden: 強制隱藏元素，無視權限設定。
 * - arg: 任意參數，目前未使用。
 *
 * 例子：
 * ```html
 * <div *appAllowPermissionIf="[[this.UserPermissions.Add,this.UserPermissions.Modify], null, true]">This content is conditionally displayed.</div>
 * ```
 *
 * @params {Array<UserPermissions | string | boolean | any>} [permissions, targetFunctionCode, forceHidden, arg]
 * - permissions: 用戶必須具有的權限列表。
 * - targetFunctionCode: 指定功能代碼來對應特定的權限設定。
 * - forceHidden: 當設為 true 時，無論權限如何都會隱藏元素。
 * - arg: 保留參數，用於未來擴展。
 *
 * @inputs
 * - appAllowPermissionIfElse: 當不滿足權限條件時，用於顯示的替代模板。
 *
 * @description
 * 該指令檢查給定的權限，並確定是否展示或隱藏模板內容。如果用戶擁有適當的權限，則顯示主模板；
 * 否則，如果設置了 `appAllowPermissionIfElse`，則展示替代模板。
 */

@Directive({
  selector: '[appAllowPermissionIf]'
})
export class AllowPermissionIfDirective {
  private permissions: UserPermissions[];
  private targetFunctionCode: string | null;
  private forceHidden = false;
  private permissionMap = {
    [UserPermissions.View]: "IsView",
    [UserPermissions.Modify]: "IsModify",
    [UserPermissions.Export]: "IsExport",
    [UserPermissions.Print]: "IsPrint",
    [UserPermissions.Add]: "IsAdd",
    [UserPermissions.Delete]: "IsDelete"
  };

  @Input() set appAllowPermissionIf([permissions, targetFunctionCode, forceHidden, arg]: [UserPermissions[] | UserPermissions, string?, boolean?, any?]) {
    this.permissions = Array.isArray(permissions) ? permissions : [permissions];
    this.targetFunctionCode = targetFunctionCode || null;  // 當targetFunctionCode是undefined時，設置為null
    this.forceHidden = forceHidden || false;  // 當forceHidden是undefined時，設置為false

    this.updateVisibility();
  }

  @Input() appAllowPermissionIfElse: TemplateRef<any>;


  // api服務
  api: WebApiService;
  // 通知服務
  notification: EasyNotificationService;

  userRoleFunction: UserRoleFunction;

  private destroy$ = new Subject<void>();


  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userRoleFunctionService: UserRoleFunctionService,
    private webApiClient: WebApiClient,
    private notificationDI: EasyNotificationService,
  ) {
    this.api = this.webApiClient.createHisService('system/user');
    this.notification = notificationDI;
  }

  ngOnInit() {
    this.subscribeUserRoleFunction();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async subscribeUserRoleFunction() {
    this.userRoleFunction = defaultUserRoleFunction;

    this.userRoleFunctionService.userRoleFunction$
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe((data: UserRoleFunction) => {
        this.userRoleFunction = data;
        this.updateVisibility();
      });
  }

  private async updateVisibility() {
    let targetUserRoleFunctions = await this.getTargetUserRoleFunctions();

    let hasPermission = true;

    if (targetUserRoleFunctions && this.permissions) {
      hasPermission = this.permissions.some(p => {
        const permissionKey = this.permissionMap[p];
        return targetUserRoleFunctions && targetUserRoleFunctions[permissionKey] === true;
      });
    }

    if (hasPermission && !this.forceHidden) {
      if (this.viewContainer.length === 0) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      }
    }
    else {
      this.viewContainer.clear();

      if (this.appAllowPermissionIfElse) {
        this.viewContainer.createEmbeddedView(this.appAllowPermissionIfElse);
      }
    }
  }

  private async getTargetUserRoleFunctions() {
    let targetUserRoleFunctions = this.userRoleFunction;
    if (this.targetFunctionCode) {
      let result: CustomResult = await this.api.get(`GetCurrentUserRoleFunctions?path=&functionCode=${this.targetFunctionCode}`, {}).toPromise();

      if (result.IsSuccess)
        targetUserRoleFunctions = result.Object;
      else
        this.notification.showError(result.Message);
    }

    return targetUserRoleFunctions;
  }
}
