<div class="app-v-select v-control position-relative" style="display: flex; height: 25px;padding-top: 1px;"
  [ngClass]="customClass" [class.v-disabled]="disabled">
  <input type="text" class="border-0 bg-transparent" style="width:calc(100% - 1.3rem);" aria-label="Number" matInput
    [value]="displayText" [ngClass]="class" #input (click)="onInputClick()" (input)="inputChange()"
    (change)="valueChange($event)" [matAutocomplete]="auto" [disabled]="disabled">
  <button type="button" mat-icon-button class="setting-button" (click)="onSettingClick()">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-autocomplete [panelWidth]="panelFitContent?'fit-content':'1000'" (optionSelected)="optSelected($event)"
    #auto="matAutocomplete" disableRipple>
    <mat-option *ngFor="let item of filteredOptions | async" [value]="item[valueField]">
      <ng-container *ngIf="displayTmp;else defaultTmp">
        <ng-container *ngTemplateOutlet="displayTmp;context:{$implicit:item}"></ng-container>
      </ng-container>
      <ng-template #defaultTmp>{{item!=undefined&&item!=null?item[textField]:''}}</ng-template>
    </mat-option>
  </mat-autocomplete>
</div>
