<div style="display: flex;flex-direction: column;min-height: inherit;width: 500px;height: 400px;">
    <div matDialogTitle>
        <div style="width: calc(100% - 30px);">
            <span>請選擇檔案</span>
        </div>
        <button type="button" tabindex="-1"
            style="position: relative;right: 0px;width: 30px;height: 30px;margin-left: 0px; min-width: unset;padding: 0px;line-height: normal;"
            id="easy-form-close-btn" class="easy-form-close-btn" mat-button (click)="close()">
            <mat-icon style="color: #ddd; font-size: 20px;margin-top: 5px;">close</mat-icon>
        </button>
    </div>
    <div matDialogContent style="flex-grow: 1; ">
        <div class="p-3" style="display: flex;flex-direction: column;width: 100%;height: 100%;">
            <div class="extend-msg" style="display: flex;flex-wrap: wrap;">
                <div class="node-path-item" *ngFor="let item of nodePath;let i=index">
                    <div (click)="setCurrentNode(item)">
                        <mat-icon class="pref-icon add mr-1">folder_open</mat-icon>
                        <span>{{item.Name}}</span>
                    </div>
                    <span *ngIf="i<nodePath.length">&nbsp;\&nbsp;</span>
                </div>
                <!-- <div class="extend-msg" style="font-size: 12px;white-space:pre-wrap;">{{currentNode.FullName}}</div>  -->
            </div>
            <div style="margin: 5px 0px 0px 20px;flex-grow: 1;overflow-y: auto;">
                <!-- <ng-container *ngIf="parentNode">
                <div style="cursor: pointer;display: flex;" (click)="setCurrentNode(parentNode)">
                    <mat-icon class="pref-icon add" style="font-size: 20px;vertical-align: middle;">
                        reply
                    </mat-icon>
                    <div style="flex-grow: 1;">{{parentNode.Name}}</div>
                </div>
            </ng-container> -->
                <div class="file-list" *ngFor="let item of currentFiles">
                    <ng-container *ngIf="item.IsDirectory;else isFile">
                        <div class="folder-item" (click)="setCurrentNode(item)">
                            <mat-icon class="pref-icon add mr-2">
                                folder_open
                            </mat-icon>
                            <div style="flex-grow: 1;">{{item.Name}}</div>
                            <div *ngIf="folderSelect">
                                <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height" (click)="select(item)">選取</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #isFile>
                        <div *ngIf="!folderSelect" class="file-item">
                            <mat-icon class="pref-icon add mr-2" >
                                insert_drive_file
                            </mat-icon>
                            <div style="flex-grow: 1;">{{item.Name}}</div>

                            <div>
                                <button class="my-0 vis-btn-warning px-1 lh-xs vis-btn-max-height" (click)="select(item)">選取</button>
                            </div>
                        </div>
                    </ng-template>


                </div>
            </div>

            <div class="px-4 text-center">

                <button type="button" class="easy-form-btn sumbit vis-btn-primary mb-1 vis-btn-capsule mx-1"
                    (click)="close()">取消</button>
                <!-- <button type="button" class="easy-form-btn sumbit vis-btn-primary mb-1 vis-btn-capsule mx-1" (click)="close()" #btn>確認</button> -->
            </div>
        </div>
    </div>
