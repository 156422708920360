import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChildren, QueryList, ElementRef, ViewChild, HostListener, Output, Query, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { HistOrder } from '../models/hist-order';
import { HistService } from '../services/hist.service';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { OrderData } from '../models/order-data';
import { OrderTab } from '../models/order-tab';
import { RxClass } from '../models/rx-class';
import { Subject } from 'rxjs';
import { HelperId } from '../models/helper-id';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { NullOrEmpty } from 'src/app/shared/utilities';
import { UserCache } from 'src/app/services/user-cache';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

import { RxApi } from 'src/app/services/api-service/rx/rx-api';

@Component({
  selector: 'app-hist-order-grid-exec',
  templateUrl: './order-grid-exec.component.html',
  styleUrls: ['./order-grid-exec.component.css', '../styles/hist.css']
})
export class OrderGridExeComponent implements OnInit, OnDestroy {

  
  @ViewChild('rxDetail')
  rxDetail: TemplateRef<any>;
  

  constructor(private fb: FormBuilder,
    private notification: EasyNotificationService,
    private rxApi: RxApi,
    private histApi: HistApi,
    private histService: HistService,
    private cd: ChangeDetectorRef,
    private userConfirm: UserConfirmService,
    private formHelper: FormHelperService,
    private clinicData: ClinicDataService) {
    this.makeFormGroup();
  }
  isHomeCare: boolean = true;
  notUseDispTP: boolean = false;
  //#region 參數 helper --------------------
  private unsubscribe: Subject<void> = new Subject();
  //#endregion 參數 helper --------------------

  areaId = HelperId.OrderArea;
  showPrice: string;
  isSum: boolean = false;
  // 輸入資料:
  // setOrderData: 輸入資料 _tab & _orders
  // set editOption: 輸入編輯選項
  // 取回資料時:
  // get orders

  //#region 主要資料 --------------------
  _tab: OrderTab = new OrderTab();
  _orders: HistOrder[] = [];
  
  get editOptions() {
    return this.histService.EditOptions;
  }
  get enterLastColIdx() {
    if (!this.editOptions || !this.editOptions.histParams || !this.editOptions.histParams.EnterToNext) {
      return 2;
    }
    return this.editOptions.histParams.EnterToNext;
  }

  @Input()
  disabled: boolean = false;

  @Input()
  set orderData(orderData: OrderData) {
    try {
      if (!orderData.tab || !orderData.orders || !orderData.tab.rxClass) {
        return;
      }
      // 重新設定資料時，該畫面資料要重製
      this.clear();
      // 設定資料
      this._tab = orderData.tab;
      this._orders = orderData.orders;
      
      this.makeOrders_FormArray(this.numberOfOrder, this._tab);
      
      this.fillOrders_FormArray(this._orders, this._tab);
      // UI
      // 自動到最後一個空白Code
    } catch (e) {
      console.error(e);
      this.notification.showErrorById('MSG_HistOrders3');
    }
  }
  @Input() isRecordDone : boolean = false;

  get orders(): HistOrder[] {
    
    const filtered = [];
    var fhs = this.formHelpers;
    var ffhs = fhs.filter(x => !NullOrEmpty(x.Value.RxCode));
    // 複製
    for (let i = 0; i < ffhs.length; i++) {
      let odr = new HistOrder();
      var fv = ffhs[i].Value;
      odr = Object.assign(odr, fv);
      // 設定DispensingType
      //odr.DispTP = HistOrder.toDispensingType(odr.SelfDispensing);
      // fv.SDate會是字串
      // stime etime 防呆
      fv.STime = fv.STime != null ? fv.STime : '';
      fv.ETime = fv.ETime != null ? fv.ETime : '';

      odr.BeginDate = fv.SDate ? new Date(new Date(fv.SDate).toLocaleDateString() + ' ' + fv.STime) : null;
      odr.EndDate = fv.EDate ? new Date(new Date(fv.EDate).toLocaleDateString() + ' ' + fv.ETime) : null;
      filtered.push(odr);
    }

    
    // 補齊資料
    this._orders = HistOrder.autoFill(this.editOptions.histParams.AutoFill, filtered, this.editOptions.dosage);
    return this._orders;
  }

  clear() {
    // 清畫面
    this.orderArray.reset();
    this._orders = [];
  }
  //#endregion 參數 source data --------------------

  //#region 參數 form and orders --------------------
  editFG: FormGroup = this.fb.group({});
  get editFV() {
    return this.editFG.value;
  }
  get orderArray() {
    return (this.editFG.get('orders') as FormArray);
  }
  get orderFG() {
    return (this.editFG.get('orders') as FormGroup);
  }
  
  numberOfOrder = 60; // (最多)有幾筆醫囑
  // Default Grid
  spRuleIdPrefix = HelperId.OdrGridMark;
  codeIdPrefix = HelperId.OdrGridCode;
  nameIdPrefix = HelperId.OdrGridName;
  qtyIdPrefix = HelperId.OdrGridQty; // enter quantity
  unitIdPrefix = HelperId.OdrGridUnit;
  freqIdPrefix = HelperId.OdrGridFreq;
  daysIdPrefix = HelperId.OdrGridDays;
  totalDoseIdPrefix = HelperId.OdrGridTotalDose;
  wayIdPrefix = HelperId.OdrGridWay;
  totalBoxIdPrefix = HelperId.OdrGridTotalBox;
  boxUnitIdPrefix = HelperId.OdrGridBoxUnit;
  dispensingIdPrefix = HelperId.OdrGridDispensing;
  remarkIdPrefix = HelperId.OdrGridRemark;
  medIdNamePrefix = HelperId.OdrGridMedIdName;
  sdatePrefix = HelperId.OdrGridSDate;
  stimePrefix = HelperId.OdrGridSTime;
  edatePrefix = HelperId.OdrGridEDate;
  etimePrefix = HelperId.OdrGridETime;
  calPricePrefix = HelperId.OdrGridCalPrice;

  // 所有可用dosage，計算dose時要用到，來自editOptions.dosage
  get dosages(): Dosage[] {
    return this.editOptions.dosage;
  }

  //#endregion 參數 form and orders --------------------
  codeNotFoundKeyword = 'codeNotFound';
  bodyWidth = document.body.clientWidth;
  namePlusWidth: string = '';

  get isLab(): boolean {
    if (this._tab.rxClass === RxClass.Lab ||
      this._tab.rxClass === RxClass.XRay) {
      return true;
    } else {
      return false;
    }
  }


  ngOnInit() {
    this.isHomeCare = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
    this.notUseDispTP = UserCache.getLoginUser().Clinic.NotUseDispTP;
    this.filteredMedUser = this.editOptions.medUser.filter(x => x.value != null && x.value.length > 0);
    
    // 會在set orders時產生與填入FormGroup & FormArray，這邊就不再處理
    // this.makeFormGroup();
    if (this.disabled) {
      this.editFG.controls.allDisabled?.disabled;
    }
    if (this.bodyWidth) {
      this.namePlusWidth = this.bodyWidth / 5 - 32 + "px";
    }
  }
  /** grid table width */
  tableWidth: string;

  //#region misc
  // 每個tab可用的OrderType不同
  getAllowedOrderTypes(): number[] {
    var rxTypes: number[] = [];
    rxTypes = Object.assign(rxTypes, this._tab.rxType);
    // var rxTypes = this._tab.rxType;
    rxTypes.push(0);
    return rxTypes;
  }
  //#endregion

  
 
  // #region form --------------------
  // 結構是 一個FormGroup裡面有一個FormArray，主要就是這個FormArray
  // 產生表單元件，並定義基本檢核規則，沒有填入資料
  makeFormGroup() {
    // 已經有就返回
    if (this.editFG.controls.orders) { return; }
    this.editFG = this.fb.group({
      orders: this.fb.array([]),
    });
  }

  // 產生FormArray，沒有填入資料
  // 若order數目超過預設最大數目，後須操作會錯誤
  makeOrders_FormArray(rowCount: number, tab: OrderTab) {
    if (this.editFG.controls.orders && (this.editFG.controls.orders as FormArray).length === rowCount) {
      // 如果已經有form array就不再產生
      return;
    } else {
      this.editFG.controls.orders = new FormArray([]);
      this.formHelpers = [];
      for (let i = 0; i < rowCount; i++) {
        const fg = this.makeOneOrder_FormGroup(tab);
        (this.editFG.controls.orders as FormArray).push(fg);
      }
    }
  }

  formHelpers: FormHelper<OrderModel>[] = []
  // 產生單個Order元件，沒有填入資料
  makeOneOrder_FormGroup(tab: OrderTab): FormGroup {
    var fh = this.formHelper.Create<OrderModel>().build({
      Id: [0, []],
      RxId: [0, []],
      RxType: [0, []],
      RxCode: ['', []],
      ProdName: ['', []],
      SDate: [new Date(this.histService.currentHist.Register.RegDate), []],
      EDate: [new Date(this.histService.currentHist.Register.RegDate), []],
      STime: ['', []],
      ETime: ['', []],
      MedID: [null, []],
      MedIDName: [null, []],
      FilterExecutor: [null, []],
      NeedExecutor: [false, []],
      NeedExecuteDate: [false, []],
      NeedExecuteTime: [false, []]
    });

    fh.Controls.SDate.disable();
    fh.Controls.EDate.disable();
    fh.Controls.STime.disable();
    fh.Controls.ETime.disable();
    // this.needSTime = false;
    // this.needETime = false;

    this.formHelpers.push(fh);
    return fh.FormGroup;
  }

  // 填入表單資料，有重新產生FormArray
  fillOrders_FormArray(orders: HistOrder[], tab: OrderTab) {
    
    if (!orders) { return; }
    for (let i = 0; i < this.orderArray.length; i++) {
      let odr: HistOrder = null;
      if (i < orders.length) {
        odr = orders[i];
      }
      this.fillOneOrder_FormGroup(i, odr, tab);
    }
    //setTimeout(() => {
    this.cd.detectChanges();
    //}, 0);
    if (this.disabled) {
      if (this.editFG.controls.allDisabled) {
        this.editFG.controls.allDisabled.disabled;
      }
      if (this.editFG.controls.MedIDName) {
        this.editFG.controls.MedIDName.disabled;
      }
    }
  }

  // 將單筆Order填入FormGroup
  async fillOneOrder_FormGroup(i: number, data: HistOrder, tab: OrderTab) {
    var fh = this.getFormHelper(i);
    if (!fh) {
      return;
    }
    if (!data) {
      data = HistOrder.createEmpty();
      data.RxClass = tab.rxClass;
    }
    fh.patchValueNoEvent({
      Id: data.Id || 0,
      RxId: data.RxId || 0,
      RxCode: data.RxCode || '',
      RxType: data.RxType || 0,
      ProdName: data.ProdName || '',
      SDate: data.BeginDate,
      STime: DateHelper.getHourMinTime(data.BeginDate),
      EDate: data.EndDate,
      ETime: DateHelper.getHourMinTime(data.EndDate),
      MedID: data.MedID,
      MedIDName:data.MedIDName,
      FilterExecutor: data.FilterExecutor,// null,
      NeedExecutor: data.NeedExecutor,
      NeedExecuteDate: data.NeedExecuteDate,
      NeedExecuteTime: data.NeedExecuteTime
    });
    if (this.disabled) { 
      fh.Controls.MedIDName.disable(); 
      fh.Controls.STime.disable();
      fh.Controls.ETime.disable();
      fh.Controls.SDate.disable();
      fh.Controls.EDate.disable();
    }
    if (data.RxCode) {
      if(data.NeedExecuteDate){
        fh.Controls.SDate.enable();
        fh.Controls.EDate.enable();
      }else{
        fh.Controls.SDate.disable();
        fh.Controls.EDate.disable();
      }
      if(data.NeedExecuteTime){
        fh.Controls.STime.enable();
        fh.Controls.ETime.enable();
      }else{
        fh.Controls.STime.disable();
        fh.Controls.ETime.disable();
      }
    } else {
      fh.Controls.MedIDName.disable(); 
      fh.Controls.STime.disable();
      fh.Controls.ETime.disable();
      fh.Controls.SDate.disable();
      fh.Controls.EDate.disable();
    }
  }
  // #endregion form --------------------

  // #region code --------------------
  

  filteredMedUser?: ValueTextPair[] = [];
  clearExecutor(fh:FormHelper<OrderModel>){
    fh.patchValue({
      NeedExecutor:false,
      NeedExecuteDate:false,
      NeedExecuteTime:false,
      SDate:null,
      EDate:null,
      STime:'',
      ETime:''
    });
  }
  enableExecutor(fh:FormHelper<OrderModel>){
    fh.Controls.SDate.enable();
    fh.Controls.EDate.enable();
    fh.Controls.STime.enable();
    fh.Controls.ETime.enable();
    fh.Controls.MedIDName.enable();
  }
  disableExecutor(fh:FormHelper<OrderModel>){
    fh.Controls.SDate.disable();
    fh.Controls.EDate.disable();
    fh.Controls.STime.disable();
    fh.Controls.ETime.disable();
    fh.Controls.MedIDName.disable();
  }


  getDateString(ev, isRoc: boolean = false, symbol = '/', isMonth = false) {
    if (ev) {
      var d = new Date(ev);
      var month = '' + (d.getMonth() + 1);
      var day = '' + d.getDate();
      var year = isRoc ? (d.getFullYear() - 1911) : d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      if (isMonth) {
        return [year, month].join(symbol);
      } else {
        return [year, month, day].join(symbol);
      }
    }
  }

  getExecuteTimeSetting(rowIndex: number, type: number): boolean {
    var fh = this.getFormHelper(rowIndex);
    var retTime = fh.Controls.NeedExecuteTime.value;
    var retDate = fh.Controls.NeedExecuteDate.value;
    if (type == 2) {
      if (retTime) {
        fh.Controls.STime.enable();
        fh.Controls.ETime.enable();
        // this.needSTime = true;
        // this.needETime = true;
      } else {
        fh.Controls.STime.disable();
        fh.Controls.ETime.disable();
        // this.needSTime = false;
        // this.needETime = false;
      }
      return !retTime;
      // return false;  // 暫時先都打開 [disabled] = false
    } else {
      if (retDate) {
        fh.Controls.SDate.enable();
        fh.Controls.EDate.enable();
        // this.needSTime = true;
        // this.needETime = true;
      } else {
        fh.Controls.SDate.disable();
        fh.Controls.EDate.disable();
        // this.needSTime = false;
        // this.needETime = false;
      }
      return !retDate;
      // return false;  // 暫時先都打開 [disabled] = false
    }
  }

  checkDisabled(): boolean {
    return this.disabled;
  }

  inputDisabled(): boolean {
    if (this.disabled) {
      return true;
    } else {
      return null;
    }
  }

  getFilterExecutor(rowIndex: number): ValueTextPair[] {
    var fh = this.getFormHelper(rowIndex);
    return fh.Controls.FilterExecutor.value;
    // return this.editOptions.executor;
  }

  getNeedExecutor(rowIndex: number): boolean {
    var fh = this.getFormHelper(rowIndex);
    return fh.Controls.NeedExecutor.value;
  }

  // #endregion

  // #region code sort --------------------
  codeSort() {
    // 因為效能的緣故
    // 不去直接移動FormArray的controls順序，而是去改變control的值
    // 因此
    // 先取回資料，然後排序
    // 再把排序好的值設定到FormArray
    if (this.disabled) return;
    
    let tmpOrders: HistOrder[] = [];
    if (this.orderArray.value && this.orderArray.value.length > 0) {
      const arrFiltered = this.orderArray.value.filter(x => x.RxCode !== null && x.RxCode !== '');
      tmpOrders = Object.assign([], arrFiltered);
    }
    // 排序
    let sortedOrder = [];
    sortedOrder = tmpOrders.sort(function (a, b) {
      // 先比OrderType，再比OrderSubType，再比ATC
      if (a.RxType === 0) {
        return 1;
      } if (a.RxType > b.RxType) {
        return 1;
      } else if (a.RxType === b.RxType) {
        // if (!a.OrderSubType) {
        //   return 1;
        // } else if (a.OrderSubType > b.OrderSubType) {
        //   return 1;
        // } else {
        if (!a.ATC) {
          return 1;
        } else if (a.ATC > b.ATC) {
          return 1;
        } else {
          return -1;
        }
        //}
      } else {
        return -1;
      }
    });
    // 把資料設定到FormArray
    this.fillOrders_FormArray(sortedOrder, this._tab);
  }
  // #endregion

  // #region freq --------------------
  
  checkNeedExecutor(rowIndex: number): boolean {
    return !this.formHelpers[rowIndex].Value.NeedExecutor;
  }

  // #endregion freq


  // #region Dispensing
  
  

  setMedID(order: FormGroup, value: string, text: string, restoreFocusId: string) {
    order.patchValue({
      MedID: value,
      MedIDName: text
    });
    setTimeout(() => {
      document.getElementById(restoreFocusId).focus();
    }, 0);
  }

  
  // #endregion
  //#region Remark


  medIdNameMenuClosed(rowId: number) {
    setTimeout(() => {
      var input = document.getElementById(this.medIdNamePrefix + rowId);
      input.focus();
    }, 0);
  }

  @ViewChildren(MatMenuTrigger)
  menuBtns: QueryList<MatMenuTrigger>;
  onMedIDNameKeyDown(event: KeyboardEvent, rowIndex: number) {
    // 按下Escape清空，helper重設
    if (event.code === 'Escape') {
      //this.helperService.reset();
    } else if (event.code === 'Space') {
      if (this.orderArray.controls[rowIndex].get('RxCode').value) {
        this.menuBtns.forEach(w => {
          if (w.menuData == this.medIdNamePrefix + rowIndex) {
            setTimeout(() => {
              w.openMenu();
            }, 0);
          }
        })
      }
    } else if (event.key === 'Tab' || event.key === 'Enter') {
      const elemId = this.sdatePrefix + rowIndex;
      document.getElementById(elemId).focus();
    } else {
      // 送到helper只是要操作helper視窗的上,下,Enter,Escape
      //this.helperService.keydownForDropdown(event);
    }
  }

  medIDNameInputBlur(event: InputEvent, rowIndex: number) {
    if (this.orderArray.controls[rowIndex].get('NeedExecutor').value) {
      var d: string = this.orderArray.controls[rowIndex].get('MedIDName').value;
      if (d) {
        if (this.isRecordDone){
          var retDone = this.editOptions.executor.find(x => x.text == d)
          if (retDone) return;
        }
        var fh = this.getFormHelper(rowIndex).Controls.FilterExecutor.value;
        var ret = fh.map(x => {
          var array = x.text.split('.');
          return array[0] == d ? x : (x.text == d ? x : null);
        })
          .filter(r => r != null);

        if (ret && ret.length > 0) {
          this.orderArray.controls[rowIndex].patchValue({
            MedID: ret[0].value,
            MedIDName: ret[0].text
          });
        } else {
          this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
          this.orderArray.controls[rowIndex].get('MedID').setValue(null);
          this.notification.showError('查無此執行人員!');
        }
      } else {
        this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
        this.orderArray.controls[rowIndex].get('MedID').setValue(null);
      }
    } else {
      this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
      this.orderArray.controls[rowIndex].get('MedID').setValue(null);
    }
  }

  medIDNameInputChange(event: InputEvent, rowIndex: number) {   // 非必要輸入執行人員
    if (!this.orderArray.controls[rowIndex].get('NeedExecutor').value) {
      this.orderArray.controls[rowIndex].get('MedIDName').setValue(null);
      this.orderArray.controls[rowIndex].get('MedID').setValue(null);
    }
  }
  //#endregion


  // #region cell --------------------

  getFormHelper(rowIndex: number) {
    var fhs = this.formHelpers[rowIndex];
    return fhs;
  }
  // UI(grid)的Name呈現欄位，中藥的Code跟Name同一個欄位
  getNameElemId(rowIndex: number): string {
    return this.nameIdPrefix + rowIndex;
  }
  getOrderName(rowIndex: number) {
    return this.getFormHelper(rowIndex)?.Value.ProdName ?? '';
  }
  // #endregion cell

  //#region layout
  get layoutCssClass(): string {
    return 'default-layout';
  }
  //#endregion

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  editControls: FormGroup[] = [];

  getTableWidthResize() {
    if (window.innerWidth < 1300 && window.matchMedia('max-width:1300px')) {
      let numWork = this.bodyWidth / 5;
      this.tableWidth = 1200 + numWork + 'px';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.tableWidth = '';
    this.getTableWidthResize();
  }


  async viewDetail(order: FormGroup) {
    var rxFull = await this.histApi.getOrderByCode(order.value.RxCode, this._tab.rxType);
    this.userConfirm.showAlert(rxFull.RxCode, '', {
      width: 500,
      template: this.rxDetail,
      templateData: rxFull
    });
  }

  isActive(input: HTMLInputElement) {
    return document.activeElement == input;
  }
  
}

declare type OrderModel = {
  Id: number;
  RxId: number;
  RxCode: string;
  RxType: number;
  ProdName: string;
  SDate: Date;
  EDate: Date;
  STime: string;
  ETime: string;
  MedID: string;
  MedIDName: string;
  FilterExecutor: ValueTextPair[];
  NeedExecutor: boolean;
  NeedExecuteDate: boolean;
  NeedExecuteTime: boolean;
}
