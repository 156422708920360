<div class="card narrow-padding patients-edit" [hidden]="!isShow()" style="margin-bottom: 25px; min-width:600px; height: 100%;">
  <div class="card-header card-header-light-blue">
    <ng-container i18n="@@PatientInData">
      <h5 class="vis-title d-inline-block text-base font-weight-bold">
        <mat-icon class="pref-icon vis-combine-icon">account_circle</mat-icon>
        病患基本資料
        <mat-icon *ngIf="enableQRCode && patientId" (beforeOpen)="getQRCode()" [appOpenDialog]="qrcodeDialog" class="position-absolute">notifications</mat-icon>
      </h5>
    </ng-container>
    <app-shared-edit-control-buttons [class.hidden]="!this.patientId && editFormGroup?.disabled"
    (onEmitSave)="save(false)" (onEmitPrint)="print(null)" (onEmitCancel)="cancel()" (onEmitDelete)="delete()" (onEmitEdit)="editFromView()"
      [hasDeleteButton]="this.mode=='basic'&&!isFromCompEnter" [showDeleteConfirm]="false">
      <button *ngIf="isNewBorn" (click)="updateNewBorn()" end [disabled]="registering"
        class="vis-btn-config p-0 position-relative pl-2 w-30 ml-1" title="更新依附註記">
        <mat-icon class="pref-icon vis-combine-icon">update</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">更新依附註記</span>
      </button>
      <button *ngIf="hasRegisterBtn() && !this.editFormGroup.enabled" (click)="preview(d)" end [disabled]="registering"
        class="vis-btn-config p-0 position-relative pl-2 w-24 ml-1" title="預覽列印">
        <mat-icon class="pref-icon vis-combine-icon">print</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">預覽列印</span>
      </button>
      <button *ngIf="hasRegisterBtn() && !this.editFormGroup.enabled" (click)="print(d)" end [disabled]="registering"
        class="vis-btn-config p-0 position-relative pl-2 w-18 ml-1" title="列印">
        <mat-icon class="pref-icon vis-combine-icon">print</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
      </button>
      <button *ngIf="hasRegisterBtn()" (click)="save(true)" end [disabled]="registering"
        class="vis-btn-config p-0 position-relative pl-2 w-18 ml-1" title="掛入">
        <mat-icon class="pref-icon vis-combine-icon">how_to_reg</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">掛入</span>
      </button>
    </app-shared-edit-control-buttons>
  </div>
  <div class="card-body" style="overflow-y: scroll;height: calc(100% - 50px);" *ngIf="editFormGroup">
    <form class="vis-form vis-patients-edit pt-2" [formGroup]="editFormGroup">
      <mat-grid-list [rowHeight]="60" cols="4" [gutterSize]="'5px'" class="mb-extended">
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@PatientNo">病歷號</span>
            <!-- <div class="k-textbox read-only">{{editFormValue.PatientNo}}</div> -->
            <input class="vis-input v-disabled" tabindex="-1" formControlName="PatientNo" readonly />
          </label>
        </mat-grid-tile>
        <ng-container *ngIf="isChineseName;else engName">
          <mat-grid-tile [colspan]="1">
            <label class="vis-form-field">
              <span class="required" i18n="@@PatientName">姓名</span>
              <input class="vis-input" formControlName="CName" class="required text-uppercase" #nameInput />
              <app-validation-tip controlName="CName"></app-validation-tip>
            </label>
          </mat-grid-tile>
        </ng-container>
        <ng-template #engName>
          <mat-grid-tile>
            <label class="vis-form-field">
              <span class="required" i18n="@@FirstName">名字</span>
              <input class="vis-input" formControlName="CName" class="required" />
              <app-validation-tip controlName="CName"></app-validation-tip>
            </label>
          </mat-grid-tile>
          <mat-grid-tile>
            <label class="vis-form-field">
              <span i18n="@@MiddleName">中間名</span>
              <input class="vis-input" formControlName="MiddleName" />
              <app-validation-tip controlName="MiddleName"></app-validation-tip>
            </label>
          </mat-grid-tile>
          <mat-grid-tile>
            <label class="vis-form-field">
              <span i18n="@@LastName">姓氏</span>
              <input class="vis-input" formControlName="LastName" />
            </label>
          </mat-grid-tile>
        </ng-template>

        <mat-grid-tile>
          <label class="vis-form-field">
            <span class="position-relative w-100 checkbox-label-ui">
              <span class="required" i18n="@@IdentityNo">身分證號</span>
              <mat-checkbox formControlName="IsCheckCId" (change)="validCidChanged()"
                class="float-right position-absolute end-0 -top-2" tabindex="-1">
                檢查
              </mat-checkbox>
            </span>
            <input formControlName="CId" (input)="CIdInput($event)" type="tel" maxlength="10"
              (change)="CIdChange($event)" class="required" #cidFocusElement />
            <app-validation-tip controlName="CId"></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Sex">性別</span>
            <app-vis-dropdownlist formControlName="Sex" [data]="getOption('Sex')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="Sex">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span class="required" i18n="@@Birthdate">生日</span>
            <app-vis-datepicker class="required" (valueChange)="onBirthdayChange($event)" formControlName="Birthday"
              [maxWidth]="false" [max]="todayMax"  appHistFocus #birthdayFocusElement>
            </app-vis-datepicker>
            <app-validation-tip controlName="Birthday">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Age">年齡</span>
            <input tabindex="-1" class="vis-input v-disabled" readonly [value]="editFormValue.Birthday |age:true">
            <app-validation-tip></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@BloodType">血型</span>
            <app-vis-dropdownlist formControlName="BlType" [data]="getOption('Blood')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="BlType">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@BloodRH">血型RH</span>
            <app-vis-dropdownlist formControlName="BlRH" [data]="getOption('RH')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="BlRH">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Insurance">保險身分</span>
            <app-vis-combobox-real-time-option formControlName="ICode" [data]="getOption('C0010')" [valueFormat]="'valuetext'" (onSetting)="onSetting('C0010')">
            </app-vis-combobox-real-time-option>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@FamilyCode">家族號</span>
            <input class="vis-input" formControlName="Family" maxlength="20" />
          </label>
        </mat-grid-tile>
        <mat-grid-tile *ngIf="false">
          <label class="vis-form-field">
            <span i18n="@@PatientTitle">稱謂</span>
            <app-vis-dropdownlist formControlName="Title" [data]="getOption('B0210')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@MaritalStatus">婚姻狀態</span>
            <app-vis-dropdownlist formControlName="Married" [data]="getOption('Married')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="Married">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Job">職業</span>
            <app-vis-dropdownlist formControlName="JobCode" [data]="getOption('B0030')" [valuePrimitive]="true">
            </app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Education">教育程度</span>
            <app-vis-combobox-real-time-option formControlName="EducationCode" [data]="getOption('B0040')" [valueFormat]="'valuetext'"  (onSetting)="onSetting('B0040')">
            </app-vis-combobox-real-time-option>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Discount">優待身分</span>
            <app-vis-dropdownlist formControlName="DiscountId" [data]="editOptions.discount" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="DiscountId">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@SupArea">支援區域代碼</span> <!-- MedLackArea -->
            <app-vis-dropdownlist formControlName="SupArea" [data]="medLackAreas" [textField]="'text'"
              [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
            <app-validation-tip controlName="DiscountId">
            </app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Kcstmr">診所自訂碼</span>
            <input class="vis-input" formControlName="Kcstmr" maxlength="10" />
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@SoldierEdt">役男期限</span>
            <app-vis-datepicker formControlName="SoldierEdt" [maxWidth]="false" [max]="todayMax">
            </app-vis-datepicker>
            <app-validation-tip controlName="SoldierEdt">
            </app-validation-tip>
          </label>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="isNewBorn">
          <label class="vis-form-field">
            <span i18n="@@NewBornNote">依附</span>
            <input type="number" class="vis-input" style="width: calc(100% - 80px);" formControlName="MonParentsNo" appNumberOnly />
            <span style="display:inline;width: 70px;">&nbsp;胎數&nbsp;</span>
            <input type="number" class="vis-input" style="width: 30px;" formControlName="BirthsNum" appNumberOnly />
          </label>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="isHomeCare">
          <label class="vis-form-field">
            <span [ngClass]="[isHomeCare ? 'required' : '']" i18n="@@aySysA">居護收案別</span> <!-- NI, NG, N4 -->
            <app-vis-dropdownlist formControlName="PaySysA" [data]="paySysAList" [textField]="'text'" #paySysAFocusElement
              [valueField]="'value'" [valuePrimitive]="true" [ngClass]="[isHomeCare ? 'required' : '']">
            </app-vis-dropdownlist>
          </label>
        </mat-grid-tile>

        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@RemarkOne">備註一</span>
            <app-vis-combobox-real-time-option formControlName="RemarkOne" [data]="getOption('B0270')" [valueFormat]="'text'" (onSetting)="onSetting('B0270')" [setNotInComboData]="true">
            </app-vis-combobox-real-time-option>
          </label>
        </mat-grid-tile>

        <mat-grid-tile>
          <div class="vis-form-field">
            <span class="required" i18n="@@NeedToCheckCName">顯示姓名變更提示</span>
            <mat-radio-group formControlName="NeedToCheckCName" class="extend-label">
              <mat-radio-button [value]="true" i18n="@@NeedToCheckYes">是</mat-radio-button>
              <mat-radio-button [value]="false" i18n="@@NeedToCheckSMSNo">否</mat-radio-button>
            </mat-radio-group>
            <app-validation-tip controlName="NeedToCheckCName"></app-validation-tip>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
      <!-- <mat-divider></mat-divider> -->
      <label class="vis-form-field mt-extend">
        <span i18n="@@RegisterAddress" class="card-header d-flex py-2 px-0 mt-0 card-header-gradation"><mat-icon
            class="pref-icon btn-initial position-relative top-2 mr-1 ml-2 btn-scale-90">phone</mat-icon>聯絡方式</span>
      </label>
      <mat-grid-list cols="8" [rowHeight]="60" [gutterSize]="'5px'" class="mt-2 mb-extended">
        <mat-grid-tile colspan="2">
          <label class="vis-form-field">
            <span i18n="@@Phone(Office)">電話(住家)</span>
            <input type="tel" maxLength="3" appNumberIntegerOnly class="vis-input border-right-0" formControlName="PhoneArea"
              style="width:30%; display: inline-block;" />
            <input type="tel" class="vis-input" formControlName="Phone" appNumberIntegerOnly maxLength="10"
              style="width:69%; display: inline-block;" />
            <app-validation-tip controlName="PhoneArea"></app-validation-tip>
            <app-validation-tip controlName="Phone"></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <label class="vis-form-field">
            <span i18n="@@Mobile">手機</span> <!-- 2023/06/12 沈協理 feeback: 電話和手機應該為非必填，方便快速讓病患掛號 -->
            <input type="tel" appNumberIntegerOnly class="vis-input" formControlName="CellPhone" maxLength="10" />
            <app-validation-tip controlName="CellPhone"></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <div class="vis-form-field">
            <span class="required" i18n="@@ReceiveSMS">接收簡訊</span>
            <mat-radio-group formControlName="IsSMS" class="extend-label">
              <mat-radio-button [value]="true" i18n="@@ReceiveSMSYes">是</mat-radio-button>
              <mat-radio-button [value]="false" i18n="@@ReceiveSMSNo">否</mat-radio-button>
            </mat-radio-group>
            <app-validation-tip controlName="IsSMS"></app-validation-tip>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
        <mat-grid-tile colspan="4">
          <label class="vis-form-field">
            <span i18n="@@EMail">EMail</span>
            <input class="vis-input" formControlName="EMail" maxlength="50" />
            <app-validation-tip controlName="EMail"></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <div class="vis-form-field">
            <span class="required" i18n="@@ReceiveMessage">接收診所訊息</span>
            <mat-radio-group formControlName="IsAgreeMsg" class="extend-label">
              <mat-radio-button [value]="true" i18n="@@ReceiveMessageYes">是</mat-radio-button>
              <mat-radio-button [value]="false" i18n="@@ReceiveMessageNo">否</mat-radio-button>
            </mat-radio-group>
            <app-validation-tip controlName="IsAgreeMsg"></app-validation-tip>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan="2"></mat-grid-tile>
      </mat-grid-list>
      <label class="vis-form-field">
        <span i18n="@@RegisterAddress">戶籍地址</span>
      </label>
      <mat-grid-list cols="8" [rowHeight]="60" [gutterSize]="'5px'">
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@ZipCode">郵遞區號</span>
            <input class="vis-input" appNumberIntegerOnly formControlName="ResZipCode" #zipCodeRes maxlength="10" />
            <app-validation-tip controlName="ResZipCode"></app-validation-tip>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@City">縣市</span>
            <app-vis-dropdownlist class="vis-input" [data]="city" formControlName="ResCity"></app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Area">鄉鎮市區</span>
            <app-vis-dropdownlist class="vis-input" [data]="areaFilterdRes"
              formControlName="ResArea"></app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan=5>
          <label class="vis-form-field">
            <span i18n="@@Address">地址</span>
            <input class="vis-input" formControlName="ResStreet" maxlength="200" />
            <app-validation-tip controlName="ResStreet"></app-validation-tip>
          </label>
        </mat-grid-tile>
      </mat-grid-list>
      <div style="display:flex;width: 100%;margin-top: 10px;" class="extend-label">
        <div class="vis-form-field">
          <div i18n="@@ResidentialAddress">居住地址</div>
        </div>
        <div class="checkbox-label-ui">
          <mat-checkbox [checked]="isSameAddressCheck"
            [disabled]="!this.editFormGroup.enabled || !this.editFormGroup.value.ResStreet"
            [title]="this.editFormGroup.enabled&&!this.editFormGroup.value.ResStreet?'請先填戶籍地址':''"
            (change)="onChkSameAddressClick($event)">同戶籍地址</mat-checkbox>
        </div>
      </div>
      <mat-grid-list cols="8" [rowHeight]="60" [gutterSize]="'5px'" class="mb-2">
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@ZipCode">郵遞區號</span>
            <input class="vis-input" appNumberIntegerOnly formControlName="ZipCode" #zipCode maxlength="10" />
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@City">縣市</span>
            <app-vis-dropdownlist class="vis-input" [data]="city" formControlName="City"></app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Area">鄉鎮市區</span>
            <app-vis-dropdownlist class="vis-input" [data]="areaFilterd" formControlName="Area">
            </app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan=5>
          <label class="vis-form-field">
            <span i18n="@@Address">地址</span>
            <input class="vis-input" formControlName="Street" maxlength="200" />
          </label>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- <mat-divider></mat-divider> -->
      <app-patient-contact *ngIf="mainContact" [data]="mainContact" [patientForm]="this.editFormGroup" [title]="'主要聯絡人'"
        [enable]="editFormGroup.enabled" #contact></app-patient-contact>
      <button class="d-flex px-4 px-5 mb-0 vis-btn-primary vis-btn-capsule position-relative m-auto end-0 start-0"
        type="button" [disabled]="!this.editFormGroup.enabled" (click)="addContact()" title="新增一筆緊急聯絡人">
        <div class="position-relative top-2">緊急聯絡人</div>
        <mat-icon class="pref-icon add position-relative -top-2">person_add</mat-icon>
      </button>
      <ng-container *ngFor="let contact of contacts; let idx = index">
        <!-- <mat-divider></mat-divider> -->
        <app-patient-contact [data]="contact" [patientForm]="this.editFormGroup" [deletable]="true"
          (delete)="deleteContact(idx)" [title]="'緊急聯絡人'+(idx+1)" [enable]="editFormGroup.enabled">
        </app-patient-contact>
      </ng-container>

      <mat-grid-list cols="4" [rowHeight]="60" [gutterSize]="'5px'" class="mt-2">
        <mat-grid-tile colspan=4>
          <div style="width:100%;height: 100%;">
            <label class="vis-form-field mt-extent mt-2">
              <span i18n="@@Contact" class="card-header d-flex py-2 px-0 mt-1 card-header-gradation"><mat-icon
                  class="pref-icon btn-initial position-relative top-2 mr-1 ml-2 btn-scale-90">content_paste_search</mat-icon>醫療資訊</span>
            </label>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field bodyHeight">
            <span i18n="@@Height">身高</span>
            <input type="number" step="0.1" class="vis-input" style="width: calc(100% - 30px);"
              (change)="calculateBMI()" formControlName="Height" appNumberOnly [max]="250" [min]="1" />
            <span style="display:inline;width: 30px;">公分</span>
            <!--<app-vis-numerictextbox [min]="0" [max]="400" [autoCorrect]="true" [format]="'n1'" [decimals]="1" formControlName="Height"></app-vis-numerictextbox>-->
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Weight">體重</span>
            <input type="number" class="vis-input" style="width: calc(100% - 30px);" (change)="calculateBMI()"
              formControlName="Weight" appNumberOnly />
            <span style="display:inline;width: 30px;">公斤</span>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@BMI">身高體重指數(BMI)</span>
            <input class="vis-input" formControlName="BMI" appNumberOnly />
          </label>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@FirstVisit">初診日</span>
            <app-vis-datepicker formControlName="FirstDate" [max]="maxFirstVisitDate" [maxWidth]="false">
            </app-vis-datepicker>
          </label>
        </mat-grid-tile>

        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@LastVisit">最後來診日</span>
            <app-vis-datepicker formControlName="LastDate" [max]="maxFirstVisitDate"
              [maxWidth]="false"></app-vis-datepicker>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="w-100 checkbox-label-ui extend-label">
            <mat-checkbox style="float: right;" [disabled]="!this.editFormGroup.enabled" (change)="onHandicappChange($event)"
              [checked]="isTheHandiCappChecked">身障紀錄</mat-checkbox>
          </div>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@LastMenstrualPeriod">最後來經日</span>
            <app-vis-datepicker formControlName="LastMCDate" [max]="todayMax" [maxWidth]="false"></app-vis-datepicker>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <div style="width: 100%;" class="extend-label">
            <mat-checkbox style="float: right;" [labelPosition]="'before'" [checked]="isPregnent()"
              (change)="pregnantChange($event)"
              [disabled]="editFormGroup.controls.LastMCDate.disabled">是否懷孕</mat-checkbox>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@DueDate(Pregnancy)">預產日</span>
            <app-vis-datepicker formControlName="DueDate" [maxWidth]="false"></app-vis-datepicker>
          </label>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile colspan=2 rowspan=2>
          <div class="vis-form-field" style="height: 130px;">
            <span><span class="position-relative">藥物過敏</span>
              <mat-icon class="pref-icon top-2 vis-combine-icon text-xlg" type="button"
                (click)="onBtnDrugAllergyClick()" title="藥物過敏選項">settings</mat-icon>
            </span>
            <textarea class="vist-extarea" style="height: calc(100% - 30px);width: 100%;resize: none;"
              formControlName="DrugAllergy"></textarea>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan=2 rowspan=2>
          <div class="vis-form-field" style="height: 130px;">
            <span><span class="position-relative">特別記載</span>
              <mat-icon class="pref-icon vis-combine-icon text-xlg" type="button" (click)="onBtnRemarkClick()"
                title="特別記載選項">settings</mat-icon>
              <!-- <button type="button" [disabled]="!this.editFormGroup.enabled" class="inline-btn"
                (click)="onBtnRemarkClick()" i18n="@@PatientEditOptions">選項</button> -->
            </span>
            <textarea class="vist-extarea" style="height: calc(100% - 30px);;width: 100%;resize: none;"
              formControlName="Remark"></textarea>
          </div>
        </mat-grid-tile>
        <mat-grid-tile colspan=4 rowspan=1>
          <div style="width: 100%; height: 100%;" class="extend-label">
            <ng-container i18n="@@Remark">特殊身分</ng-container>
            <div style="display: flex;" class="checkbox-label-ui">
              <ng-container *ngFor="let item of editOptions.spcIdentity">
                <mat-checkbox [disabled]="!this.editFormGroup.enabled" [checked]="spcChecked(item.value)"
                  style="width:7.33rem;" (change)="spcChange(item.value,$event)">{{item.text}}</mat-checkbox>
              </ng-container>
            </div>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@BMI">重傷病卡片張數</span>
            <input class="vis-input" formControlName="MajorInjuryTimes" appNumberOnly />
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@FirstVisit">初次領重傷病卡日期</span>
            <app-vis-datepicker formControlName="MajorInjuryDate" [maxWidth]="false">
            </app-vis-datepicker>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@FirstDialysisDate">初次來診洗腎日期</span>
            <app-vis-datepicker formControlName="FirstDialysisDate" [maxWidth]="false">
            </app-vis-datepicker>
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan=4>
          <div style="width:100%;height: 100%;">
            <label class="vis-form-field mt-extend">
              <span i18n="@@Contact" class="card-header d-flex py-2 px-0 mt0 card-header-gradation"><mat-icon
                  class="pref-icon btn-initial position-relative top-2 mr-1 ml-2">settings_suggest</mat-icon>系統</span>
            </label>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@Status">病歷狀態</span>
            <app-vis-dropdownlist formControlName="StatusCode" [data]="getOption('C0130')" [textField]="'text'"
              [valueField]="'value'" [valuePrimitive]="true">
            </app-vis-dropdownlist>
          </label>
        </mat-grid-tile>
        <mat-grid-tile>
          <label class="vis-form-field">
            <span i18n="@@PatientsNameShortNo">姓名拼音碼</span>
            <input class="vis-input" formControlName="ShortNo" maxlength="10" />
          </label>
        </mat-grid-tile>
        <mat-grid-tile colspan=2></mat-grid-tile>
        <ng-container *ngIf="false">
          <mat-grid-tile>
            <label [hidden]="true" class="vis-form-field">
              <span i18n="@@ExtNo">院所自定病歷號</span>
              <input class="vis-input" formControlName="Kcstmr" maxlength="10" />
            </label>
          </mat-grid-tile>
          <mat-grid-tile>
            <label [hidden]="true" class="vis-form-field">
              <span i18n="@@MissTimes">預約爽約次數</span>
              <input class="vis-input" formControlName="MissTimes" />
            </label>
          </mat-grid-tile>
          <mat-grid-tile colspan=2></mat-grid-tile>
        </ng-container>
      </mat-grid-list>
    </form>
  </div>
</div>
<!-- 病患Drug Allergy選項視窗 -->
<app-shared-patients-drug-allergy *ngIf="editFormGroup" [isDialogOpened]="switchDrugAllergyDialog"
  [selectedText]="drugAllergySelectedText" (emitResult)="getDrugAllergyWindowResult($event)">
</app-shared-patients-drug-allergy>
<app-shared-patient-remark-dialog *ngIf="editFormGroup" [isDialogOpened]="switchPatientRemarkDialog"
  [selectedText]="remarkSelectedText" (emitResult)="getRemarkWindowResult($event)">
</app-shared-patient-remark-dialog>
<app-vis-dialog title="訂閱Ly notify通知" [width]="250" [height]="280" #qrcodeDialog>
  <div >
    <img #qrcodeImg>
  </div>
</app-vis-dialog>
<!-- print zone -->
<app-vis-dialog (close)="d.show=false" [fitContent]="true" #d [height]="'95%'" [maxWidth]="'1200px'" [superWidth]="'95%'" title="病歷基資預覽">
  <app-search-bar *ngIf="d.show">
      <button type="button"
        (click)="print(d)"
        class="vis-btn-config p-0 position-relative pl-2 w-18 m-1 mt-0"
        title="列印">
        <mat-icon class="pref-icon add vis-combine-icon">print</mat-icon>
        <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">列印</span>
      </button>
  </app-search-bar>
  <mat-divider></mat-divider>
  <div style="height:470px;" class="p-0 w-100">
      <ng-container>
          <app-vis-grid
            [noHeader]="true"
            class="small-paginater"
            [scroll]="false"
            [showStickyHeight]="470"
            [showStickyHead]="true"
            [data]="previewData"
            [customClass]="'overflow-y-hidden'">
              <app-vis-grid-column>
                  <ng-template>
                      <app-report-hist-index [data]="previewOrderList()"></app-report-hist-index>
                  </ng-template>
              </app-vis-grid-column>
          </app-vis-grid>
      </ng-container>
  </div>
</app-vis-dialog>

<div style="display:none">
  <app-report-hist-index #report></app-report-hist-index>
</div>

<app-vis-dialog title="代碼新增" [show]="showSetting" (close)="onHandleDone()" [width]="1200">

  <div class="row narrow-padding">
    <div class="col-sm-12">
      <div class="card" style="border:0px;margin-left: 2vh;margin-right: 1vh;">
        <div class="card-header">
          <form class="c-form-inline" style="display: flex;">
            <div style="flex-grow: 1;"></div>
              <button type="button" (click)="onShowImport()" i18n="@@Query"
                class="vis-btn-config p-0 position-relative pl-2 w-18" title="匯入"
                *appAllowPermissionIf="[this.UserPermissions.Add,'SYS']">
                <mat-icon class="pref-icon add vis-combine-icon">checklist</mat-icon>
                <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">匯入</span>
              </button>
              <button type="button" (click)="onAddRow()" i18n="@@Query"
                class="vis-btn-config p-0 position-relative pl-2 w-18" title="新增"
                *appAllowPermissionIf="[[this.UserPermissions.Add],'SYS',!isCanEdit]">
                <mat-icon class="pref-icon add vis-combine-icon">add_circle</mat-icon>
                <span class="position-absolute text-xs-sm start-0 ml-4 pl-2">新增</span>
              </button>

          </form>
        </div>
        <div class="card-body no-padding">
          <app-system-code-list-grid-view
            *ngIf="showSetting" [clinicId]="currentClinicId"
            [systemCodeType]="systemCodeTypeTarget" [systemTypeData]="systemTypeList"
            [importTargetSystemCode]="importTargetSystemCode" (onInit)="afterGridViewInit()"
            (onImportFinish)="onCloseImport()" (checkIsCanEdit)="onCheckIsEdit($event)">
          </app-system-code-list-grid-view>
        </div>
      </div>

    </div>
  </div>


</app-vis-dialog>



