import { RegisterEditDto } from "src/app/services/api-service/register/RegisterEditDto";

export class RegisterViewItem extends RegisterEditDto {
  constructor(data: RegisterEditDto) {
    super();
    Object.assign(this, data);
    this.PatientName = `${this.PatientFirstName || ''} ${this.PatientMiddleName || ''} ${this.PatientLastName || ''}`.trim().replace('  ', ' ');
  }
  PatientAge: number;
  PatientName: string;
}
