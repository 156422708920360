import { RegisterWaitingListQueryTypeEnum } from "src/app/enums/RegisterWaitingListQueryTypeEnum";
import { TimeSecEnum } from "src/app/enums/TimeSecEnum";
import { QueryParam } from "../../api-service/queryParam";
import { ReserveDTO } from "../reserve/reserve-api.model";
import { ReserveItem } from "../../reserve.service";
import { RegisterConst } from "src/app/shared/constants/register-const";

export class RegisterWaitingQueryDTO extends QueryParam {
  QueryDate: string;
  DoctorId?: number;
  TimeSec: TimeSecEnum;
  StatusCodes: string[];
  OtherQueryType: RegisterWaitingListQueryTypeEnum;
  OtherQueryCondition?: string;
  OrderBy: string = "";
  OrderDesc: boolean = false;
}

export class RegisterWaitingDTO {
  RegisterSummaryInfo: RegisterSummaryInfoDTO;
  RegisterWaitingItems: RegisterWaitingItemDTO[];
  RegisterReserveItems: RegisterReserveDTO[];

  constructor() {
    this.RegisterSummaryInfo = new RegisterSummaryInfoDTO();
    this.RegisterWaitingItems = [];
    this.RegisterReserveItems = [];
  }
}

export class RegisterSummaryInfoDTO {
  AppointmentCount: number;
  AppointmentCheckInCount: number;
  RegisterOnSiteCount: number;
  NotSpecifiedDoctorCount: number;
  SameDayNewPatientCount: number;
  FinishCount: number;
  TotalCount: number;

  constructor() {
    this.AppointmentCount = 0;
    this.AppointmentCheckInCount = 0;
    this.RegisterOnSiteCount = 0;
    this.NotSpecifiedDoctorCount = 0;
    this.SameDayNewPatientCount = 0;
    this.FinishCount = 0;
    this.TotalCount = 0;
  }
}

export class RegisterWaitingItemDTO {
  RegisterId: number;
  PatientNo: string;
  PatientName: string;
  PatientSex: string;
  PatientAge: string;
  ICode: string;
  TreatType: string;
  PatientRemark: string;
  SameDayRemark: string;
  DoctorId?: number;
  StatusCode: string;
  WaitNo: string;
  CheckInTime?: Date;
  EstimatedTime: string;
  RegTime: Date;
  EndTime?: Date;
  PeriodItems: RegisterPeriodItemDTO[];
  ClinicHistoryies: RegisterClinicHistoryDTO[];

  constructor(){
    this.PeriodItems = [];
    this.ClinicHistoryies = [];
  }

  /**
   * 是否可修改
   * @returns
   */
  get CanEdit() {
    var today = new Date();
    if (this.RegTime >= today &&
        (this.StatusCode === RegisterConst.Code_Status_Examining ||
        this.StatusCode === RegisterConst.Code_Status_Waiting ||
        this.StatusCode === RegisterConst.Code_Status_Appointment ||
        this.StatusCode === RegisterConst.Code_Status_Emergency ||
        this.StatusCode === RegisterConst.Code_Status_ReservedLater ||
        this.StatusCode === RegisterConst.Code_Status_RegNotPay ||
        this.StatusCode === RegisterConst.Code_Status_Queue1 ||
        this.StatusCode === RegisterConst.Code_Status_Finish)) {
        return true;
    } else {
        return false;
    }
}
}

export class RegisterReserveDTO {
  Id: number;
  RegisterId?: number;
  IsCheckedIn: boolean;
  PatientId: number;
  PatientNo: string;
  PatientName: string;
  PatientSex: string;
  PatientAge: string;
  PatientPhone: string;
  ReserveMatters: string;
  DoctorId: number;
  DoctorName: string;
  EstimatedTime: number;
  ReserveDateTime: Date;

  constructor(init?: Partial<RegisterReserveDTO>) {
    Object.assign(this, init);
  }

  static fromReserveItem(reserveItem:ReserveItem[]){
    let dtos = reserveItem.map(x=>{
      let dto = new RegisterReserveDTO();
      dto.Id = x.Id;
      dto.IsCheckedIn = x.IsCheckedIn;
      dto.PatientId = x.PatientId;
      dto.PatientNo = x.PatientNo;
      dto.PatientName = x.PatientCName;
      dto.PatientSex = x.SexName;
      dto.PatientAge = x.PatientAge;
      dto.PatientPhone = x.PatientPhone;
      dto.ReserveMatters = x.ReserveMatters;
      dto.DoctorId = x.DoctorId;
      dto.DoctorName = x.DoctorName;
      dto.EstimatedTime = x.EstimatedTime;
      dto.ReserveDateTime = x.RegDate;

      return dto;
    })

    return dtos;
  }
}

export class RegisterPeriodItemDTO {
  RxId: number;
  RxCode: string;
  RxName: string;
  PreviousDate: string;
  ExecutableDate: string

  constructor(init?: Partial<RegisterPeriodItemDTO>) {
    Object.assign(this, init);
  }
}

export class RegisterClinicHistoryDTO {
  HRxId: number;
  RxCode: string;
  RegDate: string;
  ToothPos: string;

  constructor(init?: Partial<RegisterClinicHistoryDTO>) {
    Object.assign(this, init);
  }
}
