import { Directive, HostListener, ElementRef, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercase]'
})
export class UppercaseDirective {

  constructor(private el: ElementRef, @Self() public ngControl: NgControl) {}

  @HostListener('input', ['$event']) onInputChange(event) {
    const upperValue = this.el.nativeElement.value.toUpperCase();
    this.el.nativeElement.value = upperValue; // 更新視覺上的值
    this.ngControl.control.setValue(upperValue); // 手動更新模型值
  }

}
