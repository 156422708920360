<style>
  @media print {
    body {
      margin: 0;
      color: #000;
    }

    .hist-cert-report table td,
    table th {
      border: 1px solid black;
      padding-left: 8px;
      padding-right: 5px;
      padding-bottom: 5px;
      padding-top: 5px;
    }
  }
</style>
<div class="a4-print-style" #reportContainer>
  <div class="hist-cert-report" style="background-color: white;height: 100%;width: 100%;">
    <div>
      <div style="text-align: center;font-weight: bolder; font-size: 20pt; ">
        {{Data?.Clinic?.Name}}
      </div>
      <div style="text-align: center;font-weight: bolder; font-size: 18pt; ">就　醫　證　明　書
      </div>

    </div>
    <div style="-webkit-text-size-adjust: none;font-size: 16pt;zoom: 0.8;margin: 0px 10px;">

      <div style="display: flex;column-gap: 5px; margin-top: 5px;">
        <table style="width: 100%; font-size: 16pt; text-align: left;">
          <tr>
            <td style="width: 143px; border-right: 0;">
              <span>姓　</span><span>　名：</span>
            </td>
            <td style="width: 25%;  border-right: 0;border-left: 0;">
              {{Data?.Patient?.CName}}
            </td>
            <td style="width: 100px; border-right: 0;border-left: 0;">
              性　別：
            </td>
            <td style="width: 25%; border-right: 0;border-left: 0;">
              {{Data?.Patient?.SexName}}
            </td>
            <td colspan="2" style="border-left: 0;">

            </td>
          </tr>
          <tr>
            <td style="border-right: 0;">
              <span>年　</span><span>　齡：</span>
            </td>
            <td style="border-right: 0;border-left: 0;">
              {{Data?.Patient?.Birthday|age}}
            </td>
            <td colspan="2" style="border-right: 0;border-left: 0;">
              {{Data?.Patient?.BirthdayStr}} 生
            </td>
            <td colspan="2" style="border-left: 0;">

            </td>
          </tr>
          <tr>
            <td style="border-right: 0;">
              身分證字號：
            </td>
            <td colspan="3" style="border-right: 0;border-left: 0;">
              {{Data?.Patient?.CId| CIdMask:cidMask}}
            </td>
            <td colspan="2" style="border-left: 0;">

            </td>
          </tr>
          <tr>
            <td style="border-right: 0;">
              <span>住　</span><span>　址：</span>
            </td>
            <td colspan="5" style="border-left: 0;">
              {{Data?.Patient?.City}}{{Data?.Patient?.Area}}{{Data?.Patient?.Street}}
            </td>
          </tr>
          <tr>
            <td style="border-right: 0;">
              <span>應</span><span style="margin-left: 10px;margin-right: 10px;">診</span><span>日：</span>
            </td>
            <td colspan="5" style="border-left: 0;">
              {{Data?.Register?.RegDateStr}}
            </td>
          </tr>
          <tr>
            <td colspan="4" style="border-right: 0;border-bottom: 0;">
              茲證明 {{Data?.Patient?.CName}} 於 民國{{Data?.Register?.RegDateStr}} 至本院就診
            </td>
            <td colspan="2" style="border-left: 0;border-bottom: 0;">

            </td>
          </tr>
          <tr>
            <td colspan="3"
              style="font-size: 24pt; text-align: center; border-right: 0;border-top: 0;border-bottom: 0;">
              <span>　特　</span><span>　此　</span><span>　證　</span><span>　明　</span>
              <!-- 特　此　證　明 -->
            </td>
            <td colspan="3" style="border-left: 0;border-top: 0; border-bottom: 0;">

            </td>
          </tr>
          <tr>
            <td colspan="4" style="border-right: 0;border-top: 0;border-bottom: 0; padding-bottom: 0;">
              <span>醫　</span>　師：{{Data?.Register?.DoctorName}} <br>
              院所地址：{{Data?.Clinic?.City}}{{Data?.Clinic?.Area}}{{Data?.Clinic?.Street}}<br>
              院所電話：{{Data?.Clinic?.Phone}}<br>
            </td>
            <td colspan="2" style="border-left: 0;border-top: 0; border-bottom: 0; padding-bottom: 0;">
            </td>
          </tr>
          <tr>
            <td colspan="3" style="border-right: 0;border-top: 0;border-bottom: 0; padding-top: 0;">
              中華民國{{Data?.PrintDateStr}}
            </td>
            <td style="text-align: center; border-left: 0;border-right: 0;border-top: 0; border-bottom: 0; padding-top: 0;">
              院章：
            </td>
            <td colspan="2" style="text-align: center; border-left: 0;border-top: 0; border-bottom: 0; padding-top: 0;">
            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>

</div>
