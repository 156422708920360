import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { HistService } from "../hist/services/hist.service";
import { MainLayoutService } from "../layout/services/main-layout.service";
import { AuthenticationService } from "../security/services/authentication.service";
import { EasyFormComponent } from "../shared/components/easy-form/easy-form.component";
import { ValidationTipComponent } from "../shared/components/validation-tip/validation-tip.component";
import { HistApi } from "./api-service/hist/hist-api";
import { ParameterApi } from "./api-service/parameters/parameter-api";
import { PostAreaAPI } from "./api-service/postarea-api";
import { TimeSectionRange } from "./api-service/register/time-section-range";
import { ScheduleApi } from "./api-service/schedule/schedule-api";
import { FullUser, UserApi } from "./api-service/user/user-api";
import { ChildGrowDataChartService } from "./child-grow-chart-service";
import { EasyFormService } from "./easy-form-service";
import { EasyNotificationService } from "./easy-notification.service";
import { MemoryCache } from "./memory-cache-service";
import { SessionService } from "./session.service";
import { UserCache } from "./user-cache";
import { UserConfirmService } from "./user-confirm.service";
import { WebApiService } from "./web-api.service";
import { WebApiClient } from "./web-api-client.service";
import { Schedule } from "../schedule/models/schedule";
import { FontGreatService } from "./font-great.service";
import { SignalRService } from "./signalr-service";
import { NullOrEmpty } from "../shared/utilities";
import { RegReserveService } from "../registers/reserve/reg-reserve-service";
import { HcrService } from "./hcr-service.service";
import { FormFieldCollection } from "../shared/components/easy-form/form-define";
import { SatellitePharmacyServiceService } from "./satellite-pharmacy-service.service";
import { ClinicDataService } from "./data-service/clinic-data-service";
import { LabApi } from "./api-service/labs/lab-api";
import { AlertOn, AnnounceAlertService } from "./announceAlert.service";
import { ReserveService } from "./reserve.service";
import { bulletinParameter, bulletinResponse, bulletinResult, ToccService } from "./tocc-service.service";
import { AnnouncementApi } from "./api-service/announcement/announcement-api";
import { Announcement } from "./api-service/announcement/announcement-model";
import { DateHelper } from "../shared/helpers/date-helper";
import { ClinicApi } from "./api-service/clinic/clinic-api";
import { DengueFormDto, ToccApi } from "./api-service/hist/tocc-api";

export declare type VPNState = 'Ok' | 'Slow' | 'Fail';

@Injectable({
  providedIn: 'root'
})
export class StartUpService {
  userData: FullUser;
  api: WebApiService;
  isHomeCare: boolean = UserCache.getLoginUser().Clinic.TypeIsHomeCare;
  isRecoveryHome: boolean = UserCache.getLoginUser().Clinic.TypeIsRecoveryHome;
  /**
   *
   */
  private isStart = false;
  constructor(
    private postAreaApi: PostAreaAPI,
    private webApiFactory: WebApiClient,
    private userApi: UserApi,
    private scheduleApi: ScheduleApi,
    private announcement: AnnounceAlertService,
    private paramApi: ParameterApi,
    private mainLayout: MainLayoutService,
    private userConfirm: UserConfirmService,
    private easyForm: EasyFormService,
    private notify: EasyNotificationService,
    private session: SessionService,
    private auth: AuthenticationService,
    private hist: HistApi,
    private cgcService: ChildGrowDataChartService,
    private fontGreatService: FontGreatService,
    private signalRService: SignalRService,
    private reserveService: RegReserveService,
    private hcrService: HcrService,
    private satelliteService: SatellitePharmacyServiceService,
    private clinicDataService: ClinicDataService,
    private labApi: LabApi,
    private dReserveService:ReserveService,
    private toccApi: ToccApi,
    private toccService: ToccService,
    private announcementApi: AnnouncementApi,
    private clinicApi: ClinicApi
  ) {
    this.api = this.webApiFactory.createHisService('schedule/shiftschedule');
    this.auth.logoutEvent.subscribe(() => {
      // 清除參數快取
      //this.paramApi.clearCache();
      // 清除們診選項快取
      this.hist.EditOpt = null;
      this.userData = null;
    });
  }
  async start() {
    console.log('系統啟動中')
    // 預先載入
    await this.Ping_VPN();

    // 這個項目裡面有Grid的設定，先預載
    await this.clinicDataService.getParam("SYS101");

    // 載入使用者資料
    await this.getUser();
    await this.checkPwdChange();
    // Task 12024.4 登錄時預設排班表展開功能去除 2023/06/09
    // if (!this.isHomeCare) {
    //     await this.checkMonthSchedule();
    // }
    await this.initByPosition();

    // 載入不分院所資料
    // 郵遞區號
    await this.postAreaApi.GetCityArea();
    // 載入兒童成長取線資料
    await this.cgcService.load();
    // 預約掛號設定
    await this.reserveService.init();
    await this.dReserveService.init();
    // 設定需要使用StartUpService的Service，未避免循環參考故不再對方進行注入
    this.fontGreatService.Init(this);
    this.signalRService.startConnection((s) => {

      this.signalRService.userLogin()
    });
    // await this.getAnnoucementAlert();
    await this.getTOCCBulletin().then(async () => {
      setTimeout(async () => {
        await this.getAnnoucementAlert();
      }, 1000);
    });
  }

  private async getUser() {
    var user = UserCache.getLoginUser();
    // Get User Data
    this.userData = await this.userApi.getFullUser(user.UserId);

    //服務專線後面加上客服分機
    var phone = await this.clinicApi.GetServicePhone(user.Clinic.Code);
    user.Clinic.CustomerServiceExt += phone ? ' ' + phone : '';
  }

  async initByPosition() {
    // 醫師 -- 都顯示
    // if (this.isDoctor()) {
    // 醫師預載入院內資料
    await this.hist.getEditOptions();
    await this.updateLabImport();
    // var phSelected = this.session.getPhar();
    // 登入後為空, null是無藥師
    // if (phSelected == undefined) {
    // if (UserCache.getLoginUser().Clinic.HasPharmacist) {
    await this.showSelectPhar();   // 不論有無藥師都要顯示，要讓院所勾選是否寫卡和衛星藥局
    // }
    // }
    // }
  }

  private isDoctor() {
    return this.userData.positions.find(p => p.value == '10' || p.value == '11');
  }

  private isPharm() {
    this.userData.positions.find(p => p.value == '40' || p.value == '41')
  }

  public async showSelectPhar() {
    var phSelected = this.session.getPhar();
    var p = await this.clinicDataService.getParam("REG001");
    var hst001 = await this.clinicDataService.getParam("HST001");

    var secRange = new TimeSectionRange(p);
    var defCardWite = this.session.getData('writeCardData') ?? hst001.IsWriteICDefault;
    var defUseSatellite = this.session.getData('useSatellite');
    var defUseEmr = this.session.getData('useEmr');
    var sec = TimeSectionRange.getSectionValue(secRange);
    var ph = await this.scheduleApi.GetPharmarcist(new Date(), sec);
    if (ph.length == 0) {
      ph.push({ text: '無藥師', value: 0 })
    }
    var hasSatelliteIP = (!hst001.SatellitePhamacyIP || hst001.SatellitePhamacyIP == ' ') ? false : true;
    var hasEmrPath = (!hst001.EMRXmlPath || hst001.EMRXmlPath == ' ') ? false : true;
    var formField: FormFieldCollection<{ ph: number, isWriteCard: boolean, useSatellite: boolean, useEmr: boolean }> = {};
    formField.ph = { label: '值班藥師', name: 'ph', type: 'dropdown', value: (phSelected != undefined && phSelected != null) ? phSelected : ph[0].value, data: ph, order: 1 };
    formField.isWriteCard = { label: '寫IC卡', name: 'isWriteCard', value: defCardWite == undefined ? true : defCardWite, type: 'check', order: 2 };
    formField.useSatellite = { label: '衛星藥局', name: 'useSatellite', value: hasSatelliteIP ? (defUseSatellite == undefined ? true : defUseSatellite) : hasSatelliteIP, type: 'check', order: 3 };
    formField.useEmr = { label: '電子病歷', name: 'useEmr', value: hasEmrPath ? (defUseEmr == undefined ? true : defUseEmr) : hasEmrPath, type: 'check', order: 4 };
    var formValue;
    var title = '設定';
    var msg = '請選擇值班藥師';
    var finalFormField;
    if (hasSatelliteIP) {
      if (hasEmrPath) {
        finalFormField = formField;
      } else {
        var ff: FormFieldCollection<{ ph: number, isWriteCard: boolean, useSatellite: boolean }> = {};
        ff.ph = formField.ph;
        ff.isWriteCard = formField.isWriteCard;
        ff.useSatellite = formField.useSatellite;
        finalFormField = ff;
      }
    } else {
      if (hasEmrPath) {
        var ff1: FormFieldCollection<{ ph: number, isWriteCard: boolean, useEmr: boolean }> = {};
        ff1.ph = formField.ph;
        ff1.isWriteCard = formField.isWriteCard;
        ff1.useEmr = formField.useEmr;
        ff1.useEmr.order = 3;
        finalFormField = ff1;
      } else {
        var ff2: FormFieldCollection<{ ph: number, isWriteCard: boolean }> = {};
        ff2.ph = formField.ph;
        ff2.isWriteCard = formField.isWriteCard;
        finalFormField = ff2;
      }
    }

    formValue = await this.easyForm.show({
      title: title,
      msg: msg,
      fields: finalFormField
    });

    this.session.setPhar(formValue.ph == 0 ? null : formValue.ph);
    this.session.setData('writeCardData', formValue.isWriteCard);
    this.session.setData('useSatellite', (formValue.useSatellite == null || formValue.useSatellite == undefined) ? false : formValue.useSatellite);
    this.session.setData('useEmr', (formValue.useEmr == null || formValue.useEmr == undefined) ? false : formValue.useEmr);
  }

  public async checkPwdChange() {
    if (this.userData.needChangePwd) {
      await this.showChangePwd('尚未設定密碼，請更新您的密碼。')
    }
  }

  public async showChangePwd(msg: string = '') {
    var ret = await this.easyForm.show<{ oldPwd: string, newPwd: string, confirmPwd: string }>({
      title: '更改密碼',
      msg: msg,
      tip: '密碼為至少六碼以上，且包含英文與數字',
      beforeSubmit: (async (data) => {
        try {
          await this.userApi.changePwd(data.oldPwd, data.newPwd)
          return true;
        } catch (ex) {
          var msg = ex?.message;
          this.notify.showError(NullOrEmpty(msg) ? ex : msg);
          return false;
        }
      }).bind(this),
      fields: {
        oldPwd: {
          label: '目前密碼',
          name: 'oldPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 1
        },
        newPwd: {
          label: '新密碼',
          name: 'newPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 2,
          validator: [Validators.pattern(/^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/)]
        },
        confirmPwd: {
          label: '確認密碼',
          name: 'confirmPwd',
          type: 'pwd',
          value: '',
          required: true,
          order: 3,
          validator: [(c) => {
            if (!c.value) {
              //只顯示必填
              return null
            }
            var er = Validators.pattern(/^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/)(c);
            if (er) {
              // 只顯示格式不符
              return er;
            } else {
              var newV = c.parent.get('newPwd');
              if (c.value == newV.value) {
                return null;
              } else {
                return { custom: '與新密碼不一致' }
              }
            }
          }]
        }
      }
    })
  }

  async checkMonthSchedule() {
    var hasMonth = await this.scheduleApi.HasMonthSchedule(new Date());
    // 取得周排班資料
    var weeklyData: Schedule[] = await this.api.get('GetWeekly').toPromise();
    if (hasMonth == false) {
      var msgText = "查無包含今日之後的月排班資料。\n請選擇欲從周排班進行轉換的時間範圍";  // 無月排班但有周排班
      if (weeklyData.length <= 0) {
        msgText = "查無包含今日之後的月排班資料。\n請先建立排班資料";  // 無月排班也無周排班
      }
      var opts = await this.scheduleApi.GetData();
      await this.easyForm.show<{ startDate: Date, endDate: Date }>({
        title: '是否建立月排班',
        msg: msgText,
        fields: {
          startDate: {
            label: '開始日期',
            type: 'date',
            value: new Date(),
            name: 'startDate',
            required: true,
            order: 1,
            validator: [
              ValidationTipComponent.MaxDate(new Date(opts.lastGenMonthDate)),
              ValidationTipComponent.MinDate(new Date())
            ],
          }, endDate: {
            label: '結束日期',
            type: 'date',
            value: new Date(opts.lastGenMonthDate),
            name: 'endDate',
            required: true,
            order: 2,
            validator: [
              ValidationTipComponent.MaxDate(new Date(opts.lastGenMonthDate)),
              ValidationTipComponent.MinDate(new Date())
            ],
          }
        },
        showBtnCancel: true,
        beforeSubmit: async (data) => {
          try {
            await this.scheduleApi.GenMonthFromWeek(data.startDate, data.endDate);
            this.notify.showSuccess('月排班建立完成');
          } catch (ex) {
            this.notify.showError(ex)
          }
          return true;
        }
      })
    }
  }

  vpnState: VPNState = 'Fail';
  pingRs: (boolean) => void;
  pingTimeout = null;
  lastTryDt: Date = null;
  reloadAngle = 0;
  Ping_VPN(): Promise<boolean> {

    if (this.pingTimeout != null) {
      throw '正在等候結果';
    }
    if (this.lastTryDt != null && (new Date().getTime() <= (this.lastTryDt.getTime() + 5000))) {
      throw '操作時間過近';
    }
    this.lastTryDt = new Date();
    var p = new Promise<boolean>((rs, rj) => {
      this.pingRs = rs;
    })
    var ip = 'medvpn.nhi.gov.tw/'
    var img = new Image();
    img.onload = async () => {
      await vpnSuccess();
    };
    img.onerror = async () => {
      await vpnSuccess();
    };

    var st = new Date();
    var vpnSuccess = async () => {
      this.vpnState = 'Ok';
      if (this.pingTimeout != null) {
        clearTimeout(this.pingTimeout);
        this.pingTimeout = null;
      }
      var sec = (new Date().getTime() - st.getTime());
      if (sec > 3000) {
        this.vpnState = 'Slow';
      }
      this.pingRs(true);
      await this.hcrService.VisionApi.VerifySAMDC();
    }

    this.pingTimeout = setTimeout(async () => {
      console.log(new Date().toLocaleTimeString(), 'VPN Time Out')
      console.log('Ping_VPN timeout')
      this.vpnState = 'Fail';
      this.pingRs(false);
      var ret = await this.userConfirm.showConfirm(
        {
          title: '警告！！！',
          msg: '無法連線至健保VPN，請重試一次或者檢查您的網路環境。',
          textYes: '重試',
          textNo: '確認'
        })
      img.onload = null;
      img.onerror = null;
      img.src = '';
      this.pingTimeout = null;
      if (ret) {
        this.Ping_VPN();
      }
    }, 5000);
    setTimeout(() => {
      img.src = "https://" + ip;
    }, 0);

    return p;
  }

  async updateLabImport() {
    try {
      var ret = await this.labApi.ImportLab('');
      if (ret.ImportCount) {
        await this.userConfirm.showAlert('匯入結果', `新取得 ${ret.ImportCount} 筆匯入資料。\n更新 ${ret.LabForms.length} 筆檢驗報告。`)
      }
    } catch (e) {
      this.notify.showErrorWithPrefix('檢驗匯入資料取得失敗：', e,false, false);
    }
  }
  async getAnnoucementAlert() {
    await this.announcement.init();
    await this.announcement.alertOnLogin();
  }

  async getTOCCBulletin() {
    var isEnabledTOCC = await this.toccService.isEnabledTOCC(this.vpnState);
    if (isEnabledTOCC) {
      var cln = UserCache.getLoginUser().Clinic;
      var parameter: bulletinParameter = {
        regInstitutionCode: cln.NHICode
      };
      var dengueFormDto = new DengueFormDto();
      dengueFormDto.ClinicId = cln.Id;
      dengueFormDto.ClinicCode = cln.Code;
      dengueFormDto.PatientNo = '';
      dengueFormDto.PatientId = 0;
      dengueFormDto.QueryTime = new Date();
      dengueFormDto.RegInstitutionCode = cln.NHICode;
      dengueFormDto.RegInstitutionName = cln.Name;

      try {
        var [retResult, retParameter, retApiUrlType] = await this.toccService.getBulletin(parameter);

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        var bulletin = (retResult ? JSON.parse(retResult) as bulletinResponse : null);
        if (bulletin != null) {
          var toccMessage = bulletin.message ? bulletin.message : '';

          dengueFormDto.QueryReturnCode = bulletin?.code;
          dengueFormDto.QueryReturnMessage = toccMessage;

          if (bulletin.code != 200) {
            this.notify.showError('TOCC讀取公告失敗: ' + '(' + bulletin.code.toString() + ')' + toccMessage);
          } else {
            var anyPublishInstitution: boolean[] = [];
            var anyPublishPatient: boolean[] = [];
            await Promise.all(bulletin.result.map(async (b) => {
              var [isPublishInstitution, isPublishPatient] = await this.createTOCCAnnouncement(b);
              anyPublishInstitution.push(isPublishInstitution);
              anyPublishPatient.push(isPublishPatient);
            })).then(async () => {
              if ((anyPublishInstitution.length == 0 && anyPublishPatient.length == 0) ||
                (anyPublishInstitution.every(x => x === false) && anyPublishPatient.every(x => x === false))) {
                await this.expireTOCCAnnouncement('05,06');
              } else {
                if (anyPublishInstitution.every(x => x === false)) await this.expireTOCCAnnouncement('05');
                if (anyPublishPatient.every(x => x === false)) await this.expireTOCCAnnouncement('06');
              }
            });
          }
        } else {
          dengueFormDto.QueryReturnCode = -1;
          dengueFormDto.QueryReturnMessage = 'TOCC讀取公告失敗';

          this.notify.showError(dengueFormDto.QueryReturnMessage);
        }
      } catch (ex) {
        var [retResult, retParameter, retApiUrlType] = Array.isArray(ex) ? ex : [ex, null, null];

        dengueFormDto.ApiUrlType = retApiUrlType;
        dengueFormDto.QueryParameter = retParameter;
        dengueFormDto.QueryResponse = retResult;

        dengueFormDto.QueryReturnCode = -99;
        dengueFormDto.QueryReturnMessage = 'TOCC讀取公告發生錯誤: ' + retResult;

        this.notify.showError(dengueFormDto.QueryReturnMessage);
      } finally {
        try {
          await this.toccApi.CreateDengueForm(dengueFormDto);
        } catch (ex) {
          // this.notify.showError('TOCC建立紀錄發生錯誤: ' + ex);
        }
      }
    }
  }

  async createTOCCAnnouncement(result: bulletinResult) {
    if (!result) return [null, null];

    var cln = UserCache.getLoginUser().Clinic;
    var clinicCode = cln.NHICode;
    var clinicAddr = (cln.City??'') + (cln.Area??'') + (cln.Street??'');
    var isPublishInstitution = false;
    var isPublishPatient = false;
    // 0:全部發佈, 註：原TOCC政令，依照上述條件顯示於登入頁或診間首頁
    // 1:指定醫事機構行政區, 註：同上
    // 2:指定醫事機構, 註：同上
    // 3:指定病患通訊地址行政區域, 註：原TOCC警示，顯示於病歷製作頁；病人地址或機構地址符合條件就要顯示
    if (result.type == '0') {
      isPublishInstitution = true;
    } else if (result.type == '1' && result.institutionAreaList && result.institutionAreaList.length > 0) {
      var toccAreas = result.institutionAreaList.filter(x => x);
      if (toccAreas.some(x => clinicAddr.indexOf(x) >= 0)) {
        isPublishInstitution = true;
      }
    } else if (result.type == '2' && result.institutionList && result.institutionList.length > 0 && clinicCode) {
      if (result.institutionList.some(x => x == clinicCode)) {
        isPublishInstitution = true;
      }
    } else if (result.type == '3' && result.patientAreaList && result.patientAreaList.length > 0) {
      //先存起來，等看診時才判斷病患地址
      isPublishPatient = true;
    }

    if (result.institutionExcludeList && result.institutionExcludeList.length > 0 &&
      result.institutionExcludeList.some(x => x == clinicCode)) {
        isPublishInstitution = false;
        isPublishPatient = false;
    }

    if (isPublishInstitution || isPublishPatient) {
      var announcement = new Announcement();
      announcement.CompanyId = Number(cln.CompanyId);
      announcement.TypeCode = 'IMP';
      announcement.ClassCode = isPublishInstitution ? '05' : '06';//TOCC政令/TOCC警示
      announcement.Title = '高雄市衛生局TOCC' + (isPublishInstitution ? '政令' : '警示');
      announcement.Content = result?.content ?? '';
      if (announcement.Content) {
        var addition = '';
        if (isPublishPatient) {
          addition += '病患通訊地址行政區域: ' + result.patientAreaList.join(',') + '\n';
        }
        if (result.updateTime) {
          addition += '更新時間: ';
          if (!isNaN(Date.parse(result.updateTime))) {
            addition += DateHelper.formatROCDateTime(result.updateTime, false, false) + ' ' + DateHelper.getTimeString(new Date(result.updateTime), ':', true) + '\n';
          } else {
            addition += result.updateTime + '\n';
          }
        }
        addition += '高雄市政府衛生局\n';

        announcement.Content += '\n\n' + addition;
      }
      announcement.StartTime = DateHelper.today;
      announcement.EndTime = DateHelper.addMonthDay(DateHelper.today, 6);
      var alertOn: AlertOn[] = [];
      if (isPublishInstitution) {
        alertOn.push({ AlertOn: 'LOGIN', AlertCondition: '', AlertConditionCol: '', AlertConditionOp: '', AlertConditionValue: '' });
      } else {
        alertOn.push({ AlertOn: 'OPD', AlertCondition: 'patient', AlertConditionCol: 'street', AlertConditionOp: 'some', AlertConditionValue: result.patientAreaList.join(',') });
      }
      announcement.AlertOn = JSON.stringify(alertOn);
      try {
        //dataScope: 0全部, 1院所公告, 2系統公告(全院所)
        await this.announcementApi.CreateSpecific(announcement, [], 1);
      }
      catch (ex) {
        this.notify.showError('建立TOCC公告發生錯誤: ' + ex);
        return [null, null];
      }
    }

    return [isPublishInstitution, isPublishPatient];
  }

  async expireTOCCAnnouncement(classCodes: string) {
    var cln = UserCache.getLoginUser().Clinic;
    var announcement = new Announcement();
    announcement.CompanyId = Number(cln.CompanyId);
    announcement.ClassCode = classCodes;
    try {
      await this.announcementApi.ExpireSpecific(announcement, [], 1);
    }
    catch (ex) {
      this.notify.showError('過期TOCC公告發生錯誤: ' + ex);
    }
  }

}

