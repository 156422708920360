import { Pipe, PipeTransform } from '@angular/core';
import { FilesizeHelper } from 'src/app/shared/helpers/filesize-helper';

@Pipe({
  name: 'fileSizeUnit'
})
export class FileSizeUnitPipe implements PipeTransform {
  transform(size: number, extension: string = ''): any {
    let result = FilesizeHelper.fileSizeKB(size);
    if (result < 1024) {
      return result.toString() + (extension != '' ? extension : ' KB');
    }
    else {
      result = FilesizeHelper.fileSizeMB(size);
      if (result < 1024) {
        return result.toString() + (extension != '' ? extension : ' MB');
      }
      else {
        result = FilesizeHelper.fileSizeGB(size);
        if (result < 1024) {
          return result.toString() + (extension != '' ? extension : ' GB');
        }
        else {
          result = FilesizeHelper.fileSizeTB(size);
          return result.toString() + (extension != '' ? extension : ' TB');
        }
      }
    }
  }
}

@Pipe({
  name: 'fileSizeKB'
})
export class FileSizeKBPipe implements PipeTransform {
  transform(size: number, digit: number = 0, extension: string = ' KB'): any {
    return FilesizeHelper.fileSizeKB(size, digit).toString() + extension;
  }
}

@Pipe({
  name: 'fileSizeMB'
})
export class FileSizeMBPipe implements PipeTransform {
  transform(size: number, digit: number = 1, extension: string = ' MB'): any {
    return FilesizeHelper.fileSizeMB(size, digit).toString() + extension;
  }
}

@Pipe({
  name: 'fileSizeGB'
})
export class FileSizeGBPipe implements PipeTransform {
  transform(size: number, digit: number = 1, extension: string = ' GB'): any {
    return FilesizeHelper.fileSizeGB(size, digit).toString() + extension;
  }
}

@Pipe({
  name: 'fileSizeTB'
})
export class FileSizeTBPipe implements PipeTransform {
  transform(size: number, digit: number = 1, extension: string = ' TB'): any {
    return FilesizeHelper.fileSizeTB(size, digit).toString() + extension;
  }
}

