import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, empty, of, throwError } from 'rxjs';
import { BaseConfig } from 'src/app/services/base-config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserCache } from 'src/app/services/user-cache';
import { WebApiClient } from 'src/app/services/web-api-client.service';
import { WebApiService } from 'src/app/services/web-api.service';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { CryptoService } from 'src/app/services/crypto-service';
import { UserApi } from 'src/app/services/api-service/user/user-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  // 系統參數
  count = 0;
  timer: any;
  //authUrl = 'system/authenticate';
  authUrl = 'api/Auth';

  api: WebApiService;

  logoutEvent = new EventEmitter();
  constructor(private http: HttpClient,
    private router: Router,
    private crypto: CryptoService,
    private userApi: UserApi,
    private webApiFactory: WebApiClient,
    private clinicDataService: ClinicDataService) {
    if (this.isLoginAnyCompany()) {
      this.onAutoLogoutTimer();
    }
  }
  getOption(username: string, password: string, companyCode: string, clinicCode: string, devkey: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': devkey,
        'Token': '',
        'Account': username,
        'Password': this.crypto.encrypt(password),
        'CompanyCode': companyCode,
        'ClinicCode': clinicCode
      }), observe: 'response' as 'body'
    };
  }

  getOptionForLoginDirectly(companyCode: string, userId: string, clinicId: string, authKey: string, devkey: string): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': devkey,
        'Token': '',
        'UserId': userId,
        'CompanyCode': companyCode,
        'ClinicId': clinicId,
        'AuthKey': authKey,
      }), observe: 'response' as 'body'
    };
  }

  getClinics(companyCode: string, account: string): Observable<any> {
    if (!companyCode || !account) {
      return of();
    }
    const config = BaseConfig.getConfig();

    return this.http.get<any>(config.webApi.visionAuthUrl + this.authUrl +
      '/GetClinics?companyCode=' + companyCode + '&account=' + account, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': config.webApi.hisXDevKey,
        'Token': '',
        'Account': '',
        'Password': '',
        'CompanyCode': companyCode,
        'ClinicId': ''
      })
      // , observe: 'response' as 'body'
    });
  }

  getCompanyName(companyCode: string): Observable<any> {
    if (!companyCode) {
      return of();
    }
    const config = BaseConfig.getConfig();

    return this.http.get<any>(config.webApi.visionAuthUrl + this.authUrl +
      '/GetCompanyName?companyCode=' + companyCode, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-DEVKEY': config.webApi.hisXDevKey,
        'Token': '',
        'Account': '',
        'Password': '',
        'CompanyCode': companyCode,
        'ClinicId': ''
      })
      // , observe: 'response' as 'body'
    });
  }

  loginDirectly(userId: string, companyCode: string, clinicId: string, authKey: string): Observable<boolean> {
    const config = BaseConfig.getConfig();
    const options = this.getOptionForLoginDirectly(companyCode, userId, clinicId, authKey, config.webApi.hisXDevKey);
    return this.http.get<any>(config.webApi.hisBaseUrl + this.authUrl + '/loginDirectly', options)
      .map((res: any) => {
        if (res.headers.get('token') !== null) {
          UserCache.setLoginUser(res.body);
          this.onAutoLogoutTimer();
          return true;
        } else {
          UserCache.clearLoginUser();
          return false;
        }
      },
        error => {
          UserCache.clearLoginUser();
          return false;
        }
      );
  }
  async login(username: string, password: string, companyCode: string, clinic: Clinic, force: boolean, isAutoLoginClinic: boolean)//: Observable<boolean>
  {
    const config = BaseConfig.getConfig();
    const options = this.getOption(username, password, companyCode, clinic.Code, config.webApi.hisXDevKey);
    try {
      var res: HttpResponse<any> = await this.http.get<any>(config.webApi.visionAuthUrl + this.authUrl + '?force=' + force + '&isAutoLoginClinic=' + isAutoLoginClinic, options).toPromise<any>();
      if (res.headers.get('token') !== null) {
        res.body.Clinic = clinic;
        UserCache.setLoginUser(res.body);
        // console.log('Longin',res.body);
        this.onAutoLogoutTimer();
        this.userApi.login()
        return true;
      } else {
        UserCache.clearLoginUser();
        return false;
      }
    } catch (error) {
      UserCache.clearLoginUser();
      throw error;
      //return false;
    }
  }

  onAutoLogoutTimer() {
    var exp = UserCache.getLoginUser().ExpiresOn;
    const expiredOn = new Date(exp);
    let ms = expiredOn.getTime() - new Date().getTime();
    // 如果local的expiredOn過期, 5秒後更新
    if (ms <= 0) {
      ms = 5000;
    }
    // 再expiredOn的時間左右去更新expiredOn
    this.timer = setTimeout(() => {
      this.updateToken().subscribe(r => {
        // 更新成功等待下次檢查
        if (r) {
          this.onAutoLogoutTimer();
        } else {
          // 更新失敗(找不到token)則直接登出
          this.logout();
        }
      }, error => {
        this.logout();
      });
    }, ms);
  }
  updateToken() {
    const config = BaseConfig.getConfig();
    const loginUser = UserCache.getLoginUser();
    if (!loginUser.AuthToken) {
      return of();
    }
    return this.http.get<any>(config.webApi.visionAuthUrl + this.authUrl + '/Update',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'X-DEVKEY': config.webApi.hisXDevKey,
          'Authorization': 'Bearer ' + loginUser.AuthToken,
          //'Account': '',
          //'Password': '',
          //'CompanyCode': loginUser.CompanyCode,
          //'ClinicId': loginUser.ClinicId
        })
      }).map(r => {
        // server的expired時間還沒過期則回存
        if (new Date(r) > new Date()) {
          loginUser.ExpiresOn = r;
          UserCache.setLoginUser(loginUser);
          return true;
        } else {
          return false;
        }
      },
        error => {
          return false;
        });
  }
  async logout(companyCode = '') {
    const config = BaseConfig.getConfig();
    const loginUser = UserCache.getLoginUser();
    //清除快取
    this.clinicDataService.clearCache();

    if (!companyCode) {
      companyCode = loginUser.CompanyCode;
    }
    if (!loginUser || !loginUser.AuthToken) {
      // 登出時把timer清除
      clearTimeout(this.timer);
      UserCache.clearLoginUser();
      await this.router.navigate([companyCode]);
      window.location.reload()
      return;
    }
    var header = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-DEVKEY': config.webApi.hisXDevKey,
      'Token': loginUser.AuthToken,
      'Account': '',
      'Password': '',
      'CompanyCode': '',
      'ClinicId': ''
    });
    try {
      this.http.get<any>(config.webApi.visionAuthUrl + this.authUrl + '/logout',
        {
          headers: header
        }).toPromise();

      clearTimeout(this.timer);
      UserCache.clearLoginUser();
      this.logoutEvent.emit();

      if (!companyCode) {
        await this.router.navigate(['']);
      } else {
        await this.router.navigate([companyCode]);
      }
    } catch (error) {
      const companyCode = loginUser.CompanyCode;
      clearTimeout(this.timer);
      UserCache.clearLoginUser();
      if (!companyCode) {
        await this.router.navigate(['']);
      }
      await this.router.navigate([companyCode]);
    }
    window.location.reload()
  }

  changeClinic(id: number): Observable<boolean> {
    this.api = this.webApiFactory.createHisService('system/authenticate');
    return this.api.get('ChangeClinic?clinicId=' + id)
      .map((res: Clinic) => {
        if (!res) {
          return false;
        }
        return UserCache.changeClinic(res);
      },
        error => {
          return false;
        }
      );
  }

  // 是否登入某一間公司，也許不是目前公司
  public isLoginAnyCompany(): boolean {
    return !UserCache.getLoginUser().isExpired;
  }
  // 是否登入某一間公司
  public isLoginToCompany(companyCode: string): boolean {
    if (!companyCode) {
      return false;
    }

    // 沒有任何登入
    if (UserCache.getLoginUser().isExpired) {
      return false;
    }

    // 檢查登入單位與目前路由單位是否一致
    const authCompanyCode = UserCache.getLoginUser().CompanyCode;
    if (authCompanyCode && companyCode.toLowerCase() === authCompanyCode.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  }
}
