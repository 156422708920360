import { UserCache } from 'src/app/services/user-cache';
import { ICNoPHOption, RegisterApi } from '../../services/api-service/register/register-api';
import { PaymentInfoDto } from '../../services/api-service/register/PaymentInfoDto';
import { Injectable } from "@angular/core"
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { PatientApi } from 'src/app/services/api-service/patient/patient-api';
import { ValueTextPair } from 'src/app/shared/models/value-text-pair';
import { DeclareApi } from 'src/app/services/api-service/declare/declare-api';
import { CustomResult } from 'src/app/shared/models/custom-result';
import { RegisterConst } from 'src/app/shared/constants/register-const';

export class SameTreatAction {
  msg: string = '';
  action: 'confirm' | 'no' | 'alert' = 'no';
  yesText: string;
  noText: string;
  nhictp: string = '';
  keepSameTreat = false;
  constructor(nhictp: string) {
    this.nhictp = nhictp;
  }
  SameTreat(): SameTreatAction {
    this.action = 'confirm';
    this.msg = '是否為同療程第一次 ?\n【是】，為第一次療程；【否】，為第二次療程以後'
    this.yesText = '是';
    this.noText = '否';
    return this;
  }
  NoAction(): SameTreatAction {
    this.action = 'no';
    return this;
  }
  Alert(msg: string): SameTreatAction {
    this.action = 'alert';
    this.msg = msg;
    return this;
  }
  // 動作完成以後不再修正同僚選項
  KeepSameTreat():SameTreatAction {
    this.keepSameTreat = true;
    return this;
  }
}
@Injectable({
  providedIn: 'root'
})
export class OpHelper {

  constructor(
    private registerApi: RegisterApi,
    private patientApi: PatientApi,
    private declareApi: DeclareApi
  ) {

  }
  /**
      *判斷是否欠費
      *
      * @memberof OperationCenter
      */
  async makeArrears(clinicId: number, cId: string) {
    var date = new Date();
    var thirtyDaysAgo = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    var samemonth = date.getMonth() === thirtyDaysAgo.getMonth();
    var datereq = DateHelper.formatDateTime(date);
    var checkDecale = await this.declareApi.CheckDeclareXmlLog(
      UserCache.getLoginClinicId(),
      DateHelper.getFormatedDateString(thirtyDaysAgo, false),
      false
  );
  if (checkDecale)
  {
    let firstDayOfNextMonth = new Date(thirtyDaysAgo.getFullYear(), thirtyDaysAgo.getMonth() + 1, 1);
    datereq = DateHelper.formatDateTime(firstDayOfNextMonth);
  }
  else if (checkDecale && samemonth)
  {
    return new CustomResult(false,'',[]);
  }
  else  datereq = null // 後端會自己處理
    var result = await this.registerApi.getArrearsData(clinicId, cId, datereq);
    return result
  }
  /**
   * 健保卡身分註記代碼對應到HIS保險身分代碼
   */
  InsurerFlagMapToHisICode(insurerFlag: string): ValueTextPair {
    let iCode: ValueTextPair;
    // 1->003福保、2->004榮民、3->A健保、8->009災民
    switch (insurerFlag) {
      case "1":
        iCode = {
          value: "003",
          text: "福保"
        }
        break;
      case "2":
        iCode = {
          value: "004",
          text: "榮民"
        }
        break;
      case "3":
        iCode = {
          value: "A",
          text: "健保"
        }
        break;
      case "8":
        iCode = {
          value: "009",
          text: "災民"
        }
        break;
      default:
        iCode = {
          value: "",
          text: ""
        }
        break;
    }

    return iCode;
  }



  /**
  * 取得付費資訊
  */
  // async GetRegisterPaymentInfo(isFirstVisit: string, discountCode: string, itype: string, icode: string, sameTreat: string,doctorId:number = 0) {
  //   //var ICode: string = isCard || abCode?.length > 0 ? 'A' : '000']
  //   console.log('getChargePaymentInfo',itype);
  //   var result: PaymentInfoDto =
  //     await this.registerApi.GetRegisterPaymentInfo(
  //       UserCache.getLoginUser().Clinic.Id,
  //       isFirstVisit,
  //       discountCode,
  //       itype,
  //       icode,
  //       sameTreat,
  //       doctorId
  //     );
  //   return result
  // }

  /** 取得掛號提醒訊息/狀態
   *
   */
  GetRemark(isFirst: boolean = false, isCard: boolean = false, isICData: number = 0, isEdit: number = 0, abCode: string = undefined, iType: string = "", isReCardDate:boolean = false) {

    var msg = ''

    if (isCard && isEdit == 1) {
      msg = `可退掛，但卡片寫入無法抹除。`
      return msg
    }
    if (isCard && isICData == 0 && !abCode) {
      msg = `★無IC卡資料可點選[卡序]讀卡★。`
      return msg
    }
    if (isCard && !abCode) {
      msg = `預讀卡序，參考最近就醫紀錄。`
      if (isFirst)
        msg = `初診${msg}`
      return msg
    }
    if (abCode) {
      msg = `以IC卡異常代碼${abCode}掛入。`
      if (abCode == 'F00B')
        msg = `巡診${msg}`
      return msg
    }

    if (iType=="3") {
      msg = `欠卡`;
      if(isReCardDate){
        msg = `${msg}，已還卡`;
      }
      if (isFirst) {
        msg = `初診${msg}`;
      }
      return msg;
    }

  }

  /**檢查是否可刪除掛號 (預約取消)
   * 過診不可取消預約
   */
  ReserveCanCancel(dataDate: Date, dataTsec: number, currentTsec: number) {
    if (dataDate < DateHelper.today)
      return false
    if (dataTsec < currentTsec)
      return false

    return true
  }

  /**檢查是否可刪除掛號 (現場掛號取消)
  * 現場掛號後24h內均可刪除,不論是否已完診
  * 跨年不可退掛
  */
  RegisterCanCancel(dataDate: Date) {
    var toDayNow = new Date();
    // var diffY = DateHelper.today.getFullYear() - new Date(dataDate).getFullYear();
    var diffY = toDayNow.getFullYear() - new Date(dataDate).getFullYear();
    if (diffY > 0) return false;

    // var diffT = DateHelper.today.getTime() - new Date(dataDate).getTime();
    var diffT = toDayNow.getTime() - new Date(dataDate).getTime();
    var diffDs = diffT / (1000 * 3600);
    if (diffDs > 24) return false;

    return true;
  }

  TransChineseDate(sourceDate: Date): string {

    var YYY = new Date(sourceDate).getFullYear() - 1911
    var MM = new Date(sourceDate).getMonth()
    var DD = new Date(sourceDate).getDate()

    return `${(Array(3).join('0') + YYY.toString()).slice(-3)}${(Array(2).join('0') + MM.toString()).slice(-2)}${(Array(2).join('0') + DD.toString()).slice(-2)}`
  }

  SwitchMedicalType(vCode: string): string {
    var char = vCode.substring(0, 1);
    var result = '';
    switch (char) {
      case 'W': //西醫
        result = '01'
        break;
      case 'D': //牙醫
        result = '02'
        break;
      case 'C': //中醫
        result = '03'
        break;
    }
    return result
  }

  SameTreatDesc(type: string, isCard: boolean): SameTreatAction {
    var ret: SameTreatAction = new SameTreatAction('').NoAction();
    switch (type) {
      
      case RegisterConst.SameTreat_0_洗腎:
        ret = new SameTreatAction('AB');
        ret.action = 'confirm';
          ret.msg = '確定以健保身分洗腎 ?'
          ret.yesText = '【是】';
          ret.noText = '【否】';
        // } else {
        //   ret.NoAction();
        // }
        //result = isCard == 1 ? '確定以健保身分洗腎 ?^【是】^【否】' : ''
        break;
      case RegisterConst.SameTreat_1_復健:
        ret = new SameTreatAction('AA').SameTreat();
        break;
      case RegisterConst.SameTreat_2_預防保健:
        ret = new SameTreatAction('AC').NoAction();
        break;
      case RegisterConst.SameTreat_3_換藥:
        ret = new SameTreatAction('').SameTreat();
        //ret.Itp = 'AA';
        break;
      case RegisterConst.SameTreat_4_月療:
        ret = new SameTreatAction('AH').NoAction();
        break;
      case RegisterConst.SameTreat_5_全療:
        ret = new SameTreatAction('AB').SameTreat();
        break;
      case RegisterConst.SameTreat_6_內科同療:
        ret = new SameTreatAction('AG').SameTreat();
        break;
      //result = '是否為同療程第一次 ?^【是】，為第一次療程^【否】，為第二次療程以後'
      //break;
      case RegisterConst.SameTreat_7_同日同醫: //同醫師同日看診
          ret = new SameTreatAction('AI').Alert('當天查無同日看診病歷，類別不能為 7')
        //result = theSame ? '當天查無同日看診病歷，類別不能為 7' : ''
        break;
      case RegisterConst.SameTreat_8_慢箋領藥: //慢箋領藥
        ret = new SameTreatAction('AE').Alert('找不到92天內的慢箋紀錄');
        break;
      case '0A':
        ret = new SameTreatAction('AB').SameTreat();
        break;
      case '0B':
          ret = new SameTreatAction('AJ').NoAction();
        break;
      case '7A':
        ret = new SameTreatAction('AI').NoAction();
        break;
      case '7B':
        ret = new SameTreatAction('AI').Alert('不同醫師看診，就醫類別改為01')
        break;
    }
    return ret;
  }

  DefineItp(type: string,isFirst:boolean = false): string {
    {
      var result = '';
      var firstRuselt = '01'; //西醫基礎

      switch (type) {
        //AA同療
        case '1':
          result = 'AA'
          break;
        //AC預防保健
        case '2':
          result = 'AC'
          break;
        //療三
        case '3':
          result = 'AB'
          break;
        //AB月療,全療(非6次內治療)
        case '0':
          result = 'AJ'
          firstRuselt = '09'
          break;
        case '4':
          result = 'AH'
          break;
        case '5':
          result = 'AB'
          break;
        //AG排檢(內科同療)
        case '6':
          result = 'AG'
          break;
        //同日同醫師看診
        case '7':
          result = 'AI'
          firstRuselt = 'AI'
          break;
        //慢籤領藥
        case '8':
          result = 'AE'
          break;
      }
      if (isFirst)return firstRuselt;
      else return result
    }
  }

  /**
   *
   * @param patientid 預防保健查詢
   * @param regdate
   * @returns
   */
  async GetProphylacticHealth(patientid: number, regdate: string): Promise<ICNoPHOption[]> {
    return await this.registerApi.GetProphylacticHealth(UserCache.getLoginUser().Clinic.Id, patientid, regdate)
  }

  
  async GetAllProphylacticHealth(): Promise<ICNoPHOption[]> {
    return await this.registerApi.GetAllProphylacticHealth()
  }

  /**
   * 調查病人是否處於連續慢籤
   * @param patientid
   */
  async GetIsPres(patientid: number) {
    var ret = await this.patientApi.GetPresInfo(patientid)
    if (ret.PreDate == undefined) {
      return null;
    }
    return ret;
  }
}
