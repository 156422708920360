import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { setDate } from 'ngx-bootstrap/chronos/utils/date-setters';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { UserCache } from 'src/app/services/user-cache';
import { DateHelper } from 'src/app/shared/helpers/date-helper';
import { AgeYearMonthDiff } from 'src/app/shared/utilities';

@Component({
  selector: 'app-history-certificate',
  templateUrl: './history-certificate.component.html',
  styleUrls: ['./history-certificate.component.css']
})
export class HistoryCertificateComponent implements OnInit {
  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;

  @Input()
  set reportData(value) {
    if (value) {
      this.seData(value);
    }
  }

  Data;
  cidMask = '1234567890';
  bitrhMask = 'YYY年MM月DD日';
  constructor(private parameterApi: ParameterApi, private clinicDataService: ClinicDataService) { }

  ngOnInit() {
    var st = document.createElement('style');
    st.appendChild(document.createTextNode(
      '@media print {body {margin: 0;color: #000;background-color: #fff;}}\n' +
      '.hist-cert-report table{ border:1px solid black; border-collapse: collapse; }\n' +
      '.hist-cert-report table td, table th { border: 1px solid black; padding-left: 8px;padding-right: 5px; padding-bottom: 5px; padding-right: 5px;}\n' +
      '.hist-cert-report table tr:first-child th { border-top: 0; }\n' +
      '.hist-cert-report table tr:last-child td { border-bottom: 0; }\n' +
      '.hist-cert-report table tr td:first-child, table tr th:first-child { border-left: 0; }\n' +
      '.hist-cert-report table tr td:last-child, table tr th:last-child { border-right: 0; }\n' +
      '@page { size: A4; margin: 0.5cm 0.5cm; }'
    ));
    setTimeout(() => {
      this.reportContainer.nativeElement.appendChild(st);
    }, 0);
  }

  async seData(value) {
    this.Data = value;
    this.Data.Clinic = UserCache.getLoginUser().Clinic;
    var param = await this.clinicDataService.getParam("PRN001");
    this.cidMask = param.IDHidePRN ?? this.cidMask;
    this.bitrhMask = param.BirthdayHidePRN ?? this.bitrhMask;

    this.Data.Patient.BirthdayStr = this.GetDateString(this.Data?.Patient?.Birthday, true);
    this.Data.Register.RegDateStr = this.GetDateString(this.Data?.Register?.RegDate, false);
    this.Data.PrintDateStr = this.GetDateString(new Date(), false);
  }

  getHtml() {
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }

  GetDateString(val, isShowM = false) {
    if (val) {
      var dt = DateHelper.formatROCDate(new Date(val), true, false);
      if (isShowM) {
        if (dt.startsWith('民')) {
          return dt;
        } else {
          return '民國' + dt;
        }
      }
      return dt;
    } else {
      return "　　　年　　　月　　　日";;
    }
  }

}
