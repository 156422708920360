import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-vis-logo',
  template: `<svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Artboard" fill="#FFFFFF" fill-rule="nonzero">
          <g id="vis-logo" transform="translate(0.000000, -0.000000)">
              <path d="M15.9927187,2.354125 L9.661875,2.354125 L9.661875,7.51053125 L9.661875,9.21325 L13.9258437,9.21325 C14.6790625,9.21325 16,8.82196875 16,7.5069375 C16,5.5148125 16,2.354125 16,2.354125 L15.9927187,2.354125 Z M13.6459375,16 L13.6459375,9.661875 L6.79334375,9.661875 L6.79334375,13.9933438 C6.79334375,14.7465625 7.17846875,16 8.4935,16 L13.6459375,16 Z M8.8817842e-16,13.6465313 L6.36149974,13.6465313 L6.36149974,6.793375 L2.08196875,6.793375 C1.32875,6.793375 1.77635684e-15,7.1785 1.77635684e-15,8.49353125 C1.77635684e-15,9.82161458 8.8817842e-16,11.5392812 8.8817842e-16,13.6465313 Z M2.3541875,0 L2.3541875,6.33840625 L9.2065625,6.33840625 L9.2065625,2.01073802 C9.2065625,1.25751927 8.82196875,0 7.5069375,0 L2.3541875,0 Z M7.51290625,9.2065625 L7.51290625,8.4894375 L6.79334375,8.487375 L6.79334375,7.5129375 L7.51065625,7.5129375 L7.51290625,6.793375 L8.487375,6.793375 L8.487375,7.51053125 L9.2065625,7.5129375 L9.20653125,8.487375 L8.4895625,8.487375 L8.48734375,9.2065625 L7.51290625,9.2065625 Z" id="vis-slice"></path>
          </g>
      </g>
  </g>
</svg>
`,
  styles: [``]
})
export class IconVisLogoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
