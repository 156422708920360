<div style="position: relative;background-color: white;overflow: scroll;border: 1px solid black;" *ngIf="cmsData.code">
  <!--半版預覽用虛線-->
  <div *ngIf="cmsData?.isHelf" 
    style="position: absolute; border-left: 1px dashed gray;height: 100%;"
    [ngStyle]="{left:'calc('+this.cmsData.width+' / 2)'}"></div>
  
  <div style="border-bottom: 1px dashed black;border-right: 1px solid black;"
        [ngStyle]="sizeStyle()" #reportContainer>
    <div style="height: 100%;width: 100%;">
      <div style="line-height: 1.2;" [ngStyle]="innerSizeStyle()" [style.fontSize]="cmsData.style.fontSize||'16'+'px'">
        <ng-container *ngFor="let row of cmsData.rowsBeforeRx">
          <ng-container *ngTemplateOutlet="tmpRow;context: {$implicit: row,st:mergeStyle(cmsData.style,row.style)}"></ng-container>
        </ng-container>

        <ng-container *ngFor="let hrx of getHRx();let i=index">
          <ng-container *ngFor="let row of cmsData.rowsOfRx">
            <ng-container *ngTemplateOutlet="tmpRow;context: {$implicit: row,st:mergeStyle(cmsData.style,row.style),arg:{ind:i,tp:'hrx'}}"></ng-container>
          </ng-container>
        </ng-container>
        

        <ng-container *ngFor="let row of cmsData.rowsAfterRx">
          <ng-container *ngTemplateOutlet="tmpRow;context: {$implicit: row,st:mergeStyle(cmsData.style,row.style)}"></ng-container>
        </ng-container>
      </div>  
    </div>
    
  </div>
</div>

<ng-template let-row let-arg="arg" let-st="st" #tmpRow>
  <div [ngStyle]="rowStyle(row,st)" style="display: flex;">
    <ng-container *ngFor="let col of row.cols">
      <ng-container *ngTemplateOutlet="tmpCol;context: {$implicit: col,st:mergeStyle(st,col.style),arg:arg}"></ng-container>
    </ng-container>
    <ng-container *ngIf="row.cols.length==0">
      <span [ngStyle]="style(st)">&nbsp;</span>
    </ng-container>
  </div>
</ng-template>

<ng-template let-col let-arg="arg" let-st="st" #tmpCol>
  <div [ngStyle]="style(st,getData(col,arg))" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis">
    <span *ngIf="col.label">{{col.label}}{{col.dataSource?':':''}}</span>
    <span *ngIf="col.textBefore">{{col.textBefore}}</span>
    <span *ngIf="col.dataSource">{{getData(col,arg)}}</span>
    <span *ngIf="col.textAfter">{{col.textAfter}}</span>
  </div>
  
</ng-template>
