import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FileService } from 'src/app/services/api-service/file/file.service';
import { UserConfirmService } from "src/app/services/user-confirm.service";
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { FilesizeHelper } from 'src/app/shared/helpers/filesize-helper';
import { FileUploadConfig, FileUploadSelected, FileUploadDeleteKey, FileUploadInsertKey } from '../../models/file-upload-selected';
// import * as mime from 'mime';

@Component({
  selector: 'app-vis-file-upload',
  templateUrl: './vis-file-upload.component.html',
  styleUrls: ['./vis-file-upload.component.scss'],
})
export class VisFileUploadComponent implements OnInit, OnDestroy {

  _isClickNew: boolean = false;
  _isDialogOpened = false;
  @Input() set isDialogOpened(value: boolean){
    this._isDialogOpened = value;
    if (this._isDialogOpened)
    {
      this._isClickNew = this.config.is_new;
      if (this._isClickNew){
        this._isClickNew = false;
        this.initiateCancelAll(false);
      }

      if (this.config.is_edit){
        //既有已存在的檔案清單, 若刪除時搬到to_be_delete, 也包含加入新檔案
        this.selected_files = this.config.selected_files;
        //已存在的檔案選擇刪除的清單
        this.to_be_delete_files = this.config.to_be_delete_files;
      }
      //保留一開始的選擇檔案，等取消或關閉時還原。
      this.init_selected_files = [...this.selected_files];
      this.init_to_be_delete_files = [...this.to_be_delete_files];
    }
  }

  @Input() config!: FileUploadConfig;

  @Output() onEmitResult = new EventEmitter<{selected_files: FileUploadSelected[], to_be_delete_files: FileUploadSelected[]}>();

  selected_files: FileUploadSelected[] = [];
  to_be_delete_files: FileUploadSelected[] = [];
  init_selected_files: FileUploadSelected[] = [];
  init_to_be_delete_files: FileUploadSelected[] = [];

  @ViewChild("fileSelector", {static: false}) file_selector!: ElementRef;

  file_selection_form: FormGroup;

  // Subscriptions
  private file_selection_sub!: Subscription;
  private file_upload_sub!: Subscription;

  constructor(
    private fileService: FileService,
    private userConfirm: UserConfirmService,
    private notificationService: EasyNotificationService,
    ) {
      this.file_selection_form = new FormGroup({
        file_selection: new FormControl()
      });
  }

  ngOnInit(): void {
    this.trackFileSelection();
  }

  openFileSelector(){
    const file_selection = this.file_selector.nativeElement;
    file_selection.click();
  }

  trackFileSelection(){
    this.file_selection_sub = this.file_selection_form.get('file_selection')?.valueChanges.subscribe(
      ()=>{
        const file_selection = this.file_selector.nativeElement;
        this.selectFiles(file_selection.files);
        this.file_selector.nativeElement.value = '';
      }
    ) as Subscription;
  }

  selectFiles(incoming_files: any[]){
    let message = "";
    let incoming_file_count  = incoming_files.length;
    let incorrect_MIME_type = false;
    let incorrect_limit = false;
    let incorrect_size = false;

    if (this.config.max_file_limit != 0 && incoming_file_count + this.selected_files.length > this.config.max_file_limit)
    {
      incorrect_limit = true;
      message = "檔案數量超過限制，最多 " + this.config.max_file_limit + " 個檔案。";
      message += this.selected_files.length > 0 ? "目前已有 " + this.selected_files.length + " 個檔案。" : "";
    }
    else
    {
      for(let i = 0; i < incoming_file_count; i++){
        let incoming_file = incoming_files[i];
        let incoming_size = incoming_file.size;
        //let incoming_size_mb = (incoming_size / (1024 * 1024)).toFixed(2);
        let incoming_size_mb = FilesizeHelper.fileSizeMB(incoming_size);
        // Checking if MIME type is acceptable
        // if(!!!this.config.MIME_types_accepted || this.config.MIME_types_accepted.indexOf(incoming_file.type)>=0){
        if (incoming_file.type.length == 0){
          incorrect_MIME_type = true;
          message = "僅允許以下類型的檔案: " + this.mimeToExtension(this.config.MIME_types_accepted);
          break;
        }
        else if (this.config.max_file_size_mb != 0 && incoming_size_mb > this.config.max_file_size_mb){
          incorrect_size = true;
          message = "檔案大小超過限制，最大 " + this.config.max_file_size_mb + " MB，目前檔案是 " + incoming_size_mb + " MB。";
          break;
        }
        else if(!!!this.config.MIME_types_accepted || this.config.MIME_types_accepted.indexOf(incoming_file.type)>=0){
          let selected_file = {
            id: null,
            file: incoming_file,
            is_upload_in_progress: false,
            upload_result: null,
            mark_result: '',
          };
          this.markInsertFile(selected_file);
          this.selected_files.push(selected_file);
        }
        else{
          incorrect_MIME_type = true;
          message = "僅允許以下類型的檔案: " + this.mimeToExtension(this.config.MIME_types_accepted);
          break;
        }
      }
    }

    // Display error
    // let message = "Only files of the following MIME types are allowed: "+this.config.MIME_types_accepted;
    if (message != "")
    {
      //顯示訊息, 改用底層通知
      this.notificationService.showError(message, true);
    }

  }

  uploadFile(index: number){
    let file_for_upload = this.selected_files[index];

    const form_data = new FormData();
    form_data.append('file', file_for_upload.file);

    // For other fields, we have to use append() as well
    // E.g. form_data.append('thikana', 'Bishadbari Lane');

    file_for_upload.is_upload_in_progress = true;
    file_for_upload.upload_result = null;

    this.file_upload_sub = this.fileService.uploadFile(form_data, this.config.API).subscribe(
      (success)=>{
        // Dummy setTimeout to imitate API latency
        setTimeout(()=>{
          file_for_upload.upload_result=success.message;

          // Adding dummy error
          if(file_for_upload.file.name.indexOf('error')>=0){
            file_for_upload.upload_result =  this.fileService.error_messages.service_failure;
          }

          file_for_upload.is_upload_in_progress = false;
        },5000);
      },
      (error)=>{
          file_for_upload.upload_result=error.message;
          file_for_upload.is_upload_in_progress = false;
      }
    );
  }

  uploadAll(){
    let selected_file_count  = this.selected_files.length;
    for(let i = 0; i < selected_file_count; i++){
      let selected_file = this.selected_files[i];
      // Checking if the file can be uploaded
      if(!selected_file.is_upload_in_progress && selected_file.upload_result!='success'){
        this.uploadFile(i);
      }
    }
  }

  inititateFileCancel(index: number){
    // let file_for_upload = this.selected_files[index];
    //一律都詢問
    //if(file_for_upload.is_upload_in_progress){
      this.displayFileUploadAbortConfirmation(
        ()=>{
          this.cancelFile(index);
        }, false
      );
    // }else{
    //   this.cancelFile(index);
    // }
  }

  async displayFileUploadAbortConfirmation(cancel_method: any, cancel_all: boolean){
    let show_title = '取消檔案';
    let show_msg = '是否取消檔案？';
    if (cancel_all)
    {
      show_title = '取消全部檔案';
      show_msg = '是否取消全部檔案？';
    }
    var ret = await this.userConfirm.showConfirm(
      {
          title: show_title,
          msg: show_msg,
          textYes: '是',
          textNo: '否'
      })
    if (ret){
      cancel_method();
    }
  }

  cancelFile(index: number){
    this.keepDeleteFile(this.selected_files[index]);
    this.selected_files.splice(index, 1);
  }

  initiateCancelAll(showConfirm: boolean = true){
    let selected_file_count = this.selected_files.length;
    let is_any_file_being_uploaded = false;
    for(let i = 0; i < selected_file_count; i++){
      let selected_file = this.selected_files[i];
      // Checking if the file is being uploaded
      if(selected_file.is_upload_in_progress){
        is_any_file_being_uploaded = true;
        break;
      }
    }
    //一律都詢問
    // if(is_any_file_being_uploaded){
    if (showConfirm){
        this.displayFileUploadAbortConfirmation(
          ()=>{
            this.cancelAll();
          }, true
        );
      }
    else{
      this.cancelAll();
    }
  }

  cancelAll(){
    this.fileService.scrollToElement('.vis-file-uploader', 100);
    let selected_file_count  = this.selected_files.length;
    for(let i = selected_file_count - 1; i >= 0; i--){
      this.keepDeleteFile(this.selected_files[i]);
      this.selected_files.splice(i, 1);
    }
  }

  isAnyFileNotUploaded(){
    let selected_file_count  = this.selected_files.length;
    let is_any_file_not_uploaded = false;
    for(let i = 0; i < selected_file_count; i++){
      let selected_file = this.selected_files[i];
      // Checking if the file can be uploaded
      if(!selected_file.is_upload_in_progress && selected_file.upload_result!='success'){
        is_any_file_not_uploaded = true;
        break;
      }
    }
    return is_any_file_not_uploaded;
  }

  ngOnDestroy(): void {
    this.fileService.unsubscribeAll([
      this.file_selection_sub,
      this.file_upload_sub
    ]);
  }

  onDialogClose(status: string) {
    if (status === 'ok') {
      // this.onEmitResult.emit(this.selected_files);
      this.onEmitResult.emit({selected_files: this.selected_files, to_be_delete_files: this.to_be_delete_files});
    } else {
      // this.onEmitResult.emit(null);
      // this.onEmitResult.emit({selected_files: null, to_be_delete_files: null});
      //還原當初的檔案清單，因過程中清單可能增刪，沒按確定都不算。
      this.selected_files = [...this.init_selected_files];
      this.to_be_delete_files = [...this.init_to_be_delete_files];
      this.onEmitResult.emit({selected_files: this.selected_files, to_be_delete_files: this.to_be_delete_files});
    }
  }

  //當編輯模式, 代表=已有既有的檔案, 先註記delete, 後續才實際刪除, 如果是當下新增的(insert), 就不註記。
  keepDeleteFile(file: FileUploadSelected)
  {
    if (!this.config.is_edit)
      return;

    if (file.mark_result != FileUploadInsertKey){
      file.mark_result = FileUploadDeleteKey;
      this.to_be_delete_files.push(file);
    }
  }

  //當新增/編輯模式, 註記是新增
  markInsertFile(file: FileUploadSelected)
  {
    // if (!this.config.is_edit)
    //   return;

    if (file.mark_result != FileUploadInsertKey){
      file.mark_result = FileUploadInsertKey;
    }
  }

  mimeToExtension(mimeString: any): any {
    const mimeList = mimeString.split(',').map((item: string)=>item.trim());
    mimeList.forEach((value: any) => {
      //還沒install package
      // const ext = mime.getExtension(value);
      const ext = value;
      if (ext) {
        value = ext;
      }
    })

    return '\n' + mimeList.join('\n');
  }

}

