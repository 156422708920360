import { getTestBed } from '@angular/core/testing';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-cross',
  template: `<svg class="{{cssName}} cross" width="22px" height="24px" viewBox="0 0 22 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Art" transform="translate(-1.000000, 0.000000)" fill="#f44336">
          <path d="M17.1826966,3.26832393 L12,0.406451961 L6.8256708,3.26366597 L1.62609574,6.18153931 L1.60500918,11.99494 L1.62609574,17.8184607 L6.81730337,20.7316761 L12,23.593548 L17.1743292,20.736334 L22.3739043,17.8184607 L22.3949908,12.00506 L22.3739043,6.18153931 L17.1826966,3.26832393 Z M11.999,3.593 L15.8256708,5.70604606 L19.589,7.818 L19.6050092,12.00506 L19.589,16.181 L15.8173034,18.2986119 L12,20.406 L8.1743292,18.2939539 L4.41,16.181 L4.39499082,11.99494 L4.41,7.818 L8.18269663,5.70138809 L11.999,3.593 Z" id="Star" fill-rule="nonzero"></path>
          <path d="M12.6,7 C13.0418278,7 13.4,7.3581722 13.4,7.8 L13.4,10.604 L16.205,10.605 C16.6468278,10.605 17.005,10.9631722 17.005,11.405 L17.005,12.595 C17.005,13.0368278 16.6468278,13.395 16.205,13.395 L13.4,13.395 L13.4,16.2 C13.4,16.6418278 13.0418278,17 12.6,17 L11.41,17 C10.9681722,17 10.61,16.6418278 10.61,16.2 L10.61,13.395 L7.805,13.395 C7.3631722,13.395 7.005,13.0368278 7.005,12.595 L7.005,11.405 C7.005,10.9631722 7.3631722,10.605 7.805,10.605 L10.61,10.605 L10.61,7.8 C10.61,7.3581722 10.9681722,7 11.41,7 L12.6,7 Z" id="Path"></path>
      </g>
  </g>
</svg>`,
  styles: [`
   svg.open-cloud-hist g,svg.cross:hover g{
    fill: #01afa0;
  }
  `]
})
export class IconCrossComponent implements OnInit {

  constructor() { }
  @Input() cssName:string ='';

  ngOnInit(): void {
  }

}
