import { MutiTreatmentListDto } from "src/app/services/api-service/register/mutiTreatmentListDto";
import { UserSimplifyInfoDTO } from "src/app/services/api-service/user/uesrSimpleInfo";

export class RehabituserCids
{

     PTCid :string
     OTCid :string
     STCid :string
     PRTCid:string
 
}

export class InitmultiTreatmentUserData
{
     Users : UserSimplifyInfoDTO[];
     Data : MutiTreatmentListDto;
 
}