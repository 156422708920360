import { Injectable } from '@angular/core';
import { HcrService } from './hcr-service.service';
import { EasyNotificationService } from './easy-notification.service';
import { DeclareApi } from './api-service/declare/declare-api';
import { DateHelper } from '../shared/helpers/date-helper';
import { UserCache } from './user-cache';
import { ValueTextPair, ValueTextPairNumberValue } from '../shared/models/value-text-pair';
import { UserConfirmService } from './user-confirm.service';

@Injectable({
  providedIn: 'root'
})
export class CnhiEiiService {

  constructor(
    private hcrService:HcrService,
    private notification:EasyNotificationService,
    private declareApi:DeclareApi,
    private userConfirm:UserConfirmService

    ) { }
  /** 上傳申報資料 */
  public async CNHIEii_SendDeclareFile(path:string):Promise<boolean>{
    var clinicId = UserCache.getLoginClinicId();
    var fileName = path.substring(path.lastIndexOf('\\'));
    var check = await this.declareApi.CheckNHISend(clinicId,fileName);
    if(check==true){
      var conf = await this.userConfirm.showConfirm({
        title:'上傳作業',
        msg:'檔案名稱與先前的作業有重複，是否繼續進行',
      });
      if(!conf){
        return false;
      }
    }
    
    var ret = await this.hcrService.VisionApi.CNHIEiiSend(0,"03",path)
    if(ret.StatusCode!=0){
      this.notification.showError(ret.ErrorMsg);
      return false;
    }else{
      // 寫!
      await this.declareApi.NHISendRequest(clinicId,"03",path,ret.Data.LocalID,ret.Data.NHIID);
      return true;
    }
  }
  /** 申報資料下載請求
   * @param declareMonth 費用年月
   * @param applyType 申報類別
   * 1:送核；2:補報；4:申復送核；5:申復補報
   * @param hospType 醫事類別
   * 11:門診西醫診所;12:門診西醫醫院;13:門診牙醫;14:門診中醫;15:門診洗腎;19:門診其他醫事機構;
   *
   * 21:住診西醫診所;22:住診西醫醫院;29:住診其他醫事機構;30:特約藥局;40:物理治療所;50:特約檢驗所
   *
   * @param declareDate 申報日期(YYYMMDD) - 7 碼
  */
  public async CNHIEii_DownloadDeclareFile(clinicId:number,declareMonth:string, applyType:string,hospType:string,declareDate:string):Promise<boolean>{

    var hospId = await this.hcrService.VisionApi.GetHospID();
    var dt = DateHelper.formatROCDate(new Date(),false,false).padStart(7,'0');
    var ym = DateHelper.formatROCDate(declareMonth,false,false).padStart(7,'0').substring(0,5);
    var appl_dt = DateHelper.formatROCDate(declareMonth,false,false).padStart(7,'0');
    var accessDateTime = DateHelper.formatROCDate(new Date(),false,true)
    var queryFileName = `${hospId.Data}-${dt}-001.txt`;
    var saveRet = await this.hcrService.SaveTextAs(`declare_tmp\\${accessDateTime}\\${queryFileName}`,
      `FEE_YM=${ym}\n`+
      `APPL_TYPE=${applyType}\n`+
      `HOSP_DATA_TYPE=${hospType}\n`+
      `APPL_DATE=${appl_dt}`);
    var path:string = saveRet.Returns;
    var downloadFolder =path.substring(0,path.lastIndexOf('\\'));

    var ret = await this.hcrService.VisionApi.CNHIEiiDownload(0,"03",path)

    if(ret.StatusCode!=0){
      this.notification.showError(ret.ErrorMsg);
      return false;
    }else{
      // 寫!
      await this.declareApi.CreateNHIDownloadRequest(clinicId,"03",ym,ret.Data.LocalID,ret.Data.NHIID,queryFileName)
      // 拿
      //var getRet = await this.hcrService.VisionApi.CNHIEiiGet(0,downloadFolder,ret.Data.LocalID,ret.Data.NHIID);

      // if(getRet.StatusCode!=0){
      //   this.notification.showError(getRet.ErrorMsg);
      //   return false;
      // }
      var cfRet = await this.userConfirm.showConfirm({
        title:'提示',
        msg:'下載申請已提交，後續請至健保VPN下載。',
        textYes:'開啟健保VPN',
        textNo:'完成'
      });
      if(cfRet){
        window.open('https://medvpn.nhi.gov.tw/iwse0000/IWSE0010S01.aspx');
      }
      
      return true;
    }
  }

  public GetHospType():ValueTextPair[]{
    return [
      {text:'11.門診西醫診所',value:'11'},
      {text:'12.門診西醫醫院',value:'12'},
      {text:'13.門診牙醫',value:'13'},
      {text:'14.門診中醫',value:'14'},
      {text:'15.門診洗腎',value:'15'},
      {text:'19.門診其他醫事機構',value:'19'},
      {text:'21.住診西醫診所',value:'21'},
      {text:'22.住診西醫醫院',value:'22'},
      {text:'29.住診其他醫事機構',value:'29'},
      {text:'30.特約藥局',value:'30'},
      {text:'40.物理治療所',value:'40'},
      {text:'50.特約檢驗所',value:'50'},
    ]
  }
}
