import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { UserCache } from 'src/app/services/user-cache';
import { HistService } from '../../services/hist.service';
import { FormHelper, FormHelperService } from 'src/app/services/formhelper';
import { FormGroup, Validators } from '@angular/forms';
import { ValidationTipComponent as ValidTip } from 'src/app/shared/components/validation-tip/validation-tip.component';
import { UserConfirmService } from 'src/app/services/user-confirm.service';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { MainSetApi } from 'src/app/services/api-service/rx/mainset-api';
import { MainSetDto } from 'src/app/services/api-service/rx/main-set-dto';
import { RxSetDto } from 'src/app/services/api-service/rx/rx-set-dto';
import { MainSetView } from 'src/app/services/api-service/rx/main-set-view';

@Component({
  selector: 'app-template-save',
  templateUrl: './template-save.component.html',
  styleUrls: ['./template-save.component.css']
})
export class TemplateSaveComponent implements OnInit {

  @ViewChild('tmplView')
  tmplView: TemplateRef<any>
  _isTmplWinOpened = false;
  @Input()
  set isTmplWinOpened(v:boolean){
    this._isTmplWinOpened = v;
    if(v){
      this.resetTmplWinContent();
      // 開啟視窗時拉取醫令
      this.histService.fetchHist().then(d=>{
        this.histRcd = d;
      });
    }
  }
  get isTmplWinOpened():boolean{
    return this._isTmplWinOpened;
  }
  @Output()
  winClose:EventEmitter<boolean> = new EventEmitter();
  histRcd:WholeHist;
  constructor(
    private histService:HistService,
    private mainsetApi:MainSetApi,
    private fhs:FormHelperService,
    private notification:EasyNotificationService,
    private userConfirmService:UserConfirmService
  ) { }

  async ngOnInit() {
    this.makeTemplateFormGroup(); // 綁定Fformgroup

  }

  async onTmplWinClose(isSave: boolean) {
    if (isSave) {
      if (!await this.validateTmp()) {
        return;
      }

      var checkCodeResult = await this.mainsetApi.CheckMainSetCode(this.formHelper.Value.code);
      var id = UserCache.getLoginUser().UserId;

      // 範本代碼重複的時候，會跳出選擇另存的畫面
      if (checkCodeResult.newCode != '') {
        var tmp = checkCodeResult.exist;
        // 不覆蓋其他醫師建立的範本
        if (tmp?.UserId != 0 && tmp?.UserId != id) {
          this.notification.showWarning('代碼已有醫師使用');
          return;
        }
        // Todo
        var act = await this.showCompare(checkCodeResult.newCode,checkCodeResult.exist)


        if (act == true) {
          // 覆蓋 Todo
          await this.UpdateTmpl(tmp.Id);
          //this.saveTmpl(true);
        } else if (act == false) {
          // 新代碼儲存
          this.formHelper.setValue('code',checkCodeResult.newCode);
          await this.createTml();
          //this.saveTmpl(false);
        }
      } else {
        await this.createTml();
      }

      // save不關閉，等存成功再關
      // 有可能save失敗，要顯示並詢問
    } else {
      this.closeTmplWin();
    }
  }
  async showCompare(code:string ,tmp:MainSetView){
    var cur = new MainSetView();
    var histRcd = this.histRcd;
    var setting = this.formHelper.Value;
    if(setting.includeCC){
      cur.CC = histRcd.Hist.CC;
    }
    if(setting.includePE){
      cur.PE = histRcd.Hist.PE
    }
    if(setting.includeDX){
      cur.Dx1 = histRcd.Hist.Dx1;
      cur.Dx2 = histRcd.Hist.Dx2;
      cur.Dx3 = histRcd.Hist.Dx3;
      cur.Dx4 = histRcd.Hist.Dx4;
      cur.Dx5 = histRcd.Hist.Dx5;
      cur.Dx6 = histRcd.Hist.Dx6;
      cur.Dx1Name = histRcd.Hist.Dx1Name;
      cur.Dx2Name = histRcd.Hist.Dx2Name;
      cur.Dx3Name = histRcd.Hist.Dx3Name;
      cur.Dx4Name = histRcd.Hist.Dx4Name;
      cur.Dx5Name = histRcd.Hist.Dx5Name;
      cur.Dx6Name = histRcd.Hist.Dx6Name;
    }
    if(setting.includeRX){
      cur.Details = histRcd.Hist.HRxs.map(x => {
        var de = new RxSetDto();
        de.ProdName = x.ProdName;
        de.Frequency = x.Freq;
        de.Dose = x.Dose;
        de.DailyDose = x.DailyDose;
        de.TotalDose = x.TotalDose;
        de.SpecialRule = x.SPRule;
        de.Days = x.Days;
        return de;
      });
    }
    cur.Name = setting.name;
    cur.Code = setting.code;

    var act = await this.userConfirmService.showConfirm({
      'title': '範本代碼重複',
      'msg': '',
      'textYes': '覆蓋',
      'width': 1000,
      'template': this.tmplView,
      'templateData': { tmp: tmp, current: cur },
      'textNo': '以[' + code + ']另存',
      'customBtnEnd': [{
        'text': '取消',
        'return': 'cancel'
      }]
    });
    return act;
  }

  async validateTmp() {
    this.formHelper.FormGroup.markAllAsTouched();
    if (!this.formHelper.FormGroup.valid) {
      return false;
    }
    //this.isCopy = false;

    // 檢查，如果主訴、診斷、醫囑都是空的話，不可存
    if (!this.histRcd || (!this.histRcd.Hist.CC && !this.histRcd.Hist.CC && !this.histRcd.Hist.Dx1 &&
      (!this.histRcd.Hist.HRxs || this.histRcd.Hist.HRxs.length === 0))) {
      this.notification.showError('存入範本至少需有主訴/理學/診斷或處方其中之一的資料');
      return false;
    }

    return true;
  }

  /** 將門診輸入轉成套餐模型 */
  getSaveModel():MainSetDto{
    // 複製
    var model = new MainSetDto();
    var user = UserCache.getLoginUser();
    var formValue = this.formHelper.Value;

    model.ClinicId = user.Clinic.Id;
    model.Name =  formValue.name;
    model.Code = formValue.code;
    model.MedDeptCode = this.histRcd.Register.MedDeptCode;
    // 自用
    if(!formValue.isShared){
      model.UserId = user.UserId;
    }
    if(formValue.includeCC){
      model.CC = this.histRcd.Hist.CC;
    }
    if(formValue.includePE){
      model.PE = this.histRcd.Hist.PE;
    }
    if(formValue.includeDX){
      model.Dx1 = this.histRcd.Hist.Dx1;
      model.Dx2 = this.histRcd.Hist.Dx2;
      model.Dx3 = this.histRcd.Hist.Dx3;
      model.Dx4 = this.histRcd.Hist.Dx4;
      model.Dx5 = this.histRcd.Hist.Dx5;
      model.Dx6 = this.histRcd.Hist.Dx6;
    }
    if(formValue.includeRX){
      model.Details = this.histRcd.Hist.HRxs.map(x=>{
        var r =new RxSetDto();
        r.Sort = x.Sort;
        r.PackageNo = x.Sort;
        r.SpecialRule = x.SPRule;
        r.Dose = x.Dose;
        r.DailyDose = x.DailyDose;
        r.TotalDose = x.TotalDose;
        r.Frequency = x.Freq;
        r.InfoFrequency = x.FreqQTY;
        r.Way = x.Way;
        r.Days = x.Days;
        r.InfoDays = x.InfoDays;
        r.RxId = x.RxId;
        r.DispTP = x.DispTP;
        // 看Server端原本的處理方式，其他欄位沒存
        return r;
      });
    }
    return model;
  }

  /** 儲存套餐 */
  async createTml(){
    var model = this.getSaveModel()
    try{
      await this.mainsetApi.Create(model);
      this.notification.showSuccess('儲存成功');
      this.closeTmplWin();
    }catch(ex){
      this.notification.showError(ex);
    }
  }

  async UpdateTmpl(id:number){
    var model = this.getSaveModel()
    model.Id = id;
    try{
      await this.mainsetApi.Update(model);
      this.notification.showSuccess('儲存成功');
      this.closeTmplWin();
    }catch(ex){
      this.notification.showError(ex);
    }
  }

  async saveTmpl(isForced: boolean) {
    // 準備tmpFG參數
    this.histRcd.IsTemplateForcedSave = isForced;
    this.histRcd.MedDeptCode = this.histRcd.Register.MedDeptCode;
    this.histRcd.TemplateCode = this.formHelper.Value.code;
    this.histRcd.TemplateName = this.formHelper.Value.name;
    this.histRcd.IsTemplateShared = this.formHelper.Value.isShared;
    // 先清除先前留下的訊息
    this.clearTmplWinErrorMessage();
    try {
      this.closeTmplWin();
      this.notification.showOk();
    } catch (error) {
      let msg = error.message;
      if (!msg) {
        msg = 'Save failed.';
      }
      this.formHelper.setValue('errorMessage',msg);
    }

  }
  lockSaveTmp = false;
  tmplFG: FormGroup;

  formHelper:FormHelper<templateSaveModel>;
  makeTemplateFormGroup(){
    this.formHelper = this.fhs.Create<templateSaveModel>().build({
      code: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(9),
        Validators.pattern(ValidTip.patternLetterNumber)]],
        name: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(100),
        Validators.pattern(ValidTip.patternLetterNumber)]],
        isShared: [true,null],
        errorMessage: ['',null],
        includeCC:[true,null],
        includePE:[true,null],
        includeDX:[true,null],
        includeRX:[true,null]
    });
    this.tmplFG = this.formHelper.FormGroup;
  }
  clearTmplWinErrorMessage() {
    this.formHelper.setValue('errorMessage','');
  }
  resetTmplWinContent() {
    this.formHelper.setValue('code','');
    this.formHelper.setValue('name','');
    this.formHelper.setValue('isShared',true);
    this.formHelper.setValue('errorMessage','');
    this.formHelper.setValue('includeCC',true);
    this.formHelper.setValue('includePE',true);
    this.formHelper.setValue('includeDX',true);
    this.formHelper.setValue('includeRX',true);
  }
  async onTmplNameChange(event) {
    const name = event.target.value;
    this.lockSaveTmp = true;
    var code = await this.mainsetApi.GetMainSetCode(name);
    this.formHelper.setValue('code',code);
    this.lockSaveTmp = false;
  }
  onTmplCodeChange(event) {
    this.clearTmplWinErrorMessage();
  }
  closeTmplWin(){
    this.isTmplWinOpened = false;
    this.winClose.emit(this.isTmplWinOpened)
  }
}

interface templateSaveModel{
  code:string;
  name:string;
  isShared:boolean;
  errorMessage:string;
  includeCC:boolean;
  includePE:boolean;
  includeDX:boolean;
  includeRX:boolean;
}
