import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { CustomResult } from "src/app/shared/models/custom-result";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";

@Injectable({
  providedIn: 'root'
})

export class SystemcodetypeApi {
  api: WebApiService;
  clinicApi: WebApiService

  constructor(
    private apiClient: WebApiClient) {
    this.api = apiClient.createHisService('system/SystemCodeType');
  }

  private unsubscribe: Subject<void> = new Subject();

  /**
   * 取得系統代碼類型列表
   * @param group
   * @param type
   * @param name
   * @param types
   * @returns
   */
  async GetItem(group: string = '', type: string = '', name: string = '', types: string[] = []): Promise<CustomResult> {

    // 初始化查詢參數字符串
    let queryParams: string[] = [
      `group=${encodeURIComponent(group)}`,
      `type=${encodeURIComponent(type)}`,
      `name=${encodeURIComponent(name)}`
    ];

    // 為每個types元素添加查詢參數
    types.forEach(type => {
      queryParams.push(`types=${encodeURIComponent(type)}`);
    });

    // 將查詢參數組合成字符串
    const queryString = queryParams.join('&');

    // 完整的URL
    const url = `GetItem?${queryString}`;

    // 發送請求
    return this.api.get(url, {}).toPromise();
  }

  /**
   * 取得系統代碼類型列表
   * @param type
   * @param name
   * @returns
   */
  async GetAll(type: string = '', name: string = ''): Promise<CustomResult> {

    return this.api.get(`GetAll?type=${type}&name=${name}`, {}).toPromise();
  }
}
