<div class="card checkbox-label-ui extend-msg" [hidden]="this.isHide">
  <div class="card-header card-header-light-blue">
    <h5 class="vis-title d-inline-block text-base font-weight-bold">
      <ng-content select="div[panel-name]"> </ng-content>
      
    </h5>
    <app-shared-edit-control-buttons
      [hasDeleteButton]="false"
      (onEmitSave)="onSave()" (onEmitCancel)="onCancel()" (onEmitEdit)="onEdit()" #ctrlBtns>
    </app-shared-edit-control-buttons >
  </div>
  <div class="card-body" style="overflow-y: auto;padding: 5px 15px;" [style.max-height.px]="this.height">
    <!--有分群組-->
    <ng-container *ngIf="this.groupData.length>0">
      <div *ngFor="let item of this.groupData" class="row" style="margin-left: -5px">
        <div class="col-sm-12">
          {{item.groupName}}
          <div *ngIf="groupCheckAll" style="float: right;margin-right: 20px">
            <mat-checkbox   (change)="this.groupCheck($event,item.group)" class="k-checkbox" [disabled]="!this.isEditing()">
              All
            </mat-checkbox>
          </div>
        </div>

        <ng-container *ngFor="let p of this.checkData"  >
          <div *ngIf="p.Group==item.group" style="margin-left: 0px" class="col-sm-3">
            <mat-checkbox  [checked]="p.Checked" (change)="checkChange($event,p)"   class="k-checkbox" [disabled]="!this.isEditing()">
              {{p.Name}}
            </mat-checkbox>
            <ng-template *ngTemplateOutlet="templateRef;context:{$implicit:p}">
            </ng-template>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <!--沒群組-->
    <ng-container *ngIf="this.groupData.length==0">
      <div class="row" style="margin-left: -5px">
        <div *ngFor="let p of this.checkData" class="col-sm-3">
          <mat-checkbox  [checked]="p.Checked" (change)="checkChange($event,p)" class="k-checkbox" [disabled]="!this.isEditing()">
            {{p.Name}}
          </mat-checkbox>

        </div>
      </div>
    </ng-container>
  </div>
</div>
