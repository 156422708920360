import { UserRegfee } from 'src/app/services/api-service/opd-other-service/pay-register/UserRegfee';
import { Entity } from 'src/app/shared/models/entity';

export class PaymentInfoDto extends Entity {

    /**
     * 應收掛號費
     */
    RegisterFee: number;
    /**
     * 押金
     */
    Deposit: number;
    /*
     * 應收診察費
     */
    ConsultFee: number;
    /*
     * 應收自費
     */
    SelfPay: number;
    /*
     * 應收部分負擔
     */
    OwnFee: number;
    /*
     * 應收藥自付
     */
    DrugOwnFee: number;
    DoctorRegFee:UserRegfee;
}

export class DiscountCodeReport {
  startDate: Date;
  endDate: Date;
  targetType: string;
  sortType: string;
  startBirthDate?: Date;
  endBirthDate?: Date;
  iCode: string;
  statisticType: string;
  feeType: string;
  clinicId: string;
  Items: DiscountCodeReportItem[];
}

export class DiscountCodeReportItem {
  RegDate: Date | string;
  PatientNo: string;
  PatientFirstName: string;
  Birthday: Date | string;
  PatientCId: string;
  PhoneArea: string;
  Phone: string;
  ICode:number;
  Icno:number;
  DiscountCode: string;
  PatientCity: string;
  PatientArea: string;
  PatientStreet: string;
}

export class PaymentListDto {
  startDate: Date;
  endDate: Date;
  sortType: string;
  Name: string;
  iCode: string;
  feeType: string;
  clinicId: string;
  Id: string; // PaymentMaster.Id
  PatientId: string; 
  PatientNo: string; 
  CId: string; 
  PatientName: string; 
  PaySysA: string; 
  RegDate: Date; 
  ICode: string; 
  IType: string; 
  ADx: number; 
  ATx10: number; 
  ATx11: number; 
  AMat: number; 
  ARx2: number; 
  ATx13: number; 
  ARx3: number; 
  ARx4: number; 
  ATx5: number; 
  ATx6: number; 
  ATx7: number; 
  ATx8: number; 
  ATx9: number; 
  SameTreat: string; 
  FeeCode: string; 
  Subtotal: number; 
  // Items: InsuranceDeclarationComponent[];
  TotalADx: number; 
  TotalATx10: number; 
  TotalATx11: number; 
  TotalAMat: number; 
  TotalARx2: number; 
  TotalATx13: number; 
  TotalARx3: number; 
  TotalARx4: number; 
  TotalATx5: number; 
  TotalATx6: number; 
  TotalATx7: number; 
  TotalATx8: number; 
  TotalATx9: number;
}