<div class="app-v-select v-control position-relative" style="display: flex; height: 25px;padding-top: 1px;"
  [ngClass]="customClass" [class.v-disabled]="_disabled">
  <input type="text" class="border-0 bg-transparent" style="width:calc(100% - 1.3rem);" aria-label="Number" matInput
    [value]="_displayText" [ngClass]="_class" #input (click)="onInputClick()" (input)="inputChange()" (keydown)="onKeyDown($event)"
    [matAutocomplete]="auto" [disabled]="_disabled"> <!-- (change)="_valueChange($event)" -->
  <div (click)="openPanel()" class="h-100 position-absolute cursor-pointer d-inline-flex" style="right: 6px;">
    <div class="mat-select-arrow m-auto bottom-0 top-0"></div>
  </div>
  <!-- <mat-icon style="position: absolute;right: 0px;top: 3px;" matSuffix>keyboard_arrow_down</mat-icon>  -->
  <mat-autocomplete [class]="panelClass" [panelWidth]="panelFitContent?'fit-content':'1000'" (optionSelected)="optSelected($event)"
    #auto="matAutocomplete">
    <mat-option *ngFor="let item of filteredOptions | async" [value]="item[valueField]">
      <!-- {{item.text}} -->
      <ng-container *ngIf="displayTmp;else defaultTmp">
        <ng-container *ngTemplateOutlet="displayTmp;context:{$implicit:item}"></ng-container>
      </ng-container>
      <ng-template #defaultTmp>{{item!=undefined&&item!=null?item[textField]:''}}</ng-template>
    </mat-option>
  </mat-autocomplete>
</div>
