export class OrderConsumptionReport {
    startDate: Date;
    endDate: Date;
    nhiDrugType: number;
    reportType: string;
    endDateDisplay: Date;
    ReportTime: Date;
    Items: OrderConsumptionReportItem[];
}

export class OrderConsumptionReportItem {
    DoctorName: string;
    DrugCode: string;
    DrugName: string;
    UsageYear: number;
    UsageMonth: number;
    TotalUsageAmount: number;
    TotalBox: number;
    ClinicName: string;
    IsSummary: boolean;
}
