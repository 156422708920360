import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecordComponent } from './record/record.component';
import { RecordDentalComponent } from './record-dental/record-dental.component';
import { HistPrintOperationComponent } from './hist-print-operation/hist-print-operation.component';
import { BasicdataPrintOperationComponent } from './basicdata-print-operation/basicdata-print-operation.component';
import { InitialConsultationFollowUpReportComponent } from './initial-consultation-followUp-report/initial-consultation-followUp-report.component';
import { HistVisitcountComponent } from './hist-visitcount/hist-visitcount.component';
import { DoctorPatientDetailsComponent } from './doctor-patient-details/doctor-patient-details.component';
import { LabPrescriptionAndHrxListComponent } from './lab-prescription-and-hrx-list/lab-prescription-and-hrx-list.component';
import { StatusFinishWithoutPrescriptionComponent } from './status-finish-without-prescription/status-finish-without-prescription.component';


const routes: Routes = [
  {
    path: 'record',
    component: RecordComponent
  },
  {
    path: 'record-dental',
    component: RecordDentalComponent
  },
  {
    path: 'hist-print-operation',
    component: HistPrintOperationComponent,
  },
  {
    path: 'basicdata-print-operation',
    component: BasicdataPrintOperationComponent,
  },
  {
    path: 'initial-consultation-followUp-report',
    component: InitialConsultationFollowUpReportComponent,
  },
  {
    path: 'hist-visitcount',
    component: HistVisitcountComponent,
  },
  {
    path: 'doctor-patient-details',
    component: DoctorPatientDetailsComponent,
  },
  {
    path: 'lab-prescription-and-hrx-list',
    component: LabPrescriptionAndHrxListComponent
  },
  {
    path: 'status-finish-without-prescription',
    component: StatusFinishWithoutPrescriptionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HistRoutingModule { }
