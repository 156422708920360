<div #reportContainer>
  <div class="container page-container" *ngIf="hist && clinic" [ngStyle]="{'font-family': fontFamily}">

    <div class="row custom-row-style custom-font-style w-100 m-0">
      <div class="col-12 p-0">
        健保代碼:{{clinic.NHICode}} {{clinic.Name}}藥品明細收據
      </div>
    </div>

    <div class="row custom-row-style custom-font-style w-100 m-0">
      <div class="col-12 p-0">
        姓名:{{hist.Patient.CName}}&nbsp;&nbsp;
        性別:{{hist.Patient.SexName}}&nbsp;&nbsp;
        身分證:{{hist.Patient.CId| CIdMask:cidMask}}&nbsp;&nbsp;
        生日:{{hist.Patient.Birthday | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
      </div>

    </div>

    <div class="row custom-row-style w-100 custom-font-style m-0">
      <div class="col-12 p-0">
        就診日期:{{hist.Register.RegDate | formatROCDate:false:bitrhMask}}&nbsp;&nbsp;
        身分別:{{hist.Register.ICodeName}}&nbsp;&nbsp;
        診别:{{hist.Register.RoomName}}&nbsp;&nbsp;
        科別:{{getMedDeptName(hist.Register.MedDeptCode)}}&nbsp;&nbsp;
      </div>
    </div>

    <div class="row w-100 custom-row-style custom-font-style m-0">
      <div class="col-12 p-0">
        <div style="text-align: left;">
          診察:{{hist.Hist.ADx}}&nbsp;
          藥費:{{hist.Hist.ARx}}&nbsp;
          藥事:{{hist.Hist.AMed}}&nbsp;
          注射費:{{hist.Hist.ATx5}}&nbsp;
          檢驗費:{{hist.Hist.ATx6}}&nbsp;
          檢查費:{{hist.Hist.ATx7}}&nbsp;
        </div>
      </div>
    </div>

    <div class="row w-100 custom-row-style custom-font-style m-0">
      <div class="col-12 p-0">
        -----自付費用項目(金額)--------------------------------------------
    </div>
    </div>

    <div class="row w-100 custom-row-style custom-font-style m-0">
      <div class="col-12 p-0">
        <div style="text-align: left;">
          掛號費:{{hist.Register.RegisterFee}}&nbsp;
          藥品:{{receiptOwnFees.OwnRx}}&nbsp;
          注射:{{receiptOwnFees.OwnTx5}}&nbsp;
          檢驗檢查:{{receiptOwnFees.OwnTx67}}&nbsp;
          基本自付:{{hist.Register.OwnFee}}&nbsp;
        </div>
      </div>
    </div>

    <div class="row w-100 custom-row-style custom-font-style m-0">
      <div class="col-12 p-0">
        {{(hist.Hist.APartRx && hist.Hist.APartRx != 0) ? '★藥自付:' + (hist.Hist.APartRx*-1) + '&nbsp;' : ''}}
        {{(hist.Hist.APartExam && hist.Hist.APartExam != 0) ? '★檢驗自付:' + hist.Hist.APartExam + '&nbsp;' : ''}}
        {{(clinic.Code != '10272'?'押金:'+hist.Register.LAMT+ '&nbsp;' : '' )}}
        {{'其他自費:' + receiptOwnFees.OwnOther + '&nbsp;'}}
      </div>
    </div>

    <div class="row w-100 custom-row-style custom-font-style m-0" style="padding-left: 12px">
      <div class="col-12 p-0">
        ※本次收據共計:{{(hist.Register.RegisterFee + hist.Register.OwnFee + hist.Hist.APartExam + (hist.Hist.APartRx*-1) +
        hist.Hist.AOwn + (clinic.Code != '10272'?hist.Register.LAMT:0 ))}}
      </div>
    </div>
    <div class="row custom-row-style w-100 custom-font-style m-0">
      <div class="col-12 p-0">
        <table class="table">
          <thead>
            <tr class="">
              <th scope="col-1" class="table-th table-font">N</th>
              <th scope="col-5" class="table-th table-font">藥品名稱</th>
              <th scope="col-2" class="table-th table-font">服法*天數</th>
              <th scope="col-1" class="table-th table-font">劑量</th>
              <th scope="col-1" class="table-th table-font">總數</th>
              <th scope="col-1" class="table-th table-font">單位</th>
            </tr>
          </thead>
          <tbody>
            <tr class="" *ngFor="let hrx of hrxes">
              <td class="table-td table-font">{{hrx.SPRule}}</td>
              <td class="table-td table-font">{{hrx.ProdName}}</td>
              <td class="table-td table-font">{{hrx.Freq}}* {{hrx.Days}}</td>
              <td class="table-td table-font">{{hrx.QTYDose}}</td>
              <td class="table-td table-font">{{hrx.TotalDose}}</td>
              <td class="table-td table-font">{{hrx.Unit}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row custom-row-style w-100 custom-font-style" style="margin-top: 5px;">
      <div class="col-6 p-0">
        醫師: {{hist.Register.DoctorName}}
      </div>
      <div class="col-5 p-0">
        <ng-container *ngIf="hasPharmacist">
          藥師: {{hist.Hist.PharmacistName}}
        </ng-container>
      </div>
      <div class="col-12 p-0">
        地址: {{clinicAddress}}
      </div>
      <div class="col-12 p-0">
        電話: {{clinic.Phone}}
      </div>
    </div>
  </div>

</div>
