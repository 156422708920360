import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IBaseConfig } from '../shared/models/base-config';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import  * as  packageJson  from '../../../package.json';
import { UserCache } from './user-cache';
@Injectable({
  providedIn: 'root'
})
export class BaseConfig {

  // 有增減欄位需修改定義檔IBaseConfig
  private static config: IBaseConfig;
  static load = new Observable<IBaseConfig>();
  constructor(private http: HttpClient) {
  }

  static setConfig(data) {
    this.config = data;
    if(!this.config.webApi.hisBaseUrl.endsWith('/'))
    {
      this.config.webApi.hisBaseUrl+='/'
    }
    if(!this.config.webApi.visionAuthUrl.endsWith('/'))
    {
      this.config.webApi.visionAuthUrl+='/'
    }
    var debugFlag = localStorage.getItem('DebugMode')
    if(debugFlag==null){
      localStorage.setItem('DebugMode','');
    }
    if(debugFlag=='F')
    {
      this.config.env.isDebug=false;
    }
  }
  static getConfig():IBaseConfig {
    return this.config;
  }
  static isDebug(){
    // 手動開啟偵錯模式
    var debugFlag = localStorage.getItem('DebugMode')
    var manualDebugMode = debugFlag=='T';
    // 強制關閉偵錯模式
    var isForceDisableDebugMode = debugFlag=='F'
    //超級使用者
    var isAdmin = UserCache.getLoginUser().IsAdmin;

    //偵錯環境(dev/demo)
    var isDebugEnv = BaseConfig.getConfig().env?.isDebug;
    
    // 強制關閉偵錯模式 (可在偵錯環境下模擬非偵錯用)
    if(isForceDisableDebugMode){
      return false;
    }
    return isDebugEnv || manualDebugMode || isAdmin
  }

  // 依據environment讀取設定檔base-config.json
  async loadConfig() {
    let jsonFile = 'assets/configs/base-config.json';
    if (environment.envName) {
      jsonFile = 'assets/configs/base-config.' + environment.envName +'.json';
    }
    BaseConfig.load = this.http.get<IBaseConfig>(jsonFile);

    try {
      const data = await BaseConfig.load.toPromise();
      data.version = packageJson.version;
      data.ui.rocDate = true;
      BaseConfig.setConfig(data);
    } catch (err) {
      alert('Could not load config file.');
    }

  }
}
export function initializeBaseConfig(baseConfig: BaseConfig) {
  return async() => await baseConfig.loadConfig();
}

export var FunctionEnable = {
  get UserLineNotifyQRCode(){ return environment['envName']!= 'prod'},
  get EnableTeleMed(){ return environment['envName']!= 'prod'},
}