<div #reportContainer>
  <div class="container page-container" *ngIf="salesOrderRecepipt" style="-webkit-text-size-adjust: none;font-family: '{{ fontType }}';">
    <div class="row custom-row-margin w-100">
      <div class="col-12" style="font-size: x-large;text-align: center;">
        {{salesOrderRecepipt.ClinicName}} 明細收據</div>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-4 col-font-style">日期：{{salesOrderRecepipt.SaleDate}}</div>
      <div class="col-5 col-font-style">顧客編號：{{salesOrderRecepipt.PaitentNo}}</div>
      <div class="col-3 col-font-style">姓名：{{salesOrderRecepipt.PaitentName}}</div>
    </div>
    <div class="row custom-row-margin w-100">
      <table class="table">
        <thead>
          <tr class="">
            <td class="table-th table-font" style="width: 40%;">品項名稱</td>
            <td class="table-th table-font" style="width: 10%;">數量</td>
            <td class="table-th table-font" style="width: 15%;">單價</td>
            <td class="table-th table-font" style="width: 15%;">小計</td>
            <td class="table-th table-font" style="width: 10%;">折扣</td>
            <td class="table-th table-font" style="width: 10%;">折讓</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="table-td table-font" colspan="6">
              ----------------------------------------------------------------
            </td>
          </tr>
          <tr class="" *ngFor="let prod of salesOrderRecepipt.Products">
            <td class="table-td table-font">{{prod.RxName}}</td>
            <td class="table-td table-font">{{prod.Qty}}</td>
            <td class="table-td table-font">{{prod.UnitPrice}}</td>
            <td class="table-td table-font">{{prod.ActualPrice}}</td>
            <td class="table-td table-font">{{prod.Discount}}</td>
            <td class="table-td table-font">{{prod.Allowance}}</td>
          </tr>
          <tr>
            <td class="table-td table-font" colspan="6">
              ----------------------------------------------------------------
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row custom-row-margin w-100">
      <div class="col-12 col-font-style">合計金額：{{salesOrderRecepipt.TotalAmount}}</div>

    </div>
    <div class="row custom-row-margin w-100" *ngIf="false">
      <div class="col-12 col-font-style">備註：{{salesOrderRecepipt.Remark}}</div>
    </div>
  </div>

</div>
