
import * as CryptoJS from 'crypto-js';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
  export class CryptoService {
      constructor(){}
    key = 'vis1234554321siv';
    iv = 'aaaaaaaaaaaaaaaa';
    encrypt(data:string){
        const k = CryptoJS.enc.Utf8.parse(this.key);
        const p = CryptoJS.AES.encrypt(data,k,{ 
            iv: this.iv,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.ZeroPadding 
        });
        return CryptoJS.enc.Base64.stringify(p.ciphertext);
    }
  }  