
<div class="view-toolbar" *ngIf="this._isShowToolBar" [ngClass]="this._isShowToolBar?'hist-View-Narrow':''" 
  style="background-color: var(--vis-history-bg-color);display: flex; z-index: 400; position: fixed;">
  <form [formGroup]="copyFG" *ngIf="copyFG" style="display: flex;height: 25px;">
    <mat-radio-group formControlName="isInsert" class="vis-form-field extend-label" style="width: max-content; padding-top: 2px;padding-left: 5px;display: flex;">
      <mat-radio-button style="margin-right: 10px;" [value]="true">插入</mat-radio-button>
      <mat-radio-button style="margin-right: 10px;" [value]="false">取代</mat-radio-button>
    </mat-radio-group>
    <mat-checkbox style="margin-right: 10px;" formControlName="isIncludeCC" class="extend-label">主訴</mat-checkbox>
    <mat-checkbox style="margin-right: 10px;" formControlName="isIncludePE" class="extend-label">理學</mat-checkbox>
    <mat-checkbox style="margin-right: 10px;" formControlName="isIncludeDiag" class="extend-label">診斷</mat-checkbox>
    <mat-checkbox style="margin-right: 10px;" formControlName="isIncludeOrder" class="extend-label">處方</mat-checkbox>
    <!-- <input type="radio" id="isInsertTrue" value="true" class="k-radio" formControlName="isInsert">
    <label class="k-radio-label" for="isInsertTrue" i18n="@@Insert">插入</label>
    <input type="radio" id="isInsertFalse" value="false" class="k-radio" formControlName="isInsert">
    <label class="k-radio-label" for="isInsertFalse" i18n="@@Replace">取代</label> -->
    <!-- <input type="checkbox" id="isIncludeCC" class="k-checkbox" formControlName="isIncludeCC" />
    <label class="k-checkbox-label" for="isIncludeCC" i18n="@@HistCC">主訴</label>
    <input type="checkbox" id="isIncludeOrder" class="k-checkbox" formControlName="isIncludeOrder" />
    <label class="k-checkbox-label" for="isIncludeOrder" i18n="@@HistOrder">處方</label> -->
    <button type="button" (click)="onCopyClick()" i18n="@@Copy" [disabled]="_disabled">複製<span class="underline text-xs pb-1 px-1 vis-btn-block position-relative -top-2">F1</span></button>
  </form>
  <!-- <hr style="width: 100%;margin: 0px;height: 1px;background-color: #ddd;"> -->
</div>
<div *ngIf="this._isShowToolBar" style="height: 27px;"><span style="height: 27px;">&nbsp;</span></div>
<div>
  <app-shared-hist-view-content *ngIf="!isNoData" [minHist]="minHist" [showNhiPay]="showNhiPay" [HistView]="this.histView" [ClinicName]="this.ClinicName" [isNarrow]="this._isShowToolBar"></app-shared-hist-view-content>
  <div *ngIf="isNoData">無資料</div>
</div>
<div><span>&nbsp;</span></div>
