import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Arrears } from "src/app/payments/models/card-arrears";
import { WebApiClient } from "../../web-api-client.service";
import { WebApiService } from "../../web-api.service";
import { CustomResult } from "src/app/shared/models/custom-result";
import { DailyReport } from "src/app/payments/models/daily-report";
import { ReceiptQueryListDto } from "src/app/payments/period-receipt/model/receiptquerylistdto";
import { ReceiptQueryDto } from "src/app/payments/period-receipt/model/receiptquerydto";
import { DiscountCodeReport, PaymentInfoDto, PaymentListDto } from "../register/PaymentInfoDto";
import { RecardPageListDto } from "../hist/recard-list";
import { MatEndDate, MatStartDate } from "@angular/material/datepicker";
import { PatientListDto } from "../patient/patient-api";
import { SexEnum } from "src/app/enums/SexEnum";
import { InsuranceDeclarationComponent } from "src/app/nhi-assist/insurance-declaration/insurance-declaration.component";

@Injectable({
  providedIn: 'root'
})


export class PaymentApi {
  api: WebApiService;
  apiService: WebApiService;

  constructor(
    private apiClient: WebApiClient) {
    this.api = apiClient.createHisService('payments/payment');
  }
  
  private unsubscribe: Subject<void> = new Subject();

  UpdatePaymentMergeAsync() {
    return this.api.put('UpdatePaymentMergeAsync', {}).toPromise<CustomResult>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }
  
  GetChargeReport(sdate: Date, edate: Date, icode: string,
    singleFee: string, operator: string, amount: number,
    intervalFee: string, minAmount: number, maxAmount: number) {
    const query = {
      sdate: sdate.toLocaleDateString(),
      edate: edate.toLocaleDateString(),
      icode: icode,
      singleFee: singleFee,
      operators: operator,
      amount: amount,
      intervalFee: intervalFee,
      minAmount: minAmount,
      maxAmount: maxAmount
    };
    return this.api.get(`GetChargeReport`, query).toPromise<DailyReport>();
  }

  ChargeStatistics(sdate: Date, edate: Date, drCId: string, statisticsType: string) {
    const query = {
      sdate: sdate.toLocaleDateString(),
      edate: edate.toLocaleDateString(),
      drCId: drCId,
      statisticsType: statisticsType
    };
    return this.api.get(`ChargeStatisticsReport`, query).toPromise<DailyReport>();
  }

  GetDailyReport(query: any) {
    console.log('query', query);
    return this.api.get(`GetDailyReport`, query).toPromise<DailyReport>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  GetDailyReportQueryOptions() {
    return this.api.get(`GetDailyReportQueryOptions`, {}).toPromise<any>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  GetPaymentForNewReceiptAsync(data: ReceiptQueryDto) {
    // console.log('startDate',startDate,endDate);
    // var startDate = new Date(data.inputStartDate).toLocaleDateString();
    // var endDate = new Date(data.inputEndDate).toLocaleDateString();

    return this.api.post('GetPaymentForNewReceipt', data).toPromise<ReceiptQueryListDto>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  GetDiscountCode(query: any) {
    return this.api.get(`GetDiscountCode`, query).toPromise<DiscountCodeReport>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  GetDiscountCodeQueryOptions() {
    return this.api.get(`GetDiscountCodeQueryOptions`, {}).toPromise<any>();
    // return this.apiService.put(`Update`, model).toPromise<PatientsDto>();
  }

  GetClinicArrearList(query: any) {
    return this.api.post(`GetClinicArrearList`, query).toPromise<RecardPageListDto>();
  }

  async GetDiscountPatient(startDateWith: string, endDateWith: string, sortBy: string, DiscountType: string, endDate: any): Promise<PatientListDto[]> {
    return this.api.get(`GetDiscountPatient`, { startDateWith: startDateWith, endDateWith: endDateWith, sortBy: sortBy, DiscountType: DiscountType }).toPromise<PatientListDto[]>();
  }

  async GetDiscountPatientGrouping(startDateWith: string, endDateWith: string, sortBy: string, DiscountType: string, endDate: any): Promise<PatientListDto[]> {
    return this.api.get(`GetDiscountPatientGrouping`, { startDateWith: startDateWith, endDateWith: endDateWith, sortBy: sortBy, DiscountType: DiscountType }).toPromise<PatientListDto[]>();
  }

  async GetDiscountPatientDaily(startDateWith: string, endDateWith: string, groupByMonth: boolean): Promise<PatientListDto[]> {
    return this.api.get(`GetDiscountPatientDaily`, { startDateWith: startDateWith, endDateWith: endDateWith, groupByMonth: groupByMonth }).toPromise<PatientListDto[]>();
  }

  async GetDiscountPatientMonthly(startDateWith: string, endDateWith: string, groupByMonth: boolean): Promise<PatientListDto[]> {
    return this.api.get(`GetDiscountPatientMonthly`, { startDateWith: startDateWith, endDateWith: endDateWith, groupByMonth: groupByMonth }).toPromise<PatientListDto[]>();
  }

  async GetDiscountPatientStastic(startDateWith: string, endDateWith: string, groupByMonth: boolean): Promise<PatientListDto[]> {
    return this.api.get(`GetDiscountPatientStastic`, { startDateWith: startDateWith, endDateWith: endDateWith, groupByMonth: groupByMonth }).toPromise<PatientListDto[]>();
  }

  async GetInsurenceInfo(startDateWith: string, endDateWith: string,  ReportType: string, sortBy: string): Promise<PaymentListDto[]> {
    return this.api.get(`GetInsurenceInfo`, { startDateWith: startDateWith, endDateWith: endDateWith, ReportType: ReportType, sortBy: sortBy }).toPromise<PaymentListDto[]>();
  }
}
export { PatientListDto };