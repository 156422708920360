import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AIChatAPI } from 'src/app/services/api-service/ai-chat-api';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  showChat = false
  msg:MsgStack[] = []
  @ViewChild('chatContainer')
  chatContainer:ElementRef<HTMLDivElement>
  @ViewChild('chatInput')
  chatInput:ElementRef<HTMLInputElement>
  constructor(private api:AIChatAPI) { }

  ngOnInit(): void {
    this.msg.push({
      sender:'Bot',
      text:'您好，若您在使用上有任何疑問，可以在此提出，我會試著從知識庫中幫您萃取答案。'
    });
   
  }
  async keyup(evt:KeyboardEvent){
    if(evt.key=='Enter'){
      await this.submit();
      evt.preventDefault();
    }
  }
  async submit(){
    var text = this.chatInput.nativeElement.value;
    if(text.trim()){
      this.addUserMsg(text);
      await this.GetComplete(text)
    }    
  }
  private async GetComplete(text:string){
    try{
      var ret = await this.api.Complete(text);
      if(ret?.length>0){
        var m = ret[0];
        this.addBotMsg(m.Content)
        if(m.Link){
          this.addBotMsg(m.Link);
        }
      }
    }catch(e){
      this.addBotMsg('對不起...我錯了...' + e)
    }
    
  }
  private addUserMsg(text:string){
    this.msg.push({
      sender:'User',
      text:text
    })
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({'top':this.chatContainer.nativeElement.scrollHeight});  
    }, 0);
    this.chatInput.nativeElement.value = '';
  }
  private addBotMsg(text:string){
    this.msg.push({
      sender:'Bot',
      text:text
    })
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({'top':this.chatContainer.nativeElement.scrollHeight});  
    }, 0);
  }
}

class MsgStack{
  sender:'User'|'Bot';
  text:string;
}