<div class="example-header">
    <button type="button"
      (click)="previousClicked()"
      class="m-0 text-xs btn-grid p-0 w-6 mr-2px">
      <mat-icon class="vis-combine-icon d-inline-flex mr-1 mat-calendar-previous-button rounded-circle month-arrow-btn" title="上一年"></mat-icon>
    </button>
    <button mat-button (click)="currentPeriodClicked()" class="example-header-label w-32">{{periodLabel}}</button>
    <button type="button"
    (click)="nextClicked()"
    class="m-0 text-xs btn-grid p-0 w-6">
    <mat-icon class="vis-combine-icon d-inline-flex mr-1 mat-calendar-next-button rounded-circle month-arrow-btn" title="下一年"></mat-icon>
  </button>
</div>
