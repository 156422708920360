import { Injectable } from "@angular/core";
/** reload就沒了 */
@Injectable({
    providedIn: 'root'
})
export class MemoryCache{
    private cacheMap: Map<string,any> = new Map();
    /**
     *
     */
    constructor() {
        // console.log('MemoryCache constructed');
    }
    public set(key:string,data:any){
        // console.log('set cache '+key);
        this.cacheMap.set(key,data);
    }
    public get(key: string,defaultValue: any=null){
        // console.log('get cache '+key);
        return this.cacheMap.get(key) ?? defaultValue;
    }
    public clear(key:string){
        this.cacheMap.delete(key);
    }
    public has(key:string){
        return this.cacheMap.has(key);
    }
}