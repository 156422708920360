export interface IValueTextPair<T> {
  text: string;
  value: T;
  extension?: any;
}

export class ValueTextPair implements IValueTextPair<string> {
  text: string;
  value: string;
  extension?: any;

  static fromNumberValue(array: ValueTextPairNumberValue[]): ValueTextPair[] {
    return array.map(item => {
      let dic = new ValueTextPair();
      dic.text = item.text;
      dic.value = item.value.toString();
      dic.extension = item.extension;

      return dic;
    });
  }
}

export class ValueTextPairNumberValue implements IValueTextPair<number> {
  text: string;
  value: number;
  extension?: any;
}

export class ValueTextPairAnyValue {
  text: string;
  value: any;
  extension?: any;
}
export class ValueTextPairT<T> extends ValueTextPairAnyValue {
  value: T;
}
