export enum HelperSourceEnum {
  NA = 'NotAvailable',

  // --- input
  CC = 'CC', // cheif complaint
  PE = 'PE', // physical examination
  Diag = 'Diag', // diagnosis
  ChiLook = 'ChiLook',
  ChiAsk = 'ChiAsk',
  ChiPulse = 'ChiPulse',
  ChiBianZheng = 'ChiBianZheng',
  ChiTreatRule = 'ChiTreatRule',

  // --- grid
  Order = 'Order',
  ChiOrder = 'ChiOrder',

  // --- order grid dropdown
  Freq = 'Freq', // order freq
  Way = 'Way', // order way
  Dispensing = 'Dispensing',
  ChiProcess = 'ChiProcess', // ChiProcess

  WayPart = 'WayPart', // order way - part

  // history list
  HistList = 'HistList', // 歷史紀錄清單
}
