/** N標記 */
export enum OrderSpecialRuleEnum {
    /** 會申報會計價 */
    A0_ApplyPay = 0,
    /** 申報不計價 */
    A1_Apply = 1,
    /** 不申報計自費價 */
    A2_SelfPayment = 2,
    /** 不申報不計價要列印 */
    A3_FreeAndPrint = 3,
    /** 不申報不計價不列印 */
    A4_FreeNotPrint = 4,
    /** 申報計價不列印 */
    A5_ApplyNotPrint = 5,
    /** 檢驗外送代檢自行申報 */
    A6_ExtTestSelfApply = 6,
    /** 檢驗外送代檢代申報 */
    A7_ExtTest = 7
    /**  */
  //A5_Milling = 5,  //取消
    /**  */
  //A8_SelfDispensing = 8,  //取消
    /**  */
  //A9_SpecialDelivery = 9,  //取消
    /**  */
  //A10_PrintAllDrugs = 10  //取消
}

export const OrderSpecialRuleDesc: { text: string, value: number }[] =
  [{ text: '會申報會計價', value: 0 },
   { text: '申報不計價', value: 1 },
   { text: '不申報計自費價', value: 2 },
   { text: '不申報不計價要列印', value: 3 },
   { text: '不申報不計價不列印', value: 4 },
   { text: '申報計價不列印', value: 5 },
   { text: '檢驗外送代檢自行申報', value: 6 },
   { text: '檢驗外送代檢代申報', value: 7 }];
  //  {text: '本藥磨粉', value: 5 },
  //  {text: '本藥為自調', value: 8 },
  //  {text: '特材或針劑交付', value: 9 },
  //  {text: '交付藥材一律印出', value: 10 }];

export const ControlDrugType: { text: string, value: string }[] =
[{ text: '', value: ''},
{ text: 'A 第一級', value: 'A'},
{ text: 'B 第二級', value: 'B'},
{ text: 'C 第三級', value: 'C'},
{ text: 'D 第四級', value: 'D'}]

export const BoxUnitType: { text: string, value: string }[] =
[{ text: '', value: ''},
{ text: '7 代檢', value: '7'},
{ text: 'A 病代', value: 'A'},
{ text: 'D EPO', value: 'D'}]
