import { RxSetDto } from "./rx-set-dto";

export class MainSetDto {
  Id: number;
  Code: string;
  Name: string;
  ClinicId: number;
  UserId: number;
  Dx1: string;
  Dx2: string;
  Dx3: string;
  Dx4: string;
  Dx5: string;
  Dx6: string;
  CC: string;
  PE: string;
  Sur1: string;
  Sur2: string;
  MedDeptCode: string;
  IsEnabled: boolean;
  Creater: string;
  CreateTime: Date;
  Editer: string;
  EditTime: Date;
  Details:RxSetDto[];
  
  //EName: string;
}
