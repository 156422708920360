import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HistService } from 'src/app/hist/services/hist.service';
import { Dosage } from 'src/app/opd/dosage/models/dosage';
import { HistApi } from 'src/app/services/api-service/hist/hist-api';
import { HistEditOption } from 'src/app/services/api-service/hist/hist-edit-option';
import { WholeHist } from 'src/app/services/api-service/hist/whole-hist';
import { ParameterApi } from 'src/app/services/api-service/parameters/parameter-api';
import { NHIRegIC } from 'src/app/services/api-service/register/nhiRegIc';
import { RegistEditOpt, RegisterApi } from 'src/app/services/api-service/register/register-api';
import { UserCache } from 'src/app/services/user-cache';
import { Clinic } from 'src/app/system/clinic/models/clinic';
import { DispensingTypeEnum } from "src/app/enums/DispensingTypeEnum";
import { PRN002Params } from 'src/app/services/api-service/parameters/PRN002Params';
import { ClinicDataService } from 'src/app/services/data-service/clinic-data-service';
import { ReportComponent } from '../report-component';

export class ReceiptOwnFees {
  /** 自費藥品 */
  OwnRx: number = 0;
  /** 自費注射 */
  OwnTx5: number = 0;
  /** 自費檢驗檢查 */
  OwnTx67: number = 0;
  /** 自費特殊A30費 */
  OwnTx30: number = 0;
  /** 自費特殊A31費 */
  OwnTx31: number = 0;
  /** 自費特殊A32費 */
  OwnTx32: number = 0;
  /** 其他自費 */
  OwnOther: number = 0;
  /**
   *
   */
  constructor(hist:WholeHist) {
    if(!hist){
      return;
    }
    this.OwnOther = hist.Hist.AOwn ?? 0
    if (hist?.Hist?.HRxs?.length > 0) {
      let hrxs = hist?.Hist.HRxs;
      for (let i = 0; i < hrxs.length; i++) {
        if ([2, 3, 4].indexOf(hrxs[i].RxType) >= 0 && hrxs[i].SPRule == 2) {

          this.OwnRx += Math.round(hrxs[i].Price *(hrxs[i].TotalDose || 1));
        }
        else if ([6, 7].indexOf(hrxs[i].RxType) >= 0 && hrxs[i].SPRule == 2) {
          this.OwnTx67 += hrxs[i].Price;
        }
        else if (hrxs[i].RxType == 5 && hrxs[i].SPRule == 2){
          this.OwnTx5 += hrxs[i].Price;
        }
        else if (hrxs[i].PaymentType == 'A30') {
          this.OwnTx30 += hrxs[i].Price;
        }
        else if (hrxs[i].PaymentType == 'A31') {
          this.OwnTx31 += hrxs[i].Price;
        }
        else if (hrxs[i].PaymentType == 'A32') {
          this.OwnTx32 += hrxs[i].Price;
        }

      }
      // if (this.clinic.Code != '10272') ownfees.OwnRx = 0;
      this.OwnOther = (this.OwnOther - this.OwnRx - this.OwnTx5 - this.OwnTx67
        - this.OwnTx30 - this.OwnTx31 - this.OwnTx32)
    }
  }
}

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.css']
})
export class ReceiptComponent implements ReportComponent, OnInit {

  @ViewChild('reportContainer')
  reportContainer: ElementRef<HTMLDivElement>;
  hist: WholeHist;
  icData: NHIRegIC;
  hrxViewModel: HrxViewModel[] = [];
  clinic: Clinic;
  isqno: string;
  clinicAddress: string;
  editOptions: RegistEditOpt;
  type: number = 1;
  doubleSidedPrintType: number = 0;
  param2: PRN002Params;
  receiptOwnFees = new ReceiptOwnFees(null);
  hasPharmacist: boolean = false;
  fontType: string;

  @Input()
  set regId(id) {
    this.setRegId(id);
  }
  get hEditOptions(): HistEditOption {
    return this.histService.EditOptions;
  }
  get dosages(): Dosage[] {
    return this.hEditOptions.dosage;
  }
  cidMask = '1234567890'

  bitrhMask = 'YYY年MM月DD日'
  constructor(private histApi: HistApi,
    private regApi: RegisterApi,
    private histService: HistService,
    private parameterApi: ParameterApi,
    private clinicDataService: ClinicDataService,
    private cdr: ChangeDetectorRef) {

  }

  async ngOnInit() {
    this.clinic = UserCache.getLoginUser().Clinic;
    if (this.regId) {
      this.hist = await this.histApi.getWholeHist({ regId: this.regId });
    }
  }

  async setRegId(regId: number) {
    this.clinic = UserCache.getLoginUser().Clinic;
    let clinicHasPharmacist = UserCache.getLoginUser().Clinic.HasPharmacist;
    this.clinicAddress = (this.clinic.City || '') + (this.clinic.Area || '') + (this.clinic.Street || '');
    this.editOptions = await this.regApi.GetEditOptions();
    var histAndIc = await this.histApi.getReceiptInfo(regId);

    this.icData = histAndIc.NHIRegIC;
    this.hist = histAndIc.WholeHist;
    this.hist.Hist.HRxs = this.hist.Hist.HRxs.filter(x => x.SPRule != 5);

    let disptp = this.hist?.Hist?.DispTP || '';
    this.hasPharmacist = clinicHasPharmacist && (disptp == DispensingTypeEnum.Clinic || disptp == DispensingTypeEnum.EmerSelf)

    // 健保轉自費會有icDate但是退掛或刪除了，自費不要顯示就醫序號
    if (this.icData && this.hist?.Register?.ICode !== '000') {
      this.isqno = this.icData?.Isqno
    }
    else if (this.hist?.Register) {
      this.isqno = (this.hist?.Register?.ICode === '000' ? '自費' : this.hist?.Register?.Icno);
    }
    let ownfees = new ReceiptOwnFees(this.hist);
    // 併入 ReceiptOwnFees 建構子
    //ownfees.OwnOther = this.hist.Hist.AOwn ?? 0
    this.hrxViewModel = [];
    if (this.hist?.Hist?.HRxs?.length > 0) {
      this.hrxViewModel = this.hist?.Hist?.HRxs.map(x => {
        var rx = histAndIc.Rx.find(r => r.RxCode == x.RxCode);
        var opt = this.dosages.find(opt => opt.Code == x.Freq);
        return {
          SPRule: x.SPRule === 0 ? '' : x.SPRule.toString(),
          ProdName: this.stringFactory(x.ProdName, 27),
          Freq: (opt?.Name ?? x.Freq), //x.Freq,
          Days: x.Days,
          QTYDose: x.Dose,
          TotalDose: x.TotalDose,
          Unit: rx.SyrupNote=='a1'? x.BoxUnit : x.Unit,
          Indications: rx.Indications,
          SideEffects: rx.SideEffects,
          Warnings: rx.Warnings
        };
      });
      // 併入 ReceiptOwnFees 建構子
      //let hrxs = this.hist?.Hist.HRxs;
      // for (let i = 0; i < hrxs.length; i++) {
      //   hrxs[i].Rx
      //   let opt = this.dosages.find(opt => opt.Code == hrxs[i].Freq);
      //   //收據 建議 病歷聯服法用英文,可減少文字量 藥師也看得懂
      //   //因此註解掉下面這行
      //   // hrxs[i].Freq = opt?.Name ?? hrxs[i].Freq;
      //   if ([2, 3, 4].indexOf(hrxs[i].RxType) >= 0 && hrxs[i].SPRule == 2) {
      //     ownfees.OwnRx += (hrxs[i].Price * (hrxs[i].TotalDose || 1));
      //   }
      //   else if ([6, 7].indexOf(hrxs[i].RxType) >= 0 && hrxs[i].SPRule == 2) ownfees.OwnTx67 += hrxs[i].Price;
      //   else if (hrxs[i].RxType == 5 && hrxs[i].SPRule == 2) ownfees.OwnTx5 += hrxs[i].Price;
      //   else if (hrxs[i].PaymentType == 'A30') ownfees.OwnTx30 += hrxs[i].Price;
      //   else if (hrxs[i].PaymentType == 'A31') ownfees.OwnTx31 += hrxs[i].Price;
      //   else if (hrxs[i].PaymentType == 'A32') ownfees.OwnTx32 += hrxs[i].Price;

      // }
      // // if (this.clinic.Code != '10272') ownfees.OwnRx = 0;
      // ownfees.OwnOther = (ownfees.OwnOther - ownfees.OwnRx - ownfees.OwnTx5 - ownfees.OwnTx67
      //   - ownfees.OwnTx30 - ownfees.OwnTx31 - ownfees.OwnTx32)
    }

    this.receiptOwnFees = ownfees;

    var param = await this.clinicDataService.getParam("PRN001");
    this.param2 = await this.clinicDataService.getParam("PRN002");
    this.type = param?.ReceiptType ?? 1;
    this.doubleSidedPrintType = param?.DoubleSidedPrintType ?? 0;
    this.cidMask = param.IDHidePRN ?? this.cidMask;
    this.bitrhMask = param.BirthdayHidePRN ?? this.bitrhMask;
    this.fontType = (param.FontTypeForPrint == 0 ? '細明體' : (param.FontTypeForPrint == 1 ? '標楷體' : (param.FontTypeForPrint == 2 ? '微軟正黑體' : '細明體')));
    if (this.type == 1) {
      var ss = '14.3cm 19.5cm;';  // A5
      var st = document.createElement('style');
      st.appendChild(document.createTextNode(
        '@page { size: ' + ss + ' margin: 0cm; }\n' +
        '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;}}'
      ));
      setTimeout(() => {
        if (this.reportContainer) {
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
      }, 0);
    }
    else if (this.type == 3) {
      var ss = '11.0cm 14cm;';
      var st = document.createElement('style');
      st.appendChild(document.createTextNode(
        '@page { size: ' + ss + ' margin: 0cm; }\n' +
        '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;}}'
      ));
      setTimeout(() => {
        if (this.reportContainer) {
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
      }, 0);
    }
    else if (this.type == 2) {
      var ss = '21cm 29.5cm;';
      var st = document.createElement('style');
      st.appendChild(document.createTextNode(
        '@page { size: ' + ss + ' margin: 0cm; }\n' +
        '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;}}'
      ));
      setTimeout(() => {
        if (this.reportContainer) {
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
      }, 0);
    }
    else if (this.type == 4) {
      var ss = '21.5cm 14cm;';
      var st = document.createElement('style');
      st.appendChild(document.createTextNode(
        '@page { size: ' + ss + ' margin: 0cm; }\n' +
        '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;}}'
      ));
      setTimeout(() => {
        if (this.reportContainer) {
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
      }, 0);
    }
    else if (this.type == 5) {
      var ss = '21.5cm 9.4cm;';
      var st = document.createElement('style');
      st.appendChild(document.createTextNode(
        '@page { size: ' + ss + ' margin: 0cm; }\n' +
        '@media print {body {margin: 0; padding: 0; color: #000; background-color: #fff;}}'
      ));
      this.type = 4;
      setTimeout(() => {
        if (this.reportContainer) {
          this.reportContainer.nativeElement.appendChild(st);
          this.cdr.markForCheck();
        }
      }, 0);
    }
  }

  getHtml() {
    console.log("ReceiptComponent.Type", this.type);
    var html = this.reportContainer.nativeElement.innerHTML;
    return html;
  }

  dxs() {
    var h = this.hist.Hist;
    return [h.Dx1, h.Dx2, h.Dx3, h.Dx4, h.Dx5, h.Dx6].filter(x => x).join(',')
  }

  getMedDeptName(src: string): string {
    let medDep = this.editOptions.section.find(x => x.value == src);
    if (medDep) {
      return medDep.text;
    } else {
      return src;
    }
  }
  stringFactory(src: string, maxlength: number): string {
    if (src == null || src == '' || src == undefined) return ''      // 使用正規表達式匹配中文字符
    const chineseCharacters = src.match(/[\u4e00-\u9fa5]/g);
    let initlength = 0;
    // 如果沒有中文字符，則返回整個字串的長度
    for (let ind = 0; ind < src.length; ind++) {
      let chineseAlpha = src[ind].match(/[\u4e00-\u9fa5]/g);
      if (chineseAlpha) initlength += 2;
      else initlength += 1;
      if (initlength >= maxlength) {
        return src.substr(0, ind) + '...';
      }
    }
    return src;
  }
}

interface HrxViewModel {
  SPRule: string;
  ProdName: string;
  Freq: string;
  Days: number;
  QTYDose: number;
  TotalDose: number;
  Unit: string;
  Indications: string;
  SideEffects: string;
  Warnings: string;
}
