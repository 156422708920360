import { LoginUser } from 'src/app/permission/models/login-user';
import { Injectable } from '@angular/core';
import { Clinic } from 'src/app/system/clinic/models/clinic';

@Injectable({
  providedIn: 'root'
})
// UserCache預設是使用Memory的資料，只有Memory沒有時才讀取localStorage
// 這是為了效率
// 一方面為了安全，避免localStorage直接被修改，
// window.beforeunload時會把資料存到(覆蓋)localStorage一次
// 而每次login時，也是從server端取得最新資料
// 這樣資料會被竄改的時間只有在unload與load之間的時間
export class UserCache {
  private static loginUser: LoginUser;
  private static storageKeyName = 'xAuthLoginUser';

  public static setLoginUser(loginUser: LoginUser) {
    this.loginUser = loginUser;
    localStorage.setItem(this.storageKeyName, JSON.stringify(loginUser));
  }
  public static getLoginUser(): LoginUser {
    if (this.loginUser) {
      return this.loginUser;
    } else {
      this.loginUser = this.getLocalstorage(this.storageKeyName);
      if (this.loginUser) {
        return this.loginUser;
      } else {
        return new LoginUser();
      }
    }
  }

  //取得現在的ClinicId
  public static getLoginClinicId() {
    return UserCache.getLoginUser().Clinic.Id;
  }

  // 用Memory資料存到(覆蓋)localStorage
  public static setLoginUserFromMemory() {
    UserCache.setLoginUser(this.loginUser);
  }

  // function
  public static clearLoginUser() {
    // console.log('Clear clearLoginUser');
    this.loginUser = new LoginUser();
    localStorage.removeItem(this.storageKeyName);
  }
  public static changeClinic(clinic: Clinic): boolean {
    if (this.loginUser) {
      this.loginUser.Clinic = clinic;
      this.setLoginUser(this.loginUser);
      return true;
    } else {
      return false;
    }
  }

  public static clearMenu() {
    UserCache.getLoginUser().Menus = [];
    this.loginUser.Menus = [];
  }

  // private
  private static getLocalstorage(key: string) {
    try {
      const jsonStr = localStorage.getItem(key);
      if (!jsonStr) {
        return null;
      }
      return JSON.parse(jsonStr);
    } catch {
      return null;
    }
  }
}
