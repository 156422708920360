import { Component, ContentChild, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Distinct } from '../../../utilities';
import { PageData } from '../../../models/page-data';
import { EasyNotificationService } from 'src/app/services/easy-notification.service';
import { SystemcodeApi } from 'src/app/services/api-service/system-code/systemcode-api';
import { SystemcodeshareApi } from 'src/app/services/api-service/system-code/systemcodeshare-api';

@Component({
  selector: 'app-std-system-code-import',
  templateUrl: './std-system-code-import.component.html',
  styleUrls: ['./std-system-code-import.component.css']
})
export class StdSystemCodeImportComponent implements OnInit {
  @ContentChild('customBtn')
  customBtnTmp: TemplateRef<any>;

  // 標準代碼清單物件
  stdGridView: any;
  stdListItems: PageData;
  stdListpageSkipNo = 0;
  stdListpageSize: number = 1000;  //BaseConfig.getConfig().ui.pageSize;
  // 標準檔表格-所選項目
  stdSelectedItems: string[] = [];
  @Input() typeCode: string;

  private _show = false;
  get show() { return this._show; }
  set show(v: boolean) {
    this._show = v;
  }

  @Output() importDone: EventEmitter<void> = new EventEmitter();
  @Output() onClose: EventEmitter<void> = new EventEmitter();

  constructor(private notification: EasyNotificationService,
    private systemcodeApi: SystemcodeApi,
    private systemCodeShareApi: SystemcodeshareApi

  ) {

  }

  ngOnInit(): void {
    console.log(this.typeCode);
    this.refreshStdList();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['typeCode'] && !changes['typeCode'].isFirstChange()) {
      this.onTypeCodeChange(changes['typeCode'].currentValue, changes['typeCode'].previousValue);
    }
  }

  onTypeCodeChange(currentValue: string, previousValue: string) {
    if (currentValue) {
      this.handleShow();
    }
  }


  handleClose() {
    this.show = false;
    this.onClose.emit();
  }

  handleShow() {
    this.show = true;
    this.refreshStdList();
  }

  stdListPageChange(pageNum: any): void {
    this.stdListpageSkipNo = (pageNum - 1) * this.stdListpageSize;
    this.refreshStdList();
  }

  // 載入標準代碼清單
  async refreshStdList() {
    if (!this.typeCode)
      return;

    const query = this.makeStdQueryCondition();
    query.pageSize = this.stdListpageSize;
    query.pageSkip = this.stdListpageSkipNo;
    try {
      var data: PageData = await this.systemCodeShareApi.GetAll(query);
      this.stdListItems = data;
      this.setStdGridView();
    } catch (ex) {
      this.notification.showGetDataFailed();
    }
  }

  // 標準檔查詢條件
  makeStdQueryCondition(): any {
    const query = {
      typeCode: this.typeCode,
      pageSize: this.stdListpageSize
    }
    return query;
  }

  setStdGridView() {
    this.stdGridView = {
      data: this.stdListItems.data,
      total: this.stdListItems.total
    };
  }

  checkChanged(evt: { checked: boolean, data: any }) {
    // console.log('selected evt :', evt);
    if (evt.checked == true) {
      // select all
      if (evt.data == null) {
        this.stdSelectedItems = this.stdSelectedItems.concat(this.stdListItems.data.map(d => d.SN));
      } else {
        this.stdSelectedItems.push(evt.data.SN);
      }
      this.stdSelectedItems = Distinct(this.stdSelectedItems);
    } else {
      var filter = [];
      if (evt.data == null) {
        filter = filter.concat(this.stdListItems.data.map(d => d.SN));
      } else {
        filter.push(evt.data.SN);
      }
      this.stdSelectedItems = this.stdSelectedItems.filter(f => !filter.includes(f));
    }
  }

  async importRows() {

    let importStdCodeIds: number[] = [];
    let typecode = this.typeCode;

    for (var i = 0; i < this.stdSelectedItems.length; i++) {
      let sn = this.stdSelectedItems[i];

      let selectStdCodeItem = this.stdListItems.data.find(x => x.TypeCode == typecode && x.SN == sn);
      importStdCodeIds = [...importStdCodeIds, selectStdCodeItem.Id];

    }

    try {
      await this.systemcodeApi.Import(importStdCodeIds);
    } catch {
      this.notification.showFailed()
    }

    this.notification.showOk();
    this.handleClose();

    this.importDone.emit();
  }


}
